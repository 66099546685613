<section class="container-fluid">
  <app-loader [loader]="loading.claimsDetail"></app-loader>
  <div class="row p-2 bg-blue-light justify-content-end" *ngIf="claimDetail != null">
    <div class="col-4">
      <table class="table-xs mb-0" mdbTable small="true" borderless="true">
        <tr>
          <td><span>Claim ID:</span></td>
          <td>
            <strong>{{ claimDetail.patientAccountNumber }}</strong>
          </td>
        </tr>
        <tr>
          <td width="110"><span>Claim Type:</span></td>
          <td>
            <strong>{{ claimDetail.transaction.ediType }}</strong>
          </td>
        </tr>
        <tr *ngIf="claimDetail.type.toLowerCase() == 'incoming'">
          <td><span>File Name: </span></td>
          <td>
            <strong class="text-break">{{
              claimDetail.claimXRefHolista.claimFile?.fileName
            }}</strong>
          </td>
        </tr>
        <tr
          *ngIf="
            claimDetail.claimXRefHolista.processingStatus === 'PAID' &&
            claimDetail.claimXRefHolista.ediFilename
          ">
          <td><span>835 File Name: </span></td>
          <td>
            <strong class="text-break">{{ claimDetail.claimXRefHolista.ediFilename }}</strong>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-4">
      <table class="table-xs mb-0" mdbTable small="true" borderless="true">
        <tr>
          <td><span>Patient Name:</span></td>
          <td>
            <strong
              >{{
                (claimDetail.patient.patientFirstName
                  ? claimDetail.patient.patientFirstName
                  : claimDetail.patient.subscriberFirstName
                ) | titlecase
              }}
              {{
                (claimDetail.patient.patientMiddleName
                  ? claimDetail.patient.patientMiddleName
                  : claimDetail.patient.subscriberMiddleName
                ) | titlecase
              }}
              {{
                (claimDetail.patient.patientLastName
                  ? claimDetail.patient.patientLastName
                  : claimDetail.patient.subscriberLastName
                ) | titlecase
              }}</strong
            >
          </td>
        </tr>
        <tr>
          <td width="180"><span>Episode Patient Name:</span></td>
          <td>
            <strong>{{ claimDetail.claimXRefHolista.patientName }}</strong>
          </td>
        </tr>
        <tr>
          <td><span>Funding Request:</span></td>
          <td>
            <strong>{{ claimDetail.claimXRefHolista.fundingReqName }}</strong>
          </td>
        </tr>
        <tr>
          <td><span>Bundle Components: </span></td>
          <td>
            <strong>{{ claimDetail.claimXRefHolista.claimComponents }}</strong>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-4">
      <table class="table-xs mb-0" mdbTable small="true" borderless="true">
        <tr>
          <td><span>Episode Name:</span></td>
          <td>
            <strong>{{ claimDetail.claimXRefHolista.episodeName }}</strong>
          </td>
        </tr>
        <tr>
          <td><span>Bundle Name:</span></td>
          <td>
            <strong>{{ claimDetail.claimXRefHolista.bundleName }}</strong>
          </td>
        </tr>
        <tr>
          <td width="150"><span>Processing Status: </span></td>
          <td>
            <strong>{{ claimDetail.claimXRefHolista.processingStatus }}</strong>
          </td>
        </tr>
        <tr *ngIf="claimDetail.claimXRefHolista.statusReason">
          <td><span>Reason: </span></td>
          <td>
            <span class="text-danger">{{ claimDetail.claimXRefHolista.statusReason }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <!-- Tabset -->
  <div class="position-relative" *ngIf="claimDetail != null">
    <div class="position-absolute top-1 end-0 zindex-dropdown">
      <button
        *ngIf="user.roleCode !== 'PR'"
        (click)="onSendEmail()"
        type="button"
        class="btn btn-light border btn-sm waves-light m-0 mr-2"
        data-toggle="modal"
        data-target="#basicExample"
        mdbWavesEffect>
        <i class="fa-fa fa-envelope fas"></i> Send Email
      </button>
      <button
        *ngIf="user.roleCode !== 'PR'"
        type="button"
        class="btn btn-light border btn-sm waves-light m-0"
        data-toggle="modal"
        data-target="#basicExample"
        (click)="OpenNotes()"
        mdbWavesEffect>
        <i class="icofont-ui-note"></i> Notes
      </button>
    </div>
    <mdb-tabset
      [buttonClass]="'classic-tabs'"
      [contentClass]="'tab-clean'"
      class="classic-tabs custom-tabs">
      <!--Panel 1-->
      <mdb-tab heading="Submitter">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <h6>Basic Information</h6>
              <div
                *ngIf="
                  claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                    claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                  ) &&
                  claimDetail.type.toLowerCase() === 'outgoing' &&
                  utilityAccess.searchAccess('OCLM', 'isEditable', false)
                ">
                <a *ngIf="!edit.submitter" (click)="edit.submitter = !edit.submitter"
                  ><i class="fas fa-pencil-alt"></i> Edit</a
                >
                <a *ngIf="edit.submitter" (click)="update('submitter')" [class.disabled-link]="disableSubmitterUpdate" class="mr-4"
                  ><i class="fas fa-save"></i> Save</a
                >
                <a
                  *ngIf="edit.submitter"
                  (click)="edit.submitter = !edit.submitter; cancel('submitter')"
                  ><i class="fas fa-times"></i> Cancel</a
                >
              </div>
            </div>
            <table mdbTable bordered="true" class="claim-details" small="true">
              <tr>
                <th>Entity Identifier Code</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.identifierCode
                      : ''
                  }}</span>
                </td>

                <th>
                  Submitter Identifier/ Identification Code <span *ngIf="edit.submitter"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.submitter">{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.identificationCode
                      : ''
                  }}</span>
                  <span *ngIf="edit.submitter">
                    <input
                      type="text"
                      [(ngModel)]="claimDetail.transaction.submitter.identificationCode"
                      mdbInput
                      class="form-control"
                      minlength="2"
                      maxlength="15"
                      [ngClass]="{
                        'is-invalid':
                          claimDetail.transaction.submitter.identificationCode.length < 2
                      }"
                      (ngModelChange)="submitterChange()"
                      name="submitteridentificationCode"
                  /></span>
                </td>
              </tr>
              <tr>
                <th>Entity Type Qualifier</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.entityTypeQualifier
                      : ''
                  }}</span>
                </td>

                <th>Contact Code</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.contactCode
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  Organization/ Business Name
                  <span *ngIf="edit.submitter"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.submitter">{{
                    claimDetail.transaction.submitter
                      ? (claimDetail.transaction.submitter.businessName | uppercase)
                      : ''
                  }}</span>
                  <span *ngIf="edit.submitter">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="claimDetail.transaction.submitter.businessName"
                      maxlength="60"
                      [ngClass]="{'is-invalid': !claimDetail.transaction.submitter.businessName}"
                      (ngModelChange)="submitterChange()"
                      name="submitterbusinessName"
                  /></span>
                </td>

                <th>
                  Contact Name
                  <span *ngIf="edit.submitter"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.submitter">{{
                    claimDetail.transaction.submitter
                      ? (claimDetail.transaction.submitter.contactName | uppercase)
                      : ''
                  }}</span>
                  <span *ngIf="edit.submitter">
                    <input
                      type="text"
                      mdbInput
                      [(ngModel)]="claimDetail.transaction.submitter.contactName"
                      class="form-control"
                      maxlength="60"
                      [ngClass]="{'is-invalid': !claimDetail.transaction.submitter.contactName}"
                      (ngModelChange)="submitterChange()"
                      name="submittercontactName"
                  /></span>
                </td>
              </tr>
              <tr>
                <th>First Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? (claimDetail.transaction.submitter.firstName | uppercase)
                      : ''
                  }}</span>
                </td>

                <th>Contact Number Qualifier 1</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.contactNumberQualifier1
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Middle Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? (claimDetail.transaction.submitter.middleName | uppercase)
                      : ''
                  }}</span>
                </td>

                <th>
                  Contact Number 1
                  <span *ngIf="edit.submitter"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.submitter">{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.contactNumber1
                      : ''
                  }}</span>
                  <span *ngIf="edit.submitter">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="claimDetail.transaction.submitter.contactNumber1"
                      maxlength="256"
                      [ngClass]="{'is-invalid': !claimDetail.transaction.submitter.contactNumber1}"
                      (ngModelChange)="submitterChange()"
                      name="submittercontactNumber1"
                  /></span>
                </td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? (claimDetail.transaction.submitter.lastName | uppercase)
                      : ''
                  }}</span>
                </td>

                <th>Contact Number Qualifier 2</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.contactNumberQualifier2
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Prefix</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.prefix
                      : ''
                  }}</span>
                </td>

                <th>Contact Number 2</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.contactNumber2
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Suffix</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.suffix
                      : ''
                  }}</span>
                </td>

                <th>Contact Number Qualifier 3</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.contactNumberQualifier3
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Identification Code Qualifier</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.identificationCodeQualifier
                      : ''
                  }}</span>
                </td>

                <th>Contact Number 3</th>
                <td>
                  <span>{{
                    claimDetail.transaction.submitter
                      ? claimDetail.transaction.submitter.contactNumber3
                      : ''
                  }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mdb-tab>
      <!--Panel 2-->
      <mdb-tab heading="Receiver">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <h6>Basic Information</h6>
              <div
                *ngIf="
                  claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                    claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                  ) &&
                  claimDetail?.type?.toLowerCase() === 'outgoing' &&
                  utilityAccess?.searchAccess('OCLM', 'isEditable', false)
                ">
                <a *ngIf="!edit.receiver" (click)="edit.receiver = !edit.receiver"
                  ><i class="fas fa-pencil-alt"></i> Edit</a
                >
                <a *ngIf="edit.receiver" (click)="update('receiver')" [class.disabled-link]="disableReceiverUpdate" class="mr-4"
                  ><i class="fas fa-save"></i> Save</a
                >
                <a
                  *ngIf="edit.receiver"
                  (click)="edit.receiver = !edit.receiver; cancel('receiver')"
                  ><i class="fas fa-times"></i> Cancel</a
                >
              </div>
            </div>
            <table mdbTable bordered="true" class="claim-details" small="true">
              <tr>
                <th>Entity Identifier Code</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.identifierCode
                      : ''
                  }}</span>
                </td>
                <th>
                  Receiver Identifier/ Identification Code
                  <span *ngIf="edit.receiver"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.receiver">{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.identificationCode
                      : ''
                  }}</span>
                  <span *ngIf="edit.receiver"
                    ><input
                      type="text"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="claimDetail.transaction.receiver.identificationCode"
                      minlength="2"
                      maxlength="15"
                      [ngClass]="{
                        'is-invalid': claimDetail.transaction.receiver.identificationCode.length < 2
                      }"
                      (ngModelChange)="receiverChange()"
                      name="receiveridentificationCode" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Entity Type Qualifier</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.entityTypeQualifier
                      : ''
                  }}</span>
                </td>
                <th>Contact Code</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.contactCode
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  Organization/ Business Name
                  <span *ngIf="edit.receiver"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.receiver">{{
                    claimDetail.transaction.receiver
                      ? (claimDetail.transaction.receiver.businessName | uppercase)
                      : ''
                  }}</span>
                  <span *ngIf="edit.receiver">
                    <input
                      type="text"
                      mdbInput
                      [(ngModel)]="claimDetail.transaction.receiver.businessName"
                      class="form-control"
                      maxlength="60"
                      [ngClass]="{'is-invalid': !claimDetail.transaction.receiver.businessName}"
                      (ngModelChange)="receiverChange()"
                      name="receiverbusinessName" />
                  </span>
                </td>
                <th>Contact Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? (claimDetail.transaction.receiver.contactName | uppercase)
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>First Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? (claimDetail.transaction.receiver.firstName | uppercase)
                      : ''
                  }}</span>
                </td>

                <th>Contact Number Qualifier1</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.contactNumberQualifier1
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Middle Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? (claimDetail.transaction.receiver.middleName | uppercase)
                      : ''
                  }}</span>
                </td>

                <th>Contact Number 1</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.contactNumber1
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? (claimDetail.transaction.receiver.lastName | uppercase)
                      : ''
                  }}</span>
                </td>

                <th>Contact Number Qualifier 2</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.contactNumberQualifier2
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Prefix</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver ? claimDetail.transaction.receiver.prefix : ''
                  }}</span>
                </td>

                <th>Contact Number 2</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.contactNumber2
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Suffix</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver ? claimDetail.transaction.receiver.suffix : ''
                  }}</span>
                </td>

                <th>Contact Number Qualifier 3</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.contactNumberQualifier3
                      : ''
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Identification Code Qualifier</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.identificationCodeQualifier
                      : ''
                  }}</span>
                </td>

                <th>Contact Number 3</th>
                <td>
                  <span>{{
                    claimDetail.transaction.receiver
                      ? claimDetail.transaction.receiver.contactNumber3
                      : ''
                  }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mdb-tab>
      <!--Panel 3-->
      <mdb-tab heading="Billing Provider">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <h6>Basic Information</h6>
              <div
                *ngIf="
                  claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                    claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                  ) &&
                  claimDetail.type.toLowerCase() === 'outgoing' &&
                  utilityAccess.searchAccess('OCLM', 'isEditable', false)
                ">
                <a
                  *ngIf="!edit.billingProvider_basic_info"
                  (click)="edit.billingProvider_basic_info = !edit.billingProvider_basic_info"
                  ><i class="fas fa-pencil-alt"></i> Edit</a
                >
                <a
                  *ngIf="edit.billingProvider_basic_info"
                  (click)="update('billingProvider_basic_info')"
                  class="mr-4"
                  [class.disabled-link] = "disableBillingProviderUpdate"
                  ><i class="fas fa-save"></i> Save</a
                >
                <a
                  *ngIf="edit.billingProvider_basic_info"
                  (click)="
                    edit.billingProvider_basic_info = !edit.billingProvider_basic_info;
                    cancel('billingProvider_basic_info')
                  "
                  ><i class="fas fa-times"></i> Cancel</a
                >
              </div>
            </div>
            <table mdbTable bordered="true" class="claim-details" small="true">
              <tr>
                <th>Entity Identifier Code</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider.basicInfo.identifierCode
                  }}</span>
                </td>
                <th>Reference Identification Qualifier</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider.referenceIdentificationQualifier
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Entity Type Qualifier</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider.basicInfo.entityTypeQualifier
                  }}</span>
                </td>
                <th>
                  Billing Provider Tax Identification Number
                  <span *ngIf="edit.billingProvider_basic_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.taxIdentificationNumber
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      numbersOnly
                      [(ngModel)]="claimDetail.transaction.billingProvider.taxIdentificationNumber"
                      maxlength="9"
                      [ngClass]="{
                        'is-invalid':
                          !claimDetail.transaction.billingProvider.taxIdentificationNumber
                      }"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProvidertaxIdentificationNumber" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  Organization/ Business Name
                  <span *ngIf="edit.billingProvider_basic_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.basicInfo.businessName | uppercase
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="claimDetail.transaction.billingProvider.basicInfo.businessName"
                      maxlength="60"
                      [ngClass]="{
                        'is-invalid':
                          !claimDetail.transaction.billingProvider.basicInfo.businessName
                      }"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProviderbusinessName" />
                  </span>
                </td>
                <th>Special Information Provider Code</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider.specialityInformationProviderCode
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>First Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider.basicInfo.firstName | uppercase
                  }}</span>
                </td>
                <th>Special Information Reference Identification Qualifier</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider
                      .specialityInformationReferenceIdentificationQualifier
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Middle Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider.basicInfo.middleName | uppercase
                  }}</span>
                </td>
                <th>Special Information Provider Taxonomy Code</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider
                      .specialityInformationProviderTaxonomyCode
                  }}</span>
                </td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider.basicInfo.lastName | uppercase
                  }}</span>
                </td>
                <th>Contact Code</th>
                <td>
                  <span>{{ claimDetail.transaction.billingProvider.contactCode }}</span>
                </td>
              </tr>
              <tr>
                <th>Prefix</th>
                <td>
                  <span>{{ claimDetail.transaction.billingProvider.basicInfo.prefix }}</span>
                </td>
                <th>Contact Name</th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.contactName | uppercase
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      [(ngModel)]="claimDetail.transaction.billingProvider.contactName"
                      class="form-control"
                      maxlength="60"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProvidercontactName" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Suffix</th>
                <td>
                  <span>{{ claimDetail.transaction.billingProvider.basicInfo.suffix }}</span>
                </td>
                <th>Contact Number Qualifier 1</th>
                <td>
                  <span>{{ claimDetail.transaction.billingProvider.contactNumberQualifier1 }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  Address Line 1
                  <span *ngIf="edit.billingProvider_basic_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.basicInfo.addressLine1 | uppercase
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="55"
                      [(ngModel)]="claimDetail.transaction.billingProvider.basicInfo.addressLine1"
                      [ngClass]="{
                        'is-invalid':
                          !claimDetail.transaction.billingProvider.basicInfo.addressLine1
                      }"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProvideraddressLine1" />
                  </span>
                </td>
                <th>Contact Number 1</th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.contactNumber1
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="claimDetail.transaction.billingProvider.contactNumber1"
                      maxlength="255"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProvidercontactNumber1" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Address Line 2</th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.basicInfo.addressLine2 | uppercase
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="55"
                      [(ngModel)]="claimDetail.transaction.billingProvider.basicInfo.addressLine2"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProvideraddressLine2" />
                  </span>
                </td>
                <th>Contact Number Qualifier 2</th>
                <td>
                  <span>{{ claimDetail.transaction.billingProvider.contactNumberQualifier2 }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  City
                  <span *ngIf="edit.billingProvider_basic_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.basicInfo.city | uppercase
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="claimDetail.transaction.billingProvider.basicInfo.city"
                      maxlength="30"
                      [ngClass]="{
                        'is-invalid':
                          claimDetail.transaction.billingProvider.basicInfo.city.length < 2
                      }"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProvidercity" />
                  </span>
                </td>
                <th>Contact Number 2</th>
                <td>
                  <span>{{ claimDetail.transaction.billingProvider.contactNumber2 }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  State
                  <span *ngIf="edit.billingProvider_basic_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.basicInfo.state
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="claimDetail.transaction.billingProvider.basicInfo.state"
                      [ngClass]="{
                        'is-invalid': !claimDetail.transaction.billingProvider.basicInfo.state
                      }"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProviderstate" />
                  </span>
                </td>
                <th>Contact Number Qualifier 3</th>
                <td>
                  <span>{{ claimDetail.transaction.billingProvider.contactNumberQualifier3 }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  Zip Code
                  <span *ngIf="edit.billingProvider_basic_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.basicInfo.zipCode
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      numbersOnly
                      [(ngModel)]="claimDetail.transaction.billingProvider.basicInfo.zipCode"
                      maxlength="9"
                      [ngClass]="{
                        'is-invalid': !claimDetail.transaction.billingProvider.basicInfo.zipCode
                      }"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProviderzipCode" />
                  </span>
                </td>
                <th>Contact Number 3</th>
                <td>
                  <span>{{ claimDetail.transaction.billingProvider.contactNumber3 }}</span>
                </td>
              </tr>
              <tr>
                <th>Identification Code Qualifier</th>
                <td>
                  <span>{{
                    claimDetail.transaction.billingProvider.basicInfo.identificationCodeQualifier
                  }}</span>
                </td>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>
                  Billing Provider Identifier/ Identification Code
                  <span *ngIf="edit.billingProvider_basic_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.billingProvider_basic_info">{{
                    claimDetail.transaction.billingProvider.basicInfo.identificationCode
                  }}</span>
                  <span *ngIf="edit.billingProvider_basic_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      numbersOnly
                      [(ngModel)]="
                      claimDetail.transaction.billingProvider.basicInfo.identificationCode
                    "
                      maxlength="10"
                      [ngClass]="{
                        'is-invalid':
                          !claimDetail.transaction.billingProvider.basicInfo.identificationCode
                      }"
                      (ngModelChange)="billingProviderChange()"
                      name="billingProvideridentificationCode" />
                  </span>
                </td>
                <th></th>
                <td></td>
              </tr>
            </table>
            <div>
              <h6>Pay To Provider Information</h6>
            </div>
            <table mdbTable bordered="true" class="claim-details" small="true">
              <tr>
                <th>Entity Identifier Code</th>
                <td>
                  <span
                    >{{
                      claimDetail.transaction.payToProvider
                        ? claimDetail.transaction.payToProvider.identifierCode
                        : ''
                    }}
                  </span>
                </td>
                <th>City</th>
                <td>
                  <span
                    >{{
                      claimDetail.transaction.payToProvider
                        ? (claimDetail.transaction.payToProvider.city | uppercase)
                        : ''
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Entity Type Qualifier</th>
                <td>
                  <span
                    >{{
                      claimDetail.transaction.payToProvider
                        ? claimDetail.transaction.payToProvider.entityTypeQualifier
                        : ''
                    }}
                  </span>
                </td>
                <th>State</th>
                <td>
                  <span
                    >{{
                      claimDetail.transaction.payToProvider
                        ? claimDetail.transaction.payToProvider.state
                        : ''
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Address Line 1</th>
                <td>
                  <span
                    >{{
                      claimDetail.transaction.payToProvider
                        ? (claimDetail.transaction.payToProvider.addressLine1 | uppercase)
                        : ''
                    }}
                  </span>
                </td>
                <th>Zip Code</th>
                <td>
                  <span
                    >{{
                      claimDetail.transaction.payToProvider
                        ? claimDetail.transaction.payToProvider.zipCode
                        : ''
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Address Line 2</th>
                <td>
                  <span
                    >{{
                      claimDetail.transaction.payToProvider
                        ? (claimDetail.transaction.payToProvider.addressLine2 | uppercase)
                        : ''
                    }}
                  </span>
                </td>
                <th></th>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </mdb-tab>
      <!--Panel 4-->
      <mdb-tab heading="Subscriber">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <h6>Subscriber Information</h6>
              <div
                *ngIf="
                  claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                    claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                  ) &&
                  utilityAccess.searchAccess(
                    name === 'Outgoing Claims' ? 'OCLM' : 'CLM',
                    'isEditable',
                    false
                  )
                ">
                <a
                  *ngIf="!edit.subscriber_info"
                  (click)="edit.subscriber_info = !edit.subscriber_info"
                  ><i class="fas fa-pencil-alt"></i> Edit</a
                >
                <a *ngIf="edit.subscriber_info" (click)="update('subscriber_info')" class="mr-4" [class.disabled-link] = "disableSubscriberPatientUpdate"
                  ><i class="fas fa-save"></i> Save</a
                >
                <a
                  *ngIf="edit.subscriber_info"
                  (click)="edit.subscriber_info = !edit.subscriber_info; cancel('subscriber_info')"
                  ><i class="fas fa-times"></i> Cancel</a
                >
              </div>
            </div>
            <table mdbTable bordered="true" class="claim-details" small="true">
              <tr>
                <th>Payor Responsibility Sequence Number Code</th>
                <td>
                  <span>{{ claimDetail.patient.payerResponsibilitySequenceNumberCode }}</span>
                </td>

                <th>Identification Code Qualifier</th>
                <td>
                  <span>{{ claimDetail.patient.subscriberIdentificationCodeQualifier }}</span>
                </td>
              </tr>
              <tr>
                <th>Individual Relationship Code</th>
                <td>
                  <span>{{ claimDetail.patient.relationshipCode }}</span>
                </td>
                <th>
                  Subscriber Identifier/ Identification Code<span *ngIf="edit.subscriber_info">
                    *</span
                  >
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_info">{{
                    claimDetail.patient.subscriberIdentificationCode
                  }}</span>
                  <span *ngIf="edit.subscriber_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [ngClass]="{'is-invalid': !claimDetail.patient.subscriberIdentificationCode}"
                      [(ngModel)]="claimDetail.patient.subscriberIdentificationCode"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberIdentificationCode" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Subscriber Group or Policy Number</th>
                <td>
                  <span *ngIf="!edit.subscriber_info">{{
                    claimDetail.patient.insuredPolicyNumber
                  }}</span>
                  <span *ngIf="edit.subscriber_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="claimDetail.patient.insuredPolicyNumber"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientInsuredPolicyNumber" />
                  </span>
                </td>

                <th>
                  Address Line 1<span
                    *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    *</span
                  >
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_info && claimDetail.patient.relationshipCode">{{
                    claimDetail.patient.subscriberAddressLine1
                  }}</span>
                  <span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="55"
                      [ngClass]="{'is-invalid': !claimDetail.patient.subscriberAddressLine1}"
                      [(ngModel)]="claimDetail.patient.subscriberAddressLine1"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberAddressLine1" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Insured Group Name</th>
                <td>
                  <span>{{ claimDetail.patient.insuredGroupName }}</span>
                </td>

                <th>Address Line 2</th>
                <td>
                  <span *ngIf="!edit.subscriber_info && claimDetail.patient.relationshipCode">{{
                    claimDetail.patient.subscriberAddressLine2
                  }}</span>
                  <span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="55"
                      [(ngModel)]="claimDetail.patient.subscriberAddressLine2"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberAddressLine2" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Insurance Type Code</th>
                <td>
                  <span>{{ claimDetail.patient.insuredTypeCode }}</span>
                </td>

                <th>
                  City<span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    *</span
                  >
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_info && claimDetail.patient.relationshipCode">{{
                    claimDetail.patient.subscriberCity
                  }}</span>
                  <span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="30"
                      [ngClass]="{'is-invalid': claimDetail.patient.subscriberCity.length < 2}"
                      [(ngModel)]="claimDetail.patient.subscriberCity"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberCity" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Claim Filing Indicator Code</th>
                <td>
                  <span>{{ claimDetail.patient.claimFillingIndicatorCode }}</span>
                </td>

                <th>
                  State<span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    *</span
                  >
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_info && claimDetail.patient.relationshipCode">{{
                    claimDetail.patient.subscriberState
                  }}</span>
                  <span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [ngClass]="{'is-invalid': !claimDetail.patient.subscriberState}"
                      [(ngModel)]="claimDetail.patient.subscriberState"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberState" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Entity Identifier Code</th>
                <td>
                  <span>{{ claimDetail.patient.subscriberIdentifierCode }}</span>
                </td>

                <th>
                  Zip Code<span
                    *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    *</span
                  >
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_info && claimDetail.patient.relationshipCode">{{
                    claimDetail.patient.subscriberZip
                  }}</span>
                  <span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      numbersOnly
                      maxlength="9"
                      [ngClass]="{'is-invalid': !claimDetail.patient.subscriberZip}"
                      [(ngModel)]="claimDetail.patient.subscriberZip"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberZip" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Entity Type Qualifier</th>
                <td>
                  <span>{{ claimDetail.patient.subscriberEntityTypeQualifier }}</span>
                </td>

                <th>DOB Date Time Period Format Qualifier</th>
                <td>
                  <span>{{ claimDetail.patient.subscriberDobDateTimeFormatQualifier }}</span>
                </td>
              </tr>
              <tr>
                <th>First Name<span *ngIf="edit.subscriber_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_info">{{
                    claimDetail.patient.subscriberFirstName
                  }}</span>
                  <span *ngIf="edit.subscriber_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="35"
                      [ngClass]="{'is-invalid': !claimDetail.patient.subscriberFirstName}"
                      [(ngModel)]="claimDetail.patient.subscriberFirstName"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberFirstName" />
                  </span>
                </td>

                <th>
                  DOB<span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    *</span
                  >
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_info && claimDetail.patient.relationshipCode"
                    >{{
                      claimDetail.patient.subscriberDob
                        ? (claimDetail.patient.subscriberDob | date : 'MM/dd/yyyy')
                        : ''
                    }}
                  </span>
                  <span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      placeholder="MM/DD/YYYY"
                      [textMask]="{mask: dateMask}"
                      [ngClass]="{'is-invalid': !claimDetail.patient.subscriberDob}"
                      [(ngModel)]="claimDetail.patient.subscriberDob"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberDob" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Middle Name</th>
                <td>
                  <span *ngIf="!edit.subscriber_info">{{
                    claimDetail.patient.subscriberMiddleName
                  }}</span>
                  <span *ngIf="edit.subscriber_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="25"
                      [(ngModel)]="claimDetail.patient.subscriberMiddleName"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberMiddleName" />
                  </span>
                </td>

                <th>
                  Gender<span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    *</span
                  >
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_info && claimDetail.patient.relationshipCode">{{
                    claimDetail.patient.subscriberGender
                  }}</span>
                  <span *ngIf="edit.subscriber_info && claimDetail.patient.relationshipCode">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [ngClass]="{'is-invalid': !claimDetail.patient.subscriberGender}"
                      [(ngModel)]="claimDetail.patient.subscriberGender"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberGender" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  Last Name
                  <span *ngIf="edit.subscriber_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_info">{{
                    claimDetail.patient.subscriberLastName
                  }}</span>
                  <span *ngIf="edit.subscriber_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="60"
                      [ngClass]="{'is-invalid': !claimDetail.patient.subscriberLastName}"
                      [(ngModel)]="claimDetail.patient.subscriberLastName"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientsubscriberLastName" />
                  </span>
                </td>

                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>Prefix</th>
                <td>
                  <span>{{ claimDetail.patient.subscriberPrefix }}</span>
                </td>

                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>Suffix</th>
                <td>
                  <span>{{ claimDetail.patient.subscriberSuffix }}</span>
                </td>
                <th></th>
                <td></td>
              </tr>
            </table>

            <div class="d-flex justify-content-between">
              <h6>Patient Information</h6>
              <div
                *ngIf="
                  claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                    claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                  ) &&
                  !claimDetail.patient.relationshipCode &&
                  utilityAccess.searchAccess(
                    name === 'Outgoing Claims' ? 'OCLM' : 'CLM',
                    'isEditable',
                    false
                  )
                ">
                <a
                  *ngIf="!edit.subscriber_patient_info"
                  (click)="edit.subscriber_patient_info = !edit.subscriber_patient_info"
                  ><i class="fas fa-pencil-alt"></i> Edit</a
                >
                <a
                  *ngIf="edit.subscriber_patient_info"
                  (click)="update('subscriber_patient_info')"
                  class="mr-4"
                  [class.disabled-link] = "disableSubscriberPatientUpdate"
                  ><i class="fas fa-save"></i> Save</a
                >
                <a
                  *ngIf="edit.subscriber_patient_info"
                  (click)="
                    edit.subscriber_patient_info = !edit.subscriber_patient_info;
                    cancel('subscriber_patient_info')
                  "
                  ><i class="fas fa-times"></i> Cancel</a
                >
              </div>
            </div>
            <table mdbTable bordered="true" class="claim-details" small="true">
              <tr>
                <th>
                  Individual Relationship Code<span *ngIf="edit.subscriber_patient_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientIndividualRelationshipCode
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': !claimDetail.patient.patientIndividualRelationshipCode
                      }"
                      [(ngModel)]="claimDetail.patient.patientIndividualRelationshipCode"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientIndividualRelationshipCode" />
                  </span>
                </td>

                <th>Identification Code Qualifier</th>
                <td>
                  <span>{{ claimDetail.patient.patientIdentificationCodeQualifier }}</span>
                </td>
              </tr>
              <tr>
                <th>Patient Death Date Time Period Format Qualifier</th>
                <td>
                  <span>{{ claimDetail.patient.patientDeathDateTimeFormatQualifier }}</span>
                </td>

                <th>Patient Identifier/ Identification Code</th>
                <td>
                  <span>{{ claimDetail.patient.patientIdentificationCode }}</span>
                </td>
              </tr>
              <tr>
                <th>Patient Death Date</th>
                <td>
                  <span>{{ claimDetail.patient.patientDeathDate | date : 'MM/dd/yyyy' }}</span>
                </td>

                <th>Address Line 1<span *ngIf="edit.subscriber_patient_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientAddressLine1
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="55"
                      [ngClass]="{'is-invalid': !claimDetail.patient.patientAddressLine1}"
                      [(ngModel)]="claimDetail.patient.patientAddressLine1"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientAddressLine1" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Unit or Basis for Measurement Code</th>
                <td>
                  <span>{{ claimDetail.patient.unit }}</span>
                </td>

                <th>Address Line 2</th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientAddressLine2
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="55"
                      [(ngModel)]="claimDetail.patient.patientAddressLine2"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientAddressLine2" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Patient Weight</th>
                <td>
                  <span>{{ claimDetail.patient.weight }}</span>
                </td>

                <th>City<span *ngIf="edit.subscriber_patient_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientCity
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="30"
                      [ngClass]="{'is-invalid': claimDetail.patient.patientCity.length < 2}"
                      [(ngModel)]="claimDetail.patient.patientCity"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientCity" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Pregnancy Indicator</th>
                <td>
                  <span>{{ claimDetail.patient.pregnancyIndicator }}</span>
                </td>

                <th>State<span *ngIf="edit.subscriber_patient_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientState
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [ngClass]="{'is-invalid': !claimDetail.patient.patientState}"
                      [(ngModel)]="claimDetail.patient.patientState"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientState" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Entity Identifier Code</th>
                <td>
                  <span>{{ claimDetail.patient.patientIdentifierCode }}</span>
                </td>

                <th>Zip Code<span *ngIf="edit.subscriber_patient_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientZip
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      numbersOnly
                      maxlength="9"
                      [ngClass]="{'is-invalid': !claimDetail.patient.patientZip}"
                      [(ngModel)]="claimDetail.patient.patientZip"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientZip" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Entity Type Qualifier</th>
                <td>
                  <span>{{ claimDetail.patient.patientEntityTypeQualifier }}</span>
                </td>

                <th>DOB Date Time Period Format Qualifier</th>
                <td>
                  <span>{{ claimDetail.patient.patientDobDateTimeFormatQualifier }}</span>
                </td>
              </tr>
              <tr>
                <th>First Name<span *ngIf="edit.subscriber_patient_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientFirstName
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="35"
                      [ngClass]="{'is-invalid': !claimDetail.patient.patientFirstName}"
                      [(ngModel)]="claimDetail.patient.patientFirstName"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientFirstName" />
                  </span>
                </td>

                <th>DOB<span *ngIf="edit.subscriber_patient_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientDob
                      ? (claimDetail.patient.patientDob | date : 'MM/dd/yyyy')
                      : ''
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      placeholder="MM/DD/YYYY"
                      [textMask]="{mask: dateMask}"
                      [ngClass]="{'is-invalid': !claimDetail.patient.patientDob}"
                      [(ngModel)]="claimDetail.patient.patientDob"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientDob" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Middle Name</th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientMiddleName
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="25"
                      [(ngModel)]="claimDetail.patient.patientMiddleName"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientMiddleName" />
                  </span>
                </td>

                <th>Gender<span *ngIf="edit.subscriber_patient_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientGender
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [ngClass]="{'is-invalid': !claimDetail.patient.patientGender}"
                      [(ngModel)]="claimDetail.patient.patientGender"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientGender" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Last Name<span *ngIf="edit.subscriber_patient_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_patient_info">{{
                    claimDetail.patient.patientLastName
                  }}</span>
                  <span *ngIf="edit.subscriber_patient_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="60"
                      [ngClass]="{'is-invalid': !claimDetail.patient.patientLastName}"
                      [(ngModel)]="claimDetail.patient.patientLastName"
                      (ngModelChange) = subscriberPatientChange()
                      name="patientpatientLastName" />
                  </span>
                </td>

                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>Prefix</th>
                <td>
                  <span>{{ claimDetail.patient.patientPrefix }}</span>
                </td>

                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>Suffix</th>
                <td>
                  <span>{{ claimDetail.patient.patientSuffix }}</span>
                </td>

                <th></th>
                <td></td>
              </tr>
            </table>

            <div class="d-flex justify-content-between">
              <h6>Payor Information</h6>
              <div
                *ngIf="
                  claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                    claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                  ) &&
                  claimDetail.type.toLowerCase() === 'outgoing' &&
                  utilityAccess.searchAccess('OCLM', 'isEditable', false)
                ">
                <a
                  *ngIf="!edit.subscriber_payer_info"
                  (click)="edit.subscriber_payer_info = !edit.subscriber_payer_info"
                  ><i class="fas fa-pencil-alt"></i> Edit</a
                >
                <a
                  *ngIf="edit.subscriber_payer_info"
                  (click)="update('subscriber_payer_info')"
                  class="mr-4"
                  [class.disabled-link] = "disableSubscriberPayerUpdate"
                  ><i class="fas fa-save"></i> Save</a
                >
                <a
                  *ngIf="edit.subscriber_payer_info"
                  (click)="
                    edit.subscriber_payer_info = !edit.subscriber_payer_info;
                    cancel('subscriber_payer_info')
                  "
                  ><i class="fas fa-times"></i> Cancel</a
                >
              </div>
            </div>
            <table mdbTable bordered="true" class="claim-details" small="true">
              <tr>
                <th>Entity Identifier Code</th>
                <td>
                  <span>{{ claimDetail.patient.payer.identifierCode }}</span>
                </td>

                <th>Identification Code Qualifier</th>
                <td>
                  <span>{{ claimDetail.patient.payer.identificationCodeQualifier }}</span>
                </td>
              </tr>
              <tr>
                <th>Entity Type Qualifier</th>
                <td>
                  <span>{{ claimDetail.patient.payer.entityTypeQualifier }}</span>
                </td>

                <th>
                  Payor Identifier/ Identification Code<span *ngIf="edit.subscriber_payer_info">
                    *</span
                  >
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_payer_info">{{
                    claimDetail.patient.payer.identificationCode
                  }}</span>
                  <span *ngIf="edit.subscriber_payer_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="80"
                      [ngClass]="{
                        'is-invalid': claimDetail.patient.payer.identificationCode.length < 2
                      }"
                      [(ngModel)]="claimDetail.patient.payer.identificationCode"
                      (ngModelChange) = subscriberPayerChange()
                      name="payeridentificationCode" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  Organization/ Business Name<span *ngIf="edit.subscriber_payer_info"> *</span>
                </th>
                <td>
                  <span *ngIf="!edit.subscriber_payer_info">{{
                    claimDetail.patient.payer.businessName | uppercase
                  }}</span>
                  <span
                    *ngIf="
                      edit.subscriber_payer_info &&
                      claimDetail.patient.payer.entityTypeQualifier == '2'
                    ">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="60"
                      [ngClass]="{'is-invalid': !claimDetail.patient.payer.businessName}"
                      [(ngModel)]="claimDetail.patient.payer.businessName"
                      (ngModelChange) = subscriberPayerChange()
                      name="payerbusinessName" />
                  </span>
                </td>

                <th>Address Line 1<span *ngIf="edit.subscriber_payer_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_payer_info">{{
                    claimDetail.patient.payer.addressLine1 | uppercase
                  }}</span>
                  <span *ngIf="edit.subscriber_payer_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="55"
                      [ngClass]="{'is-invalid': !claimDetail.patient.payer.addressLine1}"
                      [(ngModel)]="claimDetail.patient.payer.addressLine1"
                      (ngModelChange) = subscriberPayerChange()
                      name="payeraddressLine1" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>First Name</th>
                <td>
                  <span>{{ claimDetail.patient.payer.firstName | uppercase }}</span>
                </td>

                <th>Address Line 2</th>
                <td>
                  <span *ngIf="!edit.subscriber_payer_info">{{
                    claimDetail.patient.payer.addressLine2 | uppercase
                  }}</span>
                  <span *ngIf="edit.subscriber_payer_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="55"
                      [(ngModel)]="claimDetail.patient.payer.addressLine2"
                      (ngModelChange) = subscriberPayerChange()
                      name="payeraddressLine2" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Middle Name</th>
                <td>
                  <span>{{ claimDetail.patient.payer.middleName | uppercase }}</span>
                </td>

                <th>City<span *ngIf="edit.subscriber_payer_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_payer_info">{{
                    claimDetail.patient.payer.city | uppercase
                  }}</span>
                  <span *ngIf="edit.subscriber_payer_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      maxlength="30"
                      [ngClass]="{'is-invalid': claimDetail.patient.payer.city.length < 2}"
                      [(ngModel)]="claimDetail.patient.payer.city"
                      (ngModelChange) = subscriberPayerChange()
                      name="payercity" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>
                  <span>{{ claimDetail.patient.payer.lastName | uppercase }}</span>
                </td>

                <th>State<span *ngIf="edit.subscriber_payer_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_payer_info">{{
                    claimDetail.patient.payer.state
                  }}</span>
                  <span *ngIf="edit.subscriber_payer_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      [ngClass]="{'is-invalid': !claimDetail.patient.payer.state}"
                      [(ngModel)]="claimDetail.patient.payer.state"
                      (ngModelChange) = subscriberPayerChange()
                      name="payerstate" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Prefix</th>
                <td>
                  <span>{{ claimDetail.patient.payer.prefix }}</span>
                </td>

                <th>Zip Code<span *ngIf="edit.subscriber_payer_info"> *</span></th>
                <td>
                  <span *ngIf="!edit.subscriber_payer_info">{{
                    claimDetail.patient.payer.zipCode
                  }}</span>
                  <span *ngIf="edit.subscriber_payer_info">
                    <input
                      type="text"
                      mdbInput
                      class="form-control"
                      numbersOnly
                      maxlength="9"
                      [ngClass]="{'is-invalid': !claimDetail.patient.payer.zipCode}"
                      [(ngModel)]="claimDetail.patient.payer.zipCode"
                      (ngModelChange) = subscriberPayerChange()
                      name="payerzipCode" />
                  </span>
                </td>
              </tr>
              <tr>
                <th>Suffix</th>
                <td>
                  <span>{{ claimDetail.patient.payer.suffix }}</span>
                </td>

                <th></th>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </mdb-tab>
      <!--Panel 5-->
      <mdb-tab heading="Claims">
        <div class="row">
          <div class="col-12">
            <mdb-accordion [multiple]="false" class="accordion-block">
              <mdb-accordion-item [collapsed]="false">
                <mdb-accordion-item-head (click)="onAccordionClick()">Basic Information</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div
                    class="d-flex justify-content-end"
                    *ngIf="
                      claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                        claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                      ) &&
                      claimDetail.type.toLowerCase() === 'outgoing' &&
                      utilityAccess.searchAccess('OCLM', 'isEditable', false)
                    ">
                    <a
                      *ngIf="!edit.claims_basic_info"
                      (click)="edit.claims_basic_info = !edit.claims_basic_info"
                      ><i class="fas fa-pencil-alt"></i> Edit</a
                    >
                    <a
                      *ngIf="edit.claims_basic_info"
                      (click)="update('claims_basic_info')"
                      class="mr-4"
                      [class.disabled-link] = "disableClaimUpdate"
                      ><i class="fas fa-save"></i> Save</a
                    >
                    <a
                      *ngIf="edit.claims_basic_info"
                      (click)="
                        edit.claims_basic_info = !edit.claims_basic_info;
                        cancel('claims_basic_info')
                      "
                      ><i class="fas fa-times"></i> Cancel</a
                    >
                  </div>
                  <table mdbTable bordered="true" class="claim-details" small="true">
                    <tr>
                      <th>Patient Account Number<span *ngIf="edit.claims_basic_info"> *</span></th>
                      <td>
                        <span *ngIf="!edit.claims_basic_info">{{
                          claimDetail.patientAccountNumber
                        }}</span>
                        <span *ngIf="edit.claims_basic_info">
                          <input
                            class="form-control"
                            mdbInput
                            [(ngModel)]="claimDetail.patientAccountNumber"
                            (ngModelChange)="claimChange('basic_info')"
                            [ngClass]="{'is-invalid': !claimDetail.patientAccountNumber}"
                            name="claimsPatientAccountNumber" />
                        </span>
                      </td>
                      <th>Patient Signature Source Code</th>
                      <td>
                        <span>{{ claimDetail.patientSignatureSourceCode }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Total Claim Charge Amount<span *ngIf="edit.claims_basic_info"> *</span>
                      </th>
                      <td>
                        <span *ngIf="!edit.claims_basic_info">{{
                          claimDetail.totalClaimChargeAmount | currency
                        }}</span>
                        <span *ngIf="edit.claims_basic_info">
                          <input
                            type="number"
                            mdbInput
                            class="form-control"
                            [(ngModel)]="claimDetail.totalClaimChargeAmount"
                            (ngModelChange)="claimChange('basic_info')"
                            [ngClass]="{'is-invalid': !claimDetail.totalClaimChargeAmount}"
                            name="claimstotalClaimChargeAmount" />
                        </span>
                      </td>

                      <th>Related Causes Code 1</th>
                      <td>
                        <span>{{ claimDetail.relatedCausedCode1 }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Facility Type Code<span *ngIf="edit.claims_basic_info"> *</span></th>
                      <td>
                        <span *ngIf="!edit.claims_basic_info">{{
                          claimDetail.facilityTypeCode
                        }}</span>
                        <span *ngIf="edit.claims_basic_info">
                          <input
                            type="text"
                            mdbInput
                            class="form-control"
                            [ngClass]="{'is-invalid': !claimDetail.facilityTypeCode}"
                            [(ngModel)]="claimDetail.facilityTypeCode"
                            (ngModelChange)="claimChange('basic_info')"
                            name="claimsfacilityTypeCode" />
                        </span>
                      </td>

                      <th>Related Causes Code 2</th>
                      <td>
                        <span>{{ claimDetail.relatedCausedCode2 }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Facility Code Qualifier</th>
                      <td>
                        <span>{{ claimDetail.facilityCodeQualifier }}</span>
                      </td>

                      <th>Related Causes Code 3</th>
                      <td>
                        <span>{{ claimDetail.relatedCausedCode3 }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Claim Frequency Code<span *ngIf="edit.claims_basic_info"> *</span></th>
                      <td>
                        <span>{{ claimDetail.frequencyCode }}</span>
                        
                      </td>

                      <th>Related Causes Code 4</th>
                      <td>
                        <span>{{ claimDetail.relatedCausedCode3 }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Provider or Supplier Signature Indicator</th>
                      <td>
                        <span>{{ claimDetail.providerSignatureIndicator }}</span>
                      </td>

                      <th>Country Code</th>
                      <td>
                        <span>{{ claimDetail.countryCode }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Medicare Assignment Code<span *ngIf="edit.claims_basic_info"> *</span>
                      </th>
                      <td>
                        <span *ngIf="!edit.claims_basic_info">{{
                          claimDetail.medicareAssignmentCode
                        }}</span>
                        <span *ngIf="edit.claims_basic_info">
                          <input
                            type="text"
                            mdbInput
                            class="form-control"
                            [ngClass]="{'is-invalid': !claimDetail.medicareAssignmentCode}"
                            [(ngModel)]="claimDetail.medicareAssignmentCode"
                            (ngModelChange)="claimChange('basic_info')"
                            name="claimsmedicareAssignmentCode" />
                        </span>
                      </td>

                      <th>Special Program Indicator</th>
                      <td>
                        <span>{{ claimDetail.specialProgramIndicator }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Benefits Assignment Certification Indicator<span
                          *ngIf="edit.claims_basic_info">
                          *</span
                        >
                      </th>
                      <td>
                        <span *ngIf="!edit.claims_basic_info">{{
                          claimDetail.benefitsAssignmentCertificationIndicator
                        }}</span>
                        <span *ngIf="edit.claims_basic_info">
                          <input
                            type="text"
                            mdbInput
                            class="form-control"
                            [ngClass]="{
                              'is-invalid': !claimDetail.benefitsAssignmentCertificationIndicator
                            }"
                            [(ngModel)]="claimDetail.benefitsAssignmentCertificationIndicator"
                            (ngModelChange)="claimChange('basic_info')"
                            name="claimsbenefitsAssignmentCertificationIndicator" />
                        </span>
                      </td>

                      <th>Delay Reason Code</th>
                      <td>
                        <span>{{ claimDetail.delayReasonCode }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Release of Information Code<span *ngIf="edit.claims_basic_info"> *</span>
                      </th>
                      <td>
                        <span *ngIf="!edit.claims_basic_info">{{
                          claimDetail.releaseInformationCode
                        }}</span>
                        <span *ngIf="edit.claims_basic_info">
                          <input
                            type="text"
                            mdbInput
                            class="form-control"
                            [ngClass]="{'is-invalid': !claimDetail.releaseInformationCode}"
                            [(ngModel)]="claimDetail.releaseInformationCode"
                            (ngModelChange)="claimChange('basic_info')"
                            name="claims" />
                        </span>
                      </td>

                      <th></th>
                      <td></td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="onAccordionClick()">Date Information</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div
                    class="d-flex justify-content-end"
                    *ngIf="
                      claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                        claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                      ) &&
                      claimDetail.type.toLowerCase() === 'outgoing' &&
                      utilityAccess.searchAccess('OCLM', 'isEditable', false)
                    ">
                    <a
                      *ngIf="!edit.claims_date_info"
                      (click)="edit.claims_date_info = !edit.claims_date_info"
                      ><i class="fas fa-pencil-alt"></i> Edit</a
                    >
                    <a
                      *ngIf="edit.claims_date_info"
                      (click)="update('claims_date_info')"
                      class="mr-4"
                      [class.disabled-link] = "disableClaimUpdate"
                      ><i class="fas fa-save"></i> Save</a
                    >
                    <a
                      *ngIf="edit.claims_date_info"
                      (click)="
                        edit.claims_date_info = !edit.claims_date_info; cancel('claims_date_info')
                      "
                      ><i class="fas fa-times"></i> Cancel</a
                    >
                  </div>
                  <table mdbTable bordered="true" class="claim-details" small="true">
                    <tr>
                      <th>Accident Date Time Qualifier</th>
                      <td>
                        <span>{{ claimDetail.accidentDateTimeQualifier }}</span>
                      </td>

                      <th>Admission Date</th>
                      <td>
                        <span>{{
                          claimDetail.admissionDate | date : 'MM/dd/yyyy h:mm a' : 'UTC'
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Accident Date Time Format Qualifier</th>
                      <td>
                        <span>{{ claimDetail.accidentDateTimeFormatQualifier }}</span>
                      </td>

                      <th>Discharge Date Time Qualifier</th>
                      <td>
                        <span>{{ claimDetail.dischargeDateTimeQualifier }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Accident Date</th>
                      <td>
                        <span>{{ claimDetail.accidentDate | date : 'MM/dd/yyyy' }}</span>
                      </td>

                      <th>Discharge Date Time Format Qualifier</th>
                      <td>
                        <span>{{ claimDetail.dischargeDateTimeFormatQualifier }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Mentrual Period Date Time Qualifier</th>
                      <td>
                        <span>{{ claimDetail.lastMenstrualPeriodDateTimeQualifier }}</span>
                      </td>

                      <th>Discharge Date</th>
                      <td>
                        <span>{{ claimDetail.dischargeDate }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Mentrual Period Date Time Format Qualifier</th>
                      <td>
                        <span>{{ claimDetail.lastMenstrualPeriodDateTimeFormatQualifier }}</span>
                      </td>

                      <th>Statement Date Time Qualifier</th>
                      <td>
                        <span>{{ claimDetail.statementDateTimeQualifier }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Mentrual Period Date</th>
                      <td>
                        <span>{{ claimDetail.lastMenstrualPeriodDate | date : 'MM/dd/yyyy' }}</span>
                      </td>

                      <th>Statement Date Time Format Qualifier</th>
                      <td>
                        <span>{{ claimDetail.statementDateTimeFormatQualifier }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Admission Date Time Qualifier</th>
                      <td>
                        <span>{{ claimDetail.admissionDateTimeQualifier }}</span>
                      </td>

                      <th>Statement Date<span *ngIf="edit.claims_date_info"> *</span></th>
                      <td>
                        <span *ngIf="!edit.claims_date_info">{{ claimDetail.statementDate }}</span>
                        <span *ngIf="edit.claims_date_info">
                          <input
                            class="form-control"
                            mdbInput
                            maxlength="17"
                            placeholder="eg: 20200121-20200521"
                            [ngClass]="{'is-invalid': claimDetail.statementDate.length != 17}"
                            [(ngModel)]="claimDetail.statementDate"
                            (ngModelChange)="claimChange('date_info')"
                            name="claimsstatementDate" />
                        </span>
                        <!-- <span *ngIf="edit.claims_date_info">
                                                    <input class="form-control" mdbInput
                                                        [textMask]="{mask: dateRangeMask}"
                                                        placeholder="eg: YYYY/MM/DD - YYYY/MM/DD"
                                                        [ngClass]="{'is-invalid':claimDetail.statementDate.length != 17}"
                                                        [(ngModel)]="claimDetail.statementDate"
                                                        name="claimsstatementDate" />
                                                </span> -->
                      </td>
                    </tr>
                    <tr>
                      <th>Admission Date Time Format Qualifier</th>
                      <td>
                        <span>{{ claimDetail.admissionDateTimeFormatQualifier }}</span>
                      </td>

                      <th></th>
                      <td></td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="onAccordionClick()">Other Information</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <table mdbTable bordered="true" class="claim-details" small="true">
                    <tr>
                      <th>Supplemental Information Report Type Code</th>
                      <td>
                        <span>{{ claimDetail.supplementalInformationReportTypeCode }}</span>
                      </td>

                      <th>Contract Information Discount Percentage</th>
                      <td>
                        <span>{{ claimDetail.contractInformationDiscountPercentage }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Supplemental Information Transmission Code</th>
                      <td>
                        <span>{{ claimDetail.supplementalInformationTransmissionCode }}</span>
                      </td>

                      <th>Contract Information Discount Version Identifier</th>
                      <td>
                        <span>{{ claimDetail.contractInformationDiscountVersionIdentifier }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Supplemental Information Identification Code Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.supplementalInformationIdentificationCodeQualifier
                        }}</span>
                      </td>

                      <th>Claim Note Reference Code</th>
                      <td>
                        <span>{{ claimDetail.claimNoteReferenceCode }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Supplemental Information Control Number</th>
                      <td>
                        <span>{{ claimDetail.supplementalInformationControlNumber }}</span>
                      </td>

                      <th>Claim Note text</th>
                      <td>
                        <span>{{ claimDetail.claimNoteText }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Contract Information Type Code</th>
                      <td>
                        <span>{{ claimDetail.contractInformationTypeCode }}</span>
                      </td>

                      <th>Institutional Claim Code Admission Type Code</th>
                      <td>
                        <span>{{ claimDetail.institutionalClaimCodeAdmissionTypeCode }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Contract Information Amount</th>
                      <td>
                        <span>{{ claimDetail.contractInformationAmount | currency }}</span>
                      </td>

                      <th>Institutional Claim Code Admission Source Code</th>
                      <td>
                        <span>{{ claimDetail.institutionalClaimCodeAdmissionSourceCode }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Contract Information Percentage</th>
                      <td>
                        <span>{{ claimDetail.contractInformationPercentage }}</span>
                      </td>

                      <th>Institutional Claim Code Patient Status Code</th>
                      <td>
                        <span>{{ claimDetail.institutionalClaimCodePatientStatusCode }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Contract Information Code</th>
                      <td>
                        <span>{{ claimDetail.contractInformationCode }}</span>
                      </td>

                      <th></th>
                      <td></td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="onAccordionClick()">Reference Information</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div
                    class="d-flex justify-content-end"
                    *ngIf="
                      claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                        claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                      ) &&
                      claimDetail.type.toLowerCase() === 'outgoing' &&
                      utilityAccess.searchAccess('OCLM', 'isEditable', false)
                    ">
                    <a
                      *ngIf="!edit.claimsReferenceInfo"
                      (click)="edit.claimsReferenceInfo = !edit.claimsReferenceInfo"
                      ><i class="fas fa-pencil-alt"></i> Edit</a
                    >
                    <a
                      *ngIf="edit.claimsReferenceInfo"
                      (click)="update('claimsReferenceInfo')"
                      class="mr-4"
                      [class.disabled-link] = "disableClaimUpdate"
                      ><i class="fas fa-save"></i> Save</a
                    >
                    <a
                      *ngIf="edit.claimsReferenceInfo"
                      (click)="
                        edit.claimsReferenceInfo = !edit.claimsReferenceInfo;
                        cancel('claimsReferenceInfo')
                      "
                      ><i class="fas fa-times"></i> Cancel</a
                    >
                  </div>
                  <table mdbTable bordered="true" class="claim-details" small="true">
                    <tr>
                      <th>Referrel Number Reference Identification Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.referralNumberReferenceIdentificationQualifier
                        }}</span>
                      </td>

                      <th>Claim Identifier Reference Identification Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.claimIdentifierReferenceIdentificationQualifier
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Referrel Number</th>
                      <td>
                        <span>{{ claimDetail.referralNumber }}</span>
                      </td>

                      <th>Claim Identifier Clearinghouse Trace Number</th>
                      <td>
                        <span>{{ claimDetail.claimIdentifierClearinghouseTraceNumber }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Prior Authorization Reference Identification Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.priorAuthorizationReferenceIdentificationQualifier
                        }}</span>
                      </td>

                      <th>Claim Identifier Description</th>
                      <td>
                        <span>{{ claimDetail.claimIdentifierDescription }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Prior Authorization Number</th>
                      <td>
                        <span>{{ claimDetail.priorAuthorizationNumber }}</span>
                      </td>

                      <th>Claim Identifier Reference Identifier</th>
                      <td>
                        <span>{{ claimDetail.claimIdentifierReferenceIdentifier }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>CLIA reference Identification Qualifier</th>
                      <td>
                        <span>{{ claimDetail.cliaReferenceIdentificationQualifier }}</span>
                      </td>

                      <th>Medical Record Number Reference Identification Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.medicalRecordNumberReferenceIdentificationQualifier
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>CLIA Number</th>
                      <td>
                        <span>{{ claimDetail.cliaNumber }}</span>
                      </td>

                      <th>Medical Record Number</th>
                      <td>
                        <span>{{ claimDetail.medicalRecordNumber }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>CLIA Description</th>
                      <td>
                        <span>{{ claimDetail.cliaDescription }}</span>
                      </td>

                      <th>Original Reference Number Reference Identification Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.originalReferenceNumberReferenceIdentificationQualifier
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>CLIA reference Identifier</th>
                      <td>
                        <span>{{ claimDetail.cliaReferenceIdentifier }}</span>
                      </td>

                      <th>Original Reference Number</th>
                      <td>
                        <span *ngIf="!edit.claimsReferenceInfo">{{
                          claimDetail.originalReferenceNumber
                        }}</span>
                        <span *ngIf="edit.claimsReferenceInfo">
                          <input
                            class="form-control"
                            mdbInput
                            [(ngModel)]="claimDetail.originalReferenceNumber"
                            [ngClass]="{
                              'is-invalid':
                                claimsReeditableStatuses.includes(
                                  this.claimDetail.claimXRefHolista.processingStatus
                                ) && !claimDetail.originalReferenceNumber
                            }"
                            (ngModelChange)="claimChange('reference_info')"
                            name="claimsOriginalReferenceNumber" />
                        </span>
                      </td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="onAccordionClick()">Referring Provider Information</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <table mdbTable bordered="true" class="claim-details" small="true">
                    <tr>
                      <th>Entity Identifier Code</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.identifierCode
                            : ''
                        }}</span>
                      </td>

                      <th>Identification Code Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.identificationCodeQualifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Entity Type Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.entityTypeQualifier
                            : ''
                        }}</span>
                      </td>

                      <th>Referring Provider Identifier/ Identification Code</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.identificationCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Organization/ Business Name</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.businessName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 1</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.addressLine1
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>First Name</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.firstName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 2</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.addressLine2
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Middle Name</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.middleName
                            : ''
                        }}</span>
                      </td>

                      <th>City</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider ? claimDetail.referringProvider.city : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Name</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.lastName
                            : ''
                        }}</span>
                      </td>

                      <th>State</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider ? claimDetail.referringProvider.state : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Prefix</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider ? claimDetail.referringProvider.prefix : ''
                        }}</span>
                      </td>

                      <th>Zip Code</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider ? claimDetail.referringProvider.zipCode : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Suffix</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider ? claimDetail.referringProvider.suffix : ''
                        }}</span>
                      </td>

                      <th>Secondary Information Identification Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.secondaryIdentificationQualifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td></td>

                      <th>Secondary Information Identifier</th>
                      <td>
                        <span>{{
                          claimDetail.referringProvider
                            ? claimDetail.referringProvider.secondaryIdentifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="onAccordionClick()">Rendering Provider Information</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <table mdbTable bordered="true" class="claim-details" small="true">
                    <tr>
                      <th>Entity Identifier Code</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.identifierCode
                            : ''
                        }}</span>
                      </td>

                      <th>Identification Code Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.identificationCodeQualifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Entity Type Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.entityTypeQualifier
                            : ''
                        }}</span>
                      </td>

                      <th>Rendering Provider Identifier/ Identification Code</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.identificationCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Organization/ Business Name</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.businessName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 1</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.addressLine1
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>First Name</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.firstName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 2</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.addressLine2
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Middle Name</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.middleName
                            : ''
                        }}</span>
                      </td>

                      <th>City</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider ? claimDetail.renderingProvider.city : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Name</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.lastName
                            : ''
                        }}</span>
                      </td>

                      <th>State</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider ? claimDetail.renderingProvider.state : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Prefix</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider ? claimDetail.renderingProvider.prefix : ''
                        }}</span>
                      </td>

                      <th>Zip Code</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider ? claimDetail.renderingProvider.zipCode : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Suffix</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider ? claimDetail.renderingProvider.suffix : ''
                        }}</span>
                      </td>

                      <th>Speciality Information Code</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.providerCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td></td>

                      <th>Speciality Information Reference Identification Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.specialityIdentificationQualifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td></td>

                      <th>Speciality Information Provider Taxonomy Code</th>
                      <td>
                        <span>{{
                          claimDetail.renderingProvider
                            ? claimDetail.renderingProvider.taxonomyCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="onAccordionClick()">Attending Physician Information</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <table mdbTable bordered="true" class="claim-details" small="true">
                    <tr>
                      <th>Entity Identifier Code</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.identifierCode
                            : ''
                        }}</span>
                      </td>

                      <th>Identification Code Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.identificationCodeQualifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Entity Type Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.entityTypeQualifier
                            : ''
                        }}</span>
                      </td>

                      <th>Rendering Provider Identifier/ Identification Code</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.identificationCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Organization/ Business Name</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.businessName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 1</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.addressLine1
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>First Name</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.firstName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 2</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.addressLine2
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Middle Name</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.middleName
                            : ''
                        }}</span>
                      </td>

                      <th>City</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician ? claimDetail.attendingPhysician.city : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Name</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.lastName
                            : ''
                        }}</span>
                      </td>

                      <th>State</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician ? claimDetail.attendingPhysician.state : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Prefix</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.prefix
                            : ''
                        }}</span>
                      </td>

                      <th>Zip Code</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.zipCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Suffix</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.suffix
                            : ''
                        }}</span>
                      </td>

                      <th>Speciality Information Provider Code</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.providerCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td></td>

                      <th>Speciality Information Reference Identification Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.specialityIdentificationQualifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td></td>

                      <th>Speciality Information Taxonomy Code</th>
                      <td>
                        <span>{{
                          claimDetail.attendingPhysician
                            ? claimDetail.attendingPhysician.taxonomyCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="onAccordionClick()">Operating Physician Information</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <table mdbTable bordered="true" class="claim-details" small="true">
                    <tr>
                      <th>Entity Identifier Code</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.identifierCode
                            : ''
                        }}</span>
                      </td>

                      <th>Identification Code Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.identificationCodeQualifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Entity Type Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.entityTypeQualifier
                            : ''
                        }}</span>
                      </td>

                      <th>Rendering Provider Identifier/ Identification Code</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.identificationCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Organization/ Business Name</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.businessName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 1</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.addressLine1
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>First Name</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.firstName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 2</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.addressLine2
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Middle Name</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.middleName
                            : ''
                        }}</span>
                      </td>

                      <th>City</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician ? claimDetail.operatingPhysician.city : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Name</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.lastName
                            : ''
                        }}</span>
                      </td>

                      <th>State</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician ? claimDetail.operatingPhysician.state : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Prefix</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.prefix
                            : ''
                        }}</span>
                      </td>

                      <th>Zip Code</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.zipCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Suffix</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.suffix
                            : ''
                        }}</span>
                      </td>

                      <th>Speciality Information Provider Code</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.providerCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td></td>

                      <th>Speciality Information Reference Identification Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.specialityIdentificationQualifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td></td>

                      <th>Speciality Information Taxonomy Code</th>
                      <td>
                        <span>{{
                          claimDetail.operatingPhysician
                            ? claimDetail.operatingPhysician.taxonomyCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="onAccordionClick()"
                  >Service Facility Location Information</mdb-accordion-item-head
                >
                <mdb-accordion-item-body>
                  <table mdbTable bordered="true" class="claim-details" small="true">
                    <tr>
                      <th>Entity Identifier Code</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.identifierCode
                            : ''
                        }}</span>
                      </td>

                      <th>Identification Code Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.identificationCodeQualifier
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Entity Type Qualifier</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.entityTypeQualifier
                            : ''
                        }}</span>
                      </td>

                      <th>ServiceFacility Location Identifier/ Identification Code</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.identificationCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Organization/ Business Name</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.businessName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 1</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.addressLine1
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>First Name</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.firstName
                            : ''
                        }}</span>
                      </td>

                      <th>Address Line 2</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.addressLine2
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Middle Name</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.middleName
                            : ''
                        }}</span>
                      </td>

                      <th>City</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.city
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Name</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.lastName
                            : ''
                        }}</span>
                      </td>

                      <th>State</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.state
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Prefix</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.prefix
                            : ''
                        }}</span>
                      </td>

                      <th>Zip Code</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.zipCode
                            : ''
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Suffix</th>
                      <td>
                        <span>{{
                          claimDetail.serviceFacilityLocation
                            ? claimDetail.serviceFacilityLocation.suffix
                            : ''
                        }}</span>
                      </td>

                      <th></th>
                      <td></td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="onAccordionClick()"
                  >Health Care Diagnosis Codes Information
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <table class="claim-details" mdbTable bordered="true" small="true">
                    <tr>
                      <th class="vertical-heading"></th>
                      <th>Qualifier</th>
                      <th>ICD-10CM</th>
                      <th>Format</th>
                      <th>Date</th>
                      <th>Monetary Amt.</th>
                      <th>Quantity</th>
                      <th>Version Identifier</th>
                      <th>Industry Code</th>
                      <th>Response Code</th>
                    </tr>
                    <ng-container *ngFor="let element of healthCareDiagnosis">
                      <tr *ngFor="let e of element.data; let i = index">
                        <th class="vertical-heading">
                          {{ i == 0 ? element.heading : '' }}<span *ngIf="e.edit"> *</span>
                        </th>
                        <td>{{ e.diagnosisQualifier }}</td>
                        <td>
                          <span class="d-flex justify-content-between">
                            <span *ngIf="!e.edit && e.diagnosisCode">{{ e.diagnosisCode }}</span>
                            <span style="width: 40%" *ngIf="e.edit">
                              <input
                                mdbInput
                                class="form-control"
                                [(ngModel)]="e.diagnosisCode"
                              />
                            </span>
                            <span>
                              <a
                                *ngIf="
                                  !e.edit &&
                                  e.diagnosisCode &&
                                  e.diagnosisType == 'principalDiagnosis' &&
                                  claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                                    claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                                  ) &&
                                  claimDetail.type.toLowerCase() === 'outgoing' &&
                                  utilityAccess.searchAccess('OCLM', 'isEditable', false)
                                "
                                (click)="e.edit = !e.edit"
                                ><i class="fas fa-pencil-alt"></i> edit
                              </a>
                              <a
                                *ngIf="shouldAddDiagnosis({healthDiagnosis: e, claimsEditableStatuses, claimDetail})"
                                (click)="e.edit = !e.edit"
                                ><i class="fas fa-pencil-alt"></i> Add
                              </a>
                              <a
                                *ngIf="e.edit"
                                (click)="update('claims_health_care', e)"
                                class="mr-2 mt-2 ml-2"
                                ><i class="fas fa-save"></i> save
                              </a>
                              <a
                                *ngIf="e.edit"
                                (click)="e.edit = !e.edit; cancel('claims_health_care')"
                                ><i class="fas fa-times mt-2"></i> cancel</a
                              >
                            </span>
                          </span>
                          <span style="font-weight: initial"
                            ><em>{{ e.icdCode?.shortDescription }}</em></span
                          >
                        </td>
                        <td>{{ e.diagnosisDateTimeFormatQualifier }}</td>
                        <td>
                          {{ e.diagnosisDate ? (e.diagnosisDate | date : 'MM/dd/yyyy') : '' }}
                        </td>
                        <td>{{ e.diagnosisMonetaryAmount | currency }}</td>
                        <td>{{ e.diagnosisQuantity }}</td>
                        <td>{{ e.diagnosisVersionIdentifier }}</td>
                        <td>{{ e.diagnosisIndustryCode }}</td>
                        <td>{{ e.diagnosisResponseCode }}</td>
                      </tr>
                    </ng-container>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </mdb-accordion>
          </div>
        </div>
      </mdb-tab>
      <!--Panel 6-->
      <mdb-tab heading="Service Line Information">
        <div class="row">
          <div class="col-12">
            <h6>Service Line Information</h6>
            <mdb-accordion [multiple]="false" class="accordion-block">
              <mdb-accordion-item *ngFor="let element of claimDetail.serviceLines; let i = index">
                <mdb-accordion-item-head [customClass]="'grey lighten-4'">
                  <h6>Basic Information</h6>
                  <div class="row no-gutters">
                    <div class="col-6">
                      <table class="table-xs" mdbTable small="true" borderless="true">
                        <tr>
                          <td width="360px"><span>Assigned Number</span></td>
                          <td>
                            <strong>{{ element.assignedNumber }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="360px"><span>Service ID Qualifier</span></td>
                          <td>
                            <strong>{{ element.serviceIdQualifier }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="360px"><span>Procedure Code</span></td>
                          <td>
                            <strong>{{ element.procedureCode }}</strong>
                            <br />
                            <em>{{ element.cptDescriptor?.shortDescription }}</em>
                          </td>
                        </tr>
                        <tr>
                          <td width="360px"><span>Line Item Charge Amount</span></td>
                          <td>
                            <strong>{{ element.lineItemChargeAmount | currency }}</strong>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-6">
                      <table class="table-xs" mdbTable small="true" borderless="true">
                        <tr>
                          <td><span>Revenue Code</span></td>
                          <td>
                            <strong>{{ element.institutionalServiceRevenueCode }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Service Date Time Qualifier</span></td>
                          <td>
                            <strong>{{ element.serviceDateTimeQualifer }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Service Date Time Format Qualifier</span></td>
                          <td>
                            <strong>{{ element.serviceDateTimeFormatQualifer }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Service Date</span></td>
                          <td>
                            <strong>{{ element.serviceDate }}</strong>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div
                    class="d-flex justify-content-end mt-2"
                    *ngIf="
                      claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
                        claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
                      ) &&
                      claimDetail.type.toLowerCase() === 'outgoing' &&
                      utilityAccess.searchAccess('OCLM', 'isEditable', false)
                    ">
                    <a
                      *ngIf="!element.service_line_info"
                      (click)="element.service_line_info = !element.service_line_info"
                      ><i class="fas fa-pencil-alt"></i> Edit</a
                    >
                    <a
                      *ngIf="element.service_line_info"
                      (click)="update('service_line_info', element.id)"
                      class="mr-4"
                      [class.disabled-link] = "disableServiceLineUpdate"
                      ><i class="fas fa-save"></i> Save</a
                    >
                    <a
                      *ngIf="element.service_line_info"
                      (click)="
                        element.service_line_info = !element.service_line_info;
                        cancel('service_line_info')
                      "
                      ><i class="fas fa-times"></i> Cancel</a
                    >
                  </div>
                  <table mdbTable class="claim-details">
                    <tr>
                      <td width="50%">
                        <strong>Professional Service</strong>
                        <table mdbTable bordered="true" small="true">
                          <tr>
                            <th>Service ID Qualifier</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P'
                                  ? element.serviceIdQualifier
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Code</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P'
                                  ? element.procedureCode
                                  : ''
                              }}
                              <br />
                              <em style="font-weight: initial">{{
                                claimDetail.transaction.ediType == '837P'
                                  ? element.cptDescriptor?.shortDescription
                                  : ''
                              }}</em>
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 1</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P' ? element.modifier1 : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 2</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P' ? element.modifier2 : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 3</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P' ? element.modifier3 : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 4</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P' ? element.modifier4 : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Description</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P' ? element.description : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Line Item Charge Amount</th>
                            <td>
                              {{
                                (claimDetail.transaction.ediType == '837P'
                                  ? element.lineItemChargeAmount
                                  : ''
                                ) | currency
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Measurement Code</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P'
                                  ? element.measurementCode
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Unit Count</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P'
                                  ? element.serviceUnitCount
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Place of Service Code</th>
                            <td>
                              {{ element.professionalServicePlaceOfServiceCode }}
                            </td>
                          </tr>
                          <tr>
                            <th>Diagnosis Code Pointer 1</th>
                            <td>
                              {{ element.professionalServiceDiagnosisiCodePointer1 }}
                            </td>
                          </tr>
                          <tr>
                            <th>Diagnosis Code Pointer 2</th>
                            <td>
                              {{ element.professionalServiceDiagnosisiCodePointer2 }}
                            </td>
                          </tr>
                          <tr>
                            <th>Diagnosis Code Pointer 3</th>
                            <td>
                              {{ element.professionalServiceDiagnosisiCodePointer3 }}
                            </td>
                          </tr>
                          <tr>
                            <th>Diagnosis Code Pointer 4</th>
                            <td>
                              {{ element.professionalServiceDiagnosisiCodePointer4 }}
                            </td>
                          </tr>
                          <tr>
                            <th>Emergency Indicator</th>
                            <td>
                              {{ element.professionalServiceEmergencyIndicator }}
                            </td>
                          </tr>
                          <tr>
                            <th>EPSDT Indicator</th>
                            <td>
                              {{ element.professionalServiceEpsdtIndicator }}
                            </td>
                          </tr>
                          <tr>
                            <th>Family Planning Indicator</th>
                            <td>
                              {{ element.professionalServiceFamilyPlanningIndicator }}
                            </td>
                          </tr>
                          <tr>
                            <th>Co Pay Staus Code</th>
                            <td>
                              {{ element.professionalServiceCopayStatusCode }}
                            </td>
                          </tr>
                          <tr>
                            <th>Level Of Care Code</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P'
                                  ? element.levelOfCareCode
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Date Time Qualifier</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P'
                                  ? element.serviceDateTimeQualifer
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Date Time Format Qualifier</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P'
                                  ? element.serviceDateTimeFormatQualifer
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Date</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837P' ? element.serviceDate : ''
                              }}
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>
                        <strong>Institutional Service</strong>
                        <table mdbTable bordered="true" small="true">
                          <tr>
                            <th>Revenue Code</th>
                            <td>
                              <span *ngIf="!element.service_line_info">
                                {{ element.institutionalServiceRevenueCode }}
                              </span>
                              <span *ngIf="element.service_line_info">
                                <input
                                  mdbInput
                                  maxlength="4"
                                  numbersOnly
                                  [(ngModel)]="element.institutionalServiceRevenueCode"
                                  class="form-control"
                                  (ngModelChange)="serviceLineChange(element)"
                                  [ngClass]="{
                                    'is-invalid': hasValidInstitutionalServiceRevenueCode(element)
                                  }" />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>Service ID Qualifier</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837I'
                                  ? element.serviceIdQualifier
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Code</th>
                            <td>
                              <span
                                *ngIf="
                                  !element.service_line_info &&
                                  claimDetail.transaction.ediType == '837I'
                                ">
                                {{
                                  claimDetail.transaction.ediType == '837I'
                                    ? element.procedureCode
                                    : ''
                                }}
                              </span>
                              <span
                                *ngIf="
                                  element.service_line_info &&
                                  claimDetail.transaction.ediType == '837I'
                                ">
                                <input
                                  mdbInput
                                  [(ngModel)]="element.procedureCode"
                                  maxlength="5"
                                  class="form-control"
                                  (ngModelChange)="serviceLineChange(element)"
                                  [ngClass]="{
                                    'is-invalid':
                                      element.procedureCode && element.procedureCode.length != 5
                                  }" />
                              </span>
                              <br
                                *ngIf="
                                  !element.service_line_info &&
                                  claimDetail.transaction.ediType == '837I'
                                " />
                              <em style="font-weight: initial">{{
                                claimDetail.transaction.ediType == '837I'
                                  ? element.cptDescriptor?.shortDescription
                                  : ''
                              }}</em>
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 1</th>
                            <td>
                              <span *ngIf="!element.service_line_info">
                                {{
                                  claimDetail.transaction.ediType == '837I' ? element.modifier1 : ''
                                }}
                              </span>
                              <span *ngIf="element.service_line_info">
                                <input
                                  mdbInput
                                  [(ngModel)]="element.modifier1"
                                  class="form-control"
                                  (focusout)="onFocusOutEvent(i, 'mod1')"
                                  (ngModelChange)="serviceLineChange(element)"
                                  alphaNumericWithoutComma />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 2</th>
                            <td>
                              <span *ngIf="!element.service_line_info">
                                {{
                                  claimDetail.transaction.ediType == '837I' ? element.modifier2 : ''
                                }}
                              </span>
                              <span *ngIf="element.service_line_info">
                                <input
                                  mdbInput
                                  [(ngModel)]="element.modifier2"
                                  class="form-control"
                                  [readOnly]="!element.modifier1"
                                  (focusout)="onFocusOutEvent(i, 'mod2')"
                                  (ngModelChange)="serviceLineChange(element)"
                                  alphaNumericWithoutComma />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 3</th>
                            <td>
                              <span *ngIf="!element.service_line_info">
                                {{
                                  claimDetail.transaction.ediType == '837I' ? element.modifier3 : ''
                                }}
                              </span>
                              <span *ngIf="element.service_line_info">
                                <input
                                  mdbInput
                                  [(ngModel)]="element.modifier3"
                                  class="form-control"
                                  [readOnly]="!element.modifier2"
                                  (focusout)="onFocusOutEvent(i, 'mod3')"
                                  (ngModelChange)="serviceLineChange(element)"
                                  alphaNumericWithoutComma />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 4</th>
                            <td>
                              <span *ngIf="!element.service_line_info">
                                {{
                                  claimDetail.transaction.ediType == '837I' ? element.modifier4 : ''
                                }}
                              </span>
                              <span *ngIf="element.service_line_info">
                                <input
                                  mdbInput
                                  [(ngModel)]="element.modifier4"
                                  class="form-control"
                                  (ngModelChange)="serviceLineChange(element)"
                                  [readOnly]="!element.modifier3"
                                  alphaNumericWithoutComma />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>Description</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837I' ? element.description : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              Line Item Charge Amount
                              <span *ngIf="element.service_line_info"> *</span>
                            </th>
                            <td>
                              <span *ngIf="!element.service_line_info">
                                {{
                                  (claimDetail.transaction.ediType == '837I'
                                    ? element.lineItemChargeAmount
                                    : ''
                                  ) | currency
                                }}
                              </span>
                              <span *ngIf="element.service_line_info">
                                <input
                                  mdbInput
                                  type="number"
                                  [(ngModel)]="element.lineItemChargeAmount"
                                  class="form-control"
                                  [ngClass]="{'is-invalid': !element.lineItemChargeAmount}"
                                  (ngModelChange)="serviceLineChange(element)"
                                  />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>Measurement Code</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837I'
                                  ? element.measurementCode
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Unit Count</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837I'
                                  ? element.serviceUnitCount
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Line Rate</th>
                            <td>
                              {{ element.institutionalServiceServiceLineRate }}
                            </td>
                          </tr>
                          <tr>
                            <th>Non Covered Charge Amount</th>
                            <td>
                              {{ element.institutionalServiceNonCoveredChargeAmount | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Response Code</th>
                            <td>
                              {{ element.institutionalServiceResponseCode }}
                            </td>
                          </tr>
                          <tr>
                            <th>Residential Status Code</th>
                            <td>
                              {{ element.institutionalServiceResidentialStatusCode }}
                            </td>
                          </tr>
                          <tr>
                            <th>Level Of Care Code</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837I'
                                  ? element.levelOfCareCode
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Date Time Qualifier</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837I'
                                  ? element.serviceDateTimeQualifer
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Date Time Format Qualifier</th>
                            <td>
                              {{
                                claimDetail.transaction.ediType == '837I'
                                  ? element.serviceDateTimeFormatQualifer
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Date<span *ngIf="element.service_line_info"> *</span></th>
                            <td>
                              <span *ngIf="!element.service_line_info">{{
                                claimDetail.transaction.ediType == '837I' ? element.serviceDate : ''
                              }}</span>
                              <span *ngIf="element.service_line_info">
                                <input
                                  mdbInput
                                  [(ngModel)]="element.serviceDate"
                                  name="{{ element }}ServiceDate"
                                  [maxlength]="
                                    element.serviceDateTimeFormatQualifer == 'RD8' ? 17 : 8
                                  "
                                  (ngModelChange)="serviceLineChange(element)"
                                  class="form-control"
                                  [placeholder]="
                                    element.serviceDateTimeFormatQualifer == 'RD8'
                                      ? 'eg: 20191201-20201201'
                                      : 'eg: 20191201'
                                  "
                                  [ngClass]="{
                                    'is-invalid':
                                      element.serviceDateTimeFormatQualifer == 'RD8'
                                        ? element?.serviceDate?.length != 17
                                        : element?.serviceDate?.length != 8
                                  }" />
                              </span>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <table mdbTable class="claim-details">
                    <tr>
                      <td width="50%">
                        <strong>Adjustment</strong>
                        <table mdbTable bordered="true" small="true">
                          <tr>
                            <th>Group Code</th>
                            <td>
                              {{ element.adjustmentGroupCode }}
                            </td>
                          </tr>
                          <tr>
                            <th>Reason Code</th>
                            <td>
                              {{ element.adjustmentReasonCode }}
                            </td>
                          </tr>
                          <tr>
                            <th>Amount</th>
                            <td>
                              {{ element.adjustmentAmount | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Quantity</th>
                            <td>
                              {{ element.adjustmentQuantity }}
                            </td>
                          </tr>
                          <tr>
                            <th>Date Time Qualifier</th>
                            <td>
                              {{ element.adjustmentDateTimeQualifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Date Time Format Qualifier</th>
                            <td>
                              {{ element.adjustmentDateTimeFormatQualifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Date</th>
                            <td>
                              {{ element.adjustmentDate | date : 'MM/dd/yyyy' }}
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>
                        <strong>Adjudication Information</strong>
                        <table mdbTable bordered="true" small="true">
                          <tr>
                            <th>Other Payor Primary Identifier</th>
                            <td>
                              {{ element.adjudicationInformationOtherPayerPrimaryIdentifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service Line Paid Amount</th>
                            <td>
                              {{ element.adjudicationInformationServiceLinePaidAmount | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Service ID Qualifier</th>
                            <td>
                              {{ element.adjudicationInformationServiceIdQualifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Code</th>
                            <td>
                              {{ element.adjudicationInformationProcedureCode }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 1</th>
                            <td>
                              {{ element.adjudicationInformationProcedureModifier1 }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 2</th>
                            <td>
                              {{ element.adjudicationInformationProcedureModifier2 }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 3</th>
                            <td>
                              {{ element.adjudicationInformationProcedureModifier3 }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Modifier 4</th>
                            <td>
                              {{ element.adjudicationInformationProcedureModifier4 }}
                            </td>
                          </tr>
                          <tr>
                            <th>Procedure Code Description</th>
                            <td>
                              {{ element.adjudicationInformationProcedureCodeDescription }}
                            </td>
                          </tr>
                          <tr>
                            <th>Paid Service Unit Count</th>
                            <td>
                              {{ element.adjudicationInformationPaidServiceUnitCount }}
                            </td>
                          </tr>
                          <tr>
                            <th>Bundled Line Number</th>
                            <td>
                              {{ element.adjudicationInformationBundledLineNumber }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <table mdbTable class="claim-details">
                    <tr>
                      <td width="50%">
                        <strong>Other Payor Referral Number</strong>
                        <table mdbTable bordered="true" small="true">
                          <tr>
                            <th>Entity Identifier Code</th>
                            <td>
                              {{ element.otherPayerReferralNumberIdentifierCode }}
                            </td>
                          </tr>
                          <tr>
                            <th>Entity Type Qualifier</th>
                            <td>
                              {{ element.otherPayerReferralNumberEntityTypeQualifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Organization/ Business Name</th>
                            <td>
                              {{ element.otherPayerReferralNumberBusinessName }}
                            </td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>
                              {{ element.otherPayerReferralNumberFirstName }}
                            </td>
                          </tr>
                          <tr>
                            <th>Middle Name</th>
                            <td>
                              {{ element.otherPayerReferralNumberMiddleName }}
                            </td>
                          </tr>
                          <tr>
                            <th>Last Name</th>
                            <td>
                              {{ element.otherPayerReferralNumberLastName }}
                            </td>
                          </tr>
                          <tr>
                            <th>Prefix</th>
                            <td>
                              {{ element.otherPayerReferralNumberPrefix }}
                            </td>
                          </tr>
                          <tr>
                            <th>Suffix</th>
                            <td>
                              {{ element.otherPayerReferralNumberSuffix }}
                            </td>
                          </tr>
                          <tr>
                            <th>Identification Code Qualifier</th>
                            <td>
                              {{ element.otherPayerReferralNumberIdentificationCodeQualifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Identifier/ Identification Code</th>
                            <td>
                              {{ element.otherPayerReferralNumberIdentifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Reference Identification Qualifier</th>
                            <td>
                              {{ element.otherPayerReferralNumberReferenceIdentificationQualifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Reference Identifier</th>
                            <td>
                              {{ element.otherPayerReferralNumberReferenceIdentifier }}
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>
                        <strong>Drug Info</strong>
                        <table mdbTable bordered="true" small="true">
                          <tr>
                            <th>Identification Assigned Identification</th>
                            <td>
                              {{ element.drugIdentificationAssignedIdentification }}
                            </td>
                          </tr>
                          <tr>
                            <th>Identification Service Qualifier</th>
                            <td>
                              {{ element.drugIdentificationServiceIdQualifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Identification National Drug Code</th>
                            <td>
                              {{ element.drugIdentificationNationalDrugCode }}
                            </td>
                          </tr>
                          <tr>
                            <th>Pricing Unit Price</th>
                            <td>
                              {{ element.drugPricingUnitPrice }}
                            </td>
                          </tr>
                          <tr>
                            <th>Pricing Unit Count</th>
                            <td>
                              {{ element.drugPricingUnitCount }}
                            </td>
                          </tr>
                          <tr>
                            <th>Pricing Unit of Measure</th>
                            <td>
                              {{ element.drugPricingUnitOfMeasure }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <table mdbTable class="claim-details">
                    <tr>
                      <td width="100%">
                        <strong>Line Item Control Number</strong>
                        <table mdbTable bordered="true" small="true">
                          <tr>
                            <th>Provider identification Qualifier</th>
                            <td>
                              {{ element.lineItemControlNumberProviderIdentificationQualifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Provider Control Number</th>
                            <td>{{ element.lineItemControlNumberProviderControlNumber }}</td>
                          </tr>
                          <tr>
                            <th>Prior Authorization Identification Qualifier</th>
                            <td>
                              {{ element.lineItemControlNumberPriorAuthorizationIdQualifier }}
                            </td>
                          </tr>
                          <tr>
                            <th>Prior Authorization Number</th>
                            <td>{{ element.lineItemControlNumberPriorAuthorizationNumber }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </mdb-accordion>
          </div>
        </div>
      </mdb-tab>
    </mdb-tabset>
  </div>
</section>

<!--Notes Section-->
<div
  mdbModal
  #noteModal="mdbModal"
  class="modal fade right"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}"
  (close)="closeNoteModal('hide')">
  <div
    mwlResizable
    class="modal-dialog modal-full-height fixed-height modal-right modal-notify mw-100"
    [validateResize]="validate"
    [ngStyle]="style"
    [enableGhostResize]="true"
    [resizeEdges]="{left: true}"
    [resizeSnapGrid]="{width: 400}"
    (resizeEnd)="onResizeEnd($event)"
    role="document">
    <div class="modal-content bg-light">
      <!--Header-->
      <div class="modal-header z-depth-1 position-sticky header">
        <p class="mb-0 lead">Notes</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeNoteModal('close')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <!--Body-->
      <div class="modal-body p-2 scrollbar scrollbar-primary thin">
        <app-loader [loader]="loading.notes"></app-loader>
        <div id="notesSection" #notesSection>
          <ul class="list-unstyled" *ngIf="!loading.notes && notes.length > 0">
            <li class="p-1" *ngFor="let note of notes">
              <div
                (click)="
                  note.type &&
                    note.type.toLowerCase() === COMMUNICATIONS &&
                    onNoteSelected(note.referenceId)
                "
                id="{{ note.id }}-newNote"
                class="list-content step-content bg-white border border-light ml-xl-0 p-2 rounded hover">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="avatar-wrapper d-flex justify-content-start">
                    <div class="profile-photo user-online">
                      <i class="icofont-ui-user text-info"></i>
                    </div>
                    <div class="text-smaller ml-2 text-black-50">
                      <p class="font-weight-bold">
                        {{ note?.user?.fullName }}
                      </p>
                      <p class="text-muted">
                        {{ note.createdDate | date : 'MM/dd/yyyy hh:mm a' }}
                      </p>
                      <span class="z-depth-0 badge badge-info badge-pill" *ngIf="note.type">
                        {{ note.type | titlecase }}
                      </span>
                    </div>
                  </div>
                  <span class="action-item position-relative d-none">
                    <i
                      *ngIf="note.createdBy == user.id"
                      class="icofont-ui-delete icofont-sm mr-2"
                      (click)="deleteNote(note)"></i>
                  </span>
                </div>
                <hr class="mt-2 mb-2" />
                <div [innerHtml]="note.notes"></div>
              </div>
            </li>
          </ul>
          <div class="text-center p-2 border mt-1" *ngIf="!loading.notes && notes.length == 0">
            <i class="icofont-ui-note icofont-3x text-black-50"></i>
            <p>Start adding notes to this claim.</p>
          </div>
        </div>
      </div>
      <div
        class="modal-footer position-sticky d-block z-depth-2"
        *ngIf="
          this.utilityAccess.searchAccess(
            name === 'Outgoing Claims' ? 'OCLM' : 'CLM',
            'isEditable',
            false
          )
        ">
        <button
          class="btn btn-secondary btn-block waves-light"
          type="button"
          (click)="notesstatus = !notesstatus"
          mdbWavesEffect>
          {{ notesstatus ? 'Minimize Note' : 'Add Note'
          }}<i *ngIf="notesstatus" class="icofont-rounded-down"></i>
        </button>
        <div class="mt-3 ml-0" [ngClass]="notesstatus ? 'expand' : 'collapse'">
          <form [formGroup]="noteForm">
            <div id="newNote">
              <angular-editor [config]="editorConfig" formControlName="notes"></angular-editor>
              <div class="d-flex justify-content-between pt-2">
                <button
                  class="btn btn-secondary btn-sm m-0"
                  mdbWavesEffect
                  [disabled]="submit_enabled || !noteForm.value.notes"
                  (click)="saveNote()">
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="submit_enabled"></span>
                  <mdb-icon far icon="paper-plane" *ngIf="!submit_enabled"></mdb-icon> Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<app-delete-confirmation
  [_id]="noteId"
  [message]="message"
  (confirmDelete)="deleteTriggered($event)">
</app-delete-confirmation>

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [moduleOptions]="moduleOptions"
  [metaVariablesDetail]="metaVariablesDetail"
  [showModal]="showMessageModal"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->

<!--- Email events popup -->
<div
  mdbModal
  #messageThreadModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Communications</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeMessageModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div
        class="modal-body sticky-content scrollbar scrollbar-primary thin pt-0"
        [ngStyle]="{
          height: 'calc(100vh - 220px)'
        }">
        <app-loader [loader]="isLoading"></app-loader>
        <app-message-view
          *ngIf="!isLoading && messageThread.length"
          [messageThread]="messageThread"></app-message-view>
      </div>
      <div class="modal-footer d-flex flex-end border-top-0 pt-0"></div>
    </div>
  </div>
</div>
<!--- Email events popup -->
