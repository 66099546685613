import { Injectable } from "@angular/core";
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
import { APICONSTANTS } from "../constants";
import { of } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class PaymentService {
    constructor(private http: HttpClientService) { }

    getPayments(page, limit) {
        return this.http.get(`${APICONSTANTS.PAYMENT}?page=${page}&limit=${limit}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    savePayment(payment) {
        return this.http.post(`${APICONSTANTS.PAYMENT}`, payment).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    updatePayment(payment) {
        return this.http.put(`${APICONSTANTS.PAYMENT}`, payment).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    patchPayment(paymentId, body) {
        return this.http.patch(`${APICONSTANTS.PAYMENT}/${paymentId}`, body).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getRemittanceSummaryByClaimId(claimId) {
        return this.http.get(`${APICONSTANTS.PAYMENT}/claim/${claimId}/remittance-summary`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    searchPayment(body) {
        return this.http.post(`${APICONSTANTS.PAYMENT}/search`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchNewPayments(queryParams) {
        return this.http.get(`${APICONSTANTS.PAYMENT}`, queryParams).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getPaymentByUuid(uuid: string) {
        return this.http.get(`${APICONSTANTS.PAYMENT}/${uuid}`,).pipe(
            map((res: any) => {
                return res;
            })
        );
    }

    approveEDI(body) {
        return this.http.post(`${APICONSTANTS.PAYMENT}/approveEDI`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

}