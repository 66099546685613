import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AccessType, ScrollTo, Sorting, Storage} from '../../utils';
import * as HeaderBreadCrumbActions from '../../action';
import {finalize, startWith, switchMap, debounceTime, map} from 'rxjs/operators';
import {BundleCost, AssociatedProviders} from '../../models';
import {Subject, Observable, of} from 'rxjs';
import {
  BundleCostService,
  NetworkService,
  BundleService,
  ToasterService,
  ClientService,
  PurchaserService,
} from '../../services';
import {TabsetComponent} from 'ng-uikit-pro-standard';
import {
  ACCESS_DENIED,
  ADD_BUNDLE_COMPONENT,
  ADD_SUCCESS,
  CLIENT_ADMINS,
  DELETE_SUCCESS,
  UPDATE_SUCCESS,
} from 'src/app/constants';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-bundle-cost',
  templateUrl: './bundle-cost.component.html',
  styleUrls: ['./bundle-cost.component.scss'],
})
export class BundleCostComponent implements OnInit {
  bundleCostList = [];
  searchKeyword = '';
  loading: boolean = false;
  bundleCostForm: FormGroup;
  AssociatedProviderForm: FormGroup;
  submitted = false;
  submit_enabled = false;
  order: string = 'createdAt';
  reverse: boolean = true;
  bundlecost = new BundleCost();
  bundle_search_text = new Subject();
  bundle_results: Observable<any>;
  marketList = [];
  displayMarketList = [];
  providers = new AssociatedProviders();
  submitBtnName: string = null;
  disableSave: boolean = false;
  message: string;
  bundleBody: any = {};
  provider_search_text = new Subject();
  provider_results: Observable<any>;
  searching = false;
  bundleCostGroups: any;
  valueAscOrder: any;
  activeBundleCostTab: any;
  addedBundleCost: any;
  insurerDropdown: any[] = [];
  user: any;
  backupBundleCostList: any = [];
  selectedNetworkCode: any;
  networks: any[] = [];
  deleteId: string = '';
  selectedBundleCost: any = {};

  @ViewChild('bundleCostModal', {static: true}) public bundleCostModal;
  @ViewChild('providerModal', {static: true}) public providerModal;
  @ViewChild('staticTabs', {static: true}) staticTabs: TabsetComponent;

  constructor(
    private store: Store<{bread_crumbs: any}>,
    private bundleCostService: BundleCostService,
    private formBuilder: FormBuilder,
    private toastr: ToasterService,
    private utilityAccess: AccessType,
    private _scrollTo: ScrollTo,
    private cd: ChangeDetectorRef,
    private _storage: Storage,
    private serviceBundle: BundleService,
    private networkService: NetworkService,
    private clientService: ClientService,
    private purchaserService: PurchaserService,
    private utilitySorting: Sorting
  ) {
    this.setBundleCostForm();
    this.setAssociatedProviderForm();
    this.sortObject();
  }

  ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(
      new HeaderBreadCrumbActions.AddBreadCrumb({name: 'Bundle Costs', path: '/bundle-cost'})
    );
    this.bundle_results = this.bundle_search_text.pipe(
      startWith(this.bundlecost.bundle_search_text),
      switchMap((bundle_search_text: string) => this.searchBundle(bundle_search_text))
    );
    this.user = this._storage.get('local', 'loggedInUser', 'user');
    this.getBundleCost();
    CLIENT_ADMINS.includes(this.user.roleCode)
      ? this.getClientNetworks()
      : this.user.roleCode == 'PUA'
      ? this.getPurchaserNetworks()
      : this.getNetworks();
    this.getAllMarket();
    this.provider_results = this.provider_search_text.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((provider_search_text: string) => this.searchProviders(provider_search_text))
    );
  }

  setNullForEmpty(formName) {
    Object.keys(formName.value).forEach(k => {
      formName.controls[k].setValue(
        formName.value[k] || typeof formName.value[k] == 'boolean' ? formName.value[k] : null
      );
    });
  }

  filterBundleCosts(event, type?) {
    Object.keys(this.bundleCostGroups).forEach(k => {
      this.bundleCostGroups[k].map((x: any) => {
        x.collapsed = false;
        return x;
      });
    });
    setTimeout(() => {
      if (!type)
        this.networks = this.networks.map(network => {
          if (network.value == event.value) network.disabled = true;
          else network.disabled = false;
          return network;
        });
      else
        this.networks = this.networks.map(x => {
          x.disabled = false;
          return x;
        });
      this.selectedNetworkCode = type && type == 'remove' ? null : event.value;
      this.bundleCostList =
        type && type == 'remove'
          ? this.backupBundleCostList
          : this.backupBundleCostList.filter(x => x.networkCode == event.value);
      this.combineMarketCode(this.bundleCostList);
    });
  }

  setBundleCostForm() {
    this.bundleCostForm = this.formBuilder.group({
      bundleUuid: [''],
      marketCode: [''],
      networkCode: [''],
      cost: [''],
      id: [null],
      isEdit: [false],
    });
  }

  setAssociatedProviderForm() {
    this.AssociatedProviderForm = this.formBuilder.group({
      isProvider: [false],
      taxonomyCode: [''],
      marketCode: [''],
      id: [],
      providers: [],
    });
  }

  sortObject() {
    this.valueAscOrder = (a, b): any => {
      return b.toString().localeCompare(a - b);
    };
  }

  getAllMarket() {
    this.bundleCostService.getAllMarket({limit: 0}).subscribe(
      res => {
        if (res.rows.length !== 0) {
          res.rows.map(x => {
            const data = {label: x.name, value: x.code, networkCode: x.networkCode};
            this.marketList = [...this.marketList, data];
          });
        }
      },
      error => {
        console.log('Error', error);
        this.toastr.showError(error);
      }
    );
  }

  getBundleCost() {
    this.loading = true;
    this.bundleCostService
      .getBundleCost({sortBy: 'createdAt', sortType: 'desc', limit: 0})
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        response => {
          response.rows.map(x => {
            x.totalCost = +x.totalCost;
            x.costComponents.map(y => {
              y.marketCode = x.market ? x.market.code : '';
              y.networkCode = x.networkCode;
              y.name = y.component.name;
              return y;
            });
            x.fundingRequests.map(f => {
              f.name = f.funding.name;
              f.sequence = f.funding.sequence;
              return f;
            });
            x.costComponents = this.utilitySorting.sortBy(x.costComponents, 'name');
            x.fundingRequests = this.utilitySorting.sortBy(x.fundingRequests, 'sequence');
            return x;
          });
          this.bundleCostList = JSON.parse(JSON.stringify(response.rows));
          this.backupBundleCostList = JSON.parse(JSON.stringify(response.rows));
          if (response.rows.length !== 0) {
            this.combineMarketCode(this.bundleCostList);
          }
        },
        error => {
          console.log('Error', error);
          this.toastr.showError(error);
        }
      );
  }

  combineMarketCode(bundleCosts) {
    this.bundleCostGroups = this.groupByBundleUuid(bundleCosts);
    Object.keys(this.bundleCostGroups).forEach(k => {
      let displayCode = [];
      this.bundleCostGroups[k].map((x: any) => {
        x.activeCode = this.bundleCostGroups[k][0].market.name;
        if (x.bundle.id == k) {
          displayCode.push(x.market.name);
          x.displayCode = displayCode;
        }
      });
    });
    Object.keys(this.bundleCostGroups).forEach(k => {
      this.bundleCostGroups[k].map((x: any) => {
        x.displayMarket = x.displayCode.join(', ');
      });
    });
  }

  groupByBundleUuid(array) {
    return array.reduce((r, a) => {
      if ((r[a.bundle.id] = r[a.bundle.id] || [])) r[a.bundle.id].push(a);
      return r;
    }, Object.create(null));
  }

  getClientNetworks() {
    this.clientService.getNetworksByClientCode(this.user.referenceCode).subscribe(
      res => {
        res.map(network => {
          network.label = network.name;
          network.value = network.code;
          return network;
        });
        this.networks = JSON.parse(JSON.stringify(res));
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  getPurchaserNetworks() {
    this.purchaserService.getNetworks(this.user.referenceCode).subscribe(
      res => {
        res.rows.map(network => {
          network.label = network.name;
          network.value = network.code;
          return network;
        });
        this.networks = JSON.parse(JSON.stringify(res.rows));
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  getNetworks() {
    this.networkService.getAll({limit: 0, fields: 'code,name'}).subscribe(
      (res: any) => {
        res.rows = this.utilitySorting.sortBy(res.rows, 'name');
        res.rows.map(x => {
          const data = {label: x.name, value: x.code};
          this.networks = [...this.networks, data];
        });
      },
      error => {
        console.log('Error getting Networks', error);
      }
    );
  }

  createBundleCost(bundleCost?) {
    if (this.utilityAccess.searchAccess('BCM', 'isEditable')) {
      this.bundleCostForm.controls.isEdit.setValue(true);
      if (bundleCost) {
        this.addedBundleCost = bundleCost;
        this.bundle_search_text.next(bundleCost.displayBundle ? bundleCost.displayBundle : '');
      }
      this.bundleCostModal.show();
    } else {
      this.toastr.displayWarning(ACCESS_DENIED);
    }
  }

  editBundleCost(bundleCost) {
    if (this.utilityAccess.searchAccess('BCM', 'isEditable')) {
      let selectedBundleCost = JSON.parse(JSON.stringify(bundleCost));
      this.bundleCostForm.controls.isEdit.setValue(true);
      selectedBundleCost.fundingRequests.map(f => {
        f.fundingRequestName = f.funding.name;
        return f;
      });
      selectedBundleCost.costComponents = selectedBundleCost.costComponents.map((c: any) => {
        let data = {
          ...c,
          fundingRequestName: selectedBundleCost.fundingRequests.find(
            f => f.fundingRequestUuid == c.component.fundingRequestUuid
          ).fundingRequestName,
        };
        return data;
      });
      let sum: number = 0;
      selectedBundleCost.fundingRequests.forEach(a => (sum += +a.cost));
      selectedBundleCost.totalFundingRequestCost = +Number(sum).toFixed(2);
      this.displayMarketList = this.marketList.filter(
        x => x.networkCode === selectedBundleCost.networkCode
      );
      this.bundlecost = JSON.parse(JSON.stringify(selectedBundleCost));
      this.bundle_search_text.next(
        selectedBundleCost.displayBundle ? bundleCost.displayBundle : ''
      );
      this.bundleCostForm.controls.marketCode.setValue(selectedBundleCost.market.code);
      this.bundleCostModal.show();
    } else {
      this.toastr.displayWarning(ACCESS_DENIED);
    }
  }

  closeModal() {
    this.bundleCostModal.hide();
    this.bundleCostForm.reset();
    this.submit_enabled = false;
    this.submitted = false;
    this.bundlecost = new BundleCost();
    this.bundlecost.costComponents = [];
    this.displayMarketList = [];
    this.bundle_search_text.next('');
    this.message = null;
    this.submitBtnName = null;
    this.addedBundleCost = null;
    this.disableSave = false;
  }

  save() {
    this.submit_enabled = true;
    this.recheckCondition();
    this.bundleBody.marketCode = this.bundlecost.marketCode;
    if (this.bundleBody.uuid) this.updateBundleCost(this.bundleBody);
    else this.saveBundleCost(this.bundleBody);
  }

  submitBundleCost() {
    if (this.bundlecost.bundleUuid && this.bundlecost.marketCode) {
      if (this.bundlecost.costComponents.length == 0)
        this.toastr.displayWarning(ADD_BUNDLE_COMPONENT);
      else {
        const body = {
          uuid: this.bundlecost.uuid,
          bundleUuid: this.bundlecost.bundleUuid,
          marketCode: this.bundlecost.marketCode,
          networkCode: this.bundlecost.networkCode,
          totalCost: this.bundlecost.totalCost,
          bundleCode: this.bundlecost['bundle'].bundleCode,
          costComponents: this.bundlecost.costComponents.map(x => {
            if (!x.cost) x.cost = 0;
            else x.cost = +x.cost;
            return x;
          }),
          fundingRequests: this.bundlecost.fundingRequests.map(x => {
            if (!x.cost) x.cost = 0;
            else x.cost = +x.cost;
            return x;
          }),
        };
        this.bundleBody = body;
        this.bundlecost.fundingRequests.forEach((fund: any) => {
          fund.cost = fund.cost ? +fund.cost : 0;
          let matchedComponents = this.bundlecost.costComponents.filter(
            (x: any) =>
              (x.fundingRequestUuid ? x.fundingRequestUuid : x.component.fundingRequestUuid) ==
              (fund.fundingRequestUuid ? fund.fundingRequestUuid : fund.uuid)
          );
          if (matchedComponents.length > 0) {
            let componentsSum = 0;
            matchedComponents.forEach((component: any) => {
              component.cost = component.cost ? +component.cost : 0;
              componentsSum += component.cost;
            });
            fund['fundingCostExceeds'] = false; // When funding cost exceeds the sum of cost of bundle components of particular funding
            if (+Number(fund.cost).toFixed(2) > +Number(componentsSum).toFixed(2)) {
              fund['fundingCostExceeds'] = true;
              fund['mismatchedName'] = fund.name || fund.fundingRequestName;
            } else if (+Number(fund.cost).toFixed(2) < +Number(componentsSum).toFixed(2)) {
              fund['textDanger'] = true; // When funding cost is lesser than sum of cost of bundle components of particular funding
              fund['mismatchedName'] = fund.name || fund.fundingRequestName;
              this.bundlecost.costComponents.map((x: any) => {
                if (
                  (x.fundingRequestUuid ? x.fundingRequestUuid : x.component.fundingRequestUuid) ==
                  (fund.fundingRequestUuid ? fund.fundingRequestUuid : fund.uuid)
                )
                  x['textDanger'] = true;
              });
            }
            matchedComponents = [];
          }
        });
        const mismatchedFundings = this.bundlecost.fundingRequests.filter(
          (fund: any) => fund.mismatchedName
        );
        if (!mismatchedFundings.length) {
          this.save();
        } else {
          // If there are multiple funding req where the costs are mismatched due to different reasons
          if (
            mismatchedFundings.length > 1 &&
            mismatchedFundings.find(x => x['textDanger']) &&
            mismatchedFundings.find(x => x['fundingCostExceeds'])
          ) {
            const mismatchedFundRequestNames = mismatchedFundings.map(
              (fund: any) => fund.name || fund.fundingRequestName
            );
            let fundRequestNames = '';
            fundRequestNames = mismatchedFundRequestNames.join(', ');
            this.message = `${fundRequestNames} are mismatched. Are you sure you want to proceed ? `;
          } else {
            this.message = `Sum of bundle components cost associated with a funding request ${
              mismatchedFundings[0]['fundingCostExceeds'] ? 'did not match' : 'exceeds'
            } the amount of the funding request. Are you sure you want to proceed ?`;
          }
          this.submitBtnName = this.bundleBody.uuid ? 'Update Anyway' : 'Save Anyway';
        }
      }
    }
  }

  updateBundleCost(body) {
    this.bundleCostService
      .updateBundleCost(body)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          if (res.message) {
            this.toastr.displayWarning(res.message);
          } else {
            this.refreshBundle(res, UPDATE_SUCCESS);
          }
        },
        error => {
          console.log('Error', error);
          this.toastr.showError(error);
        }
      );
  }

  saveBundleCost(body) {
    this.bundleCostService
      .saveBundleCost(body)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          if (res.message) {
            this.toastr.displayWarning(res.message);
          } else {
            this.refreshBundle(res, ADD_SUCCESS);
          }
        },
        error => {
          console.log('Error', error);
          this.toastr.showError(error);
        }
      );
  }

  refreshBundle(response, toastrMsg) {
    response.totalCost = +response.totalCost;
    let bundleCost = response;
    bundleCost.displayBundle =
      this.bundlecost && this.bundlecost['bundle']
        ? this.bundlecost['bundle'].name
        : this.bundlecost['displayBundle']
        ? this.bundlecost['displayBundle']
        : '';
    bundleCost.displayMarket =
      this.bundlecost && this.bundlecost.marketCode ? this.bundlecost.marketCode : '';
    bundleCost.costComponents = bundleCost.costComponents.map(y => {
      y.name = y.component.name;
      y.isPrimary = y.component.isPrimary;
      y.isMedicalCenter = y.component.isMedicalCenter;
      y.isFundingTrigger = y.component.isFundingTrigger;
      y.marketCode = bundleCost.marketCode;
      y.networkCode = bundleCost.networkCode;
      y.fundingRequestUuid = y.component.fundingRequestUuid;
      return y;
    });
    bundleCost.fundingRequests.map(f => {
      f.fundingRequestUuid = f.funding.uuid;
      f.sequence = f.funding.sequence;
      f.name = f.funding.name;
      return f;
    });
    bundleCost.costComponents = this.utilitySorting.sortBy(bundleCost.costComponents, 'name');
    bundleCost.fundingRequests = this.utilitySorting.sortBy(bundleCost.fundingRequests, 'sequence');
    if (this.bundleCostList && this.bundleCostList.length > 0) {
      let index = this.bundleCostList.findIndex(x => x.id === bundleCost.id);
      if (index > -1) {
        this.bundleCostList[index] = bundleCost;
      } else {
        this.bundleCostList.push(bundleCost);
        if (this.selectedNetworkCode)
          this.bundleCostList = this.bundleCostList.filter(
            x => x.networkCode == this.selectedNetworkCode
          );
      }
      let backupIndex = this.backupBundleCostList.findIndex(x => x.id === bundleCost.id);
      if (backupIndex > -1) {
        this.backupBundleCostList[backupIndex] = bundleCost;
      } else {
        this.backupBundleCostList.push(bundleCost);
      }
      this.combineMarketCode(this.bundleCostList);
      Object.keys(this.bundleCostGroups).forEach(k => {
        this.bundleCostGroups[k].map((x: any) => {
          if (x.bundle.uuid == response.bundle.uuid) {
            x.collapsed = true;
          }
        });
      });
      this.cd.detectChanges();
    } else {
      this.bundleCostList.push(bundleCost);
      if (this.selectedNetworkCode)
        this.bundleCostList = this.bundleCostList.filter(
          x => x.networkCode == this.selectedNetworkCode
        );
      this.backupBundleCostList.push(bundleCost);
      this.combineMarketCode(this.bundleCostList);
    }
    this.toastr.displaySuccess(toastrMsg);
    this.closeModal();
  }

  // setOrder(value: string) {
  //   if (this.order === value) {
  //     this.reverse = !this.reverse;
  //   }
  //   this.order = value;
  // }

  onBundleDisplayValue = (bundle?): string | undefined => {
    this.getBundleCostDetails(bundle);
    return bundle && bundle.name ? bundle.name : undefined;
  };

  getBundleCostDetails(bundle) {
    let selectedBundle = JSON.parse(JSON.stringify(bundle));
    this.bundlecost.marketCode = '';
    this.displayMarketList = this.marketList.filter(
      x => x.networkCode === selectedBundle.networkCode
    );
    if (
      !this.bundlecost.bundleUuid ||
      (this.bundlecost.bundleUuid &&
        selectedBundle.id &&
        this.bundlecost.bundleUuid != selectedBundle.id)
    ) {
      this.bundlecost.bundleUuid =
        selectedBundle && selectedBundle.uuid ? selectedBundle.uuid : null;
      this.bundlecost.networkCode = selectedBundle.networkCode;
      this.bundlecost.totalCost = 0;
      this.bundlecost.totalFundingRequestCost = 0;
      let components = [];
      selectedBundle.components = selectedBundle.components.map(c => {
        components.push({
          name: c.name,
          fundingRequestName: selectedBundle.fundingRequests.find(
            f => f.uuid == c.fundingRequestUuid
          ).name,
          fundingRequestUuid: c.fundingRequestUuid,
          cost: 0,
          uuid: c.uuid,
          isPrimary: c.isPrimary,
          isMedicalCenter: c.isMedicalCenter,
          isFundingTrigger: c.isFundingTrigger,
          component: c.component ? c.component : null,
        });
      });
      this.bundlecost.costComponents = this.utilitySorting.sortBy(components, 'name');
      let fundingReqs = [];
      selectedBundle.fundingRequests = selectedBundle.fundingRequests.map(x => {
        fundingReqs.push({
          cost: 0,
          uuid: x.uuid,
          fundingRequestName: x.name,
          sequence: x.sequence,
        });
      });
      this.bundlecost.fundingRequests = this.utilitySorting.sortBy(fundingReqs, 'sequence');
    }
    this.bundlecost['bundle'] = selectedBundle;
  }

  bundleSearchChange() {
    this.bundle_search_text.subscribe((res: any) => {
      if (res.length < 3) {
        this.bundlecost = new BundleCost();
        this.bundleCostForm.reset();
        this.displayMarketList = [];
      }
    });
  }

  searchBundle(searchText: string) {
    if (searchText.length > 2) {
      const query = {keyword: searchText};
      return this.serviceBundle.getServiceBundle(query).pipe(
        debounceTime(250),
        map((items: any) => {
          if (this.addedBundleCost) {
            const bundle = items.rows.find(x => x.id == this.addedBundleCost.bundle.id);
            this.getBundleCostDetails(bundle);
          }
          return items.rows;
        })
      );
    } else {
      return of([]);
    }
  }

  recheckCondition() {
    if (this.message && this.submitBtnName) {
      this.message = null;
      this.submitBtnName = null;
      this.disableSave = false;
      this.bundlecost.costComponents.map(
        x => ((x['textDanger'] = false), (x['mismatchedName'] = null))
      );
      this.bundlecost.fundingRequests.map(
        x => ((x['textDanger'] = false), (x['mismatchedName'] = null))
      );
    }
  }

  calculateCost() {
    this.bundlecost.totalCost = 0;
    this.bundlecost.costComponents.map(x => {
      this.bundlecost.totalCost += +x.cost;
    });
    this.recheckCondition();
  }

  calculateFundingCost() {
    this.bundlecost.totalFundingRequestCost = 0;
    this.bundlecost.fundingRequests.map(x => {
      this.bundlecost.totalFundingRequestCost += +x.cost;
    });
    this.recheckCondition();
  }

  openProviderModal(costComponent) {
    this.providers.selectedBundleCostComponent = JSON.parse(JSON.stringify(costComponent));
    this.providers.provider_loading = true;
    this.providerModal.show();
    this.getProviders(costComponent);
  }

  getProviderDetails(providerId, networkCode?: string) {
    // this.providers.providerList = []
    this.bundleCostService
      .getVitafyProviderById(providerId, networkCode)
      .pipe(
        finalize(() => {
          this.providers.provider_loading = false;
        })
      )
      .subscribe(
        res => {
          res.providerList.map(x => {
            x.marketCode = this.providers.selectedBundleCostComponent.marketCode;
            return x;
          });
          if (this.providers.providerList.length > 0)
            this.providers.providerList.push(res.providerList[0]);
          else this.providers.providerList = res.providerList;
        },
        error => {
          console.log('Error', error);
          this.toastr.showError(error);
        }
      );
  }

  closeProviderModal() {
    this.providerModal.hide();
    this.providers = new AssociatedProviders();
    this.provider_search_text.next('');
    this.providers.providerList = [];
  }

  getProviders(costComponent) {
    const {uuid, networkCode} = costComponent;
    this.bundleCostService
      .getBundleComponentCostProvider({bundleComponentCostUuid: uuid})
      .subscribe(
        (res: any) => {
          this.providers.holistaProviders = res.rows;
          if (res.rows.length !== 0) {
            this.providers.selectedProviders = res.rows.map(holProvider => {
              return holProvider.providerId;
            });
            const providerId = this.providers.selectedProviders.join();
            this.getProviderDetails(providerId, networkCode);
          } else {
            this.providers.provider_loading = false;
          }
        },
        error => {
          console.log('Error', error);
          this.toastr.showError(error);
        }
      );
  }

  marketSelect(market) {
    this.bundlecost.marketCode = market.value;
  }

  delete(bundleCost) {
    if (this.utilityAccess.searchAccess('BCM', 'isEditable')) {
      this.deleteId = bundleCost.uuid;
      this.selectedBundleCost = bundleCost;
      this.message = `Are you sure you want to delete <strong>${bundleCost.displayBundle}</strong> of <strong>${bundleCost.marketCode}</strong> market ? This is irreversible.
      If you're not sure, you can click <strong>Cancel</strong>.`;
    } else this.toastr.displayWarning(ACCESS_DENIED);
  }

  confirmDelete(id) {
    if (id) {
      this.bundleCostService.deleteBundleCost(id).subscribe(
        res => {
          this.toastr.displaySuccess(DELETE_SUCCESS);
          const index = this.bundleCostList.findIndex(x => x.uuid === id);
          if (index > -1) {
            this.bundleCostList.splice(index, 1);
          }
          const backupIndex = this.backupBundleCostList.findIndex(x => x.uuid === id);
          if (backupIndex > -1) {
            this.backupBundleCostList.splice(backupIndex, 1);
          }
          this.combineMarketCode(this.bundleCostList);
          this.bundleCostForm.controls.isEdit.setValue(true);
          Object.keys(this.bundleCostGroups).forEach(k => {
            this.bundleCostGroups[k].map((x: any) => {
              if (x.bundle.uuid == this.selectedBundleCost.bundle.uuid) {
                x.collapsed = true;
              }
            });
          });
          this.cd.detectChanges();
        },
        error => {
          console.log('Error deleting Bundle cost', error);
          this.toastr.showError(error);
        }
      );
    }
    this.deleteId = null;
    this.message = null;
  }

  scrollToTop() {
    this._scrollTo.ScrollTo('scrollToTop', 'center', 'smooth');
  }

  onProviderDisplayValue = (provider?): string | undefined => {
    return provider.businessName;
  };

  deleteViewProvider(provider) {
    const providerToDeleteIndex = this.providers.holistaProviders.findIndex(
      x => x.providerId == provider.providerId
    );
    if (providerToDeleteIndex > -1) {
      this.bundleCostService
        .deleteBundleComponentCostProvider(
          this.providers.holistaProviders[providerToDeleteIndex].uuid
        )
        .subscribe(
          res => {
            if (res) {
              const index = this.providers.providerList.findIndex(
                x => x.providerId == provider.providerId
              );
              const selectedProviderIndex = this.providers.selectedProviders.findIndex(
                x => x == provider.providerId
              );
              if (index > -1 && selectedProviderIndex > -1) {
                this.providers.providerList.splice(index, 1);
                this.providers.holistaProviders.splice(providerToDeleteIndex, 1);
                this.providers.selectedProviders.splice(selectedProviderIndex, 1);
                this.toastr.displaySuccess(DELETE_SUCCESS);
              }
            }
          },
          error => {
            console.log('Error deleting provider', error);
            this.toastr.showError(error);
          }
        );
    }
  }

  searchProviders(searchText: string) {
    const bundleCostDetail = this.providers.selectedBundleCostComponent;
    if (searchText.length > 2) {
      this.searching = true;
      const data = {
        tags: bundleCostDetail.marketCode,
        network: bundleCostDetail.networkCode,
        isMedicalCenter: bundleCostDetail.isMedicalCenter,
        keyword: searchText,
        limit: 10,
        offset: 0,
      };
      return this.bundleCostService.getvitafyProviders(data).pipe(
        finalize(() => (this.searching = false)),
        debounceTime(250),
        map((items: any) => {
          return items.providerList;
        })
      );
    } else {
      return of([]);
    }
  }

  providerSelected(event) {
    const providerFound = this.providers.providerList.find(
      x => x.providerId == event.text.providerId
    );
    if (!providerFound) {
      this.providers.selectedProviders.push(event.text.providerId);
      event.text.marketCode = this.providers.selectedBundleCostComponent.marketCode;
      event.text.networkCode = this.providers.selectedBundleCostComponent.networkCode;
      this.saveProvider(event.text);
    }
    this.provider_search_text.next('');
  }

  saveProvider(event) {
    let body = {
      providerId: event.providerId,
      bundleCostUuid: this.providers.selectedBundleCostComponent.bundleCostUuid,
      bundleComponentCostUuid: this.providers.selectedBundleCostComponent.uuid,
      bundleComponentUuid: this.providers.selectedBundleCostComponent.bundleComponentUuid,
      marketCode: event.marketCode,
      networkCode: event.networkCode,
      name: event.displayName || event.businessName,
      npi: event.npi,
    };
    this.bundleCostService.saveBundleComponentCostProvider(body).subscribe(
      res => {
        if (res) {
          this.providers.holistaProviders.push(res);
          let providerIndex = this.providers.providerList.findIndex(
            x => x.providerId == res.providerId
          );
          if (providerIndex < 0) {
            this.getProviderDetails(
              res.providerId,
              this.providers.selectedBundleCostComponent.networkCode
            );
            this.toastr.displaySuccess(ADD_SUCCESS);
          }
        }
      },
      error => {
        console.log('Error', error);
        this.toastr.showError(error);
      }
    );
  }

  onTabSelect(event) {
    this.activeBundleCostTab = event;
    Object.keys(this.bundleCostGroups).forEach(k => {
      this.bundleCostGroups[k].map(x => {
        if (x.bundle.uuid == event.bundle.uuid) x.activeCode = event.marketCode;
        return x;
      });
    });
    if (!this.bundleCostForm.value.isEdit) this.cd.detectChanges();
  }

  showBundleCostDetails(bundleCost) {
    bundleCost.collapsed = !bundleCost.collapsed;
    this.cd.detectChanges();
  }

  removeSearchKeyword() {
    this.searchKeyword = '';
  }
}
