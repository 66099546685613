<section class="container">
  <div class="my-3" *ngIf="!isView">
    <a
      [attr.data-test-id]="'edit-add-permission-back'"
      class="cursor-pointer"
      (click)="navigateBack()"
      ><i class="fas fa-arrow-left mr-1"></i> Go back
    </a>
  </div>
  <form [formGroup]="permissionForm">
    <div class="d-flex flex-column" *ngIf="type == 'role'">
      <h6 class="font-weight-bold">Role Name *</h6>
      <input
        type="text"
        class="completer-input form-control mdb-autocomplete mb-2"
        id="roleName"
        formControlName="roleName"
        [attr.data-test-id]="'role-name'"
        placeholder="Eg. Care Coordinator"
        [readOnly]="!isAdd" />
      <h6 class="font-weight-bold my-2">Assign Permissions</h6>
    </div>
    <!-- This is when we click manage permission from users list -->
    <h6 class="font-weight-bold" [class.mb-4]="!isView" *ngIf="type == 'user'">
      {{ isView ? 'View' : 'Manage' }} Permissions for - {{ userName }}
    </h6>
  </form>
  <div class="table-wrapper">
    <table
      mdbTable
      hover="true"
      small="true"
      class="table-mobile-view mb-3"
      [ngClass]="{border: !isView}">
      <thead>
        <tr>
          <th width="25"></th>
          <th class="w-50 font-weight-bold">Module</th>
          <th class="font-weight-bold">Description</th>
        </tr>
      </thead>
      <tbody mdbTableCol *ngFor="let permission of permissionList" class="bg-white">
        <tr [attr.data-test-id]="'permission-row'">
          <td (click)="permissionCollapsed(permission)">
            <button
              [attr.data-test-id]="'permission-button-expand'"
              type="button"
              class="btn btn-link p-0"
              mdbWavesEffect
              *ngIf="permission.collapsed">
              <i class="icofont-minus-circle icofont-lg text-info"></i>
            </button>
            <button
              [attr.data-test-id]="'permission-button-collapse'"
              type="button"
              class="btn btn-link p-0"
              mdbWavesEffect
              *ngIf="!permission.collapsed">
              <i class="icofont-plus-circle icofont-lg text-info"></i>
            </button>
          </td>
          <td [attr.data-test-id]="'permission-name'">
            <mdb-checkbox
              [(ngModel)]="
                !nonWritableModules.includes(permission.code)
                  ? permission.readAccess && permission.writeAccess
                  : permission.readAccess
              "
              (change)="onChecked($event, permission)"
              [disabled]="checkPermissionCheckBoxDisable(permission)"
              >{{ permission.name }}
            </mdb-checkbox>
          </td>
          <td [attr.data-test-id]="'permission-description'">
            {{ permission.description }}
          </td>
        </tr>
        <tr *ngIf="permission.collapsed">
          <td></td>
          <td class="pl-4" colspan="2">
            <div class="mb-2" *ngIf="!nonWritableModules.includes(permission.code)">
              <div class="checkbox-nested">
                <mdb-checkbox [(ngModel)]="permission.readAccess" [disabled]="isView"
                  >Read</mdb-checkbox
                >
              </div>
              <div class="checkbox-nested">
                <mdb-checkbox [(ngModel)]="permission.writeAccess" [disabled]="isView"
                  >Write</mdb-checkbox
                >
              </div>
            </div>
            <div
              *ngFor="let subModule of permission.subModules; let i = index"
              class="d-flex"
              [class.mb-3]="subModule.collapsed && i !== permission.subModules.length - 1">
              <div>
                <div class="d-flex items-center checkbox-nested">
                  <button
                    (click)="permissionCollapsed(subModule)"
                    [attr.data-test-id]="'permission-button-expand'"
                    type="button"
                    class="btn btn-link p-0 mr-2"
                    mdbWavesEffect
                    *ngIf="subModule.collapsed && subModule.subModules?.length">
                    <i class="icofont-minus-circle icofont-lg text-info"></i>
                  </button>
                  <button
                    (click)="permissionCollapsed(subModule)"
                    [attr.data-test-id]="'permission-button-collapse'"
                    type="button"
                    class="btn btn-link p-0 mr-2"
                    mdbWavesEffect
                    *ngIf="!subModule.collapsed && subModule.subModules?.length">
                    <i class="icofont-plus-circle icofont-lg text-info"></i>
                  </button>
                  <div style="width: 34px" [hidden]="!subModule.subModules?.length"></div>
                  <mdb-checkbox
                    [disabled]="isView"
                    [(ngModel)]="subModule.readAccess && subModule.writeAccess"
                    (change)="onChecked($event, subModule, permission.code)"
                    >{{ subModule.name }}</mdb-checkbox
                  >
                </div>
                <div *ngIf="subModule.collapsed" class="pl-4 ml-4 checkbox-nested">
                  <mdb-checkbox
                    *ngFor="let module of subModule.subModules"
                    [disabled]="isView"
                    [(ngModel)]="module.readAccess && module.writeAccess"
                    [disabled]="isView"
                    (change)="onChecked($event, subModule, permission.code)"
                    >{{ module.name }}</mdb-checkbox
                  >
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <app-loader [loader]="isLoading.permissionList"></app-loader>

    <div class="d-flex justify-content-between" *ngIf="!isView && !isLoading.permissionList">
      <button class="btn btn-light waves-light m-0" mdbWavesEffect (click)="navigateBack()">
        Cancel
      </button>
      <button
        class="btn btn-secondary waves-light m-0"
        mdbWavesEffect
        [disabled]="isLoading.save || permissionForm.invalid"
        (click)="savePermissions()">
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
          *ngIf="isLoading.save"></span>
        Save
      </button>
    </div>
  </div>
</section>
