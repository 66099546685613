<!-- Material form login -->
<div class="container-fluid h-100 holista-skin">
  <div class="row h-100">
    <div class="col col-sm-6 col-md-5 col-xl-4 my-auto mx-auto">
      <mdb-card class="login-content mx-auto mh-100">
        <mdb-card-header class="grey lighten-5 white-text text-center py-4">
          <!-- Logo -->

          <a [routerLink]="['/dashboard']" class="d-flex align-items-center justify-content-center">
            <img
              src="https://www.holistahealth.com/Holista/Holista_Logo.png"
              class="img-fluid flex-center w-50" />
          </a>
          <!--/. Logo -->
        </mdb-card-header>
        <!--Card content-->
        <mdb-card-body class="px-lg-5 pt-0">
          <div *ngIf="isPageInvalid || isCodeInvalid" class="text-center mt-1">
            <span><i class="fas fa-exclamation-triangle fw-2 text-danger mr-2"></i></span>
            <small class="text-danger">Expired or invalid link.</small>
          </div>
          <!-- Form -->
          <form [formGroup]="resetPasswordForm" class="text-center" style="color: #757575">
            <!-- Email -->
            <div class="md-form">
              <input
                data-error=" "
                type="email"
                formControlName="email"
                id="email"
                class="form-control"
                [readonly]="true"
                mdbInput
                [ngClass]="{
                  'is-invalid': submitted && resetPasswordForm.get('email').invalid
                }" />
              <label for="email">E-mail</label>
            </div>
            <!-- NPI -->
            <div class="md-form" *ngIf="isProvider && isSetPassword">
              <input
                data-error=" "
                formControlName="npi"
                type="text"
                id="npi"
                ngMinlength="10"
                numbersOnly
                maxlength="10"
                class="form-control"
                mdbInput
                [ngClass]="{
                  'is-invalid': submitted && resetPasswordForm.get('npi').invalid
                }" />
              <label for="npi">NPI</label>
            </div>
            <!-- Password -->
            <div class="md-form">
              <input
                data-error=" "
                type="password"
                formControlName="password"
                id="password"
                class="form-control"
                mdbInput
                [ngClass]="{
                  'is-invalid': submitted && resetPasswordForm.get('password').invalid
                }" />
              <label for="password">Password</label>
            </div>
            <!--Confirm Password -->
            <div class="md-form">
              <input
                data-error=" "
                type="password"
                formControlName="confirmPassword"
                id="confirmFormPassword"
                class="form-control"
                mdbInput
                [ngClass]="{
                  'is-invalid': submitted && resetPasswordForm.get('confirmPassword').invalid
                }" />
              <label for="confirmFormPassword"> Confirm Password</label>
            </div>
            <p>Rules to follow while setting passwords</p>
            <ul class="fa-ul text-left">
              <li>
                <span class="fa-li"
                  ><i
                    [ngClass]="
                      errorMessage === 'Password does not match pattern.' ||
                      resetPasswordForm.controls.password.errors?.pattern
                        ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                        : 'fa fa-info-circle fw-2 text-info mr-2'
                    "></i
                ></span>
                <small
                  [ngClass]="
                    errorMessage === 'Password does not match pattern.' ||
                    resetPasswordForm.controls.password.errors?.pattern
                      ? 'text-danger'
                      : ''
                  "
                  >Password must be at least 8 characters including at least one Uppercase,
                  Lowercase, Special character i.e #@! and a number. Spaces are not allowed in
                  Password.</small
                >
              </li>
              <li>
                <span class="fa-li"
                  ><i
                    [ngClass]="
                      errorMessage === 'Contains personal info'
                        ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                        : 'fa fa-info-circle fw-2 text-info mr-2'
                    "></i
                ></span>
                <small [ngClass]="errorMessage === 'Contains personal info' ? 'text-danger' : ''"
                  >Password should not include personal information, such as name or birth
                  date.</small
                >
              </li>
              <li>
                <span class="fa-li"
                  ><i
                    [ngClass]="
                      errorMessage === 'Password combination contains banned word.'
                        ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                        : 'fa fa-info-circle fw-2 text-info mr-2'
                    "></i
                ></span>
                <small
                  [ngClass]="
                    errorMessage === 'Password combination contains banned word.'
                      ? 'text-danger'
                      : ''
                  "
                  >Password should not include global banned password.</small
                >
              </li>
              <li>
                <span class="fa-li"
                  ><i
                    [ngClass]="
                      errorMessage === 'Password has already been used previously.'
                        ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                        : 'fa fa-info-circle fw-2 text-info mr-2'
                    "></i
                ></span>
                <small
                  [ngClass]="
                    errorMessage === 'Password has already been used previously.'
                      ? 'text-danger'
                      : ''
                  "
                  >New password cannot be the same as any of the previous 6 passwords.</small
                >
              </li>
              <li *ngIf="errorMessage === 'Incorrect Old Password'">
                <span class="fa-li"
                  ><i class="fas fa-exclamation-triangle fw-2 text-danger mr-2"></i
                ></span>
                <small class="text-danger">Incorrect Old Password.</small>
              </li>
              <li *ngIf="resetPasswordForm.controls.confirmPassword.errors?.MatchPassword">
                <span class="fa-li"
                  ><i class="fas fa-exclamation-triangle fw-2 text-danger mr-2"></i
                ></span>
                <small class="text-danger">New Password and Confirm Password does not match.</small>
              </li>
            </ul>
            <!-- Sign in button -->
            <button
              *ngIf="isSetPassword"
              class="btn btn-secondary btn-block my-4 waves-effect"
              mdbWavesEffect
              type="submit"
              (click)="setPassword()"
              [disabled]="submit_enabled || isPageInvalid || isCodeInvalid">
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="submit_enabled"></span>
              <i class="icofont-login" *ngIf="!submit_enabled"></i>
              Set Password
            </button>
            <button
              class="btn btn-secondary btn-block my-4 waves-effect"
              mdbWavesEffect
              type="button"
              *ngIf="!isSetPassword"
              (click)="resetPassword()"
              [disabled]="submit_enabled || isPageInvalid || isCodeInvalid">
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="submit_enabled"></span>
              <i class="icofont-login" *ngIf="!submit_enabled"></i> Reset password
            </button>
            <a href="javascript:void(0)" [routerLink]="['/login']">
              <i class="icofont-arrow-left"></i> Go back to Login</a
            >
          </form>
          <!-- Form -->
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>
<!-- Material form login -->
