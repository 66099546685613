export class Role {
    id: number;
    name: string;
    code: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
    updatedBy: string;
    createdBy: string;
    user: NameDetail;
}

class NameDetail {
    firstName: string;
    middleName: string;
    lastName: string
}