export class ExportData {
    exportList: any;
    headers: string[];
    excelName: string;
    tableTitle?: string;
    columnsWidth?: number[];
    summaryData?: SummaryData;
    dateType?: string;
    date?: ReportDate;
    options?: {
        action: string;
        customFormatter?: string;
    };
    fileName?: string;
}

export class SummaryData {
    headers: string[];
    data: any = {};
    client: string;
    date: any;
}

export class ReportDate {
    startDate: string;
    endDate: string;
}

export class PathwayExportData {
    name: string;
    description: string;
    milestones: PathwayReportMilestone[];
}

export class PathwayReportMilestone {
    id: number;
    name: string;
    description: string;
    task: PathwayReportTask[];
}

export class PathwayReportMedia {
    text: string;
    hyperlink: string;
    tooltip?: string;
}
export class PathwayReportTask extends PathwayReportMedia {
    name: string;
    description: string;
    taskType: string;
}
