<!-- new design for Episode list  start here-->
<h6 class="font-weight-500">Episode Status</h6>
<div class="">
  <mdb-accordion class="accordion-block" [multiple]="false">
    <!-- Preliminary -->
    <mdb-accordion-item id="preliminary">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Preliminary
          <div>
            <div class="chip chip-rounded alert preliminary m-0">
              {{ Episodes?.preliminaryEpisodes?.length }}
            </div>
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="Episodes.preliminaryEpisodes.length === 0">
          <h6 class="text-center mt-4">No Episodes Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin">
          <div class="d-flex justify-content-end" *ngIf="Episodes.preliminaryEpisodes.length">
            <i
              class="fas fa-lg fa-caret-up is-in-review pointer-cursor m-1"
              *ngIf="sortingType.preliminaryEpisodes === 'asc'"
              (click)="sortEpisodes('desc', 'preliminaryEpisodes')"></i>
            <i
              class="fas fa-lg fa-caret-down is-in-review pointer-cursor m-1"
              *ngIf="sortingType.preliminaryEpisodes === 'desc'"
              (click)="sortEpisodes('asc', 'preliminaryEpisodes')"></i>
          </div>
          <mdb-card
            class="pointer-cursor white"
            *ngFor="let episode of Episodes.preliminaryEpisodes"
            (click)="directToEpisodeDetail(episode)">
            <mdb-card-body class="p-3">
              <mdb-card-title>
                <h6>{{ episode.name }}</h6>
              </mdb-card-title>
              <mdb-card-text>
                <span>
                  <p class="font-weight-400">
                    Patient - {{ episode?.patient?.fullName || episode?.claimPatient?.fullName }}
                  </p>
                  <p class="font-weight-400">Care Coordinator - {{ episode?.cc?.fullName }}</p>
                  <p class="font-weight-400">Engagement Specialist - {{ episode?.es?.fullName }}</p>
                </span>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>

    <!-- Confirmed -->
    <mdb-accordion-item id="confirmed">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Confirmed
          <div class="chip chip-rounded alert confirmed m-0">
            {{ Episodes?.confirmedEpisodes?.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="Episodes.confirmedEpisodes.length === 0">
          <h6 class="text-center mt-4">No Episodes Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin">
          <div class="d-flex justify-content-end" *ngIf="Episodes.confirmedEpisodes.length">
            <i
              class="fas fa-lg fa-caret-up is-in-review pointer-cursor m-1"
              *ngIf="sortingType.confirmedEpisodes === 'asc'"
              (click)="sortEpisodes('desc', 'confirmedEpisodes')"></i>
            <i
              class="fas fa-lg fa-caret-down is-in-review pointer-cursor m-1"
              *ngIf="sortingType.confirmedEpisodes === 'desc'"
              (click)="sortEpisodes('asc', 'confirmedEpisodes')"></i>
          </div>
          <mdb-card
            class="pointer-cursor white"
            *ngFor="let episode of Episodes.confirmedEpisodes"
            (click)="directToEpisodeDetail(episode)">
            <mdb-card-body class="p-3">
              <mdb-card-title>
                <h6>{{ episode.name }}</h6>
              </mdb-card-title>
              <mdb-card-text>
                <span>
                  <p class="font-weight-400">
                    Patient - {{ episode?.patient?.fullName || episode?.claimPatient?.fullName }}
                  </p>
                  <p class="font-weight-400">Care Coordinator - {{ episode?.cc?.fullName }}</p>
                  <p class="font-weight-400">Engagement Specialist - {{ episode?.es?.fullName }}</p>
                </span>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>

    <!-- Completed -->
    <mdb-accordion-item id="completed">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Completed
          <div class="chip chip-rounded alert completed m-0">
            {{ Episodes?.completedEpisodes?.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="Episodes.completedEpisodes.length === 0">
          <h6 class="text-center mt-4">No Episodes Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin">
          <div class="d-flex justify-content-end" *ngIf="Episodes.completedEpisodes.length">
            <i
              class="fas fa-lg fa-caret-up is-in-review pointer-cursor m-1"
              *ngIf="sortingType.completedEpisodes === 'asc'"
              (click)="sortEpisodes('desc', 'completedEpisodes')"></i>
            <i
              class="fas fa-lg fa-caret-down is-in-review pointer-cursor m-1"
              *ngIf="sortingType.completedEpisodes === 'desc'"
              (click)="sortEpisodes('asc', 'completedEpisodes')"></i>
          </div>
          <mdb-card
            class="pointer-cursor white"
            *ngFor="let episode of Episodes.completedEpisodes"
            (click)="directToEpisodeDetail(episode)">
            <mdb-card-body class="p-3">
              <mdb-card-title>
                <h6>{{ episode.name }}</h6>
              </mdb-card-title>
              <mdb-card-text>
                <span>
                  <p class="font-weight-400">
                    Patient - {{ episode?.patient?.fullName || episode?.claimPatient?.fullName }}
                  </p>
                  <p class="font-weight-400">Care Coordinator - {{ episode?.cc?.fullName }}</p>
                  <p class="font-weight-400">Engagement Specialist - {{ episode?.es?.fullName }}</p>
                </span>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>

    <!-- Closed -->
    <mdb-accordion-item id="closed">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Closed
          <div class="chip chip-rounded alert closed m-0">
            {{ Episodes?.closedEpisodes?.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="Episodes.closedEpisodes.length === 0">
          <h6 class="text-center mt-4">No Episodes Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin">
          <div class="d-flex justify-content-end" *ngIf="Episodes.closedEpisodes.length">
            <i
              class="fas fa-lg fa-caret-up is-in-review pointer-cursor m-1"
              *ngIf="sortingType.closedEpisodes === 'asc'"
              (click)="sortEpisodes('desc', 'closedEpisodes')"></i>
            <i
              class="fas fa-lg fa-caret-down is-in-review pointer-cursor m-1"
              *ngIf="sortingType.closedEpisodes === 'desc'"
              (click)="sortEpisodes('asc', 'closedEpisodes')"></i>
          </div>
          <mdb-card
            class="pointer-cursor white"
            *ngFor="let episode of Episodes.closedEpisodes"
            (click)="directToEpisodeDetail(episode)">
            <mdb-card-body class="p-3">
              <mdb-card-title>
                <h6>{{ episode.name }}</h6>
              </mdb-card-title>
              <mdb-card-text>
                <span>
                  <p class="font-weight-400">
                    Patient - {{ episode?.patient?.fullName || episode?.claimPatient?.fullName }}
                  </p>
                  <p class="font-weight-400">Care Coordinator - {{ episode?.cc?.fullName }}</p>
                  <p class="font-weight-400">Engagement Specialist - {{ episode?.es?.fullName }}</p>
                </span>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>

    <!-- Cancelled -->
    <mdb-accordion-item id="cancelled">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Cancelled
          <div class="chip chip-rounded alert cancelled m-0">
            {{ Episodes?.cancelledEpisodes?.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="Episodes.cancelledEpisodes.length === 0">
          <h6 class="text-center mt-4">No Episodes Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin">
          <div class="d-flex justify-content-end" *ngIf="Episodes.cancelledEpisodes.length">
            <i
              class="fas fa-lg fa-caret-up is-in-review pointer-cursor m-1"
              *ngIf="sortingType.cancelledEpisodes === 'asc'"
              (click)="sortEpisodes('desc', 'cancelledEpisodes')"></i>
            <i
              class="fas fa-lg fa-caret-down is-in-review pointer-cursor m-1"
              *ngIf="sortingType.cancelledEpisodes === 'desc'"
              (click)="sortEpisodes('asc', 'cancelledEpisodes')"></i>
          </div>
          <mdb-card
            class="pointer-cursor white"
            *ngFor="let episode of Episodes.cancelledEpisodes"
            (click)="directToEpisodeDetail(episode)">
            <mdb-card-body class="p-3">
              <mdb-card-title>
                <h6>{{ episode.name }}</h6>
              </mdb-card-title>
              <mdb-card-text>
                <span>
                  <p class="font-weight-400">
                    Patient - {{ episode?.patient?.fullName || episode?.claimPatient?.fullName }}
                  </p>
                  <p class="font-weight-400">Care Coordinator - {{ episode?.cc?.fullName }}</p>
                  <p class="font-weight-400">Engagement Specialist - {{ episode?.es?.fullName }}</p>
                </span>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>
  </mdb-accordion>
  <!-- <div class="text-center p-3 grey lighten-4" *ngIf="episodes.length == 0">
    <i class="fa fa-3x fa-layer-group text-black-50"></i>
    <p>
      No Episodes available
    </p>
  </div> -->
</div>
<!-- new design for Episode list  end  here-->
