import { Routes } from "@angular/router";
import {
  DashboardComponent,
  ReportComponent,
  DocumentComponent,
  EpisodeComponent,
  PathwayComponent,
  PathwayMilestoneComponent,
  UserComponent,
  EpisodeMilestoneComponent,
  MyProfileComponent,
  LayoutChangepasswordComponent,
  RolePermissionsComponent,
  LogoutComponent,
  ProviderComponent,
  BundleComponent,
  BundleCostComponent,
  ActivityLogComponent,
  PatientComponent,
  EpisodeOfCareComponent,
  ClaimsComponent,
  JournalComponent,
  // LedgerComponent,
  ClaimDetailComponent,
  PaymentComponent,
  OutgoingClaimsComponent,
  ClaimsEdiComponent,
  IncomingClaimsEdiComponent,
  NetworkComponent,
  ClientComponent,
  PurchaserComponent,
  CommunicationsComponent,
  NewClaimsDashboardComponent,
  ReceivedPaymentComponent,
  PaidPaymentComponent,
  RequestEocComponent,
  PayeesComponent,
  AddEditRolePermissionComponent,
} from '../components';
import { NonPlatformUserGuard, RoleGuard } from '../guards';
import { EocGuard } from "../guards/requestEoc.guard";

export const RouteSecure: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard/claim', component: DashboardComponent },
  {
    path: 'documents', component: DocumentComponent, canActivate: [RoleGuard], data: {
      condition: 'DM',
      accessType: null,
    }
  },
  {
    path: 'episodes', component: EpisodeComponent, canActivate: [RoleGuard], data: {
      condition: 'EM',
      accessType: null,
    }
  },
  {
    path: 'episodes/:episodeId/milestone', component: EpisodeMilestoneComponent, canActivate: [RoleGuard], data: {
      condition: 'EM',
      accessType: null,
    }
  },
  {
    path: 'request-eoc', component: RequestEocComponent, canActivate: [EocGuard], data: {
      condition: 'EM',
      accessType: null,
    }
  },
  {
    path: 'episodeofcare', component: EpisodeOfCareComponent, canActivate: [RoleGuard], data: {
      condition: 'EOCM',
      accessType: null,
    }
  },
  {
    path: 'pathway', component: PathwayComponent, canActivate: [RoleGuard], data: {
      condition: 'PM',
      accessType: null,
    }
  },
  {
    path: 'pathway/:pathwayId/milestone', component: PathwayMilestoneComponent, canActivate: [RoleGuard], data: {
      condition: 'PM',
      accessType: null,
    }
  },
  {
    path: 'rolepermissions', component: RolePermissionsComponent, canActivate: [RoleGuard], data: {
      condition: 'RM',
      accessType: null,
    }
  },
  {
    path: 'rolepermission/add-edit', component: AddEditRolePermissionComponent, canActivate: [RoleGuard], data: {
      condition: 'RM',
      accessType: null,
    }
  },
  {
    path: 'users', component: UserComponent, canActivate: [RoleGuard], data: {
      condition: 'UM',
      accessType: null,
    }
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: 'patient', component: PatientComponent, canActivate: [RoleGuard], data: {
      condition: 'MM',
      accessType: null,
    },

  },
  {
    path: 'reports', component: ReportComponent, canActivate: [RoleGuard], data: {
      condition: 'RPM',
      accessType: null,
    }
  },
  {
    path: "provider", component: ProviderComponent, canActivate: [RoleGuard], data: {
      condition: 'PRM',
      accessType: null,
    }
  },
  {
    path: "bundle-cost", component: BundleCostComponent, canActivate: [RoleGuard], data: {
      condition: 'BCM',
      accessType: null,
    }
  },
  {
    path: "bundle", component: BundleComponent, canActivate: [RoleGuard], data: {
      condition: 'SBM',
      accessType: null,
    }
  },
  {
    path: "activity-log", component: ActivityLogComponent, canActivate: [RoleGuard], data: {
      condition: 'LM',
      accessType: null,
    }
  },
  {
    path: "claims", component: ClaimsComponent, canActivate: [RoleGuard], data: {
      condition: 'CLM',
      accessType: null,
    }
  },
  {
    path: 'claims/:id', component: ClaimDetailComponent, canActivate: [RoleGuard], data: {
      condition: 'CLM',
      accessType: null,
    }
  },
  {
    path: "claims/:id/edi/:isApproved", component: IncomingClaimsEdiComponent, canActivate: [RoleGuard], data: {
      condition: 'CLM',
      accessType: null,
    }
  },
  {
    path: "claims/:id/edi/:isApproved/:paymentId", component: IncomingClaimsEdiComponent, canActivate: [RoleGuard], data: {
      condition: 'CLM',
      accessType: null,
    }
  },
  {
    path: "outgoing-claims", component: OutgoingClaimsComponent, canActivate: [RoleGuard], data: {
      condition: 'OCLM',
      accessType: null,
    }
  },
  {
    path: 'outgoing-claims/:id', component: ClaimDetailComponent, canActivate: [RoleGuard], data: {
      condition: 'OCLM',
      accessType: null,
    }
  },
  {
    path: "outgoing-claims/:id/edi/:isApproved", component: ClaimsEdiComponent, canActivate: [RoleGuard], data: {
      condition: 'OCLM',
      accessType: null,
    }
  },
  {
    path: "journal", component: JournalComponent, canActivate: [RoleGuard], data: {
      condition: 'JM',
      accessType: null,
    }
  },
  {
    path: 'payment', component: PaymentComponent, canActivate: [RoleGuard], data: {
      condition: 'PRM',
      accessType: null,
    }
  },
  {
    path: 'payment/create/paid', component: PaidPaymentComponent, canActivate: [RoleGuard], data: {
      condition: 'PRM',
      accessType: null,
    }
  },
  {
    path: 'payment/edit/paid/:paymentId', component: PaidPaymentComponent, canActivate: [RoleGuard], data: {
      condition: 'PRM',
      accessType: null,
    }
  },
  {
    path: 'payment/view/paid/:paymentId', component: PaidPaymentComponent, canActivate: [RoleGuard], data: {
      condition: 'PRM',
      accessType: null,
    }
  },
  {
    path: 'payment/create/received', component: ReceivedPaymentComponent, canActivate: [RoleGuard], data: {
      condition: 'PRM',
      accessType: null,
    }
  },
  {
    path: 'payment/view/received/:paymentId', component: ReceivedPaymentComponent, canActivate: [RoleGuard], data: {
      condition: 'PRM',
      accessType: null,
    }
  },
  // {
  //   path: "ledger", component: LedgerComponent, canActivate: [RoleGuard], data: {
  //     condition: 'LM',
  //     accessType: null,
  //   }
  // },
  {
    path: "purchaser", component: PurchaserComponent, canActivate: [RoleGuard], data: {
      condition: 'PURM',
      accessType: null,
    }
  },
  {
    path: "network", component: NetworkComponent, canActivate: [RoleGuard], data: {
      condition: 'NTW',
      accessType: null,
    }
  },
  {
    path: "client", component: ClientComponent, canActivate: [RoleGuard], data: {
      condition: 'CLIM',
      accessType: null,
    }
  },
  {
    path: "communications", component: CommunicationsComponent, canActivate: [RoleGuard], canDeactivate: [NonPlatformUserGuard], data: {
      condition: 'COMMS',
      accessType: null,
    }
  },
  {
    path: 'payees', component: PayeesComponent, canActivate: [RoleGuard], data: {
      condition: 'PAM',
      accessType: null,
    }
  },
  { path: "change-password", component: LayoutChangepasswordComponent },
  { path: "my-profile", component: MyProfileComponent },
  { path: '', component: DashboardComponent }
];
