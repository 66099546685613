import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClientService
  ) { }

  getAdminDashboard(userId) {
    return this.http.get(`${APICONSTANTS.EPISODE}${APICONSTANTS.DASHBOARD_EPISODES}/${userId}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getAdminTask(userId) {
    return this.http.get(`${APICONSTANTS.EPISODE}/dashboard-tasks/${userId}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getAll() {
    return this.http.get(`/user/all`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  // getDashboard() {
  //   return this.http.get(`${APICONSTANTS.USER}${APICONSTANTS.DASHBOARD}`)
  //     .pipe(map((res: any) => {
  //       return res.data;
  //     }));
  // }

  getDashboardPatients() {
    return this.http.get(`${APICONSTANTS.USER}${APICONSTANTS.DASHBOARD}${APICONSTANTS.PATIENTS}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getDashboard(id) {
    return this.http.get(`${APICONSTANTS.EPISODE}${APICONSTANTS.DASHBOARD}/${id}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getPatientEngagementTasks(id, roleCode) {
    return this.http.get(`${APICONSTANTS.EPISODE}${APICONSTANTS.MILESTONE}/patient-engagement-tasks?userId=${id}&roleCode=${roleCode}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

}
