import { Injectable } from "@angular/core";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class ChartUtils {

  displayHorizontalLine(text, goalValue, additionalText?) {
    return [{
      afterDatasetsDraw: function (chart) {
        const { ctx, chartArea: { top, bottom, left, right }, scales } = chart;
        const yAxis = scales["y-axis-0"];
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = 'gray';
        ctx.lineWidth = 1.5;
        const yPosition = yAxis.getPixelForValue(goalValue);
        ctx.moveTo(left, yPosition);
        ctx.lineTo(right, yPosition);
        ctx.stroke();
        // Label Text
        ctx.fillStyle = 'black';
        ctx.font = '12px Arial';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${text} ${goalValue} ${additionalText ? additionalText : ''}`, (left + 20), (yPosition - 10));
        ctx.restore();
      }
    }]
  };

  public lineBreakForChartTicks(maxCharsPerLine, splitWith) {
    return function (value) {
      const words = value.split(splitWith);
      const maximumCharsPerLine = maxCharsPerLine;
      const lines = [];
      let currentLine = "";
      words.forEach((word) => {
        if (currentLine.length + word.length <= maximumCharsPerLine) {
          currentLine += word + " ";
        } else {
          lines.push(currentLine);
          currentLine = word + " ";
        }
      });
      lines.push(currentLine);
      return lines;
    }
  }

}