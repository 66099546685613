import { EPISODESTATUS } from '../constants';
import { Subject, Observable } from 'rxjs';
export class EpisodeFilter {
    episodeDropdownList = []
    filterBundles = []
    statuses = EPISODESTATUS
    filterProviders = []
    filterFacility = []
    companyList = []
    tempUserList = []
    clientList: any = []
    filter_submitted: boolean = false;
    purchaserList = []
    networkList = []
}

export class FilterUserSearch {
    user_filter_text = new Subject();
    user_filter_results: Observable<any>;
}