import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { MdbBtnDirective } from 'ng-uikit-pro-standard';
import { EpisodeTaskService } from 'src/app/services/episode-task.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { AccessType } from 'src/app/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-episode-todo',
  templateUrl: './episode-todo.component.html',
  styleUrls: ['./episode-todo.component.scss']
})
export class EpisodeTodoComponent implements OnInit {
  taskToBeDelete: any
  dependentTask: any
  todoUuid: any;
  showReasonOption: boolean = false;
  reasonList = [];
  episodeId: number;

  @Output() editTask = new EventEmitter<any>();
  @Input() public taskTodo;
  @Input() public optionId;
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.todoUuid) this.getTaskToDo(this.todoUuid)
    }
  }
  @Input() public days_difference;

  @Output() public btns = new EventEmitter<any>();
  @Output() public deletedTask = new EventEmitter<any>();
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;
  @Input() user: any;

  @Input() set taskTodoUuid(taskTodoUuid) {
    if (taskTodoUuid) {
      this.todoUuid = taskTodoUuid
      this.getTaskToDo(taskTodoUuid);
    }
  }

  constructor(
    private episodeTaskService: EpisodeTaskService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public utilityAccess: AccessType,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getReasonList();
    if (this._activatedRoute.snapshot.params.episodeId) {
      this.episodeId = parseInt(this._activatedRoute.snapshot.params.episodeId);
    }
  }
  ngAfterViewInit() {
    const buttons = this.buttons.toArray();
    setTimeout(() => {
      this.btns.emit(buttons);
    });
  }
  getTaskToDo(uuid) {
    this.taskTodo = {};
    if (this.dependentTask && this.dependentTask.taskTodos) {
      this.dependentTask.taskTodos.filter(x => {
        if (x.uuid == uuid) {
          x.form = 'todo';
          x.field = 'todo'
          x.optionId = this.optionId;
          this.taskTodo = x;
        }
      })
    }
  }

  editTaskHandler(todo) {
    this.editTask.emit(todo);
  }

  deleteTaskHandler(todo) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      todo['source'] = 'Episode'
      todo['type'] = 'Todo'
      todo['displayName'] = todo.name
      this.taskToBeDelete = todo
    }
    else this.toastr.warning("Access Denied", "Warning");
  }
  cancelDelete(e) {
    this.taskToBeDelete = e
  }
  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }

  updateTask(event, task) {
    let body = {
      id: task.id,
      type: 'todo',
      messageTitle: null,
      episodeId: this.route.snapshot.params.episodeId
    }

    this.episodeTaskService
      .updateTaskStatus(body)
      .subscribe(
        res => {
          this.toastr.success("Action Item completed successfully.", "Success");
          this.taskTodo.status = 'COMPLETED';
        },
        error => {
          console.log(error);
        }
      );
  }

  addReason() {
    this.showReasonOption = true;
  }

  getReasonList() {
    this.reasonList = [];
    this.episodeTaskService.getTaskCompletionReasons()
      .subscribe((res: any) => {
        res.map(x => {
          let data = { label: x.reason, value: x.reason };
          this.reasonList = [...this.reasonList, data];
        })
      }, (error) => {
        console.log("Error getting Task Completion Reason Lists", error);
      })
  }

  selectReason(event, task) {
    let body = {
      id: task.id,
      type: 'todo',
      messageTitle: null,
      episodeId: this.route.snapshot.params.episodeId,
      completionReason: event.label
    }
    this.episodeTaskService.updateTaskStatus(body)
      .subscribe((res: any) => {
        this.toastr.success("Reason added successfully.", "Success");
        this.taskTodo.completionReason = res.data.completionReason;
      }, (error) => {
        console.log("Error submitting the reason", error);
      })
  }
}
