import { ChartDatasets, DiscrepenciesFinancialByEpisodeTypeItem } from './../../../../models/model.reports';
import { Component, OnInit, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ChartType } from 'chart.js';
import 'chartjs-plugin-datalabels';
import { CHART_OPTIONS } from '../../../../constants/claimsReconcilitationSummary.constants'
import { BehaviorSubject } from 'rxjs';
import { CLAIMS_RECONCILIATION_VOLUME_BY_EPISODE_TYPE_DATA, MessageConstants, NO_DATA_IN_REPORT } from 'src/app/constants';

@Component({
  selector: 'app-financial-by-episode-type',
  templateUrl: './financial-by-episode-type.component.html',
  styleUrls: ['./financial-by-episode-type.component.scss']
})
export class FinancialByEpisodeTypeComponent implements OnInit {
  data: ChartDatasets[] = [];
  labels: string[] = [];
  tableItems$ = new BehaviorSubject(null);
  tableTotals: DiscrepenciesFinancialByEpisodeTypeItem;
  chartType: ChartType | string = '';
  chartOptions = JSON.parse(JSON.stringify(CHART_OPTIONS));
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT)

  @Input() set financialEpisodeTypes(financialEpisodeTypes) {
    if (financialEpisodeTypes !== null && financialEpisodeTypes.type) {
      const { chartType, labels } = financialEpisodeTypes.chart;
      const { items, total } = financialEpisodeTypes.referenceData;
      this.chartOptions.legend.display = true;
      this.chartType = chartType;
      this.labels = labels;
      this.data = financialEpisodeTypes.chart.datasets;

      this.tableItems$.next({
        data: items.concat([total]).map((item) => {
          item.totalJoint = this._currencyPipe.transform(item.totalJoint)
          item.maternity = this._currencyPipe.transform(item.maternity)
          item.total = this._currencyPipe.transform(item.total)
          return item;
        }),
        excelName: '',
        tableTitle: '',
        tableHeaders: CLAIMS_RECONCILIATION_VOLUME_BY_EPISODE_TYPE_DATA.TABLE_HEADERS,
        columnsWidth: [],
        dateType: '',
        date: {},
      });
      this.chartOptions.plugins.datalabels.formatter = function (value, context) {
        return new CurrencyPipe('en-US').transform(value, 'USD', 'symbol', '1.2-2');
      }
      this.tableTotals = total;
    } else {
      this.data = [];
    }
  }

  constructor(
    private _currencyPipe: CurrencyPipe,
    private _messageConstants: MessageConstants
  ) { }

  ngOnInit() {
  }

}
