import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Storage } from './storageUtility';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { HOLISTA_CONSTANT } from '../constants';

@Injectable({
  providedIn: 'root'
})

export class HolistaUtils {
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private _storage: Storage,
  ) {

  }
  public customRedirect(link) {
    if (link) {
      if (link.includes('http://')) {
        // link.replace('http://', 'https://');
      } else if (link.includes('https://')) {

      } else {
        link = `https://${link}`;
      }
      window.open(link, '_blank');
    }
  }

  public properCase(str) {
    if (str) {
      const splitStr = str.toLowerCase().split(' ').map(x => {
        return x.charAt(0).toUpperCase() + x.substring(1);
      });
      return splitStr.join(' ');
    }
  }

  public arrayToString(array, decorator?) {
    if (array && array.length > 0) {
      let string = '';
      array.map((item, i) => {
        string += `${i > 0 ? decorator + ' ' : ''}${item}`;
      });
      return string;
    }
  }

  public randomShuffled(arr, n) {
    const shuffledArray = [...arr].sort(() => 0.5 - Math.random());
    const selected = shuffledArray.slice(0, n);
    return { shuffledArray: shuffledArray, selected: selected };
  }

  public removeDuplicates(array, field) {
    const obj = {};
    for (let i = 0, len = array.length; i < len; i++) {
      obj[array[i][field]] = array[i];
    }
    const new_array = new Array();
    for (const key in obj) {
      new_array.push(obj[key]);
    }
    return new_array;
  }

  public sortBy(arr: any, sortBy: string): any {
    arr = arr.sort(function (a: any, b: any) {
      if (a[sortBy] < b[sortBy]) {
        return -1;
      } else if (a[sortBy] > b[sortBy]) {
        return 1;
      } else {
        return 0;
      }
    });
    return arr;
  }

  public getFullName(data: any, userType?: string): any {
    if (data) {
      switch (userType) {
        case 'patient':
          return `${data.patientFirstName ? data.patientFirstName : ''}${data.patientMiddleName ? ' ' + data.patientMiddleName : ''}${data.patientLastName ? ' ' + data.patientLastName : ''}`;
        case 'subscriber':
          return `${data.subscriberFirstName ? data.subscriberFirstName : ''}${data.subscriberMiddleName ? ' ' + data.subscriberMiddleName : ''}${data.subscriberLastName ? ' ' + data.subscriberLastName : ''}`;
        default:
          return typeof data === 'string' ? this.toTitleCase(data) : `${data.firstName ? data.firstName : ''}${data.middleName ? ' ' + data.middleName : ''}${data.lastName ? ' ' + data.lastName : ''}`;
      }
    }
  }

  public sortByDate(arr: any, sortBy: string): any {
    arr = arr.filter(x => x[sortBy]);
    arr.sort((a, b) => ((new Date(a.showDate).getTime() - new Date(b.showDate).getTime())))
    return arr;
  }

  toArrayBuffer(buf) {
    const ab = new ArrayBuffer(buf.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
      view[i] = buf[i];
    }
    return ab;
  }

  public replaceLowerCase(str, from, to, replaceForeign?) {
    const replaceFrom = new RegExp(from, 'g');
    let newStr = str
      .replace(replaceFrom, to)
      .toLowerCase();
    if (replaceForeign) {
      newStr = newStr.replace(/[^a-zA-Z 0-9]+/g, '');
    }
    return newStr;
  }

  destructuringSwap(list, iA, iB) {
    [list[iA], list[iB]] = [list[iB], list[iA]];
    return list;
  }

  toTitleCase(data: string) {
    return data.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  getUserRoleInfo(roles) {
    const user: any = { primaryRole: '', secondaryRole: '', roleCode: '' }
    roles.map(role => {
      if (role.isPrimary) user.primaryRole = role.roleCode
      if (!role.isPrimary) user.secondaryRole = role.roleCode
      if (role.isActiveRole) user.roleCode = role.roleCode
    })
    return user
  }

  /**
  * eliminates duplicate values from an array
  * @param arr 
  * @param key 
  * @param nestedKeys 
  * @returns 
  */
  getUniqueList(arr, key: string, nestedKeys?: Array<string>) {
    const getUniqueData = (item: object) => {
      const uniqueData = [];
      nestedKeys.forEach(x => {
        uniqueData.push(item[key][x])
      })
      return uniqueData;
    }
    return [...new Map(arr.map(item => [JSON.stringify(nestedKeys ? (getUniqueData(item)) : item[key]), item])).values()]
  }

  /**
   * deep clones array or object
   * @param obj 
   * @returns 
   */
  // NOTE: This does not work for objects with function or circular dependency. Perhaps use Ramda or lodash?
  // structuredClone can be used for Node v17 or higher
  deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  titleCasing(value, symbol?): any {
    if (symbol) {
      return value.split(symbol).map((word) => this.toTitleCase(word)).join(symbol);
    }
    return this.toTitleCase(value);
  }

  /**
   * Generate Year Option List
   * @param startYear
   * @param endYear
   * @returns array of object(s)
   */
  getYearOptionList(startYear: number, endYear: number) {
    let yearList = [];
    for (let i = startYear; i <= endYear; i++) {
      yearList.push({ label: i.toString(), value: i });
    }
    return yearList;
  }

  // To maintain tab index history, specially for report component
  getActiveTab(options) {
    const activeTab = this._storage.get(options.storageType, options.storageKey);
    const isTabsIncluded = options.tabList.map((x) => x.name).includes(activeTab?.name);
    if (activeTab) {
      if (!isTabsIncluded && options.tabList.length) {
        this._storage.set(options.storageType, options.storageKey, { tabIndex: options.tabList[0].order, name: options.tabList[0].name })
        return 1;
      } else {
        return activeTab.tabIndex;
      }
    } else {
      if (options.tabList.length) {
        this._storage.set(options.storageType, options.storageKey, { tabIndex: options.tabList[0].order, name: options.tabList[0].name })
      }
      return 1;
    }
  }

  /**
   * removes validator from a form control
   * @param form 
   * @param control 
   */
  removeValidator(form: FormGroup, control: string) {
    form.get(control).clearValidators();
    form.get(control).updateValueAndValidity();
  }

  convertUndefinedToNull(obj) {
    const updatedObject = { ...obj };
    for (const key in updatedObject) {
      if (updatedObject.hasOwnProperty(key) && updatedObject[key] === undefined) {
        updatedObject[key] = null;
      }
    }
    return updatedObject;
  }

  removeNullOrUndefinedKeys(obj) {
    const result = {};
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined) {
        result[key] = obj[key];
      }
    }
    return result;
  }

  /**
  * Method to filter out empty values and return the formatted values
  * @returns 
  */
  getFormattedValues(formValues) {
    const filteredObject = Object.entries(formValues)
      .filter(([key, value]) => value)
      .reduce((acc, [key, value]) => {
        if (value instanceof Date) {
          acc[key] = moment(value).format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT);
        } else {
          acc[key] = Array.isArray(value) ? value.join(',') : value;
          !acc[key] && delete acc[key];
        };
        return acc;
      }, {});
    return filteredObject;
  }
}
