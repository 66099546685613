<div class="h-100" [ngClass]="theme + '-skin'">
  <div class="h-100 bg-img overflow-auto">
    <!-- Material form login -->
    <div class="container-fluid h-100">
      <div class="row h-100">
        <!--Grid column-->
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 order-1 order-md-2 login-content-wrapper d-flex align-items-center flex-column">
          <a href="/" class="logo mt-3 d-md-none"></a>
          <div class="mx-auto pt-sm-0 mt-5 my-md-auto wow w-75 w-xs-100 fadeInRight">
            <p *ngIf="theme !== 'oakleaf' && theme !== 'provider'" class="text-center p-0 mb-1 h5">
              {{ landingPage.phoneText1 }}
            </p>
            <p *ngIf="theme !== 'oakleaf' && theme !== 'provider'" class="text-center mb-4 h4">
              {{ landingPage.phoneText2 }}<span class="orange-text">{{ landingPage.phone }}</span>
            </p>
            <p *ngIf="theme === 'oakleaf'" class="text-center p-0 mb-1 h6">
              {{ landingPage.phoneText1 }}
            </p>
            <p *ngIf="theme == 'oakleaf'" class="text-center mb-4 h4">
              {{ landingPage.phoneText2 }}
            </p>
            <p *ngIf="theme == 'provider'" class="text-center p-0 mb-1">
              {{ landingPage.phoneText1 }}
            </p>
            <h3 *ngIf="theme == 'provider'" class="text-center text-white mb-4">
              {{ landingPage.phoneText2 }}<span>{{ landingPage.phone }}</span>
            </h3>
            <mdb-card
              *ngIf="!showBasicInfoPage && !showEocListPage && !showSuccessPage"
              class="login-content mx-auto my-auto wow fadeInRight"
              data-wow-delay="0.3s">
              <mdb-card-header class="grey lighten-5 white-text text-center py-3">
                <h5 class="mb-0" *ngIf="theme !== 'provider'">Login</h5>
                <h5 class="mb-0" *ngIf="theme === 'provider'">Login to Provider Portal</h5>
              </mdb-card-header>
              <!--Card content-->
              <mdb-card-body class="px-lg-3 pt-0">
                <!-- Form -->
                <form [formGroup]="loginForm">
                  <!-- Email -->
                  <div class="md-form form-sm">
                    <mdb-icon fas icon="user" class="prefix text-primary"></mdb-icon>
                    <input
                      data-error=" "
                      type="email"
                      formControlName="userName"
                      id="materialLoginFormEmail"
                      [attr.data-test-id]="'login-username'"
                      class="form-control"
                      mdbInput
                      [ngClass]="{
                        'is-invalid': submitted && loginForm.get('userName').invalid
                      }" />
                    <label class="test" for="materialLoginFormEmail">Username</label>
                  </div>

                  <!-- Password -->
                  <div class="md-form form-sm">
                    <mdb-icon fas icon="lock" class="prefix text-primary"></mdb-icon>

                    <input
                      data-error=" "
                      type="password"
                      formControlName="password"
                      id="materialLoginFormPassword"
                      [attr.data-test-id]="'login-password'"
                      class="form-control"
                      mdbInput
                      [ngClass]="{
                        'is-invalid': submitted && loginForm.get('password').invalid
                      }" />
                    <label for="materialLoginFormPassword">Password</label>
                  </div>

                  <div class="d-flex justify-content-between">
                    <div>
                      <!-- Remember me -->
                      <mdb-checkbox formControlName="rememberMe">Remember me</mdb-checkbox>
                    </div>
                    <div tabindex="0">
                      <!-- Forgot password -->
                      <a
                        data-toggle="modal"
                        data-target="#forgotPasswordModal"
                        (click)="forgotPasswordModal.show()"
                        mdbWavesEffect
                        class="blue-text"
                        >Forgot Password?</a
                      >
                    </div>
                  </div>
                  <!-- Sign in button -->
                  <button
                    id="btn-login"
                    [attr.data-test-id]="'signin-button'"
                    class="btn btn-secondary btn-block mt-4 ml-0 mr-0 waves-effect"
                    mdbWavesEffect
                    type="submit"
                    (click)="login()"
                    [disabled]="submit_enabled || isUserVerified">
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="submit_enabled"></span>
                    <i class="icofont-login" *ngIf="!submit_enabled"></i>
                    Sign in
                  </button>
                  <!--Provider Portal-->
                  <div *ngIf="theme === 'provider'" class="calltoaction mt-4">
                    <legend class="title-legend">
                      <h6 class="mb-3 text-center">Not a registered Provider?</h6>
                    </legend>
                    <p class="mb-3 text-center h5">Call us at {{ landingPage.phone }}</p>
                  </div>
                  <div *ngIf="hasEocViewPermission">
                    <div class="calltoaction mt-4">
                      <legend class="title-legend">
                        <h6 class="mb-3 text-center font-weight-bold">Not a member?</h6>
                      </legend>
                      <p class="mb-3 text-center">Learn more about starting an Episode of Care</p>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                      <button
                        type="button"
                        class="btn btn-secondary btn-outline-secondary border-1"
                        (click)="eocRequestInitiate()">
                        Get Started
                      </button>
                    </div>
                  </div>
                  <!-- Register -->
                  <!-- <p>
                  Not a member?
                  <a href="">Register</a>
                </p> -->
                </form>
                <!-- Form -->
              </mdb-card-body>
              <mdb-card-footer>
                <div class="text-center mt-0">
                  <p class="mb-0">
                    © {{ current_year }} {{ landingPage.copyrightText }}. All rights reserved.
                  </p>
                  <!-- <p class="mb-0">© {{current_year}} Novant Health. All rights reserved.</p> -->
                  <p class="mb-0">
                    <a
                      id="termsOfUse"
                      href="javascript:void(0)"
                      [routerLink]="['/terms-of-use']"
                      target="_blank"
                      ><small> Terms of Use |</small></a
                    >
                    <a
                      id="privacyPolicy"
                      href="javascript:void(0)"
                      [routerLink]="['/privacy-policy']"
                      target="_blank"
                      ><small> Privacy Policy </small></a
                    >
                  </p>
                </div>
              </mdb-card-footer>
            </mdb-card>

            <!-- Initiate EOC REQUEST FORM START-->

            <mdb-card
              *ngIf="
                hasEocViewPermission && (showBasicInfoPage || (showEocListPage && !showSuccessPage))
              "
              class="login-content mx-auto my-auto wow fadeInRight"
              data-wow-delay="0.3s">
              <mdb-card-header class="text-center py-3">
                <section class="mb-0">Request Episode of Care Information</section>
                <!-- <h5 class="mb-0" *ngIf="theme === 'provider'">Login to Provider Portal</h5> -->
              </mdb-card-header>
              <!--Card content-->
              <mdb-card-body
                *ngIf="!showEocListPage && showBasicInfoPage"
                class="px-lg-3 pt-0 card-overflow scrollbar scrollbar-primary thin">
                <!-- Form -->
                <form [formGroup]="eocRequestForm">
                  <!-- First Name -->
                  <div class="md-form form-sm m-0 mt-3">
                    <mdb-icon fas icon="user" class="prefix text-primary"></mdb-icon>
                    <input
                      type="email"
                      formControlName="firstName"
                      id="firstName"
                      class="form-control"
                      mdbInput
                      [ngClass]="{
                        'is-invalid':
                          eocRequestForm.get('firstName').hasError('required') &&
                          eocRequestForm.get('firstName').touched
                      }" />
                    <label for="firstName">First Name</label>
                  </div>

                  <!-- Last Name -->
                  <div class="md-form form-sm m-0 mt-3">
                    <span class="prefix text-primary"></span>
                    <input
                      type="text"
                      formControlName="lastName"
                      id="lastName"
                      class="form-control"
                      mdbInput
                      [ngClass]="{
                        'is-invalid':
                          eocRequestForm.get('lastName').hasError('required') &&
                          eocRequestForm.get('lastName').touched
                      }" />
                    <label for="lastName">Last Name</label>
                  </div>
                  <div class="md-form form-sm m-0 mt-3">
                    <mdb-icon fas icon="calendar" class="prefix text-primary"></mdb-icon>
                    <input
                      type="text"
                      id="dob"
                      class="form-control"
                      mdbInput
                      [textMask]="{mask: dateMask}"
                      formControlName="dob"
                      [ngClass]="{
                        'is-invalid':
                          eocRequestForm.get('dob').hasError('required') &&
                          eocRequestForm.get('dob').touched
                      }" />
                    <label for="dob">Date of Birth - mm/dd/yyyy </label>
                  </div>
                  <div class="subscriber-section md-form form-sm m-0 mt-3">
                    <mdb-icon fas icon="id-badge" class="prefix text-primary"></mdb-icon>
                    <input
                      type="text"
                      formControlName="subscriberId"
                      id="subscriberId"
                      class="form-control"
                      mdbInput />
                    <i
                      class="icofont-info-circle btn-link pointer-cursor ml-1 position-absolute end-0 top-1"
                      [mdbPopover]="subscriberIdPopOver"
                      placement="top"
                      triggers="mouseenter:mouseleave"></i>
                    <ng-template #subscriberIdPopOver>
                      <div>
                        Your subscriber ID is located on your medical insurance ID Card. Depending
                        on your insurer it may be labeled something similar to ID#, Subscriber ID,
                        or Member ID. Typically a combination of letters and numbers.
                      </div>
                    </ng-template>
                    <label for="subscriberId">Subscriber ID - Optional</label>
                  </div>
                  <div class="mt-3">
                    <p class="text-dark font-weight-bold">Contact Information</p>
                  </div>
                  <div class="md-form form-sm m-0 mt-2">
                    <mdb-icon fas icon="mobile-alt" class="prefix text-primary"></mdb-icon>
                    <input
                      type="text"
                      formControlName="phone"
                      id="materialLoginFormPassword"
                      class="form-control"
                      [textMask]="{mask: mask}"
                      mdbInput
                      [ngClass]="{
                        'is-invalid':
                          (eocRequestForm.get('phone').hasError('required') ||
                            eocRequestForm.get('phone').hasError('pattern')) &&
                          eocRequestForm.get('phone').touched
                      }" />
                    <div
                      *ngIf="
                        eocRequestForm.get('phone').hasError('pattern') &&
                        eocRequestForm.get('phone').touched
                      "
                      class="invalid-feedback"
                      style="padding-left: 9%">
                      Phone is invalid. Please enter a valid phone number.
                    </div>
                    <label for="materialLoginFormPassword">Phone</label>
                  </div>

                  <div class="md-form form-sm m-0 mt-3">
                    <i class="prefix far fa-envelope text-primary"></i>
                    <input
                      data-error=" "
                      type="text"
                      formControlName="email"
                      id="materialLoginFormPassword"
                      class="form-control"
                      mdbInput
                      [ngClass]="{
                        'is-invalid':
                          (eocRequestForm.get('email').hasError('required') ||
                            eocRequestForm.get('email').hasError('pattern')) &&
                          eocRequestForm.get('email').touched
                      }" />
                    <div
                      *ngIf="
                        eocRequestForm.get('email').hasError('pattern') &&
                        eocRequestForm.get('email').touched
                      "
                      class="invalid-feedback"
                      style="padding-left: 9%">
                      Email is invalid. Please enter a valid email.
                    </div>
                    <label for="materialLoginFormPassword">Email</label>
                  </div>
                  <div class="mb-2">
                    <p class="text-dark font-weight-bold">What is the best way to contact you?</p>
                  </div>
                  <!-- <div class="md-form form-sm mt-3"> -->
                  <div class="radio-block form-row">
                    <div>
                      <label class="form-check-label" for="filterUserActivity">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="filterUserActivity"
                          name="filterActivity"
                          [checked]="contactMedium && contactMedium == 'email'"
                          value="email"
                          (click)="onContactViaClick('email')"
                          [ngClass]="{
                            'is-invalid': true
                          }"
                          mdbInput />
                        <div class="box d-flex justify-content-center align-items-center p-3">
                          <i class="fas fa-envelope" style="font-size: initial"></i>
                          Email
                        </div>
                      </label>
                    </div>
                    <div>
                      <label class="form-check-label" for="filterTaskActivity">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="filterTaskActivity"
                          name="filterActivity"
                          [checked]="contactMedium && contactMedium == 'call'"
                          value="call"
                          (click)="onContactViaClick('call')"
                          [ngClass]="{
                            'is-invalid': true
                          }"
                          mdbInput />
                        <div class="box d-flex justify-content-center align-items-center p-3">
                          <i class="fas fa-mobile-alt" style="font-size: initial"></i>
                          Call
                        </div>
                      </label>
                    </div>
                  </div>
                  <p *ngIf="!hasContactVia" class="error-text">Please select an option.</p>
                  <!--Provider Portal-->
                  <div *ngIf="theme === 'provider'" class="calltoaction mt-4">
                    <legend class="title-legend">
                      <h6 class="mb-3 text-center">Not a registered Provider?</h6>
                    </legend>
                    <p class="mb-3 text-center h5">Call us at {{ landingPage.phone }}</p>
                  </div>
                </form>
                <hr />
                <div class="m-0 mt-3 d-flex justify-content-between align-items-center">
                  <button
                    class="btn btn btn-outline-gray ml-0 ml-0 waves-effect text-dark"
                    mdbWavesEffect
                    (click)="onBackClick()">
                    Back
                  </button>
                  <button
                    class="btn btn-secondary ml-0 mr-0 waves-effect"
                    mdbWavesEffect
                    (click)="openEOCList()">
                    Continue
                  </button>
                </div>
              </mdb-card-body>

              <!-- EOC LIST FORM START -->
              <mdb-card-body
                *ngIf="showEocListPage && !showBasicInfoPage"
                class="eoc-list-card px-lg-3 pt-0"
                style="min-height: 464px">
                <!-- Form -->
                <!-- Email -->
                <div class="pb-3 pt-3">
                  <b>Hello, {{ toTitleCase(eocRequestForm?.value?.firstName) }}</b>
                </div>
                <div>
                  <small class="font-weight-bold"> What services are you looking for? </small>
                </div>
                <div class="form-group has-search input-group mt-2 mb-2 w-50 w-100">
                  <span class="form-control-feedback left-0">
                    <i class="icofont-search-1"></i>
                  </span>
                  <input
                    type="text"
                    [(ngModel)]="searchKey"
                    class="form-control"
                    id="search"
                    placeholder="Search"
                    (ngModelChange)="onsearchKeyChange($event)" />
                  <div class="input-group-append" *ngIf="searchKey">
                    <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
                      <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
                    </button>
                  </div>
                </div>
                <div class="loader" *ngIf="eocLoader" style="height: 16rem">
                  <app-loader [loader]="eocLoader"></app-loader>
                </div>
                <div *ngIf="!eocLoader && eocListBackUp?.length">
                  <div
                    style="overflow-y: auto; height: 16rem"
                    class="scrollbar scrollbar-primary thin">
                    <div *ngFor="let eachEoc of eocListBackUp" class="mt-1 pr-2">
                      <div
                        class="d-flex justify-content-between align-items-center pointer-cursor py-2"
                        (click)="onEocChange(!eachEoc.checked, eachEoc)">
                        <small class="text-dark">{{ eachEoc.label }}</small>
                        <p class="circle pr-2" *ngIf="!eachEoc.checked">
                          <i class="far fa-circle text-gray"></i>
                        </p>
                        <p class="filled-circle pr-2" *ngIf="eachEoc.checked">
                          <i class="fas fa-check-circle text-primary"></i>
                        </p>
                      </div>
                      <hr style="margin: 5px 0" />
                    </div>
                  </div>
                  <div class="m-0 mt-2 d-flex justify-content-between align-items-center">
                    <button
                      id="btn"
                      class="btn btn-gray btn-outline-gray text-dark"
                      type="submit"
                      [disabled]="eocSubmitButtonDisabler"
                      (click)="onEocListBackClick()">
                      Back
                    </button>
                    <button
                      class="btn btn-secondary"
                      mdbWavesEffect
                      type="submit"
                      [disabled]="selectedEoc && !eocSubmitButtonDisabler ? false : true"
                      (click)="finalEocSubmit()">
                      Submit
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                        *ngIf="eocSubmitButtonDisabler"></span>
                    </button>
                  </div>
                  <div>
                    <small class="text-danger">
                      {{ duplicateMessage }}
                    </small>
                  </div>
                </div>
                <div *ngIf="!eocLoader && !eocListBackUp?.length">
                  <div style="font-size: 14px">No Results found</div>
                </div>
                <!--Provider Portal-->
                <div *ngIf="theme === 'provider'" class="calltoaction mt-4">
                  <legend class="title-legend">
                    <h6 class="mb-3 text-center">Not a registered Provider?</h6>
                  </legend>
                  <p class="mb-3 text-center h5">Call us at {{ landingPage.phone }}</p>
                </div>
              </mdb-card-body>

              <!-- EOC LIST FORM END -->
              <mdb-card-footer>
                <div class="text-center mt-0">
                  <p class="mb-0">
                    © {{ current_year }} {{ landingPage.copyrightText }}. All rights reserved.
                  </p>
                  <!-- <p class="mb-0">© {{current_year}} Novant Health. All rights reserved.</p> -->
                  <p class="mb-0">
                    <a
                      id="termsOfUse"
                      href="javascript:void(0)"
                      [routerLink]="['/terms-of-use']"
                      target="_blank"
                      ><small> Terms of Use |</small></a
                    >
                    <a
                      id="privacyPolicy"
                      href="javascript:void(0)"
                      [routerLink]="['/privacy-policy']"
                      target="_blank"
                      ><small> Privacy Policy </small></a
                    >
                  </p>
                </div>
              </mdb-card-footer>
            </mdb-card>

            <mdb-card
              *ngIf="hasEocViewPermission && showSuccessPage"
              class="login-content mx-auto my-auto wow fadeInRight"
              data-wow-delay="0.3s">
              <!--Card content-->
              <mdb-card-body class="container" style="padding: 50px 30px">
                <div style="padding: 13px" class="text-center">
                  <mdb-icon
                    fas
                    icon="file"
                    class="text-white bg-primary rounded-circle"
                    style="
                      height: 50px;
                      width: 50px;
                      line-height: 3.2rem;
                      font-size: 24px;
                    "></mdb-icon>
                  <br />
                  <p class="text-dark font-weight-bold mt-3">Successful</p>
                </div>
                <div class="text-center text-muted" style="font-size: 14px">
                  You have successfully submitted your request. You will be contacted within 1-2
                  business days by a Holista Care Coordinator to answer questions and get you
                  started on your Episode of Care journey.
                </div>

                <!-- <div class="m-0 d-flex justify-content-between align-items-center">
                <button
                id="btn-login"
                class="btn border btn-secondary mt-4 ml-0 mr-0"
                mdbWavesEffect
                type="submit"
                (click)="showLoginPage()"
                >
                Next
              </button>
            </div> -->
                <!-- Form -->
              </mdb-card-body>

              <!-- EOC LIST FORM START -->

              <!-- EOC LIST FORM END -->
              <mdb-card-footer>
                <div class="text-center mt-0">
                  <p class="mb-0">
                    © {{ current_year }} {{ landingPage.copyrightText }}. All rights reserved.
                  </p>
                  <!-- <p class="mb-0">© {{current_year}} Novant Health. All rights reserved.</p> -->
                  <p class="mb-0">
                    <a
                      id="termsOfUse"
                      href="javascript:void(0)"
                      [routerLink]="['/terms-of-use']"
                      target="_blank"
                      ><small> Terms of Use |</small></a
                    >
                    <a
                      id="privacyPolicy"
                      href="javascript:void(0)"
                      [routerLink]="['/privacy-policy']"
                      target="_blank"
                      ><small> Privacy Policy </small></a
                    >
                  </p>
                </div>
              </mdb-card-footer>
            </mdb-card>

            <!-- Initiate EOC REQUEST FORM END-->
          </div>
        </div>
        <!--Grid column-->
        <div class="col col-sm-12 col-md-6 col-lg-8 order-2 order-md-1 intro-content-wrapper">
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12">
              <a href="/" class="logo position-fixed d-none d-md-block"></a>
            </div>
            <!-- <div
              class="col-md-9 col-lg-9 font-weight-bold col-sm-12 mt-1 pl-1"
              style="background-color: #ff9800; padding: 10px; color: white; font-size: 16px"
              *ngIf="theme === 'novant'">
              <i>
                Attention: Novant Health Maternity Episodes of Care patients – we are aware that
                some of the links in your episode care pathway are not functioning. We are working
                on a resolution plan and hope to have this updated very soon.
              </i>
            </div> -->
          </div>

          <div
            class="wow fadeInLeft w-100 h-100 d-none d-md-flex justify-content-center align-items-center px-5 flex-column"
            style="position: absolute"
            data-wow-delay="0.3s">
            <h1 id="welcome-title" class="display-5 main-title font-weight-700">
              {{ landingPage.welcomeTitle }}
            </h1>

            <div *ngIf="hasAppAnnouncement" [ngClass]="theme + '-skin'">
              <div class="app-announcement app-info app-info__box">
                <div class="app-info__text">
                  <div class="p-1">
                    <h5 class="text-white">Download our NEW APP</h5>
                    <div class="client-font">
                      View your episode in the NEW
                      <span *ngIf="showClientName" class="text-uppercase"> {{ theme }} </span> APP!
                      Manage your tasks and chat with your Care Coordinator all from your
                      Smartphone.
                    </div>
                  </div>
                  <div class="app-info__qr">
                    <div class="app-info__qr__android">
                      <div class="qr-section">
                        <img [src]="androidQr" alt="" class="me-4" width="auto" height="90" />
                      </div>
                      <a [href]="googlePlayLink" target="_blank">
                        <img
                          [src]="googlePlayIcon"
                          alt=""
                          class="me-4 google-play-icon"
                          width="auto"
                          height="40" />
                      </a>
                    </div>
                    <div class="app-info__qr__apple">
                      <div class="qr-section">
                        <img [src]="appleQr" alt="" class="me-4" width="auto" height="90" />
                      </div>
                      <a [href]="appleStoreLink" target="_blank">
                        <img
                          [src]="applePlayIcon"
                          alt=""
                          class="me-4 apple-store"
                          width="auto"
                          height="40" />
                      </a>
                    </div>
                  </div>
                </div>

                <div class="app-info__mobile">
                  <div class="app-info__mobile__screenshots">
                    <img
                      [src]="mobileTaskImage"
                      class="app-info__mobile__screenshots__task"
                      alt=""
                      height="250" />
                    <img
                      [src]="mobileLoginImage"
                      class="app-info__mobile__screenshots_login"
                      alt=""
                      width="auto"
                      height="250" />
                    <img
                      [src]="mobileDashboardImage"
                      class="app-info__mobile__screenshots__dashboard"
                      alt=""
                      width="auto"
                      height="250" />
                  </div>
                </div>
              </div>
            </div>

            <hr *ngIf="theme === 'novant' || theme === 'provider'" class="hr-separator w-100" />
            <h6 *ngIf="theme === 'novant' || theme === 'provider'" id="intro-content" class="mb-3">
              <div [innerHTML]="landingPage.welcomeDescription"></div>
            </h6>
          </div>
          <footer class="w-100 col pt-3 col-sm-12 col-md-6 col-lg-8">
            <p class="text-white" *ngIf="theme === 'provider'">
              For assistance in completing your registration, please contact the Provider Web Portal
              Support Team at <span class="font-weight-700">{{ landingPage.phone }}</span
              >, or email
              <a href="mailto:support@holistahealth.com" target="_blank"
                >support@holistahealth.com</a
              >
            </p>
            <div class="text-center" *ngIf="theme !== 'provider'">
              <h6>
                <i>{{ landingPage.footerText?.note1 }}</i>
              </h6>
              <h6>
                <i>{{ landingPage.footerText?.note2 }}</i>
              </h6>
              <h6>
                <i>{{ landingPage.footerText?.note3 }}</i>
              </h6>
            </div>
          </footer>
        </div>
      </div>
    </div>

    <!-- Material form login -->

    <!-- FORGOT PASSWORD MODAL -->
    <div
      mdbModal
      #forgotPasswordModal="mdbModal"
      class="modal fade top"
      id="frameModalTop"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
      [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <!--Content-->
        <div class="modal-content rounded-0">
          <!--Header-->
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              id="btn-close"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()">
              <i class="icofont-close-line"></i>
            </button>
          </div>

          <!--Body-->
          <div class="modal-body">
            <div class="text-center">
              <i class="fa fa-lock text-info fa-4x mb-3 animated rotateIn"></i>
              <h2 class="text-info">Forgot Password?</h2>
              <small class="d-block blue-grey-text"
                >Please enter your email address to reset your password</small
              >
              <form [formGroup]="forgotPasswordForm">
                <div class="form-group d-flex justify-content-center mt-5">
                  <input
                    type="email"
                    class="form-control w-75"
                    formControlName="email"
                    id="defaultForm-email"
                    mdbInput
                    name="defaultForm-email"
                    placeholder="Your email"
                    [ngClass]="{
                      'is-invalid': submit_email && forgotPasswordForm.get('email').invalid
                    }" />

                  <button
                    id="btn-forgotPassword"
                    class="btn btn-secondary btn-sm mt-0 mb-0 mr-0 border w-25"
                    type="submit"
                    mdbWavesEffect
                    (click)="forgotPassword()"
                    [disabled]="submit_enabled">
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="submit_email_enabled"></span>
                    <i class="icofont-paper-plane" *ngIf="!submit_enabled"></i> send email
                    <!-- <i class="fa fa-refresh ml-1"></i> -->
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center border-top-0"></div>
        </div>
        <!--/.Content-->
      </div>
    </div>
  </div>
</div>
<app-multi-factor-authentication
  #mfaModal
  [mfaDetail]="mfaDetail"
  (handleMFAVerification)="handleMFAVerification($event)"></app-multi-factor-authentication>
