import { EPISODE_EDIT_ANSWER_DISABLED_STATUSES, TASK_STATUS } from "../constants";
import { EditableTasks } from "../models";


/**
     * checks if the tasks are editable
     * @param task 
     * @returns boolean value
     */
export function isEditAnswerEnable(task: EditableTasks): boolean {
    if (task?.status?.toUpperCase() !== TASK_STATUS.COMPLETED) {
        return false
    }
    if (EPISODE_EDIT_ANSWER_DISABLED_STATUSES.includes(task.episodeStatus)) {
        return false
    }
    if (task.milestoneCompletedByCCES) {
        return false
    }
    return true
}
