import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { EpisodeOfCareService, BundleCostService, PathwayService, ProviderService, NetworkService, MilestoneService, ToasterService, ClientService, PurchaserService } from '../../services';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../action';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AccessType, HolistaUtils, ScrollTo, Storage } from '../../utils';
import { TabsetComponent } from 'ng-uikit-pro-standard';
import { Subject } from "rxjs";
import { ACCESS_DENIED, ADD_SUCCESS, DELETE_SUCCESS, NO_MILESTONE_SELECTED, PLAN_SAVE_FAILED, UPDATE_SUCCESS } from "../../constants";

@Component({
    selector: 'app-episode-of-care',
    templateUrl: './episode-of-care.component.html'
})

export class EpisodeOfCareComponent implements OnInit {

    @ViewChild('episodeOfCareModal', { static: true }) public episodeOfCareModal;
    @ViewChild('planModal', { static: true }) public planModal;
    @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
    loadingData = {
        episode: false,
        plan: false,
        pathway: false,
        bundle: false,
        categories: false,
        subCategories: false
    };
    eocQuery = {
        limit: 10,
        page: 1,
        keyword: '',
        sortType: 'desc',
        sortBy: 'id',
        networkCode: ''
    }
    totalCount = 0;
    reverse: boolean = true;
    result = { searchedEOCs: true, EOCs: true };
    episodeOfCareForm: FormGroup;
    planForm: NgForm;
    submitted = false;
    submit_enabled = false;
    valueBasedProgramList: any[] = [];
    networksForFilter: any[] = [];
    pathwayList: any = [];
    bundleList: any = [];
    bundleCosts: any[] = [];
    episodeOfCareList: any[] = [];
    fullFundingRequest: any[] = [];
    plans: any[] = [];
    selectedEpisode: any = {};
    episodeOfCareId: number;
    selectedBundle: any = {};
    selectedPathway: any = {};
    selectedEpisodeOfCare: any = {};
    message: string;
    searchModelChanged: Subject<string> = new Subject<string>();
    user: any;
    networks: any = []
    backupNetworks: any = []
    categories: any = [];
    subCategories: any = [];

    constructor(
        private fb: FormBuilder,
        private store: Store<{ bread_crumbs: any }>,
        private toastr: ToasterService,
        public utilityAccess: AccessType,
        private _episodeOfCareSerivce: EpisodeOfCareService,
        private _bundleCostService: BundleCostService,
        private _scrollTo: ScrollTo,
        private pathwayService: PathwayService,
        private _storage: Storage,
        private providerService: ProviderService,
        public holistaUtil: HolistaUtils,
        private networkService: NetworkService,
        private clientService: ClientService,
        private purchaserService: PurchaserService,
        private milestoneService: MilestoneService
    ) {
        this.setEpisodeOfCareForm();
        this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
            Object.assign(this.eocQuery, { page: 1, keyword });
            this.getEpisodeOfCareList();
        });
    }

    ngOnInit() {
        this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Episodes Of Care', path: '/episodeofcare' }));
        this.user = this._storage.get('local', 'loggedInUser', 'user')
        this.user.roleCode === 'CA' ? this.getClientNetworks() : this.user.roleCode === 'PUA' ? this.getPurchaserNetworks() : this.getNetworks()
        this.getEpisodeOfCareList();
    }

    searchKeywordChange(text) {
        this.searchModelChanged.next(text);
    }

    setEpisodeOfCareForm() {
        this.episodeOfCareForm = this.fb.group({
            id: [null],
            uuid: [null],
            name: ['', Validators.required],
            description: [null],
            pathwayUuid: [null, Validators.required],
            bundleUuid: [null, Validators.required],
            networkCode: [null, Validators.required],
            categoryId: [null],
            subCategoryId: [null]
        })
    }

    getEpisodeOfCareList() {
        this.loadingData.episode = true;
        this.result.EOCs = true;
        this.result.searchedEOCs = true;
        this._episodeOfCareSerivce.getEpisodeOfCare(this.eocQuery)
            .pipe(finalize(() => { this.loadingData.episode = false; }))
            .subscribe((response: any) => {
                if (response && response.count > 0) {
                    this.totalCount = response.count;
                    response.rows.map(eoc => {
                        if (eoc.pathway) eoc.pathwayName = eoc.pathway.name
                        if (eoc.bundle) eoc.bundleName = eoc.bundle.name
                        if (eoc.network) eoc.networkName = this.holistaUtil.toTitleCase(eoc.network.name)
                        return eoc
                    })
                    this.episodeOfCareList = response.rows;
                }
                else {
                    this.episodeOfCareList = [];
                    this.eocQuery.keyword ? (this.result.searchedEOCs = false) : (this.result.EOCs = false);
                }
            }, (error) => {
                console.log('Error fetching Episode of care list', error);
            })
    }

    filterByNetwork(event, type?) {
        this.eocQuery.page = 1;
        if (!type) {
            this.eocQuery['networkCode'] = event.value;
            this.networksForFilter = this.networksForFilter.map(network => {
                if (network.value == event.value) network.disabled = true
                else network.disabled = false
                return network
            })
        }
        else {
            this.networksForFilter = this.networksForFilter.map(x => {
                x.disabled = false;
                return x
            })
            delete this.eocQuery['networkCode']
        }
        this.getEpisodeOfCareList();
    }

    pageChanged(event) {
        this.eocQuery.page = event;
        this.getEpisodeOfCareList();
    }

    setOrder(value: string) {
        if (this.eocQuery.sortBy === value) {
            this.reverse = !this.reverse;
            this.eocQuery.sortType = this.eocQuery.sortType === "desc" ? "asc" : "desc";
        }
        else {
            this.reverse = true;
            this.eocQuery.sortType = "desc";
        }
        this.eocQuery.sortBy = value;
        this.getEpisodeOfCareList();
    }

    async getAssociatePlans(eoc) {
        eoc.collapsed = !eoc.collapsed;
        if (eoc.collapsed) {
            if (!eoc.associatedPlans || eoc.associatedPlans.length == 0 && !eoc.isPlanLoad) {
                eoc.associatedPlans = (await this.getAssociatedPlans(eoc)).filter(x => x.isChecked)
            }
        }
    }

    getClientNetworks() {
        this.clientService.getNetworksByClientCode(this.user.referenceCode)
            .subscribe(res => {
                res.map(network => {
                    network.label = network.name
                    network.value = network.code
                    return network
                })
                this.backupNetworks = JSON.parse(JSON.stringify(res))
                this.networks = JSON.parse(JSON.stringify(res))
                this.eocQuery.networkCode = this.networks.map(n => n.code).join()
            }, (error) => {
                console.log('Error', error)
            })
    }

    getPurchaserNetworks() {
        this.purchaserService.getNetworks(this.user.referenceCode)
            .subscribe(res => {
                res.rows.map(network => {
                    network.label = network.name
                    network.value = network.code
                    return network
                })
                this.backupNetworks = JSON.parse(JSON.stringify(res.rows))
                this.networks = JSON.parse(JSON.stringify(res.rows))
                this.eocQuery.networkCode = this.networks.map(n => n.code).join()
            },
                (error) => {
                    console.log('Error', error)
                })
    }

    getNetworks() {
        this.networkService.getAll({ limit: 0 })
            .subscribe((response: any) => {
                this.networks = [];
                response.rows.map(network => {
                    network.label = network.name
                    network.value = network.code
                    return network
                });
                this.networks = JSON.parse(JSON.stringify(response.rows))
                this.backupNetworks = response.rows
                this.networksForFilter = JSON.parse(JSON.stringify(response.rows))
            }, (error) => {
                console.log('Error fetching value Based Program DropDown List', error);
            })
    }

    networkSelected(data) {
        this.pathwayList = [];
        this.bundleList = [];
        this.bundleCosts = [];
        this.selectedBundle = {}
        this.episodeOfCareForm.controls['pathwayUuid'].reset();
        this.episodeOfCareForm.controls['bundleUuid'].reset();
        this.getPathwayDropdownList(data.value);
        this.getBundleDropdownList(data.value);
    }

    getPathwayDropdownList(networkCode) {
        this.loadingData.pathway = true;
        this.pathwayService.getPathways({ networkCode })
            .pipe(finalize(() => { this.loadingData.pathway = false; }))
            .subscribe((response: any) => {
                this.pathwayList = response.rows.map(x => {
                    let data = { ...x, label: x.name, value: x.uuid };
                    return data;
                });
            }, (error) => {
                console.log("Error fetching pathway dropdown list", error);
            })
    }

    getBundleDropdownList(networkCode, bundleUuid?) {
        this.loadingData.bundle = true;
        this._episodeOfCareSerivce.getBundleDropDown(null, { networkCode })
            .pipe(finalize(() => { this.loadingData.bundle = false; }))
            .subscribe((response: any) => {
                this.bundleList = response.rows.map(x => {
                    let data = { ...x, label: x.name, value: x.uuid };
                    return data;
                });
                if (bundleUuid) {
                    this._episodeOfCareSerivce.getBundleDropDown(bundleUuid, { paramType: 'uuid' }).subscribe(bundle => {
                        this.bundleSelected(bundle)
                    })
                }
            }, (error) => {
                console.log("Error fetching pathway dropdown list", error);
            })
    }


    bundleSelected(event) {
        if (event) {
            event.components = event.components.map(x => {
                let data = { ...x, label: x.name, value: x.uuid }
                return data;
            });
            event.components.forEach((component, i) => {
                if (component.isFundingTrigger) {
                    let index = event.fundingRequests.findIndex(y => y.uuid == event.components[i].fundingRequestUuid)
                    if (index > -1) {
                        let fundingTrigger: any
                        if (this.episodeOfCareForm.value.id && this.selectedEpisodeOfCare && this.selectedEpisodeOfCare.fundingTriggers.length) {
                            fundingTrigger = this.selectedEpisodeOfCare.fundingTriggers.find(({ referenceUuid }) => referenceUuid === event.components[i].uuid)
                        }
                        if (fundingTrigger || !this.episodeOfCareForm.value.id || event.fundingRequests.length) {
                            event.fundingRequests[index].isTriggered = true;
                            event.fundingRequests[index].triggeredComponentUuid = event.components[i].uuid;
                        }
                    }
                }
            });
            if (this.selectedEpisodeOfCare && this.selectedEpisodeOfCare.fundingTriggers && this.selectedEpisodeOfCare.fundingTriggers.length > 0) {
                event.fundingRequests.forEach((fund, i) => {
                    if (!fund.isTriggered) {
                        let index2 = this.selectedEpisodeOfCare.fundingTriggers.findIndex(y => y.fundingRequestUuid == fund.uuid);
                        if (index2 > -1) event.fundingRequests[i].milestoneUuid = this.selectedEpisodeOfCare.fundingTriggers[index2].referenceUuid;
                    }
                });
            }
            this.selectedBundle = event;
        }
        this.bundleCosts = [];
        this._bundleCostService.getBundleCostByBundleUuid({ bundleUuid: event.uuid, limit: 0 })
            .subscribe((response: any) => {
                if (response && response.count != 0) this.bundleCosts = response.rows;
                else this.bundleCosts = []
            }, (error) => {
                console.log("Error fetching bundle cost by bundleUuid", error);
            })
    }

    pathwaySelected(event, change?) {
        if (this.episodeOfCareForm.value.id) {
            if (this.selectedEpisodeOfCare.fundingTriggers && change) {
                this.selectedBundle.fundingRequests && this.selectedBundle.fundingRequests.map(funding => {
                    if (funding.milestoneUuid) {
                        funding.milestoneUuid = null
                    }
                    return funding
                })
            }
        }
        event.milestones = event.milestones.map(x => {
            let data = { ...x, label: x.name, value: x.uuid }
            return data;
        })
        this.selectedPathway = event;
    }

    getPathwayMilestones(uuid) {
        this.milestoneService.getMilestones(uuid, { paramType: 'uuid' }).subscribe(res => {
            if (res) this.pathwaySelected(res)
        })
    }

    openEpisodeOfCareModal(eoc) {
        this.loadingData.categories = true;
        const params = {
            limit: 0
        };
        this.clientService.getCategories(params)
            .pipe(finalize(() => { this.loadingData.categories = false; }))
            .subscribe((response: any) => {
                if (response && response.count) {
                    this.categories = response.rows.map(({ id, name }) => ({ label: name, value: id }));
                }
            }, error => {
                this.toastr.showError(error);
                console.log('error getting categories', error)
            });
        if (this.utilityAccess.searchAccess('EOCM', 'isEditable')) {
            if (eoc) {
                this.selectedEpisodeOfCare = eoc;
                this.getPathwayDropdownList(eoc.networkCode);
                this.getBundleDropdownList(eoc.networkCode, eoc.bundleUuid);
                this.getPathwayMilestones(eoc.pathwayUuid)
                this.episodeOfCareForm.patchValue(eoc);
                if (eoc.categoryId) {
                    this.onCategorySelected({ value: eoc.categoryId }, eoc.subCategoryId);
                }
            } else {
                this.pathwayList = []
                this.bundleList = []
                this.networks = [...this.backupNetworks]
            }
            this.episodeOfCareModal.show();
        }
        else {
            this.toastr.displayWarning(ACCESS_DENIED);
        }
    }

    submitEpisodeOfCare() {
        this.submitted = true;
        if (this.episodeOfCareForm.valid) {
            let hasMilestone = true;
            this.selectedBundle.fundingRequests.forEach(fund => {
                if (!fund.triggeredComponentUuid && !fund.milestoneUuid)
                    hasMilestone = false;
            });
            if (hasMilestone) {
                let body = this.episodeOfCareForm.getRawValue();
                body.fundingTriggers = [];
                if (this.selectedBundle && this.selectedBundle.fundingRequests && this.selectedBundle.fundingRequests.length > 0) {
                    this.selectedBundle.fundingRequests.map(x => {
                        let obj =
                        {
                            fundingRequestUuid: x.uuid,
                            fundingRequestName: x.fundingRequestName,
                            referenceType: x.triggeredComponentUuid ? "COMPONENT" : "MILESTONE",
                            referenceUuid: x.triggeredComponentUuid ? x.triggeredComponentUuid : x.milestoneUuid
                        }
                        body.fundingTriggers.push(obj);
                    });
                }
                this.submit_enabled = true;
                if (this.episodeOfCareForm.value.id) this.updateEpisodeOfCare(body);
                else this.saveEpisodeOfCare(body);
            }
            else {
                this.toastr.displayWarning(NO_MILESTONE_SELECTED);
            }
        }
    }

    saveEpisodeOfCare(body) {
        delete body.id
        delete body.uuid
        this._episodeOfCareSerivce.createEpisodeOfCare(body)
            .pipe(finalize(() => { this.submit_enabled = false; }))
            .subscribe((response: any) => {
                if (response) {
                    // this.episodeOfCareList.unshift(response);
                    this.eocQuery.sortBy = 'id';
                    this.eocQuery.page = 1;
                    this.getEpisodeOfCareList();
                    this.toastr.displaySuccess(ADD_SUCCESS);
                }
            }, (error) => {
                console.log("Error creating episode of care", error);
                this.toastr.showError(error);
            }, () => {
                this.closeEpisodeOfCareModal();
            })
    }

    updateEpisodeOfCare(body) {
        this._episodeOfCareSerivce.updateEpisodeOfCare(body)
            .pipe(finalize(() => { this.submit_enabled = false; }))
            .subscribe((response: any) => {
                if (response) {
                    let index = this.episodeOfCareList.findIndex(x => x.id == response.id)
                    if (index >= 0) {
                        response.networkName = this.holistaUtil.toTitleCase(response.network.name)
                        response.pathwayName = response.pathway.name
                        response.bundleName = response.bundle.name
                        this.episodeOfCareList[index] = response;
                        this.toastr.displaySuccess(UPDATE_SUCCESS);
                    }
                }
            }, (error) => {
                console.log("Error updating episode of care", error);
                this.toastr.showError(error);
            }, () => {
                this.closeEpisodeOfCareModal();
            })
    }

    closeEpisodeOfCareModal() {
        this.episodeOfCareModal.hide();
        this.episodeOfCareForm.reset();
        this.pathwayList = [];
        this.bundleList = [];
        this.bundleCosts = [];
        this.submitted = false;
        this.selectedBundle = {};
        this.selectedPathway = {};
        this.message = null;
        this.selectedEpisodeOfCare = {};
        this.staticTabs.setActiveTab(1);
    }

    async openPlanModal(eoc) {
        if (this.utilityAccess.searchAccess('EOCM', 'isEditable')) {
            this.loadingData.plan = true;
            this.plans = [];
            this.selectedEpisode = eoc;
            this.planModal.show();
            let associatePlans: any = await this.getAssociatedPlans(eoc)
            this.plans = JSON.parse(JSON.stringify(associatePlans))
            eoc.associatedPlans = associatePlans.filter(plan => plan.isChecked)
        }
        else {
            this.toastr.displayWarning(ACCESS_DENIED);
        }
    }

    getAssociatedPlans(eoc) {
        return this._episodeOfCareSerivce.getPlans(eoc.uuid).then((response: any) => {
            this.loadingData.plan = false;
            eoc.isPlanLoad = true
            return response.rows
        }, (error) => {
            this.loadingData.plan = false;
            console.log("Error fetching plans", error);
        })
    }

    checkChange(event, index) {
        this.plans[index].isChecked = event.currentTarget.checked;
        if (!this.plans[index].isChecked) {
            this.plans[index].patientResponsibility = '0.00';
        }
    }

    submitPlan() {
        this.submit_enabled = true;
        this._episodeOfCareSerivce.savePlan(this.selectedEpisode.uuid, this.plans)
            .pipe(finalize(() => { this.submit_enabled = false; }))
            .subscribe((response: any) => {
                if (response) {
                    let index = this.episodeOfCareList.findIndex(x => x.uuid == response[0].eocUuid)
                    if (index >= 0 && this.episodeOfCareList[index].associatedPlans)
                        this.episodeOfCareList[index].associatedPlans = response.filter(x => x.isChecked)
                    this.toastr.displaySuccess(UPDATE_SUCCESS);
                }
            }, (error) => {
                console.log("Error saving plans", error);
                this.toastr.showError(PLAN_SAVE_FAILED);
            }, () => {
                this.closeplanModal();
            })
    }

    closeplanModal() {
        this.planModal.hide();
    }

    delete(episode) {
        if (this.utilityAccess.searchAccess('EOCM', 'isEditable')) {
            this.episodeOfCareId = episode.uuid;
            this.message = `Are you sure you want to delete <strong>${episode.name}</strong>? This is irreversible.
            If you're not sure, you can click <strong>Cancel</strong>.`
        }
        else
            this.toastr.displayWarning(ACCESS_DENIED);
    }

    deleteEpisodeOfCare(uuid) {
        if (uuid) {
            this._episodeOfCareSerivce.deleteEpisodeOfCare(uuid)
                .subscribe((response: any) => {
                    if (response) {
                        let index = this.episodeOfCareList.findIndex(x => x.uuid == uuid);
                        if (index > -1) {
                            this.getEpisodeOfCareList();
                            this.toastr.displaySuccess(DELETE_SUCCESS);
                        }
                    }
                }, (error) => {
                    console.log("Error deleting Episode of care", error);
                    this.toastr.showError(error);
                    this.episodeOfCareId = null;
                })
        }
        else this.episodeOfCareId = null;

    }

    scrollToTop() {
        this._scrollTo.ScrollTo('scrollToTop', 'center', 'smooth')
    }

    removeSearchKeyword() {
        this.searchKeywordChange('')
        this.eocQuery.keyword = ''
    }

    onCategorySelected({ value }, subCategoryId?: number) {
        this.loadingData.subCategories = true;
        this.subCategories = [];
        this.episodeOfCareForm.controls['subCategoryId'].setValue(null);
        const params = { limit: 0 };
        this.clientService.getCategories(params, value)
            .pipe(finalize(() => { this.loadingData.subCategories = false; }))
            .subscribe((response: any) => {
                if (response) {
                    this.subCategories = response.subCategories.map(({ id, name }) => ({ label: name, value: id }));
                    this.episodeOfCareForm.controls.subCategoryId.setValue(subCategoryId);
                }
            }, error => {
                this.toastr.showError(error);
                console.log('error getting sub categories', error);
            });
    }

    onCategoryDeselected(isSubCategory: boolean = false) {
        !isSubCategory && this.episodeOfCareForm.controls.categoryId.setValue(null);
        this.episodeOfCareForm.controls.subCategoryId.setValue(null);
    }
}