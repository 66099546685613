<!-- DELETE MILESTONE MODAL -->
<div
  mdbModal
  #deleteModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <!--Content-->
    <div class="modal-content rounded-0">
      <div class="modal-header z-depth-0 border-bottom text-center" *ngIf="episodeToBeCancel">
        <p class="mb-0">
          Are you sure you want to change <b> {{ episodeToBeCancel.episode?.name }} </b> status to
          CANCELLED ? This is irreversible. If you're not sure, you can click <b>No</b>.
        </p>
      </div>
      <div class="modal-body">
        <p class="mb-0 text-center" *ngIf="user">Are you sure you want to remove Supervisor ?</p>
        <p *ngIf="dataToBeDelete" class="mb-0 text-center">
          Are you sure you want to delete
          <span class="font-weight-500">
            {{ dataToBeDelete?.noteType ? dataToBeDelete?.type : '' }}
            {{ dataToBeDelete?.noteType ? 'notes' : '' }}
          </span>
          ? This is irreversible. If you're not sure, you can click
          <span class="font-weight-500">Cancel </span>.
        </p>

        <!-- Material unchecked disabled -->
        <form action="" *ngIf="episodeToBeCancel">
          <h6>Cancellation Reasons</h6>
          <div
            class="form-check pl-0"
            [attr.data-test-id]="'cancellation-resaons'"
            *ngFor="let reason of cancelReasons; let i = index">
            <input
              type="radio"
              class="form-check-input"
              id="reason{{ i }}"
              name="cancelreason"
              (change)="selectedReason(reason)"
              [checked]="selected_Reason && selected_Reason == reason ? true : false"
              mdbInput />
            <label class="form-check-label" for="reason{{ i }}">{{ reason }}</label>
          </div>
        </form>
        <div class="p-0 mt-4 text-center" *ngIf="user">
          <button
            class="btn btn-secondary waves-light"
            [attr.data-test-id]="'submit-button'"
            mdbWavesEffect
            (click)="closeModal('Yes')">
            Yes
          </button>
          <button
            class="btn btn-light waves-light"
            [attr.data-test-id]="'milestone-topic-delete-cancel-button'"
            mdbWavesEffect
            (click)="closeModal('No')">
            No
          </button>
        </div>
        <div class="p-0 mt-4 text-center" *ngIf="!user">
          <button
            class="btn btn-secondary waves-light"
            [attr.data-test-id]="'milestone-topic-delete-submit-button'"
            mdbWavesEffect
            (click)="dataToBeDelete ? dataToDelete() : episodeToCancelled()"
            [disabled]="submit_enabled || (episodeToBeCancel && !selected_Reason)">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="submit_enabled"></span>
            {{ dataToBeDelete ? 'DELETE' : 'YES! Cancel Episode' }}
          </button>
          <button
            class="btn btn-light waves-light"
            [attr.data-test-id]="'cancel-button'"
            mdbWavesEffect
            (click)="deleteModal.hide(); closeMilestoneModal()">
            {{ dataToBeDelete ? 'CANCEL' : 'NO' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
