import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { APICONSTANTS, ROLE_CODES } from '../constants';
import { Storage } from '../utils';
@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private _storage: Storage,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const loggedInUser = this._storage.get('local', 'loggedInUser');
    let loggedInUserPermissions: any
    if (loggedInUser && loggedInUser.user) loggedInUserPermissions = loggedInUser.user.rolePermissions
    const access = loggedInUserPermissions && loggedInUserPermissions.find(x => x.permissionCode === route.data.condition)
    if (access && access.accessType === route.data.accessType) {
      loggedInUser.user.roleCode === ROLE_CODES.REPORTING ? this.router.navigate([APICONSTANTS.REPORT]) : this.router.navigate([APICONSTANTS.DASHBOARD]);
      return false;
    }
    return true;
  }
}
