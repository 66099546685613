<div class="p-3">
  <div class="row">
    <div class="col-10 d-flex align-items-center">
      <h6 class="mb-0 font-weight-bold mr-2">Period Totals</h6>
      <span class="badge badge-sm bg-primary-dark text-white z-depth-0">
        Reporting Period
        <span> - {{ parsedTransactionReportFilterData.startDate }} - {{ parsedTransactionReportFilterData.endDate }} </span>
      </span>
    </div>
    <div class="col-2">
      <form [formGroup]="filterForm">
        <div *ngIf="!isClientSelected; else nestedFilters">
          <app-select
            [options]="dateTypeList"
            [id]="'ar-report-date-type'"
            [control]="filterForm.controls['dateType']"
            [placeholder]="'Select date type'"
            [filterEnabled]="false"
            [inputClass]="{
              'disabled-options': isLoading.report
            }"
            (onSelected)="getAccountsReceivableData()"
            (onDeselected)="getAccountsReceivableData()"></app-select>
        </div>
        <ng-template #nestedFilters>
          <app-select
            [options]="episodeTypeList"
            [id]="'ar-report-episode-type'"
            [control]="filterForm.controls['episodeType']"
            [placeholder]="'Select episode type'"
            [multiple]="true"
            [inputClass]="{
              'disabled-options': isLoading.report
            }"
            (onSelected)="onEpisodeTypeSelected()"
            (onDeselected)="onEpisodeTypeSelected()"></app-select>
        </ng-template>
      </form>
    </div>
  </div>
  <div class="d-flex my-4" *ngIf="!isLoading.report">
    <div
      class="flex-1 bg-grey-lighten-2 border rounded-lg py-2 px-3 w-100"
      *ngFor="let header of ACCOUNTS_RECEIVABLE_REPORT_DATA.SUMMARY_HEADERS; let i = index"
      [class.mr-4]="i !== 4">
      <p class="mb-0">{{ header.label }}</p>
      <p class="mb-0">
        <b
          *ngIf="header.value === 'episodes'; else amountData"
          >{{ data.summary?.[header.value] }}</b
        >
        <ng-template #amountData>
          <b>{{ data.summary?.[header.value] | currency}}</b>
        </ng-template>
      </p>
    </div>
  </div>
  <a *ngIf="isClientSelected" class="text-secondary" (click)="handleBackToClientView()"
    ><i class="fas fa-long-arrow-alt-left mr-2"></i>Back to List</a
  >
  <app-loader [loader]="isLoading.report"></app-loader>
  <div class="mt-4" *ngIf="!isLoading.report">
    <app-table
      [report]="accountsReceivableReport"
      [module]="'report'"
      tableHeight="800px"
      [isRowDataClickable]="true"
      (onRowDataClick)="onClientSelected($event)">
      <ng-template #total>
        <tr
          *ngIf="isClientSelected; else viewClientBasedData"
          class="bg-grey-lighten-2 sticky-bottom">
          <td class="p-1 pl-2 text-right" colspan="5">Total</td>
          <td class="p-1 pr-2 text-right">
            {{ selectedIndividualData?.totalFundRequested | currency }}
          </td>
          <td class="p-1 pr-2 text-right">
            {{ selectedIndividualData?.totalClientFee | currency }}
          </td>
          <td class="p-1 pr-2 text-right">{{ selectedIndividualData?.total | currency }}</td>
          <td class="p-1 pr-2 text-right">
            {{ selectedIndividualData.totalHolistaFee | currency }}
          </td>
        </tr>
        <ng-template #viewClientBasedData>
          <tr class="bg-grey-lighten-2 sticky-bottom">
            <td class="p-1 pl-2 text-right" colspan="2">Total</td>
            <td class="p-1 pr-2 text-right">{{ data.summary?.fundsReceived | currency }}</td>
          </tr>
        </ng-template>
      </ng-template></app-table
    >
  </div>
</div>
