import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { MdbBtnDirective } from 'ng-uikit-pro-standard';
import { AccessType } from 'src/app/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-episode-question',
  templateUrl: './episode-question.component.html',
  styleUrls: ['./episode-question.component.scss']
})
export class EpisodeQuestionComponent implements OnInit {
  question: any;
  taskToBeDelete: any
  quesUuid: any
  dependentTask: any
  @Output() editTask = new EventEmitter<any>();
  @Output() addAction = new EventEmitter<any>();
  @Input() public taskDragged;
  @Output() public btns = new EventEmitter<any>();
  @Output() public deletedTask = new EventEmitter<any>();
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;
  @Input() user: any;
  @Input() public days_difference;
  @Input() set taskQuestion(taskQuestion) {
    if (taskQuestion) {
      taskQuestion.questions.questionOptions.map((x, index) => {
        x.sequence = index + 1;
        return x;
      })
      if (taskQuestion.status == 'COMPLETED') {
        if (taskQuestion.questions.questionTypes.code == 'TEXTFIELD') {
          taskQuestion.answer = taskQuestion.questions.ansOpt[0].answer;
          taskQuestion.showMore = true;
        } else if (taskQuestion.questions.questionTypes.code == 'RADIO') {
          taskQuestion.questions.questionOptions.map(y => {
            y.status = (taskQuestion.questions.ansOpt[0].answerOptionId == y.id) ? true : false;
            return y;
          })
        } else if (taskQuestion.questions.questionTypes.code == 'CHECKBOX') {
          taskQuestion.questions.ansOpt.map(a => {
            taskQuestion.questions.questionOptions.map(y => {
              if (a.answerOptionId == y.id)
                y.status = true;
              return y;
            })
            return a;
          })
        }
      }
      // this.question = this.utilitySorting.sortBy(res, "question");

      this.question = taskQuestion;
    }
  };
  @Input() public optionId;
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.quesUuid)
        this.getDependentTask(this.quesUuid)
    }
  }

  @Input() set taskQuesUuid(taskQuesUuid) {
    if (taskQuesUuid) {
      this.quesUuid = taskQuesUuid
      this.getDependentTask(taskQuesUuid);
    }
  }
  constructor(
    private utilityAccess: AccessType,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    const buttons = this.buttons.toArray();
    setTimeout(() => {
      this.btns.emit(buttons);
    });
  }
  receiveBtns(buttons, field) {
    if (buttons) {
      this.btns.emit(buttons);
    }
  }
  editTaskHandler(question) {
    this.editTask.emit(question);
  }

  getDependentTask(uuid) {
    this.question = {};
    if (this.dependentTask && this.dependentTask.taskQuestions) {
      this.dependentTask.taskQuestions.filter(x => {
        if (x.uuid == uuid) {
          x.form = 'question';
          x.field = 'question';
          x.optionId = this.optionId;
          if (x.status == 'COMPLETED' && x.isActivated) {
            if (x.questions.questionTypes.code == 'TEXTFIELD') {
              x.answer = x.questions.ansOpt[0].answer;
              x.showMore = true;
            } else if (x.questions.questionTypes.code == 'RADIO') {
              x.questions.questionOptions.map(y => {
                y.status = (x.questions.ansOpt[0].answerOptionId == y.id) ? true : false;
                return y;
              })
            } else if (x.questions.questionTypes.code == 'CHECKBOX') {
              x.questions.ansOpt.map(a => {
                x.questions.questionOptions.map(y => {
                  if (a.answerOptionId == y.id)
                    y.status = true;
                  return y;
                })
                return a;
              })
            }
          }
          this.question = x;
        }
      })
    }
  }

  editTaskTodo(todo) {
    this.editTask.emit(todo);
  }

  editTaskMessage(message) {
    this.editTask.emit(message);
  }

  addActionHandler(action?, uuid?) {
    if (action && action.optionId) {
      this.addAction.emit(action);
    } else {
      this.addAction.emit({ optionId: action ? action : this.optionId, isDependent: true, parentUuid: uuid })
    }
  }

  getAnswer(option, question) {
    return '';
  }

  deleteTaskHandler(question) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      question['source'] = 'Episode'
      question['type'] = 'Question'
      question['displayName'] = question.questions.question
      this.taskToBeDelete = question
    }
    else this.toastr.warning("Access Denied", "Warning");
  }

  cancelDelete(e) {
    this.taskToBeDelete = e
  }

  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }
}
