<div class="position-relative">
  <div class="communications-otp-logo p-3">
    <img src="../../../assets/img/png/holista-logo.png" />
  </div>

  <div class="d-flex flex-column communications-otp-container">
    <div class="main-content flex-grow-1">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="text-center col-md-8 mt-5">
            <div class="mb-3">
              <h1 class="mb-3"><b>OTP Verification</b></h1>
              <p>
                We have sent code to your email <b>{{ encodedEmail }}</b>
              </p>
            </div>
            <div class="py-5">
              <ng-otp-input
                [class.disabled]="isProcessing"
                #ngOtpInput
                (onInputChange)="onOtpCodeChange($event)"
                [config]="config"></ng-otp-input>
              <div class="pt-4">
                <small class="text-primary" *ngIf="isProcessing"><b>Processing...</b></small>
                <small class="text-danger" *ngIf="!isProcessing"
                  ><b>{{ errorMessage }}</b></small
                >
              </div>
            </div>
            <div class="mt-3">
              <p>The code is valid for <b>10 minutes</b></p>
              <p>
                Didn't get the code?
                <span class="text-secondary pointer-cursor" (click)="onResendOtpCode()"
                  ><b>Resend</b></span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center py-3 flex-grow-0">
      <p class="m-0">
        <small>&copy; {{ currentYear }} {{ copyrightText }}</small>
      </p>
      <div>
        <a
          id="termsOfUse"
          href="javascript:void(0)"
          [routerLink]="['/terms-of-use']"
          target="_blank"
          ><small> Terms of Use |</small></a
        >
        <a
          id="privacyPolicy"
          href="javascript:void(0)"
          [routerLink]="['/privacy-policy']"
          target="_blank"
          ><small> Privacy Policy </small></a
        >
      </div>
    </div>
  </div>
</div>
