import { finalize } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DATE_TYPES, DatePickerOption, REPORT_DATES, REPORT_TAB_CODE, ROLE_CODES } from '../../../constants';
import { ReportService, ToasterService, ReportConfigurationService, NetworkService, ClientService } from '../../../services';
import { HolistaUtils, Storage } from '../../../utils';

@Component({
  selector: 'app-client-claims-dashboard',
  templateUrl: './client-claims-dashboard.component.html',
  styleUrls: ['./client-claims-dashboard.component.scss']
})

export class ClientClaimsDashboardComponent implements OnInit {
  reportFormConfig: FormGroup;
  isPreSelectedFilter: boolean = false;
  dateTypes = DATE_TYPES;
  REPORT_TAB_CODE = REPORT_TAB_CODE;
  ROLE_CODES = ROLE_CODES;
  disableNetworkDropdown = false;
  reportDateList: object[] = REPORT_DATES;
  endDateOptions = this._holistaUtils.deepClone(DatePickerOption);
  startDateOptions = this._holistaUtils.deepClone(DatePickerOption);
  clientLists: any[] = [];
  networkLists: any[] = [];
  user: any;
  loading = {
    clientList: false,
    networkList: false,
    tabList: false,
    reportConfiguration: false,
  }
  isDownloadPDF = { download: false, tabIndex: 0, type: null };
  reportExpanded: boolean = false;
  isDownloadDisabled: boolean = true;
  isProviderAdmin: boolean = false;
  activeTabIndex: number = 0;
  clientClaimsDashboardFilterData: any;
  tabLists: any[] = [];

  @ViewChild('tabs', { static: true }) public tabs;
  @Input() reportParentCode;

  constructor(
    private _formBuilder: FormBuilder,
    private _toastr: ToasterService,
    private _storage: Storage,
    private _holistaUtils: HolistaUtils,
    private clientService: ClientService,
    private reportService: ReportService,
    private networkService: NetworkService,
    private reportConfigurationService: ReportConfigurationService,
  ) {
    this.setReportFormConfig();
  }

  ngOnInit(): void {
    const loggedInUser = this._storage.get('local', 'loggedInUser')
    this.user = loggedInUser && this._storage.get('local', 'loggedInUser', 'user');
    if (this.user.primaryRole === ROLE_CODES.PROVIDER_ADMINISTRATOR) {
      this.isProviderAdmin = this.user.primaryRole === ROLE_CODES.PROVIDER_ADMINISTRATOR;
    }

    if (this.user.primaryRole === ROLE_CODES.SUPER_CLIENT_ADMINISTRATOR) {
      this.disableNetworkDropdown = this.user.primaryRole === ROLE_CODES.SUPER_CLIENT_ADMINISTRATOR;
    }
    this.getNetworkList();
  }

  ngOnDestroy() {
    this._storage.remove('session', 'clientClaimDashboardActiveTab');
  }

  setReportFormConfig() {
    this.reportFormConfig = this._formBuilder.group({
      networkCode: [''],
      networkName: [''],
      reportDate: ['ytd'],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      toShowReport: [],
      isConfigLoading: false,
      activeTabIndex: [0]
    });
  }

  onNetworkSelected(event) {
    this.isDownloadDisabled = true;
    this.getReportConfigurations(event.code);
  }

  getNetworkList() {
    this.loading.networkList = true;
    this.networkService.getAll({ limit: 0, fields: 'code,name' })
      .pipe(finalize(() => { this.loading.networkList = false; }))
      .subscribe((res: any) => {
        res.rows.map(x => {
          x.label = x.name,
            x.value = x.code
          return x;
        })
        this.networkLists = this._holistaUtils.deepClone(res.rows);
        const networkCode = this.user.referenceCode;
        let hasDefaultNetwork;
        if (this.user.primaryRole === ROLE_CODES.PROVIDER_ADMINISTRATOR && networkCode) {
          hasDefaultNetwork = this.networkLists.find((network) => network.code.toUpperCase() === networkCode);
        } else {
          hasDefaultNetwork = this.networkLists.find((network) => network.code.toUpperCase() === 'NH');
        }

        if (this.user.primaryRole === ROLE_CODES.SUPER_CLIENT_ADMINISTRATOR) {
          this.loading.tabList = true
          this.clientService.getNetworksByClientCode(this.user.referenceCode).subscribe(res => {
            if (res.length) {
              hasDefaultNetwork = this.networkLists.find((network) => network.code.toUpperCase() === res[0].code);
              this.reportFormConfig.controls.networkCode.setValue(hasDefaultNetwork ? hasDefaultNetwork.code : '');
              this.reportFormConfig.controls.networkName.setValue(hasDefaultNetwork ? hasDefaultNetwork.name : '');
              hasDefaultNetwork && this.getReportConfigurations(hasDefaultNetwork ? hasDefaultNetwork.code : '');
            }
            this.loading.tabList = false;
          })
        } else {
          this.reportFormConfig.controls.networkCode.setValue(hasDefaultNetwork ? hasDefaultNetwork.code : '');
          this.reportFormConfig.controls.networkName.setValue(hasDefaultNetwork ? hasDefaultNetwork.name : '');
          hasDefaultNetwork && this.getReportConfigurations(hasDefaultNetwork ? hasDefaultNetwork.code : '');
        }
        this.selectedReportDate();
      }, (error) => {
        console.log("Error getting Networks", error);
      })
  }

  getTabsForValueManagementDashboard() {
    this.loading.tabList = true;
    this.reportService.getReportsList({ parentCode: this.reportParentCode })
      .pipe(finalize(() => { this.loading.tabList = false; }))
      .subscribe(res => {
        const tabLists = res.sort((a, b) => a.order - b.order);
        if (tabLists && tabLists.length) {
          const toShowReport = this.reportFormConfig.get('toShowReport').value || [];
          tabLists.map((tab) => {
            const foundNavs = toShowReport.find(report => tab.code === report.reportCode);
            if (foundNavs) {
              this.tabLists.push(tab);
            };
          });
        }
        setTimeout(() => {
          const options = {
            storageType: 'session',
            storageKey: 'clientClaimDashboardActiveTab',
            tabList: this.tabLists,
          };
          const tabIndex = this._holistaUtils.getActiveTab(options);
          this.tabs.setActiveTab(tabIndex);
        }, 100);
      }, error => {
        console.log('error getting report types', error);
        this._toastr.showError(error);
      });
  }

  getReportConfigurations(networkCode?) {
    this.reportFormConfig.controls['toShowReport'].setValue([]);
    this.loading.reportConfiguration = true;
    this.reportFormConfig.controls['isConfigLoading'].setValue(true);
    this.clientClaimsDashboardFilterData = this.reportFormConfig.value;
    this.reportConfigurationService.getAll({ networkCode })
      .pipe(finalize(() => {
        this.loading.reportConfiguration = false;
        this.reportFormConfig.controls['isConfigLoading'].setValue(false);
      }))
      .subscribe((res) => {
        let toShowReport = [];
        this.tabLists = [];
        res.map((x) => {
          if (x.report && x.status) {
            toShowReport.push(x);
          }
        })
        this.reportFormConfig.controls['toShowReport'].setValue(toShowReport);
        if (this.reportParentCode && res.length) {
          this.getTabsForValueManagementDashboard();
        }
      }, (error) => {
        console.log("Error getting report configurations.", error);
      })
  }

  /**
   * 
   * @param event 
   * @param type 
   */
  onDateChange(event?: any, type?: string) {
    const { startDate, endDate } = this.reportFormConfig.value
    let fromDate: any;
    let toDate: any;
    if (!(event && type)) {
      if (startDate) {
        fromDate = { year: fromDate.substr(-4, 4), month: fromDate.substr(-10, 2), day: fromDate.substr(-7, 2) }
      }
      if (endDate) {
        toDate = { year: toDate.substr(-4, 4), month: toDate.substr(-10, 2), day: toDate.substr(-7, 2) }
      }
    }
    if (type === 'startDate' || !(event && type)) {
      this.endDateOptions.disableUntil.year = fromDate ? fromDate.year : event ? event.date.year : 0
      this.endDateOptions.disableUntil.month = fromDate ? fromDate.month : event ? event.date.month : 0
      this.endDateOptions.disableUntil.day = fromDate ? fromDate.day : event ? event.date.day : 0
      this.reportFormConfig.controls['startDate'].setValue(event.actualDateFormatted)
    }

    if (type === 'endDate' || !(event && type)) {
      this.startDateOptions.disableSince.year = toDate ? toDate.year : event ? event.date.year : 0
      this.startDateOptions.disableSince.month = toDate ? toDate.month : event ? event.date.month : 0
      this.startDateOptions.disableSince.day = toDate ? toDate.day : event ? event.date.day : 0
      this.reportFormConfig.controls['endDate'].setValue(event.actualDateFormatted)
    }
    this.clientClaimsDashboardFilterData = this.reportFormConfig.value;
  }

  /**
   * Report type
   */
  selectedReportDate() {
    if (this.reportFormConfig.value.reportDate) {
      switch (this.reportFormConfig.value.reportDate) {
        case "thisMonth":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('month').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().endOf('month').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastMonth":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('month').subtract(1, 'months').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().startOf('month').subtract(1, 'months').endOf('month').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "ytd":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('year').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastYear":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('year').subtract(12, 'months').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().endOf('year').subtract(12, 'months').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastQuarter":
          let currentQuaterNumber = moment().quarter();
          if (currentQuaterNumber === 1) { //if 1st quater
            this.reportFormConfig.controls['startDate'].setValue(moment().startOf('year').subtract(3, 'months').format('MM/DD/YYYY'));
            this.reportFormConfig.controls['endDate'].setValue(moment().startOf('year').subtract(1, 'day').format('MM/DD/YYYY'));
          } else {
            this.reportFormConfig.controls['startDate'].setValue(moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YYYY'));
            this.reportFormConfig.controls['endDate'].setValue(moment().subtract(1, 'quarter').endOf('quarter').format('MM/DD/YYYY'));
          }
          this.isPreSelectedFilter = true;
          break;
        case "thisQuarter":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('quarter').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().endOf('quarter').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "dateRange":
          this.reportFormConfig.controls.startDate.setValidators([Validators.required]);
          this.reportFormConfig.controls.startDate.updateValueAndValidity();
          this.reportFormConfig.controls.endDate.setValidators([Validators.required]);
          this.reportFormConfig.controls.endDate.updateValueAndValidity();
          if (this.isPreSelectedFilter) {
            this.reportFormConfig.controls['startDate'].setValue(null);
            this.reportFormConfig.controls['endDate'].setValue(null);
            try {
              this.reportFormConfig.controls['startDate'].enable();
              this.reportFormConfig.controls['endDate'].enable();
            } catch (error) {
              this.reportFormConfig.controls['endDate'].enable();
            }
          }
          this.isPreSelectedFilter = false;
          break;
        default:
          break;
      }
      this.clientClaimsDashboardFilterData = this.reportFormConfig.value;
    }
  }

  downloadPDF() {
    this.isDownloadPDF = { download: true, tabIndex: this.activeTabIndex, type: 'pdf' };
  }

  downloadExcel() {
    this.isDownloadPDF = { download: true, tabIndex: this.activeTabIndex, type: 'excel' };
  }

  isReportExpanded(event: any) {
    this.reportExpanded = event;
  }

  isDownloadButtonEnabled(event: boolean) {
    this.isDownloadDisabled = !event;
  }

  download(type) {
    if (type === 'excel') {
      this.downloadExcel();
    } else {
      this.downloadPDF();
    }
  }

  onGetActiveTab(event: any) {
    this.isDownloadPDF.download = false;
    this.isDownloadDisabled = true;
    if (event) {
      this.activeTabIndex = event.activeTabIndex;
      this.reportFormConfig.controls['activeTabIndex'].setValue(event.activeTabIndex);
      this.clientClaimsDashboardFilterData = this.reportFormConfig.value;
      this._storage.set('session', 'clientClaimDashboardActiveTab', { tabIndex: event.activeTabIndex + 1, name: event.el.heading });
    }
  }

  // Receives boolean event from children to reset flag for isDownloadPDF
  isDownloaded(event) {
    if (event) {
      setTimeout(() => {
        this.isDownloadPDF.download = false;
      }, 100);
    }
  }

}
