import { Component, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import { LedgerService } from '../../services';
import * as HeaderBreadCrumbActions from '../../action';
import { finalize } from 'rxjs/operators';

@Component({
    selector: "app-ledger",
    templateUrl: "./ledger.component.html"
})

export class LedgerComponent implements OnInit {
    loading: boolean = false;
    searchKeyword = '';
    ledgerList: any[] = [];

    constructor(private store: Store<{ bread_crumbs: any }>,
        private ledgerService: LedgerService
    ) {

    }

    ngOnInit() {
        this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Ledger', path: '/ledger' }));
        this.getClaims();
    }

    getClaims() {
        this.loading = true;
        this.ledgerService.getLedger()
            .pipe(finalize(() => { this.loading = false; }))
            .subscribe((response: any) => {
                this.ledgerList = response;
            }, (error) => {
                console.log("Error getting Claims List", error);
            })
    }
}