import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-episode-user-dependent-questionnaire',
  templateUrl: './episode-user-dependent-questionnaire.component.html',
  styleUrls: ['./episode-user-dependent-questionnaire.component.scss']
})
export class EpisodeUserDependentQuestionnaireComponent implements OnInit {
  dependentQuestionnaire: any
  child_tasks = []
  @Input() set dependent_questionnaire(dependent_questionnaire) {
    dependent_questionnaire.qnrs.qnrsQues.map(x => {
      if (x.status === 'COMPLETED') {
        x.question.ansOpt.map(y => {
          if (x.question.questionTypes.code === 'RADIO' || x.question.questionTypes.code === 'CHECKBOX') {
            const optionId = y.answerOptionId ? y.answerOptionId : y
            x.question.questionOptions.map(z => {
              if (optionId === z.id) {
                y.answerValue = z.optionValue
                y.taskQuesUuid = z.taskQuesUuid
                y.taskQnrsUuid = z.taskQnrsUuid
                y.taskMessageUuid = z.taskMessageUuid
                y.taskTodoUuid = z.taskTodoUuid
                y.taskSignatureUuid = z.taskSignatureUuid
                this.getChilds(z)
              }
              return z
            })
          } else {
            y.answerValue = y.answer
          }
          return y
        })
      }
      return x
    })
    this.dependentQuestionnaire = dependent_questionnaire
  }
  @Input() dependentTopicTasks;

  constructor() { }

  ngOnInit() {
  }

  getChilds(task) {
    setTimeout(() => {
      if (this.dependentTopicTasks && this.dependentTopicTasks.length > 0) {
        if (task.taskQuesUuid) {
          const dependentQuestion = this.dependentTopicTasks.find(y => y.uuid === task.taskQuesUuid)
          if (dependentQuestion) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentQuestion.uuid)
            if (!dependentExist) this.child_tasks.push(dependentQuestion)
          }
        }
        if (task.taskQnrsUuid) {
          const dependentQuestionnaire = this.dependentTopicTasks.find(y => y.uuid === task.taskQnrsUuid)
          if (dependentQuestionnaire) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentQuestionnaire.uuid)
            if (!dependentExist) this.child_tasks.push(dependentQuestionnaire)
          }
        }
        if (task.taskMessageUuid) {
          const dependentMessage = this.dependentTopicTasks.find(y => y.uuid === task.taskMessageUuid)
          if (dependentMessage) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentMessage.uuid)
            if (!dependentExist) this.child_tasks.push(dependentMessage)
          }
        }
        if (task.taskTodoUuid) {
          const dependentTodo = this.dependentTopicTasks.find(y => y.uuid === task.taskTodoUuid)
          if (dependentTodo) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentTodo.uuid)
            if (!dependentExist) this.child_tasks.push(dependentTodo)
          }
        }
        if (task.taskSignatureUuid) {
          const dependentSignature = this.dependentTopicTasks.find(y => y.uuid === task.taskSignatureUuid)
          if (dependentSignature) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentSignature.uuid)
            if (!dependentExist) this.child_tasks.push(dependentSignature)
          }
        }
      }
    }, 500);
  }

}
