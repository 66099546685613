import { Injectable } from "@angular/core";
import { APICONSTANTS } from "../constants";
import { HttpClientService } from "./http-client.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CompanyService {
  constructor(private http: HttpClientService) { }

  getCompanys(query?) {
    let url = query ? `${APICONSTANTS.COMPANY}?limit=${query.limit}&page=${query.page}&searchKeyword=${query.searchKeyword}&orderBy=${query.orderBy}&sortBy=${query.sortBy}` : `${APICONSTANTS.COMPANY}`
    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getParentCompanies() {
    return this.http.get(`${APICONSTANTS.COMPANY}?parentCompany=true`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getCompanyById(id) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.COMPANY}/by-ids?ids=${id}`).toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  saveCompany(company) {
    return this.http.post(`${APICONSTANTS.COMPANY}`, company).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateCompany(company) {
    return this.http.put(`${APICONSTANTS.COMPANY}`, company).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  savePayer(companyId, payerInfo) {
    return this.http.post(`${APICONSTANTS.COMPANY}/${companyId}/payers`, payerInfo).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updatePayer(companyId, payerInfo) {
    return this.http.put(`${APICONSTANTS.COMPANY}/${companyId}/payers/${payerInfo.id}`, payerInfo).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getImage(device) {
    let isMobile = device ? 'mobile' : 'web'
    return this.http
      .get(`${APICONSTANTS.COMPANY}/advertise/NOVANT/${isMobile}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getInsurers() {
    return this.http.get(`${APICONSTANTS.COMPANY}?type=insurer`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
}
