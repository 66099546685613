import { finalize } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CLIENT_ADMINS, DATE_TYPES, DatePickerOption, REPORT_DATES, REPORT_TAB_CODE, ROLE_CODES } from '../../../constants';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ClientService, ReportService, ToasterService, ReportConfigurationService } from '../../../services';
import { HolistaUtils, Storage } from '../../../utils';

@Component({
  selector: 'app-value-management-dashboard',
  templateUrl: './value-management-dashboard.component.html',
  styleUrls: ['./value-management-dashboard.component.scss']
})
export class ValueManagementDashboardComponent implements OnInit {
  reportFormConfig: FormGroup;
  isPreSelectedFilter: boolean = false;
  dateTypes = DATE_TYPES;
  REPORT_TAB_CODE = REPORT_TAB_CODE;
  ROLE_CODES = ROLE_CODES;
  reportDateList: object[] = REPORT_DATES;
  endDateOptions = this._holistaUtils.deepClone(DatePickerOption);
  startDateOptions = this._holistaUtils.deepClone(DatePickerOption);
  clientLists: any[] = [];
  categoryLists: any[] = [];
  user: any;
  loading = {
    clientList: false,
    networkConfig: false,
    tabList: false,
    categoryList: false,
    reportConfiguration: false,
  }
  valueManagementFilterData: any;
  @ViewChild('tabs', { static: true }) public tabs;
  @Input() reportParentCode;
  tabLists: any[] = [];
  isDownloadPDF = { download: false, tabIndex: 0, type: null };
  reportExpanded: boolean = false;
  isDownloadDisabled: boolean = true;
  disableDownloadPDF: boolean = false;
  isClientAdmin: boolean = false;
  activeTabIndex: number = 0;

  constructor(
    private _formBuilder: FormBuilder,
    private _toastr: ToasterService,
    private _storage: Storage,
    private _holistaUtils: HolistaUtils,
    private clientService: ClientService,
    private reportService: ReportService,
    private reportConfigurationService: ReportConfigurationService,
  ) {
    this.setReportFormConfig();
  }

  ngOnInit(): void {
    const loggedInUser = this._storage.get('local', 'loggedInUser')
    this.user = loggedInUser && this._storage.get('local', 'loggedInUser', 'user');
    if (CLIENT_ADMINS.includes(this.user.primaryRole)) {
      this.isClientAdmin = CLIENT_ADMINS.includes(this.user.primaryRole);
    }
    this.getClientList();
  }

  ngOnDestroy() {
    this._storage.remove('session', 'valueManagementDashboardActiveTab');
  }

  getClientList() {
    this.loading.clientList = true;
    this.clientService.getClients({ limit: 0, fields: 'code,name' })
      .pipe(finalize(() => { this.loading.clientList = false; }))
      .subscribe((res: any) => {
        res.rows.map(x => {
          x.label = x.name,
            x.value = x.code
          return x;
        })
        this.clientLists = this._holistaUtils.deepClone(res.rows);
        const clientCode = this.user.referenceCode;
        let hasDefaultClient;
        if (CLIENT_ADMINS.includes(this.user.primaryRole) && clientCode) {
          hasDefaultClient = this.clientLists.find((client) => client.code.toUpperCase() === clientCode);
        } else {
          hasDefaultClient = this.clientLists.find((client) => client.code.toUpperCase() === 'NOVANT');
        }
        let networkCode = "";
        if (hasDefaultClient) {
          networkCode = hasDefaultClient.networks.map((x) => {
            return x.code
          }).join('|');
        }
        this.reportFormConfig.controls.networkCode.setValue(networkCode ? networkCode : '');
        this.reportFormConfig.controls.clientCode.setValue(hasDefaultClient ? hasDefaultClient.code : '');
        this.reportFormConfig.controls.clientName.setValue(hasDefaultClient ? hasDefaultClient.name : '');
        hasDefaultClient && this.getReportConfigurationsForNetwork(hasDefaultClient);
        this.selectedReportDate();
      }, (error) => {
        console.log("Error getting Networks", error);
      })
  }

  getTabsForValueManagementDashboard() {
    this.loading.tabList = true;
    this.reportService.getReportsList({ parentCode: this.reportParentCode })
      .pipe(finalize(() => { this.loading.tabList = false; }))
      .subscribe(res => {
        const tabLists = res.sort((a, b) => a.order - b.order);
        if (tabLists && tabLists.length) {
          const toShowReport = this.reportFormConfig.get('toShowReport').value || [];
          tabLists.map((tab) => {
            const foundNavs = toShowReport.find(report => tab.code === report.reportCode);
            if (foundNavs) {
              this.tabLists.push(tab);
            }
          });
        }
        setTimeout(() => {
          const options = {
            storageType: 'session',
            storageKey: 'valueManagementDashboardActiveTab',
            tabList: this.tabLists,
          };
          const tabIndex = this._holistaUtils.getActiveTab(options);
          this.tabs.setActiveTab(tabIndex);
        }, 100);
      }, error => {
        console.log('error getting report types', error);
        this._toastr.showError(error);
      });
  }

  getReportConfigurationsForClient(clientCode?, networkConfigList?) {
    const reportConfigForNetwork = networkConfigList;
    this.reportFormConfig.controls['toShowReport'].setValue([]);
    this.loading.reportConfiguration = true;
    this.reportFormConfig.controls['isConfigLoading'].setValue(true);
    this.valueManagementFilterData = this.reportFormConfig.value;
    this.reportConfigurationService.getAll({ clientCode })
      .pipe(finalize(() => {
        this.loading.reportConfiguration = false;
        this.reportFormConfig.controls['isConfigLoading'].setValue(false);
      }))
      .subscribe((res) => {
        let toShowReport = [];
        this.tabLists = [];
        // Merge 'networkConfigList' array with values obtained from the 'clientConfig'
        res = reportConfigForNetwork.map(networkItem => {
          const clientItem = res.find(clientItem => clientItem.reportId === networkItem.reportId);
          const mergedItem = {
            ...networkItem,
            ...clientItem,
            name: networkItem.report.name,
          };
          if (mergedItem.report && mergedItem.status) {
            toShowReport.push(mergedItem);
          }
        });
        this.reportFormConfig.controls['toShowReport'].setValue(toShowReport);
        if (this.reportParentCode && res.length) {
          this.getTabsForValueManagementDashboard();
        }
      }, (error) => {
        console.log("Error getting report configurations.", error);
      })
  }

  getReportConfigurationsForNetwork(client) {
    this.loading.networkConfig = true;
    const networkCode = client.networks.map((network) => {
      return network.code
    }).join('|');
    this.reportFormConfig.controls.clientName.setValue(client.name);
    this.reportFormConfig.controls.networkCode.setValue(networkCode);
    this.reportConfigurationService.getAll({ networkCode })
      .pipe(finalize(() => { this.loading.networkConfig = false; }))
      .subscribe((res) => {
        if (res) {
          this.getReportConfigurationsForClient(client.code, res);
        }
      }, (error) => {
        console.log("Error getting report configurations.", error);
      })
  }

  /**
   * sets report form
   */
  setReportFormConfig() {
    this.reportFormConfig = this._formBuilder.group({
      clientCode: [''],
      clientName: [''],
      networkCode: [''],
      reportDate: ['ytd'],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      toShowReport: [],
      isConfigLoading: false,
      activeTabIndex: [0]
    });
  }

  /**
   * 
   * @param event 
   * @param type 
   */
  onDateChange(event?: any, type?: string) {
    const { startDate, endDate } = this.reportFormConfig.value
    let fromDate: any;
    let toDate: any;
    if (!(event && type)) {
      if (startDate) {
        fromDate = { year: fromDate.substr(-4, 4), month: fromDate.substr(-10, 2), day: fromDate.substr(-7, 2) }
      }
      if (endDate) {
        toDate = { year: toDate.substr(-4, 4), month: toDate.substr(-10, 2), day: toDate.substr(-7, 2) }
      }
    }
    if (type === 'startDate' || !(event && type)) {
      this.endDateOptions.disableUntil.year = fromDate ? fromDate.year : event ? event.date.year : 0
      this.endDateOptions.disableUntil.month = fromDate ? fromDate.month : event ? event.date.month : 0
      this.endDateOptions.disableUntil.day = fromDate ? fromDate.day : event ? event.date.day : 0
      this.reportFormConfig.controls['startDate'].setValue(event.actualDateFormatted)
    }

    if (type === 'endDate' || !(event && type)) {
      this.startDateOptions.disableSince.year = toDate ? toDate.year : event ? event.date.year : 0
      this.startDateOptions.disableSince.month = toDate ? toDate.month : event ? event.date.month : 0
      this.startDateOptions.disableSince.day = toDate ? toDate.day : event ? event.date.day : 0
      this.reportFormConfig.controls['endDate'].setValue(event.actualDateFormatted)
    }
    this.valueManagementFilterData = this.reportFormConfig.value;
  }

  /**
   * Report type
   */
  selectedReportDate() {
    if (this.reportFormConfig.value.reportDate) {
      switch (this.reportFormConfig.value.reportDate) {
        case "thisMonth":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('month').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().endOf('month').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastMonth":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('month').subtract(1, 'months').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().startOf('month').subtract(1, 'months').endOf('month').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "ytd":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('year').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastYear":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('year').subtract(12, 'months').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().endOf('year').subtract(12, 'months').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastQuarter":
          let currentQuaterNumber = moment().quarter();
          if (currentQuaterNumber === 1) { //if 1st quater
            this.reportFormConfig.controls['startDate'].setValue(moment().startOf('year').subtract(3, 'months').format('MM/DD/YYYY'));
            this.reportFormConfig.controls['endDate'].setValue(moment().startOf('year').subtract(1, 'day').format('MM/DD/YYYY'));
          } else {
            this.reportFormConfig.controls['startDate'].setValue(moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YYYY'));
            this.reportFormConfig.controls['endDate'].setValue(moment().subtract(1, 'quarter').endOf('quarter').format('MM/DD/YYYY'));
          }
          this.isPreSelectedFilter = true;
          break;
        case "thisQuarter":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('quarter').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().endOf('quarter').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "dateRange":
          this.reportFormConfig.controls.startDate.setValidators([Validators.required]);
          this.reportFormConfig.controls.startDate.updateValueAndValidity();
          this.reportFormConfig.controls.endDate.setValidators([Validators.required]);
          this.reportFormConfig.controls.endDate.updateValueAndValidity();
          if (this.isPreSelectedFilter) {
            try {
              this.reportFormConfig.controls['startDate'].setValue(null);
              this.reportFormConfig.controls['endDate'].setValue(null);
              this.reportFormConfig.controls['startDate'].enable();
              this.reportFormConfig.controls['endDate'].enable();
            } catch (error) {
              try {
                this.reportFormConfig.controls['startDate'].enable();
                this.reportFormConfig.controls['endDate'].enable();
              } catch (error) {
                this.reportFormConfig.controls['endDate'].enable();
              }
            }
          }
          this.isPreSelectedFilter = false;
          break;
        default:
          break;
      }
      this.valueManagementFilterData = this.reportFormConfig.value;
    }
  }

  onClientSelected(client) {
    this.isDownloadDisabled = true;
    this.getReportConfigurationsForNetwork(client);
  }

  downloadPDF() {
    this.isDownloadPDF = { download: true, tabIndex: this.activeTabIndex, type: 'pdf' };
  }

  downloadExcel() {
    this.isDownloadPDF = { download: true, tabIndex: this.activeTabIndex, type: 'excel' };
  }

  // Receives boolean event from children to reset flag for isDownloadPDF
  isDownloaded(event) {
    if (event) {
      setTimeout(() => {
        this.isDownloadPDF.download = false;
      }, 100);
    }
  }

  onGetActiveTab(event: any) {
    this.isDownloadPDF.download = false;
    this.isDownloadDisabled = true;
    if (event) {
      this.disableDownloadPDF = (event.activeTabIndex === 2) ? true : false;
      this.activeTabIndex = event.activeTabIndex;
      this.reportFormConfig.controls['activeTabIndex'].setValue(event.activeTabIndex);
      this.valueManagementFilterData = this.reportFormConfig.value;
      this._storage.set('session', 'valueManagementDashboardActiveTab', { tabIndex: event.activeTabIndex + 1, name: event.el.heading });
    }
  }

  isReportExpanded(event: any) {
    this.reportExpanded = event;
  }

  isDownloadButtonEnabled(event: boolean) {
    this.isDownloadDisabled = !event;
  }

  download(type) {
    if (type === 'excel') {
      this.downloadExcel();
    } else {
      this.downloadPDF();
    }
  }

}
