import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { KPI_VALUES, LINE_CHART_DATA_CONFIG, LINE_CHART_OPTIONS, MONTHS_NAME_FULL, MessageConstants, NO_DATA_IN_REPORT, REPORT_NAME_CODES, REPORT_NAME_TYPES, REPORT_TAB_INDEX } from '../../../../constants';
import { ReportService } from '../../../../services';
import { ChartUtils, DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility } from '../../../../utils';

@Component({
  selector: 'app-holista-cycle-time',
  templateUrl: './holista-cycle-time.component.html',
  styleUrls: ['./holista-cycle-time.component.scss']
})
export class HolistaCycleTimeComponent implements OnInit {

  processingCycleTimeForm: FormGroup;
  paymentCycleTimeForm: FormGroup;
  loading = {
    processingCycleTime: false,
    paymentCycleTime: false,
  }
  expand = {
    processingCycleTime: false,
    paymentCycleTime: false,
  }
  baseConfigs = {
    options: {},
    plugins: [],
    labels: [],
    dataset: [],
    chartType: '',
    actualData: null,
  };
  processingCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  paymentCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT);
  REPORT_NAME_TYPES = REPORT_NAME_TYPES;
  REPORT_NAME_CODES = REPORT_NAME_CODES;

  parsedClientClaimsFilterData: any;
  toShowReport: any[] = [];
  reportNameAndCodeList: any[] = [];
  yearOptions = {
    processingCycleTime: [],
    paymentCycleTime: [],
  };

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();

  @Input() set clientClaimsDashboardFilterData(data) {
    if (data && data.activeTabIndex === REPORT_TAB_INDEX.HOLISTA_CYCLE_TIME) {
      this.expand = {
        processingCycleTime: false,
        paymentCycleTime: false,
      };
      this.parsedClientClaimsFilterData = this._holistaUtils.deepClone(data);
      const { networkCode, endDate, toShowReport } = this.parsedClientClaimsFilterData;
      this.toShowReport = toShowReport && toShowReport.map((x) => x.reportCode);
      this.reportNameAndCodeList = toShowReport && toShowReport.map((x) => ({
        reportName: x.customReportName,
        reportCode: x.reportCode
      }));
      const year = +this._dateUtility.getYearFromDate(endDate);
      const params = {
        networkCode,
        year
      };
      const additionalConditons = toShowReport.length && networkCode && year;
      if (this.toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES) && additionalConditons) {
        this.getProcessingCycleTimeChart(params);
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES) && additionalConditons) {
        this.getPaymentCycleTimeChart(params);
      }
    }
  }
  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === REPORT_TAB_INDEX.HOLISTA_CYCLE_TIME) {
      const { networkName } = this.parsedClientClaimsFilterData;
      const tabsData = [];
      if (this.toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES) && this.processingCycleTimeChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.processingCycleTimeChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Holista Processing Cycle Times',
          tabName: 'Holista Processing Cycle Times',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.processingCycleTimeForm.value.selectedYear}`,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES) && this.paymentCycleTimeChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.paymentCycleTimeChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Holista Payment Cycle Times',
          tabName: 'Holista Payment Cycle Times',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.paymentCycleTimeForm.value.selectedYear}`,
        });
      }
      if (isDownloadPDF.type === 'pdf') {
        const downloadPDF = this._pdfUtility.convertToPDF(['holista-cycle-time'], 'Client Claims Dashboard - Holista Cycle Time', true, true);
        downloadPDF.finally(() => {
          this.downloadedPDF.emit(true);
        });
      }
      if (tabsData.length && isDownloadPDF.type === 'excel') {
        this._excelMultiTabUtility.exportToExcelMultiTab('Client Claims Dashboard - Holista Cycle Time', tabsData).finally(() => {
          this.downloadedPDF.emit(true);
        })
      }
    }
  }

  constructor(
    private _messageConstants: MessageConstants,
    private _pdfUtility: PDFUtility,
    private _dateUtility: DateUtility,
    private _holistaUtils: HolistaUtils,
    private _chartUtils: ChartUtils,
    private reportService: ReportService,
    private formBuilder: FormBuilder,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
    private _reportMappingUtils: ReportMappingUtility,
  ) { }

  ngOnInit(): void {
    this.setPayorCycleTimesCompanyForm();
    this.setPayorCycleTimesPayorForm();
  }

  ngOnDestroy() {
    this.resetFlags();
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
  }

  resetFlags() {
    this.loading = {
      processingCycleTime: false,
      paymentCycleTime: false,
    }
  }

  resetAllReports() {
    this.processingCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.paymentCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  setPayorCycleTimesCompanyForm() {
    this.processingCycleTimeForm = this.formBuilder.group({
      selectedYear: [null],
    });
  }

  setPayorCycleTimesPayorForm() {
    this.paymentCycleTimeForm = this.formBuilder.group({
      selectedYear: [null],
    });
  }

  getReport(code: string) {
    return this.reportNameAndCodeList.find(report => report.reportCode === code) || null;
  }

  getProcessingCycleTimeChart(params?, isYearSelected = false) {
    this.loading.processingCycleTime = true;
    this.reportService.getHolistaProcessCycleTimes(params)
      .pipe(finalize(() => {
        this.loading.processingCycleTime = false;
        const { startDate, endDate } = this.parsedClientClaimsFilterData;
        const startYear = +this._dateUtility.getYearFromDate(startDate);
        const endYear = +this._dateUtility.getYearFromDate(endDate);
        if (startYear && endYear) {
          this.yearOptions.processingCycleTime = this._holistaUtils.getYearOptionList(startYear, endYear);
        }
        this.processingCycleTimeForm.controls['selectedYear'].setValue(isYearSelected ? params.year : endYear);
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.processingCycleTimeChart.actualData = res;
          this.yearOptions.processingCycleTime = [];
          res = {
            ...res,
            datasets: res.dataSets.map((dataset) => {
              return {
                ...LINE_CHART_DATA_CONFIG,
                label: dataset.label,
                data: dataset.datas,
              }
            })
          };
          this.processingCycleTimeChart.chartType = 'line';
          this.processingCycleTimeChart.dataset = res.datasets;
          this.processingCycleTimeChart.labels = res.labels;
          this.processingCycleTimeChart.options = LINE_CHART_OPTIONS;
          this.processingCycleTimeChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.HOLISTA_PROCESSING_CYCLE_TIMES);
        } else {
          this.processingCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Holista Processing Cycle Times", error);
      })
  }

  getPaymentCycleTimeChart(params?, isYearSelected = false) {
    this.loading.paymentCycleTime = true;
    this.reportService.getHolistaPaymentCycleTimes(params)
      .pipe(finalize(() => {
        this.loading.paymentCycleTime = false;
        const { startDate, endDate } = this.parsedClientClaimsFilterData;
        const startYear = +this._dateUtility.getYearFromDate(startDate);
        const endYear = +this._dateUtility.getYearFromDate(endDate);
        if (startYear && endYear) {
          this.yearOptions.paymentCycleTime = this._holistaUtils.getYearOptionList(startYear, endYear);
        }
        this.paymentCycleTimeForm.controls['selectedYear'].setValue(isYearSelected ? params.year : endYear);
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.paymentCycleTimeChart.actualData = res;
          this.yearOptions.paymentCycleTime = [];
          res = {
            ...res,
            datasets: res.dataSets.map((dataset) => {
              return {
                ...LINE_CHART_DATA_CONFIG,
                label: dataset.label,
                data: dataset.datas,
              }
            })
          };
          this.paymentCycleTimeChart.chartType = 'line';
          this.paymentCycleTimeChart.dataset = res.datasets;
          this.paymentCycleTimeChart.labels = res.labels;
          this.paymentCycleTimeChart.options = LINE_CHART_OPTIONS;
          this.paymentCycleTimeChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.HOLISTA_PAYMENT_CYCLE_TIMES);
        } else {
          this.paymentCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Holista Payment Cycle Times", error);
      })
  }

  onViewToggle(reportCode, expand = false) {
    // this.reportExpanded.emit(expand); // Uncomment if download is not needed while expanding
    switch (reportCode) {
      case REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES:
        this.expand.processingCycleTime = expand;
        break;
      case REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES:
        this.expand.paymentCycleTime = expand;
        break;
      default:
        break;
    }
  }

  yearSelected(event, reportCode) {
    const params = {
      year: event.value,
      networkCode: this.parsedClientClaimsFilterData.networkCode,
    };
    switch (reportCode) {
      case REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES:
        this.getProcessingCycleTimeChart(params, true);
        break;
      case REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES:
        this.getPaymentCycleTimeChart(params, true);
        break;
      default:
        break;
    }
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = (
      this.processingCycleTimeChart.labels.length ||
      this.paymentCycleTimeChart.labels.length
    );
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

}
