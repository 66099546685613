import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MilestoneTopicService, MilestoneTaskService, ToasterService } from '../../../../services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { DependentTask } from '../../../../models';
import { HolistaUtils, AccessType } from '../../../../utils';
import { ACCESS_DENIED, ADD_SUCCESS, RestrictSpace, TrimFields, UPDATE_SUCCESS } from '../../../../constants';

@Component({
  selector: 'app-milestone-topic',
  templateUrl: './milestone-topic.component.html',
  styleUrls: ['./milestone-topic.component.scss']
})
export class MilestoneTopicComponent implements OnInit {
  topics = [];
  milestone: any;
  loading = false;
  topicForm: FormGroup;
  isSubmitEnabled = false;
  submitted = false;
  triggerMilestoneName = '';
  activeTopicId = -1;
  dependentTasks = new DependentTask();
  dataToBeDelete: any;
  @ViewChild('topicModal', { static: true }) public topicModal;
  @ViewChild('deleteTopicModal', { static: true }) public deleteTopicModal;
  @Input() public milestones;
  @Input() set selected_milestone(selected_milestone) {
    if (selected_milestone.id) {
      this.milestone = selected_milestone;
      let trigger_milestone = this.milestones.find(x => x.id == this.milestone.triggerMilestoneId);
      this.milestone.triggerMilestoneName = trigger_milestone && trigger_milestone.name ? trigger_milestone.name : '';
      this.getTopics(selected_milestone.id);
    }
  }
  // @Input() selected_milestone;
  constructor(
    private milestoneTopicService: MilestoneTopicService,
    private milestoneTaskService: MilestoneTaskService,
    private formBuilder: FormBuilder,
    private _toastr: ToasterService,
    private holistaUtils: HolistaUtils,
    private utilityAccess: AccessType
  ) {
    this.setTopicForm();
  }
  setTopicForm() {
    this.topicForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      milestoneId: [null],
      topic: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      patientTopic: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      sequence: [null],
      status: []
    });
  }

  ngOnInit() { }

  closeModal(modal, form) {
    this.topicForm.reset();
    this[modal].hide();
    this.isSubmitEnabled = false;
    this.submitted = false;
  }
  getTopics(milestone_id) {
    this.loading = true;
    this.topics = [];
    this.dependentTasks = new DependentTask();
    this.milestoneTaskService.getTasks(milestone_id).subscribe(res => {
      this.topics = [];
      this.loading = false;
      if (res.length != 0) {
        // this.topics=res;
        res.map(x => {
          x.taskMessages
            .filter(a => a.isDependent)
            .map(b => {
              b['pathwayId'] = this.milestone.pathwayId;
              b['milestoneId'] = this.milestone.id;
              this.dependentTasks.taskMessages.push(b);
            });
          x.taskQuestions
            .filter(a => a.isDependent)
            .map(b => {
              b['pathwayId'] = this.milestone.pathwayId;
              b['milestoneId'] = this.milestone.id;
              this.dependentTasks.taskQuestions.push(b);
            });
          x.taskTodos
            .filter(a => a.isDependent)
            .map(b => {
              b['pathwayId'] = this.milestone.pathwayId;
              b['milestoneId'] = this.milestone.id;
              this.dependentTasks.taskTodos.push(b);
            });
          x.taskSignatures
            .filter(a => a.isDependent)
            .map(b => {
              b['pathwayId'] = this.milestone.pathwayId;
              b['milestoneId'] = this.milestone.id;
              this.dependentTasks.taskSignatures.push(b);
            });
          x.taskQnrs
            .filter(a => a.isDependent)
            .map(b => {
              b['pathwayId'] = this.milestone.pathwayId;
              b['milestoneId'] = this.milestone.id;
              this.dependentTasks.taskQnrs.push(b);
            });
          x.taskMessages = x.taskMessages.filter(a => !a.isDependent);
          x.taskQuestions = x.taskQuestions.filter(a => !a.isDependent);
          x.taskTodos = x.taskTodos.filter(a => !a.isDependent);
          x.taskQnrs = x.taskQnrs.filter(a => !a.isDependent);
          x.taskSignatures = x.taskSignatures.filter(a => !a.isDependent);
          this.topics.push(x);
        });
        this.topics.map(x => {
          x['pathwayId'] = this.milestone.pathwayId;
          x['milestoneId'] = this.milestone.id;
        });
        this.topics = [...this.holistaUtils.sortBy(this.topics, 'sequence')];
        if (this.topics && this.topics.length > 0) this.passDataToTask(this.topics[0].id);
      }
    });
  }

  createTopic() {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) this.topicModal.show();
    else this._toastr.displayWarning(ACCESS_DENIED);
  }

  editTopic(topic) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      // topic.triggerPeriod = 'days';
      this.topicForm.patchValue(topic);
      this.topicModal.show();
    } else this._toastr.displayWarning(ACCESS_DENIED);
  }
  submitTopic() {
    this.submitted = true;
    // if (this.topicForm.value.triggerPeriod == 'weeks' && this.topicForm.value.escalationDays && this.topicForm.value.escalationDays > 0) {
    //   this.topicForm.controls.escalationDays.setValue(this.topicForm.value.escalationDays * 7);
    // }
    this.topicForm.controls.milestoneId.setValue(this.topicForm.value.milestoneId ? this.topicForm.value.milestoneId : this.milestone.id ? this.milestone.id : null);
    if (this.topicForm.valid) {
      this.isSubmitEnabled = true;
      TrimFields.PathwayMilestoneTopic.forEach(element => {
        this.topicForm.value[element] = this.topicForm.value[element] ? this.topicForm.value[element].trim() : this.topicForm.value[element];
      });
      if (this.topicForm.value.id) this.updateTopic();
      else this.saveTopic();
    }
  }
  saveTopic() {
    this.topicForm.controls.sequence.setValue(this.topics ? this.topics.length + 1 : 1);
    let topic = Object.assign(this.topicForm.value);
    topic.pathwayId = this.milestone.pathwayId;
    this.milestoneTopicService
      .saveTopic(topic)
      .pipe(
        finalize(() => {
          this.isSubmitEnabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshTopics(res, 'saved');
        },
        error => {
          console.log(error);
        }
      );
  }
  updateTopic() {
    let topic = Object.assign(this.topicForm.value);
    topic.pathwayId = this.milestone.pathwayId;
    this.milestoneTopicService
      .updateTopic(topic)
      .pipe(
        finalize(() => {
          this.isSubmitEnabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshTopics(res, 'updated');
        },
        error => {
          console.log(error);
        }
      );
  }
  refreshTopics(response, action) {
    if (response.message) {
      this._toastr.displayError(response.message)
    } else {
      let topic = action === 'saved' ? response.data : response.data[0];
      topic.pathwayId = this.milestone.pathwayId;
      topic.milestoneId = this.milestone.id;
      if (this.topics && this.topics.length > 0) {
        let index = this.topics.findIndex(x => x.id === topic.id);
        if (index > -1) {
          topic.taskTodos = this.topics[index].taskTodos;
          topic.taskSignatures = this.topics[index].taskSignatures;
          topic.taskMessages = this.topics[index].taskMessages;
          topic.taskQuestions = this.topics[index].taskQuestions;
          topic.taskQnrs = this.topics[index].taskQnrs;
          this.topics[index] = topic;
        } else {
          topic.taskTodos = [];
          topic.taskSignatures = [];
          topic.taskMessages = [];
          topic.taskQuestions = [];
          topic.taskQnrs = [];
          this.topics.push(topic);
        }
      } else {
        topic.taskTodos = [];
        topic.taskSignatures = [];
        topic.taskMessages = [];
        topic.taskQuestions = [];
        topic.taskQnrs = [];
        this.topics.push(topic);
      }
      action === 'updated' ? this._toastr.displaySuccess(UPDATE_SUCCESS) : this._toastr.displaySuccess(ADD_SUCCESS);
    }
    this.closeModal('topicModal', 'topicForm');
  }

  updateTopicHandler(event) {
    this.activeTopicId = -1;
    if (event && event.isDependent) {
      let index = this.dependentTasks[`${event.active_task}`].findIndex(x => x.id == event.id);
      if (index > -1) this.dependentTasks[`${event.active_task}`][index] = event;
      else this.dependentTasks[`${event.active_task}`].push(event);
      if (event.topic) {
        event.topic.taskQuestions.map(y => {
          y.valueChanged = false;
          if (y.isDependent) {
            y.questions.questionOptions.map(z => {
              if (z.id == event.taskField.optionId) {
                z[`${event.taskField.id}`] = event.id;
                y.valueChanged = true;
              }
              return z;
            });
            let idx = this.dependentTasks.taskQuestions.findIndex(x => x.questionId == y.questionId && y.valueChanged);
            if (idx > -1) this.dependentTasks.taskQuestions[idx] = y;
          }
        });

        event.topic.taskQnrs.map(a => {
          a.valueChanged = false;
          if (a.isDependent) {
            a.qnrs.qnrsQues.map(b => {
              b.question.questionOptions.map(z => {
                if (z.id == event.taskField.optionId) {
                  z[`${event.taskField.id}`] = event.id;
                  a.valueChanged = true;
                }
                return z;
              });
              return b;
            });
            let idx = this.dependentTasks.taskQnrs.findIndex(x => x.questionnaireId == a.questionnaireId && a.valueChanged);
            if (idx > -1) this.dependentTasks.taskQnrs[idx] = a;
          }
        });

        this.topics.map(x => {
          if (x.id == event.topic.id) {
            x.taskMessages = event.topic.taskMessages.filter(a => !a.isDependent);
            x.taskQuestions = event.topic.taskQuestions.filter(a => !a.isDependent);
            x.taskQuestions.map(y => {
              y.questions.questionOptions.map(z => {
                if (z.id == event.taskField.optionId) z[`${event.taskField.id}`] = event.id;
                return z;
              });
              return y;
            });
            x.taskQnrs = event.topic.taskQnrs.filter(a => !a.isDependent);
            x.taskQnrs.map(y => {
              y.qnrs.qnrsQues.map(a => {
                a.question.questionOptions.map(z => {
                  if (z.id == event.taskField.optionId) z[`${event.taskField.id}`] = event.id;
                  return z;
                });
                return a;
              });
              return y;
            });
            x.taskTodos = event.topic.taskTodos.filter(a => !a.isDependent);
            x.taskSignatures = event.topic.taskSignatures.filter(a => !a.isDependent);
          }
          return x;
        });
      }
      setTimeout(() => {
        this.activeTopicId = event.active_topicId;
      });
    } else {
      let topic_index = this.topics.findIndex(x => x.id == event.active_topicId);
      if (topic_index > -1) {
        if (this.topics[topic_index][event.active_task] && this.topics[topic_index][event.active_task].length > 0) {
          let task_index = this.topics[topic_index][event.active_task].findIndex(y => y.id == event.id);
          if (task_index > -1) {
            this.topics[topic_index][event.active_task][task_index] = event;
          } else {
            this.topics[topic_index][event.active_task].push(event);
          }
        } else {
          this.topics[topic_index][event.active_task].push(event);
        }
      }
      setTimeout(() => {
        this.activeTopicId = event.active_topicId;
      });
    }
  }

  passDataToTask(id) {
    this.activeTopicId = id;
  }

  topicToDelete(topic) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      topic['source'] = 'Pathway';
      topic['type'] = 'Topic';
      topic['displayName'] = topic.topic;
      this.dataToBeDelete = topic;
    } else this._toastr.displayWarning(ACCESS_DENIED);
  }

  deleteTopic(e) {
    let index = this.topics.findIndex(x => x.id == e.id);
    if (index > -1) {
      this.topics.splice(index, 1);
    }
    this.dataToBeDelete = null;
  }

  cancelDelete(e) {
    this.dataToBeDelete = e;
  }

  deleteDependent(task) {
    let temp_dependent_task = JSON.parse(JSON.stringify(this.dependentTasks));
    if (task.field == 'todo') {
      let index = temp_dependent_task.taskTodos.findIndex(x => x.id == task.id);
      if (index > -1) temp_dependent_task.taskTodos.splice(index, 1);
    }
    if (task.field == 'signature') {
      let index = temp_dependent_task.taskSignatures.findIndex(x => x.id == task.id);
      if (index > -1) temp_dependent_task.taskSignatures.splice(index, 1);
    }
    if (task.field == 'message') {
      let index = temp_dependent_task.taskMessages.findIndex(x => x.id == task.id);
      if (index > -1) temp_dependent_task.taskMessages.splice(index, 1);
    }

    if (task.field == 'question') {
      let index = temp_dependent_task.taskQuestions.findIndex(x => x.id == task.id);
      if (index > -1) temp_dependent_task.taskQuestions.splice(index, 1);
    }

    if (task.field == 'questionnaire') {
      let index = temp_dependent_task.taskQnrs.findIndex(x => x.id == task.id);
      if (index > -1) temp_dependent_task.taskQnrs.splice(index, 1);
    }

    this.dependentTasks = JSON.parse(JSON.stringify(temp_dependent_task));
  }

  deleteTask(task) {
    this.topics.map(x => {
      if (task.field == 'todo') {
        let index = x.taskTodos.findIndex(x => x.id == task.id);
        if (index > -1) {
          x.taskTodos.splice(index, 1);
        }
      }
      if (task.field == 'signature') {
        let index = x.taskSignatures.findIndex(x => x.id == task.id);
        if (index > -1) {
          x.taskSignatures.splice(index, 1);
        }
      }
      if (task.field == 'message') {
        let index = x.taskMessages.findIndex(x => x.id == task.id);
        if (index > -1) {
          x.taskMessages.splice(index, 1);
        }
      }

      if (task.field == 'question') {
        let index = x.taskQuestions.findIndex(x => x.id == task.id);
        if (index > -1) {
          x.taskQuestions.splice(index, 1);
        }
      }

      if (task.field == 'questionnaire') {
        let index = x.taskQnrs.findIndex(x => x.id == task.id);
        if (index > -1) {
          x.taskQnrs.splice(index, 1);
        }
      }
    });
  }
}
