import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class BundleCostService {

  constructor(private http: HttpClientService,
    private https: HttpClient
  ) { }

  getBundleCost(query?) {
    return this.http.get(`${APICONSTANTS.BUNDLECOST}`, query).pipe(
      map((res: any) => {
        res.data.rows.map(x => {
          x.displayBundle = x.bundle && x.bundle.name ? x.bundle.name : '';
          x.displayMarket = x.market && x.market.code ? x.market.code : '';
          x.costComponents.map(y => {
            y.name = y.component.name;
            y.isPrimary = y.component ? y.component.isPrimary : false;
            y.isMedicalCenter = y.component ? y.component.isMedicalCenter : false;
            y.isFundingRequestTrigger = y.component ? y.component.isFundingRequestTrigger : false;
            return y;
          })
          return x;
        })
        return res.data;
      })
    );
  }

  saveBundleCost(bundlecost) {
    return this.http.post(`${APICONSTANTS.BUNDLECOST}`, bundlecost).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  updateBundleCost(bundlecost) {
    return this.http.put(`${APICONSTANTS.BUNDLECOST}/${bundlecost.uuid}`, bundlecost).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  searchProviderName(search_text) {
    return this.http.get(`${APICONSTANTS.BUNDLECOST}/${search_text}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getBundleCostByBundleUuid(queryParam?) {
    return this.http.get(`${APICONSTANTS.BUNDLECOST}`, queryParam)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getProvidersByBundleComponentUuid(bundleComponents) {
    return this.http.post(`${APICONSTANTS.SERVICE_BUNDLE}/${APICONSTANTS.PROVIDERS_BY_BUNDLE_COMPONENT}`, bundleComponents).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  deleteBundleCost(uuid) {
    return this.http.remove(`${APICONSTANTS.BUNDLECOST}/${uuid}`).pipe(
      map((res: any) => {
        return res.data;
      })
    )
  }

  getAllMarket(queryParam?) {
    return this.http.get(`${APICONSTANTS.MARKET}`, queryParam).pipe(
      map((res: any) => {
        return res.data;
      })
    )
  }

  getvitafyProviders(query) {
    return this.http.get(`${APICONSTANTS.PROVIDER}`, query).pipe(
      map((res: any) => {
        return res.data;
      })
    )
  }

  getBundleComponentCostProvider(query) {
    query.limit = query.limit || 0
    return this.http.get(`${APICONSTANTS.COMPONENT_COST_PROVIDER}`, query)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  saveBundleComponentCostProvider(provider) {
    return this.http.post(`${APICONSTANTS.COMPONENT_COST_PROVIDER}`, provider)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  deleteBundleComponentCostProvider(uuid) {
    return this.http.remove(`${APICONSTANTS.COMPONENT_COST_PROVIDER}/${uuid}`,)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  getVitafyProviderById(providerId, networkCode: string) {
    return this.http.get(`${APICONSTANTS.PROVIDER}/${providerId}?networkCode=${networkCode}`)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  getBundleComponentsByBundleCostId(bundleCostUuid: string) {
    return this.http.get(`${APICONSTANTS.BUNDLE_COST_SERVICE}/${bundleCostUuid}/component-costs?limit=0`).pipe(map((res: any) => {
      return res.data
    }))
  }

  getComponentCostProviders(market_code: string) {
    return this.http.get(`${APICONSTANTS.COMPONENT_COST_PROVIDER}/bundle-component-cp`,
      { ...(market_code && { market_code }) })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }
}
