<div class="m-0 p-0">
  <div class="d-flex justify-content-between align-items-center px-3 pt-3">
    <span class="d-flex">
      <span
        class="badge badge-sm bg-primary-dark text-white z-depth-0"
        *ngIf="reportingPeriod.startDate && reportingPeriod.endDate">
        Reporting Period
        <span>
          - {{ reportingPeriod.startDate }} -
          {{ reportingPeriod.endDate }}
        </span>
      </span>
    </span>
  </div>
</div>

<div class="px-3" style="min-height: 500px">
  <div class="mt-3">
    <mdb-card>
      <mdb-card-header class="pointer-cursor" (click)="toggleCard()">
        <div class="d-flex justify-content-between align-items-center">
          <p class="m-0 font-weight-bold">Filter</p>
          <span class="link pointer-cursor ml-3">
            <i [class]="expandAdvanceFiltering ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
          </span>
        </div>
      </mdb-card-header>
      <mdb-card-body class="py-1 pb-3 px-3" *ngIf="expandAdvanceFiltering">
        <form [formGroup]="portalQuestionAdvanceFilteringForm">
          <div class="form-row mt-2">
            <div class="col-4">
              <label for="categoryId">Category *</label>
              <span
                class="spinner-grow spinner-grow-sm ml-2"
                role="status"
                aria-hidden="true"
                *ngIf="loading.categoryList"></span>
              <div
                [ngClass]="{
                  'is-invalid':
                    filterSubmitted && portalQuestionAdvanceFilteringForm.get('categoryId').invalid
                }"
                class="asdfasdfasdf">
                <app-select
                  [options]="list.categoryList | sort : 'label'"
                  id="categoryId"
                  [placeholder]="'Select category'"
                  [filterEnabled]="true"
                  [allowClear]="true"
                  mdbInput
                  (onSelected)="onCategorySelected($event)"
                  [control]="portalQuestionAdvanceFilteringForm.controls['categoryId']">
                </app-select>
              </div>
            </div>
            <div class="col-4">
              <label for="subCategoryId">Sub Category</label>
              <span
                class="spinner-grow spinner-grow-sm ml-2"
                role="status"
                aria-hidden="true"
                *ngIf="loading.subCategoryList"></span>
              <app-select
                [options]="list.subCategoryList | sort : 'label'"
                id="subCategoryId"
                [placeholder]="'Select sub category'"
                [filterEnabled]="true"
                [allowClear]="true"
                mdbInput
                [control]="portalQuestionAdvanceFilteringForm.controls['subCategoryId']">
              </app-select>
            </div>
            <div class="col-4">
              <label for="milestoneName">Milestone Name</label>
              <span
                class="spinner-grow spinner-grow-sm ml-2"
                role="status"
                aria-hidden="true"
                *ngIf="loading.milestones"></span>
              <app-select
                [options]="list.milestones | sort : 'label'"
                id="milestoneName"
                [placeholder]="'Select milestone name'"
                [filterEnabled]="true"
                [allowClear]="true"
                mdbInput
                [control]="portalQuestionAdvanceFilteringForm.controls['milestoneName']">
              </app-select>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="col-4">
              <label for="questionnaireName">Questionnaire Name</label>
              <span
                class="spinner-grow spinner-grow-sm ml-2"
                role="status"
                aria-hidden="true"
                *ngIf="loading.questionnaires"></span>
              <app-select
                [options]="list.questionnaires | sort : 'label'"
                id="questionnaireName"
                [placeholder]="'Select questionnaire'"
                [filterEnabled]="true"
                [allowClear]="true"
                mdbInput
                [control]="portalQuestionAdvanceFilteringForm.controls['questionnaireName']">
              </app-select>
            </div>
            <div class="col-4">
              <label for="questionName">Question Name</label>
              <span
                class="spinner-grow spinner-grow-sm ml-2"
                role="status"
                aria-hidden="true"
                *ngIf="loading.questions"></span>
              <app-select
                [options]="list.questions | sort : 'label'"
                id="questionName"
                [placeholder]="'Select question'"
                [filterEnabled]="true"
                [allowClear]="true"
                mdbInput
                [control]="portalQuestionAdvanceFilteringForm.controls['questionName']">
              </app-select>
            </div>
            <div class="col-4">
              <label for="consentedComments">Consented Comments</label>
              <app-select
                [options]="list.consentedComments"
                id="consentedComments"
                [placeholder]="'Select consented comments'"
                [filterEnabled]="false"
                [allowClear]="true"
                mdbInput
                [control]="portalQuestionAdvanceFilteringForm.controls['isConsented']">
              </app-select>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end align-items-center mt-3">
          <button
            type="button"
            class="waves-light btn grey lighten-3 text-center text-uppercase m-0"
            mdbWavesEffect
            [disabled]="loading.portalQuestionDetail"
            (click)="resetFilter('clear')"
            id="downloadAlert">
            <span class="d-none d-sm-inline-block ml-1">Clear</span>
          </button>
          <button
            type="button"
            class="waves-light btn btn-secondary text-center text-white text-uppercase m-0 ml-2"
            mdbWavesEffect
            [disabled]="loading.portalQuestionDetail"
            (click)="submitFilter()"
            id="downloadAlert">
            <span class="d-none d-sm-inline-block ml-1">Apply Filter</span>
          </button>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
  <div class="mt-3">
    <div *ngIf="loading.portalQuestionDetail">
      <app-loader [loader]="loading.portalQuestionDetail"></app-loader>
    </div>
    <div style="margin-top: -15px">
      <div id="portal-question-detail">
        <app-table
          *ngIf="!loading.portalQuestionDetail && portalQuestionDetailTableData.data.length"
          [report]="portalQuestionDetailTableData"
          [exportTable]="false"
          [module]="'report'"
          (onScrollToBottom)="scrollToBottom($event)"
          [lazyLoading]="loading.lazyLoadingPortalQuestionDetail"
          tableHeight="600px">
          <ng-template #lazyLoadingLoader>
            <td class="p-1" colspan="100%" *ngIf="loading.lazyLoadingPortalQuestionDetail">
              <app-loader [loader]="true" [loaderSmall]="true"></app-loader>
            </td>
          </ng-template>
        </app-table>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100 mt-5"
      *ngIf="
        !loading.portalQuestionDetail &&
        !portalQuestionDetailTableData.data.length &&
        filterSubmitted &&
        isfilterValidlySubmitted
      ">
      <div class="text-center">
        <i class="icofont-file-spreadsheet icofont-4x text-secondary"></i>
        <p class="mt-2">{{ noDataMessage.value }}</p>
      </div>
    </div>
  </div>
</div>
