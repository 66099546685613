export class Dashboard {
  count={
    document:null,
    milestone:null,
    assignedUser:null,
    episodes:null,
    task:null
  };
  documents=[];
  milestones=[];
  assignedUsers=[];
  tasks=[];
  episodes=[];
}
