import { Questions } from './model.questions'

export class QuestionnaireQuestions {
    taskId: number
    name: string
    description: string
    sequence:number
    parentUuid:string
    optionId:number
    isDependent:boolean=false
    questions: Questions[] = [
    ]
}