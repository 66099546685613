<mdb-select
  *ngIf="!isTemplateDriven"
  [formControl]="control"
  [class]="class"
  [style]="style"
  filterPlaceholder="Search.."
  [options]="options | sort : 'label'"
  [id]="id"
  [placeholder]="placeholder"
  [multiple]="multiple"
  [enableSelectAll]="enableSelectAll"
  [allowClear]="allowClear"
  [visibleOptions]="visibleOptions"
  [disabled]="disabled"
  [filterEnabled]="filterEnabled"
  [appendToBody]="appendToBody"
  [highlightFirst]="false"
  (selected)="onSelected.emit($event)"
  (deselected)="onDeselected.emit($event)"
  [ngClass]="inputClass">
</mdb-select>

<mdb-select
  *ngIf="isTemplateDriven"
  [(ngModel)]="selected"
  (ngModelChange)="selectedChange.emit($event)"
  class="search-dropdown"
  [style]="style"
  filterPlaceholder="Search.."
  [options]="options | sort : 'label'"
  [id]="id"
  [placeholder]="placeholder"
  [multiple]="multiple"
  [enableSelectAll]="enableSelectAll"
  [allowClear]="allowClear"
  [visibleOptions]="visibleOptions"
  [disabled]="disabled"
  [filterEnabled]="filterEnabled"
  [highlightFirst]="false"
  (selected)="onSelected.emit($event)"
  (deselected)="onDeselected.emit($event)"
  [ngClass]="inputClass">
</mdb-select>
