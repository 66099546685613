<div
  class="d-flex justify-content-start align-items-start"
  *ngIf="taskSignature && taskSignature.id">
  <div class="icon-block">
    <i class="fas fa-file-signature skin-text-primary-dark ml-1" style="font-size: 20px"></i>
  </div>
  <mdb-checkbox
    [filledIn]="true"
    class="align-self-center"
    *ngIf="user && taskSignature.isActivated && taskSignature.assignedTo == user.id"
    [checked]="taskSignature.status && taskSignature.status !== 'DUE' ? true : false"
    [disabled]="
      !(days_difference >= 0) ||
      taskSignature.episodeStatus === 'CANCELLED' ||
      taskSignature.episodeStatus === 'CLOSED' ||
      taskSignature.episodeStatus === 'COMPLETED' ||
      taskSignature.status ||
      !utilityAccess.searchAccess('EM', 'isEditable', false)
    "
    (change)="$event.checked && redirectToDocuSignTemplate(taskSignature)">
  </mdb-checkbox>
  <div class="d-flex justify-content-between w-100 align-items-start">
    <span class="pr-4">
      <p class="font-weight-400">
        {{ taskSignature.name }}
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
          *ngIf="isLoading.docuSign || taskSignature.status === 'IN-PROGRESS'"></span>
      </p>
      <a
        class="text-break"
        [style.opacity]="isLoading.docuSign || taskSignature.status === 'IN-PROGRESS' ? 0.5 : 1"
        [style.pointer-events]="
          isLoading.docuSign || taskSignature.status === 'IN-PROGRESS' ? 'none' : 'auto'
        "
        (click)="
          user &&
          taskSignature.isActivated &&
          taskSignature.assignedTo == user.id &&
          !taskSignature.status
            ? redirectToDocuSignTemplate(taskSignature)
            : handleRedirect(taskSignature.taskTodoLink)
        "
        target="_blank"
        *ngIf="taskSignature.taskTodoLink"
        >{{ taskSignature.documentDisplayName }}</a
      >
    </span>
    <div class="d-flex align-items-center">
      <div
        *ngIf="
          taskSignature.status === 'COMPLETED' &&
          taskSignature.assignedTo == user.id &&
          !taskSignature.completionReason &&
          !showReasonOption &&
          (taskSignature.assignedToRole === 'ES' || taskSignature.assignedToRole === 'CC')
        "></div>
      <div
        style="width: 240px"
        *ngIf="taskSignature.completionReason || showReasonOption"
        [mdbTooltip]="
          taskSignature.completionReason &&
          (taskSignature.episodeStatus == 'CANCELLED' ||
            taskSignature.episodeStatus == 'CLOSED' ||
            taskSignature.episodeStatus == 'COMPLETED' ||
            taskSignature.assignedTo != user.id)
            ? taskSignature.completionReason
            : ''
        "
        placement="left">
        <mdb-select
          [options]="reasonList"
          placeholder="Select reason"
          [(ngModel)]="taskSignature.completionReason"
          [multiple]="false"
          [enableSelectAll]="false"
          [highlightFirst]="false"
          [disabled]="
            taskSignature.assignedTo != user.id ||
            taskSignature.episodeStatus == 'CANCELLED' ||
            taskSignature.episodeStatus == 'CLOSED' ||
            taskSignature.episodeStatus == 'COMPLETED'
          ">
        </mdb-select>
      </div>
      <div
        *ngIf="
          taskSignature.status &&
          taskSignature.status.toLowerCase() !== 'declined' &&
          taskSignature.isActivated
        "
        class="chip chip-sm mb-0 ml-3 alert alert-info"
        [ngClass]="taskSignature.status ? taskSignature.status.replace(' ', '').toLowerCase() : ''">
        <span class="text-sm status-label">
          {{ taskSignature.status ? taskSignature.status?.toLowerCase() : '' }}
          {{ taskSignature.status == 'DUE' ? taskSignature.escalationDays : '' }}</span
        >
      </div>

      <span
        class="action-item position-relative"
        *ngIf="
          taskSignature.status != 'COMPLETED' &&
          !(
            taskSignature.episodeStatus == 'CLOSED' ||
            taskSignature.episodeStatus == 'COMPLETED' ||
            taskSignature.episodeStatus == 'CANCELLED'
          ) &&
          taskSignature.milestoneStatus !== 'IN PROGRESS' &&
          !taskSignature.triggerDatePassed
        ">
        <i
          class="icofont-ui-edit icofont-sm mr-2"
          #actionBtn
          (click)="editTaskHandler(taskSignature)"></i>
        <i
          class="icofont-ui-delete icofont-sm mr-2"
          #actionBtn
          (click)="deleteTaskHandler(taskSignature)"></i>
      </span>
    </div>
  </div>

  <app-milestone-topic-delete
    [deleteData]="taskToBeDelete"
    (deletedData)="deleteTask($event)"
    (cancelDelete)="cancelDelete($event)">
  </app-milestone-topic-delete>
</div>
