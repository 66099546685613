import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private db: IDBDatabase;

  constructor() {
    this.openDatabase();
  }

  private async openDatabase() {
    return new Promise<void>((resolve, reject) => {
      const request = window.indexedDB.open('MyDatabase', 1);

      request.onerror = (event) => {
        console.error("IndexedDB error:", event);
        reject("Error opening IndexedDB database");
      };

      request.onsuccess = (event) => {
        this.db = (event.target as any).result;
        resolve();
      };

      request.onupgradeneeded = (event) => {
        this.db = (event.target as any).result;
        if (!this.db.objectStoreNames.contains('myStore')) {
          this.db.createObjectStore('myStore', { keyPath: 'id', autoIncrement: true });
        }
      };
    });
  }

  async addData(data: any): Promise<void> {
    const transaction = this.db.transaction(['myStore'], 'readwrite');
    const store = transaction.objectStore('myStore');
    store.add(data);
  }

  async getData(): Promise<any[]> {
    const transaction = this.db.transaction(['myStore'], 'readonly');
    const store = transaction.objectStore('myStore');
    const request = store.getAll();
    return new Promise<any[]>((resolve, reject) => {
      request.onsuccess = (event) => {
        resolve(request.result);
      };
      request.onerror = (event) => {
        reject("Error fetching data from IndexedDB");
      };
    });
  }

  async clearData(): Promise<void> {
    const transaction = this.db.transaction(['myStore'], 'readwrite');
    const store = transaction.objectStore('myStore');
    store.clear();
  }
}