import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class BundleService {

  constructor(private http: HttpClientService) { }

  getServiceBundle(data) {
    return this.http.get(`${APICONSTANTS.SERVICE_BUNDLE}`, data).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveServiceBundle(serviceBundle) {
    return this.http.post(`${APICONSTANTS.SERVICE_BUNDLE}`, serviceBundle).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateServiceBundle(serviceBundle) {
    return this.http.put(`${APICONSTANTS.SERVICE_BUNDLE}/${serviceBundle.uuid}`, serviceBundle).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  deleteServiceBundle(uuid) {
    return this.http.remove(`${APICONSTANTS.SERVICE_BUNDLE}/${uuid}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveBundleComponent(bundleComponents) {
    return this.http.post(`${APICONSTANTS.COMPONENT}`, bundleComponents).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateBundleComponent(bundleComponent) {
    return this.http.put(`${APICONSTANTS.COMPONENT}/${bundleComponent.uuid}`, bundleComponent).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  deleteServiceBundleComponent(uuid) {
    return this.http.remove(`${APICONSTANTS.COMPONENT}/${uuid}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveAssociateFundingRequest(id, body) {
    return this.http.put(`${APICONSTANTS.SERVICE_BUNDLE}/${id}/components/associate-fundings`, body).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  createSetting(body) {
    return this.http.post(`${APICONSTANTS.BENEFIT_SETTING}`, body).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateSetting(id, body) {
    Object.keys(body).forEach(keys => {
      if (body[keys] == undefined) {
        body[keys] = null
      } else {
        body[keys] = body[keys]
      }
    });
    return this.http.put(`${APICONSTANTS.BENEFIT_SETTING}/${id}`, body).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getRules(module) {
    return this.http.get(`${APICONSTANTS.LOOKUP}?keyword=${module}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getExpenseAllocation() {
    return this.http.get(`${APICONSTANTS.EXPENSE_ALLOCATION}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getFundingRequests(uuid) {
    return this.http.get(`${APICONSTANTS.FUNDING_REQUEST}?bundleUuid=${uuid}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  createFundingRequest(data) {
    return this.http.post(`${APICONSTANTS.FUNDING_REQUEST}`, data).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateFundingRequest(data) {
    return this.http.put(`${APICONSTANTS.FUNDING_REQUEST}/${data.uuid}`, data).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  deleteFundingRequest(uuid) {
    return this.http.remove(`${APICONSTANTS.FUNDING_REQUEST}/${uuid}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getBundleById(uuid, queryParam?) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.SERVICE_BUNDLE}/${uuid}`, queryParam).toPromise()
        .then((res: any) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
