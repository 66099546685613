import { Injectable } from '@angular/core';

@Injectable()
export class MessageConstants {
  constructor() { }
  //toaster messages
  getMessage(type, data?: string, title?: string) {
    //type is message switch_case_code and data is dynamic data to be append
    switch (type) {
      // common section
      case ADD_SUCCESS:
        return { value: 'Successfully Added' }
      case ADD_FAILED:
        return { value: 'Add Failed' }
      case SAVE_SUCCESS:
        return { value: 'Successfully Saved' }
      case UPDATE_SUCCESS:
        return { value: 'Successfully Updated' }
      case DELETE_SUCCESS:
        return { value: 'Successfully Deleted' }
      case ACCESS_DENIED:
        return { value: 'Access Denied' }
      case DELETE_WARNING:
        return {
          value: `Are you sure you want to delete <strong>${data}</strong>? This is irreversible.
        If you're not sure, you can click <strong>Cancel</strong>.` }
      case UPDATE_FAILED:
        return { value: 'Update Failed' }

      //login section
      case ERROR_LOGIN:
        return { value: data, header: "LOGIN" };
      case ERROR_REQUIRED_LOGIN:
        return { value: data + ' is required', header: "LOGIN" };
      case SUCCESS_EMAIL_SENT:
        return { value: 'Email has been sent to you', header: "LOGIN" };
      case ERROR_EMAIL_SENT:
        return { value: data, header: "LOGIN" };
      case OTP_VERIFIED_SUCCESSFULLY:
        return { value: 'Code verified successfully', header: 'LOGIN' }

      //bundle cost section
      case ADD_BUNDLE_COMPONENT:
        return {
          value:
            'Bundle Cost cannot be created without bundle component. Please add bundle components first.',
        };

      //dashboard section

      //company section

      //pathway section
      case DUPLICATE_TAG_NAME:
        return { value: 'Tag name already exists.' }
      case EXPORT_PATHWAY_FAILED:
        return { value: 'Unable to export pathway.' }
      case IMPORT_PATHWAY_SUCCESS:
        return { value: 'Successfully Imported' }
      case IMPORT_PATHWAY_FAILED:
        return { value: 'Unable to import pathway.' }
      case PATHWAY_NOT_FOUND:
        return { value: 'No pathway found in the uploaded file.' }
      case INVALID_FILE_FORMAT:
        return { value: 'Please select correct file format.' }

      //episode section
      case NO_EPISODE_FOUND:
        return { value: 'No episode of care available for selected patient.' }
      case ERROR_GET_EPISODES:
        return { value: 'Error getting episode list.' }
      case EPISODE_STATUS_CHANGED:
        return { value: `Episode status changed to ${data}.` }
      case NO_MARKET_CODE:
        return { value: 'No market code associated with episode of care.' }
      case NO_FACILITY_FOUND:
        return { value: 'No facility found.' }
      case NO_PROVIDER_ASSOCIATED:
        return { value: 'No provider associated.' }

      //episode of care section
      case NO_MILESTONE_SELECTED:
        return { value: 'No milestone selected for funding requests.' }
      case PLAN_SAVE_FAILED:
        return { value: 'Error saving plans.' }

      //document section
      case UPLOAD_SUCCESS:
        return { value: 'Successfully Uploaded' }

      //members section

      //procedure bundle section

      //patient section
      case RESTRICTED_PATIENT_EDIT:
        return { value: 'This patient cannot be edited.' }
      case MEMBER_ALREADY_ACTIVATED:
        return { value: 'Member has already been activated as user.' }
      case MEMBER_INVITED:
        return { value: `Member has been ${data} to be a user.` }
      case SEND_RESET_PASSWORD_LINK_SUCCESS:
        return { value: 'Password reset link has been sent to the associated email address.' }
      case SEND_INVITATION_SUCCESS:
        return { value: 'Invitation sent successfully.' }

      //journal section
      case NO_PATIENT_ASSOCIATED:
        return { value: 'No patient associate with claim' }
      case UNMATCHED_DEBIT_CREDIT_AMOUNT:
        return { value: 'Total debit amount should be equal to total credit amount.' }
      case INCOMPLETE_JOURNAL_ENTRY:
        return { value: 'Incomplete journal entry.' }
      case NO_ASSOCIATE_DETAILS:
        return { value: 'No associate details available.' }

      //claims section
      case FR_REJECTED_CANCELED:
        return { value: 'Funding request of this claim has been rejected/cancelled.' }
      case NO_PATIENT_FOUND:
        return { value: 'No patient found.' }
      case CLAIMS_ASSOCIATE_SUCCESS:
        return { value: 'Claims associated with episode successfully.' }
      case NO_ELIGIBLE_EPISODE:
        return { value: 'Selected patient does not have eligible episode for assignment.' }
      case ERROR_DELETE_CLAIMS:
        return { value: 'Error deleting claims.' }
      case CHANGE_STATUS_WARNING:
        return {
          value: `Are you sure you want to change the status to <strong>In-Review</strong>? If you're not
        sure, you can click <strong>Cancel</strong>.`}
      case CLAIM_AUTOMATION_WARNING:
        return {
          value: `Are you sure you want to run <strong>Claim Automation</strong>? If you're not sure, you can click <strong>Cancel</strong>.`
        }
      case FETCH_BUNDLE_COST_FAILED:
        return {
          value: 'Error fetching bundle cost'
        }
      case NO_BUNDLES_FOUND:
        return { value: 'No bundles found.' }

      //outgoing claims - claims-edi
      case APPROVED:
        return { value: 'Approved' }
      case SUBMITTED:
        return { value: 'Submitted' }

      //reset-password section
      case PASSWORD_SET_SUCCESS:
        return { value: 'Password set successfully.' }

      //roles and permissions section
      case ERROR_GET_ROLES:
        return { value: 'Error getting roles.' }
      case ROLE_CREATE_SUCCESS:
        return { value: 'Role created successfully.' }
      case ERROR_CREATE_ROLE:
        return { value: 'Error creating role.' }

      //payments section
      case FETCH_PAYMENT_FAILED:
        return { value: 'Fetching payment list failed.' }

      //user section
      case FETCH_USERS_FAILED:
        return { value: 'Fetching users failed.' }
      case USER_STATUS_ACTIVATED:
        return { value: 'User status activated.' }
      case USER_STATUS_DEACTIVATED:
        return { value: 'User status deactivated' }
      case INVITATION_FORWARD_SUCCESS:
        return { value: 'Invitation forwarded successfully.' }
      case RESET_PASSWORD_LINK:
        return { value: 'Password reset link has been sent to the associated email address.' }
      case SEND_EMAIL_FAILED:
        return { value: 'Failed to send email.' }
      case PROVIDER_ASSIGN_SUCCESS:
        return { value: 'Provider assigned successfully.' }

      //communication section
      case MESSAGE_SENT_SUCCESS: {
        return { value: 'Message sent successfully.' }
      }

      //communication-otp section
      case RESEND_OTP_CODE_SUCCESS: {
        return { value: 'New OTP code sent successfully.' }
      }

      //my-profile section
      case MFA_STATUS_UPDATED: {
        return { value: `Receive OTP via ${title} turned ${data}.` }
      }

      case NO_DATA_IN_REPORT: {
        return { value: 'There is no data available.' }
      }

      //type will be message as default 
      default:
        return { value: type }
    }
  }
}
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const ADD_SUCCESS = 'ADD_SUCCESS';
export const ADD_FAILED = 'ADD_FAILED';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const ERROR_REQUIRED_LOGIN = 'ERROR_REQUIRED_LOGIN';
export const SUCCESS_EMAIL_SENT = 'SUCCESS_EMAIL_SENT';
export const ERROR_EMAIL_SENT = 'ERROR_EMAIL_SENT';
export const DELETE_WARNING = 'DELETE_WARNING';
export const ADD_BUNDLE_COMPONENT = 'ADD_BUNDLE_COMPONENT';
export const DUPLICATE_TAG_NAME = 'DUPLICATE_TAG_NAME';
export const EXPORT_PATHWAY_FAILED = 'EXPORT_PATHWAY_FAILED';
export const IMPORT_PATHWAY_SUCCESS = 'IMPORT_PATHWAY_SUCCESS';
export const IMPORT_PATHWAY_FAILED = 'IMPORT_PATHWAY_FAILED';
export const PATHWAY_NOT_FOUND = 'PATHWAY_NOT_FOUND';
export const INVALID_FILE_FORMAT = 'INVALID_FILE_FORMAT';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const NO_MILESTONE_SELECTED = 'NO_MILESTONE_SELECTED';
export const PLAN_SAVE_FAILED = 'PLAN_SAVE_FAILED';
export const APPROVED = 'APPROVED';
export const SUBMITTED = 'SUBMITTED';
export const NO_PATIENT_ASSOCIATED = 'NO_PATIENT_ASSOCIATED';
export const UNMATCHED_DEBIT_CREDIT_AMOUNT = 'UNMATCHED_DEBIT_CREDIT_AMOUNT';
export const INCOMPLETE_JOURNAL_ENTRY = 'INCOMPLETE_JOURNAL_ENTRY';
export const NO_ASSOCIATE_DETAILS = 'NO_ASSOCIATE_DETAILS';
export const NO_EPISODE_FOUND = 'NO_EPISODE_FOUND';
export const ERROR_GET_EPISODES = 'ERROR_GET_EPISODES';
export const EPISODE_STATUS_CHANGED = 'EPISODE_STATUS_CHANGED';
export const NO_MARKET_CODE = 'NO_MARKET_CODE';
export const NO_FACILITY_FOUND = 'NO_FACILITY_FOUND';
export const NO_PROVIDER_ASSOCIATED = 'NO_PROVIDER_ASSOCIATED';
export const FR_REJECTED_CANCELED = 'FR_REJECTED_CANCELED';
export const NO_PATIENT_FOUND = 'NO_PATIENT_FOUND';
export const CLAIMS_ASSOCIATE_SUCCESS = 'CLAIMS_ASSOCIATE_SUCCESS';
export const NO_ELIGIBLE_EPISODE = 'NO_ELIGIBLE_EPISODE';
export const ERROR_DELETE_CLAIMS = 'ERROR_DELETE_CLAIMS';
export const RESTRICTED_PATIENT_EDIT = 'RESTRICTED_PATIENT_EDIT';
export const FIELD_UPDATE_SUCCESS = 'FIELD_UPDATE_SUCCESS';
export const MEMBER_ALREADY_ACTIVATED = 'MEMBER_ALREADY_ACTIVATED';
export const MEMBER_INVITED = 'MEMBER_INVITED';
export const SEND_RESET_PASSWORD_LINK_SUCCESS = 'SEND_RESET_PASSWORD_LINK_SUCCESS';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const CHANGE_STATUS_WARNING = 'CHANGE_STATUS_WARNING';
export const PASSWORD_SET_SUCCESS = 'PASSWORD_SET_SUCCESS';
export const ERROR_GET_ROLES = 'ERROR_GET_ROLES';
export const ROLE_CREATE_SUCCESS = 'ROLE_CREATE_SUCCESS';
export const ERROR_CREATE_ROLE = 'ERROR_CREATE_ROLE';
export const FETCH_PAYMENT_FAILED = 'FETCH_PAYMENT_FAILED';
export const UPDATE_FAILED = 'UPDATE_FAILED';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const USER_STATUS_ACTIVATED = 'USER_STATUS_ACTIVATED';
export const USER_STATUS_DEACTIVATED = 'USER_STATUS_DEACTIVATED';
export const INVITATION_FORWARD_SUCCESS = 'INVITATION_FORWARD_SUCCESS';
export const RESET_PASSWORD_LINK = 'RESET_PASSWORD_LINK';
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED';
export const PROVIDER_ASSIGN_SUCCESS = 'PROVIDER_ASSIGN_SUCCESS';
export const CLAIM_AUTOMATION_WARNING = 'CLAIM_AUTOMATION_WARNING';
export const MESSAGE_SENT_SUCCESS = 'MESSAGE_SENT_SUCCESS';
export const RESEND_OTP_CODE_SUCCESS = 'RESEND_OTP_CODE_SUCCESS';
export const FETCH_BUNDLE_COST_FAILED = 'FETCH_BUNDLE_COST_FAILED';
export const NO_BUNDLES_FOUND = 'NO_BUNDLES_FOUND';
export const OTP_VERIFIED_SUCCESSFULLY = 'OTP_VERIFIED_SUCCESSFULLY';
export const MFA_STATUS_UPDATED = 'MFA_STATUS_UPDATED';
export const NO_DATA_IN_REPORT = 'NO_DATA_IN_REPORT';