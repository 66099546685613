<div class="position-relative">
  <div class="communications-otp-logo p-3">
    <img src="../../../assets/img/png/holista-logo.png" />
  </div>

  <div class="d-flex flex-column communications-otp-container">
    <div class="main-content flex-grow-1">
      <div class="container mb-5">
        <div class="row d-flex justify-content-center">
          <div class="text-center col-md-9">
            <h1 class="mb-3"><b>Holista Communication</b></h1>
            <p class="mb-2">
              {{ holistaCommunicationDesc }}
            </p>
          </div>
        </div>
      </div>

      <div class="container-fluid d-flex justify-content-center">
        <div class="col-md-6 overflow-hidden">
          <div class="row">
            <div class="col-xs-12 col-md-6 mb-4 mb-md-0">
              <div class="grey lighten-4 p-4 text-center h-100">
                <i class="icofont-lock icofont-3x"></i>
                <h4 class="py-4 m-0"><b>OTP Verification</b></h4>
                <p>{{ otpVerificationDesc }}</p>
                <button
                  type="button"
                  class="btn btn-secondary text-white"
                  (click)="onOtpVerification()"
                  mdbWavesEffect>
                  Generate OTP
                </button>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="grey lighten-4 p-4 text-center h-100">
                <i class="icofont-user-alt-7 icofont-3x"></i>
                <h4 class="py-4 m-0"><b>Existing Member</b></h4>
                <p>
                  {{ existingMemberDesc }}
                </p>
                <button
                  type="button"
                  class="btn btn-secondary text-white"
                  (click)="onExisitingMember()"
                  mdbWavesEffect>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center py-3 flex-grow-0">
      <p class="m-0">
        <small>&copy; {{ currentYear }} {{ copyrightText }}</small>
      </p>
      <div>
        <a
          id="termsOfUse"
          href="javascript:void(0)"
          [routerLink]="['/terms-of-use']"
          target="_blank"
          ><small> Terms of Use |</small></a
        >
        <a
          id="privacyPolicy"
          href="javascript:void(0)"
          [routerLink]="['/privacy-policy']"
          target="_blank"
          ><small> Privacy Policy </small></a
        >
      </div>
    </div>
  </div>
</div>
