import { Injectable } from '@angular/core';
import { Border, Borders, Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment'
import { ExportData } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(
  ) { }

  /**
   * 
   * @param excelData 
   * @param networkName 
   */
  async exportExcel(excelData: ExportData[], isSave = true, networkName?: string) {
    const workbook = new Workbook();
    excelData.forEach(excel => {
      workbook.addWorksheet(excel.excelName);
      const worksheet = workbook.worksheets.find(x => x.name === excel.excelName);
      excel.options?.action === 'includedSummary' ? this.getReportWithSummary(worksheet, excel) : this[excel.options.action](worksheet, excel, networkName);

      if (excel.options?.customFormatter) this[excel.options.customFormatter](worksheet, excel)
      else this.setHeaderAndData(worksheet, excel);
    });
    if (!isSave) {
      return await this.getExcelData(workbook);
    };
    await this.saveExcel(workbook, excelData, isSave);
  }

  getReportWithSummary(worksheet: Worksheet, excelData: ExportData) {
    const { headers, summaryData, tableTitle, columnsWidth } = excelData;
    const header = worksheet.getCell('A1');
    header.value = tableTitle;
    header.font = { size: 16, bold: true };
    header.alignment = { vertical: 'middle' };
    worksheet.mergeCells('A1:C1');

    const getCellColumns = () => {
      const reportEndingCellLetter = String.fromCharCode('A'.charCodeAt(0) + headers.length - 1);
      let summaryStartingCellLetter = String.fromCharCode(reportEndingCellLetter.charCodeAt(0) - summaryData.headers.length);
      let summaryCellLetters: string[] = [];
      const summaryLength: number = summaryData.headers.length;

      const subHeader = worksheet.getCell(`${String.fromCharCode(summaryStartingCellLetter.charCodeAt(0) + 1)}1`);
      subHeader.value = 'Period Totals';
      subHeader.font = { size: 11, italic: true, bold: true };
      subHeader.alignment = { vertical: 'middle', horizontal: 'center' };

      for (let i = 0; i < summaryLength; i++) {
        summaryStartingCellLetter = String.fromCharCode(summaryStartingCellLetter.charCodeAt(0) + 1);
        summaryCellLetters = [...summaryCellLetters, summaryStartingCellLetter];
      }
      return summaryCellLetters;
    }

    /**
     * Getting cells for summary table
     * @param cellIDs 
     * @param data
     * @param isHeaders 
     */
    const getSummaryCells = (cellIDs: string[], data: string[], isHeadersRow?: boolean) => {
      const firstCellID: string = cellIDs[0];
      const lastCellID: string = cellIDs.slice(-1)[0];

      cellIDs.forEach(cellID => {
        const cell = worksheet.getCell(cellID);
        cell.value = data[cellIDs.indexOf(cellID)];
        cell.font = { size: 10, bold: true };
        cell.value.toString().includes('$') ? cell.alignment = { vertical: 'middle', horizontal: 'right' } : cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
          bgColor: { argb: 'FF0000FF' }
        };
        isHeadersRow ? cell.border = { top: { style: 'thin' } } : cell.border = { bottom: { style: 'thin' } };
        isHeadersRow ? (
          cellID === firstCellID && (cell.border = { top: { style: 'thin' }, left: { style: 'thin' } }),
          cellID === lastCellID && (cell.border = { top: { style: 'thin' }, right: { style: 'thin' } })
        ) : (
          cellID === firstCellID && (cell.border = { bottom: { style: 'thin' }, left: { style: 'thin' } }),
          cellID === lastCellID && (cell.border = { bottom: { style: 'thin' }, right: { style: 'thin' } })
        )
      });
    }

    getSummaryCells(getCellColumns().map(id => id + '2'), excelData.summaryData.headers, true);
    getSummaryCells(getCellColumns().map(id => id + '3'), excelData.summaryData.data);

    const client = worksheet.getCell('A3');
    client.value = excelData.summaryData.client ? 'Client' : '';
    client.font = { bold: true };

    const clientName = worksheet.getCell('B3');
    clientName.value = excelData.summaryData.client;
    clientName.font = { bold: true };

    const date = worksheet.getCell('A4');
    date.value = `${excelData.summaryData.date.startDate} - ${excelData.summaryData.date.endDate}`;
    date.alignment = { vertical: 'middle' };

    worksheet.addRow([]);

    //custom height
    worksheet.getRow(1).height = 25;

    //custom width
    worksheet.getColumn(1).width = 50;
    this.giveCommonWidth(2, 3, 24, worksheet);
    worksheet.getColumn(4).width = 40;
    this.giveCommonWidth(5, 15, 25, worksheet);

    this.formatColumnWidths(columnsWidth, worksheet);
  }

  /**
   * Sets header and data
   * @param worksheet 
   * @param excelData 
   */
  setHeaderAndData(worksheet: Worksheet, excelData: ExportData) {
    //Add Header Row
    let headerRow = worksheet.addRow(excelData.headers);
    headerRow.font = { size: 10, bold: true }
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    // Fill excel Data
    excelData.exportList.forEach(d => {
      let dataRow = worksheet.addRow(d);
      dataRow.eachCell(cell => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        cell.alignment = { vertical: 'middle', horizontal: 'left' }
        if (cell.value.toString().includes("$") || cell.value.toString() === '-') {
          cell.alignment = { vertical: 'middle', horizontal: 'right' };
        }
      })
    })
  }

  /**
   * Sets header and data
   * @param worksheet
   * @param excelData
   */
  setPathwayHeaderAndData(worksheet: Worksheet, excelData: ExportData) {
    const headerRow = worksheet.addRow(excelData.headers);
    const borderStyle: Partial<Border> = { style: 'thin' };
    headerRow.font = { size: 12, bold: true };
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.height = 20;
    headerRow.eachCell((cell) => {
      cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    });
    for (const [index, data] of excelData.exportList.entries()) {
      const [isNewPathway] = data;
      const dataRow = worksheet.addRow(data);
      const isFinalIndex = index === excelData.exportList.length - 1;
      for (const index of Array.from({ length: 9 }, (_, i) => i + 1)) {
        const style: Partial<Borders> = isNewPathway || isFinalIndex ? { right: borderStyle, [isFinalIndex ? 'bottom' : 'top']: borderStyle } : { right: borderStyle };
        dataRow.getCell(index).border = style;
      }
      // Added color and underline to distinguish hyperlink
      dataRow.getCell(9).font = {
        underline: true,
        color: { argb: 'FF0000FF' }
      }
    }
  }

  /**
   * Saves excel file
   * @param workbook 
   * @param excelName 
   */
  async saveExcel(workbook: Workbook, excelData: ExportData[], isSave: boolean) {
    const date = moment().format('L').replace(/\//g, "");
    const fileName = excelData[0].fileName ??
      (excelData[0].excelName.includes(' List')
        ? excelData[0].excelName.replace(' List', 's')
        : excelData[0].excelName);
    const blob = await this.getExcelData(workbook);
    fs.saveAs(blob, fileName + `_${date}.xlsx`);
  }

  /**
 * Saves excel file
 * @param workbook 
 * @param excelName 
 */
  async getExcelData(workbook: Workbook) {
    return await workbook.xlsx.writeBuffer().then((data) => {
      return new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    });
  }

  /**
   * 
   * @param worksheet 
   * @param excelData 
   * @param networkName 
   */
  getEpisodeOrProviderHeader(worksheet: Worksheet, excelData: ExportData, networkName?: string) {
    const networkCode = worksheet.addRow([networkName || '']);
    const isProviderList: boolean = excelData.excelName == 'Provider List' ? true : false
    networkCode.font = { size: 10, bold: true }
    networkCode.alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.mergeCells(isProviderList ? 'A1:N1' : 'A1:O1');

    const providerList = worksheet.addRow([excelData.excelName]);
    providerList.font = { size: 10, bold: true }
    providerList.alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.mergeCells(isProviderList ? 'A2:N2' : 'A2:O2');

    const dateRow = worksheet.addRow(['Export Date : ' + moment(new Date()).format('MM/DD/YYYY')]);
    dateRow.font = { size: 10 }
    dateRow.alignment = { vertical: 'middle', horizontal: 'right' }
    worksheet.mergeCells(isProviderList ? 'A3:N3' : 'A3:O3');
    worksheet.addRow(dateRow);


    // custom width
    worksheet.getColumn(7).width = isProviderList ? 25 : 22;
    worksheet.getColumn(2).width = isProviderList ? 15 : 50;
    worksheet.getColumn(4).width = isProviderList ? 25 : 35;
    worksheet.getColumn(5).width = isProviderList ? 25 : 50;
    worksheet.getColumn(6).width = isProviderList ? 15 : 22;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(9).width = isProviderList ? 15 : 13;
    worksheet.getColumn(8).width = isProviderList ? 15 : 25;
    worksheet.getColumn(12).width = isProviderList ? 20 : 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(11).width = isProviderList ? 10 : 15;
    worksheet.getColumn(10).width = isProviderList ? 10 : 12;
    worksheet.getColumn(3).width = isProviderList ? 15 : 20;
  }

  /**
   * 
   * @param worksheet
   * @param excelData
   */
  getPathwayHeader(worksheet: Worksheet, excelData: ExportData) {
    // Loop over the number of columns and apply style
    for (const index of Array.from({ length: 10 }, (_, i) => i + 1)) {
      worksheet.getColumn(index).alignment = {
        wrapText: true
      }
      switch (index) {
        case 1:
        case 6:
          worksheet.getColumn(index).width = 15;
          break;
        case 7:
        case 8:
          worksheet.getColumn(index).width = 55;
          break;

        default:
          worksheet.getColumn(index).width = 40;
          break;
      }
    }
    const code = worksheet.addRow(['Pathway Report']);
    code.font = { size: 12, bold: true };
    code.alignment = { vertical: 'middle', horizontal: 'center' };
    code.height = 20;
    worksheet.mergeCells('A1:I1');
    // Add date
    worksheet.getCell('J1').value = 'Export Date : ' + moment(new Date()).format('MM/DD/YYYY');
    worksheet.getCell('J1').alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.addRow(null);
  }

  /**
   * 
   * @param worksheet 
   * @param excelData 
   */
  setFinancialReconciliationHeader(worksheet: Worksheet, excelData: ExportData) {
    worksheet.addRow([`${excelData.summaryData.client} Episode Reconciliation Report`]);
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.addRow([`${excelData.summaryData.date.startDate} - ${excelData.summaryData.date.endDate}`]); // Quarter date from excel data
    worksheet.getCell('A2').font = { italic: true };
    worksheet.addRow('');

    const headingRow = worksheet.addRow(['Year-to-Date Summary', '', 'Current Period']);
    worksheet.addRow(['Closed Episode YTD', excelData.summaryData.data.yearToDateSummary.closedEpisodes, 'Count of Episodes', excelData.summaryData.data.currentPeriodSummary.closedEpisodes]);
    worksheet.addRow(['Total Funded YTD', excelData.summaryData.data.yearToDateSummary.totalFundReceived, 'Total Funded', excelData.summaryData.data.currentPeriodSummary.totalFundReceived]);
    worksheet.addRow(['Total Consumed YTD', excelData.summaryData.data.yearToDateSummary.totalConsumed, 'Less: Total Consumed', excelData.summaryData.data.currentPeriodSummary.totalConsumed]);
    worksheet.addRow(['', '', 'Current Period Balance', excelData.summaryData.data.currentPeriodSummary.totalBalance]);
    worksheet.addRow('');
    const commonAlignment = (alp, start, end) => {
      let i;
      for (i = start; i <= end; i++) {
        worksheet.getCell(`${alp}${i}`).alignment = { vertical: 'middle', horizontal: 'right' };
      }
    }
    commonAlignment('B', 5, 7);
    commonAlignment('D', 5, 7);
    const balance = worksheet.getCell('C8');
    balance.font = { size: 11, bold: true };
    balance.border = { top: { style: 'thick' } };
    const balanceAmt = worksheet.getCell('D8');
    balanceAmt.font = { size: 11, bold: true };
    balanceAmt.border = { top: { style: 'thick' } };
    balanceAmt.alignment = { vertical: 'middle', horizontal: 'right' };
    this.giveCommonWidth(1, 12, 25, worksheet);

    const commonStyle = (alp, start, end) => {
      let i;
      for (i = start; i <= end; i++) {
        worksheet.getCell(`${alp}${i}`).border = { right: { style: 'thin' } };
      }
    }

    commonStyle('B', 4, 8);
    this.mergeCommonCells('A', 'C', 1, 3, worksheet);
    worksheet.mergeCells('A4:B4');
    worksheet.mergeCells('C4:D4');

    headingRow.eachCell((cell) => {
      cell.font = { size: 11, bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    this.giveCommonWidth(3, 3, 50, worksheet);
  }

  /**
   * 
   * @param worksheet 
   * @param excelData 
   */
  setCurrentPeriodBalanceHeader(worksheet: Worksheet, excelData: ExportData) {
    worksheet.addRow(['Current Period Balance Detail']);
    worksheet.getCell('A1').font = { size: 15, bold: true };
    const detail = worksheet.addRow(['Total Current Period Balance (Detail)', '', '', excelData.summaryData.data.totalConsumed]);
    detail.eachCell(cell => {
      cell.font = { size: 11, bold: true };
      cell.border = { bottom: { style: 'thick' } };
    });
    worksheet.getCell('D2').alignment = { vertical: 'middle', horizontal: 'right' };
    this.mergeCommonCells('A', 'C', 1, 3, worksheet);
    this.giveCommonWidth(1, 3, 20, worksheet);
    this.giveCommonWidth(4, 4, 50, worksheet);
    this.giveCommonWidth(5, 6, 30, worksheet);
    this.giveCommonWidth(7, 10, 20, worksheet);
  }

  /**
   * 
   * @param startCol 
   * @param endCol 
   * @param width 
   * @param worksheet 
   */
  giveCommonWidth(startCol: number, endCol: number, width: number, worksheet: Worksheet) {
    let i: number = startCol;
    for (i; i <= endCol; i++) {
      worksheet.getColumn(i).width = width;
    }
  }

  /**
   * 
   * @param startAlp 
   * @param endAlp 
   * @param startNum 
   * @param endNum 
   * @param worksheet 
   */
  mergeCommonCells(startAlp: string, endAlp: string, startNum: number, endNum: number, worksheet: Worksheet) {
    let i: number = startNum;
    for (i; i <= endNum; i++) {
      worksheet.mergeCells(`${startAlp}${i}: ${endAlp}${i}`);
    }
  }


  /**
   * Funding Report
   * @param worksheet 
   * @param excelData 
   */
  getMonthEndReport(worksheet: Worksheet, excelData: ExportData) {
    const { headers, summaryData } = excelData;
    const header = worksheet.getCell('A1');
    header.value = 'SUMMARY Funded Episodes';
    header.font = { size: 16, bold: true };
    header.alignment = { vertical: 'middle' };
    worksheet.mergeCells('A1:C1');

    const subHeader = headers.includes('Funding Request') ? worksheet.getCell('G1') : worksheet.getCell('F1');
    subHeader.value = 'Period Totals';
    subHeader.font = { size: 11, italic: true, bold: true };
    subHeader.alignment = { vertical: 'middle', horizontal: 'center' };

    const getCellColumns = () => {
      let letter: string = headers.includes('Funding Request') ? 'F' : 'E';
      let cellLetters: string[] = [];
      const summaryLength: number = summaryData.headers.length;

      for (let i = 0; i < summaryLength; i++) {
        letter = String.fromCharCode(letter.charCodeAt(0) + 1);
        cellLetters = [...cellLetters, letter];
      }
      return cellLetters;
    }

    /**
     * Getting cells for summary table
     * @param cellIDs 
     * @param data
     * @param isHeaders 
     */
    const getSummaryCells = (cellIDs: string[], data: string[], isHeadersRow?: boolean) => {
      const firstCellID: string = cellIDs[0];
      const lastCellID: string = cellIDs.slice(-1)[0];

      cellIDs.forEach(cellID => {
        const cell = worksheet.getCell(cellID);
        cell.value = data[cellIDs.indexOf(cellID)];
        cell.font = { size: 10, bold: true };
        cell.value.toString().includes('$') ? cell.alignment = { vertical: 'middle', horizontal: 'right' } : cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
          bgColor: { argb: 'FF0000FF' }
        };
        isHeadersRow ? cell.border = { top: { style: 'thin' } } : cell.border = { bottom: { style: 'thin' } };
        isHeadersRow ? (
          cellID === firstCellID && (cell.border = { top: { style: 'thin' }, left: { style: 'thin' } }),
          cellID === lastCellID && (cell.border = { top: { style: 'thin' }, right: { style: 'thin' } })) : (
          cellID === firstCellID && (cell.border = { bottom: { style: 'thin' }, left: { style: 'thin' } }),
          cellID === lastCellID && (cell.border = { bottom: { style: 'thin' }, right: { style: 'thin' } })
        )
      });
    }

    getSummaryCells(getCellColumns().map(id => id + '2'), excelData.summaryData.headers, true);
    getSummaryCells(getCellColumns().map(id => id + '3'), excelData.summaryData.data);

    const client = worksheet.getCell('A3');
    client.value = excelData.summaryData.client ? 'Client' : '';
    client.font = { bold: true };

    const clientName = worksheet.getCell('B3');
    clientName.value = excelData.summaryData.client;
    clientName.font = { bold: true };

    const date = worksheet.getCell('A4');
    date.value = `${excelData.summaryData.date.startDate} - ${excelData.summaryData.date.endDate}`;
    date.alignment = { vertical: 'middle' };

    worksheet.addRow([]);

    //custom height
    worksheet.getRow(1).height = 25;

    //custom width
    worksheet.getColumn(1).width = 50;
    this.giveCommonWidth(2, 3, 24, worksheet);
    worksheet.getColumn(4).width = 40;
    this.giveCommonWidth(5, 15, 25, worksheet);
  }

  /**
   * formats the view of exported excel 
   * @param worksheet 
   * @param excelData 
   */
  formatExcel(worksheet: Worksheet, excelData: ExportData) {
    const { headers, columnsWidth, tableTitle } = excelData;

    const header = worksheet.getCell('A2');
    header.value = tableTitle;
    header.font = { size: 10, bold: true };
    header.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells(`A1:${String.fromCharCode(65 + headers.length - 1)}1`); //ASCII for 'A' starting from 65
    worksheet.mergeCells(`A2:${String.fromCharCode(65 + headers.length - 1)}2`);

    const dateType = worksheet.getCell('A3');
    dateType.value = excelData.dateType;
    dateType.font = { size: 10 };

    const reportDate = worksheet.getCell('B3');
    reportDate.value = `${excelData.date.startDate} ${excelData.date.endDate}`;
    reportDate.font = { size: 10 };

    const exportDate = worksheet.getCell(`${String.fromCharCode(65 + headers.length - 1)}3`);
    exportDate.value = 'Export Date : ' + moment(new Date()).format('MM/DD/YYYY');
    exportDate.alignment = { vertical: 'middle', horizontal: 'right' };
    exportDate.font = { size: 10 };
    worksheet.addRow('');
    this.formatColumnWidths(columnsWidth, worksheet);
  }

  formatColumnWidths(columnsWidth: number[], worksheet: Worksheet) {
    if (columnsWidth.length) {
      for (let i = 0; i < columnsWidth.length; i++) {
        worksheet.getColumn(i + 1).width = columnsWidth[i];
      };
    }
  }
}
