import { Injectable } from "@angular/core";
import { ExportData } from "../models";
import { ExcelService } from "../services";
import { CurrencyFormat, DateUtility, HolistaUtils } from "../utils";
import * as moment from "moment";
import { HOLISTA_CONSTANT } from "../constants";

@Injectable()

export class ReportUtility {
    constructor(
        private _excelService: ExcelService,
        private _holistaUtility: HolistaUtils,
        private _dateUtility: DateUtility,
        private _currencyFormat: CurrencyFormat
    ) { }

    /**
     * 
     * @param report 
     * @returns report table data to be printed in exported excel
     */
    getReportData(report) {
        const { tableHeaders, data } = report;
        const tableData = [];
        const properties: any[] = [];
        tableHeaders.forEach((header) => properties.push(header));
        data && data.forEach(x => {
            let data = [];
            properties.forEach(({ type, value }) => data.push(this.getFormattedValue(type, x[value])));
            data = data.map(item => !item || item === '' || item === ' ' ? '-' : item);
            tableData.push(data);
        });
        return tableData;
    }

    async exportToCsv(report, isSave: boolean = true, networkCodeName: string = '', action: string = 'formatExcel') {
        const { excelName, columnsWidth, tableTitle, dateType } = report;
        const date = {
            startDate: report.date && report.date.startDate ? `${report.date.startDate} -` : '',
            endDate: report.date && report.date.endDate ? report.date.endDate : '',
        }
        const exportData: ExportData[] = [{
            exportList: this.getReportData(report),
            headers: report.tableHeaders.map(header => header.label),
            excelName,
            columnsWidth,
            tableTitle,
            dateType,
            date,
            ...report.summaryData && { summaryData: report.summaryData },
            options: { action }
        },
        ];
        if (!isSave) {
            return this._excelService.exportExcel(exportData, isSave);
        }
        await this._excelService.exportExcel(exportData, isSave, networkCodeName);
    }

    mapProvidersExportData(providersData: any) {
        providersData = providersData.map(data => {
            const { physicianPrimaryTaxonomyDescription, physicianEffectiveStartDate, physicianEffectiveEndDate, facilityEffectiveStartDate, facilityEffectiveEndDate } = data;
            return {
                ...data,
                speciality: physicianPrimaryTaxonomyDescription,
                physicianEffectiveStartDate: physicianEffectiveStartDate && this._dateUtility.getFormattedDate(physicianEffectiveStartDate),
                physicianEffectiveEndDate: physicianEffectiveEndDate && this._dateUtility.getFormattedDate(physicianEffectiveEndDate),
                facilityEffectiveStartDate: facilityEffectiveStartDate && this._dateUtility.getFormattedDate(facilityEffectiveStartDate),
                facilityEffectiveEndDate: facilityEffectiveEndDate && this._dateUtility.getFormattedDate(facilityEffectiveEndDate)
            }
        })
        return providersData;
    }

    mapEpisodesExportData(episodesData: any) {
        episodesData = episodesData.map(data => {
            const { addressLine1, city, state, memberDob, zip, dateConfirmed, expectedDeliveryDate, actualDeliveryDate, benefitStartDate, benefitEndDate, dateOfSurgery } = data;
            return {
                ...data,
                address: `${addressLine1}, ${city}, ${state} ${zip}`,
                memberDob: memberDob && this._dateUtility.getFormattedDate(memberDob),
                dateConfirmed: dateConfirmed && this._dateUtility.getFormattedDate(dateConfirmed),
                dateOfSurgery: dateOfSurgery && this._dateUtility.getFormattedDate(dateOfSurgery),
                expectedDeliveryDate: expectedDeliveryDate && this._dateUtility.getFormattedDate(expectedDeliveryDate),
                actualDeliveryDate: actualDeliveryDate && this._dateUtility.getFormattedDate(actualDeliveryDate),
                benefitStartDate: benefitStartDate && this._dateUtility.getFormattedDate(benefitStartDate),
                benefitEndDate: benefitEndDate && this._dateUtility.getFormattedDate(benefitEndDate),
            }
        });
        return episodesData;
    }


    /**
     * converts data to desired format
     * @param type 
     * @param item 
     * @returns 
     */
    getFormattedValue(type: string, value: string | number) {
        if (value === 0 || (value && value !== ' ')) {
            if (type === 'amount') {
                return this._currencyFormat.format(value);
            };

            if (type === 'date') {
                return moment(value).format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT);
            };

            if (type === 'percentage') {
                return `${value}%`;
            }
            return value;
        } else return '-';
    }
}