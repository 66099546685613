<section class="container-fluid">
    <div class="d-flex justify-content-between align-items-center mt-4 action position-sticky">
        <div class="form-group has-search mt-2 mb-2 w-25">
            <span class="form-control-feedback">
                <i class="icofont-search-1"></i>
            </span>
            <input type="text" [(ngModel)]="searchKeyword" class="form-control " name="searchKeyword"
                placeholder="Search" (ngModelChange)="searchKeywordChange($event)">
        </div>
        <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#basicExample"
            (click)="createCompany()" mdbWavesEffect [disabled]="loggedInUser.user.companyType=='COMPANY'"><i
                class="icofont-plus"></i> Create</button>
    </div>
    <div class="table-wrapper">
        <div class="container-fluid">
            <div class="row">
                <table mdbTable hover="true" small="true" stickyHeader="true" class="v-top">
                    <thead id='scrollToTop'>
                        <tr>
                            <th></th>
                            <th [class.active]="companyQuery.sortBy === 'name'" (click)="setOrder('name')">Name
                                <span *ngIf="companyQuery.sortBy === 'name'">
                                    <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
                                    <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
                                </span>
                            </th>
                            <th [class.active]="companyQuery.sortBy === 'email'" (click)="setOrder('email')">Email
                                <span *ngIf="companyQuery.sortBy === 'email'">
                                    <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
                                    <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
                                </span>
                            </th>
                            <th [class.active]="companyQuery.sortBy === 'phone'" (click)="setOrder('phone')">Phone
                                <span *ngIf="companyQuery.sortBy === 'phone'">
                                    <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
                                    <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
                                </span>
                            </th>
                            <th [class.active]="companyQuery.sortBy === 'addressline1'"
                                (click)="setOrder('addressline1')">Address
                                <span *ngIf="companyQuery.sortBy === 'addressline1'">
                                    <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
                                    <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
                                </span>
                            </th>
                            <th [class.active]="companyQuery.sortBy === 'typeCode'" (click)="setOrder('typeCode')">Type
                                <span *ngIf="companyQuery.sortBy === 'typeCode'">
                                    <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
                                    <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
                                </span>
                            </th>
                            <th>Parent Company</th>
                            <th></th>
                        </tr>
                    </thead>
                    <div class="loader-wrapper" *ngIf="loading"
                        [style.top]="(companyList && companyList.length==0)?'auto':0">
                        <div class="loader">
                            <app-loader [loader]="loading"></app-loader>
                        </div>
                        <div class="backdrop"></div>
                    </div>
                    <tbody
                        *ngFor=" let company of companyList | paginate: { itemsPerPage: companyQuery.limit, currentPage: companyQuery.page, totalItems: totalCount };let i = index">
                        <tr [ngClass]="{'grey lighten-4 border': company.collapsed}">
                            <td (click)="company.collapsed = !company.collapsed;"
                                *ngIf="company.typeCode.toLowerCase() =='company'">
                                <button type="button" class="btn btn-link p-0 m-0" mdbWavesEffect
                                    *ngIf="company.collapsed">
                                    <i class="icofont-minus-circle icofont-lg text-info"></i>
                                </button>
                                <button type="button" class="btn btn-link p-0 m-0" mdbWavesEffect
                                    *ngIf="!company.collapsed">
                                    <i class="icofont-plus-circle icofont-lg text-info"></i>
                                </button>
                            </td>
                            <td *ngIf="company.typeCode.toLowerCase() !=='company'"></td>
                            <td (click)="editCompany(company)">
                                {{ company?.name }}
                            </td>
                            <td (click)="editCompany(company)">{{ company?.email }}</td>
                            <td width="120" (click)="editCompany(company)">{{ company?.phone|formatPhoneNumber}}</td>
                            <td (click)="editCompany(company)">
                                {{company?.addressline1+', '}}
                                {{ (company.addressline2)?company.addressline2+', ':''}} <br>
                                {{ company?.city+', ' }} {{ company.state+', '}}
                                <span *ngIf="company.zip4">
                                    {{company.zip + '-' + company.zip4}}
                                </span>
                                <span *ngIf="company.zip && !company.zip4">
                                    {{company.zip | slice : 0:5 }}{{company.zip.length > 5 ? '-' +
                                    (company.zip | slice:5) : ''}}
                                </span>
                            </td>
                            <td (click)="editCompany(company)">{{ company?.typeCode }}</td>
                            <td (click)="editCompany(company)">{{ company?.parentCompany?.name }}</td>
                            <td width="32">
                                <div class="custom-dropdown">
                                    <button
                                        class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                                        type="button" mdbWavesEffect>
                                        <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                                    </button>
                                    <div class="custom-dropdown-content rounded-sm z-depth-2">
                                        <a class="dropdown-item" (click)="editCompany(company)"><i
                                                class="icofont-edit-alt"></i>
                                            Edit</a>
                                        <a class="dropdown-item" (click)="openPayerModal(company.id,company.payer)"
                                            *ngIf="company.typeCode.toLowerCase() =='company'">
                                            <i class="icofont-edit-alt" *ngIf="company.payer"></i>
                                            <i class="icofont-plus" *ngIf="!company.payer"></i>
                                            {{company.payer ? 'Edit' : 'Add'}} Payor</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="company.collapsed" class="grey lighten-4">
                            <td colspan="100%" class="p-3">
                                <div *ngIf="company.payer && company.payer.id">
                                    <h6>Payor Information</h6>
                                    <div class="row">
                                        <div class="col-4">
                                            <table class="table-xs" mdbTable small="true" borderless="true">
                                                <tr>
                                                    <td><span>Name:</span></td>
                                                    <td><strong>{{company.payer.name}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Identifier:</span></td>
                                                    <td><strong>{{company.payer.identifier}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Billing Holista NPI: </span></td>
                                                    <td><strong>{{company.payer.billingHolistaNpi}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>NPI: </span></td>
                                                    <td><strong>{{company.payer.npi}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>TIN:</span></td>
                                                    <td><strong>{{company.payer.tin}}</strong></td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-4">
                                            <table class="table-xs" mdbTable small="true" borderless="true">
                                                <tr>
                                                    <td><span>Address Line 1:</span></td>
                                                    <td><strong>{{company.payer.addressLine1}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Address Line 2: </span></td>
                                                    <td><strong>{{company.payer.addressLine2}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>City: </span></td>
                                                    <td><strong>{{company.payer.city}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>State:</span></td>
                                                    <td><strong>{{company.payer.state}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Zip Code:</span></td>
                                                    <td>
                                                        <strong *ngIf="company.payer.zip4">
                                                            {{company.payer.zipCode + '-' + company.payer.zip4}}
                                                        </strong>
                                                        <strong *ngIf="!company.payer.zip4">
                                                            {{company.payer.zipCode | slice :
                                                            0:5}}{{company.payer.zipCode.length > 5 ? '-' +
                                                            (company.payer.zipCode | slice:5) : ''}}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-4">
                                            <table class="table-xs" mdbTable small="true" borderless="true">
                                                <tr>
                                                    <td><span>Contact Name: </span></td>
                                                    <td><strong>{{company.payer.contactName}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Contact Email: </span></td>
                                                    <td><strong>{{company.payer.contactEmail}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td><span>Contact Phone: </span></td>
                                                    <td><strong>{{company.payer.phone}}</strong></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!company.payer">
                                    <h6>No Payor Information</h6>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <pagination-controls *ngIf="companyList && companyList.length>0" class="text-center"
            (pageChange)="pageChanged($event)" autoHide="true">
        </pagination-controls>
    </div>

    <div appScrollToTop class="fixed-action-btn donotprint" style="bottom: 145px; right: 10px;display:none;">
        <a mdbPageScroll (click)="ScrollToTop()" class="waves-light btn-light btn-floating">
            <i class="fas fa-angle-up text-black-50"></i>
        </a>
    </div>

    <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.Companies">
        <i class="icofont-building-alt icofont-4x text-black-50"></i>
        <p>
            Start adding Companys.
        </p>
    </div>

    <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.searchedCompanies">
        <i class="far fa-3x fa-building text-black-50"></i>
        <p>
            No results found.
        </p>
    </div>
</section>

<!-- ADD/EDIT COMPANY MODAL -->
<div mdbModal #companyModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true"
    [config]="{backdrop: true, ignoreBackdropClick: true, keyboard:false}">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content add-company-modal">
            <div class="modal-header">
                <h5 class="modal-title w-100">
                    {{ companyForm.value.id ? "Edit" : "Add" }} Company
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                    <i class="icofont-close-line"></i>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="companyForm">
                    <div class="form-group">
                        <label for="companyParentCompany">Parent Company *</label>
                        <mdb-select [options]="displayParentList | sort:'label'" id="companyParentCompany"
                            (selected)="onParentCompanyChange($event)" formControlName="parentId"
                            placeholder="Select parent company"
                            [disableControl]="loggedInUser.user.companyType!='PLATFORM_OWNER' || companyForm.get('id').value ? true : false"
                            [ngClass]="{
                            'error-select': submitted && companyForm.get('parentId').invalid ,
                            'pointer-disabled': companyForm.get('id').value
                            }">
                        </mdb-select>
                    </div>
                    <div class="form-row">
                        <div class="col-8">
                            <div class="form-group">
                                <label for="companyName">Name *</label>
                                <input type="text" id="companyName" class="form-control" formControlName="name" mdbInput
                                    [ngClass]="{'is-invalid': submitted && companyForm.get('name').invalid}" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="companyidentifier">Identifier *</label>
                                <input type="text" id="companyidentifier" class="form-control"
                                    formControlName="identifier" mdbInput maxlength="15"
                                    [ngClass]="{'is-invalid': submitted && companyForm.get('identifier').invalid}" />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label for="companyNPI">NPI *</label>
                                <input type="text" id="companyNPI" class="form-control" formControlName="npi" mdbInput
                                    maxlength="10"
                                    [ngClass]="{'is-invalid': submitted && companyForm.get('npi').invalid}" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="companyTIN">TIN *</label>
                                <input type="text" id="companyTIN" class="form-control" formControlName="tin" mdbInput
                                    maxlength="9"
                                    [ngClass]="{'is-invalid': submitted && companyForm.get('tin').invalid}" />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label for="companyaddress1">Address Line 1 *</label>
                                <input type="text" id="companyaddress1" class="form-control"
                                    formControlName="addressline1" mdbInput [ngClass]="{
                                'is-invalid': submitted && companyForm.get('addressline1').invalid
                              }" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="companyaddress2">Address Line 2</label>
                                <input type="text" id="companyaddress2" class="form-control"
                                    formControlName="addressline2" mdbInput />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label for="companyCity">City *</label>
                                <input type="text" id="companyCity" class="form-control" formControlName="city" mdbInput
                                    [ngClass]="{'is-invalid': submitted && companyForm.get('city').invalid}" />
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label for="companyState">State *</label>
                                <mdb-select [options]="usStates" id="companyState" formControlName="state"
                                    placeholder="Select state" [ngClass]="{
                                    'error-select': submitted && companyForm.get('state').invalid 
                                    }">
                                </mdb-select>

                            </div>
                        </div>
                        <div class="col-4">
                            <label for="companyZip">Zip Code *</label>
                            <div class="form-row">
                                <div class="col">
                                    <input type="text" id="companyZip" class="form-control" formControlName="zip"
                                        mdbInput numbersOnly maxlength='5'
                                        [ngClass]="{'is-invalid': submitted && companyForm.get('zip').invalid}" />
                                </div>
                                <div>
                                    <span class="zip-dash">-</span>
                                </div>
                                <div class="col">
                                    <input type="text" id="companyZip2" class="form-control" formControlName="zip4"
                                        placeholder="Zip4" numbersOnly maxlength='4' mdbInput
                                        [readonly]="!companyForm.value.zip"
                                        [ngClass]="{'is-invalid': submitted && companyForm.get('zip4').invalid}" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label for="contactName">Contact Name *</label>
                                <input type="text" id="contactName" class="form-control" formControlName="contactName"
                                    mdbInput
                                    [ngClass]="{'is-invalid': submitted && companyForm.get('contactName').invalid}" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="companyEmail">Contact Email *</label>
                                <input type="text" id="companyEmail" class="form-control" formControlName="email"
                                    mdbInput
                                    [ngClass]="{'is-invalid': submitted && companyForm.get('email').invalid}" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="companyPhone">Contact Phone *</label>
                                <input type="text" id="companyPhone" class="form-control" formControlName="phone"
                                    [textMask]="{mask: mask}" mdbInput
                                    [ngClass]="{'is-invalid': submitted && companyForm.get('phone').invalid}" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
                <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeModal()">
                    Cancel
                </button>
                <button class="btn btn-secondary waves-light m-0" mdbWavesEffect (click)="submitCompany()"
                    [disabled]="submit_enabled"><span class="spinner-grow spinner-grow-sm" role="status"
                        aria-hidden="true" *ngIf="submit_enabled"></span>
                    {{ companyForm.value.id ? "Update" : "Save" }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- PAYER MODAL -->
<div mdbModal #payerModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true"
    [config]="{backdrop: true, ignoreBackdropClick: true, keyboard:false}">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content add-company-modal">
            <div class="modal-header">
                <h5 class="modal-title w-100">
                    {{ payerForm.value.id ? "Edit" : "Add" }} Payor
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePayerModal()">
                    <i class="icofont-close-line"></i>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="payerForm">
                    <div class="form-row">
                        <div class="col-8">
                            <div class="form-group">
                                <label for="payerName">Payor Name *</label>
                                <input type="text" id="payerName" class="form-control" formControlName="name" mdbInput
                                    [ngClass]="{
                                'error-select': submitted && payerForm.get('name').invalid 
                                }" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="payerIdentifier">Payor Identifier *</label>
                                <input type="text" id="payerIdentifier" class="form-control"
                                    formControlName="identifier" mdbInput maxlength="15" [ngClass]="{
                                        'error-select': submitted && payerForm.get('identifier').invalid 
                                        }" />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label for="billingHolistaNpi">Billing Holista NPI</label>
                                <input type="text" id="billingHolistaNpi" class="form-control"
                                    formControlName="billingHolistaNpi" mdbInput maxlength="10" [ngClass]="{
                                        'error-select': submitted && payerForm.get('billingHolistaNpi').invalid 
                                        }" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="payerNPI">NPI</label>
                                <input type="text" id="payerNPI" class="form-control" formControlName="npi" mdbInput
                                    maxlength="10" [ngClass]="{
                                    'error-select': submitted && payerForm.get('npi').invalid 
                                    }" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="payerTIN">TIN</label>
                                <input type="text" id="payerTIN" class="form-control" formControlName="tin" mdbInput
                                    maxlength="9" [ngClass]="{
                                    'error-select': submitted && payerForm.get('tin').invalid 
                                    }" />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label for="payeraddressLine1">Address Line 1 *</label>
                                <input type="text" id="payeraddressLine1" class="form-control"
                                    formControlName="addressLine1" mdbInput [ngClass]="{
                                        'error-select': submitted && payerForm.get('addressLine1').invalid 
                                        }" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="payeraddressLine2">Address Line 2</label>
                                <input type="text" id="payeraddressLine2" class="form-control"
                                    formControlName="addressLine2" mdbInput />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label for="payerCity">City *</label>
                                <input type="text" id="payerCity" class="form-control" formControlName="city" mdbInput
                                    [ngClass]="{'is-invalid': submitted && payerForm.get('city').invalid}" />
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label for="payerState">State *</label>
                                <mdb-select [options]="usStates" id="payerState" formControlName="state"
                                    placeholder="Select state" [ngClass]="{
                                    'error-select': submitted && payerForm.get('state').invalid 
                                    }">
                                </mdb-select>

                            </div>
                        </div>
                        <div class="col-4">
                            <label for="companyZip">Zip Code *</label>
                            <div class="form-row">
                                <div class="col">
                                    <input type="text" id="payerZip" class="form-control" formControlName="zipCode"
                                        mdbInput numbersOnly maxlength='5'
                                        [ngClass]="{'is-invalid': submitted && payerForm.get('zipCode').invalid}" />
                                </div>
                                <div>
                                    <span class="zip-dash">-</span>
                                </div>
                                <div class="col">
                                    <input type="text" id="payerZip2" class="form-control" formControlName="zip4"
                                        placeholder="Zip4" numbersOnly maxlength='4' mdbInput
                                        [readonly]="!payerForm.value.zipCode"
                                        [ngClass]="{'is-invalid': submitted && payerForm.get('zip4').invalid}" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label for="payercontactName">Contact Name</label>
                                <input type="text" id="payercontactName" class="form-control"
                                    formControlName="contactName" mdbInput />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="payercontactEmail">Contact Email</label>
                                <input type="text" id="payercontactEmail" class="form-control"
                                    formControlName="contactEmail" mdbInput />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="payercontactphone">Contact Phone</label>
                                <input type="text" id="payercontactphone" class="form-control" formControlName="phone"
                                    mdbInput [textMask]="{mask: mask}" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
                <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closePayerModal()">
                    Cancel
                </button>
                <button class="btn btn-secondary waves-light m-0" mdbWavesEffect (click)="submitPayer()"
                    [disabled]="submit_enabled"><span class="spinner-grow spinner-grow-sm" role="status"
                        aria-hidden="true" *ngIf="submit_enabled"></span>
                    {{ payerForm.value.id ? "Update" : "Save" }}
                </button>
            </div>
        </div>
    </div>
</div>