import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EpisodeService {

  constructor(
    private http: HttpClientService,
    private https: HttpClient,
  ) { }

  getEpisodes(page, limit) {
    return new Promise((ressolve, reject) => {
      this.http.get(`${APICONSTANTS.EPISODE}?page=${page}&limit=${limit}`).toPromise()
        .then((res: any) => {
          ressolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getNonPreliminaryEpisodes() {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.EPISODE}/non-preliminary-episodes`).toPromise()
        .then((res: any) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getEpisodesBySearchKeyword(body) {
    return new Promise((ressolve, reject) => {
      this.http.post(`${APICONSTANTS.EPISODE}/search`, body).toPromise()
        .then((res: any) => {
          ressolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getEpisodesDropdown() {
    return new Promise((ressolve, reject) => {
      this.http.get(`${APICONSTANTS.EPISODE}?all=true`).toPromise()
        .then((res: any) => {
          ressolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  searchEpisodes(queryParam) {
    return this.http.get(`${APICONSTANTS.EPISODE}`, queryParam)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getEpisode(episode_id) {
    return this.http.get(`${APICONSTANTS.EPISODE}/${episode_id}${APICONSTANTS.MILESTONE}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  saveEpisode(episode) {
    return this.http.post(`${APICONSTANTS.EPISODE}`, episode)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateEpisode(episode) {
    return this.http.put(`${APICONSTANTS.EPISODE}`, episode)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  updateEpisodeStatus(body) {
    return this.http.put(`${APICONSTANTS.EPISODE}/episode-status`, body)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  saveMilestone(milestone) {
    return this.http.post(`${APICONSTANTS.EPISODE}/${milestone.episodeId}${APICONSTANTS.MILESTONE}`, milestone)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateMilestone(milestone) {
    return this.http.put(`${APICONSTANTS.EPISODE}/${milestone.episodeId}${APICONSTANTS.MILESTONE}`, milestone)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  changeEpisodeAssignedTo(episode) {
    return this.http.post(`${APICONSTANTS.EPISODE}/${episode.id}/assign-to`, episode)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  setMilestoneDate(milestone) {
    return this.http.put(`${APICONSTANTS.EPISODE}/${milestone.episodeId}/milestone-date`, milestone)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  deleteMilestoneTopic(credentials) {
    let path = credentials.source == 'Episode' ? APICONSTANTS.EPISODE : APICONSTANTS.MILESTONE
    return this.http.put(`${path}/type/delete`, credentials)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getEpisodeUsers(user) {
    return new Promise((resolve, reject) => {
      this.http.post(`${APICONSTANTS.EPISODE}/episode-users/${user.id}`, user)
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getEpisodesAssignedUsers() {
    return this.http.get(`${APICONSTANTS.EPISODE}${APICONSTANTS.PATIENTS}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getMemberEpisodeCare(uuid, companyId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.EPISODE_OF_CARE}/member/${uuid}/${companyId}`)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getFacilities(bundleUuid, networkCode: string) {
    return this.http.get(`${APICONSTANTS.SERVICE_BUNDLE}/${bundleUuid}/facility-providers?networkCode=${networkCode}`)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getVitafyProvidersById(providerIds) {
    return this.http.get(`${APICONSTANTS.PROVIDER}/${providerIds}`)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getAssociatedProviders(data) {
    const apiUrl = data.episodeId ? `${APICONSTANTS.EPISODE}/${data.episodeId}${APICONSTANTS.PROVIDER}` : `${APICONSTANTS.SERVICE_BUNDLE}/${data.bundleUuid}/primary-providers`
    return new Promise((ressolve, reject) => {
      this.http.get(apiUrl, !data.episodeId ? { marketCode: data.marketCode, providerId: data.providerId } : '')
        .toPromise()
        .then((res: any) => {
          ressolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getNetworkCode(code) {
    return new Promise((ressolve, reject) => {
      this.http.get(`/network/${code}/insurer-code`).toPromise()
        .then((res: any) => {
          ressolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getEpisodeForClaims(id) {
    return this.http.get(`${APICONSTANTS.EPISODE}/claim-patient/${id}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  // For Manual Journal Entry
  getBundleComponent(episodeId) {
    return this.http.get(`${APICONSTANTS.EPISODE}/episode-bundle-cost-components/${episodeId}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getFundingRequest(episodeId, fundingReqId) {
    return this.http.get(`${APICONSTANTS.EPISODE}/${episodeId}/funding-requests/${fundingReqId}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getEpisodeDetail(episodeId) {
    return new Promise((ressolve, reject) => {
      this.http.get(`${APICONSTANTS.EPISODE}/${episodeId}`)
        .toPromise()
        .then((res: any) => {
          ressolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getInsurer(episodeId) {
    return new Promise((ressolve, reject) => {
      this.http.get(`${APICONSTANTS.COMPANY}/by-ids?ids=${episodeId}`)
        .toPromise()
        .then((res: any) => {
          ressolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getEOBByEpisode(episodeId) {
    return this.http.get(`${APICONSTANTS.EPISODE}/eob-by-episode/${episodeId}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getEpisodeByUserId(userId) {
    return this.http.get(`${APICONSTANTS.EPISODE}/user/${userId}`)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getEpisodeBySubscriberAndBundleId(subscriberNumber: string, bundleUuid: string) {
    return this.http.get(`${APICONSTANTS.EPISODE}/${subscriberNumber}/${bundleUuid}/subscriber-number`)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getEpisodeAllowedStatus(query) {
    const apiUrl = `${APICONSTANTS.EPISODE}/allowed-status?${query.initialStatus ? `fromStatus=${query.initialStatus}` : ''}${query.finalStatus ? `&toStatus=${query.finalStatus}` : ''}${query.roleCode ? `&role=${query.roleCode}` : ''}`
    return this.http.get(apiUrl)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  getUniqueProviders(params?) {
    return this.http.get(`${APICONSTANTS.EPISODE}/provider-reference/episode-providers`, params).pipe(map((response: any) => response.data));
  }

  getUniqueEpisodeTypes(params?) {
    const payload = {
      ...params,
    };
    const { providerIds } = payload;
    if (providerIds && providerIds.length) {
      payload.facilityId = String(providerIds);
    } else if (providerIds && !providerIds.length) {
      payload.facilityId = 0;
    } else {
      delete payload.facilityId;
      delete payload.providerIds;
    }

    if (!payload.clientCode) {
      delete payload.clientCode
    }

    return this.http.get(`${APICONSTANTS.EPISODE}/unique-episodes`, payload).pipe(map((response: any) => response.data));
  }

  syncSignatureProgress(body) {
    return this.http.post(`${APICONSTANTS.EPISODE}/document-sign-progress`, body).pipe(map((response: any) => response.data));
  }
}