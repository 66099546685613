import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

// const API_BASE_URL = environment.apiUrl;
const API_BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root"
})
export class HttpClientService {
  constructor(private http: HttpClient) { }

  get(url, params?) {
    return this.http.get(API_BASE_URL + url, { params });
  }

  post(url, data) {
    return this.http.post(API_BASE_URL + url, data);
  }

  put(url, data) {
    return this.http.put(API_BASE_URL + url, data);
  }

  delete(url, params?) {
    return this.http.delete(API_BASE_URL + url, { params });
  }

  remove(url) {
    return this.http.delete(API_BASE_URL + url);
  }

  patch(url, data) {
    return this.http.patch(API_BASE_URL + url, data);
  }
}
