import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs/operators';
import { Storage } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

  constructor(
    private http: HttpClientService,
    private _storage: Storage
  ) { }

  getLoginActivity(limit, page, filter, keyword?) {
    const action = filter && filter.loginAction && filter.loginAction.length > 0 ? `&action=${filter.loginAction}` : ''
    const user = filter && filter.userId && filter.userId.length > 0 ? `&user_id=${filter.userId}` : ''
    const fromDate = filter && filter.fromDate ? `&fromDate=${filter.fromDate}` : ''
    const toDate = filter && filter.toDate ? `&toDate=${filter.toDate}` : ''
    const searchWord = keyword ? `&search=${keyword}` : ''
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.ACTIVITY_LOG}/authentication?page=${page}&limit=${limit}${action}${user}${fromDate}${toDate}${searchWord}`)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getUserActivity(limit, page, filter, keyword?) {
    const user = filter && filter.userId && filter.userId.length > 0 ? `&created_by=${filter.userId}` : ''
    const module_name = filter && filter.module && filter.module.length > 0 ? `&module_name=${filter.module}` : ''
    const sub_module = filter && filter.sub_module && filter.sub_module.length > 0 ? `&sub_module=${filter.sub_module}` : ''
    const action = filter && filter.userAction && filter.userAction.length > 0 ? `&action=${filter.userAction}` : ''
    const fromDate = filter && filter.fromDate ? `&fromDate=${filter.fromDate}` : ''
    const toDate = filter && filter.toDate ? `&toDate=${filter.toDate}` : ''
    const searchWord = keyword ? `&search=${keyword}` : ''
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.ACTIVITY_LOG}/activity?page=${page}&limit=${limit}${action}${module_name}${fromDate}${toDate}${sub_module}${user}${searchWord}`)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getTaskActivity(limit, page, filter, keyword?) {
    const user = filter && filter.userId && filter.userId.length > 0 ? `&created_by_id=${filter.userId}` : ''
    const task_type = filter && filter.taskType && filter.taskType.length > 0 ? `&task_type=${filter.taskType}` : ''
    const fromDate = filter && filter.fromDate ? `&fromDate=${filter.fromDate}` : ''
    const toDate = filter && filter.toDate ? `&toDate=${filter.toDate}` : ''
    const searchWord = keyword ? `&search=${keyword}` : ''
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.ACTIVITY_LOG}/task-activity?page=${page}&limit=${limit}${fromDate}${toDate}${user}${searchWord}${task_type}`)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  logUserActivities(module: string, subModule: string, action: string, payload: any = {}) {
    const params = {
      user: this._storage.get('local', 'loggedInUser', 'user'),
      log: {
        module_name: module,
        sub_module: subModule,
        action,
        payload_data: payload
      }
    }
    return this.http.post(`${APICONSTANTS.ACTIVITY_LOG}/user-activity`, params).pipe(
      map((response => {
        if (response) {
          return response;
        }
      }))
    )
  }
}
