import { ChartDatasets, DiscrepenciesVolumeByEpisodeTypeItem } from './../../../../models/model.reports';
import { Component, OnInit, Input } from '@angular/core';
import { ChartType } from 'chart.js';
import 'chartjs-plugin-datalabels';
import { CHART_OPTIONS } from '../../../../constants/claimsReconcilitationSummary.constants'
import { BehaviorSubject } from 'rxjs';
import { CLAIMS_RECONCILIATION_VOLUME_BY_EPISODE_TYPE_DATA, MessageConstants, NO_DATA_IN_REPORT } from 'src/app/constants';

@Component({
  selector: 'app-volume-by-episode-type',
  templateUrl: './volume-by-episode-type.component.html',
  styleUrls: ['./volume-by-episode-type.component.scss']
})
export class VolumeByEpisodeTypeComponent implements OnInit {
  data: ChartDatasets[] = [];
  labels: string[] = [];
  tableItems$ = new BehaviorSubject(null);
  tableTotals: DiscrepenciesVolumeByEpisodeTypeItem;
  chartType: ChartType | string = '';
  chartOptions = JSON.parse(JSON.stringify(CHART_OPTIONS));
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT)

  @Input() set volumeEpisodeTypes(volumeEpisodeTypes) {
    if (volumeEpisodeTypes !== null && volumeEpisodeTypes.type) {
      const { chartType, labels } = volumeEpisodeTypes.chart;
      const { items, total } = volumeEpisodeTypes.referenceData;
      this.chartOptions.legend.display = true;
      this.chartType = chartType;
      this.labels = labels;
      this.data = volumeEpisodeTypes.chart.datasets;
      this.tableItems$.next(
        {
          data: items.concat([total]),
          excelName: '',
          tableTitle: '',
          tableHeaders: CLAIMS_RECONCILIATION_VOLUME_BY_EPISODE_TYPE_DATA.TABLE_HEADERS,
          columnsWidth: [],
          dateType: '',
          date: {},
        }
      );
      this.tableTotals = total;
    } else {
      this.data = [];
    }
  }

  constructor(
    private _messageConstants: MessageConstants
  ) { }

  ngOnInit() {
  }

}
