import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { MdbBtnDirective } from 'ng-uikit-pro-standard';
import { EpisodeTaskService } from 'src/app/services/episode-task.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { AccessType } from 'src/app/utils';

@Component({
  selector: 'app-episode-message',
  templateUrl: './episode-message.component.html',
  styleUrls: ['./episode-message.component.scss']
})
export class EpisodeMessageComponent implements OnInit {
  taskToBeDelete: any
  dependentTask: any
  messageUuid: any
  @Output() editTask = new EventEmitter<any>();
  @Input() public message;
  @Input() public optionId;
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.messageUuid)
        this.getMessage(this.messageUuid)
    }

  }
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;
  @Output() public btns = new EventEmitter<any>();
  @Output() public deletedTask = new EventEmitter<any>();
  @Input() public days_difference;

  @Input() user: any;

  @Input() set taskMessageUuid(taskMessageUuid) {
    if (taskMessageUuid) {
      this.messageUuid = taskMessageUuid
      this.getMessage(taskMessageUuid);
    }
  }
  constructor(
    private episodeTaskService: EpisodeTaskService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private utilityAccess: AccessType
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const buttons = this.buttons.toArray();
    setTimeout(() => {
      this.btns.emit(buttons);
    });
  }

  getMessage(uuid) {
    this.message = {};
    if (this.dependentTask && this.dependentTask.taskMessages) {
      this.dependentTask.taskMessages.filter(x => {
        if (x.uuid == uuid) {
          x.form = 'message';
          x.field = 'message';
          x.optionId = this.optionId;
          this.message = x;
        }
      });
    }
  }

  editTaskHandler(message) {
    this.editTask.emit(message);
  }

  deleteTaskHandler(message) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      message['source'] = 'Episode'
      message['type'] = 'Message'
      message['displayName'] = message.title
      this.taskToBeDelete = message
    }
    else this.toastr.warning("Access Denied", "Warning");
  }
  cancelDelete(e) {
    this.taskToBeDelete = e
  }
  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }

  updateTask(event, task) {
    let body = {
      id: task.id,
      type: 'message',
      messageTitle: task.title,
      episodeId: this.route.snapshot.params.episodeId
    }

    this.episodeTaskService
      .updateTaskStatus(body)
      .subscribe(
        res => {
          this.toastr.success("Message completed successfully.", "Success");
          this.message.status = 'COMPLETED';
        },
        error => {
          console.log(error);
        }
      );
  }
}
