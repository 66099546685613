export const EOB_CONSTANTS = {
    END_NOTE: "If you are currently on the high-deductible plan, you may be responsible for up to $1,500.00, please consult with your HR Department to determine your total patient responsibility.",
    CLIENTS_WITH_END_NOTE: ['GJ'],
    GREETING: 'Thank you for choosing',
    PLATFORM_INFO_REFERENCE_CODE: 'HOLISTA'
};

export const DOCUSIGN_ELIGIBLE_STATUS = ['DUE', 'DECLINED'];

export const FULL_EPISODE_EDIT_ELIGIBLE_ROLES = ['PA', 'CC', 'ES', 'SV'];

export const EDIT_EPISODE_DISABLED_STATUSES = ['CANCELLED', 'CLOSED'];

export const EPISODE_EDIT_ANSWER_DISABLED_STATUSES = ['CANCELLED', 'CLOSED', 'COMPLETED'];

export const TASK_STATUS = {
    COMPLETED: 'COMPLETED'
}

export const EPISODE_CANCELLED = ['CANCELLED']

export const EPISODE_STATUS_LIST = ['IN PROGRESS', 'COMPLETED', 'CLOSED', 'CANCELLED', 'DUE']
