<div class="h-100">
  <div class="flex-center flex-column">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-5 mx-auto my-auto">
          <!-- Material form login -->
          <mdb-card>
            <mdb-card-header class="info-color text-center white-text py-4 rounded-0">
              <i class="fas fa-lock-open"></i>
              Change Password
            </mdb-card-header>
            <!--Card content-->
            <mdb-card-body class="px-lg-5">
              <!-- Form -->
              <form class="" style="color: #757575" [formGroup]="changePasswordForm">
                <!-- Old Password -->
                <div class="md-form">
                  <input
                    type="password"
                    id="oldPassword"
                    class="form-control"
                    formControlName="oldPassword"
                    data-error=" "
                    data-success=" "
                    mdbInput
                    [ngClass]="{
                      'is-invalid': submitted && changePasswordForm.get('oldPassword').invalid
                    }" />
                  <label for="oldPassword">Old Password</label>
                </div>
                <!-- Password -->
                <div class="md-form">
                  <input
                    type="password"
                    id="newpassword"
                    class="form-control"
                    formControlName="password"
                    data-error=" "
                    data-success=" "
                    mdbInput
                    [ngClass]="{
                      'is-invalid': submitted && changePasswordForm.get('password').invalid
                    }" />
                  <label for="newpassword">New Password</label>
                </div>
                <!--Confirm Password -->
                <div class="md-form">
                  <input
                    type="password"
                    id="confirmPassword"
                    class="form-control"
                    formControlName="confirmPassword"
                    data-error=" "
                    data-success=" "
                    mdbInput
                    [ngClass]="{
                      'is-invalid': submitted && changePasswordForm.get('confirmPassword').invalid
                    }" />
                  <label for="confirmPassword">Confirm Password</label>
                </div>
                <p
                  class="text-sm"
                  *ngIf="
                    !changePasswordForm.controls.password.value ||
                    changePasswordForm.controls.password.errors?.pattern
                  ">
                  <i
                    [ngClass]="
                      changePasswordForm.controls.password.errors?.pattern
                        ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                        : 'fa fa-info-circle fw-2 text-info mr-2'
                    "></i>
                  <small
                    >Password must be at least 8 characters including at least one Uppercase ,
                    Special character i.e #@! and a number</small
                  >
                </p>
                <p
                  class="text-sm"
                  *ngIf="
                    !changePasswordForm.controls.confirmPassword.value ||
                    changePasswordForm.controls.confirmPassword.errors?.MatchPassword
                  ">
                  <i
                    [ngClass]="
                      changePasswordForm.controls.confirmPassword.errors?.MatchPassword
                        ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                        : 'fa fa-info-circle fw-2 text-info mr-2'
                    "></i>
                  <small>New Password and Confirm Password does not match</small>
                </p>
                <button
                  class="btn btn-secondary btn-rounded btn-block my-4 waves-effect"
                  mdbWavesEffect
                  type="button"
                  (click)="changePassword()"
                  [disabled]="isSubmitEnabled">
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="isSubmitEnabled"></span>
                  <i class="icofont-login" *ngIf="!isSubmitEnabled"></i>
                  Change password
                </button>
              </form>
              <!-- Form -->
            </mdb-card-body>
            <!-- Card -->
          </mdb-card>
          <!-- Material form login -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- PASSWORD CHANGE CONFIRMATION MODAL -->
<div
  mdbModal
  #changePasswordModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: false, keyboard: false}">
  <div class="modal-dialog modal-notify modal-info modal-dialog-centered" role="document">
    <!--Content-->
    <div class="modal-content rounded-0">
      <!--Header-->
      <div
        class="modal-header d-flex align-items-center primary-color rounded-0 z-depth-0 d-flex align-items-center">
        <p class="heading lead"><span class="icon-box"> </span>Change Password</p>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <p>Your password is changed Successfully.Please Login Again.</p>
          <button
            class="btn btn-secondary btn-md waves-light"
            mdbWavesEffect
            (click)="changeSuccess()"
            class="white-text"
            [disabled]="isSubmitEnabled">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="isSubmitEnabled"></span>
            Ok
          </button>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
