<div
  mdbModal
  #viewPermissonModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Permissions</h5>
      </div>
      <div class="scrollbar scrollbar-primary thin overflow-auto" style="max-height: 600px">
        <div class="modal-body">
          <app-add-edit-role-permissions
            *ngIf="Id"
            [id]="Id"
            [userName]="userName"
            [isView]="true"
            [type]="type"></app-add-edit-role-permissions>
        </div>
      </div>
      <div class="modal-footer p-1 d-flex justify-content-end grey lighten-3 border-0">
        <button class="btn btn-secondary mr-4" mdbWavesEffect (click)="hideModal()">Done</button>
      </div>
    </div>
  </div>
</div>
