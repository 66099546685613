import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { EpisodeService, ToasterService } from '../../../../../../services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-episode-signature-completion',
  templateUrl: './episode-signature-completion.component.html',
  styleUrls: ['./episode-signature-completion.component.scss']
})
export class EpisodeSignatureCompletionComponent implements OnInit {

  constructor(
    private _episodeService: EpisodeService,
    private _toastrService: ToasterService,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    const { event, episodeId, taskTodoId, taskId } = this._activatedRoute.snapshot.queryParams;
    const body = {
      status: event === 'signing_complete' ? 'in-progress' : 'declined',
      episodeId,
      taskTodoId,
      taskId
    };
    if (event === 'signing_complete') {
      this._episodeService.syncSignatureProgress(body).pipe(
        finalize(() => { })
      ).subscribe(() => window.close())
        , (error) => {
          console.log('error', error);
          this._toastrService.showError(error);
        }
    } else window.close();
  }
}
