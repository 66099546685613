<!-- PATIENT ID CARD -->
<app-loader [loader]="loading.idCard"></app-loader>

<div *ngIf="idCards && idCards.length > 0">
  <div
    *ngFor="let cardDetail of idCards"
    class="card-id"
    [ngClass]="cardDetail.id == printIdCard ? 'print-id' : 'print-d-none'">
    <div *ngIf="cardDetail && cardDetail.member?.memberUuid && !loading.idCard">
      <div id="contentToConvert">
        <div
          class="donotprint d-flex"
          [ngClass]="showText ? 'justify-content-between  mt-4' : 'justify-content-end'">
          <h5 *ngIf="showText" class="mb-0">Member ID Card</h5>
          <button
            class="btn btn-link waves-light p-0"
            mdbWavesEffect
            (click)="captureScreen(cardDetail)">
            <i class="icofont-print text-primary-dark"></i> Print
          </button>
        </div>
        <div class="idcard scrollbar scrollbar-primary thin overflow-auto" [ngClass]="showText ? 'h-100' : 'w-100'">
            <table class="table-sm table-bordered h-100" [ngClass]="{'w-100': !showText}" id="print">
              <tr>
                <td>
                  <table class="table-borderless">
                    <tr>
                      <td>
                        <img [src]="cardDetail?.logo" class="img-fluid w-50 mt-2 mb-2" />
                      </td>
                      <td *ngIf="cardDetail?.secondaryLogo">
                        <img [src]="cardDetail?.secondaryLogo" class="img-fluid w-75 mt-2 mb-2" />
                      </td>
                      <td *ngIf="!cardDetail?.secondaryLogo">
                        <h6 class="font-weight-500">Episode of Care Member ID Card</h6>
                      </td>
                    </tr>
                    <tr *ngIf="cardDetail?.secondaryLogo">
                      <td colspan="100%" class="text-center">
                        <h6 class="font-weight-500">Episode of Care Member ID Card</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ hideGroupNameLabel.includes(cardDetail?.groupName) ? '' : 'Group Name:' }}
                      </td>
                      <td>{{ cardDetail?.groupName }}</td>
                    </tr>
                    <tr *ngIf="cardDetail?.clientCode === clientCodes.novant">
                      <td>Group ID:</td>
                      <td>{{ cardDetail?.groupId }}</td>
                    </tr>
                    <tr>
                      <td>Benefit Plan:</td>
                      <td>
                        {{ cardDetail?.benefitPlan }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        (cardDetail?.planCode &&
                          cardDetail?.clientCode !== clientCodes.hps &&
                          cardDetail?.clientCode !== clientCodes.prairieStates) ||
                        (cardDetail?.clientCode == clientCodes.prairieStates && cardDetail?.planCode)
                      ">
                      <td>Plan Code:</td>
                      <td>
                        {{ cardDetail?.planCode }}
                      </td>
                    </tr>
                    <tr>
                      <td>Subscriber/Member ID:</td>
                      <td>
                        {{ cardDetail?.member?.subscriberNumber?.replace('-', '').toUpperCase() }}
                      </td>
                    </tr>
                    <tr>
                      <td>Member Name:</td>
                      <td>{{ cardDetail.member?.firstName }} {{ cardDetail.member?.lastName }}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth:</td>
                      <td>{{ cardDetail.member?.dob | date : 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr *ngIf="cardDetail.benefitDetails.length == 1">
                      <td>{{ cardDetail?.episodeTypeText }}</td>
                      <td>
                        {{ cardDetail.benefitDetails[0].episodeType }}
                      </td>
                    </tr>

                    <tr *ngIf="cardDetail.benefitDetails.length == 1">
                      <td>
                        {{ cardDetail?.episodeBenefitDateText }}
                      </td>
                      <td>
                        {{
                          cardDetail &&
                          cardDetail.episodeBenefitDateText &&
                          cardDetail.episodeBenefitDateText.toLowerCase() == 'benefit dates:'
                            ? cardDetail.benefitDetails[0].benefitDates
                            : cardDetail.benefitDetails[0].episodeCoverageDates
                        }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr *ngIf="cardDetail.benefitDetails && cardDetail.benefitDetails.length > 1">
                <td class="border-0 print-p-0">
                  <table class="print-border">
                    <tbody>
                      <tr>
                        <td class="border-top-0 border-left-0 text-muted">
                          {{ cardDetail?.episodeTypeText }}
                        </td>
                        <td class="border-top-0 border-right-0 text-left text-muted">
                          {{ cardDetail?.episodeBenefitDateText }}
                        </td>
                      </tr>
                      <tr *ngFor="let episode of cardDetail.benefitDetails">
                        <td class="border-left-0">
                          {{ episode.episodeType ? episode.episodeType : 'N/A' }}
                        </td>
                        <td class="text-left border-right-0">
                          {{
                            cardDetail &&
                            cardDetail.episodeBenefitDateText &&
                            cardDetail.episodeBenefitDateText.toLowerCase() ==
                              'benefit
                                                  dates:'
                              ? episode.benefitDates
                              : episode.episodeCoverageDates
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="d-flex justify-content-center font-italic">For Administration</p>
                  <table class="table-borderless">
                    <tr>
                      <td>
                        {{ cardDetail?.claimContactText }}
                      </td>
                      <td>{{ cardDetail?.claimContactNumber }}</td>
                    </tr>
                    <tr>
                      <td>
                        {{ cardDetail?.customerContactText }}
                      </td>
                      <td>{{ cardDetail?.customerContactNumber }}</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-left">
                        {{ cardDetail?.submitClaimText }}
                        <table class="sub-level">
                          <tr>
                            <td class="text-left pl-3">{{ cardDetail?.clearingHouseText }}</td>
                            <td class="pr-0">{{ cardDetail?.clearingHouseName }}</td>
                          </tr>
                          <tr>
                            <td class="text-left pl-3">{{ cardDetail?.payerText }}</td>
                            <td class="pr-0">{{ cardDetail?.payerId }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td>
                        {{ cardDetail?.pwpText }}
                      </td>
                      <td class="text-link">
                        <a [href]="cardDetail?.pwpLink" target="_blank"
                          ><u>{{ cardDetail?.pwpLink }}</u></a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-center pt-1">
                        <strong>{{ cardDetail?.copayText }}</strong>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="idCards && idCards.length == 0 && !loading.idCard">
  <p>No ID Card Available</p>
</div>
