import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from "rxjs/operators";
// import { HttpClient } from '@angular/common/http';
// import { environment } from 'src/environments/environment';
// import { Storage } from '../utils'
// const API_BASE_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class MilestoneService {

  constructor(
    private http: HttpClientService,
    // private http1: HttpClient,
    // private _storage: Storage
  ) { }

  // createHeader(formdata?) {
  //   let headers;
  //   const loggedInUser: any = this._storage.get('local', 'loggedInUser')
  //   if (loggedInUser) {
  //     const token = loggedInUser.token;
  //     headers = new HttpHeaders().set('Authorization', token)
  //     if (formdata) {
  //       headers.delete('Content-Type');
  //     }
  //   }
  //   return headers;
  // }

  getMilestones(pathway_id, queryParam?) {
    return this.http.get(`${APICONSTANTS.PATHWAY}/${pathway_id}${APICONSTANTS.MILESTONE}`, queryParam)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  saveMilestone(milestone) {
    return this.http.post(`${APICONSTANTS.MILESTONE}`, milestone)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateMilestone(milestone) {
    return this.http.put(`${APICONSTANTS.MILESTONE}`, milestone)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  changeMilestoneSequence(milestone) {
    return this.http.put(`${APICONSTANTS.MILESTONE}/order`, milestone)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getNotes(episodeId) {
    return this.http.get(`${APICONSTANTS.EPISODE}/${episodeId}${APICONSTANTS.NOTES}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  saveNote(note) {
    let formData = new FormData();
    if (note.documents) formData.append('documents', note.documents);
    if (note.holistaMedia && note.holistaMedia.length > 0) {
      for (var i = 0; i < note.holistaMedia.length; i++) {
        formData.append("holistaMedia", note.holistaMedia[i], note.holistaMedia[i].displayName);
      }
    }
    // formData.append("holistaMedia[]", note.holistaMedia[0],  note.holistaMedia[0].name );
    formData.append('type', note.type);
    formData.append('note', note.note);
    formData.append('episodeId', note.episodeId);
    // let headers = this.createHeader(true);
    // return this.http1.post(`${API_BASE_URL}${APICONSTANTS.EPISODE}${APICONSTANTS.NOTES}`, formData
    return this.http.post(`${APICONSTANTS.EPISODE}${APICONSTANTS.NOTES}`, formData
      // , {
      //   headers: headers
      // }
    )
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  deleteNote(note) {
    return this.http.remove(`${APICONSTANTS.EPISODE}${APICONSTANTS.NOTES}/${note.id}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  // searchDocument(search_text) {
  //   return this.http.get(`${APICONSTANTS.DOCUMENT}/search/${search_text}`)
  //     .pipe(map((res: any) => {
  //       return res.data;
  //     }));
  // }

  completeMilestoneReason(milestoneId, reason?) {
    const completionReason = reason ? { reason: reason } : ''
    return this.http.patch(`/episode-milestones/${milestoneId}/complete`, completionReason)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  // updateNote(note){
  //   return this.http.put(`${APICONSTANTS.MILESTONE}/order`, note)
  //     .pipe(map((res: any) => {
  //       return res;
  //     }));
  // }

}
