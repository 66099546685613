import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MdbFileUploadModule } from 'mdb-file-upload';
import { AppStoreModule } from './app-store.modules';
import { ToastrModule } from 'ngx-toastr';
import { TextMaskModule } from 'angular2-text-mask';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { ResizableModule } from 'angular-resizable-element';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ChartsModule, ThemeService } from 'ng2-charts';
import * as common from './common';
import * as components from './components';
import * as services from './services';
import * as guards from './guards';
import * as utils from './utils';
import * as constants from './constants';
import * as pipes from '../app/pipes';
import * as directive from '../app/directives';
import * as modals from '../app/modals';
import * as interceptors from './interceptors';
import { AngularDraggableModule } from 'angular2-draggable';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { NgOtpInputModule } from 'ng-otp-input';
import { DndListModule } from 'ngx-drag-and-drop-lists';

@NgModule({
  declarations: [
    AppComponent,
    common.HeaderComponent,
    common.SidebarComponent,
    common.LoaderComponent,
    common.SelectComponent,
    common.TableComponent,
    components.LayoutLoginComponent,
    components.LayoutMainComponent,
    components.DashboardComponent,
    components.LoginComponent,
    components.ResetPasswordComponent,
    components.PrivacyPolicyComponent,
    components.TermsOfUseComponent,
    components.CompanyComponent,
    components.UserComponent,
    components.PathwayComponent,
    components.PathwayMilestoneComponent,
    components.EpisodeComponent,
    components.EpisodeOfCareComponent,
    components.ClaimsComponent,
    components.ClaimDetailComponent,
    components.OutgoingClaimsComponent,
    components.ClaimsEdiComponent,
    components.ServicesDetailComponent,
    components.JournalComponent,
    components.LedgerComponent,
    components.DocumentComponent,
    components.MilestoneTopicComponent,
    components.TopicTaskComponent,
    components.RolePermissionsComponent,
    components.EpisodeMilestoneComponent,
    components.EpisodeTopicComponent,
    components.EpisodeTaskComponent,
    components.LogoutComponent,
    components.LayoutChangepasswordComponent,
    components.TaskTodoComponent,
    components.TaskMessageComponent,
    components.TaskQuestionComponent,
    components.MyProfileComponent,
    components.EpisodeTodoComponent,
    components.EpisodeQuestionComponent,
    components.EpisodeMessageComponent,
    components.EpisodeUserTaskComponent,
    components.TaskQuestionnaireComponent,
    components.EpisodeUserMessageComponent,
    components.EpisodeUserTodoComponent,
    components.EpisodeUserQuestionComponent,
    components.EpisodeQuestionnaireComponent,
    components.EpisodeUserQuestionnaireComponent,
    components.IncomingCallComponent,
    components.OutgoingCallComponent,
    components.ProviderComponent,
    components.BundleComponent,
    components.BundleCostComponent,
    components.AdminEpisodeComponent,
    components.PatientComponent,
    components.ActivityLogComponent,
    components.EpisodeUserDependentQuestionComponent,
    components.EpisodeUserDependentQuestionnaireComponent,
    components.EpisodeUserDependentTodoComponent,
    components.EpisodeUserDependentMessageComponent,
    components.PaymentComponent,
    components.IncomingClaimsEdiComponent,
    components.ClaimsProcessingStatusComponent,
    components.ClaimsDashboardComponent,
    components.ProvidersComponent,
    components.MobileActivationComponent,
    components.SSOLoginComponent,
    components.PurchaserComponent,
    components.NetworkComponent,
    components.ClientComponent,
    components.NewClaimsDashboardComponent,
    components.ReportComponent,
    components.ClaimsReconciliationSummaryComponent,
    components.HighOccurrenceProvidersComponent,
    components.TotalClaimsComponent,
    components.VolumeByEpisodeTypeComponent,
    components.FinancialByEpisodeTypeComponent,
    components.FinancialReconciliationComponent,
    components.FundingReportComponent,
    components.Ub04FormComponent,
    components.CommunicationsComponent,
    components.MessageListComponent,
    components.MessageViewComponent,
    components.MessageComponent,
    components.CommunicationsOtpComponent,
    components.OtpVerificationComponent,
    components.PatientSearchComponent,
    components.PatientListComponent,
    components.ReceivedPaymentComponent,
    components.PaidPaymentComponent,
    components.EpisodeSignatureCompletionComponent,
    components.TaskSignatureComponent,
    components.EpisodeSignatureComponent,
    components.EpisodeUserSignatureComponent,
    components.EpisodeUserDependentSignatureComponent,
    components.ValueManagementDashboardComponent,
    components.ClientClaimsDashboardComponent,
    components.QuestionReviewDashboardComponent,
    components.OverallClaimsComponent,
    components.OverviewComponent,
    components.PatientExperienceComponent,
    components.TriggerClaimCycleTimeComponent,
    components.PayorCycleTimeComponent,
    components.RequestEocComponent,
    components.HolistaCycleTimeComponent,
    components.TotalCycleTimeComponent,
    components.PortalQuestionDetailComponent,
    components.ConsentedPatientCommentsComponent,
    components.NetworkCycleTimeComponent,
    components.ServiceCategoryUtilizationComponent,
    components.TransactionReportComponent,
    components.AccountsReceivableComponent,
    components.AccountsPayableComponent,
    components.SummaryYtdComponent,
    components.PaymentReportComponent,
    components.ProjectedEpisodeProfitComponent,
    components.FinancialReportComponent,
    components.PayeesComponent,
    components.AncillaryReportComponent,
    components.AddEditRolePermissionComponent,
    pipes.ManualFilterPipe,
    pipes.FormatPhoneNumberPipe,
    pipes.TruncatePipe,
    pipes.SafeHtmlPipe,
    pipes.SecondsToTimePipe,
    pipes.FormatWithDash,
    pipes.ArraySortPipe,
    pipes.ChatDate,
    pipes.MessageDate,
    directive.NumberDirective,
    directive.ScrollToTopDirective,
    directive.DisableControlDirective,
    directive.PreviousChatUsersDirective,
    modals.MilestoneTopicDeleteComponent,
    directive.NumberCommaDirective,
    directive.AlphaNumericDirective,
    modals.PatientDemographicComponent,
    modals.ProviderDetailComponent,
    modals.SwitchRoleComponent,
    modals.IdCardComponent,
    modals.DeleteComfirmationComponent,
    modals.CardTemplateComponent,
    modals.ViewEOBComponent,
    modals.CommunicationMessageComponent,
    modals.MultiFactorAuthenticationComponent,
    modals.ViewPermissionComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppStoreModule,
    AppRoutingModule,
    MDBBootstrapModulesPro.forRoot(),
    MdbFileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    TextMaskModule,
    OrderModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      // closeButton: false,
      tapToDismiss: true,
      preventDuplicates: true
    }),
    NgxPaginationModule,
    ResizableModule,
    HttpClientModule,
    AngularEditorModule,
    AngularDraggableModule,
    ChartsModule,
    NgOtpInputModule,
    DndListModule
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    PercentPipe,
    ThemeService,
    guards.AuthGuard,
    guards.RoleGuard,
    guards.EocGuard,
    guards.loginGuard,
    guards.NonPlatformUserGuard,
    services.HttpClientService,
    services.AuthService,
    services.CompanyService,
    services.DashboardService,
    services.DocumentService,
    services.EpisodeService,
    services.EpisodeOfCareService,
    services.PathwayService,
    services.UserService,
    services.MilestoneTaskService,
    services.MilestoneTopicService,
    services.MemberService,
    services.FundingRequestService,
    services.PurchaserService,
    services.PlatformService,
    services.ValueBasedProgramService,
    services.FundingSourceService,
    services.EmployerGroupService,
    services.ToasterService,
    services.ActivityLogService,
    services.ClaimsService,
    services.JournalService,
    services.LedgerService,
    services.VersionCheckService,
    services.CacheService,
    services.ReportConfigurationService,
    services.NetworkService,
    services.MarketService,
    services.UnreadInboxCountService,
    services.IdleTimerService,
    services.DataLinkService,
    services.StorageService,
    constants.MessageConstants,
    utils.Sorting,
    utils.HolistaUtils,
    utils.AccessType,
    utils.RollingSevenDays,
    utils.Storage,
    utils.ScrollTo,
    utils.FormatPhoneNumber,
    utils.CurrencyFormat,
    utils.HostTheme,
    utils.TitleCase,
    utils.NameInitials,
    utils.EmailUtility,
    utils.MetaVariablesBinding,
    utils.PDFUtility,
    utils.FormatHtmlText,
    utils.DateUtility,
    utils.ReportUtility,
    { provide: HTTP_INTERCEPTORS, useClass: interceptors.HeaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
