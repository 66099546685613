import { Component, OnInit, ViewChild } from "@angular/core";
import { Store } from '@ngrx/store';
import { ClaimsService, ClientService, ToasterService } from '../../services';
import * as HeaderBreadCrumbActions from '../../action';
import { finalize, startWith, switchMap, debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { Router, NavigationEnd, Event } from '@angular/router';
import { statusModel } from '../claims/claims.component';
import { ACCESS_DENIED, APPROVED, OutgoingClaimsConstant, UPDATE_SUCCESS } from '../../constants'
import { FormBuilder, FormGroup } from "@angular/forms";
import { Storage, AccessType } from '../../utils';
import { CommunicationModuleOptions } from '../../models';
@Component({
    selector: 'outgoing-claims',
    templateUrl: './outgoing-claims.component.html'
})

export class OutgoingClaimsComponent implements OnInit {
    @ViewChild('reasonModal', { static: true }) public reasonModal;
    @ViewChild('outgoingClaimsFilterModal', { static: true }) public outgoingClaimsFilterModal;
    statusModel: statusModel = new statusModel();
    selectedClaim: any = {};
    searchModelChanged: Subject<string> = new Subject<string>();
    submitterSearchText = new Subject();
    submitterResults: Observable<any>;
    patientSearchText = new Subject();
    patientResults: Observable<any>;
    billingProviderSearchText = new Subject();
    billingProviderResults: Observable<any>;
    episodeSearchText = new Subject();
    episodeResults: Observable<any>;
    payerSearchText = new Subject();
    payerResults: Observable<any>;
    clientSearchText = new Subject();
    clientResults: Observable<any>;
    purchaserSearchText = new Subject();
    purchaserResults: Observable<any>;
    outgoingClaimsfilterForm: FormGroup;
    filterList = [];
    tempPatient: any[] = [];
    tempSubmiter: any[] = [];
    tempBillingProvider: any[] = [];
    tempClient: any[] = [];
    tempEpisode: any[] = [];
    tempPayer: any[] = [];
    tempPurchaser: any[] = [];
    claimsList: any[] = [];
    backupClaimsList: any[] = [];
    loading = {
        claims: false,
        saving: false,
    };
    page = 1;
    limit = 10;
    claimType = 'OUTGOING';
    sortBy: string;
    sortOrder: string = 'DESC';
    reverse: boolean = true;
    totalCount = 0;
    searchKeyword = '';
    processingStatus = OutgoingClaimsConstant.processingStatus;
    exceptionCodes: any[] = [];
    showMessageModal: boolean = false;
    metaVariablesDetail: any;
    moduleOptions: CommunicationModuleOptions
    allowedOutgoingClaimStatus = {
        'in-review': [],
        'approved': [],
        'rejected': [],
        'cancelled': [],
        'received': [],
        'submitted': [],
        're-submitted': [],
        'pended': []
    }


    constructor(private store: Store<{ bread_crumbs: any }>,
        private claimsService: ClaimsService,
        private router: Router,
        private _toastr: ToasterService,
        private formBuilder: FormBuilder,
        private _storage: Storage,
        private utilityAccess: AccessType,
        private _clientService: ClientService
    ) {
        this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
            this.searchKeyword = data;
            let filterData = this._storage.get('session', 'outgoingClaimsFilterData');
            if (filterData) {
                filterData.keyword = data;
                this._storage.set('session', 'outgoingClaimsFilterData', filterData)
            }
            this.outgoingClaimsfilterForm.controls.keyword.setValue(data.trim());
            this.page = 1
            this.outgoingClaimsfilterForm.controls.page.setValue(1);
            this.searchClaims(this.outgoingClaimsfilterForm.value);
        });

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (!event.url.startsWith('/outgoing-claims') && this._storage.get('session', 'outgoingClaimsFilterData')) {
                    let filterData = this._storage.get('session', 'outgoingClaimsFilterData');
                    filterData.keyword = '';
                    filterData.page = 1;
                    this._storage.set('session', 'outgoingClaimsFilterData', filterData);
                }
            }
        });
        this.claimsService.getClaimStatus().subscribe((response: any) => {
            if (response) {
                Object.keys(this.allowedOutgoingClaimStatus).forEach(status => {
                    let allowedStatus = response.filter(x => x.fromStatus === status.toUpperCase()).map(x => {
                        x.label = x.toStatus.toLowerCase();
                        x.value = x.toStatus
                        delete x.toStatus
                        delete x.fromStatus
                        return x
                    }
                    )
                    this.allowedOutgoingClaimStatus[status] = allowedStatus
                })
            }
        }, (error) => {
            this._toastr.showError(error);
        })
        this.setoutgoingClaimsFilterForm();
    }

    ngOnInit() {
        this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Outgoing Claims', path: '/outgoing-claims' }));
        this.getExceptionCodes();
        this.submitterResults = this.submitterSearchText
            .pipe(
                startWith(this.submitterSearchText),
                switchMap((submitter_search_text: string) => this.searchSubmitter(submitter_search_text))
            );
        this.patientResults = this.patientSearchText
            .pipe(
                startWith(this.patientSearchText),
                switchMap((patient_search_text: string) => this.searchPatient(patient_search_text))
            );
        this.billingProviderResults = this.billingProviderSearchText
            .pipe(
                startWith(this.billingProviderSearchText),
                switchMap((billing_provider_search_text: string) => this.searchBillingProvider(billing_provider_search_text))
            );
        this.episodeResults = this.episodeSearchText
            .pipe(
                startWith(this.episodeSearchText),
                switchMap((episode_search_text: string) => this.searchEpisode(episode_search_text))
            );
        this.payerResults = this.payerSearchText
            .pipe(
                startWith(this.payerSearchText),
                switchMap((payer_search_text: string) => this.searchPayer(payer_search_text))
            );
        this.clientResults = this.clientSearchText
            .pipe(
                startWith(this.clientSearchText),
                switchMap((client_search_text: string) => this.searchClient(client_search_text))
            );
        this.purchaserResults = this.purchaserSearchText
            .pipe(
                startWith(this.purchaserSearchText),
                switchMap((purchaser_search_text: string) => this.searchPurchaser(purchaser_search_text))
            );
        let filterData = this._storage.get('session', 'outgoingClaimsFilterData');
        if (filterData) {
            this.getFilterList(filterData)
            if (filterData.keyword && filterData.keyword.length > 0)
                this.searchKeyword = filterData.keyword;
            this.outgoingClaimsfilterForm.patchValue(filterData);
            this.filterClaims();
        }
        else
            this.searchClaims(this.outgoingClaimsfilterForm.value);
    }

    getFilterList(filterData) {
        if (filterData.patientId) {
            this.tempPatient = this._storage.get('session', 'outgoingClaimsFilteredPatient');
            let patientName = this.tempPatient.find(x => x.value == filterData.patientId).label;
            this.patientSearchText.next(this.toTitleCase(patientName));
        }
        if (filterData.submitterId) {
            this.tempSubmiter = this._storage.get('session', 'outgoingClaimsFilteredSubmitter');
            let submitterName = this.tempSubmiter.find(x => x.value == filterData.submitterId).label;
            this.submitterSearchText.next(this.toTitleCase(submitterName));
        }
        if (filterData.billingProviderId) {
            this.tempBillingProvider = this._storage.get('session', 'outgoingClaimsFilteredBillingProvider');
            let billingProviderName = this.tempBillingProvider.find(x => x.value == filterData.billingProviderId).label;
            this.billingProviderSearchText.next(this.toTitleCase(billingProviderName));
        }
        if (filterData.episodeName) {
            this.tempEpisode = this._storage.get('session', 'outgoingClaimsFilteredEpisode');
            let episodeName = this.tempEpisode.find(x => x.value == filterData.episodeName).label;
            this.episodeSearchText.next(this.toTitleCase(episodeName));
        }
        if (filterData.payerCode) {
            this.tempPayer = this._storage.get('session', 'outgoingClaimsFilteredPayer');
            let payerName = this.tempPayer.find(x => x.value == filterData.payerCode).label;
            this.payerSearchText.next(this.toTitleCase(payerName));
        }
        if (filterData.clientCode) {
            this.tempClient = this._storage.get('session', 'outgoingClaimsFilteredClient');
            let clientName = this.tempClient.find(x => x.value == filterData.clientCode).label;
            this.clientSearchText.next(this.toTitleCase(clientName));
        }
        if (filterData.purchaserCode) {
            this.tempPurchaser = this._storage.get('session', 'outgoingClaimsFilteredPurchaser');
            let purchaserName = this.tempPurchaser.find(x => x.value == filterData.purchaserCode).label;
            this.purchaserSearchText.next(this.toTitleCase(purchaserName));
        }
    }

    setOrder(value: string) {
        if (this.sortBy === value) {
            this.reverse = !this.reverse;
            this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
        }
        else {
            this.reverse = true;
            this.sortOrder = "DESC";
        }
        this.sortBy = value;
        let filter_data = this._storage.get('session', 'outgoingClaimsFilterData');
        if (filter_data) {
            filter_data.sortOrder = this.sortOrder;
            filter_data.sortBy = this.sortBy;
            this._storage.set('session', 'outgoingClaimsFilterData', filter_data)
        }
        this.outgoingClaimsfilterForm.controls.sortOrder.setValue(this.sortOrder);
        this.outgoingClaimsfilterForm.controls.sortBy.setValue(this.sortBy);
        this.searchClaims(this.outgoingClaimsfilterForm.value);
    }

    setoutgoingClaimsFilterForm() {
        this.outgoingClaimsfilterForm = this.formBuilder.group({
            patientId: [null],
            submitterId: [null],
            billingProviderId: [null],
            subscriberNumber: '',
            processingStatus: [null],
            episodeName: [null],
            payerCode: [null],
            clientCode: [null],
            purchaserCode: [null],
            page: 1,
            keyword: '',
            sortBy: '',
            sortOrder: ''
        });
    }

    getClassName(status) {
        switch (status) {
            case 'in-review':
                return 'preliminary'
            case 'pended':
                return 'queue'
            case 'submitted':
            case 're-submitted':
                return 'requested'
            case 'cancelled':
                return 'closed'
            case 'rejected':
                return 'due'
            case 'received':
                return 'confirmed'
            case 'approved':
                return 'completed'
        }
    }

    statusChanged(event, claim) {
        if (this.utilityAccess.searchAccess('OCLM', 'isEditable')) {
            if (event.value == 'REJECTED' || event.value == 'CANCELLED') {
                this.statusModel = new statusModel();
                this.statusModel.status = event.value;
                this.reasonModal.show();
                this.selectedClaim = claim;
            }
            else {
                let status = { status: event.value };
                this.updateStatus(claim.id, status);
            }
        }
        else {
            this._toastr.displayWarning(ACCESS_DENIED);
        }
    }

    // Reject/ Cancel Claim
    submitReason() {
        this.updateStatus(this.selectedClaim.id, this.statusModel);
    }

    updateStatus(claimId, statusBody) {
        this.loading.saving = true;
        let stausForError = this.backupClaimsList.find(x => x.id == claimId).claimXRefHolista.processingStatus;
        this.claimsService.updateOutGoingClaimStatus(claimId, statusBody)
            .pipe(finalize(() => { this.loading.saving = false; }))
            .subscribe((res: any) => {
                if (res) {
                    res['processingStatus'] = this.allowedOutgoingClaimStatus[res.claimXRefHolista.processingStatus.toLowerCase()]
                    res['claimStatusClass'] = this.getClassName(res.claimXRefHolista.processingStatus.toLowerCase());
                    let index = this.claimsList.findIndex(x => x.id == res.id)
                    if (index > -1)
                        this.claimsList[index] = JSON.parse(JSON.stringify(res));
                    index = this.backupClaimsList.findIndex(x => x.id == res.id)
                    if (index > -1)
                        this.backupClaimsList[index] = JSON.parse(JSON.stringify(res));
                    if (statusBody.status === APPROVED)
                        this._toastr.displaySuccess(APPROVED);
                    else
                        this._toastr.displaySuccess(UPDATE_SUCCESS);
                }
            }, (error) => {
                let index = this.claimsList.findIndex(x => x.id == claimId);
                if (index > -1)
                    this.claimsList[index].claimXRefHolista.processingStatus = stausForError;
                console.log('Error changing status', error);
                this._toastr.showError(error);
            }, () => {
                this.closeReasonModal(null);
            })
    }

    closeReasonModal(action) {
        if (action == 'cancel') {
            let index = this.claimsList.findIndex(x => x.id == this.selectedClaim.id)
            if (index > -1) {
                let index2 = this.backupClaimsList.findIndex(x => x.id == this.selectedClaim.id)
                if (index2 > -1)
                    this.claimsList[index].claimXRefHolista.processingStatus = this.backupClaimsList[index2].claimXRefHolista.processingStatus;
            }
        }
        this.reasonModal.hide();
        this.selectedClaim = {};
    }

    searchByKeyword(event) {
        this.searchModelChanged.next(event);
    }

    view(claims) {
        this._clientService.getFundingSourceByCode(claims.claimXRefHolista.payerCode).subscribe(res => {
            this.router.navigate(['outgoing-claims', claims.id, 'edi', (claims.claimXRefHolista.processingStatus.toLowerCase() == 'cancelled' || claims.claimXRefHolista.processingStatus.toLowerCase() == 'received' ? 'true' : claims.isApproved)]);
            let claimsEdiData = { fundingFormat: null, processingStatus: null, originalReferenceNumber: null }
            claimsEdiData.fundingFormat = res && res.rows.length ? res.rows[0].fundingFormat : null;
            claimsEdiData.processingStatus = claims.claimXRefHolista.processingStatus
            claimsEdiData.originalReferenceNumber = claims.originalReferenceNumber;
            this._storage.set('session', 'claimsEdiData', claimsEdiData);
        });
        this._storage.set('session', 'outgoingClaimsFilterData', this.outgoingClaimsfilterForm.value);
    }

    pageChanged(event) {
        this.page = event;
        this.outgoingClaimsfilterForm.controls.page.setValue(event);
        this.searchClaims(this.outgoingClaimsfilterForm.value);
    }

    goToPayment(claim) {
        if (this.utilityAccess.searchAccess('PAY', 'isEditable'))
            this.router.navigate(['payment/create/received'], { queryParams: { claimId: claim.id, claimIdentifier: claim.patientAccountNumber } });
        else
            this._toastr.displayWarning(ACCESS_DENIED);
    }

    //Advance Search
    openFilterModal() {
        const filterData = this._storage.get('session', 'outgoingClaimsFilterData');
        if (filterData) {
            this.getFilterList(filterData);
            this.outgoingClaimsfilterForm.reset();
            this.outgoingClaimsfilterForm.patchValue(filterData);
        }
        this.outgoingClaimsFilterModal.show();
    }

    searchClaims(body) {
        this.loading.claims = true;
        this.claimsService.searchClaims(body, this.claimType)
            .pipe(finalize(() => { this.loading.claims = false; }))
            .subscribe((response: any) => {
                if (response.count != 0) {
                    this.totalCount = response.count;
                    response.rows.map(x => {
                        if (x.claimXRefHolista && x.claimXRefHolista.status.toLowerCase() !== 'invalid') {
                            x['processingStatus'] = this.allowedOutgoingClaimStatus[x.claimXRefHolista.processingStatus.toLowerCase()]
                            x['claimStatusClass'] = this.getClassName(x.claimXRefHolista.processingStatus.toLowerCase());
                        }
                        return x;
                    })
                    this.claimsList = JSON.parse(JSON.stringify(response.rows));
                    this.backupClaimsList = JSON.parse(JSON.stringify(response.rows));
                }
                else {
                    this.claimsList = [];
                    this.backupClaimsList = [];
                }
            }, (error) => {
                console.log("Error while advance search", error);
            })
    }

    onSearchResultSelected(event, field) {
        if (field == 'billingProviderId')
            this.outgoingClaimsfilterForm.controls[`${field}`].setValue(event.text && event.text.billingProvider.id ? event.text.billingProvider.id : null);
        else if (field == 'patientId')
            this.outgoingClaimsfilterForm.controls[`${field}`].setValue(event.text && event.text.displayName ? event.text.displayName : null);
        else if (field == 'episodeName')
            this.outgoingClaimsfilterForm.controls[`${field}`].setValue(event.text && event.text.episodeName ? event.text.episodeName : null);
        else if (field == 'clientCode')
            this.outgoingClaimsfilterForm.controls[`${field}`].setValue(event.text && event.text.clientCode ? event.text.clientCode : null);
        else if (field == 'payerCode')
            this.outgoingClaimsfilterForm.controls[`${field}`].setValue(event.text && event.text.payerCode ? event.text.payerCode : null);
        else if (field == 'purchaserCode')
            this.outgoingClaimsfilterForm.controls[`${field}`].setValue(event.text && event.text.purchaserCode ? event.text.purchaserCode : null);
        else
            this.outgoingClaimsfilterForm.controls[`${field}`].setValue(event.text && event.text.id ? event.text.id : null);
        switch (field) {
            case 'patientId':
                this.tempPatient = [];
                this.tempPatient.push({
                    label: event.text && event.text.displayName ? event.text.displayName : '',
                    value: event.text && event.text.displayName ? event.text.displayName : null
                });
                this._storage.set('session', 'outgoingClaimsFilteredPatient', this.tempPatient);
                break;
            case 'submitterId':
                this.tempSubmiter = [];
                this.tempSubmiter.push({
                    label: event.text && event.text.id ? event.text.displayName : '',
                    value: event.text && event.text.id ? event.text.id : null
                });
                this._storage.set('session', 'outgoingClaimsFilteredSubmitter', this.tempSubmiter);
                break;
            case 'billingProviderId':
                this.tempBillingProvider = [];
                this.tempBillingProvider.push({
                    label: event.text && event.text.id ? event.text.displayName : '',
                    value: event.text && event.text.id ? event.text.billingProvider.id : null
                });
                this._storage.set('session', 'outgoingClaimsFilteredBillingProvider', this.tempBillingProvider);
                break;
            case 'episodeName':
                this.tempEpisode = [];
                this.tempEpisode.push({
                    label: event.text && event.text.episodeName ? event.text.episodeName : '',
                    value: event.text && event.text.episodeName ? event.text.episodeName : null
                });
                this._storage.set('session', 'outgoingClaimsFilteredEpisode', this.tempEpisode);
                break;
            case 'payerCode':
                this.tempPayer = [];
                this.tempPayer.push({
                    label: event.text && event.text.payerCode ? event.text.payerName : '',
                    value: event.text && event.text.payerCode ? event.text.payerCode : null
                });
                this._storage.set('session', 'outgoingClaimsFilteredPayer', this.tempPayer);
                break;
            case 'clientCode':
                this.tempClient = [];
                this.tempClient.push({
                    label: event.text && event.text.clientCode ? event.text.clientName : '',
                    value: event.text && event.text.clientCode ? event.text.clientCode : null
                });
                this._storage.set('session', 'outgoingClaimsFilteredClient', this.tempClient);
                break;
            case 'purchaserCode':
                this.tempPurchaser = [];
                this.tempPurchaser.push({
                    label: event.text && event.text.purchaserCode ? event.text.purchaserName : '',
                    value: event.text && event.text.purchaserCode ? event.text.purchaserCode : null
                });
                this._storage.set('session', 'outgoingClaimsFilteredPurchaser', this.tempPurchaser);
                break;
        }
    }

    filterClaims(page?) {
        this.filterList.length = 0;
        let filter_data = this.outgoingClaimsfilterForm.value;
        filter_data.processingStatus = this.outgoingClaimsfilterForm.value.processingStatus && this.outgoingClaimsfilterForm.value.processingStatus.length == 0 ? null : this.outgoingClaimsfilterForm.value.processingStatus;
        this.page = page ? 1 : this.outgoingClaimsfilterForm.value.page;
        filter_data.page = this.page;
        this.outgoingClaimsfilterForm.controls.page.setValue(this.page);
        this._storage.set('session', 'outgoingClaimsFilterData', filter_data);

        //show applied tags for episode filter
        Object.keys(filter_data).forEach(key => {
            let value = filter_data[key];
            if (value && key == 'patientId') this.updateFilterApplied('Patient', key, value, this.tempPatient);
            if (!value && key == 'patientId') this.patientSearchText.next('');
            if (value && key == 'submitterId') this.updateFilterApplied('Submitter', key, value, this.tempSubmiter);
            if (!value && key == 'submitterId') this.submitterSearchText.next('');
            if (value && key == 'billingProviderId') this.updateFilterApplied('Billing Provider', key, value, this.tempBillingProvider);
            if (!value && key == 'billingProviderId') this.billingProviderSearchText.next('');
            if (value && key == 'episodeName') this.updateFilterApplied('Episode', key, value, this.tempEpisode);
            if (!value && key == 'episodeName') this.episodeSearchText.next('');
            if (value && key == 'payerCode') this.updateFilterApplied('Payor', key, value, this.tempPayer);
            if (!value && key == 'payerCode') this.payerSearchText.next('');
            if (value && key == 'clientCode') this.updateFilterApplied('Client', key, value, this.tempClient);
            if (!value && key == 'clientCode') this.clientSearchText.next('');
            if (value && key == 'purchaserCode') this.updateFilterApplied('Purchaser', key, value, this.tempPurchaser);
            if (!value && key == 'purchaserCode') this.purchaserSearchText.next('');
            if (value && key == 'subscriberNumber') this.updateFilterApplied('Subscriber Number', key, value, null);
            if (value && key == 'processingStatus') this.updateFilterApplied('Processing Status', key, value, this.processingStatus);
        });
        this.searchClaims(filter_data);
        this.closeFilterModal(null);
    }

    updateFilterApplied(field, formField, value, list) {
        if (this.filterList.length > 0) {
            let index = this.filterList.findIndex(x => x.field == field);
            if (index > -1)
                this.filterList.splice(index, 1);
        }
        if (list) {
            if (formField == 'processingStatus') {
                Object.keys(value).forEach(y => {
                    let values = value[y];
                    let valueIndex = list.findIndex(x => x.value == values);
                    if (valueIndex > -1) {
                        let index = this.filterList.findIndex(x => x.field == field && x.value == values);
                        if (index == -1) {
                            let data = { field: field, formField: formField, value: values, label: list[valueIndex].label };
                            this.filterList.push(data);
                        }
                    }
                });
            }
            else {
                let valueIndex = list.findIndex(x => x.value == value);
                if (valueIndex > -1) {
                    let data = { field: field, formField: formField, value: value, label: list[valueIndex].label };
                    this.filterList.push(data);
                }
            }
        }
        else {
            let data = { field: field, formField: formField, value: value, label: value };
            this.filterList.push(data);
        }
    }

    closeFilterModal(action) {
        if (action == 'close') {
            this.patientSearchText.next('');
            this.submitterSearchText.next('');
            this.billingProviderSearchText.next('');
            this.episodeSearchText.next('');
            this.payerSearchText.next('');
            this.clientSearchText.next('');
            this.purchaserSearchText.next('');
            this.outgoingClaimsfilterForm.reset();
        }
        this.outgoingClaimsFilterModal.hide();
    }

    searchSubmitter(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchSubmitter(searchText)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displaySubmitter = (submitter?): string | undefined => {
        return submitter ? this.toTitleCase(submitter.displayName) : undefined;
    };

    searchPatient(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchPatient(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayPatient = (patient?): string | undefined => {
        return patient ? this.toTitleCase(patient.displayName) : undefined;
    };

    searchBillingProvider(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchBillingProvider(searchText)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayBillingInfo = (billing?): string | undefined => {
        return billing ? this.toTitleCase(billing.displayName) : undefined;
    };

    searchEpisode(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchEpisode(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayEpisode = (episode?): string | undefined => {
        return episode ? this.toTitleCase(episode.episodeName) : undefined;
    };

    searchPayer(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchPayer(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayPayer = (payer?): string | undefined => {
        return payer ? this.toTitleCase(payer.payerName) : undefined;
    };

    searchClient(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchClient(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayClient = (client?): string | undefined => {
        return client ? this.toTitleCase(client.clientName) : undefined;
    };

    searchPurchaser(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchPurchaser(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayPurchaser = (purchaser?): string | undefined => {
        return purchaser ? this.toTitleCase(purchaser.purchaserName) : undefined;
    };

    toTitleCase(name) {
        return name.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    removeFilter(filter) {
        const filteredData = this._storage.get('session', 'outgoingClaimsFilterData');
        this.outgoingClaimsfilterForm.reset();
        this.outgoingClaimsfilterForm.patchValue(filteredData);
        switch (filter.formField) {
            case 'patientId':
                this.patientSearchText.next('');
                this.outgoingClaimsfilterForm.controls.patientId.setValue('');
                break;
            case 'submitterId':
                this.submitterSearchText.next('');
                this.outgoingClaimsfilterForm.controls.submitterId.setValue('');
                break;
            case 'billingProviderId':
                this.billingProviderSearchText.next('');
                this.outgoingClaimsfilterForm.controls.billingProviderId.setValue('');
                break;
            case 'subscriberNumber':
                this.outgoingClaimsfilterForm.controls.subscriberNumber.setValue('');
                break;
            case 'processingStatus':
                let filteredProcessingStatus = this.outgoingClaimsfilterForm.get(filter.formField).value;
                let removeIndex = filteredProcessingStatus.findIndex(x => x == filter.value);

                removeIndex > -1 && filteredProcessingStatus.splice(removeIndex, 1);
                this.outgoingClaimsfilterForm.get('processingStatus').reset();
                this.outgoingClaimsfilterForm.controls.processingStatus.setValue(filteredProcessingStatus.length ? filteredProcessingStatus : null);
                break;
            case 'episodeName':
                this.episodeSearchText.next('');
                this.outgoingClaimsfilterForm.controls.episodeName.setValue('');
                break;
            case 'payerCode':
                this.payerSearchText.next('');
                this.outgoingClaimsfilterForm.controls.payerCode.setValue('');
                break;
            case 'clientCode':
                this.clientSearchText.next('');
                this.outgoingClaimsfilterForm.controls.clientCode.setValue('');
                break;
            case 'purchaserCode':
                this.purchaserSearchText.next('');
                this.outgoingClaimsfilterForm.controls.purchaserCode.setValue('');
                break;
        }
        let index = this.filterList.findIndex(x => x.value == filter.value)
        if (index > -1)
            this.filterList.splice(index, 1);
        this.page = 1;
        this.outgoingClaimsfilterForm.controls.page.setValue(1);
        this.searchClaims(this.outgoingClaimsfilterForm.value);
        this._storage.set('session', 'outgoingClaimsFilterData', this.outgoingClaimsfilterForm.value);
    }

    ResetFilter() {
        this.patientSearchText.next('');
        this.submitterSearchText.next('');
        this.billingProviderSearchText.next('');
        this.billingProviderSearchText.next('');
        this.episodeSearchText.next('');
        this.payerSearchText.next('');
        this.clientSearchText.next('');
        this.purchaserSearchText.next('');
        this.filterList = [];
        this.outgoingClaimsfilterForm.reset();
        this.page = 1;
        this.outgoingClaimsfilterForm.controls.keyword.setValue(this.searchKeyword);
        this.outgoingClaimsfilterForm.controls.sortOrder.setValue(this.sortOrder);
        this.outgoingClaimsfilterForm.controls.sortBy.setValue(this.sortBy);
        this.outgoingClaimsfilterForm.controls.page.setValue(1);
        this.outgoingClaimsfilterForm.controls.processingStatus.setValue(null);
        this.searchClaims(this.outgoingClaimsfilterForm.value);
        this._storage.remove('session', 'outgoingClaimsFilterData')
    }

    getOutgoingClaimsDetail(id) {
        this.router.navigate(['outgoing-claims', id]);
    }

    getExceptionCodes() {
        this.claimsService.fetchExceptionCodes()
            .subscribe((res: any) => {
                if (res) {
                    this.exceptionCodes = res.map(x => {
                        let data = { ...x, label: x.description, value: x.id }
                        return data
                    })
                }
            }, (error) => {
                console.log("Error getting exception codes", error);
            })
    }

    reasonSelected(event) {
        this.statusModel.reason = event.description;
        this.statusModel.statusCode = event.code;
    }

    removeSearchKeyword() {
        this.searchKeyword = ''
        this.searchByKeyword('')
    }

    onSendEmail(metaVariablesDetail) {
        this.metaVariablesDetail = { detail: metaVariablesDetail, module: 'claim' };
        this.moduleOptions = {
            module: "outgoingClaims",
            moduleId: metaVariablesDetail.id.toString(),
            moduleIdentifier: metaVariablesDetail.patientAccountNumber,
            type: 'communications'
        }
        this.showMessageModal = true;
    }

    closeMessageModal() {
        this.showMessageModal = false;
    }
}