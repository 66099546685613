import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { CHART_CUSTOM_COLORS, EPISODE_CARE_PATHWAY_DATA, HORIZONTAL_BAR_OPTION_WITH_PERCENTAGE_OPTIONS, LINE_CHART_DATA_CONFIG, LINE_CHART_DEFAULT_COLOR_CONFIG, LINE_CHART_OPTIONS, MONTHS_NAME_FULL, MessageConstants, NO_DATA_IN_REPORT, REPORT_FILTERING_TYPES, REPORT_NAME_CODES, REPORT_NAME_TYPES } from '../../../../constants';
import { ReportService } from '../../../../services';
import { ChartUtils, DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility, ReportUtility } from '../../../../utils';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  overviewFilterForm: FormGroup;
  newEpisodeByMonthForm: FormGroup;
  patientComplianceRequiredTaskYtdForm: FormGroup;
  patientAgreementSignedIn3DaysForm: FormGroup;
  MONTHS_NAME_FULL = ['Year', ...MONTHS_NAME_FULL];
  REPORT_NAME_TYPES = REPORT_NAME_TYPES;
  REPORT_NAME_CODES = REPORT_NAME_CODES;
  baseConfigs = {
    options: {},
    plugins: [],
    labels: [],
    dataset: [],
    chartType: '',
    actualData: null,
  };
  episodeCarePathwayChart = this._holistaUtils.deepClone(this.baseConfigs);
  newEpisodeByMonthChart = this._holistaUtils.deepClone(this.baseConfigs);
  portalEngagementChart = this._holistaUtils.deepClone(this.baseConfigs);
  portalEngagementIndividualChart = this._holistaUtils.deepClone(this.baseConfigs);
  patientComplianceChart = this._holistaUtils.deepClone(this.baseConfigs);
  patientComplianceRequiredTaskChart = this._holistaUtils.deepClone(this.baseConfigs);
  portalEngagementMilestoneDetailChart = this._holistaUtils.deepClone(this.baseConfigs);
  patientAgreementSignedIn3DaysChart = this._holistaUtils.deepClone(this.baseConfigs);
  reasonForCancellationAccumulativeChart = this._holistaUtils.deepClone(this.baseConfigs);
  categoryList: any[] = [];
  patientComplianceCategoryList: any[] = [];
  patientComplianceYtdYearOptions: any[] = [];
  yearOptionReports = {
    patientAgreementSignedIn3Days: [],
  };
  loading = {
    activeEpisodeByMonthReport: false,
    episodeByCarePathwayReport: false,
    newEpisodesByMonthReport: false,
    categoryList: false,
    portalEngagementReport: false,
    portalEngagementIndividual: false,
    portalEngagementMilestoneDetailReport: false,
    patientComplianceRequiredTaskYtd: false,
    patientAgreementSignedIn3Days: false,
    reasonForCancellationAccumulative: false,
  }
  expand = {
    activeEpisodeByMonth: false,
    newEpisodeByMonth: false,
    episodeByCarePathway: false,
    portalEngagementIndividual: false,
    portalEngagement: false,
    patientComplianceRequiredTaskYtd: false,
    portalEngagementMilestoneDetail: false,
    patientAgreementSignedIn3Days: false,
    reasonForCancellationAccumulative: false,
  }
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT)
  activeEpisodeByMonthList: any[] = [];
  toShowReport: any[] = [];
  reportNameAndCodeList: any[] = [];
  parsedValueManagementFilterData: any;
  yearOptions: any[] = [];
  isIndividual: boolean = false;
  patientComplianceData = {
    list: [],
    incrementCount: 1,
  };
  isNovantAndMaternity: boolean = false;
  totalEpisodeByCareTableData = {
    data: [],
    excelName: '',
    tableTitle: '',
    tableHeaders: [],
    columnsWidth: [],
    dateType: '',
    date: {},
  };
  totalEpisodeCount: any;

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();
  @Input() set valueManagementFilterData(data) {
    if (data && data.activeTabIndex === 0) {
      this.expand = {
        activeEpisodeByMonth: false,
        newEpisodeByMonth: false,
        episodeByCarePathway: false,
        portalEngagement: false,
        portalEngagementIndividual: false,
        patientComplianceRequiredTaskYtd: false,
        portalEngagementMilestoneDetail: false,
        patientAgreementSignedIn3Days: false,
        reasonForCancellationAccumulative: false,
      }
      this.parsedValueManagementFilterData = this._holistaUtils.deepClone(data);
      const { networkCode, clientCode, startDate, endDate, toShowReport } = this.parsedValueManagementFilterData;
      if (networkCode && !this.categoryList.length) {
        this.getCategoryList({ networkCode });
      }
      let isIndividual = false;
      let categoryName = '';
      let categoryId = '';
      if (this.overviewFilterForm) {
        categoryName = this.overviewFilterForm.get('categoryName').value;
        categoryId = this.overviewFilterForm.get('categoryId').value;
        isIndividual = categoryName !== 'All';
        this.isIndividual = isIndividual;
      } else {
        this.setOverviewFilterForm();
      }
      if (!this.patientComplianceRequiredTaskYtdForm) {
        this.setPatientComplianceRequiredTaskYtdForm();
      }
      if (categoryName.toLowerCase() === 'maternity') {
        this.getReportCodes(REPORT_FILTERING_TYPES.INDIVIDUAL_MATERNITY_REPORT);
        this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.INDIVIDUAL_MATERNITY_REPORT);
      } else if (categoryName.toLowerCase() === 'all') {
        this.getReportCodes(REPORT_FILTERING_TYPES.OVERALL_REPORT);
        this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.OVERALL_REPORT);
      } else {
        this.getReportCodes(REPORT_FILTERING_TYPES.INDIVIDUAL_REPORT);
        this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.INDIVIDUAL_REPORT);
      }
      const params = {
        clientCode,
        startDate,
        endDate,
        ...(isIndividual && { categoryId: this.overviewFilterForm.get('categoryId').value })
      };
      const reportYear = +this._dateUtility.getYearFromDate(endDate);
      const additionalConditons = toShowReport && toShowReport.length && startDate && endDate;
      if (this.toShowReport && this.toShowReport.includes(REPORT_NAME_CODES.ACTIVE_EPISODE_BY_MONTH) && additionalConditons) {
        this.getActiveEpisodeByMonthReport(params, isIndividual);
      }
      if (this.toShowReport && this.toShowReport.includes(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY) && additionalConditons) {
        const newParams = { ...params, };
        delete newParams['startDate'];
        delete newParams['endDate'];
        this.getEpisodeByCarePathway(newParams, isIndividual);
      }
      if (this.toShowReport && this.toShowReport.includes(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH) && toShowReport.length && endDate) {
        this.getNewEpisodesByMonth(
          {
            clientCode,
            year: reportYear,
            ...(isIndividual && { categoryId: this.overviewFilterForm.get('categoryId').value })
          }, isIndividual);
      }
      if (this.toShowReport && this.toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED) && additionalConditons) {
        this.getPortalEngagementReport(params, isIndividual);
      }
      if (this.toShowReport && this.toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL) && additionalConditons) {
        this.getPortalEngagementIndividual(params);
      }
      if (this.toShowReport && this.toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL) && additionalConditons) {
        this.getPortalEngagementMilestoneDetail(params);
      }
      if (this.toShowReport && this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD) && toShowReport.length && reportYear) {
        this.getPatientCompilance(
          {
            clientCode,
            year: reportYear,
            ...(isIndividual && { categoryId: this.overviewFilterForm.get('categoryId').value })
          }, isIndividual);
      }
      if (this.toShowReport && this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS) && toShowReport.length && reportYear) {
        this.getPatientAgreementSigned(
          {
            clientCode,
            year: reportYear,
            categoryId: this.overviewFilterForm.get('categoryId').value
          }, isIndividual);
      }
    }
  };
  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === 0) {
      var elementToHide = document.getElementById('hide-while-downloading');
      const { clientName, startDate, endDate } = this.parsedValueManagementFilterData;
      const reportDate = `${startDate} ${endDate ? `- ${endDate}` : ''}`;
      const tabsData = [];
      if (this.toShowReport.includes(REPORT_NAME_CODES.ACTIVE_EPISODE_BY_MONTH) && this.activeEpisodeByMonthList.length) {
        const activeEpisodeByMonthSheet = this._reportMappingUtils.activeEpisodeByMonthDataMapper(this.activeEpisodeByMonthList);
        tabsData.push({
          clientName,
          reportName: 'Active Episode By Month',
          tabName: 'Active Episode By Month',
          headers: activeEpisodeByMonthSheet.header,
          data: activeEpisodeByMonthSheet.body,
          reportDate,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH) && this.newEpisodeByMonthChart.labels.length) {
        const body = this._reportMappingUtils.newEpisodeByMonthMapper(this.newEpisodeByMonthChart.actualData);
        tabsData.push({
          clientName,
          reportName: 'New Episodes By Month',
          tabName: 'New Episodes By Month',
          headers: ['Category', ...this.newEpisodeByMonthChart.actualData.labels],
          data: body,
          reportDate: `Period: ${this.newEpisodeByMonthForm.value.year}`,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY) && this.episodeCarePathwayChart.labels.length) {
        const body = this._reportMappingUtils.episodeCarePathwayMapper(this.totalEpisodeByCareTableData);
        tabsData.push({
          clientName,
          reportName: 'Total Episodes By Care Pathway',
          tabName: 'Total Episodes By Care Pathway',
          headers: body.header,
          data: body.reportData,
          reportDate,
          totalRow: {
            values: ['Total', '', '', this.totalEpisodeByCareTableData.data[0].totalCount, ''],
          },
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED) && this.portalEngagementChart.labels.length) {
        const body = this._reportMappingUtils.portalEngagementYtdOfContentAccessedMapper(this.portalEngagementChart.actualData);
        tabsData.push({
          clientName,
          reportName: 'Portal Engagement % Of Content Accessed Detail',
          tabName: 'Portal Engagement % Of Content Accessed Detail',
          headers: ['Category', 'Completed', 'Not Completed', 'Percentage'],
          data: body,
          reportDate,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL) && this.portalEngagementMilestoneDetailChart.labels.length) {
        const body = this._reportMappingUtils.portalEngagementMilestonesDetailMapper(this.portalEngagementMilestoneDetailChart.actualData);
        tabsData.push({
          clientName,
          reportName: 'Portal Engagement - Milestones Detail',
          tabName: 'Portal Engagement - Milestones Detail',
          headers: ['Category', 'Completed', 'Engaged Percentage', 'Not Completed', 'Total Count'],
          data: body,
          reportDate,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD) && this.patientComplianceRequiredTaskChart.labels.length) {
        const body = this._reportMappingUtils.patientComplianceWithRequiredTaskMapper(this.patientComplianceRequiredTaskChart.actualData, this.isIndividual, this.overviewFilterForm.value.categoryName);
        tabsData.push({
          clientName,
          reportName: 'Patient Compliance',
          tabName: 'Patient Compliance',
          headers: ['Category', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.newEpisodeByMonthForm.value.year}`,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS) && this.patientAgreementSignedIn3DaysChart.labels.length) {
        const body = this._reportMappingUtils.patientAgreementSignedMapper(this.patientAgreementSignedIn3DaysChart.actualData, this.overviewFilterForm.value.categoryName);
        tabsData.push({
          clientName,
          reportName: `Patient Agreement Signed ${this.isNovantAndMaternity ? '(Signed in < 3 days)' : ''}`,
          tabName: 'Patient Agreement Signed',
          headers: ['Category', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.patientAgreementSignedIn3DaysForm.value.selectedYear}`,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL) && this.portalEngagementIndividualChart.labels.length) {
        const body = this._reportMappingUtils.portalEngagementDetailMapper(this.portalEngagementIndividualChart.actualData);
        tabsData.push({
          clientName,
          reportName: 'Portal Engagement Detail',
          tabName: 'Portal Engagement Detail',
          headers: ['Name', 'Engaged Percentage',],
          data: body,
          reportDate,
        });
      }
      const resetDownload = () => {
        this.downloadedPDF.emit(true);
        elementToHide.style.display = 'block';
      }
      if (tabsData.length && isDownloadPDF.type === 'excel') {
        this._excelMultiTabUtility.exportToExcelMultiTab('Value Management Dashboard - Overview', tabsData).finally(() => {
          resetDownload();
        })
      }
      if (isDownloadPDF.type === 'pdf') {
        elementToHide.style.display = 'none';
        const downloadPDF = this._pdfUtility.convertToPDF(['vmd-overview'], 'Value-Management-Dashboard-Overview', true, true);
        downloadPDF.finally(() => {
          resetDownload();
        });
      }
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private _messageConstants: MessageConstants,
    private reportService: ReportService,
    private _dateUtility: DateUtility,
    private _pdfUtility: PDFUtility,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
    private _holistaUtils: HolistaUtils,
    private _chartUtils: ChartUtils,
    private _reportUtils: ReportUtility,
    private _reportMappingUtils: ReportMappingUtility,
  ) {
  }

  ngOnInit(): void {
    this.setOverviewFilterForm();
    this.setNewEpisodeByMonthForm();
    this.setPatientComplianceRequiredTaskYtdForm();
    this.setPatientAgreementSignedIn3DaysForm();
  }

  resetAllReports() {
    this.activeEpisodeByMonthList = [];
    this.episodeCarePathwayChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.newEpisodeByMonthChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.portalEngagementChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.patientComplianceChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.patientComplianceRequiredTaskChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.portalEngagementMilestoneDetailChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.patientAgreementSignedIn3DaysChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.reasonForCancellationAccumulativeChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  resetFlags() {
    this.loading = {
      activeEpisodeByMonthReport: false,
      episodeByCarePathwayReport: false,
      newEpisodesByMonthReport: false,
      categoryList: false,
      portalEngagementReport: false,
      portalEngagementIndividual: false,
      portalEngagementMilestoneDetailReport: false,
      patientComplianceRequiredTaskYtd: false,
      patientAgreementSignedIn3Days: false,
      reasonForCancellationAccumulative: false,
    }
  }

  ngOnDestroy() {
    this.resetFlags();
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
    this.totalEpisodeByCareTableData.data = [];
  }

  setOverviewFilterForm() {
    this.overviewFilterForm = this.formBuilder.group({
      categoryId: ['all'],
      categoryName: ['All'],
    });
  }

  getReportCodes(filterKey) {
    const { toShowReport } = this.parsedValueManagementFilterData;
    this.toShowReport = toShowReport.filter((x) => x.report[filterKey]).map((x) => x.reportCode);
  }

  getReportNameAndCodeList(filterKey) {
    const { toShowReport } = this.parsedValueManagementFilterData;
    this.reportNameAndCodeList = toShowReport.filter((x) => x.report[filterKey]).map((x) => ({
      reportName: x.customReportName,
      reportCode: x.reportCode
    }));
  }

  setPatientAgreementSignedIn3DaysForm() {
    this.patientAgreementSignedIn3DaysForm = this.formBuilder.group({
      selectedYear: [null],
    });
  }

  setPatientComplianceRequiredTaskYtdForm() {
    this.patientComplianceRequiredTaskYtdForm = this.formBuilder.group({
      categoryId: [null],
      selectedYear: [null],
    });
  }

  setNewEpisodeByMonthForm() {
    this.newEpisodeByMonthForm = this.formBuilder.group({
      year: [null],
    });
  }

  getActiveEpisodeByMonthReport(params?, isIndividual = false) {
    this.activeEpisodeByMonthList = [];
    this.loading.activeEpisodeByMonthReport = true;
    const request = isIndividual
      ? this.reportService.getActiveEpisodeByMonthIndividualReport(params)
      : this.reportService.getActiveEpisodeByMonthReport(params);
    request.pipe(finalize(() => { this.loading.activeEpisodeByMonthReport = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.length) {
          this.activeEpisodeByMonthList = res;
        } else {
          this.activeEpisodeByMonthList = [];
        }
      }, error => {
        console.log("Error getting Active Episode By Month Report", error);
      })
  }

  getCategoryList(params?) {
    this.loading.categoryList = true;
    this.reportService.getCategoryList(params)
      .pipe(finalize(() => {
        this.loading.categoryList = false;
        this.categoryList.push({ id: 'all', label: "All", value: "all" });
      }))
      .subscribe((res) => {
        const categoryList = res.map((x) => {
          return {
            ...x,
            label: x.name,
            value: x.name,
          }
        });
        this.categoryList = categoryList;
        this.patientComplianceCategoryList = this._holistaUtils.deepClone(categoryList);
      }, error => {
        console.log("Error getting Category List", error);
      })
  }

  getEpisodeByCarePathway(params?, isIndividual = false) {
    this.loading.episodeByCarePathwayReport = true;
    this.reportService.getEpisodeByCarePathway(params)
      .pipe(finalize(() => { this.loading.episodeByCarePathwayReport = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.details && res.details.length) {
          this.totalEpisodeByCareTableData.data = [];
          this.totalEpisodeCount = res.totalEpisodeCount;
          const { TABLE_HEADERS } = EPISODE_CARE_PATHWAY_DATA;
          this.totalEpisodeByCareTableData.tableHeaders = TABLE_HEADERS;
          res.details.forEach(categoryEntry => {
            const categoryKey = Object.keys(categoryEntry)[0];
            const { details: subCategories, episodeCount: categoryCount, percent: categoryPercent } = categoryEntry[categoryKey];
            subCategories.forEach(subCategoryEntry => {
              const subCategoryKey = Object.keys(subCategoryEntry)[0];
              const episodeCount = subCategoryEntry[subCategoryKey];
              this.totalEpisodeByCareTableData.data.push({
                category: categoryKey,
                subCategory: subCategoryKey,
                episodeCount,
                categoryCount,
                categoryPercent: categoryPercent ? `${categoryPercent}%` : 0,
                totalCount: res.totalEpisodeCount,
              });
            });
          });
          const dataInPercent = res.details.map(obj => {
            const key = Object.keys(obj)[0];
            return obj[key].percent;
          });
          this.episodeCarePathwayChart.actualData = res;
          this.episodeCarePathwayChart.chartType = 'doughnut';
          this.episodeCarePathwayChart.dataset = [{
            data: res.details.map(obj => {
              const key = Object.keys(obj)[0];
              return obj[key].episodeCount;
            }),
            backgroundColor: CHART_CUSTOM_COLORS,
            borderColor: CHART_CUSTOM_COLORS,
            hoverBackgroundColor: CHART_CUSTOM_COLORS,
            details: res.details.map(obj => {
              const key = Object.keys(obj)[0];
              const details = obj[key].details.map(detail => {
                const detailKey = Object.keys(detail)[0];
                const detailValue = detail[detailKey];
                return `${detailKey}: ${detailValue}`;
              });
              return details;
            })
          }];
          this.episodeCarePathwayChart.labels = res.details.map((obj) => Object.keys(obj)[0]);
          this.episodeCarePathwayChart.options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: { position: 'right' },
            cutoutPercentage: 65,
            plugins: {
              datalabels: {
                display: false,
                font: {
                  size: 20,
                }
              }
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const label = data.labels[tooltipItem.index];
                  const value =
                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  return `${label}: ${value} (${dataInPercent[tooltipItem.index]}%)`;
                },
                footer: function (tooltipItem, data) {
                  return data.datasets[0].details[tooltipItem[0].index]
                },
              },
              footerFontStyle: "normal",
            },
          };
          this.episodeCarePathwayChart.plugins = [{
            afterDraw(chart) {
              const ctx = chart.ctx;
              var txt1 = res.totalEpisodeCount;
              var txt2 = 'Total Episodes';
              //Get options from the center object in options
              const sidePadding = 60;
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
              const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
              //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
              const stringWidth = ctx.measureText(txt1).width;
              // Pick a new font size so it will not be larger than the height of label.
              const fontSizeToUse = 24;
              ctx.font = fontSizeToUse + 'px Arial';
              ctx.fillStyle = 'black';
              // Draw text in center
              ctx.fillText(txt1, centerX, centerY - 10);
              var fontSizeToUse1 = 11;
              ctx.font = fontSizeToUse1 + 'px Arial';
              ctx.fillText(txt2, centerX, centerY + 10);
            }
          }];
        } else {
          this.episodeCarePathwayChart = this._holistaUtils.deepClone(this.baseConfigs);
          this.totalEpisodeByCareTableData.data = [];
        }
      }, error => {
        console.log("Error getting Episode By Care Pathway", error);
      })
  }

  getNewEpisodesByMonth(params?, isIndividual = false, isYearSelected = false) {
    this.loading.newEpisodesByMonthReport = true;
    const request = isIndividual
      ? this.reportService.getNewEpisodesByMonthIndividual(params)
      : this.reportService.getNewEpisodesByMonth(params);
    request
      .pipe(finalize(() => {
        this.loading.newEpisodesByMonthReport = false;
        const { startDate, endDate } = this.parsedValueManagementFilterData;
        const reportYear = +this._dateUtility.getYearFromDate(endDate);
        const reportStartYear = +this._dateUtility.getYearFromDate(startDate);
        if (reportYear && endDate) {
          this.yearOptions = this._holistaUtils.getYearOptionList(reportStartYear, reportYear);
          this.newEpisodeByMonthForm.controls['year'].setValue(isYearSelected ? params.year : reportYear);
        }
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.yearOptions = [];
          this.newEpisodeByMonthChart.actualData = res;
          res = {
            ...res,
            datasets: res.datasets.map((x, index) => {
              return {
                ...x,
                maxBarThickness: 50,
                categoryPercentage: 1,
                backgroundColor: CHART_CUSTOM_COLORS[index],
                hoverBackgroundColor: CHART_CUSTOM_COLORS[index],
              }
            })
          }
          this.newEpisodeByMonthChart.chartType = 'bar';
          this.newEpisodeByMonthChart.dataset = res.datasets;
          this.newEpisodeByMonthChart.labels = res.labels;
          this.newEpisodeByMonthChart.options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  stacked: true,
                  gridLines: {
                    display: false,
                  },
                }
              ],
              yAxes: [
                {
                  stacked: true,
                  gridLines: {
                    display: true
                  },
                  ticks: {
                    display: false,
                  },
                }
              ]
            },
            plugins: {
              datalabels: {
                anchor: "end",
                align: "top",
                formatter: function (value, context) {
                  if (context.datasetIndex === context.chart.data.datasets.length - 1) {
                    var total = context.chart.data.datasets.reduce(function (
                      acc,
                      dataset
                    ) {
                      return acc + dataset.data[context.dataIndex];
                    },
                      0);
                    return total === 0 ? '' : total;
                  } else {
                    return "";
                  }
                },
                color: "#000000",
                font: {
                  weight: "bold"
                }
              }
            }
          };
          this.newEpisodeByMonthChart.plugins = [{
            beforeInit(chart) {
              const originalFit = chart.legend.fit;
              chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
                this.height += 20;
              }
            }
          }]
        } else {
          this.newEpisodeByMonthChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, error => {
        console.log("Error getting New Episode By Month", error);
      })
  }

  getPortalEngagementReport(params?, isIndividual = false) {
    this.loading.portalEngagementReport = true;
    this.reportService.getPortalEngagementReport(params)
      .pipe(finalize(() => { this.loading.portalEngagementReport = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.portalEngagementChart.actualData = res;
          res = {
            ...res,
            datasets: [{
              data: res.percentage,
              maxBarThickness: 30,
              backgroundColor: CHART_CUSTOM_COLORS,
              borderColor: CHART_CUSTOM_COLORS,
              hoverBackgroundColor: CHART_CUSTOM_COLORS,
            }]
          };
          this.portalEngagementChart.chartType = 'horizontalBar';
          this.portalEngagementChart.dataset = res.datasets;
          this.portalEngagementChart.labels = res.labels;
          this.portalEngagementChart.options = HORIZONTAL_BAR_OPTION_WITH_PERCENTAGE_OPTIONS;
        } else {
          this.portalEngagementChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Portal Engagement Report", error);
      })
  }

  getPortalEngagementIndividual(params?) {
    this.loading.portalEngagementIndividual = true;
    this.reportService.getPortalEngagementIndividual(params)
      .pipe(finalize(() => { this.loading.portalEngagementIndividual = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.portalEngagementIndividualChart.actualData = res;
          res = {
            ...res,
            datasets: [{
              data: res.dataSets.find((data) => data.label === 'engaged_percent').datas,
              maxBarThickness: 30,
              backgroundColor: CHART_CUSTOM_COLORS,
              borderColor: CHART_CUSTOM_COLORS,
              hoverBackgroundColor: CHART_CUSTOM_COLORS,
            }]
          };
          this.portalEngagementIndividualChart.chartType = 'horizontalBar';
          this.portalEngagementIndividualChart.dataset = res.datasets;
          this.portalEngagementIndividualChart.labels = res.labels;
          this.portalEngagementIndividualChart.options = HORIZONTAL_BAR_OPTION_WITH_PERCENTAGE_OPTIONS;
        } else {
          this.portalEngagementIndividualChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Portal Engagement Individual Report", error);
      })
  }

  getPortalEngagementMilestoneDetail(params?) {
    this.loading.portalEngagementMilestoneDetailReport = true;
    this.reportService.getPortalEngagementMilestoneDetail(params)
      .pipe(finalize(() => { this.loading.portalEngagementMilestoneDetailReport = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.portalEngagementMilestoneDetailChart.actualData = res;
          res = {
            ...res,
            datasets: res.engaged_percent.map((percentage) => {
              return {
                data: percentage,
                borderWidth: 2,
                fill: false,
                lineTension: 0,
                ...LINE_CHART_DEFAULT_COLOR_CONFIG,
              }
            })
          };
          this.portalEngagementMilestoneDetailChart.chartType = 'line';
          this.portalEngagementMilestoneDetailChart.dataset = res.datasets;
          this.portalEngagementMilestoneDetailChart.labels = res.labels;
          this.portalEngagementMilestoneDetailChart.options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: false,
              }
            },
            legend: {
              display: false,
              labels: {
                fontColor: "black"
              },
              position: "bottom"
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false
                  },
                  scaleLabel: {
                    display: false,
                    labelString: "% of Content Accessed (Accumulative)",
                  },
                  ticks: {
                    fontSize: 11,
                    callback: this._chartUtils.lineBreakForChartTicks(10, '-'),
                  }
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    suggestedMin: 100,
                    callback: function (value) {
                      if (Number.isInteger(value)) {
                        if (value === 0) return value;
                        return value + "%";
                      }
                      return "";
                    }
                  },
                  gridLines: {
                    display: true
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "% of Milestone Accessed"
                  }
                }
              ]
            },
          };
        } else {
          this.portalEngagementMilestoneDetailChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Portal Engagement Milestone Detail", error);
      })
  }

  getPatientCompilance(params?, isIndividual = false, isYearSelected = false) {
    this.patientComplianceData = {
      list: [],
      incrementCount: 1,
    };
    this.loading.patientComplianceRequiredTaskYtd = true;
    this.reportService.getPatientCompilance(params)
      .pipe(finalize(() => {
        this.loading.patientComplianceRequiredTaskYtd = false;
        const { startDate, endDate } = this.parsedValueManagementFilterData;
        const reportYear = +this._dateUtility.getYearFromDate(endDate);
        const reportStartYear = +this._dateUtility.getYearFromDate(startDate);
        if (reportYear && endDate) {
          this.patientComplianceYtdYearOptions = this._holistaUtils.getYearOptionList(reportStartYear, reportYear);
          this.patientComplianceRequiredTaskYtdForm.controls['selectedYear'].setValue(isYearSelected ? params.year : reportYear);
        }
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res && res.length) {
          this.patientComplianceData.list = res;
          this.patientComplianceRequiredTaskChart.actualData = res;
          res = {
            ...res,
            ...(!isIndividual && {
              datasets: [{
                ...LINE_CHART_DATA_CONFIG,
                label: res[0].label,
                data: res[0].dataSets[3].datas,
                backgroundColor: CHART_CUSTOM_COLORS[0],
                borderColor: CHART_CUSTOM_COLORS[0],
                pointBackgroundColor: CHART_CUSTOM_COLORS[0],
                pointHoverBackgroundColor: CHART_CUSTOM_COLORS[0],
                compliantCount: res[0].dataSets.find((x) => x.label === "compliant_count")?.datas || null,
              }]
            }),
            ...(isIndividual && {
              datasets: [
                {
                  ...LINE_CHART_DATA_CONFIG,
                  data: res[0].dataSets[3].datas,
                  compliantCount: res[0].dataSets.find((x) => x.label === "compliant_count")?.datas || null,
                }
              ]
            })
          };
          if (!isIndividual) {
            this.patientComplianceRequiredTaskYtdForm.controls['categoryId'].setValue(res[0].label)
            this.patientComplianceData = {
              ...this.patientComplianceData,
              incrementCount: this.patientComplianceData.incrementCount + 1,
            }
          };
          this.patientComplianceRequiredTaskChart.chartType = 'line';
          this.patientComplianceRequiredTaskChart.dataset = res.datasets;
          this.patientComplianceRequiredTaskChart.labels = res[0].labels;
          this.patientComplianceRequiredTaskChart.options = {
            ...LINE_CHART_OPTIONS,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const label = data.datasets[tooltipItem.datasetIndex].label;
                  const compliantCount = data.datasets[tooltipItem.datasetIndex].compliantCount[tooltipItem.index];
                  const value =
                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  return `${label ? `${label}:` : ''} ${+value > 0 ? `${value}%` : 0} ${+compliantCount > 0 ? `(${compliantCount})` : ''}`;
                }
              }
            },
            ...(isIndividual && {
              legend: {
                display: false,
              }
            }),
            scales: {
              ...LINE_CHART_OPTIONS.scales,
              yAxes: [
                {
                  ...LINE_CHART_OPTIONS.scales.yAxes[0],
                  scaleLabel: {
                    display: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    suggestedMin: 100,
                    callback: function (value) {
                      if (Number.isInteger(value)) {
                        if (value === 0) return value;
                        return value + "%";
                      }
                      return "";
                    }
                  }
                }
              ]
            }
          }
        } else {
          this.patientComplianceRequiredTaskChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Patient Compliance With Required Task", error);
      })
  }

  getPatientAgreementSigned(params?, isIndividual = false, isYearSelected = false) {
    this.loading.patientAgreementSignedIn3Days = true;
    this.reportService.getPatientAgreementSigned(params)
      .pipe(finalize(() => {
        this.loading.patientAgreementSignedIn3Days = false;
        const { startDate, endDate } = this.parsedValueManagementFilterData;
        const reportYear = +this._dateUtility.getYearFromDate(endDate);
        const reportStartYear = +this._dateUtility.getYearFromDate(startDate);
        if (reportYear && endDate) {
          this.yearOptionReports.patientAgreementSignedIn3Days = this._holistaUtils.getYearOptionList(reportStartYear, reportYear);
          this.patientAgreementSignedIn3DaysForm.controls['selectedYear'].setValue(isYearSelected ? params.year : reportYear);
        }
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res) {
          this.patientAgreementSignedIn3DaysChart.actualData = res;
          res = {
            ...res,
            datasets: [
              {
                ...LINE_CHART_DATA_CONFIG,
                data: res.dataSets.find((x) => x.label === "signed_on_time_percent")?.datas || null,
              }
            ]
          };
          this.patientAgreementSignedIn3DaysChart.chartType = 'line';
          this.patientAgreementSignedIn3DaysChart.dataset = res.datasets;
          this.patientAgreementSignedIn3DaysChart.labels = res.labels;
          this.patientAgreementSignedIn3DaysChart.options = {
            ...LINE_CHART_OPTIONS,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const label = data.datasets[tooltipItem.datasetIndex].label;
                  const value =
                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  return `${label ? `${label}:` : ''} ${+value > 0 ? `${value}%` : 0}`;
                }
              }
            },
            legend: {
              display: false,
            },
            scales: {
              ...LINE_CHART_OPTIONS.scales,
              yAxes: [
                {
                  ...LINE_CHART_OPTIONS.scales.yAxes[0],
                  scaleLabel: {
                    display: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    suggestedMin: 100,
                    callback: function (value) {
                      if (Number.isInteger(value)) {
                        if (value === 0) return value;
                        return value + "%";
                      }
                      return "";
                    }
                  }
                }
              ]
            }
          }
        } else {
          this.patientAgreementSignedIn3DaysChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Patient Agreement Signed", error);
      })
  }

  patientComplianceCategorySelected(event) {
    if (event.label) {
      let categoryData = null;
      const categoryExistInChart = this.patientComplianceRequiredTaskChart.dataset.find((data) => data.label === event.label);
      if (categoryExistInChart) return;
      if (this.patientComplianceData.list.length) {
        categoryData = this.patientComplianceData.list.find((x) => x.label === event.label);
      }
      const { incrementCount } = this.patientComplianceData;
      if (categoryData) {
        categoryData = {
          ...LINE_CHART_DATA_CONFIG,
          label: categoryData.label,
          data: categoryData.dataSets[3].datas,
          backgroundColor: CHART_CUSTOM_COLORS[incrementCount + 1],
          borderColor: CHART_CUSTOM_COLORS[incrementCount + 1],
          pointBackgroundColor: CHART_CUSTOM_COLORS[incrementCount + 1],
          pointHoverBackgroundColor: CHART_CUSTOM_COLORS[incrementCount + 1],
          compliantCount: categoryData.dataSets.find((x) => x.label === "compliant_count")?.datas || null,
        }
        this.patientComplianceData = {
          ...this.patientComplianceData,
          incrementCount: 2,
        }
        this.patientComplianceRequiredTaskChart.dataset.push(categoryData);
        this.patientComplianceRequiredTaskChart.dataset = this.patientComplianceRequiredTaskChart.dataset.filter((data) => data.label);
      }
    }
  }

  patientComplianceCategoryDeselected(event) {
    if (event.label) {
      const isCategoryPresent = this.patientComplianceRequiredTaskChart.dataset.find((category) => category.label === event.label);
      if (isCategoryPresent && isCategoryPresent.label) {
        this.patientComplianceData = {
          ...this.patientComplianceData,
          incrementCount: this.patientComplianceData.incrementCount - 1,
        }
        this.patientComplianceRequiredTaskChart.dataset = this.patientComplianceRequiredTaskChart.dataset.filter((data) => data.label !== event.label && data.label);
      }
    }
  }

  getReport(code: string) {
    return this.reportNameAndCodeList.find(report => report.reportCode === code) || null;
  }

  categorySelected(category) {
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
    this.totalEpisodeByCareTableData.data = [];
    this.isNovantAndMaternity = false;
    const isCategoryIndividual = category.id !== 'all';
    this.isIndividual = isCategoryIndividual;
    this.MONTHS_NAME_FULL.shift();
    isCategoryIndividual
      ? this.MONTHS_NAME_FULL.unshift(' ')
      : this.MONTHS_NAME_FULL.unshift('Year');
    this.overviewFilterForm.controls['categoryId'].setValue(category.id);
    this.overviewFilterForm.controls['categoryName'].setValue(category.label);
    const { clientCode, startDate, endDate, toShowReport } = this.parsedValueManagementFilterData;
    const reportYear = this._dateUtility.getYearFromDate(endDate);
    const categoryName = category.value.toLowerCase();
    if (categoryName === 'maternity') {
      this.getReportCodes(REPORT_FILTERING_TYPES.INDIVIDUAL_MATERNITY_REPORT);
      this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.INDIVIDUAL_MATERNITY_REPORT);
    } else if (categoryName === 'all') {
      this.getReportCodes(REPORT_FILTERING_TYPES.OVERALL_REPORT);
      this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.OVERALL_REPORT);
    } else {
      this.getReportCodes(REPORT_FILTERING_TYPES.INDIVIDUAL_REPORT);
      this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.INDIVIDUAL_REPORT);
    }
    if (reportYear && endDate) {
      this.yearOptions = [{ label: reportYear, value: reportYear }];
      this.newEpisodeByMonthForm.controls['year'].setValue(reportYear);
    }
    const params = { clientCode, startDate, endDate, categoryId: category.id };
    const additionalConditons = this.toShowReport.length && startDate && endDate;
    if (this.toShowReport.includes(REPORT_NAME_CODES.ACTIVE_EPISODE_BY_MONTH) && additionalConditons) {
      this.getActiveEpisodeByMonthReport(params, isCategoryIndividual);
    }
    if (this.toShowReport.includes(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY) && additionalConditons) {
      const newParams = { ...params, };
      delete newParams['startDate'];
      delete newParams['endDate'];
      this.getEpisodeByCarePathway(newParams, isCategoryIndividual);
    }
    if (this.toShowReport.includes(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH) && this.toShowReport.length && endDate) {
      this.getNewEpisodesByMonth({ clientCode, year: reportYear, categoryId: category.id }, isCategoryIndividual);
    }
    if (this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD) && this.toShowReport.length && reportYear) {
      const params = { clientCode, year: reportYear, categoryId: category.id };
      if (category.id === 'all') {
        delete params.categoryId;
      }
      this.getPatientCompilance(params, isCategoryIndividual);
    }
    if (this.toShowReport && this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS) && toShowReport.length && reportYear) {
      const params = { clientCode, year: reportYear, categoryId: category.id };
      this.getPatientAgreementSigned(params);
    }
    if (this.toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED) && this.toShowReport.length && additionalConditons) {
      this.getPortalEngagementReport(params);
    }
    if (this.toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL) && additionalConditons) {
      this.getPortalEngagementIndividual(params);
    }
    if (isCategoryIndividual && additionalConditons) {
      this.getPortalEngagementMilestoneDetail(params);
    }
  }

  onViewToggle(reportName, expand = false) {
    // this.reportExpanded.emit(expand); // Uncomment if download is not needed while expanding
    switch (reportName) {
      case REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH:
        this.expand.newEpisodeByMonth = expand;
        break;
      case REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY:
        this.expand.episodeByCarePathway = expand;
        break;
      case REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED:
        this.expand.portalEngagement = expand;
        break;
      case REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL:
        this.expand.portalEngagementIndividual = expand;
        break;
      case REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD:
        setTimeout(() => {
          if (!this.isIndividual) {
            this.patientComplianceRequiredTaskChart.dataset = this.patientComplianceRequiredTaskChart.dataset.filter((data) => data.label);
          }
        }, 50);
        this.expand.patientComplianceRequiredTaskYtd = expand;
        break;
      case REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL:
        this.expand.portalEngagementMilestoneDetail = expand;
        break;
      case REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS:
        this.expand.patientAgreementSignedIn3Days = expand;
        break;
      case REPORT_NAME_CODES.REASON_FOR_CANCELLATION_ACCUMULATIVE:
        this.expand.reasonForCancellationAccumulative = expand;
        break;
      default:
        break;
    }
  }

  yearSelected(event) {
    const isIndividual = this.overviewFilterForm.get('categoryName').value !== 'All';
    this.getNewEpisodesByMonth(
      {
        clientCode: this.parsedValueManagementFilterData.clientCode,
        year: event.value,
        ...(isIndividual && { categoryId: this.overviewFilterForm.get('categoryId').value })
      }, isIndividual, true);
  }

  reportYearSelected(event, reportName) {
    switch (reportName) {
      case REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD:
        const isIndividual = this.overviewFilterForm.get('categoryName').value !== 'All';
        this.getPatientCompilance(
          {
            clientCode: this.parsedValueManagementFilterData.clientCode,
            year: event.value,
            ...(isIndividual && { categoryId: this.overviewFilterForm.get('categoryId').value })
          }, isIndividual, true);
        break;
      case REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS:
        this.getPatientAgreementSigned({
          clientCode: this.parsedValueManagementFilterData.clientCode,
          year: event.value,
          categoryId: this.overviewFilterForm.get('categoryId').value,
        }, true, true);
        break;
      case REPORT_NAME_CODES.REASON_FOR_CANCELLATION_ACCUMULATIVE:
        break;
      default:
        break;
    }
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = (
      this.activeEpisodeByMonthList.length ||
      this.episodeCarePathwayChart.labels.length ||
      this.newEpisodeByMonthChart.labels.length ||
      this.portalEngagementChart.labels.length ||
      this.portalEngagementIndividualChart.labels.length ||
      this.patientComplianceChart.labels.length ||
      this.patientComplianceRequiredTaskChart.labels.length ||
      this.portalEngagementMilestoneDetailChart.labels.length ||
      this.patientAgreementSignedIn3DaysChart.labels.length ||
      this.reasonForCancellationAccumulativeChart.labels.length
    );
    if (this.parsedValueManagementFilterData.clientCode &&
      this.overviewFilterForm.value.categoryName === 'Maternity') {
      this.isNovantAndMaternity = true;
    } else {
      this.isNovantAndMaternity = false;
    }
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

}
