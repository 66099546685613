<h6 class="font-weight-500">Providers</h6>
<div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-2">
  <mdb-accordion class="accordion-block" [multiple]="false">
    <div class="text-center p-3 grey lighten-4" *ngIf="claims.providers.length == 0">
      <i class="icofont-hospital icofont-3x text-black-50"></i>
      <p>No Providers Available</p>
    </div>
    <mdb-accordion-item id="providers" *ngFor="let provider of claims.providers">
      <mdb-accordion-item-head>
        <span class="font-weight-bold">{{ provider.businessName | titlecase }}</span>
        <p class="text-muted mb-0 font-small">{{ provider.identificationCode }}</p>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
          <mdb-card *ngFor="let claims of provider.providerClaims" class="white tasks shadow-sm">
            <mdb-card-header (click)="redirectToClaim(claims)">
              Claim ID - {{ claims.patientAccountNumber }}
            </mdb-card-header>
            <mdb-card-body class="p-3">
              <div class="d-flex justify-content-between">
                <span>
                  <p class="mb-0 text-muted">Total Charged</p>
                  <p class="text-dark">
                    {{ claims.totalClaimChargeAmount | currency }}
                  </p>
                </span>
                <span>
                  <p class="mb-0 text-muted">Total Paid</p>
                  <p class="text-dark">
                    {{
                      claims.claimPayment?.payment?.totalAmount
                        ? (claims.claimPayment.payment.totalAmount | currency)
                        : 'N/A'
                    }}
                  </p>
                </span>
                <span>
                  <p class="mb-0 text-muted">Payment Date</p>
                  <p class="text-dark">
                    {{
                      claims.claimPayment?.payment?.paymentDate
                        ? (claims.claimPayment.payment.paymentDate | date : 'MM/dd/yyyy')
                        : 'N/A'
                    }}
                  </p>
                </span>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
