import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Storage } from '../utils'
import { APICONSTANTS } from '../constants';

const COMMUNICATIONS_ACCESS_KEY = environment.communicationsAccessKey;
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private _storage: Storage) { }
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const loggedInUser: any = this._storage.get('local', 'loggedInUser');
        const communicationToken: string = this._storage.get('session', 'communicationToken');
        const ownerId = environment?.ownerId;
        const loginHeaders = loggedInUser && loggedInUser.communicationToken ? { 'access-token': loggedInUser.communicationToken } : { "access-key": COMMUNICATIONS_ACCESS_KEY };
        const otpHeaders = communicationToken ? { 'access-token': communicationToken, ownerId } : { "access-key": COMMUNICATIONS_ACCESS_KEY, ownerId };
        const passwordResetCode = this._storage.get('session', 'passwordResetCode');
        let Authorization = '';
        if (loggedInUser) Authorization = loggedInUser.token;
        if (httpRequest.url.includes('holista-versioning')) return next.handle(httpRequest.clone({ setHeaders: { Authorization: ' ' } }));
        const communicationServiceSecurity = loggedInUser ? loginHeaders : otpHeaders;
        if (httpRequest.url.includes(APICONSTANTS.COMETCHAT)) return next.handle(httpRequest.clone({ setHeaders: { Authorization } }))
        return next.handle(httpRequest.clone({ setHeaders: { Authorization, ...communicationServiceSecurity, ...passwordResetCode && { Code: passwordResetCode } } }));
    }
}