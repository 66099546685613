<section class="container-fluid donotprint">
  <div class="d-flex justify-content-between align-items-center mt-4 action position-sticky">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group has-search input-group mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          [(ngModel)]="searchKey"
          class="form-control"
          id="search"
          placeholder="Search"
          (ngModelChange)="change($event)" />
        <div class="input-group-append" *ngIf="searchKey">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-light pl-3 pr-3"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="OpenFilterModal()"
          mdbWavesEffect>
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
      </div>
    </div>
    <div class="field-value w-50">
      <div class="radio-block d-flex justify-content-end align-items-center">
        <p class="mb-0 mr-2">Show:</p>
        <label class="form-check-label w-auto" for="loginActivity">
          <input
            type="radio"
            class="form-check-input"
            id="loginActivity"
            name="materialExampleRadios"
            [(ngModel)]="activity_type"
            [value]="'login'"
            (change)="changeActivityType('login')"
            mdbInput />
          <div class="box box-sm pl-3 pr-3 mb-0">Login Activity</div>
        </label>
        <label class="form-check-label w-auto" for="userActivity">
          <input
            type="radio"
            class="form-check-input"
            id="userActivity"
            name="materialExampleRadios"
            [(ngModel)]="activity_type"
            [value]="'user'"
            (change)="changeActivityType('user')"
            mdbInput />
          <div class="box box-sm pl-3 pr-3 mb-0">User Activity</div>
        </label>
        <label class="form-check-label w-auto" for="taskActivity">
          <input
            type="radio"
            class="form-check-input"
            id="taskActivity"
            name="materialExampleRadios"
            [(ngModel)]="activity_type"
            [value]="'task'"
            (change)="changeActivityType('task')"
            mdbInput />
          <div class="box box-sm pl-3 pr-3 mb-0">Task Activity</div>
        </label>
      </div>
    </div>
  </div>
  <div class="keywords position-sticky" *ngIf="filterList && filterList.length > 0">
    <!-- <p class="mb-1 mt-2 d-block">Applied Filters</p> -->
    <div class="keywords d-flex mt-2 flex-wrap align-items-center">
      <small class="mb-2">Filters: &nbsp;</small>
      <span class="d-block mr-1 mb-2" *ngFor="let filter of filterList">
        <small class="d-block text-muted ml-1"></small>
        <div class="chip blue lighten-5 mb-0">
          {{ filter.field }}: <span class="font-weight-500 text-dark">{{ filter.label }}</span>
          <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
        </div>
      </span>
      <button
        type="button"
        class="btn btn-link waves-light m-0 p-0"
        mdbWavesEffect
        (click)="ResetFilter()">
        <span class="text-info">Reset Filter</span>
      </button>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table mdbTable hover="true" small="true" class="table-mobile-view" stickyHeader="true">
          <thead>
            <tr>
              <th width="200">Date & Time</th>
              <th width="200">User</th>
              <th width="200">Action</th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading"
            [style.top]="filteredLogList && filteredLogList.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let activity of filteredLogList
                | paginate : {itemsPerPage: limit, currentPage: page, totalItems: totalCount}
            ">
            <tr>
              <td>{{ activity.createdAt | date : 'MM/dd/yyyy hh:mm a' }}</td>
              <td>
                {{
                  activity_type == 'login'
                    ? activity.user_name
                    : activity.payload_data?.senderName
                    ? activity.payload_data?.senderName
                    : activity.created_by_name
                }}
              </td>
              <td *ngIf="activity_type == 'user' && activity?.module_name">
                <div>
                  <b
                    >{{
                      activity.module_name !== 'report' && activity.sub_module
                        ? (activity.module_name | titlecase) + "'s " + activity.sub_module
                        : (activity.module_name | titlecase)
                    }}
                    {{
                      activity.payload_data && activity.payload_data.event
                        ? (activity.payload_data.event | lowercase)
                        : activity.action
                    }}</b
                  >
                </div>
                <div
                  *ngIf="
                    activity.sub_module !== 'email' &&
                    activity.module_name !== 'report' &&
                    !activity.payload_data?.event
                  ">
                  <!-- To show Module name -->
                  {{
                    activity.module_name?.toLowerCase() == 'claim' ||
                    activity.module_name?.toLowerCase() == 'outgoing claim'
                      ? 'Claim Identifier:
                                    '
                      : activity.module_name == 'Payment' ||
                        activity.module_name == 'journal' ||
                        activity.module_name.toLowerCase() ==
                          'health care
                                    diagnosis' ||
                        activity.module_name?.toLowerCase() == 'service line'
                      ? ''
                      : (activity.sub_module
                          ? (activity.module_name | titlecase) +
                            '
                                    ' +
                            (activity.sub_module | titlecase)
                          : (activity.module_name | titlecase)) + ' Name: '
                  }}
                  <!-- To show Module name -->

                  <!-- Episode -->
                  {{
                    activity.module_name == 'episode' && activity.sub_module == null
                      ? activity.payload_data?.episodeName
                        ? activity.payload_data?.episodeName
                        : activity.payload_data?.episode?.name
                      : ''
                  }}
                  {{
                    activity.module_name == 'episode' && activity.sub_module !== null
                      ? activity.payload_data?.episodeName
                      : ''
                  }}

                  <!--Pathway-->
                  {{
                    activity.module_name == 'pathway' && activity.sub_module == null
                      ? activity.payload_data?.name?.newVal
                        ? activity.payload_data?.name?.newVal
                        : activity.payload_data?.name
                      : ''
                  }}
                  {{
                    activity.module_name == 'pathway' && activity.sub_module !== null
                      ? activity.payload_data?.pathwayName
                      : ''
                  }}

                  <!-- Company -->
                  {{
                    activity.module_name == 'company'
                      ? activity.payload_data?.name?.newVal
                        ? activity.payload_data?.name?.newVal
                        : activity.payload_data?.name
                      : ''
                  }}

                  <!-- Document -->
                  {{
                    activity.module_name == 'document'
                      ? activity.payload_data?.name
                        ? activity.payload_data?.name?.newVal
                          ? activity.payload_data?.name?.newVal
                          : activity.payload_data?.name
                        : activity.payload_data?.displayName?.newVal
                        ? activity.payload_data?.displayName?.newVal
                        : activity.payload_data?.displayName
                      : ''
                  }}

                  <!-- User -->
                  {{ activity.module_name == 'user' ? activity.payload_data?.name : '' }}

                  <!-- Client -->
                  {{
                    activity.module_name == 'client' && activity.payload_data
                      ? activity.payload_data.name && activity.payload_data.name.newVal
                        ? activity.payload_data.name.newVal
                        : activity.payload_data.name
                      : ''
                  }}

                  <!-- Purchaser -->
                  {{
                    activity.module_name == 'purchaser' && activity.payload_data
                      ? activity.payload_data.name && activity.payload_data.name.newVal
                        ? activity.payload_data.name.newVal
                        : activity.payload_data.name
                      : ''
                  }}

                  <!-- Role & permission -->
                  {{ activity.module_name == 'permission' ? activity.payload_data?.value : '' }}
                  {{
                    activity.module_name == 'role'
                      ? activity.payload_data?.name?.newVal
                        ? activity.payload_data?.name?.newVal
                        : activity.payload_data?.name
                      : ''
                  }}

                  <!-- Member -->
                  {{
                    activity.module_name == 'member' && activity.sub_module == null
                      ? activity.payload_data?.name
                      : ''
                  }}

                  <!-- Bundle Cost -->
                  {{
                    activity.module_name == 'bundle cost' && activity.payload_data
                      ? activity.payload_data.bundle && activity.payload_data.bundle.name
                        ? activity.payload_data.bundle.name
                        : activity.payload_data.name
                      : ''
                  }}

                  <!-- Bundle -->
                  {{
                    activity.module_name == 'bundle' && activity.payload_data
                      ? activity.payload_data.name && activity.payload_data.name.newVal
                        ? activity.payload_data.name.newVal
                        : activity.payload_data.name
                      : ''
                  }}

                  <!-- Episode of Care -->
                  {{
                    activity.module_name == 'episode of care' && activity.payload_data
                      ? activity.payload_data.name && activity.payload_data.name.newVal
                        ? activity.payload_data.name.newVal
                        : activity.payload_data.name
                      : ''
                  }}

                  <!-- Provider -->
                  {{
                    activity.module_name == 'provider' && activity.sub_module == null
                      ? activity.payload_data?.providerName
                      : ''
                  }}

                  <!-- Claim -->
                  {{
                    activity.module_name.toLowerCase() == 'claim' ||
                    activity.module_name.toLowerCase() == 'outgoing claim'
                      ? activity.payload_data?.claimIdentifier
                      : ''
                  }}

                  <!-- EDI File -->
                  {{
                    activity.module_name.toLowerCase() == 'edi file' && activity.payload_data
                      ? activity.payload_data.fileName
                        ? activity.payload_data.fileName
                        : activity.payload_data.name
                      : ''
                  }}

                  <!-- Payor -->
                  {{
                    (activity.module_name.toLowerCase() == 'payer' ||
                      activity.module_name.toLowerCase() == 'billing provider' ||
                      activity.module_name.toLowerCase() == 'receiver' ||
                      activity.module_name.toLowerCase() == 'submitter' ||
                      activity.module_name.toLowerCase() == 'subscriber') &&
                    activity.payload_data
                      ? activity.payload_data.name
                      : ''
                  }}

                  {{
                    (activity.module_name.toLowerCase() == 'network' ||
                      activity.module_name.toLowerCase() == 'market' ||
                      activity.module_name.toLowerCase() == 'platform owner' ||
                      activity.module_name.toLowerCase() == 'bank' ||
                      activity.module_name.toLowerCase() == 'contact' ||
                      activity.module_name.toLowerCase() == 'document' ||
                      activity.module_name.toLowerCase() == 'account info') &&
                    activity.payload_data
                      ? activity?.payload_data?.name && activity.payload_data.name.newVal
                        ? activity.payload_data.name.newVal
                        : activity.payload_data.name
                      : ''
                  }}
                </div>

                <!--Report-->
                <div *ngIf="activity?.module_name === 'report'">
                  Type: {{ activity.sub_module | titlecase }}
                </div>
                <!--Report-->

                <!--Communications-->
                <div *ngIf="activity.sub_module === 'email'">
                  <div *ngIf="activity.payload_data?.moduleIdentifier">
                    Claim Identifier:
                    {{ activity.payload_data?.moduleIdentifier }}
                  </div>
                  <div *ngIf="activity.payload_data?.subject">
                    Subject: {{ activity.payload_data?.subject }}
                  </div>
                  <div *ngIf="activity.payload_data?.receiverNames">
                    To:
                    {{ activity.payload_data?.receiverNames }}
                  </div>
                </div>
                <!--Communications-->

                <div
                  *ngIf="
                    (activity.module_name == 'episode' &&
                      (activity.payload_data?.episode?.patientName ||
                        activity.payload_data?.patientName)) ||
                    activity.payload_data?.episode?.userName ||
                    activity.payload_data?.userName
                  ">
                  Patient Name:
                  {{
                    activity.payload_data?.episode?.patientName ||
                      activity.payload_data?.patientName ||
                      activity.payload_data?.episode?.userName ||
                      activity.payload_data?.userName
                  }}
                </div>
                <div *ngIf="activity.module_name == 'provider' && activity.action == 'assigned'">
                  was assigned to user {{ activity.payload_data?.name }}
                </div>
                <div *ngIf="activity.module_name == 'provider' && activity.action == 'deleted'">
                  was deleted from user {{ activity.payload_data?.name }}
                </div>
                <div *ngIf="activity.module_name == 'Payment' && activity.action == 'created'">
                  Payment Type: {{ activity.payload_data?.paymentType }} <br />
                  Invoice Id : {{ activity.payload_data?.invoiceId }}<br />
                  Amount: {{ activity.payload_data?.totalAmount | currency }}
                </div>
                <div *ngIf="activity.module_name == 'journal'">
                  Journal Entry Number: {{ activity.payload_data?.entryNumber }}<br />
                  Narration: {{ activity.payload_data?.narration }}
                </div>
                <div
                  *ngIf="
                    (activity.payload_data && activity.payload_data.milestoneName) ||
                    (activity.sub_module === 'milestone' && activity.payload_data.name)
                  ">
                  Milestone Name:
                  {{
                    activity.payload_data.milestoneName
                      ? activity.payload_data.milestoneName
                      : activity.sub_module === 'milestone'
                      ? activity.payload_data.name?.newVal
                        ? activity.payload_data.name?.newVal
                        : activity.payload_data.name
                      : ''
                  }}
                </div>
                <div
                  *ngIf="
                    activity.module_name == 'episode' &&
                    activity.sub_module == 'milestone' &&
                    activity.action == 'updated' &&
                    activity.payload_data.status
                  ">
                  Updated <span class="text-secondary">status</span> from
                  <span class="text-danger"
                    ><del>{{
                      activity.payload_data.status.oldValue
                        ? activity.payload_data.status.oldValue
                        : 'null'
                    }}</del></span
                  >
                  to
                  <span class="text-success">{{
                    activity.payload_data.status.newValue
                      ? activity.payload_data.status.newValue
                      : 'null'
                  }}</span>
                </div>
                <div *ngIf="activity.sub_module === 'topic'">
                  Topic Name:
                  {{
                    activity.action === 'deleted'
                      ? activity.payload_data?.topicName
                      : activity.payload_data?.topic?.newVal
                      ? activity.payload_data?.topic?.newVal
                      : activity.payload_data?.topic
                  }}
                </div>
                <div *ngIf="activity.sub_module === 'task'">
                  Task Name:
                  {{ activity.action === 'deleted' ? activity.payload_data?.taskName : '' }}
                  {{
                    activity.payload_data?.taskType === 'todo'
                      ? activity.payload_data?.name?.newVal
                        ? activity.payload_data?.name?.newVal
                        : activity.payload_data?.name
                      : ''
                  }}
                  {{
                    activity.payload_data?.taskType === 'message'
                      ? activity.payload_data?.title?.newVal
                        ? activity.payload_data?.title?.newVal
                        : activity.payload_data?.title
                      : ''
                  }}
                  {{
                    activity.payload_data?.taskType === 'question'
                      ? activity.payload_data?.questions?.question?.newVal
                        ? activity.payload_data?.questions?.question?.newVal
                        : activity.payload_data?.questions?.question
                      : ''
                  }}
                  {{
                    activity.payload_data?.taskType === 'questionnaire'
                      ? activity.payload_data?.name?.newVal
                        ? activity.payload_data?.name?.newVal
                        : activity.payload_data?.qnrs?.name?.newVal
                        ? activity.payload_data?.qnrs?.name?.newVal
                        : activity.payload_data?.qnrs?.name
                        ? activity.payload_data?.qnrs?.name
                        : activity.payload_data?.name
                      : ''
                  }}
                </div>
                <div *ngIf="activity.sub_module === 'task'">
                  Task Type: {{ activity.payload_data?.taskType }}
                </div>
                <div *ngIf="activity.action == 'updated'">
                  <div *ngFor="let item of activity.payload_data | keyvalue">
                    <div
                      *ngIf="
                        item.value &&
                        item.key !== 'updatedDate' &&
                        item.key !== 'updatedBy' &&
                        item.key !== 'insurerId' &&
                        item.key !== 'createdDate' &&
                        item.key !== 'uuid' &&
                        item.key !== 'parentUuid' &&
                        item.key !== 'taskId' &&
                        item.key !== 'triggerMilestoneId' &&
                        item.key !== 'triggerMilestoneUuid' &&
                        item.key !== 'company_id' &&
                        item.key !== 'companyId' &&
                        item.key !== 'role_code' &&
                        item.key !== 'episodeBundleUuid' &&
                        item.key !== 'fundingReqId' &&
                        item.key !== 'ccId' &&
                        item.key !== 'esId' &&
                        item.key !== 'providerId' &&
                        item.key !== 'episodeId' &&
                        item.key !== 'patientId' &&
                        item.value.oldVal !== item.value.newVal &&
                        (item.value.oldVal ||
                          item.value.oldVal == null ||
                          item.value.oldVal == '') &&
                        (item.value.newVal || item.value.newVal == null || item.value.newVal == '')
                      ">
                      Updated <span class="text-secondary">{{ item.key }}</span> from
                      <span class="text-danger"
                        ><del>{{ item.value.oldVal ? item.value.oldVal : 'null' }}</del></span
                      >
                      to
                      <span class="text-success">{{
                        item.value.newVal ? item.value.newVal : 'null'
                      }}</span>
                    </div>
                    <div *ngFor="let item1 of item.value | keyvalue">
                      <div
                        *ngIf="
                          item1.value &&
                          item1.value.oldVal !== item1.value.newVal &&
                          (item1.value.oldVal ||
                            item1.value.oldVal == null ||
                            item1.value.oldVal == '') &&
                          (item1.value.newVal ||
                            item1.value.newVal == null ||
                            item1.value.newVal == '')
                        ">
                        Updated <span class="text-secondary">{{ item1.key }}</span> from
                        <span class="text-danger"
                          ><del>{{ item1.value.oldVal ? item1.value.oldVal : 'null' }}</del></span
                        >
                        to
                        <span class="text-success">{{
                          item1.value.newVal ? item1.value.newVal : 'null'
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td *ngIf="activity_type == 'user' && !activity?.module_name">N/A</td>
              <td *ngIf="activity_type == 'login'">
                {{ activity.action }}
                <div class="{{ activity.status == 'success' ? 'text-success' : 'text-danger' }}">
                  {{ activity.status | titlecase }}
                </div>
              </td>
              <td *ngIf="activity_type == 'task'">
                <div *ngIf="activity.task_type == 'message'">
                  <strong
                    >Message sent from {{ activity.task_from_name }} to
                    {{ activity.task_to_name }}</strong
                  >
                  <div class="text-secondary">Message: {{ activity.task_name }}</div>
                </div>
                <div *ngIf="activity.task_type == 'todo'">
                  <strong>Todo task completed</strong>
                  <div class="text-secondary">Task Name: {{ activity.task_name }}</div>
                </div>
                <div
                  *ngIf="activity.task_type == 'question' || activity.task_type == 'questionnaire'">
                  <strong>{{
                    activity.task_type == 'question'
                      ? 'Question answered'
                      : "Questionnaire's
                                        Question answered"
                  }}</strong>
                  <div class="text-secondary">Question: {{ activity.task_name }}</div>
                  <div class="text-success">Answer: {{ activity.question_answer_name }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="filteredLogList && filteredLogList.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <div
    *ngIf="
      !loading &&
      (!filteredLogList ||
        filteredLogList.length == 0 ||
        !selectedLogList ||
        selectedLogList.length == 0)
    "
    class="text-center p-4 border mt-3">
    <i class="fas fa-3x fa-history text-black-50"></i>
    <!-- <p *ngIf="!filterForm.get('filterEnabled').value">
            No recent activity.
        </p> -->
    <p>No results found.</p>
  </div>

  <!-- <div class="text-center p-4 border mt-3" *ngIf="!loading && filteredLogList.length>0">
        <i class="fas fa-3x fa-history text-light"></i>
        <p>
            No results found.
        </p>
    </div> -->
</section>

<div
  mdbModal
  #filterModal="mdbModal"
  class="modal fade top"
  id="filterModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Advance Filter</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFilterModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <p>Select Activity Type</p>
          <div class="radio-block form-row mb-3">
            <div class="col">
              <label class="form-check-label" for="filterLoginActivity">
                <input
                  type="radio"
                  class="form-check-input"
                  id="filterLoginActivity"
                  name="filterActivity"
                  [(ngModel)]="filter_activity_type"
                  [value]="'login'"
                  (change)="changeFilterActivityType()"
                  mdbInput />
                <div class="box m-0 d-flex justify-content-center align-items-center">
                  Login Activity
                </div>
              </label>
            </div>

            <div class="col">
              <label class="form-check-label" for="filterUserActivity">
                <input
                  type="radio"
                  class="form-check-input"
                  id="filterUserActivity"
                  name="filterActivity"
                  [(ngModel)]="filter_activity_type"
                  [value]="'user'"
                  (change)="changeFilterActivityType()"
                  mdbInput />
                <div class="box m-0 d-flex justify-content-center align-items-center">
                  User Activity
                </div>
              </label>
            </div>

            <div class="col">
              <label class="form-check-label" for="filterTaskActivity">
                <input
                  type="radio"
                  class="form-check-input"
                  id="filterTaskActivity"
                  name="filterActivity"
                  [(ngModel)]="filter_activity_type"
                  [value]="'task'"
                  (change)="changeFilterActivityType()"
                  mdbInput />
                <div class="box m-0 d-flex justify-content-center align-items-center">
                  Task Activity
                </div>
              </label>
            </div>
          </div>
        </form>
        <form [formGroup]="filterForm">
          <div class="form-group">
            <label for="users">Choose User</label>
            <mdb-select
              [options]="allUsersList"
              id="users"
              placeholder="Select user"
              [disableControl]="
                filter_activity_type === 'user' &&
                (user.roleCode === 'CC' || user.roleCode === 'ES')
                  ? true
                  : false
              "
              formControlName="userId"
              [multiple]="true"
              [highlightFirst]="false">
            </mdb-select>
          </div>

          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <mdb-date-picker
                  #fromDatePicker
                  [inline]="true"
                  name="fromDate"
                  class="w-100 d-inline-block"
                  id="fromDate"
                  [options]="startDateOptions"
                  [placeholder]="'Select from date'"
                  formControlName="fromDate"
                  (dateChanged)="onDateChange($event, 'startDate')"
                  required>
                </mdb-date-picker>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <mdb-date-picker
                  #toDatePicker
                  [inline]="true"
                  name="toDate"
                  class="w-100 d-inline-block"
                  id="toDate"
                  [options]="endDateOptions"
                  [placeholder]="'Select to date'"
                  formControlName="toDate"
                  (dateChanged)="onDateChange($event, 'endDate')"
                  required>
                </mdb-date-picker>
              </div>
            </div>
          </div>

          <div
            class="form-group"
            *ngIf="filter_activity_type === 'login' || filter_activity_type === 'user'">
            <label for="action">Choose Action</label>
            <mdb-select
              *ngIf="filter_activity_type === 'login'"
              [options]="logConstants.loginActions | sort : 'label'"
              id="loginAction"
              placeholder="Select action"
              formControlName="loginAction"
              [multiple]="true"
              [highlightFirst]="false">
            </mdb-select>

            <mdb-select
              *ngIf="filter_activity_type === 'user'"
              [options]="logConstants.userActions | sort : 'label'"
              id="userAction"
              placeholder="Select action"
              formControlName="userAction"
              [multiple]="true"
              [highlightFirst]="false">
            </mdb-select>
          </div>

          <div class="form-group" *ngIf="filter_activity_type === 'user'">
            <label for="module">Choose Module</label>
            <mdb-select
              [options]="logConstants.module_list | sort : 'label'"
              id="module"
              placeholder="Select module"
              formControlName="module"
              [multiple]="true"
              (selected)="moduleSelect()"
              (deselected)="moduleSelect($event)"
              [highlightFirst]="false">
            </mdb-select>
          </div>

          <div class="form-group" *ngIf="filter_activity_type === 'user' && show_Sub_Module">
            <label for="sub_module">Choose Sub Module</label>
            <mdb-select
              [options]="display_sub_module_list | sort : 'label'"
              id="sub_module"
              placeholder="Select sub module"
              formControlName="sub_module"
              [multiple]="true"
              [highlightFirst]="false">
            </mdb-select>
          </div>
          <!-- <div > -->
          <div class="form-group" *ngIf="filter_activity_type === 'task'">
            <label for="taskType">Choose Task Type</label>
            <mdb-select
              [options]="logConstants.task_list | sort : 'label'"
              id="taskType"
              placeholder="Select task type"
              formControlName="taskType"
              [multiple]="true"
              [enableSelectAll]="false"
              [highlightFirst]="false">
            </mdb-select>
          </div>
          <!-- </div> -->
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeFilterModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitFilterActivity()">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>
