<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
    <div class="form-group has-search input-group mt-2 mb-2 w-25 w-xs-100">
      <span class="form-control-feedback left -0">
        <i class="icofont-search-1"></i>
      </span>
      <input
        type="text"
        [(ngModel)]="query.keyword"
        class="form-control"
        name="searchKeyword"
        placeholder="Search"
        (ngModelChange)="searchKeywordChange($event)" />
      <div class="input-group-append" *ngIf="query.keyword">
        <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
          <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50 mt-2 mb-2">
      <div style="width: 40%; margin-top: 5px" *ngIf="user.roleCode !== 'PUA'">
        <app-select
          [options]="clients"
          [placeholder]="'Select client'"
          (onSelected)="filterByClient($event)"
          (onDeselected)="filterByClient($event, 'remove')"
          [allowClear]="true">
        </app-select>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#basicExample"
          mdbWavesEffect
          (click)="openPurchaserModal()">
          <i class="icofont-plus"></i> ADD
        </button>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table mdbTable stickyHeader="true" small="true" hover="true">
          <thead id="scrollToTop">
            <tr>
              <th width="20"></th>
              <th scope="col" [class.active]="query.sortBy === 'tin'" (click)="setOrder('tin')">
                TIN
                <span *ngIf="query.sortBy === 'tin'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th scope="col" [class.active]="query.sortBy === 'name'" (click)="setOrder('name')">
                Name
                <span *ngIf="query.sortBy === 'name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="110"
                [class.active]="query.sortBy === 'createdAt'"
                (click)="setOrder('createdAt')">
                Created Date
                <span *ngIf="query.sortBy === 'createdAt'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th width="20"></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loadingData.purchasers"
            [style.top]="purchasers && purchasers.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loadingData.purchasers"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let purchaser of purchasers
                | paginate
                  : {
                      itemsPerPage: query.limit,
                      currentPage: query.page,
                      totalItems: totalCount
                    };
              let i = index
            ">
            <tr>
              <td (click)="purchaser.collapsed = !purchaser.collapsed; getDetails(purchaser)">
                <button
                  type="button"
                  class="btn btn-link p-0 mt-0"
                  mdbWavesEffect
                  *ngIf="purchaser.collapsed">
                  <i class="icofont-minus-circle icofont-lg text-info"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-link p-0 mt-0"
                  mdbWavesEffect
                  *ngIf="!purchaser.collapsed">
                  <i class="icofont-plus-circle icofont-lg text-info"></i>
                </button>
              </td>
              <td>{{ purchaser?.tin }}</td>
              <td>
                {{ purchaser?.name }}
                <br />
                <span *ngFor="let name of purchaser?.clientNames">
                  <span class="badge badge-sm bg-primary-dark text-white z-depth-0">
                    <i class="far fa-building mr-1"></i>
                    {{ name | titlecase }} </span
                  >&nbsp;
                </span>
              </td>
              <td>{{ purchaser?.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}</td>
              <td>
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div
                    class="custom-dropdown-content rounded-sm z-depth-2"
                    [style.top]="(i === query.limit - 2 || i === query.limit - 1) && '-215px'">
                    <a class="dropdown-item" (click)="openValueBasedProgramModal(purchaser)">
                      <i class="far fa-check-square"></i>
                      Value Based Program
                    </a>
                    <a class="dropdown-item" (click)="openEmployerGroupModal(purchaser)">
                      <i class="fas fa-users"></i>
                      Add Employer Group
                    </a>
                    <a
                      class="dropdown-item"
                      (click)="openFundingSourceModal(purchaser)"
                      [ngClass]="purchaser.fundingSource ? 'isDisabled' : ''">
                      <i class="fas fa-hand-holding-usd"></i>
                      Add Funding Source
                    </a>
                    <a class="dropdown-item" (click)="openPurchaserModal(purchaser)">
                      <i class="icofont-edit-alt"></i>
                      Edit
                    </a>
                    <a class="dropdown-item" (click)="delete(purchaser, 'purchaser')">
                      <i class="icofont-ui-delete"></i>
                      Delete
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="purchaser.collapsed" class="grey lighten-5 border">
              <td colspan="100%" class="p-3">
                <div class="row">
                  <div class="col-7">
                    <h6>Additional Information</h6>
                    <div class="d-flex mb-3">
                      <div class="list-content w-50">
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0 m-0">
                            <span class="d-none d-sm-inline-block">Address:</span></label
                          >
                          <div class="field-value">
                            {{ purchaser?.addressLine1 ? purchaser.addressLine1 : '' }}
                            {{ purchaser.addressLine2 ? purchaser.addressLine2 : '' }}
                            <br />
                            <span
                              >{{ purchaser?.city ? purchaser.city : '' }}
                              {{ purchaser?.state ? purchaser.state : '' }}
                              {{ purchaser?.zip ? purchaser.zip : ''
                              }}{{ purchaser?.zip4 ? '-' + purchaser.zip4 : '' }}</span
                            >
                          </div>
                        </div>
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0 m-0">
                            <span class="d-none d-sm-inline-block">Phone:</span></label
                          >
                          <div class="field-value">
                            {{ purchaser?.phone ? purchaser.phone : 'N/A' }}
                          </div>
                        </div>
                      </div>
                      <div class="list-content border-top-0 w-50">
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0 m-0"
                            ><span class="d-none d-sm-inline-block">Contact Name:</span></label
                          >
                          <div class="field-value">
                            {{ purchaser.contactName }}
                          </div>
                        </div>
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0 m-0"
                            ><span class="d-none d-sm-inline-block">Contact Email:</span></label
                          >
                          <div class="field-value">
                            {{ purchaser.contactEmail }}
                          </div>
                        </div>
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0 m-0"
                            ><span class="d-none d-sm-inline-block">Contact Phone:</span></label
                          >
                          <div class="field-value">
                            {{
                              purchaser.contactPhone
                                ? (purchaser.contactPhone | formatPhoneNumber)
                                : 'N/A'
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <mdb-tabset
                  [buttonClass]="'classic-tabs border-bottom'"
                  [contentClass]="'card tab-clean d-block p-0'"
                  class="classic-tabs">
                  <mdb-tab heading="Value Based Program">
                    <app-loader [loader]="purchaser?.vbpData"></app-loader>
                    <div class="row mt-3" *ngIf="!purchaser?.vbpData">
                      <div class="col-12">
                        <div
                          *ngIf="
                            purchaser?.valueBasedPrograms && purchaser.valueBasedPrograms.length > 0
                          ">
                          <div class="row">
                            <div
                              class="col-4 col-sm-4 col-md-4 col-xl-3 pb-2"
                              *ngFor="let vbp of purchaser.valueBasedPrograms">
                              <mdb-card class="z-depth-0 bg-blue-light h-100">
                                <mdb-card-header
                                  class="d-flex justify-content-between align-items-center p-2">
                                  <div>
                                    <h6 class="font-weight-500">
                                      {{ vbp?.vbp?.name }}
                                    </h6>
                                    <mdb-badge class="z-depth-0 bg-primary-dark">
                                      <i class="icofont-building"></i>
                                      {{ vbp?.vbpCode }}
                                    </mdb-badge>
                                  </div>
                                  <div class="custom-dropdown">
                                    <button
                                      class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                                      type="button"
                                      mdbWavesEffect>
                                      <mdb-icon class="skin-link" fas icon="ellipsis-v"> </mdb-icon>
                                    </button>
                                    <div class="custom-dropdown-content rounded-sm z-depth-2">
                                      <a
                                        class="dropdown-item"
                                        (click)="openValueBasedProgramModal(purchaser, vbp)">
                                        <i class="icofont-edit-alt"></i>
                                        Edit
                                      </a>
                                      <a
                                        class="dropdown-item"
                                        (click)="delete(vbp?.vbp, 'vbp', purchaser)">
                                        <i class="icofont-ui-delete"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </mdb-card-header>
                                <mdb-card-body class="p-2">
                                  <p>{{ vbp?.vbp?.description }}</p>
                                  <p class="font-weight-500 mt-3">Associated Episode Of Care</p>
                                  <ul
                                    class="pl-0 mb-0 scrollbar scrollbar-primary thin overflow-auto"
                                    style="max-width: 250px">
                                    <li *ngFor="let eoc of vbp.vbp?.episodeOfCares | slice : 0 : 3">
                                      <span class="badge badge-info z-depth-0 mr-2 mb-2 p-1">
                                        {{ eoc?.name }}
                                      </span>
                                    </li>
                                  </ul>
                                  <div
                                    *ngIf="
                                      vbp.vbp?.episodeOfCares && vbp.vbp?.episodeOfCares?.length > 3
                                    "
                                    class="action-item position-absolute"
                                    (click)="showAssociatedEOC(vbp.vbp, vbp.vbp.episodeOfCares)"
                                    style="bottom: 10px; right: 10px">
                                    <span class="chip chip-rounded alert preliminary m-0">
                                      {{ vbp.vbp?.episodeOfCares?.length - 3 }}+</span
                                    >
                                    View more
                                  </div>
                                </mdb-card-body>
                              </mdb-card>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="
                            purchaser?.valueBasedPrograms &&
                            purchaser.valueBasedPrograms.length == 0
                          ">
                          <strong>No Value Based Programs Available</strong>
                        </div>
                      </div>
                    </div>
                  </mdb-tab>
                  <mdb-tab heading="Funding Source">
                    <div class="row mt-3">
                      <div class="col-12">
                        <div *ngIf="purchaser?.fundingSource">
                          <mdb-card class="z-depth-0 bg-blue-light border h-100">
                            <mdb-card-header
                              class="d-flex justify-content-between align-items-center p-2">
                              <h6 class="mb-0">
                                {{ purchaser.fundingSource.name }}
                              </h6>
                              <div class="custom-dropdown">
                                <button
                                  class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                                  type="button"
                                  mdbWavesEffect>
                                  <mdb-icon class="skin-link" fas icon="ellipsis-v"> </mdb-icon>
                                </button>
                                <div class="custom-dropdown-content rounded-sm z-depth-2">
                                  <a
                                    class="dropdown-item"
                                    (click)="openFundingSourceModal(purchaser)">
                                    <i class="icofont-edit-alt"></i>
                                    Edit
                                  </a>
                                  <!-- <a class="dropdown-item"
                                                                        (click)="delete(purchaser.fundingSource,'funding-source')">
                                                                        <i class="icofont-ui-delete"></i>
                                                                        Delete
                                                                    </a> -->
                                </div>
                              </div>
                            </mdb-card-header>
                            <mdb-card-body class="p-2">
                              <div class="d-flex">
                                <div class="list-content">
                                  <div class="field-group pt-0 pb-0">
                                    <label class="pl-0"
                                      ><span class="d-none d-sm-inline-block">Payor ID</span></label
                                    >
                                    <div class="field-value">
                                      {{ purchaser.fundingSource.identifier }}
                                    </div>
                                  </div>
                                  <div class="field-group pt-0 pb-0">
                                    <label class="pl-0"
                                      ><span class="d-none d-sm-inline-block">NPI </span></label
                                    >
                                    <div class="field-value">
                                      {{ purchaser.fundingSource.npi }}
                                    </div>
                                  </div>
                                  <div class="field-group pt-0 pb-0">
                                    <label class="pl-0">
                                      <span class="d-none d-sm-inline-block">TIN</span></label
                                    >
                                    <div class="field-value">
                                      {{ purchaser.fundingSource.tin }}
                                    </div>
                                  </div>
                                </div>
                                <div class="ml-5 list-content border-top-0">
                                  <div class="field-group pt-0 pb-0">
                                    <label class="pl-0"
                                      ><span class="d-none d-sm-inline-block">Address</span></label
                                    >
                                    <div class="field-value">
                                      <div class="field-value">
                                        {{
                                          purchaser.fundingSource.addressLine1
                                            ? purchaser.fundingSource.addressLine1
                                            : ''
                                        }}
                                        {{
                                          purchaser.fundingSource.addressLine2
                                            ? purchaser.fundingSource.addressLine2
                                            : ''
                                        }}
                                        <br />
                                        <span
                                          >{{
                                            purchaser.fundingSource.city
                                              ? purchaser.fundingSource.city
                                              : ''
                                          }}
                                          {{
                                            purchaser.fundingSource.state
                                              ? purchaser.fundingSource.state
                                              : ''
                                          }}
                                          {{
                                            purchaser.fundingSource.zip
                                              ? purchaser.fundingSource.zip
                                              : ''
                                          }}{{
                                            purchaser.fundingSource.zip4
                                              ? '-' + purchaser.fundingSource.zip4
                                              : ''
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="ml-5 list-content border-top-0">
                                  <div class="field-group pt-0 pb-0">
                                    <label class="pl-0"
                                      ><span class="d-none d-sm-inline-block"
                                        >Contact Name:</span
                                      ></label
                                    >
                                    <div class="field-value">
                                      {{
                                        purchaser.fundingSource.contactName
                                          ? purchaser.fundingSource.contactName
                                          : 'N/A'
                                      }}
                                    </div>
                                  </div>
                                  <div class="field-group pt-0 pb-0">
                                    <label class="pl-0"
                                      ><span class="d-none d-sm-inline-block"
                                        >Contact Email:</span
                                      ></label
                                    >
                                    <div class="field-value">
                                      {{
                                        purchaser.fundingSource.contactEmail
                                          ? purchaser.fundingSource.contactEmail
                                          : 'N/A'
                                      }}
                                    </div>
                                  </div>
                                  <div class="field-group pt-0 pb-0">
                                    <label class="pl-0"
                                      ><span class="d-none d-sm-inline-block"
                                        >Contact Phone:</span
                                      ></label
                                    >
                                    <div class="field-value">
                                      {{
                                        purchaser.fundingSource.contactPhone
                                          ? (purchaser.fundingSource.contactPhone
                                            | formatPhoneNumber)
                                          : 'N/A'
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="ml-5 list-content border-top-0">
                                  <div class="field-group pt-0 pb-0">
                                    <label class="pl-0"
                                      ><span class="d-none d-sm-inline-block"
                                        >Holista Billing NPI:</span
                                      ></label
                                    >
                                    <div class="field-value">
                                      {{
                                        purchaser?.fundingSource?.holistaBillingNpi
                                          ? purchaser.fundingSource.holistaBillingNpi
                                          : 'N/A'
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </mdb-card-body>
                          </mdb-card>
                        </div>
                        <div *ngIf="!purchaser?.fundingSource">
                          <strong>No Funding Source Available</strong>
                        </div>
                      </div>
                    </div>
                  </mdb-tab>
                  <mdb-tab heading="Employer Group">
                    <div class="row mt-3">
                      <div class="col-12">
                        <div
                          *ngIf="purchaser?.employerGroups && purchaser.employerGroups.length > 0">
                          <div class="form-row">
                            <div
                              class="col-4 col-sm-4 col-md-3 col-xl-3"
                              *ngFor="let grp of purchaser.employerGroups">
                              <div
                                class="alert bg-blue-light rounded-sm d-flex justify-content-between align-items-center p-2">
                                <span>
                                  <h6>{{ grp.name }}</h6>
                                  <span class="badge badge-info z-depth-0">{{ grp.code }}</span>
                                </span>
                                <div class="custom-dropdown">
                                  <button
                                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                                    type="button"
                                    mdbWavesEffect>
                                    <mdb-icon class="skin-link" fas icon="ellipsis-v"> </mdb-icon>
                                  </button>
                                  <div class="custom-dropdown-content rounded-sm z-depth-2">
                                    <a
                                      class="dropdown-item"
                                      (click)="openEmployerGroupModal(purchaser, grp)">
                                      <i class="icofont-edit-alt"></i>
                                      Edit
                                    </a>
                                    <a
                                      class="dropdown-item"
                                      (click)="delete(grp, 'employer-group')">
                                      <i class="icofont-ui-delete"></i>
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="purchaser?.employerGroups && purchaser.employerGroups.length == 0">
                          <strong>No Employer Groups Available</strong>
                        </div>
                      </div>
                    </div>
                  </mdb-tab>
                </mdb-tabset>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="purchasers && purchasers.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <!-- <div appScrollToTop class="fixed-action-btn donotprint" style="bottom: 145px; right: 10px;display:none;">
        <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
            <i class="fas fa-angle-up text-black-50"></i>
        </a>
    </div> -->
  <div class="text-center p-4 border mt-3" *ngIf="!loadingData.purchasers && !result.purchasers">
    <i class="fas fa-3x fa-city text-black-50"></i>
    <p>Start adding purchasers.</p>
  </div>
  <div
    class="text-center p-4 border mt-3"
    *ngIf="!loadingData.purchasers && !result.searchedPurchasers">
    <i class="fas fa-3x fa-city text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>

<!-- Delete Modal -->
<app-delete-confirmation
  [_id]="deleteId"
  [message]="message"
  (confirmDelete)="deleteConfirm($event)">
</app-delete-confirmation>

<!-- Purchaser Add/Edit Modal -->
<div
  mdbModal
  #purchaserModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ purchaserForm.value.id ? 'Edit' : 'Add' }} Purchaser</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closePurchaserModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="purchaserForm">
          <div class="form-group">
            <label for="pName">Name *</label>
            <input
              type="text"
              id="pName"
              class="md-textarea form-control"
              mdbInput
              formControlName="name"
              [ngClass]="{
                'is-invalid': submitted && purchaserForm.get('name').invalid
              }" />
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="pNumber">Number *</label>
                <input
                  type="number"
                  id="pNumber"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="number"
                  numbersOnly
                  [disableControl]="purchaserForm.value.id ? true : false"
                  [ngClass]="{
                    'is-invalid': submitted && purchaserForm.get('number').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="pTIN">TIN *</label>
                <input
                  type="text"
                  id="pTIN"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="tin"
                  maxlength="9"
                  numbersOnly
                  [ngClass]="{
                    'is-invalid': submitted && purchaserForm.get('tin').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="phone">Phone *</label>
            <input
              type="text"
              id="phone"
              class="form-control"
              formControlName="phone"
              [textMask]="{mask: phoneMask}"
              mdbInput
              [ngClass]="{
                'is-invalid': submitted && purchaserForm.get('phone').invalid
              }" />
          </div>
          <div class="form-group">
            <label for="pAddress">Address *</label>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="pAddress1"
                  class="form-control"
                  formControlName="addressLine1"
                  mdbInput
                  placeholder="Address line 1"
                  [ngClass]="{
                    'is-invalid': submitted && purchaserForm.get('addressLine1').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="pAddress2"
                  class="form-control"
                  formControlName="addressLine2"
                  mdbInput
                  placeholder="Address line 2"
                  [ngClass]="{
                    'is-invalid': submitted && purchaserForm.get('addressLine2').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <input
                  type="text"
                  id="pCity"
                  placeholder="City"
                  class="form-control"
                  formControlName="city"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': submitted && purchaserForm.get('city').invalid
                  }" />
              </div>
            </div>
            <div class="col-2">
              <mdb-select
                [options]="usStates | sort : 'label'"
                id="pStates"
                formControlName="state"
                placeholder="State"
                [ngClass]="{
                  'error-select': submitted && purchaserForm.get('state').invalid
                }">
              </mdb-select>
            </div>
            <div class="col">
              <input
                type="text"
                id="pZip"
                class="form-control"
                formControlName="zip"
                placeholder="Zip"
                numbersOnly
                maxlength="5"
                mdbInput
                [ngClass]="{
                  'is-invalid': submitted && purchaserForm.get('zip').invalid
                }" />
            </div>
            <div>
              <span class="zip-dash">-</span>
            </div>
            <div class="col">
              <input
                type="text"
                id="pZip4"
                class="form-control"
                formControlName="zip4"
                placeholder="Zip4"
                numbersOnly
                maxlength="4"
                mdbInput
                [readonly]="!purchaserForm.value.zip"
                [ngClass]="{
                  'is-invalid': submitted && purchaserForm.get('zip4').invalid
                }" />
            </div>
          </div>
          <div class="form-group">
            <label for="pContactName">Contact Name *</label>
            <input
              type="text"
              id="pContactName"
              class="md-textarea form-control"
              mdbInput
              formControlName="contactName"
              [ngClass]="{
                'error-select': submitted && purchaserForm.get('contactName').invalid
              }" />
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="pContactEmail">Contact Email *</label>
                <input
                  type="text"
                  id="pContactEmail"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="contactEmail"
                  [ngClass]="{
                    'is-invalid': submitted && purchaserForm.get('contactEmail').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="pContactPhone">Contact Phone *</label>
                <input
                  type="text"
                  id="pContactPhone"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="contactPhone"
                  [textMask]="{mask: phoneMask}"
                  [ngClass]="{
                    'is-invalid': submitted && purchaserForm.get('contactPhone').invalid
                  }" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closePurchaserModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitPurchaser()">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ purchaserForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Value Based Program Add/Edit Modal -->
<div
  mdbModal
  #valueBasedProgramModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ valueBasedProgramForm.value.id ? 'Edit' : 'Add' }} Value Based Program
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeValueBasedProgramModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="valueBasedProgramForm">
          <div class="form-group position-relative">
            <label for="clients"
              >Client *
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="loadingData.clients"></span
            ></label>
            <input
              type="text"
              class="completer-input form-control mdb-autocomplete mb-0"
              [ngModel]="client_search_text | async"
              (ngModelChange)="client_search_text.next($event)"
              [ngModelOptions]="{standalone: true}"
              [mdbAutoCompleter]="autos"
              [readonly]="valueBasedProgramForm.value.id"
              placeholder="Type to search client" />
            <mdb-auto-completer
              #autos="mdbAutoCompleter"
              textNoResults="No result found."
              (selected)="clientSelected($event)"
              [displayValue]="displayClient"
              [disabled]="valueBasedProgramForm.value.id">
              <mdb-option *ngFor="let option of client_results | async" [value]="option">
                {{ option.name }}
              </mdb-option>
            </mdb-auto-completer>
          </div>
          <div class="form-row">
            <div class="col-6">
              <label for="programStartDate"
                >Value Based Program *
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loadingData.vbp"></span
              ></label>
              <mdb-select
                [options]="vbpList"
                id="vbp"
                placeholder="Select value based program"
                [multiple]="false"
                [enableSelectAll]="false"
                (selected)="vbpSelected($event)"
                formControlName="vbpCode"
                [disableControl]="valueBasedProgramForm.value.id ? true : false"
                [ngClass]="{
                  'error-select': submitted && valueBasedProgramForm.get('vbpCode').invalid
                }">
              </mdb-select>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="programStartDate">Program Start Date</label>
                <input
                  type="text"
                  id="programStartDate"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="effectiveDate"
                  placeholder="MM/DD/YYYY"
                  [textMask]="{mask: dateMask}"
                  [ngClass]="{
                    'error-select': submitted && valueBasedProgramForm.get('effectiveDate').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="programEndDate">Program End Date</label>
                <input
                  type="text"
                  id="programEndDate"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="terminationDate"
                  placeholder="MM/DD/YYYY"
                  [textMask]="{mask: dateMask}"
                  [ngClass]="{
                    'error-select':
                      submitted && valueBasedProgramForm.get('terminationDate').invalid
                  }" />
              </div>
            </div>
          </div>
          <div *ngIf="selectedVbp?.name">
            <label>Selected Value Based Program</label>
            <div class="row">
              <div class="col-12">
                <mdb-accordion class="accordion-block">
                  <mdb-accordion-item [collapsed]="false">
                    <mdb-accordion-item-head>{{ selectedVbp?.name }}</mdb-accordion-item-head>
                    <mdb-accordion-item-body>
                      <p class="font-weight-500">Description</p>

                      <p>{{ selectedVbp?.description }}</p>
                      <p class="font-weight-500 mt-3">Associated Episode Of Care</p>
                      <div
                        class="scrollbar scrollbar-primary thin overflow-auto"
                        [style.maxHeight]="'300px'">
                        <span
                          class="badge badge-info z-depth-0 mb-2 mr-2"
                          *ngFor="let eoc of selectedVbp?.episodeOfCares"
                          >{{ eoc.name }}</span
                        >
                      </div>
                    </mdb-accordion-item-body>
                  </mdb-accordion-item>
                </mdb-accordion>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeValueBasedProgramModal()">
          Cancel
        </button>
        <div>
          <button
            class="btn btn-secondary waves-light mr-2"
            mdbWavesEffect
            (click)="submitValueBasedProgram('addNew')"
            *ngIf="!valueBasedProgramForm.value.id">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="submit_enabled"></span>
            Save and New
          </button>
          <button
            class="btn btn-secondary waves-light m-0"
            mdbWavesEffect
            (click)="submitValueBasedProgram('close')">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="submit_enabled"></span>
            {{ valueBasedProgramForm.value.id ? 'Update' : 'Save and Close' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Employer Group Add/Edit Modal -->
<div
  mdbModal
  #employerGroupModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ employerGroupForm.value.id ? 'Edit' : 'Add' }} Employer Group
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeEmployerGroupModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="employerGroupForm">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="eName">Name *</label>
                <input
                  type="text"
                  id="eName"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="name"
                  [ngClass]="{
                    'is-invalid': submitted && employerGroupForm.get('name').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="eNumber">Number *</label>
                <input
                  type="number"
                  id="eNumber"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="number"
                  numbersOnly
                  [ngClass]="{
                    'is-invalid': submitted && employerGroupForm.get('number').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="eCode">Code *</label>
                <input
                  type="text"
                  id="eCode"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="code"
                  [disableControl]="employerGroupForm.value.id ? true : false"
                  [ngClass]="{
                    'is-invalid': submitted && employerGroupForm.get('code').invalid
                  }" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeEmployerGroupModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitEmplpoyerGroup()">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ employerGroupForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Funding Source Add/Edit Modal -->
<div
  mdbModal
  #fundingSourceModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ fundingSourceForm.value.id ? 'Edit' : 'Add' }} Funding Source
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFundingSourceModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="fundingSourceForm">
          <div class="form-group">
            <label for="fName">Name *</label>
            <input
              type="text"
              id="fName"
              class="md-textarea form-control"
              mdbInput
              formControlName="name"
              [ngClass]="{
                'is-invalid': submitted && fundingSourceForm.get('name').invalid
              }" />
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label for="fPayerID">Payor ID *</label>
                <input
                  type="text"
                  id="fPayerID"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="identifier"
                  maxlength="15"
                  [ngClass]="{
                    'is-invalid': submitted && fundingSourceForm.get('identifier').invalid
                  }"
                  name="contactName" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="fNPI">NPI *</label>
                    <input
                      type="text"
                      id="fNPI"
                      class="md-textarea form-control"
                      mdbInput
                      formControlName="npi"
                      maxlength="10"
                      numbersOnly
                      [ngClass]="{
                        'is-invalid': submitted && fundingSourceForm.get('npi').invalid
                      }" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="fTIN">TIN *</label>
                    <input
                      type="text"
                      id="fTIN"
                      class="md-textarea form-control"
                      mdbInput
                      formControlName="tin"
                      maxlength="9"
                      numbersOnly
                      [ngClass]="{
                        'is-invalid': submitted && fundingSourceForm.get('tin').invalid
                      }" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="fNPI">Holista Biliing NPI *</label>
            <input
              type="text"
              id="holistaBillingNpi"
              class="md-textarea form-control"
              mdbInput
              formControlName="holistaBillingNpi"
              maxlength="10"
              numbersOnly
              [ngClass]="{
                'is-invalid': submitted && fundingSourceForm.get('holistaBillingNpi').invalid
              }" />
          </div>

          <div class="form-group">
            <label for="pAddress">Address *</label>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="fAddress1"
                  class="form-control"
                  formControlName="addressLine1"
                  mdbInput
                  placeholder="Address line 1"
                  [ngClass]="{
                    'is-invalid': submitted && fundingSourceForm.get('addressLine1').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="fAddress2"
                  class="form-control"
                  formControlName="addressLine2"
                  mdbInput
                  placeholder="Address line 2"
                  [ngClass]="{
                    'is-invalid': submitted && fundingSourceForm.get('addressLine2').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <input
                  type="text"
                  id="fCity"
                  placeholder="City"
                  class="form-control"
                  formControlName="city"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': submitted && fundingSourceForm.get('city').invalid
                  }" />
              </div>
            </div>
            <div class="col-2">
              <mdb-select
                [options]="usStates | sort : 'label'"
                id="fStates"
                formControlName="state"
                placeholder="State"
                [ngClass]="{
                  'error-select': submitted && fundingSourceForm.get('state').invalid
                }">
              </mdb-select>
            </div>
            <div class="col">
              <input
                type="text"
                id="fZip"
                class="form-control"
                formControlName="zip"
                placeholder="Zip"
                numbersOnly
                maxlength="5"
                mdbInput
                [ngClass]="{
                  'is-invalid': submitted && fundingSourceForm.get('zip').invalid
                }" />
            </div>
            <div>
              <span class="zip-dash">-</span>
            </div>
            <div class="col">
              <input
                type="text"
                id="fZip4"
                class="form-control"
                formControlName="zip4"
                placeholder="Zip4"
                numbersOnly
                maxlength="4"
                mdbInput
                [readonly]="!fundingSourceForm.value.zip"
                [ngClass]="{
                  'is-invalid': submitted && fundingSourceForm.get('zip4').invalid
                }" />
            </div>
          </div>
          <div class="form-group">
            <label for="pContactName">Contact Name</label>
            <input
              type="text"
              id="fContactName"
              class="md-textarea form-control"
              mdbInput
              formControlName="contactName"
              [ngClass]="{
                'is-invalid': submitted && fundingSourceForm.get('contactName').invalid
              }" />
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="pContactEmail">Contact Email</label>
                <input
                  type="text"
                  id="fContactEmail"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="contactEmail"
                  [ngClass]="{
                    'is-invalid': submitted && fundingSourceForm.get('contactEmail').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="pContactPhone">Contact Phone</label>
                <input
                  type="text"
                  id="fContactPhone"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="contactPhone"
                  [textMask]="{mask: phoneMask}"
                  [ngClass]="{
                    'is-invalid': submitted && fundingSourceForm.get('contactPhone').invalid
                  }" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeFundingSourceModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitFundingSource()">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ fundingSourceForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ASSOCIATED EOC LIST MODAL -->
<div
  mdbModal
  #associatedEOCModal="mdbModal"
  class="modal fade top"
  id="associatedEOC"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ selectedValueBasedProgramEOCs?.vbp?.name }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeAssociatedEOCModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div
        class="modal-body scrollbar scrollbar-primary thin overflow-auto p-2"
        style="max-height: 400px">
        <div *ngIf="selectedValueBasedProgramEOCs?.vbp?.description">
          <p class="font-weight-500 pl-2">Description</p>
          <p class="pl-2">
            {{ selectedValueBasedProgramEOCs?.vbp?.description }}
          </p>
        </div>
        <p class="font-weight-500 pl-2">Associated Episode Of Care</p>
        <ul class="pl-2 mb-0">
          <li *ngFor="let associatedEoc of selectedValueBasedProgramEOCs?.eocs">
            <span class="badge badge-info z-depth-0 mr-2 mb-2 p-1">
              {{ associatedEoc?.name }}
            </span>
          </li>
        </ul>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-end p-2 border-top-0">
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="closeAssociatedEOCModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
