import { Injectable } from "@angular/core";
import { HolistaUtils } from "../utils";
import { KPI_VALUES } from "../constants";

@Injectable({
    providedIn: 'root',
})

export class ReportMappingUtility {
    constructor(
        private _holistaUtility: HolistaUtils,
    ) { }

    activeEpisodeByMonthDataMapper(data: any[]) {
        const header = Object.keys(data[0]);
        const displayHeader = Object.keys(data[0]).map((data) => this._holistaUtility.titleCasing(data));
        const body = data.map((item) => {
            const values = header.map((key) => typeof (item[key]) === 'number' ? Number(item[key]) : item[key].toString());
            return values;
        });
        return { header: displayHeader, body };
    }

    newEpisodeByMonthMapper(data: any) {
        return data.datasets.map(dataset => {
            const labelAndData = [dataset.label, ...dataset.data];
            return labelAndData;
        });
    }

    episodeCarePathwayMapper(data: any) {
        const displayHeader = ['Category', 'Sub Category', 'Episode Count by Subcategory', 'Episode Count by Category', 'Category Percent'];
        const reportData = data.data.map((item) => [
            item.category,
            item.subCategory,
            item.episodeCount,
            item.categoryCount,
            item.categoryPercent,
        ]);
        return { header: displayHeader, reportData };
    }

    portalEngagementYtdOfContentAccessedMapper(data: any) {
        return data.labels.map((label, index) => [label, data.completedCount[index], data.notCompletedCount[index], `${data.percentage[index]}%`]);
    }

    portalEngagementMilestonesDetailMapper(data: any) {
        return data.labels.map((label, index) => [
            label,
            data.completed_count[index],
            `${data.engaged_percent[index]}%`,
            data.not_completed_count[index],
            data.total_count[index]
        ]);
    }

    consentedPatientsCommentsMapper(data: any) {
        return data.map((x) => [x.answerDate, x.fullName, x.questionnaireName, x.question, x.answer]);
    }

    portalQuestionDetailMapper(data: any) {
        return data.map((x) => [x.answerDate, x.fullName, x.milestoneStartDate, x.questionnaireName, x.question, x.answer]);
    }

    patientSatisfactionKeyQuestionMapper(data: any) {
        return data.labels.map((label, index) => {
            const valueOfNo = data.dataSets[1].datas[index];
            return [label, data.dataSets[0].datas[index], valueOfNo > 0 ? valueOfNo : valueOfNo * -1];
        });
    }

    surveyResponseRateYTDMapper(data: any) {
        return data.labels.map((label, index) => [
            label,
            +data.dataSets[0].datas[index],
            +data.dataSets[1].datas[index],
            +data.dataSets[2].datas[index],
            `${data.dataSets[3].datas[index]}%`,
            `${data.dataSets[4].datas[index]}%`,
        ]);
    }

    surveyQuestionWithHightstVolumeOfNoMapper(actualData, data) {
        const years = data.map((x) => x.label).sort((a, b) => a.localeCompare(b));
        const labels = actualData.labels;
        const filteredData = actualData.dataSets.filter((item) => years.includes(item.label));
        const resultArray = [];
        for (let i = 0; i < labels.length; i++) {
            const labelText = labels[i];
            const dataForLabel = [labelText];
            for (const entry of filteredData) {
                const yearIndex = labels.findIndex(year => year === entry.label);
                dataForLabel.push(entry.datas[i]);
            }
            resultArray.push(dataForLabel);
        }
        return { header: years, body: resultArray }
    }

    patientComplianceWithRequiredTaskMapper(data, isIndividual, categoryName?) {
        if (isIndividual) {
            return [
                [
                    categoryName,
                    ...data[0].dataSets[3].datas.map((x, i) => x ? `${x}% (${data[0].dataSets[0].datas[i]})` : '-'),
                ]
            ];
        } else {
            return data.map((item) => [
                item.label,
                ...item.dataSets[3].datas.map((x, i) => x ? `${x}% (${item.dataSets[0].datas[i]})` : '-'),
            ]);
        }
    }

    episodeWithClaimsTableMapper(data: any) {
        const header = ['', '', '',];
        for (let i = 0; i < 12; i++) {
            header.push('Active', 'Closed')
        }
        const body = data.map((item) => {
            return [
                item.category,
                item.subCategory,
                item.year,
                item.janActive,
                item.janClosed,
                item.febActive,
                item.febClosed,
                item.marActive,
                item.marClosed,
                item.aprActive,
                item.aprClosed,
                item.mayActive,
                item.mayClosed,
                item.junActive,
                item.junClosed,
                item.julActive,
                item.julClosed,
                item.augActive,
                item.augClosed,
                item.sepActive,
                item.sepClosed,
                item.octActive,
                item.octClosed,
                item.novActive,
                item.novClosed,
                item.decActive,
                item.decClosed,
            ];
        })
        return { header, body };
    }

    cycleTimesMapper(data: any) {
        const KPIS = [
            KPI_VALUES.HOLISTA_PAYMENT_CYCLE_TIMES,
            KPI_VALUES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY,
            KPI_VALUES.HOLISTA_PROCESSING_CYCLE_TIMES,
            KPI_VALUES.PAYOR_CYCLE_TIMES_COMPANY,
            KPI_VALUES.TOTAL_CYCLE_TIMES,
        ];
        return data.labels.map((label, index) => ([
            label,
            data.datasets[0].data[index],
            KPIS[index],
        ]))
    }

    lineChartLabelAndDataMapper(data: any) {
        return data.dataSets.map((x) => [
            x.label,
            ...x.datas.map((x) => x ? x : '-'),
        ])
    }

    triggerClaimCycleTimesMapper(data: any) {
        return data.label.map((item, index) => [
            item,
            data.average_days[index],
            data.goal
        ])
    }

    patientAgreementSignedMapper(data, categoryName?) {
        return [
            [
                categoryName,
                ...data.dataSets.find((dataset) => dataset.label === 'signed_on_time_percent').datas.map((x) => x ? `${x}%` : '-'),
            ]
        ];
    }

    portalEngagementDetailMapper(data: any) {
        return data.labels.map((label, index) => [
            label,
            `${data.dataSets[3].datas[index]}%`
        ]);
    }

    percentageBarChartLabelAndDataMapper(data: any) {
        return data.labels.map((label: string, index: number) => [
            label,
            data.percentage[index] ? `${data.percentage[index].toFixed(2)}%` : '-'
        ]);
    }
}
