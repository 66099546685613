import { Injectable } from "@angular/core";
@Injectable()
export class Sorting {
    public sortBy(arr: any, sortBy: string): any {
        arr = arr.sort(function (a: any, b: any) {
            if ((typeof a[sortBy] == 'string' ? a[sortBy].toLowerCase() : a[sortBy]) < (typeof b[sortBy] == 'string' ? b[sortBy].toLowerCase() : b[sortBy])) {
                return -1;
            } else if ((typeof a[sortBy] == 'string' ? a[sortBy].toLowerCase() : a[sortBy]) > (typeof b[sortBy] == 'string' ? b[sortBy].toLowerCase() : b[sortBy])) {
                return 1;
            } else {
                return 0;
            }
        });
        return arr;
    }

    public sortByDate(arr: any, sortBy: string, type: string = 'asc'): any {
        if (type === 'desc') {
            return arr.sort((a: any, b: any) => { return Date.parse(b[sortBy]) - Date.parse(a[sortBy]) });
        }
        return arr.sort((a: any, b: any) => { return Date.parse(a[sortBy]) - Date.parse(b[sortBy]) });
    }
}
