import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss']
})
export class PatientSearchComponent implements OnInit {
  @Input() advanceSearchForm: FormGroup;
  @Input() dateMask;
  @Input() validPatientDob;
  @Output() clearAdvanceSearch: EventEmitter<any> = new EventEmitter();
  @Output() searchMembers: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

}
