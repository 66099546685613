import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as Actions from '../../action';
import { AuthService, NotificationService, RolePermissionsService, UserService, ToasterService, CommunicationService, UnreadInboxCountService } from '../../services';
import { Router } from '@angular/router';
import { EXCLUDED_SUB_MODULES, REPORTING_CUSTOM_REDIRECT_PATH, ROLECONSTANTS } from '../../constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AccessType, Storage, HolistaUtils } from '../../utils';
import { COMMS, ROLE_CODES } from '../../constants';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  bread_crumbs: Observable<string>;
  header$;
  userMessages = [];
  sidenavStatus;
  accessTypeForCompany: any;
  accessTypeForUser: any;
  accessTypeForRoles: any;
  accessTypeForPathway: any;
  loggedInDatas;
  user: any;
  userDetails = [];
  notifications = [];
  messageCount: any;
  isMobile = false;
  otpToken: string;
  // imageDetails;
  episodes = [];
  menus: any = [];
  dontShowAgain: boolean = false;
  roleCodes = ROLE_CODES;
  excludedSubModules = EXCLUDED_SUB_MODULES

  @Output() emitMessageRead = new EventEmitter<any>();
  @ViewChild('sidenav', { static: true }) public sidenav;
  @ViewChild('disclaimerModal', { static: true }) public disclaimerModal;

  constructor(
    private store: Store<{ bread_crumbs: string; sidenav_status: string; user_message: any }>,
    public utilityAccess: AccessType,
    private auth: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private rolePermissionService: RolePermissionsService,
    private _storage: Storage,
    private userService: UserService,
    private toasterService: ToasterService,
    private holistaUtils: HolistaUtils,
    private _communicationService: CommunicationService,
    public unreadInboxCountService: UnreadInboxCountService
  ) {
    store.select('bread_crumbs').subscribe(res => {
      this.header$ = res;
    });
    store.select('sidenav_status').subscribe(res => {
      this.sidenavStatus = res[0];
    });
    store.select('user_message').subscribe(res => {
      res = JSON.parse(JSON.stringify(res));
      res.map(x => {
        x.messageId = x.lastMessage && x.lastMessage.id
        return x
      })
      this.userMessages = res.sort((a, b) => b.messageId - a.messageId);
    });
    const loggedInUser = this._storage.get('local', 'loggedInUser')
    this.user = loggedInUser && this._storage.get('local', 'loggedInUser', 'user');
    this.user && this.getSideBarMenu();
    this.getCommunicationsUnreadCount();
  }

  ngOnInit() {
    this.otpToken = this._storage.get('session', 'otpToken');
    if (this.user) {
      this.user.roles.map(r => r.name = ROLECONSTANTS.find(x => x.role == r.roleCode).roleName);
      this.user.displayRole = ROLECONSTANTS.find(x => x.role == this.user.roleCode).roleName;
      this.dontShowAgain = this.user.userSetting.disclaimerAck ? this.user.userSetting.disclaimerAck : false;
      this.notificationService.fetchNotification().subscribe((result: any) => {
        if (result.id) {
          if (result.id == this.user.id) {
            this.notifications = result.notifications
          }
        } else {
          this.notifications = result.notifications;
        }
      })
      this.isMobile = this.deviceService.isMobile()
      // this.companyService.getImage(this.isMobile).subscribe(res => {
      //   this.imageDetails = res.data;
      // });

      setTimeout(() => {
        if (this.user.roleCode === 'MP' && !this.user.userSetting.disclaimerAck)
          this.disclaimerModal.show()
      }, 5000);
    }
  }

  getSideBarMenu() {
    this.auth.getSideBarMenu(this.user.id).subscribe((res: any) => {
      if (res) {
        this.menus = res.groups
        this.menus.map((menu: any) => {
          if (this.user.roleCode == ROLE_CODES.REPORTING && Object.keys(REPORTING_CUSTOM_REDIRECT_PATH).includes(menu.name.toUpperCase())) {
            menu.redirectPath = REPORTING_CUSTOM_REDIRECT_PATH[menu.name.toUpperCase()]
          }
          menu.isExpanded = false
          menu.modules.forEach((module: any) => {
            module.isExpanded = false
          });
        })
      }
    }, (error => {
      console.log('Error', error)
    }))
  }

  markNotification(notification) {
    let route = ''
    if (notification.milestoneId) {
      route = `/episodes/${notification.episodeId}/milestone` + '?active=' + `${notification.milestoneId}`;
      if (notification.taskId) route = route + '&task=' + notification.taskId;
    } else route = `/episodes/${notification.episodeId}/milestone`;
    this.notificationService.updateNotification(notification.id).subscribe(result => {
      this.router.navigateByUrl('/episodes');
      setTimeout(() => {
        this.router.navigateByUrl(route);
      }, 1);
      this.notifications = result.notifications;
    });
  }

  episodeMilestone(notification) {
    this.router.navigateByUrl(`/episodes/${notification.episodeId}/milestone`)
  }

  hideSideNav() {
    if (this.deviceService.isMobile()) {
      this.sidenav.hide();
      this.toggleSideNav();
    }
  }

  toggleSideNav() {
    // if (this.deviceService.isMobile()) {
    //   this.sidenav.toggle();
    // } else {
    this.store.dispatch(new Actions.SetSidenavStatus(this.sidenavStatus == '' ? 'layoutExpand' : this.sidenavStatus == 'layoutCollapse' ? 'layoutExpand' : 'layoutCollapse'));
    // }
  }

  logout() {
    // this.auth.changeOnlineStatus(this.user.id, { isOnline: false })
    //   .subscribe((res) => {
    //   }, (error) => {
    //     console.log("Error changing online status", error);
    //   })
    this.auth.logout();
  }

  markMessageAsRead(user) {
    this.emitMessageRead.emit(user.uid)
  }

  showDisclaimerModal() {
    this.disclaimerModal.show();
  }

  hideDisclaimerModal() {
    this.disclaimerModal.hide();
    if (this.user && !this.user.userSetting.disclaimerAck && this.dontShowAgain) {
      this.userService.changeUserDisclaimerSetting(this.user.id, { disclaimerAck: true })
        .subscribe((res: any) => {
          if (res) {
            this.user.userSetting.disclaimerAck = res.disclaimerAck;
            let data = this._storage.get('local', 'loggedInUser')
            data.user.userSetting.disclaimerAck = res.disclaimerAck
            this._storage.set('local', 'loggedInUser', data)
            this.toasterService.displaySuccess('Disclaimer settings updated.');
          }
        }, (error) => {
          console.log('Error updating user disclaimer settings', error);
          this.toasterService.showError(error);
        })
    }
  }

  switchRole(role) {
    if (['CC', 'ES'].includes(role.roleCode) && !role.isActiveRole) {
      this.auth.switchRole().subscribe(async (res: any) => {
        res.communicationToken = this._storage.get('local', 'communicationToken')
        res.user = { ...res.user, ...this.holistaUtils.getUserRoleInfo(res.user.roles) }
        res.user.rolePermissions = await this.rolePermissionService.getRolePermission(res.user.roleCode).then((res: any) => {
          return res.rows
        })
        this._storage.set('local', 'loggedInUser', res)
        this._storage.clear('session')
        this.toasterService.displaySuccess('User role is switched successfully');
        window.location.reload();
      }, (error => {
        console.log('Error', error)
        this.toasterService.displayError('Something went wrong. Please try again');
      }))
    }
  }

  getCommunicationsUnreadCount() {
    this._communicationService.getInboxUnreadCount().subscribe(res => {
      this.unreadInboxCountService.count = res;
    });
  }

  navigateToProviderDirectory() {
    window.open(`${environment?.providerDirectoryUrl}`, '_blank');
  }

  generateSideBarDataTestId(componentName: string, path: string) {
    path = path.replace('/', '')
    return componentName + path
  }

  showMessageCount(code: string) {
    if (code === COMMS) {
      if (this.unreadInboxCountService.count > 0) {
        return true
      }
    }

    return false
  }

  toggleCollapsible(menu: any) {
    this.menus.map((menu: any) => {
      menu.modules.forEach((module: any) => {
        module.isExpanded = false
      });
    })
    if (menu?.subModules?.length) {
      menu.isExpanded = menu.isExpanded ? false : true
    }
  }
}
