import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ClaimsService, CommunicationService } from '../../../services';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../../action';
import { ToastrService } from "ngx-toastr";
import { MaskConstant, TEXTEDITOR, NOTE_TYPE, CLAIMS_EDITABLE_STATUSES, CLAIMS_REEDITABLE_STATUSES, SERVICE_LINE_VALIDATION_CONSTANTS, PRINCIPLE_DIAGNOSIS_QUALIFIER, PRINCIPLE_DIAGNOSIS, OUTGOING, OUTGOING_CLAIM_MANAGEMENT_PERMISSION_CODE, IS_EDITABLE } from '../../../constants';
import * as moment from 'moment'
import isEqual from 'lodash.isequal';
import { AccessType, ScrollTo, Storage, HolistaUtils } from "../../../utils";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ResizeEvent } from 'angular-resizable-element';
import { DatePipe } from '@angular/common';
import { CommunicationModuleOptions, IAddDiagnosisDetail } from '../../../models';

@Component({
    selector: 'claim-detail',
    templateUrl: './claim-detail.component.html',
    styles: [`.claim-details th 
    {
        background-color:rgb(238, 238, 238) ; width:20%
    }
    .claim-details td{
        width:30%;
        font-weight: bold;
    }

    .vertical-heading{
        background-color:rgb(238, 238, 238) ; width:20%
    }
    
    `]
})

export class ClaimDetailComponent implements OnInit {
    user: any;
    name: any;
    noteId = null;
    message = '';
    claimId: any;
    dateMask: any[] = MaskConstant.DATE;
    // dateRangeMask: any[] = MaskConstant.DATERANGE;
    loading = {
        claimsDetail: false,
        submitter: false,
        receiver: false,
        billingProvider_basic_info: false,
        subscriber_info: false,
        subscriber_patient_info: false,
        subscriber_payer_info: false,
        claims_basic_info: false,
        claims_date_info: false,
        notes: false
    }
    claimDetail: any = null;
    disableSubmitterUpdate = true;
    disableBillingProviderUpdate = true;
    disableReceiverUpdate = true;
    disableClaimUpdate = true;
    disableSubscriberPatientUpdate = true;
    disableSubscriberPayerUpdate = true;
    disableServiceLineUpdate = true;
    backupClaimDetail: any = null;
    edit = {
        submitter: false,
        receiver: false,
        billingProvider_basic_info: false,
        subscriber_info: false,
        subscriber_patient_info: false,
        subscriber_payer_info: false,
        claims_basic_info: false,
        claims_date_info: false,
        claimsReferenceInfo: false,
    };

    notes = [];
    editorConfig = TEXTEDITOR;
    notesstatus = false;
    noteForm: FormGroup;
    submit_enabled = false;
    public style: object = {};
    messageThread: Array<any> = [];
    isLoading: boolean = false;
    readonly COMMUNICATIONS: string = NOTE_TYPE.COMMUNICATIONS;
    showMessageModal: boolean = false;
    metaVariablesDetail: any;
    moduleOptions: CommunicationModuleOptions;
    claimsEditableStatuses = CLAIMS_EDITABLE_STATUSES
    claimsReeditableStatuses = CLAIMS_REEDITABLE_STATUSES

    @ViewChild('noteModal', { static: true }) public noteModal;
    @ViewChild('messageThreadModal', { static: true }) public messageThreadModal;

    constructor(private route: ActivatedRoute,
        private claimsService: ClaimsService,
        private store: Store<{ bread_crumbs: any }>,
        private toaster: ToastrService,
        public utilityAccess: AccessType,
        private formBuilder: FormBuilder,
        private _storage: Storage,
        private _scrollTo: ScrollTo,
        public datepipe: DatePipe,
        private holistaUtils: HolistaUtils,
        private _communicationService: CommunicationService
    ) {
        this.setNoteForm();
    }

    ngOnInit() {
        this.route.url.subscribe(url => {
            this.name = url[0].path == "outgoing-claims" ? 'Outgoing Claims' : 'Claims';
            const path = url[0].path == "outgoing-claims" ? '/outgoing-claims' : '/claims';
            this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
            this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: this.name, path: path }));
            this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Detail', path: '' }));
        })
        this.user = this._storage.get('local', 'loggedInUser', 'user');
        this.claimId = this.route.snapshot.paramMap.get("id");
        if (this.claimId)
            this.getClaimDetail();
    }

    setNoteForm() {
        this.noteForm = this.formBuilder.group({
            notes: ['', Validators.required],
            claimId: [null],
            claimIdentifier: [null],
            type: [null],
        })
    }

    healthCareDiagnosis: any[] = [
        { heading: 'Reason For Visit', data: [], type: 'reasonforVisit' },
        { heading: 'Other Diagnosis Information', data: [], type: 'otherDiagnosisInfo' },
        { heading: 'Condition Info', data: [], type: 'conditionInfo' },
        { heading: 'Principal Diagnosis', data: [], type: 'principalDiagnosis' },
        { heading: 'Admitting Diagnosis', data: [], type: 'admittingDiagnosis' },
        { heading: 'DRG Information', data: [], type: 'dRGInformation' },
        { heading: 'Occurrence Information', data: [], type: 'occurrenceInfo' },
        { heading: 'Other Procedure Info', data: [], type: 'otherProcedureInfo' },
        { heading: 'Principal Procedure Information', data: [], type: 'principalProcedureInfo' },
        { heading: 'Value Info', data: [], type: 'valueInfo' }
    ];
    backupHealthCareDiagnosis: any;

    getClaimDetail() {
        this.loading.claimsDetail = true;
        this.claimsService.getClaimsDetail(this.claimId)
            .pipe(finalize(() => { this.loading.claimsDetail = false; }))
            .subscribe((response: any) => {
                response.claimXRefHolista['claimComponents'] = response.claimXRefHolista.claimComponents ? response.claimXRefHolista.claimComponents.map(element => {
                    return element.bundleComponentName
                }).join(', ') : '';
                response.healthCareDiagnosis.map(x => {
                    this.mapHealthCareDiagnosis(x);
                });
                this.backupHealthCareDiagnosis = JSON.parse(JSON.stringify(this.healthCareDiagnosis));
                response.patient.subscriberDob = response.patient.subscriberDob ? moment(response.patient.subscriberDob).utc().format('MM-DD-YYYY') : null
                response.patient.patientDob = response.patient.patientDob ? moment(response.patient.patientDob).utc().format('MM-DD-YYYY') : null
                response.dischargeDate = response.dischargeDate ? (response.dischargeDate.length > 4 ? this.datepipe.transform(response.dischargeDate, 'MM/dd/yyyy') : response.dischargeDate.replace(/(.{2})$/, ':$1')) : ''
                this.claimDetail = JSON.parse(JSON.stringify(response));
                this.backupClaimDetail = JSON.parse(JSON.stringify(response));
            }, (error) => {
                console.log("Error getting Claims Detail", error);
            })
    }

    formatDOB(date) {
        return date ? moment(date).format('MM-DD-YYYY') : null;
    }
    onAccordionClick() {
        this.claimDetail = JSON.parse(JSON.stringify(this.backupClaimDetail))
        this.disableClaimUpdate = true;
    }

    mapHealthCareDiagnosis(x, action?) {
        let index = this.healthCareDiagnosis.findIndex(y => y.type.toLowerCase() == x.diagnosisType.toLowerCase());
        if (index > -1) {
            if (action) {
                let index2 = this.healthCareDiagnosis[index].data.findIndex(z => z.id == x.id)
                if (index2 > -1)
                    this.healthCareDiagnosis[index].data[index2] = x;
                else
                    this.healthCareDiagnosis[index].data.push(x);
            }
            else
                this.healthCareDiagnosis[index].data.push(x);
        }
        this.backupHealthCareDiagnosis = JSON.parse(JSON.stringify(this.healthCareDiagnosis));
    }

    billingProviderChange() {
        this.disableBillingProviderUpdate = isEqual(this.claimDetail.transaction.billingProvider, this.backupClaimDetail.transaction.billingProvider)
    }
    submitterChange() {
        this.disableSubmitterUpdate = isEqual(this.claimDetail.transaction.submitter, this.backupClaimDetail.transaction.submitter)
    }
    receiverChange() {
        this.disableReceiverUpdate = isEqual(this.claimDetail.transaction.receiver, this.backupClaimDetail.transaction.receiver)
    }
    claimChange(type) {
        const claimClone = JSON.parse(JSON.stringify(this.claimDetail));
        const claimCloneDup = JSON.parse(JSON.stringify(this.backupClaimDetail));
        claimClone.totalClaimChargeAmount = Number(claimClone.totalClaimChargeAmount)
        claimCloneDup.totalClaimChargeAmount = Number(claimCloneDup.totalClaimChargeAmount)
        this.disableClaimUpdate = isEqual(claimClone, claimCloneDup);
    }
    subscriberPatientChange() {
        this.disableSubscriberPatientUpdate = isEqual(this.claimDetail.patient, this.backupClaimDetail.patient)
    }
    subscriberPayerChange() {
        this.disableSubscriberPayerUpdate = isEqual(this.claimDetail.patient.payer, this.backupClaimDetail.patient.payer)
    }
    serviceLineChange(e) {
        let index = this.claimDetail.serviceLines.findIndex(x => x.id == e.id)
        const claimClone = JSON.parse(JSON.stringify(this.claimDetail.serviceLines[index]));
        delete claimClone.service_line_info
        const claimCloneDup = JSON.parse(JSON.stringify(this.backupClaimDetail.serviceLines[index]));
        claimCloneDup.lineItemChargeAmount = Number(claimCloneDup.lineItemChargeAmount)
        claimClone.lineItemChargeAmount = Number(claimClone.lineItemChargeAmount)
        this.disableServiceLineUpdate = isEqual(claimClone, claimCloneDup)
    }

    update(name, additionalData?) {
        switch (name) {
            case 'submitter':
                const { businessName, contactName, contactNumber1, identificationCode: submitterIdentificationCode } = this.claimDetail.transaction.submitter;
                if (businessName && contactName && contactNumber1 && submitterIdentificationCode.length >= 2) {
                    this.loading.submitter = true;
                    const props = ['businessName', 'contactName', 'contactNumber1', 'identificationCode'];
                    props.forEach(element => {
                        this.claimDetail.transaction.submitter[element] = this.claimDetail.transaction.submitter[element].trim();
                    });
                    this.claimDetail.transaction.submitter.claimId = this.claimDetail.id
                    this.claimsService.updateSubmitterReceiver(this.claimDetail.transaction.submitter, this.claimDetail.ediTransactionId || this.claimDetail.edi_transaction_id, this.claimDetail.patientAccountNumber)
                        .pipe(finalize(() => this.loading.submitter = false))
                        .subscribe((res: any) => {
                            if (res) {
                                this.claimDetail.transaction.submitter = JSON.parse(JSON.stringify(res.submitterReceiver));
                                this.claimDetail.frequencyCode = res.frequencyCode || this.claimDetail.frequencyCode;
                                this.backupClaimDetail.transaction.submitter = JSON.parse(JSON.stringify(res.submitterReceiver));
                                this.edit.submitter = false;
                                this.disableSubmitterUpdate = true;
                                this.toaster.success("Updating successful", "Success");
                            }
                        }, (error) => {
                            console.log('Error updating submitter basic information', error);
                            this.toaster.error(`${error.error.message}`, "Error");
                        })
                }
                break;
            case 'receiver':
                const { businessName: receiverBusinessName, identificationCode: receiverIdentificationCode } = this.claimDetail.transaction.receiver;
                if (receiverBusinessName && receiverIdentificationCode.length >= 2) {
                    this.loading.receiver = true;
                    const props = ['businessName', 'identificationCode'];
                    props.forEach(element => {
                        this.claimDetail.transaction.receiver[element] = this.claimDetail.transaction.receiver[element].trim();
                    });
                    this.claimDetail.transaction.receiver.claimId = this.claimDetail.id
                    this.claimsService.updateSubmitterReceiver(this.claimDetail.transaction.receiver, this.claimDetail.ediTransactionId, this.claimDetail.patientAccountNumber)
                        .pipe(finalize(() => this.loading.receiver = false))
                        .subscribe((res: any) => {
                            if (res) {
                                this.claimDetail.transaction.receiver = JSON.parse(JSON.stringify(res.submitterReceiver));
                                this.claimDetail.frequencyCode = res.frequencyCode || this.claimDetail.frequencyCode;
                                this.backupClaimDetail.transaction.receiver = JSON.parse(JSON.stringify(res.submitterReceiver));
                                this.edit.receiver = false;
                                this.disableReceiverUpdate = true;
                                this.toaster.success("Updating successful", "Success");
                            }
                        }, (error) => {
                            console.log('Error updating receiver basic information', error);
                            this.toaster.error(`${error.error.message}`, "Error");
                        })
                }
                break;
            case 'billingProvider_basic_info':
                const { businessName: bsName, addressLine1, city, state, zipCode, identificationCode: idCode } = this.claimDetail.transaction.billingProvider.basicInfo;
                if (this.claimDetail.transaction.billingProvider.taxIdentificationNumber && bsName && addressLine1 && city.length >= 2 && state && zipCode && idCode) {
                    this.loading.billingProvider_basic_info = true;
                    const props = ['businessName', 'addressLine1', 'city', 'state', 'zipCode', 'identificationCode', 'addressLine2'];
                    const props2 = ['taxIdentificationNumber', 'contactName', 'contactNumber1'];
                    props.forEach(element => {
                        this.claimDetail.transaction.billingProvider.basicInfo[element] = this.claimDetail.transaction.billingProvider.basicInfo[element].trim();
                    });
                    props2.forEach(element => {
                        this.claimDetail.transaction.billingProvider[element] = this.claimDetail.transaction.billingProvider[element].trim();
                    });
                    let body = JSON.parse(JSON.stringify(this.claimDetail.transaction.billingProvider.basicInfo));
                    body['billingProvider'] = JSON.parse(JSON.stringify(this.claimDetail.transaction.billingProvider));
                    delete body.billingProvider.basicInfo;
                    body.claimId = this.claimDetail.id
                    this.claimsService.updateProvider(body, this.claimDetail.ediTransactionId, this.claimDetail.patientAccountNumber)
                        .pipe(finalize(() => this.loading.billingProvider_basic_info = false))
                        .subscribe((res: any) => {
                            if (res) {
                                this.claimDetail.transaction.billingProvider = JSON.parse(JSON.stringify(res.billingProvider));
                                this.claimDetail.transaction.billingProvider.basicInfo = JSON.parse(JSON.stringify(res.updatedProvider));
                                this.claimDetail.frequencyCode = res.frequencyCode || this.claimDetail.frequencyCode;
                                this.disableBillingProviderUpdate = true;
                                this.backupClaimDetail.transaction.billingProvider = JSON.parse(JSON.stringify(res.billingProvider));
                                this.backupClaimDetail.transaction.billingProvider.basicInfo = JSON.parse(JSON.stringify(res.updatedProvider));
                                this.edit.billingProvider_basic_info = false;
                                this.toaster.success("Updating successful", "Success");
                            }
                        }, (error) => {
                            console.log('Error updating billing provider basic information', error);
                            this.toaster.error(`${error.error.message}`, "Error");
                        })
                }
                break;
            case 'subscriber_info':
            case 'subscriber_patient_info':
                const { relationshipCode, subscriberIdentificationCode, subscriberAddressLine1, subscriberCity, subscriberState, subscriberZip, subscriberFirstName, subscriberDob, subscriberGender, subscriberLastName } = this.claimDetail.patient
                const { patientIndividualRelationshipCode, patientAddressLine1, patientCity, patientState, patientZip, patientFirstName, patientDob, patientGender, patientLastName } = this.claimDetail.patient;
                if ((name == 'subscriber_info' && relationshipCode && subscriberIdentificationCode && subscriberAddressLine1 && subscriberCity.length >= 2 && subscriberState && subscriberZip && subscriberFirstName && subscriberDob && subscriberGender && subscriberLastName)
                    || (!relationshipCode && patientIndividualRelationshipCode && patientAddressLine1 && patientCity.length >= 2 && patientState && patientZip && patientFirstName && patientDob && patientGender && patientLastName && subscriberIdentificationCode && subscriberFirstName && subscriberLastName)) {
                    name == 'subscriber_info' ? this.loading.subscriber_info = true : this.loading.subscriber_patient_info = true;
                    const props = ['subscriberIdentificationCode', 'insuredPolicyNumber', 'subscriberAddressLine1', 'subscriberAddressLine2', 'subscriberCity', 'subscriberState', 'subscriberZip', 'subscriberFirstName', 'subscriberGender', 'subscriberMiddleName', 'subscriberLastName', 'patientIndividualRelationshipCode', 'patientAddressLine1', 'patientAddressLine2', 'patientCity', 'patientState', 'patientZip', 'patientFirstName', 'patientMiddleName', 'patientGender', 'patientLastName'];
                    props.forEach(element => {
                        this.claimDetail.patient[element] = this.claimDetail.patient[element].trim();
                    });
                    let body = JSON.parse(JSON.stringify(this.claimDetail.patient));
                    body.subscriberDob = body.subscriberDob
                    body.patientDob = body.patientDob
                    body.claimId = this.claimDetail.id
                    this.claimsService.updateSubscriberPatientInfo(body, this.claimDetail.patientAccountNumber)
                        .pipe(finalize(() => name == 'subscriber_info' ? this.loading.subscriber_info = false : this.loading.subscriber_patient_info = false))
                        .subscribe((res: any) => {
                            if (res) {
                                const payer = JSON.parse(JSON.stringify(this.claimDetail.patient.payer));
                                res.updatedPatient.subscriberDob = this.formatDOB(res.updatedPatient.subscriberDob);
                                res.updatedPatient.patientDob = this.formatDOB(res.updatedPatient.patientDob);
                                this.claimDetail.patient = JSON.parse(JSON.stringify(res.updatedPatient));
                                this.claimDetail.patient.payer = payer;
                                this.disableSubscriberPatientUpdate = true;
                                this.claimDetail.frequencyCode = res.frequencyCode || this.claimDetail.frequencyCode;
                                this.backupClaimDetail.patient = JSON.parse(JSON.stringify(res.updatedPatient));
                                this.backupClaimDetail.patient.payer = payer;
                                name == 'subscriber_info' ? this.edit.subscriber_info = false : this.edit.subscriber_patient_info = false;
                                this.toaster.success("Updating successful", "Success");
                            }
                        }, (error) => {
                            console.log('Error updating patient information', error);
                            this.toaster.error(`${error.error.message}`, "Error");
                        })
                }
                break;
            case 'subscriber_payer_info':
                const { identificationCode: payerIdentificationCode, businessName: payerBusinessName, addressLine1: payerAddLine, city: payerCity, state: payerState, zipCode: payerZipCode } = this.claimDetail.patient.payer
                if (payerIdentificationCode.length >= 2 && payerBusinessName && payerAddLine && payerCity.length >= 2 && payerState && payerZipCode) {
                    this.loading.subscriber_payer_info = true;
                    const props = ['identificationCode', 'businessName', 'city', 'state', 'zipCode', 'addressLine1', 'addressLine2'];
                    props.forEach(element => {
                        this.claimDetail.patient.payer[element] = this.claimDetail.patient.payer[element].trim();
                    });
                    this.claimDetail.patient.payer.claimId = this.claimDetail.id
                    this.claimsService.updateProvider(this.claimDetail.patient.payer, this.claimDetail.ediTransactionId, this.claimDetail.patientAccountNumber)
                        .pipe(finalize(() => this.loading.subscriber_payer_info = false))
                        .subscribe((res: any) => {
                            if (res) {
                                this.claimDetail.patient.payer = JSON.parse(JSON.stringify(res.provider));
                                this.claimDetail.frequencyCode = res.frequencyCode || this.claimDetail.frequencyCode;
                                this.backupClaimDetail.patient.payer = JSON.parse(JSON.stringify(res.provider));
                                this.edit.subscriber_payer_info = false;
                                this.disableSubscriberPayerUpdate = true;
                                this.toaster.success("Updating successful", "Success");
                            }
                        }, (error) => {
                            console.log('Error updating payer information', error);
                            this.toaster.error(`${error.error.message}`, "Error");
                        })
                }
                break;
            case 'claims_basic_info':
            case 'claimsReferenceInfo':
            case 'claims_date_info':
                // this.claimDetail.statementDate = this.claimDetail.statementDate && this.claimDetail.statementDate.includes('/') ? this.claimDetail.statementDate.replace(/[^0-9\-.]+/g, "") : this.claimDetail.statementDate;
                const { totalClaimChargeAmount, facilityTypeCode, frequencyCode, releaseInformationCode, medicareAssignmentCode, benefitsAssignmentCertificationIndicator, statementDate, patientAccountNumber, originalReferenceNumber } = this.claimDetail;
                if ((name == 'claims_basic_info' && totalClaimChargeAmount && facilityTypeCode && frequencyCode && medicareAssignmentCode && benefitsAssignmentCertificationIndicator && releaseInformationCode && patientAccountNumber)
                    || (name == 'claims_date_info' && statementDate.length == 17) || (name == 'claimsReferenceInfo' && (CLAIMS_REEDITABLE_STATUSES.includes(this.claimDetail.claimXRefHolista.processingStatus) ? originalReferenceNumber : true))) {
                    name == 'claims_basic_info' ? this.loading.claims_basic_info = true : this.loading.claims_date_info = true;
                    const props = ['facilityTypeCode', 'frequencyCode', 'releaseInformationCode', 'medicareAssignmentCode', 'benefitsAssignmentCertificationIndicator', 'statementDate'];
                    props.forEach(element => {
                        this.claimDetail[element] = this.claimDetail[element].trim();
                    });
                    this.claimDetail.dischargeDate = this.claimDetail.dischargeDate ? this.claimDetail.dischargeDate.replace(':', '') : null
                    this.claimsService.updateClaimsInfo(this.claimDetail)
                        .pipe(finalize(() => name == 'claims_basic_info' ? this.loading.claims_basic_info = false : this.loading.claims_date_info = false))
                        .subscribe((res: any) => {
                            if (res) {
                                const attendingPhysician = JSON.parse(JSON.stringify(this.claimDetail.attendingPhysician));
                                const claimXRefHolista = JSON.parse(JSON.stringify(this.claimDetail.claimXRefHolista));
                                const healthCareDiagnosis = JSON.parse(JSON.stringify(this.claimDetail.healthCareDiagnosis));
                                const operatingPhysician = JSON.parse(JSON.stringify(this.claimDetail.operatingPhysician));
                                const patient = JSON.parse(JSON.stringify(this.claimDetail.patient));
                                const serviceLines = JSON.parse(JSON.stringify(this.claimDetail.serviceLines));
                                const transaction = JSON.parse(JSON.stringify(this.claimDetail.transaction));
                                const referringProvider = this.claimDetail.referringProvider ? JSON.parse(JSON.stringify(this.claimDetail.referringProvider)) : null;
                                const renderingProvider = this.claimDetail.renderingProvider ? JSON.parse(JSON.stringify(this.claimDetail.renderingProvider)) : null;
                                const serviceFacilityLocation = this.claimDetail.serviceFacilityLocation ? JSON.parse(JSON.stringify(this.claimDetail.serviceFacilityLocation)) : null;
                                this.disableClaimUpdate = true;
                                this.claimDetail = JSON.parse(JSON.stringify(res));
                                this.claimDetail.attendingPhysician = attendingPhysician;
                                this.claimDetail.claimXRefHolista = claimXRefHolista;
                                this.claimDetail.healthCareDiagnosis = healthCareDiagnosis;
                                this.claimDetail.operatingPhysician = operatingPhysician;
                                this.claimDetail.patient = patient;
                                this.claimDetail.serviceLines = serviceLines;
                                this.claimDetail.transaction = transaction;
                                this.claimDetail.referringProvider = referringProvider;
                                this.claimDetail.renderingProvider = renderingProvider;
                                this.claimDetail.serviceFacilityLocation = serviceFacilityLocation;
                                this.claimDetail.dischargeDate = this.claimDetail.dischargeDate ? (this.claimDetail.dischargeDate.length > 4 ? this.datepipe.transform(this.claimDetail.dischargeDate, 'MM/dd/yyyy') : this.claimDetail.dischargeDate.replace(/(.{2})$/, ':$1')) : ''
                                this.backupClaimDetail = JSON.parse(JSON.stringify(this.claimDetail));
                                name == 'claims_basic_info' ? this.edit.claims_basic_info = false : name == 'claimsReferenceInfo' ? this.edit.claimsReferenceInfo = false : this.edit.claims_date_info = false;
                                this.toaster.success("Updating successful", "Success");
                            }
                        }, (error) => {
                            console.log('Error updating claims information', error);
                            this.toaster.error(`${error.error.message}`, "Error");
                        })
                }
                break;
            case 'claims_health_care':
                additionalData.diagnosisCode = additionalData.diagnosisCode.trim();
                if (!additionalData.diagnosisCode) {
                    additionalData.diagnosisQualifier = '';
                }

                if (additionalData.diagnosisCode && !additionalData.diagnosisQualifier) {
                    additionalData.diagnosisQualifier = PRINCIPLE_DIAGNOSIS_QUALIFIER;
                }

                this.claimsService.updateHealthCareDiagnosis(this.claimDetail.id, additionalData, this.claimDetail.patientAccountNumber)
                    .subscribe((res: any) => {
                        if (res) {
                            this.mapHealthCareDiagnosis(res.healthCareDiagnosis, 'updated');
                            this.claimDetail.frequencyCode = res.frequencyCode || this.claimDetail.frequencyCode;
                            this.disableClaimUpdate = true;
                            this.toaster.success("Updating successful", "Success");
                        }
                    }, (error) => {
                        console.log('Error updating heath care diagnosis', error);
                        this.toaster.error(`${error.error.message}`, "Error");
                    })
                break;
            case 'service_line_info':
                let index = this.claimDetail.serviceLines.findIndex(x => x.id == additionalData)
                if (index > -1) {
                    if (this.hasValidServiceLineValues(this.claimDetail.serviceLines[index])) {
                        const props = ['procedureCode', 'institutionalServiceRevenueCode', 'serviceDate'];
                        props.forEach(element => {
                            this.claimDetail.serviceLines[index][element] = this.claimDetail.serviceLines[index][element].trim();
                        });
                        this.claimsService.updateServiceLineInfo(this.claimDetail.id, this.claimDetail.serviceLines[index], this.claimDetail.patientAccountNumber)
                            .subscribe((res: any) => {
                                if (res) {
                                    index = this.claimDetail.serviceLines.findIndex(x => x.id == res.serviceLine.id)
                                    if (index > -1) {
                                        this.claimDetail.serviceLines[index] = JSON.parse(JSON.stringify(res.serviceLine));
                                        this.claimDetail.serviceLines[index].service_line_info = false;
                                    }
                                    index = this.backupClaimDetail.serviceLines.findIndex(x => x.id == res.serviceLine.id)
                                    this.claimDetail.frequencyCode = res.frequencyCode || this.claimDetail.frequencyCode;
                                    if (index > -1)
                                        this.backupClaimDetail.serviceLines[index] = JSON.parse(JSON.stringify(res.serviceLine));
                                    this.disableServiceLineUpdate = true;
                                    this.toaster.success("Updating successful", "Success");
                                }
                            }, (error) => {
                                console.log('Error updating service line information', error);
                                this.toaster.error(`${error.error.message}`, "Error");
                            })
                    }
                }
                break;
            default:
                break;
        }
    }

    hasValidInstitutionalServiceRevenueCode(element) {
        return element.institutionalServiceRevenueCode && element.institutionalServiceRevenueCode.length != SERVICE_LINE_VALIDATION_CONSTANTS.REVENUE_CODE_LENGTH
    }

    hasValidServiceLineValues(serviceLine) {
        const { procedureCode, lineItemChargeAmount, institutionalServiceRevenueCode, serviceDate, serviceDateTimeFormatQualifer } = serviceLine;
        const { PROCEDURE_CODE_LENGTH, REVENUE_CODE_LENGTH, RD8_DATE_FORMAT, RD8_FORMAT_DATE_LENGTH, NON_EDI_DATE_LENGTH } = SERVICE_LINE_VALIDATION_CONSTANTS
        if (procedureCode && procedureCode.length !== PROCEDURE_CODE_LENGTH) {
            return false;
        }

        if (!lineItemChargeAmount) {
            return false;
        }

        if (institutionalServiceRevenueCode && institutionalServiceRevenueCode.length !== REVENUE_CODE_LENGTH) {
            return false
        }

        if (serviceDateTimeFormatQualifer == RD8_DATE_FORMAT) {
            if (serviceDate.length !== RD8_FORMAT_DATE_LENGTH) {
                return false;
            }
            return true
        }
        if (serviceDate.length !== NON_EDI_DATE_LENGTH) {
            return false
        }

        return true;
    }

    cancel(name) {
        switch (name) {
            case 'submitter':
                this.claimDetail.transaction.submitter = JSON.parse(JSON.stringify(this.backupClaimDetail.transaction.submitter));
                this.disableSubmitterUpdate = true;
                break;
            case 'receiver':
                this.claimDetail.transaction.receiver = JSON.parse(JSON.stringify(this.backupClaimDetail.transaction.receiver));
                this.disableReceiverUpdate = true;
                break;
            case 'billingProvider_basic_info':
                this.claimDetail.transaction.billingProvider = JSON.parse(JSON.stringify(this.backupClaimDetail.transaction.billingProvider));
                this.disableBillingProviderUpdate = true;
                break;
            case 'subscriber_info':
            case 'subscriber_patient_info':
                this.disableSubscriberPatientUpdate = true;
            case 'subscriber_payer_info':
                this.claimDetail.patient = JSON.parse(JSON.stringify(this.backupClaimDetail.patient));
                this.disableSubscriberPayerUpdate = true;
                break;
            case 'claims_basic_info':
            case 'claims_date_info':
            case 'claimsReferenceInfo':
                this.claimDetail = JSON.parse(JSON.stringify(this.backupClaimDetail));
                this.disableClaimUpdate = true;
                break;
            case 'claims_health_care':
                this.disableClaimUpdate = true;
                this.healthCareDiagnosis = JSON.parse(JSON.stringify(this.backupHealthCareDiagnosis));
            case 'service_line_info':
                this.claimDetail.serviceLines = JSON.parse(JSON.stringify(this.backupClaimDetail.serviceLines));
                this.disableServiceLineUpdate = true;
                break;
            default:
                break;
        }
    }

    OpenNotes() {
        this.getNotes();
        this.noteModal.show();
    }

    closeNoteModal(status) {
        if (status == 'close')
            this.noteModal.hide();
        this.notesstatus = false;
        this.setNoteForm();
    }

    getNotes() {
        this.loading.notes = true;
        this.claimsService.getClaimNotes(this.claimId)
            .pipe(finalize(() => this.loading.notes = false))
            .subscribe(res => {
                if (res.length != 0) {
                    this.notes = res.map(note => {
                        note.user.fullName = this.holistaUtils.getFullName(note.user)
                        return note
                    })
                }
                // this.ScrollTo('newNote', 'center', 'smooth')
            }, (error) => {
                console.log('Error', error)
            })
    }

    saveNote() {
        this.noteForm.controls.claimId.setValue(this.claimId);
        this.noteForm.controls.claimIdentifier.setValue(this.claimDetail.patientAccountNumber);
        if (this.noteForm.valid) {
            this.submit_enabled = true;
            this.claimsService.createNotes(this.noteForm.value)
                .pipe(finalize(() => this.submit_enabled = false))
                .subscribe(res => {
                    if (res) {
                        res.user.fullName = this.holistaUtils.getFullName(res.user)
                        this.notes.push(res);
                        this.ScrollTo(`${res.id}-newNote`, 'center', 'smooth');
                        this.toaster.success('Note added successfully.', 'Success');
                    }
                },
                    error => {
                        console.log('Error adding note', error);
                        this.toaster.error(`${error.error.message}`, 'Error');
                    }, () => {
                        this.closeNoteModal(null);
                    })
        }
    }

    deleteNote(note) {
        this.noteId = note.id;
        this.message = `Are you sure you want to delete <strong>note</strong>? This is irreversible.
        If you're not sure, you can click <strong>Cancel</strong>.`
    }

    deleteTriggered(id) {
        if (id) {
            this.loading.notes = true;
            this.claimsService.deleteNote(id)
                .pipe(finalize(() => { this.loading.notes = false; }))
                .subscribe((response: any) => {
                    if (response) {
                        let index = this.notes.findIndex(x => x.id == response.id);
                        if (index >= 0) {
                            this.notes.splice(index, 1);
                            this.toaster.success(`Note deleted successfully.`, 'Success');
                            this.noteId = null;
                        }
                    }
                }, (error) => {
                    console.log("Error deleting note", error);
                    this.toaster.error(`Error deleting note`, 'Error');
                    this.noteId = null;
                })
        }
        else this.noteId = null;
    }

    onFocusOutEvent(i, modifierName) {
        if (modifierName == 'mod1' && !this.claimDetail.serviceLines[i].modifier1) {
            this.claimDetail.serviceLines[i].modifier2 = '';
            this.claimDetail.serviceLines[i].modifier3 = '';
            this.claimDetail.serviceLines[i].modifier4 = '';
        }
        if (modifierName == 'mod2' && !this.claimDetail.serviceLines[i].modifier2) {
            this.claimDetail.serviceLines[i].modifier3 = '';
            this.claimDetail.serviceLines[i].modifier4 = '';
        }
        if (modifierName == 'mod3' && !this.claimDetail.serviceLines[i].modifier3) {
            this.claimDetail.serviceLines[i].modifier4 = '';
        }
    }

    shouldAddDiagnosis(healthDiagnosisDetail: IAddDiagnosisDetail) {
        const { healthDiagnosis, claimsEditableStatuses, claimDetail } = healthDiagnosisDetail

        if (healthDiagnosis.edit) {
            return false;
        }

        if (healthDiagnosis.diagnosisCode) {
            return false;
        }

        if (healthDiagnosis.diagnosisType !== PRINCIPLE_DIAGNOSIS) {
            return false;
        }

        if (!claimsEditableStatuses[claimDetail.type.toLowerCase()].includes(
            claimDetail?.claimXRefHolista?.processingStatus?.toUpperCase()
        )) {
            return false;
        }

        if (claimDetail.type.toLowerCase() !== OUTGOING) {
            return false;
        }

        if (!this.utilityAccess.searchAccess(OUTGOING_CLAIM_MANAGEMENT_PERMISSION_CODE, IS_EDITABLE, false)) {
            return false;
        }

        return true;
    }

    ScrollTo(id, placement, transition) {
        if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
            setTimeout(() => {
                this._scrollTo.ScrollTo(id, placement, transition)
            }, 200);
        }
    }

    validate(event: ResizeEvent): boolean {
        const MIN_DIMENSIONS_PX: number = 400;
        if (event.rectangle.width && event.rectangle.width < MIN_DIMENSIONS_PX) {
            return false;
        }
        return true;
    }

    onResizeEnd(event: ResizeEvent): void {
        this.style = {
            width: `${event.rectangle.width}px`
        };
    }

    onSendEmail() {
        this.metaVariablesDetail = { detail: this.claimDetail, module: 'claim' };
        this.moduleOptions = {
            module: "claims",
            moduleId: this.claimDetail.id.toString(),
            moduleIdentifier: this.claimDetail.patientAccountNumber,
            type: 'communications'
        }
        this.showMessageModal = true;
    }

    closeMessageModal() {
        this.showMessageModal = false;
        this.messageThreadModal.hide();
    }

    /**
     * opens modal that displays the related message thread of the note event selected
     * @param id 
     */
    onNoteSelected(id: number) {
        this.isLoading = true;
        id ? this._communicationService.getMessageThread(id, { moduleId: this.claimDetail.id }).pipe(
            finalize(() => {
                this.isLoading = false;
            })
        ).subscribe(res => {
            if (res.rows.length) {
                const messageDate = res.rows[0].createdAt.split('T')[0];
                const todaysDate = moment().format('YYYY-MM-DD');
                messageDate === todaysDate ? (
                    this.messageThread = res.rows.map((x: object) => ({ ...x, extendedDate: moment(x['createdAt']).calendar() }))
                ) : (
                    this.messageThread = res.rows.map((x: object) => ({ ...x, extendedDate: moment(x['createdAt']).format('llll') }))
                )
            }
        }) : (
            this.messageThread.length = 0,
            this.isLoading = false
        );
        !this.noteId && this.messageThreadModal.show();
    }
}