import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { CacheService } from "../services";
@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    blockCaching = ['milestones', 'pathway'];

    constructor(private cache: CacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (this.isCacheable(req)) {
            let cachedResponse = this.cache.get(req.url);
            return cachedResponse ? of(cachedResponse) : this.sendNewRequest(req, next)
        }
        else {
            return next.handle(req);
        }
    }

    isCacheable(req) {
        // second condition added for not caching the milestones.
        const blockCacheFound = this.blockCaching.find(x => req.url.includes(x))
        return req.method === 'GET' && !blockCacheFound ? true : false;
    }

    sendNewRequest(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            tap(event => this.cache.set(req.url, event, new Date())))
    }
}