import { Component, OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
@Component({
  selector: 'app-mobile-activation',
  templateUrl: './mobile-activation.component.html',
  styleUrls: ['./mobile-activation.component.scss']
})
export class MobileActivationComponent implements OnInit {
  appStoreLink;
  playStoreLink;
  constructor() { }

  ngOnInit() {
    this.appStoreLink = environment.appStoreLink;
    this.playStoreLink = environment.playStoreLink;
  }

}
