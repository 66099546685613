import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RESEND_OTP_CODE_SUCCESS, OTP_LENGTH, COPYRIGHT_TEXT, COMMUNICATIONS } from '../../../constants';
import { CommunicationService, ToasterService } from '../../../services';
import { Storage } from '../../../utils';
import { NonPlatformUser } from '../../../models';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  errorMessage: string;
  user: NonPlatformUser;
  encodedEmail: string;
  copyrightText: string = COPYRIGHT_TEXT;
  prevOtpValue: string;
  isProcessing: boolean = false;
  readonly COMMUNICATIONS = COMMUNICATIONS;

  @ViewChild("ngOtpInput", { static: false }) ngOtpInput; config = {
    allowNumbersOnly: true,
    length: 6,
  };

  constructor(
    private _communicationService: CommunicationService,
    private _storage: Storage,
    private _toastr: ToasterService,
    private _router: Router,
  ) {
  }

  ngOnInit() {
    this.user = this._storage.get('session', 'user');
    this.getEncodedEmail();
  }

  ngOnDestroy() {
    this._storage.remove('session', 'generateOtpToken');
  }

  getEncodedEmail() {
    const email = this.user.email;
    const username = email.slice(0, 2);
    const domain = email.substring(email.indexOf('.'));
    this.encodedEmail = username + '****' + '@' + '**********' + domain;
  }

  onOtpCodeChange(otpCode: string) {
    if (otpCode.length === OTP_LENGTH) {
      if (this.prevOtpValue !== otpCode) {
        this.onVerifyOtpCode(otpCode)
        this.prevOtpValue = otpCode;
      }
    }
  }

  onVerifyOtpCode(code: string) {
    this.isProcessing = true;
    const body = {
      email: this.user.email,
      otpCode: code,
      token: this._storage.get('session', 'generateOtpToken')
    }
    this._communicationService.verifyOtp(body).subscribe(res => {
      this.isProcessing = false;
      this.errorMessage = null;
      this._storage.set('session', 'otpToken', res);
      this._communicationService.getCommunicationToken({ email: this.user.email }).subscribe(res => {
        const communicationToken = res;
        this._storage.set('session', 'communicationToken', communicationToken);
        this._router.navigateByUrl(`/${COMMUNICATIONS}`);
      }
      )
    }, (error) => {
      this.isProcessing = false;
      this.errorMessage = error.error.message;
    });
  }

  onResendOtpCode() {
    this.prevOtpValue = null;
    this.ngOtpInput.otpForm.reset();
    this.errorMessage = null;
    const body = { email: this.user.email }
    this._communicationService.generateOtp(body).subscribe(res => {
      this._storage.set('session', 'generateOtpToken', res);
    });
    this._toastr.displaySuccess(RESEND_OTP_CODE_SUCCESS);
  }
}
