import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { CommunicationService } from '../../../services';
import { Storage } from '../../../utils';
import { INBOX, OUTBOX } from '../../../constants';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit {
  @Input() type: string;
  @Input() sentMessageId: number;
  @Output() messageSelect: EventEmitter<any> = new EventEmitter();

  inboxMessageList: Array<any> = [];
  outboxMessageList: Array<any> = [];
  isLoading: boolean = false;
  totalCountMessages: number;
  readonly INBOX: string = INBOX;
  queryParams = {
    limit: 10,
    offset: 0
  }

  constructor(
    private _communicationService: CommunicationService,
  ) { }

  ngOnInit() {
    this.getMessageList();
  }

  /**
   * Fetch messages for related tab, shows time for messages generated today and date for rest
   */
  getMessageList(isReload?: boolean, type?: string) {
    this.isLoading = true;
    if (type === 'next') {
      this.queryParams.offset += this.queryParams.limit
    } else if (type === 'prev') {
      this.queryParams.offset -= this.queryParams.limit
    }
    isReload && (this.queryParams.offset = 0);
    const todaysDate = moment().format('YYYY-MM-DD');
    const route = this.type === INBOX ? INBOX : OUTBOX;
    this._communicationService.getMessageList(route, this.queryParams).subscribe(res => {
      this.isLoading = false;
      this.totalCountMessages = res.count;
      const response = res.rows;
      const messageList = response.map(x => {
        const message = x;
        message.latestMessage.extendedDate = x.latestMessage.createdAt.split('T')[0] === todaysDate && moment(x.latestMessage.createdAt).format('LT');
        return message;
      })
      this.type === INBOX ? this.inboxMessageList = messageList : this.outboxMessageList = messageList;
    });
  }
}