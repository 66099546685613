<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center mt-4 action position-sticky">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group has-search input-group mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          [(ngModel)]="query.keyword"
          (ngModelChange)="change($event)"
          class="form-control"
          id="search"
          placeholder="Search" />
        <div class="input-group-append" *ngIf="query.keyword">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
      <div *ngIf="!CLIENT_ADMINS.includes(loggedUser.roleCode) && loggedUser.roleCode !== 'PUA'">
        <button
          type="button"
          class="btn btn-light z-depth-0 pl-3 pr-3"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="OpenFilterModal()"
          mdbWavesEffect>
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
      </div>
      <div *ngIf="loggedUser?.roleCode === 'SV'" class="w-25 ml-3">
        <div class="d-flex justify-content-start align-items-center mt-3 mt-sm-0">
          <p class="mb-0 mr-2">Show:</p>
          <mdb-select
            [options]="viewUserList"
            class="bg-white"
            placeholder="All"
            (selected)="selectedUserList($event)">
          </mdb-select>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-secondary"
      data-toggle="modal"
      data-target="#basicExample"
      (click)="createUser()"
      mdbWavesEffect>
      <i class="icofont-plus"></i> ADD
    </button>
  </div>
  <div class="keywords position-sticky" *ngIf="filterList && filterList.length > 0">
    <div class="keywords d-flex mt-2 flex-wrap align-items-center">
      <small class="mb-2">Filters: &nbsp;</small>
      <span class="d-block mr-1 mb-2" *ngFor="let filter of filterList">
        <small class="d-block text-muted ml-1"></small>
        <div class="chip blue lighten-5 mb-0">
          {{ filter['field'] }}:
          <span class="font-weight-500 text-dark">{{ filter['label'] }}</span>
          <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
        </div>
      </span>
      <button
        type="button"
        class="btn btn-link waves-light m-0 p-0"
        mdbWavesEffect
        (click)="resetFilter()">
        <span class="text-info">Reset Filter</span>
      </button>
    </div>
  </div>
  <table mdbTable hover="true" small="true" stickyHeader="true">
    <thead id="scrollToTop">
      <tr>
        <th width="20"></th>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <!-- <th [class.active]="query.sortBy === 'firstName'" (click)="setOrder('firstName')">Name
          <span *ngIf="query.sortBy === 'firstName'">
            <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
            <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
          </span>
        </th> -->
        <!-- <th [class.active]="query.sortBy === 'email'" (click)="setOrder('email')">Email
          <span *ngIf="query.sortBy === 'email'">
            <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
            <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
          </span>
        </th> -->
        <!-- <th [class.active]="query.sortBy === 'phone'" (click)="setOrder('phone')">Phone
          <span *ngIf="query.sortBy === 'phone'">
            <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
            <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
          </span>
        </th> -->
        <th>Role</th>
        <th>Supervisor</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <div
      class="loader-wrapper"
      *ngIf="loading"
      [style.top]="users && users.length == 0 ? 'auto' : 0">
      <div class="loader">
        <app-loader [loader]="loading"></app-loader>
      </div>
      <div class="backdrop"></div>
    </div>
    <tbody
      mdbTableCol
      *ngFor="
        let user of users
          | paginate : {itemsPerPage: query.limit, currentPage: query.page, totalItems: totalCount};
        let i = index
      ">
      <tr [ngClass]="{'grey lighten-4 border': user?.collapsed}">
        <td (click)="userCollapsed(user, i)">
          <button type="button" class="p-0 mt-0 btn-link btn" mdbWavesEffect *ngIf="user.collapsed">
            <i class="icofont-minus-circle icofont-lg text-info"></i>
          </button>
          <button
            type="button"
            class="p-0 mt-0 btn-link btn"
            mdbWavesEffect
            *ngIf="!user.collapsed && user?.roleCode == 'PR'">
            <i class="icofont-plus-circle icofont-lg text-info"></i>
          </button>
        </td>
        <td (click)="editUser(user)">
          {{ user?.fullName }}
          <br />
          <span class="badge badge-sm bg-primary-dark badge text-white z-depth-0">
            {{ user?.reference?.name }}
          </span>
        </td>
        <td (click)="editUser(user)">{{ user?.email }}</td>
        <td (click)="editUser(user)">{{ user?.phone | formatPhoneNumber }}</td>
        <td (click)="editUser(user)" class="align-middle">
          <!-- <span class="chip bg-white border-grey m-1">{{ user?.roleName }}</span> -->
          {{ user?.roleName }}
          <!-- <span
            class="chip bg-white border-grey m-1"
            [mdbPopover]="rolesList"
            placement="bottom"
            triggers="mouseenter:mouseleave"
            >2+</span
          >
          <ng-template #rolesList>
            <div>
              <span class="chip bg-white border-grey m-1 mr-2">{{ user?.roleName }}</span
              ><br />
              <span class="chip bg-white border-grey m-1 mr-2">{{ user?.roleName }}</span>
            </div>
          </ng-template> -->
        </td>
        <td (click)="editUser(user)">
          {{
            user.roleCode === 'CC' || user.roleCode === 'ES'
              ? user.supervisor && user.supervisor.user
                ? holistaUtils.getFullName(user.supervisor.user)
                : 'Not Assigned'
              : 'N/A'
          }}
        </td>
        <td>
          <div class="switch">
            <label>
              <input
                type="checkbox"
                [checked]="user.status == 'active'"
                (change)="changeUserStatus(user.id, $event.target.checked)"
                [disabled]="
                  !utilityAccess.searchAccess('UM', 'isEditable', false) || !user.isRegistered
                " />
              <span class="lever m-0"></span>
            </label>
          </div>
        </td>
        <td width="32">
          <div class="custom-dropdown">
            <button
              class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
              type="button"
              mdbWavesEffect>
              <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
            </button>
            <div
              class="custom-dropdown-content rounded-sm z-depth-2"
              [style.top]="i === query.limit - 1 && '-130px'">
              <a
                *ngIf="
                  loggedUser.roleCode === 'PA' && (user.roleCode === 'CC' || user.roleCode === 'ES')
                "
                class="dropdown-item"
                (click)="showSupervisorModal(user)">
                <mdb-icon fas icon="user-plus"></mdb-icon>
                Assign Supervisor
              </a>
              <a
                *ngIf="
                  loggedUser.roleCode === 'SV' &&
                  (user.roleCode === 'CC' || user.roleCode === 'ES') &&
                  !user.supervisor
                "
                type="button"
                class="dropdown-item"
                (click)="assignSupervisor(user.id, loggedUser.id)">
                <i class="icofont-check-circled"></i>
                Assign to me</a
              >
              <!-- <a *ngIf="loggedUser.roleCode==='SV' && supervisorExist(user)" type='button' class="dropdown-item"
                (click)="openRemoveSupervisorModal(user)">
                <i class="icofont-close-circled"></i>
                Remove assigned user</a> -->
              <a class="dropdown-item" (click)="managePermissions(user)"
                ><i class="fas fa-cog mr-1"></i> Manage Permission</a
              >
              <!-- If view permission is needed -->
              <!-- <a class="dropdown-item" (click)="viewPermissions(user)"
                ><i class="fas fa-eye mr-1"></i> View Permission</a
              > -->
              <!-- If view permission is needed -->

              <a
                class="dropdown-item"
                (click)="resendInvitation(user)"
                *ngIf="user.email && !user.isRegistered"
                ><i class="icofont-send-mail mr-1"></i> Resend Invitation</a
              >
              <a
                class="dropdown-item"
                (click)="resetPassword(user)"
                *ngIf="user.email && user.isRegistered"
                ><i class="icofont-send-mail"></i> Reset Password</a
              >
              <a class="dropdown-item" (click)="editUser(user)"
                ><i class="icofont-edit-alt mr-1"></i> Edit</a
              >
              <a
                [ngClass]="{isDisabled: !enableChat || user.id === loggedUser.id}"
                type="button"
                class="dropdown-item"
                (click)="openChat(user.id)">
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="!enableChat"></span>
                <i *ngIf="enableChat" class="icofont-chat"></i>
                Chat</a
              >
              <a
                class="dropdown-item"
                *ngIf="
                  user.roleCode === 'PR' && utilityAccess.searchAccess('UM', 'isEditable', false)
                "
                (click)="showProviderModal(user)">
                <mdb-icon fas icon="user-plus"></mdb-icon> Assign Provider
              </a>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="user.collapsed && user.memberUuid" class="grey lighten-4 border">
        <td colspan="100%" class="p-2 pl-5">
          <table mdbTable borderless="true" class="mb-0">
            <thead>
              <tr style="background-color: transparent">
                <th *ngFor="let head of masterHeadElements" scope="col" class="pb-0">{{ head }}</th>
              </tr>
            </thead>
            <tbody>
              <app-loader [loader]="user && !user.dob"></app-loader>
              <tr style="background-color: transparent">
                <td (click)="user.collapsed = !user.collapsed">
                  {{ user?.dob | date : 'MM/dd/yyyy' }}
                </td>
                <td class="pt-0">{{ user?.gender }}</td>
                <td class="pt-0">
                  {{ user?.addressline1 }} {{ user?.addressline2 }}<br />
                  {{ user?.city }} {{ user?.state }} {{ user?.zip }}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr *ngIf="user.collapsed && user.roleCode == 'PR'" class="grey lighten-5 border">
        <td colspan="100%" class="p-2 pl-5">
          <h6 *ngIf="user.providers && user.providers.length > 0">Providers</h6>
          <app-loader [loader]="!user.providers"></app-loader>
          <div *ngIf="user.providers && user.providers.length == 0">
            <h6 style="margin: auto">No Assigned Providers</h6>
          </div>
          <div class="form-row">
            <div
              class="col-6 col-sm-4 col-md-3 col-xl-3"
              *ngFor="let component of user.providers | orderBy : 'displayName'">
              <mdb-card class="z-depth-0 border mb-3">
                <mdb-card-body class="p-3">
                  <div class="media">
                    <div class="avatar d-flex border mr-3">
                      <img
                        class="img-fluid"
                        src="{{
                          component.imageUrl
                            ? component.imageUrl
                            : '../../../assets/img/png/building_icon.png'
                        }}" />
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0 font-weight-bold mb-0">
                        {{ component.displayName || component.businessName }}
                      </h6>
                      <p class="mb-2 text-muted">
                        NPI - {{ component.npi ? component.npi : 'N/A' }}
                        <br />
                        TIN - {{ component.taxId ? component.taxId : 'N/A' }}
                      </p>
                      <p>
                        {{ component?.addressline1 }} <br />
                        {{ component?.city }} {{ component?.state }}
                        {{ component?.zip }}
                      </p>
                    </div>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <pagination-controls
    *ngIf="users && users.length > 0"
    class="text-center"
    (pageChange)="pageChanged($event)"
    autoHide="true">
  </pagination-controls>

  <div
    appScrollToTop
    class="fixed-action-btn donotprint"
    style="bottom: 145px; right: 10px; display: none">
    <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
      <i class="fas fa-angle-up text-black-50"></i>
    </a>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.users">
    <i class="fas fa-3x fa-user-tie text-black-50"></i>
    <p>Start adding Users.</p>
  </div>

  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.searchedUsers">
    <i class="fas fa-3x fa-user-tie text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>

<!-- USER MODAL -->
<div
  mdbModal
  #userModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content add-user-modal">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ userForm.value.id ? 'Edit' : 'Add' }} User</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="userForm">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="userFirstName">First Name *</label>
                <input
                  type="text"
                  id="userFirstName"
                  class="form-control"
                  formControlName="firstName"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': submitted && userForm.get('firstName').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="userLastName">Last Name *</label>
                <input
                  type="text"
                  id="userLastName"
                  class="form-control"
                  formControlName="lastName"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': submitted && userForm.get('lastName').invalid
                  }" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="userEmail">Email *</label>
            <input
              type="text"
              id="userEmail"
              class="form-control"
              formControlName="email"
              mdbInput
              [ngClass]="{
                'is-invalid': submitted && userForm.get('email').invalid
              }" />
          </div>

          <div class="form-group">
            <label for="userPhone">Phone *</label>
            <input
              type="text"
              id="userPhone"
              class="form-control"
              formControlName="phone"
              [textMask]="{mask: mask}"
              (change)="checkPhoneValue()"
              mdbInput
              [ngClass]="{'is-invalid': submitted && userForm.get('phone').invalid}" />
          </div>

          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="isMobile">Is this a mobile phone? </label>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="Yes"
                    name="isMobile"
                    formControlName="isMobile"
                    [value]="true"
                    mdbInput />
                  <label class="form-check-label" for="Yes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="No"
                    name="isMobile"
                    formControlName="isMobile"
                    [value]="false"
                    mdbInput />
                  <label class="form-check-label" for="No">No</label>
                </div>
              </div>
            </div>

            <div class="col" *ngIf="userForm.value.isMobile">
              <div class="form-group">
                <label for="triggerSMS">Trigger SMS </label>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="t-Yes"
                    name="triggerSMS"
                    formControlName="triggerSMS"
                    [value]="true"
                    mdbInput />
                  <label class="form-check-label" for="t-Yes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="t-No"
                    name="triggerSMS"
                    formControlName="triggerSMS"
                    [value]="false"
                    mdbInput />
                  <label class="form-check-label" for="t-No">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="displayRolesList && displayRolesList.length > 0">
            <label for="RoleList">Role *</label>
            <app-select
              id="RoleList"
              [options]="displayRolesList"
              [disabled]="userForm.get('id').value ? true : false"
              (onSelected)="filterRefenceType($event)"
              placeholder="Select role"
              [control]="userForm.controls['roleCode']"
              [inputClass]="{
                'error-select': submitted && userForm.get('roleCode').invalid,
                'pointer-disabled': userForm.get('id').value
              }">
            </app-select>
          </div>

          <div class="form-group" *ngIf="isDualRoleAssignable">
            <label for="secondaryRoleList">Secondary Role </label>
            <mdb-select
              id="secondaryRoleList"
              [options]="secondaryRoleList"
              placeholder="Select secondary role"
              formControlName="secondaryRoleCode"
              [ngClass]="{'error-select': submitted && userForm.get('secondaryRoleCode').invalid}">
            </mdb-select>
          </div>

          <div class="form-group" *ngIf="referenceType && referenceType.label">
            <label for="referenceType">{{ referenceType.label }} *</label>
            <app-select
              id="referenceType"
              [options]="referenceCodes | sort : 'label'"
              placeholder="Select {{ referenceType.label }}"
              [control]="userForm.controls['referenceCode']"
              [disabled]="userForm.get('id').value || referenceCodes.length == 0 ? true : false"
              [inputClass]="{
                'error-select': submitted && userForm.get('referenceCode').invalid,
                'pointer-disabled': userForm.get('id').value
              }">
            </app-select>
          </div>

          <div class="form-group">
            <label for="userNote">Notes</label>
            <textarea
              type="text"
              id="userNote"
              class="md-textarea form-control"
              mdbInput
              [ngClass]="{'is-invalid': submitted && userForm.get('note').invalid}"
              formControlName="note"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitUser()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ userForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-milestone-topic-delete
  [userData]="userData"
  (sendDeleteConfirmation)="receiveRemoveConfirmation($event)">
</app-milestone-topic-delete>

<!-- Assign Supervisor -->
<div
  mdbModal
  #assignSupervisormodal="mdbModal"
  class="modal fade top"
  id="myModalLabel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myBasicModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="closeAssignSupervisorModal()">
          <span aria-hidden="true">×</span>
        </button>
        <h5 class="modal-title w-100" id="myModalLabel">Assign Supervisor</h5>
      </div>
      <div class="modal-body">
        <form class="scrollbar scrollbar-primary thin overflow-auto" style="max-height: 300px">
          <div class="form-row m-0">
            <div class="col-6" *ngFor="let supervisor of supervisorList; let i = index">
              <div class="form-check list-group-item border-0">
                <input
                  type="radio"
                  class="form-check-input"
                  name="defaultExampleRadios"
                  id="defaultChecked-{{ i }}"
                  (change)="changeSupervisor(supervisor)"
                  [checked]="supervisor.id == assignedSupervisorId" />
                <label
                  class="form-check-label text-lg font-weight-normal"
                  for="defaultChecked-{{ i }}">
                  {{ supervisor.firstName }} {{ supervisor.lastName }}</label
                >
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer d-flex justify-content-between p-2">
        <button
          type="button"
          class="waves-light m-0 btn-light btn"
          aria-label="Close"
          (click)="closeAssignSupervisorModal()"
          mdbWavesEffect>
          Close
        </button>
        <button
          type="button"
          class="btn btn-secondary waves-light"
          mdbWavesEffect
          [disabled]="!assignedSupervisorId"
          (click)="saveSupervisor()">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Assign Providers -->
<div
  mdbModal
  #assignProvidermodal="mdbModal"
  class="modal fade top"
  id="providerModalLabel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myBasicModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="closeAssignProviderModal()">
          <span aria-hidden="true">×</span>
        </button>
        <h5 class="modal-title w-100" id="providerModalLabel">Assign Provider</h5>
      </div>
      <div class="modal-body">
        <form autocomplete="false">
          <div class="form-group position-relative">
            <label for="providerName"
              >Provider *
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="searching"></span
            ></label>
            <input
              type="text"
              [ngModel]="provider_search_text | async"
              (ngModelChange)="provider_search_text.next($event)"
              required
              [ngModelOptions]="{standalone: true}"
              class="completer-input form-control mdb-autocomplete mb-0"
              [mdbAutoCompleter]="auto1"
              placeholder="Search by provider name, NPI, TIN"
              [ngClass]="{isDisabled: false}" />
            <mdb-auto-completer
              #auto1="mdbAutoCompleter"
              textNoResults="No Provider found"
              (selected)="onItemSelect($event)"
              [displayValue]="onProviderDisplayValue">
              <mdb-option
                class="border-bottom"
                *ngFor="let option of provider_results | async"
                [value]="option">
                <div class="d-flex flex-column">
                  <strong>{{ option.displayName || option.businessName }}</strong>
                  <span>
                    located in {{ option.practiceAddress.city
                    }}{{ option.practiceAddress.state ? ',' : '' }}
                    {{ option.practiceAddress.state }}</span
                  >
                  <small
                    >(N){{ option.npi ? option.npi : 'N/A' }}, (T){{
                      option.taxId ? option.taxId : 'N/A'
                    }}</small
                  >
                </div>
              </mdb-option>
            </mdb-auto-completer>
          </div>
          <div class="scrollbar scrollbar-primary thin overflow-auto" style="max-height: 300px">
            <div
              class="text-center p-4 mt-3"
              *ngIf="providerLocationList && !(providerLocationList.length > 0)">
              <i class="fas fa-3x fa-hospital text-black-50 mb-3"></i>
              <p>No provider is assigned</p>
            </div>
            <table
              class="v-top"
              mdbTable
              small="true"
              stickyHeader="true"
              *ngIf="providerLocationList && providerLocationList.length > 0">
              <thead>
                <tr>
                  <th style="width: 400px">Provider Name</th>
                  <th>Address</th>
                  <th></th>
                </tr>
              </thead>
              <div class="loader">
                <app-loader [loader]="providerLoading"></app-loader>
              </div>
              <tbody>
                <tr mdbTableCol *ngFor="let location of providerLocationList">
                  <td>
                    <h6 class="font-weight-bold mb-0">
                      {{ location?.displayName || location?.businessName }}
                    </h6>
                    <p *ngIf="location?.npi || location?.taxId">
                      <span *ngIf="location?.npi"
                        >{{ location?.npi ? 'NPI - ' : '' }} {{ location?.npi }}</span
                      >
                      <br />
                      <span href="" class="text-sm" *ngIf="location?.taxId"
                        >{{ location?.taxId ? 'TIN - ' : '' }} {{ location?.taxId }}</span
                      >
                    </p>
                  </td>
                  <td>
                    <p>
                      {{ location?.addressline1 }}<br />
                      {{ location?.city }} {{ location?.state }} {{ location?.zip }}
                    </p>
                  </td>
                  <td>
                    <ng-template #deletealert>
                      <div class="text-center">
                        <p>
                          Are you sure you want to delete ? This is irreversible. If you're not
                          sure, you can click Cancel.
                        </p>
                        <p class="mb-0">
                          <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                            (click)="removeProvider(location); pop.hide()">
                            Delete
                          </button>
                          <button type="button" (click)="pop.hide()" class="btn btn-light btn-sm">
                            Cancel
                          </button>
                        </p>
                      </div>
                    </ng-template>
                    <button
                      id="deletealert"
                      [mdbPopover]="deletealert"
                      #pop="bs-mdbPopover"
                      placement="left"
                      class="btn btn-link mt-0 mb-0 p-0 z-depth-0"
                      type="button">
                      <i class="icofont-ui-delete"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
      <div class="modal-footer d-flex justify-content-between p-2">
        <button
          type="button"
          class="waves-light m-0 btn-light btn"
          aria-label="Close"
          (click)="closeAssignProviderModal()"
          mdbWavesEffect>
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!--Filter Users-->
<div
  mdbModal
  #filterModal="mdbModal"
  class="modal fade top"
  id="filterModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter Users</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFilterModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="filterForm">
          <div class="col-12 p-0">
            <div class="form-group">
              <label>Choose Roles</label>
              <app-select
                [options]="displayRolesList"
                [placeholder]="'Select roles'"
                [id]="'option5'"
                [visibleOptions]="3"
                [multiple]="true"
                [enableSelectAll]="true"
                [control]="filterForm.controls['roles']">
              </app-select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeFilterModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitFilterUsers()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span
          >Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <app-view-permissions
  [userName]="userName"
  [id]="userId"
  [type]="'user'"
  (resetId)="resetUserId()"></app-view-permissions> -->
