<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center mt-4 action position-sticky">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group has-search input-group mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          class="form-control"
          id="search"
          [(ngModel)]="searchKeyword"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="searchRequestEoc($event)"
          placeholder="Search" />
        <div class="input-group-append">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i *ngIf="searchKeyword" (click)="clearSearch()" class="icofont-close-line fa-2x"></i>
          </button>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-light z-depth-0 pl-3 pr-3"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="openAdvanceSearch()"
          mdbWavesEffect>
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
      </div>
    </div>
    <!-- <div class="d-flex align-items-center justify-content-end">
        <a
          [routerLink]="['/payment/create/received']"
          class="waves-light btn btn-secondary text-center text-white text-uppercase"
          mdbWavesEffect>
          <i class="icofont-plus"></i> Receive Payment
        </a>
        <a
          [routerLink]="['/payment/create/paid']"
          class="waves-light btn btn-secondary text-center text-white text-uppercase"
          mdbWavesEffect>
          <i class="icofont-plus"></i> Issue Payment
        </a>
      </div> -->
  </div>
  <!-- <div class="keywords position-sticky" *ngIf="  List && filterList.length > 0">
      <div class="keywords d-flex mt-2 flex-wrap align-items-center">
        <small class="mb-2 mr-2">Filters: </small>
        <span class="d-block mr-1 mb-2" *ngFor="let filter of filterList">
          <small class="d-block text-muted ml-1"></small>
          <div class="chip blue lighten-5 mb-0">
            {{ filter.field }}:
            <span class="font-weight-500 text-dark">{{ filter.label | titlecase }}</span>
            <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
          </div>
        </span>
        <button
          type="button"
          class="btn btn-link waves-light m-0 p-0"
          mdbWavesEffect
          (click)="resetFilter()">
          <span class="text-info">Reset Filter</span>
        </button>
      </div>
    </div> -->
  <div class="keywords position-sticky" *ngIf="appliedFilter && appliedFilter.length > 0">
    <div class="keywords d-flex mt-2 flex-wrap align-items-center">
      <small class="mb-2">Filters: </small>
      <span class="d-block mr-1 mb-2" *ngFor="let filter of appliedFilter">
        <small class="d-block text-muted ml-1"></small>
        <div *ngIf="filter?.value?.length" class="chip blue lighten-5 mb-0">
          {{ filter.label }}:
          <span class="font-weight-500 text-dark">{{ toTitleCase(filter.value) }}</span>
          <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
        </div>
      </span>
      <button
        type="button"
        class="btn btn-link waves-light m-0 p-0"
        mdbWavesEffect
        (click)="onResetFilter()">
        <span class="text-info">Reset Filter</span>
      </button>
    </div>
  </div>

  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table mdbTable hover="true" small="true" stickyHeader="true">
          <thead id="scrollToTop">
            <tr>
              <!-- <th width=""></th> -->
              <th width="15%" class="text-left">Name</th>
              <th width="15%" class="text-left">Subscriber ID</th>
              <th width="20%" class="text-left">Requested EOC</th>
              <th width="5%" class="text-left">DOB</th>
              <th width="15%" class="text-left">Email</th>
              <th width="12%" class="text-left">Phone</th>
              <th width="5%%" class="text-left">Contact</th>
              <th width="3%" class="text-left">Note</th>
              <th width="8%" class="text-left">Status</th>
              <th width="5%" class="text-left">Date</th>

              <th></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="showSpinner"
            [style.top]="requestEoc && requestEoc.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="showSpinner"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let eachEoc of requestEoc
                | paginate : {itemsPerPage: limit, currentPage: page, totalItems: totalCount};
              let i = index
            "
            mdbTableCol>
            <tr [ngClass]="eachEoc.status ? eachEoc.status.toLowerCase() : 'preliminary'">
              <!-- <td >
                
                </td> -->

              <td class="text-left">
                {{ eachEoc.eocMember[0].firstName + ' ' + eachEoc.eocMember[0].lastName }}
              </td>
              <td class="text-left">
                {{ eachEoc.eocMember[0]?.subscriberId ? eachEoc.eocMember[0]?.subscriberId : '-' }}
              </td>
              <td width="350" class="text-left">
                {{ eachEoc.eocReference[0]?.bundleDisplayName }}
              </td>
              <td class="text-left">
                {{ eachEoc.eocMember[0].dob | date : 'MM/dd/yyyy' : 'UTC' }}
              </td>
              <td class="text-left">
                {{ eachEoc.eocMember[0].email }}
              </td>
              <td class="text-left">
                {{ eachEoc.eocMember[0]?.phone }}
              </td>
              <td class="text-left">
                {{ toTitleCase(eachEoc.eocMember[0]?.contactVia) }}
              </td>
              <td>
                <!-- {{eachEoc?.note ? eachEoc?.note: ''}} -->
                <!-- <i
                  *ngIf="eachEoc.note"
                  class="icofont-info-circle btn-link pointer-cursor ml-1"
                  [mdbPopover]="notePopOver"
                  placement="top"
                  triggers="mouseenter:mouseleave"></i> -->
                <i
                  *ngIf="eachEoc.note"
                  class="icofont-notepad btn-link pointer-cursor ml-1"
                  [mdbPopover]="notePopOver"
                  placement="top"
                  triggers="mouseenter:mouseleave"></i>
                <ng-template #notePopOver>
                  <div class="m-1" style="max-width: 350px">
                    {{ eachEoc?.note | slice : 0 : 110 }}
                    <span *ngIf="eachEoc?.note?.length > 107">...</span>
                  </div>
                </ng-template>
              </td>

              <td class="text-left">
                <div
                  class="chip alert m-0 mb-2 rounded-sm w-100"
                  [ngClass]="eachEoc.status ? eachEoc.status.toLowerCase() : 'preliminary'"
                  *ngIf="eachEoc && eachEoc.status">
                  <div class="custom-dropdown w-100">
                    <div class="d-flex justify-content-between w-100 pl-2 pr-2">
                      {{ toTitleCase(eachEoc.status) }}
                      <button
                        class="btn btn-link border-0 dropdown-toggle waves-light m-0 ml-2 p-0"
                        type="button"></button>
                    </div>
                    <div class="custom-dropdown-content drop-satatus w-100 rounded-sm z-depth-2">
                      <a
                        *ngFor="let status of eachEoc?.statusList"
                        class="cursor-pointer dropdown-item"
                        (click)="statusChanged(status, eachEoc)">
                        {{ status.label ? status.label : '' }}</a
                      >
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-column">
                  <!-- <small
                        class="text-info"
                        *ngIf="selectedClaim && selectedClaim.id === eachEoc.id && loading.statusChanged"
                        >Processing...</small -->
                </div>
              </td>
              <td class="text-left">
                {{ eachEoc?.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}
              </td>
              <td width="32">
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div class="custom-dropdown-content rounded-sm z-depth-2">
                    <!-- <a class="dropdown-item"
                            ><i class="icofont-edit-alt"></i> Editss</a
                          > -->
                    <a (click)="openNoteAdd(eachEoc)" class="dropdown-item"
                      ><i class="icofont-notepad"></i> {{ eachEoc?.note ? 'Edit' : 'Add' }} Note</a
                    >
                    <!-- <a class="dropdown-item"
                            ><i class="iconFont-delete-alt"></i> Delete</a
                          > -->
                  </div>
                </div>
              </td>
            </tr>
            <!-- <tr  class="grey lighten-4 border">
                <td colspan="100%" class="p-3">
                  <table mdbTable small="true" hover="true" bordered="true" striped="true">
                    <thead>
                      <tr>
                        <th>header</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <td>test123</td>
                        <td>
done
                        </td>
                        <td>how</td>
                        <td>is</td>
                        <td>this</td>
                        <td>
                          how
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr> -->
          </tbody>
        </table>
      </div>
    </div>

    <pagination-controls
      *ngIf="requestEoc && requestEoc.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <div *ngIf="!requestEoc.length && !showSpinner" class="text-center p-4 border mt-3">
    <i class="fas fa-3x fa-clone text-black-50"></i>
    <!-- <p >Start adding Payments.</p> -->
    <p class="mt-1">No results found.</p>
  </div>
</section>

<!-- FILTER MODAL -->
<div
  mdbModal
  #advanceSearchModal="mdbModal"
  #filterModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter Request EOC</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFilterModal('close')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <!-- <form [formGroup]="filterForm"> -->
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label for="paymentMode">Name</label>
                <input
                  type="text"
                  [ngModel]="displayName"
                  id="patient"
                  (ngModelChange)="userSearch($event)"
                  [ngModelOptions]="{standalone: true}"
                  class="completer-input form-control mdb-autocomplete mb-0"
                  [mdbAutoCompleter]="auto1"
                  placeholder="Type to select patient" />
                <mdb-auto-completer
                  #auto1="mdbAutoCompleter"
                  textNoResults="No result found"
                  [displayValue]="displayUserValue"
                  [optionHeight]="40"
                  (selected)="onSearchResultSelected($event, 'userName')">
                  <mdb-option *ngFor="let user of searchUsers" [value]="user">
                    <div class="d-flex flex-column">
                      <strong>{{ user?.firstName }} {{ user?.lastName }}</strong>
                    </div>
                  </mdb-option>
                </mdb-auto-completer>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="paymentMode">Requested EOC</label>
                <input
                  type="text"
                  [ngModel]="filterEoc"
                  id="patient"
                  (ngModelChange)="eocSearch($event)"
                  [ngModelOptions]="{standalone: true}"
                  class="completer-input form-control mdb-autocomplete mb-0"
                  [mdbAutoCompleter]="auto2"
                  placeholder="Type to select EOC" />
                <mdb-auto-completer
                  #auto2="mdbAutoCompleter"
                  textNoResults="No result found"
                  [displayValue]="displayEocValue"
                  [optionHeight]="40"
                  (selected)="onSearchResultSelected($event, 'eocName')">
                  <mdb-option *ngFor="let eoc of searchEoc" [value]="eoc">
                    <div class="d-flex flex-column">
                      <strong>{{ eoc?.bundleDisplayName }}</strong>
                    </div>
                  </mdb-option>
                </mdb-auto-completer>
              </div>
            </div>
          </div>
          <form [formGroup]="filterForm">
            <div class="form-row">
              <div class="col-6">
                <div class="form-group">
                  <label for="processingstatus">Choose Status</label>
                  <mdb-select
                    [options]="allStatus"
                    id="processingstatus"
                    formControlName="filteredStatus"
                    placeholder="Select status"
                    [multiple]="true"
                    [allowClear]="filterForm.value.filteredStatus.length ? true : false"
                    [enableSelectAll]="false">
                  </mdb-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="contactVia">Choose Contact Via</label>
                  <mdb-select
                    [options]="contactViaList"
                    id="contactVia"
                    formControlName="filteredContact"
                    placeholder="Select contactVia"
                    [multiple]="true"
                    [enableSelectAll]="false">
                  </mdb-select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeFilterModal('cancel')">
          Cancel
        </button>
        <button class="btn btn-secondary waves-light m-0" mdbWavesEffect (click)="onApplyFilter()">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>

<div
  mdbModal
  #noteModal="mdbModal"
  #noteModald="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ selectedEoc.note ? 'Update' : 'Add' }} Note</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeNoteModal('cancel')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <!-- <form [formGroup]="filterForm"> -->
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <textarea
                  style="width: 568px; height: 100px"
                  type="text"
                  [ngModel]="note"
                  (ngModelChange)="changeNote($event)"
                  id="bundleDescription"
                  class="md-textarea form-control"
                  mdbInput>
                </textarea>
                <!-- <input
                  type="textarea"
                  [ngModel]="displayName"
                  id="patient"
                  (ngModelChange)="userSearch($event)"
                  [ngModelOptions]="{standalone: true}"
                  class="completer-input form-control mdb-autocomplete mb-0"
                  [mdbAutoCompleter]="auto1"
                  placeholder="Type to select patient" /> -->
              </div>
            </div>
          </div>

          <!-- </form> -->
        </div>
      </div>

      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeNoteModal('cancel')">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          [disabled]="(!selectedEoc.note && !note) || (note && note.trim() === '')"
          (click)="onAddNote(selectedEoc.note)">
          {{ selectedEoc.note ? 'Update' : 'Add' }}
        </button>
      </div>
    </div>
  </div>
</div>
