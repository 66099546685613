import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../action';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { finalize, startWith, switchMap, debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { UserService, EpisodeService, EpisodeOfCareService, ExcelService, MemberService, ValueBasedProgramService, PathwayService, BundleService, BundleCostService, ClientService, PurchaserService, NetworkService, ProviderService, ToasterService } from '../../services';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Subject, of, forkJoin } from 'rxjs';
import { AccessType, DateUtility, HolistaUtils, Storage } from '../../utils';
import { EpisodeFilter, EpisodeCreate, CreateUserSearch, FilterUserSearch, ExportData, CommunicationModuleOptions } from '../../models';
import { ACCESS_DENIED, ADD_SUCCESS, FULL_EPISODE_EDIT_ELIGIBLE_ROLES, EPISODESTATUS, EPISODE_STATUS_CHANGED, ERROR_GET_EPISODES, MessageConstants, NO_EPISODE_FOUND, NO_FACILITY_FOUND, NO_MARKET_CODE, NO_PROVIDER_ASSOCIATED, PROVIDER_NETWORK, PROVIDER_TYPE, PURCHASER_CODES, UPDATE_SUCCESS, EDIT_EPISODE_DISABLED_STATUSES, EPISODE_STATUS_LIST, EPISODE_CANCELLED, CLIENT_ADMINS } from '../../constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';

@Component({
  selector: 'app-episode',
  templateUrl: './episode.component.html',
  styleUrls: ['./episode.component.scss']
})
export class EpisodeComponent implements OnInit, OnDestroy {
  searchKeyword = '';
  page = 1;
  limit = 10;
  totalCount = 0;
  sortBy: string;
  sortOrder: string = 'DESC';
  reverse: boolean = true;
  searchModelChanged: Subject<string> = new Subject<string>();
  loading = false;
  cardLoading = false;
  isSubmitEnabled = false;
  episodes = [];
  selectedEpisode: any;
  episodeForm: FormGroup;
  providerListForm: FormGroup;
  hideEpisodeProviderForm = false;
  isEpisodeCancelled = false;
  filterForm: FormGroup;
  assignedToCCList = [];
  assignedToESList = [];
  procedureBundleList = [];
  procedureBundleDisplayList = [];
  submitted = false;
  filterModel = new EpisodeFilter();
  filterList = [];
  bundleComponentWithShowInEpisode = [];
  user: any;
  usermode = false;
  episodeActionStatus = [];
  companyDetails = {};
  filterFromDashboard = false;
  userNameChanges: '';
  patientDemographicId = null;
  userEpisode: any;
  isMobile = false;
  episodeToBeCancel: any;
  initialStatus = null;
  assignedToCCListBackup = [];
  assignedToESListBackup = [];
  userProfile: any;
  bundleComponentListProvidersLoader = false
  fullUserList: any[] = [];
  userDetail: any;
  providerIdList: any = {}
  episodeResultSettings: any = {};
  result = { episodes: true, searchedEpisodes: true };
  masterHeadElements = ['Provider', 'Benefit Start Date', 'Benefit End Date', 'Engagement Specialist'];
  episodeCreate = new EpisodeCreate();
  createUserSearch = new CreateUserSearch()
  filterUserSearch = new FilterUserSearch()
  defaultClient: any;
  isNewEpisodeModal = false;
  bundleDetails: any
  bundleComponentProviderList: any
  bundleComponentProviderDisabler = true
  isExportLoading = false
  exportEpisodeList: any = []
  memberPlansInfo: any = []
  selectedPatient: any
  patientIdCard: any;
  isEocDetailLoading = false
  allEpisodes: any = []
  showMessageModal: boolean = false;
  metaVariablesDetail: any;
  moduleOptions: CommunicationModuleOptions;
  allowedEpisodeStatus: any = {};
  PURCHASER_CODES = PURCHASER_CODES;
  FULL_EPISODE_EDIT_ELIGIBLE_ROLES = FULL_EPISODE_EDIT_ELIGIBLE_ROLES;
  EDIT_EPISODE_DISABLED_STATUSES = EDIT_EPISODE_DISABLED_STATUSES;
  episode: any;
  memberSearchLoading: boolean = false

  @ViewChild('episodeModal', { static: true }) public episodeModal;
  @ViewChild('filterModal', { static: true }) public filterModal;
  @ViewChild('idCardModal', { static: true }) public idCardModal;

  constructor(
    private episodeService: EpisodeService,
    private store: Store<{ bread_crumbs: any }>,
    private router: Router,
    private formBuilder: FormBuilder,
    private _toastr: ToasterService,
    private userService: UserService,
    public utilityAccess: AccessType,
    public holistaUtils: HolistaUtils,
    private deviceService: DeviceDetectorService,
    private providerService: ProviderService,
    private _storage: Storage,
    private episodeOfCareService: EpisodeOfCareService,
    private excelService: ExcelService,
    private memberService: MemberService,
    private valueBasedProgramService: ValueBasedProgramService,
    private pathwayService: PathwayService,
    private bundleService: BundleService,
    private bundleCostService: BundleCostService,
    private clientService: ClientService,
    private purchaserService: PurchaserService,
    private networkService: NetworkService,
    private _messageConstants: MessageConstants,
    private _dateUtility: DateUtility,
  ) {
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      this.searchKeyword = data;
      this.searchEpisode(1);
    });
    this.setEpisodeForm();
    this.setProviderListForm()
    this.setFilterForm();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (!event.url.startsWith('/episodes') && this._storage.get('session', 'episodeResultSettings'))
          this._storage.remove('session', 'episodeResultSettings');
      }
    });
  }

  setProviderListForm() {
    this.providerListForm = this.formBuilder.group({})
  }

  setEpisodeForm() {
    this.episodeForm = this.formBuilder.group({
      id: [null],
      eocUuid: [null, Validators.required],
      userId: [null],
      memberUuid: [null],
      assignedToCCId: [null, Validators.required],
      assignedToESId: [null, Validators.required],
      providerId: [null],
      providerName: [''],
      facilityId: [null, Validators.required],
      facilityName: [''],
      clinicId: [null],
      clinicName: [''],
      marketCode: [''],
      tag: [''],
      bundleName: [''],
      status: [null],
    });
  }

  setFilterForm() {
    this.filterForm = this.formBuilder.group({
      name: [''],
      userId: [null],
      bundleName: [''],
      providerId: [null],
      facilityId: [null],
      ccId: [null],
      esId: [null],
      filterEnabled: [false],
      status: [''],
      userName: [''],
      clientCode: [null],
      networkCode: [null],
      purchaserCode: [null],
      subscriberNumber: [null]
    });
  }

  async ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Episodes', path: '/episodes' }));

    this.createUserSearch.user_results = this.createUserSearch.user_search_text.pipe(
      startWith(this.createUserSearch.user_search_text),
      switchMap((user_search_text: string) => this.createSearchUser(user_search_text))
    );

    this.filterUserSearch.user_filter_results = this.filterUserSearch.user_filter_text.pipe(
      startWith(this.filterUserSearch.user_filter_text),
      switchMap((user_filter_text: string) => this.filterSearchUser(user_filter_text))
    );
    this.user = this._storage.get('local', 'loggedInUser', 'user');
    if (this.user.roleCode === 'MP') this.usermode = true;
    await this.getEpisodeAllowedStatus();
    this.getAssignedToUsers();
    this.getClients();
    this.getPurchasers();
    if (CLIENT_ADMINS.includes(this.user.roleCode)) this.getClientNetworks()
    else this.getNetworks()
    this.getEpisodesDropdown();
    this.isMobile = this.deviceService.isMobile();
    this.episodeResultSettings = this._storage.get('session', 'episodeResultSettings');
    let filterData = this._storage.get('session', 'episodeFilterData');
    if (filterData && filterData.formData && filterData.formData.filterEnabled) {
      Object.keys(filterData.backup).forEach(key => {
        if (key === 'assignedToCCList' || key === 'assignedToESList') this[key] = filterData.backup[key]
        else this.filterModel[key] = filterData.backup[key]
      })
      this.filterForm.patchValue(filterData.formData);
      if (filterData.formData.userName) {
        this.filterUserSearch.user_filter_text.next(filterData.formData.userName);
        this.userNameChanges = filterData.formData.userName;
        this.filterFromDashboard = true;
      }
    }
    if ((filterData && filterData.formData && filterData.formData.filterEnabled) || this.episodeResultSettings)
      this.submitFilterEpisode();
    else {
      this.getEpisodes();
    }
  }

  ngOnDestroy() {
    this.closeModal();
  }

  getEpisodeAllowedStatus() {
    this.episodeService.getEpisodeAllowedStatus({ roleCode: this.user.roleCode }).subscribe(res => {
      EPISODESTATUS.forEach(status => {
        let allowedStatus = res.length ? res.filter(x => x.fromStatus === status.value).map(x => x.toStatus) : []
        this.allowedEpisodeStatus[status.value] = []
        this.allowedEpisodeStatus[status.value] = this.allowedEpisodeStatus[status.value].concat(allowedStatus);
      })
    })
  }

  getClients() {
    this.clientService.getClients({ limit: 0 }).subscribe(res => {
      if (res.count != 0) {
        res.rows.map(client => {
          client.label = client.name
          client.value = client.code
          return client
        })
        this.filterModel.clientList = res.rows
      }
    },
      (error) => {
        console.log('Error', error)
      })
  }

  getPurchasers() {
    this.purchaserService.getAll({ limit: 0 }).subscribe(res => {
      if (res.count != 0) {
        res.rows.map(purchaser => {
          purchaser.label = purchaser.name
          purchaser.value = purchaser.code
          return purchaser
        })
        this.filterModel.purchaserList = res.rows
      }
    },
      (error) => {
        console.log('Error', error)
      })
  }

  getNetworks() {
    this.networkService.getAll({ limit: 0 }).subscribe(res => {
      if (res.count != 0) {
        res.rows.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.filterModel.networkList = res.rows
      }
    },
      (error) => {
        console.log('Error', error)
      })
  }

  getClientNetworks() {
    this.clientService.getNetworksByClientCode(this.user.referenceCode)
      .subscribe(res => {
        res.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.filterModel.networkList = res
      }, (error) => {
        console.log('Error', error)
      })
  }

  setOrder(value: string) {
    this.removeEpisodeResultSettings();
    if (this.sortBy === value) {
      this.reverse = !this.reverse;
      this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
    }
    else {
      this.reverse = true;
      this.sortOrder = "DESC";
    }
    this.sortBy = value;
    this.searchEpisode(this.page);
  }

  updateFilterApplied(field, formField, value, list) {
    if (formField === 'userId' || formField === 'clientCode' || formField === 'networkCode') {
      let valueIndex = list.findIndex(x => x.value == value);
      if (valueIndex > -1) {
        let index = this.filterList.findIndex(x => x.field == field && x.value == value);
        if (index == -1) {
          if (formField == 'userId') {
            let filter_data = this.filterForm.value;
            filter_data.userName = list[valueIndex].label;
            // this._storage.set('session', 'episodeFilterData', filter_data);
          }
          let data = { field: field, formField: formField, value: value, label: list[valueIndex].label };
          this.filterList.push(data);
        }
      }
    } else {
      if (value) {
        Object.keys(value).forEach(y => {
          let values = value[y];
          let valueIndex = list.findIndex(x => x.value == values);
          if (valueIndex > -1) {
            let index = this.filterList.findIndex(x => x.field == field && x.value == values);
            if (index == -1) {
              let data = { field: field, formField: formField, value: values, label: list[valueIndex].label };
              this.filterList.push(data);
            }
          }
        });
      }
    }
  }

  createEpisode() {
    this.hideEpisodeProviderForm = false
    this.isNewEpisodeModal = true
    this.bundleComponentWithShowInEpisode = [];
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      let patientEpisode = this._storage.get('session', 'patientEpisode');
      if (patientEpisode) {
        patientEpisode.fullName = patientEpisode.fullName.replace(/\s+/g, ' ').trim()
        this.createUserSearch.user_search_text.next(patientEpisode.fullName);
      }
      this.episodeModal.show();
    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  changes(e) {
    this.userNameChanges = e.target.value;
  }

  createSearchUser(searchText: string) {
    this.memberSearchLoading = true
    if (searchText && searchText.length > 2) {
      return this.memberService.searchMembers({ keyword: searchText }).pipe(
        debounceTime(250),
        map((items: any) => {
          items = items.rows.map(user => {
            user.fullName = this.holistaUtils.getFullName(user)
            const purchaser = this.filterModel.purchaserList.find(x => x.code == user.purchaserCode)
            user.purchaserName = purchaser ? purchaser.label : ''
            // user = { ...user, ...this.holistaUtils.getUserRoleInfo(user.roles) }
            return user
          })
          let patientEpisode = this._storage.get('session', 'patientEpisode');
          if (patientEpisode) {
            let searchedPatient = items.find(x => x.uuid == patientEpisode.uuid);
            this.selectedPatient = searchedPatient
            if (searchedPatient && searchedPatient.uuid) {
              this.episodeForm.controls.memberUuid.setValue(searchedPatient.uuid);
              this.episodeCreate.search_member = { id: searchedPatient.id, name: searchedPatient.fullName };
              this.episodeCreate.getInfo = true
              this.getPatientPurchaserVBP(searchedPatient)
            }
          }
          if (this.episodeCreate.selected_CC?.value) items = items.filter(x => x.id !== this.episodeCreate.selected_CC?.value);
          if (this.episodeCreate.selected_ES?.value) items = items.filter(x => x.id !== this.episodeCreate.selected_ES?.value);
          // return items.filter(x => x.roleCode === 'MP' || (x.secondaryRole == 'MP' && x.id != this.user.id));
          this.memberSearchLoading = false
          return items;
        })
      );
    } else {
      this.memberSearchLoading = false
      return of([]);
    }
  }

  filterSearchUser(searchText: string) {
    if (searchText && searchText.length > 2) {
      const patients = this.allEpisodes.map(x => {
        x.referenceCode = x.purchaser.code
        x.firstName = x.patient ? x.patient.firstName : x.claimPatient.firstName;
        x.id = x.patient && x.patient.id
        x.lastName = x.patient ? x.patient.lastName : x.claimPatient.lastName;
        x.dob = x.patient && (x.patient.dob ? x.patient.dob : null)
        x.fullName = this.holistaUtils.getFullName(x.patient || x.claimPatient)
        x.purchaserName = x.purchaser.name
        return x
      })
      let uniquePatients = patients.filter((patient, i) => patient && (patients.findIndex(x => x.fullName == patient.fullName) == i));
      if (this.filterFromDashboard) {
        let filterData: any = this._storage.get('session', 'episodeFilterData');
        this.filterFromDashboard = false;
        let filterList = uniquePatients.filter(x => x.fullName == filterData && filterData.userName);
        this.filterModel.tempUserList.push({
          label: filterList[0] && filterList[0].id ? filterList[0].fullName : '',
          value: filterList[0] && filterList[0].id ? filterList[0].id : null
        });
      }
      if (this.episodeCreate.selected_CC?.value) uniquePatients = uniquePatients.filter(x => x.id !== this.episodeCreate.selected_CC?.value);
      if (this.episodeCreate.selected_ES?.value) uniquePatients = uniquePatients.filter(x => x.id !== this.episodeCreate.selected_ES?.value);
      let data: any = uniquePatients.filter(el => {
        return el.fullName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      })
      return of([...data])
    } else {
      return of([]);
    }
  }

  onDisplayValue = (user?): string | undefined => {
    return user ? (user.lastName ? user.firstName + ' ' + user.lastName : user.firstName) : undefined;
  };

  // updateUser(user) { Could not find this function being called from anywhere
  //   if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
  //     this.episodeForm.controls.userId.setValue(user && user.id ? user.id : null);
  //   } else {
  //     this._toastr.displayWarning(ACCESS_DENIED);
  //   }
  // }

  async onUserSelect(event, form) {
    const user = event.text
    !user.id && (user.id = event.text.userId.toString()); //For carrum patient whose id remains 0 and 0 is treated as null. PS: for showing filters applied
    if (event.text.claimPatient) this.filterForm.controls.subscriberNumber.setValue(event.text.claimPatient.subscriberNumber);
    this.selectedPatient = user
    this.episodeForm.reset()
    this.providerListForm.reset()
    this.episodeForm.controls.userId.setValue(user.id)
    this.episodeForm.controls.memberUuid.setValue(user.uuid)
    const { userId } = this.episodeForm.value
    if (form === 'episodeForm') {
      this.episodeCreate = new EpisodeCreate();
      this.episodeCreate.search_member = { name: user.fullName, id: user.id, };
      if (userId && userId !== this.episodeCreate.search_member.id) {
        this.episodeForm.reset()
        this.providerListForm.reset()
        this.episodeCreate.selected_episodeCare = null
        this.episodeForm.controls.userId.setValue(null);
      }
      this.episodeCreate.getInfo = true
      await this.getPatientPurchaserVBP(user)
    } else {
      this.filterModel.tempUserList = [];
      this[`${form}`].controls.userId.setValue(user && user.id ? form === 'filterForm' ? [user.id] : user.id : null);
      this.filterModel.tempUserList.push({
        label: user && user.id ? (user.lastName ? user.firstName + ' ' + user.lastName : user.firstName) : '',
        value: user && user.id ? user.id : null
      });
      this._storage.set('session', 'filteredPatient', this.filterModel.tempUserList);
    }
  }

  async getPatientPurchaserVBP(user) {
    forkJoin([this.memberService.getMemberPlans({ memberUuid: user.uuid }),
    this.purchaserService.getValueBasedProgramsByPurchaserCode(user.purchaserCode, { limit: 0, defaultIncludes: false })
    ]).subscribe(([memberPlans, purchaserVBPs]: any) => {
      this.memberPlansInfo = memberPlans.rows.map(plan => plan)
      let purchaserVbpCodes: any = purchaserVBPs.rows.map(vbp => vbp.vbpCode)
      const memberVbpCodes = this.memberPlansInfo.map(x => x.programId)
      const availableVbpCodes = purchaserVbpCodes.filter(p => memberVbpCodes.includes(p))
      if (availableVbpCodes && availableVbpCodes.length != 0) this.getSelectedPatientEpisodeOfCare()
      else {
        this._toastr.displayWarning(NO_EPISODE_FOUND);
        this.episodeCreate.getInfo = false
      }
    },
      (error) => {
        this.episodeCreate.getInfo = false
        console.log('Error', error)
      }
    )
  }

  getSelectedPatientEpisodeOfCare() {
    const data = {
      memberUuid: this.selectedPatient.memberUuid || this.selectedPatient.uuid,
      purchaserCode: this.selectedPatient.referenceCode || this.selectedPatient.purchaserCode
    }
    this.episodeOfCareService.getEocOfMember(data)
      .pipe(finalize(() => { this.episodeCreate.getInfo = false }))
      .subscribe((res: any) => {
        this.episodeCreate.episodeCareList = []
        if (res.count !== 0) {
          return res.map(eoc => {
            const data = { label: `${eoc.name.trim()} (${eoc.vbpCode})`, value: eoc.uuid, pathwayUuid: eoc.pathwayUuid, bundleUuid: eoc.bundleUuid, vbpCode: eoc.vbpCode, networkCode: eoc.networkCode }
            this.episodeCreate.episodeCareList = [...this.episodeCreate.episodeCareList, data]
          })
        } else this._toastr.displayWarning(NO_EPISODE_FOUND);
      }, error => {
        console.log('error', error);
        this._toastr.displayWarning(error.error.message);
      });
  }

  getProcedureDate(arr) {
    const procedureDate = arr.find(x => x.isMainProcedure === true);
    return (procedureDate && procedureDate.startDate) ? moment(procedureDate.startDate).format('MM/DD/YYYY') : '-';
  }

  async getEpisodes(exportEpisode?) {
    if (!exportEpisode) this.loading = true;
    const episodeUrl = exportEpisode ? 'getEpisodesDropdown' : 'getEpisodes'
    await this.episodeService[episodeUrl](exportEpisode ? '' : this.page, exportEpisode ? '' : this.limit)
      .then((res: any) => {
        this.loading = false
        if (exportEpisode) {
          this.isExportLoading = false
          this.setExportData(res.rows)
        }
        if (res.count != 0) {
          this.totalCount = res.count;
          res.rows = res.rows.map(episode => {
            episode.esName = this.holistaUtils.getFullName(episode.es)
            episode.ccName = this.holistaUtils.getFullName(episode.cc)
            return episode
          })
          res.rows.forEach(x => {
            x.procedureDate = this.getProcedureDate(x.milestones);
            x.numberOfDays = this._dateUtility.getNumberOfDays(x.procedureDate)
            x.episodeActionStatus = this.selectOpened(x)
          });
          this.episodes = res.rows
          this.result.episodes = true;
          if (this._storage.get('session', 'patientEpisode'))
            this.createEpisode();
        }
        else {
          this.episodes = [];
          this.result.episodes = false;
        };
      }, (error) => {
        this.isExportLoading = false
        this.loading = false;
        this.result.episodes = false;
        this._toastr.displayError(ERROR_GET_EPISODES);
        console.log('Error getting Episodes List', error);
      });
  }

  checkFilterFormData() {
    let hasFormData = false;
    for (const key in this.filterForm.value) {
      if (this.filterForm.value[key])
        hasFormData = true;
    }
    return hasFormData;
  }

  searchEpisode(page) {
    this.page = this.episodeResultSettings && this.episodeResultSettings.page ? this.episodeResultSettings.page : page;
    this.searchKeyword = this.episodeResultSettings && this.episodeResultSettings.searchKeyword ? this.episodeResultSettings.searchKeyword : this.searchKeyword;
    this.sortBy = this.episodeResultSettings && this.episodeResultSettings.sortBy ? this.episodeResultSettings.sortBy : this.sortBy;
    this.sortOrder = this.episodeResultSettings && this.episodeResultSettings.sortOrder ? this.episodeResultSettings.sortOrder : this.sortOrder;
    this.reverse = this.sortOrder === 'ASC' ? false : true;
    const clientCode = this.filterForm.value.clientCode ? this.filterForm.value.clientCode : null
    const formValue = JSON.parse(JSON.stringify(this.filterForm.value))
    if (formValue.bundleName) {
      formValue['episodeBundle.name'] = formValue.bundleName;
      formValue['episodeOfCare.bundle.name'] = formValue.bundleName;
    }
    if (formValue.subscriberNumber) formValue['claimPatient.subscriberNumber'] = formValue.subscriberNumber;
    delete formValue.bundleName
    delete formValue.subscriberNumber
    delete formValue.backup
    if (this.searchKeyword || this.checkFilterFormData() || this.sortBy) {
      this.loading = true;
      let body = { ...formValue, ...{ searchKeyword: this.searchKeyword ? this.searchKeyword.trim() : this.searchKeyword }, ...{ sortBy: this.sortBy }, ...{ sortOrder: this.sortOrder }, ...{ page: this.page }, ...{ limit: this.limit }, ...{ clientCode: clientCode && typeof clientCode == 'number' ? [clientCode] : clientCode } };
      this.episodeService
        .getEpisodesBySearchKeyword(body)
        .then((res: any) => {
          this.loading = false;
          if (res.count != 0) {
            res.rows.map(episode => {
              episode.ccName = this.holistaUtils.getFullName(episode.cc)
              episode.esName = this.holistaUtils.getFullName(episode.es)
              return episode
            })
            this.totalCount = res.count;
            res.rows.forEach(x => {
              x.procedureDate = this.getProcedureDate(x.milestones);
              x.numberOfDays = this._dateUtility.getNumberOfDays(x.procedureDate)
              x.episodeActionStatus = this.selectOpened(x)
            });
            this.episodes = res.rows
            this.result.searchedEpisodes = true;
          }
          else {
            this.loading = false;
            this.episodes = [];
            this.result.searchedEpisodes = false
          };
        })
    }
    else
      this.getEpisodes();
  }

  searchEpisodeByKeyword(event) {
    this.removeEpisodeResultSettings();
    this.searchModelChanged.next(event);
  }

  pageChanged(event) {
    this.removeEpisodeResultSettings();
    this.page = event;
    if (this.searchKeyword || this.checkFilterFormData() || this.sortBy)
      this.searchEpisode(this.page);
    else
      this.getEpisodes();
  }

  removeEpisodeResultSettings() {
    if (this._storage.get('session', 'episodeResultSettings')) {
      this._storage.remove('session', 'episodeResultSettings');
      this.episodeResultSettings = {};
    }
  }

  getAssignedToUsers() {
    let query: any = {}
    query.limit = 0
    query['roles-roleCode'] = 'CC,ES'
    if (CLIENT_ADMINS.includes(this.user.roleCode)) query.referenceCode = 'HOLISTA'
    this.userService
      .getUsers(query)
      .then((res: any) => {
        this.assignedToCCList = [];
        this.assignedToESList = [];
        if (res.rows.length != 0) {
          this.fullUserList = res.rows;
          res.rows.map(x => {
            let data = { label: x.firstName + ' ' + x.lastName, value: x.id };
            if (x.roles && x.roles.length != 0) {
              const userRoles = x.roles.map(r => r.roleCode)
              if (userRoles.includes('CC') && x.status === 'active') {
                this.assignedToCCListBackup = [...this.assignedToCCListBackup, data];
                this.assignedToCCList = [...this.assignedToCCList, data];
              }
              if (userRoles.includes('ES') && x.status === 'active') {
                this.assignedToESList = [...this.assignedToESList, data];
                this.assignedToESListBackup = [...this.assignedToESListBackup, data];
              }
            }
          });
          // this._storage.set('session', 'assignedToCCList', this.assignedToCCList);
          // this._storage.set('session', 'assignedToESList', this.assignedToESList);
        }
      });
  }

  goToMileStone(episode) {
    // this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb('MILESTONE'));
    this.router.navigate(['episodes', episode.id, 'milestone'], { state: { data: episode } });
    this._storage.set('session', 'episodeResultSettings', { page: this.page, searchKeyword: this.searchKeyword, sortBy: this.sortBy, sortOrder: this.sortOrder });
    this.selectedEpisode = episode;
  }

  getProviders(episodes) {
    this.filterModel.filterProviders = [];
    const validEpisode = episodes.filter(episode => episode.provider && episode.provider.name && episode.providerId)
    const uniqueProvider: any = validEpisode.filter((provider, i) => provider && (validEpisode.findIndex(x => x.provider.name == provider.provider.name) == i));
    uniqueProvider.map(x => {
      let data = { label: x.provider.name, value: x.providerId };
      this.filterModel.filterProviders = [...this.filterModel.filterProviders, data];
    })
  }

  getFilterFacilities() {
    this.filterModel.filterFacility = [];
    this.episodeService.getUniqueProviders({ entityCode: 2 }).subscribe((response: any) => {
      this.filterModel.filterFacility = response.map(res => ({ label: res.name, value: res.providerId }))
    }, error => {
      console.log('error fetching facilities', error);
    });
  }

  async getEpisodesDropdown() {
    await this.episodeService.getEpisodesDropdown()
      .then((res: any) => {
        this.filterModel.episodeDropdownList = [];
        const validEpisodes = res.rows.filter(episode => episode.facility && episode.facility.name && episode.facilityId && (episode.episodeBundle && episode.episodeBundle.name || episode.episodeOfCare.bundle.name) && (episode.patient || episode.claimPatient));
        this.getBundles(validEpisodes);
        this.getFilterFacilities();
        this.getProviders(validEpisodes);
        this.allEpisodes = validEpisodes
        const uniqueEpisodes = validEpisodes.filter((episode, i) => episode && (validEpisodes.findIndex(x => x.name == episode.name) == i));
        uniqueEpisodes.map((episode: any) => {
          let data = { label: episode.name.trim(), value: episode.name, patient: episode.patient, purchaser: episode.purchaser };
          this.filterModel.episodeDropdownList = [...this.filterModel.episodeDropdownList, data];
        })
      }, (error) => {
        console.log("Error getting Episode Dropdown List", error);
      })
  }

  getBundles(episodes) {
    this.filterModel.filterBundles = [];
    const instanceBundles = episodes.filter((bundle, i) => bundle && (episodes.findIndex(x => x.episodeBundle && bundle.episodeBundle && (x.episodeBundle.name == bundle.episodeBundle.name)) == i));
    const originalBundles = episodes.filter((bundle, i) => bundle && (episodes.findIndex(x => x.episodeOfCare.bundle && bundle.episodeOfCare.bundle && (x.episodeOfCare.bundle.name === bundle.episodeOfCare.bundle.name)) === i));
    const combinedBundles = [...instanceBundles, ...originalBundles];

    combinedBundles.map((x: any) => {
      let bundleExists = false;
      if (x.episodeOfCare.bundle && x.episodeOfCare.bundle.name) {
        bundleExists = this.filterModel.filterBundles.some(bundle => bundle.value === x.episodeOfCare.bundle.name);
      }
      let data = { label: !bundleExists && x.episodeBundle ? x.episodeBundle.name : x.episodeOfCare.bundle.name, value: !bundleExists && x.episodeBundle ? x.episodeBundle.name : x.episodeOfCare.bundle.name };
      this.filterModel.filterBundles = [...this.filterModel.filterBundles, data];
    })
    this.filterModel.filterBundles = [...new Map(this.filterModel.filterBundles.map(obj => ([JSON.stringify(obj), obj]))).values()]; //gets unique bundle eliminating duplicates
    this.filterModel.filterBundles = this.filterModel.filterBundles.map(({ label, value }) => ({
      label: label.trim(),
      value: value.trim()
    }))
  }

  addEpisodeProviderControl(episode) {
    if (!episode.episodeProviders && this.bundleComponentWithShowInEpisode.length) {
      this.bundleComponentWithShowInEpisode.forEach(eachBundleComponent => {
        this.providerListForm.addControl(eachBundleComponent.uuid, new FormControl(''))
      })
    } else {
      Object.keys(episode.episodeProviders).forEach(eachBundleProviderFromEpisode => {
        this.providerListForm.addControl(eachBundleProviderFromEpisode, new FormControl(episode.episodeProviders[eachBundleProviderFromEpisode]))
      })

      this.bundleComponentWithShowInEpisode.forEach(eachBundle => {
        const bundleUuid = eachBundle.uuid;
        if (!(bundleUuid in episode.episodeProviders)) {
          this.providerListForm.addControl(eachBundle.uuid, new FormControl(''))
        }

      })
    }
  }


  async editEpisode(episode) {
    this.isNewEpisodeModal = false;
    this.hideEpisodeProviderForm = true;
    this.isEpisodeCancelled = false;
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      if (EPISODE_CANCELLED.includes(episode?.status)) {
        this.isEpisodeCancelled = true;
      }
      this.episodeCreate.getInfo = true
      const pathway = await this.getPathwayById(episode.episodeOfCare.pathwayUuid)
      const bundle = await this.getBundleById(episode.episodeOfCare.bundleUuid)
      this.bundleComponentWithShowInEpisode = bundle.components.filter((bundleComponent) => {
        if (bundleComponent?.isPrimary) {
          const bundleComponentWithShowInEpisodeOnly = bundleComponent.showInEpisode && !bundleComponent.isPrimary && !bundleComponent.isMedicalCenter
          return bundleComponentWithShowInEpisodeOnly
        }

        return bundleComponent.showInEpisode && !bundleComponent.isMedicalCenter;
      });
      if (episode.episodeProviders || this.bundleComponentWithShowInEpisode.length) {
        this.addEpisodeProviderControl(episode)
      }

      this.hideEpisodeProviderForm = false;

      this.bundleDetails = bundle
      const data = {
        pathway: pathway.name,
        bundle: episode.episodeBundle ? episode.episodeBundle.name : episode.episodeOfCare.bundle.name,
        memberResponsibility: episode.patientResponsibility,
        bundleUuid: episode.episodeOfCare.bundleUuid
      }
      if (bundle && bundle.components.length) this.episodeCreate.triggerComponent = bundle.components.find(comp => comp.isMedicalCenter)
      this.episodeCreate.selected_episodeCare = data
      episode.bundleName = episode.episodeBundle ? episode.episodeBundle.name : episode.episodeOfCare.bundle.name;
      episode.facilityName = episode.facility.name
      episode.episodeProviders = episode.episodeProviders
      episode.providerName = episode.provider && episode.provider.name ? episode.provider.name : episode.provider
      episode.clinicName = episode.clinic && episode.clinic.name ? episode.clinic.name : episode.clinic
      episode.eocUuid = episode.episodeOfCare.uuid
      episode.assignedToCCId = episode.ccId;
      episode.assignedToESId = episode.esId;
      this.createUserSearch.user_search_text.next(this.holistaUtils.getFullName(episode.patient || episode.claimPatient));
      const episodeOfCare = { label: episode.name, value: episode.episodeOfCare.uuid }
      this.episodeCreate.episodeCareList = [...this.episodeCreate.episodeCareList, episodeOfCare]
      this.getFacilities(episode.networkCode);
      const tag = { label: episode.tag, value: episode.tag }
      this.episodeCreate.tagsList = [...this.episodeCreate.tagsList, tag]
      this.episodeCreate.search_member = { name: this.holistaUtils.getFullName(episode.patient || episode.claimPatient), id: episode.userId };
      // to check inactive user
      let index = this.assignedToCCList.findIndex(x => x.value == episode.ccId)
      if (index == -1) {
        let inactiveCC = this.fullUserList.find(x => x.id == episode.ccId);
        if (inactiveCC) {
          inactiveCC = { label: inactiveCC.firstName + ' ' + inactiveCC.lastName, value: inactiveCC.id }
          this.assignedToCCList = [...this.assignedToCCList, inactiveCC];
        }
      }
      let indexES = this.assignedToESList.findIndex(x => x.value == episode.esId)
      if (indexES == -1) {
        let inactiveES = this.fullUserList.find(x => x.id == episode.esId);
        if (inactiveES) {
          inactiveES = { label: inactiveES.firstName + ' ' + inactiveES.lastName, value: inactiveES.id }
          this.assignedToESList = [...this.assignedToESList, inactiveES];
        }
      }
      // to check inactive user
      // if (this.user.companyCode !== 'HOLISTA') {
      //   const ccId = this.assignedToCCList.find(x => x.value === episode.ccId);
      //   const esId = this.assignedToESList.find(x => x.value === episode.esId);
      //   if (!ccId) {
      //     const assignCC = { label: episode.ccName, value: episode.ccId };
      //     this.assignedToCCList = [...this.assignedToCCList, assignCC];
      //   }
      //   if (!esId) {
      //     const assignES = { label: episode.esName, value: episode.esId };
      //     this.assignedToESList = [...this.assignedToESList, assignES];
      //   }
      // }
      episode.isProcedureDatePassed = moment(episode.procedureDate).format() < moment(new Date()).format();
      this.episodeForm.patchValue(episode);
      this.episode = episode;
      this.getAssociatedProviders(episode)
      if (episode.providerId) this.getAssociatedClinics(episode)
      this.episodeModal.show();
    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  closeModal() {
    this.episodeForm.reset();
    this.episodeModal.hide();
    this.hideEpisodeProviderForm = true
    this.isSubmitEnabled = false;
    this.submitted = false;
    this.createUserSearch.user_search_text.next('');
    this.episodeCreate = new EpisodeCreate()
    this.assignedToESList = [...this.assignedToESListBackup];
    this.assignedToCCList = [...this.assignedToCCListBackup];
    this._storage.remove('session', 'patientEpisode');
    this.episodeCreate.selected_episodeCare = null
    this.selectedPatient = null
    this.setProviderListForm()
  }

  submitEpisode() {
    this.submitted = true;
    if (this.episodeForm.valid) {
      this.isSubmitEnabled = true;
      const { eocUuid, assignedToESId, assignedToCCId, userId, memberUuid, facilityId, providerId, clinicId, facilityName, providerIdList, providerName, clinicName, tag, bundleName, id } = this.episodeForm.value;
      const { facilityName: prevFacility, providerName: prevProvider, clinicName: prevClinic, ccName: prevCcName, esName: prevEsName } = this.episode ?? {};
      const data = {
        eocUuid: eocUuid ? eocUuid : null,
        marketCode: this.episodeCreate.selectedFacilityMarketCode[0],
        facilityId: facilityId,
        facilityName: facilityName ? facilityName : null,
        providerId: providerId ? providerId : null,
        episodeProviders: this.providerListForm.value,
        providerName: providerName,
        clinicId: clinicId ? clinicId : null,
        clinicName: clinicName,
        userId: userId,
        memberUuid: memberUuid,
        ccId: assignedToCCId,
        esId: assignedToESId,
        bundleName: id ? bundleName : this.episodeCreate.selected_episodeCare.bundle,
        tag: tag,
        vbpCode: this.episodeCreate.vbpCode,
        prevFacility,
        prevProvider,
        prevClinic,
        ccName: this.episodeCreate.selected_CC?.label,
        esName: this.episodeCreate.selected_ES?.label,
        prevCcName,
        prevEsName
      };
      if (id) this.updateEpisode(data);
      else this.saveEpisode(data);
    }
  }

  saveEpisode(data) {
    this.episodeService.saveEpisode(data)
      .pipe(finalize(() => {
        this.isSubmitEnabled = false;
      })).subscribe((res: any) => {
        if (res) {
          this.page = 1;
          this.searchEpisode(1);
          this._toastr.displaySuccess(ADD_SUCCESS);
          this.closeModal();
        }
      },
        error => {
          console.log('Error', error);
          this._toastr.displayError(error.error.message);
        }
      );
  }

  updateEpisode(data) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      data.id = this.episodeForm.value.id
      this.episodeService.updateEpisode(data)
        .pipe(finalize(() => {
          this.isSubmitEnabled = false;
        })).subscribe((res: any) => {
          if (res) {
            this.searchEpisode(this.page);
            this._toastr.displaySuccess(UPDATE_SUCCESS);
            this.closeModal();
          }
        },
          error => {
            console.log("Error", error);
            this._toastr.displayError(error.error.message);

          }
        );
    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  OpenFilterModal() {
    const filter_data = this.filterForm.value;
    if (this.userNameChanges === '' && this.filterModel.filter_submitted) {
      filter_data.userName = '';
      this._storage.set('session', 'episodeFilterData', filter_data);
    }
    const filterData = this._storage.get('session', 'episodeFilterData');
    if (filterData) {
      const { formData } = filterData;
      this.filterForm.reset();
      this.filterForm.patchValue(formData);
    }
    if (filterData && filterData.formData && filterData.formData.filterEnabled) {
      this.filterUserSearch.user_filter_text.next(filterData.formData.userName);
    }
    this.filterModal.show();
  }

  submitFilterEpisode() {
    this.filterModel.filter_submitted = true;
    this.filterList.length = 0;
    if (this.filterForm.value.bundleName && !Array.isArray(this.filterForm.value.bundleName)) {
      let bundles = [];
      bundles.push(this.filterForm.value.bundleName);
      this.filterForm.controls.bundleName.setValue(bundles);
    }
    let filter_data = this.filterForm.value;
    let filterValue: any = { formData: filter_data, backup: {} }
    this.defaultClient = filter_data.clientCode ? filter_data.clientCode : null;
    this.searchEpisode(1);
    //show applied tags for episode filter
    Object.keys(filter_data).forEach(key => {
      let value = filter_data[key];
      if (value && key == 'name') {
        this.updateFilterApplied('Episode', key, value, this.filterModel.episodeDropdownList);
        filterValue.backup.episodeDropdownList = this.filterModel.episodeDropdownList
      }
      if (value && key == 'ccId') {
        this.updateFilterApplied('Care Coordinator', key, value, this.assignedToCCList);
        filterValue.backup.assignedToCCList = this.assignedToCCList
      }
      if (value && key == 'esId') {
        this.updateFilterApplied('Engagement Specialist', key, value, this.assignedToESList);
        filterValue.backup.assignedToESList = this.assignedToESList
      }
      if (value && key == 'bundleName') {
        this.updateFilterApplied('Bundle', key, value, this.filterModel.filterBundles);
        filterValue.backup.filterBundles = this.filterModel.filterBundles
      }
      if (key == 'status') {
        this.updateFilterApplied('Status', key, value, this.filterModel.statuses);
        filterValue.backup.statuses = this.filterModel.statuses
      }
      if (value && key == 'providerId') {
        this.updateFilterApplied('Provider', key, value, this.filterModel.filterProviders);
        filterValue.backup.filterProviders = this.filterModel.filterProviders
      }
      if (value && key == 'facilityId') {
        this.updateFilterApplied('Facility', key, value, this.filterModel.filterFacility);
        filterValue.backup.filterFacility = this.filterModel.filterFacility
      }
      if (value && key == 'userId') {
        this.updateFilterApplied('Member', key, value, this.filterModel.tempUserList)
        filterValue.backup.tempUserList = this.filterModel.tempUserList
      };
      if (value && key == 'companyId') {
        this.updateFilterApplied('Company', key, value, this.filterModel.companyList)
        filterValue.backup.companyList = this.filterModel.companyList
      };
      if (value && key == 'clientCode') {
        this.updateFilterApplied('Client', key, value, this.filterModel.clientList)
        filterValue.backup.clientList = this.filterModel.clientList
      };
      if (value && key == 'purchaserCode') {
        this.updateFilterApplied('Purchaser', key, value, this.filterModel.purchaserList)
        filterValue.backup.purchaserList = this.filterModel.purchaserList
      };
      if (value && key == 'networkCode') {
        this.updateFilterApplied('Network', key, value, this.filterModel.networkList)
        filterValue.backup.networkList = this.filterModel.networkList
      };
    });
    if (this.filterList.length == 0) filterValue.formData.filterEnabled = false
    else filterValue.formData.filterEnabled = true
    this._storage.set('session', 'episodeFilterData', filterValue);
    this.filterModal.hide();
  }

  closeFilterModal() {
    this.filterModel.filter_submitted = false;
    if (this.filterList && this.filterList.length > 0) {
      this.filterList.map(y => {
        if (y.formField == 'userId') {
          this.filterUserSearch.user_filter_text.next(y.label);
          this.filterForm.controls[`${y.formField}`].setValue(y.value);
        }
      });
    } else {
      this.filterUserSearch.user_filter_text.next('');
      this.filterForm.reset()
    }
    this.filterModal.hide();
  }

  ResetFilter() {
    this.filterUserSearch.user_filter_text.next('');
    this.filterForm.reset();
    this.filterForm.controls.status.setValue('');
    this.filterList.length = 0;
    this.defaultClient = null
    this.filterModel.clientList = this.filterModel.clientList.map(client => {
      client.disabled = false
      return client
    })
    this._storage.remove('session', 'episodeFilterData');
    this.filterForm.controls.filterEnabled.setValue(false);
    this.searchEpisode(1);
  }

  removeFilter(filter) {
    const { formField, value } = filter;
    const filteredData = this.filterForm.get(formField).value;

    if (filteredData && formField === 'userId') {
      this.filterUserSearch.user_filter_text.next('');
      this.filterForm.controls.userName.setValue('');
      this.filterForm.controls.subscriberNumber.setValue(null);
    }
    if (filteredData && filteredData.length && typeof filteredData === 'object') {
      const removeIndex = filteredData.findIndex((x: string | number) => x.toString() === value.toString());
      if (removeIndex > -1) {
        filteredData.splice(removeIndex, 1);
      }
    }
    if (filteredData && formField === 'clientCode') {
      this.defaultClient = null;
      this.filterModel.clientList = this.filterModel.clientList.map(client => {
        client.disabled = false;
        return client;
      })
    }
    this.filterForm.get(formField).reset();
    this.filterForm.controls[`${formField}`].setValue(filteredData && typeof filteredData === 'object' && filteredData.length ? filteredData : null);
    this.submitFilterEpisode();
  }

  selectOpened(episode) {
    this.initialStatus = episode.status;
    episode.episodeActionStatus = [];
    this.allowedEpisodeStatus && this.allowedEpisodeStatus[episode.status].forEach(value => {
      // To avoid showing closed and completed status for episodes that have numberOfDays < 90 
      if (episode.numberOfDays < 90 && episode.status === 'CONFIRMED' && (value === 'CLOSED' || value === 'COMPLETED')) return
      const statusDetail = EPISODESTATUS.find(status => status.value === value)
      episode.episodeActionStatus = [...episode.episodeActionStatus, statusDetail];
    })
    return episode.episodeActionStatus
  }

  optionSelected(event, episode) {
    if (event.value === 'CANCELLED') {
      this.episodeToBeCancel = { event, episode };
    } else {
      let body = {
        episodeId: episode.id,
        episodeStatus: event.value,
        isCancelled: false
      };
      this.episodeService
        .updateEpisodeStatus(body)
        .pipe(
          finalize(() => {
          })
        )
        .subscribe(
          res => {
            this.episodes.map(x => {
              if (res.data.id === x.id) {
                x.cancelReason = res.data.cancelReason
                x.status = res.data.status
                this.episodeActionStatus = [];
                const statusChangedMessage = this._messageConstants.getMessage(EPISODE_STATUS_CHANGED, this.holistaUtils.properCase(x.status));
                this._toastr.displaySuccess(statusChangedMessage.value);
              }
              return x;
            });
            this.selectOpened(episode);
          },
          error => {
            console.log("Error", error);
          }
        );
    }
  }

  async getPathwayById(uuid) {
    return await this.pathwayService.getPathwayById(uuid, { paramType: 'uuid' }).then((pathway: any) => {
      return pathway
    })
      .catch((error) => {
        console.log('Error', error)
      })
  }

  async getBundleById(uuid) {
    return await this.bundleService.getBundleById(uuid, { paramType: 'uuid' }).then((bundle: any) => {
      return bundle
    })
      .catch((error) => {
        console.log('Error', error)
      })
  }

  bundleComponentProviderListDisabler(bundleComponent) {
    if (this.bundleComponentProviderDisabler) {
      return true
    }

    if (this.bundleComponentListProvidersLoader) {
      return true
    }

    if (this.bundleComponentProviderList && !this.bundleComponentProviderList[bundleComponent?.uuid]?.length) {
      return true
    }

    if (this.isEpisodeCancelled) {
      return true;
    }

    return false
  }

  async episodeCareSelected(episodeCare) {
    this.episodeCreate.networkCode = episodeCare.networkCode;
    this.isEocDetailLoading = true
    this.episodeCreate.getInfo = true
    this.episodeCreate.facilityList = []
    this.episodeCreate.providerList = []
    this.episodeCreate.clinicList = []
    this.episodeCreate.tagsList = [];
    this.bundleComponentProviderList = {};
    this.episodeForm.controls.facilityId.setValue(null);
    this.episodeForm.controls.providerId.setValue(null);
    this.episodeForm.controls.clinicId.setValue(null);
    this.episodeForm.controls.tag.setValue(null);
    this.episodeForm.controls.facilityName.setValue('');
    this.episodeForm.controls.providerName.setValue('');
    this.episodeForm.controls.clinicName.setValue('');
    this.episodeCreate.vbpCode = episodeCare.vbpCode
    const memberBenefitCode = this.memberPlansInfo.find(x => x.programId == episodeCare.vbpCode)
    const memberPlanGroup = await this.episodeOfCareService.getMemberPlanCode(memberBenefitCode.benefitPlanCode, { paramType: 'code', purchaserCode: this.selectedPatient.referenceCode }).then(async (res: any) => {
      if (res && res.planGroups) {
        const plangroup = res.planGroups.find(x => x.purchaserCode == res.purchaserCode)
        return plangroup ? plangroup.planGroupCode : null
      }
      return null;
    })
    forkJoin([this.getPathwayById(episodeCare.pathwayUuid),
    this.getBundleById(episodeCare.bundleUuid),
    this.episodeOfCareService.getMemberPlanGroup(episodeCare.value, { limit: 0 }),
    this.bundleCostService.getBundleCostByBundleUuid({ bundleUuid: episodeCare.bundleUuid, limit: 0 })
    ]).subscribe(([pathway, bundle, memberResponsibility, bundleCost]: any) => {
      this.bundleComponentWithShowInEpisode = bundle.components.filter((bundleComponent) => {
        if (bundleComponent?.isPrimary) {
          return bundleComponent.showInEpisode && !bundleComponent.isPrimary && !bundleComponent.isMedicalCenter
        }

        return bundleComponent.showInEpisode && !bundleComponent.isMedicalCenter;
      });
      const formControlList = [];

      this.bundleComponentWithShowInEpisode.forEach((eachBundle) => {
        formControlList.push({ bundleComponentUuid: eachBundle.uuid, control: new FormControl('') })
      })
      this.bundleComponentListProvidersLoader = false

      formControlList.forEach(f => {
        this.providerListForm.addControl(f.bundleComponentUuid, f.control)
      })

      this.isEocDetailLoading = false
      this.episodeCreate.facilityList = []
      this.episodeCreate.providerList = []
      this.episodeCreate.clinicList = []
      this.episodeCreate.bundleComponentProviders = []
      this.episodeCreate.episodeCareMarketCodes = []
      // set pathway
      if (pathway && pathway.id) {
        episodeCare.pathway = pathway.name
        episodeCare.tags = pathway.tags
        let tags = JSON.parse(JSON.stringify(episodeCare.tags))
        if (tags) {
          tags = JSON.parse(episodeCare.tags)
          tags.map(x => {
            const data = { label: x, value: x }
            this.episodeCreate.tagsList = [...this.episodeCreate.tagsList, data]
          })
        }
      }
      // set bundle
      if (bundle && bundle.id) episodeCare.bundle = bundle.name
      this.bundleDetails = bundle

      // set memberResponsibility
      if (memberResponsibility.rows.length != 0 && memberPlanGroup) {
        episodeCare.memberResponsibility = memberResponsibility.rows.find(x => x.planGroup == memberPlanGroup).patientResponsibility
      }

      if (bundle && bundle.components.length) this.episodeCreate.triggerComponent = bundle.components.find(comp => comp.isMedicalCenter)

      // set bundle cost
      bundleCost.rows.map(x => {
        this.episodeCreate.episodeCareMarketCodes = [...this.episodeCreate.episodeCareMarketCodes, x.marketCode]
      })
      if (this.episodeCreate.episodeCareMarketCodes && this.episodeCreate.episodeCareMarketCodes.length > 0) {
        this.getFacilities(episodeCare.networkCode)
      } else {
        this.episodeCreate.getInfo = false
        this._toastr.displayError(NO_MARKET_CODE);
      }
    },
      (error) => {
        this.isEocDetailLoading = false
        this.episodeCreate.getInfo = false
        console.log('Error', error)
      })
    this.episodeCreate.selected_episodeCare = episodeCare
  }

  getFacilities(networkCode: string) {
    this.episodeService.getFacilities(this.episodeCreate.selected_episodeCare.bundleUuid, networkCode)
      .pipe(finalize(() => { this.episodeCreate.getInfo = false }))
      .subscribe(res => {
        this.episodeCreate.facilityList = []
        if (res.providerList.length !== 0) {
          res.providerList.map(x => {
            const data = { label: x.displayName && x.displayName !== '' ? x.displayName : x.businessName, value: x.providerId, marketCode: x.tag, networkCode: x.network }
            this.episodeCreate.facilityList = [...this.episodeCreate.facilityList, data]
          })
        } else {
          this._toastr.displayError(NO_FACILITY_FOUND);
        }
        if (!this.episodeCreate.facilityList.length) {
          this.bundleComponentProviderDisabler = true;
          this.bundleComponentListProvidersLoader = false;
          const providerList = this.providerListForm.value;
          Object.keys(providerList).forEach(bundleComponentUuid => {
            this.providerListForm.controls[bundleComponentUuid].setValue('')
          })
        } else {
          this.bundleComponentProviderDisabler = false;
        }
      },
        (error) => {
          console.log('Error', error)
          this._toastr.displayError(error.error.message);
        })
  }

  ViewPatientDemographic(userId) {
    this.patientDemographicId = userId;
  }
  closePatientDemographic(e) {
    this.patientDemographicId = null;
  }

  abortEpisodeCancel(e) {
    this.episodeToBeCancel = null;
  }

  episodeCancelled(e) {
    this.episodes.map(x => {
      if (e.data.id == x.id) {
        x.status = 'CANCELLED';
        x.isCancelled = e.data.isCancelled;
        x.cancelReason = e.data.cancelReason;
        x.episodeActionStatus = this.selectOpened(e.data)
        const statusChangedMessage = this._messageConstants.getMessage(EPISODE_STATUS_CHANGED, this.holistaUtils.properCase(!x.status ? 'PRELIMINARY' : x.status));
        this._toastr.displaySuccess(statusChangedMessage.value);
      }
      return x;
    });
  }

  selectedCC(event) {
    this.episodeCreate.selected_CC = event;
  }

  selectedES(event) {
    this.episodeCreate.selected_ES = event;
  }

  viewIdCard(episode) {
    this.patientIdCard = { memberUuid: episode.patient.memberUuid, episodeId: episode.id }
  }

  idCardClose(event) {
    this.patientIdCard = event;
  }

  searchUserChange() {
    this.createUserSearch.user_search_text.subscribe((res: any) => {
      if (res.length < 3) {
        this.bundleComponentWithShowInEpisode = []
        this.bundleComponentProviderList = {}
        this.episodeCreate = new EpisodeCreate()
        this.episodeForm.reset()
        this.providerListForm.reset()
        this.bundleComponentProviderList = {}
        this.selectedPatient = null
        this.episodeForm.controls.userId.setValue(null);
      }
    });
    if (this.episodeCreate.search_member) {
      this.createUserSearch.user_search_text.next(this.episodeCreate.search_member.name)
    }
  }

  resetValuesOfProviderListForm() {
    const providerListValues = this.providerListForm.value;
    Object.keys(providerListValues).forEach((eachBundle) => {
      this.providerListForm.controls[eachBundle].setValue('')
    })
  }

  async facilitySelected(facility, isSelected: boolean = false) {
    this.episodeCreate.providerList = []
    this.resetValuesOfProviderListForm()
    this.episodeForm.controls.providerName.setValue('')
    this.episodeForm.controls.facilityName.setValue(facility.label)
    this.episodeForm.controls.providerId.setValue(null)
    this.episodeForm.controls.clinicId.setValue(null)
    this.episodeForm.controls.clinicName.setValue('')
    await this.getAssociatedProviders(facility, isSelected);
  }

  async providerSelected(provider) {
    this.episodeCreate.clinicList = []
    this.episodeForm.controls.clinicName.setValue('')
    this.episodeForm.controls.providerName.setValue(provider.label)
    this.episodeForm.controls.clinicId.setValue(null)
    await this.getAssociatedClinics(provider)
  }

  async bundleComponentProviderMapper(marketCode) {
    const bundleCost = this.bundleDetails.bundleCost.find(eachBundleCost => eachBundleCost.marketCode == marketCode);
    if (this.bundleComponentWithShowInEpisode.length) {
      let showInEpisodeIds = {}
      this.bundleComponentWithShowInEpisode.forEach((showInEpisode) => {
        {
          showInEpisodeIds[`"${showInEpisode.name}"`] = showInEpisode.uuid
        }
      });
      this.bundleCostService.getProvidersByBundleComponentUuid({ bundleComponents: showInEpisodeIds, marketCode: marketCode, bundleCostUuid: bundleCost.uuid }).subscribe((providerList) => {
        let bundleComp = {}
        if (providerList?.length) {
          const groupedData = {};
          providerList.forEach(obj => {
            const key = obj.bundleComponentUuid;
            if (!groupedData[key]) {
              groupedData[key] = { ...obj };
            } else {
              groupedData[key].providerList.push(...obj.providerList);
            }
          });
          this.bundleComponentProviderDisabler = false;
          this.bundleComponentListProvidersLoader = false;
          const newProviderList: any = Object.values(groupedData);
          newProviderList.forEach(eachProvider => {
            let bundleComponentsWithProviders = [];
            eachProvider.providerList.forEach(updatedProvider => {
              const providersLabel: any = {};
              providersLabel.label = updatedProvider.displayName ? updatedProvider.displayName : updatedProvider.businessName
              providersLabel.value = updatedProvider.providerId
              providersLabel.bundleComponentUuid = eachProvider.bundleComponentUuid
              bundleComponentsWithProviders.push(providersLabel)
              bundleComp[eachProvider.bundleComponentUuid] = bundleComponentsWithProviders
            })
          })
        }
        this.bundleComponentListProvidersLoader = false;
        this.bundleComponentProviderList = bundleComp;
        if (!this.episodeCreate.facilityList.length) {
          this.bundleComponentProviderDisabler = true;
        }
        if (this.isNewEpisodeModal) {
          Object.keys(bundleComp).forEach(eachBundle => {
            if (bundleComp[eachBundle].length == 1) {
              const autoAddedBundle = bundleComp[eachBundle][0].value
              this.providerListForm.controls[eachBundle].setValue(autoAddedBundle)
            }
          })
        }
      })
    }
  }

  clinicSelected(clinic) {
    const { label } = clinic;
    const index = label.lastIndexOf('-');
    this.episodeForm.controls.clinicName.setValue(index !== -1 ? label.substring(0, index - 1) : label);
  }

  tagDisabled(episodeFormValue, tagsList) {
    const { id, status } = episodeFormValue;
    if (id || tagsList.length == 0 || EPISODE_STATUS_LIST.includes(status)) {
      return true
    }
    return false
  }

  disableClinic() {
    if (this.episodeForm.value.id && this.episode?.isProcedureDatePassed) {
      return true
    }

    if (!this.episodeCreate.search_member) {
      return true
    }

    if (!this.episodeForm.value.facilityId) {
      return true
    }

    if (!this.episodeForm.value.providerId) {
      return true
    }

    if (!this.episodeCreate.clinicList) {
      return true
    }

    if (this.episodeCreate.clinicList.length === 0) {
      return true
    }

    if (EDIT_EPISODE_DISABLED_STATUSES.includes(this.episodeForm.value.status)) {
      return true
    }
    return false
  }

  facilityDisabled(user) {
    if (!FULL_EPISODE_EDIT_ELIGIBLE_ROLES.includes(user.roleCode)) {
      return true
    }


    if (EDIT_EPISODE_DISABLED_STATUSES.includes(this.episodeForm.value.status)) {
      return true
    }

    if (!this.episodeCreate.search_member) {
      return true
    }


    if (!this.episodeCreate.facilityList) {
      return true
    }

    if (this.episodeCreate.facilityList.length === 0) {
      return true
    }

    return false
  }

  async getAssociatedProviders(facility, isSelected: boolean = false) {
    this.bundleComponentListProvidersLoader = true
    this.episodeCreate.selectedFacilityMarketCode = []
    let currentBundle: any
    this.episodeCreate.isGetProviders = true;
    this.bundleCostService.getBundleCostByBundleUuid({ bundleUuid: this.episodeCreate.selected_episodeCare.bundleUuid, limit: 0 })
      .pipe()
      .subscribe(async response => {
        if (response.count) {
          response.rows.map(x => {
            this.episodeCreate.episodeCareMarketCodes = [...new Set([...this.episodeCreate.episodeCareMarketCodes, x.marketCode])];
          });
          if (!this.episodeForm.value.id || isSelected) {
            this.episodeCreate.episodeCareMarketCodes.forEach(x => {
              facility?.marketCode?.forEach(y => {
                if (y.toUpperCase() === x.toUpperCase()) {
                  this.episodeCreate.selectedFacilityMarketCode.push(y)
                }
              })
            });
            const bundleUuid = this.episodeCreate.selected_episodeCare.bundleUuid;
            if (this.episodeCreate.selectedFacilityMarketCode.length > 1) {
              this.episodeCreate.selectedFacilityMarketCode = await this.getMarketCode({ bundleComponentUuid: this.episodeCreate.triggerComponent?.uuid, providerId: facility.value })
            }
            const marketCode = this.episodeCreate.selectedFacilityMarketCode[0];
            currentBundle = { bundleUuid: bundleUuid, marketCode: marketCode, providerId: facility.value };
          } else {
            currentBundle = { episodeId: facility.id };
            this.episodeCreate.selectedFacilityMarketCode.push(facility.marketCode);
            this.episodeCreate.networkCode = Array.isArray(facility.networkCode) ? this.episodeCreate.networkCode : facility.networkCode;
          }

          this.bundleComponentProviderMapper(currentBundle.marketCode ? currentBundle.marketCode : facility.marketCode)
          this.episodeService.getAssociatedProviders(currentBundle).then((res: any) => {
            if (res.length !== 0) {
              res = res.map(x => {
                return x.providerId
              })
              this.getVitafyProvidersById(res.join(' '))
            } else {
              this.episodeCreate.isGetProviders = false
              this._toastr.displayWarning(NO_PROVIDER_ASSOCIATED);
            }
          })
            .catch((error) => {
              this.episodeCreate.isGetProviders = false
              this._toastr.displayWarning(error.error.message);
              console.log('Error', error)
            })
        }
      },
        error => {
          console.log('error getting bundle costs', error);
        })
  }

  /**
   * Gets market code according to query (if bundle  uuid is passed in query, marketcodes of bundle is returned) 
   * @param query 
   * @returns market codes
   */

  onBundleComponentProviderClear(event) {
    this.providerListForm.controls[event.bundleComponentUuid].setValue('')
  }

  selectedProviderBundleComponent(event) {
    this.providerListForm.controls[event.bundleComponentUuid].setValue(event.value)
  }

  async getMarketCode(query) {
    return new Promise(resolve => {
      this.bundleCostService.getBundleComponentCostProvider(query).subscribe(
        (res: any) => {
          if (res.count) resolve(res.rows.map(x => x.marketCode))
          resolve(this.episodeCreate.selectedFacilityMarketCode) // In case of trigger component change
        }, error => {
          this._toastr.displayWarning(error.error.message);
          console.log('Error', error)
        })
    })
  }

  async getAssociatedClinics(provider) {
    this.episodeCreate.getInfo = true
    let body: any
    let id: any
    if (!this.episodeForm.value.id || !provider.providerId) { // in case of provider selected or changed
      id = provider.value
      body = {
        networkCode: this.episodeCreate.networkCode,
        physicianId: provider.value
      }
    } else { // in case edit episode provider data mapped
      id = provider.providerId
      body = {
        networkCode: provider.networkCode,
        physicianId: provider.providerId
      }
    }
    this.providerService.getAssociatedFacility(id, body)
      .pipe(finalize(() => { this.episodeCreate.getInfo = false }))
      .subscribe(res => {
        this.episodeCreate.clinicList = []
        if (res.length !== 0) {
          res.providerList.map(x => {
            x.tag = x.tag.map(t => t.toUpperCase());
            if (x.tag.includes(PROVIDER_TYPE.clinic.toUpperCase())) {
              const data = { label: `${x.displayName} - (N)${x.npi ? x.npi : 'N/A'}, (T)${x.taxId ? x.taxId : 'N/A'}`, value: x.providerId.toString() };
              this.episodeCreate.clinicList = [...this.episodeCreate.clinicList, data];
            }
          })
        }
      }, error => {
        this._toastr.displayWarning(error.error.message);
        console.log('Error', error)
      })
  }

  getVitafyProvidersById(providerIds) {
    this.episodeCreate.getInfo = true
    this.episodeService.getVitafyProvidersById(providerIds)
      .pipe(finalize(() => { this.episodeCreate.getInfo = false }))
      .subscribe(res => {
        this.episodeCreate.providerList = []
        if (res.length !== 0) {
          res.providerList.map(x => {
            const data = { label: x.displayName, value: x.providerId }
            this.episodeCreate.providerList = [...this.episodeCreate.providerList, data]
          })
        }
      },
        (error) => {
          console.log('Error', error)
        })
  }

  episodeCollapsed(episode) {
    episode.collapsed = !episode.collapsed;
  }

  filterEpisodeClient(event: any, status?) {
    this.filterModel.clientList = this.filterModel.clientList.map(client => {
      if (!status || status == 'filter') {
      } else client.disabled = false
      return client
    })
    if (!status || status == 'remove') {
      if (!status) this.filterForm.controls.clientCode.setValue(event.value)
      else this.filterForm.controls.clientCode.setValue(null)
      this.page = 1
      this.submitFilterEpisode()
    }
  }

  async exportToCsv() {
    this.isExportLoading = true;
    await this.getExportEpisode()
    const headerList = ["SN.", "Episode", "Patient", "Bundle", "Facility", "Care Coordinator", "Engagement Specialist", "Provider", "Client", "Purchaser", "Network", "Episode Status", "Created Date", "Benefit Start Date", "Benefit End Date"]
    const exportData: ExportData[] = [{
      exportList: this.exportEpisodeList, headers: headerList, excelName: 'Episode List', options: { action: 'getEpisodeOrProviderHeader' }
    }]
    this.excelService.exportExcel(exportData)
  }

  async getExportEpisode() {
    this.searchKeyword = this.episodeResultSettings && this.episodeResultSettings.searchKeyword ? this.episodeResultSettings.searchKeyword : this.searchKeyword;
    this.sortBy = this.episodeResultSettings && this.episodeResultSettings.sortBy ? this.episodeResultSettings.sortBy : this.sortBy;
    this.sortOrder = this.episodeResultSettings && this.episodeResultSettings.sortOrder ? this.episodeResultSettings.sortOrder : this.sortOrder;
    this.reverse = this.sortOrder === 'ASC' ? false : true;
    const clientCode = this.filterForm.value.clientCode ? this.filterForm.value.clientCode : null
    const formValue = JSON.parse(JSON.stringify(this.filterForm.value))
    if (formValue.bundleName) {
      formValue['episodeBundle.name'] = formValue.bundleName;
      formValue['episodeOfCare.bundle.name'] = formValue.bundleName;
    }
    delete formValue.bundleName
    if (this.searchKeyword || this.checkFilterFormData() || this.sortBy) {
      let body = { ...formValue, ...{ searchKeyword: this.searchKeyword ? this.searchKeyword.trim() : this.searchKeyword }, ...{ sortBy: this.sortBy }, ...{ sortOrder: this.sortOrder }, ...{ limit: this.totalCount }, ...{ clientCode: clientCode && typeof clientCode == 'number' ? [clientCode] : clientCode } };
      await this.episodeService
        .getEpisodesBySearchKeyword(body)
        .then((res: any) => {
          this.loading = false;
          this.exportEpisodeList = []
          this.isExportLoading = false;
          this.setExportData(res.rows)
        })
        .catch((error) => {
          this.loading = false;
          console.log('Error', error)
        })
    }
    else
      await this.getEpisodes('export');
  }

  setExportData(exportList) {
    this.exportEpisodeList = exportList.map((episode, i) => {
      episode.userName = this.holistaUtils.getFullName(episode.patient || episode.claimPatient)
      episode.bundleName = episode.episodeBundle ? episode.episodeBundle.name : episode.episodeOfCare.bundle.name || '';
      episode.facilityName = episode.facility && episode.facility.name ? episode.facility.name : ''
      episode.providerName = episode.provider && episode.provider.name ? episode.provider.name : ''
      episode.ccName = episode.cc ? this.holistaUtils.getFullName(episode.cc) : ''
      episode.esName = episode.es ? this.holistaUtils.getFullName(episode.es) : ''
      episode.purchaserName = episode.purchaser && episode.purchaser.name ? episode.purchaser.name : ''
      episode.networkName = episode.network && episode.network.name ? episode.network.name : ''
      episode.clientName = episode.client && episode.client.name ? episode.client.name : ''
      if (episode.ccName == 'No Assigned Care Coordinator') episode.ccName = ''
      if (episode.esName == 'No Assigned Engagement Specialist') episode.esName = ''
      episode.createdAt = moment(episode.createdAt).format('MM/DD/YYYY')
      episode.benefitStartDate = episode.benefitStartDate ? moment(episode.benefitStartDate).format('MM/DD/YYYY') : ''
      episode.benefitEndDate = episode.benefitEndDate ? moment(episode.benefitEndDate).format('MM/DD/YYYY') : ''
      return [i + 1, episode.name, episode.userName, episode.bundleName, episode.facilityName, episode.ccName, episode.esName, episode.providerName, episode.clientName, episode.purchaserName, episode.networkName, episode.status, episode.createdAt,
      episode.benefitStartDate, episode.benefitEndDate];
    })
  }

  removeSearchKeyword() {
    this.searchKeyword = ''
    this.searchEpisodeByKeyword('')
  }

  /**
   * removes selected option along with its dependant selected options if any
   * @param value 
   * @param action 
   */
  onOptionDeselected(value: string, action?: string) {
    action ? ((!this.filterForm.controls[value].value || !this.filterForm.controls[value].value.length) && this.filterForm.controls[value].setValue(null)) : (
      value === 'provider' && this.episodeForm.controls.providerName.setValue(null),
      this.episodeForm.controls.clinicId.setValue(null),
      this.episodeForm.controls.clinicName.setValue(null)
    );
  }

  onSendEmail(metaVariablesDetail) {
    this.metaVariablesDetail = { detail: metaVariablesDetail, module: 'episode' };
    this.moduleOptions = {
      module: "episodes",
      moduleId: metaVariablesDetail.id.toString(),
      type: 'communications'
    }
    this.showMessageModal = true;
  }

  closeMessageModal() {
    this.showMessageModal = false;
  }
}
