import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../action';
import { CompanyService } from '../../services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Company, AuthResponse } from '../../models';
import { AccessType, Storage, ScrollTo, FormatPhoneNumber } from '../../utils';
import { US_STATES, RestrictSpace, TrimFields, MaskConstant, PhoneFormat } from '../../constants';
import { Subject } from "rxjs";
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  companyList: any[] = [];
  loading: boolean = false;
  companyForm: FormGroup;
  payerForm: FormGroup;
  submit_enabled: boolean = false;
  submitted: boolean = false;
  mask: any[] = MaskConstant.PHONE;
  searchKeyword = '';
  displayParentList = [];
  tempParentInfo: any;
  loggedInUser: AuthResponse;
  usStates = US_STATES;
  companyId: any;
  reverse: boolean = true;
  result = { searchedCompanies: true, Companies: true };
  companyQuery = {
    limit: 10,
    page: 1,
    searchKeyword: '',
    orderBy: 'desc',
    sortBy: 'id',
  };
  totalCount = 0;
  searchModelChanged: Subject<string> = new Subject<string>();

  @ViewChild('companyModal', { static: true }) public companyModal;
  @ViewChild('payerModal', { static: true }) public payerModal;

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private companyservice: CompanyService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private utilityAccess: AccessType,
    private _storage: Storage,
    private _scrollTo: ScrollTo,
    private _formatPhone: FormatPhoneNumber
  ) {
    this.setCompanyForm();
    this.setPayerForm();
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
      this.searchKeyword = keyword;
      Object.assign(this.companyQuery, { page: 1, searchKeyword: keyword });
      this.getCompanys();
    });
  }

  ngOnInit() {
    this.loggedInUser = this._storage.get('local', 'loggedInUser');
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(
      new HeaderBreadCrumbActions.AddBreadCrumb({
        name: 'Companies',
        path: '/companies'
      })
    );
    this.getCompanys();
    if (this.loggedInUser.user.companyType == 'PLATFORM_OWNER')
      this.getParentCompany();
  }

  setCompanyForm() {
    this.companyForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(PhoneFormat)]],
      addressline1: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      addressline2: ['', Validators.pattern(RestrictSpace)],
      city: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
      zip4: ['', Validators.pattern(/^[0-9]{4}$/)],
      parentId: [null, Validators.required],
      id: [null],
      isActive: [true],
      companyCode: [''],
      identifier: ['', [Validators.required, Validators.maxLength(15)]],
      contactName: ['', [Validators.required]],
      npi: ['', [Validators.required, Validators.maxLength(10)]],
      tin: ['', [Validators.required, Validators.maxLength(9)]],
    });
  }

  setPayerForm() {
    this.payerForm = this.formBuilder.group({
      id: [null],
      name: ['', Validators.required],
      identifier: ['', [Validators.required, Validators.maxLength(15)]],
      npi: ['', Validators.maxLength(10)],
      tin: ['', Validators.maxLength(9)],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
      zip4: ['', Validators.pattern(/^[0-9]{4}$/)],
      phone: [''],
      contactName: [''],
      contactEmail: [''],
      billingHolistaNpi: ['', Validators.maxLength(10)]
    });
  }

  getCompanys() {
    this.loading = true;
    this.result.Companies = true;
    this.result.searchedCompanies = true;
    this.companyservice
      .getCompanys(this.companyQuery)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res: any) => {
        if (res && res.count > 0) {
          this.totalCount = res.count;
          this.companyList = res.rows;
        }
        else {
          this.companyList = [];
          this.companyQuery.searchKeyword.length > 0 ? (this.result.searchedCompanies = false) : (this.result.Companies = false);
        }
      }, (error) => {
        console.log('Error fetching Company list', error);
        this.toastr.error(`${error.error.message}`, "Error")
      })
  }

  getParentCompany() {
    this.companyservice.getParentCompanies()
      .subscribe((res: any) => {
        if (res && res.count > 0) {
          this.displayParentList = res.rows.map(company => {
            let data = { ...company, label: company.name, value: company.id };
            return data;
          });
        }
      }, (error) => {
        console.log('Error fetching parent company list', error);
        this.toastr.error(`${error.error.message}`, "Error")
      })
  }

  searchKeywordChange(text) {
    this.searchModelChanged.next(text);
  }

  pageChanged(event) {
    this.companyQuery.page = event;
    this.getCompanys();
  }

  setOrder(value: string) {
    if (this.companyQuery.sortBy === value) {
      this.reverse = !this.reverse;
      this.companyQuery.orderBy = this.companyQuery.orderBy === "desc" ? "asc" : "desc";
    }
    else {
      this.reverse = true;
      this.companyQuery.orderBy = "desc";
    }
    this.companyQuery.sortBy = value;
    this.getCompanys();
  }

  editCompany(company) {
    this.tempParentInfo = company.parentCompany;
    if (this.utilityAccess.searchAccess('CM', 'isEditable')) {
      if (!company.parentCompany) {
        this.companyForm.controls.parentId.clearValidators();
        this.companyForm.controls.parentId.updateValueAndValidity();
      }
      company.phone = this._formatPhone.phoneNumber(company.phone);
      if (company.zip && company.zip.length > 5) {
        company.zip4 = company.zip.slice(5);
        company.zip = company.zip.slice(0, -4);
      }
      this.companyForm.patchValue(company);
      this.companyModal.show();
    } else {
      this.toastr.warning('Access Denied', 'Warning');
    }
  }

  closeModal() {
    this.companyForm.reset();
    this.companyModal.hide();
    this.submit_enabled = false;
    this.submitted = false;
    this.companyForm.controls.parentId.setValidators([Validators.required]);
    this.companyForm.controls.parentId.updateValueAndValidity();
    // this.displayParentList = [];
  }

  openPayerModal(companyId, payerInfo?) {
    if (this.utilityAccess.searchAccess('CM', 'isEditable')) {
      this.payerModal.show();
      this.companyId = companyId;
      if (payerInfo) {
        if (payerInfo.zipCode && payerInfo.zipCode.length > 5) {
          payerInfo.zip4 = payerInfo.zipCode.slice(5);
          payerInfo.zipCode = payerInfo.zipCode.slice(0, -4);
        }
        this.payerForm.patchValue(payerInfo)
      }
    } else {
      this.toastr.warning('Access Denied', 'Warning');
    }
  }

  submitPayer() {
    this.submitted = true;
    if (this.payerForm.valid) {
      this.submit_enabled = true;
      if (this.payerForm.value.id) this.updateCompanyPayer();
      else this.saveCompanyPayer();
    }
  }

  updateCompanyPayer() {
    let payer = { ...this.payerForm.value };
    payer.phone = payer.phone ? payer.phone.replace(/\D/g, '') : '';
    payer.zipCode = payer.zip4 ? payer.zipCode + payer.zip4 : payer.zipCode;
    this.companyservice.updatePayer(this.companyId, payer)
      .pipe(finalize(() => this.submit_enabled = false))
      .subscribe((res: any) => {
        if (res) {
          this.companyList.find(x => x.id == res.companyId).payer = res;
          this.toastr.success('Payor information updated successfully.', 'Success');
        }
      }, (error) => {
        console.log('Error updating payer', error);
        this.toastr.error(`${error.error.message}`, 'Error');
      }, () => this.closePayerModal())
  }

  saveCompanyPayer() {
    let payer = { ...this.payerForm.value };
    payer.phone = payer.phone ? payer.phone.replace(/\D/g, '') : '';
    payer.zipCode = payer.zip4 ? payer.zipCode + payer.zip4 : payer.zipCode;
    this.companyservice.savePayer(this.companyId, payer)
      .pipe(finalize(() => this.submit_enabled = false))
      .subscribe((res: any) => {
        if (res) {
          this.companyList.find(x => x.id == res.companyId).payer = res;
          this.toastr.success('Payor information saved successfully.', 'Success');
        }
      }, (error) => {
        console.log('Error saving payer', error);
        this.toastr.error(`${error.error.message}`, 'Error');
      }, () => this.closePayerModal())
  }

  closePayerModal() {
    this.payerForm.reset();
    this.payerModal.hide();
    this.companyId = null;
    this.submitted = false;
  }

  submitCompany() {
    this.submitted = true;
    if (this.companyForm.valid) {
      this.submit_enabled = true;
      TrimFields.Company.forEach(element => {
        this.companyForm.value[element] = this.companyForm.value[element] ? this.companyForm.value[element].trim() : this.companyForm.value[element];
      });
      if (this.companyForm.value.id) this.updateCompany();
      else this.saveCompany();
    }
  }

  createCompany() {
    if (this.utilityAccess.searchAccess('CM', 'isEditable')) {
      this.companyModal.show();
    } else {
      this.toastr.warning('Access Denied', 'Warning');
    }
  }

  saveCompany() {
    let company = Object.assign(new Company(), this.companyForm.value);
    company.phone = company.phone.replace(/\D/g, '');
    company.zip = company.zip4 ? company.zip + company.zip4 : company.zip;
    this.companyservice
      .saveCompany(company)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          if (res.data) {
            res.data.parentCompany = {
              id: this.tempParentInfo.id,
              name: this.tempParentInfo.name
            };
            this.refreshCompany(res, 'saved');
          } else if (!res.isCreated && res.message) {
            this.toastr.warning(res.message, 'Warning');
          } else if (res.fields) {
            this.toastr.warning(`${res.fields.email} already exists`, 'Warning');
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  updateCompany() {
    let company = Object.assign(new Company(), this.companyForm.getRawValue());
    company.phone = company.phone.replace(/\D/g, '');
    company.zip = company.zip4 ? company.zip + company.zip4 : company.zip;
    company.isActive = true;
    this.companyservice
      .updateCompany(company)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          if (res.data) {
            res.data.parentCompany = this.tempParentInfo;
            this.refreshCompany(res, 'updated');
          } else if (!res.isCreated && res.message) {
            this.toastr.warning(`${res.message}`, 'Warning');
          }
        },
        error => {
          console.log('error', error);
          this.toastr.error(error, 'Error');
        }
      );
  }
  refreshCompany(response, action) {
    let company = response.data
    if (this.companyList && this.companyList.length > 0) {
      const index = this.companyList.findIndex(x => x.id === company.id);
      if (index > -1) {
        this.companyList[index] = { ...company };
      } else this.companyList.push({ ...company });
    } else {
      this.companyList.push({ ...company });
    }
    this.toastr.success(`Company ${action} successfully.`, 'Success');
    this.closeModal();
  }

  onParentCompanyChange(event: { label: string; value: number }) {
    this.tempParentInfo = this.displayParentList.find(company => company.id == event.value);
  }

  ScrollToTop() {
    this._scrollTo.ScrollTo('scrollToTop', 'center', 'smooth')
  }
}
