import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subject, Observable, of } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import * as HeaderBreadCrumbActions from '../../action'
import { DashboardService, MemberService, UserService } from '../../services'
import { finalize, startWith, switchMap, debounceTime, map } from 'rxjs/operators'
import * as moment from 'moment'
import { HolistaUtils, Sorting, RollingSevenDays, Storage } from '../../utils'
import { Router } from '@angular/router'
import { NOTETYPES, DashboardType } from '../../constants'
import { AdminTasks, Dashboard } from '../../models'
import * as Actions from '../../action'
import { DeviceDetectorService } from 'ngx-device-detector'
import { ThrowStmt } from '@angular/compiler'
import { RequestEocService } from 'src/app/services/request-eoc.service'
const ACCESS_TYPES = ["ERM"]
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  count$: Observable<number>
  loading = false
  noteloading = false
  dashboard = new Dashboard()
  user: any
  selectedEpisode: any = { label: null, value: null, data: null }
  userEpisodeList = []
  assignedUsers: any = []
  episodeNotes = []
  noteTypes = NOTETYPES
  patientDemographicId = null
  adminTaskLists = []
  patient_search_text = new Subject()
  user_search_text = new Subject()
  patient_results: Observable<any>
  user_results: Observable<any>
  allUsersList = []
  adminEpisodeList: any = []
  selected_user = null
  totalEOCCount = 0;
  isEocRequestPermitted = false;
  adminTasks = new AdminTasks()
  // chatUserList: any = []
  enableChat = false
  /* #region  for new desgin */
  searchText = new Subject()
  results: Observable<string[]>
  data: any = ['red', 'green', 'blue', 'cyan', 'magenta', 'yellow', 'black']
  isMobile = false
  showAdminDashboard = false
  showPatientDashboard = false
  isAssignedSupervisor: boolean = false
  criticalTasks: any = []
  nonCriticalTasks: any = []
  selectedDashboard = 'admin'
  dashboardTypes: any = []
  requestEocList: any = []
  patientSearchKey = false
  userSearchKey = false
  sortingType = {
    highPriorityTasks: 'asc',
    mediumPriorityTasks: 'asc',
    completedTasks: 'asc',
    highPriorityTasks2: 'asc',
    mediumPriorityTasks2: 'asc',
    completedTasks2: 'asc',
    eocList: 'asc'
  }
  adminTaskloading: boolean = false;
  patientTaskLoading: boolean = false;
  /* #endregion */

  constructor(
    private store: Store<{
      bread_crumbs: any
      chatUsers: any
      enableChat: boolean
    }>,
    private toastr: ToastrService,
    private dashboardService: DashboardService,
    private userService: UserService,
    private requestEocService: RequestEocService,
    private holistaUtils: HolistaUtils,
    public router: Router,
    private utilitySorting: Sorting,
    private memberService: MemberService,
    private rollingDaysUtility: RollingSevenDays,
    private _storage: Storage,
    private deviceService: DeviceDetectorService
  ) {
    // this.count$ = store.pipe(select('count'));
    store.select('enableChat').subscribe(res => {
      this.enableChat = res
    })
  }

  ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb())
    this.store.dispatch(
      new HeaderBreadCrumbActions.AddBreadCrumb({
        name: 'Dashboard',
        path: '/dashboard'
      })
    )
    this.patient_results = this.patient_search_text.pipe(
      startWith(this.patient_search_text),
      switchMap((patient_search_text: string) => this.searchPatient(patient_search_text))
    )
    this.user_results = this.user_search_text.pipe(
      startWith(this.user_search_text),
      switchMap((user_search_text: string) => this.searchUsers(user_search_text))
    )

    this.selectedDashboard = this.router.url.includes('/claim') ? 'claim' : this.selectedDashboard;

    this.user = this._storage.get('local', 'loggedInUser', 'user')
    if (this.user.roleCode !== 'PR' && this.user.roleCode !== 'CLA') {
      if (this.user.roleCode === 'MP') this.getDashboard(this.user)
      else {
        if (this.getEOCPermission())
          this.getEocRequest()
        this.getAdminDashboard(this.user.id)
        this.getAdminTask(this.user.id)
        this.dashboardTypes = JSON.parse(JSON.stringify(DashboardType))
        if (this.user.roleCode == 'CC' || this.user.roleCode == 'ES') this.getPatientEngagementTasks()
      }
      // if (this.user.roleCode == 'PA' || this.user.roleCode == 'CA' || this.user.roleCode == 'IA' || this.user.roleCode == 'SV') {
      //   this.getAllUsers()
      // }
      this.userDashboardSpecifier()
      this.isMobile = this.deviceService.isMobile()
    }
  }
  /**
   * Get all users according to their role
   */
  searchUsers(searchText: string) {
    if (searchText.length > 2) {
      this.userSearchKey = true
      let query = {
        keyword: searchText,
        limit: 0,
        sortBy: 'firstName',
        sortType: 'desc',
        fields: 'id,firstName,lastName,roles,supervisor'
      }
      if (this.user.roleCode !== 'SV') {
        query['roles-roleCode'] = 'PR,CLA'
        query['roles-roleCodeOperator'] = '!='
        return this.userService.searchUsers(query).pipe(
          debounceTime(250),
          map((items: any) => {
            this.userSearchKey = false
            items.rows.map(x => {
              x.roles.map(r => {
                if (r.isActiveRole) {
                  x.activeRole = r.roleCode
                  x.roleCode = r.roleCode
                }
                if (!r.isPrimary) x.secondaryRole = r.roleCode
              })
            })
            return items.rows
          })
        )
      } else {
        query['withPatient'] = true
        return this.userService.getAssignUsers(this.user.id, query).pipe(
          debounceTime(250),
          map((items: any) => {
            this.userSearchKey = false
            items.rows.map(x => {
              x.roles.map(r => {
                x.activeRole = r.isActiveRole ? r.roleCode : ''
                x.roleCode = r.isActiveRole ? r.roleCode : ''
                x.secondaryRole = !r.isPrimary ? r.roleCode : ''
              })
            })
            return items.rows
          })
        )
      }
    } else {
      this.userSearchKey = false
      return of([])
    }
  }

  getEOCPermission() {
    const isPermitted = this.user.rolePermissions.filter(role => ACCESS_TYPES.includes(role.permissionCode));
    if (isPermitted.length && isPermitted[0]?.accessType) {
      this.isEocRequestPermitted = true
    } else {
      this.isEocRequestPermitted = false
    }
    return this.isEocRequestPermitted;
  }

  getEocRequest() {
    const query = {
      status: 'PENDED',
      limit: 0
    }
    this.requestEocService.getEocRequestMemberByQuery(query).subscribe(d => {
      this.requestEocList = d.eocRequest.rows;
      this.totalEOCCount = d.totalCount
    })
  }
  /**
   * Get administrator dashboard if loggedId/selected user's roleCode is CC/ES/CA/IA/SV
   * @param {int} id id of user
   */
  getAdminDashboard(id) {
    this.loading = true
    this.dashboardService
      .getAdminDashboard(id)
      .pipe(
        finalize(() => {
          this.loading = false
        })
      )
      .subscribe((res: any) => {
        this.assignedUsers = []
        this.adminEpisodeList = []
        if (res.data.episodes.length != 0) {
          res.data.episodes.map(e => {
            if (e.patient) e.patient['fullName'] = this.holistaUtils.getFullName(e.patient)
            if (e.cc) e.cc['fullName'] = this.holistaUtils.getFullName(e.cc)
            if (e.es) e.es['fullName'] = this.holistaUtils.getFullName(e.es)
            return e
          })
          this.adminEpisodeList = res.data.episodes
          this.adminEpisodeList['countVisibleEpisodes'] = res.data.episodes.filter(x => x.status == 'PRELIMINARY' || x.status == 'CONFIRMED')
        }
        if (res.data.episodes.length != 0) {
          this.assignedUsers = res.data.episodes
          this.assignedUsers['countVisibleUsers'] = [...new Set(this.adminEpisodeList['countVisibleEpisodes'].map(x => x.userId))]
        }
      })
  }
  /**
   * Get assign task if user's roleCode is CC/ES/CA/IA/SV
   * @param {int} id id of user
   */
  getAdminTask(id) {
    this.adminTaskloading = true
    this.adminTasks = new AdminTasks()
    this.dashboardService
      .getAdminTask(id)
      .pipe(
        finalize(() => {
          this.adminTaskloading = false
        })
      )
      .subscribe(res => {
        this.adminTaskLists = []
        if (res.taskList.length !== 0) {
          this.adminTaskLists = res.taskList
          this.adminTaskLists.map(x => {
            this.taskSpecifier(x)
            return x
          })
          Object.keys(this.adminTasks).forEach(x => {
            this.adminTasks[x].forEach((y, i) => {
              this.adminTasks[x][i].tasks.forEach(tasks => {
                if (tasks.escalationDays) {
                  tasks.dueDate = tasks.startDate ? moment(new Date(tasks.startDate)).add(tasks.escalationDays, 'days').format("YYYY-MM-DD") : moment(new Date(tasks.relativeStartDate)).add(tasks.escalationDays, 'days').format("YYYY-MM-DD");
                } else {
                  tasks.dueDate = tasks.startDate || tasks.relativeStartDate
                }
              })
              this.adminTasks[x][i].tasks = this.utilitySorting.sortByDate(this.adminTasks[x][i].tasks, 'dueDate', 'asc')
            })
          })
        } else {
          this.adminTasks = new AdminTasks()
        }
      }, (error) => {
        console.log('Error', error)
      })
  }

  /**
   * Separate tasks either it is a high priority, medium priority, completed task
   * @param {any} assignUser tasks assign to the administrator
   */
  taskSpecifier(assignUser) {
    if (assignUser.taskType == 'myTask') {
      const highPriority = {
        assignedToName: assignUser.assignedToName,
        tasks: []
      }
      const mediumPriority = {
        assignedToName: assignUser.assignedToName,
        tasks: []
      }
      const completedTasks = {
        assignedToName: assignUser.assignedToName,
        tasks: []
      }
      assignUser.tasks.map(y => {
        if ((y.isHighPriority || y.escalationDays) && y.status !== 'COMPLETED') {
          highPriority.tasks.push(y)
        }
        if (!y.escalationDays && !y.isHighPriority && y.status !== 'COMPLETED') {
          mediumPriority.tasks.push(y)
        }
        if (y.status === 'COMPLETED' && y.statusUpdatedDate && this.rollingDaysUtility.rollingDays(y.statusUpdatedDate)) {
          completedTasks.tasks.push(y)
        }
      })

      if (highPriority.tasks && highPriority.tasks.length > 0) {
        this.totalTaskCount(highPriority, 'highPriorityTasks')
      }
      if (mediumPriority.tasks && mediumPriority.tasks.length > 0) {
        this.totalTaskCount(mediumPriority, 'mediumPriorityTasks')
      }
      if (completedTasks.tasks && completedTasks.tasks.length > 0) {
        this.totalTaskCount(completedTasks, 'completedTasks')
      }
    }
    // for assignUser.taskType == 'myTeamTask'
    else {
      const highPriority = {
        assignedToName: assignUser.assignedToName,
        tasks: []
      }
      const mediumPriority = {
        assignedToName: assignUser.assignedToName,
        tasks: []
      }
      const completedTasks = {
        assignedToName: assignUser.assignedToName,
        tasks: []
      }
      assignUser.tasks.map(y => {
        if ((y.isHighPriority || y.escalationDays) && y.status !== 'COMPLETED') {
          highPriority.tasks.push(y)
        }
        if (!y.escalationDays && !y.isHighPriority && y.status !== 'COMPLETED') {
          mediumPriority.tasks.push(y)
        }
        if (y.status === 'COMPLETED' && y.statusUpdatedDate && this.rollingDaysUtility.rollingDays(y.statusUpdatedDate)) {
          completedTasks.tasks.push(y)
        }
      })

      if (highPriority.tasks && highPriority.tasks.length > 0) {
        this.totalTaskCount(highPriority, 'highPriorityTasks2')
      }
      if (mediumPriority.tasks && mediumPriority.tasks.length > 0) {
        this.totalTaskCount(mediumPriority, 'mediumPriorityTasks2')
      }
      if (completedTasks.tasks && completedTasks.tasks.length > 0) {
        this.totalTaskCount(completedTasks, 'completedTasks2')
      }
    }

  }
  /**
   * Count total tasks for each task type
   * @param {any} task tasks to be count
   * @param {string} taskType type of task
   */
  totalTaskCount(task, taskType: string) {
    this.adminTasks[`${taskType}`].push(task)
    this.adminTasks[`${taskType}`]['totalCount'] = this.adminTasks[`${taskType}`]
      .map(a => {
        return a.tasks.length
      })
      .reduce((x, y) => x + y, 0)
  }
  /**
   * Navigate to selected episode/task topic page
   * @param {any} topic selected episode/task topic detail
   */
  directToTaskMilestone(topic) {
    if (topic.todo || topic.tqn || topic.tqnrs) topic.id = topic.todo ? topic.todo.taskId : topic.tqn ? topic.tqn.taskId : topic.tqnrs.taskId
    this.router.navigate(['episodes', topic.episodeId, 'milestone'], {
      queryParams: {
        active: topic.milestoneId,
        task: topic.topicId ? topic.topicId : topic.id
      }
    })
  }
  /**
   * Get dashboard if selected/loggedIn user is patient
   * @param {any} user responsible user
   * @param {string} selected_patient optional param to check whether selected user is patient
   */
  getDashboard(user, selected_patient?) {
    this.loading = true
    this.dashboardService
      .getDashboard(user.id)
      .pipe(
        finalize(() => {
          this.loading = false
        })
      )
      .subscribe(res => {
        this.dashboard = new Dashboard()
        this.userEpisodeList = []
        this.selectedEpisode = null
        if (res.length !== 0) {
          res.sort((a, b) => b.id - a.id)
          if (this.user.roleCode == 'PA') {
            this.dashboard.count.episodes = res.length
            this.dashboard.episodes = res
          }
          if (this.user.roleCode == 'CC' || this.user.roleCode == 'ES') {
            res.map(x => {
              let milestoneDate = x.milestoneStartDate ? x.milestoneStartDate : x.milestoneRelativeStartDate
              if (milestoneDate) {
                let days_difference = moment.duration(moment(new Date()).diff(milestoneDate)).asDays()
                if (days_difference >= 0) {
                  this.dashboard.count.task = this.dashboard.count.task + 1
                  this.dashboard.tasks.push(x)
                }
              }
            })
          }
          if (
            selected_patient
              ? user.activeRole == 'MP' ||
              ((user.roleCode === 'CA' || user.roleCode === 'SCA' || user.roleCode === 'IA' || user.roleCode === 'CC' || user.roleCode === 'ES' || user.roleCode === 'SV') &&
                user.secondaryRole &&
                user.secondaryRole === 'MP' &&
                this.user.roleCode === 'SV')
              : this.user.roleCode == 'MP'
          ) {
            res.map((x: any) => {
              let label = x.tag ? `${x.name} - ${x.tag}` : x.name
              let data = { label: label, value: x.id, data: x }
              this.userEpisodeList = [...this.userEpisodeList, data]
            })
            if (this.userEpisodeList.length > 0) this.optionSelectedEpisode(this.userEpisodeList[0])
            else {
              this.dashboard.count.milestone = 0
              this.dashboard.milestones = []
              this.dashboard.count.task = 0
              this.dashboard.tasks = []
            }
          }
        }
      })
  }
  /**
   * Get patient's dashboard Milestone Detail
   * @param {any} episode Patient episode detail
   */
  optionSelectedEpisode(episode) {
    this.selectedEpisode = episode
    this.dashboard.count.milestone = 0
    this.dashboard.milestones = []
    this.dashboard.count.task = 0
    this.dashboard.tasks = []
    this.dashboard.count.milestone = this.selectedEpisode.data.milestones.length
    this.dashboard.milestones = this.selectedEpisode.data.milestones
    this.dashboard.milestones = this.dashboard.milestones.map(x => {
      let showDate = x.startDate ? x.startDate : x.relativeStartDate
      x.showDate = showDate ? moment(showDate.split('T')[0]).format('MM/DD/YYYY') : null
      return x
    })
    this.dashboard.milestones = [...this.holistaUtils.sortByDate(this.dashboard.milestones, 'showDate'), ...this.dashboard.milestones.filter(x => !x.showDate)]
    this.selectedEpisode.data.milestones.map(milestone => {
      let milestoneDate = milestone.startDate ? milestone.startDate : milestone.relativeStartDate
      if (milestoneDate) {
        let days_difference = moment.duration(moment(new Date()).diff(milestoneDate)).asDays()
        if (days_difference >= 0 && (this.selectedEpisode.data.status !== 'PRELIMINARY' || (milestone.isTriggerOnStart && this.selectedEpisode.data.status === 'PRELIMINARY'))) {
          milestone.milestonetasks.map(milestonetask => {
            this.dashboard.count.task =
              this.dashboard.count.task + milestonetask.topic.taskTodos.length + milestonetask.topic.taskQuestions.length + milestonetask.topic.taskQnrs.length
            milestonetask.topic.milestoneId = milestone.id
            milestonetask.topic.episodeId = this.selectedEpisode.data.id
            if (this.dashboard.count.task > 0) this.dashboard.tasks.push(milestonetask.topic)
          })
        }
      }
    })
  }

  /**
   * Navigate to selected milestone page
   * @param {any}milestone selected milestone
   */
  goToMilestone(milestone) {
    this.router.navigate(['episodes', this.selectedEpisode.data.id, 'milestone'], { queryParams: { active: milestone.id } })
  }
  /**
   * Search patient based on keyword
   * @param {string} searchText keyword to be search
   */
  searchPatient(searchText: string) {
    if (searchText.length > 2) {
      this.patientSearchKey = true
      return this.memberService.searchMembers({ search: searchText, page: 0 }).pipe(
        debounceTime(250),
        map((items: any) => {
          this.patientSearchKey = false
          return items.rows
        })
      )
    } else {
      this.patientSearchKey = false
      return of([])
    }
  }
  /**
   * Display selected search user name
   * @param {any}procedure selected search user
   */
  onDisplayValue = (procedure?): string | undefined => {
    return procedure
      ? !procedure.middlename && procedure.lastname
        ? procedure.firstname + ' ' + procedure.lastname
        : procedure.middlename && procedure.lastname
          ? procedure.firstname + ' ' + procedure.middlename + ' ' + procedure.lastname
          : procedure.firstname
      : undefined
  }

  onDisplayUser = (user?): string | undefined => {
    return user ? (user.lastName ? user.firstName + ' ' + user.lastName : user.firstName) : undefined
  }
  /**
   * Get patient/administrator dashboard
   * @param {any}selected_user selected user from dropdown
   */

  selectedUserDashboard(selected_user) {
    selected_user = selected_user.text
    this.selectedDashboard = 'admin'
    this.showSelectedDashboard({ value: 'admin' }, 'search')
    this.adminTasks = new AdminTasks()
    this.selected_user = selected_user
    this.isAssignedSupervisor = selected_user.supervisor ? true : false
    if (
      ((this.user.roleCode === 'PA' || this.user.roleCode === 'CA' || this.user.roleCode === 'SCA' || this.user.roleCode === 'IA') &&
        (selected_user.activeRole == 'PA' ||
          selected_user.activeRole == 'CC' ||
          selected_user.activeRole == 'ES' ||
          selected_user.activeRole == 'CA' ||
          selected_user.activeRole == 'SCA' ||
          selected_user.activeRole == 'IA' ||
          selected_user.activeRole == 'SV')) ||
      (this.user.roleCode === 'SV' &&
        (((selected_user.roleCode === 'CC' || selected_user.roleCode === 'ES') && this.isAssignedSupervisor) ||
          (selected_user.roleCode == 'SV' && selected_user.id === this.user.id) ||
          ((selected_user.roleCode === 'CA' || selected_user.roleCode === 'SCA' || selected_user.roleCode === 'IA') && !selected_user.secondaryRole)))
    ) {
      this.getAdminTask(selected_user.id)
      this.getAdminDashboard(selected_user.id)
    } else {
      this.getDashboard(selected_user, 'patient')
    }
    if (
      (this.user.roleCode === 'PA' || this.user.roleCode === 'CA' || this.user.roleCode === 'SCA' || this.user.roleCode === 'IA' || this.user.roleCode === 'SV') &&
      (selected_user.activeRole == 'CC' || selected_user.activeRole == 'ES')
    )
      this.getPatientEngagementTasks()
    this.userDashboardSpecifier(selected_user)
  }
  /**
   * Show admin/patient dashboard based on loggedIn/selected user's roleCode
   * @param {any}selected_user selected user from dropdown
   */
  userDashboardSpecifier(selected_user?) {
    if (
      this.user.roleCode !== 'MP' &&
      (!selected_user ||
        (selected_user &&
          ((this.user.roleCode === 'SV' &&
            (((selected_user.roleCode === 'CC' || selected_user.roleCode === 'ES') && this.isAssignedSupervisor) ||
              (selected_user.roleCode === 'SV' && selected_user.id === this.user.id))) ||
            (selected_user.activeRole !== 'MP' && this.user.roleCode !== 'SV'))))
    ) {
      this.showAdminDashboard = true
    } else {
      this.showAdminDashboard = false
    }
    if (
      this.user.roleCode === 'MP' ||
      (selected_user &&
        ((this.user.roleCode === 'SV' &&
          (((selected_user.roleCode == 'CA' || selected_user.roleCode == 'SCA' || selected_user.roleCode === 'IA' || (selected_user.roleCode === 'SV' && selected_user.id !== this.user.id)) &&
            selected_user.secondaryRole &&
            selected_user.secondaryRole === 'MP') ||
            (this.user.roleCode === 'SV' && selected_user.roleCode === 'MP') ||
            ((selected_user.roleCode === 'CC' || selected_user.roleCode === 'ES') && (!selected_user.supervisor.id || !this.isAssignedSupervisor)))) ||
          (selected_user.activeRole === 'MP' && this.user.roleCode !== 'SV')))
    ) {
      this.showPatientDashboard = true
    } else {
      this.showPatientDashboard = false
    }
  }

  onOneEocRequestClick() {
    this.router.navigate(['/request-eoc'])
  }
  /**
   * Choose patient from search result
   * @param {any}patient selected search patient
   */
  onPatientSelect(patient) {
    this.router.navigate(['/patient'])
    let name =
      !patient.text.middleName && patient.text.lastName
        ? patient.text.firstName + ' ' + patient.text.lastName
        : patient.text.middleName && patient.text.lastName
          ? patient.text.firstName + ' ' + patient.text.middleName + ' ' + patient.text.lastName
          : patient.text.firstName
    this._storage.set('session', 'searchPatient', name)
  }
  /**
   * Open chat modal of assigned Care Cordinator/Engagement Specialist
   * @param {int}userId id of assigned Care Cordinator/Engagement Specialist
   */
  openChat(userId) {
    let assignAdmin = { uid: null }
    assignAdmin.uid = userId
    this.store.dispatch(new Actions.GetUser(assignAdmin))
  }

  getPatientEngagementTasks() {
    this.patientTaskLoading = true
    this.dashboardService
      .getPatientEngagementTasks(this.selected_user ? this.selected_user.id : '', this.selected_user ? this.selected_user.activeRole : '')
      .pipe(
        finalize(() => {
          this.patientTaskLoading = false
        })
      )
      .subscribe(
        res => {
          if (res && res.success) {
            res.criticalTasks['groupByEpisode'] = []
            res.nonCriticalTasks['groupByEpisode'] = []
            res.criticalTasks.data.map(x => {
              if (res.criticalTasks['groupByEpisode'].length == 0)
                res.criticalTasks['groupByEpisode'] = [
                  {
                    episode: x.episodeName,
                    episodeId: x.episodeId,
                    patientName: x.patientName,
                    tasks: [x]
                  }
                ]
              else {
                let index = res.criticalTasks['groupByEpisode'].findIndex(y => y.episodeId == x.episodeId)
                if (index >= 0) res.criticalTasks['groupByEpisode'][index].tasks.push(x)
                else
                  res.criticalTasks['groupByEpisode'].push({
                    episode: x.episodeName,
                    episodeId: x.episodeId,
                    patientName: x.patientName,
                    tasks: [x]
                  })
              }
            })

            res.nonCriticalTasks.data.map(x => {
              if (res.nonCriticalTasks['groupByEpisode'].length == 0)
                res.nonCriticalTasks['groupByEpisode'] = [
                  {
                    episode: x.episodeName,
                    episodeId: x.episodeId,
                    patientName: x.patientName,
                    tasks: [x]
                  }
                ]
              else {
                let index = res.nonCriticalTasks['groupByEpisode'].findIndex(y => y.episodeId == x.episodeId)
                if (index >= 0) res.nonCriticalTasks['groupByEpisode'][index].tasks.push(x)
                else
                  res.nonCriticalTasks['groupByEpisode'].push({
                    episode: x.episodeName,
                    episodeId: x.episodeId,
                    patientName: x.patientName,
                    tasks: [x]
                  })
              }
            })
            this.criticalTasks = res.criticalTasks
            this.nonCriticalTasks = res.nonCriticalTasks
          }
        },
        error => {
          console.log('Error getting patient engagement tasks', error)
        }
      )
  }

  showSelectedDashboard(event, action?) {
    if (!action) this.user_search_text.next('')
    this.dashboardTypes = this.dashboardTypes.map((dashboard: any) => {
      if (dashboard.value == event.value) dashboard.disabled = true
      else dashboard.disabled = false
      return dashboard
    })
  }

  userChange() {
    this.user_search_text.subscribe((res: any) => {
      if (res.length < 3 && this.selected_user) {
        this.selected_user = null
        this.user_search_text.next('')
        this.userDashboardSpecifier()
        this.getAdminTask(this.user.id)
        this.getAdminDashboard(this.user.id)
      }
    });
    if (this.selected_user) {
      this.user_search_text.next(this.selected_user.firstName + ' ' + this.selected_user.lastName)
    }
  }

  removeSearchKeyword(type) {
    if (type == 'patient') this.patient_search_text.next('')
    else this.user_search_text.next('')
  }

  receiveSelectedDashboard(event) {
    this.selectedDashboard = event
    this.showSelectedDashboard({ value: event }, 'search')
  }

  sortEoc(type: string, taskType: string) {
    this.sortingType[taskType] = type;
    this.requestEocList = this.utilitySorting.sortByDate(this.requestEocList, 'createdAt', type)
  }

  sortTasks(type: string, taskType: string) {
    this.sortingType[taskType] = type;
    this.adminTasks[taskType].forEach((y, i) => {
      this.adminTasks[taskType][i].tasks = this.utilitySorting.sortByDate(this.adminTasks[taskType][i].tasks, 'dueDate', type)
    })
  }
}
