export class DependentTask {
  taskMessages = [];
  taskQuestions = [];
  taskTodos = [];
  taskQnrs = [];
  taskSignatures = [];
}

export class TaskOptions {
  optionId: number = null;
  isDependent: boolean = false;
  parentUuid: string = '';
  sequence: number = null;
}