<!-- Expandable Chart Begins -->
<div
  [attr.id]="expand.processingCycleTime ? 'holista-cycle-time' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES) &&
    expand.processingCycleTime
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="processingCycleTimeForm">
            <div class="d-flex justify-content-between align-items-center">
              <label class="m-0">Period</label>
              <mdb-select
                style="width: 80px"
                class="ml-3"
                placeholder="Year"
                [options]="yearOptions.processingCycleTime | sort : 'label'"
                id="year-select"
                [visibleOptions]="3"
                formControlName="selectedYear"
                (selected)="yearSelected($event, REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES)">
              </mdb-select>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.processingCycleTime && processingCycleTimeChart.labels.length"
        [datasets]="processingCycleTimeChart.dataset"
        [labels]="processingCycleTimeChart.labels"
        [options]="processingCycleTimeChart.options"
        [plugins]="processingCycleTimeChart.plugins"
        [chartType]="processingCycleTimeChart.chartType">
      </canvas>
      <p *ngIf="!loading.processingCycleTime && !processingCycleTimeChart.labels.length">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.processingCycleTime">
        <app-loader [loader]="loading.processingCycleTime"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  [attr.id]="expand.paymentCycleTime ? 'holista-cycle-time' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES) &&
    expand.paymentCycleTime
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="paymentCycleTimeForm">
            <div class="d-flex justify-content-between align-items-center">
              <label class="m-0">Period</label>
              <mdb-select
                style="width: 80px"
                class="ml-3"
                placeholder="Year"
                [options]="yearOptions.paymentCycleTime | sort : 'label'"
                id="year-select"
                [visibleOptions]="3"
                formControlName="selectedYear"
                (selected)="yearSelected($event, REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES)">
              </mdb-select>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.paymentCycleTime && paymentCycleTimeChart.labels.length"
        [datasets]="paymentCycleTimeChart.dataset"
        [labels]="paymentCycleTimeChart.labels"
        [options]="paymentCycleTimeChart.options"
        [plugins]="paymentCycleTimeChart.plugins"
        [chartType]="paymentCycleTimeChart.chartType">
      </canvas>
      <p *ngIf="!loading.paymentCycleTime && !paymentCycleTimeChart.labels.length">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.paymentCycleTime">
        <app-loader [loader]="loading.paymentCycleTime"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<!-- Expandable Chart Ends -->
<div [className]="!expand.processingCycleTime && !expand.paymentCycleTime ? 'mt-3' : 'd-none'">
  <div
    class="mt-3"
    *ngIf="
      parsedClientClaimsFilterData?.isConfigLoading &&
      !parsedClientClaimsFilterData?.toShowReport.length
    ">
    <app-loader [loader]="true"></app-loader>
  </div>
  <div
    id="holista-cycle-time"
    class="m-0 p-0"
    *ngIf="
      !parsedClientClaimsFilterData?.isConfigLoading &&
      parsedClientClaimsFilterData?.toShowReport &&
      parsedClientClaimsFilterData?.toShowReport.length
    ">
    <div class="px-3">
      <div
        class="row"
        *ngIf="
          toShowReport &&
          (toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES) ||
            toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES))
        ">
        <div
          class="col-md-6 mb-4"
          *ngIf="
            toShowReport && toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="processingCycleTimeForm">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="m-0">Period</label>
                      <mdb-select
                        style="width: 80px"
                        class="ml-3"
                        placeholder="Year"
                        [options]="yearOptions.processingCycleTime | sort : 'label'"
                        id="year-select"
                        [visibleOptions]="3"
                        formControlName="selectedYear"
                        (selected)="
                          yearSelected($event, REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES)
                        ">
                      </mdb-select>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor ml-3"
                    (click)="onViewToggle(REPORT_NAME_CODES.HOLISTA_PROCESSING_CYCLE_TIMES, true)">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="!loading.processingCycleTime && processingCycleTimeChart.labels.length"
                [datasets]="processingCycleTimeChart.dataset"
                [labels]="processingCycleTimeChart.labels"
                [options]="processingCycleTimeChart.options"
                [plugins]="processingCycleTimeChart.plugins"
                [chartType]="processingCycleTimeChart.chartType">
              </canvas>
              <p *ngIf="!loading.processingCycleTime && !processingCycleTimeChart.labels.length">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.processingCycleTime">
                <app-loader [loader]="loading.processingCycleTime"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div
          class="col-md-6 mb-4"
          *ngIf="
            toShowReport && toShowReport.includes(REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="paymentCycleTimeForm">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="m-0">Period</label>
                      <mdb-select
                        style="width: 80px"
                        class="ml-3"
                        placeholder="Year"
                        [options]="yearOptions.paymentCycleTime | sort : 'label'"
                        id="year-select"
                        [visibleOptions]="3"
                        formControlName="selectedYear"
                        (selected)="
                          yearSelected($event, REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES)
                        ">
                      </mdb-select>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor ml-3"
                    (click)="onViewToggle(REPORT_NAME_CODES.HOLISTA_PAYMENT_CYCLE_TIMES, true)">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="!loading.paymentCycleTime && paymentCycleTimeChart.labels.length"
                [datasets]="paymentCycleTimeChart.dataset"
                [labels]="paymentCycleTimeChart.labels"
                [options]="paymentCycleTimeChart.options"
                [plugins]="paymentCycleTimeChart.plugins"
                [chartType]="paymentCycleTimeChart.chartType">
              </canvas>
              <p *ngIf="!loading.paymentCycleTime && !paymentCycleTimeChart.labels.length">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.paymentCycleTime">
                <app-loader [loader]="loading.paymentCycleTime"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>
</div>
