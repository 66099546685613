import { Injectable } from "@angular/core";

@Injectable()

export class NameInitials {

    /**
     * initial letters of first and last name
     * @param name 
     * @returns 
     */
    getNameInitials(name: string) {
        let initials = '';
        initials = name.includes(' ') ? name.charAt(0) + name.charAt(name.lastIndexOf(' ') + 1) : name.charAt(0);
        return initials.toUpperCase();
    }
}