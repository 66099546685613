<div class="position-relative overflow-hidden">
  <button
    *ngIf="!otpToken && loggedInUser?.user.roleCode !== 'MP'"
    type="button"
    class="btn btn-secondary position-absolute end-1 mt-3"
    data-toggle="modal"
    data-target="#basicExample"
    (click)="onComposeEmail()"
    [ngStyle]="{zIndex: '99'}"
    mdbWavesEffect>
    <i class="icofont-plus"></i> Compose Email
  </button>
  <mdb-tabset
    #tabs
    [buttonClass]="'classic-tabs grey lighten-4 pt-4 pl-4'"
    [contentClass]="'card tab-clean d-block'"
    class="classic-tabs">
    <mdb-tab
      heading="{{
        unreadInboxCountService.count > 0
          ? inboxHeadingWithUnreadCount
          : inboxHeadingWithoutUnreadCount
      }}"
      (select)="onTabSelected(INBOX, $event)">
      <div class="row" *ngIf="selectedTab === INBOX">
        <div
          class="sticky-content scrollbar scrollbar-primary thin col-3"
          [ngStyle]="{
            height: 'calc(100vh - 220px)'
          }">
          <app-message-list
            type="inbox"
            (messageSelect)="getMessageData($event)"></app-message-list>
        </div>
        <div
          class="sticky-content scrollbar scrollbar-primary thin col-9"
          [ngStyle]="{
            height: 'calc(100vh - 220px)'
          }">
          <app-loader [loader]="isLoading"></app-loader>
          <app-message-view
            [showReply]="true"
            *ngIf="!isLoading && messageThread.length"
            [messageThread]="messageThread"
            (messageReplied)="getMessageThread($event)"></app-message-view>
          <div
            class="text-center p-4 border mt-3 d-flex flex-column justify-content-center no-conversation-container"
            *ngIf="!isLoading && !messageThread.length">
            <i class="fa-fa fa-envelope fas fa-3x text-black-50"></i>
            <p>No conversation selected.</p>
          </div>
        </div>
      </div>
    </mdb-tab>
    <mdb-tab
      heading="<i class='icofont-paper-plane mr-1 fa-lg'></i></i> Sent"
      (select)="onTabSelected(OUTBOX, $event)">
      <div class="row" *ngIf="selectedTab === OUTBOX">
        <div
          class="sticky-content scrollbar scrollbar-primary thin col-3"
          [ngStyle]="{
            height: 'calc(100vh - 220px)'
          }">
          <app-message-list
            type="outbox"
            [sentMessageId]="sentMessageId"
            (messageSelect)="getMessageData($event)"></app-message-list>
        </div>
        <div
          class="sticky-content scrollbar scrollbar-primary thin col-9"
          [ngStyle]="{
            height: 'calc(100vh - 220px)'
          }">
          <app-loader [loader]="isLoading"></app-loader>
          <app-message-view
            [showReply]="true"
            *ngIf="!isLoading && messageThread.length"
            [messageThread]="messageThread"
            (messageReplied)="getMessageThread($event)"></app-message-view>
          <div
            class="text-center p-4 border mt-3 d-flex flex-column justify-content-center no-conversation-container"
            *ngIf="!isLoading && !messageThread.length">
            <i class="fa-fa fa-envelope fas fa-3x text-black-50"></i>
            <p>No conversation selected.</p>
          </div>
        </div>
      </div>
    </mdb-tab>
  </mdb-tabset>
</div>

<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [isCommunicationModule]="true"
  (changeTabToSent)="changeTabToSent()"
  (closeModal)="closeMessageModal()"></app-communication-message>
