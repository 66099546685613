<div class="m-0 p-0">
  <div class="d-flex justify-content-between align-items-center p-3 bg-blue-light">
    <div class="d-flex">
      <h5 class="font-weight-bold m-0">Transaction Report</h5>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <form [formGroup]="reportFormConfig">
        <div class="d-flex justify-content-end align-items-center">
          <div class="row" style="width: 850px">
            <div class="col-md-3 p-0 pl-2">
              <div class="form-group bg-white m-0">
                <app-select
                    [options]="networkList | sort : 'label'"
                    placeholder="Select network"
                    (onSelected)="onNetworkSelected($event)"
                    id="tr-network-select"
                    [control]="reportFormConfig.controls['networkCode']"
                    >
                </app-select>
              </div>
            </div>
            <div class="col-md-3 p-0 pl-2">
              <div class="form-group bg-white m-0">
                <app-select
                  [options]="clientList | sort : 'label'"
                  placeholder="Select client"
                  id="tr-client-select"
                  allowClear="true"
                  [disabled]="isLoading.clientList || !clientList.length"
                  [control]="reportFormConfig.controls['clientCode']"
                  >
                </app-select>
              </div>
            </div>
            <div class="col-md-2 p-0 pl-2">
              <div class="form-group bg-white m-0">
                <app-select
                  [options]="reportDateTypeList | sort : 'label'"
                  [filterEnabled]="false"
                  placeholder="Select date"
                  id="tr-report-date-select"
                  [disabled]="reportFormConfig.get('activeTabIndex').value === TRANSACTION_REPORT_TAB_INDEXES.SUMMARY_YTD"
                  [control]="reportFormConfig.controls['reportDateType']"
                  (onSelected)="onReportDateTypeSelected()">
                </app-select>
              </div>
            </div>
            <div class="col-md-4 p-0 pl-2">
              <div class="form-row my-1">
                <div class="col">
                  <div class="form-group bg-white m-0">
                    <div class="form-group bg-white m-0">
                      <mdb-date-picker
                        #toDatePicker
                        [inline]="true"
                        name="fromDate"
                        id="tr-from-date-select"
                        [options]="startDateOptions"
                        placeholder="From"
                        formControlName="startDate"
                        [disabled]="isPreSelectedFilter || !reportFormConfig.value.reportDateType"
                        (dateChanged)="onDateChange($event, 'startDate')">
                      </mdb-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group bg-white m-0">
                    <mdb-date-picker
                      #toDatePicker
                      [inline]="true"
                      name="toDate"
                      id="tr-to-date-select"
                      [options]="endDateOptions"
                      placeholder="To"
                      formControlName="endDate"
                      [disabled]="isPreSelectedFilter || !reportFormConfig.value.reportDateType"
                      (dateChanged)="onDateChange($event, 'endDate')">
                    </mdb-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <button
        type="button"
        class="waves-light btn btn-secondary text-center text-white text-uppercase ml-4"
        data-toggle="modal"
        data-target="#downloadFile"
        [disabled]="downloadFile.download"
        placement="bottom"
        mdbWavesEffect
        id="downloadAlert"
        [mdbPopover]="downloadAlert"
        #pop="bs-mdbPopover">
        <span
          *ngIf="downloadFile.download"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"></span>
        <i class="icofont-download" *ngIf="!downloadFile.download"></i>
        <span class="d-none d-sm-inline-block ml-1">Download</span>
      </button>
      <ng-template #downloadAlert>
        <div class="d-flex justify-content-between align-items-center">
          <p>Choose the file type to download.</p>
          <a class="ml-4 mb-3" aria-label="Close" (click)="pop.hide()">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="text-center">
          <p class="mb-0">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              [disabled]="false"
              (click)="download('excel'); pop.hide()">
              Excel
            </button>
            <button
              type="button"
              [attr.disabled]="true"
              (click)="download('pdf'); pop.hide()"
              class="btn btn-secondary btn-sm">
              PDF
            </button>
          </p>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Tabs Starts -->
  <div style="overflow-x: hidden">
    <!-- Nav tabs -->
    <mdb-tabset
      #tabs
      [buttonClass]="'classic-tabs bg-blue-light border-top rounded-0 '"
      [contentClass]="'card m-0 shadow-none p-0'"
      (getActiveTab)="onGetActiveTab($event)"
      class="classic-tabs bg-blue-light border-0 p-0">
      <div class="d-flex p-3 border-top bg-blue-light" *ngIf="isLoading.tabList">
        <app-loader [loaderSmall]="true" [loader]="isLoading.tabList"></app-loader>
      </div>
      <mdb-tab *ngFor="let tab of tabList" heading="{{ tab.name }}">
        <app-accounts-receivable
          *ngIf="
            tab.code === REPORT_TAB_CODE.ACCOUNTS_RECEIVABLE &&
            reportFormConfig.get('activeTabIndex').value ===
              TRANSACTION_REPORT_TAB_INDEXES.ACCOUNTS_RECEIVABLE
          "
          [downloadFile]="downloadFile"
          [transactionReportFilterData]="reportFormConfig.value"
          [episodeTypeList]="episodeTypeList"
          (filtersApplied)="applyFilters($event)"
          ></app-accounts-receivable>
        <app-accounts-payable
          *ngIf="
            tab.code === REPORT_TAB_CODE.ACCOUNTS_PAYABLE &&
            reportFormConfig.get('activeTabIndex').value ===
              TRANSACTION_REPORT_TAB_INDEXES.ACCOUNTS_PAYABLE
          "
          [transactionReportFilterData]="reportFormConfig.value"
          [episodeTypeList]="episodeTypeList"
          [downloadFile]="downloadFile"
          (filtersApplied)="applyFilters($event)"
          ></app-accounts-payable>
        <app-summary-ytd
          *ngIf="
            tab.code === REPORT_TAB_CODE.SUMMARY_YTD &&
            reportFormConfig.get('activeTabIndex').value ===
              TRANSACTION_REPORT_TAB_INDEXES.SUMMARY_YTD
          "
          [transactionReportFilterData]="reportFormConfig.value"
          [episodeTypeList]="episodeTypeList"
          [downloadFile]="downloadFile"
          (filtersApplied)="applyFilters($event)"
          ></app-summary-ytd>
      </mdb-tab>
    </mdb-tabset>
  </div>
  <!-- Tabs End -->
</div>
