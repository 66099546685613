import { Injectable } from "@angular/core";
import { APICONSTANTS } from "../constants";
import { HttpClientService } from "./http-client.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private http: HttpClientService) { }

  getUsers(queryParam?) {
    const url = typeof queryParam == 'string' ? `?${queryParam}` : ``
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.USER}${url}`, typeof queryParam == 'object' ? queryParam : {}).toPromise()
        .then((res: any) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  searchUsers(queryParam?) {
    return this.http.get(`${APICONSTANTS.USER}`, queryParam).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getEpisodeType(userId) {
    return this.http.get(`${APICONSTANTS.EPISODE}/episode-type/${userId}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getIdCard(id, queryParam?) {
    return this.http.get(`${APICONSTANTS.USER}/${id}/id-cards`, queryParam).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getUserById(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.USER}/${id}`).toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  saveUser(user) {
    return this.http.post(`${APICONSTANTS.USER}`, user).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  updateUser(user) {
    return this.http.put(`${APICONSTANTS.USER}/${user.id}`, user).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getRoles(queryParam?) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.ROLE}`, queryParam).toPromise()
        .then((res: any) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getUserRoles(userId: string, paramType: string) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.USER}/${userId}/roles`, { paramType }).toPromise()
        .then((res: any) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }


  updateStatus(Id, status) {
    return this.http.patch(`${APICONSTANTS.USER}/${Id}/status`, { status: status }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  searchQuestions(search_text) {
    return this.http.get(`${APICONSTANTS.TASK}/questions/${search_text}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveUserDocument(document) {
    return this.http.post(`${APICONSTANTS.DOCUMENT}`, document).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  resendInvitation(user) {
    return this.http.patch(`${APICONSTANTS.USER}${APICONSTANTS.SEND_INVITATION}`, user).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getPatientDemographics(id) {
    return this.http.get(`${APICONSTANTS.USER}/${id}/demographic-info`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  updateTriggerSms(userId, triggerSms: any) {
    return this.http.put(`${APICONSTANTS.USER}/${userId}/settings`, { triggerSMS: triggerSms }).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }
  changeEmail(credentials) {
    return this.http.patch(`${APICONSTANTS.USER}/change-email`, credentials)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  changePhone(credentials) {
    return this.http.patch(`${APICONSTANTS.USER}/change-phone`, credentials)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  changeMemberNote(body) {
    return this.http.patch(`${APICONSTANTS.MEMBER}/${body.uuid}/notes`, body)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  // changeNote(credentials) {
  //   return this.http.post(`${APICONSTANTS.USER}/member/note`, credentials)
  //     .pipe(map((res: any) => {
  //       return res.data;
  //     }));
  // }

  getNote(id) {
    return this.http.get(`${APICONSTANTS.USER}/member/${id}/note`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  reInvite(credentials) {
    return this.http.patch(`${APICONSTANTS.USER}/re-invite`, credentials)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getMobile(id) {
    return this.http.get(`${APICONSTANTS.USER}/member/${id}/phone`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getAllUsers(query?) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.USER}`, query)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })

  }

  assignSupervisor(userId, data) {
    return this.http.put(`${APICONSTANTS.USER}/${userId}/assign-supervisor`, data).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveToProviderAccess(data) {
    return this.http.post(`${APICONSTANTS.USER}/${data.userId}/assign-provider`, data).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getProvidersByUserId(userId, queryParam?) {
    return this.http.get(`${APICONSTANTS.USER}/${userId}${APICONSTANTS.PROVIDER}`, queryParam).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  removeProvider(provider) {
    return this.http.remove(`${APICONSTANTS.USER}/${provider.userId}${APICONSTANTS.PROVIDER}/${provider.id}`).pipe(
      map((res: any) => {
        return res.data;
      }
      ))
  }

  getUsersAccordingToRole() {
    return this.http.get(`${APICONSTANTS.USER}/all`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateNPI(body) {
    return this.http.patch(`${APICONSTANTS.USER}/${body.userId}/update-npi`, body).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  findClaimPatient(body) {
    return this.http.post(`${APICONSTANTS.USER}/find-claim-patient`, body).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  changeUserDisclaimerSetting(id, body) {
    return this.http.put(`${APICONSTANTS.USER}/${id}/settings`, body).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  getAssignUsers(supervisorId, queryParam?) {
    const url = typeof queryParam == 'string' ? `?${queryParam}` : ``
    return this.http.get(`${APICONSTANTS.USER}/${supervisorId}/assign-users${url}`, typeof queryParam == 'object' ? queryParam : {}).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  getUserLastLoginDate(userId) {
    return this.http.get(`${APICONSTANTS.USER}/${userId}/last-login`).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  updateMFAPreference(body) {
    return this.http.patch(`${APICONSTANTS.USER}/${body.userId}${APICONSTANTS.MFA_PREFERENCE}`, body.userSetting).pipe(
      map((response: any) => {
        return response
      })
    );
  };
}
