import { Component, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { NetworkService, } from "src/app/services";
import { ToastrService } from 'ngx-toastr';
import * as HeaderBreadCrumbActions from '../../action';
import { MaskConstant, RestrictSpace, US_STATES, ValidDate } from "src/app/constants";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Storage } from '../../utils';
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { PayeeService } from "src/app/services/payee.service";
import * as moment from "moment";
import { DUPLICATE_STATUS, MMDDYYYY, SUCCESS, activeClass, payeeDateFormat } from "src/app/constants/payee.constant";
@Component({
    selector: 'app-payees',
    templateUrl: './payees.component.html',
    styleUrls: ['./payees.component.scss']
})
export class PayeesComponent implements OnInit {

    usStates = US_STATES;
    dateMask: any[] = MaskConstant.DATE;
    page = 1;
    limit = 10;
    totalCount = 11;
    selectedPayee: any;
    buttonSpinner = false;
    loading = true;
    searchModelChanged: Subject<string> = new Subject<string>();
    backupNetworks: any = [];
    submitAnyway = false;
    updateAnyway = false;
    addPayeeAnywayMessage = '';
    updatePayeeAnywayMessage = '';
    networks: any = []
    networksForFilter: any = []
    submitted = false;
    query = {
        searchKey: '',
        page: 1,
        limit: 10,
        sortType: 'desc',
        sortBy: 'createdAt',
        network: '',
        code: '',
    }
    user: any
    reverse: boolean = true;
    reverseCreatedDateSort = true;
    payeeList = [];
    forceAdd = false;
    payeeForm: FormGroup;
    isEditPayee: boolean = false
    message: string;
    deleteId: number
    // deactivateId: number

    @ViewChild('addPayeeModal', { static: true }) public addPayeeModal;

    constructor(private store: Store<{ bread_crumbs: any }>,
        private networkService: NetworkService,
        private formBuilder: FormBuilder,
        private _storage: Storage,
        private payeeService: PayeeService,
        private toaster: ToastrService
    ) {
        this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
            this.loading = true;
            const query = Object.assign(this.query, { page: 1, searchKey: keyword });
            this.getPayeeList(query);

        });
    }

    ngOnInit() {
        this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Payees', path: '/payees' }));
        this.user = this._storage.get('local', 'loggedInUser', 'user')
        this.networks = this.getNetworks()
        this.setPayeeForm()
    }

    setPayeeForm() {
        this.payeeForm = this.formBuilder.group({
            network: [null, Validators.required],
            name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
            isActive: [true],
            tin: [null, Validators.required],
            npi: [null, Validators.required],
            addressLine1: [null, Validators.pattern(RestrictSpace)],
            addressLine2: [null, Validators.pattern(RestrictSpace)],
            city: [null],
            state: [null],
            zip: [null, Validators.pattern(/^[0-9]{5}$/)],
            payeeId: [null],
            effectiveDate: [null, [Validators.required, Validators.pattern(ValidDate)]],
            terminationDate: [null, Validators.pattern(ValidDate)],
            networkName: [null]
        })
    }

    getNetworks() {
        this.networkService.getAll({ limit: 0, fields: 'code,name' }).subscribe(res => {
            res.rows.map(network => {
                network.label = network.name
                network.value = network.code
                return network
            })
            this.getPayeeList(this.query)
            this.backupNetworks = JSON.parse(JSON.stringify(res.rows))
            this.networks = JSON.parse(JSON.stringify(res.rows))
            this.networksForFilter = JSON.parse(JSON.stringify(res.rows))
        },
            (error) => {
                console.log('Error', error)
            })
    }

    getPayeeList(query) {
        this.payeeService.getPayee(query).subscribe(res => {
            this.loading = false
            this.totalCount = res.count;
            res.rows.map(payee => {
                if (this.networks)
                    payee.networkName = this.networks.find((network: any) => network.code === payee?.network)?.name;
                payee.createdAt = payeeDateFormat(payee.createdAt, MMDDYYYY);
                payee.statusClass = activeClass(payee.isActive);
                payee.effectiveDate = payeeDateFormat(payee.effectiveDate, MMDDYYYY)
                payee.terminationDate = payeeDateFormat(payee.terminationDate, MMDDYYYY)
            });
            this.payeeList = res.rows;
        })
    }

    filterByNetwork(event, type?) {
        this.query.network = (type && type == 'remove') ? '' : event.value;
        this.query.page = 1;
        if (!type) {
            this.networksForFilter = this.networksForFilter.map((x: any) => {
                if (x.value == event.value) x.disabled = true
                else x.disabled = false
                return x
            })
        }
        else {
            this.networksForFilter = this.networksForFilter.map((x: any) => { x.disabled = false; return x })
        }
        this.loading = true;
        this.getPayeeList(this.query);
    }

    payeeCollapsed(payee) {
        payee.collapsed = !payee.collapsed;
    }

    setOrder(value: string) {
        switch (value) {
            case 'createdAt':
                this.reverseCreatedDateSort = !this.reverseCreatedDateSort;
                break;

            case 'name':
                this.reverse = !this.reverse;
                break;

            default:
        }
        this.query.sortType = this.query.sortType === "desc" ? "asc" : "desc";
        this.query.sortBy = value;
        this.getPayeeList(this.query);
    }

    pageChanged(event) {
        this.query.page = event;
        this.loading = true;
        this.getPayeeList(this.query);
    }

    change(text) {
        this.searchModelChanged.next(text);
    }

    removeSearchKeyword() {
        this.query.searchKey = ''
        this.change('')
    }

    openPayeeModal(payeeInfo?: any) {
        this.selectedPayee = payeeInfo
        if (payeeInfo) {
            this.isEditPayee = true
            this.payeeForm.patchValue(payeeInfo)
        }

        this.submitted = false
        this.addPayeeModal.show()
    }

    closePayeeModal() {
        if (this.isEditPayee) this.isEditPayee = false
        this.setPayeeForm()
        this.loading = false;
        this.buttonSpinner = false;
        this.hidePayeeModal();
        this.submitted = false;
    }

    hidePayeeModal() {
        this.addPayeeModal.hide();
        this.selectedPayee = null
    }

    addPayee($event) {
        this.submitAnyway = false;
        if ($event) {
            this.submitted = true
            const payeeFields = { ...this.payeeForm.value, forceAdd: true }
            delete payeeFields.networkName
            if (this.payeeForm.valid) {
                this.buttonSpinner = true
                if (this.isEditPayee) {
                    this.updatePayee({ ...payeeFields, id: this.selectedPayee.id });
                } else {
                    this.savePayee(payeeFields, this.forceAdd)
                }
            } else {
                this.submitted = true;
                this.buttonSpinner = false;
            }
        }
        else {
            this.buttonSpinner = false;
        }
    }

    forceAddPayee() {
        this.submitPayee(true)
    }

    submitPayee(forceAdd = false) {
        this.submitted = true;
        let payeeFields = { ...this.payeeForm.value }
        payeeFields = { ...this.payeeForm.value, forceAdd: false };

        delete payeeFields.networkName
        if (this.payeeForm.valid) {
            this.buttonSpinner = true
            if (this.isEditPayee) {
                this.updatePayee({ ...payeeFields, id: this.selectedPayee.id }, forceAdd);
            } else {
                this.savePayee(payeeFields, forceAdd)
            }
        } else {
            this.submitted = true;
            this.buttonSpinner = false;
        }

    }

    deletePayee(payee) {
        this.message = `Are you sure you want to delete <strong>payee</strong>? This is irreversible.
        If you're not sure, you can click <strong>Cancel</strong>.`
        this.deleteId = payee.id
    }

    confirmDelete(id) {
        if (id) {
            this.payeeService.deletePayee(id).subscribe(res => {
                this.toaster.success('Succesfully Deleted', "Success");

                const index = this.payeeList.findIndex((eachPayee) => eachPayee.id == id);
                if (index !== -1) {
                    this.payeeList.splice(index, 1);
                }

            })
        }
        this.deleteId = null
    }

    deactivatePayee(payee) {
        payee.isActive = false
        this.submitted = true
        this.updatePayee(payee)
        payee.statusClass = 'danger'
    }

    forceUpdatePayee($event) {
        this.updateAnyway = false;
        this.buttonSpinner = true;
        if ($event) {
            this.payeeService.updatePayee({ ...this.selectedPayee, forceAdd: true }).subscribe(res => {
                res.networkName = this.networks.find((network: any) => network.code === res?.network)?.name;
                res.statusClass = activeClass(res.isActive);
                res.createdAt = payeeDateFormat(res.createdAt, MMDDYYYY)
                res.effectiveDate = payeeDateFormat(res.effectiveDate, MMDDYYYY)
                res.terminationDate = payeeDateFormat(res.terminationDate, MMDDYYYY)
                this.toaster.success('Succesfully Updated', "Success");

                const index = this.payeeList.findIndex((eachPayee) => eachPayee.id == this.selectedPayee.id);
                if (index !== -1) {
                    this.payeeList[index] = res;
                }

                this.buttonSpinner = false;
                this.closePayeeModal();
            }), (e) => {
                this.buttonSpinner = false;
                this.loading = false;

            }
        } else {
            // this.updateAnyway = false;
            this.buttonSpinner = false;
        }
    }

    payeeMapper(res: any, payee) {
        res.networkName = this.networks.find((network: any) => network.code === res?.network)?.name;
        res.statusClass = activeClass(res.isActive);
        res.createdAt = payeeDateFormat(res.createdAt, MMDDYYYY)
        res.effectiveDate = payeeDateFormat(res.effectiveDate, MMDDYYYY)
        res.terminationDate = payeeDateFormat(res.terminationDate, MMDDYYYY)
        this.toaster.success('Succesfully Updated', "Success");
        const index = this.payeeList.findIndex((eachPayee) => eachPayee.id == payee.id);
        if (index !== -1) {
            this.payeeList[index] = res;
        };

        this.selectedPayee = payee
        this.buttonSpinner = false;
        this.closePayeeModal();
    }

    savePayee(payeeFields, forceAdd) {
        this.payeeService.savePayee(payeeFields, forceAdd).subscribe(res => {
            if (res.status === DUPLICATE_STATUS) {
                this.buttonSpinner = false
                this.loading = false
                this.addPayeeAnywayMessage = `The payee with same npi, tax id and zip already exists. Are you sure you want to add <strong>payee</strong>?
                        If you're not sure, you can click <strong>Cancel</strong>.`
                this.buttonSpinner = false
                this.submitAnyway = true;
            } else {
                this.submitted = false
                this.buttonSpinner = false
                res.networkName = this.networks.find((network: any) => network.code === res?.network)?.name;
                res.createdAt = payeeDateFormat(res.createdAt, MMDDYYYY)
                res.effectiveDate = payeeDateFormat(res.effectiveDate, MMDDYYYY)
                res.terminationDate = payeeDateFormat(res.terminationDate, MMDDYYYY)
                res.statusClass = activeClass(res.isActive);
                if (!this.query.network || this.query.network == res.network) {
                    this.payeeList.unshift(res);
                }

                this.closePayeeModal();
                this.toaster.success('Succesfully Added', "Success");
            }


        }, (e) => {
            this.loading = false
        });
    }

    updatePayee(payee, forceUpdate = false) {
        this.selectedPayee = payee;
        this.payeeService.updatePayee(payee, forceUpdate).subscribe(res => {
            if (res?.status === DUPLICATE_STATUS) {
                this.buttonSpinner = false
                this.loading = false
                this.updatePayeeAnywayMessage = `The payee with same npi, tax id and zip already exists. Are you sure you want to update <strong>payee</strong>?
                        If you're not sure, you can click <strong>Cancel</strong>.`
                this.buttonSpinner = false
                this.updateAnyway = true;
            } else {
                this.payeeMapper(res, payee);
            }
        }, (e) => {
            this.buttonSpinner = false
            this.loading = false;
        })
    }

    activatePayee(payee) {
        payee.isActive = true
        this.submitted = true
        this.updatePayee(payee)
        payee.statusClass = 'success'
    }

}

