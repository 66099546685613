import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { environment } from '../../environments/environment';
// import { Storage } from '../utils'

// const API_BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class EpisodeTaskService {

  constructor(
    private http: HttpClientService,
    // private httpClient: HttpClient,
    // private _storage: Storage
  ) { }

  // createHeader(formdata?) {
  //   let headers;
  //   const loggedInUser: any = this._storage.get('local', 'loggedInUser')
  //   if (loggedInUser) {
  //     const token = loggedInUser.token;
  //     headers = new HttpHeaders().set('Authorization', token)
  //   }
  //   return headers;
  // }

  // getTopics(milestone_id) {
  //   return this.http.get(`${APICONSTANTS.TASK}/${milestone_id}`).pipe(
  //     map((res: any) => {
  //       return res.data;
  //     })
  //   );
  // }

  saveTopic(topic) {
    return this.http.post(`${APICONSTANTS.EPISODE}/${topic.milestoneId}${APICONSTANTS.TASK}`, topic)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateTopic(topic) {
    return this.http.put(`${APICONSTANTS.EPISODE}/${topic.milestoneId}${APICONSTANTS.TASK}`, topic)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getTasks(milestone_id) {
    return this.http.get(`${APICONSTANTS.EPISODE}${APICONSTANTS.MILESTONE}/${milestone_id}${APICONSTANTS.TASK}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  saveTask(task) {
    let formData = new FormData();
    if (task.holistaMedia && task.fileName) {
      formData.append('holistaMedia', task.holistaMedia, task.fileName);
      formData.append('fileName', task.fileName);
    }
    else
      formData.append('taskTodoLink', task.taskTodoLink ? task.taskTodoLink : '');
    if (task.parentUuid)
      formData.append('parentUuid', task.parentUuid);
    if (task.sequence)
      formData.append('sequence', task.sequence);
    task.type && formData.append('type', task.type);
    task.isNotifiableToAssignor && formData.append('isNotifiableToAssignor', task.isNotifiableToAssignor);
    task.isNotifiableToAssignee && formData.append('isNotifiableToAssignee', task.isNotifiableToAssignee);
    task.docuSignTemplateId && formData.append('docuSignTemplateId', task.docuSignTemplateId);
    task.isAcknowledgedRequired && formData.append('isAcknowledgedRequired', task.isAcknowledgedRequired);
    formData.append('assignedTo', task.assignedTo);
    formData.append('assignedBy', task.assignedBy);
    formData.append('taskId', task.taskId);
    formData.append('name', task.name);
    formData.append('instruction', task.instruction ? task.instruction : '');
    formData.append('isHighPriority', task.isHighPriority);
    formData.append('optionId', task.optionId ? task.optionId : 0);
    formData.append('isDependent', task.isDependent ? task.isDependent : false);
    formData.append('milestoneId', task.milestoneId);
    formData.append('documentDisplayName', task.documentDisplayName);
    formData.append('episodeId', task.episodeId);
    formData.append('escalationDays', task.escalationDays);
    formData.append('assignedByRole', task.assignedByRole);
    formData.append('assignedToRole', task.assignedToRole);
    if (task.supervisorOf && task.supervisorOfId) {
      formData.append('supervisorOf', task.supervisorOf);
      formData.append('supervisorOfId', task.supervisorOfId);
    }
    return this.http.post(`${APICONSTANTS.EPISODE}/${task.taskId}${APICONSTANTS.TASKTODO}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateTask(task) {
    let formData = new FormData();
    if (task.holistaMedia && task.fileName) {
      formData.append('holistaMedia', task.holistaMedia, task.fileName);
      formData.append('fileName', task.fileName);
    }
    else
      formData.append('taskTodoLink', task.taskTodoLink ? task.taskTodoLink : '');
    formData.append('id', task.id);
    formData.append('parentUuid', task.parentUuid);
    task.sequence && formData.append('sequence', task.sequence);
    task.type && formData.append('type', task.type);
    task.isNotifiableToAssignor && formData.append('isNotifiableToAssignor', task.isNotifiableToAssignor);
    task.isNotifiableToAssignee && formData.append('isNotifiableToAssignee', task.isNotifiableToAssignee);
    task.docuSignTemplateId && formData.append('docuSignTemplateId', task.docuSignTemplateId);
    task.isAcknowledgedRequired && formData.append('isAcknowledgedRequired', task.isAcknowledgedRequired);
    formData.append('documentDisplayName', task.documentDisplayName);
    formData.append('assignedTo', task.assignedTo);
    formData.append('assignedBy', task.assignedBy);
    formData.append('taskId', task.taskId);
    formData.append('name', task.name);
    formData.append('isHighPriority', task.isHighPriority);
    formData.append('instruction', task.instruction ? task.instruction : '');
    formData.append('optionId', task.optionId ? task.optionId : 0);
    formData.append('isDependent', task.isDependent ? task.isDependent : false);
    formData.append('milestoneId', task.milestoneId);
    formData.append('episodeId', task.episodeId);
    formData.append('escalationDays', task.escalationDays);
    formData.append('assignedByRole', task.assignedByRole);
    formData.append('assignedToRole', task.assignedToRole);
    if (task.supervisorOf && task.supervisorOfId) {
      formData.append('supervisorOf', task.supervisorOf);
      formData.append('supervisorOfId', task.supervisorOfId);
    }
    return this.http.put(`${APICONSTANTS.EPISODE}/${task.taskId}${APICONSTANTS.TASKTODO}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  saveQuestion(question) {
    return this.http.post(`${APICONSTANTS.EPISODE}/${question.taskId}/questions`, question)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateQuestion(question) {
    return this.http.put(`${APICONSTANTS.EPISODE}/${question.taskId}/questions`, question)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getQuestionTypes() {
    return this.http.get(`${APICONSTANTS.EPISODE}/${APICONSTANTS.QUESTION_TYPE}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveMessage(message) {
    let formData = new FormData();
    if (message.holistaMedia && message.fileName) {
      formData.append('holistaMedia', message.holistaMedia, message.fileName);
      formData.append('fileName', message.fileName);
    }
    else
      formData.append('documentPath', message.documentPath ? message.documentPath : '');
    if (message.parentUuid)
      formData.append('parentUuid', message.parentUuid);
    if (message.sequence)
      formData.append('sequence', message.sequence);
    formData.append('title', message.title);
    formData.append('taskId', message.taskId);
    formData.append('messages', message.messages);
    formData.append('messageFrom', message.messageFrom);
    formData.append('messageTo', message.messageTo);
    formData.append('severity', message.severity);
    formData.append('optionId', message.optionId ? message.optionId : 0);
    formData.append('isDependent', message.isDependent ? message.isDependent : false);
    formData.append('milestoneId', message.milestoneId);
    formData.append('documentDisplayName', message.documentDisplayName);
    formData.append('episodeId', message.episodeId);
    formData.append('escalationDays', message.escalationDays);
    formData.append('messageFromRole', message.assignedByRole);
    formData.append('messageToRole', message.assignedToRole);
    if (message.supervisorOf && message.supervisorOfId) {
      formData.append('supervisorOf', message.supervisorOf);
      formData.append('supervisorOfId', message.supervisorOfId);
    }
    return this.http.post(`${APICONSTANTS.EPISODE}/${message.taskId}${APICONSTANTS.MESSAGE}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateMessage(message) {
    let formData = new FormData();
    if (message.holistaMedia && message.fileName) {
      formData.append('holistaMedia', message.holistaMedia, message.fileName);
      formData.append('fileName', message.fileName);
    }
    else
      formData.append('documentPath', message.documentPath ? message.documentPath : '');
    formData.append('id', message.id);
    formData.append('parentUuid', message.parentUuid);
    if (message.sequence)
      formData.append('sequence', message.sequence);
    formData.append('title', message.title);
    formData.append('taskId', message.taskId);
    formData.append('messages', message.messages);
    formData.append('messageFrom', message.messageFrom);
    formData.append('messageTo', message.messageTo);
    formData.append('severity', message.severity);
    formData.append('optionId', message.optionId ? message.optionId : 0);
    formData.append('isDependent', message.isDependent ? message.isDependent : false);
    formData.append('milestoneId', message.milestoneId);
    formData.append('documentDisplayName', message.documentDisplayName);
    formData.append('episodeId', message.episodeId);
    formData.append('escalationDays', message.escalationDays);
    formData.append('messageFromRole', message.assignedByRole);
    formData.append('messageToRole', message.assignedToRole);
    if (message.supervisorOf && message.supervisorOfId) {
      formData.append('supervisorOf', message.supervisorOf);
      formData.append('supervisorOfId', message.supervisorOfId);
    }
    return this.http.put(`${APICONSTANTS.EPISODE}/${message.taskId}${APICONSTANTS.MESSAGE}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  updateTaskStatus(task) {
    return this.http.post(`${APICONSTANTS.EPISODE}/task/status-update`, task)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getTaskCompletionReasons() {
    return this.http.get(`${APICONSTANTS.EPISODE}/task-completion-reasons`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  saveAnswer(answer) {
    return this.http.post(`${APICONSTANTS.EPISODE}/question-answer`, answer)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateAnswer(answer) {
    return this.http.put(`${APICONSTANTS.EPISODE}/question-answer`, answer)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  searchQuestions(search_text) {
    return this.http.get(`${APICONSTANTS.EPISODE}/questions/${search_text}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveQuestionnaire(questionnaire) {
    return this.http.post(`${APICONSTANTS.EPISODE}/${questionnaire.taskId}/questionnaires`, questionnaire)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateQuestionnaire(questionnaire) {
    return this.http.put(`${APICONSTANTS.EPISODE}/${questionnaire.taskId}/questionnaires`, questionnaire)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  changeTaskSequence(tasks) {
    return this.http.post(`${APICONSTANTS.EPISODE}/order`, tasks)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getLinkToDocuSign(episodeId: number, taskId: number, params) {
    return this.http.get(`${APICONSTANTS.EPISODE}/${episodeId}${APICONSTANTS.TASK}/${taskId}/request-sign`, params)
      .pipe(map((response: any) => response.data));
  }
}
