<section class="container-fluid donotprint">
  <div
    class="d-flex justify-content-between align-items-center mt-4 action position-sticky"
    *ngIf="!selectedEpisode">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group input-group has-search mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          [attr.data-test-id]="'episode-search'"
          type="text"
          [(ngModel)]="searchKeyword"
          (ngModelChange)="searchEpisodeByKeyword($event)"
          class="form-control"
          id="search"
          placeholder="Search" />
        <div class="input-group-append" *ngIf="searchKeyword">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
      <div *ngIf="!usermode">
        <button
          [attr.data-test-id]="'episode-filter-button'"
          type="button"
          class="btn btn-light z-depth-0 pl-3 pr-3"
          data-toggle="modal"
          data-target="#basicExample"
          [attr.data-test-id]="'filter-button'"
          (click)="OpenFilterModal()"
          mdbWavesEffect>
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50 mt-2 mb-2">
      <div style="width: 40%; margin-top: 5px" *ngIf="user.referenceType === 'PLATFORM'">
        <app-select
          [attr.data-test-id]="'episode-client-select'"
          [options]="filterModel.clientList"
          [id]="'filterClient'"
          [placeholder]="'Select client'"
          [(selected)]="defaultClient"
          (onSelected)="filterEpisodeClient($event)"
          (onDeselected)="filterEpisodeClient($event, 'remove')"
          [allowClear]="true"
          [isTemplateDriven]="true"></app-select>
      </div>
      <button
        [attr.data-test-id]="'export-episode-button'"
        *ngIf="user.roleCode != 'MP'"
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        [disabled]="!episodes || episodes.length === 0 || loading"
        (click)="exportToCsv()"
        data-target="#basicExample"
        mdbWavesEffect>
        <span
          *ngIf="isExportLoading"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"></span>
        <i class="fas fa-file-export"></i>
        <span class="d-none d-sm-inline-block ml-1"> Export</span>
      </button>
      <button
        [attr.data-test-id]="'add-episode-button'"
        *ngIf="!usermode"
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        data-target="#basicExample"
        (click)="createEpisode()"
        mdbWavesEffect>
        <i class="icofont-plus"></i>
        <span class="d-none d-sm-inline-block ml-1"> ADD</span>
      </button>
    </div>
  </div>
  <div class="keywords position-sticky" *ngIf="filterList && filterList.length > 0">
    <div class="keywords d-flex mt-2 flex-wrap align-items-center">
      <small class="mb-2">Filters: </small>
      <span class="d-block mr-1 mb-2" *ngFor="let filter of filterList">
        <small class="d-block text-muted ml-1"></small>
        <div class="chip blue lighten-5 mb-0">
          {{ filter.field }}: <span class="font-weight-500 text-dark">{{ filter.label }}</span>
          <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
        </div>
      </span>
      <button
        type="button"
        class="btn btn-link waves-light m-0 p-0"
        mdbWavesEffect
        (click)="ResetFilter()">
        <span class="text-info">Reset Filter</span>
      </button>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table
          mdbTable
          hover="true"
          small="true"
          class="table-mobile-view v-top mb-5"
          stickyHeader="true">
          <thead>
            <tr>
              <th width="20"></th>
              <th *ngIf="!usermode">Patient</th>
              <th>Episode of Care</th>
              <th>Bundle Name</th>
              <th>Facility</th>
              <th>Procedure Date</th>
              <th>Care Coordinator</th>
              <th>Status</th>
              <th>Created Date</th>
              <!-- <th *ngIf="!usermode" [class.active]="sortBy === 'patient.firstName'"
                (click)="setOrder('patient.firstName')">Patient
                <span *ngIf="sortBy === 'patient.firstName'">
                  <span [hidden]="!reverse"><i class="fas fa-sort-down "></i></span>
                  <span [hidden]="reverse"><i class="fas fa-sort-up "></i></span>
                </span>
              </th>
              <th [class.active]="sortBy === 'name'" (click)="setOrder('name')">Episode of Care
                <span *ngIf="sortBy === 'name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i> </span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i> </span>
                </span>
              </th>
              <th [class.active]="sortBy === 'episodeBundle.name'" (click)="setOrder('episodeBundle.name')">Bundle
                Name
                <span *ngIf="sortBy === 'episodeBundle.name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i> </span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i> </span>
                </span>
              </th>
              <th [class.active]="sortBy === 'facility.name'" (click)="setOrder('facility.name')">Facility
                <span *ngIf="sortBy === 'facility.name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i> </span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i> </span>
                </span>
              </th>
              <th [class.active]="sortBy === 'cc.firstName'" (click)="setOrder('cc.firstName')">Care Coordinator
                <span *ngIf="sortBy === 'cc.firstName'">
                  <span [hidden]="!reverse"><i class="fas fa-sort-down"></i> </span>
                  <span [hidden]="reverse"><i class="fas fa-sort-up"></i> </span>
                </span>
              </th>
              <th>Status</th>
              <th width="110" [class.active]="sortBy === 'createdAt'" (click)="setOrder('createdAt')">Created
                Date
                <span *ngIf="sortBy === 'createdAt'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i> </span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i> </span>
                </span>
              </th> -->
              <th></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading"
            [style.top]="episodes && episodes.length === 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            mdbTableCol
            *ngFor="
              let episode of episodes
                | paginate : {itemsPerPage: limit, currentPage: page, totalItems: totalCount};
              let i = index
            ">
            <tr
              [attr.data-test-id]="'episode-row'"
              [ngClass]="
                episode.status ? episode.status.replace(' ', '').toLowerCase() : 'preliminary'
              ">
              <td (click)="episodeCollapsed(episode)">
                <button
                  [attr.data-test-id]="'episode-button-expand'"
                  type="button"
                  class="btn btn-link p-0 mt-0"
                  mdbWavesEffect
                  *ngIf="episode.collapsed">
                  <i class="icofont-minus-circle icofont-lg text-info"></i>
                </button>
                <button
                  [attr.data-test-id]="'episode-button-collapse'"
                  type="button"
                  class="btn btn-link p-0 mt-0"
                  mdbWavesEffect
                  *ngIf="!episode.collapsed">
                  <i class="icofont-plus-circle icofont-lg text-info"></i>
                </button>
              </td>
              <td
                [attr.data-test-id]="'episode-patient-name'"
                *ngIf="!usermode"
                (click)="episode.userId && ViewPatientDemographic(episode.userId)"
                width="170">
                {{
                  episode.patient
                    ? episode.patient.firstName + ' ' + episode.patient.lastName
                    : episode.claimPatient
                    ? episode.claimPatient.fullName
                    : ''
                }}
                <br />
                <span
                  [attr.data-test-id]="'episode-purchase-name'"
                  class="badge badge-sm bg-primary-dark text-white z-depth-0">
                  {{ episode?.purchaser?.name }}
                </span>
                <br />
                <span
                  [attr.data-test-id]="'episode-sso-patient'"
                  class="z-depth-0 badge-primary badge badge-sm"
                  *ngIf="episode?.isSSO">
                  SSO Patient
                </span>
              </td>
              <td [attr.data-test-id]="'episode-of-care-column'" (click)="goToMileStone(episode)">
                <div class="d-flex">
                  <span class="d-inline-block d-sm-none w-25">Episode of care</span>
                  <div
                    [attr.data-test-id]="'episode-of-care-name'"
                    class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0">
                    {{ episode?.name }}
                  </div>
                </div>
              </td>
              <td [attr.data-test-id]="'bundle-name-column'" (click)="goToMileStone(episode)">
                <div class="d-flex">
                  <span class="d-inline-block d-sm-none w-25">Bundle Name</span>
                  <div
                    [attr.data-test-id]="'episode-bundle-name'"
                    class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0">
                    {{ episode?.episodeBundle?.name || episode?.episodeOfCare?.bundle?.name }}
                  </div>
                </div>
              </td>
              <td [attr.data-test-id]="'facility-column'" (click)="goToMileStone(episode)">
                <div class="d-flex">
                  <span class="d-inline-block d-sm-none w-25">Facility</span>
                  <div
                    [attr.data-test-id]="'episode-facility-name'"
                    class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0">
                    {{ episode?.facility?.name }}
                  </div>
                </div>
              </td>
              <td [attr.data-test-id]="'procedure-date-column'" (click)="goToMileStone(episode)">
                <div class="d-flex">
                  <span class="d-inline-block d-sm-none w-25">Procedure Date</span>
                  <div
                    [attr.data-test-id]="'episode-procedure-date'"
                    class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0 text-center">
                    {{ episode?.procedureDate }}
                  </div>
                </div>
              </td>
              <td [attr.data-test-id]="'care-coordinator-column'" (click)="goToMileStone(episode)">
                <div class="d-flex">
                  <span class="d-inline-block d-sm-none w-25">Care Coordinator</span>
                  <div
                    [attr.data-test-id]="'episode-care-coordinator'"
                    class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0">
                    {{ episode?.ccName }}
                  </div>
                </div>
              </td>
              <td [attr.data-test-id]="'episode-status-column'" width="140">
                <div class="d-flex flex-column">
                  <span class="d-inline-block d-sm-none w-25">Status</span>
                  <div class="w-xs-75 w-100">
                    <div
                      class="chip alert m-0 w-auto rounded-sm"
                      [attr.data-test-id]="'episode-status'"
                      [ngClass]="
                        episode.status
                          ? episode.status.replace(' ', '').toLowerCase()
                          : 'preliminary'
                      ">
                      <div
                        *ngIf="episode.episodeActionStatus.length"
                        [ngClass]="{
                          'custom-dropdown': utilityAccess.searchAccess('EM', 'isEditable', false)
                        }"
                        class="">
                        <div class="d-flex justify-content-between w-100 pl-2 pr-2">
                          {{ episode.status | titlecase }}
                          <button
                            [attr.data-test-id]="'episode-status-button'"
                            class="btn btn-link border-0 dropdown-toggle waves-light m-0 ml-2 p-0"
                            type="button"></button>
                        </div>
                        <div
                          [attr.data-test-id]="'episode-status-dropdown'"
                          class="custom-dropdown-content drop-satatus rounded-sm z-depth-2">
                          <a
                            *ngFor="let status of episode.episodeActionStatus"
                            [attr.data-test-id]="'episode-status-' + status.value.toLowerCase()"
                            class="cursor-pointer dropdown-item"
                            (click)="optionSelected(status, episode)">
                            {{ status.label }}</a
                          >
                        </div>
                      </div>
                      <span
                        [attr.data-test-id]="'episode-status-label'"
                        class="status-label"
                        placement="left"
                        *ngIf="!episode.episodeActionStatus.length">
                        {{ episode.status ? episode.status.toLowerCase() : 'Preliminary' }}
                      </span>
                    </div>
                  </div>
                  <small
                    [attr.data-test-id]="'episode-cancel-reason'"
                    *ngIf="episode.cancelReason"
                    class="is-cancelled"
                    [class.mt-2]="user.roleCode === 'PA'"
                    >{{ episode.cancelReason }}</small
                  >
                </div>
              </td>
              <td
                [attr.data-test-id]="'episode-created-date-column'"
                (click)="goToMileStone(episode)">
                <div class="d-flex">
                  <span class="d-inline-block d-sm-none w-25">Created Date</span>
                  <div
                    [attr.data-test-id]="'episode-created-date'"
                    class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0">
                    {{ episode?.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}
                  </div>
                </div>
              </td>
              <td width="32" class="d-none d-sm-block">
                <div class="custom-dropdown">
                  <button
                    [attr.data-test-id]="'episode-ellipsis'"
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div
                    [attr.data-test-id]="'episode-ellipsis-dropdown'"
                    class="custom-dropdown-content rounded-sm z-depth-2">
                    <a
                      [attr.data-test-id]="'episode-detail'"
                      (click)="goToMileStone(episode)"
                      class="cursor-pointer dropdown-item"
                      ><i class="icofont-eye-alt"></i> Detail</a
                    >
                    <a
                      [attr.data-test-id]="'episode-edit'"
                      (click)="editEpisode(episode)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="!usermode"
                      ><i class="icofont-edit-alt"></i> Edit</a
                    >
                    <a
                      [attr.data-test-id]="'episode-view-id-card'"
                      (click)="viewIdCard(episode)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="
                        episode.status.toLowerCase() !== 'cancelled' &&
                        episode.purchaserCode !== PURCHASER_CODES.carrum
                      ">
                      <i class="icofont-id-card"></i>
                      ID Card</a
                    >
                    <a
                      [attr.data-test-id]="'episode-send-email'"
                      (click)="onSendEmail(episode)"
                      class="cursor-pointer dropdown-item">
                      <i class="fa-fa fa-envelope fas"></i> Send Email</a
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="episode.collapsed" class="grey lighten-5 border">
              <td colspan="100%" class="p-0 p-sm-2 pl-sm-5">
                <table mdbTable small="true" borderless="true" class="grey lighten-5 mb-0">
                  <thead>
                    <tr style="background-color: transparent">
                      <th
                        width="25%"
                        *ngFor="let head of masterHeadElements"
                        scope="col"
                        class="pb-0">
                        {{ head }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="background-color: transparent">
                      <td class="pt-0">
                        <div class="d-flex">
                          <span class="d-inline-block d-sm-none w-25">Provider</span>
                          <div
                            class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0">
                            {{ episode?.provider?.name ? episode.provider.name : 'N/A' }}
                          </div>
                        </div>
                      </td>
                      <td class="pt-0">
                        <div class="d-flex">
                          <span class="d-inline-block d-sm-none w-25">Benefit Start Date</span>
                          <div
                            class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0">
                            {{
                              episode.benefitStartDate
                                ? (episode.benefitStartDate | date : 'MM/dd/yyyy')
                                : 'N/A'
                            }}
                          </div>
                        </div>
                      </td>
                      <td class="pt-0">
                        <div class="d-flex">
                          <span class="d-inline-block d-sm-none w-25">Benefit End Date</span>
                          <div
                            class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0">
                            {{
                              episode.benefitEndDate
                                ? (episode.benefitEndDate | date : 'MM/dd/yyyy')
                                : 'N/A'
                            }}
                          </div>
                        </div>
                      </td>
                      <td class="pt-0 pr-0">
                        <div class="d-flex">
                          <span class="d-inline-block d-sm-none w-25">Engagement Specialist</span>
                          <div
                            class="field-value w-xs-75 w-100 font-weight-normal pt-0 pl-2 pl-sm-0">
                            {{ episode?.esName }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <pagination-controls
      *ngIf="episodes && episodes.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>

  <div class="text-center p-4 border mt-3" *ngIf="!loading && (!episodes || episodes.length === 0)">
    <i class="fa fa-3x fa-layer-group text-black-50"></i>
    <p *ngIf="usermode">No Episodes available.</p>
    <p *ngIf="!result.episodes && !usermode">Start adding your Episodes.</p>
    <p *ngIf="!result.searchedEpisodes && !usermode">No results found.</p>
  </div>
</section>

<app-episode-milestone
  *ngIf="selectedEpisode && selectedEpisode.id"
  [selected_episode]="selectedEpisode"
  [isMobile]="isMobile">
</app-episode-milestone>

<!-- Episode Add/Edit Modal -->
<div
  mdbModal
  #episodeModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ episodeForm.value.id ? 'Edit' : 'Add' }} Episode</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="episodeForm">
          <div class="form-group position-relative">
            <label>Choose Patient *</label>
            <span
              class="spinner-grow spinner-grow-sm ml-1"
              role="status"
              aria-hidden="true"
              *ngIf="memberSearchLoading"></span>
            <input
              type="text"
              [ngModel]="createUserSearch.user_search_text | async"
              (ngModelChange)="createUserSearch.user_search_text.next($event)"
              [ngModelOptions]="{standalone: true}"
              class="completer-input form-control mdb-autocomplete mb-0"
              id="autocompleteEpisodeUser"
              [attr.data-test-id]="'episode-autocomplete-episode-user'"
              autocomplete="off"
              [mdbAutoCompleter]="auto"
              placeholder="Type to select patient"
              [readonly]="episodeForm.value.id"
              [disabled]="episodeForm.value.id"
              (change)="searchUserChange()"
              [ngClass]="{'is-invalid': submitted && episodeForm.get('userId').invalid}" />
            <mdb-auto-completer
              #auto="mdbAutoCompleter"
              textNoResults="No patient found"
              [displayValue]="onDisplayValue"
              (selected)="onUserSelect($event, 'episodeForm')"
              [disabled]="episodeForm.value.id">
              <mdb-option
                *ngFor="let option of createUserSearch.user_results | async"
                [value]="option">
                <div
                  class="d-flex flex-column"
                  mdbTooltip="{{ option.firstName }} {{ option.lastName }}"
                  placement="right">
                  <strong
                    >{{ option.firstName }} {{ option.lastName }}
                    <small>({{ option.dob | date : 'MM/dd/yyyy' }})</small></strong
                  >
                  <small>{{ option.purchaserName ? option.purchaserName : '' }}</small>
                </div>
              </mdb-option>
            </mdb-auto-completer>
          </div>

          <!-- Episode of care starts -->
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label for="eocUuid"
                  >Choose Episode of Care *
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="
                      episodeCreate.getInfo &&
                      (!episodeCreate.episodeCareList || episodeCreate.episodeCareList.length === 0)
                    "></span
                ></label>
                <app-select
                  [options]="episodeCreate.episodeCareList"
                  [placeholder]="'Select episode of care'"
                  [id]="'eocUuid'"
                  [attr.data-test-id]="'autocomplete-eoc'"
                  [control]="episodeForm.controls['eocUuid']"
                  [disabled]="
                    !episodeCreate.search_member ||
                    !episodeCreate.episodeCareList ||
                    episodeCreate.episodeCareList.length === 0 ||
                    episodeForm.value.id
                  "
                  (onSelected)="episodeCareSelected($event)"
                  [inputClass]="{
                    'error-select': submitted && episodeForm.get('eocUuid').invalid,
                    'pointer-disabled': episodeForm.value.id
                  }"></app-select>
              </div>
            </div>
            <!-- Episode of care lable ends -->

            <!-- tag label ends -->

            <!-- <div class="form-group"> -->

            <!-- tag lable starts -->

            <div class="col-6">
              <div class="form-group">
                <label for="tag">{{ episodeForm.value.id ? 'Tag' : 'Choose Tag' }}</label>
                <mdb-select
                  [options]="episodeCreate.tagsList | sort : 'label'"
                  placeholder="Select tag"
                  formControlName="tag"
                  [attr.data-test-id]="'episode-select-tag'"
                  [allowClear]="true"
                  [disableControl]="tagDisabled(episodeForm.value, episodeCreate.tagsList)"
                  [ngClass]="{
                    'error-select': submitted && episodeForm.get('tag').invalid,
                    'pointer-disabled': episodeForm.value.id
                  }">
                </mdb-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="facility"
                  >Choose Facility *
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="
                      episodeCreate.getInfo &&
                      (!episodeCreate.facilityList || episodeCreate.facilityList.length === 0) &&
                      episodeCreate.episodeCareList.length > 0
                    "></span
                ></label>
                <app-select
                  [options]="episodeCreate.facilityList"
                  [id]="'facility'"
                  [attr.data-test-id]="'episode-autocomplete-facility'"
                  [placeholder]="'Select facility'"
                  mdbInput
                  [control]="episodeForm.controls['facilityId']"
                  (onSelected)="facilitySelected($event, true)"
                  [disabled]="facilityDisabled(user)"
                  [inputClass]="{
                    'error-select': submitted && episodeForm.get('facilityId').invalid,
                    'pointer-disabled': episodeForm.value.id
                  }">
                </app-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="provider"
                  >Choose Clinic
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="
                      episodeCreate.getInfo &&
                      (!episodeCreate.clinicList || episodeCreate.clinicList.length === 0) &&
                      episodeCreate.providerList.length > 0
                    "></span
                ></label>
                <app-select
                  [options]="episodeCreate.clinicList"
                  [id]="'clinic'"
                  [attr.data-test-id]="'episode-autocomplete-clinic'"
                  [placeholder]="'Select clinic'"
                  (onSelected)="clinicSelected($event)"
                  (onDeselected)="onOptionDeselected('clinic')"
                  [disabled]="disableClinic()"
                  [control]="episodeForm.controls['clinicId']"
                  [allowClear]="true"
                  [inputClass]="{
                    'error-select': submitted && episodeForm.get('clinicId').invalid,
                    'pointer-disabled': episodeForm.value.id
                  }">
                </app-select>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label for="provider"
                  >Choose Provider
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="
                      episodeCreate.isGetProviders &&
                      (!episodeCreate.providerList || episodeCreate.providerList.length === 0) &&
                      episodeCreate.facilityList.length > 0
                    "></span
                ></label>
                <app-select
                  [options]="episodeCreate.providerList"
                  [id]="'provider'"
                  [attr.data-test-id]="'episode-autocomplete-provider'"
                  [placeholder]="'Select provider'"
                  (onSelected)="providerSelected($event)"
                  (onDeselected)="onOptionDeselected('provider')"
                  [disabled]="
                    (episodeForm.value.id && episode?.isProcedureDatePassed) ||
                    !episodeCreate.search_member ||
                    !this.episodeForm.value.facilityId ||
                    !episodeCreate.providerList ||
                    episodeCreate.providerList.length === 0 ||
                    EDIT_EPISODE_DISABLED_STATUSES.includes(episodeForm.value.status)
                  "
                  [control]="episodeForm.controls['providerId']"
                  [allowClear]="true"
                  [inputClass]="{
                    'error-select': submitted && episodeForm.get('providerId').invalid,
                    'pointer-disabled': episodeForm.value.id
                  }">
                </app-select>
              </div>
            </div>

            <ng-container
              *ngIf="bundleComponentWithShowInEpisode.length && !hideEpisodeProviderForm">
              <div *ngFor="let bundleComponent of bundleComponentWithShowInEpisode" class="col-6">
                <div class="form-group">
                  <label for="assignedToCC"
                    >Choose {{ bundleComponent?.name }}
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="bundleComponentListProvidersLoader"></span>
                  </label>
                  <app-select
                    [options]="
                      bundleComponentProviderList &&
                      bundleComponentProviderList[bundleComponent?.uuid] &&
                      bundleComponentProviderList[bundleComponent?.uuid]
                    "
                    [id]="bundleComponent?.name"
                    [placeholder]="'Select' + ' ' + bundleComponent?.name"
                    [disabled]="bundleComponentProviderListDisabler(bundleComponent)"
                    (onSelected)="selectedProviderBundleComponent($event)"
                    [control]="providerListForm.controls[bundleComponent.uuid]"
                    (onDeselected)="onBundleComponentProviderClear($event)"
                    [allowClear]="true">
                  </app-select>
                </div>
              </div>
            </ng-container>
          </div>

          <div *ngIf="episodeCreate.selected_episodeCare && !isEocDetailLoading">
            <mdb-card class="z-depth-0 bg-blue-light mb-3 mt-3">
              <mdb-card-body class="p-2">
                <table mdbTable small="true" class="mb-0">
                  <tbody class="bg-transparent">
                    <tr>
                      <td>Bundle</td>
                      <td>
                        {{ episodeCreate.selected_episodeCare.bundle }}
                      </td>
                    </tr>
                    <tr>
                      <td>Pathway</td>
                      <td>
                        {{ episodeCreate.selected_episodeCare.pathway }}
                      </td>
                    </tr>
                    <tr>
                      <td>Member Responsibility</td>
                      <td>
                        {{
                          episodeCreate.selected_episodeCare.memberResponsibility
                            ? '$' + episodeCreate.selected_episodeCare.memberResponsibility
                            : 'N/A'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mdb-card-body>
            </mdb-card>
          </div>
          <app-loader [loader]="isEocDetailLoading"></app-loader>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label for="assignedToCC">Assign To Care Coordinator *</label>
                <app-select
                  [options]="assignedToCCList"
                  [id]="'assignedToCC'"
                  [attr.data-test-id]="'episode-assignedCC'"
                  [placeholder]="'Select assignee'"
                  [disabled]="
                    !episodeCreate.search_member ||
                    EDIT_EPISODE_DISABLED_STATUSES.includes(episodeForm.value.status)
                  "
                  (onSelected)="selectedCC($event)"
                  [control]="episodeForm.controls['assignedToCCId']"
                  [inputClass]="{
                    'error-select': submitted && episodeForm.get('assignedToCCId').invalid,
                    'pointer-disabled': episodeForm.value.id
                  }">
                </app-select>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label for="assignedToES">Assign To Engagement Specialist *</label>
                <app-select
                  [options]="assignedToESList"
                  [id]="'assignedToES'"
                  [attr.data-test-id]="'episode-assignedES'"
                  [placeholder]="'Select assignee'"
                  [disabled]="
                    !episodeCreate.search_member ||
                    EDIT_EPISODE_DISABLED_STATUSES.includes(episodeForm.value.status)
                  "
                  (onSelected)="selectedES($event)"
                  [control]="episodeForm.controls['assignedToESId']"
                  [inputClass]="{
                    'error-select': submitted && episodeForm.get('assignedToESId').invalid,
                    'pointer-disabled': episodeForm.value.id
                  }">
                </app-select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="btn waves-light m-0 btn-light"
          [attr.data-test-id]="'episode-component-cancel-button'"
          mdbWavesEffect
          (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          [attr.data-test-id]="'episode-component-submit-button'"
          mdbWavesEffect
          (click)="submitEpisode()"
          [disabled]="
            isSubmitEnabled ||
            (episodeForm.value.id &&
              (episodeCreate.facilityList.length === 0 ||
                (episodeForm.value.providerId && episodeCreate.providerList.length === 0))) ||
            (episodeForm.value.status && episodeForm.value.status === 'CANCELLED')
          ">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="isSubmitEnabled"></span>
          {{ episodeForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Episode Filter Modal -->
<div
  mdbModal
  #filterModal="mdbModal"
  class="modal fade top"
  id="filterModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter Episode</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFilterModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="filterForm">
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label>Choose Episode Type</label>
                <app-select
                  [options]="filterModel.episodeDropdownList"
                  [placeholder]="'Select episode type'"
                  [multiple]="true"
                  [control]="filterForm.controls['name']"
                  (onDeselected)="onOptionDeselected('name', 'filter')"
                  [allowClear]="true"
                  [enableSelectAll]="false">
                </app-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group position-relative" *ngIf="true">
                <label>Choose Patient</label>
                <input
                  type="text"
                  [ngModel]="filterUserSearch.user_filter_text | async"
                  (ngModelChange)="filterUserSearch.user_filter_text.next($event)"
                  (change)="changes($event)"
                  id="autocompleteFilterUser"
                  [ngModelOptions]="{standalone: true}"
                  class="completer-input form-control mdb-autocomplete mb-0"
                  autocomplete="off"
                  [mdbAutoCompleter]="auto"
                  placeholder="Type to select patient" />
                <mdb-auto-completer
                  #auto="mdbAutoCompleter"
                  textNoResults="No patient found"
                  [displayValue]="onDisplayValue"
                  (selected)="onUserSelect($event, 'filterForm')"
                  [optionHeight]="40">
                  <mdb-option
                    *ngFor="let option of filterUserSearch.user_filter_results | async"
                    [value]="option">
                    <div
                      class="d-flex flex-column"
                      mdbTooltip="{{ option.firstName }} {{ option.lastName }}"
                      placement="right">
                      <strong
                        >{{ option.firstName }} {{ option.lastName }}
                        <small *ngIf="option.dob"
                          >({{ option.dob | date : 'MM/dd/yyyy' }})</small
                        ></strong
                      >
                      <small>{{ option.purchaserName ? option.purchaserName : '' }}</small>
                    </div>
                  </mdb-option>
                </mdb-auto-completer>
              </div>
            </div>
          </div>
          <div class="form-row" *ngIf="user.referenceType != 'PURCHASER'">
            <div class="col-6">
              <div class="form-group">
                <label for="filterAssignedToCC">Choose Care Coordinator</label>
                <app-select
                  [options]="assignedToCCList"
                  [id]="'filterAssignedToCC'"
                  [placeholder]="'Select assignee'"
                  [control]="filterForm.controls['ccId']"
                  (onDeselected)="onOptionDeselected('ccId', 'filter')"
                  [allowClear]="true"
                  [multiple]="true">
                </app-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="filterAssignedToES">Choose Engagement Specialist</label>
                <app-select
                  [options]="assignedToESList"
                  [id]="'filterAssignedToES'"
                  [placeholder]="'Select assignee'"
                  [control]="filterForm.controls['esId']"
                  (onDeselected)="onOptionDeselected('esId', 'filter')"
                  [allowClear]="true"
                  [multiple]="true">
                </app-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label for="filterBundle">Choose Bundle</label>
                <app-select
                  [options]="filterModel.filterBundles"
                  [id]="'filterBundle'"
                  [placeholder]="'Select bundle'"
                  [control]="filterForm.controls['bundleName']"
                  (onDeselected)="onOptionDeselected('bundleName', 'filter')"
                  [allowClear]="true"
                  [multiple]="true">
                </app-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="filterEpisodeStatus">Choose Status</label>
                <app-select
                  [options]="filterModel.statuses"
                  [id]="'filterEpisodeStatus'"
                  [placeholder]="'Select episode status'"
                  [control]="filterForm.controls['status']"
                  (onDeselected)="onOptionDeselected('status', 'filter')"
                  [allowClear]="true"
                  [multiple]="true">
                </app-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label for="filterProvider">Choose Provider</label>
                <app-select
                  [options]="filterModel.filterProviders"
                  [id]="'filterProvider'"
                  [placeholder]="'Select provider'"
                  [control]="filterForm.controls['providerId']"
                  (onDeselected)="onOptionDeselected('providerId', 'filter')"
                  [allowClear]="true"
                  [multiple]="true">
                </app-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="filterFacility">Choose Facility</label>
                <app-select
                  [options]="filterModel.filterFacility"
                  [id]="'filterFacility'"
                  [placeholder]="'Select facility'"
                  [control]="filterForm.controls['facilityId']"
                  (onDeselected)="onOptionDeselected('facilityId', 'filter')"
                  [allowClear]="true"
                  [multiple]="true">
                </app-select>
              </div>
            </div>
          </div>
          <div class="form-row" *ngIf="user.referenceType === 'PLATFORM'">
            <div class="col-6">
              <div class="form-group">
                <label for="filterClient"> Choose Client</label>
                <app-select
                  [options]="filterModel.clientList"
                  [id]="'filterClient'"
                  [placeholder]="'Select client'"
                  (onSelected)="filterEpisodeClient($event, 'filter')"
                  (onDeselected)="onOptionDeselected('clientCode', 'filter')"
                  [allowClear]="true"
                  [control]="filterForm.controls['clientCode']">
                </app-select>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label for="filterPurchaser"> Choose Purchaser</label>
                <app-select
                  [options]="filterModel.purchaserList"
                  [id]="'filterPurchaser'"
                  [placeholder]="'Select purchaser'"
                  [control]="filterForm.controls['purchaserCode']"
                  (onDeselected)="onOptionDeselected('purchaserCode', 'filter')"
                  [allowClear]="true"
                  [multiple]="true">
                </app-select>
              </div>
            </div>
          </div>

          <div
            class="form-group"
            *ngIf="user.referenceType != 'CLIENT' && user.referenceType != 'PURCHASER'">
            <label for="filterNetwork"> Choose Network</label>
            <app-select
              [options]="filterModel.networkList"
              [id]="'filterNetwork'"
              [placeholder]="'Select network'"
              [allowClear]="true"
              [control]="filterForm.controls['networkCode']"
              (onDeselected)="onOptionDeselected('networkCode', 'filter')">
            </app-select>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeFilterModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitFilterEpisode()"
          [disabled]="isSubmitEnabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="isSubmitEnabled"></span
          >Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>

<!-- PATIENT DETAIL MODAL -->
<app-patient-demographic
  [userId]="patientDemographicId"
  (hidePatientDemographic)="closePatientDemographic($event)">
</app-patient-demographic>
<!-- PATIENT DETAIL MODAL -->

<!-- SHOW ID CARD  MODAL-->
<app-id-card [patientIdCard]="patientIdCard" (idCardClose)="idCardClose($event)"> </app-id-card>
<!-- SHOW ID CARD MODAL-->

<!-- EPISODE CANCEL MODAL -->
<app-milestone-topic-delete
  [episodeCancel]="episodeToBeCancel"
  (abortEpisodeCancel)="abortEpisodeCancel($event)"
  (episodeCancelled)="episodeCancelled($event)">
</app-milestone-topic-delete>
<!-- EPISODE CANCEL MODAL -->

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [moduleOptions]="moduleOptions"
  [showModal]="showMessageModal"
  [metaVariablesDetail]="metaVariablesDetail"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->
