<div
  mdbModal
  #messageModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div
    class="modal-dialog modal-lg modal-dialog-centered"
    role="document"
    *ngIf="!isTemplateSelected">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex align-items-center">
          <h5 class="modal-title w-100">Choose Templates</h5>
          <span
            *ngIf="loading.data"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"></span>
        </div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeMessageModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div
        class="modal-body pt-0 sticky-content scrollbar scrollbar-primary thin"
        [ngStyle]="{
          height: 'calc(100vh - 180px)'
        }">
        <div class="row">
          <div class="mt-3 col-md-6" *ngFor="let template of messageTemplates; let i = index">
            <mdb-card
              class="z-depth-0 pointer-cursor skin-light p-2"
              [class.active]="
                this.selectedTemplate && this.selectedTemplate['_id'] === template['_id']
              "
              (click)="onTemplateSelected(template)">
              <div class="d-flex align-items-center mb-2">
                <span
                  [class.text-white]="
                    this.selectedTemplate && this.selectedTemplate['_id'] === template['_id']
                  "
                  >{{ template['name'] ? template['name'] : template['subject'] }}</span
                >
              </div>
              <div class="template-content">
                <small [innerHTML]="template['body'] && template['body'].email"></small>
                <div
                  *ngIf="template['isBlank']"
                  class="h-100 d-flex align-items-center justify-content-center">
                  <span class="template-blank"><i class="icofont-ui-add fa-3x"></i></span>
                </div>
              </div>
            </mdb-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal-dialog modal-lg modal-dialog-centered"
    role="document"
    *ngIf="isTemplateSelected">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ selectedTemplate && selectedTemplate['name'] }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="pop.hide(); closeMessageModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div
        #scrollWrapper
        [scrollTop]="shouldScroll && scrollWrapper.scrollHeight"
        style="scroll-behavior: smooth"
        class="modal-body overflow-auto scrollbar scrollbar-primary thin pt-2"
        [ngStyle]="{
          height: files.length > 0 ? '570px' : '540px'
        }">
        <form [formGroup]="messageForm">
          <div class="col-12 p-0">
            <div class="form-group">
              <label>To *</label>
              <input
                type="text"
                id="toAddress"
                class="form-control"
                formControlName="to"
                [ngClass]="{'is-invalid': isSubmitted && messageForm.controls['to'].invalid}" />
              <p class="pt-1" [ngStyle]="{'font-size': '11px'}">
                Commas can be used to separate multiple message recipients.
              </p>
            </div>
            <div class="form-group">
              <label>Subject</label>
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="loading.data"></span>
              <input type="text" class="form-control" formControlName="subject" />
            </div>
            <div class="form-group">
              <label>Message *</label>
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="loading.data"></span>
              <div
                class="text-editor"
                [class.invalid]="isSubmitted && messageForm.controls['body'].invalid">
                <angular-editor [config]="editorConfig" formControlName="body"></angular-editor>
              </div>
            </div>
            <div class="form-group">
              <div class="file-field md-form m-0">
                <div size="sm">
                  <small class="pt-1 text-secondary"
                    ><i class="icofont-paper-clip"></i> Attach Files</small
                  >
                  <input
                    [ngStyle]="{width: '84px'}"
                    type="file"
                    multiple
                    mdbFileSelect
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput" />
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div *ngFor="let file of files" class="col-md-4 p-0 px-1">
                  <div class="file-wrapper d-flex justify-content-between mb-2 p-2">
                    <div
                      class="file-name-wrapper pr-1"
                      mdbTooltip="{{ file.name }}"
                      placement="top">
                      <mdb-icon far icon="file-alt" class="text-muted mr-1"></mdb-icon
                      ><small class="mr-1">{{ file.name }}</small>
                    </div>
                    <mdb-icon
                      fas
                      icon="times"
                      class="pointer-cursor text-muted"
                      (click)="onRemoveFile(file)"></mdb-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer d-flex justify-content-between border-top-0">
        <ng-template #discardAlert>
          <div class="text-center">
            <p>Are you sure you want to discard?</p>
            <p class="mb-0">
              <button
                type="button"
                class="btn btn-light btn-sm border"
                (click)="onDiscardChanges(); pop.hide()">
                Yes
              </button>
              <button type="button" (click)="pop.hide()" class="btn btn-secondary btn-sm">
                No
              </button>
            </p>
          </div>
        </ng-template>
        <button
          id="discardAlert"
          (click)="
            messageForm.pristine && !isFileAttachedManually && onDiscardChanges(); pop.hide()
          "
          [mdbPopover]="discardAlert"
          #pop="bs-mdbPopover"
          placement="top"
          class="btn btn-light border ml-0 mt-0 mb-0 z-depth-0">
          Change Template
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          [class.disabled]="loading.process"
          mdbWavesEffect
          (click)="onSendMessage()">
          <span
            *ngIf="loading.process"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"></span>
          Send
        </button>
      </div>
    </div>
  </div>
</div>
