import { Injectable } from "@angular/core";
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
import { APICONSTANTS } from "../constants";
import { ExportReportParams } from "../models";
import { Storage } from "../utils";

@Injectable({
  providedIn: "root"
})

export class ReportService {
  constructor(
    private _http: HttpClientService,
    private _storage: Storage
  ) { }

  /**
   * 
   * @param queryParams 
   * @returns API data
   */
  getEpisodeFundingReport(queryParams: any) {
    const { clientCode, startDate, endDate, episodeType, referenceCode } = queryParams;
    const params = {
      clientCode: String(clientCode),
      startDate,
      endDate,
      episodeType: String(episodeType),
      referenceCode
    }
    return this._http.get(`${APICONSTANTS.REPORT}/episode-fundings`, params)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  /**
   * 
   * @param params 
   * @returns API data
   */
  getEpisodeFinancialDetail(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/episode-financials?clientCode=${params.clientCode}&startDate=${params.startDate}&endDate=${params.endDate}`)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  /**
   * 
   * @param params 
   * @param yearToDate 
   * @returns API data
   */
  getEpisodeFinancialSummary(params: any, yearToDate?: boolean) {
    return this._http.get(`${APICONSTANTS.REPORT}/episode-financial-summary?clientCode=${params.clientCode}&startDate=${params.startDate}&endDate=${params.endDate}&isYearToDate=${yearToDate}`)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  /**
   * 
   * @returns claims report data
   */
  getClaimsReport(filters = {} as ExportReportParams) {
    const { dateType, startDate, endDate, procedureCode, provider, status, claimIDs } = filters;
    const user = this._storage.get('local', 'loggedInUser', 'user');
    const params = {
      limit: 0,
      dateType: dateType || '',
      startDate: startDate || '',
      endDate: endDate || '',
      procedureCode: procedureCode || '',
      billingProvider: provider ? String(provider) : '',
      processingStatus: status && status.length ? String(status) : '',
      claimId: claimIDs && claimIDs.length ? String(claimIDs) : '',
      referenceCode: user.roleCode === 'PR' && user.referenceCode === 'NH' ? user.referenceCode : ''
    };
    return this._http.post(`${APICONSTANTS.REPORT}/claims`, params).pipe(map((res: any) => {
      return res.data
    }))
  }

  /**
   * 
   * @returns types of report
   */
  getReportTypes() {
    return this._http.get(`${APICONSTANTS.REPORT_TYPES}?limit=0`).pipe(map((res: any) => {
      return res.data
    }))
  }

  getProvidersReport(queryParams = {} as ExportReportParams, isReport = true) {
    const { network, speciality, provider, entityCode } = queryParams;
    const params = {
      entityCode: entityCode || 1,
      ...network && { network: String(network) },
      ...speciality && { keyword: speciality },
      ...provider && (isReport || entityCode === 2) && { facilityId: typeof provider === 'object' ? String(provider) : provider },
      ...provider && (!isReport && entityCode === 1) && { physicianId: provider },
    };
    return this._http.get(`${APICONSTANTS.REPORT}${APICONSTANTS.PROVIDER}`, params).pipe(map((res: any) => res.data))
  }

  /**
   * 
   * @param queryParams 
   * @returns data for client and provider episode report
   */
  getEpisodesReport(queryParams = {} as ExportReportParams) {
    const { client, status, episodeType, provider } = queryParams;
    const params = {
      ...client && client.length && { clientCode: String(client) },
      ...status && status.length && { status: String(status) },
      ...episodeType && episodeType.length && { episodeType: String(episodeType) },
      provider: null
    };
    if (provider && provider.length) {
      params.provider = String(provider);
    } else if (provider && !provider.length) {
      params.provider = 0;
    } else delete params.provider;
    return this._http.get(`${APICONSTANTS.REPORT}${APICONSTANTS.EPISODE}`, params).pipe(map((res: any) => res.data))
  }

  /**
   * @params params
   * @returns data for claims Reconciliation summary
   */
  getClaimsReconcilitationSummary(params?: any) {
    return this._http.get(`${APICONSTANTS.CLAIM_RECON_SUMMARY}`, params)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  /**
   * @params params
   * @returns data for claims Reconciliation by provider and episode
   */
  getClaimsReconcilitationByProviderAndEpisode(params?: any) {
    return this._http.get(`${APICONSTANTS.CLAIM_RECON_BY_PROVIDER_AND_EPISODE}`, params)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  /**
   * @params params
   * @returns data for claims Reconciliation by provider and episode
   */
  getClaimsReconRawData(params?: any) {
    return this._http.get(`${APICONSTANTS.CLAIM_RAW_DATA}`, params)
      .pipe(map((res: any) => {
        return res.data
      }))
  }

  /**
   * @params params
   * @returns data for claims Reconciliation by provider and episode
   */
  getReportsList(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT_V2}`, params)
      .pipe(map((res: any) => {
        return res.data.rows
      }))
  }

  getActiveEpisodeByMonthReport(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/active-episode-by-month`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getActiveEpisodeByMonthIndividualReport(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/active-episode-by-month-individual`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getEpisodeByCarePathway(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/episode-by-care-pathway`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getNewEpisodesByMonth(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/new-episode-by-month`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getNewEpisodesByMonthIndividual(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/new-episode-by-month-individual`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPortalEngagementReport(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/portal-engagement`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getSubCategoryList(params?: any) {
    return this._http.get(`${APICONSTANTS.SUB_CATEGORIES}`, { ...params, reporting: true })
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getCategoryList(params?: any) {
    return this._http.get(`${APICONSTANTS.CATEGORIES}`, { ...params, reporting: true })
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getEpisodeWithClaimsTableList(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/episode-with-claims`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getTriggerCycleTimesCompany(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/trigger-claim-cycle-time-company`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getTriggerCycleTimesFacility(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/trigger-claim-cycle-time-facility`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getTriggerCycleTimesFacilityChart(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/trigger-claim-cycle-time-facility-chart`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getProviderReferences(params?: any) {
    return this._http.get(`${APICONSTANTS.PROVIDER_REFERENCE}`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPortalEngagementMilestoneDetail(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/portal-engagement-milestone-detail`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPayorCycleTimesPayorChart(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/payor-cycle-time-payor-chart`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPayorCycleTimesCompany(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/payor-cycle-time-company`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPayorCycleTimesPayor(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/payor-cycle-time-payor`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getHolistaProcessCycleTimes(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/holista-processing-cycle-time`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getHolistaPaymentCycleTimes(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/holista-payment-cycle-time`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getTotalCycleTimes(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/total-cycle-time`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getSummaryCycleTimes(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/summary-cycle-time`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPatientSatisfactionKeyQuestionReport(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/patient-satisfaction-key-question`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPatientSatisfactionKeyQuestionIndividualReport(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/patient-satisfaction-key-question-detail`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getQuestionConfigurations(params?: any) {
    return this._http.get(`${APICONSTANTS.QUESTION_CCONFIGURATION}`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  updateQuestionConfigurationStatus(params?: any) {
    return this._http.patch(`${APICONSTANTS.QUESTION_CCONFIGURATION}/update-status`, params)
      .pipe(map((res: any) => {
        return res.success;
      }))
  }

  getSurveyResponseRate(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/survey-response-rate`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getSurveyQuestionWithHighestNo(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/survey-questions-with-highest-no`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getConsentedPatientsComments(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/consented-patients-comments`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPatientCompilance(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/patient-compliance`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getDistinctMilestones(networkCode, params?: any) {
    return this._http.get(`${APICONSTANTS.EPISODE_MILESTONES}/${networkCode}/distinct`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getDistinctQuestions(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT_FILTER}${APICONSTANTS.QUESTIONS}`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getDistinctQuestionnaires(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT_FILTER}${APICONSTANTS.QUESTIONNAIRES}`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPortalQuestionDetail(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/portal-question-detail`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPatientAgreementSigned(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/patient-agreement-signed`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getPortalEngagementIndividual(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/portal-engagement-individual`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getSourcePayors(networkCode) {
    return this._http.get(`${APICONSTANTS.FUNDING_SOURCE}/${networkCode}/source-payors`,)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }

  getClaimProviders(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT_FILTER}/claim-providers`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  getClaimMarkets(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT_FILTER}/claim-markets`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  getOverallServiceCategoryCycleTimeChart(params) {
    return this._http.get(`${APICONSTANTS.REPORT}/overall-service-category-cycle-time`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  getProviderCycleTimeChart(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/provider-cycle-time`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  createServiceCategoryGroupConfigurations(params: any) {
    return this._http.post(`${APICONSTANTS.SERVICE_CATEGORY_GROUP_CONFIGURATIONS}`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  getServiceCategoryGroupConfigurations(params: any) {
    return this._http.get(`${APICONSTANTS.SERVICE_CATEGORY_GROUP_CONFIGURATIONS}`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  updateServiceCategoryGroupConfigurations(params: any) {
    return this._http.patch(`${APICONSTANTS.SERVICE_CATEGORY_GROUP_CONFIGURATIONS}/update-status`, params)
      .pipe(map((response: any) => {
        return response.success
      }))
  }

  getServiceCategoryUtilizationChart(params) {
    return this._http.get(`${APICONSTANTS.REPORT}/bundle-component-utilization`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  getMarketCycleTimeChart(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/market-cycle-time`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  getBundleComponentUtilizationDetail(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/bundle-component-utilization-detail`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  getNetworkCycleTimeDetail(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/network-cycle-time-detail`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  getBundleComponentCategories(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT_FILTER}/bundle-component-categories`, params)
      .pipe(map((response: any) => {
        return response.data
      }))
  }

  getAccountsPayableReport(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/account-payable`, params)
      .pipe(map((response: any) => {
        return response;
      }))
  }

  getAccountsReceivableReport(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/account-receivable`, params)
      .pipe(map((response: any) => {
        return response;
      }))
  }

  getSummaryYTDReport(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/account-summary-ytd`, params)
      .pipe(map((response: any) => {
        return response;
      }))
  }

  getPaymentReport(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/payment-report`, params)
      .pipe(map((response: any) => {
        return response;
      }))
  }

  getProjectedEpisodeProfitReport(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/episode-projected-report`, params)
      .pipe(map((response: any) => {
        return response;
      }))
  }

  getAncillaryReport(params: any) {
    return this._http.get(`${APICONSTANTS.REPORT}/ancillary-report`, params)
      .pipe(map((response: any) => {
        return response;
      }))
  }
}
