import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-episode-user-dependent-question',
  templateUrl: './episode-user-dependent-question.component.html',
  styleUrls: ['./episode-user-dependent-question.component.scss']
})
export class EpisodeUserDependentQuestionComponent implements OnInit {
  child_tasks = [];
  dependentQuestion: any;
  dependent_topic_tasks: any;
  @Input() dependentTopicTasks;
  @Input() set dependent_question(dependent_question) {
    if (dependent_question.status === 'COMPLETED') {
      dependent_question.questions.ansOpt.map(a => {
        if (dependent_question.questions.questionTypes.code === 'RADIO' || dependent_question.questions.questionTypes.code === 'CHECKBOX') {
          const optionId = a.answerOptionId ? a.answerOptionId : a;
          dependent_question.questions.questionOptions.map(q => {
            if (optionId === q.id) {
              a.answerValue = q.optionValue;
              a.taskQuesUuid = q.taskQuesUuid;
              a.taskQnrsUuid = q.taskQnrsUuid;
              a.taskMessageUuid = q.taskMessageUuid;
              a.taskTodoUuid = q.taskTodoUuid;
              a.taskSignatureUuid = q.taskSignatureUuid;
              this.getChilds(q);
            }
            return q;
          });
        } else {
          a.answerValue = a.answer;
        }
        return a;
      });
    }
    this.dependentQuestion = dependent_question;
  }

  constructor() { }

  ngOnInit() { }

  getChilds(task) {
    setTimeout(() => {
      if (this.dependentTopicTasks && this.dependentTopicTasks.length > 0) {
        if (task.taskQuesUuid) {
          const dependentQuestion = this.dependentTopicTasks.find(y => y.uuid === task.taskQuesUuid);
          if (dependentQuestion) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentQuestion.uuid);
            if (!dependentExist) this.child_tasks.push(dependentQuestion);
          }
        }
        if (task.taskQnrsUuid) {
          const dependentQuestionnaire = this.dependentTopicTasks.find(y => y.uuid === task.taskQnrsUuid);
          if (dependentQuestionnaire) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentQuestionnaire.uuid);
            if (!dependentExist) this.child_tasks.push(dependentQuestionnaire);
          }
        }
        if (task.taskMessageUuid) {
          const dependentMessage = this.dependentTopicTasks.find(y => y.uuid === task.taskMessageUuid);
          if (dependentMessage) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentMessage.uuid);
            if (!dependentExist) this.child_tasks.push(dependentMessage);
          }
        }
        if (task.taskTodoUuid) {
          const dependentTodo = this.dependentTopicTasks.find(y => y.uuid === task.taskTodoUuid);
          if (dependentTodo) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentTodo.uuid);
            if (!dependentExist) this.child_tasks.push(dependentTodo);
          }
        }
        if (task.taskSignatureUuid) {
          const dependentSignature = this.dependentTopicTasks.find(y => y.uuid === task.taskSignatureUuid);
          if (dependentSignature) {
            const dependentExist = this.child_tasks.find(tsk => tsk.uuid === dependentSignature.uuid);
            if (!dependentExist) this.child_tasks.push(dependentSignature);
          }
        }
      }
    }, 500);
  }
}
