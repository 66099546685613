import * as Actions from '../action';

export const initialState_userMessages = [];

export function userMessageReducer(state = initialState_userMessages, action) {
  switch (action.type) {
    case Actions.READ_MESSAGE:
      const newState = state.filter(x => x.uid != action.payload.uid);
      return [...newState];
    case Actions.GET_MESSAGE:
      return [...action.payload];
    case Actions.GET_UNREAD_MESSAGE_USER_LIST:
      return [...action.payload];
    case Actions.UPDATE_USER_MESSAGE:
      const newMessageState = state.filter(x => x.uid != action.payload.uid);
      return [...newMessageState, action.payload];
    case Actions.ADD_MESSAGE_NOTIFICATION:
      return [...action.payload];
    default:
      return state;
  }
};
