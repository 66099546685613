import { Component, Input, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ADD_SUCCESS, MODULE_CODES, RestrictSpace, UPDATE_SUCCESS } from "../../../constants";
import { RolePermissionsService, ToasterService } from "../../../services";
import { finalize } from "rxjs/operators";
import { Storage } from "src/app/utils";

@Component({
    selector: 'app-add-edit-role-permissions',
    templateUrl: './add-edit-role-permission.component.html',
    styleUrls: ['./add-edit-role-permission.component.scss']
})
export class AddEditRolePermissionComponent implements OnInit {

    permissionForm: FormGroup;
    permissionList: any
    roleCode: string
    userId: number
    isEdit: boolean = false
    isAdd: boolean = false
    isLoading = {
        save: false,
        permissionList: false
    };
    nonWritableModules = [MODULE_CODES.REPORTING, MODULE_CODES.PATHWAY];

    @Input() isView: boolean = false;
    @Input() type: string = 'role';
    @Input() id: any;
    @Input() userName: string;

    constructor(
        private location: Location,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private _toasterService: ToasterService,
        private _rolePermissionsService: RolePermissionsService,
        private _router: Router,
        private _storage: Storage
    ) { }

    ngOnInit() {
        if (!this.isView) {
            this.route.queryParams.subscribe(params => {
                if (params.roleCode || params.userId) {
                    if (params.roleCode) {
                        this.roleCode = params.roleCode // Or we can make it role name
                    }

                    if (params.userId) {
                        this.userId = params.userId
                        this.type = 'user'
                    }

                    if (params.userName) {
                        this.userName = params.userName
                        this.type = 'user'
                    }

                    if (!this.isView) {
                        this.isEdit = true
                    }

                } else {
                    this.isAdd = true
                }
            })
        } else {
            if (this.type == 'user') {
                this.userId = this.id
            } else {
                this.roleCode = this.id
            }
        }
        this.setPermissionForm();
        if (this.type === 'role') {
            this.permissionForm.controls.roleName.setValidators(Validators.required);
            this.permissionForm.controls.roleName.updateValueAndValidity();
        };
        this.getPermissionsByUserId();
    }

    ngOnDestroy() {
        this._storage.remove('local', 'roleName');
    }

    setPermissionForm() {
        this.permissionForm = this.formBuilder.group({
            roleName: ['', [Validators.pattern(RestrictSpace)]],
            userId: [null],
            permissions: this.formBuilder.array([]),
        });
    }

    getPermissionsByUserId() {
        this.isLoading.permissionList = true;
        if (this.type === 'role' && this.roleCode) {
            const roleName = this._storage.get('local', 'roleName')
            this.permissionForm.controls.roleName.setValue(roleName);
        };
        const params = {
            userId: this.userId,
            roleCode: this.roleCode
        };
        this._rolePermissionsService.getUpdatedRolePermissions(params)
            .pipe(finalize(() => { this.isLoading.permissionList = false; }))
            .subscribe(response => {
                this.permissionList = response.rows ? response.rows : response;
            },
                error => console.log('error getting role permissions', error)
            )
    }

    get formPermissions(): FormArray {
        return this.permissionForm.get('permissions') as FormArray;
    }

    permissionCollapsed(permission) {
        permission.collapsed = !permission.collapsed;
    }

    savePermissions() {
        this.isLoading.save = true;
        if (this.userId || this.roleCode) {
            const params = {
                userId: this.userId,
                roleCode: this.roleCode
            };
            this._rolePermissionsService.updateRolePermissions(params, this.permissionList)
                .pipe(finalize(() => { this.isLoading.save = false; }))
                .subscribe(response => {
                    if (response) {
                        this.permissionList = response;
                        this._toasterService.displaySuccess(UPDATE_SUCCESS);
                    };
                },
                    error => console.log('error updating role permissions', error)
                );
            return;
        };

        const body = {
            roleName: this.permissionForm.get('roleName').value,
            permissions: this.permissionList
        };
        this._rolePermissionsService.addRolePermissions(body)
            .pipe(finalize(() => { this.isLoading.save = false; }))
            .subscribe(response => {
                if (response) {
                    this._router.navigate([`/rolepermissions`]);
                    this._toasterService.displaySuccess(ADD_SUCCESS);
                };
            },
                error => {
                    console.log('error adding role permissions', error);
                    error.status === 500 && this._toasterService.showError(error);
                }
            );
    }

    checkPermissionCheckBoxDisable(permission: any) {
        if (this.nonWritableModules.includes(permission.code)) {
            return permission.subModules.find(subModules => subModules.readAccess)
        }
    }

    onChecked(event: any, permission: any, code: string = null) {
        const { checked } = event;
        permission.readAccess = checked;
        permission.writeAccess = checked;

        if (code) {
            const selectedPermission = this.permissionList.find(permission => permission.code === code);
            //If atleast one submodules have access the parent module must also have access
            selectedPermission.readAccess = selectedPermission.subModules.find(subModules => subModules.readAccess) ? true : false;
        }
    }

    navigateBack() {
        this.location.back();
    }

}

