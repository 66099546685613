import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appScrollToTop]'
})
export class ScrollToTopDirective {
    constructor(private el: ElementRef) { }

    @HostListener('window:scroll', ['$event']) scrollToTop(timeout) {
        this.scroll('none');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            this.scroll('inline-block');
            if (window.scrollY === 0) {
                this.scroll('none');
            }
        }, 300);
    }

    private scroll(display: string) {
        this.el.nativeElement.style.display = display;
    }
}