
export const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
    labels: {
      fontColor: "black"
    },
    position: "bottom"
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontStyle: "bold"
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          fontStyle: "bold"
        },
        gridLines: {
          display: false
        }
      }
    ]
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
      formatter: undefined,
    }
  },
  layout: {
    padding: {
      top: 25, // TO OFFSET THE LABEL BEING HIDDEN FOR MAX VALUE
    }
  }
};
