import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-episode-user-dependent-signature',
  templateUrl: './episode-user-dependent-signature.component.html',
  styleUrls: ['./episode-user-dependent-signature.component.scss']
})
export class EpisodeUserDependentSignatureComponent implements OnInit {
  @Input() dependentSignature

  constructor() { }

  ngOnInit() {
  }

}
