import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  // user: any
  constructor(private authService: AuthService) { }

  ngOnInit() {
    // this.user = this._storage.get('local', 'loggedInUser', 'user')
    this.authService.logout();
  }
}