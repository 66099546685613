import { createAction, props, Action } from '@ngrx/store';

export const ADD_TASK_QUESTION = '[TASKSQUESTION] Add'
export const RESET_TASK_QUESTION = '[TASKSQUESTION] Reset'
export const ADD_TASK_MESSAGE = '[TASKSMESSAGE] Add'
export const RESET_TASK_MESSAGE = '[TASKSMESSAGE] Reset'
export const ADD_TASK_TODO = '[TASKSTODO] Add'
export const RESET_TASK_TODO = '[TASKSTODO] Reset'

export class AddTaskQuestion implements Action {
    readonly type= ADD_TASK_QUESTION
    constructor(public payload:{}){}
}

export class ResetTaskQuestion implements Action {
  readonly type= RESET_TASK_QUESTION
}

export class AddTaskMessage implements Action {
  readonly type= ADD_TASK_MESSAGE
  constructor(public payload:{}){}
}

export class ResetTaskMessage implements Action {
readonly type= RESET_TASK_MESSAGE
}

export class AddTaskTodo implements Action {
  readonly type= ADD_TASK_TODO
  constructor(public payload:{}){}
}

export class ResetTaskTodo implements Action {
readonly type= RESET_TASK_TODO
}

export type TaskActions = AddTaskQuestion | ResetTaskQuestion | AddTaskMessage | ResetTaskMessage | AddTaskTodo | ResetTaskTodo
