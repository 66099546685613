import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdleTimerService {
  timeout: any;
  onTimeout: any;
  eventHandler: any;
  interval: any;
  timeoutTracker: any;

  constructor() { }

  startUserIdleTimer({ timeout, onTimeout }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.eventHandler = this.updateLastActiveTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateLastActiveTime();
    this.interval = setInterval(() => {
      const lastActiveTime = parseInt(localStorage.getItem("lastActiveTime"), 10);
      if (lastActiveTime > 0 && lastActiveTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateLastActiveTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem("lastActiveTime", Date.now() + this.timeout);
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    localStorage.removeItem('lastActiveTime');
    clearInterval(this.interval);
  }
}
