import * as Actions from '../action';

export const initialState_user = {};
export const initialState_chatUsers = []
export const initialchatState = false
// export const initialChatOpen = false
export const initialChatUserLoad = false
export function userReducer(state = initialState_user, action) {
  switch (action.type) {
    case Actions.GET_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export function chatUsersReducer(state = initialState_chatUsers, action) {
  switch (action.type) {
    case Actions.GET_ALL_CHAT_USER:
      return [...action.payload];
    default:
      return state;
  }
};

export function enableChatReducer(state = initialchatState, action) {
  switch (action.type) {
    case Actions.ENABLE_CHAT:
      return !initialchatState;
    default:
      return state;
  }
};

// export function chatOpenReducer(state = initialChatOpen, action) {
//   switch (action.type) {
//     case Actions.OPEN_CHAT_BOX:
//       return action.payload
//     default:
//       return state;
//   }
// };

export function chatUserLoadReducer(state = initialChatUserLoad, action) {
  switch (action.type) {
    case Actions.CHAT_USER_LOADING:
      return action.payload
    default:
      return state;
  }
};
