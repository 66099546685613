import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class FundingRequestService {

    constructor(
        private http: HttpClientService
    ) { }

    getAllFundingRequest() {
        return this.http.get(`${APICONSTANTS.FUNDING_REQUREST}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getFundingRequestByInsurerId(id) {
        return this.http.get(`${APICONSTANTS.FUNDING_REQUREST}/insurer/${id}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }
}