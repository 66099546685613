import { Injectable } from "@angular/core";
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
import { APICONSTANTS } from "../constants";
import { DEFAULT_LIMIT, DEFAULT_PAGE } from "../constants/payee.constant";

@Injectable({
    providedIn: "root"
})

export class PayeeService {
    constructor(private http: HttpClientService) { }

    getPayee(query, filterIsActive?) {
        if (!query.limit) query.limit = DEFAULT_LIMIT;
        if (!query.page) query.page = DEFAULT_PAGE;

        const { limit, page } = query;
        let fetchPayeeUrl = `${APICONSTANTS.PAYEE}?currentPage=${page}&pageSize=${limit}`

        if (query.sortBy) {
            fetchPayeeUrl = `${fetchPayeeUrl}&&sortBy=${query.sortBy}&&sortType=${query.sortType}`
        }


        if (query?.searchKey) {
            fetchPayeeUrl = `${fetchPayeeUrl}&&searchKey=${query.searchKey}`
        }

        if (query?.network) {
            fetchPayeeUrl = `${fetchPayeeUrl}&&network=${query.network}`
        }

        return this.http.get(fetchPayeeUrl).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    savePayee(payee, forceAdd = false) {
        let apiUrl = `${APICONSTANTS.PAYEE}`
        if (forceAdd) {
            apiUrl = apiUrl + APICONSTANTS.CHECK_AND_CREATE_PAYEE
        }

        return this.http.post(apiUrl, payee).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    updatePayee(payee, forceUpdate = false) {
        let apiUrl = `${APICONSTANTS.PAYEE}`
        if (forceUpdate) {
            apiUrl = apiUrl + APICONSTANTS.CHECK_AND_CREATE_PAYEE
        }

        return this.http.patch(apiUrl, payee).pipe(
            map((res: any) => {
                if (Array.isArray(res.data)) {
                    return res.data[1][0];
                }

                return res.data
            })
        );
    }

    deletePayee(id) {
        return this.http.delete(`${APICONSTANTS.PAYEE}/${id}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

}