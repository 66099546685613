<section class="container-fluid">
  <div class="loader">
    <app-loader [loader]="loading.edi"></app-loader>
  </div>
  <div *ngIf="ediFile">
    <div class="row">
      <div class="col-10 mx-auto">
        <div class="mt-3 mb-3 d-flex justify-content-between align-items-end donotprint">
          <span>
            <label>Choose Option</label>
            <mdb-select
              [disabled]="saving"
              [(ngModel)]="defaultSelected"
              [options]="options"
              (ngModelChange)="valueChanged($event); pop.hide()"></mdb-select>

            <ng-template #downloadAlert>
              <div class="d-flex justify-content-between align-items-center">
                <p>Choose the file type to download.</p>
                <a class="ml-4 mb-3" aria-label="Close" (click)="pop.hide()">
                  <i class="fas fa-times"></i>
                </a>
              </div>
              <div class="text-center">
                <p class="mb-0">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    (click)="download('text'); pop.hide()"
                    [disabled]="defaultSelected == 2">
                    .txt
                  </button>
                  <button
                    type="button"
                    (click)="download('pdf'); pop.hide()"
                    class="btn btn-secondary btn-sm">
                    .pdf
                  </button>
                </p>
              </div>
            </ng-template>
          </span>
          <span>
            <button
              *ngIf="defaultSelected === 2"
              type="button"
              class="btn btn-light mb-0 p-0 rounded-circle"
              style="height: 40px; width: 40px"
              (click)="onSendEmail()"
              mdbTooltip="Send Email"
              mdbWavesEffect>
              <i class="fa-fa fa-envelope fas" *ngIf="!loading.attachment"></i>
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="loading.attachment"></span>
            </button>
            <button
              type="button"
              class="btn btn-light mb-0 p-0 rounded-circle"
              style="height: 40px; width: 40px"
              (click)="captureScreen()"
              mdbTooltip="Print"
              mdbWavesEffect>
              <i class="fas fa-print"></i>
            </button>
            <span placement="top" mdbTooltip="Download" style="display: inline-block">
              <button
                type="button"
                class="btn btn-light mb-0 p-0 rounded-circle"
                style="height: 40px; width: 40px"
                mdbWavesEffect
                id="downloadAlert"
                [mdbPopover]="downloadAlert"
                placement="bottom"
                #pop="bs-mdbPopover">
                <i class="fas fa-download" *ngIf="!loading.pdf"></i>
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loading.pdf"></span>
              </button>
            </span>
            <button
              *ngIf="isClaimApproved == 'false' && user.roleCode !== 'PR'"
              type="button"
              class="btn btn-light mb-0"
              (click)="editPayment()"
              mdbWavesEffect>
              <i class="fas fa-pencil-alt"></i> Edit
            </button>
            <!-- <button type="button" class="btn btn-light mb-0" mdbWavesEffect (click)="download(null)"
                            *ngIf=" defaultSelected==2"><i class="fas fa-download"></i>
                            Download</button> -->

            <div
              class="d-inline-block"
              [mdbTooltip]="isApproveDisabled ? disableApproveEdiDescription : ''">
              <button
                type="button"
                class="btn btn-secondary mb-0 mr-0"
                (click)="approve()"
                mdbWavesEffect
                [disabled]="isApproveDisabled"
                *ngIf="
                  isClaimApproved == 'false' && user.roleCode !== ROLE_CODES.PROVIDER_ADMINISTRATOR
                ">
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="saving"></span>
                <i class="fas fa-check" *ngIf="!saving"></i> Approve
              </button>
            </div>
          </span>
        </div>

        <!-- EDI Section -->
        <div
          class="border mx-auto h-auto p-4 grey lighten-5"
          [innerHTML]="ediFile"
          *ngIf="defaultSelected == 1"></div>

        <!-- Remittance Summary Section -->
        <app-loader [loader]="loading.remittance"></app-loader>
        <div
          id="remittance-wrapper"
          class="border mx-auto h-auto"
          *ngIf="defaultSelected == 2 && !loading.remittance">
          <div class="d-flex align-items-center bg-primary-dark p-4" style="height: 180px">
            <div class="media w-75">
              <div
                class="icon-block icon-block-lg text-center text-white bg-primary rounded-circle d-flex justify-content-center align-items-center mr-3">
                <mdb-icon fas icon="building"></mdb-icon>
              </div>
              <div class="media-body">
                <p class="text-white mb-0">Payee Name</p>
                <h4 class="text-white">{{ remittancePdf?.payeeName | titlecase }}</h4>
                <table mdbTable borderless="true" class="text-white mb-0 table-xs">
                  <tr>
                    <td class="p-0 w-25">Billed Amount</td>
                    <td class="p-0 w-25">Allowed Amount</td>
                    <td class="p-0 w-25">Patient Pay</td>
                    <td class="p-0 w-25">Net Amount</td>
                    <td class="p-0 w-25">Paid Date</td>
                  </tr>
                  <tr>
                    <td class="p-0">
                      <h4 class="text-white mb-0">
                        {{ remittancePdf?.totalBilledAmount | currency }}
                      </h4>
                    </td>
                    <td class="p-0">
                      <h4 class="text-white mb-0">
                        {{ remittancePdf?.totalAllowedAmount | currency }}
                      </h4>
                    </td>
                    <td class="p-0">
                      <h4 class="text-white mb-0">
                        {{ remittancePdf?.totalPatientPay | currency }}
                      </h4>
                    </td>
                    <td class="p-0">
                      <h4 class="text-white mb-0">
                        {{ remittancePdf?.totalNetAmount | currency }}
                      </h4>
                    </td>
                    <td class="p-0">
                      <h4 class="text-white mb-0">
                        {{ remittancePdf?.transactionDate | date : 'MM/dd/yyyy' }}
                      </h4>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- Multiple Details Starts -->
          <div *ngFor="let item of remittanceSummaryItem">
            <app-services-detail [remittancePdf]="item"></app-services-detail>
          </div>
          <div class="pt-0 px-4 pb-4" *ngIf="excptCodeLists.length">
            <div *ngFor="let excptCode of excptCodeLists">
              <p class="text-right font-italic m-0">
                <strong>Exception Code {{ excptCode.code }}</strong>
              </p>
              <p class="text-right font-italic">
                {{ excptCode.description }}
              </p>
            </div>
          </div>
          <!-- <div class="p-4">
            <h6><strong>Services Detail</strong></h6>
            <div class="d-flex justify-content-between mt-3">
              <table class="table-xs" mdbTable small="true" borderless="true">
                <tr>
                  <td><span>Claim ID #:</span></td>
                  <td>
                    <strong>{{ remittancePdf.patientControlNumber }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Patient Name:</span></td>
                  <td>
                    <strong>{{ remittancePdf.patientName | titlecase }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Subscriber/ Member: </span></td>
                  <td>
                    <strong>{{ remittancePdf.subscriberMember }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>DOB: </span></td>
                  <td>
                    <strong>{{ remittancePdf.dob | date: 'MM/dd/yyyy' }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Patient Control No:</span></td>
                  <td>
                    <strong>{{ remittancePdf.patientControlNumber }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Medical Record: </span></td>
                  <td>
                    <strong>{{ remittancePdf.medicalRecord }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Statement Date: </span></td>
                  <td>
                    <strong>{{ remittancePdf.statementDate }}</strong>
                  </td>
                </tr>
              </table>
              <table class="table-xs" mdbTable small="true" borderless="true">
                <tr>
                  <td><span>Facility Name:</span></td>
                  <td>
                    <strong>{{ remittancePdf.facilityName | titlecase }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Plan:</span></td>
                  <td>
                    <strong>{{ remittancePdf.plan }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Bundle Name: </span></td>
                  <td>
                    <strong>{{ remittancePdf.bundleName }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Tax ID: </span></td>
                  <td>
                    <strong>{{ remittancePdf.taxId }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Transaction No: </span></td>
                  <td>
                    <strong>{{ remittancePdf.transactionNumber }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Type of Bill: </span></td>
                  <td>
                    <strong>{{ remittancePdf.typeOfBill }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Date of Service: </span></td>
                  <td>
                    <strong>{{
                      remittancePdf.serviceLines[0].serviceStartDate.date
                        ? remittancePdf.serviceLines[0].serviceStartDate.date
                        : 'N/A'
                    }}</strong>
                    <strong *ngIf="remittancePdf.serviceLines[0].serviceEndDate?.date">
                      -
                      {{ remittancePdf.serviceLines[0].serviceEndDate?.date }}</strong
                    >
                  </td>
                </tr>
              </table>
            </div>
            <table class="mt-3" mdbTable small="true">
              <tbody
                *ngIf="remittancePdf && remittancePdf.serviceLines.length > 0"
                style="background-color: white">
                <tr>
                  <th class="font-weight-bold">#</th>
                  <th class="font-weight-bold">Rev <br />Code</th>
                  <th class="font-weight-bold" style="width: 250px">
                    Procedure/<br />
                    Mod
                  </th>
                  <th class="font-weight-bold">Billed <br />Qty</th>
                  <th class="text-right font-weight-bold">Billed<br />Amt</th>
                  <th class="text-right font-weight-bold">Allowed<br />Qty</th>
                  <th class="text-right font-weight-bold">Allowed<br />Amt</th>
                  <th class="text-right font-weight-bold">Patient<br />Pay</th>
                  <th class="text-right font-weight-bold">Net<br />Amt</th>
                  <th class="text-center font-weight-bold">EXCPT<br />Code</th>
                </tr>
                <tr *ngFor="let data of remittancePdf.serviceLines; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ data.code ? data.code : '-' }}</td>
                  <td>
                    <span>{{ data.procedureCode ? data.procedureCode : '-' }}</span
                    ><br />
                    <em *ngIf="!data.showMore"
                      ><small>{{ data.description ? (data.description | slice: 0:30) : '' }} </small
                      ><a
                        (click)="data.showMore = !data.showMore"
                        *ngIf="data.description && data.description.length > 30"
                        >...</a
                      ></em
                    >
                    <em *ngIf="data.showMore"
                      ><small>{{ data.description }}</small></em
                    >
                  </td>
                  <td>{{ data.billedQuantity }}</td>
                  <td class="text-right">{{ (data.billedAmount | currency) || '-' }}</td>
                  <td class="text-right">{{ data.allowedQuantity }}</td>
                  <td class="text-right">{{ data.allowedAmount | currency }}</td>
                  <td class="text-right">{{ (data.patientPay | currency) || '-' }}</td>
                  <td class="text-right">{{ data.netAmount | currency }}</td>
                  <td class="text-right">{{ data.excptCode || '-' }}</td>
                </tr>
                <tr class="grey lighten-2 w-100">
                  <td colspan="3"></td>
                  <td class="font-weight-bold">Total</td>
                  <td class="text-right font-weight-bold">
                    {{ remittancePdf.totalBilledAmount | currency }}
                  </td>
                  <td></td>
                  <td class="text-right font-weight-bold">
                    {{ remittancePdf.totalAllowedAmount | currency }}
                  </td>
                  <td class="text-right font-weight-bold">
                    {{ remittancePdf.totalPatientPay | currency }}
                  </td>
                  <td class="text-right font-weight-bold">
                    {{ remittancePdf.totalNetAmount | currency }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p class="text-right font-italic m-0"><strong>Exception Code {{ EXCEPTION_CODES.SERVICES_INCL.code }}</strong></p>
            <p class="text-right font-italic">
              {{ EXCEPTION_CODES.SERVICES_INCL.description }}
            </p>
            <div *ngIf="hraHsaServiceLine">
              <p class="text-right font-italic m-0"><strong>Exception Code {{ EXCEPTION_CODES.HRA_HSA.code }}</strong></p>
              <p class="text-right font-italic">
                {{ EXCEPTION_CODES.HRA_HSA.description }}
              </p>
            </div>
          </div> -->
          <!-- Multiple Details End -->
          <footer class="d-flex justify-content-between p-3 align-items-end grey lighten-2">
            <div>
              <strong>Holista</strong>
              <ul class="fa-ul fa-inline mb-0 mt-2">
                <li class="text-muted">
                  <span class="fa-li"> <i class="fas fa-map-marker-alt"></i></span>
                  {{ mailingAddress }} <br />
                  {{ address }}
                </li>
                <li class="text-muted">
                  <span class="fa-li">
                    <mdb-icon fas icon="mobile-alt"></mdb-icon>
                  </span>
                  855-844-0619
                </li>
              </ul>
            </div>
            <p class="mb-0 text-muted">© {{ currentYear }} Holista. All rights reserved.</p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Confirmation Modal -->
<div
  mdbModal
  #approveConfirmationModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <!--Body-->
      <div class="modal-body">
        <p class="mb-0 text-center">
          This file will be sent to the provider. Are you sure you want to proceed ?
        </p>
        <div class="p-0 mt-4 text-center">
          <button
            class="btn btn-secondary waves-light"
            mdbWavesEffect
            (click)="triggerAction('yes')">
            Yes
          </button>
          <button class="btn btn-light waves-light" mdbWavesEffect (click)="triggerAction('no')">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [idPDF]="['remittance-wrapper' + '-id-' + remittancePdf.patientControlNumber]"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->
