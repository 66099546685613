import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ContentChild, HostListener, ElementRef, ViewChild } from '@angular/core';
import { HOLISTA_CONSTANT, MODULES, MessageConstants, NAME_VARIABLES, NO_DATA_IN_REPORT } from '../../constants';
import { CommunicationModuleOptions, TableHeader } from '../../models';
import { ActivityLogService } from '../../services';
import { CurrencyFormat, ReportUtility, Storage } from '../../utils';
import * as moment from 'moment';
import { StorageService } from '../../services';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() report: any;
  @Input() exportTable: boolean = false;
  @Input() tableHeight: string;
  @Input() orderBy: string = 'asc';
  @Input() module: string;
  @Input() showCheckbox: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() uneditableFields: string[] = [];
  @Input() nonPrePopulatedFields: string[] = [];
  @Input() invalidStatusReason: string;
  @Input() isCheckboxDisabled: boolean = false;
  @Input() lazyLoading: boolean = false;
  @Input() isRowDataClickable: boolean = false;
  @Input() isCollapsed: boolean = false;
  @Input() hasLazyLoadedData: boolean = false;

  @Input() set completeReport(report) {
    if (report.data?.length) {
      this.emitReset.emit(true);
      report.isDownload ? this.exportToCsv(report) : this.onSendEmail(report);
    }
  }

  @Output() onCheckboxChecked = new EventEmitter<any>();
  @Output() onCheckboxUnchecked = new EventEmitter<any>();
  @Output() onUpdateCellData = new EventEmitter<any>();
  @Output() onCollapse = new EventEmitter<any>();
  @Output() onScrollToBottom = new EventEmitter<any>();
  @Output() onRowDataClick = new EventEmitter<any>();
  @Output() getCompleteReport = new EventEmitter<any>();
  @Output() emitReset = new EventEmitter<any>();

  @ContentChild('additionalHeader', { static: true }) additionalHeader: TemplateRef<any> | undefined;
  @ContentChild('collapsibleContainer', { static: true }) collapsibleContainer: TemplateRef<any> | undefined;
  @ContentChild('total', { static: true }) total: TemplateRef<any> | undefined;
  @ContentChild('lazyLoadingLoader', { static: true }) lazyLoadingLoader: TemplateRef<any> | undefined;

  @ViewChild('uiElement', { static: false }) public uiElement: ElementRef;

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    const nativeElement = this.uiElement.nativeElement;
    if (event.target.clientHeight + Math.round(nativeElement.scrollTop) === nativeElement.scrollHeight) {
      this.onScrollToBottom.emit({ scrolledToBottom: true });
      this.hasLazyLoadedData && this.emitReset.emit(false);
    }
  }

  showMessageModal: boolean = false;
  isLoading = {
    download: false,
    saving: false
  };
  moduleOptions: CommunicationModuleOptions;
  isMasterCheckboxSelected: boolean = false;
  checkedList: any;
  isEditData: boolean = false;
  selectedHeaderValue: any;
  selectedRowId: number;
  dataInput: any;
  checkboxSelectedCount: number = 0;
  nameVariables = NAME_VARIABLES;
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT)

  constructor(
    private _reportUtility: ReportUtility,
    private _activityLogService: ActivityLogService,
    private _currencyFormat: CurrencyFormat,
    private _messageConstants: MessageConstants,
    private _storageService: StorageService
  ) { }

  ngOnInit() {
    if (this.module === MODULES.PAYMENT)
      if (!this.checkboxSelectedCount) {
        if (!this.report.data.find(data => !data.isSelected)) {
          this.isMasterCheckboxSelected = true
        }
      }
  }

  onSendEmail(report?: any) {
    this.showMessageModal = true;
    this.moduleOptions = {
      module: this.module,
      subModule: this.report.tableTitle.toLowerCase()
    };
    this._storageService.addData(report ?? this.report);
  }

  closeMessageModal() {
    this.showMessageModal = false;
    this.hasLazyLoadedData && this.emitReset.emit(false);
  }

  download() {
    this.isLoading.download = true;
    this.hasLazyLoadedData ? this.getCompleteReport.emit(true) : this.exportToCsv();
  }

  sendEmail() {
    this.hasLazyLoadedData ? this.getCompleteReport.emit(false) : this.onSendEmail();
  }

  /**
   * download excel file
   */
  async exportToCsv(report?: any) {
    await this._reportUtility.exportToCsv(report ?? this.report, true, '', this.report.options?.action);
    report && this.emitReset.emit(true);
    this.isLoading.download = false;
    this._activityLogService.logUserActivities(this.module, this.report.tableTitle.toLowerCase(), 'downloaded').subscribe();
  }

  /**
   * check all checkbox
   */
  onAllChecked(isSelected: boolean) {
    this.isEditData = false;
    const unselectedItems = this.report.data.filter((item: any) => !item.isSelected);
    const items = isSelected ? unselectedItems : this.report.data;
    items.forEach((item: any) => {
      this.onChecked(item, isSelected);
    });
  }

  /**
   * Check individual checkbox
   * @param item 
   */
  onChecked(item, isSelected: boolean) {
    item.isSelected = isSelected;
    this.isEditData = false;
    if (isSelected) {
      this.checkboxSelectedCount += 1;
      this.onCheckboxChecked.emit(item);
      this.isMasterCheckboxSelected = this.checkboxSelectedCount === this.report.data.length;
      return;
    }
    this.onCheckboxUnchecked.emit(item);
    this.checkboxSelectedCount -= 1;
    this.isMasterCheckboxSelected = this.checkboxSelectedCount === this.report.data.length;
  }

  /**
   * Edit editable cell data
   */
  editData(item, headerValue: string) {
    if (!this.uneditableFields.includes(headerValue)) {
      this.isEditData = true;
      this.dataInput = item[headerValue] ? item[headerValue].replace(/[$,]/g, '') : 0;
      this.selectedHeaderValue = headerValue;
      this.selectedRowId = item.id;
    }
  }

  closeEdit() {
    this.isEditData = false;
  }

  updateValue(item, headerValue: string) {
    const index = this.report.data.findIndex(({ id }) => id === item.id);
    this.report.data[index][headerValue] = this._currencyFormat.format(this.dataInput || 0);
    this.isEditData = false;
    if (headerValue === 'hraHsaAmount' || headerValue === 'patientPayAmount') {
      const isHraHsaAmount = headerValue === 'hraHsaAmount';
      Object.assign(this.report.data[index], {
        [isHraHsaAmount ? 'hasHraHsaError' : 'hasPatientResponsibilityError']: false,
      });
    }
    this.onUpdateCellData.emit(item);
  }

  setStatus(item) {
    const index = this.report.data.findIndex(({ id }) => id === item.id);
    this.report.data[index] = item;
  }

  /**
   * Change Collapsable Status
   */
  onCollapseClicked(item, status: boolean) {
    item.isCollapsed = status;
    this.onCollapse.emit(status);
  }

  /**
   * View related data
   * @param event 
   */
  onRowDataClicked(event: any) {
    this.isRowDataClickable && this.onRowDataClick.emit(event);
  }

  /**
   * converts data to desired format
   * @param item
   * @param header 
   * @returns 
   */
  formattedValue(item, header: TableHeader) {
    if (item[header.value] && item[header.value] !== ' ') {
      if (header.type === 'amount') {
        return this._currencyFormat.format(item[header.value]);
      };

      if (header.type === 'date') {
        return moment(item[header.value]).format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT);
      };

      if (header.type === 'percentage') {
        return `${item[header.value]}%`;
      };
      return item[header.value];
    } else return '-';
  }

  showNoDataMessage(): boolean {
    return !this.isCollapsed && (!this.report.data || this.report.data.length === 0);
  }
}
