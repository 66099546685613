<section class="container-fluid">
  <div
    class="d-flex justify-content-between align-items-center mt-4 action position-sticky"
    *ngIf="!selected_pathway">
    <div class="form-group has-search input-group mt-2 mb-2 w-25 w-xs-100">
      <span class="form-control-feedback left-0">
        <i class="icofont-search-1"></i>
      </span>
      <input
        type="text"
        [(ngModel)]="query.searchKeyword"
        class="form-control"
        id="search"
        placeholder="Search"
        (ngModelChange)="change($event)" />
      <div class="input-group-append" *ngIf="query.searchKeyword">
        <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
          <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50 mt-2 mb-2">
      <div style="width: 40%; margin-top: 5px">
        <app-select
          [options]="networksForFilter"
          [placeholder]="'Select network'"
          (onSelected)="searchByNetwork($event)"
          (onDeselected)="searchByNetwork($event, 'remove')"
          [allowClear]="true">
        </app-select>
      </div>
      <div class="file-field z-depth-0">
        <div
          class="btn btn-secondary waves-light text-center"
          [class.disabled]="
            !utilityAccess.searchAccess('PM', 'isEditable', false) ||
            loggedUser.referenceType === 'CLIENT'
          "
          mdbWavesEffect>
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="import_enabled"></span>
          <span *ngIf="!import_enabled"><i class="fas fa-file-import mr-1"></i></span> Import
          <input
            type="file"
            mdbFileSelect
            (uploadOutput)="onUploadOutput($event)"
            [options]="importOptions"
            title="Import Pathway" />
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          [disabled]="!pathways || !pathways.length || loading.pathways"
          (click)="exportPathwayAsExcel()"
          mdbWavesEffect>
          <i *ngIf="!exportingExcel" class="fas fa-file-excel"></i>
          <span
            *ngIf="exportingExcel"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"></span>
          EXPORT
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="openPathwayModal()"
          mdbWavesEffect>
          <i class="icofont-plus"></i> ADD
        </button>
      </div>
    </div>
  </div>

  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table mdbTable hover="true" small="true" striped="true" stickyHeader="true" class="v-top">
          <thead id="scrollToTop">
            <tr>
              <th scope="col" [class.active]="query.sortBy === 'name'" (click)="setOrder('name')">
                Name
                <span *ngIf="query.sortBy === 'name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                scope="col"
                [class.active]="query.sortBy === 'description'"
                (click)="setOrder('description')">
                Description
                <span *ngIf="query.sortBy === 'description'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                scope="col"
                [class.active]="query.sortBy === 'networkName'"
                (click)="setOrder('networkName')">
                Network
                <span *ngIf="query.sortBy === 'networkName'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                scope="col"
                [class.active]="query.sortBy === 'createdDate'"
                (click)="setOrder('createdDate')">
                Created Date
                <span *ngIf="query.sortBy === 'createdDate'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading.pathways"
            [style.top]="pathways && pathways.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading.pathways"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody>
            <tr
              *ngFor="
                let pathway of pathways
                  | paginate
                    : {itemsPerPage: query.limit, currentPage: query.page, totalItems: totalCount};
                let i = index
              ">
              <td width="400" (click)="goToMileStone(pathway)">
                {{ pathway?.name }}
                <br />
                <span *ngFor="let tag of pathway?.tags">
                  <span class="badge badge-sm bg-primary-dark text-white z-depth-0 text-uppercase">
                    {{ tag }} </span
                  >&nbsp;
                </span>
              </td>
              <td width="400" (click)="goToMileStone(pathway)">{{ pathway?.description }}</td>
              <td (click)="goToMileStone(pathway)">{{ pathway?.network?.name }}</td>
              <td (click)="goToMileStone(pathway)">
                {{ pathway?.createdDate | date : 'MM/dd/yyyy' : 'UTC' }}
              </td>
              <td width="32">
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div
                    class="custom-dropdown-content rounded-sm z-depth-2"
                    [style.top]="(i === query.limit - 2 || i === query.limit - 1) && '-175px'">
                    <a class="dropdown-item" (click)="goToMileStone(pathway)"
                      ><i class="icofont-eye-alt"></i> Detail</a
                    >
                    <a class="dropdown-item" (click)="openPathwayModal(pathway)"
                      ><i class="icofont-edit-alt"></i> Edit</a
                    >
                    <a class="dropdown-item" (click)="deletePathway(pathway)"
                      ><i class="icofont-ui-delete"></i> Delete</a
                    >
                    <a class="dropdown-item" (click)="exportPathway(pathway)"
                      ><i class="icofont-share-alt"></i> Export</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="pathways && pathways.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>

  <div
    appScrollToTop
    class="fixed-action-btn donotprint"
    style="bottom: 145px; right: 10px; display: none">
    <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
      <i class="fas fa-angle-up text-black-50"></i>
    </a>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading.pathways && !result.pathways">
    <i class="fa fa-3x fa-tasks text-black-50"></i>
    <p>Start adding pathways.</p>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading.pathways && !result.searchedPathways">
    <i class="fa fa-3x fa-tasks text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>

<app-pathway-milestone
  *ngIf="selected_pathway && selected_pathway.id"
  [selected_pathway]="selected_pathway">
</app-pathway-milestone>

<div
  mdbModal
  #pathwayModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ pathwayForm.value.id ? 'Edit' : 'Add' }} Pathway</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closePathwayModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="pathwayForm">
          <div class="form-group">
            <label for="network">Network *</label>
            <app-select
              [options]="networks"
              [id]="'networks'"
              [control]="pathwayForm.controls['networkCode']"
              [placeholder]="'Select network'"
              [inputClass]="{
                'error-select': submitted && pathwayForm.get('networkCode').invalid,
                'pointer-disabled': pathwayForm.get('id').value
              }">
            </app-select>
          </div>
          <div class="form-group">
            <label for="pathwayName">Pathway Name *</label>
            <input
              type="text"
              id="pathwayName"
              class="form-control"
              mdbInput
              formControlName="name"
              [ngClass]="{'is-invalid': submitted && pathwayForm.get('name').invalid}" />
          </div>
          <div class="form-group">
            <label for="pathwayNameDesc">Description</label>
            <textarea
              type="text"
              id="pathwayNameDesc"
              class="md-textarea form-control"
              mdbInput
              formControlName="description"
              (change)="descriptionChange($event)"
              [ngClass]="{
                'is-invalid': submitted && pathwayForm.get('description').invalid
              }"></textarea>
          </div>
          <div class="form-group">
            <label for="pathwayTags">Tags </label>
            <input
              type="text"
              id="pathwayTags"
              class="form-control"
              mdbInput
              formControlName="tagName"
              (keyup.enter)="onEnterTag()"
              (keydown.tab)="onEnterTag()"
              [ngClass]="{'is-invalid': submitted && pathwayForm.get('tagName').invalid}" />
            <input type="text" [hidden]="true" formControlName="tagName" />
            <div class="keywords">
              <div class="keywords d-flex mt-2 flex-wrap align-items-center">
                <span class="d-block mr-1 mb-2" *ngFor="let tag of pathwayForm.controls.tags.value">
                  <small class="d-block text-muted ml-1"></small>
                  <div class="chip blue lighten-5 mb-0">
                    <span class="font-weight-500 text-dark">{{ tag }}</span>
                    <mdb-icon fas icon="times" class="pl-1" (click)="removetag(tag)"></mdb-icon>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closePathwayModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitPathway()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ pathwayForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<app-delete-confirmation
  [_id]="deleteId"
  [message]="message"
  (confirmDelete)="confirmDelete($event)">
</app-delete-confirmation>
