<h6 class="font-weight-500">Claims Processing Status</h6>
<div class="">
  <mdb-accordion class="accordion-block" [multiple]="false">
    <!-- In Review -->
    <mdb-accordion-item id="inReview">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          In-Review
          <div class="chip chip-rounded alert preliminary m-0">
            {{ claims.inReview.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="claims.inReview.length === 0">
          <h6 class="text-center mt-4">No Claims Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
          <mdb-card *ngFor="let claim of claims.inReview" class="tasks white shadow-sm">
            <mdb-card-header (click)="redirectToClaim(claim)">
              Claim ID - {{ claim.patientAccountNumber }}
            </mdb-card-header>
            <mdb-card-body class="p-1">
              <table mdbTable borderless="true" class="table-journal mb-0">
                <tbody class="bg-transparent">
                  <tr>
                    <th>Total charged</th>
                    <td class="text-right">
                      {{
                        claim.totalClaimChargeAmount
                          ? (claim.totalClaimChargeAmount | currency)
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr *ngIf="claim.statusReason">
                    <td>Reason</td>
                    <td class="text-right">
                      <span class="text-danger">{{ claim.statusReason }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>

    <!-- Queue -->
    <mdb-accordion-item id="queue">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Queue
          <div class="chip chip-rounded alert queue m-0">
            {{ claims.queue.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="claims.queue.length === 0">
          <h6 class="text-center mt-4">No Claims Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
          <mdb-card *ngFor="let claim of claims.queue" class="tasks white shadow-sm">
            <mdb-card-header (click)="redirectToClaim(claim)">
              Claim ID - {{ claim.patientAccountNumber }}
            </mdb-card-header>
            <mdb-card-body class="p-1">
              <table mdbTable borderless="true" class="table-journal table-xs mb-0">
                <tbody class="bg-transparent">
                  <tr>
                    <td width="140">Total charged</td>
                    <td class="text-right">
                      {{
                        claim.totalClaimChargeAmount
                          ? (claim.totalClaimChargeAmount | currency)
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr *ngIf="claim.statusReason">
                    <td>Reason</td>
                    <td class="text-right">
                      <span class="text-danger">{{ claim.statusReason }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>

    <!-- payment In Process -->
    <mdb-accordion-item id="paymentInProcess">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Payment In-Process
          <div class="chip chip-rounded alert confirmed m-0">
            {{ claims.paymentInProcess.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="claims.paymentInProcess.length === 0">
          <h6 class="text-center mt-4">No Claims Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
          <mdb-card *ngFor="let claim of claims.paymentInProcess" class="tasks white shadow-sm">
            <mdb-card-header (click)="redirectToClaim(claim)"
              >Claim ID - {{ claim.patientAccountNumber }}
            </mdb-card-header>
            <mdb-card-body class="p-1">
              <table mdbTable borderless="true" class="table-journal table-xs mb-0">
                <tbody class="bg-transparent">
                  <tr>
                    <td width="140">Total charged</td>
                    <td class="text-right">
                      {{
                        claim.totalClaimChargeAmount
                          ? (claim.totalClaimChargeAmount | currency)
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr *ngIf="claim.statusReason">
                    <td>Reason</td>
                    <td class="text-right">
                      <span class="text-danger">{{ claim.statusReason }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>

    <!-- Paid -->
    <mdb-accordion-item id="paid">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Paid
          <div class="chip chip-rounded alert completed m-0">
            {{ claims.paid.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="claims.paid.length === 0">
          <h6 class="text-center mt-4">No Claims Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
          <mdb-card *ngFor="let claim of claims.paid" class="tasks white shadow-sm">
            <mdb-card-header (click)="redirectToClaim(claim)"
              >Claim ID - {{ claim.patientAccountNumber }}
            </mdb-card-header>
            <mdb-card-body>
              <div class="d-flex justify-content-between">
                <span>
                  <p class="mb-0 text-muted">Total charged</p>
                  <p>
                    {{
                      claim.totalClaimChargeAmount
                        ? (claim.totalClaimChargeAmount | currency)
                        : 'N/A'
                    }}
                  </p>
                </span>
                <span>
                  <p class="mb-0 text-muted">Total Paid</p>
                  <p>
                    {{
                      claim.claimPayment?.payment?.totalAmount
                        ? (claim.claimPayment.payment.totalAmount | currency)
                        : 'N/A'
                    }}
                  </p>
                </span>
                <span>
                  <p class="mb-0 text-muted">Payment Date</p>
                  <p>
                    {{
                      claim.claimPayment?.payment?.paymentDate
                        ? (claim.claimPayment.payment.paymentDate | date : 'MM/dd/yyyy')
                        : 'N/A'
                    }}
                  </p>
                </span>
              </div>
              <div *ngIf="claim.statusReason">
                <hr />
                <p>Reason</p>
                <span class="text-danger">{{ claim.statusReason }}</span>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>

    <!-- Denied -->
    <mdb-accordion-item id="denied">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Denied
          <div class="chip chip-rounded alert closed m-0">
            {{ claims.denied.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="claims.denied.length == 0">
          <h6 class="text-center mt-4">No Claims Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
          <mdb-card *ngFor="let claim of claims.denied" class="tasks white shadow-sm">
            <mdb-card-header (click)="redirectToClaim(claim)">
              Claim ID - {{ claim.patientAccountNumber }}
            </mdb-card-header>
            <mdb-card-body class="p-1">
              <table mdbTable borderless="true" class="table-journal table-xs mb-0">
                <tbody class="bg-transparent">
                  <tr>
                    <td width="140">Total charged</td>
                    <td class="text-right">
                      {{
                        claim.totalClaimChargeAmount
                          ? (claim.totalClaimChargeAmount | currency)
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr *ngIf="claim.statusReason">
                    <td>Reason</td>
                    <td class="text-right">
                      <span class="text-danger">{{ claim.statusReason }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>

    <!-- Reject -->
    <mdb-accordion-item id="reject">
      <mdb-accordion-item-head>
        <div class="d-flex justify-content-between align-items-center">
          Rejected
          <div class="chip chip-rounded alert due m-0">
            {{ claims.rejected.length }}
          </div>
        </div>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div *ngIf="claims.rejected.length === 0">
          <h6 class="text-center mt-4">No Claims Available</h6>
        </div>
        <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
          <mdb-card *ngFor="let claim of claims.rejected" class="tasks white shadow-sm">
            <mdb-card-header (click)="redirectToClaim(claim)">
              Claim ID - {{ claim.patientAccountNumber }}
            </mdb-card-header>
            <mdb-card-body class="p-1">
              <table mdbTable borderless="true" class="table-journal table-xs mb-0">
                <tbody class="bg-transparent">
                  <tr>
                    <td width="130">Total charged</td>
                    <td class="text-right">
                      {{
                        claim.totalClaimChargeAmount
                          ? (claim.totalClaimChargeAmount | currency)
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr *ngIf="claim.statusReason">
                    <td>Reason</td>
                    <td class="text-right">
                      <span class="text-danger">{{ claim.statusReason }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </mdb-card-body>
          </mdb-card>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
