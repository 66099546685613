<div class="loader">
  <app-loader [loader]="loading"></app-loader>
</div>
<div class="main-wrapper" *ngIf="!loading">
  <mdb-checkbox
    class="donotprint"
    (change)="onInvoiceCoverSheetClicked($event)"
    [(ngModel)]="showInvoiceCoverSheet">
    Invoice Cover Sheet
  </mdb-checkbox>
  <div id="ub04-with-invoice" class="ub04-with-invoice">
    <!-- Invoice Cover Sheet Begins -->
    <div id="invoice-cover-sheet" class="invoice-cover-sheet" *ngIf="showInvoiceCoverSheet">
      <div class="header">
        <h4>Invoice Cover Sheet</h4>
        <!-- <img src="../../../../../assets/img/png/carrum_health_logo.png" alt="Carrum Health Logo" /> -->
      </div>
      <div class="carrum-health-invoice">
        <!-- <p class="text-center">
          This completed cover sheet must be submitted to Carrum Health at email below:
        </p>
        <p class="secure-email">
          Send via secure email:
          <a href="mailto:{{ carrumHealth.CARRUM_EMAIL }}">{{ carrumHealth.CARRUM_EMAIL }}</a>
        </p> -->
      </div>
      <div class="section-1">
        <h6 class="mb-3">SECTION 1 – Patient and Surgery Information</h6>
        <table mdbTable bordered="true" small="true">
          <tr>
            <th>Patient Name</th>
            <td>
              {{ ub04Detail?.patientDetail?.patientName }}
            </td>
          </tr>
          <tr>
            <th>Patient MRN</th>
            <td>{{ ub04Detail?.medicalRecordNumber }}</td>
          </tr>
          <tr>
            <th>Member ID.</th>
            <td>{{ ub04Detail?.subscriber?.identificationCode }}</td>
          </tr>
          <tr>
            <th>Group Number</th>
            <td>{{ ub04Detail?.subscriber?.insuredGroupNumber }}</td>
          </tr>
          <tr>
            <th>Facility Name</th>
            <td>{{ ub04Detail?.provider?.businessName }}</td>
          </tr>
          <tr>
            <th>Date of Consult</th>
            <td>
              {{ ub04Detail?.statementDate?.fromDate | date : 'MM/dd/yyyy' }} -
              {{ ub04Detail?.statementDate?.throughDate | date : 'MM/dd/yyyy' }}
            </td>
          </tr>
          <tr>
            <th>Date of Surgery</th>
            <td>{{ ub04Detail?.serviceLines[0]?.serviceDate?.date | date : 'MM/dd/yyyy' }}</td>
          </tr>
        </table>
      </div>
      <div class="section-2">
        <h6 class="mb-3">SECTION 2 – Provider Information</h6>
        <table mdbTable bordered="true" small="true">
          <tr>
            <th>Remit-To Provider Name</th>
            <td>{{ ub04Detail?.provider?.businessName }}</td>
          </tr>
          <tr>
            <th>Remit-To Provider TIN</th>
            <td>{{ ub04Detail?.provider?.secondaryInfo?.secondaryIdentifier }}</td>
          </tr>
          <tr>
            <th>Remit-To Address</th>
            <td>
              {{ ub04Detail?.provider?.addressLine1 }} {{ ub04Detail?.provider?.addressLine2 }}
              <br />
              {{ ub04Detail?.provider?.city }} {{ ub04Detail?.provider?.state }}
              {{ ub04Detail?.provider?.zip }}
            </td>
          </tr>
        </table>
        <!-- <p>
          In accordance with the Carrum Health program agreement, providers have authorized
          anypayments and EOPs to be returned to the Remit-To Name, TIN and Address indicated above.
        </p> -->
      </div>
      <div class="section-3">
        <h6 class="mb-3">SECTION 3 – Invoiced Amounts</h6>
        <table mdbTable bordered="true" small="true">
          <tr>
            <th>Bundle Identifier (i.e. CPT/DRG/Consult, as may be contracted)</th>
            <td>
              {{ ub04Detail?.serviceLines[0]?.institutionalService?.procedureCode }} /
              {{ ub04Detail?.serviceLines[0]?.institutionalService?.revenueCode }} /
            </td>
          </tr>
          <tr>
            <th>Contracted Rate</th>
            <td>
              {{
                ub04Detail?.serviceLines[0]?.institutionalService?.lineItemChargeAmount | currency
              }}
            </td>
          </tr>
          <tr>
            <th class="section-3-bg-redish" colspan="2">
              <p class="text-itallic text-white">
                If patient received any unrelated covered services, please fill out the additional
                information:
              </p>
            </th>
          </tr>
          <tr>
            <th>Unrelated Covered Services</th>
            <td></td>
          </tr>
          <tr>
            <th>
              Invoiced Amount for Unrelated Covered Services (Billed atContracted Default Rate)
            </th>
            <td></td>
          </tr>
          <tr>
            <th># Claims Enclosed for Unrelated Covered Services</th>
            <td></td>
          </tr>
        </table>
        <!-- <p class="fw-bold">Please contact Carrum Health with any questions:</p>
        <p>
          Email:
          <a href="mailto:{{ carrumHealth.CARRUM_EMAIL }}">{{ carrumHealth.CARRUM_EMAIL }}</a>
          / Phone: <span class="fw-bold">1-888-773-4716</span> / Fax:
          <span class="fw-bold">1-650-539-0777</span>
        </p> -->
      </div>
    </div>
    <!-- Invoice Cover Sheet Ends -->

    <!-- UB04 Form Begins -->
    <div id="ub04-wrapper" class="ub04-wrapper">
      <div class="container-1-to-3 clear-float">
        <div class="container-1 float-left">
          <!-- 1. First Row -->
          <p class="paragraph-style fw-bold small-font">{{ ub04Detail?.provider?.businessName }}</p>
          <!-- 1. Second Row -->
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.provider?.addressLine1 }} {{ ub04Detail?.provider?.addressLine2 }}
          </p>
          <!-- 1. Third Row -->
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.provider?.city }} {{ ub04Detail?.provider?.state }}
            {{ ub04Detail?.provider?.zip }}
          </p>
          <!-- 1. Forth Row -->
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.provider?.contactInfo?.contactNumber }}
          </p>
        </div>
        <div class="container-2 float-left">
          <!-- 2. First Row -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 2. Second Row -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 2. Third Row -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 2. Forth Row -->
          <p class="paragraph-style fw-bold"></p>
        </div>
        <div class="container-3-to-7 float-right">
          <div class="container-3-4 clear-float">
            <div class="container-3 float-left">
              <!-- 3a. PAT CNTL # -->
              <p class="paragraph-style fw-bold">{{ ub04Detail?.claim?.patientAccountNumber }}</p>
              <!-- 3b. MED REC. # # -->
              <p class="paragraph-style fw-bold">{{ ub04Detail?.medicalRecordNumber }}</p>
            </div>
            <div class="container-4 float-left">
              <!-- 4. Type of Bill -->
              <span class="fw-bold">{{ ub04Detail?.claim?.facilityTypeCode }}</span>
            </div>
          </div>
          <div class="container-5-to-7 clear-float">
            <div class="container-5 float-left">
              <!-- 5. FED TAX NO. -->
              <span class="fw-bold">{{
                ub04Detail?.provider?.secondaryInfo?.secondaryIdentifier
              }}</span>
            </div>
            <div class="container-6 float-left">
              <div class="clear-float">
                <div class="period-from float-left">
                  <!-- 6. STATEMENT COVERS PERIOD FROM -->
                  <span class="fw-bold">{{
                    ub04Detail?.statementDate?.fromDate | date : 'MMddyy'
                  }}</span>
                </div>
                <div class="period-through float-left">
                  <!-- 6. STATEMENT COVERS PERIOD THROUGH -->
                  <span class="fw-bold">{{
                    ub04Detail?.statementDate?.throughDate | date : 'MMddyy'
                  }}</span>
                </div>
              </div>
            </div>
            <div class="container-7 float-left">
              <span class="fw-bold">
                <!-- 7. -->
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="container-8-9 clear-float">
        <div class="container-8 float-left">
          <!-- 8a. Patient Name -->
          <p class="paragraph-style fw-bold ml-11-5">
            {{ ub04Detail?.subscriber?.identificationCode }}
          </p>
          <!-- 8b. -->
          <p class="paragraph-style fw-bold ml-2">
            {{ ub04Detail?.patientDetail?.patientName }}
          </p>
        </div>
        <div class="container-9 float-left">
          <!-- 9a. PATIENT ADDRESS -->
          <p class="paragraph-style fw-bold ml-11-5">
            {{
              ub04Detail?.subscriber?.addressLine1
                ? ub04Detail?.subscriber?.addressLine1 + ' ' + ub04Detail?.subscriber?.addressLine2
                : ub04Detail?.patient?.addressLine1 + ' ' + ub04Detail?.patient?.addressLine2
            }}
          </p>
          <div class="container-9-b">
            <!-- 9b. -->
            <span class="fw-bold">{{
              ub04Detail?.subscriber?.city
                ? ub04Detail?.subscriber?.city
                : ub04Detail?.patient?.city
            }}</span>
            <!-- 9c. -->
            <span class="fw-bold">{{
              ub04Detail?.subscriber?.state
                ? ub04Detail?.subscriber?.state
                : ub04Detail?.patient?.state
            }}</span>
            <!-- 9d. -->
            <span class="fw-bold">{{
              ub04Detail?.subscriber?.zip ? ub04Detail?.subscriber?.zip : ub04Detail?.patient?.zip
            }}</span>
            <!-- 9e. -->
            <span class="fw-bold"></span>
          </div>
        </div>
      </div>

      <div class="container-10-to-30 clear-float">
        <div class="container-10-to-16 float-left">
          <div class="clear-float">
            <div class="container-10 float-left">
              <!-- 10. BIRTHDATE -->
              <span class="fw-bold">
                {{
                  ub04Detail?.subscriber?.dob
                    ? (ub04Detail?.subscriber?.dob | date : 'MMddyyyy')
                    : (ub04Detail?.patient?.demographic?.dateOfBirth | date : 'MMddyyyy')
                }}
              </span>
            </div>
            <div class="container-11 float-left">
              <!-- 11. SEX -->
              <span class="fw-bold">{{
                ub04Detail?.subscriber?.gender
                  ? ub04Detail?.subscriber?.gender
                  : ub04Detail?.patient?.demographic?.genderCode
              }}</span>
            </div>
            <div class="container-12-to-16 float-left ml-1">
              <div class="clear-float">
                <div class="container-12 float-left">
                  <!-- 12. DATE -->
                  <span class="fw-bold">{{ ub04Detail?.admissionDate | date : 'MMddyyyy' }}</span>
                </div>
                <div class="container-13 float-left">
                  <!-- 13. HR -->
                  <span class="fw-bold">{{ ub04Detail?.admissionHour }}</span>
                </div>
                <div class="container-14 float-left">
                  <!-- 14. TYPE -->
                  <span class="fw-bold">{{ ub04Detail?.admissionType }}</span>
                </div>
                <div class="container-15 float-left">
                  <!-- 15. SRC -->
                  <span class="fw-bold">{{ ub04Detail?.admissionSource }}</span>
                </div>
                <div class="container-16 float-left">
                  <!-- 16. DHR -->
                  <span class="fw-bold">{{ ub04Detail?.dischargeHour }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-17-to-30 float-left">
          <div class="clear-float">
            <div class="container-17 float-left">
              <!-- 17 STAT -->
              <span class="fw-bold">{{ ub04Detail?.patient?.patientStatusCode }}</span>
            </div>
            <div class="container-18-to-28 float-left">
              <div *ngFor="let cInfo of ub04Detail?.conditionInfo; let i = index">
                <div class="container-{{ 18 + i }} float-left">
                  <span class="fw-bold">{{ cInfo?.diagnosisCode }}</span>
                </div>
              </div>
              <!-- <div class="container-18 float-left">
                <span class="fw-bold">18</span>
              </div>
              <div class="container-19 float-left">
                <span class="fw-bold">19</span>
              </div>
              <div class="container-20 float-left">
                <span class="fw-bold">20</span>
              </div>
              <div class="container-21 float-left">
                <span class="fw-bold">21</span>
              </div>
              <div class="container-22 float-left">
                <span class="fw-bold">22</span>
              </div>
              <div class="container-23 float-left">
                <span class="fw-bold">23</span>
              </div>
              <div class="container-24 float-left">
                <span class="fw-bold">24</span>
              </div>
              <div class="container-25 float-left">
                <span class="fw-bold">25</span>
              </div>
              <div class="container-26 float-left">
                <span class="fw-bold">26</span>
              </div>
              <div class="container-27 float-left">
                <span class="fw-bold">27</span>
              </div>
              <div class="container-28 float-left">
                <span class="fw-bold">28</span>
              </div> -->
            </div>
            <div class="container-29 float-left">
              <!-- 29. ACDT STATE -->
              <span class="fw-bold"></span>
            </div>
            <div class="container-30 float-left">
              <!-- 30. -->
              <span class="fw-bold"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="container-31-to-37 clear-float">
        <div class="container-31 float-left">
          <!-- 31. a -->
          <p class="paragraph-style">
            <span class="fw-bold"></span>
            <span class="fw-bold ml-1"></span>
          </p>
          <!-- 31. b -->
          <p class="paragraph-style">
            <span class="fw-bold"></span>
            <span class="fw-bold ml-1"></span>
          </p>
        </div>
        <div class="container-33 float-left">
          <!-- 33. a -->
          <p class="paragraph-style">
            <span class="fw-bold"></span>
            <span class="fw-bold ml-1"></span>
          </p>
          <!-- 33. b -->
          <p class="paragraph-style">
            <span class="fw-bold"></span>
            <span class="fw-bold ml-1"></span>
          </p>
        </div>
        <div class="container-33 float-left">
          <!-- 33. a -->
          <p class="paragraph-style">
            <span class="fw-bold"></span>
            <span class="fw-bold ml-1"></span>
          </p>
          <!-- 33. b -->
          <p class="paragraph-style">
            <span class="fw-bold"></span>
            <span class="fw-bold ml-1"></span>
          </p>
        </div>
        <div class="container-34 float-left">
          <!-- 34. a -->
          <p class="paragraph-style">
            <span class="fw-bold"></span>
            <span class="fw-bold ml-1"></span>
          </p>
          <!-- 34. b -->
          <p class="paragraph-style">
            <span class="fw-bold"></span>
            <span class="fw-bold ml-1"></span>
          </p>
        </div>
        <div class="container-35 float-left">
          <p class="paragraph-style">
            <!-- 35. a CODE -->
            <span class="fw-bold"></span>
            <!-- 35. a FROM -->
            <span class="fw-bold"></span>
            <!-- 35. a  THROUGH -->
            <span class="fw-bold"></span>
          </p>
          <p class="paragraph-style">
            <!-- 35. b CODE -->
            <span class="fw-bold"></span>
            <!-- 35. b FROM -->
            <span class="fw-bold ml-1"></span>
            <!-- 35. b THROUGH -->
            <span class="fw-bold ml-1"></span>
          </p>
        </div>
        <div class="container-36 float-left">
          <p class="paragraph-style">
            <!-- 36. a CODE -->
            <span class="fw-bold"></span>
            <!-- 36. a FROM -->
            <span class="fw-bold ml-1"></span>
            <!-- 36. a THROUGH -->
            <span class="fw-bold ml-1"></span>
          </p>
          <p class="paragraph-style">
            <!-- 36. b CODE -->
            <span class="fw-bold"></span>
            <!-- 36. b FROM -->
            <span class="fw-bold ml-1"></span>
            <!-- 36. b THROUGH -->
            <span class="fw-bold ml-1"></span>
          </p>
        </div>
        <div class="container-37 float-left">
          <!-- 37. a -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 37. b -->
          <p class="paragraph-style fw-bold"></p>
        </div>
      </div>

      <div class="container-38-t0-41 clear-float">
        <div class="container-38 float-left">
          <!-- 38. -->
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.subscriber?.firstName }} {{ ub04Detail?.subscriber?.middleName }}
            {{ ub04Detail?.subscriber?.lastName }}<br />
            {{ ub04Detail?.subscriber?.addressLine1 }} {{ ub04Detail?.subscriber?.addressLine2 }}
            <br />
            {{ ub04Detail?.subscriber?.city }} {{ ub04Detail?.subscriber?.state }}
            {{ ub04Detail?.subscriber?.zip }}
          </p>
        </div>
        <div class="container-39-to-41 float-left">
          <div class="clear-float">
            <div class="container-39 float-left">
              <p class="paragraph-style">
                <!-- 39. a CODE -->
                <span class="fw-bold"></span>
                <!-- 39. a AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
              <p class="paragraph-style">
                <!-- 39. b CODE -->
                <span class="fw-bold"></span>
                <!-- 39. b AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
              <p class="paragraph-style">
                <!-- 39. c CODE -->
                <span class="fw-bold"></span>
                <!-- 39. c AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
              <p class="paragraph-style">
                <!-- 39. d CODE -->
                <span class="fw-bold"></span>
                <!-- 39. d AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
            </div>
            <div class="container-40 float-left">
              <p class="paragraph-style">
                <!-- 40. a CODE -->
                <span class="fw-bold"></span>
                <!-- 40. a AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
              <p class="paragraph-style">
                <!-- 40. b CODE -->
                <span class="fw-bold"></span>
                <!-- 40. b AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
              <p class="paragraph-style">
                <!-- 40. c CODE -->
                <span class="fw-bold"></span>
                <!-- 40. c AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
              <p class="paragraph-style">
                <!-- 40. d CODE -->
                <span class="fw-bold"></span>
                <!-- 40. d AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
            </div>
            <div class="container-41 float-left">
              <p class="paragraph-style">
                <!-- 41. a CODE -->
                <span class="fw-bold"></span>
                <!-- 41. a AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
              <p class="paragraph-style">
                <!-- 41. b CODE -->
                <span class="fw-bold"></span>
                <!-- 41. b AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
              <p class="paragraph-style">
                <!-- 41. c CODE -->
                <span class="fw-bold"></span>
                <!-- 41. c AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
              <p class="paragraph-style">
                <!-- 41. d CODE -->
                <span class="fw-bold"></span>
                <!-- 41. d AMOUNT -->
                <span class="fw-bold ml-1"></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-42-to-49">
        <div class="service-div">
          <!-- Loop service row for multiple lines -->
          <div *ngFor="let item of ub04Detail?.serviceLines" class="service-row">
            <span class="fw-bold">{{ item?.institutionalService?.revenueCode }}</span>
            <span class="fw-bold">{{ item?.institutionalService?.description }}</span>
            <span class="fw-bold"
              >{{ generateProcedureCodeWithModifier(item?.institutionalService) }}
            </span>
            <span class="fw-bold">
              {{ item?.serviceDate?.date | date : 'MMddyy' }}
            </span>
            <span class="fw-bold">{{ item?.institutionalService?.serviceUnitCount }}</span>
            <span class="fw-bold">{{
              item?.institutionalService?.lineItemChargeAmount | currency
            }}</span>
            <span class="fw-bold">{{
              item?.institutionalService?.nonCoveredChargeAmount | currency
            }}</span>
            <span class="fw-bold"></span>
          </div>
        </div>
        <div class="totals-div">
          <span class="fw-bold"></span>
          <span class="pageNumberHolder">
            <span class="fw-bold pageNumber">1</span>
            <span class="fw-bold totalPageNumber">1</span>
          </span>
          <span class="fw-bold">{{ ub04Detail?.createdDate | date : 'MMddyy' }}</span>
          <span class="fw-bold"></span>
          <span class="fw-bold">{{
            ub04Detail?.totalClaimChargeAmount > 0
              ? (ub04Detail?.totalClaimChargeAmount | currency)
              : ''
          }}</span>
          <span class="fw-bold">{{
            ub04Detail?.totalNonCoveredChargeAmount > 0
              ? (ub04Detail?.totalNonCoveredChargeAmount | currency)
              : ''
          }}</span>
          <span class="fw-bold"></span>
        </div>
      </div>

      <div class="container-50-to-57 clear-float">
        <div class="container-50 float-left">
          <!-- 50. a -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.payer?.businessName }}</p>
          <!-- 50. b -->
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.payer?.addressLine1 }} {{ ub04Detail?.payer?.addressLine2 }}
          </p>
          <!-- 50. c -->
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.payer?.city }} {{ ub04Detail?.payer?.state }}
            {{ ub04Detail?.payer?.zip }}
          </p>
        </div>
        <div class="container-51 float-left">
          <!-- 51. a -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.payer?.identificationCode }}</p>
          <!-- 51. b -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 51. c -->
          <p class="paragraph-style fw-bold"></p>
        </div>
        <div class="container-52 float-left">
          <!-- 52. REL. INFO a -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.claim?.releaseInformationCode }}</p>
          <!-- 52. REL. INFO b -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 52. REL. INFO c -->
          <p class="paragraph-style fw-bold"></p>
        </div>
        <div class="container-53 float-left">
          <!-- 53. ASG INFO a -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.claim?.medicareAssignmentCode }}</p>
          <!-- 53. ASG INFO b -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 53. ASG INFO c -->
          <p class="paragraph-style fw-bold"></p>
        </div>
        <div class="container-54 float-left">
          <!-- 54. PRIOR PAYMENTS a -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 54. PRIOR PAYMENTS b -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 54. PRIOR PAYMENTS c -->
          <p class="paragraph-style fw-bold"></p>
        </div>
        <div class="container-55 float-left">
          <!-- 55. EST. AMOUNT DUE a -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 55. EST. AMOUNT DUE b -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 55. EST. AMOUNT DUE c -->
          <p class="paragraph-style fw-bold"></p>
        </div>
        <div class="container-56-57 float-left">
          <!-- 56. NPI -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.provider?.identificationCode }}</p>
          <!-- 57 -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 57. OTHER -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 57. PRV ID. -->
          <p class="paragraph-style fw-bold"></p>
        </div>
      </div>

      <div class="container-58-to-62 clear-float">
        <!-- 58. INSURED'S NAME -->
        <div class="container-58 float-left">
          <!-- 58. A -->
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.subscriber?.firstName }} {{ ub04Detail?.subscriber?.middleName }}
            {{ ub04Detail?.subscriber?.lastName }}
          </p>
          <!-- 58. B -->
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.subscriber?.addressLine1 }} {{ ub04Detail?.subscriber?.addressLine2 }}
          </p>
          <!-- 58. C -->
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.subscriber?.city }} {{ ub04Detail?.subscriber?.state }}
            {{ ub04Detail?.subscriber?.zip }}
          </p>
        </div>
        <!-- 59. P. REL -->
        <div class="container-59 float-left">
          <!-- 59. P. REL A -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.patient?.relationshipCode }}</p>
          <!-- 59. P. REL B -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 59. P. REL C -->
          <p class="paragraph-style fw-bold"></p>
        </div>
        <!-- 60. INSURED'S UNIQUE ID -->
        <div class="container-60 float-left">
          <!-- 60. A -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.subscriber?.identificationCode }}</p>
          <!-- 60. B -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 60. C -->
          <p class="paragraph-style fw-bold"></p>
        </div>
        <!-- 61. GROUP NAME -->
        <div class="container-61 float-left">
          <!-- 61. A -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.subscriber?.insuredGroupName }}</p>
          <!-- 61. B -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 61. C -->
          <p class="paragraph-style fw-bold"></p>
        </div>
        <!-- 62. INSURANCE GROUP NO. -->
        <div class="container-62 float-left">
          <!-- 62. A -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.subscriber?.insuredGroupNumber }}</p>
          <!-- 62. B -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 62. C -->
          <p class="paragraph-style fw-bold"></p>
        </div>
      </div>

      <div class="container-63-to-65 clear-float">
        <!-- 63. TREATMENT AUTHORIZATION CODES -->
        <div class="container-63 float-left">
          <!-- 63. TREATMENT AUTHORIZATION CODES A. -->
          <!-- <p class="paragraph-style fw-bold">AZ6ASF67Z7F6</p> -->
          <!-- 63. TREATMENT AUTHORIZATION CODES B. -->
          <!-- <p class="paragraph-style fw-bold">8667XFWF7SF6</p> -->
          <!-- 63. TREATMENT AUTHORIZATION CODES C. -->
          <!-- <p class="paragraph-style fw-bold">DASFD676A6F6</p> -->
          <p class="paragraph-style fw-bold" *ngFor="let item of ub04Detail?.treatmentCodeInfo">
            {{ item.diagnosisCode }}
          </p>
        </div>

        <!-- 64. DOCUMENT CONTROL NUMBER -->
        <div class="container-64 float-left">
          <!-- 64. DOCUMENT CONTROL NUMBER A. -->
          <p class="paragraph-style fw-bold">{{ ub04Detail?.documentControlNumber }}</p>
          <!-- 64. DOCUMENT CONTROL NUMBER B. -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 64. DOCUMENT CONTROL NUMBER C. -->
          <p class="paragraph-style fw-bold"></p>
        </div>

        <!-- 65. EMPLOYER NAME -->
        <div class="container-65 float-left">
          <!-- 65. EMPLOYER NAME A. -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 65. EMPLOYER NAME B. -->
          <p class="paragraph-style fw-bold"></p>
          <!-- 65. EMPLOYER NAME C. -->
          <p class="paragraph-style fw-bold"></p>
        </div>
      </div>

      <div class="container-66-to-68 clear-float">
        <div class="container-66 float-left">
          <!-- 66. DX -->
          <span class="fw-bold">0</span>
        </div>
        <div class="container-67 float-left">
          <!-- 67. -->
          <span class="fw-bold">{{ ub04Detail?.principalDiagnosis[0]?.diagnosisCode }}</span>
          <!-- <span class="fw-bold">A</span>
          <span class="fw-bold">B</span>
          <span class="fw-bold">C</span>
          <span class="fw-bold">D</span>
          <span class="fw-bold">E</span>
          <span class="fw-bold">F</span>
          <span class="fw-bold">G</span>
          <span class="fw-bold">H</span>
          <span class="fw-bold">I</span>
          <span class="fw-bold">J</span>
          <span class="fw-bold">K</span>
          <span class="fw-bold">L</span>
          <span class="fw-bold">M</span>
          <span class="fw-bold">N</span>
          <span class="fw-bold">O</span>
          <span class="fw-bold">P</span>
          <span class="fw-bold">Q</span> -->
          <span class="fw-bold" *ngFor="let item of ub04Detail?.otherDiagnosisInfo">
            {{ item.diagnosisCode }}
          </span>
        </div>
        <div class="container-68 float-left">
          <p class="paragraph-style fw-bold"></p>
          <p class="paragraph-style fw-bold"></p>
        </div>
      </div>

      <div class="container-69-to-73 clear-float">
        <!-- 69. ADMIT DX -->
        <div class="container-69 float-left">
          <p class="paragraph-style fw-bold">
            {{ ub04Detail?.admittingDiagnosis[0]?.diagnosisCode }}
          </p>
        </div>
        <!-- 70. PATIENT REASON DX -->
        <div class="container-70 float-left">
          <!-- <span class="fw-bold">a</span>
          <span class="fw-bold">b</span>
          <span class="fw-bold">c</span> -->
          <span class="fw-bold" *ngFor="let item of ub04Detail?.reasonforVisit">
            {{ item.diagnosisCode }}
          </span>
        </div>
        <!-- 71 PPS CODE -->
        <div class="container-71 float-left">
          <p class="paragraph-style fw-bold"></p>
        </div>
        <!-- 72. ECI -->
        <div class="container-72 float-left">
          <!-- <span class="fw-bold">a</span>
          <span class="fw-bold">b</span>
          <span class="fw-bold">c</span> -->
          <span class="fw-bold" *ngFor="let item of ub04Detail?.causeOfInjury">
            {{ item.diagnosisCode }}
          </span>
        </div>
        <div class="container-73 float-left">
          <p class="paragraph-style fw-bold"></p>
        </div>
      </div>

      <div class="container-74-to-77 clear-float">
        <div class="container-74-to-75 float-left">
          <div class="clear-float">
            <div class="container-74 float-left">
              <div class="container-74-top">
                <!-- 74. OTHER PROCEDURE CODE -->
                <span class="fw-bold">{{
                  ub04Detail?.principalProcedureInfo[0]?.diagnosisCode
                }}</span>
                <!-- 74. OTHER PROCEDURE DATE -->
                <span class="fw-bold">{{
                  ub04Detail?.principalProcedureInfo[0]?.date | date : 'MMddyyyy'
                }}</span>

                <!-- 74 a. OTHER PROCEDURE CODE -->
                <span class="fw-bold"></span>
                <!-- 74 a. OTHER PROCEDURE DATE -->
                <span class="fw-bold"></span>

                <!-- 74 b. OTHER PROCEDURE CODE -->
                <span class="fw-bold"></span>
                <!-- 74 b. OTHER PROCEDURE DATE -->
                <span class="fw-bold"></span>
              </div>
              <div class="container-74-bottom">
                <!-- 74 c. PRINCIPAL PROCEDURE CODE -->
                <span class="fw-bold"></span>
                <!-- 74 c. PRINCIPAL PROCEDURE DATE -->
                <span class="fw-bold"></span>

                <!-- 74 d. OTHER PROCEDURE CODE -->
                <span class="fw-bold"></span>
                <!-- 74 d. OTHER PROCEDURE DATE -->
                <span class="fw-bold"></span>

                <!-- 74 e. OTHER PROCEDURE CODE -->
                <span class="fw-bold"></span>
                <!-- 74 e. OTHER PROCEDURE DATE -->
                <span class="fw-bold"></span>
              </div>
            </div>
            <div class="container-75 float-left">
              <!-- 75. text here -->
              <p class="paragraph-style fw-bold"></p>
            </div>
          </div>
        </div>
        <div class="container-76-to-77 float-left">
          <div class="clear-float">
            <div class="container-76">
              <div class="container-76-top">
                <!-- 76. NPI -->
                <span class="fw-bold">{{
                  ub04Detail?.attendingPhysician?.identificationCode
                }}</span>
                <!-- 76. QUAL FIRST BOX -->
                <span class="fw-bold">{{
                  ub04Detail?.attendingPhysician?.entityTypeQualifier
                    ? ub04Detail?.attendingPhysician?.identificationCodeQualifier
                    : ' '
                }}</span>
                <!-- 76. QUAL SECOND BOX -->
                <span class="fw-bold"></span>
              </div>
              <div class="container-76-bottom">
                <!-- 76. ATTENDING LAST -->
                <span class="fw-bold">{{ ub04Detail?.attendingPhysician?.lastName }}</span>
                <!-- 76. ATTENDING FIRST -->
                <span class="fw-bold">{{ ub04Detail?.attendingPhysician?.firstName }}</span>
              </div>
            </div>
            <div class="container-77">
              <div class="container-77-top">
                <!-- 77. NPI -->
                <span class="fw-bold">
                  {{ ub04Detail?.operatingPhysician?.identificationCode }}
                </span>
                <!-- 77. QUAL FIRST BOX -->
                <span class="fw-bold">
                  {{ ub04Detail?.operatingPhysician?.identificationCodeQualifier }}
                </span>
                <!-- 77. QUAL SECOND BOX -->
                <span class="fw-bold"> </span>
              </div>
              <div class="container-77-bottom">
                <!-- 77. ATTENDING LAST -->
                <span class="fw-bold">
                  {{ ub04Detail?.operatingPhysician?.lastName }}
                </span>
                <!-- 77. ATTENDING FIRST -->
                <span class="fw-bold">
                  {{ ub04Detail?.operatingPhysician?.firstName }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-78-to-81 clear-float">
        <!-- 80. REMARKS -->
        <div class="container-80 float-left">
          <p class="paragraph-style fw-bold"></p>
          <p class="paragraph-style fw-bold"></p>
          <p class="paragraph-style fw-bold"></p>
        </div>
        <div class="container-81 float-left">
          <!-- 81. a FIRST ROW -->
          <div class="container-81-a">
            <span class="fw-bold"></span>
            <span class="fw-bold"></span>
            <span class="fw-bold"></span>
          </div>
          <!-- 81. b SECOND ROW -->
          <div class="container-81-b">
            <span class="fw-bold"></span>
            <span class="fw-bold"></span>
            <span class="fw-bold"></span>
          </div>
          <!-- 81. c THIRD ROW -->
          <div class="container-81-c">
            <span class="fw-bold"></span>
            <span class="fw-bold"></span>
            <span class="fw-bold"></span>
          </div>
          <!-- 81. d Fourth ROW -->
          <div class="container-81-d">
            <span class="fw-bold"></span>
            <span class="fw-bold"></span>
            <span class="fw-bold"></span>
          </div>
        </div>
        <div class="container-78-to-79 float-left">
          <div class="container-78">
            <div class="container-78-top">
              <span class="fw-bold"></span>
              <!-- 78. NPI -->
              <span class="fw-bold"></span>
              <!-- 78. QUAL FIRST BOX -->
              <span class="fw-bold"></span>
              <!-- 78. QUAL SECOND BOX -->
              <span class="fw-bold"></span>
            </div>
            <div class="container-78-bottom">
              <!-- 78. OTHER LAST -->
              <span class="fw-bold"></span>
              <!-- 78. OTHER FIRST -->
              <span class="fw-bold"></span>
            </div>
          </div>
          <div class="container-79">
            <div class="container-79-top">
              <span class="fw-bold"></span>
              <!-- 79. NPI -->
              <span class="fw-bold"></span>
              <!-- 79. QUAL FIRST BOX -->
              <span class="fw-bold"></span>
              <!-- 79. QUAL SECOND BOX -->
              <span class="fw-bold"></span>
            </div>
            <div class="container-79-bottom">
              <!-- 79. OTHER LAST -->
              <span class="fw-bold"></span>
              <!-- 79. OTHER FIRST -->
              <span class="fw-bold"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- UB04 Form Ends -->
  </div>
</div>
