import { Injectable } from "@angular/core";
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
import { APICONSTANTS } from "../constants";

@Injectable({
    providedIn: "root"
})

export class PlatformService {
    constructor(private http: HttpClientService) { }

    getAll(queryParam?) {
        return this.http.get(`${APICONSTANTS.PLATFORM_OWNERS}`, queryParam).pipe(
            map((response: any) => {
                return response.data
            })
        );
    }

    getById(id) {
        return this.http.get(`${APICONSTANTS.PLATFORM_OWNERS}/${id}`).pipe(
            map((response: any) => {
                return response.data
            })
        );
    }

    update(id, body) {
        return this.http.put(`${APICONSTANTS.PLATFORM_OWNERS}/${id}`, body).pipe(
            map((response: any) => {
                return response.data
            })
        );
    }

}