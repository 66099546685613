<div
  mdbModal
  #mfaModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true}">
  <div class="modal-dialog modal-dialog-centered share-modal" role="document">
    <!--Content-->
    <div class="modal-content rounded-0">
      <!--Header-->

      <div class="modal-header">
        <h4 class="w-100 text-center mb-0" id="myModalLabel">Verify Code</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="hideModal($event)">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <!--Body-->
      <!-- <div class="modal-body"> -->
      <div class="modal-body">
        <form [formGroup]="mfaForm">
          <div class="text-center">
            <small class="d-block">
              <p [innerHTML]="mfaDetail?.popupMessage"></p>
            </small>
            <div class="row mt-4">
              <div class="col-10 mx-auto">
                <ng-otp-input
                  *ngIf="mfaDetail && object.keys(mfaDetail).length"
                  #ngOtpInput
                  (onInputChange)="onOtpCodeChange($event)"
                  [config]="{length: mfaDetail?.otpLength, allowNumbersOnly: true}"></ng-otp-input>
                <ul class="small mt-4 p-0 text-center">
                  <li>
                    <p class="mb-2">
                      The code is valid for <strong>{{ mfaDetail?.expiresIn }}</strong>
                    </p>
                  </li>
                  <li>
                    <p>
                      Didn't receive the code?
                      <a class="text-secondary" (click)="resendMFACode()"><b>Resend</b></a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <mdb-checkbox formControlName="rememberMfaCode">
              <p>
                Remember this code for <b>{{ mfaDetail?.rememberMfaFor }} days</b>
              </p>
            </mdb-checkbox>
          </div>
        </form>
      </div>
      <!--/.Content-->
    </div>
  </div>
</div>
