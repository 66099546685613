import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MESSAGE_TEXT_EDITOR, MESSAGE_SENT_SUCCESS, USER_DATA, EI_TO_CLIENT, CLAIMS_INQUIRY_TO_PROVIDER } from '../../constants';
import { PDFUtility, MetaVariablesBinding, Storage, EmailUtility, TitleCase, ReportUtility, HolistaUtils } from '../../utils';
import { CommunicationService, DataLinkService, EpisodeService, StorageService, ToasterService, UserService } from '../../services';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import * as moment from 'moment';
import { CommunicationModuleOptions } from '../../models';
import * as uuid from 'uuid';

@Component({
  selector: 'app-communication-message',
  templateUrl: './communication-message.component.html',
  styleUrls: ['./communication-message.component.scss']
})
export class CommunicationMessageComponent implements OnInit {
  messageForm: FormGroup;
  editorConfig = MESSAGE_TEXT_EDITOR;
  isTemplateSelected: boolean = false;
  messageTemplates: Array<object> = [{
    name: 'Blank',
    isBlank: true
  }];
  selectedTemplate: any;
  isSubmitted: boolean = false;
  user: any;
  otpToken: string;
  sentMessageId: number;
  uploadInput: EventEmitter<UploadInput>;
  files: UploadFile[] = [];
  shouldScroll: boolean = false;
  pdfConvertedFile: any;
  loading = {
    process: false,
    data: false
  };
  isFileAttachedManually: boolean = false;

  @Input() set showModal(showModal: boolean) {
    if (showModal) {
      this.loading.data = this.messageTemplates.length > 1 ? false : true;
      this.isTemplateSelected = false;
      this.messageForm.reset();
      setTimeout(() => {
        this.messageModal.show();
      }, 1)
    }
  }
  @Input() isCommunicationModule: boolean;
  @Input() moduleOptions: CommunicationModuleOptions;
  @Input() metaVariablesDetail;
  @Input() idPDF: Array<string>;
  @Output() changeTabToSent: EventEmitter<any> = new EventEmitter();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() onMessageSent: EventEmitter<any> = new EventEmitter();

  @ViewChild('messageModal', { static: true }) public messageModal;
  @ViewChild('scrollWrapper', { static: false }) private scrollWrapper;

  constructor(
    private _formBuilder: FormBuilder,
    private _storage: Storage,
    private _communicationService: CommunicationService,
    private _emailUtility: EmailUtility,
    private _holistaUtility: HolistaUtils,
    private _toastr: ToasterService,
    private _metaVariablesBinding: MetaVariablesBinding,
    private _pdfUtility: PDFUtility,
    private _titleCase: TitleCase,
    private _episodeService: EpisodeService,
    private _userService: UserService,
    private _reportUtility: ReportUtility,
    private _dataLinkService: DataLinkService,
    private _storageService: StorageService
  ) {
    this.setMessageForm();
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  ngOnInit() {
    this.otpToken = this._storage.get('session', 'otpToken');
    this.user = !this.otpToken && this._storage.get('local', 'loggedInUser', 'user');
    this.user && this._communicationService.getMessageTemplates().subscribe(res => {
      this.messageTemplates = [...this.messageTemplates, ...res.rows]
      this.loading.data = false;
    });
  }

  setMessageForm() {
    this.messageForm = this._formBuilder.group({
      to: [null, [Validators.required, this._emailUtility.validateEmail]],
      subject: [null],
      body: [null, Validators.required]
    });
  }

  closeMessageModal() {
    this.isSubmitted = false;
    this.selectedTemplate = null;
    this.closeModal.emit();
    this.messageModal.hide();
    this.files.length = 0;
    this._storageService.clearData();
  }

  getRecieverList(receiverEmails) {
    return new Promise((resolve, reject) => {
      const receiverList = [];
      receiverEmails && receiverEmails.forEach((email: string) => {
        this._userService.searchUsers({ email: email.toLowerCase() }).subscribe(res => {
          const data = res.rows.length ?
            ({ type: 'Client', email: email.toLowerCase(), name: `${res.rows[0].firstName} ${res.rows[0].lastName}` }) :
            ({ type: 'Client', email: email.toLowerCase() })
          receiverList.push(data);
          receiverList.length === receiverEmails.length && resolve(receiverList);
        });
      });
    });
  }

  onSendMessage() {
    this.isSubmitted = true;
    this.messageForm.valid && (this.loading.process = true);
    const receiverEmails = this.messageForm.value.to && this.messageForm.value.to.split(/[ ,]+/);
    this.getRecieverList(receiverEmails).then(res => {
      const uniqueReceiverList = this._holistaUtility.getUniqueList(res, 'email');
      const message = {
        sender: {
          type: "Client",
          name: this.user && (`${this.user.firstName && this.user.firstName} ${this.user.lastName && this.user.lastName}`),
          email: this.user && this.user.email
        },
        receivers: {
          to: uniqueReceiverList
        },
        subject: this.messageForm.value.subject,
        body: this.messageForm.value.body,
        attachments: this.files.map((x) => x.nativeFile ? x.nativeFile : x),
        moduleOption: this.moduleOptions
      };
      this.messageForm.valid && this._communicationService.sendMessage(message).subscribe(res => {
        this._toastr.displaySuccess(MESSAGE_SENT_SUCCESS);
        this.closeMessageModal();
        this.isCommunicationModule && (this.sentMessageId = res._id, this.changeTabToSent.emit())
        this.loading.process = false;
      },
        error => {
          console.log('error sending message', error);
          this._toastr.displayError(error.error.message);
          this.loading.process = false;
        })
    })
  }

  /**
   * opens the message with binded metavariables for selected template
   * @param template 
   */
  onTemplateSelected(template?) {
    if (template) {
      this.isTemplateSelected = true;
      this.selectedTemplate = template;
      this._storageService.getData().then(reportData => {
        const summaryReportBlob = this._dataLinkService.getDataSource.getValue();
        if (reportData.length) {
          const reportBlobPromise = this._reportUtility.exportToCsv(reportData[0], false);
          reportBlobPromise.then(res => {
            const reportBlob = res;
            const excelFile: any = new File([reportBlob], `${reportData[0].excelName}.xlsx`);
            this.files.push(excelFile);
          })
        } else if (!reportData.length && summaryReportBlob.key === 'dataSummaryBlob') {
          const summaryBlob = summaryReportBlob.value;
          const date = moment().format('L').replace(/\//g, "");
          const excelFile: any = new File([summaryBlob as BlobPart], `Claims_Reconciliation_Report.xlsx`);
          this.files.push(excelFile);
        } else {
          this.idPDF && this.getAttachedPDF();
        }
      });

      if (template.body) { //If other than blank template is selected, perform meta variable binding.
        this.loading.data = true;
        let subject = template.subject;
        let messageBody = template.body.email;  //email -> message body

        if (template.lookupCode === CLAIMS_INQUIRY_TO_PROVIDER) { //checks for milestone that is set as main procedure and gets the date
          const episodeId = this.metaVariablesDetail && this.metaVariablesDetail.detail && this.metaVariablesDetail.detail.claimXRefHolista && this.metaVariablesDetail.detail.claimXRefHolista.episodeId;
          if (episodeId) {
            this._episodeService.getEpisode(episodeId).pipe().subscribe((res: any) => {
              const milestone = res.milestones.find(x => x.isMainProcedure);
              const mainProcedureDate = milestone && milestone.startDate;
              this.metaVariablesDetail.detail.claimXRefHolista = { ...this.metaVariablesDetail.detail.claimXRefHolista, mainProcedureDate: mainProcedureDate };
            })
          }
        }
        this._communicationService.getTemplateData(template.lookupCode).subscribe((res: any) => {
          res && res.metaData.variables.forEach(x => {
            if (this.metaVariablesDetail && this.metaVariablesDetail.module === 'episode') {
              const patient = this.metaVariablesDetail.detail.patient || this.metaVariablesDetail.detail.claimPatient;
              this.metaVariablesDetail.detail.patient = { ...patient, fullName: patient.firstName + ' ' + patient.lastName }
            }
            if (this.metaVariablesDetail && subject.includes(x.title)) {
              subject = this.getMetaVariablesBindedText(x.key, this.metaVariablesDetail.module, x.title, this.metaVariablesDetail.detail, subject);
            }
            if (this.metaVariablesDetail && messageBody.includes(x.title)) {
              messageBody = this.getMetaVariablesBindedText(x.key, this.metaVariablesDetail.module, x.title, this.metaVariablesDetail.detail, messageBody)
            }
          })
          if (template.lookupCode === EI_TO_CLIENT) { // uses logged in user data for Episode Information (To Client)
            const loggedInUser = this._storage.get('local', 'loggedInUser');
            const userData = { ...loggedInUser.user, fullName: loggedInUser.user.firstName + ' ' + loggedInUser.user.lastName };
            const metaData = USER_DATA;
            metaData.forEach(x => {
              messageBody = this.getMetaVariablesBindedText(x.key, 'user', x.title, userData, messageBody);
            })
          }
          this.messageForm.controls['subject'].setValue(subject);
          this.messageForm.controls['body'].setValue(messageBody);
          this.loading.data = false;
        })
      }
    }
  }

  /**
   * binds the values related to metaVariables provided
   * @param key
   * @param module 
   * @param title 
   * @param metaVariablesDetail 
   * @param body 
   * @returns 
   */
  getMetaVariablesBindedText(key: string, module: string, title: string, metaVariablesDetail, body: string) {
    const variablePath = this._metaVariablesBinding.getVariablePath(key, module);
    if (variablePath) {
      const keys = variablePath.split('?.');
      if (body.includes(title)) {
        body = body.replace(`{{${title}}}`, key === 'patientName' ? this._titleCase.toTitleCase(this.getVariableData(metaVariablesDetail, keys, title)) : this.getVariableData(metaVariablesDetail, keys, title));
        return body;
      }
    } else return body;
  }

  onDiscardChanges() {
    this.shouldScroll = false;
    this.isTemplateSelected = false;
    this.messageForm.reset();
    this.isSubmitted = false;
    this.files.length = 0;
    this.isFileAttachedManually = false;
  }

  onMessageSelected() {
    this.sentMessageId = null;
  }

  onUploadOutput(output: UploadOutput | any): void {
    if (output.type === 'addedToQueue') {
      this.files.push(output.file); // add file to array when added
      this.scrollToBottom();
      this.isFileAttachedManually = true;
    }
  }

  onRemoveFile(event) {
    this.files = this.files.filter(x => x.id !== event.id);
    !this.files.length && (this.isFileAttachedManually = false);
  }

  /**
   * returns values associated to specific keys
   * @param data 
   * @param keys 
   * @returns 
   */
  getVariableData(data, keys, title: string) {
    const keyName = keys.shift();
    const obj = data[keyName];
    if (obj && keys.length > 0) {
      return this.getVariableData(obj, keys, title);
    } else if (!obj) {
      return `{{${title}}}`
    }
    else {
      return obj && (moment(obj, moment.ISO_8601, true).isValid() ? moment(obj).format('MM-DD-YYYY') : obj);
    }
  }

  scrollToBottom() {
    this.shouldScroll = true;
    try {
      this.scrollWrapper.nativeElement.scrollTop = this.scrollWrapper.nativeElement.scrollHeight;
    } catch (err) { }
  }

  getFileName(type, id?: string) {
    switch (type) {
      case 'reconciliationSummaryPDF':
        return 'Episode_Reconciliation_Report';
      case 'reconciliationDetailPDF':
        return 'Period_Balance_Detail'
      case 'fundingReportPDF':
        return 'Funding_Report'
      case 'remittance-wrapper':
        return `Remittance_Summary${id ? '-' + id : ''}`
      case 'invoice-wrapper':
        return `Invoice${id ? '-' + id : ''}`
      case 'invoice-cover-sheet':
        return `Cover_Sheet${id ? '-' + id : ''}`
      case 'ub04-wrapper':
        return `UB04${id ? '-' + id : ''}`
      default:
        return;
    }
  }

  /**
   * converts to PDF and attachs it to email with id
   */
  getAttachedPDF() {
    this.idPDF.forEach(async x => {
      const [elementId, id] = x.split('-id-');
      const fileName = this.getFileName(elementId, id);
      this.pdfConvertedFile = await this._pdfUtility.convertToPDF([elementId], fileName);
      this.pdfConvertedFile.id = uuid.v4();
      this.files.push(this.pdfConvertedFile);
    })
  }
}
