import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-episode-user-message',
  templateUrl: './episode-user-message.component.html',
  styleUrls: ['./episode-user-message.component.scss']
})
export class EpisodeUserMessageComponent implements OnInit {
  @Input() message;
  @Input() user;
  constructor() { }

  ngOnInit() {
  }

}
