<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center mt-4 action position-sticky">
    <div class="form-group input-group has-search mt-2 mb-2 w-25 w-xs-100">
      <span class="form-control-feedback left-0">
        <i class="icofont-search-1"></i>
      </span>
      <input
        type="text"
        [(ngModel)]="documentQuery.keyword"
        class="form-control"
        id="search"
        placeholder="Search"
        (ngModelChange)="change($event)" />
      <div class="input-group-append" *ngIf="documentQuery.keyword">
        <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
          <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
        </button>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-secondary"
      data-toggle="modal"
      data-target="#basicExample"
      *ngIf="user.roleCode !== 'MP'"
      (click)="uploadDocument()"
      mdbWavesEffect>
      <mdb-icon fas icon="upload"></mdb-icon> Upload
    </button>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table
          mdbTable
          [hover]="documentList.length > 0 ? true : false"
          small="true"
          class="table-mobile-view table-document">
          <thead id="scrollToTop">
            <tr>
              <th [class.active]="documentQuery.sortBy === 'name'" (click)="setOrder('name')">
                Name
                <span *ngIf="documentQuery.sortBy === 'name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="120"
                [class.active]="documentQuery.sortBy === 'id'"
                (click)="setOrder('id')">
                Created Date
                <span *ngIf="documentQuery.sortBy === 'id'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th width="200"></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading"
            [style.top]="documentList && documentList.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody>
            <tr
              *ngFor="
                let document of documentList
                  | paginate
                    : {
                        itemsPerPage: documentQuery.limit,
                        currentPage: documentQuery.page,
                        totalItems: totalCount
                      };
                let i = index
              "
              [ngClass]="{
                'alert alert-info shadow': editedRow && selected_row && document.id == selected_row
              }">
              <td *ngIf="selected_document !== document.id">
                <div
                  class="pointer-cursor"
                  [attr.contenteditable]="
                    user.roleCode !== 'MP' && utilityAccess.searchAccess('DM', 'isEditable', false)
                  "
                  [ngClass]="{
                    'content-editable':
                      user.roleCode !== 'MP' &&
                      utilityAccess.searchAccess('DM', 'isEditable', false)
                  }"
                  (click)="user.roleCode !== 'MP' ? editDisplayName(document) : ''"
                  mdbTooltip="{{ document.documentPath }}"
                  placement="left"
                  id="document{{ document.id }}">
                  {{ document.name }}
                </div>
              </td>
              <td *ngIf="selected_document && document.id == selected_document">
                <form [formGroup]="documentNameForm" class="form-inline align-items-start">
                  <div class="form-group">
                    <input
                      type="text"
                      style="width: 300px"
                      id="docName"
                      class="form-control"
                      formControlName="name"
                      mdbInput
                      [ngClass]="{
                        'is-invalid': nameSubmitted && documentNameForm.get('name').invalid
                      }" />
                  </div>

                  <button
                    class="btn btn-flat waves-effect m-0 pl-2 pr-2 skin-link"
                    mdbWavesEffect
                    type="submit"
                    (click)="updateName(document.id)">
                    <mdb-icon fas icon="save"></mdb-icon>
                  </button>
                  <button
                    class="btn btn-flat waves-effect m-0 pl-2 pr-2"
                    (click)="cancel()"
                    mdbWavesEffect
                    type="submit">
                    <mdb-icon fas icon="times"></mdb-icon>
                  </button>
                </form>
              </td>
              <td>{{ document.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}</td>
              <td>
                <button
                  class="z-depth-0 mt-0 mb-0 btn btn-sm btn-secondary"
                  type="button"
                  (click)="downloadDocument(document)"
                  mdbWavesEffect>
                  <i class="icofont-download"></i> <span class="d-none d-sm-inline"> Download</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="documentList && documentList.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <div
    appScrollToTop
    class="fixed-action-btn donotprint"
    style="bottom: 145px; right: 10px; display: none">
    <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
      <i class="fas fa-angle-up text-black-50"></i>
    </a>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.documents">
    <i class="far fa-3x fa-file-alt text-black-50"></i>
    <p>
      {{ usermode ? 'No Documents available' : 'Start adding Documents.' }}
    </p>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.searchedDocuments">
    <i class="far fa-3x fa-file-alt text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>
<!-- UPLOAD DOCUMENT MODAL -->
<div
  mdbModal
  #uploadDocumentModal="mdbModal"
  class="modal fade"
  id="modalLoginForm"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Upload Document</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="documentForm">
          <div class="form-group">
            <label for="name">File Display Name *</label>
            <input
              type="text"
              id="name"
              class="form-control"
              mdbInput
              formControlName="name"
              [ngClass]="{'is-invalid': submitted && documentForm.get('name').invalid}" />
          </div>
          <div class="form-group">
            <label for="documentPath">File/Video Reference URL *</label>
            <input
              type="text"
              id="documentPath"
              class="form-control"
              mdbInput
              formControlName="documentPath"
              [readonly]="
                documentForm.get('holistaMedia').value &&
                documentForm.get('holistaMedia').value.name
              "
              [ngClass]="{
                'is-invalid':
                  submitted &&
                  documentForm.get('documentPath').invalid &&
                  documentForm.get('holistaMedia').invalid
              }" />
          </div>
          <p class="text-center">OR</p>
          <div class="form-group">
            <mdb-file-upload
              #uploader
              (fileRemoved)="onFileRemove()"
              (fileAdded)="onFileAdd($event)"
              [disabled]="documentForm.get('documentPath').value"></mdb-file-upload>
          </div>
          <!-- <div class="form-group">
            <label for="FileName">File name</label>
            <input type="text" id="FileName" class="form-control" mdbInput formControlName="fileName"
              placeholder="Enter file name" [readonly]="documentForm.get('documentPath').value"
              [ngClass]="{'is-invalid': submitted && documentForm.get('fileName').invalid}">
          </div> -->
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitDocument()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
