import { Directive, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Actions from '../action';
import { ChatService } from '../services';
@Directive({
    selector: '[appPreviousChatUsers]'
})

export class PreviousChatUsersDirective {
    constructor(
        private _chatService: ChatService,
        private store: Store<{}>,
    ) { }

    @HostListener('scroll', ['$event'])
    async scrollToBottom(event: any) {
        // visible height + pixel scrolled >= total height 
        if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) || event.target.scrollHeight - (event.target.offsetHeight + event.target.scrollTop) < 1) {
            const users: any = await this._chatService.getPreviousConversation()
            if (users && users.length > 0) {
                this.store.dispatch(new Actions.ChatUsersLoad(true));
                setTimeout(() => {
                    this.store.dispatch(new Actions.GetAllChatUser(users));
                }, 200);
            } else {
                this.store.dispatch(new Actions.ChatUsersLoad(false));
            }
        }
    }
}