<div
  class="questionnaire p-2"
  [ngClass]="{'second-level': optionId}"
  *ngIf="taskQuestionnaires && taskQuestionnaires.qnrs">
  <div class="d-flex justify-content-start align-items-start">
    <div class="icon-block">
      <i class="icofont-clip-board icofont-2x skin-text-primary-dark"></i>
    </div>
    <div class="d-flex justify-content-between w-100 align-items-center">
      <span class="pl-2 pr-4">
        <p class="font-weight-400">
          {{ taskQuestionnaires?.qnrs?.name }}
        </p>
      </span>

      <span class="action-item position-relative">
        <i
          class="icofont-ui-edit icofont-sm mr-2"
          #actionBtn
          (click)="editTaskHandler(taskQuestionnaires)"></i>
        <i
          class="icofont-ui-delete icofont-sm mr-2"
          #actionBtn
          (click)="deleteTaskHandler(taskQuestionnaires)"></i
      ></span>
    </div>
  </div>
  <div class="list-content p-2" [hidden]="taskQuestionnaires && taskDragged">
    <div class="flex-row justify-content-start align-items-start">
      <div
        class="list-content p-2"
        [ngClass]="{'second-level': true}"
        *ngFor="let qnrQ of taskQuestionnaires.qnrs.qnrsQues | orderBy : 'id'">
        <div class="d-flex justify-content-start align-items-start">
          <div class="icon-block">
            <i class="icofont-question-circle icofont-2x skin-text-primary-dark"></i>
          </div>
          <div class="d-flex justify-content-between w-100 align-items-center">
            <span class="pl-2 pr-4">
              <p class="font-weight-400">
                {{ qnrQ.question?.question }}
              </p>
              <p *ngIf="qnrQ.question.questionHelp">
                {{ qnrQ.question?.questionHelp }}
              </p>
            </span>
            <!-- 
                        <span class="action-item position-relative d-none"><i class="icofont-ui-edit"
                                (click)="editTaskHandler(question)"></i></span> -->
          </div>
        </div>
        <div class="list-content-menu" [ngClass]="{'sub-level': optionId}">
          <div
            class="list-content-item"
            *ngFor="let option of qnrQ.question.questionOptions | orderBy : 'id'">
            <div
              class="d-flex justify-content-between align-items-start list-content-item-option pt-0 pb-0 mb-2">
              <p *ngIf="option.optionValue">
                <i
                  class="far fa-square mr-2"
                  *ngIf="qnrQ.question.questionTypes.code == 'CHECKBOX'"></i>
                <i
                  class="far fa-circle mr-2"
                  *ngIf="qnrQ.question.questionTypes.code == 'RADIO'"></i>
                {{ option.optionValue }}
              </p>
              <a
                class="add-action"
                #actionBtn
                (click)="addActionHandler(option.id, qnrQ.question.uuid)"
                *ngIf="qnrQ.status != 'COMPLETED'"
                >Add action</a
              >
            </div>
            <div
              class="list-content p-2"
              *ngIf="
                option.taskMessageId ||
                option.taskQuestionId ||
                option.taskTodoId ||
                option.taskQnrsId ||
                option.taskSignatureId
              ">
              <div class="p-2 flex-row justify-content-start align-items-start dependent-task">
                <app-task-todo
                  *ngIf="option.taskTodoId"
                  [optionId]="option.id"
                  [dependent_task]="dependentTask"
                  [taskTodoId]="option.taskTodoId"
                  (btns)="receiveBtns($event, 'todo')"
                  (editTask)="editTaskTodo($event)"
                  (deletedTask)="deleteTask($event)">
                </app-task-todo>
                <app-task-signature
                  *ngIf="option.taskSignatureId"
                  [optionId]="option.id"
                  [dependent_task]="dependentTask"
                  [taskSignatureId]="option.taskSignatureId"
                  (btns)="receiveBtns($event, 'signature')"
                  (editTask)="editTaskTodo($event)"
                  (deletedTask)="deleteTask($event)">
                </app-task-signature>
                <app-task-message
                  *ngIf="option.taskMessageId"
                  [optionId]="option.id"
                  [dependent_task]="dependentTask"
                  [taskMessageId]="option.taskMessageId"
                  (btns)="receiveBtns($event, 'message')"
                  (editTask)="editTaskMessage($event)"
                  (deletedTask)="deleteTask($event)">
                </app-task-message>
                <app-task-question
                  *ngIf="option.taskQuestionId"
                  [optionId]="option.id"
                  [dependent_task]="dependentTask"
                  [taskQuestionId]="option.taskQuestionId"
                  (btns)="receiveBtns($event, 'question')"
                  (editTask)="editTaskHandler($event)"
                  (addAction)="addActionHandler($event)"
                  (deletedTask)="deleteTask($event)">
                </app-task-question>
                <app-task-questionnaire
                  *ngIf="option.taskQnrsId"
                  [optionId]="option.id"
                  [dependent_task]="dependentTask"
                  [taskQnrsId]="option.taskQnrsId"
                  (btns)="receiveBtns($event, 'questionnaire')"
                  (editTask)="editTaskHandler($event)"
                  (addAction)="addActionHandler($event)"
                  (deletedTask)="deleteTask($event)">
                </app-task-questionnaire>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-milestone-topic-delete
  [deleteData]="taskToBeDelete"
  (deletedData)="deleteTask($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>
