<mdb-card
  *ngFor="let message of messageList; let i = index"
  class="z-depth-0 pointer-cursor border p-2 mb-3 message-preview"
  [class.skin-light]="type === INBOX && !message.isRead"
  [class.text-white]="
    type === INBOX
      ? selectedInboxMessageId === message.latestMessage.messageId
      : selectedOutboxMessageId === message.latestMessage.messageId
  "
  [class.active]="
    type === INBOX
      ? selectedInboxMessageId === message.latestMessage.messageId
      : selectedOutboxMessageId === message.latestMessage.messageId
  "
  (click)="
    onMessageSelected(message.latestMessage.messageId, message.latestMessage._id, message.isRead)
  ">
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <p
        [class.text-white]="
          type === INBOX
            ? selectedInboxMessageId === message.latestMessage.messageId
            : selectedOutboxMessageId === message.latestMessage.messageId
        ">
        <b
          >{{
            type === INBOX
              ? message?.latestMessage.sender.name
                ? message?.latestMessage.sender.name
                : (message?.latestMessage.sender.email.split('@')[0] | titlecase)
              : message?.latestMessage.receivers.to[0].name
              ? message?.latestMessage.receivers.to[0].name
              : (message?.latestMessage.receivers.to[0].email.split('@')[0] | titlecase)
          }}
        </b>
        <small
          *ngIf="message.latestMessage.receivers.to.length > 1"
          [class.text-white]="
            type === INBOX
              ? selectedInboxMessageId === message.latestMessage.messageId
              : selectedOutboxMessageId === message.latestMessage.messageId
          "
          >+{{ message.latestMessage.receivers.to.length - 1 }} more</small
        >
      </p>
      <span
        *ngIf="
          type === INBOX &&
          !message.isRead &&
          selectedInboxMessageId !== message.latestMessage.messageId
        "
        class="ml-1">
        <mdb-icon fas icon="circle" class="text-primary fa-xs"></mdb-icon>
      </span>
      <span
        *ngIf="
          message.status === BLOCKED ||
          message.status === DEFERRED ||
          message.status === DROPPED ||
          message.status === BOUNCE
        "
        ><i
          class="icofont-warning-alt text-warning ml-1"
          [mdbTooltip]="'Message has been ' + message.status + '.'"
          placement="right"></i>
      </span>
    </div>
    <p
      class="mb-2"
      [class.text-white]="
        type === INBOX
          ? selectedInboxMessageId === message.latestMessage.messageId
          : selectedOutboxMessageId === message.latestMessage.messageId
      ">
      <!-- <small *ngIf="message.threadCount > 1" class="mr-2">({{ message.threadCount }})</small> -->
      <small>{{
        message.latestMessage.extendedDate
          ? message.latestMessage.extendedDate
          : (message.latestMessage.createdAt | date: 'MMM d, y')
      }}</small>
    </p>
  </div>
  <div *ngIf="message.latestMessage.subject" class="mb-2">
    <small class="text-muted"
      ><b
        [class.text-white]="
          type === INBOX
            ? selectedInboxMessageId === message.latestMessage.messageId
            : selectedOutboxMessageId === message.latestMessage.messageId
        "
        >{{ message.latestMessage.subject }}</b
      ></small
    >
    <br />
  </div>
  <small [innerHTML]="message.latestMessage['body']"></small>
</mdb-card>
