<div mdbModal #deleteModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true, backdrop: true, keyboard:false}">
    <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
        <div class="modal-content rounded-0">
            <!--Body-->
            <div class="modal-body">
                <p class="mb-0 text-center" [innerHTML]="message">
                </p>
                <div class="p-0 mt-4 text-center">
                    <button class="btn btn-secondary waves-light" mdbWavesEffect (click)="triggerAction('yes')">
                        {{yes}}
                    </button>
                    <button class="btn btn-light waves-light" mdbWavesEffect (click)="triggerAction('no')">
                        {{no}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>