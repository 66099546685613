import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../action';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserService, MemberService, AuthService, ToasterService, NetworkService, ClientService, PlatformService, PurchaserService, ProviderService } from '../../services';
import { ACCESS_DENIED, ADD_SUCCESS, CLIENT_ADMINS, DELETE_SUCCESS, FETCH_USERS_FAILED, INVITATION_FORWARD_SUCCESS, MaskConstant, PROVIDER_ASSIGN_SUCCESS, RESET_PASSWORD_LINK, ROLECONSTANTS, SEND_EMAIL_FAILED, UPDATE_SUCCESS, USER_STATUS_ACTIVATED, USER_STATUS_DEACTIVATED, ValidEmail } from '../../constants';
import { finalize, startWith, switchMap, debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { Sorting, AccessType, Storage, ScrollTo, FormatPhoneNumber } from '../../utils';
import { CometChatUser, User } from '../../models';
import * as Actions from '../../action';
import { RestrictSpace, TrimFields, PhoneFormat } from '../../constants';
import { Subject, Observable, of, forkJoin } from 'rxjs';
import { REFERENCE_TYPES } from '../../constants/refernceType.constant';
import { HolistaUtils } from '../../utils/holista.utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  users = [];
  userId: any;
  usersBackup = [];
  supervisorList = [];
  filteredList = [];
  CLIENT_ADMINS = CLIENT_ADMINS;
  userForm: FormGroup;
  showSupervisor: boolean = false;
  roleList = [];
  mask: any[] = MaskConstant.PHONE;
  loading = false;
  submit_enabled: boolean = false;
  submitted: boolean = false;
  cometChatUser = new CometChatUser();
  edited_user_status: boolean;
  dualUser: boolean;
  masterHeadElements = ['DOB', 'Gender', 'Address'];
  // chatUserList: any = []
  loggedUser: any;
  userData: any = {};
  enableChat = false;
  disableReferenceCode: boolean = false;
  displayRolesList = [];
  userListType = 'all';
  viewUserList = [
    { value: 'all', label: 'All' },
    { value: 'assign', label: 'Assigned to me' },
  ];
  provider_search_text = new Subject();
  provider_results: Observable<any>;
  providerLocationList = [];
  searching: boolean = false;
  providerNetwork: '';
  assignedSupervisorId: any;
  totalCount = 0;
  referenceType: any;
  referenceCodes: any = [];
  searchModelChanged: Subject<string> = new Subject<string>();
  query: any = {
    limit: 10,
    page: 1,
    keyword: '',
    sortBy: 'createdAt',
    sortType: 'desc'
  };
  providerLoading = false;
  result = { searchedUsers: true, users: true };
  reverse: boolean = true;
  platforms: any = [];
  clients: any = [];
  networks: any = [];
  purchasers: any = [];
  isDualRoleAssignable = false;
  secondaryRoleList: any = [];
  filterForm: FormGroup;
  filterList: object[] = [];
  userName: string;

  @ViewChild('userModal', { static: true }) public userModal;
  @ViewChild('assignSupervisormodal', { static: true }) public assignSupervisormodal;
  @ViewChild('assignProvidermodal', { static: true }) public assignProvidermodal;
  @ViewChild('filterModal', { static: true }) public filterModal;

  constructor(
    private store: Store<{ bread_crumbs: any; chatUsers: any; enableChat: boolean }>,
    private router: Router,
    private userService: UserService,
    private providerService: ProviderService,
    private _formBuilder: FormBuilder,
    private utilitySorting: Sorting,
    public utilityAccess: AccessType,
    private memberService: MemberService,
    private authService: AuthService,
    private _storage: Storage,
    private _toastr: ToasterService,
    private _scrollTo: ScrollTo,
    private _formatPhone: FormatPhoneNumber,
    public holistaUtils: HolistaUtils,
    public clientService: ClientService,
    public platformService: PlatformService,
    private networkService: NetworkService,
    private purchaserService: PurchaserService
  ) {
    this.setUserForm();
    store.select('enableChat').subscribe(res => {
      this.enableChat = res;
    });
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
      this.query.keyword = keyword;
      this.search(1);
    });
    this.setFilterForm();
  }

  ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Users', path: '/users' }));
    this.loggedUser = this._storage.get('local', 'loggedInUser', 'user');
    this.provider_results = this.provider_search_text
      .pipe(
        startWith(''),
        debounceTime(300),
        switchMap((provider_search_text: string) => this.searchProvider(provider_search_text))
      );
    this.loading = true;
    this.query['roles-roleCode'] = 'MP',
      this.query['roles-roleCodeOperator'] = '!='
    this.query.keyword = this.query.keyword ? this.query.keyword.trim() : ''
    let queryParam: any = { limit: 0 }
    if (this.loggedUser.roleCode == 'CA') queryParam.code = 'CA'
    if (this.loggedUser.roleCode == 'SCA') queryParam.code = 'SCA'

    forkJoin([
      this.userService.getRoles(queryParam),
      this.platformService.getAll({ limit: 0 }),
      this.clientService.getClients({ limit: 0 }),
      this.networkService.getAll({ limit: 0 }),
      this.purchaserService.getAll({ limit: 0 })
    ]).subscribe(([roles, platforms, clients, networks, purchasers]: any) => {
      const filterData = this._storage.get('session', 'userFilterData');
      filterData && this.filterForm.setValue(filterData);
      if (roles.rows.length != 0) {
        roles.rows.map(x => {
          x['value'] = x.code;
          x['label'] = x.name;
        });
        this.roleList = this.utilitySorting.sortBy(roles.rows, 'name');
        this.displayRolesList = this.roleList.filter(role => role.isActive);
      }
      this.getFilterApplied();
      this.platforms = platforms.rows
      this.clients = clients.rows
      this.networks = networks.rows
      this.purchasers = purchasers.rows
    }, (error) => {
      console.log('Error', error)
    });
  }

  setUserForm() {
    this.userForm = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      lastName: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      email: ['', [Validators.required, Validators.pattern(ValidEmail)]],
      phone: ['', [Validators.required, Validators.pattern(PhoneFormat)]],
      isMobile: [false],
      referenceType: ['', Validators.required],
      referenceCode: ['', Validators.required],
      roleCode: ['', Validators.required],
      id: [null],
      supervisors: [[]],
      note: [''],
      triggerSMS: [false],
      inviteType: ['email'],
      secondaryRoleCode: ['']
    });
  }

  setFilterForm() {
    this.filterForm = this._formBuilder.group({
      roles: [null]
    })
  }

  async getUsers(query: any = {}, param?) {
    if (!param) {
      this.loading = true;
      this.result.searchedUsers = true;
      this.result.users = true;
    }
    if (!this.query.keyword) delete this.query.keyword
    let encodedQuery = new URLSearchParams(query).toString()
    await this.userService.getUsers(encodedQuery)
      .then((res: any) => {
        this.loading = false;
        if (res && res.count > 0) {
          if (!param) {
            this.totalCount = res.count;
            res.rows.map(user => {
              user.fullName = this.holistaUtils.getFullName(user)
              const reference = this[user.referenceType.toLowerCase() + 's'].find(reference => reference.code == user.referenceCode)
              user.reference = reference ? { name: reference.name, code: reference.code } : ''
              this.getRoleName(user)
              return user
            })
            this.users = [...res.rows];
            this.usersBackup = [...res.rows];
            if (this.supervisorList.length == 0 && (this.loggedUser.roleCode == 'SV' || this.loggedUser.roleCode == 'PA')) this.getUsers({ limit: 0, 'roles-roleCode': 'SV' }, 'getSupervisor')

          } else {
            this.supervisorList = this.utilitySorting.sortBy(res.rows, 'firstName');
          }
        } else {
          this.users = [];
          this.query.keyword && this.query.keyword.length > 0 || this.filterList.length ? (this.result.searchedUsers = false) : (this.result.users = false);
        }
      }), (error) => {
        this.loading = false;
        console.log("Error fetching Users List", error);
        this._toastr.displayError(FETCH_USERS_FAILED);
      };
    delete this.query['roles-roleCode']
    delete this.query['roles-roleCodeOperator']
  }

  getRoleName(user) {
    user.roleCode = user.roles.find(u => u.isActiveRole) ? user.roles.find(u => u.isActiveRole).roleCode : null
    user.secondaryRoleCode = user.roles.find(u => !u.isActiveRole) ? user.roles.find(u => !u.isActiveRole).roleCode : null
    user.primaryRoleName = this.roleList.find(role => role.code == user.roleCode) ? this.roleList.find(role => role.code == user.roleCode).name : 'N/A'
    user.secondaryRoleName = this.roleList.find(role => role.code === user.secondaryRoleCode) ? this.roleList.find(role => role.code == user.secondaryRoleCode).name : 'N/A'
    user.roleName = this.roleList.filter(list => user.roles.find(role => role.roleCode == list.code)).map(x => x.name.trim()).join(', ')
  }

  createUser() {
    if (this.utilityAccess.searchAccess('UM', 'isEditable')) {
      this.checkPhoneValue();
      this.userModal.show();
    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  editUser(user) {
    if (this.utilityAccess.searchAccess('UM', 'isEditable')) {
      this.edited_user_status = user.status;
      user.phone = this._formatPhone.phoneNumber(user.phone)
      user.triggerSMS = user.userSetting.triggerSMS
      this.displayRolesList = [{ label: user.primaryRoleName, value: user.roleCode }]
      if (user.roleCode === 'CC' || user.roleCode === 'ES') {
        this.isDualRoleAssignable = true
        if (user.roleCode === 'CC') this.secondaryRoleList = [{ label: 'Select Secondary Role', value: '' }, { label: 'Engagement Specialist', value: 'ES' }]
        else this.secondaryRoleList = [{ label: 'Select Secondary Role', value: '' }, { label: 'Care Cordinator', value: 'CC' }]
        this.userForm.controls.secondaryRoleCode.setValue(user.secondaryRoleCode)
      }
      this.userForm.patchValue(user);
      this.dualUser = user && user.memberUuid ? true : false;
      this.disableReferenceCode = ((this.userForm.get('id').value && this.loggedUser.roleCode !== 'PA') || this.userForm.value.roleCode === 'SV' || this.userForm.value.roleCode === 'PA' || this.dualUser) ? true : false;
      this.referenceCodes = []
      this.referenceType = { label: this.holistaUtils.toTitleCase(user.referenceType) }
      let references: any[] = this[user.referenceType.toLocaleLowerCase() + 's'].map(reference => {
        reference.label = reference.name
        reference.value = reference.code
        return reference
      })
      this.referenceCodes = references
      this.checkPhoneValue();
      this.userModal.show();
    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  closeModal() {
    this.userForm.reset();
    this.userModal.hide();
    this.setUserForm();
    this.submit_enabled = false;
    this.submitted = false;
    this.disableReferenceCode = false;
    this.referenceCodes = []
    this.referenceType = {}
    this.displayRolesList = JSON.parse(JSON.stringify(this.roleList.filter(role => role.isActive)));
    this.isDualRoleAssignable = false
  }

  submitUser() {
    this.submitted = true;
    if (this.userForm.valid) {
      this.submit_enabled = true;
      TrimFields.User.forEach(element => {
        this.userForm.value[element] = this.userForm.value[element] ? this.userForm.value[element].trim() : this.userForm.value[element];
      });
      if (!this.userForm.value.isMobile) this.userForm.controls.triggerSMS.setValue(false)
      if (this.userForm.value.id) this.updateUser();
      else this.saveUser();
    }
  }

  saveUser() {
    let user = Object.assign(new User(), this.userForm.value);
    user.phone = user.phone.replace(/\D/g, '')
    user.note = user.note ? user.note.replace(/[^a-zA-Z0-9_ ]/g, "") : null
    if (user.secondaryRoleCode) user.roles = [{ roleCode: user.roleCode, isPrimary: true }, { roleCode: user.secondaryRoleCode }]
    else user.roles = [{ roleCode: user.roleCode, isPrimary: true }]
    delete user.roleCode
    delete user.secondaryRoleCode
    delete user.id
    delete user.supervisors
    if (!user.note) delete user.note
    this.userService
      .saveUser(user)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          // this.mapCometChatUser(user, res, true);
          res.fullName = this.holistaUtils.getFullName(res)
          this.refreshUsers(res, 'saved');
        },
        error => {
          this.isDualRoleAssignable = this.isDualRoleAssignable ? this.isDualRoleAssignable : false
          console.log("Error Saving User", error);
          this._toastr.showError(error);
        }
      );
  }

  // mapCometChatUser(user, res, isUserCreate) {
  //   this.cometChatUser.name = user.firstname + ' ' + user.lastname;
  //   this.cometChatUser.email = user.email;
  //   this.cometChatUser.role = user.roleCode;
  //   this.cometChatUser.uid = isUserCreate ? res.data.id : user.id;

  //   if (isUserCreate) {
  //     this.cometChatUser.metadata = { isActive: true };
  //     this.chatService
  //       .userCreate(this.cometChatUser)
  //       .subscribe(comchat_user => {
  //       });
  //   } else {
  //     this.cometChatUser.metadata = {
  //       isActive: user.status ? user.status : this.edited_user_status
  //     };
  //     this.chatService
  //       .updateUser(this.cometChatUser)
  //       .subscribe(update_user => {
  //       });
  //   }
  // }
  updateUser() {
    let user = Object.assign(new User(), this.userForm.getRawValue());
    user.phone = user.phone.replace(/\D/g, '')
    user.note = user.note ? user.note.replace(/[^a-zA-Z0-9_ ]/g, "") : null
    if (user.secondaryRoleCode) user.roles = [{ roleCode: user.roleCode, isPrimary: true }, { roleCode: user.secondaryRoleCode }]
    else user.roles = [{ roleCode: user.roleCode, isPrimary: true }]
    delete user.roleCode
    delete user.secondaryRoleCode
    this.userService
      .updateUser(user)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(res => {
        res.fullName = this.holistaUtils.getFullName(res)
        const reference = this[res.referenceType.toLowerCase() + 's'].find(reference => reference.code == res.referenceCode)
        res.reference = reference ? { name: reference.name, code: reference.code } : ''
        this.refreshUsers(res, 'updated');
      },
        error => {
          this.isDualRoleAssignable = this.isDualRoleAssignable ? this.isDualRoleAssignable : false
          console.log("Error Updating User", error);
          this._toastr.showError(error);
        }
      );
  }

  async refreshUsers(response, action) {
    if (response.message) {
      this._toastr.displayWarning(response.message);
    } else {
      let user = response
      this.query['roles-roleCode'] = 'MP',
        this.query['roles-roleCodeOperator'] = '!='
      this.query.keyword = this.query.keyword ? this.query.keyword.trim() : ''
      if (this.users && this.users.length > 0) {
        const index = this.users.findIndex(x => x.id === user.id);
        if (index > -1) {
          this.getRoleName(user)
          if (user.supervisor) {
            if (this.supervisorList && this.supervisorList.length != 0) user.supervisor.user = this.supervisorList.find(x => x.id == user.supervisor.supervisorId)
            else {
              await this.userService.getUserById(user.supervisor.supervisorId).then((res: any) => {
                user.supervisor.user = res
              })
            }
          }
          this.users[index] = user;
        } else {
          this.query.page = 1
          this.getUsers(this.query)
        }
      } else {
        this.query.page = 1
        this.getUsers(this.query)
      }
      action === 'updated' ? this._toastr.displaySuccess(UPDATE_SUCCESS) : this._toastr.displaySuccess(ADD_SUCCESS);
    }
    this.closeModal();
  }

  changeUserStatus(id, status) {
    status ? (status = 'active') : (status = 'inactive');
    this.userService.updateStatus(id, status).subscribe(res => {
      if (res) {
        if (res.message) {
          let index = this.users.findIndex(x => x.id == id);
          let index2 = this.usersBackup.findIndex(y => y.id == id);
          if (index > -1 && index2 > -1)
            this.users[index] = { ...this.usersBackup[index2] };
          this._toastr.displayError(res.message);

        }
        else {
          if (status == 'active') {
            this._toastr.displaySuccess(USER_STATUS_ACTIVATED);
          }
          else {
            this._toastr.displaySuccess(USER_STATUS_DEACTIVATED);
          }
        }
      }
    }, (error) => {
      console.log("Error", error);
      this._toastr.showError(error);
    });
  }

  resendInvitation(user) {
    if (this.utilityAccess.searchAccess('UM', 'isEditable')) {
      let body = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id,
        memberUuid: user.memberUuid,
        userId: user.id,
        inviteType: 'email'
      };
      this.userService
        .resendInvitation(body)
        .pipe(
          finalize(() => {
            this.submit_enabled = false;
          })
        )
        .subscribe(
          res => {
            this._toastr.displaySuccess(INVITATION_FORWARD_SUCCESS);
          },
          error => {
            console.log("Error Sending Invitation", error);
            this._toastr.showError(error);
          }
        );
    } else this._toastr.displayWarning(ACCESS_DENIED);

  }

  userCollapsed(user, i) {
    if (user.memberUuid && user.roleCode != 'PR') {
      if (!user.collapsed) {
        this.memberService
          .getMemberById(user.memberUuid)
          .pipe(finalize(() => { }))
          .subscribe(res => {
            this.users.map(x => {
              if (x.memberUuid == user.memberUuid) {
                x.dob = res.dob;
                x.gender = res.gender;
                x.addressline1 = res.addressline1;
                x.addressline2 = res.addressline2;
                x.city = res.city;
                x.state = res.state;
                x.zip = res.zip;
              }
            });
          });
      }
      user.collapsed = !user.collapsed;
    } else if (user.roleCode == 'PR') {
      if (user.memberUuid) {
        if (!user.collapsed) {
          this.memberService
            .getMemberById(user.memberUuid)
            .pipe(finalize(() => { }))
            .subscribe(res => {
              this.users.map(x => {
                if (x.memberUuid == user.memberUuid) {
                  x.dob = res.dob;
                  x.gender = res.gender;
                  x.addressline1 = res.addressline1;
                  x.addressline2 = res.addressline2;
                  x.city = res.city;
                  x.state = res.state;
                  x.zip = res.zip;
                }
              });
            });
        }
      }

      if (!user.collapsed) {
        if (!this.users[i].providers) {
          this.userService
            .getProvidersByUserId(user.id)
            .pipe(finalize(() => { }))
            .subscribe(res => {
              this.users.map(x => {
                if (x.id == user.id) {
                  if (Object.keys(res).length == 0)
                    x.providers = [];
                  else
                    x.providers = res;
                }
              })
            });
        }
      }
      user.collapsed = !user.collapsed;
    }
    else this.editUser(user);
  }

  resetPassword(user) {
    if (this.utilityAccess.searchAccess('UM', 'isEditable')) {
      let body = {
        email: user.email,
        resetPassword: true
      };
      this.authService.forgotPassword(body).subscribe(
        (res: any) => {
          if (res.success) {
            this._toastr.displaySuccess(RESET_PASSWORD_LINK);
          } else {
            this._toastr.displayError(SEND_EMAIL_FAILED);
          }
        },
        error => {
          console.log("Error Reseting Password", error);
          this._toastr.showError(error);
        }
      );
    } else this._toastr.displayWarning(ACCESS_DENIED);
  }

  checkPhoneValue() {
    if (this.userForm.controls.phone.value && this.userForm.controls.phone.value !== '') this.userForm.controls.isMobile.enable();
    else {
      this.userForm.controls.isMobile.disable();
      this.userForm.controls.isMobile.setValue(false);
    }
  }

  openChat(userId) {
    let userChat = { uid: null };
    userChat.uid = userId;
    this.store.dispatch(new Actions.GetUser(userChat));
  }

  filterRefenceType(event: any) {
    this.referenceType = {}
    this.referenceCodes = []
    if (event.value === 'CC' || event.value === 'ES') {
      this.isDualRoleAssignable = true
      if (event.value === 'CC') this.secondaryRoleList = [{ label: 'Select Secondary Role', value: '' }, { label: 'Engagement Specialist', value: 'ES' }]
      else this.secondaryRoleList = [{ label: 'Select Secondary Role', value: '' }, { label: 'Care Cordinator', value: 'CC' }]
    }
    else this.isDualRoleAssignable = false
    this.userForm.controls.referenceType.setValue('')
    this.userForm.controls.referenceCode.setValue('')
    const referenceType = REFERENCE_TYPES.find(x => x.role.includes(event.code))
    if (referenceType) {
      this.referenceType = referenceType
      let references: any[] = this[referenceType.value.toLocaleLowerCase() + 's']
      if (CLIENT_ADMINS.includes(this.loggedUser.roleCode)) {
        references = references.filter(x => x.code == this.loggedUser.referenceCode)
      }
      this.referenceCodes = references.map(x => {
        x.label = x.name
        x.value = x.code
        return x
      })
      this.userForm.controls.referenceType.setValue(referenceType.value)
    }
  }

  changeSupervisor(supervisor) {
    this.assignedSupervisorId = supervisor.id
  }

  assignSupervisor(userId, supervisorID) {
    const body = {
      supervisorId: supervisorID
    }
    this.userService.assignSupervisor(userId, body)
      .pipe(finalize(() => { this.submit_enabled = false }))
      .subscribe(res => {
        let index = this.users.findIndex(x => x.id === userId);
        if (index > -1) {
          if (!this.users[index].supervisor) {
            const supervisor = { user: res }
            this.users[index].supervisor = supervisor
          } else this.users[index].supervisor.user = res;
          this._toastr.displaySuccess(UPDATE_SUCCESS);
        }
        this.assignSupervisormodal.hide();
      }), (error) => {
        console.log("Error", error);
        this._toastr.showError(error);
      };
  }

  selectedUserList(event?) {
    if (event) this.userListType = event.value;
    this.query['roles-roleCodeOperator'] = this.filterList.length ? '' : '!=';
    if (this.userListType === 'all') {
      this.query['roles-roleCode'] = this.filterList.length ? this.filterForm.value['roles'] : 'MP',
        this.query.keyword = this.query.keyword ? this.query.keyword.trim() : ''
      this.getUsers(this.query);
    }
    if (this.userListType === 'assign') {
      this.query['roles-roleCode'] = this.filterList.length ? this.filterForm.value['roles'] : ['MP', 'SV']
      this.query.page = 1
      this.getAssignUsers(this.loggedUser.id)
    }
  }

  getAssignUsers(supervisorId) {
    this.query.keyword = this.query.keyword ? this.query.keyword.trim() : ''
    if (!this.query.keyword) delete this.query.keyword
    this.loading = true
    this.result.searchedUsers = true;
    this.result.users = true;
    let encodedQuery = new URLSearchParams(this.query).toString()
    this.userService.getAssignUsers(supervisorId, encodedQuery)
      .pipe(finalize(() => { this.loading = false }))
      .subscribe(res => {
        this.users = []
        this.totalCount = res.count
        res.rows.map(user => {
          user.fullName = this.holistaUtils.getFullName(user)
          const reference = this[user.referenceType.toLowerCase() + 's'].find(reference => reference.code == user.referenceCode)
          user.reference = reference ? { name: reference.name, code: reference.code } : ''
          this.getRoleName(user)
          return user
        })
        this.users = res.rows
        if (res.count === 0) this.query.keyword && this.query.keyword.length > 0 || this.filterList.length ? (this.result.searchedUsers = false) : (this.result.users = false);
      }, (error) => {
        console.log('Error', error)
      })
  }

  openRemoveSupervisorModal(user) {
    this.userData = user;
  }

  receiveRemoveConfirmation(event) {
    if (event.value) {
      const userId = event.user.supervisors.find(x => x.userId == this.loggedUser.id).userId;
      this.assignSupervisor(event.user.id, userId);
    }
    this.userData = null;
  }

  async showSupervisorModal(user) {
    this.userId = user.id;
    this.assignedSupervisorId = null
    if (!this.supervisorList || this.supervisorList.length == 0) await this.getUsers({ limit: 0, 'roles-roleCode': 'SV' }, 'getSupervisor')
    if (user.supervisor) this.assignedSupervisorId = this.supervisorList.find(s => user.supervisor.user.id == s.id).id
    this.assignSupervisormodal.show();
  }

  saveSupervisor() {
    this.submit_enabled = true;
    this.assignSupervisor(this.userId, this.assignedSupervisorId);
  }

  closeAssignSupervisorModal() {
    this.assignSupervisormodal.hide();
    this.assignedSupervisorId = null
  }

  scrollToTop() {
    this._scrollTo.ScrollTo('scrollToTop', 'center', 'smooth')
  }

  supervisorExist(user) {
    return user.supervisors && user.supervisors.findIndex(x => x.userId == this.loggedUser.id) > -1;
  }

  showProviderModal(user: any) {
    if (this.utilityAccess.searchAccess('UM', 'isEditable')) {
      this.providerLoading = true;
      this.provider_search_text.next('');
      this.providerLocationList = [];
      this.userId = user.id;
      this.providerNetwork = user.referenceCode
      this.userService
        .getProvidersByUserId(this.userId)
        .pipe(finalize(() => { this.providerLoading = false }))
        .subscribe(res => {
          if (res.length > 0) {
            this.users.map(x => {
              if (x.id == this.userId) {
                x.providers = [];
                x.providers = res;
              }
            })
            this.providerLocationList = res;
          } else {
            this.providerLocationList = [];
          }
          this.assignProvidermodal.show();
        }, (error) => {
          console.log('Error', error)
        });
    }
    else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  closeAssignProviderModal() {
    this.providerLocationList = [];
    this.assignProvidermodal.hide();
    this.providerNetwork = ''
  }

  searchProvider(searchText: string) {
    if (searchText.length > 2) {
      this.searching = true;
      return this.providerService.searchVitafyProvider({ keyword: searchText, network: this.providerNetwork, entityCode: 2 })
        .pipe(finalize(() => this.searching = false),
          debounceTime(250),
          map((items: any) => {
            return items.providerList
          })
        );
    } else {
      return of([]);
    }
  }

  removeProvider(provider) {
    if (provider) {
      this.userService.removeProvider(provider)
        .subscribe((response: any) => {
          if (response) {
            let index = this.providerLocationList.findIndex(x => x.id == response.id);
            if (index >= 0) {
              this.providerLocationList.splice(index, 1);
              this._toastr.displaySuccess(DELETE_SUCCESS);
            }
          }
        }, (error) => {
          console.log("Error deleting Provider", error);
          this._toastr.showError(error);
        })
    }
  }

  onProviderDisplayValue = (provider?): string | undefined => {
    return provider.displayName;
  };

  onItemSelect(e) {
    let obj = {};
    let provider;
    provider = this.providerLocationList.find(x => x.providerId == e.text.providerId);
    if (!provider) {
      const body = {
        userId: this.userId,
        providerId: e.text.providerId,
        taxId: e.text.taxId,
        npi: e.text.npi,
        network: this.providerNetwork
      }
      this.userService.saveToProviderAccess(body)
        .pipe(finalize(() => {
        }))
        .subscribe(
          res => {
            obj = {
              id: res.data.id,
              userId: res.data.userId.toString(),
              providerId: res.data.providerId,
              npi: res.data.npi,
              taxId: res.data.taxId,
              providerType: e.text.providerType,
              businessName: e.text.businessName,
              displayName: e.text.displayName,
              addressline1: e.text.practiceAddress.addressline1,
              city: e.text.practiceAddress.city,
              state: e.text.practiceAddress.state,
              zip: e.text.practiceAddress.zip,
              imageUrl: e.text.imageUrl
            }
            this.providerLocationList.push(obj);
            let idx = this.users.findIndex(x => x.id == this.userId);
            this.users[idx].providers = this.providerLocationList;
            this._toastr.displaySuccess(PROVIDER_ASSIGN_SUCCESS);
          },
          error => {
            console.log('Error', error);
            this._toastr.showError(error)
          }
        );
    }
    this.provider_search_text.next('');
  }

  pageChanged(event) {
    this.query.page = event;
    this.search(this.query.page);
  }

  search(page) {
    this.query['roles-roleCodeOperator'] = this.filterList.length ? '' : '!=';
    if (this.userListType == 'assign' && this.loggedUser.roleCode == 'SV') {
      this.query.page = page;
      this.query['roles-roleCode'] = this.filterList.length ? this.filterForm.value['roles'] : ['MP', 'SV'],
        this.getAssignUsers(this.loggedUser.id)
    } else {
      this.query.page = page;
      this.query['roles-roleCode'] = this.filterList.length ? this.filterForm.value['roles'] : 'MP',
        this.query['roles-roleCodeOperator'] = this.filterList.length ? '' : '!=';
      this.query.keyword = this.query.keyword ? this.query.keyword.trim() : '';
      this.getUsers(this.query);
    }
  }

  change(text) {
    this.searchModelChanged.next(text);
  }

  setOrder(value: string) {
    if (this.query.sortBy === value) {
      this.reverse = !this.reverse;
      this.query.sortType = this.query.sortType === "desc" ? "asc" : "desc";
    }
    else {
      this.reverse = true;
      this.query.sortType = "desc";
    }
    this.query.sortBy = value;
    this.query['roles-roleCode'] = 'MP',
      this.query['roles-roleCodeOperator'] = '!='
    this.query.keyword = this.query.keyword ? this.query.keyword.trim() : ''
    this.getUsers(this.query);
  }

  removeSearchKeyword() {
    this.query.keyword = ''
    this.change('')
  }

  OpenFilterModal() {
    const filterData = this._storage.get('session', 'userFilterData');
    this.filterForm.reset();
    this.filterList.length && this.filterForm.patchValue(filterData);
    this.filterModal.show();
  }

  closeFilterModal() {
    this.filterModal.hide();
  }

  getUserRole(value: string) {
    const role = this.displayRolesList.find(role => role.code === value);
    return role.name;
  }

  getFilterApplied() {
    const filterData = this.filterForm.value;
    this.filterList.length = 0;
    Object.keys(filterData).forEach(key => {
      if (filterData[key]) {
        key === 'roles' && filterData[key].forEach((val: string) => this.updateFilterApplied(val, this.getUserRole(val), key, 'add', 'Role'));
        this._storage.set('session', 'userFilterData', this.filterForm.value);
        this.search(1);
      } else {
        this.search(1);
      }
    })
  }

  updateFilterApplied(value: string, label: string, key: string, action: string, field?: string,) {
    const valueIndex = this.filterList.findIndex(x => x['value'] === value && x['label'] === label);
    if (action === 'add') {
      const data = { key, field, value, label };
      this.filterList.push(data);
    }
    if (action === 'remove') {
      const index = this.filterForm.value[key].findIndex(x => x === value);
      this.filterForm.value[key].splice(index, 1);
      this.filterList.splice(valueIndex, 1);
    };
  }

  submitFilterUsers() {
    this.loading = true;
    this.getFilterApplied();
    this.filterModal.hide();
  }

  removeFilter(filter) {
    const { value, label } = filter;
    const filterData = this.filterForm.value;
    Object.keys(filterData).forEach(key => {
      this.updateFilterApplied(value, label, key, 'remove');
    })
    this._storage.set('session', 'userFilterData', this.filterForm.value);
    this.loading = true;
    this.search(1);
  }

  resetFilter() {
    this.filterList.length = 0;
    this.filterForm.reset();
    this._storage.set('session', 'userFilterData', this.filterForm.value);
    this.search(1);
  }

  managePermissions(user: any) {
    this.router.navigate(
      ['/rolepermission/add-edit'],
      { queryParams: { userId: user.id, userName: user.fullName } })
  }

  viewPermissions(user: any) {
    this.userId = user.id;
    this.userName = user.fullName;
  }

  // resetUserId() {
  //   this.userId = null
  // }
}
