import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs/operators';
import { HttpClientService } from './http-client.service';
@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private http: HttpClientService,
  ) { }

  saveDocument(document) {
    let formData = new FormData();
    if (document.holistaMedia && document.holistaMedia.name) {
      formData.append('holistaMedia', document.holistaMedia);
    }
    // formData.append('fileName', document.fileName);
    formData.append('displayName', document.name);
    formData.append('documentPath', document.documentPath ? document.documentPath : '');
    return this.http.post(`${APICONSTANTS.DOCUMENT}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getDocument(queryParam?) {
    return this.http.get(`${APICONSTANTS.DOCUMENT}`, queryParam).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  downloadDocuments(id) {
    return this.http.get(`${APICONSTANTS.DOCUMENT}/download/${id}`
    ).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateDocumentName(id, name) {
    return this.http.put(`${APICONSTANTS.DOCUMENT}/${id}`, { name: name }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  // getDocumentsSearchKeyword(keyword, page, limit) {
  //   let headers = this.createHeader();
  //   return this.http.get(`${API_BASE_URL}${APICONSTANTS.DOCUMENT}/search/${keyword}?page=${page}&limit=${limit}`, {
  //     headers: headers
  //   }).pipe(
  //     map((res: any) => {
  //       return res.data;
  //     })
  //   );
  // }
}
