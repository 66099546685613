import { Subject, Observable, of } from 'rxjs';
export class BundleCost {
  id: number;
  uuid: string = '';
  bundleUuid: string = '';
  marketCode: string = '';
  networkCode: string = '';
  totalCost: number = 0;
  costComponents: CostComponent[] = [];
  bundle_search_text = new Subject();
  fundingRequests: FundingRequestCost[] = [];
  totalFundingRequestCost: number = 0;
  bundleCode: string = ''
}
export class CostComponent {
  id: number;
  bundleUuid: string;
  bundleComponentUuid: string;
  bundleCostUuid: string;
  cost: number = 0;
  isActive: boolean = true
}

export class FundingRequestCost {
  id: number = 0;
  uuid: string;
  cost: number = 0;
  fundingRequestUuid: string;
  bundleCostUuid: string;
  isActive: boolean = true;
  sequence: number
}