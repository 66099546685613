import { Injectable } from "@angular/core";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable()

export class PDFUtility {
    async convertToPDF(elementIDs, fileName, isDownload = false, isScalable = false) {
        fileName = `${fileName}.pdf`;
        const doc = new jsPDF('p', 'mm', 'a4');
        const totalPages = elementIDs.length;
        for (let i = 0; i < elementIDs.length; i++) {
            // Get the DOM element using its ID
            const element = document.getElementById(elementIDs[i]);
            // Convert the DOM element to a canvas using html2canvas library,
            // ensuring the scrollY is adjusted to the current window position
            const canvas = await html2canvas(element, {
                scrollY: -window.scrollY,
                ...(isScalable && { scale: 2 })
            });
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 210;
            // Calculate the corresponding height based on the aspect ratio
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let position = 0;
            let remainingHeight = imgHeight;

            // Divide the image into multiple pages if it exceeds the available height
            while (remainingHeight > 0) {
                // Calculate the page height based on the remaining height and the internal page size of the PDF
                const pageHeight = Math.min(remainingHeight, doc.internal.pageSize.getHeight());
                // Add the image to the current page of the PDF at the specified position
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                // Update the remaining height and position variables
                remainingHeight -= pageHeight;
                position -= pageHeight;
                // Add a new page if there is remaining height or more elements to be converted
                if (remainingHeight > 0 || i < totalPages - 1) {
                    doc.addPage();
                }
            }
        }
        if (isDownload) {
            doc.save(fileName);
        } else {
            const pdfConvertedFile = new File([doc.output('blob')], fileName);
            return pdfConvertedFile;
        }
    }
}