import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PROJECTED_EPISODE_PROFIT_REPORT_DATA, REPORT_DATES } from '../../../constants';
import * as moment from 'moment';
import { ClientService, EpisodeService, MarketService, NetworkService, ReportService } from '../../../services';
import { HolistaUtils, ReportUtility } from '../../../utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-projected-episode-profit',
  templateUrl: './projected-episode-profit.component.html',
  styleUrls: ['./projected-episode-profit.component.scss']
})
export class ProjectedEpisodeProfitComponent implements OnInit {

  projectedEpisodeProfitForm: FormGroup
  expandAdvanceFiltering: boolean = true;
  reportDateTypeList: any[] = REPORT_DATES;
  isPreSelectedFilter: boolean = false;
  episodeList: any[] = [];
  networkList: any[] = [];
  clientList: any[] = [];
  marketList: any[] = [];
  isLoading = {
    clientList: false,
    episodeList: false,
    marketList: false,
    projectedEpisodeProfitReport: false,
    networkList: false
  };
  report: any;
  showReport: boolean = false;
  payload = {};
  summary: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _episodeService: EpisodeService,
    private _holistaUtils: HolistaUtils,
    private _networkService: NetworkService,
    private _clientService: ClientService,
    private _marketService: MarketService,
    private _reportService: ReportService,
    private _reportUtility: ReportUtility
  ) {
    this.setProjectedEpisodeProfitForm();
  }

  ngOnInit(): void {
    this.getNetworks();
    this.getClients();
    this.getEpisodes();
    this.getMarkets();
  }

  setProjectedEpisodeProfitForm() {
    this.projectedEpisodeProfitForm = this._formBuilder.group({
      reportDateType: [null, Validators.required],
      networkCode: [null],
      clientCode: [null],
      episodeName: [null],
      marketCode: [null],
      startDate: [null],
      endDate: [null],
    })
  };

  getEpisodes(params?) {
    this.isLoading.episodeList = true;
    this._episodeService.getUniqueEpisodeTypes(params)
      .pipe(finalize(() => { this.isLoading.episodeList = false; }))
      .subscribe((response: any) => {
        response = response.map((episode) => {
          const name = episode.name.trim();
          return {
            name,
            label: name,
            value: name.replaceAll(',', '')
          };
        });
        const episodeNames = response.map(episode => episode['name']);
        const filteredEpisodeList = response.filter((episode, index: number) => !episodeNames.includes(episode['name'], index + 1))
          .map((episode: any) => ({ ...episode, name: episode.name.toLowerCase() }));
        this.episodeList = this._holistaUtils.getUniqueList(filteredEpisodeList, 'name');
      },
        error => {
          console.log('error getting episode types', error);
        })
  }

  onNetworkChange() {
    const networkCode = this.projectedEpisodeProfitForm.get('networkCode').value.join(',');
    this.payload = { network_code: networkCode };
    this.projectedEpisodeProfitForm.controls['marketCode'].setValue(null);
    this.projectedEpisodeProfitForm.controls['clientCode'].setValue(null);
    this.getMarkets({ networkCode });
    this.getClients({ networkCode });

    if (this.projectedEpisodeProfitForm.get('networkCode').value.length) {
      this.getEpisodes(this.payload);
      return;
    };
    this.getEpisodes();
  }

  onMarketChange() {
    this.projectedEpisodeProfitForm.controls['episodeName'].setValue(null);
    if (this.projectedEpisodeProfitForm.get('marketCode').value.length) {
      this.payload = { ...this.payload, market_code: this.projectedEpisodeProfitForm.get('marketCode').value.join(',') }
      this.getEpisodes(this.payload);
      return;
    };
    delete this.payload['market_code'];
    this.getEpisodes(this.payload);
  }

  onClientChange() {
    this.projectedEpisodeProfitForm.controls['episodeName'].setValue(null);
    if (this.projectedEpisodeProfitForm.get('clientCode').value.length) {
      this.payload = { ...this.payload, clientCode: this.projectedEpisodeProfitForm.get('clientCode').value.join(',') };
      this.getEpisodes(this.payload);
      return;
    };
    delete this.payload['clientCode'];
    this.getEpisodes(this.payload);
  }

  getMarkets(params?: any) {
    this.isLoading.marketList = true;
    this._marketService.getAll({ ...params, limit: 0 })
      .pipe(finalize(() => { this.isLoading.marketList = false; }))
      .subscribe((response: any) => {
        this.marketList = response.rows.map(({ name, code }) => ({ label: `${name} - ${code}`, value: code }));
      },
        error => {
          console.log('error getting market list', error);
        }
      );
  }

  getClients(params?: any) {
    this.isLoading.clientList = true;
    this._clientService.getClients({ ...params, limit: 0 })
      .pipe(finalize(() => { this.isLoading.clientList = false; }))
      .subscribe(response => {
        if (response.count) {
          this.clientList = response.rows.map(({ name, code }) => ({ label: name, value: code }))
        }
      },
        error => {
          console.log('error getting clients', error);
        })
  }

  getNetworks() {
    this.isLoading.networkList = true;
    this._networkService
      .getAll({ limit: 0, fields: 'code,name' })
      .pipe(
        finalize(() => { this.isLoading.networkList = false; })
      )
      .subscribe((response) => {
        if (response.count) {
          this.networkList = response.rows.map(({ name, code }) => ({ label: name, value: code }));
        };
      },
        error => {
          console.log('error getting networks', error);
        });
  }

  onReportDateTypeSelected() {
    if (this.projectedEpisodeProfitForm.value.reportDateType) {
      switch (this.projectedEpisodeProfitForm.value.reportDateType) {
        case "thisMonth":
          this.projectedEpisodeProfitForm.controls['startDate'].setValue(new Date(moment().startOf('month').format()));
          this.projectedEpisodeProfitForm.controls['endDate'].setValue(new Date(moment().endOf('month').format()));
          this.isPreSelectedFilter = true;
          break;
        case "lastMonth":
          this.projectedEpisodeProfitForm.controls['startDate'].setValue(new Date(moment().startOf('month').subtract(1, 'months').format()));
          this.projectedEpisodeProfitForm.controls['endDate'].setValue(new Date(moment().startOf('month').subtract(1, 'months').endOf('month').format()));
          this.isPreSelectedFilter = true;
          break;
        case "ytd":
          this.projectedEpisodeProfitForm.controls['startDate'].setValue(new Date(moment().startOf('year').format()));
          this.projectedEpisodeProfitForm.controls['endDate'].setValue(new Date(moment().format()));
          this.isPreSelectedFilter = true;
          break;
        case "lastYear":
          this.projectedEpisodeProfitForm.controls['startDate'].setValue(new Date(moment().startOf('year').subtract(12, 'months').format()));
          this.projectedEpisodeProfitForm.controls['endDate'].setValue(new Date(moment().endOf('year').subtract(12, 'months').format()));
          this.isPreSelectedFilter = true;
          break;
        case "lastQuarter":
          let currentQuaterNumber = moment().quarter();
          if (currentQuaterNumber === 1) { //if 1st quater
            this.projectedEpisodeProfitForm.controls['startDate'].setValue(new Date(moment().startOf('year').subtract(3, 'months').format()));
            this.projectedEpisodeProfitForm.controls['endDate'].setValue(new Date(moment().startOf('year').subtract(1, 'day').format()));
          } else {
            this.projectedEpisodeProfitForm.controls['startDate'].setValue(new Date(moment().subtract(1, 'quarter').startOf('quarter').format()));
            this.projectedEpisodeProfitForm.controls['endDate'].setValue(new Date(moment().subtract(1, 'quarter').endOf('quarter').format()));
          }
          this.isPreSelectedFilter = true;
          break;
        case "thisQuarter":
          this.projectedEpisodeProfitForm.controls['startDate'].setValue(new Date(moment().startOf('quarter').format()));
          this.projectedEpisodeProfitForm.controls['endDate'].setValue(new Date(moment().endOf('quarter').format()));
          this.isPreSelectedFilter = true;
          break;
        case "dateRange":
          this.projectedEpisodeProfitForm.controls.startDate.setValidators([Validators.required]);
          this.projectedEpisodeProfitForm.controls.startDate.updateValueAndValidity();
          this.projectedEpisodeProfitForm.controls.endDate.setValidators([Validators.required]);
          this.projectedEpisodeProfitForm.controls.endDate.updateValueAndValidity();
          if (this.isPreSelectedFilter) {
            try {
              this.projectedEpisodeProfitForm.controls['startDate'].setValue(null);
              this.projectedEpisodeProfitForm.controls['endDate'].setValue(null);
              this.projectedEpisodeProfitForm.controls['startDate'].enable();
              this.projectedEpisodeProfitForm.controls['endDate'].enable();
            } catch (error) {
              try {
                this.projectedEpisodeProfitForm.controls['startDate'].enable();
                this.projectedEpisodeProfitForm.controls['endDate'].enable();
              } catch (error) {
                this.projectedEpisodeProfitForm.controls['endDate'].enable();
              }
            }
          }
          this.isPreSelectedFilter = false;
          break;
        default:
          break;
      }
    }
  };

  toggleCard() {
    this.expandAdvanceFiltering = !this.expandAdvanceFiltering;
  }

  onResetFilters() {
    this.projectedEpisodeProfitForm.reset();
    this.projectedEpisodeProfitForm.controls['startDate'].enable();
    this.projectedEpisodeProfitForm.controls['endDate'].enable();
    this.showReport = false;
  }

  onDateChange(event, type: string) {
    this.projectedEpisodeProfitForm.controls[type].setValue(event.actualDateFormatted);
  }

  onGenerateProjectedEpisodeProfitReport() {
    this.isLoading.projectedEpisodeProfitReport = true;
    const params = this._holistaUtils.getFormattedValues(this.projectedEpisodeProfitForm.value);
    this._reportService.getProjectedEpisodeProfitReport(params)
      .pipe(finalize(() => { this.isLoading.projectedEpisodeProfitReport = false; }))
      .subscribe(response => {
        if (response.success) {
          const data = response.data;
          this.summary = response.data.summary;
          const { TABLE_HEADERS, EXCEL_NAME, TABLE_TITLE, SUMMARY_HEADERS } = PROJECTED_EPISODE_PROFIT_REPORT_DATA;
          this.report = {
            data: data.projectedReports,
            tableHeaders: TABLE_HEADERS,
            summaryData: {
              headers: SUMMARY_HEADERS.map(header => header.label),
              data: SUMMARY_HEADERS.map(header => this._reportUtility.getFormattedValue(header.type, data.summary[header.value])),
              date: { startDate: params['startDate'], endDate: params['endDate'] }
            },
            options: { action: 'includedSummary' },
            excelName: EXCEL_NAME,
            tableTitle: TABLE_TITLE,
            columnsWidth: TABLE_HEADERS.map(header => header.columnWidth)
          };
          this.showReport = true;
        }
      },
        error => {
          console.log('error getting projected episode profit report', error);
        }
      );
  }

  enableReportGenerate(): boolean {
    return this.isLoading.projectedEpisodeProfitReport || !this.projectedEpisodeProfitForm.valid;
  }
}
