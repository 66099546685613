import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from "rxjs/operators";
@Injectable({
    providedIn: 'root'
})
export class MarketService {

    constructor(private http: HttpClientService) { }

    getAll(query?) {
        return this.http.get(`${APICONSTANTS.MARKET}`, query)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    create(market) {
        return this.http.post(`${APICONSTANTS.MARKET}`, market)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    update(market, paramType) {
        return this.http.put(`${APICONSTANTS.MARKET}/${market.id}?paramType=${paramType}`, market)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    delete(id, paramType) {
        return this.http.remove(`${APICONSTANTS.MARKET}/${id}?paramType=${paramType}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }
}