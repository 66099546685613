<div mdbModal #switchModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true, backdrop: true, keyboard:false}">
    <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
        <!--Content-->
        <div class="modal-content rounded-0">
            <!--Body-->
            <div class="modal-body">
                <p *ngIf="roleSwitch" class="mb-0 text-center">Are you sure you want to switch
                    role to <b class='text-uppercase'>{{roleSwitch.activeRole=='MP'?roleSwitch?.role?.name:'patient'}}
                    </b>?
                    If you're not sure, you can click <span class="font-weight-500">No </span>.
                </p>

                <!-- Material unchecked disabled -->
                <div class="p-0 mt-4" [ngClass]="{'text-center': roleSwitch}">
                    <button class="btn btn-secondary waves-light" (click)='roleSwitched(roleSwitch)'
                        mdbWavesEffect>
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
                            *ngIf="submit_enabled"></span>
                        YES
                    </button>
                    <button class="btn btn-light waves-light" (click)="cancelSwitch($event)"
                        mdbWavesEffect>
                        NO
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>