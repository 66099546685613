export class ReportTypes {
    code: string;
    id: number;
    name: string;
    order: number;
}

export class ExportReportParams {
    reportDate: string;
    episodeType: string;
    client: string;
    columnOptions: [];
    endDate: string;
    startDate: string;
    dateType: string;
    provider: string[];
    procedureCode: string;
    status: string; //claims module
    claimIDs: number[];
    network: string;
    speciality: string;
    entityCode: number;
}

export class TotalClaimsItem {
    name: string;
    volume: number;
    measure: number;
}

export class HighOccuranceProvidersItem {
    provider: string;
    taxId: number;
    duplicate: number;
    misdirect: number;
    total: number;
}

export class DiscrepenciesVolumeByEpisodeTypeItem {
    name: string;
    maternity: number;
    totalJoint: number;
    total: number;
}

export class DiscrepenciesFinancialByEpisodeTypeItem
    extends DiscrepenciesVolumeByEpisodeTypeItem { }

export class ChartDatasets {
    data: number[];
    label: string;
}

export class ClaimReconciliationRawData {
    clientClaimId: string;
    dateReceived: string;
    patientName: string;
    dob: string | null;
    subscriber: string;
    provLocation: string | null;
    provider: string;
    providerNpi: string;
    taxId: string;
    par: string;
    bundle: string | null;
    diagnosisCode1: string | null;
    diagnosisCode2: string | null;
    diagnosisCode3: string | null;
    pModifier: string | null;
    fModifier: string | null;
    procedureCode: string | null;
    dateOfService: string;
    billedAmount: string | null;
    paidAmount: string | null;
    copayAmount: string | null;
    cobAmount: string | null;
    currentStatus: string | null;
    previousStatus: string | null;
}

export class TableHeader {
    label: string;
    value: string;
    columnWidth: number;
    type: string;
};

export class TransactionReportFilterCriteria {
    activeTabIndex: number;
    clientCode: string;
    endDate: string;
    reportDateType: string;
    startDate: string;
    networkCode: string;
}

export class AccountsReceivableData {
    formattedAccountReceivable: any[];
    summary: FormattedAccountReceivableSummaryData
}

export class FormattedAccountReceivableSummaryData {
    episodes: number;
    fundsRequested: number;
    fundsReceived: number;
    holistaDistribution: number;
    clientDistribution: number
}

export class AccountsPayableData {
    accountPayables: any[];
    summary: {
        episodes: number,
        providerDistribution: number
    }
}

export class SummaryYTDData {
    accountSummaryDatas: any[];
    monthwiseSummary: any[];
    summary: {
        episodes: number,
        fundsRequested: number,
        clientDistribution: number,
        patientResponsibility: number,
        fundsReceived: number,
        providerDistribution: number,
        holistaDistribution: number,
        totalNotConsumed: number,
    };
}

