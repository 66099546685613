<!--   Table Template 2-->
<div class="d-flex justify-content-between align-items-center mb-2">
  <h5>Episodes Financial Reconciliation Report</h5>
  <div>
    <button
      type="button"
      class="btn btn-sm btn-light border mr-0"
      (click)="onSendEmail()"
      [class.disabled]="
        !financialReconciliationData?.episodeFinancialSummaryDetail?.episodeFinancialSummary?.length
      ">
      <i class="fa-fa fa-envelope fas"></i> Send Email
    </button>
    <button
      type="button"
      class="btn btn-sm btn-light border mr-0"
      (click)="exportFinancialReconciliation()"
      [class.disabled]="
        !financialReconciliationData?.episodeFinancialSummaryDetail?.episodeFinancialSummary?.length
      ">
      <i class="fas fa-download"></i> Download
    </button>
  </div>
</div>
<!-- Nav tabs -->
<!-- Nav tabs -->
<mdb-tabset
  #tabs
  [buttonClass]="'classic-tabs bg-light border'"
  [contentClass]="'card m-0 shadow-none'"
  class="classic-tabs">
  <!--Panel 1-->
  <mdb-tab heading="Summary" (select)="onTabSelected($event)">
    <div *ngIf="displayNone; else reconcilationReport"></div>
  </mdb-tab>
  <!--Panel 2-->
  <mdb-tab heading="Detail" (select)="onTabSelected($event)">
    <div *ngIf="displayNone; else reconcilationReport"></div>
  </mdb-tab>
</mdb-tabset>

<ng-template #reconcilationReport>
  <!--Summary-->
  <div class="row" [ngClass]="hideDetail ? '' : 'h-0 overflow-hidden'">
    <div class="col-12 px-0">
      <table
        class="datatable mb-0"
        bordered="true"
        hover="true"
        mdbTable
        id="reconciliationSummaryPDF">
        <thead>
          <tr>
            <th class="pt-3 pb-3" colspan="100%">
              <h6>
                {{ financialReconciliationData?.client?.label }} Episode Reconciliation Report
              </h6>
              <span>
                {{ financialReconciliationData.date.startDate }} -
                {{ financialReconciliationData.date.endDate }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="grey lighten-3">
          <tr>
            <td class="p-0" colspan="2">
              <table class="table-borderless w-100">
                <tr class="grey lighten-1">
                  <th colspan="100%">Year-to-Date Summary</th>
                </tr>
                <tr>
                  <td>Closed Episode YTD</td>
                  <td class="text-right">
                    {{ financialReconciliationData.yearToDateSummary.closedEpisodes }}
                  </td>
                </tr>
                <tr>
                  <td>Total Funded YTD</td>
                  <td class="text-right">
                    {{ financialReconciliationData.yearToDateSummary.totalFundReceived }}
                  </td>
                </tr>
                <tr>
                  <td>Total Consumed YTD</td>
                  <td class="text-right">
                    {{ financialReconciliationData.yearToDateSummary.totalConsumed }}
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="2" class="p-0">
              <table class="table-borderless w-100">
                <tr class="grey lighten-1">
                  <th colspan="100%">Current Period</th>
                </tr>
                <tr>
                  <td>Count of Episodes</td>
                  <td class="text-right">
                    {{
                      financialReconciliationData.episodeFinancialSummaryDetail.currentPeriodSummary
                        .closedEpisodes
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Total Funded</td>
                  <td class="text-right">
                    {{
                      financialReconciliationData.episodeFinancialSummaryDetail.currentPeriodSummary
                        .totalFundReceived
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Less: Total Consumed</td>
                  <td class="text-right">
                    {{
                      financialReconciliationData.episodeFinancialSummaryDetail.currentPeriodSummary
                        .totalConsumed
                    }}
                  </td>
                </tr>
                <tfoot>
                  <tr>
                    <td class="border-top font-weight-bold">Current Period Balance</td>
                    <td class="text-right border-top font-weight-bold">
                      {{
                        financialReconciliationData.episodeFinancialSummaryDetail
                          .currentPeriodSummary.totalBalance
                      }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </td>
            <!-- <td class="p-0">
              <table class="table-borderless w-100">
                <tr class="grey lighten-1">
                  <th colspan="100%">Episode Surplus Management</th>
                </tr>
                <tr>
                  <td>Target Surplus Balance</td>
                  <td class="text-right">$15,000.00</td>
                </tr>
                <tr>
                  <td>Less: Previous Surplus Balance</td>
                  <td class="text-right">$-</td>
                </tr>
                <tr>
                  <td>Funds Needed To Meet Target Surplus</td>
                  <td class="text-right">$15,000.00</td>
                </tr>
                <tr>
                  <td>Less: Amt of Current Period Balance Applied to Surplus</td>
                  <td class="text-right">$(15,000.00)</td>
                </tr>
                <tr>
                  <td>Remaing Funds Needed to Meet Target Surplus</td>
                  <td class="text-right">$-</td>
                </tr>
                <tfoot>
                  <tr>
                    <td class="border-top font-weight-bold">New Episode Surplus Balance</td>
                    <td class="text-right border-top font-weight-bold">$15,000.00</td>
                  </tr>
                </tfoot>
              </table>
            </td>
            <td class="p-0">
              <table class="table-borderless w-100">
                <tr class="grey lighten-1">
                  <th colspan="100%">Remaining Unused Funds</th>
                </tr>
                <tr>
                  <td>Current Period Balance</td>
                  <td class="text-right">$25,635.01</td>
                </tr>
                <tr>
                  <td>Less: Amt of Current Period Balance Applied to Surplus</td>
                  <td class="text-right">$(15,000.00)</td>
                </tr>
                <tfoot>
                  <tr>
                    <td class="border-top font-weight-bold">
                      Remaining Unused Funds Owed to Novant Health
                    </td>
                    <td class="text-right border-top font-weight-bold">$10,635.01</td>
                  </tr>
                </tfoot>
              </table>
            </td> -->
          </tr>
        </tbody>
        <tbody class="bg-white">
          <tr class="green lighten-5">
            <th>Subscriber ID</th>
            <th>Patient Name</th>
            <th>Episode Type</th>
            <th class="text-right">Funded Amount</th>
            <th class="text-right">HRA/HSA Received</th>
            <th class="text-right">Consumed Amount</th>
            <th class="text-right">HRA/HSA Amount</th>
            <th class="text-right">Balance</th>
          </tr>
          <tr
            *ngIf="
              financialReconciliationData.episodeFinancialSummaryDetail.episodeFinancialSummary
                .length === 0
            ">
            <td>There is no data available.</td>
          </tr>
          <tr
            *ngFor="
              let summaryData of financialReconciliationData.episodeFinancialSummaryDetail
                .episodeFinancialSummary
            ">
            <td>{{ summaryData.subscriberNumber }}</td>
            <td>{{ summaryData.firstName | titlecase }} {{ summaryData.lastName | titlecase }}</td>
            <td>{{ summaryData.episodeType }}</td>
            <td class="text-right">{{ summaryData.fundReceived }}</td>
            <td class="text-right">{{ summaryData.hraHsaReceivedAmount }}</td>
            <td class="text-right">{{ summaryData.consumedAmount }}</td>
            <td class="text-right">
              {{ summaryData.hraHsaPaidAmount === null ? '-' : summaryData.hraHsaPaidAmount }}
            </td>
            <td class="text-right">{{ summaryData.balance }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--Summary-->

  <!--Detail-->
  <div class="row" [ngClass]="hideDetail ? 'h-0 overflow-hidden' : ''">
    <div class="col-12 px-0">
      <table
        class="datatable mb-0"
        bordered="true"
        hover="true"
        mdbTable
        id="reconciliationDetailPDF">
        <thead>
          <tr>
            <th class="pt-3 pb-3" colspan="100%">
              <h6>Current Period Balance Detail</h6>
              <span class="font-weight-bold">
                Total Current Period Balance (Detail)
                {{ financialReconciliationData.episodeFinancialDetail.totalConsumed }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="green lighten-5">
            <th>Subscriber ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Episode</th>
            <th>Market</th>
            <th>Claim Category</th>
            <th>Type</th>
            <th class="text-right">Category Amount</th>
            <th class="text-right">Consumed Amount</th>
            <th class="text-right">HRA/HSA Amount</th>
          </tr>
        </tbody>
        <tbody class="bg-white">
          <tr
            *ngIf="
              financialReconciliationData.episodeFinancialDetail.episodeFinancials.length === 0
            ">
            <td>There is no data available.</td>
          </tr>
          <tr
            *ngFor="
              let financialDetail of financialReconciliationData.episodeFinancialDetail
                .episodeFinancials
            ">
            <td>{{ financialDetail.subscriberNumber }}</td>
            <td>{{ financialDetail.firstName }}</td>
            <td>{{ financialDetail.lastName }}</td>
            <td>{{ financialDetail.episodeType }}</td>
            <td>{{ financialDetail.marketCode }}</td>
            <td>{{ financialDetail.componentName }}</td>
            <td>{{ financialDetail.type }}</td>
            <td class="text-right">{{ financialDetail.componentCost }}</td>
            <td class="text-right">{{ financialDetail.consumedAmount }}</td>
            <td class="text-right">
              {{ financialDetail.hraHsaAmount === null ? '-' : financialDetail.hraHsaAmount }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--Detail-->
</ng-template>

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [idPDF]="['reconciliationSummaryPDF', 'reconciliationDetailPDF']"
  [moduleOptions]="moduleOptions"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->
