export const Theme = [
    'hps', 'oakleaf', 'hps', 'novant', 'provider'
]

export const RequestEocDomainList = [
    'holista.holistahealth.com', 'hps.holistahealth.com', 'stage-holista.noveltytechnology.com', 'stage-hps.noveltytechnology.com', 'dev-hps.noveltytechnology.com', 'dev-holista.noveltytechnology.com'
]

export const APP_OWNER = [
    'holista'
];

export const appAnnouncementExcludedClient = ['oakleaf', 'providers'];

