import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, ViewChildren, ElementRef, QueryList } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'
import { finalize, debounceTime, map, startWith, switchMap } from 'rxjs/operators'
import { QuestionOption, DependentTask, TaskOptions, PathwayResponse } from '../../../../../models'
import { EpisodeTaskService, UserService, MilestoneService, DocumentService, PathwayService, EpisodeService, LookupService } from '../../../../../services'
import { QuestionnaireQuestions } from '../../../../../models'
import { Subject, Observable, of } from 'rxjs'
import { HolistaUtils, AccessType, Storage } from '../../../../../utils'
import { MdbBtnDirective } from 'ng-uikit-pro-standard'
import { MODULES, PATHWAY_CONSTANTS, RestrictSpace } from '../../../../../constants';
import { ActivatedRoute } from '@angular/router'


@Component({
  selector: 'app-episode-task',
  templateUrl: './episode-task.component.html',
  styleUrls: ['./episode-task.component.scss']
})
export class EpisodeTaskComponent implements OnInit, OnChanges {
  tasks = []
  taskTodos = []
  questions = []
  messages = []
  questionnaires = []
  todoForm: FormGroup
  questionForm: FormGroup
  messageForm: FormGroup
  questionnaireForm: FormGroup
  signatureForm: FormGroup;
  todoFile: File;
  messageFile: File;
  topicId: number;
  submit_enabled = false;
  submitted = false
  task_type = 'todo'
  optionList = []
  QnrsOptionList = []
  questionTypes = []
  questionTypeList = []
  questionOption = new QuestionOption()
  QnrsquestionOption = new QuestionOption()
  messageFromList = []
  messageToList = []
  assignFromList = []
  assignToList = []
  dependent_task = new DependentTask()
  questionnaireQuestions = []
  selectedQuestion: any
  createSelectedQuestion: any
  question_search_text = new Subject()
  question_results: Observable<any>
  create_submitted = false
  editQuestionnaire: any
  taskOptions = new TaskOptions()
  isEdit = false
  sequenceChangeEnabled = true
  taskDragged = false
  triggerDatePassed = false
  topicDetail: any
  document_search_text = new Subject()
  document_name_results: Observable<any>
  attachFileDetail: any
  triggerPeriodOptions = [
    { label: "Day(s)", value: "days" },
    { label: "Week(s)", value: "weeks" }
  ];
  assignedEsId: any
  assignedCcId: any
  supervisorOf: any = []
  loggedInUser: any
  assignUserAdded: any = []
  agreementTemplateList: any = [];
  isLoading = {
    agreementTemplateList: false,
    groups: false
  }
  groups: any;

  @ViewChild('taskModal', { static: true }) private taskModal
  @ViewChildren(MdbBtnDirective, { read: ElementRef }) button: QueryList<ElementRef>
  disabledElements = []
  buttons: any
  @Output() updateTopic = new EventEmitter<any>()
  @Output() deleteDependent = new EventEmitter<any>()
  @Output() toBeDeleteTask = new EventEmitter<any>()
  @Input() public days_difference
  @Input() assignedBy
  @Input() assignedTo
  @Input() user: any
  @Input() public userDetails;
  @Input() set topic(topic) {
    if (topic && topic.id) {
      this.tasks = [];
      this.topicDetail = topic
      this.topicId = topic.id;
      this.triggerDatePassed = topic.triggerDatePassed
      this.assignedEsId = topic.esId
      this.assignedCcId = topic.ccId
      if (topic.taskTodos) {
        topic.taskTodos.map(x => {
          x.field = 'todo'
          x.topicStatus = topic.status
          x.episodeStatus = topic.episodeStatus
          x.milestoneStatus = topic.milestoneStatus
          x.triggerDatePassed = topic.triggerDatePassed
          x.milestoneId = topic.milestoneId
          x.episodeId = topic.episodeId
          this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskSignatures) {
        topic.taskSignatures.map(x => {
          x.field = 'signature'
          x.topicStatus = topic.status
          x.episodeStatus = topic.episodeStatus
          x.milestoneStatus = topic.milestoneStatus
          x.triggerDatePassed = topic.triggerDatePassed
          x.milestoneId = topic.milestoneId
          x.episodeId = topic.episodeId
          this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskQuestions) {
        topic.taskQuestions.map(x => {
          x.field = 'question'
          x.topicStatus = topic.status
          x.episodeStatus = topic.episodeStatus
          x.milestoneStatus = topic.milestoneStatus
          x.triggerDatePassed = topic.triggerDatePassed
          x.milestoneId = topic.milestoneId
          x.episodeId = topic.episodeId
          this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskMessages) {
        topic.taskMessages.map(x => {
          x.field = 'message'
          x.topicStatus = topic.status
          x.episodeStatus = topic.episodeStatus
          x.milestoneStatus = topic.milestoneStatus
          x.triggerDatePassed = topic.triggerDatePassed
          x.milestoneId = topic.milestoneId
          x.episodeId = topic.episodeId
          this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskQnrs) {
        topic.taskQnrs.map(x => {
          x.field = 'questionnaire'
          x.topicStatus = topic.status
          x.episodeStatus = topic.episodeStatus
          x.milestoneStatus = topic.milestoneStatus
          x.triggerDatePassed = topic.triggerDatePassed
          x.milestoneId = topic.milestoneId
          x.episodeId = topic.episodeId
          this.tasks.push(x);
          return x;
        });
      }
      this.tasks = [...this.holistaUtils.sortBy(this.tasks, 'sequence')]
    }
  }

  @Input() set dependent_tasks(dependent_tasks) {
    if (dependent_tasks) {
      this.dependent_task = dependent_tasks
    }
  }

  constructor(
    private episodeTaskService: EpisodeTaskService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private holistaUtils: HolistaUtils,
    public utilityAccess: AccessType,
    private milestoneService: MilestoneService,
    private documentService: DocumentService,
    private _storage: Storage,
    private _pathwayService: PathwayService,
    private _activatedRoute: ActivatedRoute,
    private _episodeService: EpisodeService,
    private _lookupService: LookupService
  ) {
    this.setToDoForm()
    this.setQuestionForm()
    this.setMessageForm()
    this.setQuestionnaireForm()
    this.setSignatureForm();
  }
  receiveBtns(buttons, field) {
    if (buttons) {
      buttons.map(button => {
        if (button.nativeElement && (button.nativeElement.className == 'icofont-ui-edit' || button.nativeElement.className.includes('add-action')))
          this.disabledElements.push(button.nativeElement)
      })
    }
  }

  ngOnInit() {
    this.loggedInUser = this._storage.get('local', 'loggedInUser', 'user');
    this.getQuestionTypes()
    this.getUsers()
    this.todoForm.controls.assignedBy.setValue(this.assignedBy)
    this.todoForm.controls.assignedTo.setValue(this.assignedTo)
    this.messageForm.controls.messageFrom.setValue(this.assignedBy)
    this.messageForm.controls.messageTo.setValue(this.assignedTo)

    this.questionForm.get('questionTypeId').valueChanges.subscribe(value => {
      if (value) {
        let index = this.questionTypeList.findIndex(x => x.id == value)
        if (index > -1) this.questionForm.controls.questionTypeCode.setValue(this.questionTypeList[index].code)
      }
    })

    this.document_name_results = this.document_search_text
      .pipe(
        startWith(this.document_search_text),
        switchMap((provider_search_text: string) => this.searchDocument(provider_search_text))
      );

    this.questionnaireForm.get('questionTypeId').valueChanges.subscribe(value => {
      if (value) {
        let index = this.questionTypeList.findIndex(x => x.id == value)
        if (index > -1) this.questionnaireForm.controls.questionTypeCode.setValue(this.questionTypeList[index].code)
      }
    })

    this.question_results = this.question_search_text.pipe(
      startWith(this.question_search_text),
      switchMap((question_search_text: string) => this.searchQuestion(question_search_text))
    )
  }

  ngOnChanges() {
    this.todoForm.controls.assignedBy.setValue(this.assignedBy)
    this.todoForm.controls.assignedTo.setValue(this.assignedTo)
    this.messageForm.controls.messageFrom.setValue(this.assignedBy)
    this.messageForm.controls.messageTo.setValue(this.assignedTo)
  }

  getQuestionTypes() {
    this.questionTypes = []
    this.episodeTaskService.getQuestionTypes().subscribe(
      res => {
        this.questionTypeList = res
        res.map(x => {
          let data = { label: x.name, value: x.id }
          this.questionTypes = [...this.questionTypes, data]
        })
      },
      error => {
        console.log(error)
      }
    )
  }

  async getUsers() {
    await this.userService
      .getAllUsers({ limit: 0 })
      .then((res: any) => {
        this.messageFromList = []
        this.messageToList = []
        this.assignFromList = []
        this.assignToList = []
        let supervisorIds = []
        if (res.rows.length != 0) {
          res.rows.map(t => {
            if ((t.id == this.assignedCcId) || (t.id == this.assignedEsId) && t.supervisor) {
              supervisorIds.push(t.supervisor.supervisorId)
            }
          })
          res.rows.map(x => {
            const role = this.holistaUtils.getUserRoleInfo(x.roles)
            let data = { label: x.firstName + ' ' + x.lastName, value: x.id, roleCode: role.roleCode, secondaryRoleCode: role.secondaryRole, disabled: false, company: x.company, supervisor: x.supervisor }
            if ((data.roleCode === 'CC' || data.roleCode === 'ES' || (data.roleCode === 'SV' && supervisorIds.includes(data.value)) || (data.roleCode === 'MP' && this.assignedTo == data.value) || (this.assignedTo == data.value && data.secondaryRoleCode && data.secondaryRoleCode === 'MP'))) {
              this.assignFromList = [...this.assignFromList, data].filter(x => x.roleCode !== 'SV')
              this.messageFromList = [...this.messageFromList, data].filter(x => x.roleCode !== 'SV')
              this.messageToList = [...this.messageToList, data]
              this.assignToList = [...this.assignToList, data]
            }
          })
          this.assignFromList = this.holistaUtils.sortBy(this.assignFromList, 'label')
          this.messageFromList = this.holistaUtils.sortBy(this.messageFromList, 'label')
          this.messageToList = this.holistaUtils.sortBy(this.messageToList, 'label')
          this.assignToList = this.holistaUtils.sortBy(this.assignToList, 'label')
        }
      })
  }

  onFileAdd(file: File) {
    // let file_name = file.name.split('.').join('_' + new Date().getTime() + '.')
    if (this.task_type == 'todo') {
      this.todoForm.controls.holistaMedia.setValue(file)
      this.todoForm.controls.fileName.setValue(file.name)
      this.todoFile = file
    } else {
      this.messageForm.controls.holistaMedia.setValue(file)
      this.messageForm.controls.fileName.setValue(file.name)
      this.messageFile = file
    }
  }

  onFileRemove() {
    if (this.task_type == 'todo') {
      this.todoForm.controls.holistaMedia.setValue(null)
      this.todoForm.controls.fileName.setValue('')
      this.todoFile = null
    } else {
      this.messageForm.controls.holistaMedia.setValue(null)
      this.messageForm.controls.fileName.setValue('')
      this.messageFile = null
    }
  }

  setToDoForm() {
    this.todoForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      name: ['', Validators.required],
      instruction: [''],
      taskTodoLink: [''],
      holistaMedia: [null],
      fileName: [''],
      isAcknowledgedRequired: [false],
      isHighPriority: [false],
      assignedBy: ['', Validators.required],
      assignedTo: ['', Validators.required],
      isDependent: [false],
      optionId: [null],
      sequence: [null],
      parentUuid: [null],
      escalationDays: [],
      triggerPeriod: ['days'],
      documentDisplayName: [''],
      type: [''],
      isAttach: [false],
      assignedByRole: [''],
      assignedToRole: [''],
      supervisorOf: [''],
      supervisorOfId: [null]
    })
  }
  setQuestionForm() {
    this.questionForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      questionTypeId: [null],
      question: ['', Validators.required],
      questionHelp: [''],
      description: [''],
      questionOptions: [],
      isRequired: [false],
      isHighPriority: [false],
      questionTypeCode: ['', Validators.required],
      isDependent: [false],
      optionId: [null],
      sequence: [null],
      parentUuid: [null],
      escalationDays: [],
      triggerPeriod: ['days'],
      groupCode: [null]
    })
  }
  setMessageForm() {
    this.messageForm = this.formBuilder.group({
      id: [null],
      title: ['', Validators.required],
      taskId: [null],
      messages: [null, Validators.required],
      documentPath: [''],
      holistaMedia: [null],
      messageFrom: ['', Validators.required],
      messageTo: ['', Validators.required],
      fileName: [''],
      isDependent: [false],
      optionId: [null],
      sequence: [null],
      parentUuid: [null],
      severity: [false],
      documentDisplayName: [''],
      type: [''],
      isAttach: [false],
      escalationDays: [],
      triggerPeriod: ['days'],
      assignedByRole: [''],
      assignedToRole: [''],
      supervisorOf: [''],
      supervisorOfId: [null]
    })
  }

  setQuestionnaireForm() {
    this.questionnaireForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      name: [''],
      isHighPriority: [false],
      questionId: [null],
      isRequired: [false],
      questions: [''],
      question: [''],
      questionHelp: [''],
      questionTypeId: [null],
      questionTypeCode: [''],
      optionId: [null],
      isDependent: [false],
      questionOptions: [],
      description: [''],
      sequence: [null],
      parentUuid: [null],
      escalationDays: [],
      triggerPeriod: ['days']
    })
  }

  setSignatureForm() {
    this.signatureForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      escalationDays: [],
      triggerPeriod: ['days'],
      assignedBy: ['', Validators.required],
      assignedTo: ['', Validators.required],
      assignedByRole: [''],
      assignedToRole: [''],
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      instruction: ['', Validators.pattern(RestrictSpace)],
      docuSignTemplateId: ['', Validators.required],
      isHighPriority: [false],
      isNotifiableToAssignor: [false],
      isNotifiableToAssignee: [false],
      supervisorOf: [''],
      supervisorOfId: [''],
      parentUuid: [null],
      isDependent: [false],
      documentDisplayName: [''],
      optionId: [null],
    });
  }

  closeModal() {
    this.taskOptions = new TaskOptions()
    this.todoForm.reset()
    this.messageForm.reset()
    this.questionForm.reset()
    this.questionnaireForm.reset()
    this.signatureForm.reset();
    this.taskModal.hide()
    this.submit_enabled = false
    this.submitted = false
    this.create_submitted = false
    if (this.task_type == 'todo') {
      this.todoFile = null
    } else {
      this.messageFile = null
    }
    this.task_type = 'todo'
    this.optionList = []
    this.QnrsOptionList = []
    this.questionOption = new QuestionOption()
    this.QnrsquestionOption = new QuestionOption()
    this.selectedQuestion = null
    this.createSelectedQuestion = null
    this.questionnaireQuestions = []
    this.question_search_text.next('')
    this.isEdit = false
  }

  async addTask() {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      let form_name = this.task_type + 'Form'
      this.removeAddedSupervisor()
      this.taskModal.show()
      if (this.assignToList.length > 0) {
        let asssignToRole = this.assignToList.find(x => x.value === this.assignedTo)
        const assignedRole = asssignToRole.roleCode && asssignToRole.roleCode === 'MP' ? asssignToRole.roleCode : asssignToRole.secondaryRoleCode
        this.todoForm.controls.assignedToRole.setValue(assignedRole)
      }
      this.taskOptions.sequence = this.tasks.length + 1
      this.todoForm.controls.assignedBy.setValue(this.assignedBy)
      this.todoForm.controls.assignedTo.setValue(this.assignedTo)
      this.messageForm.controls.messageFrom.setValue(this.assignedBy)
      this.messageForm.controls.messageTo.setValue(this.assignedTo)
      this[form_name].controls.triggerPeriod.setValue('days')
    } else this.toastr.warning('Access Denied', 'Warning')
  }

  async editTask(task, form) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      this.isEdit = true
      form = form;;
      this.task_type = form
      let form_name = form + 'Form'
      this.taskOptions.sequence = task.sequence
      this.taskOptions.isDependent = task.isDependent
      this.taskOptions.optionId = task.optionId
      this.taskOptions.parentUuid = task.parentUuid
      this.attachFileDetail = null
      if (form == 'todo' || form === 'signature') {
        task.assignedBy = +task.assignedBy
        task.assignedTo = +task.assignedTo
        if (form === 'signature') {
          this.getAgreementTemplates();
          this.signatureForm.controls.assignedByRole.setValue(task.assignedByRole);
          this.signatureForm.controls.assignedToRole.setValue(task.assignedToRole);
        } else {
          this.todoForm.controls.assignedByRole.setValue(task.assignedByRole);
          this.todoForm.controls.assignedToRole.setValue(task.assignedToRole);
          if (task.taskTodoLink) {
            task.isAttach = true
            task.type = 'link'
            this.attachFileDetail = {
              documentDisplayName: task.documentDisplayName,
              taskTodoLink: task.taskTodoLink
            }
          }
        }
      }
      if (form == 'message') {
        task.messageFrom = +task.messageFrom
        task.messageTo = +task.messageTo
        this.messageForm.controls.assignedByRole.setValue(task.messageFromRole)
        this.messageForm.controls.assignedToRole.setValue(task.messageToRole)
        task.severity = task.severity == 'YES' ? true : false
        if (task.documentPath) {
          task.isAttach = true
          task.type = 'link'
          this.attachFileDetail = {
            documentDisplayName: task.documentDisplayName,
            documentPath: task.documentPath
          }
        }
      }
      // For dependent task patchValue in form
      if (task.isDependent && task.optionId) {
        let data = { optionId: task.optionId, isDependent: task.isDependent }
        this.todoForm.patchValue(data)
        this.questionForm.patchValue(data)
        this.messageForm.patchValue(data)
        this.questionnaireForm.patchValue(data)
        this.signatureForm.patchValue(data);
      }

      task.supervisorOfId = task.supervisorOfId ? +task.supervisorOfId : null
      this[form_name].patchValue(task)
      this[form_name].controls.triggerPeriod.setValue('days')

      if ((form == 'todo' || form == 'message' || form === 'signature')) {
        if (!this.assignToList.length || this.assignToList.length === 0) await this.getUsers()
        const assignedToList = (form_name == 'todoForm') ? 'assignToList' : 'messageToList'
        const assignedFromList = (form_name == 'todoForm') ? 'assignFromList' : 'messageFromList'
        const assignTo = (form_name == 'todoForm') ? 'assignedTo' : 'messageTo'
        const assignFrom = (form_name == 'todoForm') ? 'assignedBy' : 'messageFrom'
        const assignToValue = this[form_name].value[assignTo]
        const assignFromValue = this[form_name].value[assignFrom]
        const assignedToUser = this[assignedToList].find(x => x.value == assignToValue)
        const assignedFromUser = this[assignedFromList].find(x => x.value == assignFromValue)
        // to get assignToUser if loggedIn user is cc/es
        if (!assignedToUser && (this.loggedInUser.roleCode == 'CC' || this.loggedInUser.roleCode == 'ES')) {
          await this.addedAssignUser(assignToValue, assignedToList)
        }
        // to get assignFromUser if loggedIn user is cc/es
        if (!assignedFromUser && (this.loggedInUser.roleCode == 'CC' || this.loggedInUser.roleCode == 'ES')) {
          await this.addedAssignUser(assignFromValue, assignedFromList)
        }
        // To get supervisorOf list
        if (this[form_name].value.assignedByRole == 'MP' && this[form_name].value.assignedToRole == 'SV') {
          if ((this.loggedInUser.roleCode == 'CC' || this.loggedInUser.roleCode == 'ES')) {
            const supervisorOfIdValue = this[form_name].value.supervisorOfId
            const supervisorOfId = this[assignedToList].find(x => x.value == supervisorOfIdValue)
            if (!supervisorOfId) {
              await this.addedAssignUser(supervisorOfIdValue, assignedToList)
            }
          }
          this.filterSupervisorOfList(form_name, assignTo, assignedToList)
          this[form_name].controls["supervisorOfId"].setValidators([Validators.required])
          this[form_name].controls["supervisorOfId"].updateValueAndValidity();
        } else {
          this[form_name].controls['supervisorOfId'].clearValidators()
          this[form_name].controls['supervisorOfId'].updateValueAndValidity()
        }
      }
      if (form == 'question') {
        let temp_optList = []
        this.getQuestionGroups();
        task.questions.questionTypeCode = task.questions.questionTypes.code
        this[form_name].patchValue(task.questions)
        task.questions.questionOptions.map(x => {
          let y = JSON.parse(JSON.stringify(x))
          y.severity = y.severity == 'YES' ? true : false
          temp_optList.push(y)
        })
        this.optionList = temp_optList
      }

      if (form == 'questionnaire') {
        this.editQuestionnaire = new Object()
        this.editQuestionnaire['taskId'] = task.taskId
        this.editQuestionnaire['id'] = task.qnrs.id
        this.editQuestionnaire['name'] = task.qnrs.name
        this.editQuestionnaire['description'] = task.qnrs.description
        this.editQuestionnaire['isHighPriority'] = task.qnrs.isHighPriority
        let array = []
        task.qnrs.qnrsQues.map(x => {
          x.question['questionTypeCode'] = x.question.questionTypes.code
          array.push(x.question)
        })
        this.questionnaireQuestions = array
        this[form_name].patchValue(this.editQuestionnaire)
      }
      this.taskModal.show()
    } else this.toastr.warning('Access Denied', 'Warning')
  }

  getQuestionGroups() {
    this.isLoading.groups = true;
    this._lookupService.getLookups({ module: MODULES.QUESTION })
      .pipe(finalize(() => { this.isLoading.groups = false }))
      .subscribe((response: any) => {
        this.groups = response.rows.map(group => ({ ...group, label: group.name, value: group.code }));
      }, (error) => {
        this.toastr.error(error);
        console.log('error getting groups', error);
      });
  }

  async addedAssignUser(assignValue, assignList) {
    await this.userService.getUserById(assignValue).then((res: any) => {
      if (res && res.id) {
        const role = this.holistaUtils.getUserRoleInfo(res.roles)
        const addedUser = { label: res.firstName + ' ' + res.lastName, value: res.id, roleCode: role.roleCode, secondaryRoleCode: role.secondaryRole, disabled: false, supervisor: res.supervisor }
        this[assignList] = [...this[assignList], addedUser]
        this.assignUserAdded = [...this.assignUserAdded, addedUser]
      }
    },
      error => {
        console.log('Error', error)
      })
  }

  removeAddedSupervisor() {
    if ((this.loggedInUser.roleCode == 'CC' || this.loggedInUser.roleCode == 'ES') && (this.task_type == 'todo' || this.task_type == 'message')) {
      const assignedToList = this.task_type == 'todo' ? 'assignToList' : 'messageToList'
      const assignedFromList = this.task_type == 'todo' ? 'assignFromList' : 'messageFromList'
      this[assignedToList] = this[assignedToList].filter(i => !this.assignUserAdded.some(j => j == i))
      this[assignedFromList] = this[assignedFromList].filter(i => !this.assignUserAdded.some(j => j == i))
      this.assignUserAdded = []
    }
  }

  addAction(optionTask) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      this.task_type = 'todo'
      if (this.assignToList.length > 0) {
        const asssignToRole = this.assignToList.find(x => x.value === this.assignedTo)
        this.todoForm.controls.assignedToRole.setValue(asssignToRole.roleCode)
      }
      const form_name = this.task_type + 'Form'
      this.removeAddedSupervisor()
      this.taskOptions = optionTask
      this.todoForm.patchValue(optionTask)
      this.questionForm.patchValue(optionTask)
      this.messageForm.patchValue(optionTask)
      this.questionnaireForm.patchValue(optionTask)
      this.signatureForm.patchValue(optionTask)
      this.todoForm.controls.assignedBy.setValue(this.assignedBy)
      this.todoForm.controls.assignedTo.setValue(this.assignedTo)
      this[form_name].controls.triggerPeriod.setValue('days')
      this.taskModal.show()
    } else this.toastr.warning('Access Denied', 'Warning')
  }

  submit() {
    this.submitted = true
    let form_name = this.task_type + "Form";
    if (
      this[form_name].value.triggerPeriod == "weeks" &&
      this[form_name].value.escalationDays &&
      this[form_name].value.escalationDays > 0
    ) {
      this[form_name].controls.escalationDays.setValue(
        this[form_name].value.escalationDays * 7
      );
    }
    if (this.task_type == 'todo' || this.task_type == 'message') this.setValidator()
    if (this.task_type == 'todo') this.submitTask()
    if (this.task_type == 'question') this.submitQuestion()
    if (this.task_type == 'message') this.submitMessage()
    if (this.task_type == 'questionnaire') this.submitQuestionnaire()
    if (this.task_type === 'signature') this.submitSignatureForm();
  }

  submitSignatureForm() {
    const { id, taskId, isHighPriority, docuSignTemplateId } = this.signatureForm.value;
    this.signatureForm.controls.taskId.setValue(taskId ? taskId : this.topicId ? this.topicId : null);
    this.signatureForm.controls.isHighPriority.setValue(isHighPriority ? isHighPriority : false);
    this.signatureForm.patchValue(this.taskOptions)
    if (this.signatureForm.valid) {
      this.submit_enabled = true;
      const templateName = this.agreementTemplateList.find(({ code }) => code === docuSignTemplateId).name;
      this.signatureForm.get('documentDisplayName').setValue(templateName);
      id ? this.updateSignature() : this.saveSignature();
    }
  }

  submitTask() {
    this.todoForm.controls.isAcknowledgedRequired.setValue(this.todoForm.value.isAcknowledgedRequired ? this.todoForm.value.isAcknowledgedRequired : false)
    this.todoForm.controls.isHighPriority.setValue(this.todoForm.value.isHighPriority ? this.todoForm.value.isHighPriority : false)
    this.todoForm.controls.taskId.setValue(this.todoForm.value.taskId ? this.todoForm.value.taskId : this.topicId ? this.topicId : null)
    this.todoForm.patchValue(this.taskOptions)
    if (this.todoForm.valid) {
      this.submit_enabled = true
      if (this.todoForm.value.id) this.updateTask()
      else this.saveTask()
    }
  }

  setValidator() {
    let form_name = this.task_type + "Form";
    if (this[form_name].value.type) {
      this[form_name].controls["documentDisplayName"].setValidators([Validators.required])
      this[form_name].controls["documentDisplayName"].updateValueAndValidity();
    }
    if (this.task_type == 'message' && this[form_name].value.type && this[form_name].value.type != "uploadFile") {
      this[form_name].controls['documentPath'].setValidators([Validators.required])
      this[form_name].controls['documentPath'].updateValueAndValidity()
    }
    if (this.task_type == 'todo' && this[form_name].value.type && this[form_name].value.type != "uploadFile") {
      this[form_name].controls['taskTodoLink'].setValidators([Validators.required])
      this[form_name].controls['taskTodoLink'].updateValueAndValidity()
    }
    if (this[form_name].value.type && this[form_name].value.type == "uploadFile") {
      this[form_name].controls['holistaMedia'].setValidators([Validators.required])
      this[form_name].controls['holistaMedia'].updateValueAndValidity()
    }

    // remove attach file
    if (!this[form_name].value.isAttach) {
      this[form_name].controls.documentDisplayName.setValue('')
      this[form_name].controls.holistaMedia.setValue('')

      if (form_name == 'todoForm') {
        this[form_name].controls.taskTodoLink.setValue('')
      }

      if (form_name == 'messageForm') {
        this[form_name].controls.documentPath.setValue('')
      }
    }
  }

  saveSignature() {
    let signature = Object.assign({ ...this.signatureForm.value, type: 'signature' });
    signature.milestoneId = this.topicDetail.milestoneId
    signature.episodeId = this.topicDetail.episodeId
    this.episodeTaskService
      .saveTask(signature)
      .pipe(
        finalize(() => {
          this.submit_enabled = false
        })
      )
      .subscribe(
        res => {
          res.data.taskField = { uuid: 'taskSignatureUuid', optionId: this.todoForm.value.optionId }
          this.refreshTasks(res, 'Signature saved')
        },
        error => {
          console.log(error)
        }
      )
  }

  updateSignature() {
    let signature = Object.assign({ ...this.signatureForm.value, type: 'signature' });
    signature.milestoneId = this.topicDetail.milestoneId
    signature.episodeId = this.topicDetail.episodeId
    this.episodeTaskService
      .updateTask(signature)
      .pipe(
        finalize(() => {
          this.submit_enabled = false
        })
      )
      .subscribe(
        res => {
          this.refreshTasks(res, 'Signature updated')
        },
        error => {
          console.log(error)
        }
      )
  }

  saveTask() {
    let task = Object.assign(this.todoForm.value);
    task.milestoneId = this.topicDetail.milestoneId
    task.episodeId = this.topicDetail.episodeId
    this.episodeTaskService
      .saveTask(task)
      .pipe(
        finalize(() => {
          this.submit_enabled = false
        })
      )
      .subscribe(
        res => {
          res.data.taskField = { uuid: 'taskTodoUuid', optionId: this.todoForm.value.optionId }
          this.refreshTasks(res, 'Action Item saved')
        },
        error => {
          console.log(error)
        }
      )
  }
  updateTask() {
    let task = Object.assign(this.todoForm.value);
    task.milestoneId = this.topicDetail.milestoneId
    task.episodeId = this.topicDetail.episodeId
    this.episodeTaskService
      .updateTask(task)
      .pipe(
        finalize(() => {
          this.submit_enabled = false
        })
      )
      .subscribe(
        res => {
          this.refreshTasks(res, 'Action Item updated')
        },
        error => {
          console.log(error)
        }
      )
  }

  addOptionValue() {
    if (this.questionOption.optionValue) {
      this.optionList.push(this.questionOption)
      this.questionOption = new QuestionOption()
    }
  }
  QnrsaddOptionValue() {
    if (this.QnrsquestionOption.optionValue) {
      this.QnrsOptionList.push(this.QnrsquestionOption)
      this.QnrsquestionOption = new QuestionOption()
    }
  }

  removeOption(option) {
    let index = this.optionList.findIndex(x => x.optionValue == option.optionValue)
    let Qnrsindex = this.QnrsOptionList.findIndex(x => x.optionValue == option.optionValue)

    if (index > -1) this.optionList.splice(index, 1)
    if (Qnrsindex > -1) this.QnrsOptionList.splice(index, 1)
  }

  submitQuestion() {
    this.addOptionValue()
    this.questionForm.controls.isRequired.setValue(this.questionForm.value.isRequired ? this.questionForm.value.isRequired : false)
    this.questionForm.controls.isHighPriority.setValue(this.questionForm.value.isHighPriority ? this.questionForm.value.isHighPriority : false)
    this.questionForm.controls.taskId.setValue(this.questionForm.value.taskId ? this.questionForm.value.taskId : this.topicId ? this.topicId : null)
    this.questionForm.patchValue(this.taskOptions)
    const questionOptions = (this.questionForm.value.questionTypeCode == 'CHECKBOX' || this.questionForm.value.questionTypeCode == 'RADIO') ? true : false
    if (this.questionForm.valid) {
      if (this.questionForm.value.id) this.checkQuestionType(questionOptions, 'updateQuestion')
      else this.checkQuestionType(questionOptions, 'saveQuestion')
    }
  }

  checkQuestionType(questionOptions, action) {
    if (questionOptions) {
      let temp_optList = []
      if (this.optionList) {
        this.optionList.map(x => {
          let y = JSON.parse(JSON.stringify(x))
          y.severity = y.severity ? 'YES' : 'NO'
          temp_optList.push(y)
        })
      }
      this.questionForm.controls.questionOptions.setValue(temp_optList)
      if (this.optionList.length > 0) {
        this.submit_enabled = true
        this[action]()
      }
    } else {
      this.submit_enabled = true
      this[action]()
    }
  }

  saveQuestion() {
    let question = Object.assign(this.questionForm.value)
    question.milestoneId = this.topicDetail.milestoneId
    question.episodeId = this.topicDetail.episodeId
    this.episodeTaskService
      .saveQuestion(question)
      .pipe(
        finalize(() => {
          this.submit_enabled = false
        })
      )
      .subscribe(
        res => {
          res.data.taskField = { uuid: 'taskQuesUuid', optionId: this.questionForm.value.optionId }
          this.refreshTasks(res, 'Question saved')
        },
        error => {
          console.log(error)
        }
      )
  }
  updateQuestion() {
    let question = Object.assign(this.questionForm.value)
    question.milestoneId = this.topicDetail.milestoneId
    question.episodeId = this.topicDetail.episodeId
    this.episodeTaskService
      .updateQuestion(question)
      .pipe(
        finalize(() => {
          this.submit_enabled = false
        })
      )
      .subscribe(
        res => {
          this.refreshTasks(res, 'Question updated')
        },
        error => {
          console.log(error)
        }
      )
  }

  submitMessage() {
    // this.messageForm.controls.severity.setValue(this.messageForm.value.severity ? 'YES' : 'NO');
    this.messageForm.controls.taskId.setValue(this.messageForm.value.taskId ? this.messageForm.value.taskId : this.topicId ? this.topicId : null);
    this.messageForm.patchValue(this.taskOptions);
    if (this.messageForm.valid) {
      this.submit_enabled = true
      if (this.messageForm.value.id) this.updateMessage()
      else this.saveMessage()
    }
  }
  saveMessage() {
    let message = Object.assign(this.messageForm.value)
    message.milestoneId = this.topicDetail.milestoneId
    message.episodeId = this.topicDetail.episodeId
    message.severity = message.severity ? 'YES' : 'NO'
    this.episodeTaskService.saveMessage(message)
      .pipe(finalize(() => {
        this.submit_enabled = false;
      }))
      .subscribe(res => {
        res.data.taskField = { uuid: 'taskMessageUuid', optionId: this.messageForm.value.optionId };
        this.refreshTasks(res, 'Message saved');
      },
        error => {
          console.log(error)
        }
      )
  }
  updateMessage() {
    let message = Object.assign(this.messageForm.value)
    message.milestoneId = this.topicDetail.milestoneId
    message.episodeId = this.topicDetail.episodeId
    message.severity = message.severity ? 'YES' : 'NO'
    this.episodeTaskService.updateMessage(message)
      .pipe(finalize(() => {
        this.submit_enabled = false;
      }))
      .subscribe(res => {
        this.refreshTasks(res, 'Message updated');
      },
        error => {
          console.log(error)
        }
      )
  }

  refreshTasks(response, action) {
    if (response.message) {
      this.toastr.error(response.message, 'Error')
    } else {
      let topic = action.includes('saved') ? response.data : action.includes('Qnrs') ? response.data : response.data[0]
      topic.active_topicId = this.topicId
      topic.active_task = action.includes('Action') ? 'taskTodos' : action.includes('Signature') ? 'taskSignatures' : action.includes('Question') ? 'taskQuestions' : action.includes('Qnrs') ? 'taskQnrs' : 'taskMessages';
      this.updateTopic.emit(topic)
      this.toastr.success(`${action} successfully.`, 'Success')
    }
    this.closeModal()
  }

  selectOpen(list, selected) {
    this[`${list}`].map(x => {
      x.disabled = x.value == selected ? true : false
    })
    this[`${list}`] = [...this[`${list}`]]
  }

  changeTask() {
    if (!this.todoForm.value.id) this.todoForm.reset()
    if (!this.messageForm.value.id) this.messageForm.reset()
    if (!this.signatureForm.value.id) this.signatureForm.reset();
    this.removeAddedSupervisor()
    if (!this.questionForm.value.id) {
      this.questionForm.reset()
      this.questionOption = new QuestionOption()
    }
    if (!this.questionnaireForm.value.id) {
      this.QnrsquestionOption = new QuestionOption()
      if (this.questionnaireQuestions.length > 0) {
        this.questionnaireQuestions = []
      }
      this.questionnaireForm.reset()
    }
    this.submitted = false
    this.create_submitted = false
    let taskList = this.task_type === 'todo' ? this.assignToList : this.messageToList
    const asssignToRole = taskList.find(x => x.value === this.assignedTo)
    const assignedRole = asssignToRole.roleCode && asssignToRole.roleCode === 'MP' ? asssignToRole.roleCode : asssignToRole.secondaryRoleCode
    if (this.task_type == 'todo') {
      this.todoForm.controls.assignedBy.setValue(this.assignedBy)
      this.todoForm.controls.assignedTo.setValue(this.assignedTo)
      this.todoForm.controls.assignedToRole.setValue(assignedRole)
    }
    if (this.task_type == 'signature') {
      this.signatureForm.controls.assignedBy.setValue(this.assignedBy)
      this.signatureForm.controls.assignedTo.setValue(this.assignedTo)
      this.signatureForm.controls.assignedToRole.setValue(assignedRole)
    }
    if (this.task_type == 'message') {
      this.messageForm.controls.messageFrom.setValue(this.assignedBy)
      this.messageForm.controls.messageTo.setValue(this.assignedTo)
      this.messageForm.controls.assignedToRole.setValue(assignedRole)
    }
    this.task_type === 'signature' && this.getAgreementTemplates();
    let form_name = this.task_type + 'Form'
    if (!this[form_name].value.id) this[form_name].controls.triggerPeriod.setValue('days')
    this.groups = this.task_type === 'question' && this.getQuestionGroups();
  }

  onQuestionSelect(event) {
    event.text.questionOptions.sort((a, b) => a.id - b.id)
    delete event.text.id
    this.selectedQuestion = event.text
  }

  searchQuestion(searchText: string) {
    this.questionnaireForm.controls.questionId.setValue(null)
    if (searchText.length > 2) {
      return this.episodeTaskService.searchQuestions(searchText).pipe(
        debounceTime(250),
        map((items: any) => {
          return items
        })
      )
    } else {
      return of([])
    }
  }
  questionTypeChange() {
    this.optionList = []
    this.QnrsOptionList = []
  }

  addQuestions() {
    if (this.selectedQuestion) {
      let index = this.questionnaireQuestions.findIndex(x => x.uuid == this.selectedQuestion.uuid)
      if (index && index < 0) {
        this.selectedQuestion.questionTypeCode = this.selectedQuestion.questionTypes.code
        this.selectedQuestion.questionOptions.map(x => {
          delete x.questionId
          delete x.id
        })
        this.questionnaireQuestions = [...this.questionnaireQuestions, this.selectedQuestion]
        this.selectedQuestion = null
        this.question_search_text.next('')
      } else {
        // showToaster
        this.question_search_text.next('')
      }
    } else {
      this.create_submitted = true
      this.QnrsValidator('set')
      this.questionnaireForm.controls['name'].clearValidators()
      this.questionnaireForm.controls['name'].updateValueAndValidity()

      if (this.questionnaireForm.valid) {
        let typeCode = this.questionTypeList.find(x => x.id == this.questionnaireForm.get('questionTypeId').value).code
        this.createSelectedQuestion = new Object()
        let temp_QnrsOptList = []
        this.createSelectedQuestion['questionTypeCode'] = typeCode
        this.createSelectedQuestion['questionTypeId'] = this.questionnaireForm.get('questionTypeId').value
        this.createSelectedQuestion['description'] = null
        this.createSelectedQuestion['questionHelp'] = this.questionnaireForm.get('questionHelp').value
        this.createSelectedQuestion['question'] = this.questionnaireForm.get('question').value
        if (this.QnrsOptionList) {
          this.QnrsOptionList.map(x => {
            let y = JSON.parse(JSON.stringify(x))
            y.severity = y.severity ? 'YES' : 'NO'
            temp_QnrsOptList.push(y)
          })
        }
        this.createSelectedQuestion['questionOptions'] = temp_QnrsOptList
        this.createSelectedQuestion['id'] = null

        if (this.questionnaireForm.get('questionTypeCode').value == 'CHECKBOX' || this.questionnaireForm.get('questionTypeCode').value == 'RADIO') {
          if (this.QnrsOptionList.length > 0) {
            this.questionnaireQuestions = [...this.questionnaireQuestions, this.createSelectedQuestion]
            this.QnrsValidator('clear')

            // clear panel - 2 form
            this.clearQuestionnaireForm()
            this.create_submitted = false
          }
        } else {
          this.questionnaireQuestions = [...this.questionnaireQuestions, this.createSelectedQuestion]
          this.QnrsValidator('clear')

          // clear panel - 2 form
          this.clearQuestionnaireForm()
        }
      }
    }
  }

  QnrsValidator(status) {
    if (status == 'clear') {
      this.questionnaireForm.controls['question'].clearValidators()
      this.questionnaireForm.controls['question'].updateValueAndValidity()
      this.questionnaireForm.controls['questionTypeCode'].clearValidators()
      this.questionnaireForm.controls['questionTypeCode'].updateValueAndValidity()
    }
    if (status == 'set') {
      this.questionnaireForm.controls['question'].setValidators([Validators.required])
      this.questionnaireForm.controls['question'].updateValueAndValidity()
      this.questionnaireForm.controls['questionTypeCode'].setValidators([Validators.required])
      this.questionnaireForm.controls['questionTypeCode'].updateValueAndValidity()
    }
  }
  clearQuestionnaireForm() {
    this.questionnaireForm.controls.questionTypeId.setValue('')
    this.questionnaireForm.controls.isRequired.setValue('')
    this.questionnaireForm.controls.question.setValue('')
    this.questionnaireForm.controls.questionHelp.setValue('')
    this.questionnaireForm.controls.questionTypeCode.setValue('')
  }

  removeQuestion(question) {
    if (question.id) {
      let index = this.questionnaireQuestions.findIndex(x => x.id == question.id)
      if (index > -1) {
        this.questionnaireQuestions.splice(index, 1)
      }
    } else {
      let index = this.questionnaireQuestions.findIndex(x => x.question == question.question)
      if (index > -1) {
        this.questionnaireQuestions.splice(index, 1)
      }
    }
  }

  submitQuestionnaire() {
    this.QnrsaddOptionValue()
    this.questionnaireForm.controls['name'].setValidators([Validators.required])
    this.questionnaireForm.controls['name'].updateValueAndValidity()
    this.QnrsValidator('clear')
    this.questionnaireForm.controls.taskId.setValue(this.questionnaireForm.value.taskId ? this.questionnaireForm.value.taskId : this.topicId ? this.topicId : null)
    this.questionnaireForm.controls.questions.setValue(this.questionnaireQuestions)
    this.questionnaireForm.patchValue(this.taskOptions)

    if (this.questionnaireForm.valid && this.questionnaireQuestions.length > 0) {
      this.submit_enabled = true
      if (this.questionnaireForm.value.id) this.updateQuestionnaire()
      else this.saveQuestionnaire()
    }
  }
  saveQuestionnaire() {
    let questionnaire = Object.assign(new QuestionnaireQuestions())
    questionnaire.taskId = this.questionnaireForm.get('taskId').value
    questionnaire.sequence = this.questionnaireForm.get('sequence').value
    questionnaire.parentUuid = this.questionnaireForm.get('parentUuid').value
    questionnaire.name = this.questionnaireForm.get('name').value
    questionnaire.isHighPriority = this.questionnaireForm.get('isHighPriority').value
    questionnaire.description = this.questionnaireForm.get('description').value
    questionnaire.questions = this.questionnaireForm.get('questions').value
    questionnaire.optionId = this.questionnaireForm.get('optionId').value
    questionnaire.isDependent = this.questionnaireForm.get('isDependent').value
    questionnaire.questions.map((x, index) => {
      x.sequence = index + 1
      return x
    })
    questionnaire.milestoneId = this.topicDetail.milestoneId
    questionnaire.episodeId = this.topicDetail.episodeId
    questionnaire.escalationDays = this.questionnaireForm.get('escalationDays').value
    this.episodeTaskService
      .saveQuestionnaire(questionnaire)
      .pipe(
        finalize(() => {
          this.submit_enabled = false
        })
      )
      .subscribe(
        res => {
          res.data.taskField = { uuid: 'taskQnrsUuid', optionId: this.questionnaireForm.value.optionId }
          this.refreshTasks(res, 'Qnrs saved')
        },
        error => {
          console.log(error)
        }
      )
  }
  updateQuestionnaire() {
    let questionnaire = Object.assign(new QuestionnaireQuestions())
    questionnaire.id = this.questionnaireForm.get('id').value
    questionnaire.sequence = this.questionnaireForm.get('sequence').value
    questionnaire.parentUuid = this.questionnaireForm.get('parentUuid').value
    questionnaire.taskId = this.questionnaireForm.get('taskId').value
    questionnaire.name = this.questionnaireForm.get('name').value
    questionnaire.isHighPriority = this.questionnaireForm.get('isHighPriority').value
    questionnaire.description = this.questionnaireForm.get('description').value
    questionnaire.questions = this.questionnaireForm.get('questions').value
    questionnaire.optionId = this.questionnaireForm.get('optionId').value
    questionnaire.isDependent = this.questionnaireForm.get('isDependent').value
    questionnaire.questions.map((x, index) => {
      // delete x.id;
      x.sequence = index + 1
      return x
    })
    questionnaire.milestoneId = this.topicDetail.milestoneId
    questionnaire.episodeId = this.topicDetail.episodeId
    questionnaire.escalationDays = this.questionnaireForm.get('escalationDays').value
    this.episodeTaskService
      .updateQuestionnaire(questionnaire)
      .pipe(
        finalize(() => {
          this.submit_enabled = false
        })
      )
      .subscribe(
        res => {
          this.refreshTasks(res, 'Qnrs updated')
        },
        error => {
          console.log(error)
        }
      )
  }
  onDisplayValue = (question?): string | undefined => {
    return question ? question.question : undefined
  }

  onDrop(item: any, list: any[]) {
    if (list.length) {
      const index = list.indexOf(item);
      list.splice(index, 1);
      this.sequenceChangeEnabled = false;
      this.tasks = [...list];
      let body = []
      this.tasks.map((a, index) => {
        a.sequence = index + 1
        let data = {
          type: a.field,
          id: a.id,
          sequence: a.sequence
        }
        body.push(data)
        return a
      })
      this.episodeTaskService
        .changeTaskSequence(body)
        .pipe(
          finalize(() => {
            this.sequenceChangeEnabled = true
          })
        )
        .subscribe(
          res => {
            // console.log('episode after update', this.tasks, res)
          },
          error => {
            console.log(error)
          }
        )
    }
  }
  deleteTask(task) {
    if (!task.isDependent) {
      this.toBeDeleteTask.emit(task)
      let index = this.tasks.findIndex(x => x.id == task.id)
      if (index > -1) {
        this.tasks.splice(index, 1)
      }
    }

    if (task.isDependent) {
      this.deleteDependent.emit(task)
    }
  }

  searchDocument(searchText: string) {
    if (searchText.length > 2) {
      return this.documentService.getDocument({ limit: 0, keyword: searchText })
        .pipe(
          debounceTime(250),
          map((items: any) => {
            return items.rows
          })
        );
    } else {
      return of([]);
    }
  }

  onDocumentValue = (procedure?): string | undefined => {
    return procedure ? procedure.name : undefined;
  }

  onDocumentSelect(e) {
    let path = e.text.documentPath
    if (this.task_type == "todo") {
      this.todoForm.controls.taskTodoLink.setValue(path);
      this.todoForm.controls.documentDisplayName.setValue(e.text.name)
    } else {
      this.messageForm.controls.documentPath.setValue(path);
      this.messageForm.controls.documentDisplayName.setValue(e.text.name)
    }
  }

  attachChange(form) {
    let form_name = form + "Form";
    this[form_name].controls['holistaMedia'].clearValidators()
    this[form_name].controls['holistaMedia'].updateValueAndValidity()
    this[form_name].controls['documentDisplayName'].clearValidators()
    this[form_name].controls['documentDisplayName'].updateValueAndValidity()
    this[form_name].controls.documentDisplayName.setValue('')

    if (form == 'message') {
      this[form_name].controls['documentPath'].clearValidators()
      this[form_name].controls['documentPath'].updateValueAndValidity()
      this[form_name].controls.documentPath.setValue('')
      if (this[form_name].value.type == 'link' && this[form_name].value.id && this.attachFileDetail) {
        this[form_name].controls.documentPath.setValue(this.attachFileDetail.documentPath)
        this[form_name].controls.documentDisplayName.setValue(this.attachFileDetail.documentDisplayName)
      }
    }
    if (form == 'todo') {
      this[form_name].controls['taskTodoLink'].clearValidators()
      this[form_name].controls['taskTodoLink'].updateValueAndValidity()
      this[form_name].controls.taskTodoLink.setValue('')
      if (this[form_name].value.type == 'link' && this[form_name].value.id && this.attachFileDetail) {
        this[form_name].controls.taskTodoLink.setValue(this.attachFileDetail.taskTodoLink)
        this[form_name].controls.documentDisplayName.setValue(this.attachFileDetail.documentDisplayName)
      }
    }
  }

  onAttach() {
    let form_name = this.task_type + "Form";
    this[form_name].controls.isAttach.setValue(true)
    this[form_name].controls.type.setValue('link')
  }

  closeAttach() {
    let form_name = this.task_type + "Form";
    this.attachChange(this.task_type)
    this[form_name].controls.isAttach.setValue(false)
    this[form_name].controls.type.setValue('')
  }

  assignedSelected(event, type) {
    let form_name = this.task_type + 'Form'
    const assignedToList = (form_name == 'todoForm' || form_name === 'signatureForm') ? 'assignToList' : 'messageToList'
    this[form_name].controls[type].setValue(event.roleCode)
    const assignedByRole = this[form_name].value.assignedByRole
    const assignedToRole = this[form_name].value.assignedToRole
    if (assignedByRole == 'MP' && assignedToRole == 'SV') {
      const assignTo = (form_name == 'todoForm' || form_name === 'signatureForm') ? 'assignedTo' : 'messageTo'
      this.filterSupervisorOfList(form_name, assignTo, assignedToList)
      this[form_name].controls["supervisorOfId"].setValidators([Validators.required])
      this[form_name].controls["supervisorOfId"].updateValueAndValidity();
    } else {
      this.supervisorOf = []
      this[form_name].controls.supervisorOf.setValue('')
      this[form_name].controls['supervisorOfId'].clearValidators()
      this[form_name].controls['supervisorOfId'].updateValueAndValidity()
    }
  }

  filterSupervisorOfList(form_name, assignTo, assignedToList) {
    let supervisors = this[assignedToList].filter(x => x.supervisor && x.supervisor.supervisorId == this[form_name].value[assignTo] && (x.supervisor.userId == this.assignedCcId || x.supervisor.userId == this.assignedEsId))
    this.supervisorOf = []
    this.supervisorOf = [...supervisors]
  }

  selectedSupervisorOf(event) {
    let form_name = this.task_type + 'Form'
    this[form_name].controls.supervisorOf.setValue(event.roleCode)
  }

  getAgreementTemplates() {
    this.isLoading.agreementTemplateList = true;
    const episodeId = this._activatedRoute.snapshot.params.episodeId;
    this._episodeService.getEpisode(episodeId)
      .pipe(finalize(() => { }))
      .subscribe(
        (response: any) => {
          const params = {
            module: PATHWAY_CONSTANTS.DOCUSIGN_AGREEMENT_TEMPLATE,
            subModule: response.networkCode
          };
          this._pathwayService.getDocuSignAgreementTemplates(params)
            .pipe(finalize(() => { this.isLoading.agreementTemplateList = false }))
            .subscribe(res => {
              if (res && res.count) {
                this.agreementTemplateList = res.rows.map(template => ({ ...template, label: template.name }));
              };
            }, error => {
              this.toastr.error(error);
              console.log('Error getting agreement templates', error);
            });
        }, error => {
          this.toastr.error(error);
          console.log('Error getting episodes', error);
        }
      )
  }

  onGroupDeselected() {
    this.questionForm.controls.groupCode.setValue(null);
  }
}
