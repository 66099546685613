import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap } from 'rxjs/operators';
import { ClientService, NetworkService, ToasterService, ValueBasedProgramService, PurchaserService, EpisodeOfCareService, FundingSourceService } from '../../services';
import { AccessType, FormatPhoneNumber, HolistaUtils, Storage } from '../../utils';
import { US_STATES, RestrictSpace, PhoneFormat, MaskConstant, ValidEmail, ADD_SUCCESS, UPDATE_SUCCESS, DELETE_SUCCESS, DELETE_WARNING, MessageConstants, CLIENT_ADMINS } from '../../constants'
import { Observable, of, Subject } from 'rxjs';
import * as HeaderBreadCrumbActions from '../../action';
import { Store } from '@ngrx/store';
import { TabsetComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  clientForm: FormGroup
  valueBasedProgramForm: FormGroup
  bankInfoForm: FormGroup
  clients: any = [];
  CLIENT_ADMINS = CLIENT_ADMINS;
  networks: any = []
  networksForFilter: any = []
  loading = false
  query = {
    keyword: '',
    page: 1,
    limit: 10,
    sortType: 'desc',
    sortBy: 'createdAt',
    networkCode: '',
    code: '',
    searchFields: 'tin,name'
  }

  totalCount = 0;
  usStates = US_STATES
  submit_enabled = false
  submitted = false
  result = { clients: true, searchedClients: true };
  searchModelChanged: Subject<string> = new Subject<string>();
  deleteModelName: string = '';
  childObj: any = {}
  deleteId: string
  message: string
  reverse: boolean = true;
  mask: any[] = MaskConstant.PHONE;
  bankInfo_submitted = false
  valueBased_submit_enabled = false
  bankInfo_submit_enabled = false
  valueBased_submitted = false
  episode_of_care_search_text = new Subject();
  episode_of_care_results: Observable<any>;
  bank_loading = false
  purchaser_loading = false
  value_based_loading = false
  purchaserDetail: any
  purchaser_vbp_loading = false
  purchaser_funding_source_loading = false
  vbpClientNetworks: any = []
  selectedClientNetwork = ''
  associatedEocList: any = []
  selectedEpisodeOfCares: any = []
  associateEpisodeOfCares: any = []
  totalClients: any = []
  backupNetworks: any = []
  clientNetworks: any = []
  selectedValueBasedProgramEOCs: any = {}
  user: any

  @ViewChild('clientModal', { static: true }) public clientModal;
  @ViewChild('valueBaseProgramModal', { static: true }) public valueBaseProgramModal;
  @ViewChild('bankInfoModal', { static: true }) public bankInfoModal;
  @ViewChild('purchaserModal', { static: true }) public purchaserModal;
  @ViewChild('clientExpandTabs', { static: false }) clientExpandTabs: TabsetComponent
  @ViewChild('viewPurchaserDetailTabs', { static: true }) viewPurchaserDetailTabs: TabsetComponent
  @ViewChild('associatedEOCModal', { static: true }) public associatedEOCModal

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private holistaUtil: HolistaUtils,
    private networkService: NetworkService,
    private store: Store<{ bread_crumbs: any }>,
    public utilityAccess: AccessType,
    private toastr: ToasterService,
    private valueBasedProgramService: ValueBasedProgramService,
    private cdRef: ChangeDetectorRef,
    private purchaserService: PurchaserService,
    private episodeOfCareService: EpisodeOfCareService,
    private fundingSourceService: FundingSourceService,
    private _storage: Storage,
    private _formatPhone: FormatPhoneNumber,
    private _messageConstants: MessageConstants
  ) {
    this.setClientForm()
    this.setValueBasedProgramForm()
    this.setBankInfoForm()
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
      Object.assign(this.query, { page: 1, keyword });
      this.getClients();
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setClientForm() {
    this.clientForm = this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      code: [''],
      networks: ['', Validators.required],
      tin: ['', [Validators.required, Validators.pattern(/^[0-9]{9}$/)]],
      addressLine1: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      addressLine2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: [null, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
      zip4: [null, Validators.pattern(/^[0-9]{4}$/)],
      contactName: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      contactEmail: ['', [Validators.required, Validators.pattern(ValidEmail)]],
      contactPhone: ['', [Validators.required, Validators.pattern(PhoneFormat)]],
      generateEob: [false],
      phone: ['', [Validators.required, Validators.pattern(PhoneFormat)]]
    })
  }

  setValueBasedProgramForm() {
    this.valueBasedProgramForm = this.formBuilder.group({
      id: [null],
      code: ['', Validators.required],
      networkCode: ['', Validators.required],
      name: ['', [Validators.pattern(RestrictSpace), Validators.required]],
      description: ['', Validators.pattern(RestrictSpace)],
      episodeOfCares: [''],
      clientCode: ['']
    })
  }

  setBankInfoForm() {
    this.bankInfoForm = this.formBuilder.group({
      id: [null],
      uuid: [''],
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      accountNumber: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      routingNumber: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      contactName: ['', Validators.pattern(RestrictSpace)],
      contactEmail: ['', Validators.pattern(ValidEmail)],
      contactPhone: ['', Validators.pattern(PhoneFormat)],
      addressLine1: ['', Validators.pattern(RestrictSpace)],
      addressLine2: ['', Validators.pattern(RestrictSpace)],
      city: ['', Validators.pattern(RestrictSpace)],
      state: [''],
      zip: [null, Validators.pattern(/^[0-9]{5}$/)],
      zip4: [null, Validators.pattern(/^[0-9]{4}$/)],
      clientCode: [''],
      bankUuid: [''],
      contactUuid: ['']
    })
  }

  ngOnInit() {
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    this.query.code = this.user.referenceType !== 'PLATFORM' ? this.user.referenceCode : ''
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Clients', path: '/clients' }));
    this.episode_of_care_results = this.episode_of_care_search_text
      .pipe(
        startWith(this.episode_of_care_search_text),
        switchMap((episode_of_care_search_text: string) => this.searchEpisodeOfCare(episode_of_care_search_text))
      )
    this.getClients()
    CLIENT_ADMINS.includes(this.user.roleCode) ? this.getClientNetworks() : this.getNetworks()
  }

  getClients() {
    this.loading = true
    this.result.searchedClients = true
    this.result.clients = true
    if (!this.query.keyword) delete this.query.keyword
    this.clientService.getClients(this.query)
      .pipe(finalize(() => { this.loading = false }))
      .subscribe(res => {
        if (res.count != 0) {
          this.totalCount = res.count
          res.rows.map(client => {
            client.purchasers = []
            client.valueBasedPrograms = []
            client.banks = []
            client.label = client.name
            client.value = client.code
            client.networkName = client.networks.length > 0 ? client.networks.map(network => network.name) : []
            return client
          })
          this.clients = res.rows
        } else {
          this.clients = [];
          this.query.keyword ? (this.result.searchedClients = false) : (this.result.clients = false);
        }
      }, (error) => {
        this.toastr.showError(error);
        console.log('Error', error)
      })
  }

  showClientDetails(client, index) {
    if (client.collapsed) {
      this.getValueBasedPrograms(client)
      this.getPurchasers(client)
      this.getBanks(client)
    } else {
      if (this.clients[index] && this.clients[index].banks && this.clients[index].banks.lehgth != 0) {
        this.clients[index].banks = this.clients[index].banks.map(bank => {
          bank.isAccountHide = true
          bank.isRoutingHide = true
          return bank
        })
      }
    }
  }

  getClientNetworks() {
    this.clientService.getNetworksByClientCode(this.user.referenceCode)
      .subscribe(res => {
        res.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.backupNetworks = JSON.parse(JSON.stringify(res))
        this.networks = JSON.parse(JSON.stringify(res))
      },
        (error) => {
          console.log('Error', error)
        })
  }

  getNetworks() {
    this.networkService.getAll({ limit: 0, fields: 'code,name' }).subscribe(res => {
      res.rows.map(network => {
        network.label = network.name
        network.value = network.code
        return network
      })
      this.backupNetworks = JSON.parse(JSON.stringify(res.rows))
      this.networks = JSON.parse(JSON.stringify(res.rows))
      this.networksForFilter = JSON.parse(JSON.stringify(res.rows))
    },
      (error) => {
        console.log('Error', error)
      })
  }

  filterByNetwork(event, type?) {
    this.query.networkCode = (type && type == 'remove') ? '' : event.value;
    this.query.page = 1;
    if (!type)
      this.networksForFilter = this.networksForFilter.map(x => {
        if (x.value == event.value) x.disabled = true
        else x.disabled = false
        return x
      })
    else
      this.networksForFilter = this.networksForFilter.map(x => { x.disabled = false; return x })
    this.getClients();
  }

  async editClient(client) {
    if (this.utilityAccess.searchAccess('CLIM', 'isEditable')) {
      await this.getValueBasedPrograms(client)
      const vbps = client.valueBasedPrograms.map(vbp => vbp.networkCode)
      this.networks = this.networks.map(network => {
        if (vbps && vbps.length != 0 && vbps.includes(network.code)) network.disabled = true
        return network
      })
      const networks = client.networks.map(network => network.code)
      client.phone = this._formatPhone.phoneNumber(client.phone);
      client.contactPhone = this._formatPhone.phoneNumber(client.contactPhone);
      this.clientForm.patchValue(client)
      this.clientForm.controls.networks.setValue(networks)
      this.clientModal.show()
    }
  }

  openClientModal() {
    if (this.utilityAccess.searchAccess('CLIM', 'isEditable')) this.clientModal.show()
  }

  closeClientModal() {
    this.clientForm.reset()
    this.clientModal.hide()
    this.networks = JSON.parse(JSON.stringify(this.backupNetworks))
    this.submitted = false
  }

  pageChanged(event) {
    this.query.page = event;
    this.getClients();
  }

  change(text) {
    this.searchModelChanged.next(text);
  }

  submit(type) {
    this.submitted = true;
    if (type == 'bankInfo') this.submitBankInfo()
    if (type == 'client') this.submitClient()
    if (type == 'valueBasedProgram') this.submitValueBasedProgram()
  }

  submitClient() {
    if (this.clientForm.valid) {
      this.submit_enabled = true;
      if (this.clientForm.value.id) this.updateClient();
      else this.saveClient();
    }
  }

  saveClient() {
    const client = JSON.parse(JSON.stringify(Object.assign(this.clientForm.value)))
    client.contactPhone = client.contactPhone ? client.contactPhone.replace(/\D/g, '') : ''
    client.phone = client.phone ? client.phone.replace(/\D/g, '') : ''
    client.networks = client.networks.map(code => ({ code: code }))
    client.generateEob = client.generateEob ? true : false
    client.zip4 = client.zip4 ? client.zip4 : null
    delete client.id
    delete client.uuid
    delete client.code
    this.clientService.saveClient(client)
      .pipe(finalize(() => { this.submit_enabled = false }))
      .subscribe(res => {
        this.refreshClients(res, 'saved')
      },
        (error) => {
          this.submit_enabled = false
          this.toastr.showError(error);
          console.log('Error', error)
        })
  }

  updateClient() {
    const client = JSON.parse(JSON.stringify(Object.assign(this.clientForm.getRawValue())))
    client.contactPhone = client.contactPhone ? client.contactPhone.replace(/\D/g, '') : ''
    client.phone = client.phone ? client.phone.replace(/\D/g, '') : ''
    client.networks = client.networks.map(code => ({ code: code }))
    client.generateEob = client.generateEob ? true : false
    client.zip4 = client.zip4 ? client.zip4 : null
    this.clientService.updateClient(client)
      .pipe(finalize(() => { this.submit_enabled = false }))
      .subscribe(res => {
        this.refreshClients(res, 'updated')
      },
        (error) => {
          this.submit_enabled = false
          this.toastr.showError(error);
          console.log('Error', error)
        })
  }

  refreshClients(response, action) {
    if (response.message) {
      this.toastr.showError(response)
    } else {
      response.networkName = response.networks.length > 0 ? response.networks.map(network => network.name) : []
      if (this.clients && this.clients.length > 0) {
        const index = this.clients.findIndex(x => x.id == response.id);
        if (index > -1) this.clients[index] = response;
        else {
          this.query.page = 1
          this.getClients()
        }
      } else this.getClients()
      this.toastr.displaySuccess(action === 'saved' ? ADD_SUCCESS : UPDATE_SUCCESS)
    }
    this.closeClientModal();
  }

  closePurchaserModal() {
    this.purchaserDetail = {}
    this.purchaserModal.hide()
  }

  delete(model?, type?) {
    if (this.utilityAccess.searchAccess('CLIM', 'isEditable')) {
      this.childObj = model;
      this.deleteId = model.code ? model.code : model.id;
      this.deleteModelName = type;
      model.name = type == 'bankInformation' ? model.bank.name : model.name
      const deleteWarningMessage = this._messageConstants.getMessage(DELETE_WARNING, model.name)
      this.message = deleteWarningMessage.value
    }
  }

  deleteConfirm(id) {
    if (id) {
      switch (this.deleteModelName) {
        case 'client':
          this.deleteClient(id)
          break;
        case 'valueBasedProgram':
          this.deleteValueBasedProgram()
          break;
        case 'bankInformation':
          this.deleteBankInformation()
          break;

        default:
          break;
      }
    }
    else this.deleteId = null;
  }

  deleteClient(code) {
    this.clientService.delete(code, { paramType: 'code' })
      .subscribe((response: any) => {
        if (response) {
          let index = this.clients.findIndex(x => x.code === code);
          if (index > -1) {
            this.toastr.displaySuccess(DELETE_SUCCESS);
            this.getClients()
          }
          this.childObj = {}
        }
      }, (error) => {
        console.log("Error deleting Client", error);
        this.toastr.showError(error)
        this.deleteId = null;
      })
  }

  deleteValueBasedProgram() {
    this.valueBasedProgramService.deleteValueBasedProgram(this.childObj.code)
      .subscribe((response: any) => {
        if (response) {
          let clientIndex = this.clients.findIndex(x => x.code == this.childObj.clientCode);
          if (clientIndex > -1) {
            let valueBasedIndex = this.clients[clientIndex].valueBasedPrograms.findIndex(f => f.id == this.childObj.id)
            if (valueBasedIndex > -1) {
              this.clients[clientIndex].valueBasedPrograms.splice(valueBasedIndex, 1)
              this.toastr.displaySuccess(DELETE_SUCCESS);
              const vbps = this.clients[clientIndex].valueBasedPrograms.map(vbp => vbp.networkCode)
              if (vbps && vbps.length != 0) {
                this.clients = this.clients.map(client => {
                  client.networks.map(network => {
                    if (!vbps.includes(network.code)) network.disabled = false
                    return network
                  })
                  return client
                })
              }
            }
          }
          this.childObj = {}
        }
      }, (error) => {
        console.log("Error deleting Value Based Program", error);
        this.toastr.showError(error)
        this.deleteId = null;
      })
  }

  deleteBankInformation() {
    this.clientService.deleteBank(this.childObj.uuid)
      .subscribe((response: any) => {
        if (response) {
          let clientIndex = this.clients.findIndex(x => x.code == this.childObj.referenceCode);
          if (clientIndex > -1) {
            let bankInfoIndex = this.clients[clientIndex].banks.findIndex(f => f.id == this.childObj.id)
            if (bankInfoIndex > -1) {
              this.clients[clientIndex].banks.splice(bankInfoIndex, 1)
              this.toastr.displaySuccess(DELETE_SUCCESS);
            }
          }
          this.childObj = {}
        }
      }, (error) => {
        console.log("Error deleting Bank Information ", error);
        this.toastr.showError(error)
        this.deleteId = null;
      })
  }

  setOrder(value: string) {
    if (this.query.sortBy === value) {
      this.reverse = !this.reverse;
      this.query.sortType = this.query.sortType === "desc" ? "asc" : "desc";
    }
    else {
      this.reverse = true;
      this.query.sortType = "desc";
    }
    this.query.sortBy = value;
    this.getClients();
  }

  async getValueBasedPrograms(client) {
    if (!client.isVbpLoad) this.value_based_loading = true
    if (!client.isVbpLoad) {
      await this.valueBasedProgramService.getValueBasedProgramByClentCode({ clientCode: client.code, limit: 0 })
        .then((res: any) => {
          this.value_based_loading = false
          const clientIndex = this.clients.findIndex(c => c.id == client.id)
          if (clientIndex > -1) {
            this.clients[clientIndex].valueBasedPrograms = res.rows
            this.clients[clientIndex].isVbpLoad = true
          }
        },
          (error) => {
            this.value_based_loading = false
            console.log('Error', error)
          })
    }
  }

  createValueBasedProgram(client) {
    if (this.utilityAccess.searchAccess('CLIM', 'isEditable')) {
      if (client.networks && client.networks.length != 0) {
        this.vbpClientNetworks = client.networks.map(client => {
          client.label = client.name
          client.value = client.code
          client.disabled = false
          return client
        })
      } else this.vbpClientNetworks = []
      this.valueBasedProgramForm.controls.clientCode.setValue(client.code)
      this.valueBaseProgramModal.show()
    }
  }

  async editValueBasedProgram(valueBasedProgram, client) {
    if (this.utilityAccess.searchAccess('CLIM', 'isEditable')) {
      this.vbpClientNetworks = client.networks.map(client => {
        client.label = client.name
        client.value = client.code
        return client
      })
      await this.episodeOfCareService.getEocByNetworkCode({ networkCode: valueBasedProgram.networkCode, defaultIncludes: false, limit: 0 }).then((res: any) => {
        this.associateEpisodeOfCares = res.rows
      })
      this.selectedEpisodeOfCares = JSON.parse(JSON.stringify(valueBasedProgram.episodeOfCares))
      const notAssociatedEOCs = this.associateEpisodeOfCares.filter(a => !this.selectedEpisodeOfCares.map(s => s.id).includes(a.id))
      this.selectedEpisodeOfCares.map(s => {
        this.associateEpisodeOfCares.map(a => {
          if (a.id == s.id) a.selected = true
          if (!notAssociatedEOCs || notAssociatedEOCs.length == 0) a.selectAll = true
          else a.selectAll = false
          return a
        })
      })

      this.selectedClientNetwork = valueBasedProgram.networkCode
      this.valueBasedProgramForm.patchValue(valueBasedProgram)
      this.valueBaseProgramModal.show()
    }
  }

  selectClientNetwork(network) {
    this.selectedEpisodeOfCares = []
    this.associateEpisodeOfCares = []
    this.selectedClientNetwork = network.code
    this.episodeOfCareService.getEocByNetworkCode({ networkCode: this.selectedClientNetwork, defaultIncludes: false, limit: 0 }).then((res: any) => {
      this.associateEpisodeOfCares = res.rows
    })
      .catch((error) => {
        console.log('Error', error)
      })
  }

  selectAllEOC(event) {
    if (event.checked) {
      this.associateEpisodeOfCares = this.associateEpisodeOfCares.map(a => {
        a.selected = true
        a.selectAll = true
        return a
      })
      this.selectedEpisodeOfCares = [...new Set(this.associateEpisodeOfCares)]
    } else {
      this.associateEpisodeOfCares.map(a => {
        a.selected = false
        a.selectAll = true
        return a
      })
      this.selectedEpisodeOfCares = []
    }
  }

  selectClientEoc(event, eoc) {
    if (event.checked) {
      if (!this.selectedEpisodeOfCares || this.selectedEpisodeOfCares.length == 0) this.selectedEpisodeOfCares.push(eoc)
      else {
        if (!this.selectedEpisodeOfCares.map(x => x.id).includes(eoc.id)) this.selectedEpisodeOfCares.push(eoc)
      }
      this.selectedEpisodeOfCares = [...new Set(this.selectedEpisodeOfCares)]
      const notAssociatedEOCs = this.associateEpisodeOfCares.filter(x => !x.selected && x.id != eoc.id)
      this.selectedEpisodeOfCares.map(s => {
        this.associateEpisodeOfCares.map(a => {
          if (notAssociatedEOCs.length == 0) a.selectAll = true
          if (a.id == s.id) a.selected = true
          return a
        })
      })
    } else {
      this.selectedEpisodeOfCares = [... new Set(this.selectedEpisodeOfCares.filter(x => x.id !== eoc.id))]
      this.associateEpisodeOfCares.map(a => {
        a.selectAll = false
        if (a.id == eoc.id) a.selected = false
        return a
      })
    }
  }

  /**
 * Search episode of care based on keyword
 * @param {string} searchText keyword to be search
 */
  searchEpisodeOfCare(searchText: string) {
    if (searchText.length > 2) {
      let data: any = this.associateEpisodeOfCares.filter(el => {
        return el.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      })
      return of([...data])
    }
    else {
      return of([]);
    }
  }

  /**
   * Display selected search episode of care name
   * @param {any}eoc selected search episode of care
   */
  onDisplayValue = (eoc?): string | undefined => {
    return eoc ? eoc.name : undefined;
  }

  selectedEoc(event) {
    this.episode_of_care_search_text.next('')
    event.checked = true
    this.selectClientEoc(event, event.text)
  }

  showAssociatedEOC(vbp, eocs) {
    const data = { vbp, eocs }
    this.selectedValueBasedProgramEOCs = data
    this.associatedEOCModal.show()
  }

  closeAssociatedEOCModal() {
    this.associatedEOCModal.hide()
    this.selectedValueBasedProgramEOCs = {}
  }

  submitValueBasedProgram() {
    const description = this.valueBasedProgramForm.value.description ? this.valueBasedProgramForm.value.description.replace(/[^a-zA-Z0-9_ ]/g, "") : ''
    this.valueBasedProgramForm.controls.description.setValue(description)
    if (this.valueBasedProgramForm.valid) {
      this.submit_enabled = true;
      this.valueBasedProgramForm.controls.episodeOfCares.setValue(this.selectedEpisodeOfCares.map(eoc => eoc.uuid))
      if (this.valueBasedProgramForm.value.id) this.updateValueBasedProgram();
      else this.saveValueBasedProgram();
    }
  }

  saveValueBasedProgram() {
    const valueBasedProgram = this.valueBasedProgramForm.value
    delete valueBasedProgram.id
    this.valueBasedProgramService.saveValueBasedProgram(valueBasedProgram)
      .pipe(finalize(() => { this.submit_enabled = false }))
      .subscribe(res => {
        this.refreshValueBasedPrograms(res, 'saved')
      },
        (error) => {
          this.toastr.showError(error);
          this.submit_enabled = false
          console.log('Error', error)
        })
  }

  updateValueBasedProgram() {
    this.valueBasedProgramService.updateValueBasedProgram(this.valueBasedProgramForm.getRawValue())
      .pipe(finalize(() => { this.submit_enabled = false }))
      .subscribe(res => {
        this.refreshValueBasedPrograms(res, 'updated')
      },
        (error) => {
          this.submit_enabled = false
          this.toastr.showError(error);
          console.log('Error', error)
        })
  }

  refreshValueBasedPrograms(response, action) {
    if (response.message) {
      this.toastr.displayWarning(response.message);
    } else {
      const valueBasedProgram = response
      let clientIndex = this.clients.findIndex(x => x.code == valueBasedProgram.clientCode);
      if (clientIndex > -1) {
        this.clients[clientIndex].networks.map(network => {
          if (network.value == response.networkCode) network.disabled = true
        })
        if (this.clients[clientIndex].valueBasedPrograms && this.clients[clientIndex].valueBasedPrograms.length != 0) {
          let valueBasedIndex = this.clients[clientIndex].valueBasedPrograms.findIndex(f => f.id == valueBasedProgram.id)
          if (valueBasedIndex > -1) this.clients[clientIndex].valueBasedPrograms[valueBasedIndex] = valueBasedProgram
          else this.clients[clientIndex].valueBasedPrograms.unshift(valueBasedProgram)
        } else {
          this.clients[clientIndex].valueBasedPrograms = []
          this.clients[clientIndex].valueBasedPrograms.push(valueBasedProgram)
        }
      }
      this.toastr.displaySuccess(action === 'saved' ? ADD_SUCCESS : UPDATE_SUCCESS);
    }
    this.closeValueBasedModal();
  }

  closeValueBasedModal() {
    this.valueBasedProgramForm.reset()
    this.valueBaseProgramModal.hide()
    this.submitted = false
    this.vbpClientNetworks = []
    this.selectedClientNetwork = ''
    this.selectedEpisodeOfCares = []
  }

  getPurchasers(client) {
    if (!client.isPurchaserLoad) this.purchaser_loading = true
    if ((!client.purchasers || client.purchasers.length == 0) && !client.isPurchaserLoad) {
      this.clientService.getPurchasersByClientCode(client.code, { defaultIncludes: false })
        .pipe(finalize(() => { this.purchaser_loading = false }))
        .subscribe(res => {
          const clientIndex = this.clients.findIndex(c => c.id == client.id)
          if (clientIndex > -1) {
            this.clients[clientIndex].purchasers = res.rows
            this.clients[clientIndex].isPurchaserLoad = true
          }
        },
          (error) => {
            console.log('Error', error)
          })
    }
  }

  viewClientPurchaser(purchaser, client) {
    this.viewPurchaserDetailTabs.setActiveTab(1)
    this.purchaserDetail = { purchaser, client }
    this.purchaserModal.show()
    this.getPurchaserValueBasedPrograms()
    this.getFundingSources()
  }

  getPurchaserValueBasedPrograms() {
    const client = this.purchaserDetail.client
    const purchaser = this.purchaserDetail.purchaser
    const clientIndex = this.clients.findIndex(x => x.id == client.id)
    if (clientIndex > -1) {
      if (client.purchasers && client.purchasers.length > 0) {
        const purchaserIndex = client.purchasers.findIndex(y => y.id == purchaser.id)
        if (purchaserIndex > -1) {
          if (!this.clients[clientIndex].purchasers[purchaserIndex].valueBasedPrograms || this.clients[clientIndex].purchasers[purchaserIndex].valueBasedPrograms.length == 0) {
            this.purchaser_vbp_loading = true
            this.purchaserService.getValueBasedProgramsByPurchaserCode(this.purchaserDetail.purchaser.code, { limit: 0 })
              .then((res: any) => {
                this.purchaser_vbp_loading = false
                if (res.count != 0) {
                  res.rows.map(async (vbp: any) => {
                    vbp.episodeOfCares = await this.getEpisodeOfCaresByVBP(vbp.vbpCode)
                    return vbp
                  })
                  this.clients[clientIndex].purchasers[purchaserIndex].valueBasedPrograms = res.rows
                }
              },
                (error) => {
                  this.purchaser_vbp_loading = false
                  console.log('Error', error)
                })
          }
        }
      }
    }
  }

  async getEpisodeOfCaresByVBP(vbpCode) {
    return await this.episodeOfCareService.getEocByVbpCode(vbpCode, { defaultIncludes: false, limit: 0 }).then((res: any) => {
      return res.rows
    })
      .catch((error => {
        console.log('Error', error)
      }))
  }

  getFundingSources() {
    const client = this.purchaserDetail.client
    const purchaser = this.purchaserDetail.purchaser
    const clientIndex = this.clients.findIndex(x => x.id == client.id)
    if (clientIndex > -1) {
      if (client.purchasers && client.purchasers.length > 0) {
        const purchaserIndex = client.purchasers.findIndex(y => y.id == purchaser.id)
        if (purchaserIndex > -1) {
          if (!(this.clients[clientIndex].purchasers[purchaserIndex].fundingSource && this.clients[clientIndex].purchasers[purchaserIndex].fundingSource.success)) {
            this.purchaser_funding_source_loading = true
            this.fundingSourceService.getFundingSourceByPurchaserCode(this.purchaserDetail.purchaser.code, { defaultIncludes: false, paramType: 'purchaserCode' })
              .pipe(finalize(() => { this.purchaser_funding_source_loading = false }))
              .subscribe((res: any) => {
                this.clients[clientIndex].purchasers[purchaserIndex].fundingSource = res
              },
                (error) => {
                  console.log('Error', error)
                })
          }
        }
      }
    }
  }

  getBanks(client) {
    if (!client.isBankLoad) this.bank_loading = true
    if (!client.isBankLoad) {
      this.clientService.getBanksByClientCode(client.code)
        .pipe(finalize(() => { this.bank_loading = false }))
        .subscribe(res => {
          const clientIndex = this.clients.findIndex(c => c.id == client.id)
          if (clientIndex > -1) {
            this.clients[clientIndex].isBankLoad = true
            res.rows.map(bank => {
              this.hideBankInfo(bank)
            })
            this.clients[clientIndex].banks = res.rows
          }
        },
          (error) => {
            console.log('Error', error)
          })
    }
  }

  addBankInfo(client) {
    if (this.utilityAccess.searchAccess('CLIM', 'isEditable')) {
      this.bankInfoForm.controls.clientCode.setValue(client.code)
      this.bankInfoModal.show()
    }
  }

  editBank(bank) {
    if (this.utilityAccess.searchAccess('CLIM', 'isEditable')) {
      bank.name = bank.bank.name
      bank.addressLine1 = bank.bank.addressLine1
      bank.addressLine2 = bank.bank.addressLine2
      bank.city = bank.bank.city
      bank.state = bank.bank.state
      bank.zip = bank.bank.zip
      bank.zip4 = bank.bank.zip4
      bank.contactName = bank.contact.name
      bank.contactEmail = bank.contact.email
      bank.contactPhone = this._formatPhone.phoneNumber(bank.contact.phone)
      bank.clientCode = bank.referenceCode
      this.bankInfoForm.patchValue(bank)
      this.bankInfoModal.show()
    }
  }

  submitBankInfo() {
    if (this.bankInfoForm.valid) {
      this.submit_enabled = true;
      if (this.bankInfoForm.value.id) this.updateBankInfo();
      else this.saveBankInfo();
    }
  }

  saveBankInfo() {
    const payLoad = JSON.parse(JSON.stringify(Object.assign(this.bankInfoForm.value)))
    payLoad.contactPhone = payLoad.contactPhone ? payLoad.contactPhone.replace(/\D/g, '') : ''
    payLoad.referenceCode = payLoad.clientCode
    payLoad.bank = {
      name: payLoad.name,
      addressLine1: payLoad.addressLine1,
      addressLine2: payLoad.addressLine2,
      city: payLoad.city,
      state: payLoad.state,
      zip: payLoad.zip ? payLoad.zip : null,
      zip4: payLoad.zip && payLoad.zip4 ? payLoad.zip4 : null
    }
    payLoad.contact = { name: payLoad.contactName, email: payLoad.contactEmail, phone: payLoad.contactPhone }
    const props = ['id', 'contactName', 'contactEmail', 'contactPhone', 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'zip4', 'clientCode', 'uuid', 'contactUuid', 'bankUuid']
    props.forEach(element => {
      delete payLoad[element]
    });
    this.clientService.saveBankInfo(payLoad)
      .pipe(finalize(() => { this.submit_enabled = false }))
      .subscribe(res => {
        this.hideBankInfo(res)
        this.refreshbankInfo(res, 'saved')
      },
        (error) => {
          console.log('Error', error)
          this.toastr.showError(error)
        })
  }

  updateBankInfo() {
    const payLoad = JSON.parse(JSON.stringify(Object.assign(this.bankInfoForm.value)))
    payLoad.referenceCode = payLoad.clientCode
    payLoad.contactPhone = payLoad.contactPhone ? payLoad.contactPhone.replace(/\D/g, '') : ''
    payLoad.bank = {
      name: payLoad.name,
      addressLine1: payLoad.addressLine1,
      addressLine2: payLoad.addressLine2,
      city: payLoad.city,
      state: payLoad.state,
      zip: payLoad.zip ? payLoad.zip : null,
      zip4: payLoad.zip && payLoad.zip4 ? payLoad.zip4 : null
    }
    payLoad.contact = { name: payLoad.contactName, email: payLoad.contactEmail, phone: payLoad.contactPhone }
    const props = ['contactName', 'contactEmail', 'contactPhone', 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'zip4', 'clientCode']
    props.forEach(element => {
      delete payLoad[element]
    });
    this.clientService.updateBankInfo(payLoad)
      .pipe(finalize(() => { this.submit_enabled = false }))
      .subscribe(res => {
        this.hideBankInfo(res)
        this.refreshbankInfo(res, 'updated')
      },
        (error) => {
          this.submit_enabled = false
          console.log('Error', error)
          this.toastr.showError(error)
        })
  }

  hideBankInfo(bank) {
    bank.hideAccountNumber = bank.accountNumber.replace(/\d(?=\d{0})/g, "*");
    bank.hideRoutingNumber = bank.routingNumber.replace(/\d(?=\d{0})/g, "*");
    bank.isAccountHide = true
    bank.isRoutingHide = true
    return bank
  }

  refreshbankInfo(response, action) {
    if (response.message) {
      this.toastr.displayWarning(response.message);
    } else {
      const bank = response
      let clientIndex = this.clients.findIndex(x => x.code == bank.referenceCode);
      if (clientIndex > -1) {
        if (this.clients[clientIndex].banks && this.clients[clientIndex].banks.length != 0) {
          let valueBasedIndex = this.clients[clientIndex].banks.findIndex(f => f.id == bank.id)
          if (valueBasedIndex > -1) this.clients[clientIndex].banks[valueBasedIndex] = bank
          else this.clients[clientIndex].banks.unshift(bank)
        } else {
          this.clients[clientIndex].banks = []
          this.clients[clientIndex].banks.push(bank)
        }
      }
      this.toastr.displaySuccess(action === 'saved' ? ADD_SUCCESS : UPDATE_SUCCESS);
    }
    this.closeBankInfoModal();
  }

  closeBankInfoModal() {
    this.submitted = false
    this.bankInfoForm.reset()
    this.bankInfoModal.hide()
  }

  hideBankDetail(clientIndex, bankIndex, action) {
    const hideData = action == 'account' ? 'isAccountHide' : 'isRoutingHide'
    this.clients[clientIndex].banks[bankIndex][hideData] = !this.clients[clientIndex].banks[bankIndex][hideData]
  }

  removeSearchKeyword() {
    this.query.keyword = ''
    this.change('')
  }
}
