import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { APICONSTANTS, ROLE_CODES } from '../constants';
import { Storage } from '../utils';
const ACCESS_TYPES = ['ERM']
@Injectable()
export class EocGuard implements CanActivate {

    constructor(
        private router: Router,
        private _storage: Storage,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const loggedInUser = this._storage.get('local', 'loggedInUser');
        let isPermitted = [];
        if (loggedInUser && loggedInUser.user) isPermitted = loggedInUser.user.rolePermissions.filter(role => ACCESS_TYPES.includes(role.permissionCode));
        if (isPermitted.length && isPermitted[0]?.accessType) {
            return true
        } return false
    }
}
