<div class="d-flex justify-content-start align-items-start">
  <div class="d-flex justify-content-between w-100 align-items-center">
    <!-- questionnaire task section starts here -->
    <div class="list-content questionnaire w-100">
      <div class="d-flex justify-content-start align-items-start">
        <div class="d-flex justify-content-between align-items-start w-100">
          <span class="d-flex pr-4 w-75">
            <div class="icon-block d-none d-sm-block">
              <i class="icofont-clip-board icofont-lg skin-text-primary-dark"></i>
            </div>
            <p class="font-weight-400">
              {{ questionnaires.qnrs.name }}
            </p>
          </span>
          <span
            class="pr-3"
            *ngIf="isEditAnswerEnable(questionnaires)"
            (click)="$event.stopPropagation(); editAnswer(questionnaires)">
            <a class="skin-link"><i class="icofont-edit-alt"></i> Edit answer</a></span
          >
          <span
            *ngIf="questionnaires.status && questionnaires.status === 'DUE'"
            class="chip chip-sm mb-0 ml-1 alert alert-info mt-2"
            [ngClass]="
              questionnaires.status ? questionnaires.status.replace(' ', '').toLowerCase() : ''
            ">
            <span class="text-sm status-label">
              {{ questionnaires.status ? questionnaires.status?.toLowerCase() : '' }}
              {{ questionnaires.status === 'DUE' ? questionnaires.escalationDays : '' }}
            </span>
          </span>
        </div>
      </div>
      <!-- question list starts from here -->
      <div
        *ngFor="let qnrsQuestion of questionnaires.qnrs.qnrsQues"
        class="list-content-menu first-level">
        <div class="list-content-item d-flex justify-content-start align-items-start w-100">
          <div class="icon-block d-none d-sm-block">
            <i class="icofont-question-circle icofont-lg skin-text-primary-dark"></i>
          </div>
          <div class="w-100">
            <span>
              <p class="font-weight-400">
                {{ qnrsQuestion.question.question }}
              </p>
              <p *ngIf="qnrsQuestion.question.questionHelp">
                {{ qnrsQuestion.question.questionHelp }}
              </p>
            </span>
            <div *ngIf="qnrsQuestion.question.status === 'COMPLETED'">
              <div *ngFor="let answer of qnrsQuestion.question.ansOpt | orderBy : 'answerOptionId'">
                <p *ngIf="answer.answerValue" class="text-primary">
                  <span class="text-muted">Ans.</span>{{ answer.answerValue }}
                </p>
                <div *ngFor="let task of dependent_tasks">
                  <app-episode-user-dependent-todo
                    *ngIf="
                      task && task.uuid === answer.taskTodoUuid && task.assignedToRole === 'MP'
                    "
                    [dependent_todo]="task">
                  </app-episode-user-dependent-todo>
                  <app-episode-user-dependent-signature
                    *ngIf="
                      task && task.uuid === answer.taskSignatureUuid && task.assignedToRole === 'MP'
                    "
                    [dependentSignature]="task">
                  </app-episode-user-dependent-signature>
                  <app-episode-user-dependent-message
                    *ngIf="
                      task.field === 'message' &&
                      task.uuid === answer.taskMessageUuid &&
                      task.messageToRole === 'MP'
                    "
                    [dependent_message]="task">
                  </app-episode-user-dependent-message>
                  <app-episode-user-dependent-question
                    *ngIf="task.field === 'question' && task.uuid === answer.taskQuesUuid"
                    [dependent_question]="task"
                    [dependentTopicTasks]="dependentTopicTasks">
                  </app-episode-user-dependent-question>
                  <app-episode-user-dependent-questionnaire
                    *ngIf="task.field === 'questionnaire' && task.uuid === answer.taskQnrsUuid"
                    [dependent_questionnaire]="task"
                    [dependentTopicTasks]="dependentTopicTasks">
                  </app-episode-user-dependent-questionnaire>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
