<section class="container">
  <div class="mt-4">
    <form [formGroup]="paymentForm">
      <fieldset [disabled]="isViewPayment">
        <div *ngIf="!isViewPayment" class="row">
          <div class="col form-group">
            <label for="claimIdentifier">Claim Identifier *</label>
            <input
              type="text"
              [ngModel]="claimIdentifierSearchText | async"
              id="claimIdentifier"
              (ngModelChange)="claimIdentifierSearchText.next($event)"
              [ngModelOptions]="{standalone: true}"
              [attr.disabled]="paymentForm.value.transactionId"
              (change)="searchClaimIdentifierChange()"
              class="completer-input form-control mdb-autocomplete mb-0"
              autocomplete="off"
              [mdbAutoCompleter]="auto3"
              placeholder="Type to select claim ID"
              [ngClass]="{
                'error-select':
                  submitted &&
                  paymentForm.controls.claimPayments['controls'][0].get('patientAccountNumber')
                    .invalid
              }"
              [attr.disabled]="isAddPayment ? '' : null" />
            <mdb-auto-completer
              #auto3="mdbAutoCompleter"
              textNoResults="No result found"
              [displayValue]="displayClaimIdentifier"
              [optionHeight]="40"
              class="completer-dropdown-holder-payment-new"
              (selected)="onClaimIdentifierSelected($event)">
              <mdb-option *ngFor="let option of claimIdentifier_results | async" [value]="option">
                <div class="d-flex flex-column">
                  <strong
                    >{{ option.patientAccountNumber }} -
                    {{ option.totalClaimChargeAmount | currency }}</strong
                  >
                </div>
              </mdb-option>
            </mdb-auto-completer>
          </div>
        </div>
        <hr *ngIf="!isViewPayment" />
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="paymentMode">Payment Mode *</label>
              <mdb-select
                [options]="paymentModeList | sort : 'label'"
                id="paymentMode"
                formControlName="paymentMode"
                placeholder="Select payment mode"
                [disabled]="paymentForm.value.transactionId ? true : false"
                [ngClass]="{
                  'error-select': submitted && paymentForm.get('paymentMode').invalid,
                  'pointer-disabled': paymentForm.get('paymentMode').value
                }">
              </mdb-select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="paymentModeTraceNumber">Payment Mode Trace Number *</label>
              <input
                type="text"
                id="paymentModeTraceNumber"
                class="form-control"
                formControlName="paymentModeTraceNumber"
                mdbInput
                [attr.disabled]="paymentForm.value.transactionId"
                [ngClass]="{
                  'is-invalid': submitted && paymentForm.get('paymentModeTraceNumber').invalid
                }" />
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label for="paymentDate">Payment Date *</label>
              <input
                type="text"
                id="paymentDate"
                class="form-control"
                formControlName="paymentDate"
                placeholder="MM/DD/YYYY"
                [attr.disabled]="paymentForm.value.transactionId"
                [textMask]="{mask: dateMask}"
                [ngClass]="{
                  'is-invalid': submitted && paymentForm.get('paymentDate').invalid
                }" />
            </div>
          </div>
        </div>
        <p class="my-3"><b>Payment Information</b></p>
        <div class="row">
          <div class="col-6">
            <table>
              <tbody>
                <tr>
                  <td><strong>Payor</strong></td>
                </tr>
                <tr>
                  <td>
                    <p class="m-0">
                      <label>{{ paymentForm.value.payorName | titlecase }}</label>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6">
            <table>
              <tbody>
                <tr>
                  <td><strong>Payee</strong></td>
                </tr>
                <tr>
                  <td>
                    <p class="m-0">
                      <label>{{ paymentForm.value.payeeName | titlecase }}</label>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr class="mt-0" />
        <app-loader [loader]="loading.claims || loading.detail"></app-loader>
        <app-table
          *ngIf="claimList && claimList.data.length"
          [report]="claimList"
          [module]="'payment'"
          tableHeight="800px"
          [isCheckboxDisabled]="isSearchedByClaimIdentifier"
          [showCheckbox]="true"
          [isEditable]="true"
          [uneditableFields]="claimsTableUneditableFields"
          [nonPrePopulatedFields]="nonPrePopulatedFields"
          [invalidStatusReason]="invalidStatusReason"
          (onCheckboxChecked)="onClaimSelected($event)"
          (onUpdateCellData)="verifyPaymentValidity($event)">
          <ng-template #additionalHeader>
            <tr class="grey lighten-4">
              <td class="p-0"></td>
              <td class="p-1 pl-2">Claim</td>
              <td class="p-1 pl-2 text-right" colspan="2">Cash Received</td>
              <td class="p-1 pl-2 text-right" colspan="2">Payment Adjustment</td>
              <td class="p-1 pl-2 text-right">Amount</td>
              <td class="p-1 pl-2 text-right">Allowed Amt.</td>
              <td class="p-0"></td>
            </tr>
          </ng-template>
          <ng-template #total>
            <tr class="table-borderless">
              <td colspan="5" class="p-0"></td>
              <td class="text-right p-1 font-weight-bold">Total</td>
              <td class="text-right p-1 pr-2 font-weight-bold">{{ totalPayment | currency }}</td>
              <td class="text-right p-1 pr-2 font-weight-bold">
                {{ totalAllowedAmount | currency }}
              </td>
              <td class="p-0"></td>
            </tr>
          </ng-template>
          <ng-template #collapsibleContainer let-data="data">
            <tr>
              <td colspan="100%" class="p-3">
                <h6 class="font-weight-bold">Service Information</h6>
                <div class="row">
                  <div class="col">
                    <div class="d-flex">
                      <div style="font-size: 14px">
                        <div class="pt-0 pb-0">
                          <p class="mb-0">Patient</p>
                          <p class="text-muted">
                            <strong>
                              {{ data.patientName }}
                            </strong>
                          </p>
                        </div>
                        <div class="pt-0 pb-0">
                          <p class="mb-0">Episode</p>
                          <p class="text-muted">
                            <strong>
                              {{ data.episode }}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex">
                      <div class="ml-5" style="font-size: 14px">
                        <div class="t-0 pb-0">
                          <p class="mb-0">Funding Request</p>
                          <p class="text-muted">
                            <strong>
                              {{ data.fundingRequestName }}
                            </strong>
                          </p>
                        </div>
                        <div class="pt-0 pb-0">
                          <p class="mb-0">Bundle</p>
                          <p class="text-muted">
                            <strong>
                              {{ data.bundleName }}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex">
                      <div class="ml-5 border-top-0" style="font-size: 14px">
                        <div class="pt-0 pb-0">
                          <p class="mb-0">Bundle Component</p>
                          <p class="text-muted">
                            <strong>
                              {{ data.bundleComponent }}
                            </strong>
                          </p>
                        </div>
                        <div class="pt-0 pb-0">
                          <p class="mb-0">Allowed Amount</p>
                          <p class="text-muted">
                            <strong>
                              {{ data.allowedAmount }}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </fieldset>
    </form>
    <p
      *ngIf="!loading.claims && claimList?.data.length"
      class="grey lighten-4 text-right font-weight-bold m-0 p-2"
      style="margin-top: -1rem !important">
      Total Payment &nbsp;{{ totalPayment | currency }}
    </p>
    <div
      class="text-center mt-4"
      *ngIf="!loading.claims && !claimList?.data.length && !isViewPayment">
      <h5>Search Claims</h5>
      <p>
        {{
          !isSearched
            ? CLAIMS_PAYMENT_DESCRIPTIONS.RECEIVED_PAYMENT
            : CLAIMS_PAYMENT_DESCRIPTIONS.NO_CLAIMS
        }}
      </p>
    </div>
    <div class="d-flex justify-content-between border-top-0 mt-4">
      <a [routerLink]="['/payment']" class="waves-light m-0 btn-light btn" mdbWavesEffect>
        Go Back
      </a>
      <button
        class="btn btn-secondary waves-light m-0"
        mdbWavesEffect
        (click)="submitPayment()"
        *ngIf="!isViewPayment"
        [disabled]="
          (submit_enabled && !isAddPayment) || disableSave || paymentForm.invalid || loading.saving
        ">
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
          *ngIf="loading.saving"></span>
        Save
      </button>
    </div>
  </div>
</section>
