import { Component, OnInit, Output, Input, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ToasterService, ProviderService } from '../../services';
import { finalize } from 'rxjs/operators';
import { PROVIDER_TYPE, PROVIDER_NETWORK } from '../../constants';

@Component({
  selector: 'app-provider-detail',
  templateUrl: './provider-detail.component.html'
})

export class ProviderDetailComponent implements OnInit {
  id: any;
  type: string = '';
  loading = false;
  loadingEPOC = false;
  providerData: any;
  episodePOC: any = [];

  @Output() hideProviderDetail = new EventEmitter<any>();
  @Input() set provider(data) {
    if (data) {
      this.id = data.id;
      this.type = data.type
      this.viewProviderDetail(this.id, this.type);
    }
  }
  
  @ViewChild('providerDetailModal', { static: true }) public providerDetailModal;

  constructor(private providerService: ProviderService,
    private toastr: ToasterService
  ) { }

  ngOnInit() {
  }

  viewProviderDetail(id, type) {
    this.loading = true;
    this.providerDetailModal.show();
    this.providerService.getProvidersByIds(id)
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe(res => {
        this.providerData = res.providerList[0];
        if (type.toUpperCase() === PROVIDER_TYPE.clinic) {
          this.loadingEPOC = true;
          const network = this.providerData.network.find(n => n !== PROVIDER_NETWORK.vitafy)
          this.providerService.getEpisodePointOfContact(id, network)
          .pipe(finalize(() => { this.loadingEPOC = false; }))
          .subscribe(response => {
            this.episodePOC = response &&  response.providerContact && response.providerContact.length > 0 ? response.providerContact : []
          }, error => {
            this.loadingEPOC = false;
            this.toastr.showError(error);
            console.log("Error getting provider detail episode point of contact info", error);
          })
        }
      }, error => {
        this.loading = false;
        this.toastr.showError(error);
        console.log("Error getting provider detail info", error);
      })
  }

  closeProviderDetail() {
    this.providerDetailModal.hide();
    this.hideProviderDetail.emit(null)
    this.providerData = {};
    this.episodePOC = [];
  }
}
