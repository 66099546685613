<!-- View EOB Modal -->
<div
  mdbModal
  #viewEOBModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered align-items-start" role="document">
    <div class="modal-content">
      <div class="modal-header z-depth-0 blue-grey lighten-5 donotprint">
        <p class="modal-title">Explanation Of Benefits (EOB)</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeEOBModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="view-eob">
        <div class="d-flex justify-content-between p-3 blue-grey lighten-5">
          <div style="width: 50%" *ngIf="!loading">
            <img
              [src]="eobDetail?.client?.logo || '../../../assets/img/png/holista-logo.png'"
              class="img-fluid w-50 mt-2 mb-2" />
          </div>
          <div *ngIf="!loading">
            <p class="m-0 text-right">
              <span class="font-weight-bold">{{ eobDetail?.client?.name }}</span>
              <br />
              {{ eobDetail?.client?.addressLine1 }}<br />
              {{ eobDetail?.client?.addressLine2 }}<br *ngIf="eobDetail?.client?.addressLine2" />
              {{ eobDetail?.client?.city }}, {{ eobDetail?.client?.state }}
              {{
                eobDetail?.client?.zip4
                  ? eobDetail.client.zip + '-' + eobDetail.client.zip4
                  : eobDetail?.client?.zip
              }}
            </p>
          </div>
        </div>
        <div class="modal-body p-3">
          <app-loader [loader]="loading"></app-loader>
          <div *ngIf="!loading">
            <div class="text-center mt-2 mb-4">
              <h5>EXPLANATION OF BENEFITS</h5>
              <p>This is not a bill</p>
            </div>
            <div class="d-flex justify-content-between">
              <p>
                <strong>Generated Date</strong>
                {{ eobDetail?.episode.eobDate | date : 'MM/dd/yyyy' }}
              </p>
              <p><strong>Claim ID</strong> {{ eobDetail?.claim?.patientAccountNumber }}</p>
            </div>
            <h5>{{ eobDetail?.member?.firstName }} {{ eobDetail?.member?.lastName }}</h5>
            <p class="font-small">
              {{ eobDetail?.member?.subscriberNumber }} <br />
              {{ eobDetail?.member?.residentialAddressLine1 }} <br />
              {{ eobDetail?.member?.residentialAddressLine2 }}
              <br *ngIf="eobDetail?.member?.residentialAddressLine2" />
              {{ eobDetail?.member?.residentialCity }} {{ eobDetail?.member?.residentialState }}
              {{
                eobDetail?.member?.residentialZip4
                  ? eobDetail?.member?.residentialZip + '-' + eobDetail?.member?.residentialZip4
                  : eobDetail?.member?.residentialZip
              }}
            </p>

            <table mdbTable small="true" class="table table-bordered">
              <thead>
                <tr>
                  <th>Episode Of Care</th>
                  <th class="text-right" style="width: 100px">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ eobDetail?.episode?.name }}</td>
                  <td class="font-weight-bold text-right">
                    {{ eobDetail?.totalFundAmount | currency }}
                  </td>
                </tr>
                <tr class="mb-3">
                  <td class="text-right">Total Billed Amount</td>
                  <td class="font-weight-bold text-right">
                    {{ eobDetail?.totalFundAmount | currency }}
                  </td>
                </tr>
                <tr class="grey lighten-2">
                  <td class="text-right">Amount Paid by Insurance</td>
                  <td class="font-weight-bold text-right">
                    {{ eobDetail?.totalPaidAmount | currency }}
                  </td>
                </tr>
                <tr class="mb-4">
                  <td class="text-right border-0">Total Patient Responsibility</td>
                  <td class="font-weight-bold text-right border-0">
                    {{ eobDetail?.patientResponsibilityAmount | currency }}
                  </td>
                </tr>
                <tr>
                  <td class="text-right border-0">HRA/HSA</td>
                  <td class="font-weight-bold text-right border-0">
                    {{ eobDetail?.hraHsaAmount | currency }}
                  </td>
                </tr>
              </tbody>
              <tfoot class="grey lighten-2">
                <tr>
                  <td class="text-right">Net Patient Responsibility</td>
                  <td class="font-weight-bold text-right">
                    {{ eobDetail?.netPatientResponsibilityAmount | currency }}
                  </td>
                </tr>
              </tfoot>
            </table>
            <p class="font-weight-bold text-center m-0">
              {{ EOB_CONSTANTS.GREETING }} {{ eobDetail?.client?.name }}!
            </p>
            <p
              class="text-center mt-2 mb-0"
              *ngIf="EOB_CONSTANTS.CLIENTS_WITH_END_NOTE.includes(eobDetail?.client?.code)">
              <small>{{ EOB_CONSTANTS.END_NOTE }}</small>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center p-1 donotprint">
        <button
          type="button"
          class="btn btn-secondary waves-light"
          mdbWavesEffect
          (click)="download()">
          <i class="icofont-download"></i> Download
        </button>
        <button
          type="button"
          class="btn btn-secondary waves-light"
          mdbWavesEffect
          (click)="captureScreen()">
          <i class="icofont-print"></i> Print
        </button>
      </div>
    </div>
  </div>
</div>
