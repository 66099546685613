import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { AccessType } from 'src/app/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-task-question',
  templateUrl: './task-question.component.html',
  styleUrls: ['./task-question.component.scss']
})
export class TaskQuestionComponent implements OnInit {
  taskToBeDelete: any
  question: any;
  dependentTask: any
  questionId: any
  @Output() editTask = new EventEmitter<any>();
  @Output() addAction = new EventEmitter<any>();
  @Input() public taskQuestion;
  @Input() public optionId;
  @Output() public btns = new EventEmitter<any>();
  @Output() public deletedTask = new EventEmitter<any>();
  @Input() public taskDragged;
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;


  @Input() set taskQuestionId(taskQuestionId) {
    if (taskQuestionId) {
      this.questionId = taskQuestionId
      this.getDependentTask(taskQuestionId);
    }
  }
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.questionId) this.getDependentTask(this.questionId)
    }
  }
  constructor(
    private utilityAccess: AccessType,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const buttons = this.buttons.toArray();
    setTimeout(() => {
      this.btns.emit(buttons);
    });
  }

  receiveBtns(buttons, field) {
    if (buttons) {
      this.btns.emit(buttons);
    }
  }

  editTaskHandler(question) {
    this.editTask.emit(question);
  }


  getDependentTask(id) {
    this.taskQuestion = {}
    if (this.dependentTask && this.dependentTask.taskQuestions) {
      this.dependentTask.taskQuestions.filter(x => {
        if (x.id == id) {
          x.form = 'question';
          x.field = 'question';
          x.optionId = this.optionId;
          this.taskQuestion = x;
        }
      })
    }
  }

  editTaskTodo(todo) {
    this.editTask.emit(todo);
  }

  editTaskMessage(message) {
    this.editTask.emit(message);
  }

  addActionHandler(action?, uuid?) {
    if (action && action.optionId) {
      this.addAction.emit(action);
    } else {
      this.addAction.emit({ optionId: action ? action : this.optionId, isDependent: true, parentUuid: uuid })
    }
  }

  deleteTaskHandler(question) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      question['source'] = 'Pathway'
      question['type'] = 'Question'
      question['displayName'] = question.questions.question
      this.taskToBeDelete = question
    }
    else this.toastr.warning("Access Denied", "Warning")

  }
  cancelDelete(e) {
    this.taskToBeDelete = e
  }
  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }
}
