import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from 'src/app/utils';
@Component({
  selector: 'app-claims-processing-status',
  templateUrl: './claims-processing-status.component.html',
  styleUrls: ['./claims-processing-status.component.scss']
})
export class ClaimsProcessingStatusComponent implements OnInit {

  @Input() claims;

  constructor(
    public router: Router,
    private _storage: Storage) { }

  ngOnInit() {
  }

  redirectToClaim(claim) {
    this.router.navigate(['/claims'])
    let claimIdentifier = claim.patientAccountNumber
    this._storage.set('session', 'searchClaim', claimIdentifier)
  }

}
