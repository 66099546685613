import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MilestoneTaskService, UserService, MilestoneService, DocumentService, PathwayService, LookupService } from 'src/app/services';
import { ToastrService } from 'ngx-toastr';
import { finalize, startWith, switchMap, debounceTime, map } from 'rxjs/operators';
import { QuestionOption, DependentTask, TaskOptions, Questions, QuestionnaireQuestions, PathwayResponse } from '../../../../../models';
import { Subject, Observable, of } from 'rxjs';
import { HolistaUtils, AccessType } from '../../../../../utils';
import { MdbBtnDirective } from 'ng-uikit-pro-standard';
import { MODULES, PATHWAY_CONSTANTS, ROLE_CODES, RestrictSpace, TrimFields } from '../../../../../constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-topic-task',
  templateUrl: './topic-task.component.html',
  styleUrls: ['./topic-task.component.scss']
})
export class TopicTaskComponent implements OnInit, AfterViewInit {
  tasks = [];
  taskTodos = [];
  questions = [];
  messages = [];
  questionnaires = [];
  todoForm: FormGroup;
  questionForm: FormGroup;
  messageForm: FormGroup;
  questionnaireForm: FormGroup;
  signatureForm: FormGroup;
  todoFile: File;
  messageFile: File;
  topicId: number;
  submit_enabled = false;
  task_type = 'todo';
  optionList = [];
  QnrsOptionList = [];
  questionTypes = [];
  questionTypeList = [];
  questionOption = new QuestionOption();
  QnrsquestionOption = new QuestionOption();
  Questions = new Questions();
  messageFromList = [];
  messageToList = [];
  assignFromList = [];
  assignToList = [];
  agreementTemplateList = [];
  dependent_task = new DependentTask();
  question_search_text = new Subject();
  question_results: Observable<any>;
  questionnaireQuestions = [];
  selectedQuestion: any;
  submitted = false;
  createSelectedQuestion: any;
  editQuestionnaire: any;
  create_submitted = false;
  taskOptions = new TaskOptions();
  isEdit = false;
  topicStatus = null;
  sequenceChangeEnabled = true;
  taskDragged = false;
  disabledElements = [];
  buttons: any;
  document_search_text = new Subject();
  document_name_results: Observable<any>;
  attachFileDetail: any;
  topicDetail: any;
  triggerPeriodOptions = [
    { label: 'Day(s)', value: 'days' },
    { label: 'Week(s)', value: 'weeks' }
  ];
  supervisorOf: any = [];
  isLoading = {
    agreementTemplateList: false,
    groups: false
  };
  groups: any;

  @ViewChild('taskModal', { static: true }) private taskModal;
  @ViewChild('testbutton', { static: true }) private testbutton;
  @ViewChildren(MdbBtnDirective, { read: ElementRef }) button: QueryList<ElementRef>;
  @Output() deleteDependent = new EventEmitter<any>();
  @Output() toBeDeleteTask = new EventEmitter<any>();

  @Output() updateTopic = new EventEmitter<any>();
  @Input() set topic(topic) {
    if (topic && topic.id) {
      this.tasks = [];
      this.topicDetail = topic;
      this.topicId = topic.id;
      this.topicStatus = topic.status;
      if (topic.taskTodos) {
        topic.taskTodos.map(x => {
          x.field = 'todo';
          x.pathwayId = topic.pathwayId;
          x.milestoneId = topic.milestoneId;
          this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskSignatures) {
        topic.taskSignatures.map(x => {
          x.field = 'signature';
          x.pathwayId = topic.pathwayId;
          x.milestoneId = topic.milestoneId;
          this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskQuestions) {
        topic.taskQuestions.map(x => {
          x.field = 'question';
          x.pathwayId = topic.pathwayId;
          x.milestoneId = topic.milestoneId;
          this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskMessages) {
        topic.taskMessages.map(x => {
          x.field = 'message';
          x.pathwayId = topic.pathwayId;
          x.milestoneId = topic.milestoneId;
          this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskQnrs) {
        topic.taskQnrs.map(x => {
          x.field = 'questionnaire';
          x.pathwayId = topic.pathwayId;
          x.milestoneId = topic.milestoneId;
          this.tasks.push(x);
          return x;
        });
      }
      this.tasks = [...this.holistaUtils.sortBy(this.tasks, 'sequence')];
    }
  }

  @Input() set dependent_tasks(dependent_tasks) {
    if (dependent_tasks) {
      this.dependent_task = dependent_tasks;
    }
  }

  // @HostListener('dragend') ondragend() {
  //   console.log("drag end");
  // }
  // @HostListener('dragstart') ondragstart() {
  //   console.log("drag start");
  // }

  constructor(
    private milestoneTaskService: MilestoneTaskService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private holistaUtils: HolistaUtils,
    public utilityAccess: AccessType,
    public documentService: DocumentService,
    private milestoneService: MilestoneService,
    private _pathwayService: PathwayService,
    private _activatedRoute: ActivatedRoute,
    private _lookupService: LookupService
  ) {
    this.setToDoForm();
    this.setQuestionForm();
    this.setMessageForm();
    this.setQuestionnaireForm();
    this.setSignatureForm();
  }
  ngAfterViewInit() { }
  receiveBtns(buttons, field) {
    if (buttons) {
      buttons.map(button => {
        if (button.nativeElement && (button.nativeElement.className == 'icofont-ui-edit' || button.nativeElement.className.includes('add-action')))
          this.disabledElements.push(button.nativeElement);
      });
    }
  }
  ngOnInit() {
    this.getQuestionTypes();
    this.getRoles();
    this.question_results = this.question_search_text.pipe(
      startWith(this.question_search_text),
      switchMap((question_search_text: string) => this.searchQuestion(question_search_text))
    );

    this.document_name_results = this.document_search_text.pipe(
      startWith(this.document_search_text),
      switchMap((provider_search_text: string) => this.searchDocument(provider_search_text))
    );

    this.questionForm.get('questionTypeId').valueChanges.subscribe(value => {
      if (value) {
        let index = this.questionTypeList.findIndex(x => x.id == value);
        if (index > -1) this.questionForm.controls.questionTypeCode.setValue(this.questionTypeList[index].code);
      }
    });

    this.questionnaireForm.get('questionTypeId').valueChanges.subscribe(value => {
      if (value) {
        let index = this.questionTypeList.findIndex(x => x.id == value);
        if (index > -1) this.questionnaireForm.controls.questionTypeCode.setValue(this.questionTypeList[index].code);
      }
    });
  }

  getQuestionTypes() {
    this.questionTypes = [];
    this.milestoneTaskService.getQuestionTypes().subscribe(
      res => {
        this.questionTypeList = res;
        res.map(x => {
          let data = { label: x.name, value: x.id };
          this.questionTypes = [...this.questionTypes, data];
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  getRoles() {
    this.userService.getRoles({ limit: 0 }).then(
      (res: any) => {
        this.messageFromList = [];
        this.messageToList = [];
        this.assignFromList = [];
        this.assignToList = [];
        res.rows.map(x => {
          let data = { label: x.name, value: x.code, disabled: false };
          const assignRoles = [ROLE_CODES.CARE_COORDINATOR, ROLE_CODES.ENGAGEMENT_SPECIALIST, ROLE_CODES.MEMBER_PATIENT];
          if (assignRoles.includes(data.value)) {

            // if (data.value !== ROLE_CODES.SUPER_ADMINISTRATOR) {
            this.messageFromList = [...this.messageFromList, data];
            this.messageToList = [...this.messageToList, data];
            // };
            this.assignToList = [...this.assignToList, data];
            this.assignFromList = [...this.assignFromList, data];
          };

          if (data.value == ROLE_CODES.SUPERVISOR) {
            this.assignToList = [...this.assignToList, data];
            this.messageToList = [...this.messageToList, data];
          };
        });
        this.assignFromList = this.holistaUtils.sortBy(this.assignFromList, 'label');
        this.messageFromList = this.holistaUtils.sortBy(this.messageFromList, 'label');
        this.messageToList = this.holistaUtils.sortBy(this.messageToList, 'label');
        this.assignToList = this.holistaUtils.sortBy(this.assignToList, 'label');
      },
      error => {
        console.log(error);
      }
    );
  }

  getAgreementTemplates() {
    this.isLoading.agreementTemplateList = true;
    const pathwayId = this._activatedRoute.snapshot.params.pathwayId;
    this.milestoneService.getMilestones(parseInt(pathwayId))
      .pipe(finalize(() => { }))
      .subscribe(
        (res: PathwayResponse) => {
          const networkCode = res.networkCode;
          const params = {
            module: PATHWAY_CONSTANTS.DOCUSIGN_AGREEMENT_TEMPLATE,
            subModule: networkCode
          };
          this._pathwayService.getDocuSignAgreementTemplates(params)
            .pipe(finalize(() => { this.isLoading.agreementTemplateList = false }))
            .subscribe(res => {
              if (res && res.count) {
                this.agreementTemplateList = res.rows.map(template => ({ ...template, label: template.name }));
              };
            }, error => {
              this.toastr.error(error);
              console.log('Error getting agreement templates', error);
            });
        },
        error => {
          this.toastr.error(error);
          console.log('Error getting pathway', error);
        }
      );
  }

  onFileAdd(file: File) {
    // let file_name = file.name.split(".").join("_" + new Date().getTime() + ".");
    if (this.task_type == 'todo') {
      this.todoForm.controls.holistaMedia.setValue(file);
      this.todoForm.controls.fileName.setValue(file.name);
      this.todoFile = file;
    } else {
      this.messageForm.controls.holistaMedia.setValue(file);
      this.messageForm.controls.fileName.setValue(file.name);
      this.messageFile = file;
    }
  }

  onFileRemove() {
    if (this.task_type == 'todo') {
      this.todoForm.controls.holistaMedia.setValue(null);
      this.todoForm.controls.fileName.setValue('');
      this.todoFile = null;
    } else {
      this.messageForm.controls.holistaMedia.setValue(null);
      this.messageForm.controls.fileName.setValue('');
      this.messageFile = null;
    }
  }

  setToDoForm() {
    this.todoForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      instruction: ['', Validators.pattern(RestrictSpace)],
      taskTodoLink: [''],
      holistaMedia: [null],
      fileName: [''],
      isHighPriority: [false],
      isAcknowledgedRequired: [false],
      assignedBy: ['', Validators.required],
      assignedTo: ['', Validators.required],
      isDependent: [false],
      optionId: [null],
      sequence: [null],
      parentUuid: [null],
      documentDisplayName: [''],
      type: [''],
      isAttach: [false],
      escalationDays: [],
      triggerPeriod: ['days'],
      supervisorOf: ['']
    });
  }
  setQuestionForm() {
    this.questionForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      questionTypeId: [null],
      question: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      questionHelp: [''],
      description: [''],
      isHighPriority: [false],
      questionOptions: [],
      isRequired: [false],
      questionTypeCode: ['', Validators.required],
      isDependent: [false],
      optionId: [null],
      sequence: [null],
      parentUuid: [null],
      escalationDays: [],
      triggerPeriod: ['days'],
      supervisorOf: [''],
      groupCode: [null]
    });
  }
  setMessageForm() {
    this.messageForm = this.formBuilder.group({
      id: [null],
      title: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      taskId: [null],
      messages: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      documentPath: [''],
      holistaMedia: [null],
      messageFrom: ['', Validators.required],
      messageTo: ['', Validators.required],
      fileName: [''],
      isDependent: [false],
      optionId: [null],
      sequence: [null],
      parentUuid: [null],
      severity: [false],
      documentDisplayName: [''],
      type: [''],
      isAttach: [false],
      escalationDays: [],
      triggerPeriod: ['days'],
      supervisorOf: ['']
    });
  }
  setQuestionnaireForm() {
    this.questionnaireForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      name: [''],
      questionId: [null],
      isRequired: [false],
      questions: [''],
      question: [''],
      questionHelp: [''],
      questionTypeId: [null],
      questionTypeCode: [''],
      optionId: [null],
      isDependent: [false],
      isHighPriority: [false],
      questionOptions: [],
      description: [''],
      sequence: [],
      parentUuid: [null],
      escalationDays: [],
      triggerPeriod: ['days'],
      supervisorOf: ['']
    });
  }

  setSignatureForm() {
    this.signatureForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      escalationDays: [],
      triggerPeriod: ['days'],
      assignedBy: ['', Validators.required],
      assignedTo: ['', Validators.required],
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      instruction: ['', Validators.pattern(RestrictSpace)],
      docuSignTemplateId: ['', Validators.required],
      isHighPriority: [false],
      isNotifiableToAssignor: [false],
      isNotifiableToAssignee: [false],
      supervisorOf: [''],
      parentUuid: [null],
      isDependent: [false],
      documentDisplayName: [''],
      optionId: [null],
    });
  }

  closeModal() {
    this.taskOptions = new TaskOptions();
    this.questionForm.reset();
    this.todoForm.reset();
    this.signatureForm.reset();
    this.messageForm.reset();
    this.questionnaireForm.reset();
    this.taskModal.hide();
    this.submit_enabled = false;
    this.submitted = false;
    this.create_submitted = false;
    if (this.task_type == 'todo') {
      this.todoFile = null;
    } else {
      this.messageFile = null;
    }
    this.task_type = 'todo';
    this.optionList = [];
    this.QnrsOptionList = [];
    this.questionOption = new QuestionOption();
    this.QnrsquestionOption = new QuestionOption();
    this.selectedQuestion = null;
    this.createSelectedQuestion = null;
    this.questionnaireQuestions = [];
    this.question_search_text.next('');
    this.isEdit = false;
  }

  addTask() {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      let form_name = this.task_type + 'Form';
      this.taskOptions.sequence = this.tasks.length + 1;
      this[form_name].controls.triggerPeriod.setValue('days');
      this.taskModal.show();
      this.getRoles();
    } else this.toastr.warning('Access Denied', 'Warning');
  }

  editTask(task, form) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      this.isEdit = true;
      form = form;
      this.task_type = form;
      let form_name = form + 'Form';
      this.taskOptions.sequence = task.sequence;
      this.taskOptions.isDependent = task.isDependent;
      this.taskOptions.optionId = task.optionId;
      this.taskOptions.parentUuid = task.parentUuid;
      this.attachFileDetail = null;
      if (form == 'message') {
        if (task.documentPath) {
          this.messageForm.controls.isAttach.setValue(true);
          this.messageForm.controls.type.setValue('link');
          this.attachFileDetail = {
            documentDisplayName: task.documentDisplayName,
            documentPath: task.documentPath
          };
        }
        if (task.severity == 'YES') {
          task.severity = true;
        }
        if (task.severity == 'NO') {
          task.severity = false;
        }
      }
      if (task.isDependent && task.optionId) {
        let data = { optionId: task.optionId, isDependent: task.isDependent };
        this.todoForm.patchValue(data);
        this.questionForm.patchValue(data);
        this.messageForm.patchValue(data);
        this.questionnaireForm.patchValue(data);
        this.signatureForm.patchValue(data);
      }
      this[form_name].patchValue(task);
      this[form_name].controls.triggerPeriod.setValue('days');
      this.assignedSelected();
      if (form == 'todo') {
        if (task.taskTodoLink) {
          this.todoForm.controls.isAttach.setValue(true);
          this.todoForm.controls.type.setValue('link');
          this.attachFileDetail = {
            documentDisplayName: task.documentDisplayName,
            taskTodoLink: task.taskTodoLink
          };
        }
      }
      if ((form === 'todo' || form === 'message' || form === 'signature')) {
        const assignBy = (form_name === 'todoForm' || form_name === 'signatureForm') ? 'assignedBy' : 'messageFrom'
        const assignTo = (form_name === 'todoForm' || form_name === 'signatureForm') ? 'assignedTo' : 'messageTo'
        if (task[assignBy] == 'MP' && task[assignTo] == 'SV') {
          const supervisorOf = [{ label: 'Care Cordinator', value: 'CC' }, { label: 'Engagement Specialist', value: 'ES' }]
          this.supervisorOf = []
          this.supervisorOf = [...supervisorOf]

          this[form_name].controls["supervisorOf"].setValidators([Validators.required])
          this[form_name].controls["supervisorOf"].updateValueAndValidity();
        } else {
          this[form_name].controls['supervisorOf'].clearValidators()
          this[form_name].controls['supervisorOf'].updateValueAndValidity()
        }
        form === 'signature' && this.getAgreementTemplates();
      }
      if (form == 'question') {
        let temp_optList = [];
        this.groups = this.getQuestionGroups();
        task.questions.questionTypeCode = task.questions.questionTypes.code;
        this[form_name].patchValue(task.questions);

        task.questions.questionOptions.map(x => {
          let y = JSON.parse(JSON.stringify(x));
          y.severity = y.severity == 'YES' ? true : false;
          temp_optList.push(y);
        });
        this.optionList = temp_optList;
      }
      if (form == 'questionnaire') {
        this.editQuestionnaire = new Object();
        this.editQuestionnaire['taskId'] = task.taskId;
        this.editQuestionnaire['id'] = task.qnrs.id;
        this.editQuestionnaire['name'] = task.qnrs.name;
        this.editQuestionnaire['description'] = task.qnrs.description;
        this.editQuestionnaire['isHighPriority'] = task.qnrs.isHighPriority;
        let array = [];
        task.qnrs.qnrsQues.map(x => {
          x.question['questionTypeCode'] = x.question.questionTypes.code;
          array.push(x.question);
        });
        this.questionnaireQuestions = array;
        this[form_name].patchValue(this.editQuestionnaire);
      }
      this.taskModal.show();
    } else this.toastr.warning('Access Denied', 'Warning');
  }
  deleteTask(task) {
    if (!task.isDependent) {
      this.toBeDeleteTask.emit(task);
      let index = this.tasks.findIndex(x => x.id == task.id);
      if (index > -1) {
        this.tasks.splice(index, 1);
      }
    }

    if (task.isDependent) {
      this.deleteDependent.emit(task);
    }
  }

  addAction(optionTask) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      this.task_type = 'todo';
      this.taskOptions = optionTask;
      this.todoForm.patchValue(optionTask);
      this.questionForm.patchValue(optionTask);
      this.messageForm.patchValue(optionTask);
      this.questionnaireForm.patchValue(optionTask);
      this.signatureForm.patchValue(optionTask);
      this.taskModal.show();
    } else this.toastr.warning('Access Denied', 'Warning');
  }

  submit() {
    this.submitted = true;
    let form_name = this.task_type + 'Form';
    if (this[form_name].value.triggerPeriod == 'weeks' && this[form_name].value.escalationDays && this[form_name].value.escalationDays > 0) {
      this[form_name].controls.escalationDays.setValue(this[form_name].value.escalationDays * 7);
    }
    if (this.task_type == 'todo' || this.task_type == 'message') this.setValidator();
    if (this.task_type == 'todo') this.submitTask();
    if (this.task_type == 'question') this.submitQuestion();
    if (this.task_type == 'message') this.submitMessage();
    if (this.task_type == 'questionnaire') this.submitQuestionnaire();
    if (this.task_type === 'signature') this.submitSignatureForm();
  }

  submitSignatureForm() {
    const { id, taskId, isHighPriority, docuSignTemplateId } = this.signatureForm.value;
    this.signatureForm.controls.taskId.setValue(taskId ? taskId : this.topicId ? this.topicId : null);
    this.signatureForm.controls.isHighPriority.setValue(isHighPriority ? isHighPriority : false);
    this.signatureForm.patchValue(this.taskOptions);
    if (this.signatureForm.valid) {
      this.submit_enabled = true;
      const templateName = this.agreementTemplateList.find(({ code }) => code === docuSignTemplateId).name;
      this.signatureForm.get('documentDisplayName').setValue(templateName);
      id ? this.updateSignature() : this.saveSignature();
    }
  }

  submitTask() {
    this.todoForm.controls.isAcknowledgedRequired.setValue(this.todoForm.value.isAcknowledgedRequired ? this.todoForm.value.isAcknowledgedRequired : false);
    this.todoForm.controls.isHighPriority.setValue(this.todoForm.value.isHighPriority ? this.todoForm.value.isHighPriority : false);
    this.todoForm.controls.taskId.setValue(this.todoForm.value.taskId ? this.todoForm.value.taskId : this.topicId ? this.topicId : null);

    this.todoForm.patchValue(this.taskOptions);
    if (this.todoForm.valid) {
      this.submit_enabled = true;
      TrimFields.ToDo.forEach(element => {
        this.todoForm.value[element] = this.todoForm.value[element] ? this.todoForm.value[element].trim() : this.todoForm.value[element];
      });
      let data = this.todoForm.value;
      if (this.todoForm.value.id) this.updateTask();
      else this.saveTask();
    }
  }
  setValidator() {
    let form_name = this.task_type + 'Form';
    if (this[form_name].value.type) {
      this[form_name].controls['documentDisplayName'].setValidators([Validators.required]);
      this[form_name].controls['documentDisplayName'].updateValueAndValidity();
    }
    if (this.task_type == 'message' && this[form_name].value.type && this[form_name].value.type != 'uploadFile') {
      this[form_name].controls['documentPath'].setValidators([Validators.required]);
      this[form_name].controls['documentPath'].updateValueAndValidity();
    }
    if (this.task_type == 'todo' && this[form_name].value.type && this[form_name].value.type != 'uploadFile') {
      this[form_name].controls['taskTodoLink'].setValidators([Validators.required]);
      this[form_name].controls['taskTodoLink'].updateValueAndValidity();
    }
    if (this[form_name].value.type && this[form_name].value.type == 'uploadFile') {
      this[form_name].controls['holistaMedia'].setValidators([Validators.required]);
      this[form_name].controls['holistaMedia'].updateValueAndValidity();
    }

    if (!this[form_name].value.isAttach) {
      this[form_name].controls.documentDisplayName.setValue('');
      this[form_name].controls.holistaMedia.setValue('');

      if (form_name == 'todoForm') {
        this[form_name].controls.taskTodoLink.setValue('');
      }

      if (form_name == 'messageForm') {
        this[form_name].controls.documentPath.setValue('');
      }
    }
  }

  saveSignature() {
    let signature = Object.assign({ ...this.signatureForm.value, type: 'signature' });
    signature.pathwayId = this.topicDetail.pathwayId;
    signature.milestoneId = this.topicDetail.milestoneId;
    this.milestoneTaskService
      .saveTask(signature)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          res.data.taskField = {
            id: 'taskSignatureId',
            optionId: this.signatureForm.value.optionId
          };
          this.refreshTasks(res, 'Signature saved');
        },
        error => {
          console.log(error);
        }
      );
  }

  updateSignature() {
    let signature = Object.assign({ ...this.signatureForm.value, type: 'signature' });
    signature.pathwayId = this.topicDetail.pathwayId;
    signature.milestoneId = this.topicDetail.milestoneId;
    this.milestoneTaskService
      .updateTask(signature)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshTasks(res, 'Signature updated');
        },
        error => {
          console.log(error);
        }
      );
  }

  saveTask() {
    let todo = Object.assign(this.todoForm.value);
    todo.pathwayId = this.topicDetail.pathwayId;
    todo.milestoneId = this.topicDetail.milestoneId;
    this.milestoneTaskService
      .saveTask(todo)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          res.data.taskField = {
            id: 'taskTodoId',
            optionId: this.todoForm.value.optionId
          };
          this.refreshTasks(res, 'Action Item saved');
        },
        error => {
          console.log(error);
        }
      );
  }
  updateTask() {
    let todo = Object.assign(this.todoForm.value);
    todo.pathwayId = this.topicDetail.pathwayId;
    todo.milestoneId = this.topicDetail.milestoneId;
    this.milestoneTaskService
      .updateTask(todo)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshTasks(res, 'Action Item updated');
        },
        error => {
          console.log(error);
        }
      );
  }

  addOptionValue() {
    if (this.questionOption.optionValue) {
      this.optionList.push(this.questionOption);
      this.questionOption = new QuestionOption();
    }
  }
  QnrsaddOptionValue() {
    if (this.QnrsquestionOption.optionValue) {
      this.QnrsOptionList.push(this.QnrsquestionOption);
      this.QnrsquestionOption = new QuestionOption();
    }
  }

  removeOption(option) {
    let index = this.optionList.findIndex(x => x.optionValue == option.optionValue);
    let Qnrsindex = this.QnrsOptionList.findIndex(x => x.optionValue == option.optionValue);

    if (index > -1) this.optionList.splice(index, 1);
    if (Qnrsindex > -1) this.QnrsOptionList.splice(index, 1);
  }

  submitQuestion() {
    this.addOptionValue();
    this.questionForm.controls.isRequired.setValue(this.questionForm.value.isRequired ? this.questionForm.value.isRequired : false);
    this.questionForm.controls.isHighPriority.setValue(this.questionForm.value.isHighPriority ? this.questionForm.value.isHighPriority : false);
    this.questionForm.controls.taskId.setValue(this.questionForm.value.taskId ? this.questionForm.value.taskId : this.topicId ? this.topicId : null);
    this.questionForm.patchValue(this.taskOptions);
    let temp_optList = [];

    if (this.questionForm.value.questionTypeCode == 'CHECKBOX' || this.questionForm.value.questionTypeCode == 'RADIO')
      if (this.optionList) {
        this.optionList.map(x => {
          let y = JSON.parse(JSON.stringify(x));
          y.severity = y.severity ? 'YES' : 'NO';
          temp_optList.push(y);
        });
      }
    this.questionForm.controls.questionOptions.setValue(temp_optList);
    if (this.questionForm.valid) {
      TrimFields.Question.forEach(element => {
        this.questionForm.value[element] = this.questionForm.value[element] ? this.questionForm.value[element].trim() : this.questionForm.value[element];
      });
      let data = this.questionForm.value;
      if (this.questionForm.value.id) {
        this.submit_enabled = true;
        this.updateQuestion();
      } else if (this.questionForm.get('questionTypeCode').value == 'CHECKBOX' || this.questionForm.get('questionTypeCode').value == 'RADIO') {
        if (this.optionList.length > 0) {
          this.submit_enabled = true;
          this.saveQuestion();
        }
      } else {
        this.saveQuestion();
        this.submit_enabled = true;
      }
    }
  }
  saveQuestion() {
    let question = Object.assign(this.questionForm.value);
    question.pathwayId = this.topicDetail.pathwayId;
    question.milestoneId = this.topicDetail.milestoneId;
    this.milestoneTaskService
      .saveQuestion(question)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          res.data.taskField = {
            id: 'taskQuestionId',
            optionId: this.questionForm.value.optionId
          };
          this.refreshTasks(res, 'Question saved');
        },
        error => {
          console.log(error);
        }
      );
  }
  updateQuestion() {
    let question = Object.assign(this.questionForm.value);
    question.pathwayId = this.topicDetail.pathwayId;
    question.milestoneId = this.topicDetail.milestoneId;
    this.milestoneTaskService
      .updateQuestion(question)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshTasks(res, 'Question updated');
        },
        error => {
          console.log(error);
        }
      );
  }

  submitMessage() {
    // this.messageForm.controls.severity.setValue(this.messageForm.value.severity ? 'YES' : null);
    this.messageForm.controls.taskId.setValue(this.messageForm.value.taskId ? this.messageForm.value.taskId : this.topicId ? this.topicId : null);
    this.messageForm.patchValue(this.taskOptions);
    if (this.messageForm.valid) {
      TrimFields.TaskMessage.forEach(element => {
        this.messageForm.value[element] = this.messageForm.value[element] ? this.messageForm.value[element].trim() : this.messageForm.value[element];
      });
      this.submit_enabled = true;
      let data = this.messageForm.value;
      if (this.messageForm.value.id) this.updateMessage();
      else this.saveMessage();
    }
  }
  saveMessage() {
    let message = Object.assign(this.messageForm.value);
    message.pathwayId = this.topicDetail.pathwayId;
    message.milestoneId = this.topicDetail.milestoneId;
    message.severity = message.severity ? 'YES' : 'NO';
    this.milestoneTaskService
      .saveMessage(message)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          res.data.taskField = {
            id: 'taskMessageId',
            optionId: this.messageForm.value.optionId
          };
          this.refreshTasks(res, 'Message saved');
        },
        error => {
          console.log(error);
        }
      );
  }
  updateMessage() {
    let message = Object.assign(this.messageForm.value);
    message.pathwayId = this.topicDetail.pathwayId;
    message.milestoneId = this.topicDetail.milestoneId;
    message.severity = message.severity ? 'YES' : 'NO';
    this.milestoneTaskService
      .updateMessage(message)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshTasks(res, 'Message updated');
        },
        error => {
          console.log(error);
        }
      );
  }

  refreshTasks(response, action) {
    if (response.message) {
      this.toastr.error(response.message, 'Error');
    } else {
      let topic = action.includes('saved') ? response.data : action.includes('Qnrs') ? response.data : response.data[0];
      topic.active_topicId = this.topicId;
      topic.active_task = action.includes('Action') ? 'taskTodos' : action.includes('Signature') ? 'taskSignatures' : action.includes('Question') ? 'taskQuestions' : action.includes('Qnrs') ? 'taskQnrs' : 'taskMessages';
      this.updateTopic.emit(topic);
      this.toastr.success(`${action} successfully.`, 'Success');
    }
    this.closeModal();
  }

  changeTask() {
    if (!this.todoForm.value.id) this.todoForm.reset();
    if (!this.signatureForm.value.id) this.signatureForm.reset();
    if (!this.messageForm.value.id) this.messageForm.reset();
    if (!this.questionForm.value.id) {
      this.questionOption = new QuestionOption();
      this.questionForm.reset();
    }
    if (!this.questionnaireForm.value.id) {
      this.QnrsquestionOption = new QuestionOption();
      if (this.questionnaireQuestions.length > 0) {
        this.questionnaireQuestions = [];
      }
      this.questionnaireForm.reset();
    }
    let form_name = this.task_type + 'Form';
    if (!this[form_name].value.id) this[form_name].controls.triggerPeriod.setValue('days');
    this.submitted = false;
    this.create_submitted = false;
    this.task_type === 'signature' && this.getAgreementTemplates();
    this.groups = this.task_type === 'question' && this.getQuestionGroups();
  }

  getQuestionGroups() {
    this.isLoading.groups = true;
    this._lookupService.getLookups({ module: MODULES.QUESTION })
      .pipe(finalize(() => { this.isLoading.groups = false }))
      .subscribe((response: any) => {
        this.groups = response.rows.map(group => ({ ...group, label: group.name, value: group.code }));
      }, (error) => {
        this.toastr.error(error);
        console.log('error getting groups', error);
      });
  }

  removeSelected(e, list) {
    this[`${list}`].map(x => {
      x.disabled = x.value == e.value ? true : false;
    });
    this[`${list}`] = [...this[`${list}`]];
  }

  selectOpen(list, selected) {
    this[`${list}`].map(x => {
      x.disabled = x.value == selected ? true : false;
    });
    this[`${list}`] = [...this[`${list}`]];
  }

  onDisplayValue = (question?): string | undefined => {
    return question ? question.question : undefined;
  };

  onQuestionSelect(event) {
    event.text.questionOptions.sort((a, b) => a.id - b.id);
    delete event.text.id;
    this.selectedQuestion = event.text;
  }

  searchQuestion(searchText: string) {
    this.questionnaireForm.controls.questionId.setValue(null);
    if (searchText.length > 2) {
      return this.userService.searchQuestions(searchText).pipe(
        debounceTime(250),
        map((items: any) => {
          return items;
        })
      );
    } else {
      return of([]);
    }
  }

  addQuestions() {
    if (this.selectedQuestion) {
      let index = this.questionnaireQuestions.findIndex(x => x.uuid == this.selectedQuestion.uuid);

      if (index && index < 0) {
        this.selectedQuestion.questionTypeCode = this.selectedQuestion.questionTypes.code;
        this.selectedQuestion.questionOptions.map(x => {
          delete x.questionId;
          delete x.id;
        });
        this.questionnaireQuestions = [...this.questionnaireQuestions, this.selectedQuestion];
        this.selectedQuestion = null;
        this.question_search_text.next('');
      } else {
        // showToaster
        this.question_search_text.next('');
      }
    } else {
      this.create_submitted = true;
      this.QnrsValidator('set');
      this.questionnaireForm.controls['name'].clearValidators();
      this.questionnaireForm.controls['name'].updateValueAndValidity();

      if (this.questionnaireForm.valid) {
        let typeCode = this.questionTypeList.find(x => x.id == this.questionnaireForm.get('questionTypeId').value).code;
        this.createSelectedQuestion = new Object();
        let temp_QnrsOptList = [];
        this.createSelectedQuestion['questionTypeCode'] = typeCode;
        this.createSelectedQuestion['questionTypeId'] = this.questionnaireForm.get('questionTypeId').value;
        this.createSelectedQuestion['description'] = null;
        this.createSelectedQuestion['questionHelp'] = this.questionnaireForm.get('questionHelp').value;
        this.createSelectedQuestion['question'] = this.questionnaireForm.get('question').value;

        if (this.QnrsOptionList) {
          this.QnrsOptionList.map(x => {
            let y = JSON.parse(JSON.stringify(x));
            y.severity = y.severity ? 'YES' : 'NO';
            temp_QnrsOptList.push(y);
          });
        }
        this.createSelectedQuestion['questionOptions'] = temp_QnrsOptList;
        this.createSelectedQuestion['id'] = null;

        if (this.questionnaireForm.get('questionTypeCode').value == 'CHECKBOX' || this.questionnaireForm.get('questionTypeCode').value == 'RADIO') {
          if (this.QnrsOptionList.length > 0) {
            this.questionnaireQuestions = [...this.questionnaireQuestions, this.createSelectedQuestion];
            this.QnrsValidator('clear');

            // clear panel - 2 form
            this.clearQuestionnaireForm();
            this.create_submitted = false;
          }
        } else {
          this.questionnaireQuestions = [...this.questionnaireQuestions, this.createSelectedQuestion];
          this.QnrsValidator('clear');

          // clear panel - 2 form
          this.clearQuestionnaireForm();
        }
      }
    }
  }
  questionTypeChange() {
    this.QnrsOptionList = [];
    this.optionList = [];
  }
  clearQuestionnaireForm() {
    this.questionnaireForm.controls.questionTypeId.setValue('');
    this.questionnaireForm.controls.isRequired.setValue('');
    this.questionnaireForm.controls.question.setValue('');
    this.questionnaireForm.controls.questionHelp.setValue('');
    this.questionnaireForm.controls.questionTypeCode.setValue('');
  }
  QnrsValidator(status) {
    if (status == 'clear') {
      this.questionnaireForm.controls['question'].clearValidators();
      this.questionnaireForm.controls['question'].updateValueAndValidity();
      this.questionnaireForm.controls['questionTypeCode'].clearValidators();
      this.questionnaireForm.controls['questionTypeCode'].updateValueAndValidity();
    }
    if (status == 'set') {
      this.questionnaireForm.controls['question'].setValidators([Validators.required]);
      this.questionnaireForm.controls['question'].updateValueAndValidity();
      this.questionnaireForm.controls['questionTypeCode'].setValidators([Validators.required]);
      this.questionnaireForm.controls['questionTypeCode'].updateValueAndValidity();
    }
  }

  removeQuestion(question) {
    if (question.id) {
      let index = this.questionnaireQuestions.findIndex(x => x.id == question.id);
      if (index > -1) {
        this.questionnaireQuestions.splice(index, 1);
      }
    } else {
      let index = this.questionnaireQuestions.findIndex(x => x.question == question.question);
      if (index > -1) {
        this.questionnaireQuestions.splice(index, 1);
      }
    }
  }

  submitQuestionnaire() {
    this.QnrsaddOptionValue();
    this.questionnaireForm.controls['name'].setValidators([Validators.required]);
    this.questionnaireForm.controls['name'].updateValueAndValidity();
    this.QnrsValidator('clear');
    this.questionnaireForm.controls.taskId.setValue(this.questionnaireForm.value.taskId ? this.questionnaireForm.value.taskId : this.topicId ? this.topicId : null);
    this.questionnaireForm.controls.questions.setValue(this.questionnaireQuestions);
    this.questionnaireForm.patchValue(this.taskOptions);
    if (this.questionnaireForm.valid && this.questionnaireQuestions.length > 0) {
      this.submit_enabled = true;
      let data = this.questionnaireForm.value;
      if (this.questionnaireForm.value.id) this.updateQuestionnaire();
      else this.saveQuestionnaire();
    }
  }
  saveQuestionnaire() {
    let questionnaire = Object.assign(new QuestionnaireQuestions());
    questionnaire.taskId = this.questionnaireForm.get('taskId').value;
    questionnaire.sequence = this.questionnaireForm.get('sequence').value;
    questionnaire.parentUuid = this.questionnaireForm.get('parentUuid').value;
    questionnaire.name = this.questionnaireForm.get('name').value;
    questionnaire.description = this.questionnaireForm.get('description').value;
    questionnaire.isHighPriority = this.questionnaireForm.get('isHighPriority').value;
    questionnaire.questions = this.questionnaireForm.get('questions').value;
    questionnaire.optionId = this.questionnaireForm.get('optionId').value;
    questionnaire.isDependent = this.questionnaireForm.get('isDependent').value;
    questionnaire.questions.map((x, index) => {
      x.sequence = index + 1;
      return x;
    });
    questionnaire.escalationDays = this.questionnaireForm.get('escalationDays').value;
    questionnaire.pathwayId = this.topicDetail.pathwayId;
    questionnaire.milestoneId = this.topicDetail.milestoneId;
    this.milestoneTaskService
      .saveQuestionnaire(questionnaire)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          res.data.taskField = {
            id: 'taskQnrsId',
            optionId: this.questionnaireForm.value.optionId
          };
          this.refreshTasks(res, 'Qnrs saved');
        },
        error => {
          console.log(error);
        }
      );
  }
  updateQuestionnaire() {
    let questionnaire = Object.assign(new QuestionnaireQuestions());
    questionnaire.id = this.questionnaireForm.get('id').value;
    questionnaire.sequence = this.questionnaireForm.get('sequence').value;
    questionnaire.parentUuid = this.questionnaireForm.get('parentUuid').value;
    questionnaire.taskId = this.questionnaireForm.get('taskId').value;
    questionnaire.name = this.questionnaireForm.get('name').value;
    questionnaire.isHighPriority = this.questionnaireForm.get('isHighPriority').value;
    questionnaire.description = this.questionnaireForm.get('description').value;
    questionnaire.questions = this.questionnaireForm.get('questions').value;
    questionnaire.optionId = this.questionnaireForm.get('optionId').value;
    questionnaire.isDependent = this.questionnaireForm.get('isDependent').value;
    questionnaire.questions.map((x, index) => {
      // delete x.id;
      x.sequence = index + 1;
      return x;
    });
    questionnaire.escalationDays = this.questionnaireForm.get('escalationDays').value;
    questionnaire.pathwayId = this.topicDetail.pathwayId;
    questionnaire.milestoneId = this.topicDetail.milestoneId;
    this.milestoneTaskService
      .updateQuestionnaire(questionnaire)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshTasks(res, 'Qnrs updated');
        },
        error => {
          console.log(error);
        }
      );
  }

  onDrop(item: any, list: any[]) {
    if (list.length > 0) {
      const index = list.indexOf(item);
      list.splice(index, 1);
      this.sequenceChangeEnabled = false;
      this.tasks = [...list];
      let body = [];
      this.tasks.map((a, index) => {
        a.sequence = index + 1;
        let data = {
          type: a.field,
          id: a.id,
          sequence: a.sequence
        };
        body.push(data);
        return a;
      });
      this.milestoneTaskService
        .changeTaskSequence(body)
        .pipe(
          finalize(() => {
            this.sequenceChangeEnabled = true;
          })
        )
        .subscribe(
          res => { },
          error => {
            console.log(error);
          }
        );
    }
  }

  onDocumentValue = (procedure?): string | undefined => {
    return procedure ? procedure.name : undefined;
  };

  searchDocument(searchText: string) {
    if (searchText.length > 2) {
      return this.documentService.getDocument({ limit: 0, keyword: searchText }).pipe(
        debounceTime(250),
        map((items: any) => {
          return items.rows;
        })
      );
    } else {
      return of([]);
    }
  }

  onDocumentSelect(e) {
    let path = e.text.documentPath;
    if (this.task_type == 'todo') {
      this.todoForm.controls.taskTodoLink.setValue(path);
      this.todoForm.controls.documentDisplayName.setValue(e.text.name);
    } else {
      this.messageForm.controls.documentPath.setValue(path);
      this.messageForm.controls.documentDisplayName.setValue(e.text.name);
    }
  }

  attachChange(form) {
    let form_name = form + 'Form';
    this[form_name].controls['holistaMedia'].clearValidators();
    this[form_name].controls['holistaMedia'].updateValueAndValidity();
    this[form_name].controls['documentDisplayName'].clearValidators();
    this[form_name].controls['documentDisplayName'].updateValueAndValidity();
    this[form_name].controls.documentDisplayName.setValue('');

    if (form == 'message') {
      this[form_name].controls['documentPath'].clearValidators();
      this[form_name].controls['documentPath'].updateValueAndValidity();
      this[form_name].controls.documentPath.setValue('');
      if (this[form_name].value.type && this[form_name].value.type == 'link' && this[form_name].value.id && this.attachFileDetail) {
        this[form_name].controls.documentPath.setValue(this.attachFileDetail.documentPath);
        this[form_name].controls.documentDisplayName.setValue(this.attachFileDetail.documentDisplayName);
      }
    }
    if (form == 'todo') {
      this[form_name].controls['taskTodoLink'].clearValidators();
      this[form_name].controls['taskTodoLink'].updateValueAndValidity();
      this[form_name].controls.taskTodoLink.setValue('');
      if (this[form_name].value.type && this[form_name].value.type == 'link' && this[form_name].value.id && this.attachFileDetail) {
        this[form_name].controls.taskTodoLink.setValue(this.attachFileDetail.taskTodoLink);
        this[form_name].controls.documentDisplayName.setValue(this.attachFileDetail.documentDisplayName);
      }
    }
  }

  onAttach() {
    let form_name = this.task_type + 'Form';
    this[form_name].controls.isAttach.setValue(true);
    this[form_name].controls.type.setValue('link');
  }

  closeAttach() {
    let form_name = this.task_type + 'Form';
    this.attachChange(this.task_type);
    this[form_name].controls.isAttach.setValue(false);
    this[form_name].controls.type.setValue('');
  }

  assignedSelected() {
    let form_name = this.task_type + 'Form'
    const assignBy = (form_name === 'todoForm' || form_name === 'signatureForm') ? 'assignedBy' : 'messageFrom';
    const assignTo = (form_name === 'todoForm' || form_name === 'signatureForm') ? 'assignedTo' : 'messageTo';
    const assignedByRole = this[form_name].value[assignBy]
    const assignedToRole = this[form_name].value[assignTo]
    if (assignedByRole == 'MP' && assignedToRole == 'SV') {
      this.supervisorOf = []
      const supervisorOf = [{ label: 'Care Cordinator', value: 'CC' }, { label: 'Engagement Specialist', value: 'ES' }]
      this.supervisorOf = [...supervisorOf]
      this[form_name].controls["supervisorOf"].setValidators([Validators.required])
      this[form_name].controls["supervisorOf"].updateValueAndValidity();
    } else {
      this.supervisorOf = []
      this[form_name].controls.supervisorOf.setValue('')
      this[form_name].controls['supervisorOf'].clearValidators()
      this[form_name].controls['supervisorOf'].updateValueAndValidity()
    }
  }

  onGroupDeselected() {
    this.questionForm.controls.groupCode.setValue(null);
  }
}
