import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClaimsService, ClientService, EpisodeService, NetworkService, ProviderService, ReportService, ToasterService, UserService } from '../../services';
import * as HeaderBreadCrumbActions from '../../action';
import { DatePickerOption, FUNDING_ADDITIONAL_HEADERS, REPORT_DATES, REPORT_FILTERS, CLAIMS_REPORT_DATA, DATE_TYPES, ClaimsConstants, PROVIDERS_REPORT_DATA, EPISODE_REPORT_DATA, REPORT_TYPES, ROLE_CODES, NON_NOVANT_ELIMINATED_TABLE_HEADERS, CLIENT_EPISODE_REPORT_ELIMINATED_TABLE_HEADERS, PROVIDER_EPISODE_REPORT_ELIMIMATED_TABLE_HEADERS, EPISODESTATUS, CLIENT_ADMINS } from '../../constants';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Storage, CurrencyFormat, ReportUtility, FormatPhoneNumber, HolistaUtils } from '../../utils';
import { ReportDate, ReportTypes } from '../../models';
import { debounceTime, finalize, map, startWith, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html'
})
export class ReportComponent implements OnInit {
  reportForm: FormGroup;
  clientList: object[] = [];
  dateTypes = DATE_TYPES;
  reportTypeList: ReportTypes[];
  reportDateList: object[] = REPORT_DATES;
  columnOptionList: object[] = FUNDING_ADDITIONAL_HEADERS;
  episodeTypeList: object[] = [];
  client: object;
  showFinancialReconciliation: boolean = false;
  showClaimsReconciliationSummary: boolean = false;
  financialReconciliationData = {
    episodeFinancialDetail: {},
    episodeFinancialSummaryDetail: {},
    yearToDateSummary: {},
    client: {},
    date: {}
  }
  showFundingReport: boolean = false;
  fundingReportData = {
    reportContainer: {},
    date: {},
    client: {},
    selectedOptions: [],
  };
  showReport: boolean = false;
  params: any = {};
  isPreSelectedFilter: boolean = false;
  endDateOptions = JSON.parse(JSON.stringify(DatePickerOption));
  startDateOptions = JSON.parse(JSON.stringify(DatePickerOption));
  user: any = {};
  isEpisodeTypeDisabled: boolean = false;
  reportData = [];
  additionalFilterFields: string[] = [];
  reportFilters = REPORT_FILTERS;
  report = {
    data: [],
    excelName: '',
    tableTitle: '',
    tableHeaders: [],
    columnsWidth: [],
    dateType: '',
    date: {} as ReportDate
  };
  providerSearchText = new Subject();
  specialitySearchText = new Subject();
  providersResult: Observable<any>;
  specialityResult: Observable<any>;
  isSearching = {
    providers: false,
    speciality: false,
    facilities: false
  };
  status: any;
  networks: any;
  searchKeyword: string;
  facilitiesResult: any;
  selectedReportType: string;
  reportTypes = REPORT_TYPES;
  roleCodes = ROLE_CODES;
  selectedProviderNetworks: string[] = [];
  facilityList = [];
  isLoading = {
    report: false,
    reportType: false,
    episodeType: false,
    networkType: false,
    facilityList: false
  };
  uniqueEpisodeTypeList = [];
  REPORT_TYPE_CRS = REPORT_TYPES.CLAIMS_RECONCILIATION_SUMMARY;
  searchReportTypeKeyword: string = '';
  reportView: 'grid' | 'list' = 'grid';
  backupReportTypeList: ReportTypes[];
  ownPageReportList = [
    REPORT_TYPES.CLIENTS_CLAIMS_DASHBOARD,
    REPORT_TYPES.VALUE_MANAGEMENT_DASHBOARD,
    REPORT_TYPES.QUESTION_REVIEW_DASHBOARD,
    REPORT_TYPES.TRANSACTION_REPORT,
    REPORT_TYPES.PAYMENT_REPORT,
    REPORT_TYPES.PROJECTED_EPISODE_PROFIT_REPORT,
    REPORT_TYPES.ANCILLARY_REPORT,
  ];
  initialUrl: string;

  @ViewChild('billingProviderInput', { static: false }) public billingProviderInput;

  constructor(
    private _store: Store<{ bread_crumbs: any }>,
    private _formBuilder: FormBuilder,
    private _reportService: ReportService,
    private _clientService: ClientService,
    private _episodeService: EpisodeService,
    private _toastr: ToasterService,
    private _currencyFormat: CurrencyFormat,
    private _storage: Storage,
    private _userService: UserService,
    private _networkService: NetworkService,
    private _claimsService: ClaimsService,
    private _providerService: ProviderService,
    private _reportUtility: ReportUtility,
    private _formatPhoneNumberUtility: FormatPhoneNumber,
    private _holistaUtility: HolistaUtils,
    private _router: Router,
    private _route: ActivatedRoute,
    private location: Location,
  ) {
    this.setReportForm();
  }

  ngOnInit() {
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    this.addBreadCrum();
    this.initialUrl = this._router.url;
    CLIENT_ADMINS.includes(this.user.roleCode) || this.user.roleCode === this.roleCodes.REPORTING ? this.getClients({ code: this.user.referenceCode, limit: 0 }) : this.getClients({ limit: 0 });
    this.getReportsType();
    this.providersResult = this.providerSearchText.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((providerSearchText: string) => this.reportForm.value.reportType === this.reportTypes.CLAIMS_REPORT
        ? this.searchProviders(providerSearchText)
        : this.searchFacilities(providerSearchText))
    );
  }

  ngDoCheck() {
    const currentUrl = this._router.url;
    if (currentUrl !== this.initialUrl) {
      this.initialUrl = currentUrl;
      this._route.snapshot.url[0].path = '/reports';
      this.resetFormValue();
    }
  }

  addBreadCrum() {
    this._store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this._store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Reporting', path: '/reports' }));
  }

  searchProviders(searchKeyword: string) {
    this.searchKeyword = searchKeyword;
    if (searchKeyword.length > 2) {
      this.isSearching.providers = true;
      return this._claimsService.searchBillingProvider(searchKeyword)
        .pipe(
          finalize(() => (this.isSearching.providers = false)),
          debounceTime(250),
          map((items: any) => {
            return items
          })
        );
    } else {
      return of([]);
    }
  }

  async searchFacilities(searchKeyword: string) {
    if (searchKeyword.length >= 3) {
      this.isSearching.facilities = true;
      const query = {
        keyword: searchKeyword,
        offset: 0,
        entityCode: 2,
        category: 'medicalNetwork',
        network: String(this.reportForm.value.network)
      }
      return await this._providerService.getProviders(query).then(res => {
        this.isSearching.facilities = false;
        if (res) {
          return res['providerList'];
        }
      })
    }
  }

  searchSpeciality(searchText: string = " ") {
    if (searchText.length > 2) {
      this.isSearching.speciality = true;
      return this._providerService.searchSpecialityByName(searchText)
        .pipe(
          finalize(() => (this.isSearching.speciality = false)),
          debounceTime(250),
          map((items: any) => {
            return items.map(item => {
              return { code: item.Code, displayName: item.name, vSpecialty: item.vSpecialty, value: item.vSpecialty }
            })
          })
        );
    } else {
      return of([]);
    }
  }

  getReportsType() {
    this.isLoading.reportType = true;
    this._reportService.getReportsList({ type: 1 })
      .pipe(finalize(() => { this.isLoading.reportType = false; }))
      .subscribe(res => {
        this.reportTypeList = res.map(item => ({
          ...item,
          label: item.name,
          value: item.code
        })).sort((a, b) => a.order - b.order);
        this.backupReportTypeList = this.reportTypeList;
      }, error => {
        console.log('error getting report types', error);
        this._toastr.showError(error);
      });
  }

  onClientOrFacilityChange(providerId) {
    const { provider, client } = this.reportForm.value;
    const episodes = this.selectedReportType === this.reportTypes.PROVIDER_EPISODE_REPORT
      ? this.episodeTypeList.filter(episode => provider
        && (
          provider.includes(episode['facilityId'])
          || provider.includes(episode['providerId'])
          || provider.includes(episode['clinicId'])
        ))
      : this.episodeTypeList.filter(episode => client && client.includes(episode['clientCode']));
    const listedEpisodes = (provider && provider.length || client && client.length)
      ? episodes
      : this.selectedReportType === this.reportTypes.FUNDING_REPORT
        ? this._holistaUtility.getUniqueList(this.episodeTypeList, 'name')
        : [];
    const episodeNames = listedEpisodes.map(episode => episode['name']);
    const filteredEpisodeTypeList = listedEpisodes
      .filter((episode, index: number) => !episodeNames.includes(episode['name'], index + 1))
      .map((episode: any) => ({ ...episode, name: episode.name.toLowerCase() }));
    this.uniqueEpisodeTypeList = this._holistaUtility.getUniqueList(filteredEpisodeTypeList, 'name');
    const displayedEpisodeTypes = this.uniqueEpisodeTypeList.filter((episode: any) => episode.facilityId !== providerId).map(x => x['value']);
    if (this.reportForm.value.episodeType && this.reportForm.value.episodeType.length) {
      const filteredSelectedEpisodeTypes = this.reportForm.value.episodeType.filter(episode => displayedEpisodeTypes.includes(episode));
      this.reportForm.controls.episodeType.setValue(null);
      this.reportForm.controls.episodeType.setValue(filteredSelectedEpisodeTypes);
    } else {
      this.reportForm.controls.episodeType.setValue(null);
    };
  }

  /**
   * selecting a client from clients dropdown
   * @param event 
   */
  onNetworkChange(event, action?) {
    this.reportForm.controls['network'].setValue(action ? null : event.code);
  }

  /**
   * 
   * @param event 
   * @param type 
   */
  onDateChange(event?: any, type?: string) {
    const { startDate, endDate } = this.reportForm.value
    let fromDate: any;
    let toDate: any;
    if (!(event && type)) {
      if (startDate) {
        fromDate = { year: fromDate.substr(-4, 4), month: fromDate.substr(-10, 2), day: fromDate.substr(-7, 2) }
      }
      if (endDate) {
        toDate = { year: toDate.substr(-4, 4), month: toDate.substr(-10, 2), day: toDate.substr(-7, 2) }
      }
    }
    if (type === 'startDate' || !(event && type)) {
      this.endDateOptions.disableUntil.year = fromDate ? fromDate.year : event ? event.date.year : 0
      this.endDateOptions.disableUntil.month = fromDate ? fromDate.month : event ? event.date.month : 0
      this.endDateOptions.disableUntil.day = fromDate ? fromDate.day : event ? event.date.day : 0
    }

    if (type === 'endDate' || !(event && type)) {
      this.startDateOptions.disableSince.year = toDate ? toDate.year : event ? event.date.year : 0
      this.startDateOptions.disableSince.month = toDate ? toDate.month : event ? event.date.month : 0
      this.startDateOptions.disableSince.day = toDate ? toDate.day : event ? event.date.day : 0
    }
  }

  /**
   * sets report form
   */
  setReportForm() {
    let values = FUNDING_ADDITIONAL_HEADERS.map(({ value }) => value);
    this.reportForm = this._formBuilder.group({
      reportId: [''],
      reportCode: [''],
      reportType: [''],
      reportDate: [null],
      episodeType: [''],
      client: [''],
      columnOptions: new FormControl(values),
      endDate: ['', Validators.required],
      startDate: ['', Validators.required],
      dateType: [''],
      provider: [null],
      procedureCode: [''],
      status: [''],
      network: [null, Validators.required],
      speciality: [null],
    });
  }

  /**
   * Get clients from API
   */
  getClients(params) {
    this._clientService.getClients(params).subscribe(res => {
      res && res.count > 0 && res.rows.forEach(({ name, code }) => {
        const data = { label: name, value: code };
        this.clientList.push(data);
      })
    }, error => {
      console.log('Error', error);
      this._toastr.showError(error);
    })
  }

  /**
   * Gets episode types from API
   */
  getEpisodeTypes(providerIds = null, clientCode = null) {
    const roles = [this.roleCodes.PROVIDER_ADMINISTRATOR, this.roleCodes.CLIENT_ADMINISTRATOR, this.roleCodes.SUPER_CLIENT_ADMINISTRATOR, this.roleCodes.REPORTING];
    this.isLoading.episodeType = true;
    this._episodeService.getUniqueEpisodeTypes({ providerIds, clientCode })
      .pipe(finalize(() => { this.isLoading.episodeType = false }))
      .subscribe((response: any) => {
        this.episodeTypeList = response.map(res => {
          const name = res.name.trim();
          return {
            ...res,
            name,
            label: name,
            value: name.replaceAll(',', '')
          }
        });
        if (this.selectedReportType === this.reportTypes.FUNDING_REPORT || roles.includes(this.user.roleCode)) {
          const episodeNames = this.episodeTypeList.map(episode => episode['name']);
          const filteredEpisodeTypeList = this.episodeTypeList
            .filter((episode, index: number) => !episodeNames.includes(episode['name'], index + 1))
            .map((episode: any) => ({ ...episode, name: episode.name.toLowerCase() }));
          this.uniqueEpisodeTypeList = this._holistaUtility.getUniqueList(filteredEpisodeTypeList, 'name');
        }
      }, error => {
        console.log('Error', error);
        this._toastr.showError(error);
      })
  }

  /**
   * episode funding report
   * @param clientCode 
   */
  getFundingReport() {
    this.fundingReportData.selectedOptions = [];
    const { columnOptions } = this.reportForm.value;

    columnOptions && columnOptions.forEach((value: string) => {
      let label: string;

      switch (value) {
        case 'fundingReqName':
          label = 'Funding Request';
          break;
        case 'holistaFee':
          label = 'Holista Distribution';
          break;
        case 'clientFee':
          label = 'Client Distribution';
          break;
        case 'patientPayAmount':
          label = 'Patient Responsibility';
          break;
        case 'paidAmount':
          label = 'Provider Distribution';
          break;
        case 'hraHsaReceivedAmount':
          label = 'HRA/HSA Received';
          break;
        case 'hraHsaPaidAmount':
          label = 'HRA/HSA Distribution';
          break;
        default:
          break;
      }

      const data = { label, value };
      this.fundingReportData.selectedOptions.push(data);
    });

    this._reportService.getEpisodeFundingReport(this.params).subscribe((res) => {
      if (res.name === 'Client Error') {
        this.isLoading.report = false;
        this._toastr.displayError(res.message);
      }
      else {
        this.fundingReportData.reportContainer = res;
        this.isLoading.report = false;
        this.showFundingReport = true;
      }
    }, error => {
      console.log('Error', error);
      this._toastr.showError(error);
    })
    this.fundingReportData.client = this.client;
    this.fundingReportData.date = { startDate: this.reportForm.value.startDate, endDate: this.reportForm.value.endDate };
  }

  /**
  * report data for claims
  */
  getClaimsReport() {
    const { TABLE_HEADERS, TABLE_TITLE, EXCEL_NAME, COLUMNS_WIDTH } = CLAIMS_REPORT_DATA;
    const { dateType, startDate, endDate } = this.reportForm.value;
    this._reportService.getClaimsReport(this.reportForm.value).subscribe(res => {
      if (res) {
        this.report.data = res.map(claim => ({
          ...claim,
          billedAmount: claim.billedAmount,
          paidAmount: claim.paidAmount,
          hraHsaAmount: claim.hraHsaAmount,
          dateReceived: claim.dateReceived ? moment(claim.dateReceived).format('MM-DD-YYYY') : claim.dateReceived,
          dob: claim.dob ? moment(claim.dob).format('MM-DD-YYYY') : claim.dob,
          paidDate: claim.paidDate ? moment(claim.paidDate).format('MM-DD-YYYY') : claim.paidDate
        }));
      } else {
        this.report.data = [];
      }
      this.report.tableHeaders = TABLE_HEADERS;
      this.report.excelName = EXCEL_NAME;
      this.report.tableTitle = TABLE_TITLE;
      this.report.columnsWidth = COLUMNS_WIDTH;
      this.report.date = { startDate, endDate };
      this.report.dateType = DATE_TYPES.find(type => type.value === dateType).label;
      this.showReport = true;
      this.isLoading.report = false;
    }, error => {
      console.log('Error', error);
      this._toastr.showError(error);
      this.isLoading.report = false;
    })
  }

  generateClaimsReport() {
    if (this.user.roleCode === this.roleCodes.PROVIDER_ADMINISTRATOR) {
      this._userService.getProvidersByUserId(this.user.id).subscribe(res => {
        const provider = [];
        res.length ? res.forEach(item => provider.push(item.npi)) : provider.push(0);
        this.reportForm.controls.provider.setValue(provider);
        this.getClaimsReport();
      })
    } else this.getClaimsReport();
    this.getClaimsReport();
  }

  generateProvidersReport() {
    const { TABLE_HEADERS, TABLE_TITLE, EXCEL_NAME } = PROVIDERS_REPORT_DATA;
    const columnsWidth = TABLE_HEADERS.map(header => header.columnWidth);
    this._reportService.getProvidersReport(this.reportForm.value).subscribe((res: any) => {
      res.map(x => {
        if (x.facilityPhone) {
          x.facilityPhone = this._formatPhoneNumberUtility.phoneNumber(x.facilityPhone);
        }
        return x
      });
      this.report.data = this._reportUtility.mapProvidersExportData(res);
      this.showReport = true;
      this.isLoading.report = false;
    }, error => {
      console.log('Error', error);
      this._toastr.showError(error);
      this.isLoading.report = false;
    });
    this.report.tableHeaders = TABLE_HEADERS;
    this.report.excelName = EXCEL_NAME;
    this.report.tableTitle = TABLE_TITLE;
    this.report.columnsWidth = columnsWidth;
  }

  generateEpisodeReport() {
    const { TABLE_HEADERS, TABLE_TITLE, EXCEL_NAME } = JSON.parse(JSON.stringify(EPISODE_REPORT_DATA));
    const { client, provider } = this.reportForm.value;
    let selectedProviderNetworks = [];
    if (this.selectedReportType === this.reportTypes.PROVIDER_EPISODE_REPORT) {
      if (this.user.roleCode === this.roleCodes.PROVIDER_ADMINISTRATOR) {
        selectedProviderNetworks.push(this.user.referenceCode)
      } else {
        this.facilityList.filter(x => provider.includes(x['value'])).map(y => {
          selectedProviderNetworks = selectedProviderNetworks.concat(y.networkCode)
        });
      }
    };
    //differentiates table headers
    let tableHeaders = client && client.length && !client.includes('NOVANT')
      ? TABLE_HEADERS.filter(({ value }) => !NON_NOVANT_ELIMINATED_TABLE_HEADERS.includes(value))
      : provider && !selectedProviderNetworks.includes('NH') ? TABLE_HEADERS.filter(({ value }) => !NON_NOVANT_ELIMINATED_TABLE_HEADERS.includes(value))
        : TABLE_HEADERS; //between novant and non-novant
    tableHeaders = this.selectedReportType === this.reportTypes.CLIENT_EPISODE_REPORT ?
      tableHeaders.filter(header => !CLIENT_EPISODE_REPORT_ELIMINATED_TABLE_HEADERS.includes(header.value))
      : tableHeaders.filter(header => !PROVIDER_EPISODE_REPORT_ELIMIMATED_TABLE_HEADERS.includes(header.value)); //between client and provider episode report
    //differentiates table headers
    this._reportService.getEpisodesReport(this.reportForm.value).subscribe((res: any) => {
      this.report.data = this._reportUtility.mapEpisodesExportData(res);
      this.showReport = true;
      this.isLoading.report = false;
    }, error => {
      console.log('error generating episode report', error);
      this._toastr.showError(error);
      this.isLoading.report = false;
    });
    this.report.tableHeaders = tableHeaders;
    this.report.excelName = this.selectedReportType === this.reportTypes.PROVIDER_EPISODE_REPORT
      ? EXCEL_NAME.replace('Client', 'Provider')
      : EXCEL_NAME;
    if (this.selectedReportType === this.reportTypes.CLIENT_EPISODE_REPORT) {
      this.report.tableTitle = TABLE_TITLE;
    }
    if (this.selectedReportType === this.reportTypes.PROVIDER_EPISODE_REPORT) {
      this.report.tableTitle = TABLE_TITLE.replace('Client', 'Provider');
    }
    this.report.columnsWidth = tableHeaders.map(({ columnWidth }) => columnWidth);
  }

  /**
   * search for report/s
   */
  onReportSearch() {
    this.isLoading.report = true;
    this.params = {
      clientCode: this.reportForm.value.client ? this.reportForm.value.client : '',
      referenceCode: this.user.referenceCode,
      startDate: this.reportForm.value.startDate,
      endDate: this.reportForm.value.endDate
    };
    this.client = this.reportForm.value.client ? this.client : null;
    this.showFundingReport = false;
    this.showFinancialReconciliation = false;
    this.showReport = false;
    const { reportType } = this.reportForm.value;

    switch (reportType) {
      case this.reportTypes.FUNDING_REPORT:
        this.params.episodeType = this.reportForm.value.episodeType ? this.reportForm.value.episodeType : ''
        this.getFundingReport();
        break;
      case this.reportTypes.EPISODE_FINANCIAL_RECONCILIATION_REPORT:
        this.getFinancialReconciliation();
        break;
      case this.reportTypes.CLAIMS_REPORT:
        this.generateClaimsReport();
        break;
      case this.reportTypes.PROVIDER_FACILITY_REPORT:
        this.generateProvidersReport();
        break;
      case this.reportTypes.CLIENT_EPISODE_REPORT:
      case this.reportTypes.PROVIDER_EPISODE_REPORT:
        this.generateEpisodeReport();
        break;
      case this.reportTypes.CLAIMS_RECONCILIATION_SUMMARY:
        this.params.dateType = this.reportForm.value.dateType ? this.reportForm.value.dateType : '';
        this.showClaimsReconciliationSummary = true;
        break;
      default:
        break;
    }
  }

  /**
   * Resets Form Values
   */
  resetFormValue(isReportTypeSelected = false) {
    !isReportTypeSelected && this.addBreadCrum();
    const selectedReport = this.reportForm.value.reportType;
    this.reportForm.reset();
    if (selectedReport === this.reportTypes.CLAIMS_RECONCILIATION_SUMMARY) {
      this.reportForm.controls.dateType.setValue('receivedDate');
    }
    this.providerSearchText.next('');
    this.reportForm.controls['startDate'].setValue(null);
    this.reportForm.controls['endDate'].setValue(null);
    this.reportForm.controls['startDate'].enable();
    this.reportForm.controls['endDate'].enable();
    this.endDateOptions.disableUntil.year = 0;
    this.endDateOptions.disableUntil.month = 0;
    this.endDateOptions.disableUntil.day = 0;
    this.startDateOptions.disableSince.year = 0;
    this.startDateOptions.disableSince.month = 0;
    this.startDateOptions.disableSince.day = 0;
    this.showFinancialReconciliation = false;
    this.showClaimsReconciliationSummary = false;
    this.showFundingReport = false;
    this.showReport = false;
    this.isPreSelectedFilter = false;
    this.reportForm.controls.provider.setValue(null);
    isReportTypeSelected ? this.reportForm.controls.reportType.setValue(this.selectedReportType) : this.additionalFilterFields = [];
  }

  /**
   * Report type
   */
  selectedReportDate() {
    if (this.reportForm.value.reportDate) {
      switch (this.reportForm.value.reportDate) {
        case "thisMonth":
          this.reportForm.controls['startDate'].setValue(moment().startOf('month').format('MM/DD/YYYY'));
          this.reportForm.controls['endDate'].setValue(moment().endOf('month').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastMonth":
          this.reportForm.controls['startDate'].setValue(moment().startOf('month').subtract(1, 'months').format('MM/DD/YYYY'));
          this.reportForm.controls['endDate'].setValue(moment().startOf('month').subtract(1, 'months').endOf('month').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "ytd":
          this.reportForm.controls['startDate'].setValue(moment().startOf('year').format('MM/DD/YYYY'));
          this.reportForm.controls['endDate'].setValue(moment().format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastYear":
          this.reportForm.controls['startDate'].setValue(moment().startOf('year').subtract(12, 'months').format('MM/DD/YYYY'));
          this.reportForm.controls['endDate'].setValue(moment().endOf('year').subtract(12, 'months').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastQuarter":
          let currentQuaterNumber = moment().quarter();
          if (currentQuaterNumber === 1) { //if 1st quater
            this.reportForm.controls['startDate'].setValue(moment().startOf('year').subtract(3, 'months').format('MM/DD/YYYY'));
            this.reportForm.controls['endDate'].setValue(moment().startOf('year').subtract(1, 'day').format('MM/DD/YYYY'));
          } else {
            this.reportForm.controls['startDate'].setValue(moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YYYY'));
            this.reportForm.controls['endDate'].setValue(moment().subtract(1, 'quarter').endOf('quarter').format('MM/DD/YYYY'));
          }
          this.isPreSelectedFilter = true;
          break;
        case "thisQuarter":
          this.reportForm.controls['startDate'].setValue(moment().startOf('quarter').format('MM/DD/YYYY'));
          this.reportForm.controls['endDate'].setValue(moment().endOf('quarter').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "dateRange":
          this.reportForm.controls.startDate.setValidators([Validators.required]);
          this.reportForm.controls.startDate.updateValueAndValidity();
          this.reportForm.controls.endDate.setValidators([Validators.required]);
          this.reportForm.controls.endDate.updateValueAndValidity();
          if (this.isPreSelectedFilter) {
            try {
              this.reportForm.controls['startDate'].setValue(null);
              this.reportForm.controls['endDate'].setValue(null);
              this.reportForm.controls['startDate'].enable();
              this.reportForm.controls['endDate'].enable();
            } catch (error) {
              try {
                this.reportForm.controls['startDate'].enable();
                this.reportForm.controls['endDate'].enable();
              } catch (error) {
                this.reportForm.controls['endDate'].enable();
              }
            }
          }
          this.isPreSelectedFilter = false;
          break;
        default:
          break;
      }
    }
  }

  /**
   * gets Financial Reconciliation datas
   */
  getFinancialReconciliation() {
    forkJoin([this._reportService.getEpisodeFinancialDetail(this.params),
    this._reportService.getEpisodeFinancialSummary(this.params, false),
    this._reportService.getEpisodeFinancialSummary(this.params, true)
    ]).subscribe(([episodeFinancialDetail, episodeFinancialSummaryDetail, episodeFinancialSummaryYearToDate]: any) => {
      if (episodeFinancialDetail.name === 'Client Error' || episodeFinancialSummaryDetail.name === 'Client Error' || episodeFinancialSummaryYearToDate.name === 'Client Error') {
        this.isLoading.report = false;
        this._toastr.displayError(episodeFinancialDetail.message || episodeFinancialSummaryDetail.message || episodeFinancialSummaryYearToDate.message)
      }
      else {
        episodeFinancialDetail.episodeFinancials = episodeFinancialDetail.episodeFinancials.map(x => {
          x.componentCost = this._currencyFormat.format(x.componentCost);
          x.consumedAmount = this._currencyFormat.format(x.consumedAmount);
          x.hraHsaAmount = this._currencyFormat.format(x.hraHsaAmount);
          return x;
        })
        episodeFinancialDetail.totalConsumed = this._currencyFormat.format(episodeFinancialDetail.totalConsumed);
        episodeFinancialSummaryDetail.episodeFinancialSummary = episodeFinancialSummaryDetail.episodeFinancialSummary.map(x => {
          x.fundReceived = this._currencyFormat.format(x.fundReceived);
          x.balance = this._currencyFormat.format(x.balance);
          x.consumedAmount = this._currencyFormat.format(x.consumedAmount);
          x.hraHsaReceivedAmount = this._currencyFormat.format(x.hraHsaReceivedAmount)
          x.hraHsaPaidAmount = this._currencyFormat.format(x.hraHsaPaidAmount);
          return x;
        })
        const mapValue = (data: any) => {
          data.totalBalance = this._currencyFormat.format(data.totalBalance);
          data.totalConsumed = this._currencyFormat.format(data.totalConsumed);
          data.totalFundReceived = this._currencyFormat.format(data.totalFundReceived);
          return data;
        }
        episodeFinancialSummaryDetail.currentPeriodSummary = mapValue(episodeFinancialSummaryDetail.currentPeriodSummary);
        this.financialReconciliationData.episodeFinancialDetail = episodeFinancialDetail;
        this.financialReconciliationData.episodeFinancialSummaryDetail = episodeFinancialSummaryDetail;
        this.financialReconciliationData.yearToDateSummary = mapValue(episodeFinancialSummaryYearToDate.yearToDateSummary);
        this.financialReconciliationData.client = this.client;
        this.financialReconciliationData.date = { startDate: this.reportForm.value.startDate, endDate: this.reportForm.value.endDate }
        this.isLoading.report = false;
        this.showFinancialReconciliation = true;
      }
    }, error => {
      console.log('Error', error);
      this._toastr.showError(error);
    })
  }

  /**
   * sets filter options based on type of report selected
   * @param event 
   */
  onReportTypeSelected(event) {
    this.location.replaceState('/reports');
    this.selectedReportType = event.code;
    this.status = this.selectedReportType === this.reportTypes.CLAIMS_REPORT ? ClaimsConstants.processingStatus : EPISODESTATUS;
    this.additionalFilterFields = this.reportFilters[event.value];
    this.resetFormValue(true);
    this._store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: event.name, path: '/reports' }));
    this.report.date = { startDate: null, endDate: null };
    event.code === this.reportTypes.CLAIMS_RECONCILIATION_SUMMARY && this.reportForm.controls.dateType.setValue('receivedDate');
    if (event.value === this.reportTypes.PROVIDER_FACILITY_REPORT || event.value === this.reportTypes.CLIENT_EPISODE_REPORT || event.value === this.reportTypes.PROVIDER_EPISODE_REPORT) {
      event.value === this.reportTypes.PROVIDER_FACILITY_REPORT ? (this.reportForm.controls.network.enable()) : this.reportForm.controls.network.disable();
      if (event.value === this.reportTypes.PROVIDER_EPISODE_REPORT) {
        if (this.user.roleCode === this.roleCodes.PROVIDER_ADMINISTRATOR) {
          this.isLoading.facilityList = true;
          this.reportForm.controls.provider.setValue(this.user.referenceCode);
          this._userService.getProvidersByUserId(this.user.id)
            .pipe(finalize(() => {
              this.isLoading.facilityList = false;
            }))
            .subscribe(response => {
              this.facilityList = response.map(res => ({ ...res, label: (res.displayName || res.businessName), value: res.providerId }));
              const providerIds = response.map(provider => provider.providerId);
              this.reportForm.controls.provider.setValue(providerIds);
              providerIds.length
                ? providerIds.forEach((id: string) => {
                  this.onClientOrFacilityChange(id);
                })
                : this.getEpisodeTypes([]);
            });
        } else {
          this.getProviders();
        }
        this.getEpisodeTypes();
        this.reportForm.controls.provider.setValidators([Validators.required]);
        this.reportForm.controls.provider.updateValueAndValidity();
      }
      if (event.value !== this.reportTypes.PROVIDER_EPISODE_REPORT) {
        this.reportForm.controls.provider.clearValidators();
        this.reportForm.controls.provider.updateValueAndValidity();
      }
      this.reportForm.controls["reportDate"].setErrors(null);
      this.reportForm.controls["reportDate"].clearValidators();
      this.reportForm.controls["reportDate"].updateValueAndValidity();
      this.reportForm.controls["startDate"].setErrors(null);
      this.reportForm.controls["startDate"].clearValidators();
      this.reportForm.controls["startDate"].updateValueAndValidity();
      this.reportForm.controls["endDate"].setErrors(null);
      this.reportForm.controls["endDate"].clearValidators();
      this.reportForm.controls["endDate"].updateValueAndValidity();
      if (CLIENT_ADMINS.includes(this.roleCodes.CLIENT_ADMINISTRATOR)) { // For client administrators
        this.getClientNetworks()
      } else {
        this.getNetworks();
      }
      this.specialityResult = this.specialitySearchText.pipe(
        startWith(''),
        debounceTime(300),
        switchMap((specialitySearchText: string) => this.searchSpeciality(specialitySearchText))
      );
    }
    else if (this.reportForm.get('provider').validator) {
      this.reportForm.controls.provider.clearValidators();
      this.reportForm.controls.provider.updateValueAndValidity();
    }
    else this.reportForm.controls['network'].disable();
    this.user.roleCode === this.roleCodes.PROVIDER_ADMINISTRATOR && this.reportForm.controls['network'].setValue(this.user.referenceCode);
    event.code === this.reportTypes.CLAIMS_REPORT && this.reportForm.controls.dateType.setValue('receivedDate');
    if (event.value === this.reportTypes.CLIENT_EPISODE_REPORT) {
      this.reportForm.controls.client.setValidators([Validators.required]);
      this.reportForm.controls.client.updateValueAndValidity();
      if (CLIENT_ADMINS.includes(this.user.roleCode) || this.user.roleCode === this.roleCodes.REPORTING) {
        this.reportForm.controls.client.setValue(this.user.referenceCode);
        this.getEpisodeTypes(null, this.user.referenceCode);
      } else this.getEpisodeTypes();
    } else if (this.reportForm.get('client').validator) {
      this.reportForm.controls.client.clearValidators();
      this.reportForm.controls.client.updateValueAndValidity();
    }
    event.value === this.reportTypes.FUNDING_REPORT && this.getEpisodeTypes();
    this.reportForm.controls.reportId.setValue(event.id);
    this.reportForm.controls.reportCode.setValue(event.code);
  }

  /**
   * 
   * @param event 
   * @returns selected value to be displayed in input field
   */
  onDisplayValue(event) {
    const { displayName, businessName } = event;
    return displayName || businessName;
  }

  /**
   * sets the value of selected provider in reportForm
   * @param event 
   */
  providerSelected(event) {
    this.selectedReportType === this.reportTypes.CLAIMS_REPORT
      ? this.reportForm.controls.provider.setValue([event.text.identificationCode])
      : this.reportForm.controls.provider.setValue([event.text.providerId]);
    this.selectedProviderNetworks = event.text.network;
  }

  specialitySelected(event) {
    this.reportForm.controls.speciality.setValue([event.text.code]);
  }

  onProviderSearchTextChange(event) {
    this.providerSearchText.next(event);
    event === '' && this.reportForm.controls.provider.setValue(null);
  }

  onSpecialitySearchTextChange(event) {
    this.specialitySearchText.next(event);
    event === '' && this.reportForm.controls.speciality.setValue(null);
  }

  getNetworks() {
    this.isLoading.networkType = true;
    this._networkService
      .getAll({ limit: 0, fields: 'code,name' })
      .pipe(
        finalize(() => { this.isLoading.networkType = false; })
      )
      .subscribe((res) => {
        if (res.rows.length != 0) {
          res.rows.map(network => {
            network.label = network.name
            network.value = network.code
            return network
          })
          this.networks = res.rows;
        }
      });
  }

  getClientNetworks() {
    this._clientService.getNetworksByClientCode(this.user.referenceCode)
      .subscribe(res => {
        res.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.networks = JSON.parse(JSON.stringify(res))
      }, (error) => {
        console.log('Error', error)
      })
  }

  onNetworkDeslected() {
    let isNetworkIncluded: boolean = false;
    this.selectedProviderNetworks.forEach(network => isNetworkIncluded = this.reportForm.value.network.includes(network));
    if (!isNetworkIncluded) {
      this.providerSearchText.next('');
      this.reportForm.controls.provider.setValue(null);
      this.billingProviderInput.nativeElement.blur();
    }
  }

  getProviders() {
    this.isLoading.facilityList = true;
    this.facilityList = [];
    this._episodeService.getUniqueProviders().subscribe(response => {
      this.facilityList = response.map(res => ({ ...res, label: res.name, value: res.providerId }));
      this.isLoading.facilityList = false;
    })
  }

  setFetching(event) {
    if (event) {
      this.isLoading.report = false;
    }
  }

  onReportKeywordSearch() {
    this.reportTypeList = this.backupReportTypeList.filter((report) => report.name.toLowerCase().includes(this.searchReportTypeKeyword.toLowerCase()));
    if (!this.searchReportTypeKeyword) {
      this.reportTypeList = this.backupReportTypeList;
    }
  }

  removeSearchKeyword() {
    this.searchReportTypeKeyword = '';
    this.reportTypeList = this.backupReportTypeList;
  }

  changeReportTypeView(type) {
    this.reportView = type === 'list' ? 'list' : 'grid';
  }

}
