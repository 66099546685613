<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group has-search input-group mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          [(ngModel)]="query.keyword"
          (ngModelChange)="searchByKeyword($event)"
          class="form-control"
          id="search"
          placeholder="Search" />
        <div class="input-group-append" *ngIf="query.keyword">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50 mt-2 mb-2">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#basicExample"
          mdbWavesEffect
          (click)="openNetworkModal()">
          <i class="icofont-plus"></i> ADD
        </button>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table mdbTable stickyHeader="true" small="true" hover="true">
          <thead id="scrollToTop">
            <tr>
              <th width="20"></th>
              <th scope="col" [class.active]="query.sortBy === 'name'" (click)="setOrder('name')">
                Name
                <span *ngIf="query.sortBy === 'name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                scope="col"
                [class.active]="query.sortBy === 'createdAt'"
                (click)="setOrder('createdAt')">
                Created Date
                <span *ngIf="query.sortBy === 'createdAt'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th width="20"></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading"
            [style.top]="networks && networks.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let network of networks
                | paginate
                  : {itemsPerPage: query.limit, currentPage: query.page, totalItems: totalCount};
              let i = index
            ">
            <tr>
              <td
                (click)="
                  network.collapsed = !network.collapsed;
                  network.collapsed ? getMarkets(network) : ''
                ">
                <button
                  type="button"
                  class="btn btn-link p-0 m-0"
                  mdbWavesEffect
                  *ngIf="network.collapsed">
                  <i class="icofont-minus-circle icofont-lg text-info"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-link p-0 m-0"
                  mdbWavesEffect
                  *ngIf="!network.collapsed">
                  <i class="icofont-plus-circle icofont-lg text-info"></i>
                </button>
              </td>
              <td>{{ network.name }}</td>
              <td>{{ network.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}</td>
              <td>
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div
                    class="custom-dropdown-content rounded-sm z-depth-2"
                    [style.top]="i === query.limit - 1 && '-134px'">
                    <a class="dropdown-item" (click)="openMarketModal(network.code)">
                      <i class="fas fa-shopping-cart"></i>
                      Add Market
                    </a>
                    <a class="dropdown-item" (click)="openNetworkModal(network)">
                      <i class="icofont-edit-alt"></i>
                      Edit
                    </a>
                    <a class="dropdown-item" (click)="onDelete(network, 'network')">
                      <i class="icofont-ui-delete"></i>
                      Delete
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="network.collapsed" class="grey lighten-5 border">
              <td colspan="100%" class="p-3">
                <app-loader [loader]="!network?.markets"></app-loader>
                <div *ngIf="network?.markets && network.markets.length > 0">
                  <h6>Market</h6>
                  <div class="form-row">
                    <div
                      class="col-4 col-sm-4 col-md-3 col-xl-3"
                      *ngFor="let market of network?.markets">
                      <div
                        class="alert bg-blue-light rounded-sm d-flex justify-content-between align-items-center p-2">
                        <span>
                          <h6 class="mb-0">{{ market.name }}</h6>
                          <span class="badge badge-info z-depth-0">{{ market.code }}</span>
                        </span>

                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"> </mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="openMarketModal(network.code, market)">
                              <i class="icofont-edit-alt"></i>
                              Edit
                            </a>
                            <a class="dropdown-item" (click)="onDelete(market, 'market')">
                              <i class="icofont-ui-delete"></i>
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="network?.markets && network.markets.length == 0">
                  <h6>No Market Available</h6>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="networks && networks.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.networks">
    <i class="fa-3x fas fa-sitemap text-black-50"></i>
    <p>Start adding Network.</p>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.searchedNetwork">
    <i class="fa-3x fas fa-sitemap text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>

<!-- Delete Modal -->
<app-delete-confirmation
  [_id]="dataToDelete?.code"
  [message]="message"
  (confirmDelete)="confirmDelete($event)">
</app-delete-confirmation>

<!-- NETWORK MODAL -->
<div
  mdbModal
  #networkModal="mdbModal"
  class="modal fade top"
  id="networkModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-md modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ networkForm.value.id ? 'Edit' : 'Add' }} Network</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeNetworkModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="networkForm">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="networkCode">Vitafy Network Code *</label>
                <input
                  type="text"
                  class="form-control"
                  id="networkCode"
                  formControlName="code"
                  placeholder="Enter code"
                  [readOnly]="networkForm.value.id ? true : false"
                  [ngClass]="{'is-invalid': submitted && networkForm.get('code').invalid}" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="name">Name *</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  formControlName="name"
                  placeholder="Enter network name"
                  [ngClass]="{'is-invalid': submitted && networkForm.get('name').invalid}" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="timelyFiling">Timely Filing</label>
                <input
                  type="number"
                  class="form-control"
                  id="timelyFiling"
                  numbersOnly
                  formControlName="timelyFiling" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeNetworkModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitNetwork()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ networkForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- MARKET MODAL -->
<div
  mdbModal
  #marketModal="mdbModal"
  class="modal fade top"
  id="marketModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ marketForm.value.id ? 'Edit' : 'Add' }} Market</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeMarketModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="marketForm">
          <div class="form-row">
            <div class="col-4">
              <div class="form-group">
                <label for="marketCode">Vitafy Market Code *</label>
                <input
                  type="text"
                  class="form-control"
                  id="marketCode"
                  formControlName="code"
                  placeholder="Enter code"
                  [readOnly]="marketForm.value.id ? true : false"
                  [ngClass]="{'is-invalid': submitted && marketForm.get('code').invalid}" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="name">Market Name *</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  formControlName="name"
                  placeholder="Enter market name"
                  [ngClass]="{'is-invalid': submitted && marketForm.get('name').invalid}" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeMarketModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitMarket()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ marketForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>
