import { Action } from '@ngrx/store';

export const GET_USER = '[USER] GET';
export const GET_ALL_CHAT_USER = '[USER] GET_ALL_CHAT_USER';
export const ENABLE_CHAT = '[USER] ENABLE_CHAT';
// export const OPEN_CHAT_BOX = '[USER] OPEN_CHAT_BOX';
export const CHAT_USER_LOADING = '[USER] CHAT_USER_LOADING';


export class GetUser implements Action {
  readonly type = GET_USER;
  constructor(public payload: {}) { }
}

export class GetAllChatUser implements Action {
  readonly type = GET_ALL_CHAT_USER;
  constructor(public payload: []) { }
}

export class EnableChat implements Action {
  readonly type = ENABLE_CHAT;
  constructor() { }
}

// export class OpenChat implements Action {
//   readonly type = OPEN_CHAT_BOX;
//   constructor(public payload: boolean) { }
// }

export class ChatUsersLoad implements Action {
  readonly type = CHAT_USER_LOADING;
  constructor(public payload: boolean) { }
}

export type UserActions = GetUser | GetAllChatUser | EnableChat | ChatUsersLoad;
