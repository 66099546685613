import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DependentTask } from '../../../../../models';
import { HolistaUtils, Storage, ScrollTo } from '../../../../../utils';
@Component({
  selector: 'app-episode-user-task',
  templateUrl: './episode-user-task.component.html',
  styleUrls: ['./episode-user-task.component.scss']
})
export class EpisodeUserTaskComponent implements OnInit {
  tasks = [];
  topics: any
  orderedTasks = [];
  tempTasks = [];
  taskTodos = [];
  taskSignatures = [];
  questions = [];
  messages = [];
  questionnaire = [];
  todoFile: File;
  messageFile: File;
  topicId: number;
  submit_enabled = false;
  task_type = 'todo';
  optionList = [];
  questionTypes = [];
  questionTypeList = [];
  messageFromList = [];
  messageToList = [];
  selected = { taskField: '', task: {}, topicId: null };
  dependent_task = new DependentTask();
  topicStatus = null
  episodeStatus = null
  milestoneCompletedByCCES = false
  isTaskDisabled: boolean;

  @Input() user: any;
  @Input() set updated_task(updated_task) {
    if (updated_task && updated_task.id) {
      let idx = this.tasks.findIndex(x => (x.id == updated_task.id && x.field == updated_task.field))
      if (idx > -1) {
        updated_task.selected = false;
        this.tasks[idx] = updated_task;
      }
    }
  }
  @Input() dependentTopicTasks
  @Output() updateSelectedTask = new EventEmitter<any>();
  @Output() updateQuestionnaireAnswer = new EventEmitter<any>();
  @Output() updateQuestionAnswer = new EventEmitter<any>();
  @Input() assignedBy;
  @Input() assignedTo;
  @Input() set topic(topic) {
    this.topics = topic
    this.milestoneCompletedByCCES = topic.completedByCcEs
    this.isTaskDisabled = topic.isTaskDisabled
    if (topic && topic.id) {
      this.tasks = [];
      this.tempTasks = [];
      this.topicId = topic.id;
      this.topicStatus = topic.status;
      this.episodeStatus = topic.episodeStatus
      if (topic.taskTodos) {
        this.taskTodos = topic.taskTodos.map(x => {
          x.selected = false;
          x.field = 'todo';
          x.topicStatus = topic.status;
          if ((x.assignedTo == this.user.id))
            this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskSignatures) {
        this.taskSignatures = topic.taskSignatures.map(x => {
          x.selected = false;
          x.field = 'signature';
          x.topicStatus = topic.status;
          if ((x.assignedTo == this.user.id))
            this.tasks.push(x);
          return x;
        });
      }

      if (topic.taskQuestions) {
        this.questions = topic.taskQuestions.map(x => {
          x.selected = false;
          x.field = 'question';
          x.topicStatus = topic.status;
          x.episodeStatus = topic.episodeStatus
          x.milestoneCompletedByCCES = topic.completedByCcEs
          this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskMessages) {
        this.messages = topic.taskMessages.map(x => {
          x.selected = false;
          x.field = 'message';
          x.topicStatus = topic.status
          if ((x.messageTo == this.user.id))
            this.tasks.push(x);
          return x;
        });
      }
      if (topic.taskQnrs) {
        this.questionnaire = [];
        topic.taskQnrs.map(x => {
          x.field = 'questionnaire'
          x.topicStatus = topic.status;
          x.episodeStatus = topic.episodeStatus
          x.milestoneCompletedByCCES = topic.completedByCcEs
          x.qnrs.qnrsQues.map(y => {
            y.selected = false;
            y.sequence = x.sequence;
            y.field = 'questionnaire';
            y.status = x.status
            y.isDependent = x.isDependent;
            y.uuid = x.uuid
            y.topicStatus = topic.status;
            y.taskId = x.taskId;
            y.escalationDays = x.escalationDays
            return y
          })
          this.questionnaire.push(x);
          this.tasks.push(x);
          return x;
        });
      }
      let nondependentTask = this.holistaUtils.sortBy([...this.tasks.filter(x => !x.isDependent)], 'sequence');
      nondependentTask.forEach(x => {
        this.orderedTasks.push(x);
        if ((x.field === 'question' && x.status === 'COMPLETED') || (x.field === 'questionnaire' && x.status === 'COMPLETED'))
          // if ((x.field == 'question' || x.field == 'questionnaire') && x.status == 'COMPLETED')
          // this.getChilds(x);
          return x;
      })
      this.tasks = [...this.orderedTasks];
      let answeredTaskId = this._storage.get('session', 'answeredTaskId')
      setTimeout(() => {
        if (answeredTaskId) {
          this.ScrollTo(answeredTaskId, 'center', 'smooth')
          this._storage.remove('session', 'answeredTaskId')
        }
      }, 300);
    }
  };

  @Input() set dependent_tasks(dependent_tasks) {
    if (dependent_tasks) {
      this.dependent_task = dependent_tasks;
    }
  };

  constructor(
    private holistaUtils: HolistaUtils,
    private _storage: Storage,
    private _scrollTo: ScrollTo,
  ) { }

  ngOnInit() { }

  getChilds(x) {
    let question = x.questions ? x.questions : x.question
    if (question.questionTypes.code == 'RADIO' || question.questionTypes.code == 'CHECKBOX') {
      question.ansOpt.map(y => {
        question.questionOptions.map(z => {
          if (y.answerOptionId == z.id) {
            if (z.taskTodoUuid) {
              this.updateArray(z.taskTodoUuid);
            }
            if (z.taskSignatureUuid) {
              this.updateArray(z.taskSignatureUuid);
            }
            if (z.taskMessageUuid) {
              this.updateArray(z.taskMessageUuid);
            }
            if (z.taskQuesUuid) {
              this.updateArray(z.taskQuesUuid);
            }
            if (z.taskQnrsUuid) {
              this.updateArray(z.taskQnrsUuid, true);
            }
          }
        })
      })
    }
  }

  updateArray(triggerUuid, questionnaire = false) {
    let task: any = {}
    let tasks = []
    if (questionnaire) {
      tasks = this.tasks.filter(a => a.uuid == triggerUuid)
    } else {
      task = this.tasks.find(x => x.uuid === triggerUuid)
    }
    tasks.map(x => {
      this.updateOrderedTasks(x)
    })
    if (task && task.uuid) {
      this.updateOrderedTasks(task)
    }
  }

  updateOrderedTasks(task) {
    if (task && (task.assignedTo || task.messageTo)) {
      if ((task.assignedTo && ((task.assignedTo == this.user.id) || ((task.status == 'COMPLETED') && (task.assignedBy == this.user.id))))
        || (task.messageTo
        )) {
        this.orderedTasks.push(task);
      }
    } else {
      this.orderedTasks.push(task);
      if (task.status === 'COMPLETED')
        this.getChilds(task);
    }
  }

  selectTask(event, task) {
    if (this.episodeStatus !== 'CANCELLED' && this.episodeStatus !== 'CLOSED' && !this.milestoneCompletedByCCES) {
      this.selected = { taskField: '', task: {}, topicId: null };
      this.questions.map(x => {
        if ((event.checked == undefined || event.checked) && (task.field == 'question') && (task.id == x.id)) {
          x.selected = true;
          this.selected = { taskField: task.field, task: task, topicId: this.topicId };
        }
        else
          x.selected = false;
        return x;
      })
      this.taskTodos.map(x => {
        if ((event.checked == undefined || event.checked) && (task.field == 'todo') && (task.id == x.id)) {
          x.selected = true;
          this.selected = { taskField: task.field, task: task, topicId: this.topicId };
        }
        else
          x.selected = false;
        return x;
      })
      this.taskSignatures.map(x => {
        if ((event.checked == undefined || event.checked) && (task.field == 'signature') && (task.id == x.id)) {
          x.selected = true;
          this.selected = { taskField: task.field, task: task, topicId: this.topicId };
        }
        else
          x.selected = false;
        return x;
      })
      this.messages.map(x => {
        if ((event.checked == undefined || event.checked) && (task.field == 'message') && (task.id == x.id)) {
          x.selected = true;
          this.selected = { taskField: task.field, task: task, topicId: this.topicId };
        }
        else
          x.selected = false;
        return x;
      })
      this.questionnaire.map(x => {
        if ((event.checked == undefined || event.checked) && (task.field == 'questionnaire') && (task.id == x.id)) {
          x.selected = true;
          this.selected = { taskField: task.field, task: task, topicId: this.topicId };
        }
        else
          x.selected = false;
        return x;
      })
      this.updateSelectedTask.emit(this.selected);
    }

  }

  ScrollTo(id, placement, transition) {
    const objId = 'task' + id
    setTimeout(() => {
      this._scrollTo.ScrollTo(objId, placement, transition)
    }, 500);
  }

  updateQuestionsAnswer(event) {
    this.updateQuestionAnswer.emit(event);
  }

  updateQuestionnairesAnswer(event) {
    this.updateQuestionnaireAnswer.emit(event);
  }

}
