import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import * as HeaderBreadCrumbActions from '../../action';
import { AuthService, CommunicationService, UnreadInboxCountService } from '../../services';
import { AccessType, Storage } from '../../utils';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { INBOX, OUTBOX, COMMUNICATIONS } from '../../constants';
import { Observable } from 'rxjs';
import { CommunicationMessageComponent } from '../../modals';
import { MessageListComponent } from './message-list/message-list.component';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent implements OnInit {
  messageThread: Array<object> = [];
  inboxMessageId: number;
  outboxMessageId: number;
  selectedTab: string = INBOX;
  isLoading: boolean = true;
  sentMessageId: number;
  readonly INBOX: string = INBOX;
  readonly OUTBOX: string = OUTBOX;
  otpToken: string;
  showMessageModal: boolean = false;
  unreadInboxCount: number;
  inboxHeadingWithUnreadCount: string;
  inboxHeadingWithoutUnreadCount: string = "<i class='icofont-inbox mr-1 fa-lg'></i> Inbox";
  loggedInUser: any;

  @ViewChild('tabs', { static: true }) public tabs;
  @ViewChild(CommunicationMessageComponent, { static: false }) private _communicationMessageComponent;
  @ViewChild(MessageListComponent, { static: false }) private _messageListComponent;

  constructor(
    private _store: Store<{ bread_crumbs: any }>,
    private _communicationService: CommunicationService,
    private _storage: Storage,
    private _location: Location,
    private _auth: AuthService,
    public unreadInboxCountService: UnreadInboxCountService,
    private _utilityAccess: AccessType
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    return this.otpToken ? false : true;
  }

  ngOnInit() {
    this._store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this._store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Communications', path: `/${COMMUNICATIONS}` }));
    this.otpToken = this._storage.get('session', 'otpToken');
    this.loggedInUser = this._storage.get('local', 'loggedInUser');
    this.getDecryptedData();
  }

  /**
   * gets decrypted data from token if sent as param and removes the param displayed from url
   */
  getDecryptedData() {
    let token: string;
    const encryptedMessageToken = this._storage.get('session', 'encryptedMessageToken');
    if (encryptedMessageToken) {
      token = encryptedMessageToken;
    } else {
      const [path, query] = this._location.path().split('?');
      const params = new HttpParams({ fromString: query });
      token = params.get('token');
      this._location.replaceState(path, params.delete('token').toString());
    }
    if (token) {
      const decryptedData = this._auth.decryptMessageToken(token);
      this._storage.set('session', 'user', JSON.parse(decryptedData));
    }
  }

  onComposeEmail() {
    if (this._utilityAccess.searchAccess('COMMS', 'isEditable')) {
      this.showMessageModal = true;
    }
  }

  changeTabToSent() {
    this.tabs.setActiveTab(2);
    this._messageListComponent.getMessageList();
  }

  /**
   * gets the thread of messages related to specific id
   * @param id 
   */
  getMessageThread(id: number) {
    this.isLoading = true;
    id ? this._communicationService.getMessageThread(id).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(res => {
      this.sentMessageId = null;
      const messageDate = res.rows[0].createdAt.split('T')[0];
      const todaysDate = moment().format('YYYY-MM-DD');
      messageDate === todaysDate ? (
        this.messageThread = res.rows.map((x: object) => ({ ...x, extendedDate: moment(x['createdAt']).calendar() }))
      ) : (
        this.messageThread = res.rows.map((x: object) => ({ ...x, extendedDate: moment(x['createdAt']).format('llll') }))
      )
    }) : (
      this.messageThread.length = 0,
      this.isLoading = false
    );
  }

  /**
   * gets data related to message selected
   * @param message 
   */
  getMessageData(message) {
    const { id, isRead } = message;
    !isRead && this.selectedTab === INBOX && (
      this.unreadInboxCountService.count -= 1,
      this.inboxHeadingWithUnreadCount = `<i class='icofont-inbox mr-1 fa-lg'></i> Inbox 
      <small class='bg-primary-dark text-white px-2 py-1 ml-1 rounded-circle'>
        ${this.unreadInboxCountService.count}
      </small>`
    );
    setTimeout(() => {
      this.selectedTab === INBOX ? this.inboxMessageId = id : this.outboxMessageId = id;
      this.getMessageThread(id);
      this._communicationMessageComponent && this._communicationMessageComponent.onMessageSelected();
    }, 1);
  }

  /**
   *  gets count of unseen messages and sets sent message id
   * @param tab 
   * @param event 
   */
  onTabSelected(tab: string, event: any) {
    if (event.tabset) { // to prevent tab select event while selecting input text
      this.isLoading = true;
      this.selectedTab = tab === 'inbox' ? INBOX : OUTBOX;
      this.selectedTab === INBOX && this.getInboxUnreadCount();
      this.sentMessageId = this._communicationMessageComponent && this._communicationMessageComponent.sentMessageId;
    }
  }

  closeMessageModal() {
    this.showMessageModal = false;
  }

  getInboxUnreadCount() {
    this._communicationService.getInboxUnreadCount().subscribe(res => {
      this.unreadInboxCountService.count = res;
      this.inboxHeadingWithUnreadCount = `<i class='icofont-inbox mr-1 fa-lg'></i> Inbox 
      <small class='bg-primary-dark text-white px-2 py-1 ml-1 rounded-circle'>
        ${this.unreadInboxCountService.count}
      </small>`;
    });
  }
}