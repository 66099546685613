export class HraHsaServiceLine {
    allowedAmount: number;
    allowedQuantity: number;
    billedQuantity: number;
    excptCode: string;
    netAmount: number;
    procedureCode: string;
}

export class PayeeDetail {
    payeeId: number;
    name: string;
    addressLine: string;
    city: string;
    state: string;
    zip: string;
    taxId: number;
    npi: number;
}

class IHealthDiagnosis {
    edit?: string;
    diagnosisCode: string;
    diagnosisType: string;
}

class IClassXrefHolista {
    processingStatus: string;
}

class IClaimDetail {
    type: string;
    claimXRefHolista: IClassXrefHolista
}

class IClaimEditableStatus {
    incoming: string[];
    outgoing: string[];
}
export class IAddDiagnosisDetail {
    healthDiagnosis: IHealthDiagnosis;
    claimsEditableStatuses: IClaimEditableStatus;
    claimDetail: IClaimDetail
}

