import {
  HostListener,
  Component,
  OnInit,
  Input,
  ViewChild,
  NgZone,
  Output,
  EventEmitter,
  OnDestroy
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { finalize, takeWhile } from "rxjs/operators";
import { DependentTask, QuestionAnswers, submitAnswer } from "../../../../models";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import { ChatService, EpisodeTaskService, NotificationService } from '../../../../services';
import { AccessType, Storage, ScrollTo, HolistaUtils } from '../../../../utils';
import { DOCUSIGN_ELIGIBLE_STATUS } from "src/app/constants";

@Component({
  selector: "app-episode-topic",
  templateUrl: "./episode-topic.component.html",
  styleUrls: ["./episode-topic.component.scss"]
})
export class EpisodeTopicComponent implements OnInit, OnDestroy {
  topics: any = [];
  milestone: any;
  isLoading = {
    topics: false,
    docuSign: false
  }
  topicForm: FormGroup;
  submit_enabled = false;
  triggerMilestoneName = "";
  active_topicId: any;
  dependent_tasks = new DependentTask();
  // triggerPeriodOptions = [
  //   { label: "Day(s)", value: "days" },
  //   { label: "Week(s)", value: "weeks" }
  // ];
  selected_task: any;
  updated_task: any;
  episodeId = null;
  user: any;
  submitted = false;
  inheritedTopics = [];
  days_difference;
  dataToBeDelete: any
  taskMessageToProcess = [];
  dependentTasks = []
  dependentTodos = []
  dependentSignatures = []
  dependentMessages = []
  dependentQuestions = []
  dependentQuestionnaires = []
  topicTasks = []
  taskQuestionList = []
  removeList = []
  completePercentage = 0
  answers = new QuestionAnswers();
  questionnaireAnswer = new submitAnswer()
  dependentTopicTasks = []
  activeIndex = 0
  alive: boolean = true
  signatureStatus: string;
  DOCUSIGN_ELIGIBLE_STATUS = DOCUSIGN_ELIGIBLE_STATUS;

  @ViewChild("topicModal", { static: true }) public topicModal;
  @ViewChild("answerModal", { static: true }) public answerModal;
  @Input() public milestones;
  @Input() public assignedBy;
  @Input() public assignedTo;
  @Input() public usermode = false;
  @Input() public episodeDescHeight;
  @Input() public isMobile;
  @Input() public userDetails;
  @Output() setMilestoneView = new EventEmitter<any>();

  @Input() set selected_milestone(selected_milestone) {
    if (selected_milestone.id) {
      this.milestone = selected_milestone;
      this.inheritedTopics = selected_milestone.topics ? selected_milestone.topics : [];
      let trigger_milestone = this.milestones.find(
        x => x.uuid == this.milestone.triggerMilestoneUuid
      );
      this.milestone.triggerMilestoneName =
        trigger_milestone && trigger_milestone.name
          ? trigger_milestone.name
          : "";
      let milestoneDate = selected_milestone.startDate ? selected_milestone.startDate : selected_milestone.relativeStartDate
      if (selected_milestone && milestoneDate)
        this.days_difference = (moment.duration(moment(new Date()).diff(milestoneDate)).asDays());
      this.topics = [];
      this.selected_task = {};
      let activeTopicId = this._activatedRoute.snapshot.queryParams.task ? this._activatedRoute.snapshot.queryParams.task : null;
      if (!this.usermode || (this.usermode && selected_milestone && (selected_milestone.isTriggerOnStart || (milestoneDate && (this.days_difference >= 0) && selected_milestone.episodeStatus !== 'PRELIMINARY'))))
        this.getTopics(selected_milestone.id, activeTopicId, selected_milestone);
    }
  }

  // sticky content
  @HostListener("window:scroll", []) onWindowScroll() {
    // sticky filter
    if (!this.usermode && !this.isMobile) {
      const element = document.getElementById("sticky");
      if (window.pageYOffset > 120 && element) {
        element.classList.add("sticky");
      } else if (element) {
        element.classList.remove("sticky");
      }
    }
  }

  constructor(
    private episodeTaskService: EpisodeTaskService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private router: Router,
    readonly chatService: ChatService,
    public utilityAccess: AccessType,
    private _storage: Storage,
    private _scrollTo: ScrollTo,
    private _notificationService: NotificationService,
    private _holistaUtility: HolistaUtils
  ) {
    this.setTopicForm();
  }
  setTopicForm() {
    this.topicForm = this.formBuilder.group({
      id: [null],
      taskId: [null],
      milestoneId: [null],
      topic: ["", Validators.required],
      patientTopic: ["", Validators.required],
      sequence: [null],
      status: [],
    });
  }

  ngOnInit() {
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    if (this._activatedRoute.snapshot.params.episodeId) {
      this.episodeId = parseInt(this._activatedRoute.snapshot.params.episodeId);
    }
    this._notificationService.listen('document-sign-progress').subscribe((response) => {
      if (response) {
        const { status, taskTodoId: taskSignatureId, taskId } = response;
        if (this.taskQuestionList.length) {
          const index = this.taskQuestionList.findIndex(({ field }) => field === 'signature');
          index && (this.taskQuestionList[index].status = status.toUpperCase());
        } else {
          const updatedStatus = status.toUpperCase();
          if (updatedStatus === 'IN-PROGRESS') {
            this.topics.length && (
              this.topics.find(({ id }) => id === +taskId)
                .taskSignatures.find(({ id }) => id === +taskSignatureId).status = updatedStatus
            );
          }
        }
      }
    });
    this._notificationService.listen('document-signed').subscribe((response) => {
      if (response) {
        const { status, taskTodoId: taskSignatureId, taskId, updatedLink } = response;
        if (this.selected_task && this.selected_task.task && this.selected_task.task.field === 'question') {
          const index = this.taskQuestionList.findIndex(({ field }) => field === 'signature');
          if (index) {
            this.taskQuestionList[index].status = status.toUpperCase();
            this.taskQuestionList[index].taskTodoLink = updatedLink;
            status === 'COMPLETED' && (this.taskQuestionList[index].isAnswered = true);
            const answeredList = this.taskQuestionList.filter(({ status, isAnswered }) => status === 'COMPLETED' || isAnswered);
            this.completePercentage = Math.round(answeredList.length / this.taskQuestionList.length * 100);
          }
        } else {
          const updatedStatus = status.toUpperCase();
          if (updatedStatus === 'COMPLETED' && this.topics.length) {
            const topicIndex = this.topics.findIndex(({ id }) => id === +taskId);
            const taskSignatureIndex = this.topics[topicIndex].taskSignatures.findIndex(({ id }) => id === +taskSignatureId);
            if ((topicIndex > -1) && (taskSignatureIndex > -1)) {
              this.topics[topicIndex].taskSignatures[taskSignatureIndex].status = updatedStatus;
              this.topics[topicIndex].taskSignatures[taskSignatureIndex].taskTodoLink = updatedLink;
              updatedStatus === 'COMPLETED' && this.toastr.success(
                `Signature task completed successfully.`,
                "Success"
              );
            }
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.alive = false
  }

  createTopic() {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      this.topicModal.show()
    }
    else this.toastr.warning("Access Denied", "Warning");
  }

  closeModal(modal, form) {
    this[form].reset();
    this[modal].hide();
    this.submit_enabled = false;
    this.submitted = false;
  }
  processTaskMessages() {
    if (this.taskMessageToProcess.length > 0 && !this.milestone.completedByCcEs) {
      let message = this.taskMessageToProcess[0];
      // this.chatService.sendMessage(message.messageTo, message.messages);
      //update message status via api call and then only increment the loop

      let milestoneId = this._activatedRoute.snapshot.queryParams.active ? this._activatedRoute.snapshot.queryParams.active : null;
      let body = {
        id: message.id,
        type: 'message',
        messageTitle: message.title,
        episodeId: this.episodeId,
        milestoneId
      }
      !this.milestone.isTaskDisabled && this.episodeTaskService
        .updateTaskStatus(body)
        .pipe(
          finalize(() => {
            this.taskMessageToProcess.splice(0, 1);
            this.processTaskMessages()
          })
        )
        .subscribe(
          res => {
            if (res && res.data) {
              this.topics.map(x => {
                x.taskMessages.map(y => {
                  if (res.data.uuid == y.uuid) {
                    y.status = res.data.status;
                  }
                  return y;
                })
                return x;
              })
              this.dependent_tasks.taskMessages.map(x => {
                if (res.data.uuid == x.uuid) {
                  x.status = res.data.status;
                }
                return x;
              })
              this.topics = [...this.topics]
              this.dependent_tasks = JSON.parse(JSON.stringify(this.dependent_tasks));
            }
          },
          error => {
            console.log(error);
          }
        );
    }
  }
  getTopics(milestone_id, activeTopicId?, selected_milestone?) {
    this.taskMessageToProcess.length = 0;
    this.selected_task = {};
    this.isLoading.topics = true;
    this.topics = [];
    this.dependent_tasks = new DependentTask();
    this.episodeTaskService.getTasks(milestone_id).pipe(
      takeWhile(() => this.alive))
      .subscribe(res => {
        this.isLoading.topics = false;
        if (res.length != 0 && this.milestone && this.milestone.id == res[0].milestoneId) {
          this.topics = [];
          this.topicTasks = []
          res.map(x => {
            if (x.taskTodos) {
              x.taskTodos.map((y: any) => {
                this.topicTasks.push(y)
                if (!y.isDependent || y.isDependent && y.isActivated) {
                  y.showDate = this.milestone.showDate
                  if (y.escalationDays && this.milestone.assignedUserId == y.assignedTo && y.status !== 'COMPLETED' && (this.milestone.episodeStatus !== 'PRELIMINARY' || this.milestone.isTriggerOnStart)) {
                    let daysDiff = moment(new Date()).diff(y.showDate, 'days')
                    if (daysDiff >= y.escalationDays) {
                      let dueDay = (daysDiff - y.escalationDays) + 1
                      y.escalationDays = `${dueDay}${(dueDay > 1) ? ' days' : ' day'} ago`;
                      y.status = 'DUE';
                    }
                  }
                }
              })
            }
            if (x.taskSignatures) {
              x.taskSignatures.map((y: any) => {
                this.topicTasks.push(y)
                if (!y.isDependent || y.isDependent && y.isActivated) {
                  y.showDate = this.milestone.showDate
                  if (y.escalationDays && this.milestone.assignedUserId == y.assignedTo && y.status !== 'COMPLETED' && (this.milestone.episodeStatus !== 'PRELIMINARY' || this.milestone.isTriggerOnStart)) {
                    let daysDiff = moment(new Date()).diff(y.showDate, 'days')
                    if (daysDiff >= y.escalationDays) {
                      let dueDay = (daysDiff - y.escalationDays) + 1
                      y.escalationDays = `${dueDay}${(dueDay > 1) ? ' days' : ' day'} ago`;
                      y.status = 'DUE';
                    }
                  }
                }
              })
            }

            if (x.taskMessages) {
              x.taskMessages.map((y: any) => {
                this.topicTasks.push(y)
                if (!y.isDependent || y.isDependent && y.isActivated) {
                  y.showDate = this.milestone.showDate
                  if (y.escalationDays && this.milestone.assignedUserId == y.messageTo && y.status !== 'COMPLETED' && (this.milestone.episodeStatus !== 'PRELIMINARY' || this.milestone.isTriggerOnStart)) {
                    let daysDiff = moment(new Date()).diff(y.showDate, 'days')
                    if (daysDiff >= y.escalationDays) {
                      let dueDay = (daysDiff - y.escalationDays) + 1
                      y.escalationDays = `${dueDay}${(dueDay > 1) ? ' days' : ' day'} ago`;
                      y.status = 'DUE';
                    }
                  }
                }
              })
            }

            if (x.taskQuestions) {
              x.taskQuestions.map((y: any) => {
                this.topicTasks.push(y)
                y.questions.questionOptions.map(z => (z["value"] = false))
                if (!y.isDependent || y.isDependent && y.isActivated) {
                  y.showDate = this.milestone.showDate
                  if (y.escalationDays && y.status !== 'COMPLETED' && (this.milestone.episodeStatus !== 'PRELIMINARY' || this.milestone.isTriggerOnStart)) {
                    let daysDiff = moment(new Date()).diff(y.showDate, 'days')
                    if (daysDiff >= y.escalationDays) {
                      let dueDay = (daysDiff - y.escalationDays) + 1
                      y.escalationDays = `${dueDay}${(dueDay > 1) ? ' days' : ' day'} ago`;
                      y.status = 'DUE';
                    }
                  }
                }
              })
            }

            if (x.taskQnrs) {
              x.taskQnrs.map((y: any) => {
                this.topicTasks.push(y)
                if (!y.isDependent || y.isDependent && y.isActivated) {
                  y.showDate = this.milestone.showDate
                  if (y.escalationDays && y.status !== 'COMPLETED' && (this.milestone.episodeStatus !== 'PRELIMINARY' || this.milestone.isTriggerOnStart)) {
                    let daysDiff = moment(new Date()).diff(y.showDate, 'days')
                    if (daysDiff >= y.escalationDays) {
                      let dueDay = (daysDiff - y.escalationDays) + 1
                      y.escalationDays = `${dueDay}${(dueDay > 1) ? ' days' : ' day'} ago`;
                      y.status = 'DUE';
                    }
                  }
                }
              })
            }
          })
          if (this.days_difference >= 0 && ((this.user.roleCode == 'MP' && this.assignedTo == this.user.id) || ((this.user.roleCode == 'CC' || this.user.roleCode == 'ES') && this.usermode))) {
            res.map(a => {
              a.taskMessages.map(b => {
                if (b.isActivated && (!b.status || (b.status != 'COMPLETED')) && !(this.milestone.episodeStatus == 'CLOSED' || this.milestone.episodeStatus == 'COMPLETED' || this.milestone.episodeStatus == 'CANCELLED')) {
                  //get list of message to complete and take action on
                  this.taskMessageToProcess.push(b);
                }
              })
            })
          }
          this.processTaskMessages();
          res.map(x => {
            x.taskMessages
              .filter(a =>
                this.usermode ? a.isDependent && !a.isActivated : a.isDependent
              )
              .map((b: any) => {
                b.milestoneStatus = this.milestone.status == 'DUE' ? this.milestone.originalMilestoneStatus : this.milestone.status
                b.episodeStatus = this.milestone.episodeStatus
                b.triggerDatePassed = this.milestone.triggerDatePassed
                b.milestoneId = selected_milestone ? selected_milestone.id : null
                b.episodeId = selected_milestone ? selected_milestone.episodeId : null
                b.isTaskDisabled = selected_milestone ? selected_milestone.isTaskDisabled : null
                b.ccId = this.milestone.ccId
                b.esId = this.milestone.esId
                this.dependent_tasks.taskMessages.push(b);
              });
            x.taskQuestions
              .filter(a =>
                this.usermode ? a.isDependent && !a.isActivated : a.isDependent
              )
              .map((b: any) => {
                b.milestoneStatus = this.milestone.status == 'DUE' ? this.milestone.originalMilestoneStatus : this.milestone.status
                b.episodeStatus = this.milestone.episodeStatus
                b.triggerDatePassed = this.milestone.triggerDatePassed
                b.milestoneId = selected_milestone ? selected_milestone.id : null
                b.episodeId = selected_milestone ? selected_milestone.episodeId : null
                b.isTaskDisabled = selected_milestone ? selected_milestone.isTaskDisabled : null
                b.ccId = this.milestone.ccId
                b.esId = this.milestone.esId
                this.dependent_tasks.taskQuestions.push(b);
              });
            x.taskTodos
              .filter(a =>
                this.usermode ? a.isDependent && !a.isActivated : a.isDependent
              )
              .map((b: any) => {
                b.milestoneStatus = this.milestone.status == 'DUE' ? this.milestone.originalMilestoneStatus : this.milestone.status
                b.episodeStatus = this.milestone.episodeStatus
                b.triggerDatePassed = this.milestone.triggerDatePassed
                b.milestoneId = selected_milestone ? selected_milestone.id : null
                b.episodeId = selected_milestone ? selected_milestone.episodeId : null
                b.isTaskDisabled = selected_milestone ? selected_milestone.isTaskDisabled : null
                b.ccId = this.milestone.ccId
                b.esId = this.milestone.esId
                this.dependent_tasks.taskTodos.push(b);
              });
            x.taskSignatures
              .filter(a =>
                this.usermode ? a.isDependent && !a.isActivated : a.isDependent
              )
              .map((b: any) => {
                b.milestoneStatus = this.milestone.status == 'DUE' ? this.milestone.originalMilestoneStatus : this.milestone.status
                b.episodeStatus = this.milestone.episodeStatus
                b.triggerDatePassed = this.milestone.triggerDatePassed
                b.milestoneId = selected_milestone ? selected_milestone.id : null
                b.episodeId = selected_milestone ? selected_milestone.episodeId : null
                b.isTaskDisabled = selected_milestone ? selected_milestone.isTaskDisabled : null
                b.ccId = this.milestone.ccId
                b.esId = this.milestone.esId
                this.dependent_tasks.taskSignatures.push(b);
              });
            x.taskQnrs
              .filter(a =>
                this.usermode ? a.isDependent && !a.isActivated : a.isDependent
              )
              .map((b: any) => {
                b.milestoneStatus = this.milestone.status == 'DUE' ? this.milestone.originalMilestoneStatus : this.milestone.status
                b.episodeStatus = this.milestone.episodeStatus
                b.triggerDatePassed = this.milestone.triggerDatePassed
                b.milestoneId = selected_milestone ? selected_milestone.id : null
                b.episodeId = selected_milestone ? selected_milestone.episodeId : null
                b.isTaskDisabled = selected_milestone ? selected_milestone.isTaskDisabled : null
                b.ccId = this.milestone.ccId
                b.esId = this.milestone.esId
                this.dependent_tasks.taskQnrs.push(b);
              });
            x.taskMessages = x.taskMessages.filter(a =>
              this.usermode ? a : !a.isDependent
            );

            x.taskQuestions = x.taskQuestions.filter(a =>
              this.usermode ? a : !a.isDependent
            );

            x.taskTodos = x.taskTodos.filter(a =>
              this.usermode ? a : !a.isDependent
            );

            x.taskQnrs = x.taskQnrs.filter(a =>
              this.usermode ? a : !a.isDependent
            );

            x.taskSignatures = x.taskSignatures.filter(a =>
              this.usermode ? a : !a.isDependent
            );

            this.topics.push(x);
          });
          this.topics.map(x => {
            this.inheritedTopics.map(y => {
              if (y.uuid == x.uuid) {
                x.status = y.status;
                // x.escalatedDays = y.escalatedDays;
              }
            });
            return x;
          });
          this.topics.map((x: any) => {
            x.episodeStatus = selected_milestone ? selected_milestone.episodeStatus : null
            x.triggerDatePassed = selected_milestone ? selected_milestone.triggerDatePassed : null
            x.milestoneStatus = selected_milestone ? (selected_milestone.status == 'DUE' ? selected_milestone.originalMilestoneStatus : selected_milestone.status) : null
            x.episodeId = selected_milestone ? selected_milestone.episodeId : null
            x.milestoneId = selected_milestone ? selected_milestone.id : null
            x.isTaskDisabled = selected_milestone ? selected_milestone.isTaskDisabled : null
            x.ccId = this.milestone.ccId
            x.esId = this.milestone.esId
            x.completedByCcEs = this.milestone.completedByCcEs
          })
          this.topics.sort((a, b) => a.id - b.id)
          this.dependentTopicTasks = this.topicTasks.filter(x => x.isDependent)
          this.passDataToTask(activeTopicId ? activeTopicId : this.topics[0].id);
        }
      });
  }
  editTopic(topic) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      topic.triggerPeriod = "days";
      this.topicForm.patchValue(topic);
      this.topicModal.show();
    }
    else this.toastr.warning("Access Denied", "Warning");
  }
  topicToDelete(topic: any) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      topic.source = 'Episode'
      topic.type = 'Topic'
      topic.displayName = topic.topic
      this.dataToBeDelete = topic
    }
    else this.toastr.warning("Access Denied", "Warning");
  }

  submitTopic() {
    this.submitted = true;
    this.topicForm.controls.milestoneId.setValue(
      this.topicForm.value.milestoneId
        ? this.topicForm.value.milestoneId
        : this.milestone.id
          ? this.milestone.id
          : null
    );
    if (this.topicForm.valid) {
      this.submit_enabled = true;
      if (this.topicForm.value.id) this.updateTopic();
      else this.saveTopic();
    }
  }
  saveTopic() {
    this.topicForm.controls.sequence.setValue(
      this.topics ? this.topics.length + 1 : 1
    );
    let topic = Object.assign(this.topicForm.value)
    topic.episodeId = this.milestone.episodeId
    this.episodeTaskService
      .saveTopic(topic)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshTopics(res, "saved");
        },
        error => {
          console.log(error);
        }
      );
  }
  updateTopic() {
    let topic = Object.assign(this.topicForm.value)
    topic.episodeId = this.milestone.episodeId
    this.episodeTaskService
      .updateTopic(topic)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshTopics(res, "updated");
        },
        error => {
          console.log(error);
        }
      );
  }
  refreshTopics(response, action) {
    if (response.message) {
      this.toastr.error(response.message, "Error");
    } else {
      let topic = action == "saved" ? response.data : response.data[0];
      topic.episodeId = this.milestone.episodeId
      topic.milestoneId = this.milestone.id
      if (this.topics && this.topics.length > 0) {
        let index = this.topics.findIndex(x => x.id === topic.id);
        if (index > -1) {
          topic.taskTodos = this.topics[index].taskTodos;
          topic.taskMessages = this.topics[index].taskMessages;
          topic.taskQuestions = this.topics[index].taskQuestions;
          topic.taskQnrs = this.topics[index].taskQnrs;
          topic.taskSignatures = this.topics[index].taskSignatures;
          this.topics[index] = topic;
        } else {
          topic.taskTodos = [];
          topic.taskMessages = [];
          topic.taskQuestions = [];
          topic.taskQnrs = [];
          topic.taskSignatures = [];
          this.topics.push(topic);
        }
      } else {
        topic.taskTodos = [];
        topic.taskMessages = [];
        topic.taskQuestions = [];
        topic.taskQnrs = [];
        topic.taskSignatures = [];
        this.topics.push(topic);
      }
      this.toastr.success(`Topic ${action} successfully.`, "Success");
    }
    this.closeModal("topicModal", "topicForm");
  }

  updateTopicHandler(event) {
    this.active_topicId = null;
    if (event && event.isDependent) {
      let index = this.dependent_tasks[`${event.active_task}`].findIndex(
        x => x.id == event.id
      );
      if (index > -1)
        this.dependent_tasks[`${event.active_task}`][index] = event;
      else this.dependent_tasks[`${event.active_task}`].push(event);
      if (event.topic) {
        event.topic.taskQuestions.map(y => {
          y.valueChanged = false;
          if (y.isDependent) {
            y.questions.questionOptions.map(z => {
              if (z.id == event.taskField.optionId) {
                z[`${event.taskField.uuid}`] = event.uuid;
                y.valueChanged = true;
              }
              return z;
            });
            let idx = this.dependent_tasks.taskQuestions.findIndex(
              x => x.questionId == y.questionId && y.valueChanged
            );
            if (idx > -1) this.dependent_tasks.taskQuestions[idx] = y;
          }
        });

        event.topic.taskQnrs.map(a => {
          a.valueChanged = false;
          if (a.isDependent) {
            a.qnrs.qnrsQues.map(b => {
              b.question.questionOptions.map(z => {
                if (z.id == event.taskField.optionId) {
                  z[`${event.taskField.uuid}`] = event.uuid;
                  a.valueChanged = true;
                }
                return z;
              });
              return b;
            });
            let idx = this.dependent_tasks.taskQnrs.findIndex(
              x => x.questionnaireId == a.questionnaireId && a.valueChanged
            );
            if (idx > -1) this.dependent_tasks.taskQnrs[idx] = a;
          }
        });
        this.topics.map(x => {
          if (x.id == event.topic.id) {
            x.taskMessages = event.topic.taskMessages.filter(
              a => a.isActivated && !a.isDependent
            );
            x.taskQuestions = event.topic.taskQuestions.filter(
              a => a.isActivated && !a.isDependent
            );
            x.taskQuestions.map(y => {
              y.questions.questionOptions.map(z => {
                if (z.id == event.taskField.optionId)
                  z[`${event.taskField.uuid}`] = event.uuid;
                return z;
              });
              return y;
            });
            x.taskQnrs = event.topic.taskQnrs.filter(a => a.isActivated && !a.isDependent);
            x.taskQnrs.map(y => {
              y.qnrs.qnrsQues.map(a => {
                a.question.questionOptions.map(z => {
                  if (z.id == event.taskField.optionId)
                    z[`${event.taskField.uuid}`] = event.uuid;
                  return z;
                });
                return a;
              });
              return y;
            });
            x.taskTodos = event.topic.taskTodos.filter(a => a.isActivated && !a.isDependent);
          }
          return x;
        });
      }
    } else {
      let topic_index = this.topics.findIndex(
        x => x.id == event.active_topicId
      );
      if (topic_index > -1) {
        if (
          this.topics[topic_index][event.active_task] &&
          this.topics[topic_index][event.active_task].length > 0
        ) {
          let task_index = this.topics[topic_index][
            event.active_task
          ].findIndex(y => y.id == event.id);
          if (task_index > -1) {
            this.topics[topic_index][event.active_task][task_index] = event;
          } else {
            this.topics[topic_index][event.active_task].push(event);
          }
        } else {
          this.topics[topic_index][event.active_task].push(event);
        }
      }
    }
    setTimeout(() => {
      // this.episodeDescHeight = document.getElementById('episodeDesc').offsetHeight;
      this.active_topicId = event.active_topicId;
    });
  }
  passDataToTask(id) {
    this.active_topicId = id;
    this.selected_task = {};
    this.answers = new QuestionAnswers();
    let url = this._activatedRoute.snapshot.url;
    this.router.navigate([url[0].path, url[1].path, url[2].path], { queryParams: { active: this._activatedRoute.snapshot.queryParams.active, task: this.active_topicId } });
    let answeredTaskId = this._storage.get('session', 'answeredTaskId')
    if (answeredTaskId) {
      setTimeout(() => {
        this.ScrollTo(id, 'center', 'smooth')
      }, 300);
    }
  }

  updateSelectedTaskHandler(event) {
    if (event && event.task) {
      setTimeout(() => {
        this.selected_task = event;
        this.answers = new QuestionAnswers();
        if (
          this.selected_task.task &&
          (!this.selected_task.task.status || this.selected_task.task.status == 'DUE') &&
          !(this.selected_task.task.topicStatus == 'COMPLETED' || this.selected_task.task.topicStatus == 'CANCELLED' || this.selected_task.task.topicStatus == 'CLOSED') &&
          ((this.selected_task.taskField == "todo" &&
            this.selected_task.task.type !== 'signature' &&
            !this.selected_task.task.isAcknowledgedRequired &&
            !this.selected_task.task.taskTodoLink) ||
            (this.selected_task.taskField == "message" &&
              !this.selected_task.task.documentPath))
        ) {
          setTimeout(() => {
            this.updateUserTaskStatus();
          }, 1000);
        }
      });
    }
  }
  assignCheckboxValue(index, value, option) {
    if (!value) {
      let idx = this.answers.checkbox.findIndex(x => x == option.id);
      if (idx > -1) this.answers.checkbox.splice(idx, 1);
    } else {
      this.answers.checkbox.push(option.id);
    }
  }

  updateUserTaskStatus() {
    let milestoneId = this._activatedRoute.snapshot.queryParams.active ? this._activatedRoute.snapshot.queryParams.active : null;
    let body = {
      id: this.selected_task.task.id,
      type: this.selected_task.taskField,
      messageTitle: this.selected_task.taskField == 'message' ? this.selected_task.task.title : null,
      episodeId: this.episodeId,
      milestoneId
    }
    let topictask = this.selected_task.taskField;
    if (this.selected_task.task.isAcknowledgedRequired)
      this.submit_enabled = true;
    this.episodeTaskService
      .updateTaskStatus(body)
      .pipe(
        finalize(() => {
          if (this.selected_task.task.isAcknowledgedRequired)
            this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          if (res && res.data) {
            res.data.field = topictask;
            res.data.selected = false;
            this.updated_task = res.data;
          }
          if (this.selected_task.taskField != 'message') {
            this.topics = [...this.topics.map(x => {
              if (x.id == this.active_topicId) {
                x.taskTodos = [...x.taskTodos.map(y => {
                  if (y.id == this.selected_task.task.id) {
                    y.status = 'COMPLETED';
                  }
                  return y;
                })]
              }
              return x;
            })];
          }
          this.selected_task.task = res.data;
          this.toastr.success(
            `${(this.selected_task.taskField == 'message') ? "Message" : "Action Item"
            } completed successfully.`,
            "Success"
          );
        },
        error => {
          console.log(error);
        }
      );
  }

  submitUserQuestionAnswer() {
    let milestone_id = this._activatedRoute.snapshot.queryParams.active;
    let answeredList = []
    answeredList = this.taskQuestionList.filter(x => x.isAnswered)
    this.completePercentage = Math.round(answeredList.length / this.taskQuestionList.length * 100);
    const selected_task = this.selected_task.task ? this.selected_task.task : this.selected_task
    const task_field = this.selected_task.taskField ? this.selected_task.taskField : this.selected_task.field
    this.submit_enabled = true
    this.questionnaireAnswer.type = task_field
    this.questionnaireAnswer.taskId = selected_task.id
    this.questionnaireAnswer.episodeId = this.episodeId
    this.questionnaireAnswer.milestoneId = this.milestone.id
    this.questionnaireAnswer.topicId = this.selected_task.topicId ? this.selected_task.topicId : this.selected_task.taskId
    this.questionnaireAnswer.taskName = task_field === 'questionnaire' ? selected_task.qnrs.name : selected_task.questions.question
    this.taskQuestionList.map(x => {
      if (x.field === 'question' || x.field === 'questionnaire') {
        this.setAnswer(x)
      }
    })
    this.episodeTaskService
      .saveAnswer(this.questionnaireAnswer)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(res => {
        let answeredTaskId = this.selected_task.task ? this.selected_task.task.uuid : this.selected_task.uuid
        this.getTopics(this.milestone.id, this.active_topicId);
        this._storage.set('session', 'answeredTaskId', answeredTaskId)
        this.closeAnswerModal()
        this.toastr.success(`Question completed successfully.`, "Success");
      });
  }

  ScrollTo(id, placement, transition) {
    const objId = 'topic' + id
    setTimeout(() => {
      this._scrollTo.ScrollTo(objId, placement, transition)
    }, 200);
  }

  visitLink(task) {
    let link = task.taskTodoLink ? task.taskTodoLink : task.documentPath;
    const selected_task = this.selected_task.task ? this.selected_task.task : this.selected_task
    this._holistaUtility.customRedirect(link);
    if ((!task.status || task.status === 'DUE') && !(task.topicStatus == 'CANCELLED' || task.topicStatus == 'CLOSED' || task.topicStatus == 'COMPLETED') && selected_task.field === 'todo') {
      if (task.field == 'message' || (task.field == 'todo' && !task.isAcknowledgedRequired))
        this.updateUserTaskStatus();
    }
  }

  deleteTopic(e) {
    let index = this.topics.findIndex(x => x.id == e.id)
    if (index > -1) {
      this.topics.splice(index, 1)
    }
    this.dataToBeDelete = null
  }

  cancelDelete(e) {
    this.dataToBeDelete = e
  }

  deleteDependent(task) {
    let temp_dependent_task = JSON.parse(JSON.stringify(this.dependent_tasks));
    if (task.field == 'todo') {
      let index = temp_dependent_task.taskTodos.findIndex(
        x => x.id == task.id
      );
      if (index > -1)
        temp_dependent_task.taskTodos.splice(index, 1)
    }
    if (task.field == 'signature') {
      let index = temp_dependent_task.taskSignatures.findIndex(
        x => x.id == task.id
      );
      if (index > -1)
        temp_dependent_task.taskSignatures.splice(index, 1)
    }
    if (task.field == 'message') {
      let index = temp_dependent_task.taskMessages.findIndex(
        x => x.id == task.id
      );
      if (index > -1)
        temp_dependent_task.taskMessages.splice(index, 1)
    }

    if (task.field == 'question') {
      let index = temp_dependent_task.taskQuestions.findIndex(
        x => x.id == task.id
      );
      if (index > -1)
        temp_dependent_task.taskQuestions.splice(index, 1)
    }

    if (task.field == 'questionnaire') {
      let index = temp_dependent_task.taskQnrs.findIndex(
        x => x.id == task.id
      );
      if (index > -1)
        temp_dependent_task.taskQnrs.splice(index, 1)
    }

    this.dependent_tasks = JSON.parse(JSON.stringify(temp_dependent_task));
  }

  checkForUnprocessedMessages() {
    if (this.topics && this.topics.length > 0 && this.active_topicId) {
      let topic = this.topics.find(x => x.id == this.active_topicId)
      if (topic) {
        if (topic.taskMessages && topic.taskMessages.length > 0) {
          topic.taskMessages.map(b => {
            if (b.isActivated && (!b.status || (b.status != 'COMPLETED')) && !(this.milestone.status == 'CLOSED' || this.milestone.status == 'COMPLETED')) {
              //get list of message to complete and take action on
              this.taskMessageToProcess.push(b);
            }
          })
          this.processTaskMessages();
        }
      }
    }
  }
  goBack(state) {
    // this.selected_milestone
    let url = this._activatedRoute.snapshot.url;
    if (state == 'Milestone') {
      this.setMilestoneView.emit('setMilestoneView');

      this.router.navigate([url[0].path, url[1].path, url[2].path]);

    }
    if (state == 'Topic')
      this.selected_task = {};
  }

  deleteTask(task) {
    this.topics.map(x => {
      if (task.field == 'todo') {
        let index = x.taskTodos.findIndex(x => x.id == task.id)
        if (index > -1) {
          x.taskTodos.splice(index, 1)
        }
      }
      if (task.field == 'signature') {
        let index = x.taskSignatures.findIndex(x => x.id == task.id)
        if (index > -1) {
          x.taskSignatures.splice(index, 1)
        }
      }
      if (task.field == 'message') {
        let index = x.taskMessages.findIndex(x => x.id == task.id)
        if (index > -1) {
          x.taskMessages.splice(index, 1)
        }
      }

      if (task.field == 'question') {
        let index = x.taskQuestions.findIndex(x => x.id == task.id)
        if (index > -1) {
          x.taskQuestions.splice(index, 1)
        }
      }

      if (task.field == 'questionnaire') {
        let index = x.taskQnrs.findIndex(x => x.id == task.id)
        if (index > -1) {
          x.taskQnrs.splice(index, 1)
        }
      }
    })
  }

  answerOption(event, option, task?, index?) {
    const taskVerifier = task.field === 'question' ? 'questions' : 'question'
    const eventChecker = event === true ? true : task[taskVerifier].questionTypes.code === 'CHECKBOX' ? event.checked : event.target.checked
    const questionOptionUuid = option.uuid
    if (eventChecker) {
      if (task[taskVerifier].questionTypes.code === 'RADIO') {
        this.taskQuestionList[index].isAnswered = true
        if (task[taskVerifier].ansOpt.length > 0) {
          task[taskVerifier].ansOpt.map(x => {
            const optionId = x.answerOptionId ? x.answerOptionId : x
            task[taskVerifier].questionOptions.map(y => {
              if (optionId === y.id && option.id !== optionId) {
                this.removeDependentTask(y)
              }
            })
          })
          this.refreshTaskQuestions(task.id)
        }
      }
      if (this.taskQuestionList[index]) {
        let optionAnswered = this.taskQuestionList[index][taskVerifier].ansOpt.find(x => (x.answerOptionId ? x.answerOptionId : x) === option.id)
        if (!optionAnswered) {
          if (this.taskQuestionList[index][taskVerifier].questionTypes.code === 'RADIO') {
            this.taskQuestionList[index][taskVerifier].ansOpt = []
            this.taskQuestionList[index][taskVerifier].ansOpt.push(option.id)
          } else {
            this.taskQuestionList[index][taskVerifier].ansOpt.push(option.id)
            this.taskQuestionList[index][taskVerifier].ansOpt.map(a => {
              const optionId = a.answerOptionId ? a.answerOptionId : a
              this.taskQuestionList[index][taskVerifier].questionOptions.map(q => {
                if (optionId === q.id) {
                  q.selected = true
                }
                return q
              })
            })
          }
        }
      }

      // To get dependent task
      this.updateArray(option, questionOptionUuid, index);

      if (task[taskVerifier].questionTypes.code === 'CHECKBOX') {
        this.taskQuestionList[index].isAnswered = true
      }
    } else {
      let ansOptionIndex = this.taskQuestionList[index][taskVerifier].ansOpt.findIndex(x => (x.answerOptionId ? x.answerOptionId : x) === option.id)
      if (ansOptionIndex > -1) {
        this.taskQuestionList[index][taskVerifier].questionOptions.map(q => {
          if (option.id === q.id) {
            q.selected = false
          }
          return q
        })
        this.taskQuestionList[index][taskVerifier].ansOpt.splice(ansOptionIndex, 1)
      }
      if (this.taskQuestionList[index][taskVerifier].ansOpt.length === 0) {
        task.isAnswered = false
      }
      this.removeDependentTask(option)
      this.refreshTaskQuestions()
    }
  }

  refreshTaskQuestions(taskId?) {
    if (this.removeList.length > 0) {
      this.taskQuestionList = this.taskQuestionList.filter(x => {
        let uuidFound = false
        this.removeList.map(y => {
          const question = y.question ? y.question : y.questions ? y.questions : null
          y.isAnswered = false
          if ((y.field === 'question' || y.field === 'questionnaire') && y.status !== 'COMPLETED') {
            question.ansOpt = []
            question.questionOptions.map(x => {
              if (x.selected) x.selected = false
            })
          }
          if (x.uuid === y.uuid) {
            uuidFound = true
          }
        })
        if (!uuidFound) {
          return x
        }
      })
    }
    if (taskId) {
      let taskIndex = this.taskQuestionList.findIndex(x => x.id == taskId)
      if (taskIndex > -1) {
        this.taskQuestionList[taskIndex].isAnswered = true
      }
    }
  }

  removeDependentTask(option, dependent?) {
    if (!dependent) {
      this.removeList = []
    }
    this.taskQuestionList.map(x => {
      if (x.dependentUuid === option.uuid) {
        const question = x.question ? x.question : x.questions ? x.questions : null
        if (question && x.isAnswered) {
          question.ansOpt.map(a => {
            const optionId = a.answerOptionId ? a.answerOptionId : a
            question.questionOptions.map(b => {
              if (optionId === b.id)
                this.removeDependentTask(b, 'dependent')
            })
          })
        }
        this.removeList.push(x)
      }
    })
  }

  updateArray(option, questionOptionUuid, index) {
    let dependentTask = []
    let duplicateTopicTasks = JSON.parse(JSON.stringify(this.topicTasks))
    duplicateTopicTasks.filter(x => {
      if (x.uuid === option.taskTodoUuid || x.uuid === option.taskSignatureUuid || x.uuid === option.taskMessageUuid || x.uuid === option.taskQuesUuid || x.uuid === option.taskQnrsUuid) {
        x.dependentUuid = questionOptionUuid
        const question = x.field === 'questionnaire' ? 'question' : 'questions'
        if (x.field === 'questionnaire') {
          x.qnrs.qnrsQues.map(y => {
            y.dependentOptionId = option.id
            if (y.status === 'COMPLETED') {
              y[question].field = 'question'
              if (y[question].ansOpt && y[question].ansOpt.length > 0) {
                y.isAnswered = true
                y.dependentUuid = questionOptionUuid
                if (y[question].questionTypes.code === 'TEXTFIELD') {
                  y[question].answer = y[question].ansOpt[0].answer
                } else {
                  y[question].ansOpt.map(a => {
                    const optionId = a.answerOptionId ? a.answerOptionId : a
                    y.question.questionOptions.map(q => {
                      if (optionId === q.id) {
                        q.selected = true
                      }
                      return q
                    })
                  })
                }
              }
            } else {
              // y[question].field = 'question'
              y.dependentUuid = questionOptionUuid
              y[question].ansOpt = [];
              y[question].answer = ''
              if (y[question].questionOptions && y[question].questionOptions.length > 0) {
                y[question].questionOptions.map(q => {
                  if (q.selected)
                    q.selected = false
                })
              }
            }
            dependentTask.push(y)
            return y
          })
        }
        else if (x.field === 'question') {
          x.dependentOptionId = option.id
          if (x.status === 'COMPLETED') {
            if (x[question].ansOpt && x[question].ansOpt.length > 0) {
              x.isAnswered = true
              if (x[question].questionTypes.code === 'TEXTFIELD') {
                x[question].answer = x.questions.ansOpt[0].answer
              } else {
                x[question].ansOpt.map(a => {
                  const optionId = a.answerOptionId ? a.answerOptionId : a
                  x[question].questionOptions.map(q => {
                    if (optionId === q.id) {
                      q.selected = true
                    }
                    return q
                  })
                })
              }
            }
          } else {
            x[question].ansOpt = [];
            x[question].answer = ''
            if (x[question].questionOptions && x[question].questionOptions.length > 0) {
              x[question].questionOptions.map(q => {
                if (q.selected)
                  q.selected = false
              })
            }
          }
          dependentTask.push(x)
        } else {
          if (x.field === 'todo' && x.isAcknowledgedRequired && x.status !== 'COMPLETED') {
            x.isAnswered = false
          } else if (x.field === 'signature' && x.status !== 'COMPLETED') {
            x.isAnswered = false
          } else {
            x.isAnswered = true
          }
          if (x.assignedToRole === 'MP' || x.messageToRole === 'MP') {
            dependentTask.push(x)
          }
        }
      }
    })
    if (dependentTask && dependentTask.length > 0) {
      let taskToBeAdded = []
      dependentTask.filter(x => {
        if (x.field === 'question' || x.field === 'questionnaire') {
          const task = this.taskQuestionList.find(y => x.questionId === y.questionId)
          if (!task) {
            taskToBeAdded.push(x)
          }
        } else {
          const task = this.taskQuestionList.find(y => x.id === y.id && (y.field === "todo" || y.field === "message" || y.field === "signature"))
          if (!task) {
            taskToBeAdded.push(x)
          }
        }
      })
      this.taskQuestionList.splice(index + 1, 0, ...taskToBeAdded)
    }
  }

  closeAnswerModal() {
    this.answerModal.hide()
    this.taskQuestionList = []
    this.completePercentage = 0
    this.activeIndex = 0
    this.questionnaireAnswer = new submitAnswer()
  }

  stepperNext(index, task) {
    if (task.field === 'message' || task.field === 'todo' && !task.isAcknowledgedRequired) {
      task.isAnswered = true
    }
    this.updateDependentTask(task)
    if (this.taskQuestionList[index + 1] && (this.taskQuestionList[index + 1].field === 'question' || this.taskQuestionList[index + 1].field === 'questionnaire') && this.taskQuestionList[index + 1].status === 'COMPLETED') {
      const question = this.taskQuestionList[index + 1].field === 'question' ? 'questions' : 'question'
      if (this.taskQuestionList[index + 1][question].questionTypes.code === 'CHECKBOX' || this.taskQuestionList[index + 1][question].questionTypes.code === 'RADIO') {
        this.taskQuestionList[index + 1][question].ansOpt.map(x => {
          const optionId = x.answerOptionId ? x.answerOptionId : x
          this.taskQuestionList[index + 1][question].questionOptions.map(y => {
            if (y.id === optionId) {
              this.updateArray(y, y.uuid, index + 1)
            }
          })
        })
      }
    }

    let answeredList = []
    answeredList = this.taskQuestionList.filter(x => x.isAnswered)
    this.completePercentage = Math.round(answeredList.length / this.taskQuestionList.length * 100)
    this.activeIndex++
  }

  updateDependentTask(task) {
    if (task.field === 'question' || task.field === 'questionnaire' && task.status === 'COMPLETED') {
      const question = task.field === 'question' ? 'questions' : task.field === 'questionnaire' ? 'question' : null
      let taskIndex = this.taskQuestionList.findIndex(x => x.id === task.id)
      if (task[question].questionTypes.code === 'RADIO' || task[question].questionTypes.code === 'CHECKBOX') {
        task[question].ansOpt.map(x => {
          const optionId = x.answerOptionId ? x.answerOptionId : x
          task[question].questionOptions.map(y => {
            if (y.id === optionId) {
              this.updateArray(y, y.uuid, taskIndex)
            }
          })
        })
      }
    }
  }

  stepperPrevious(index, task) {
    this.taskQuestionList = [...this.taskQuestionList]
    this.activeIndex--
  }

  setAnswer(task) {
    const question = task.field === 'question' ? 'questions' : task.field === 'questionnaire' ? 'question' : null
    let body = {
      episodeId: this.episodeId,
      milestoneId: this.milestone.id,
      topicId: this.selected_task.topicId ? this.selected_task.topicId : this.selected_task.taskId,
      taskId: task.id,
      questionId: task.questionId,
      userId: this.user.id,
      isDependent: task.isDependent,
      taskType: task.field,
      dependentOptionId: task.isDependent ? task.dependentOptionId : null,
      questionType: task.questions
        ? task.questions.questionTypes.code
        : task.question.questionTypes.code,
      answer: task[question].answer ? task[question].answer : null,
      questionName: task.questions ? task.questions.question : task.question.question,
      answerOptionId: [] = task[question] && task[question].questionTypes.code == 'CHECKBOX' || task[question].questionTypes.code == 'RADIO' ? task[question].ansOpt.map(x => x.answerOptionId ? x.answerOptionId : x) : []
    }
    this.questionnaireAnswer.answers.push(body)
  }

  textAnswer(task, index) {
    const question = task.question ? task.question : task.questions
    let answeredList = []
    if (question.answer && question.answer.length > 0) {
      this.taskQuestionList[index].isAnswered = true
      answeredList = this.taskQuestionList.filter(x => x.isAnswered)
      this.completePercentage = Math.round(answeredList.length / this.taskQuestionList.length * 100);
    }
    else {
      this.taskQuestionList[index].isAnswered = false
      answeredList = this.taskQuestionList.filter(x => x.isAnswered)
      this.completePercentage = Math.round(answeredList.length / this.taskQuestionList.length * 100);
    }
  }

  showAnswerModal() {
    let selected_task = JSON.parse(JSON.stringify(this.selected_task.task ? this.selected_task.task : this.selected_task))
    const task = selected_task
    const taskVerifier = task.field === 'question' ? 'questions' : 'question'
    const task_field = this.selected_task.taskField ? this.selected_task.taskField : this.selected_task.field
    if (task.status === 'COMPLETED') {
      this.completePercentage = 100
    }
    this.answerModal.show()
    this.taskQuestionList = []
    if (task_field === 'questionnaire') {
      task.qnrs.qnrsQues.map(x => {
        if (x.status === 'COMPLETED') {
          x.isAnswered = true
          // x.question.field = 'question'
          if (x[taskVerifier].questionTypes.code === 'TEXTFIELD') {
            x[taskVerifier].answer = x[taskVerifier].ansOpt[0].answer
          } else {
            if (x[taskVerifier].ansOpt && x[taskVerifier].ansOpt.length > 0) {
              x[taskVerifier].ansOpt.map(a => {
                const optionId = a.answerOptionId ? a.answerOptionId : a
                x[taskVerifier].questionOptions.map(q => {
                  if (optionId === q.id) {
                    q.selected = true
                  }
                  return q
                })
              })
            }
          }
        } else {
          x.isAnswered = false
          // x[taskVerifier].field = 'question'
          x[taskVerifier].ansOpt = []
          x[taskVerifier].answer = ''
        }
        this.taskQuestionList.push(x)
        this.updateDependentTask(x)
        return x
      })
    } else {
      if (task.status === 'COMPLETED') {
        task.isAnswered = true
        if (task[taskVerifier].questionTypes.code === 'TEXTFIELD') {
          task[taskVerifier].answer = task[taskVerifier].ansOpt[0].answer
        } else {
          if (task[taskVerifier].ansOpt && task[taskVerifier].ansOpt.length > 0) {
            task[taskVerifier].ansOpt.map(a => {
              const optionId = a.answerOptionId ? a.answerOptionId : a
              task[taskVerifier].questionOptions.map(q => {
                if (optionId === q.id) {
                  q.selected = true
                }
                return q
              })
            })
          }
        }
      } else {
        task.isAnswered = false
        // task.questions.field = 'question'
        task[taskVerifier].ansOpt = []
        task[taskVerifier].answer = ''
      }
      this.taskQuestionList.push(task)
      this.updateDependentTask(task)
    }
  }

  editQuestionnaireAnswer(event) {
    this.selected_task = null
    let questionnaireEdit = JSON.parse(JSON.stringify(event))
    this.selected_task = questionnaireEdit
    this.selected_task.taskField = event.field
    this.showAnswerModal()
  }

  editQuestionAnswer(event) {
    this.selected_task = null
    let questionEdit = JSON.parse(JSON.stringify(event))
    this.selected_task = questionEdit
    this.selected_task.taskField = event.field
    this.showAnswerModal()
  }

  todoAcknowledge(index) {
    this.taskQuestionList[index].isAnswered = true
  }

  redirectToDocuSignTemplate(selectedTask) {
    this.isLoading.docuSign = true;
    const { id, taskId } = selectedTask.task;
    const params = {
      taskTodoId: id
    }
    this.episodeTaskService.getLinkToDocuSign(this.episodeId, taskId, params)
      .pipe(
        finalize(() => { this.isLoading.docuSign = false }))
      .subscribe((response: any) => {
        if (response.success) {
          this.handleRedirect(response.data.envelopeUrl);
        } else this.toastr.error(response.message);
      }, error => {
        console.log('Error getting link to docuSign');
        this.toastr.error(error)
      }
      )
  }

  handleRedirect(url: string) {
    window.open(url, '_blank');
  }
}
