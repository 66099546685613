<div class="grey lighten-5 mb-2 p-3">
  <mdb-card>
    <mdb-card-header class="pointer-cursor" (click)="toggleCard()">
      <div class="d-flex justify-content-between align-items-center">
        <p class="m-0 font-weight-bold">Report Filter Criteria</p>
        <span class="link pointer-cursor ml-3">
          <i [class]="expandAdvanceFiltering ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body class="p-3" *ngIf="expandAdvanceFiltering">
      <form [formGroup]="ancillaryReportForm" (ngSubmit)="onGenerateAncillaryReport()">
        <div class="row">
          <div class="col-4 form-group">
            <label for="ancillary-report-date-type-select">Report Date *</label>
            <app-select
              [options]="reportDateTypeList"
              placeholder="Select report date"
              id="ancillary-report-date-type-select"
              filterEnabled="false"
              [control]="ancillaryReportForm.controls['dateType']"
              (onSelected)="onReportDateTypeSelected($event)">
            </app-select>
          </div>
          <div class="col-4 form-group">
            <div class="form-row">
              <div class="col">
                <label for="ancillary-report-date-from">From</label>
                <mdb-date-picker
                  [inline]="true"
                  name="ancillary-report-date-from"
                  data-cy="ancillary-report-date-from"
                  placeholder="Select date"
                  formControlName="startDate"
                  [disabled]="isPreSelectedFilter || !ancillaryReportForm.value.dateType">
                </mdb-date-picker>
              </div>
              <div class="col">
                <label for="ancillary-report-start-date">To</label>
                <mdb-date-picker
                  [inline]="true"
                  name="ancillary-report-start-date"
                  data-cy="ancillary-report-start-date"
                  placeholder="Select date"
                  formControlName="endDate"
                  [disabled]="isPreSelectedFilter || !ancillaryReportForm.value.dateType">
                </mdb-date-picker>
              </div>
            </div>
          </div>
          <div class="col-4 form-group">
            <label for="ancillary-report-status-select">Status</label>
            <app-select
              [options]="statusList"
              placeholder="Select status"
              filterEnabled="false"
              allowClear="true"
              id="ancillary-report-status-select"
              multiple="true"
              [control]="ancillaryReportForm.controls['status']">
            </app-select>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-4 form-group">
              <label for="ancillary-report-market-select">Market</label>
              <span
                *ngIf="isLoading.marketList"
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"></span>
              <app-select
                  [options]="marketList"
                  multiple="true"
                  [disabled]="isMarketSelectDisabled()"
                  (onSelected)="getProviders()"
                  (onDeselected)="getProviders()"
                  allowClear="true"
                  placeholder="Select market"
                  id="ancillary-report-market-select"
                  [control]="ancillaryReportForm.controls['marketCode']"
                  >
              </app-select>
            </div> -->
          <div class="col-4 form-group">
            <label for="ancillary-report-provider-select">Provider</label>
            <span
              *ngIf="isLoading.providerList"
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"></span>
            <app-select
              [options]="providerList"
              placeholder="Select provider"
              id="ancillary-report-provider-select"
              multiple="true"
              filterEnabled="true"
              allowClear="true"
              [disabled]="isProviderSelectDisabled()"
              [control]="ancillaryReportForm.controls['provider']">
            </app-select>
          </div>
          <div class="col-4 form-group">
            <label for="ancillary-report-episode-select">Episode Type</label>
            <span
              *ngIf="isLoading.episodeTypeList"
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"></span>
            <app-select
              [options]="episodeTypeList"
              placeholder="Select episode"
              [disabled]="isEpisodeSelectDisabled()"
              id="ancillary-report-episode-select"
              multiple="true"
              allowClear="true"
              [control]="ancillaryReportForm.controls['episodeType']">
            </app-select>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
          <button
            type="button"
            class="btn btn-light bg-white border m-0"
            [disabled]="isLoading.ancillaryReport"
            mdbWavesEffect
            (click)="onResetFilters()">
            Clear
          </button>
          <button
            class="btn btn-secondary waves-light m-0 ml-2"
            mdbWavesEffect
            type="submit"
            [disabled]="enableReportGenerate()">
            <span
              *ngIf="isLoading.ancillaryReport"
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"></span>
            Generate Report
          </button>
        </div>
      </form>
    </mdb-card-body>
  </mdb-card>
</div>

<div class="container-fluid mb-5 pb-5">
  <app-loader [loader]="isLoading.ancillaryReport"></app-loader>
  <app-table
    *ngIf="displayReport()"
    [report]="report"
    [exportTable]="true"
    [module]="'report'"
    tableHeight="800px"></app-table>
</div>
