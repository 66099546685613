import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { MdbFileUploadComponent } from 'mdb-file-upload';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { DocumentService, ToasterService } from '../../services';
import { AccessType, ScrollTo, Storage } from '../../utils';
import * as HeaderBreadCrumbActions from '../../action';
import { DocumentResponse } from '../../models';
import { ACCESS_DENIED, RestrictSpace, UPDATE_SUCCESS, UPLOAD_SUCCESS } from '../../constants';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  documentForm: FormGroup;
  documentNameForm: FormGroup;
  submit_enabled = false;
  loading = false;
  documentList: DocumentResponse[] = [];
  fileExtension: string;
  fileUrl;
  downloadFileName: any;
  user: any;
  usermode = false;
  selected_document: number;
  submitted = false;
  nameSubmitted: boolean = false;
  editedRow = false;
  selected_row: number;
  totalCount = 0;
  documentQuery = {
    page: 1,
    limit: 10,
    sortType: 'desc',
    sortBy: 'id',
    keyword: ''
  }
  reverse: boolean = true;
  result = { documents: true, searchedDocuments: true };
  searchModelChanged: Subject<string> = new Subject<string>();
  @ViewChild('uploadDocumentModal', { static: true }) public uploadDocumentModal;
  @ViewChild('uploader', { static: true }) uploader: MdbFileUploadComponent;

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private formBuilder: FormBuilder,
    private toastr: ToasterService,
    private documentService: DocumentService,
    public utilityAccess: AccessType,
    private _storage: Storage,
    private _scrollTo: ScrollTo
  ) {
    this.setDocumentForm();
    this.setDocumentNameForm();
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
      Object.assign(this.documentQuery, { page: 1, keyword });
      this.getDocuments();
    });
  }

  ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Documents', path: '/documents' }));
    this.getDocuments();
    this.user = this._storage.get('local', 'loggedInUser', 'user');
    if (this.user.roleCode == 'MP') this.usermode = true;
  }

  getDocuments() {
    this.loading = true;
    this.result.searchedDocuments = true;
    this.result.documents = true;
    if (!this.documentQuery.keyword) delete this.documentQuery.keyword
    this.documentService
      .getDocument(this.documentQuery)
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe((res: any) => {
        if (res && res.count > 0) {
          this.totalCount = res.count;
          this.documentList = res.rows;
        } else {
          this.documentList = [];
          this.documentQuery.keyword ? (this.result.searchedDocuments = false) : (this.result.documents = false);
        }
      }, (error) => {
        this.toastr.showError(error);
        console.log('Error getting documents', error);
      });
  }

  setDocumentForm() {
    this.documentForm = this.formBuilder.group({
      // fileName: [''],
      documentPath: [null, Validators.required],
      holistaMedia: [null, Validators.required],
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      id: [null]
    });
  }

  setDocumentNameForm() {
    this.documentNameForm = this.formBuilder.group({
      name: ['', [Validators.required]]
    });
  }

  uploadDocument() {
    if (this.utilityAccess.searchAccess('DM', 'isEditable'))
      this.uploadDocumentModal.show();
    else this.toastr.displayWarning(ACCESS_DENIED);
  }

  downloadDocument(document) {
    saveAs(document.documentPath, document.name);
  }

  closeModal() {
    this.setDocumentForm();
    this.documentForm.reset();
    this.uploadDocumentModal.hide();
    this.submitted = false;
    this.uploader.reset();
  }

  onFileAdd(file: File) {
    this.documentForm.controls.holistaMedia.setValue(file);
    // this.documentForm.controls.fileName.setValue(file.name);
  }

  onFileRemove() {
    this.documentForm.controls.holistaMedia.setValue(null);
    // this.documentForm.controls.fileName.setValue('');
  }

  submitDocument() {
    this.submitted = true;
    if (this.documentForm.value.holistaMedia) {
      // this.documentForm.controls['fileName'].setValidators([Validators.required]);
      // this.documentForm.controls['fileName'].updateValueAndValidity();
      this.documentForm.controls['documentPath'].clearValidators();
      this.documentForm.controls['documentPath'].updateValueAndValidity();
    }
    if (this.documentForm.value.documentPath) {
      this.documentForm.controls['holistaMedia'].clearValidators();
      this.documentForm.controls['holistaMedia'].updateValueAndValidity();
    }
    if (this.documentForm.valid) {
      this.saveDocument();
    }
  }

  saveDocument() {
    this.submit_enabled = true;
    this.documentService
      .saveDocument(this.documentForm.value)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshDocuments(res);
        },
        error => {
          console.log(error);
        }
      );
  }

  refreshDocuments(response) {
    if (response.message) {
      this.toastr.displayWarning(response.message);
    } else {
      this.reverse = true;
      if (this.documentQuery.page == 1) {
        this.documentList.unshift(response);
        this.result.documents = true;
      }
      else {
        Object.assign(this.documentQuery, {
          page: 1,
          limit: 10,
          sortType: 'desc',
          sortBy: 'id',
          keyword: this.documentQuery.keyword
        })
        this.getDocuments()
      }
      this.toastr.displaySuccess(UPLOAD_SUCCESS);
    }
    this.closeModal();
  }

  editDisplayName(document) {
    if (this.utilityAccess.searchAccess('DM', 'isEditable')) {
      this.selected_document = document.id;
      this.documentNameForm.controls.name.setValue(document.name);
    } else this.toastr.displayWarning(ACCESS_DENIED);
  }

  updateName(id: number) {
    this.nameSubmitted = true;
    if (this.documentNameForm.valid) {
      this.documentService
        .updateDocumentName(id, this.documentNameForm.value.name)
        .subscribe(
          res => {
            let index = this.documentList.findIndex(x => x.id == res.id);
            if (index > -1) this.documentList[index] = res;
            this.toastr.displaySuccess(UPDATE_SUCCESS);
            this.closeEditable();
            this.nameSubmitted = false;
            setTimeout(() => {
              this.editedRow = false;
              this.selected_row = null;
            }, 2000);
          },
          error => {
            console.log("Error updating document name", error);
            this.toastr.showError(error);
          }
        );
    }
  }

  closeEditable() {
    this.editedRow = true;
    this.selected_row = this.selected_document;
    this.selected_document = null;
    this.setDocumentNameForm();
  }

  cancel() {
    this.selected_document = null;
    this.nameSubmitted = false;
    this.setDocumentNameForm();
  }

  scrollToTop() {
    this._scrollTo.ScrollTo('scrollToTop', 'center', 'smooth')
  }
  pageChanged(event) {
    this.documentQuery.page = event;
    this.getDocuments();
  }

  setOrder(value: string) {
    if (this.documentQuery.sortBy === value) {
      this.reverse = !this.reverse;
      this.documentQuery.sortType = this.documentQuery.sortType === "desc" ? "asc" : "desc";
    }
    else {
      this.reverse = true;
      this.documentQuery.sortType = "desc";
    }
    this.documentQuery.sortBy = value;
    this.getDocuments();
  }

  change(text) {
    this.searchModelChanged.next(text);
  }

  removeSearchKeyword() {
    this.documentQuery.keyword = ''
    this.change('')
  }
}
