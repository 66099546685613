<div class="d-flex justify-content-between align-items-center mb-2">
  <h5>Claim Reconciliation Report</h5>
  <div>
    <button
      type="button"
      class="btn btn-sm btn-light border mr-0"
      (click)="onSendEmailOrDownloadClicked('email')"
      [disabled]="downloadReportLoading || !(doesDataExist$ | async)">
      <i class="fa-fa fa-envelope fas"></i> Send Email
    </button>
    <button
      type="button"
      class="btn btn-sm btn-light border mr-0"
      (click)="onSendEmailOrDownloadClicked('download')"
      [disabled]="downloadReportLoading || !(doesDataExist$ | async)">
      <span
        *ngIf="downloadReportLoading"
        class="spinner-grow spinner-grow-sm"
        role="status"
        aria-hidden="true"></span>
      <i *ngIf="!downloadReportLoading" class="fas fa-download"></i> Download
    </button>
  </div>
</div>

<mdb-tabset
  #tabs
  [buttonClass]="'classic-tabs bg-light border'"
  [contentClass]="'card m-0 shadow-none p-0'"
  class="classic-tabs">
  <mdb-tab [heading]="claimsReconTabs.SUMMARY" (select)="onTabSelected($event)">
    <ng-container
      *ngIf="
        selectedTab === claimsReconTabs.SUMMARY && summaryData$ | async as sumarryData;
        else displayLoader
      ">
      <div class="mt-3">
        <div class="row">
          <div class="col-md-6 col-sm-12 mb-3">
            <div class="grid-item w-100">
              <mdb-card>
                <mdb-card-header class="text-center font-weight-bold">Total Claims</mdb-card-header>
                <mdb-card-body>
                  <app-total-claims [totalClaims]="sumarryData.totalClaims"></app-total-claims>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 mb-2 mb-3">
            <div class="grid-item w-100" style="min-height: 350px">
              <mdb-card>
                <mdb-card-header class="text-center font-weight-bold"
                  >High Occurrence Providers - Top 10</mdb-card-header
                >
                <mdb-card-body>
                  <app-high-occurrence-providers
                    [highOccuranceProviders]="
                      sumarryData.highOccuranceProviders
                    "></app-high-occurrence-providers>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12 mb-3">
            <div class="grid-item w-100">
              <mdb-card>
                <mdb-card-header class="text-center font-weight-bold">
                  Claims Discrepencies - volume by episode type
                </mdb-card-header>
                <mdb-card-body>
                  <app-volume-by-episode-type
                    [volumeEpisodeTypes]="
                      sumarryData.discrepenciesVolumeByEpisodeType
                    "></app-volume-by-episode-type>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 mb-3">
            <div class="grid-item w-100">
              <mdb-card>
                <mdb-card-header class="text-center font-weight-bold">
                  Claims Discrepencies - financial by episode type
                </mdb-card-header>
                <mdb-card-body>
                  <app-financial-by-episode-type
                    [financialEpisodeTypes]="
                      sumarryData.discrepenciesFinancialByEpisodeType
                    "></app-financial-by-episode-type>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mdb-tab>
  <mdb-tab
    [disabled]="!(hasSummaryData$ | async)"
    [heading]="claimsReconTabs.BY_PROVIDER_AND_EPISODE"
    (select)="onTabSelected($event)">
    <div
      *ngIf="
        selectedTab === claimsReconTabs.BY_PROVIDER_AND_EPISODE && tableData$
          | async as providerAndEpisodeData;
        else displayLoader
      ">
      <div style="max-height: 800px">
        <div>
          <app-table
            [report]="providerAndEpisodeData.referenceData.items"
            [exportTable]="false"
            [module]="'report'"
            tableHeight="800px">
            <ng-template #additionalHeader>
              <tr class="position-sticky text-left bg-white">
                <th class="text-right" colspan="4">Total</th>
                <th
                  [class.text-right]="
                    !providerAndEpisodeData?.referenceData?.total
                      ?.totalClaimSubmittedByBillingProviderOrEpisode
                  ">
                  {{
                    providerAndEpisodeData?.referenceData?.total
                      ?.totalClaimSubmittedByBillingProviderOrEpisode
                      ? providerAndEpisodeData?.referenceData?.total
                          ?.totalClaimSubmittedByBillingProviderOrEpisode
                      : '-'
                  }}
                </th>
                <th
                  [class.text-right]="
                    !providerAndEpisodeData?.referenceData?.total?.totalMisdirectedClaim
                  ">
                  {{
                    providerAndEpisodeData?.referenceData?.total?.totalMisdirectedClaim
                      ? providerAndEpisodeData?.referenceData?.total?.totalMisdirectedClaim
                      : '-'
                  }}
                </th>
                <th
                  [class.text-right]="
                    !providerAndEpisodeData?.referenceData?.total?.totalDuplicateClaim
                  ">
                  {{
                    providerAndEpisodeData?.referenceData?.total?.totalDuplicateClaim
                      ? providerAndEpisodeData?.referenceData?.total?.totalDuplicateClaim
                      : '-'
                  }}
                </th>
              </tr>
            </ng-template>
          </app-table>
        </div>
      </div>
    </div>
  </mdb-tab>
  <mdb-tab
    [disabled]="!(hasSummaryData$ | async)"
    [heading]="claimsReconTabs.RAW_DATA"
    (select)="onTabSelected($event)">
    <div
      *ngIf="
        selectedTab === claimsReconTabs.RAW_DATA && rawData$ | async as rawData;
        else displayLoader
      ">
      <app-table
        [report]="rawData"
        [exportTable]="false"
        [module]="'report'"
        tableHeight="800px"></app-table>
    </div>
  </mdb-tab>
</mdb-tabset>

<ng-template #displayLoader>
  <div class="loader mt-5">
    <app-loader [loader]="true"></app-loader>
  </div>
</ng-template>

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [moduleOptions]="moduleOptions"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->
