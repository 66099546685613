import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { RoutePublic } from "./routes/route.public";
import { RouteSecure } from "./routes/route.secure";
import { AuthGuard } from './guards';
import { loginGuard } from './guards/loginRedirect.guard';
import { LayoutMainComponent } from './components';

const routes: Routes = [
  { path: "", component: LayoutMainComponent, children: RouteSecure, canActivate: [AuthGuard] },
  { path: "", children: RoutePublic },
  { path: "**", redirectTo: "/login" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
