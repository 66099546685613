<!-- Expandable Chart Begins -->
<div
  [attr.id]="expand.payorCycleTimesCompany ? 'payor-cycle-time-detail' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY) &&
    expand.payorCycleTimesCompany
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="payorCycleTimesCompanyForm">
            <div class="d-flex justify-content-between align-items-center">
              <label class="m-0">Period</label>
              <mdb-select
                style="width: 80px"
                class="ml-3"
                placeholder="Year"
                [options]="yearOptions.payorCycleTimesCompany | sort : 'label'"
                id="year-select"
                [visibleOptions]="3"
                formControlName="selectedYear"
                (selected)="yearSelected($event, REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY)">
              </mdb-select>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.payorCycleTimesCompany && payorCycleTimesByCompanyChart.labels.length"
        [datasets]="payorCycleTimesByCompanyChart.dataset"
        [labels]="payorCycleTimesByCompanyChart.labels"
        [options]="payorCycleTimesByCompanyChart.options"
        [plugins]="payorCycleTimesByCompanyChart.plugins"
        [chartType]="payorCycleTimesByCompanyChart.chartType">
      </canvas>
      <p *ngIf="!loading.payorCycleTimesCompany && !payorCycleTimesByCompanyChart.labels.length">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.payorCycleTimesCompany">
        <app-loader [loader]="loading.payorCycleTimesCompany"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  [attr.id]="expand.payorCycleTimesPayor ? 'payor-cycle-time-detail' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR) &&
    expand.payorCycleTimesPayor
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="payorCycleTimesPayorForm">
            <div class="d-flex justify-content-between align-items-center">
              <div class="w-100">
                <div style="display: inline-block; width: 250px">
                  <app-select
                    [options]="payorIdentifierList"
                    [id]="'payor'"
                    [placeholder]="'Select payor'"
                    [filterEnabled]="false"
                    mdbInput
                    [control]="payorCycleTimesPayorForm.controls['payorIdentifier']"
                    (onSelected)="payorIdentifierSelected($event)">
                  </app-select>
                </div>
                <mdb-select
                  style="width: 80px"
                  class="ml-3"
                  placeholder="Year"
                  [options]="yearOptions.payorCycleTimesPayor | sort : 'label'"
                  id="year-select"
                  [visibleOptions]="3"
                  formControlName="selectedYear"
                  (selected)="yearSelected($event, REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR)">
                </mdb-select>
              </div>
            </div>
          </form>
          <span
            class="link pointer-cursor donotprint ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.payorCycleTimesPayor && payorCycleTimesByPayorChart.labels.length"
        [datasets]="payorCycleTimesByPayorChart.dataset"
        [labels]="payorCycleTimesByPayorChart.labels"
        [options]="payorCycleTimesByPayorChart.options"
        [plugins]="payorCycleTimesByPayorChart.plugins"
        [chartType]="payorCycleTimesByPayorChart.chartType">
      </canvas>
      <div
        class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
        *ngIf="
          !loading.payorCycleTimesPayor &&
          !payorCycleTimesByPayorChart.labels.length &&
          !payorCycleTimesPayorForm.get('payorIdentifier').value
        ">
        <div class="text-center">
          <i class="icofont-building icofont-4x text-secondary"></i>
          <p class="mt-2">Please select payor</p>
        </div>
      </div>
      <p
        *ngIf="
          !loading.payorCycleTimesPayor &&
          !payorCycleTimesByPayorChart.labels.length &&
          payorCycleTimesPayorForm.get('payorIdentifier').value
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.payorCycleTimesPayor">
        <app-loader [loader]="loading.payorCycleTimesPayor"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  [attr.id]="expand.payorCycleTimesPayorChart ? 'payor-cycle-time-detail' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR_CHART) &&
    expand.payorCycleTimesPayorChart
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR_CHART) as report">
          {{ report.reportName }}
        </p>
        <span
          class="link pointer-cursor donotprint"
          (click)="onViewToggle(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR_CHART, false)">
          <i class="fas fa-compress-alt"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.payorCycleTimesPayorChart && payorCycleTimesPayorChart.labels.length"
        [datasets]="payorCycleTimesPayorChart.dataset"
        [labels]="payorCycleTimesPayorChart.labels"
        [options]="payorCycleTimesPayorChart.options"
        [plugins]="payorCycleTimesPayorChart.plugins"
        [chartType]="payorCycleTimesPayorChart.chartType">
      </canvas>
      <p *ngIf="!loading.payorCycleTimesPayorChart && !payorCycleTimesPayorChart.labels.length">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.payorCycleTimesPayorChart">
        <app-loader [loader]="loading.payorCycleTimesPayorChart"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<!-- Expandable Chart Ends -->
<div
  [className]="
    !expand.payorCycleTimesCompany &&
    !expand.payorCycleTimesPayor &&
    !expand.payorCycleTimesPayorChart
      ? 'mt-3'
      : 'd-none'
  ">
  <div
    class="mt-3"
    *ngIf="
      parsedClientClaimsFilterData?.isConfigLoading &&
      !parsedClientClaimsFilterData?.toShowReport.length
    ">
    <app-loader [loader]="true"></app-loader>
  </div>
  <div
    id="payor-cycle-time-detail"
    class="m-0 p-0"
    *ngIf="
      !parsedClientClaimsFilterData?.isConfigLoading &&
      parsedClientClaimsFilterData?.toShowReport &&
      parsedClientClaimsFilterData?.toShowReport.length
    ">
    <div class="px-3">
      <!-- Payor Cucle Times - Company and Payor Begins -->
      <div
        class="row"
        *ngIf="
          toShowReport &&
          (toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY) ||
            toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR))
        ">
        <div
          class="col-md-6 mb-4"
          *ngIf="
            toShowReport && toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="payorCycleTimesCompanyForm">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="m-0">Period</label>
                      <mdb-select
                        style="width: 80px"
                        class="ml-3"
                        placeholder="Year"
                        [options]="yearOptions.payorCycleTimesCompany | sort : 'label'"
                        id="year-select"
                        [visibleOptions]="3"
                        formControlName="selectedYear"
                        (selected)="
                          yearSelected($event, REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY)
                        ">
                      </mdb-select>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor ml-3"
                    (click)="onViewToggle(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY, true)">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="
                  !loading.payorCycleTimesCompany && payorCycleTimesByCompanyChart.labels.length
                "
                [datasets]="payorCycleTimesByCompanyChart.dataset"
                [labels]="payorCycleTimesByCompanyChart.labels"
                [options]="payorCycleTimesByCompanyChart.options"
                [plugins]="payorCycleTimesByCompanyChart.plugins"
                [chartType]="payorCycleTimesByCompanyChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.payorCycleTimesCompany && !payorCycleTimesByCompanyChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.payorCycleTimesCompany">
                <app-loader [loader]="loading.payorCycleTimesCompany"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div
          class="col-md-6 mb-4"
          *ngIf="toShowReport && toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR)">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="payorCycleTimesPayorForm" style="min-width: 300px">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="w-100 d-flex justify-content-between align-items-center">
                        <div style="display: inline-block; width: 220px">
                          <app-select
                            [options]="payorIdentifierList"
                            [id]="'payor'"
                            [placeholder]="'Select payor'"
                            [filterEnabled]="false"
                            mdbInput
                            [control]="payorCycleTimesPayorForm.controls['payorIdentifier']"
                            (onSelected)="payorIdentifierSelected($event)">
                          </app-select>
                        </div>
                        <mdb-select
                          style="width: 80px"
                          class="ml-3"
                          placeholder="Year"
                          [options]="yearOptions.payorCycleTimesPayor | sort : 'label'"
                          id="year-select"
                          [visibleOptions]="3"
                          formControlName="selectedYear"
                          (selected)="
                            yearSelected($event, REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR)
                          ">
                        </mdb-select>
                      </div>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor donotprint ml-3"
                    (click)="onViewToggle(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR, true)">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="!loading.payorCycleTimesPayor && payorCycleTimesByPayorChart.labels.length"
                [datasets]="payorCycleTimesByPayorChart.dataset"
                [labels]="payorCycleTimesByPayorChart.labels"
                [options]="payorCycleTimesByPayorChart.options"
                [plugins]="payorCycleTimesByPayorChart.plugins"
                [chartType]="payorCycleTimesByPayorChart.chartType">
              </canvas>
              <div
                class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
                *ngIf="
                  !loading.payorCycleTimesPayor &&
                  !payorCycleTimesByPayorChart.labels.length &&
                  !payorCycleTimesPayorForm.get('payorIdentifier').value
                ">
                <div class="text-center">
                  <i class="icofont-building icofont-4x text-secondary"></i>
                  <p class="mt-2">Please select payor</p>
                </div>
              </div>
              <p
                *ngIf="
                  !loading.payorCycleTimesPayor &&
                  !payorCycleTimesByPayorChart.labels.length &&
                  payorCycleTimesPayorForm.get('payorIdentifier').value
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.payorCycleTimesPayor">
                <app-loader [loader]="loading.payorCycleTimesPayor"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
      <!-- Episode Claims (with) Table and Cycle Times Ends -->

      <!-- Payor cycle Times - (Payor Chart) Begins -->
      <div
        class="mb-4"
        *ngIf="
          toShowReport && toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR_CHART)
        ">
        <mdb-card>
          <mdb-card-header>
            <div class="d-flex justify-content-between align-items-center">
              <p
                class="m-0 font-weight-normal"
                *ngIf="getReport(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR_CHART) as report">
                {{ report.reportName }}
              </p>
              <span
                class="link pointer-cursor donotprint"
                (click)="onViewToggle(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR_CHART, true)">
                <i class="fas fa-expand-alt"></i>
              </span>
            </div>
          </mdb-card-header>
          <mdb-card-body style="height: 320px">
            <canvas
              baseChart
              *ngIf="!loading.payorCycleTimesPayorChart && payorCycleTimesPayorChart.labels.length"
              [datasets]="payorCycleTimesPayorChart.dataset"
              [labels]="payorCycleTimesPayorChart.labels"
              [options]="payorCycleTimesPayorChart.options"
              [plugins]="payorCycleTimesPayorChart.plugins"
              [chartType]="payorCycleTimesPayorChart.chartType">
            </canvas>
            <p
              *ngIf="
                !loading.payorCycleTimesPayorChart && !payorCycleTimesPayorChart.labels.length
              ">
              {{ noDataMessage.value }}
            </p>
            <div
              class="d-flex justify-content-center align-items-center h-75"
              *ngIf="loading.payorCycleTimesPayorChart">
              <app-loader [loader]="loading.payorCycleTimesPayorChart"></app-loader>
            </div>
          </mdb-card-body>
        </mdb-card>
      </div>
      <!-- Payor cycle Times - (Payor Chart) Ends -->
    </div>
  </div>
</div>
