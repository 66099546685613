<div class="container-fluid p-0 p-sm-2">
  <div class="row ml-0">
    <div
      class="col"
      *ngIf="!usermode || (usermode && !(isMobile && selected_task && selected_task.taskField))">
      <div
        class="mt-2 sticky-content scrollbar scrollbar-primary thin pr-sm-3 p-0 h-md-auto"
        [ngStyle]="{height: 'calc(100vh - ' + '(' + episodeDescHeight + 'px + 98px)' + ')'}">
        <div class="position-sticky header pb-0">
          <div class="h-auto">
            <div class="d-flex justify-content-between h-auto">
              <div>
                <h5 class="mb-0">
                  {{ milestone.name }}
                  <div
                    class="chip chip-sm mb-0 ml-1"
                    *ngIf="
                      !milestone.startDate &&
                      milestone.triggerPeriod &&
                      milestone.triggerCondition &&
                      milestone.triggerMilestoneName
                    ">
                    <mdb-icon far icon="calendar-check"></mdb-icon> {{ milestone.triggerDays }}
                    {{ milestone.triggerDays ? milestone.triggerPeriod : '' }}
                    {{ milestone.triggerDays ? milestone.triggerCondition : 'After completion of' }}
                    <span class="font-weight-bold">{{ milestone.triggerMilestoneName }}</span>
                  </div>
                </h5>
                <p>{{ milestone.description }}</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <h6>Topic</h6>
            <a
              *ngIf="
                !(
                  usermode ||
                  milestone.status == 'COMPLETED' ||
                  milestone.epiosdeStatus == 'CLOSED' ||
                  milestone.episodeStatus == 'CANCELLED' ||
                  milestone.episodeStatus == 'COMPLETED' ||
                  milestone.status == 'IN PROGRESS' ||
                  milestone.triggerDatePassed
                )
              "
              (click)="createTopic()"
              class="skin-link"
              [attr.data-test-id]="'add-topic'"
              ><i class="icofont-plus"></i> Add Topic</a
            >
          </div>
        </div>
        <div
          class="p-4 text-center grey lighten-4 border-light"
          *ngIf="!isLoading.topics && topics.length == 0">
          <i class="icofont-tasks icofont-4x text-black-50"></i>
          <p class="text-muted">
            {{
              usermode
                ? milestone &&
                  milestone.episodeStatus !== 'PRELIMINARY' &&
                  (milestone.isTriggerOnStart ||
                    ((milestone.startDate || milestone.relativeStartDate) && days_difference >= 0))
                  ? 'No topics available in your milestone. Please contact your Care Coordinator.'
                  : 'Please wait for your milestone to start.'
                : 'Start adding topics to your milestone'
            }}
          </p>
        </div>
        <app-loader [loader]="isLoading.topics"> </app-loader>
        <!--Accordion wrapper-->
        <mdb-accordion
          class="accordion-block"
          [multiple]="false"
          aria-multiselectable="true"
          *ngIf="!isLoading.topics && topics.length > 0">
          <mdb-accordion-item
            *ngFor="let topic of topics | orderBy : 'id'; let i = index"
            id="topic{{ topic.id }}"
            [collapsed]="active_topicId == topic.id ? false : true"
            (click)="passDataToTask(topic.id)">
            <mdb-accordion-item-head
              class="position-sticky"
              [ngClass]="
                topic.status ? topic.status.replace(' ', '').toLowerCase() : 'preliminary'
              ">
              {{ usermode ? topic.patientTopic : topic.topic }}
              <span class="text-dark action-item">
                <div
                  *ngIf="topic.status"
                  class="chip chip-sm mb-0 ml-1 alert alert-info"
                  [ngClass]="topic.status ? topic.status.replace(' ', '').toLowerCase() : ''">
                  <span class="text-sm status-label">
                    {{ topic.status ? topic.status?.toLowerCase() : '' }}
                  </span>
                </div>
                <span
                  *ngIf="
                    !(
                      milestone.status == 'IN PROGRESS' ||
                      milestone.triggerDatePassed ||
                      topic.episodeStatus == 'CLOSED' ||
                      topic.episodeStatus == 'CANCELLED' ||
                      topic.episodeStatus == 'COMPLETED'
                    )
                  ">
                  <i
                    class="icofont-ui-edit icofont-sm mr-2"
                    [attr.data-test-id]="'topic-edit'"
                    (click)="editTopic(topic)"
                    *ngIf="!topic.status && !usermode"></i>
                  <i
                    class="icofont-ui-delete icofont-sm"
                    [attr.data-test-id]="'topic-delete'"
                    (click)="topicToDelete(topic)"
                    *ngIf="!topic.status && !usermode"></i>
                </span>
              </span>
            </mdb-accordion-item-head>

            <mdb-accordion-item-body [customClass]="'p-0'">
              <app-episode-task
                *ngIf="!usermode && active_topicId == topic.id"
                [user]="user"
                [topic]="topic"
                [days_difference]="days_difference"
                [assignedBy]="assignedBy"
                [assignedTo]="assignedTo"
                [dependent_tasks]="dependent_tasks"
                (updateTopic)="updateTopicHandler($event)"
                (deleteDependent)="deleteDependent($event)"
                (toBeDeleteTask)="deleteTask($event)"
                [userDetails]="userDetails">
              </app-episode-task>
              <app-episode-user-task
                *ngIf="usermode && active_topicId == topic.id"
                [user]="user"
                [topic]="topic"
                [assignedBy]="assignedBy"
                [assignedTo]="assignedTo"
                [dependent_tasks]="dependent_tasks"
                [updated_task]="updated_task"
                (updateSelectedTask)="updateSelectedTaskHandler($event)"
                [dependentTopicTasks]="dependentTopicTasks"
                (updateQuestionnaireAnswer)="editQuestionnaireAnswer($event)"
                (updateQuestionAnswer)="editQuestionAnswer($event)">
              </app-episode-user-task>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
        </mdb-accordion>
        <!--/.Accordion wrapper-->
      </div>
      <button
        class="btn btn-light btn-lg btn-block waves-light border position-sticky mt-3 mx-auto"
        mdbWavesEffect
        *ngIf="usermode && isMobile"
        (click)="goBack('Milestone')">
        Back to Milestone
      </button>
    </div>

    <div
      class="col-12 col-sm-5 col-xl-4"
      *ngIf="usermode && selected_task && selected_task.taskField">
      <div
        class="mt-2 sticky-content scrollbar scrollbar-primary thin pr-0 pr-sm-3"
        [ngStyle]="{height: 'calc(100vh - ' + '(' + episodeDescHeight + 'px + 98px)' + ')'}">
        <div class="header" *ngIf="selected_task">
          <div class="h-auto">
            <h4 class="mb-2">
              {{
                selected_task.taskField == 'todo' || selected_task.taskField === 'signature'
                  ? selected_task.task.name
                  : selected_task.taskField == 'message'
                  ? selected_task.task.title
                  : ''
              }}
            </h4>
            <p>
              {{
                selected_task.taskField == 'todo' || selected_task.taskField === 'signature'
                  ? selected_task.task.instruction
                  : selected_task.taskField == 'message'
                  ? selected_task.task.messages
                  : ''
              }}
            </p>
          </div>
        </div>
        <div
          *ngIf="
            selected_task.taskField == 'todo' ||
            selected_task.taskField == 'signature' ||
            selected_task.taskField == 'message'
          ">
          <a
            *ngIf="
              !selected_task.task.docuSignTemplateId &&
              (selected_task.task.documentPath || selected_task.task.taskTodoLink)
            "
            href="javascript:void(0)"
            class="link"
            (click)="visitLink(selected_task.task)">
            View Information</a
          >
          <button
            class="btn btn-secondary waves-light m-0"
            *ngIf="
              selected_task.taskField === 'signature' &&
              (!selected_task.task.status ||
                DOCUSIGN_ELIGIBLE_STATUS.includes(selected_task.task.status))
            "
            mdbWavesEffect
            (click)="redirectToDocuSignTemplate(selected_task)"
            [disabled]="isLoading.docuSign">
            <span
              *ngIf="isLoading.docuSign"
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"></span>
            SIGN DOCUMENT
          </button>
        </div>
        <div
          *ngIf="
            selected_task.task &&
            selected_task.task.type === 'signature' &&
            selected_task.task.status === 'IN-PROGRESS'
          "
          class="my-4 py-4">
          <div class="d-flex justify-content-center position-relative">
            <mdb-spinner
              spinnerType="big"
              spinnerColor="green"
              class="position-absolute"></mdb-spinner>
            <i
              class="fas fa-file-signature fa-3x text-primary mt-3 mb-4 ml-2 pb-2"
              style="font-size: 30px"></i>
          </div>
          <div class="d-flex flex-column align-items-center">
            <h6><b>Document Signature is In-Progress</b></h6>
            <p class="text-center mt-2">
              You can continue working on other tasks while we process your document.
            </p>
          </div>
        </div>
        <div
          *ngIf="
            selected_task.task &&
            selected_task.task.type === 'signature' &&
            selected_task.task.status === 'COMPLETED'
          "
          class="my-4 py-4 d-flex flex-column align-items-center">
          <i class="fas fa-handshake fa-3x text-primary my-4 pb-2"></i>
          <h6><b>Document is Signed</b></h6>
          <p class="text-center mt-2">Thank you for signing document.</p>
          <a (click)="handleRedirect(selected_task.task.taskTodoLink)">{{
            selected_task.task.documentDisplayName
          }}</a>
        </div>
        <div
          *ngIf="
            selected_task.taskField == 'todo' &&
            selected_task?.task?.isAcknowledgedRequired &&
            (!selected_task?.task?.status || selected_task?.task?.status == 'DUE') &&
            !(
              milestone?.episodeStatus == 'CLOSED' ||
              milestone?.episodeStatus == 'CANCELLED' ||
              selected_task?.task?.topicStatus == 'COMPLETED'
            )
          ">
          <button
            type="submit"
            class="btn mt-3 ml-0 btn-secondary border"
            (click)="updateUserTaskStatus()">
            <span
              class="spinner-grow spinner-grow-sm"
              aria-hidden="true"
              *ngIf="submit_enabled"></span>
            <i class="icofont-tick-mark" *ngIf="!submit_enabled"></i> Acknowledge
          </button>
        </div>
        <!-- questionnaire modal section starts from here -->
        <div
          *ngIf="
            selected_task.taskField == 'questionnaire' || selected_task.taskField == 'question'
          ">
          <h4>
            {{ selected_task.taskField == 'questionnaire' ? 'Questionnaire' : 'Question' }} Set
          </h4>
          <p *ngIf="selected_task.taskField == 'questionnaire'">
            This questionnaire set consists of the list of questions and may have dependent tasks
            also. Patients are required to complete all the tasks. If you exit the questionnaire
            without completing, your answers will be lost.
          </p>
          <p *ngIf="selected_task.taskField !== 'questionnaire'">
            This question may have dependent tasks also. Patients are required to complete all the
            tasks. If you exit the question without completing, your answers will be lost.
          </p>
          <button
            type="button"
            class="btn btn-secondary relative waves-light"
            (click)="showAnswerModal()"
            mdbWavesEffect>
            {{
              (selected_task.task ? selected_task.task.status : selected_task.status) ===
              'COMPLETED'
                ? 'Edit'
                : 'Start'
            }}
            {{
              (selected_task.taskField ? selected_task.taskField : selected_task.field) ==
              'questionnaire'
                ? 'Questionnaire'
                : 'Question'
            }}
          </button>
        </div>

        <!-- questionnaire modal section ends here -->
      </div>
      <button
        class="btn btn-light btn-lg btn-block waves-light border position-sticky mt-3 mx-auto"
        mdbWavesEffect
        *ngIf="usermode && isMobile"
        (click)="goBack('Topic')">
        Back to Topic
      </button>
    </div>
  </div>

  <!-- questionnaire modal -->
  <div
    mdbModal
    #answerModal="mdbModal"
    class="modal fade"
    tabindex="-1"
    [config]="{backdrop: true, ignoreBackdropClick: true}"
    role="dialog"
    aria-labelledby="myBasicModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="closeAnswerModal()">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel">
            {{
              (selected_task.taskField ? selected_task.taskField : selected_task.field) | titlecase
            }}
            Answers
          </h4>
        </div>
        <div class="modal-body">
          <mdb-progress
            [value]="completePercentage"
            min="0"
            max="100"
            type="success"
            class="questionnaire-progress"
            aria-valuenow="completePercentage"
            aria-valuemin="0"
            aria-valuemax="100"
            >{{ completePercentage }} %
          </mdb-progress>
          <div
            class="questionnaire-container"
            *ngIf="taskQuestionList && taskQuestionList.length > 0">
            <div *ngFor="let task of taskQuestionList; let i = index">
              <div *ngIf="activeIndex === i">
                <div *ngIf="task.field === 'questionnaire'" class="w-100">
                  <label class="h6 font-weight-medium text-dark"
                    >{{ i + 1 }}. {{ task.question.question }}</label
                  >
                  <div
                    *ngFor="
                      let option of task.question.questionOptions | orderBy : 'id';
                      let index = index
                    "
                    class="form-check pl-0">
                    <div *ngIf="task.question.questionTypes.code === 'RADIO'">
                      <input
                        type="radio"
                        (change)="answerOption($event, option, task, i)"
                        class="form-check-input"
                        [checked]="
                          task.question.ansOpt &&
                          task.question.ansOpt.length > 0 &&
                          (task.question.ansOpt[0] === option.id ||
                            (task.question.ansOpt[0].answerOptionId &&
                              task.question.ansOpt[0].answerOptionId === option.id))
                        "
                        id="qnrsRadioOption{{ option.uuid }}"
                        name="qnrsRadioOption{{ task.question.uuid }}"
                        mdbInput />
                      <label class="form-check-label" for="qnrsRadioOption{{ option.uuid }}">{{
                        option.optionValue
                      }}</label>
                    </div>

                    <div *ngIf="task.question.questionTypes.code === 'CHECKBOX'">
                      <mdb-checkbox
                        id="qnrsCheckboxOption{{ option.uuid }}"
                        name="qnrsCheck{{ task.question.uuid }}"
                        [checked]="option.selected"
                        (change)="answerOption($event, option, task, i)">
                        {{ option.optionValue }}</mdb-checkbox
                      >
                    </div>
                  </div>

                  <div
                    *ngIf="
                      task.question.questionTypes &&
                      task.question.questionTypes.code &&
                      task.question.questionTypes.code === 'TEXTFIELD'
                    ">
                    <textarea
                      type="text"
                      class="md-textarea form-control"
                      name=" textField"
                      [(ngModel)]="task.question.answer"
                      (input)="textAnswer(task, i)"></textarea>
                  </div>
                </div>

                <div *ngIf="task.field === 'question'" class="w-100">
                  <label class="h6 font-weight-medium text-dark"
                    >{{ i + 1 }}. {{ task.questions.question }}</label
                  >

                  <div
                    *ngFor="
                      let option of task.questions.questionOptions | orderBy : 'id';
                      let index = index
                    "
                    class="form-check pl-0">
                    <div *ngIf="task.questions.questionTypes.code === 'RADIO'">
                      <input
                        type="radio"
                        (change)="answerOption($event, option, task, i)"
                        class="form-check-input"
                        [checked]="
                          task.questions.ansOpt &&
                          task.questions.ansOpt.length > 0 &&
                          (task.questions.ansOpt[0] === option.id ||
                            (task.questions.ansOpt[0].answerOptionId &&
                              task.questions.ansOpt[0].answerOptionId === option.id))
                        "
                        id="questionRadioOption{{ option.uuid }}"
                        name="questionRadioOption{{ task.questions.uuid }}"
                        mdbInput />
                      <label class="form-check-label" for="questionRadioOption{{ option.uuid }}">{{
                        option.optionValue
                      }}</label>
                    </div>

                    <div *ngIf="task.questions.questionTypes.code === 'CHECKBOX'">
                      <mdb-checkbox
                        id="questionCheckOption{{ option.uuid }}"
                        name="questionCheckbox{{ task.questions.uuid }}"
                        [checked]="option.selected"
                        (change)="answerOption($event, option, task, i)"
                        >{{ option.optionValue }}
                      </mdb-checkbox>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      task.questions.questionTypes &&
                      task.questions.questionTypes.code &&
                      task.questions.questionTypes.code === 'TEXTFIELD'
                    ">
                    <textarea
                      type="text"
                      class="md-textarea form-control"
                      [(ngModel)]="task.questions.answer"
                      name=" textField"
                      (input)="textAnswer(task, i)"></textarea>
                  </div>
                </div>

                <div *ngIf="task.field === 'message'" class="w-100">
                  <label class="h6 font-weight-medium text-dark"
                    >{{ i + 1 }}. {{ task.title }}</label
                  >
                  <p class="pl-3">{{ task.messages }}</p>
                </div>

                <div *ngIf="task.field === 'todo'" class="w-100 d-flex flex-column">
                  <label class="h6 font-weight-medium text-dark"
                    >{{ i + 1 }}. {{ task.name }}</label
                  >
                  <p *ngIf="task.instruction" class="pl-3">
                    {{ task.instruction }}
                  </p>
                  <span class="pl-3">
                    <a
                      *ngIf="task.documentPath || task.taskTodoLink"
                      href="javascript:void(0)"
                      class="link"
                      (click)="visitLink(task)">
                      View Information</a
                    >
                  </span>
                  <div
                    *ngIf="
                      task?.isAcknowledgedRequired &&
                      (!task?.status || task?.status == 'DUE') &&
                      !task.isAnswered &&
                      !(
                        milestone?.episodeStatus == 'CLOSED' ||
                        milestone?.episodeStatus == 'CANCELLED'
                      )
                    "
                    class="pl-3">
                    <button type="submit" class="mt-3 ml-0 border" (click)="todoAcknowledge(i)">
                      <span
                        class="spinner-grow spinner-grow-sm"
                        aria-hidden="true"
                        *ngIf="submit_enabled"></span>
                      <i class="icofont-tick-mark" *ngIf="!submit_enabled"></i> Acknowledge
                    </button>
                  </div>
                </div>

                <div *ngIf="task.field === 'signature'" class="w-100 d-flex flex-column">
                  <label class="h6 font-weight-medium text-dark"
                    >{{ i + 1 }}. {{ task.name }}</label
                  >
                  <p *ngIf="task.instruction" class="pl-3">
                    {{ task.instruction }}
                  </p>
                  <button
                    *ngIf="!task.status || DOCUSIGN_ELIGIBLE_STATUS.includes(task.status)"
                    class="btn btn-secondary waves-light m-0"
                    mdbWavesEffect
                    (click)="redirectToDocuSignTemplate({task: task})"
                    [disabled]="isLoading.docuSign">
                    <span
                      *ngIf="isLoading.docuSign"
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"></span>
                    SIGN DOCUMENT
                  </button>
                  <div
                    *ngIf="task.status === 'IN-PROGRESS'"
                    class="my-4 py-4 d-flex flex-column align-items-center">
                    <div class="d-flex justify-content-center position-relative">
                      <mdb-spinner
                        spinnerType="big"
                        spinnerColor="green"
                        class="position-absolute"></mdb-spinner>
                      <i
                        class="fas fa-file-signature fa-3x text-primary mt-3 mb-4 ml-2 pb-2"
                        style="font-size: 30px"></i>
                    </div>
                    <h6><b>Document Signature is In-Progress</b></h6>
                    <p class="text-center mt-2">
                      You can continue working on other tasks while we process your document.
                    </p>
                  </div>
                  <div
                    *ngIf="task.status === 'COMPLETED'"
                    class="my-4 py-4 d-flex flex-column align-items-center">
                    <i class="fas fa-handshake fa-3x text-primary my-4 pb-2"></i>
                    <h6><b>Document is Signed</b></h6>
                    <p class="text-center mt-2">Thank you for signing document.</p>
                    <a (click)="handleRedirect(task.taskTodoLink)">{{
                      task.documentDisplayName
                    }}</a>
                  </div>
                  <div
                    *ngIf="
                      task?.isAcknowledgedRequired &&
                      (!task?.status || task?.status == 'DUE') &&
                      !task.isAnswered &&
                      !(
                        milestone?.episodeStatus == 'CLOSED' ||
                        milestone?.episodeStatus == 'CANCELLED'
                      )
                    "
                    class="pl-3">
                    <button type="submit" class="mt-3 ml-0 border" (click)="todoAcknowledge(i)">
                      <span
                        class="spinner-grow spinner-grow-sm"
                        aria-hidden="true"
                        *ngIf="submit_enabled"></span>
                      <i class="icofont-tick-mark" *ngIf="!submit_enabled"></i> Acknowledge
                    </button>
                  </div>
                </div>

                <div class="modal-footer pl-0 pr-0 pb-0 mt-5 d-flex justify-content-between">
                  <button
                    [disabled]="i === 0"
                    class="btn btn-light"
                    (click)="stepperPrevious(i, task)">
                    <i class="icofont-arrow-left"></i> BACK
                  </button>
                  <button
                    class="btn btn-secondary float-right"
                    *ngIf="taskQuestionList.length !== i + 1"
                    (click)="stepperNext(i, task)"
                    [disabled]="
                      !task.isAnswered &&
                      (task.field === 'question' ||
                        task.field === 'questionnaire' ||
                        task.field === 'signature' ||
                        (task.field === 'todo' &&
                          task.isAcknowledgedRequired &&
                          task.status !== 'COMPLETED'))
                    ">
                    NEXT
                  </button>
                  <button
                    *ngIf="taskQuestionList.length === i + 1"
                    class="btn btn-secondary"
                    [disabled]="
                      submit_enabled ||
                      (!task.isAnswered &&
                        (task.field === 'question' ||
                          task.field === 'questionnaire' ||
                          task.field === 'signature' ||
                          (task.field === 'todo' &&
                            task.isAcknowledgedRequired &&
                            task.status !== 'COMPLETED')))
                    "
                    (click)="submitUserQuestionAnswer()">
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="submit_enabled"></span>
                    <i class="icofont-paper-plane" *ngIf="!submit_enabled"></i>
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- questionnaire modal ends-->

  <div
    mdbModal
    #topicModal="mdbModal"
    class="modal fade top"
    id="topicModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100 mb-0">{{ topicForm.value.id ? 'Edit' : 'Add' }} Topic</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModal('topicModal', 'topicForm')">
            <i class="icofont-close-line"></i>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="topicForm">
            <div class="form-group mt-0">
              <label for="TopicName">Topic Name *</label>
              <input
                type="text"
                id="TopicName"
                [attr.data-test-id]="'topic-name'"
                class="form-control"
                mdbInput
                formControlName="topic"
                [ngClass]="{'is-invalid': submitted && topicForm.get('topic').invalid}" />
            </div>
            <div class="form-group">
              <label for="patientTopic-name">Patient facing Topic *</label>
              <input
                type="text"
                id="patientTopicName"
                [attr.data-test-id]="'patient-facing-topic-name'"
                class="form-control"
                mdbInput
                formControlName="patientTopic"
                [ngClass]="{'is-invalid': submitted && topicForm.get('patientTopic').invalid}" />
            </div>
          </form>
        </div>
        <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2">
          <button
            class="waves-light m-0 btn-light btn"
            [attr.data-test-id]="'topic-cancel-button'"
            mdbWavesEffect
            (click)="closeModal('topicModal', 'topicForm')">
            Cancel
          </button>
          <button
            class="btn btn-secondary waves-light m-0"
            [attr.data-test-id]="'topic-submit-button'"
            mdbWavesEffect
            (click)="submitTopic()"
            [disabled]="submit_enabled">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="submit_enabled"></span>
            {{ topicForm.value.id ? 'Update' : 'Save' }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-milestone-topic-delete
    [deleteData]="dataToBeDelete"
    (deletedData)="deleteTopic($event)"
    (cancelDelete)="cancelDelete($event)">
  </app-milestone-topic-delete>
</div>
