import { TotalClaimsItem } from './../../../../models/model.reports';
import { Component, Input } from '@angular/core';
import { ChartType } from 'chart.js';
import { ChartDatasets } from 'src/app/models';
import 'chartjs-plugin-datalabels';
import { CHART_OPTIONS } from '../../../../constants/claimsReconcilitationSummary.constants'
import { BehaviorSubject } from 'rxjs';
import { CLAIMS_RECONCILIATION_TOTAL_CLAIM_DATA, MessageConstants, NO_DATA_IN_REPORT } from 'src/app/constants';
import { PercentPipe } from '@angular/common';

@Component({
  selector: 'app-total-claims',
  templateUrl: './total-claims.component.html',
  styleUrls: ['./total-claims.component.scss']
})
export class TotalClaimsComponent {
  data: ChartDatasets[] = [];
  labels: string[] = [];
  tableItems$ = new BehaviorSubject(null);
  tableTotals: TotalClaimsItem;
  chartType: ChartType | string = '';
  chartOptions = JSON.parse(JSON.stringify(CHART_OPTIONS));
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT)

  @Input() set totalClaims(totalClaims) {
    if (totalClaims !== null && totalClaims.type) {
      const { chartType, labels } = totalClaims.chart;
      const { items, total } = totalClaims.referenceData;
      this.chartType = chartType;
      this.labels = labels;
      this.data = totalClaims.chart.datasets;
      this.tableItems$.next(
        {
          data: items.concat([total]).map((item) => {
            item.measure = this._percentPipe.transform((item.measure / 100), '1.2-2')
            return item;
          }),
          excelName: '',
          tableTitle: '',
          tableHeaders: CLAIMS_RECONCILIATION_TOTAL_CLAIM_DATA.TABLE_HEADERS,
          columnsWidth: [],
          dateType: '',
          date: {},
        }
      );
      this.tableTotals = total;
    } else {
      this.data = [];
    }
  }

  constructor(
    private _percentPipe: PercentPipe,
    private _messageConstants: MessageConstants
  ) { }
}
