<div class="text-center">
<div class="avatar avatar-lg mx-auto">
    <i class="icofont-ui-user"></i>
    <!-- <img [src]="call.callInitiator.avatar" height="150" width="150" /> -->
</div>

<h5>Incoming call from {{call.callInitiator.name}}</h5>
</div>

<div class="d-flex justify-content-center text-center">
    <a href="javascript:void(0)"  class="positive mx-3" (click)="accept()">
            <div class="icon-block icon-block-lg text-center bg-success rounded-circle">
                <i class="icofont-phone text-white"></i>
            </div>
            <small>Accept</small>
          </a>
    <a href="javascript:void(0)"  class="negative mx-3" (click)="reject()">
        <div class="icon-block icon-block-lg text-center bg-danger rounded-circle">
            <i class="icofont-phone text-white icofont-rotate-90"></i>
        </div>
        <small>Reject</small>
      </a>
</div>