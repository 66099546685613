import { DateUtility } from './../../../utils/date.utils';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { finalize, debounceTime, startWith, switchMap, map, filter, take } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../../action';
import { CLAIMS_PAYMENT_DESCRIPTIONS, MISMATCHED_STATUS, NEGATIVE_CLAIM_AMOUNT, PaymentMode, REFERENCE_TYPE, SUBMIT_DISABLED_REASONS, UPDATE_SUCCESS, US_STATES } from '../../../constants';
import { ClaimsService, ClientService, PaymentService, PlatformService, ToasterService } from 'src/app/services';
import { ADD_SUCCESS, ACCESS_DENIED, CLAIM_TABLE_HEADERS, MaskConstant, CLAIMS_TABLE_UNEDITABLE_FIELDS, UPDATE_FAILED, NON_PRE_POPULATED_FIELDS } from '../../../constants';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { AccessType, CurrencyFormat, HolistaUtils } from '../../../utils';
import { TableComponent } from '../../../common';
import { PayeeDetail } from '../../../models';
import { PayeeService } from 'src/app/services/payee.service';

@Component({
  selector: 'app-paid-payment',
  templateUrl: './paid-payment.component.html',
  styleUrls: ['./paid-payment.component.scss']
})
export class PaidPaymentComponent implements OnInit {
  data = {
    patientName: '',
    episode: '',
    fundingRequestName: '',
    bundleName: '',
    bundleComponent: '',
    allowedAmount: '',
  }
  paymentForm: FormGroup;
  submitted = false;
  submit_enabled: boolean = false;
  bundleComponentList: any = [];
  payeeList = [];
  payorList = [];
  tempPayor = [];
  tempPayee = [];
  allPayorList = [];
  allPayeeList = [];
  clientList: any[] = [];
  platformList: any[] = [];
  payeeSearchText = new Subject();
  billingProviderList = [];
  payee_results: Observable<any>;
  searchKeyword: string;
  claimIdentifierSearchText = new BehaviorSubject<string>('');
  payeesSearchText = new BehaviorSubject<string>('');
  claimIdentifier_results: Observable<any>;
  payeesFound$: Observable<any>;
  patientAccNumText = '';
  searchModelChanged: Subject<string> = new Subject<string>();
  loading = {
    payment: false,
    popup: false,
    claims: false,
    saving: false,
    detail: false,
    payeeDetail: false,
    savingPayeeDetail: false
  };
  totalPayment: number = 0;
  paymentModeList = [];
  claimList: any;
  dateMask: any[] = MaskConstant.DATE;
  result = { payments: true, searchedPayments: true };
  page = 1;
  limit = 10;
  totalCount = 0;
  paymentList = [];
  isEnabled = null;
  claimTableHeaders = CLAIM_TABLE_HEADERS;
  nonPrePopulatedFields = NON_PRE_POPULATED_FIELDS;
  selectedClaims = [];
  claimsTableUneditableFields = CLAIMS_TABLE_UNEDITABLE_FIELDS;
  invalidStatusReason: string;
  CLAIMS_PAYMENT_DESCRIPTIONS = CLAIMS_PAYMENT_DESCRIPTIONS;
  NEGATIVE_CLAIM_AMOUNT = NEGATIVE_CLAIM_AMOUNT;
  REFERENCE_TYPE = REFERENCE_TYPE;
  isSearchedByClaimIdentifier: boolean = false;
  totalAllowedAmount: number = 0;
  isViewPayment: boolean = false;
  isEditPayment: boolean = false;
  isAddPayment: boolean = false;
  paymentUuid: string;
  isTableFormDisabled: boolean = false;
  isSearched: boolean = false;
  isLengthGreaterThan18: boolean;
  US_STATES = US_STATES;
  payeeForm: FormGroup;
  clonedPayeeForm: PayeeDetail;
  isSubmitted = {
    editPayee: false
  }
  SUBMIT_DISABLED_REASONS = SUBMIT_DISABLED_REASONS

  uniqueClaimProviders = []

  @ViewChild(TableComponent, { static: false }) private _tableComponent;

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private formBuilder: FormBuilder,
    private _toastr: ToasterService,
    private paymentService: PaymentService,
    private payeeService: PayeeService,
    private router: Router,
    private route: ActivatedRoute,
    private dateUtility: DateUtility,
    private claimsService: ClaimsService,
    private _currencyFormat: CurrencyFormat,
    private _holistaUtils: HolistaUtils,
    private clientService: ClientService,
    private platformService: PlatformService,
    private utilityAccess: AccessType,
  ) {
  }

  ngOnInit() {
    this.isViewPayment = this.router.url.includes('/view/');
    this.isEditPayment = this.router.url.includes('/edit/');
    if (this.isViewPayment || this.isEditPayment) {
      this.isTableFormDisabled = true;
    } else {
      this.isTableFormDisabled = false;
    }
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      if (params.claimId && params.claimIdentifier) {
        this.isAddPayment = true;
      }
    })
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Payments', path: '/payment' }));
    this.store.dispatch(
      new HeaderBreadCrumbActions.AddBreadCrumb({
        name: `${this.isViewPayment ? 'View Payment' : this.isEditPayment ? 'Edit Payment' : 'Issue Payment'}`,
        path: '/payment/create/paid'
      })
    );

    this.getAllPayor().then((data: any) => {
      this.allPayorList = data;
      this.getPayor('PAID');
      this.getAllPayee().then((data: any) => {
        this.allPayeeList = data;
        this.getPayee('PAID');
      })
    });
    this.payee_results = this.payeeSearchText
      .pipe(
        startWith(this.payeeSearchText),
        switchMap((payeeSearchText: string) => this.searchPayee(payeeSearchText))
      );
    this.claimIdentifier_results = this.claimIdentifierSearchText
      .pipe(
        filter(() => !this.isAddPayment),
        startWith(this.claimIdentifierSearchText),
        switchMap((claimIdentifierSearchText: string) => this.searchClaimIdentifier(claimIdentifierSearchText))
      );
    this.payeesFound$ = this.payeesSearchText.pipe(
      debounceTime(300),
      filter((text: string) => text.length > 2),
      switchMap((payeesSearchText: string) => this.searchPayeeDetail(payeesSearchText))
    );
    this.setPaymentForm();
    this.setPayeeForm();
    this.paymentModeList = PaymentMode;

    this.route.params.subscribe((res) => {
      this.paymentUuid = res.paymentId;
    })

    if (this.isViewPayment || this.isEditPayment) {
      this.claimsTableUneditableFields = this.claimsTableUneditableFields.concat(['amount', 'hraHsaAmount', 'patientPayAmount', 'clientFeeAdjustment'])
      this.getPaymentById(this.paymentUuid);
    }

    if (this.isAddPayment) {
      this.addPayment();
    }
  }

  ngOnDestroy() {
    this.claimList = null;
    this.paymentUuid = null;
  }

  setPaymentForm() {
    this.paymentForm = this.formBuilder.group({
      receiptNumber: [null],
      paymentMode: [null, Validators.required],
      paymentModeTraceNumber: [null],
      payeeCode: [null],
      payorCode: [null],
      payorName: [null, Validators.required],
      payeeName: [null, Validators.required],
      paymentType: ['PAID', Validators.required],
      paymentDate: [null, Validators.required],
      totalAmount: [null, Validators.required],
      claimPayments: this.formBuilder.array([]),
      payorId: [null, Validators.required],
      payeeId: [null, Validators.required],
      payeeReferenceType: [REFERENCE_TYPE.PROVIDER, Validators.required],
      payorReferenceType: [REFERENCE_TYPE.PLATFORM, Validators.required],
    })
  }

  setPayeeForm() {
    this.payeeForm = this.formBuilder.group({
      id: [],
      name: [null, Validators.required],
      addressLine1: [null, Validators.required],
      addressLine2: [null],
      city: [null, Validators.required],
      state: [null, Validators.required],
      zip: [null, Validators.required],
      taxId: [null]
    })
  }

  getPaymentById(uuid) {
    this.loading.detail = true;
    this.paymentService.getPaymentByUuid(uuid)
      .pipe((finalize(() => { this.loading.detail = false })))
      .subscribe((res) => {
        const { payorId, payorName, payorCode, payeeName, payeeId, payeeCode, paymentDate, paymentMode, paymentModeTraceNumber, claimPayments } = res;
        const payeeInfo = res.payeeInfo;
        this.paymentForm.patchValue({
          payorId,
          payorName,
          payorCode,
          payeeName,
          payeeId,
          payeeCode,
          paymentDate: this.dateUtility.getFormattedDate(paymentDate),
          paymentMode,
          paymentModeTraceNumber,
        });

        this.uniqueClaimProviders = claimPayments.map((obj: any) => obj.billingProvider.providerId).filter((value, index, self) => self.indexOf(value) === index);
        // Data structures are different from onPayeeSelected() function so could not make common function
        let claimDataArr = []
        this.uniqueClaimProviders.forEach(prov => {
          let claimData: any
          const provClaims = claimPayments.filter(claim => claim.billingProvider.providerId == prov)
          const { addressLine1, addressLine2, businessName, city, state, zipCode: zip } = provClaims[0].billingProvider.basicInfo;
          claimData = {
            totalPayment: 0,
            totalAllowedAmount: 0,
            collapsed: false,
            data: provClaims && provClaims.length ? provClaims.map((res: any) => {
              return {
                ...res,
                claimIdentifier: res.patientAccountNumber,
                claimsStatus: res.claimXrefHolista.processingStatus ? res.claimXrefHolista.processingStatus : '-',
                hraHsaAmount: res.hraHsa ? `${this._currencyFormat.format(res.hraHsa)}` : res.hraHsa,
                amount: res.claimAmount ? `${this._currencyFormat.format(res.claimAmount)}` : res.claimAmount,
                totalPayment: res.claimXrefHolista.allowedAmount ? `${this._currencyFormat.format(res.claimXrefHolista.allowedAmount)}` : res.claimXrefHolista.allowedAmount,
                patientPayAmount: res.patientResponsibility ? `${this._currencyFormat.format(res.patientResponsibility)}` : res.patientResponsibility,
                allowedAmount: res.claimXrefHolista.allowedAmount ? `${this._currencyFormat.format(res.claimXrefHolista.allowedAmount)}` : res.claimXrefHolista.allowedAmount,
                collapsibleMenu: {
                  patientName: res.episodeUserName || res.claimXrefHolista.claim.patient.displayName || '-',
                  episode: res.episodeName ? res.episodeName : '-',
                  fundingRequestName: res.fundingRequestName ? res.fundingRequestName : '-',
                  bundleName: res.bundleName ? res.bundleName : '-',
                  bundleComponent: res.claimPaymentComponents.length ? res.claimPaymentComponents.map((x) => x.bundleComponentName).join(', ') : '-',
                  allowedAmount: res.claimXrefHolista.allowedAmount ? `${this._currencyFormat.format(res.claimXrefHolista.allowedAmount)}` : 0 || '-',
                  facility: {
                    name: businessName,
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zipCode: zip,
                    taxId: payeeInfo.taxId,
                    providerId: provClaims[0].billingProvider.providerId
                  },
                  serviceDate: res.claimXrefHolista.serviceLines && res.claimXrefHolista.serviceLines.length && res.claimXrefHolista.serviceLines[0].serviceDate ? res.claimXrefHolista.serviceLines[0].serviceDate : res.claimXrefHolista.claim ? res.claimXrefHolista.claim.statementDate : ''
                },
                isSelected: true,
                status: true,
              }
            }) : [],
            tableHeaders: this.claimTableHeaders.filter(header => header.value !== 'clientFeeAdjustment'),
          }
          claimDataArr.push(claimData)
        })

        this.claimList = claimDataArr

        this.calculateTotal(true)

        if (payeeId) {
          this.loading.payeeDetail = true;
          this.payeeForm.patchValue({
            ...payeeInfo
          });
          this.loading.payeeDetail = false;
        }
        setTimeout(() => {
          this._tableComponent.isMasterCheckboxSelected = true;
        }, 100);
      })
  }

  displayClaimIdentifier = (claim?): string | undefined => {
    return claim ? claim.patientAccountNumber : undefined;
  };

  onPayeeDisplayValue = (payee) => {
    return payee.name;
  };

  calculateTotal(isEditOrView?: boolean) {
    this.uniqueClaimProviders.forEach(prov => {
      const claimsList = this.claimList.find((claim) => claim.data[0].collapsibleMenu.facility.providerId == prov);
      let selectedClaims = claimsList.data

      if (!isEditOrView) {
        selectedClaims = this.selectedClaims.filter(claim => claim.collapsibleMenu.facility.providerId == prov)
      }
      claimsList.totalPayment = selectedClaims.reduce((prev, curr) => {
        const totalPayment = (curr.totalPayment ? this._currencyFormat.parseCurrency(curr.totalPayment) : 0);
        return prev += totalPayment
      }, 0);
      claimsList.totalAllowedAmount = selectedClaims.reduce((prev, curr) => {
        const allowedAmt = (curr.allowedAmount ? this._currencyFormat.parseCurrency(curr.allowedAmount) : 0);
        return prev += allowedAmt
      }, 0);
    })

    this.totalPayment = this.claimList.reduce((prev, curr) => {
      const totalPayment = (curr.totalPayment ? this._currencyFormat.parseCurrency(curr.totalPayment.toString()) : 0);
      return prev += totalPayment
    }, 0);

    this.totalAllowedAmount = this.claimList.reduce((prev, curr) => {
      const allowedAmt = (curr.totalAllowedAmount ? this._currencyFormat.parseCurrency(curr.totalAllowedAmount.toString()) : 0);
      return prev += allowedAmt
    }, 0);

  }

  /**
   * verifies if the selected claim/s are valid for payment
   * @param claim 
   * @returns validity for payment
   */
  verifyPaymentValidity(claim) {
    const { allowedAmount, amount, hraHsaAmount, patientPayAmount } = claim;
    const isAmountNegative = (amount ? this._currencyFormat.parseCurrency(amount) : 0) < 0;
    this.invalidStatusReason = (typeof (amount) === 'string' && amount.includes('-')) ? this.NEGATIVE_CLAIM_AMOUNT : MISMATCHED_STATUS;
    const totalAmount = ((amount ? this._currencyFormat.parseCurrency(amount) : 0) + (patientPayAmount ? this._currencyFormat.parseCurrency(patientPayAmount) : 0) + (hraHsaAmount ? this._currencyFormat.parseCurrency(hraHsaAmount) : 0)).toFixed(2);
    if (isAmountNegative) { claim.status = false; } else {
      claim.status = (((!isAmountNegative) && (allowedAmount ? this._currencyFormat.parseCurrency(allowedAmount) : 0)).toFixed(2) === totalAmount);
    }
    claim.totalPayment = this._currencyFormat.format(totalAmount);
    this._tableComponent.setStatus(claim);
    const invalidClaim = this.selectedClaims.find(claim => !claim.status);
    this.submit_enabled = !this.selectedClaims.length || invalidClaim ? false : true;
    this.calculateTotal();

    this.paymentForm.controls.totalAmount.setValue(this.totalPayment);
    setTimeout(() => {
      this.setConditionalFieldEnability(this.selectedClaims.length && this.totalAllowedAmount <= 0, 'paymentModeTraceNumber', '9000000000');
    }, 100);
  }

  get claimPayments(): FormArray {
    return this.paymentForm.get('claimPayments') as FormArray;
  }

  /**
   * disables or enables specific field based on desired condition
   * @param condition 
   * @param formControl 
   * @param value 
   */
  setConditionalFieldEnability(condition: any, formControl: string, value: number | string) {
    if (condition) {
      this.paymentForm.get(formControl).setValue('9000000000');
    } else {
      const formValue = this.paymentForm.get(formControl).value;
      this.paymentForm.get(formControl).setValue(formValue ? formValue : null);
    }
  }

  onClaimSelected(claim) {
    this.isSearched = true;
    const claimComponentList = this.isSearchedByClaimIdentifier ? claim.claimComponents : claim.claimXRefHolista.claimComponents;
    const claimComponents = claimComponentList.map(component => (
      this.formBuilder.group({
        bundleComponentUuid: component.bundleComponentUuid,
        bundleComponentName: component.bundleComponentName,
      })));
    const allowedAmount = claim.claimXRefHolista && claim.claimXRefHolista.allowedAmount || claim.allowedAmount || 0
    const claimPayment = this.formBuilder.group({
      patientAccountNumber: [(claim.claimXRefHolista && claim.claimXRefHolista.patientAccountNumber) || claim.patientAccountNumber],
      claimId: [claim.claimId || claim.id],
      episodeId: [claim.claimXRefHolista && claim.claimXRefHolista.episodeId || claim.episodeId],
      episodeUserId: [claim.claimXRefHolista && claim.claimXRefHolista.patientId || claim.patientId],
      episodeUserName: [claim.claimXRefHolista && claim.claimXRefHolista.patientName || claim.patientName || (claim.patient ? this._holistaUtils.properCase(claim.patient.displayName) : '')],
      episodeName: [claim.claimXRefHolista && claim.claimXRefHolista.episodeName || claim.episodeName],
      bundleUuid: [claim.claimXRefHolista && claim.claimXRefHolista.bundleUuid || claim.bundleUuid],
      bundleName: [claim.claimXRefHolista && claim.claimXRefHolista.bundleName || claim.bundleName],
      fundingRequestUuid: [claim.claimXRefHolista && claim.claimXRefHolista.fundingReqUuid || claim.fundingReqUuid],
      fundingRequestName: [claim.claimXRefHolista && claim.claimXRefHolista.fundingReqName || claim.fundingReqName],
      allowedAmount: [allowedAmount ? this._currencyFormat.parseCurrency(allowedAmount) : 0],
      hraHsa: [claim.claimXRefHolista && claim.claimXRefHolista.hraHsaAmount || claim.hraHsaAmount || 0],
      patientResponsibility: [claim.claimXRefHolista && claim.claimXRefHolista.patientPayAmount || claim.patientPayAmount || 0],
      claimAmount: [claim.claimXRefHolista && claim.claimXRefHolista.netAmount || claim.netAmount || 0],
      miscellaneous: [null],
      claimPaymentComponents: this.formBuilder.array(claimComponents),
    });
    this.claimPayments.push(claimPayment);
    this.selectedClaims.push(claim);
    this.verifyPaymentValidity(claim);
  }

  onClaimDeselected(claim) {
    const { allowedAmount, amount, hraHsaAmount, patientPayAmount, status } = claim;
    const indexToRemove = this.paymentForm.value.claimPayments.findIndex(({ claimId }) => claimId === claim.claimId || claim.id);
    (this.paymentForm.controls.claimPayments as FormArray).removeAt(indexToRemove);
    this.selectedClaims = this.selectedClaims.filter(({ id }) => id !== claim.claimId || claim.id);
    const totalAmount = (amount ? this._currencyFormat.parseCurrency(amount) : 0) + (patientPayAmount ? this._currencyFormat.parseCurrency(patientPayAmount) : 0) + (hraHsaAmount ? this._currencyFormat.parseCurrency(hraHsaAmount) : 0);
    this.verifyPaymentValidity(claim);
  }

  clearFormArray() {
    while ((this.paymentForm.controls.claimPayments as FormArray).length) {
      (this.paymentForm.controls.claimPayments as FormArray).removeAt(0)
    }
  }

  onClaimIdentifierSelected(event) {
    this.claimList = null;
    this.isSubmitted.editPayee = false;
    this.totalAllowedAmount = 0;
    this.totalPayment = 0;
    this.uniqueClaimProviders = [];
    this.paymentForm.reset();
    this.selectedClaims = [];
    this.clearFormArray();
    this.setPaymentForm();
    this.payeeForm.reset();
    this.loading.payeeDetail = true;
    this.submitted = false;
    this.loading.claims = true;
    this.payeesSearchText.next('');
    this.isSearchedByClaimIdentifier = true;
    this.bundleComponentList = [];
    const claimId = event.text != undefined ? event.text.claimId : event;
    if (claimId != '') {
      this.paymentForm.controls.paymentDate.setValue(this.dateUtility.getFormattedDate(new Date().toISOString().split('T')[0]));
      this.claimsService.getClaimAssociationsByClaimIdentifier(claimId, 'INCOMING')
        .pipe(finalize(() => { this.loading.claims = false }))
        .subscribe(res => {
          const { id: payeeId, specialityInformationProviderCode, taxIdentificationNumber: taxId, providerId } = res.data.payee;
          const { id: payeeProviderId, businessName, firstName, lastName, addressLine1, addressLine2, city, state, zipCode, identificationCode: npi } = res.data.payee.basicInfo;
          this.isLengthGreaterThan18 = false;
          if (res.data !== undefined) {
            const { id, patientAccountNumber: claimIdentifier, hraHsaAmount, netAmount: amount, patientPayAmount, processingStatus } = event.text;
            let requiredAmount = amount ? parseFloat(amount) : 0;
            if (res.data.outgoingClaimInfo) {
              const { remainingHraHsaAmount, remainingPatientPayAmount } = res.data.outgoingClaimInfo;
              requiredAmount = remainingHraHsaAmount ? (requiredAmount - parseFloat(remainingHraHsaAmount)) : requiredAmount;
              requiredAmount = remainingPatientPayAmount ? (requiredAmount - parseFloat(remainingPatientPayAmount)) : requiredAmount;
            };
            this.uniqueClaimProviders = [providerId]
            const data = [{
              ...res.data,
              ...event.text,
              id,
              claimIdentifier,
              hraHsaAmount: res.data.outgoingClaimInfo && res.data.outgoingClaimInfo.remainingHraHsaAmount ? this._currencyFormat.format(res.data.outgoingClaimInfo.remainingHraHsaAmount) : 0 || hraHsaAmount || 0,
              patientPayAmount: res.data.outgoingClaimInfo && res.data.outgoingClaimInfo.remainingPatientPayAmount ? this._currencyFormat.format(res.data.outgoingClaimInfo.remainingPatientPayAmount) : 0 || patientPayAmount || 0,
              amount: amount ? this._currencyFormat.format(requiredAmount) : 0,
              allowedAmount: amount ? this._currencyFormat.format(amount) : 0,
              claimsStatus: this._holistaUtils.titleCasing(processingStatus, '-'),
              totalPayment: 0,
              collapsibleMenu: {},
              isCollapsed: true,
            }];
            const filteredHeaders = ['isChecked', 'clientFeeAdjustment']
            this.claimsService.getPayeeDetail(taxId)
              .pipe(finalize(() => { this.loading.payeeDetail = false; })).subscribe(response => {
                const { id, name, addressLine1, addressLine2, city, state, zip, taxId } = response.rows[0] ?? {};
                name && this.payeeForm.patchValue({
                  id,
                  name,
                  addressLine1,
                  addressLine2,
                  city,
                  state,
                  zip,
                  taxId
                });
              }, (error) => {
                console.log("Error getting payee detail", error);
              });
            this.claimList = [{
              totalPayment: 0,
              totalAllowedAmount: 0,
              collapsed: false,
              data: data.map((x, index) => {
                Object.assign(x.collapsibleMenu, {
                  patientName: res.data.patientName ? res.data.patientName : x.patientName ? x.patientName : '-',
                  episode: x.episodeName ? x.episodeName : '-',
                  fundingRequestName: x.fundingReqName ? x.fundingReqName : '-',
                  bundleName: x.bundleName ? x.bundleName : '-',
                  bundleComponent: x.claimComponents.length ? x.claimComponents.map((x) => x.bundleComponentName).join(', ') : '-',
                  allowedAmount: x.allowedAmount ? x.allowedAmount : '-',
                  facility: {
                    name: businessName,
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    zipCode,
                    npi,
                    taxId,
                    providerId
                  },
                  serviceDate: x.serviceLines && x.serviceLines.length && x.serviceLines[0].serviceDate ? x.serviceLines[0].serviceDate : x.claim ? x.claim.statementDate : ''
                })
                return x;
              }),
              tableHeaders: this.claimTableHeaders.filter(header => !filteredHeaders.includes(header.value))
            }];
            this.selectedClaims.length = 0;
            setTimeout(() => {
              const claim: any = data[0];
              claim.isSelected = true;
              this.onClaimSelected(claim);
              this._tableComponent.isMasterCheckboxSelected = true;
            }, 100)
            this.paymentForm.patchValue({
              payorId: this.payorList[0].value,
              payorName: this.payorList[0].label,
              payorCode: this.payorList[0].typeCode,
              payeeId,
              payeeName: businessName ? businessName : firstName + ' ' + lastName,
              payeeCode: specialityInformationProviderCode,
              payeeProviderId,
              paymentType: 'PAID',
            });
            this.bundleComponentList = res.data.claimComponents;
            this.bundleComponentList.forEach(x => {
              x.label = x.bundleComponentName,
                x.value = x.bundleComponentUuid
            })
          }
          else {
            this._toastr.displayWarning(res.message);
          }
        }), (error) => {
          console.log("Error fetching Claim Associations", error);
        };
    }
  }

  getAllPayor() {
    this.loading.payment = true;
    return new Promise(resolve => {
      this.clientService.getClients({ limit: 0, fields: 'name,code,id' })
        .subscribe((res) => {
          if (res && res.count > 0) {
            this.clientList = res.rows;
            this.clientList.map(c => {
              this.allPayorList = [...this.allPayorList, { label: c.name, value: c.id, typeCode: 'CLIENT' }];
            })
          }
        });
      this.platformService.getAll({ limit: 0, fields: 'name,code,id,addressLine1,addressLine2,city,state,zip' })
        .pipe(finalize(() => { this.loading.payment = false; }))
        .subscribe((res) => {
          if (res && res.count > 0) {
            this.platformList = res.rows;
            this.platformList.map(p => {
              this.allPayorList = [...this.allPayorList, { label: p.name, value: p.id, typeCode: 'PLATFORM' }];
            })
            resolve(this.allPayorList);
          }
        });
    })
  }

  getAllPayee() {
    this.loading.payment = true;
    return new Promise(resolve => {
      this.platformList.map(p => {
        this.allPayeeList = [...this.allPayeeList, { label: p.name, value: p.id, typeCode: 'PLATFORM' }];
      })
      this.claimsService.getBillingProviders('')
        .pipe(finalize(() => { this.loading.payment = false; }))
        .subscribe((res) => {
          this.billingProviderList = res;
          this.billingProviderList.forEach(x => {
            this.allPayeeList = [...this.allPayeeList, {
              label: x.basicInfo.businessName ? x.basicInfo.businessName : x.basicInfo.firstName + ' ' + x.basicInfo.lastName,
              value: x.id, typeCode: x.specialityInformationProviderCode
            }];
          })
          resolve(this.allPayeeList);
        });
    });
  }

  searchPayee(searchText) {
    if (searchText.length > 2) {
      return this.claimsService.getBillingProviders(searchText)
        .pipe(
          debounceTime(250),
          map((items: any) => {
            if (items.length > 0)
              items = items.filter(x => x.basicInfo.businessName.toLowerCase() !== 'holista')
            if (searchText.toLowerCase() == 'hol' || searchText.toLowerCase() == 'holi' || searchText.toLowerCase() == 'holis' || searchText.toLowerCase() == 'holist' || searchText.toLowerCase() == 'holista') {
              this.platformList.map(c => {
                if (c.code == 'HOLISTA') {
                  items = [...items, {
                    'id': c.id,
                    'basicInfo': {
                      'businessName': c.name,
                      'addressLine1': c.addressLine1,
                      'addressLine2': c.addressLine2,
                      'city': c.city,
                      'state': c.state,
                      'zipCode': c.zip
                    }
                  }];
                }
              })
            }
            return items
          })
        );
    } else {
      return of([]);
    }
  }

  displayPayee = (payee?): string | undefined => {
    return payee ? this._holistaUtils.toTitleCase(payee.basicInfo.businessName ? payee.basicInfo.businessName : payee.basicInfo.firstName + ' ' + payee.basicInfo.lastName) : undefined;
  };

  searchClaimIdentifier(searchText) {
    if (this.paymentForm.value.claimPayments.length) {
      this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientAccountNumber.setValidators(Validators.required);
      this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientAccountNumber.updateValueAndValidity();
    }
    if (searchText.length > 2) {
      return this.claimsService.getClaimIdentifierForPayment(searchText, 'INCOMING')
        .pipe(
          debounceTime(250),
          map((items: any) => {
            return items
          })
        );
    } else {
      if (searchText.length == 0 && this.paymentForm.value.claimPayments.length)
        this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientAccountNumber.setValue('');
      return of([]);
    }
  }

  searchClaimIdentifierChange() {
    this.claimIdentifierSearchText.subscribe((res: any) => {
      if (res.length < 3) {
        let payType = this.paymentForm.controls.paymentType.value;
        this.patientAccNumText = '';
        this.paymentForm.controls.paymentType.setValue(payType);
        this.paymentForm.controls.paymentDate.setValue(this.dateUtility.getFormattedDate(new Date().toISOString().split('T')[0]));
      }
    });
    if (this.patientAccNumText != '') {
      this.claimIdentifierSearchText.next(this.patientAccNumText)
    }
  }

  addPayment() {
    if (this.utilityAccess.searchAccess('PAY', 'isEditable')) {
      this.paymentForm.controls.paymentDate.setValue(this.dateUtility.getFormattedDate(new Date().toISOString().split('T')[0]));
      this.route.queryParams.subscribe(params => {
        if (params.claimId && params.claimIdentifier) {
          this.isEnabled = true;
          let type = 'PAID';
          this.paymentForm.controls.paymentType.setValue(type);
          this.claimIdentifierSearchText.next(params.claimIdentifier);
          this.patientAccNumText = params.claimIdentifier;
          this.getPayor(type);
          this.getPayee(type);
          this.searchClaimIdentifier(params.claimIdentifier).subscribe((res) => {
            const claim = res.find((claim) => claim.claimId === parseInt(params.claimId));
            if (claim.claimId) {
              this.onClaimIdentifierSelected({ text: claim });
            }
          })
        } else {
          this.paymentForm.controls.paymentType.setValue("PAID");
          this.getPayor('PAID');
          this.getPayee('PAID');
        }
      });
      this.totalPayment = this.paymentForm.value.totalAmount || 0;
    }
    else
      this._toastr.displayWarning(ACCESS_DENIED);
  }

  getPayor(type) {
    this.payorList = [];
    this.allPayorList.map(c => {
      if (c.typeCode == 'PLATFORM') {
        this.payorList = [...this.payorList, { label: c.label, value: c.value, typeCode: c.typeCode }];
      }
    })
  }

  getPayee(type) {
    this.payeeList = [];
    this.allPayeeList.map(c => {
      if (c.typeCode != 'PLATFORM') {
        this.payeeList = [...this.payeeList, { label: c.label, value: c.value, typeCode: c.typeCode }];
      }
    })
  }

  updatePayment(paymentId, body) {
    this.claimsService.updatePayeeDetail(this.payeeForm.value)
      .pipe(finalize(() => { }))
      .subscribe(response => {
        if (response) {
          body = { ...body, payeeId: response[0].id, payeeName: response[0].name, }
          this.paymentService.patchPayment(paymentId, body)
            .pipe(finalize(() => {
              this.submit_enabled = false;
              this.loading.saving = false;
              this.isSubmitted.editPayee = false;
              this.loading.savingPayeeDetail = false;
            }))
            .subscribe((res) => {
              this.router.navigate(['/payment']);
              this._toastr.displaySuccess(UPDATE_SUCCESS);
            }, error => {
              console.log("Error Updating Payment", error);
              this._toastr.displayError(UPDATE_FAILED);
            })
        }
      }, (error) => {
        console.log("Error saving payee detail", error);
      })


  }

  savePayment(payee) {
    const selectedClaimsPayment = this.selectedClaims.map(claimPayment => ({
      // ...claimPayment,
      claimId: claimPayment.claimId || claimPayment.id,
      allowedAmount: (claimPayment.allowedAmount ? this._currencyFormat.parseCurrency(claimPayment.allowedAmount) : 0),
      claimAmount: (claimPayment.amount ? this._currencyFormat.parseCurrency(claimPayment.amount) : 0),
      hraHsa: (claimPayment.hraHsaAmount ? this._currencyFormat.parseCurrency(claimPayment.hraHsaAmount) : 0),
      patientResponsibility: (claimPayment.patientPayAmount ? this._currencyFormat.parseCurrency(claimPayment.patientPayAmount) : 0),
    }));
    let { claimPayments } = this.paymentForm.value;
    claimPayments = claimPayments.map(claim => {
      const selectedClaim = selectedClaimsPayment.find(x => x.claimId === claim.claimId)
      claim = {
        ...claim,
        ...selectedClaim
      }
      return claim
    });
    const paymentValue = { ...this.paymentForm.value, payeeInfo: { ...payee }, payeeId: payee.id, payeeName: payee.name, claimPayments, paymentModeTraceNumber: this.paymentForm.value.paymentModeTraceNumber || null }
    this.paymentService
      .savePayment(paymentValue)
      .pipe(
        finalize(() => {
          this.loading.saving = false;
          this.submitted = false;
        })
      )
      .subscribe(
        res => {
          this.router.navigate(['/payment']);
          this._toastr.displaySuccess(ADD_SUCCESS);
        },
        error => {
          console.log("Error Saving Payment", error);
          if (error.error.message) {
            const parsedMessage = JSON.parse(error.error.message);
            const hrsHsaMessage = parsedMessage.hraHsa && parsedMessage.hraHsa.length ? `Not enough HRA/HSA amount for ${parsedMessage.hraHsa.join(", ")}.` : '';
            const patientResponsibilityMessage = parsedMessage.patientResponsibility && parsedMessage.patientResponsibility.length ? `Not enough Patient Responsibility amount for ${parsedMessage.patientResponsibility.join(", ")}.` : '';
            const errorMessage = hrsHsaMessage ? `${hrsHsaMessage} <br> ${patientResponsibilityMessage}` : patientResponsibilityMessage;
            this._toastr.displayError(errorMessage, null, null, {
              enableHtml: true,
              toastClass: 'toast-custom-width',
            });
            this.mergeClaimList(this.claimList).forEach(claim => {
              claim.hasHraHsaError = parsedMessage.hraHsa.includes(claim.claimIdentifier);
              claim.hasPatientResponsibilityError = parsedMessage.patientResponsibility.includes(claim.claimIdentifier);
            });
          } else {
            this._toastr.displayError(error.error);
          }
        }
      );
  }

  submitPayment() {
    this.loading.saving = true;
    if (this.isEditPayment) {
      const body = {
        paymentModeTraceNumber: this.paymentForm.controls['paymentModeTraceNumber'].value,
      }
      this.updatePayment(this.paymentUuid, body);
      return;
    }
    !this.paymentForm.value.claimPayments[0].patientResponsibility && this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientResponsibility.setValue(0);
    !this.paymentForm.value.totalAmount && this.paymentForm.controls.totalAmount.setValue(0);
    !this.paymentForm.value.claimPayments[0].hraHsa && this.paymentForm.controls.claimPayments['controls'][0]['controls'].hraHsa.setValue(0);
    this.submitted = true;

    if (this.paymentForm.valid) {
      this.onSaveEditedPayeeDetail(false);
    }
  }

  searchPayeeDetail(searchText: string) {
    if (this.paymentForm.value.claimPayments.length) {
      this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientAccountNumber.clearValidators();
      this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientAccountNumber.updateValueAndValidity();
    }
    return this.payeeService.getPayee({ searchKey: searchText }, true)
      .pipe(
        debounceTime(250),
        map(({ rows }) => rows)
      );
  }

  onPayeeSelected(event) {
    const { id, name, addressLine1, addressLine2, city, state, tin: taxId, zip } = event.text;
    this.claimList = null;
    this.isSubmitted.editPayee = false;
    this.totalAllowedAmount = 0;
    this.totalPayment = 0;
    this.uniqueClaimProviders = []
    this.paymentForm.reset();
    this.selectedClaims = [];
    this.clearFormArray();
    this.setPaymentForm();
    this.submitted = false;
    this.submit_enabled = false;
    this.loading.claims = true;
    this.payeeForm.reset();
    this.payeesSearchText.next(event.text.name);
    this.paymentForm.controls.paymentDate.setValue(this.dateUtility.getFormattedDate(new Date().toISOString().split('T')[0]));
    this._holistaUtils.removeValidator(this.paymentForm, 'payeeId');
    this.paymentForm.patchValue({
      payorName: this.payorList[0].label,
      payorCode: this.payorList[0].typeCode,
      payeeName: name,
      payorId: this.payorList[0].value,
      paymentType: 'PAID'
    });
    this.payeeForm.patchValue({
      id,
      name,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      taxId
    });
    this.isSearchedByClaimIdentifier = false;
    this.claimIdentifierSearchText.next('');
    const body = {
      processingStatus: ["READY-TO-PAY", "DENIED"],
      limit: 0, // to get all claims while selecting provider
      providerTaxId: taxId,
    };
    this.isSearched = true;

    this.claimsService.searchClaims(body, 'INCOMING', true).pipe(finalize(() => { this.loading.claims = false; }))
      .subscribe(response => {
        if (response.count) {
          this.isLengthGreaterThan18 = response.rows.length > 19;
          response.rows.reduce((acc, obj) => {
            const foundClaim = acc.find(o => o.claimXRefHolista.episodeId === obj.claimXRefHolista.episodeId && o.claimXRefHolista.fundingReqUuid === obj.claimXRefHolista.fundingReqUuid);
            if (foundClaim) {
              obj.outgoingClaimInfo = {
                ...obj.outgoingClaimInfo,
                remainingPatientPayAmount: 0,
                remainingHraHsaAmount: 0,
              };
            }
            return acc.concat(obj);
          }, []);

          this.uniqueClaimProviders = response.rows.map((obj: any) => obj.transaction.billingProvider.providerId).filter((value, index, self) => self.indexOf(value) === index);
          let claimDataArr = []
          this.uniqueClaimProviders.forEach(prov => {
            let claimData: any
            const provClaims = response.rows.filter(claim => claim.transaction.billingProvider.providerId == prov)
            claimData = {
              totalPayment: 0,
              totalAllowedAmount: 0,
              collapsed: true,
              data: provClaims.map((res: any) => {
                const { displayName: name, addressLine1, addressLine2, city, state, zipCode, identificationCode: npi } = res.transaction.billingProvider.basicInfo;
                let requiredAmount = res.claimXRefHolista.netAmount ? parseFloat(res.claimXRefHolista.netAmount) : 0;
                if (res.outgoingClaimInfo) {
                  const { remainingHraHsaAmount, remainingPatientPayAmount } = res.outgoingClaimInfo;
                  requiredAmount = remainingHraHsaAmount ? (requiredAmount - parseFloat(remainingHraHsaAmount)) : requiredAmount;
                  requiredAmount = remainingPatientPayAmount ? (requiredAmount - parseFloat(remainingPatientPayAmount)) : requiredAmount;
                };
                return {
                  ...res,
                  claimIdentifier: res.patientAccountNumber,
                  claimsStatus: this._holistaUtils.titleCasing((res.claimXRefHolista.processingStatus ? res.claimXRefHolista.processingStatus : res.processingStatus), '-'),
                  hraHsaAmount: res.outgoingClaimInfo ? `${this._currencyFormat.format(res.outgoingClaimInfo.remainingHraHsaAmount || 0)}` : `${this._currencyFormat.format(0)}`,
                  amount: requiredAmount ? `${this._currencyFormat.format(requiredAmount)}` : 0,
                  patientPayAmount: res.outgoingClaimInfo ? `${this._currencyFormat.format(res.outgoingClaimInfo.remainingPatientPayAmount || 0)}` : `${this._currencyFormat.format(0)}`,
                  allowedAmount: res.claimXRefHolista.allowedAmount ? `${this._currencyFormat.format(res.claimXRefHolista.allowedAmount)}` : res.claimXRefHolista.allowedAmount,
                  collapsibleMenu: {
                    patientName: res.claimXRefHolista.patientName ? res.claimXRefHolista.patientName : res.patient.displayName,
                    episode: res.claimXRefHolista.episodeName ? res.claimXRefHolista.episodeName : '-',
                    fundingRequestName: res.claimXRefHolista.fundingReqName ? res.claimXRefHolista.fundingReqName : '-',
                    bundleName: res.claimXRefHolista.bundleName ? res.claimXRefHolista.bundleName : '-',
                    bundleComponent: res.claimXRefHolista.claimComponents.length ? res.claimXRefHolista.claimComponents.map((x) => x.bundleComponentName).join(', ') : '-',
                    allowedAmount: res.claimXRefHolista.allowedAmount ? `${this._currencyFormat.format(res.claimXRefHolista.allowedAmount)}` : res.claimXRefHolista.allowedAmount || '-',
                    facility: {
                      name,
                      addressLine1,
                      addressLine2,
                      city,
                      state,
                      zipCode,
                      npi,
                      taxId: res.transaction.billingProvider.taxIdentificationNumber,
                      providerId: res.transaction.billingProvider.providerId
                    },
                    serviceDate: res.serviceLines && res.serviceLines.length && res.serviceLines[0].serviceDate ? res.serviceLines[0].serviceDate : res.statementDate || null
                  }
                }
              }),
              tableHeaders: this.claimTableHeaders.filter(header => header.value !== 'clientFeeAdjustment'),
            };
            claimDataArr.push(claimData)
          })

          this.claimList = claimDataArr
          this._tableComponent && (this._tableComponent.isMasterCheckboxSelected = false);
        };
      });
  }

  onCollapse(event) {
    const totalClaims = this.mergeClaimList(this.claimList)
    const isCollapsedData = totalClaims.find((x) => x.isCollapsed);
    if (totalClaims.length > 19) {
      this.isLengthGreaterThan18 = true;
    } else {
      if ((totalClaims.length < 19 && isCollapsedData) || (event)) {
        this.isLengthGreaterThan18 = true;
      } else {
        this.isLengthGreaterThan18 = false;
      }
    }
  }

  handleEditPayeeInfo() {
    this.clonedPayeeForm = this.payeeForm.value;
  }

  facilityPaymentCollapsed(facilityClaims) {
    facilityClaims.collapsed = !facilityClaims.collapsed;
  }

  mergeClaimList(claimList) {
    let totalClaims = []
    claimList.forEach(claims => {
      totalClaims = totalClaims.concat(claims.data)
    })
    return totalClaims
  }

  onSaveEditedPayeeDetail(isEdited: boolean = true) {
    this.isSubmitted.editPayee = isEdited && true;
    if (this.payeeForm.valid) {
      this.loading.savingPayeeDetail = true;
      if (this.isEditPayment) {
        this.submitPayment();
      } else {
        this.claimsService.updatePayeeDetail(this.payeeForm.value)
          .pipe(finalize(() => {
            this.isSubmitted.editPayee = false;
            this.loading.savingPayeeDetail = false;
          }))
          .subscribe(response => {
            if (response) {
              if (isEdited) {
                this._toastr.displaySuccess(UPDATE_SUCCESS);
              } else this.savePayment(response[0]);
            }
          }, (error) => {
            console.log("Error saving payee detail", error);
          })
      }
    }
  }

}
