import { Milestone } from './model.milestones';
export class PathwayResponse {
    companyId: number;
    createdDate: string;
    description: string;
    id: number;
    isActive: boolean;
    milestones: Milestone[];
    name: string;
    tags: string;
    networkCode: string;
}