import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import * as HeaderBreadCrumbActions from '../../../action';
import { ACCESS_DENIED, ADD_SUCCESS, DELETE_SUCCESS, RestrictSpace, TrimFields, UPDATE_SUCCESS, MODULES } from '../../../constants';
import { PathwayResponse } from '../../../models';
import { LookupService, MilestoneService, ToasterService } from '../../../services';
import { AccessType, HolistaUtils, Storage } from '../../../utils';
@Component({
  selector: 'app-pathway-milestone',
  templateUrl: './pathway-milestone.component.html',
  styleUrls: ['./pathway-milestone.component.scss']
})
export class PathwayMilestoneComponent implements OnInit {
  pathway: any;
  selectedMilestone = {};
  milestoneForm: FormGroup;
  triggerPeriodOptions = [
    { label: 'Day(s)', value: 'days' },
    { label: 'Week(s)', value: 'weeks' }
  ];
  triggerConditionOptions = [
    { label: 'Before', value: 'before' },
    { label: 'After', value: 'after' }
  ];
  triggerMilestoneOptions = [];
  milestoneList = [];
  searchKey = '';
  loading = false;
  isSubmitEnabled = false;
  submitted = false;
  dataToBeDelete: any;
  sectionDescHeight = 65;
  TriggerOnStart = false;
  initialTriggerDays = 0;
  loggedUser: any;
  groups: any;
  isLoading = {
    groups: false
  }

  @ViewChild('milestoneModal', { static: true }) public milestoneModal;
  @ViewChild('deleteMilestoneModal', { static: true }) public deleteMilestoneModal;

  @Input() set selected_pathway(selected_pathway) {
    if (selected_pathway.id) {
    }
  }

  // sticky content
  // @HostListener('window:scroll', []) onWindowScroll() {
  //   // sticky filter
  //   const element = document.getElementById('stickyContent');
  //   if (window.pageYOffset > 120) {
  //     element.classList.add('sticky');
  //   } else if (element) {
  //     element.classList.remove('sticky');
  //   }
  // }

  constructor(
    private milestoneService: MilestoneService,
    private formBuilder: FormBuilder,
    private _toastr: ToasterService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<{ bread_crumbs: any }>,
    private holistaUtils: HolistaUtils,
    public utilityAccess: AccessType,
    private _storage: Storage,
    private _lookupService: LookupService
  ) {
    this.setMilestoneForm();
  }

  setMilestoneForm() {
    this.milestoneForm = this.formBuilder.group({
      id: [null],
      pathwayId: [null, Validators.required],
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      description: ['', Validators.pattern(RestrictSpace)],
      startDate: [null],
      endDate: [null],
      triggerDays: [0],
      triggerPeriod: ['days'],
      triggerCondition: ['after'],
      triggerMilestoneId: [null],
      triggerMilestoneUuId: [null],
      createdBy: [null],
      updatedBy: [null],
      updatedDate: [null],
      sequence: [null],
      isTriggerOnStart: [false],
      isKeyMilestone: [false],
      isMainProcedure: [false],
      groupCode: [null]
    });
  }

  ngOnInit() {
    this.loggedUser = this._storage.get('local', 'loggedInUser', 'user');
    if (history.state.data) {
      this.refreshPathway(history.state.data);
    } else {
      let pathwayId = this._activatedRoute.snapshot.params.pathwayId;
      this.getPathway(pathwayId);
    }
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Pathways', path: '/pathway' }));
  }

  getMilestoneGroups() {
    this.isLoading.groups = true;
    this._lookupService.getLookups({ module: MODULES.MILESTONE })
      .pipe(finalize(() => { this.isLoading.groups = false }))
      .subscribe((response: any) => {
        this.groups = response.rows.map(group => ({ ...group, label: group.name, value: group.code }));
      }, (error) => {
        this._toastr.displayError(error);
        console.log('error getting groups', error);
      });
  }

  addMilestone() {
    if (this.loggedUser.companyType === 'COMPANY') return this._toastr.displayWarning(ACCESS_DENIED);
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      this.getMilestoneGroups();
      this.milestoneModal.show();
      // if (this.triggerMilestoneOptions.length > 0) {
      //   let latestMilestone = Math.max.apply(
      //     Math,
      //     this.triggerMilestoneOptions.map(x => x.value)
      //   );
      //   this.milestoneForm.controls.triggerMilestoneId.setValue(latestMilestone);
      // }
    } else
      this._toastr.displayWarning(ACCESS_DENIED);
  }

  deselectMilestone(event) {
    this.milestoneForm.controls.triggerMilestoneId.setValue(null);
  }

  refreshPathway(pathway) {
    this.pathway = pathway;
    if (this.pathway.milestones.length > 0) {
      this.pathway.milestones.map(x => {
        let data = { value: x.id, label: x.name };
        this.milestoneList = [...this.milestoneList, data];
        this.triggerMilestoneOptions = [...this.triggerMilestoneOptions, data];
      });
      this.pathway.milestones = this.holistaUtils.sortBy(this.pathway.milestones, 'sequence');
      this.goToMileStoneTopic(pathway.milestones[0]);
    }
    setTimeout(() => {
      this.sectionDescHeight = document.getElementById('sectionDesc').offsetHeight;
      this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Milestone', path: '' }));
    }, 0);
  }

  getPathway(id) {
    this.loading = true;
    this.milestoneService.getMilestones(parseInt(id)).subscribe(
      (res: PathwayResponse) => {
        this.refreshPathway(res);
        this.loading = false;
      },
      error => {
        console.log(error);
      }
    );
  }
  goToMileStoneTopic(milestone) {
    this.selectedMilestone = milestone;
  }

  editMilestone(milestone) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      this.initialTriggerDays = milestone.triggerDays;
      this.milestoneForm.patchValue(milestone);
      // this.triggerMilestoneOptions=[];
      let list = this.milestoneList.filter(x => x.value != milestone.id);
      this.triggerMilestoneOptions = list;
      this.getMilestoneGroups();
      this.milestoneModal.show();
    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  closeModal() {
    this.milestoneForm.reset();
    this.setMilestoneForm();
    this.milestoneModal.hide();
    this.triggerMilestoneOptions = [...this.milestoneList];
    this.isSubmitEnabled = false;
    this.submitted = false;
    this.TriggerOnStart = false;
  }

  submitMilestone() {
    this.submitted = true;
    this.milestoneForm.controls.pathwayId.setValue(this.milestoneForm.value.pathwayId ? this.milestoneForm.value.pathwayId : this.pathway.id ? this.pathway.id : null);
    if (this.milestoneForm.valid) {
      TrimFields.PathwayMilestone.forEach(element => {
        this.milestoneForm.value[element] = this.milestoneForm.value[element] ? this.milestoneForm.value[element].trim() : this.milestoneForm.value[element];
      });
      this.isSubmitEnabled = true;
      if (this.milestoneForm.value.id) this.updateMilestone();
      else this.saveMilestone();
    }
  }

  saveMilestone() {
    this.milestoneForm.controls.sequence.setValue(this.pathway.milestones ? this.pathway.milestones.length + 1 : 1);
    let milestone = this.milestoneForm.value;
    if (this.milestoneForm.value.isTriggerOnStart) milestone.triggerMilestoneId = null
    this.milestoneService
      .saveMilestone(milestone)
      .pipe(
        finalize(() => {
          this.isSubmitEnabled = false;
        })
      )
      .subscribe(
        (res: any) => {
          this.selectedMilestone = {};
          this.refreshMilestones(res, 'saved');
        },
        error => {
          console.log(error);
        }
      );
  }

  updateMilestone() {
    this.milestoneService
      .updateMilestone(this.milestoneForm.getRawValue())
      .pipe(
        finalize(() => {
          this.isSubmitEnabled = false;
        })
      )
      .subscribe(
        res => {
          this.refreshMilestones(res, 'updated');
        },
        error => {
          console.log(error);
        }
      );
  }

  refreshMilestones(response, action) {
    if (response.message) {
      this._toastr.displayError(response.message);
    } else {
      // let milestone = response;
      // if (this.pathway && this.pathway.milestones && this.pathway.milestones.length > 0) {
      //   let index = this.pathway.milestones.findIndex(x => x.id === milestone.id);
      //   if (index > -1) {
      //     this.pathway.milestones[index] = milestone;
      //   } else this.pathway.milestones.push(milestone);
      // } else {
      //   this.pathway.milestones.push(milestone);
      // }
      response = response.data;
      this.pathway.milestones = response;
      let index = response.findIndex(x => x.id == this.selectedMilestone['id']);
      if (this.selectedMilestone && index >= 0) {
        this.selectedMilestone = response[index];
      } else if (this.pathway.milestones.length > 0) {
        this.goToMileStoneTopic(this.pathway.milestones[0]);
      }
      this.milestoneList = [];
      this.triggerMilestoneOptions = [];
      this.pathway.milestones.map(x => {
        let data = { value: x.id, label: x.name };
        this.milestoneList = [...this.milestoneList, data];
        this.triggerMilestoneOptions = [...this.triggerMilestoneOptions, data];
      });
      action === 'saved' && this._toastr.displaySuccess(ADD_SUCCESS);
      action === 'updated' && this._toastr.displaySuccess(UPDATE_SUCCESS);
    }
    this.closeModal();
  }

  milestoneToDelete(milestone) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      const dependantMilestones = this.pathway.milestones.filter(({ triggerMilestoneUuid }) => triggerMilestoneUuid === milestone.uuid);
      let relativeMilestones = '';
      const lastIndex = dependantMilestones.length - 1;

      dependantMilestones && dependantMilestones.length ? (
        dependantMilestones.forEach(({ name }, index: number) => relativeMilestones += (index !== 0 && index === lastIndex) ? ` and ${name}` : index !== 0 ? `, ${name}` : name),
        this._toastr.displayWarning(`${relativeMilestones} ${dependantMilestones.length > 1 ? 'are' : 'is'} dependent on ${milestone.name}. Please update them to delete. `, null, null, { timeOut: 10000 })
      ) : (
        milestone['source'] = 'Pathway',
        milestone['type'] = 'Milestone',
        milestone['displayName'] = milestone.name,
        this.dataToBeDelete = milestone
      )

    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  deleteMilestone(e) {
    let index = this.pathway.milestones.findIndex(x => x.id == e.id);
    if (index > -1) {
      if (index !== 0) {
        this.goToMileStoneTopic(this.pathway.milestones[index - 1]);
      } else if (this.pathway.milestones.length == 1) {
        this.selectedMilestone = {};
      } else {
        this.goToMileStoneTopic(this.pathway.milestones[index + 1]);
      }
      this.pathway.milestones.splice(index, 1);
      this.refreshMilestones({ data: this.pathway.milestones }, 'deleted');
    }
    this.dataToBeDelete = null;
  }

  cancelDelete(e) {
    this.dataToBeDelete = e;
  }

  onDrop(item: any, list: any[]): void {
    if (list.length) {
      const index = list.indexOf(item);
      list.splice(index, 1);
      this.pathway.miletones = [...list];
      let body = [];
      this.pathway.miletones.map((a, index) => {
        a.sequence = index + 1;
        let data = {
          name: a.name,
          id: a.id,
          sequence: a.sequence
        };
        body.push(data);
        return a;
      });
      this.milestoneService
        .changeMilestoneSequence(body)
        .pipe(finalize(() => { }))
        .subscribe(
          res => { },
          error => {
            console.log(error);
          }
        );
    }
  }

  isTriggerOnStart(e, id) {
    if (e.target.checked) {
      this.milestoneForm.controls.triggerDays.setValue(0);
    } else if (id) this.milestoneForm.controls.triggerDays.setValue(this.initialTriggerDays);
  }

  isPrimaryMilestone(event: any) {
    this.milestoneForm.controls.isKeyMilestone.setValue(event.target.checked);
  }

  isMainProcedure(event: any) {
    this.milestoneForm.controls.isMainProcedure.setValue(event.target.checked);
  }

  onGroupDeselected() {
    this.milestoneForm.controls.groupCode.setValue(null);
  }
}
