export class Provider {
    npi: number
    type: number
    firstName: string
    middleName: string
    lastName: string
    businessName: string
    email: string
    website: string
    description: string
    addressline1: string
    addressline2: string
    city: string
    state: string
    zip: number
    latitude: number
    longitude: number
    locationList: []
    individualList: []
    phone: number
}