import { DateUtility } from './../../../utils/date.utils';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { finalize, debounceTime, startWith, switchMap, map, filter, take } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../../action';
import { CLAIMS_PAYMENT_DESCRIPTIONS, FETCH_PAYMENT_FAILED, INVALID_STATUS_EXCEEDS_REASON, INVALID_STATUS_SUBCEEDS_REASON, PaymentMode, REFERENCE_TYPE } from '../../../constants';
import { ClaimsService, ClientService, PaymentService, ToasterService, PlatformService } from 'src/app/services';
import { ADD_SUCCESS, ACCESS_DENIED, CLAIM_TABLE_HEADERS, MaskConstant, CLAIMS_TABLE_UNEDITABLE_FIELDS, UPDATE_FAILED, NON_PRE_POPULATED_FIELDS } from '../../../constants';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { PURCHASER_CODES } from '../../../constants';
import { AccessType, CurrencyFormat, HolistaUtils } from 'src/app/utils';
import { TableComponent } from '../../../common';

@Component({
  selector: 'app-received-payment',
  templateUrl: './received-payment.component.html',
  styleUrls: ['./received-payment.component.scss']
})
export class ReceivedPaymentComponent implements OnInit {
  data = {
    patientName: '',
    episode: '',
    fundingRequestName: '',
    bundleName: '',
    bundleComponent: '',
    allowedAmount: '',
  }
  paymentForm: FormGroup;
  submitted = false;
  submit_enabled = false;
  bundleComponentList: any = [];
  tempPayor = [];
  tempPayee = [];
  allPayorList = [];
  allPayeeList = [];
  payeeList = [];
  payorList = [];
  clientList: any[] = [];
  platformList: any[] = [];
  searchKeyword: string;
  payeeSearchText = new Subject();
  claimIdentifierSearchText = new BehaviorSubject<string>('');
  claimIdentifier_results: Observable<any>;
  payee_results: Observable<any>;
  patientAccNumText = '';
  billingProviderList = [];
  searchModelChanged: Subject<string> = new Subject<string>();
  loading = {
    payment: false,
    popup: false,
    claims: false,
    saving: false,
    detail: false,
  };
  claimList: any;
  totalPayment: number = 0;
  paymentModeList = [];
  dateMask: any[] = MaskConstant.DATE;
  result = { payments: true, searchedPayments: true };
  page = 1;
  limit = 10;
  totalCount = 0;
  paymentList = [];
  isEnabled = null;
  disableSave: boolean = false;
  isSearchedByClaimIdentifier: boolean = false;
  claimTableHeaders = CLAIM_TABLE_HEADERS;
  nonPrePopulatedFields = NON_PRE_POPULATED_FIELDS;
  selectedClaims = [];
  claimsTableUneditableFields = CLAIMS_TABLE_UNEDITABLE_FIELDS;
  invalidStatusExceedsReason = INVALID_STATUS_EXCEEDS_REASON;
  invalidStatusSubceedsReason = INVALID_STATUS_SUBCEEDS_REASON;
  CLAIMS_PAYMENT_DESCRIPTIONS = CLAIMS_PAYMENT_DESCRIPTIONS;
  REFERENCE_TYPE = REFERENCE_TYPE;
  totalAllowedAmount: number = 0;
  isViewPayment: boolean = false;
  isAddPayment: boolean = false;
  isSearched: boolean = false;
  paymentUuid: string;
  invalidStatusReason: string;

  @ViewChild(TableComponent, { static: false }) private _tableComponent;

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private formBuilder: FormBuilder,
    private _toastr: ToasterService,
    private clientService: ClientService,
    private platformService: PlatformService,
    private paymentService: PaymentService,
    private router: Router,
    private route: ActivatedRoute,
    private dateUtility: DateUtility,
    private claimsService: ClaimsService,
    private utilityAccess: AccessType,
    private _currencyFormat: CurrencyFormat,
    private _holistaUtils: HolistaUtils,
  ) {
  }

  async ngOnInit() {
    this.isViewPayment = this.router.url.includes('/view/');
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      if (params.claimId && params.claimIdentifier) {
        this.isAddPayment = true;
      }
    })

    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Payments', path: '/payment' }));
    this.store.dispatch(
      new HeaderBreadCrumbActions.AddBreadCrumb({
        name: `${this.isViewPayment ? 'View Payment' : 'Received Payment'}`,
        path: '/payment/create/received'
      })
    );

    this.paymentModeList = PaymentMode;
    this.setPaymentForm();

    await this.getAllPayor().then((data: any) => {
      this.allPayorList = data;
      this.getPayor('RECEIVED');
      this.getAllPayee().then((data: any) => {
        this.allPayeeList = data;
        this.getPayee('RECEIVED');
      })
    });
    this.payee_results = this.payeeSearchText
      .pipe(
        startWith(this.payeeSearchText),
        switchMap((payeeSearchText: string) => this.searchPayee(payeeSearchText))
      );
    this.claimIdentifier_results = this.claimIdentifierSearchText
      .pipe(
        filter(() => !this.isAddPayment),
        startWith(this.claimIdentifierSearchText),
        switchMap((claimIdentifierSearchText: string) => this.searchClaimIdentifier(claimIdentifierSearchText))
      );

    this.route.params.subscribe((res) => {
      this.paymentUuid = res.paymentId;
    })

    if (this.isAddPayment) {
      this.addPayment();
    }

    if (this.isViewPayment) {
      this.getPaymentById(this.paymentUuid);
    }
  }

  ngOnDestroy() {
    this.claimList = null;
    this.paymentUuid = null;
  }

  getPaymentById(uuid) {
    this.loading.detail = true;
    this.paymentService.getPaymentByUuid(uuid)
      .pipe((finalize(() => { this.loading.detail = false })))
      .subscribe((res) => {
        const { payorId, payorName, payorCode, payeeName, payeeId, payeeCode, paymentDate, paymentMode, paymentModeTraceNumber, claimPayments } = res;
        this.paymentForm.patchValue({
          payorId,
          payorName,
          payorCode,
          payeeName,
          payeeId,
          payeeCode,
          paymentDate: this.dateUtility.getFormattedDate(paymentDate),
          paymentMode,
          paymentModeTraceNumber,
        });
        this.paymentForm.disable();
        this.claimList = {
          data: claimPayments && claimPayments.length ? claimPayments.map((res: any) => {
            return {
              ...res,
              claimIdentifier: res.patientAccountNumber,
              hraHsaAmount: res.hraHsa ? `${this._currencyFormat.format(res.hraHsa)}` : res.hraHsa,
              amount: res.claimAmount ? `${this._currencyFormat.format(res.claimAmount)}` : res.claimAmount,
              totalPayment: res.claimXrefHolista.allowedAmount ? `${this._currencyFormat.format(res.claimXrefHolista.allowedAmount)}` : res.claimXrefHolista.allowedAmount,
              patientPayAmount: res.patientResponsibility ? `${this._currencyFormat.format(res.patientResponsibility)}` : res.patientResponsibility,
              clientFeeAdjustment: res.clientFee ? `${this._currencyFormat.format(res.clientFee)}` : res.clientFee,
              allowedAmount: res.claimXrefHolista.allowedAmount ? `${this._currencyFormat.format(res.claimXrefHolista.allowedAmount)}` : res.claimXrefHolista.allowedAmount,
              collapsibleMenu: {
                patientName: res.episodeUserName || res.claimXrefHolista.claim.patient.displayName || '-',
                episode: res.episodeName ? res.episodeName : '-',
                fundingRequestName: res.fundingRequestName ? res.fundingRequestName : '-',
                bundleName: res.bundleName ? res.bundleName : '-',
                bundleComponent: res.claimPaymentComponents.length ? res.claimPaymentComponents.map((x) => x.bundleComponentName).join(', ') : '-',
                allowedAmount: res.claimXrefHolista.allowedAmount ? `${this._currencyFormat.format(res.claimXrefHolista.allowedAmount)}` : 0 || '-',
              },
              isSelected: true,
              isCollapsed: true,
              status: true,
            }
          }) : [],
          tableHeaders: this.claimTableHeaders.filter(header => header.value !== 'isChecked' && header.value !== 'claimsStatus'),
        }
        this.totalPayment = this.claimList.data.reduce((prev, curr) => {
          const totalPayment = (curr.totalPayment ? (this._currencyFormat.parseCurrency(curr.totalPayment)) : 0);
          return prev += totalPayment
        }, 0);
        this.totalAllowedAmount = this.claimList.data.reduce((prev, curr) => {
          const allowedAmt = (curr.allowedAmount ? (this._currencyFormat.parseCurrency(curr.allowedAmount)) : 0);
          return prev += allowedAmt
        }, 0);
        setTimeout(() => {
          this._tableComponent.isMasterCheckboxSelected = true;
        }, 100);
      })
  }

  displayClaimIdentifier = (claim?): string | undefined => {
    return claim ? claim.patientAccountNumber : undefined;
  };

  calculateTotal() {
    this.totalPayment = this.selectedClaims.reduce((prev, curr) => {
      const totalPayment = (curr.totalPayment ? this._currencyFormat.parseCurrency(curr.totalPayment) : 0);
      return prev += totalPayment
    }, 0);
    this.totalAllowedAmount = this.selectedClaims.reduce((prev, curr) => {
      const allowedAmt = (curr.allowedAmount ? this._currencyFormat.parseCurrency(curr.allowedAmount) : 0);
      return prev += allowedAmt
    }, 0);
    this.paymentForm.controls.totalAmount.setValue(this.totalPayment);
    this.setConditionalFieldEnability(this.selectedClaims.length && this.totalAllowedAmount <= 0, 'paymentModeTraceNumber', '9000000000');
    this.invalidStatusReason = (this.totalPayment > this.totalAllowedAmount) ? this.invalidStatusExceedsReason : this.invalidStatusSubceedsReason;
  }

  /**
   * verifies if the selected claim/s are valid for payment
   * @param claim 
   * @returns validity for payment
   */
  verifyPaymentValidity(claim) {
    const { amount, patientPayAmount, hraHsaAmount, clientFeeAdjustment, allowedAmount } = claim;
    const totalAmount = (this._currencyFormat.parseCurrency(amount) + (patientPayAmount && this._currencyFormat.parseCurrency(patientPayAmount)) + (hraHsaAmount && this._currencyFormat.parseCurrency(hraHsaAmount)) + (clientFeeAdjustment && this._currencyFormat.parseCurrency(clientFeeAdjustment))).toFixed(2);
    claim.status = this._currencyFormat.parseCurrency(allowedAmount).toFixed(2) === totalAmount;
    claim.totalPayment = this._currencyFormat.format(totalAmount);
    this.disableSave = !claim.status;
    this._tableComponent.setStatus(claim);
    const invalidClaim = this.selectedClaims.find(claim => !claim.status);
    this.disableSave = !this.selectedClaims.length || invalidClaim ? true : false;
    this.calculateTotal();
  }

  get claimPayments(): FormArray {
    return this.paymentForm.get('claimPayments') as FormArray;
  }

  clearFormArray() {
    while ((this.paymentForm.controls.claimPayments as FormArray).length) {
      (this.paymentForm.controls.claimPayments as FormArray).removeAt(0)
    }
  }

  /**
   * disables or enables specific field based on desired condition
   * @param condition 
   * @param formControl 
   * @param value 
   */
  setConditionalFieldEnability(condition: any, formControl: string, value: number | string) {
    if (condition) {
      this.paymentForm.get(formControl).setValue('9000000000');
    } else {
      const formValue = this.paymentForm.get(formControl).value;
      this.paymentForm.get(formControl).setValue(formValue ? formValue : null);
    }
  }

  onClaimSelected(claim) {
    const claimComponents = claim.claimComponents.map(component => (
      this.formBuilder.group({
        bundleComponentUuid: component.bundleComponentUuid,
        bundleComponentName: component.bundleComponentName,
      })));
    const claimPayment = this.formBuilder.group({
      patientAccountNumber: [claim.patientAccountNumber],
      claimId: [claim.claimId],
      episodeId: [claim.episodeId, Validators.required],
      episodeUserId: [claim.claimXRefHolista && claim.claimXRefHolista.patientId || claim.patientId],
      episodeUserName: [claim.claimXRefHolista && claim.claimXRefHolista.patientName || claim.patientName],
      episodeName: [claim.claimXRefHolista && claim.claimXRefHolista.episodeName || claim.episodeName],
      bundleUuid: [claim.bundleUuid, Validators.required],
      bundleName: [claim.bundleName],
      fundingRequestUuid: [claim.fundingReqUuid],
      fundingRequestName: [claim.fundingReqName],
      allowedAmount: [claim.allowedAmount],
      hraHsa: [claim.hraHsaAmount],
      patientResponsibility: [claim.patientPayAmount],
      clientFee: [claim.clientFee],
      claimAmount: [claim.netAmount],
      miscellaneous: [null],
      claimPaymentComponents: this.formBuilder.array(claimComponents),
    });
    this.claimPayments.push(claimPayment);
    this.selectedClaims.push(claim);
    !claim.isSelected && (this.selectedClaims = this.selectedClaims.filter(({ id }) => id !== claim.id));
    this.verifyPaymentValidity(claim);
  }

  onClaimIdentifierSelected(event) {
    this.totalAllowedAmount = 0;
    this.totalPayment = 0;
    this.paymentForm.reset();
    this.selectedClaims = [];
    this.clearFormArray();
    this.setPaymentForm();
    this.submitted = false;
    this.loading.claims = true;
    this.isSearchedByClaimIdentifier = true;
    this.bundleComponentList = [];
    const claimId = event.text != undefined ? event.text.claimId : event;
    this.isSearched = true;
    if (claimId != '') {
      this.paymentForm.controls.paymentDate.setValue(this.dateUtility.getFormattedDate(new Date().toISOString().split('T')[0]));
      this.claimsService.getClaimAssociationsByClaimIdentifier(claimId, 'OUTGOING')
        .pipe(finalize(() => { this.loading.claims = false }))
        .subscribe(res => {
          if (res.data !== undefined) {
            const { id, patientAccountNumber: claimIdentifier, hraHsaAmount, netAmount: amount, patientPayAmount, clientFee, allowedAmount } = event.text;
            const data = [{
              ...res.data,
              ...event.text,
              id,
              claimIdentifier,
              amount: amount ? this._currencyFormat.format(amount) : 0,
              hraHsaAmount: 0,
              patientPayAmount: 0,
              clientFeeAdjustment: 0,
              allowedAmount: allowedAmount ? this._currencyFormat.format(allowedAmount) : 0,
              totalPayment: 0,
              collapsibleMenu: {},
              isCollapsed: true,
            }]
            this.claimList = {
              data: data.map((x, index) => {
                Object.assign(x.collapsibleMenu, {
                  patientName: x.patientName ? x.patientName : '-',
                  episode: x.episodeName ? x.episodeName : '-',
                  fundingRequestName: x.fundingReqName ? x.fundingReqName : '-',
                  bundleName: x.bundleName ? x.bundleName : '-',
                  bundleComponent: x.claimComponents.length ? x.claimComponents.map((x) => x.bundleComponentName).join(', ') : '-',
                  allowedAmount: x.allowedAmount ? x.allowedAmount : '-',
                })
                return x;
              }),
              tableHeaders: this.claimTableHeaders.filter(header => header.value !== 'isChecked' && header.value !== 'claimsStatus'),
            };
            this.selectedClaims.length = 0;
            setTimeout(() => {
              const claim: any = data[0];
              claim.isSelected = true;
              this.onClaimSelected(claim);
              this._tableComponent.isMasterCheckboxSelected = true;
            }, 100)
            this.paymentForm.patchValue({
              payorId: res.data.payor.id,
              payorName: res.data.payor.name,
              payorCode: res.data.payor.code,
              payeeId: this.payeeList[0].value,
              payeeName: this.payeeList[0].label,
              payeeCode: this.payeeList[0].typeCode,
              paymentType: 'RECEIVED'
            });
            this.bundleComponentList = res.data.claimComponents;
            this.bundleComponentList.forEach(x => {
              x.label = x.bundleComponentName,
                x.value = x.bundleComponentUuid
            })
          }
          else {
            this._toastr.displayWarning(res.message);
          }
        }), (error) => {
          console.log("Error fetching Claim Associations", error);
        };
    }
  }

  setPaymentForm() {
    this.paymentForm = this.formBuilder.group({
      receiptNumber: [null],
      paymentMode: [null, Validators.required],
      paymentModeTraceNumber: [null, Validators.required],
      payeeCode: [null],
      payorCode: [null],
      payorName: [null, Validators.required],
      payeeName: [null, Validators.required],
      paymentType: ['RECEIVED', Validators.required],
      paymentDate: [null, Validators.required],
      totalAmount: [null, Validators.required],
      claimPayments: this.formBuilder.array([]),
      payorId: [null, Validators.required],
      payeeId: [null, Validators.required],
      payeeReferenceType: [REFERENCE_TYPE.PLATFORM, Validators.required],
      payorReferenceType: [REFERENCE_TYPE.CLIENT, Validators.required],
    })
  }

  getAllPayor() {
    this.loading.payment = true;
    return new Promise(resolve => {
      this.clientService.getClients({ limit: 0, fields: 'name,code,id' })
        .subscribe((res) => {
          if (res && res.count > 0) {
            this.clientList = res.rows;
            this.clientList.map(c => {
              this.allPayorList = [...this.allPayorList, { label: c.name, value: c.id, typeCode: 'CLIENT' }];
            })
          }
        });
      this.platformService.getAll({ limit: 0, fields: 'name,code,id,addressLine1,addressLine2,city,state,zip' })
        .pipe(finalize(() => { this.loading.payment = false; }))
        .subscribe((res) => {
          if (res && res.count > 0) {
            this.platformList = res.rows;
            this.platformList.map(p => {
              this.allPayorList = [...this.allPayorList, { label: p.name, value: p.id, typeCode: 'PLATFORM' }];
            })
            resolve(this.allPayorList);
          }
        });
    })
  }

  getAllPayee() {
    this.loading.payment = true;
    return new Promise(resolve => {
      this.platformList.map(p => {
        this.allPayeeList = [...this.allPayeeList, { label: p.name, value: p.id, typeCode: 'PLATFORM' }];
      })
      this.claimsService.getBillingProviders('')
        .pipe(finalize(() => { this.loading.payment = false; }))
        .subscribe((res) => {
          this.billingProviderList = res;
          this.billingProviderList.forEach(x => {
            this.allPayeeList = [...this.allPayeeList, {
              label: x.basicInfo.businessName ? x.basicInfo.businessName : x.basicInfo.firstName + ' ' + x.basicInfo.lastName,
              value: x.id, typeCode: x.specialityInformationProviderCode
            }];
          })
          resolve(this.allPayeeList);
        });
    });
  }

  searchPayee(searchText) {
    if (searchText.length > 2) {
      return this.claimsService.getBillingProviders(searchText)
        .pipe(
          debounceTime(250),
          map((items: any) => {
            if (items.length > 0)
              items = items.filter(x => x.basicInfo.businessName.toLowerCase() !== 'holista')
            if (searchText.toLowerCase() == 'hol' || searchText.toLowerCase() == 'holi' || searchText.toLowerCase() == 'holis' || searchText.toLowerCase() == 'holist' || searchText.toLowerCase() == 'holista') {
              this.platformList.map(c => {
                if (c.code == 'HOLISTA') {
                  items = [...items, {
                    'id': c.id,
                    'basicInfo': {
                      'businessName': c.name,
                      'addressLine1': c.addressLine1,
                      'addressLine2': c.addressLine2,
                      'city': c.city,
                      'state': c.state,
                      'zipCode': c.zip
                    }
                  }];
                }
              })
            }
            return items
          })
        );
    } else {
      return of([]);
    }
  }

  displayPayee = (payee?): string | undefined => {
    return payee ? this._holistaUtils.toTitleCase(payee.basicInfo.businessName ? payee.basicInfo.businessName : payee.basicInfo.firstName + ' ' + payee.basicInfo.lastName) : undefined;
  };

  searchClaimIdentifier(searchText) {
    if (this.paymentForm.value.claimPayments.length) {
      this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientAccountNumber.setValidators(Validators.required);
      this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientAccountNumber.updateValueAndValidity();
    }

    if (searchText.length > 2) {
      return this.claimsService.getClaimIdentifierForPayment(searchText, 'OUTGOING')
        .pipe(
          debounceTime(250),
          map((items: any) => {
            return items
          })
        );
    } else {
      if (searchText.length == 0 && this.paymentForm.value.claimPayments.length)
        this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientAccountNumber.setValue('');
      return of([]);
    }
  }

  searchClaimIdentifierChange() {
    this.claimIdentifierSearchText.subscribe((res: any) => {
      if (res.length < 3) {
        let payType = this.paymentForm.controls.paymentType.value;
        this.paymentForm.reset();
        this.patientAccNumText = '';
        this.paymentForm.controls.paymentType.setValue(payType);
        this.paymentForm.controls.paymentDate.setValue(this.dateUtility.getFormattedDate(new Date().toISOString().split('T')[0]));
      }
    });
    if (this.patientAccNumText != '') {
      this.claimIdentifierSearchText.next(this.patientAccNumText)
    }
  }

  addPayment() {
    if (this.utilityAccess.searchAccess('PAY', 'isEditable')) {
      this.paymentForm.controls.paymentDate.setValue(this.dateUtility.getFormattedDate(new Date().toISOString().split('T')[0]));
      this.route.queryParams.subscribe(params => {
        if (params.claimId && params.claimIdentifier) {
          this.isEnabled = true;
          let type = 'RECEIVED';
          this.paymentForm.controls.paymentType.setValue(type);
          this.claimIdentifierSearchText.next(params.claimIdentifier);
          this.patientAccNumText = params.claimIdentifier;
          this.getPayor(type);
          this.getPayee(type);
          this.searchClaimIdentifier(params.claimIdentifier).subscribe((res) => {
            const claim = res.find((claim) => claim.claimId === parseInt(params.claimId));
            if (claim.claimId) {
              this.onClaimIdentifierSelected({ text: claim });
            }
          })
        } else {
          this.paymentForm.controls.paymentType.setValue("RECEIVED");
          this.getPayor('RECEIVED');
          this.getPayee('RECEIVED');
        }
      });
      this.totalPayment = this.paymentForm.value.amount || 0;
    }
    else
      this._toastr.displayWarning(ACCESS_DENIED);
  }

  getPayor(type) {
    this.payorList = [];
    if (type == 'RECEIVED') {
      this.allPayorList.map(c => {
        if (c.typeCode == 'CLIENT') {
          this.payorList = [...this.payorList, { label: c.label, value: c.value, typeCode: c.typeCode }];
        }
      })
    }
  }

  getPayee(type) {
    this.payeeList = [];
    if (type == 'RECEIVED') {
      this.allPayeeList.map(c => {
        if (c.typeCode == 'PLATFORM') {
          this.payeeList = [...this.payeeList, { label: c.label, value: c.value, typeCode: c.typeCode }];
        }
      })
    }
  }

  updatePayment() {
    let bundleCompList = [];
    if (this.paymentForm.value.claimPayments[0].bundleComponents[0].bundleComponentUuid) {
      this.paymentForm.value.claimPayments[0].bundleComponents[0].bundleComponentUuid.forEach(element => {
        let index = this.bundleComponentList.findIndex(x => x.bundleComponentUuid == element);
        if (index >= 0)
          bundleCompList.push(this.bundleComponentList[index]);
      });
    }
    this.paymentForm.value.claimPayments[0].bundleComponents = bundleCompList;

    this.paymentService
      .updatePayment(this.paymentForm.value)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
          this.loading.saving = false;
        })
      )
      .subscribe(
        res => {
          // this.refreshPayments(res, 'updated');
        },
        error => {
          console.log("Error Updating Payment", error);
          this._toastr.displayError(UPDATE_FAILED);
        }
      );
  }

  savePayment() {
    const selectedClaimsPayment = this.selectedClaims.map(claimPayment => ({
      claimId: claimPayment.claimId,
      allowedAmount: (claimPayment.allowedAmount ? this._currencyFormat.parseCurrency(claimPayment.allowedAmount) : 0),
      claimAmount: (claimPayment.amount ? this._currencyFormat.parseCurrency(claimPayment.amount) : 0),
      hraHsa: (claimPayment.hraHsaAmount ? this._currencyFormat.parseCurrency(claimPayment.hraHsaAmount) : 0),
      clientFee: (claimPayment.clientFeeAdjustment ? this._currencyFormat.parseCurrency(claimPayment.clientFeeAdjustment) : 0),
      patientResponsibility: (claimPayment.patientPayAmount ? this._currencyFormat.parseCurrency(claimPayment.patientPayAmount) : 0),
    }));
    let { claimPayments } = this.paymentForm.value;
    claimPayments = claimPayments.map(claim => {
      const selectedClaim = selectedClaimsPayment.find(x => x.claimId === claim.claimId)
      claim = {
        ...claim,
        ...selectedClaim
      }
      return claim
    })
    const paymentValue = { ...this.paymentForm.value, claimPayments, paymentModeTraceNumber: this.paymentForm.value.paymentModeTraceNumber || null }
    this.paymentService
      .savePayment(paymentValue)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
          this.loading.saving = false;
        })
      )
      .subscribe(
        res => {
          this.router.navigate(['/payment']);
          this._toastr.displaySuccess(ADD_SUCCESS);
        },
        error => {
          console.log("Error Saving Payment", error);
          this._toastr.displayError(error.error.message || error.error);
        }
      );
  }

  submitPayment() {
    this.loading.saving = true;
    !this.paymentForm.value.claimPayments[0].patientResponsibility && this.paymentForm.controls.claimPayments['controls'][0]['controls'].patientResponsibility.setValue(0);
    !this.paymentForm.value.claimPayments[0].clientFee && this.paymentForm.controls.claimPayments['controls'][0]['controls'].clientFee.setValue(0);
    !this.paymentForm.value.totalAmount && this.paymentForm.controls.totalAmount.setValue(0);
    !this.paymentForm.value.claimPayments[0].hraHsa && this.paymentForm.controls.claimPayments['controls'][0]['controls'].hraHsa.setValue(0);
    this.submitted = true;
    if (this.paymentForm.valid) {
      this.savePayment();
    }
  }

}
