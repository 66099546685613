<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
    <div class="form-group has-search input-group mt-2 mb-2 w-25 w-xs-100">
      <span class="form-control-feedback left-0">
        <i class="icofont-search-1"></i>
      </span>
      <input
        type="text"
        [(ngModel)]="eocQuery.keyword"
        class="form-control"
        name="searchKeyword"
        placeholder="Search"
        (ngModelChange)="searchKeywordChange($event)" />
      <div class="input-group-append" *ngIf="eocQuery.keyword">
        <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
          <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50 mt-2 mb-2">
      <div
        style="width: 40%; margin-top: 5px"
        *ngIf="
          user.referenceType === 'PLATFORM' ||
          user.referenceType === 'CLIENT' ||
          user.referenceType === 'PURCHASER'
        ">
        <app-select
          [options]="backupNetworks"
          [placeholder]="'Select network'"
          (onSelected)="filterByNetwork($event)"
          (onDeselected)="filterByNetwork($event, 'remove')"
          [allowClear]="true">
        </app-select>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#basicExample"
          mdbWavesEffect
          (click)="openEpisodeOfCareModal(null)">
          <i class="icofont-plus"></i> ADD
        </button>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table mdbTable stickyHeader="true" small="true" hover="true">
          <thead id="scrollToTop">
            <tr>
              <th width="20"></th>
              <th
                scope="col"
                [class.active]="eocQuery.sortBy === 'name'"
                (click)="setOrder('name')">
                Name
                <span *ngIf="eocQuery.sortBy === 'name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                scope="col"
                [class.active]="eocQuery.sortBy === 'network.name'"
                (click)="setOrder('network.name')">
                Network
                <span *ngIf="eocQuery.sortBy === 'network.name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                scope="col"
                [class.active]="eocQuery.sortBy === 'pathway.name'"
                (click)="setOrder('pathway.name')">
                Pathway
                <span *ngIf="eocQuery.sortBy === 'pathway.name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                scope="col"
                [class.active]="eocQuery.sortBy === 'bundle.name'"
                (click)="setOrder('bundle.name')">
                Bundle
                <span *ngIf="eocQuery.sortBy === 'bundle.name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th scope="col" [class.active]="eocQuery.sortBy === 'id'" (click)="setOrder('id')">
                Created Date
                <span *ngIf="eocQuery.sortBy === 'id'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th width="20"></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loadingData.episode"
            [style.top]="episodeOfCareList && episodeOfCareList.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loadingData.episode"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let eoc of episodeOfCareList
                | paginate
                  : {
                      itemsPerPage: eocQuery.limit,
                      currentPage: eocQuery.page,
                      totalItems: totalCount
                    };
              let i = index
            ">
            <tr>
              <td (click)="getAssociatePlans(eoc)">
                <button
                  type="button"
                  class="btn btn-link p-0 m-0"
                  mdbWavesEffect
                  *ngIf="eoc?.collapsed">
                  <i class="icofont-minus-circle icofont-lg text-info"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-link p-0 m-0"
                  mdbWavesEffect
                  *ngIf="!eoc?.collapsed">
                  <i class="icofont-plus-circle icofont-lg text-info"></i>
                </button>
              </td>
              <td>{{ eoc?.name }}</td>
              <td>{{ eoc?.networkName }}</td>
              <td>{{ eoc?.pathwayName }}</td>
              <td>{{ eoc?.bundleName }}</td>
              <td>{{ eoc?.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}</td>
              <td>
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div
                    class="custom-dropdown-content rounded-sm z-depth-2"
                    [style.top]="i === eocQuery.limit - 1 && '-134px'">
                    <a class="dropdown-item" (click)="openPlanModal(eoc)">
                      <i class="fa-fw fas fa-plus-circle"></i>
                      Associate Plan Group
                    </a>
                    <a class="dropdown-item" (click)="openEpisodeOfCareModal(eoc)">
                      <i class="icofont-edit-alt"></i>
                      Edit
                    </a>
                    <a class="dropdown-item" (click)="delete(eoc)">
                      <i class="icofont-ui-delete"></i>
                      Delete
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="eoc.collapsed" class="grey lighten-5 border">
              <td colspan="100%" class="p-3">
                <app-loader
                  [loader]="
                    !eoc.isPlanLoad && (!eoc.associatedPlans || eoc.associatedPlans.length == 0)
                  ">
                </app-loader>
                <div *ngIf="eoc.associatedPlans && eoc.associatedPlans.length > 0">
                  <h6>Associated Plan Groups</h6>
                  <div
                    class="chip chip-lg blue lighten-5 rounded mb-2"
                    *ngFor="let plan of eoc.associatedPlans">
                    <mdb-icon fas icon="check-circle" class="text-primary"></mdb-icon>
                    {{ plan.planGroup }} <span>-</span>
                    {{
                      plan.patientResponsibility === 0
                        ? '$0.00'
                        : plan.patientResponsibility
                        ? (plan.patientResponsibility | currency)
                        : 'N/A'
                    }}
                  </div>
                </div>
                <div
                  *ngIf="
                    eoc.isPlanLoad && (!eoc.associatedPlans || eoc.associatedPlans.length == 0)
                  ">
                  <h6>No Associated Plan Groups</h6>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="episodeOfCareList && episodeOfCareList.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <div
    appScrollToTop
    class="fixed-action-btn donotprint"
    style="bottom: 145px; right: 10px; display: none">
    <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
      <i class="fas fa-angle-up text-black-50"></i>
    </a>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loadingData.episode && !result.EOCs">
    <i class="far fa-3x fa-clone text-black-50"></i>
    <p>Start adding Episode of care.</p>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loadingData.episode && !result.searchedEOCs">
    <i class="far fa-3x fa-clone text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>

<!-- Delete Modal -->
<app-delete-confirmation
  [_id]="episodeOfCareId"
  [message]="message"
  (confirmDelete)="deleteEpisodeOfCare($event)">
</app-delete-confirmation>

<!-- Episode Of Care Add/Edit Modal -->
<div
  mdbModal
  #episodeOfCareModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ episodeOfCareForm.value.id ? 'Edit' : 'Add' }} Episode Of Care
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeEpisodeOfCareModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="episodeOfCareForm">
          <div class="form-group">
            <label for="name">Name *</label>
            <input
              type="text"
              id="name"
              class="md-textarea form-control"
              mdbInput
              formControlName="name"
              [ngClass]="{
                'error-select': submitted && episodeOfCareForm.get('name').invalid
              }" />
          </div>
          <div class="form-group">
            <div class="form-row">
              <div class="col">
                <label for="name"
                  >Category
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="loadingData.categories"></span>
                </label>
                <app-select
                  [options]="categories"
                  [id]="'category'"
                  [control]="episodeOfCareForm.controls['categoryId']"
                  [disabled]="loadingData.categories"
                  [allowClear]="true"
                  (onSelected)="onCategorySelected($event)"
                  (onDeselected)="onCategoryDeselected()">
                </app-select>
              </div>
              <div class="col">
                <label for="name"
                  >Sub Category
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="
                      episodeOfCareForm.get('categoryId').value && loadingData.subCategories
                    "></span>
                </label>
                <app-select
                  [options]="subCategories"
                  [id]="'sub-category'"
                  [control]="episodeOfCareForm.controls['subCategoryId']"
                  [allowClear]="true"
                  (onDeselected)="onCategoryDeselected(true)"
                  [disabled]="
                    !subCategories.length ||
                    !episodeOfCareForm.get('categoryId').value ||
                    loadingData.subCategories
                  ">
                </app-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              type="text"
              id="description"
              class="md-textarea form-control"
              mdbInput
              formControlName="description"></textarea>
          </div>
          <!-- Nav tabs -->
          <mdb-tabset
            #staticTabs
            [buttonClass]="'classic-tabs'"
            [contentClass]="'card tab-clean d-block p-0'"
            class="classic-tabs">
            <!--Panel 1-->
            <mdb-tab heading="Program Information">
              <div class="row">
                <div class="col-12">
                  <br />
                  <div class="form-group">
                    <label for="network">Network *</label>
                    <app-select
                      [options]="networks"
                      [id]="'network'"
                      [placeholder]="'Select network'"
                      [control]="episodeOfCareForm.controls['networkCode']"
                      (onSelected)="networkSelected($event)"
                      [disabled]="networks.length === 0"
                      [inputClass]="{
                        'error-select': submitted && episodeOfCareForm.get('networkCode').invalid,
                        'pointer-disabled': episodeOfCareForm.get('id').value
                      }">
                    </app-select>
                  </div>
                  <div class="form-group">
                    <label for="pathway"
                      >Pathway *
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                        *ngIf="episodeOfCareForm.value.networkCode && loadingData.pathway"></span
                    ></label>
                    <app-select
                      [options]="pathwayList"
                      [id]="'pathway'"
                      [placeholder]="'Select pathway'"
                      [control]="episodeOfCareForm.controls['pathwayUuid']"
                      (onSelected)="pathwaySelected($event, 'change')"
                      [disabled]="pathwayList.length === 0"
                      [inputClass]="{
                        'error-select': submitted && episodeOfCareForm.get('pathwayUuid').invalid,
                        'pointer-disabled': episodeOfCareForm.get('id').value
                      }">
                    </app-select>
                  </div>
                  <div class="form-group">
                    <label for="bundle"
                      >Bundle *
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                        *ngIf="episodeOfCareForm.value.networkCode && loadingData.bundle"></span
                    ></label>
                    <app-select
                      [options]="bundleList"
                      [id]="'bundle'"
                      [placeholder]="'Select bundle'"
                      [control]="episodeOfCareForm.controls['bundleUuid']"
                      (onSelected)="bundleSelected($event)"
                      [disabled]="bundleList.length === 0"
                      [inputClass]="{
                        'error-select': submitted && episodeOfCareForm.get('bundleUuid').invalid,
                        'pointer-disabled': episodeOfCareForm.get('id').value
                      }">
                    </app-select>
                  </div>
                  <div class="form-group">
                    <div class="form-row" *ngIf="bundleCosts && bundleCosts.length > 0">
                      <div class="col-6" *ngFor="let cost of bundleCosts">
                        <mdb-card class="blue lighten-5 z-depth-0 mb-2">
                          <mdb-card-body class="p-2">
                            <p class="text-dark">
                              {{ cost.marketCode }} <span>-</span>
                              {{ cost.totalCost | currency }}
                            </p>
                            <small class="text-muted">({{ cost?.market?.name }})</small>
                          </mdb-card-body>
                        </mdb-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mdb-tab>
            <!--Panel 2-->
            <mdb-tab heading="Funding Request Settings">
              <div class="row">
                <div class="col-12">
                  <table mdbTable small="true">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Trigger By</th>
                      </tr>
                    </thead>
                    <tbody
                      *ngIf="
                        selectedBundle &&
                        selectedBundle.fundingRequests &&
                        selectedBundle.fundingRequests.length > 0
                      ">
                      <tr *ngFor="let fund of selectedBundle.fundingRequests; let i = index">
                        <td>{{ fund.name }}</td>
                        <td *ngIf="fund?.isTriggered">
                          <mdb-select
                            [options]="selectedBundle.components | sort : 'label'"
                            id="component{{ i }}"
                            [(ngModel)]="fund.triggeredComponentUuid"
                            [ngModelOptions]="{standalone: true}"
                            filterEnabled="true"
                            allowClear="true"
                            [disabled]="fund.triggeredComponentUuid">
                          </mdb-select>
                        </td>
                        <td *ngIf="!fund?.isTriggered">
                          <mdb-select
                            [options]="selectedPathway.milestones | sort : 'label'"
                            id="milestones{{ i }}"
                            [(ngModel)]="fund.milestoneUuid"
                            [ngModelOptions]="{standalone: true}"
                            filterEnabled="true"
                            allowClear="true"
                            placeholder="Select milestone">
                          </mdb-select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mdb-tab>
          </mdb-tabset>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeEpisodeOfCareModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitEpisodeOfCare()">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ episodeOfCareForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add/Edit Plan Modal  -->
<div
  mdbModal
  #planModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Associate Plan Group</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeplanModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form #planForm="ngForm">
          <div class="table-wrapper">
            <div class="container-fluid">
              <div class="row">
                <p>
                  Associate plan group for
                  <strong>{{ selectedEpisode.name }}</strong> Episode of Care
                </p>
                <table *ngIf="plans && plans.length != 0" mdbTable small="true">
                  <thead>
                    <tr>
                      <th width="60%">Plan Group</th>
                      <th>Member Responsibility</th>
                    </tr>
                  </thead>
                  <tbody>
                    <app-loader [loader]="loadingData.plan"></app-loader>
                    <tr *ngFor="let plan of plans; let i = index">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input form-control-sm"
                            id="checkbox{{ i }}"
                            [checked]="plan.isChecked"
                            (change)="checkChange($event, i)" />
                          <label class="custom-control-label" for="checkbox{{ i }}">{{
                            plan.planGroup
                          }}</label>
                        </div>
                      </td>
                      <td>
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            type="number"
                            id="amount{{ i }}"
                            class="md-textarea form-control"
                            mdbInput
                            [disabled]="!plan.isChecked"
                            [(ngModel)]="plan.patientResponsibility"
                            name="memberResponsibility{{ i }}"
                            style="text-align: right" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                *ngIf="(!plans || plans.length == 0) && !loadingData.plan"
                class="text-center p-4 border mt-3">
                <p>No Plan Group Available.</p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeplanModal()">
          Cancel
        </button>
        <button class="btn btn-secondary waves-light m-0" mdbWavesEffect (click)="submitPlan()">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
