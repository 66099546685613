import { createReducer, on } from '@ngrx/store';
import * as Actions from '../action';

export const initialState_todos = [];
export const initialState_questions = [];
export const initialState_messages = [];

// const _headerBreadcrumbsReducer = createReducer(initialState_breadCrumb,
//   on(addHeaderBreadcrumbs, state =>[]),
//   on(removeHeaderBreadcrumbs, state => ['abc']),
//   on(resetHeaderBreadcrumbs, state => []),
// );

export function tasksMessageReducer(state=initialState_messages, action: Actions.TaskActions) {
  switch (action.type) {
    case Actions.ADD_TASK_MESSAGE:
      return [...state, action.payload];
    case Actions.RESET_TASK_MESSAGE:
      return [];
    default:
      return state;
  }

}

export function tasksTodoReducer(state=initialState_todos, action: Actions.TaskActions) {
  switch (action.type) {
    case Actions.ADD_TASK_TODO:
      return [...state, action.payload];
    case Actions.RESET_TASK_TODO:
      return [];
    default:
      return state;
  }

}

export function tasksQuestionReducer(state=initialState_questions, action: Actions.TaskActions) {
  switch (action.type) {
    case Actions.ADD_TASK_QUESTION:
      return [...state,action.payload];
    case Actions.RESET_TASK_QUESTION:
      return [];
    default:
      return state;
  }

}