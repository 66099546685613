import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { EPISODE_WITH_CLAIMS_TABLE_DATA, MessageConstants, NO_DATA_IN_REPORT, REPORT_NAME_TYPES, KPI_VALUES, HORIZONTAL_BAR_OPTION, CHART_CUSTOM_COLORS, REPORT_NAME_CODES, MONTHS_NAME_FULL, REPORT_TAB_INDEX } from '../../../../constants';
import { ReportService } from '../../../../services';
import { DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility } from '../../../../utils';

@Component({
  selector: 'app-overall-claims',
  templateUrl: './overall-claims.component.html',
  styleUrls: ['./overall-claims.component.scss']
})
export class OverallClaimsComponent implements OnInit {

  cycleTimesForm: FormGroup;
  loading = {
    episodeWithClaimsTable: false,
    cycleTimes: false,
    detail: false,
  }
  expand = {
    cycleTimes: false,
  }
  MONTHS_NAME_FULL = MONTHS_NAME_FULL;
  episodeWithClaimsTableData = {
    data: [],
    excelName: '',
    tableTitle: '',
    tableHeaders: [],
    columnsWidth: [],
    dateType: '',
    date: {},
  };
  baseConfigs = {
    options: {},
    plugins: [],
    labels: [],
    dataset: [],
    chartType: '',
    actualData: null,
  };
  cycleTimesChart = this._holistaUtils.deepClone(this.baseConfigs);
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT);
  REPORT_NAME_TYPES = REPORT_NAME_TYPES;
  REPORT_NAME_CODES = REPORT_NAME_CODES;

  parsedClientClaimsFilterData: any;
  toShowReport: any[] = [];
  reportNameAndCodeList: any[] = [];
  detailList: any[] = [];
  yearOptions = {
    cycleTimes: [],
  };

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();
  @Input() set clientClaimsDashboardFilterData(data) {
    if (data && data.activeTabIndex === REPORT_TAB_INDEX.OVERALL_CLAIMS) {
      this.expand = {
        cycleTimes: false,
      };
      this.parsedClientClaimsFilterData = this._holistaUtils.deepClone(data);
      const { networkCode, startDate, endDate, toShowReport } = this.parsedClientClaimsFilterData;
      this.toShowReport = toShowReport && toShowReport.map((x) => x.reportCode);
      this.reportNameAndCodeList = toShowReport && toShowReport.map((x) => ({
        reportName: x.customReportName,
        reportCode: x.reportCode
      }));
      const params = {
        networkCode,
        startDate,
        endDate,
      };
      const additionalConditons = toShowReport.length && startDate && endDate;
      if (this.toShowReport.includes(REPORT_NAME_CODES.EPISODE_WITH_CLAIMS_TABLE) && additionalConditons) {
        this.getEpisodeWithClaimsTableList(params);
      }
      const year = +this._dateUtility.getYearFromDate(endDate);
      if (this.toShowReport.includes(REPORT_NAME_CODES.CYCLE_TIMES) && additionalConditons) {
        this.getCycleTimesReport({ networkCode, year });
      }
    }
  }
  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === REPORT_TAB_INDEX.OVERALL_CLAIMS) {
      const { networkName, startDate, endDate } = this.parsedClientClaimsFilterData;
      const reportDate = `${startDate} ${endDate ? `- ${endDate}` : ''}`;
      const tabsData = [];
      if (this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD) && this.episodeWithClaimsTableData.data.length) {
        const data = this._reportMappingUtils.episodeWithClaimsTableMapper(this.episodeWithClaimsTableData.data);
        tabsData.push({
          clientName: networkName,
          reportName: 'Episode (With Claims)',
          tabName: 'Episode (With Claims)',
          headers: data.header,
          data: data.body,
          reportDate,
          additionalHeaders: [
            'Category',
            'Sub Category',
            'Year',
            ...MONTHS_NAME_FULL.map((x) => ({
              name: x,
              colspan: 2
            }))
          ],
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.CYCLE_TIMES) && this.cycleTimesChart.labels.length) {
        const body = this._reportMappingUtils.cycleTimesMapper(this.cycleTimesChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Cycle Times',
          tabName: 'Cycle Times',
          headers: ['Reports', 'Average Days', 'KPI'],
          data: body,
          reportDate: `Period: ${this.cycleTimesForm.value.selectedYear}`,
        });
      }
      if (isDownloadPDF.type === 'pdf') {
        const downloadPDF = this._pdfUtility.convertToPDF(['ccd-overview-claims'], 'Client Claims Dashboard - Overall Claims', true, true);
        downloadPDF.finally(() => {
          this.downloadedPDF.emit(true);
        });
      }
      if (tabsData.length && isDownloadPDF.type === 'excel') {
        this._excelMultiTabUtility.exportToExcelMultiTab('Client Claims Dashboard - Overall Claims', tabsData).finally(() => {
          this.downloadedPDF.emit(true);
        })
      }
    }
  }

  constructor(
    private _messageConstants: MessageConstants,
    private _pdfUtility: PDFUtility,
    private _holistaUtils: HolistaUtils,
    private _dateUtility: DateUtility,
    private reportService: ReportService,
    private formBuilder: FormBuilder,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
    private _reportMappingUtils: ReportMappingUtility,
  ) { }

  ngOnInit(): void {
    this.setCycleTimesForm();
  }

  ngOnDestroy() {
    this.resetFlags();
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
  }

  resetFlags() {
    this.loading = {
      episodeWithClaimsTable: false,
      cycleTimes: false,
      detail: false,
    }
  }

  resetAllReports() {
    this.episodeWithClaimsTableData.data = [];
    this.cycleTimesChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  setCycleTimesForm() {
    this.cycleTimesForm = this.formBuilder.group({
      selectedYear: [null],
    });
  }

  getReport(code: string) {
    return this.reportNameAndCodeList.find(report => report.reportCode === code) || null;
  }

  getEpisodeWithClaimsTableList(params?) {
    this.loading.episodeWithClaimsTable = true;
    this.reportService.getEpisodeWithClaimsTableList(params)
      .pipe(finalize(() => { this.loading.episodeWithClaimsTable = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.length) {
          const { TABLE_HEADERS } = EPISODE_WITH_CLAIMS_TABLE_DATA;
          this.episodeWithClaimsTableData.tableHeaders = TABLE_HEADERS;
          this.episodeWithClaimsTableData.data = res;
        } else {
          this.episodeWithClaimsTableData.data = [];
        }
      }, error => {
        console.log("Error getting Episode (With Claims) Table", error);
      })
  }

  getCycleTimesReport(params?, isYearSelected = false) {
    this.loading.cycleTimes = true;
    this.reportService.getSummaryCycleTimes(params)
      .pipe(finalize(() => {
        this.loading.cycleTimes = false;
        const { startDate, endDate } = this.parsedClientClaimsFilterData;
        const startYear = +this._dateUtility.getYearFromDate(startDate);
        const endYear = +this._dateUtility.getYearFromDate(endDate);
        if (startYear && endYear) {
          this.yearOptions.cycleTimes = this._holistaUtils.getYearOptionList(startYear, endYear);
        }
        this.cycleTimesForm.controls['selectedYear'].setValue(isYearSelected ? params.year : endYear);
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.cycleTimesChart.actualData = res;
          res = {
            ...res,
            datasets: [{
              data: res.datasets[0].data,
              maxBarThickness: 30,
            }]
          };
          this.cycleTimesChart.chartType = 'horizontalBar';
          this.cycleTimesChart.dataset = res.datasets.map((x) => ({
            ...x,
            backgroundColor: CHART_CUSTOM_COLORS[1],
            borderColor: CHART_CUSTOM_COLORS[1],
            hoverBackgroundColor: CHART_CUSTOM_COLORS[1],
          }));
          this.cycleTimesChart.labels = res.labels;
          this.cycleTimesChart.options = HORIZONTAL_BAR_OPTION;
          this.cycleTimesChart.plugins = [{
            afterDatasetsDraw: function (chart) {
              const { ctx, scales } = chart;
              const xAxis = scales["x-axis-0"];
              const KPIS = [
                KPI_VALUES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY,
                KPI_VALUES.HOLISTA_PROCESSING_CYCLE_TIMES,
                KPI_VALUES.PAYOR_CYCLE_TIMES_COMPANY,
                KPI_VALUES.HOLISTA_PAYMENT_CYCLE_TIMES,
                KPI_VALUES.TOTAL_CYCLE_TIMES,
              ];
              const pixelKPIS = [
                xAxis.getPixelForValue(KPI_VALUES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY),
                xAxis.getPixelForValue(KPI_VALUES.HOLISTA_PROCESSING_CYCLE_TIMES),
                xAxis.getPixelForValue(KPI_VALUES.PAYOR_CYCLE_TIMES_COMPANY),
                xAxis.getPixelForValue(KPI_VALUES.HOLISTA_PAYMENT_CYCLE_TIMES),
                xAxis.getPixelForValue(KPI_VALUES.TOTAL_CYCLE_TIMES),
              ];
              const meta = chart.getDatasetMeta(0);
              if (!meta.hidden) {
                meta.data.map((metaData, index) => {
                  const barModel = metaData._model;
                  ctx.beginPath();
                  ctx.strokeStyle = 'gray';
                  ctx.lineWidth = 1.5;
                  ctx.moveTo(pixelKPIS[index], barModel.y - (barModel.height / 2));
                  ctx.lineTo(pixelKPIS[index], barModel.y + 15);
                  ctx.stroke();
                  ctx.font = '11px Arial';
                  ctx.textAlign = 'left';
                  ctx.textBaseline = 'middle';
                  ctx.fillText(`KPI: ${KPIS[index]}`, (pixelKPIS[index] + 8), barModel.y + 1);
                  ctx.restore();
                })
              }
            }
          }]
        } else {
          this.cycleTimesChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Summary Cycle Time", error);
      })
  }

  onViewToggle(reportName, expand = false) {
    // this.reportExpanded.emit(expand);  // Uncomment if download is not needed while expanding
    switch (reportName) {
      case REPORT_NAME_CODES.CYCLE_TIMES:
        this.expand.cycleTimes = expand;
        break;
      default:
        break;
    }
  }

  yearSelected(event, reportCode) {
    const params = {
      year: event.value,
      networkCode: this.parsedClientClaimsFilterData.networkCode,
    };
    switch (reportCode) {
      case REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY:
        this.getCycleTimesReport(params, true);
        break;
      default:
        break;
    }
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = (
      this.cycleTimesChart.labels.length ||
      this.episodeWithClaimsTableData.data.length
    );
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

}
