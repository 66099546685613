<div class="p-3">
  <div class="row mb-4">
    <div class="d-flex align-items-center" class="col-5">
      <h6 class="mb-0 font-weight-bold mr-2">Period Summary</h6>
      <span class="badge badge-sm bg-primary-dark text-white z-depth-0">
        Reporting Period
        <span> - {{ parsedTransactionReportFilterData.startDate }} - {{ parsedTransactionReportFilterData.endDate }} </span>
      </span>
    </div>

    <div class="col-7">
      <form [formGroup]="filterForm">
        <div class="row">
          <div class="col-3 pl-0">
            <app-select
              [options]="dateTypeList"
              [id]="'ap-report-date-type'"
              [control]="filterForm.controls['dateType']"
              [placeholder]="'Select date type'"
              [filterEnabled]="false"
              [inputClass]="{
                'disabled-options': isLoading.report
              }"
              (onSelected)="getAccountsPayableData()"
              (onDeselected)="getAccountsPayableData()"></app-select>
          </div>
          <div class="form-group col-3 pl-0 position-relative mb-0">
            <input
              type="text"
              [ngModel]="billingProviderSearchText | async"
              id="billingProvider"
              (ngModelChange)="billingProviderSearchText.next($event)"
              [ngModelOptions]="{standalone: true}"
              class="completer-input form-control mdb-autocomplete mb-0"
              [mdbAutoCompleter]="auto7"
              placeholder="Search provider" />
            <mdb-auto-completer
              class="position-absolute"
              style="top: -30px"
              #auto7="mdbAutoCompleter"
              textNoResults="No result found"
              [displayValue]="onDisplaySelectedProvider"
              [optionHeight]="100"
              (selected)="onProviderSelected($event)">
              <mdb-option
                *ngFor="let provider of billingProviderResults | async"
                [value]="provider">
                <div class="d-flex flex-column">
                  <strong>{{ provider.displayName | titlecase }}</strong>
                  <span>{{ provider.identificationCode }}</span>
                  <span>
                    located in {{ provider.city | titlecase }}{{ provider.state ? ',' : '' }}
                    {{ provider.state }}</span
                  >
                </div>
              </mdb-option>
            </mdb-auto-completer>
          </div>
          <div class="col-3 pl-0">
            <input
              type="text"
              id="transaction-number"
              class="form-control mb-0"
              (input)="onTransactionNumberChange($event)"
              (keyup.enter)="onTransactionNumberChange($event)"
              placeholder="Type payment mode trace no." />
          </div>
          <div class="col-3 pl-0">
            <app-select
              [options]="episodeTypeList"
              [id]="'ap-report-episode-type'"
              [control]="filterForm.controls['episodeType']"
              [placeholder]="'Select episode type'"
              [multiple]="true"
              [inputClass]="{
                'disabled-options': isLoading.report
              }"
              (onSelected)="getAccountsPayableData()"
              (onDeselected)="getAccountsPayableData()"></app-select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-loader [loader]="isLoading.report"></app-loader>
  <div class="mt-4" *ngIf="!isLoading.report">
    <app-table [report]="accountsPayableReport" [module]="'report'" tableHeight="800px">
      <tr class="bg-grey-lighten-2 sticky-bottom">
        <td class="p-1 pl-2 text-right" colspan="8">Provider Distribution</td>
        <td class="p-1 pr-2 text-right">{{ data.summary?.providerDistribution | currency }}</td>
      </tr>
    </app-table>
  </div>
</div>
