import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services';
import { Storage } from '../utils';
import { COMMUNICATIONS, COMMUNICATIONS_OTP, APICONSTANTS, ROLE_CODES } from '../constants';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private _storage: Storage
  ) {
  }

  getRedirected(path: string) {
    this.router.navigate([`/${path}`]);
    return true;
  } //redirects to specific path fter logging in if the user is forwareded to platform from external link

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const loggedInUser = this._storage.get('local', 'loggedInUser');
    if (loggedInUser && loggedInUser.user.roleCode === ROLE_CODES.REPORTING && state.url.includes(APICONSTANTS.DASHBOARD)) {
      this.router.navigate([APICONSTANTS.REPORT]);
    }
    if (this.auth.isAuthenticated()) {
      return true;
    } // logged in so return true
    if (this.auth.isOtpAuthenticated()) {
      return true;
    }
    if (state.url.includes(`/${COMMUNICATIONS}?token=`)) {
      const token = state.url.replace(`/${COMMUNICATIONS}?token=`, '');
      this._storage.set('session', 'encryptedMessageToken', token);
      return this.getRedirected(COMMUNICATIONS_OTP);
    }
    if (state.url.includes(`/${APICONSTANTS.REPORT}`)) {
      return this.getRedirected(APICONSTANTS.REPORT);
    }
    if (state.url) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } }); // not logged in so redirect to login page with the return url
    }
    this.router.navigate(['/login']);
  }
}
