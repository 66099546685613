<div class="d-flex justify-content-start align-items-start" *ngIf="message && message.id">
  <div class="icon-block">
    <i class="icofont-ui-text-chat icofont-lg skin-text-primary-dark"></i>
  </div>
  <mdb-checkbox
    class="pl-3 align-self-center"
    *ngIf="user && message.isActivated && message.messageTo == user.id"
    [checked]="message.status ? true : false"
    [disabled]="true"></mdb-checkbox>
  <div class="d-flex justify-content-between w-100 align-items-start">
    <span class="pl-2 pr-4">
      <p class="font-weight-400">
        {{ message.title }}
      </p>
      <p>
        {{ message.messages }}
      </p>
    </span>
    <div class="action-item position-relative">
      <div
        *ngIf="message.status && message.isActivated"
        class="chip chip-sm mb-0 ml-1 alert alert-info"
        [ngClass]="message.status ? message.status.replace(' ', '').toLowerCase() : ''">
        <span class="text-sm status-label">
          {{ message.status ? message.status?.toLowerCase() : '' }}
          {{ message.status == 'DUE' ? message.escalationDays : '' }}
        </span>
      </div>
      <span
        *ngIf="
          message.status != 'COMPLETED' &&
          !(
            message.episodeStatus == 'CLOSED' ||
            message.episodeStatus == 'COMPLETED' ||
            message.episodeStatus == 'CANCELLED'
          ) &&
          message.milestoneStatus !== 'IN PROGRESS' &&
          !message.triggerDatePassed
        ">
        <i
          class="icofont-ui-edit icofont-sm mr-2"
          #actionBtn
          (click)="editTaskHandler(message)"></i>
        <i
          class="icofont-ui-delete icofont-sm mr-2"
          #actionBtn
          (click)="deleteTaskHandler(message)"></i>
      </span>
    </div>
  </div>
</div>

<app-milestone-topic-delete
  [deleteData]="taskToBeDelete"
  (deletedData)="deleteTask($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>
