import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class EmployerGroupService {

    constructor(
        private http: HttpClientService
    ) { }

    getAll(query?) {
        return this.http.get(`${APICONSTANTS.EMPLOYER_GROUP}`, query)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    create(employerGroup) {
        return this.http.post(`${APICONSTANTS.EMPLOYER_GROUP}`, employerGroup)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    update(employerGroup) {
        return this.http.put(`${APICONSTANTS.EMPLOYER_GROUP}/${employerGroup.code}`, employerGroup)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    delete(id, paramType) {
        return this.http.remove(`${APICONSTANTS.EMPLOYER_GROUP}/${id}?paramType=${paramType}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }
}