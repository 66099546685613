import { Injectable } from "@angular/core";

@Injectable()

export class ScrollTo {

    public ScrollTo(id, placement, transition): any {
        document.getElementById(id).scrollIntoView({
            block: placement,
            behavior: transition
        });
    }
}