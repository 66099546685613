import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccessType, NameInitials, Storage, EmailUtility, HolistaUtils } from '../../../utils';
import { MESSAGE_TEXT_EDITOR, MESSAGE_SENT_SUCCESS } from '../../../constants';
import { CommunicationService, ToasterService } from '../../../services';
import { INBOX, RE } from '../../../constants';
import { saveAs } from 'file-saver';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.scss']
})
export class MessageViewComponent implements OnInit {
  @Input() messageThread: any;
  @Input() showReply: boolean;
  @Output() messageReplied: EventEmitter<any> = new EventEmitter();

  name: string = 'Jessica Mye';
  initials: string;
  accordionState: string;
  selectedMessageIndex: number;
  replyForm: FormGroup;
  editorConfig = MESSAGE_TEXT_EDITOR;
  isLoading: boolean = false;
  user: any;
  isSubmitted: boolean = false;
  messageReceipents: Array<string> = [];
  readonly INBOX: string = INBOX;
  readonly RE: string = RE;
  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput>;
  shouldScroll: boolean = false;
  otpToken: string;

  @ViewChild('replyModal', { static: true }) public replyModal;
  @ViewChild('scrollWrapper', { static: false }) private scrollWrapper;

  constructor(
    private _nameInitials: NameInitials,
    private _formBuilder: FormBuilder,
    private _storage: Storage,
    private _communicationService: CommunicationService,
    private _toastr: ToasterService,
    private _holistaUtility: HolistaUtils,
    private _emailUtility: EmailUtility,
    private _utilityAccess: AccessType
  ) {
    this.setReplyForm();
  }

  ngOnInit() {
    this.otpToken = this._storage.get('session', 'otpToken');
    this.user = this.otpToken ? this._storage.get('session', 'user') : this._storage.get('local', 'loggedInUser', 'user');
  }

  ngOnChanges() {
    this.messageThread = this.messageThread.length &&
      this.messageThread.map((x: object) => ({ ...x, initials: x['sender'].name ? this._nameInitials.getNameInitials(x['sender'].name) : this._nameInitials.getNameInitials(x['sender'].email) }));
  }

  /**
   * if accordion expands or collapses
   */
  onAnimationStateChange(event: any, index: number) {
    this.selectedMessageIndex = index;
    this.accordionState = event.state;
  }

  setReplyForm() {
    this.replyForm = this._formBuilder.group({
      to: [null, [Validators.required, this._emailUtility.validateEmail]],
      subject: [null],
      body: [null, Validators.required]
    });
  }

  /**
   * opens modal with mapped list of required receipents to reply
   */
  openReplyModal(message, isReplyAll: boolean) {
    if (this.otpToken || this._utilityAccess.searchAccess('COMMS', 'isEditable')) {
      const receivers = [];
      receivers.push(message.sender.email);
      message.receivers.to.forEach(({ email }) => receivers.push(email));
      this.messageReceipents = receivers.filter(email => email !== this.user.email);
      isReplyAll ? (
        this.replyForm.controls['to'].setValue(this.messageReceipents.join(', '))
      ) : (
        message.sender.email === this.user.email ? this.replyForm.controls['to'].setValue(this.messageReceipents.join(', ')) :
          this.replyForm.controls['to'].setValue(message.sender.email)
      );
      message.subject && this.replyForm.controls['subject'].setValue(RE + message.subject);
      this.replyModal.show();
    }
  }

  closeReplyModal() {
    this.replyModal.hide();
    this.messageReceipents.length = 0;
    this.replyForm.reset();
    this.isSubmitted = false;
  }

  onSendMessage() {
    this.isSubmitted = true;
    if (this.replyForm.valid) {
      this.isLoading = true;
      const receiverEmails = this.replyForm.value.to.split(/[ ,]+/);
      const receiverList = [];
      receiverEmails.forEach((email: string) => {
        const data = { type: 'Client', email: email.toLowerCase() }
        receiverList.push(data);
      });
      const uniqueReceiverList = this._holistaUtility.getUniqueList(receiverList, 'email');
      const lastMessageOfThread = this.messageThread[this.messageThread.length - 1];
      const messageData = {
        sender: {
          type: "Client",
          name: this.user && this.user.firstName || this.user.lastName ? `${this.user.firstName || ''} ${this.user.lastName || ''}` : null,
          email: this.user && this.user.email
        },
        receivers: {
          to: uniqueReceiverList
        },
        subject: this.replyForm.value.subject.includes(RE) ? this.replyForm.value.subject.replace(RE, '') : this.replyForm.value.subject,
        body: this.replyForm.value.body,
        attachments: this.files.map((x) => x.nativeFile ? x.nativeFile : x),
        messageId: !lastMessageOfThread.messageId ? lastMessageOfThread._id : lastMessageOfThread.messageId
      }
      this._communicationService.sendMessage(messageData).subscribe(res => {
        this.isLoading = false;
        this.messageReplied.emit(messageData.messageId);
        this._toastr.displaySuccess(MESSAGE_SENT_SUCCESS);
        this.closeReplyModal();
      },
        error => {
          this.isLoading = false;
          console.log('error sending message', error);
          this._toastr.displayError(error.error.message);
        })
    }
  }

  downloadAttachment(file) {
    saveAs(file.path, file.name);
  }

  scrollToBottom() {
    this.shouldScroll = true;
    try {
      this.scrollWrapper.nativeElement.scrollTop = this.scrollWrapper.nativeElement.scrollHeight;
    } catch (err) { }
  }

  onUploadOutput(output: UploadOutput | any): void {
    if (output.type === 'addedToQueue') {
      this.files.push(output.file); // add file to array when added
      this.scrollToBottom();
    }
  }

  onRemoveFile(event) {
    this.files = this.files.filter(x => x.id !== event.id);
  }
}
