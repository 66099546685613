<ng-template #showLoader>
  <div class="mt-3">
    <app-loader [loader]="true"></app-loader>
  </div>
</ng-template>
<div
  class="my-3 col-md-12"
  id="service-category-utilization"
  *ngIf="toShowReport.length; else showLoader">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.BUNDLE_COMPONENT_UTILIZATION) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex align-items-center">
          <form [formGroup]="serviceCategoryUtilizationForm">
            <app-select
              [options]="marketList"
              [id]="'bcu-market'"
              [style]="'width: 300px'"
              [placeholder]="'Select market'"
              [allowClear]="true"
              [disabled]="loading.marketList"
              [control]="serviceCategoryUtilizationForm.controls['marketCode']"
              (onSelected)="onMarketSelected($event)"
              (onDeselected)="onClear('market')"></app-select>
            <app-select
              [options]="episodeCategoryList"
              [id]="'bcu-episodes'"
              [style]="'width: 300px'"
              [class]="'ml-3'"
              [placeholder]="'Select category'"
              [allowClear]="true"
              [control]="serviceCategoryUtilizationForm.controls['episodeCategoryId']"
              (onSelected)="onEpisodeCategorySelected($event)"
              (onDeselected)="onClear('category')"></app-select>
          </form>
          <span class="link pointer-cursor ml-3" (click)="showfilterModal()">
            <i class="fas fa-cog"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        *ngIf="showUtilizationChart()"
        baseChart
        [datasets]="serviceCategoryUtilizationChart.dataset"
        [options]="serviceCategoryUtilizationChart.options"
        [labels]="serviceCategoryUtilizationChart.labels"
        [chartType]="'bar'">
      </canvas>
      <p *ngIf="dataNotAvailable()">
        {{ noDataMessage.value }}
      </p>
      <p *ngIf="isServiceCategoryNotSelected()">Please select a Service Category</p>
      <div
        class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
        *ngIf="!serviceCategoryUtilizationForm.get('episodeCategoryId').value">
        <div class="text-center">
          <i class="icofont-file-spreadsheet icofont-4x text-secondary"></i>
          <p class="mt-2">Please select Category</p>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.serviceCategoryUtilizationChart">
        <app-loader [loader]="loading.serviceCategoryUtilizationChart"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>

<!-- Additional Filter Modal Begins -->
<div
  mdbModal
  #filterModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Service Categories</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12 p-0">
          <div class="form-group position-relative">
            <label>Category</label>
            <input
              type="text"
              class="completer-input form-control mdb-autocomplete"
              [ngModel]="categorySearchText | async"
              (ngModelChange)="categorySearchText.next($event)"
              [ngModelOptions]="{standalone: true}"
              [mdbAutoCompleter]="auto"
              [readonly]="loading.categories"
              placeholder="Search"
              class="completer-input form-control mdb-autocomplete mb-0" />
            <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="No results found">
              <mdb-option
                *ngFor="let option of categoryResults | async"
                [value]="option.name"
                (click)="onCategorySelected(option)">
                {{ option.name }}
              </mdb-option>
            </mdb-auto-completer>
          </div>
        </div>
        <app-loader [loader]="loading.serviceCategories"> </app-loader>
        <div
          class="mt-4 px-2"
          *ngIf="!loading.serviceCategories"
          class="scrollbar scrollbar-primary thin overflow-auto"
          style="max-height: 400px">
          <div *ngFor="let category of serviceCategories">
            <mdb-checkbox
              [checked]="category.isActive"
              (change)="onServiceCategoryChecked($event, category)">
              {{ category.name }}
            </mdb-checkbox>
            <hr />
          </div>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="applyFilter()"
          [disabled]="
            loading.serviceCategories || !serviceCategories.length || loading.saveServiceCategories
          ">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading.saveServiceCategories"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Additional Filter Modal Ends -->
