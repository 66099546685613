<h6 class="mb-0 text-muted position-sticky header">
  <b>{{ messageThread[0]?.subject }}</b>
</h6>
<mdb-accordion [multiple]="false" aria-multiselectable="false">
  <mdb-accordion-item
    [collapsed]="last ? false : true"
    *ngFor="let message of messageThread; let i = index; let last = last">
    <mdb-accordion-item-head [indicator]="false">
      <table>
        <tr>
          <td>
            <div class="thread-column-start">
              <div class="avatar text-center user-initials">
                <b>{{ message?.initials }}</b>
              </div>
            </div>
          </td>
          <td class="ml-3 w-100">
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center w-75">
                  <div>
                    <div class="d-flex align-items-end mb-1">
                      <h6 class="mb-0 text-muted">
                        <b>{{
                          message?.sender?.name
                            ? message?.sender?.name
                            : (message?.sender?.email.split('@')[0] | titlecase)
                        }}</b>
                      </h6>
                      &nbsp;
                      <small class="text-muted">&lt;{{ message?.sender?.email }}&gt;</small>
                    </div>
                    <div
                      *ngIf="selectedMessageIndex === i && accordionState === 'expanded'"
                      [mdbPopover]="messageRecipient"
                      triggers="hover"
                      placement="right"
                      class="text-muted d-inline">
                      <small (click)="$event.stopPropagation()">to</small>
                      <small
                        (click)="$event.stopPropagation()"
                        *ngFor="let receiver of message?.receivers?.to; last as isLast">
                        {{ user['email'] === receiver.email ? 'me' : receiver.email.split('@')[0]
                        }}<span *ngIf="!isLast">, </span></small
                      >
                      <i class="fas fa-caret-down ml-1" (click)="$event.stopPropagation()"></i>
                    </div>
                    <div *ngIf="selectedMessageIndex !== i || accordionState !== 'expanded'">
                      <p class="m-0">
                        <small class="collapsed-message-show" [innerHTML]="message?.body"></small>
                      </p>
                    </div>
                    <ng-template #messageRecipient>
                      <div class="popover-receipent p-1 pb-2">
                        <table>
                          <tr>
                            <td class="text-right"><span class="text-muted">from:</span></td>
                            <td class="pl-2">
                              <span
                                ><b>{{ message?.sender?.name }}&nbsp;</b></span
                              >
                              <span class="text-muted">&lt;{{ message?.sender?.email }}&gt;</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="d-flex align-items-start justify-content-end">
                              <span class="text-muted">to:</span>
                            </td>
                            <td class="pl-2">
                              <span *ngFor="let receiver of message.receivers.to; index as i"
                                >{{ receiver.email }}<br
                              /></span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right" class="text-muted"><span>date:</span></td>
                            <td class="pl-2">
                              <span>{{ message?.createdAt | date: 'EE, MMM d, y h:mm a' }}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </ng-template>
                  </div>
                </div>
                <p>
                  <small>{{ message?.extendedDate }}</small>
                </p>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </mdb-accordion-item-head>
    <mdb-accordion-item-body
      (animationStateChange)="onAnimationStateChange($event, i)"
      class="pt-2">
      <table>
        <tr *ngIf="selectedMessageIndex === i && accordionState === 'expanded'">
          <td>
            <div class="thread-column-start"></div>
          </td>
          <td>
            <b [innerHTML]="message?.body"></b>
          </td>
        </tr>
        <tr
          *ngIf="
            selectedMessageIndex === i &&
            accordionState === 'expanded' &&
            message?.attachments &&
            message?.attachments.length
          ">
          <td>
            <div class="thread-column-start"></div>
          </td>
          <td>
            <p class="pb-2 pt-5">
              <b
                >{{ message?.attachments.length }}
                {{ message?.attachments.length > 1 ? 'Attachments' : 'Attachment' }}</b
              >
            </p>
          </td>
        </tr>
        <tr *ngIf="selectedMessageIndex === i && accordionState === 'expanded'">
          <td>
            <div class="thread-column-start"></div>
          </td>
          <td class="w-100">
            <div class="container m-0 p-2">
              <div class="row">
                <div *ngFor="let file of message?.attachments" class="col-3 p-0 px-1 mb-2">
                  <div
                    class="file-wrapper d-flex justify-content-between p-2 pointer-cursor text-secondary">
                    <div
                      class="file-name-wrapper pr-1"
                      mdbTooltip="{{ file.name }}"
                      placement="top"
                      (click)="downloadAttachment(file)">
                      <mdb-icon far icon="file-alt" class="mr-1"></mdb-icon
                      ><small class="mr-1">{{ file.name }}</small>
                    </div>
                    <i (click)="downloadAttachment(file)" class="icofont-download"></i>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="showReply">
          <td class="pt-5">
            <div class="thread-column-start"></div>
          </td>
          <td class="pt-5">
            <button
              type="button"
              class="btn btn-light border ml-0"
              (click)="openReplyModal(message, false)">
              <p class="m-0"><i class="fas fa-reply"></i>&nbsp; Reply</p>
            </button>
            <button
              type="button"
              class="btn btn-light border"
              (click)="openReplyModal(message, true)">
              <p class="m-0"><i class="fas fa-reply-all"></i>&nbsp; Reply All</p>
            </button>
          </td>
        </tr>
      </table>
    </mdb-accordion-item-body>
  </mdb-accordion-item>
</mdb-accordion>

<div
  mdbModal
  #replyModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Reply</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeReplyModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div
        #scrollWrapper
        [scrollTop]="shouldScroll && scrollWrapper.scrollHeight"
        style="scroll-behavior: smooth"
        class="modal-body overflow-auto scrollbar scrollbar-primary thin pt-2"
        [ngStyle]="{
          height: files.length > 0 ? '570px' : '540px'
        }">
        <form [formGroup]="replyForm">
          <div class="col-12 p-0">
            <div class="form-group">
              <label>To *</label>
              <input
                type="text"
                class="form-control"
                formControlName="to"
                [ngClass]="{'is-invalid': isSubmitted && replyForm.controls['to'].invalid}" />
            </div>
            <div class="form-group">
              <label>Subject</label>
              <input type="text" class="form-control" formControlName="subject" disabled />
            </div>
            <div class="form-group">
              <label>Message *</label>
              <div
                class="text-editor"
                [class.invalid]="isSubmitted && replyForm.controls['body'].invalid">
                <angular-editor [config]="editorConfig" formControlName="body"></angular-editor>
              </div>
            </div>
            <div class="form-group">
              <div class="file-field md-form m-0">
                <div size="sm">
                  <small class="pt-1 text-secondary"
                    ><i class="icofont-paper-clip"></i> Attach Files</small
                  >
                  <input
                    [ngStyle]="{width: '84px'}"
                    type="file"
                    multiple
                    mdbFileSelect
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput" />
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div *ngFor="let file of files" class="col-md-4 p-0 px-1">
                  <div class="file-wrapper d-flex justify-content-between mb-2 p-2">
                    <div
                      class="file-name-wrapper pr-1"
                      mdbTooltip="{{ file.name }}"
                      placement="top">
                      <mdb-icon far icon="file-alt" class="text-muted mr-1"></mdb-icon
                      ><small class="mr-1">{{ file.name }}</small>
                    </div>
                    <mdb-icon
                      fas
                      icon="times"
                      class="pointer-cursor text-muted"
                      (click)="onRemoveFile(file)"></mdb-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer d-flex flex-end border-top-0">
        <button
          class="btn btn-secondary waves-light m-0"
          [class.disabled]="isLoading"
          mdbWavesEffect
          (click)="onSendMessage()">
          <span
            *ngIf="isLoading"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"></span>
          Send
        </button>
      </div>
    </div>
  </div>
</div>
