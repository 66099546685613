
import { finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CONSENTED_PATIENTS_COMMENTS_TABLE_DATA, MessageConstants, NO_DATA_IN_REPORT, REPORT_NAME_TYPES } from '../../../../constants';
import { ReportService } from '../../../../services';
import { DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility } from '../../../../utils';

@Component({
  selector: 'app-consented-patient-comments',
  templateUrl: './consented-patient-comments.component.html',
  styleUrls: ['./consented-patient-comments.component.scss']
})
export class ConsentedPatientCommentsComponent implements OnInit {
  overviewFilterForm: FormGroup;
  loading = {
    consentedPatientsComments: false,
    categoryList: false,
  };
  consentedPatientsCommentsTableData = {
    data: [],
    excelName: '',
    tableTitle: '',
    tableHeaders: [],
    columnsWidth: [],
    dateType: '',
    date: {},
  };
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT);
  reportingPeriod = {
    startDate: null,
    endDate: null,
  };
  REPORT_NAME_TYPES = REPORT_NAME_TYPES;

  categoryList: any[] = [];
  toShowReport: any[] = [];
  isIndividual: boolean = false;
  parsedValueManagementFilterData: any;

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();

  @Input() set valueManagementFilterData(data) {
    if (data && data.activeTabIndex === 2) {
      this.parsedValueManagementFilterData = this._holistaUtils.deepClone(data);
      const { networkCode, clientCode, startDate, endDate } = this.parsedValueManagementFilterData;
      if (startDate && endDate) {
        this.reportingPeriod = {
          startDate,
          endDate,
        }
      }
      if (!this.categoryList.length) {
        this.getCategoryList({ networkCode });
      }
      let isIndividual = false;
      let categoryName = '';
      let categoryId = '';
      if (this.overviewFilterForm) {
        categoryName = this.overviewFilterForm.get('categoryName').value;
        categoryId = this.overviewFilterForm.get('categoryId').value;
        isIndividual = categoryName !== 'All';
        this.isIndividual = isIndividual;
      } else {
        this.setOverviewFilterForm();
      }
      if (categoryId) {
        const params = { clientCode, startDate, endDate, categoryId: categoryId === 'all' ? 0 : categoryId };
        const additionalConditons = startDate && endDate;
        if (clientCode && additionalConditons) {
          this.getConsentedPatientsComments(params, isIndividual);
        }
      }
    }
  }

  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === 2) {
      var elementToHide = document.getElementById('hide-show-label');
      const { clientName, startDate, endDate } = this.parsedValueManagementFilterData;
      const reportDate = `${startDate} ${endDate ? `- ${endDate}` : ''}`;
      const tabsData = [];
      const resetDownload = () => {
        this.downloadedPDF.emit(true);
      }
      if (this.consentedPatientsCommentsTableData.data.length) {
        const { TABLE_HEADERS } = CONSENTED_PATIENTS_COMMENTS_TABLE_DATA;
        const consentedPatientsCommentsSheet = this._reportMappingUtils.consentedPatientsCommentsMapper(this.consentedPatientsCommentsTableData.data);
        tabsData.push({
          clientName,
          reportName: 'Consented Patients: Comments',
          tabName: 'Consented Patients Comments',
          headers: TABLE_HEADERS.map((header) => header.label),
          data: consentedPatientsCommentsSheet,
          reportDate,
        });
      }
      if (tabsData.length && isDownloadPDF.type === 'excel') {
        this._excelMultiTabUtility.exportToExcelMultiTab('Value Management Dashboard - Consented Patients Comments', tabsData).finally(() => {
          resetDownload();
        })
      }
      if (isDownloadPDF.type === 'pdf') {
        elementToHide.style.display = 'none';
        const downloadPDF = this._pdfUtility.convertToPDF(['consented-patients-comments'], 'Value-Management-Dashboard Consented Patients Comments', true, true);
        downloadPDF.finally(() => {
          resetDownload();
        });
      }
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private _holistaUtils: HolistaUtils,
    private _dateUtility: DateUtility,
    private _messageConstants: MessageConstants,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
    private _pdfUtility: PDFUtility,
    private _reportMappingUtils: ReportMappingUtility,
  ) {
    this.setOverviewFilterForm();
  }

  ngOnInit(): void {
    this.setOverviewFilterForm();
  }

  resetFlags() {
    this.loading = {
      consentedPatientsComments: false,
      categoryList: false,
    };
    this.consentedPatientsCommentsTableData.data = [];
  }


  ngOnDestroy() {
    this.resetFlags();
    this.downloadButtonEnabled.emit(false);
  }

  getCategoryList(params?) {
    this.loading.categoryList = true;
    this.reportService.getCategoryList(params)
      .pipe(finalize(() => {
        this.loading.categoryList = false;
        this.categoryList.push({ id: 'all', label: "All", value: "all" });
      }))
      .subscribe((res) => {
        const categoryList = res.map((x) => {
          return {
            ...x,
            label: x.name,
            value: x.name,
          }
        });
        this.categoryList = categoryList;
      }, error => {
        console.log("Error getting Category List", error);
      })
  }

  setOverviewFilterForm() {
    this.overviewFilterForm = this.formBuilder.group({
      categoryId: ['all'],
      categoryName: ['All'],
    });
  }

  categorySelected(category) {
    const isCategoryIndividual = category.id !== 'all';
    this.isIndividual = isCategoryIndividual;
    this.overviewFilterForm.controls['categoryId'].setValue(category.id);
    this.overviewFilterForm.controls['categoryName'].setValue(category.label);
    const { clientCode, startDate, endDate } = this.parsedValueManagementFilterData;
    const params = { clientCode, startDate, endDate, categoryId: category.id === 'all' ? 0 : category.id };
    const additionalConditons = startDate && endDate;
    if (clientCode && additionalConditons) {
      this.getConsentedPatientsComments(params, isCategoryIndividual);
    }
  }

  getConsentedPatientsComments(params, isIndividual?) {
    this.loading.consentedPatientsComments = true;
    this.reportService.getConsentedPatientsComments(params)
      .pipe(finalize(() => { this.loading.consentedPatientsComments = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.length) {
          const { TABLE_HEADERS, COLUMNS_WIDTH } = CONSENTED_PATIENTS_COMMENTS_TABLE_DATA;
          this.consentedPatientsCommentsTableData.tableHeaders = TABLE_HEADERS;
          this.consentedPatientsCommentsTableData.columnsWidth = COLUMNS_WIDTH;
          this.consentedPatientsCommentsTableData.data = res.map((x) =>
          ({
            ...x,
            answerDate: this._dateUtility.getFormattedDate(x.answerDate),
            fullName: `${x.lastName}, ${x.firstName}`,
          }));
        } else {
          this.consentedPatientsCommentsTableData.data = [];
        }
      }, error => {
        console.log("Error getting Consented Patients Comments", error);
      })
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = (
      this.consentedPatientsCommentsTableData.data.length
    );
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

}
