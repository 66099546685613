import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class RequestEocService {

  constructor(private http: HttpClientService) { }
  updateStatus(status, id) {
    return this.http.put(`${APICONSTANTS.REQUEST_EOC}/${id}`, status).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  getEocRequestMembers(body) {
    return this.http.post(`${APICONSTANTS.REQUEST_EOC}/eoc`, body).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getEocRequestMemberByQuery(query) {
    return this.http.get(`${APICONSTANTS.REQUEST_EOC}`,  query).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
}
