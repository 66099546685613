import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class CacheService {
    constructor() { }
    
    cache = new Map();
    expiry: number = 15000;

    get(url: string) {
        let result = this.cache.get(url);
        if (result) {
            let response = result.response;
            let setDate = result.date;
            return Date.now() - setDate.getTime() > this.expiry ? this.delete(url) : response;
        }
        else { return null; }
    }

    set(url: string, response, date) {
        this.cache.set(url, { response: response, date: date });
    }

    delete(url: string) {
        this.cache.delete(url);
        return null;
    }
}