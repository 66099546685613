import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { CHART_CUSTOM_COLORS, KPI_VALUES, LINE_CHART_DATA_CONFIG, LINE_CHART_OPTIONS, MONTHS_NAME_FULL, MessageConstants, NO_DATA_IN_REPORT, PURCHASER_CODES, REPORT_NAME_CODES, REPORT_NAME_TYPES, REPORT_TAB_INDEX } from '../../../../constants';
import { ClientService, ReportService } from '../../../../services';
import { ChartUtils, DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility } from '../../../../utils';

@Component({
  selector: 'app-payor-cycle-time',
  templateUrl: './payor-cycle-time.component.html',
  styleUrls: ['./payor-cycle-time.component.scss']
})
export class PayorCycleTimeComponent implements OnInit {

  payorCycleTimesCompanyForm: FormGroup;
  payorCycleTimesPayorForm: FormGroup;
  loading = {
    payorCycleTimesCompany: false,
    payorCycleTimesPayor: false,
    payorCycleTimesPayorChart: false,
    payorIdentifierList: false,
  }
  expand = {
    payorCycleTimesCompany: false,
    payorCycleTimesPayor: false,
    payorCycleTimesPayorChart: false,
  }
  baseConfigs = {
    options: {},
    plugins: [],
    labels: [],
    dataset: [],
    chartType: '',
    actualData: null,
  };
  payorCycleTimesByCompanyChart = this._holistaUtils.deepClone(this.baseConfigs);
  payorCycleTimesByPayorChart = this._holistaUtils.deepClone(this.baseConfigs);
  payorCycleTimesPayorChart = this._holistaUtils.deepClone(this.baseConfigs);
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT);
  REPORT_NAME_TYPES = REPORT_NAME_TYPES;
  REPORT_NAME_CODES = REPORT_NAME_CODES;

  parsedClientClaimsFilterData: any;
  toShowReport: any[] = [];
  reportNameAndCodeList: any[] = [];
  payorIdentifierList: any[] = [];
  yearOptions = {
    payorCycleTimesCompany: [],
    payorCycleTimesPayor: [],
  };

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();

  @Input() set clientClaimsDashboardFilterData(data) {
    if (data && data.activeTabIndex === REPORT_TAB_INDEX.PAYOR_CYCLE_TIME_DETAIL) {
      let payorIdentifier = '';
      if (this.payorCycleTimesPayorForm) {
        payorIdentifier = this.payorCycleTimesPayorForm.get('payorIdentifier').value;
      } else {
        this.setPayorCycleTimesPayorForm();
      }
      this.expand = {
        payorCycleTimesCompany: false,
        payorCycleTimesPayor: false,
        payorCycleTimesPayorChart: false,
      };
      this.parsedClientClaimsFilterData = this._holistaUtils.deepClone(data);
      const { networkCode, startDate, endDate, toShowReport } = this.parsedClientClaimsFilterData;
      this.toShowReport = toShowReport && toShowReport.map((x) => x.reportCode);
      this.reportNameAndCodeList = toShowReport && toShowReport.map((x) => ({
        reportName: x.customReportName,
        reportCode: x.reportCode
      }));
      if (this.payorIdentifierList.length === 0) {
        this.getPayorIdentifierList(networkCode);
      }
      const params = {
        networkCode,
        startDate,
        endDate,
      };
      const year = +this._dateUtility.getYearFromDate(endDate);
      const additionalConditons = toShowReport.length && startDate && endDate;
      if (this.toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR_CHART) && additionalConditons) {
        this.getPayorCycleTimesPayorChart(params);
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY) && networkCode && year) {
        this.getPayerCycleTimesCompany({ networkCode, year });
      }
      if (payorIdentifier && this.toShowReport.includes(REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR) && additionalConditons) {
        this.getPayerCycleTimesPayer(
          {
            networkCode,
            year,
            payorIdentifier,
          }
        );
      }
    }
  }
  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === REPORT_TAB_INDEX.PAYOR_CYCLE_TIME_DETAIL) {
      const { networkName, startDate, endDate } = this.parsedClientClaimsFilterData;
      const reportDate = `${startDate} ${endDate ? `- ${endDate}` : ''}`;
      const tabsData = [];
      if (this.toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART) && this.payorCycleTimesByCompanyChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.payorCycleTimesByCompanyChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Payor Cycle Times - Company',
          tabName: 'Payor Cycle Times - Company',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.payorCycleTimesCompanyForm.value.selectedYear}`,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART) && this.payorCycleTimesByPayorChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.payorCycleTimesByPayorChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Payor Cycle Times - Payor',
          tabName: 'Payor Cycle Times - Payor',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.payorCycleTimesPayorForm.value.selectedYear}`,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART) && this.payorCycleTimesPayorChart.labels.length) {
        const body = this._reportMappingUtils.triggerClaimCycleTimesMapper(this.payorCycleTimesPayorChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Payor Cycle Times - (Payor Chart)',
          tabName: 'Payor Cycle Times - (Payor Chart)',
          headers: ['Payor', 'Average Days', 'Goal'],
          data: body,
          reportDate,
        });
      }
      if (isDownloadPDF.type === 'pdf') {
        const downloadPDF = this._pdfUtility.convertToPDF(['payor-cycle-time-detail'], 'Client Claims Dashboard - Payor Cycle Time Detail', true, true);
        downloadPDF.finally(() => {
          this.downloadedPDF.emit(true);
        });
      }
      if (tabsData.length && isDownloadPDF.type === 'excel') {
        this._excelMultiTabUtility.exportToExcelMultiTab('Client Claims Dashboard - Payor Cycle Time Detail', tabsData).finally(() => {
          this.downloadedPDF.emit(true);
        })
      }
    }
  }

  constructor(
    private _messageConstants: MessageConstants,
    private _pdfUtility: PDFUtility,
    private _dateUtility: DateUtility,
    private _holistaUtils: HolistaUtils,
    private _chartUtils: ChartUtils,
    private reportService: ReportService,
    private formBuilder: FormBuilder,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
    private _reportMappingUtils: ReportMappingUtility,
  ) { }

  ngOnInit(): void {
    this.setPayorCycleTimesCompanyForm();
    this.setPayorCycleTimesPayorForm();
  }

  ngOnDestroy() {
    this.resetFlags();
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
  }

  resetFlags() {
    this.loading = {
      payorCycleTimesCompany: false,
      payorCycleTimesPayor: false,
      payorCycleTimesPayorChart: false,
      payorIdentifierList: false,
    }
  }

  resetAllReports() {
    this.payorCycleTimesByCompanyChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.payorCycleTimesByPayorChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.payorCycleTimesPayorChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  setPayorCycleTimesCompanyForm() {
    this.payorCycleTimesCompanyForm = this.formBuilder.group({
      selectedYear: [null],
    });
  }

  getReport(code: string) {
    return this.reportNameAndCodeList.find(report => report.reportCode === code) || null;
  }

  setPayorCycleTimesPayorForm() {
    this.payorCycleTimesPayorForm = this.formBuilder.group({
      payorIdentifier: [null],
      selectedYear: [null],
    });
  }

  getPayerCycleTimesCompany(params?, isYearSelected = false) {
    this.loading.payorCycleTimesCompany = true;
    this.reportService.getPayorCycleTimesCompany(params)
      .pipe(finalize(() => {
        this.loading.payorCycleTimesCompany = false;
        const { startDate, endDate } = this.parsedClientClaimsFilterData;
        const startYear = +this._dateUtility.getYearFromDate(startDate);
        const endYear = +this._dateUtility.getYearFromDate(endDate);
        if (startYear && endYear) {
          this.yearOptions.payorCycleTimesCompany = this._holistaUtils.getYearOptionList(startYear, endYear);
        }
        this.payorCycleTimesCompanyForm.controls['selectedYear'].setValue(isYearSelected ? params.year : endYear);
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.payorCycleTimesByCompanyChart.actualData = res;
          this.yearOptions.payorCycleTimesCompany = [];
          res = {
            ...res,
            datasets: res.dataSets.map((dataset) => {
              return {
                ...LINE_CHART_DATA_CONFIG,
                label: dataset.label,
                data: dataset.datas,
              }
            })
          };
          this.payorCycleTimesByCompanyChart.chartType = 'line';
          this.payorCycleTimesByCompanyChart.dataset = res.datasets;
          this.payorCycleTimesByCompanyChart.labels = res.labels;
          this.payorCycleTimesByCompanyChart.options = LINE_CHART_OPTIONS;
          this.payorCycleTimesByCompanyChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.PAYOR_CYCLE_TIMES_COMPANY);
        } else {
          this.payorCycleTimesByCompanyChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Payor Cycle Times - Company", error);
      })
  }

  getPayerCycleTimesPayer(params?, isYearSelected = false) {
    this.loading.payorCycleTimesPayor = true;
    this.reportService.getPayorCycleTimesPayor(params)
      .pipe(finalize(() => {
        this.loading.payorCycleTimesPayor = false;
        const { startDate, endDate } = this.parsedClientClaimsFilterData;
        const startYear = +this._dateUtility.getYearFromDate(startDate);
        const endYear = +this._dateUtility.getYearFromDate(endDate);
        if (startYear && endYear) {
          this.yearOptions.payorCycleTimesPayor = this._holistaUtils.getYearOptionList(startYear, endYear);
        }
        this.payorCycleTimesPayorForm.controls['selectedYear'].setValue(isYearSelected ? params.year : endYear);
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.payorCycleTimesByPayorChart.actualData = res;
          this.yearOptions.payorCycleTimesPayor = [];
          res = {
            ...res,
            datasets: res.dataSets.map((dataset) => {
              return {
                ...LINE_CHART_DATA_CONFIG,
                label: dataset.label,
                data: dataset.datas,
              }
            }).filter((x) => x.label === 'Average Days'),
          };
          this.payorCycleTimesByPayorChart.chartType = 'line';
          this.payorCycleTimesByPayorChart.dataset = res.datasets.map(dataset => ({ ...dataset, label: params.year }));
          this.payorCycleTimesByPayorChart.labels = res.labels;
          this.payorCycleTimesByPayorChart.options = LINE_CHART_OPTIONS;
          this.payorCycleTimesByPayorChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.PAYOR_CYCLE_TIMES_PAYOR);
        } else {
          this.payorCycleTimesByPayorChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Payor Cycle Times - Payor", error);
      })
  }

  getPayorCycleTimesPayorChart(params?) {
    this.loading.payorCycleTimesPayorChart = true;
    this.reportService.getPayorCycleTimesPayorChart(params)
      .pipe(finalize(() => { this.loading.payorCycleTimesPayorChart = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.label && res.label.length) {
          this.payorCycleTimesPayorChart.actualData = res;
          res = {
            ...res,
            datasets: [{
              data: res.average_days,
              maxBarThickness: 30,
              backgroundColor: CHART_CUSTOM_COLORS[1],
              borderColor: CHART_CUSTOM_COLORS[1],
              hoverBackgroundColor: CHART_CUSTOM_COLORS[1],
            }]
          };
          this.payorCycleTimesPayorChart.chartType = 'horizontalBar';
          this.payorCycleTimesPayorChart.dataset = res.datasets;
          this.payorCycleTimesPayorChart.labels = res.label;
          this.payorCycleTimesPayorChart.options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    fontStyle: "bold"
                  },
                  scaleLabel: {
                    display: false,
                  },
                  gridLines: {
                    display: false
                  }
                }
              ],
              xAxes: [
                {
                  ticks: {
                    fontStyle: "bold",
                    beginAtZero: true,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Average Days",
                  },
                  gridLines: {
                    display: true
                  }
                }
              ]
            },
            plugins: {
              datalabels: {
                display: false,
              }
            },
            layout: {
              padding: {
                right: 50
              }
            }
          };
          this.payorCycleTimesPayorChart.plugins = [{
            afterDatasetsDraw: function (chart) {
              const { ctx, chartArea: { top, bottom }, scales } = chart;
              const xAxis = scales["x-axis-0"]; // Retrieve x-axis scale
              ctx.save();
              const goal = res.goal;
              ctx.beginPath();
              ctx.strokeStyle = 'gray';
              ctx.lineWidth = 1.5;
              const xPosition = xAxis.getPixelForValue(goal); // Use xAxis instead of x
              ctx.moveTo(xPosition, top);
              ctx.lineTo(xPosition, bottom);
              ctx.stroke();
              // Add text label
              ctx.fillStyle = 'black';
              ctx.font = '12px Arial';
              ctx.textAlign = 'left';
              ctx.textBaseline = 'middle';
              ctx.fillText('Goal: ' + goal, (xPosition + 5), (bottom - 10)); // Adjust the position as needed
              ctx.restore();
            }
          }];
        } else {
          this.payorCycleTimesPayorChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      })
  }

  getPayorIdentifierList(networkCode) {
    this.payorIdentifierList = [];
    this.loading.payorIdentifierList = true;
    this.reportService.getSourcePayors(networkCode)
      .pipe(finalize(() => {
        this.loading.payorIdentifierList = false;
      }))
      .subscribe((res) => {
        this.payorIdentifierList = res.map((x) => {
          return {
            ...x,
            label: x.name,
            value: x.identifier,
            purchaserCode: x.purchaserCode
          }
        }).filter(({ purchaserCode }) => purchaserCode !== PURCHASER_CODES.carrum);
      }, (error) => {
        console.log("Error getting Payor Identifier List", error)
      })
  }

  onViewToggle(reportCode, expand = false) {
    // this.reportExpanded.emit(expand); // Uncomment if download is not needed while expanding
    switch (reportCode) {
      case REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY:
        this.expand.payorCycleTimesCompany = expand;
        break;
      case REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR:
        this.expand.payorCycleTimesPayor = expand;
        break;
      case REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR_CHART:
        this.expand.payorCycleTimesPayorChart = expand;
        break;
      default:
        break;
    }
  }

  payorIdentifierSelected(event) {
    const { networkCode, endDate } = this.parsedClientClaimsFilterData;
    const year = +this._dateUtility.getYearFromDate(endDate);
    const params = {
      networkCode,
      year,
      payorIdentifier: event.identifier,
    };
    this.payorCycleTimesPayorForm.controls['payorIdentifier'].setValue(event.identifier);
    this.getPayerCycleTimesPayer(params);
  }

  yearSelected(event, reportCode) {
    const params = {
      year: event.value,
      networkCode: this.parsedClientClaimsFilterData.networkCode,
    };
    switch (reportCode) {
      case REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY:
        this.getPayerCycleTimesCompany(params, true);
        break;
      case REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_PAYOR:
        this.getPayerCycleTimesPayer(
          {
            ...params,
            payorIdentifier: this.payorCycleTimesPayorForm.get('payorIdentifier').value,
          }, true);
        break;
      default:
        break;
    }
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = (
      this.payorCycleTimesByCompanyChart.labels.length ||
      this.payorCycleTimesByPayorChart.labels.length ||
      this.payorCycleTimesPayorChart.labels.length
    );
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

}
