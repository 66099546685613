export class RolePermission {
    id: number;
    roleCode: string;
    permissionCode: string;
    accessType: string;
    isActive: boolean;
    createdAt: Date;
    updatedAt: Date;
    permission: Permission = new Permission()
}

export class Permission {
    code: string;
    name: string;
}