import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { EpisodeOfCareService, ToasterService } from '../../../services';
import { NameInitials, Storage } from '../../../utils';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit {
  @Input() members;
  @Input() loading;
  @Input() limit: number;
  @Input() totalCount: number;
  @Input() page: number;
  @Output() pageChanged: EventEmitter<any> = new EventEmitter();

  headElements = ['Name', 'DOB', 'Gender', 'Client', 'Subscriber ID', 'Effective Date', 'Termination Date', 'Address'];
  member: any;
  eligibleEocList: any;
  searchText: string = '';
  filteredEligibleEocList: any;
  user: any;

  @ViewChild('viewEocModal', { static: true }) public viewEocModal;
  @ViewChild('scrollWrapper', { static: false }) private scrollWrapper: ElementRef;

  constructor(
    private _nameInitials: NameInitials,
    private _episodeOfCareService: EpisodeOfCareService,
    private _storage: Storage,
    private _toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.user = this._storage.get('local', 'loggedInUser', 'user');
  }

  /**
   * opens eligible eoc modal
   * @param member 
   */
  onViewEoc(member: any) {
    this.member = member;
    this.member.loading = true;
    this.member.nameInitials = this._nameInitials.getNameInitials(member.fullName);
    const data = {
      memberUuid: member.uuid,
      purchaserCode: member.purchaserCode,
      networkCode: this.user.referenceCode
    }
    this._episodeOfCareService.getEocOfMember(data)
      .subscribe((res: any) => {
        this.eligibleEocList = res;
        this.search();
        this.viewEocModal.show();
        this.member.loading = false;
      }, (error) => {
        console.log("Error getting eoc list", error);
        this._toasterService.displayError(error.error.message);
        this.member.loading = false;
      });
  }

  closeModal() {
    this.viewEocModal.hide();
    this.scrollWrapper.nativeElement.scrollTop = 0;
    this.searchText = "";
    this.filteredEligibleEocList = this.eligibleEocList;
  }

  /**
   * searches for eligible eoc through keyword
   * @param data 
   */
  searchByKeyword(data: string) {
    this.searchText = data;
    this.search();
  }

  search() {
    this.filteredEligibleEocList = this.searchText === "" ? this.eligibleEocList : this.eligibleEocList.filter((element) => {
      return element.name.toLowerCase().includes(this.searchText.toLowerCase()) || element.cptCodes.map((code: string) => code.toLowerCase()).includes(this.searchText.toLowerCase());
    });
  }
}
