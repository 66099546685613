import { createAction, props, Action } from '@ngrx/store';

// export const addHeaderBreadcrumbs = createAction('[HeaderBreadCrumb] AddHeaderBreadcrumbs',props<[]>());
// export const removeHeaderBreadcrumbs = createAction('[HeaderBreadCrumb] RemoveHeaderBreadcrumbs');
// export const resetHeaderBreadcrumbs = createAction('[HeaderBreadCrumb] Reset');
export const ADD_HEADER_BREADCRUMB = '[HEADERBREADCRUMB] Add'
export const REMOVE_HEADER_BREADCRUMB = '[HEADERBREADCRUMB] Remove'
export const RESET_HEADER_BREADCRUMB = '[HEADERBREADCRUMB] Reset'


export class AddBreadCrumb implements Action {
    readonly type= ADD_HEADER_BREADCRUMB
    constructor(public payload:{}){}
}

export class RemoveBreadCrumb implements Action {
  readonly type= REMOVE_HEADER_BREADCRUMB
  constructor(public payload:[]){}
}

export class ResetBreadCrumb implements Action {
  readonly type= RESET_HEADER_BREADCRUMB
  // constructor(public payload:[]){}
}

export type Actions = AddBreadCrumb | RemoveBreadCrumb | ResetBreadCrumb
