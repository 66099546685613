<!-- Expandable Chart Begins -->
<div
  [attr.id]="expand.cycleTimes ? 'ccd-overview-claims' : null"
  class="my-3 col-md-12"
  *ngIf="toShowReport && toShowReport.includes(REPORT_NAME_CODES.CYCLE_TIMES) && expand.cycleTimes">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.CYCLE_TIMES) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="cycleTimesForm">
            <div class="d-flex justify-content-between align-items-center">
              <label class="m-0">Period</label>
              <mdb-select
                style="width: 80px"
                class="ml-3"
                placeholder="Year"
                [options]="yearOptions.cycleTimes | sort : 'label'"
                id="year-select"
                [visibleOptions]="3"
                formControlName="selectedYear"
                (selected)="yearSelected($event, REPORT_NAME_CODES.CYCLE_TIMES)">
              </mdb-select>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.CYCLE_TIMES, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="min-height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.cycleTimes && cycleTimesChart.labels.length"
        [datasets]="cycleTimesChart.dataset"
        [labels]="cycleTimesChart.labels"
        [options]="cycleTimesChart.options"
        [plugins]="cycleTimesChart.plugins"
        [chartType]="cycleTimesChart.chartType">
      </canvas>
      <p *ngIf="!loading.cycleTimes && !cycleTimesChart.labels.length">
        {{ noDataMessage.value }}
      </p>
      <div class="d-flex justify-content-center align-items-center h-75" *ngIf="loading.cycleTimes">
        <app-loader [loader]="loading.cycleTimes"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<!-- Expandable Chart Ends -->

<div [className]="!expand.cycleTimes ? '' : 'd-none'">
  <div
    class="mt-3"
    *ngIf="
      parsedClientClaimsFilterData?.isConfigLoading &&
      !parsedClientClaimsFilterData?.toShowReport.length
    ">
    <app-loader [loader]="true"></app-loader>
  </div>
  <div
    id="ccd-overview-claims"
    class="m-0 p-0"
    *ngIf="
      !parsedClientClaimsFilterData?.isConfigLoading &&
      parsedClientClaimsFilterData?.toShowReport &&
      parsedClientClaimsFilterData?.toShowReport.length
    ">
    <h6 class="mb-0 p-3 font-weight-bold">Overall Claims Cycle Dashboard</h6>
    <div class="px-3">
      <!-- Episode Claims (with) Table and Cycle Times Begins -->
      <div
        *ngIf="
          toShowReport &&
          (toShowReport.includes(REPORT_NAME_CODES.EPISODE_WITH_CLAIMS_TABLE) ||
            toShowReport.includes(REPORT_NAME_CODES.CYCLE_TIMES))
        ">
        <div
          class="col-md-12 mb-4"
          *ngIf="
            toShowReport && toShowReport.includes(REPORT_NAME_CODES.EPISODE_WITH_CLAIMS_TABLE)
          ">
          <mdb-card>
            <mdb-card-header>
              <p
                class="m-0 font-weight-normal"
                *ngIf="getReport(REPORT_NAME_CODES.EPISODE_WITH_CLAIMS_TABLE) as report">
                {{ report.reportName }}
              </p>
            </mdb-card-header>
            <mdb-card-body class="pb-0 pl-0 pr-0">
              <div style="margin-top: -25px">
                <app-table
                  *ngIf="
                    !loading.episodeWithClaimsTable &&
                    (!episodeWithClaimsTableData || episodeWithClaimsTableData.data.length)
                  "
                  [report]="episodeWithClaimsTableData"
                  [exportTable]="false"
                  [module]="'report'"
                  tableHeight="300px">
                  <ng-template #additionalHeader>
                    <tr class="grey lighten-4 text-center">
                      <td class="p-1 pl-2 font-size-12px"><span>Category</span></td>
                      <td class="p-1 pl-2 pr-2 font-size-12px" style="white-space: nowrap">
                        <span>Sub Category</span>
                      </td>
                      <td class="p-1 pl-2 font-size-12px">Year</td>
                      <td
                        class="p-1 pl-2 font-size-12px"
                        colspan="2"
                        *ngFor="let month of MONTHS_NAME_FULL">
                        <span>{{ month }}</span>
                      </td>
                    </tr>
                  </ng-template>
                </app-table>
              </div>
              <p
                style="margin-top: 25px"
                *ngIf="
                  !loading.episodeWithClaimsTable &&
                  (!episodeWithClaimsTableData || !episodeWithClaimsTableData.data.length)
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75 py-4 mt-4"
                *ngIf="loading.episodeWithClaimsTable">
                <app-loader [loader]="loading.episodeWithClaimsTable"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div
          class="col-md-12 mb-4"
          *ngIf="toShowReport && toShowReport.includes(REPORT_NAME_CODES.CYCLE_TIMES)">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.CYCLE_TIMES) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="cycleTimesForm">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="m-0">Period</label>
                      <mdb-select
                        style="width: 80px"
                        class="ml-3"
                        placeholder="Year"
                        [options]="yearOptions.cycleTimes | sort : 'label'"
                        id="year-select"
                        [visibleOptions]="3"
                        formControlName="selectedYear"
                        (selected)="
                          yearSelected($event, REPORT_NAME_CODES.PAYOR_CYCLE_TIMES_COMPANY)
                        ">
                      </mdb-select>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor ml-3"
                    (click)="onViewToggle(REPORT_NAME_CODES.CYCLE_TIMES, true)">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="!loading.cycleTimes && cycleTimesChart.labels.length"
                [datasets]="cycleTimesChart.dataset"
                [labels]="cycleTimesChart.labels"
                [options]="cycleTimesChart.options"
                [plugins]="cycleTimesChart.plugins"
                [chartType]="cycleTimesChart.chartType">
              </canvas>
              <p *ngIf="!loading.cycleTimes && !cycleTimesChart.labels.length">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.cycleTimes">
                <app-loader [loader]="loading.cycleTimes"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
      <!-- Episode Claims (with) Table and Cycle Times Ends -->

      <!-- Detail Begins -->
      <div class="mb-4" *ngIf="toShowReport && toShowReport.includes(REPORT_NAME_CODES.DETAIL)">
        <mdb-card>
          <mdb-card-header>
            <p class="m-0 font-weight-normal">{{ REPORT_NAME_TYPES.DETAIL | titlecase }}</p>
          </mdb-card-header>
          <mdb-card-body>
            <div style="margin-top: -25px">
              <!-- table goes here -->
            </div>
            <p style="margin-top: 25px" *ngIf="!loading.detail && !detailList.length">
              {{ noDataMessage.value }}
            </p>
            <div
              class="d-flex justify-content-center align-items-center h-75"
              *ngIf="loading.detail">
              <app-loader [loader]="loading.detail"></app-loader>
            </div>
          </mdb-card-body>
        </mdb-card>
      </div>
      <!-- Detail Ends -->
    </div>
  </div>
</div>
