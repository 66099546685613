<div style="padding: 10px 25px" class="grey lighten-5 mt-2 mb-2">
  <div class="d-flex justify-content-between align-items-center">
    <h5>Search Patient Eligibility Records</h5>
  </div>
  <form [formGroup]="advanceSearchForm">
    <div class="row">
      <div class="col-9">
        <div class="d-flex">
          <div class="form-group mr-4">
            <label for="fname">First Name</label>
            <input
              type="text"
              id="fname"
              class="md-textarea form-control"
              formControlName="firstName"
              mdbInput
              (keyup.enter)="searchMembers.emit(1)" />
          </div>
          <div class="form-group mr-4">
            <label for="mname">Middle Name</label>
            <input
              type="text"
              id="mname"
              class="md-textarea form-control"
              formControlName="middleName"
              mdbInput
              (keyup.enter)="searchMembers.emit(1)" />
          </div>
          <div class="form-group mr-4">
            <label for="lname">Last Name</label>
            <input
              type="text"
              id="lname"
              class="md-textarea form-control"
              formControlName="lastName"
              mdbInput
              (keyup.enter)="searchMembers.emit(1)" />
          </div>
          <div class="form-group mr-4">
            <label for="SubscriberNumber">Subscriber Number</label>
            <input
              type="text"
              id="SubscriberNumber"
              class="md-textarea form-control"
              formControlName="subscriberNumber"
              mdbInput
              (keyup.enter)="searchMembers.emit(1)" />
          </div>
          <div class="form-group">
            <label for="dob">Date of Birth</label>
            <input
              type="text"
              id="dob"
              placeholder="MM/DD/YYYY"
              class="form-control"
              [textMask]="{mask: dateMask}"
              formControlName="dob"
              [ngClass]="{
                'is-invalid': advanceSearchForm.get('dob').invalid || validPatientDob
              }"
              (keyup.enter)="searchMembers.emit(1)" />
          </div>
        </div>
      </div>
      <div class="col-3 pl-2">
        <div class="text-right mt-4">
          <button
            type="button"
            class="btn"
            style="background-color: white"
            mdbWavesEffect
            (click)="clearAdvanceSearch.emit()">
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-secondary waves-light"
            mdbWavesEffect
            (click)="searchMembers.emit(1)">
            Search
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
