<!-- Expandable Chart Begins -->
<div
  [attr.id]="expand.totalCycleTime ? 'total-cycle-time' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.TOTAL_CYCLE_TIMES) &&
    expand.totalCycleTime
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.TOTAL_CYCLE_TIMES) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="totalCycleTimeForm">
            <div class="d-flex justify-content-between align-items-center">
              <label class="m-0">Period</label>
              <mdb-select
                style="width: 80px"
                class="ml-3"
                placeholder="Year"
                [options]="yearOptions.totalCycleTime | sort : 'label'"
                id="year-select"
                [visibleOptions]="3"
                formControlName="selectedYear"
                (selected)="yearSelected($event, REPORT_NAME_CODES.TOTAL_CYCLE_TIMES)">
              </mdb-select>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.TOTAL_CYCLE_TIMES, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.totalCycleTime && totalCycleTimeChart.labels.length"
        [datasets]="totalCycleTimeChart.dataset"
        [labels]="totalCycleTimeChart.labels"
        [options]="totalCycleTimeChart.options"
        [plugins]="totalCycleTimeChart.plugins"
        [chartType]="totalCycleTimeChart.chartType">
      </canvas>
      <p *ngIf="!loading.totalCycleTime && !totalCycleTimeChart.labels.length">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.totalCycleTime">
        <app-loader [loader]="loading.totalCycleTime"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<!-- Expandable Chart Ends -->
<div [className]="!expand.totalCycleTime ? 'mt-3' : 'd-none'">
  <div
    class="mt-3"
    *ngIf="
      parsedClientClaimsFilterData?.isConfigLoading &&
      !parsedClientClaimsFilterData?.toShowReport.length
    ">
    <app-loader [loader]="true"></app-loader>
  </div>
  <div
    id="total-cycle-time"
    class="m-0 p-0"
    *ngIf="
      !parsedClientClaimsFilterData?.isConfigLoading &&
      parsedClientClaimsFilterData?.toShowReport &&
      parsedClientClaimsFilterData?.toShowReport.length
    ">
    <div class="px-3">
      <div
        class="row"
        *ngIf="toShowReport && toShowReport.includes(REPORT_NAME_CODES.TOTAL_CYCLE_TIMES)">
        <div
          class="col-md-12 mb-4"
          *ngIf="toShowReport && toShowReport.includes(REPORT_NAME_CODES.TOTAL_CYCLE_TIMES)">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.TOTAL_CYCLE_TIMES) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="totalCycleTimeForm">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="m-0">Period</label>
                      <mdb-select
                        style="width: 80px"
                        class="ml-3"
                        placeholder="Year"
                        [options]="yearOptions.totalCycleTime | sort : 'label'"
                        id="year-select"
                        [visibleOptions]="3"
                        formControlName="selectedYear"
                        (selected)="yearSelected($event, REPORT_NAME_CODES.TOTAL_CYCLE_TIMES)">
                      </mdb-select>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor ml-3"
                    (click)="onViewToggle(REPORT_NAME_CODES.TOTAL_CYCLE_TIMES, true)">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="!loading.totalCycleTime && totalCycleTimeChart.labels.length"
                [datasets]="totalCycleTimeChart.dataset"
                [labels]="totalCycleTimeChart.labels"
                [options]="totalCycleTimeChart.options"
                [plugins]="totalCycleTimeChart.plugins"
                [chartType]="totalCycleTimeChart.chartType">
              </canvas>
              <p *ngIf="!loading.totalCycleTime && !totalCycleTimeChart.labels.length">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.totalCycleTime">
                <app-loader [loader]="loading.totalCycleTime"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>
</div>
