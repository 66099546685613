import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClaimsService, PaymentService } from '../../../services';
import * as HeaderBreadCrumbActions from '../../../action';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AccessType, Storage } from '../../../utils';
import { saveAs as importedSaveAs } from 'file-saver';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { ADDRESS, EXCEPTION_CODES, MAILING_ADDRESS, DISABLE_APPROVE_EDI_DESCRIPTION, ROLE_CODES, CLAIMS_PROCESSING_STATUS, PAYMENT_STATUS } from '../../../constants';
import { HraHsaServiceLine } from 'src/app/models';

@Component({
  selector: 'app-incoming-claims-edi',
  templateUrl: './incoming-claims-edi.component.html',
  styleUrls: ['./incoming-claims-edi.component.scss']
})
export class IncomingClaimsEdiComponent implements OnInit {
  @ViewChild('approveConfirmationModal', { static: true }) public approveConfirmationModal;

  claimId = null;
  ediFile = null;
  backupEdi = null;
  remittancePdf = null;
  loading = {
    edi: false,
    remittance: false,
    pdf: false,
    attachment: false
  };
  saving: boolean = false;
  isClaimApproved: any;
  user: any;
  defaultSelected = 1;
  currentYear = new Date().getFullYear();
  options = [
    { label: 'EDI', value: 1 },
    { label: 'Remittance Summary', value: 2 },
  ];
  showMessageModal: boolean = false;
  isApproveDisabled: boolean = false;
  address: string = ADDRESS;
  mailingAddress: string = MAILING_ADDRESS;
  patientControlNumber: number;
  EXCEPTION_CODES = EXCEPTION_CODES;
  ROLE_CODES = ROLE_CODES;
  CLAIMS_PROCESSING_STATUS = CLAIMS_PROCESSING_STATUS;
  PAYMENT_STATUS = PAYMENT_STATUS;
  hraHsaServiceLine: HraHsaServiceLine;
  paymentId: string;
  remittanceSummaryItem: any[];
  excptCodeLists: any[] = [];
  disableApproveEdiDescription = DISABLE_APPROVE_EDI_DESCRIPTION;

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private claimsService: ClaimsService,
    private paymentService: PaymentService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private _storage: Storage,
    private utilityAccess: AccessType,
    private router: Router,
    private location: Location) { }

  ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Claims', path: '/claims' }));
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: '835 EDI', path: '' }));
    this.user = this._storage.get('local', 'loggedInUser', 'user');
    this.claimId = this.route.snapshot.paramMap.get("id");
    this.paymentId = this.route.snapshot.paramMap.get("paymentId");
    if (this.claimId)
      this.viewEdi(this.claimId);
    this.isClaimApproved = this.route.snapshot.paramMap.get("isApproved");
  }

  viewEdi(claimId) {
    this.loading.edi = true;
    this.claimsService.getEdi(claimId, '835')
      .pipe(finalize(() => this.loading.edi = false))
      .subscribe((res: any) => {
        this.ediFile = `<pre>${res.data}</pre>`;
        this.backupEdi = res.data;
        this.patientControlNumber = res.claimId;
        this.isApproveDisabled = res.processingStatus.toUpperCase() === CLAIMS_PROCESSING_STATUS.PAYMENT_IN_PROCESS && !res.paymentModeTraceNumber;
      }, (error) => {
        console.log('Error getting claims edi', error);
        this.toaster.error(`${error.error.message}`, 'Error');
      })
  }

  public captureScreen() {
    window.print();
  }

  documentDefinition: any;
  download(type) {
    if (this.defaultSelected == 1)
      type == 'text' ? this.downloadTextFile('835', this.backupEdi) : this.downloadPDFFile('835', this.backupEdi);
    else {
      var data = document.getElementById('remittance-wrapper');
      this.loading.pdf = true;
      html2canvas(data).then(canvas => {
        this.loading.pdf = false;
        this.loading.attachment = false;
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm', "a4");
        var position = 0;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 10);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = (heightLeft - imgHeight) + 2;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 10);
          heightLeft -= pageHeight;
        }
        doc.save(`Remittance Summary-${this.remittancePdf.patientControlNumber}.pdf`);
      });
    }
  }

  downloadTextFile(filename, data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    importedSaveAs(url, `${filename}-${this.patientControlNumber}.txt`);
  }

  downloadPDFFile(filename, data) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const documentDefinition = { content: data };
    pdfMake.createPdf(documentDefinition).download(`${filename}` + '-' + this.patientControlNumber);
  }

  approve() {
    if (this.utilityAccess.searchAccess('CLM', 'isEditable'))
      this.approveConfirmationModal.show();
    else
      this.toaster.warning("Access Denied", "Warning");
  }

  triggerAction(action) {
    if (action == 'yes') {
      this.saving = true;
      let body = {
        // isApproved: true
        claimId: this.claimId
      }
      // this.claimsService.approveClaim(this.claimId, body, '835')
      this.paymentService.approveEDI(body)
        .pipe(finalize(() => this.saving = false))
        .subscribe((res: any) => {
          if (res) {
            this.isClaimApproved = 'true';
            this.location.replaceState(`/claims/${this.claimId}/edi/true/${this.paymentId}`);
            this.toaster.success('Approved', "Success");
          }
        }, (error) => {
          console.log('Error approving', error);
          this.toaster.error(error.error.message, "Error");
        })
    }
    this.approveConfirmationModal.hide();
  }

  valueChanged(event) {
    this.defaultSelected = event;
    if (event == 2 && !this.remittancePdf)
      this.getRemittance()
  }

  editPayment() {
    this.route.params.subscribe((res) => {
      this.router.navigate([`payment/edit/paid/${res.paymentId}`]);
    })
  }

  getRemittance() {
    this.loading.remittance = true;
    this.paymentService.getRemittanceSummaryByClaimId(this.claimId)
      .pipe(finalize(() => this.loading.remittance = false))
      .subscribe((res: any) => {
        this.remittancePdf = res[0];
        this.remittanceSummaryItem = res.map((remittance) => {
          if (remittance.serviceLines && remittance.serviceLines.length) {
            remittance.serviceLinesTotal = {
              totalServiceBilledAmount: 0,
              totalServiceAllowedAmount: 0,
              totalServicePatientPay: 0,
              totalServiceNetAmount: 0,
            }

            // Push All Code and Description in list
            remittance.serviceLines.map(item => {
              this.excptCodeLists.push({
                code: item.excptCode,
                description: item.claimStatusReason,
              })
            });

            remittance.serviceLines = remittance.serviceLines.map((service) => {
              remittance.serviceLinesTotal.totalServiceBilledAmount += +service.billedAmount || 0;
              remittance.serviceLinesTotal.totalServiceAllowedAmount += +service.allowedAmount || 0;
              remittance.serviceLinesTotal.totalServicePatientPay += +service.patientPay || 0;
              remittance.serviceLinesTotal.totalServiceNetAmount += +service.netAmount || 0;
              return service;
            })
          } else {
            remittance.serviceLines = [];
          }
          return remittance;
        });
        if (this.excptCodeLists.length) {
          // Filter duplicate object with same code from list.
          this.excptCodeLists = this.excptCodeLists.filter((obj, index, self) =>
            index === self.findIndex((t) => t.code === obj.code && typeof t.code === 'string')
          );
        }
        if (this.remittanceSummaryItem.length) {
          // Total of all amounts
          let totalBilledAmount = 0;
          let totalAllowedAmount = 0;
          let totalPatientPay = 0;
          let totalNetAmount = 0;
          this.remittanceSummaryItem.map((remittanceSummary) => {
            totalBilledAmount += +remittanceSummary.serviceLinesTotal.totalServiceBilledAmount || 0;
            totalAllowedAmount += +remittanceSummary.serviceLinesTotal.totalServiceAllowedAmount || 0;
            totalPatientPay += +remittanceSummary.serviceLinesTotal.totalServicePatientPay || 0;
            totalNetAmount += +remittanceSummary.serviceLinesTotal.totalServiceNetAmount || 0;
            this.remittancePdf['totalBilledAmount'] = totalBilledAmount;
            this.remittancePdf['totalAllowedAmount'] = totalAllowedAmount;
            this.remittancePdf['totalPatientPay'] = totalPatientPay;
            this.remittancePdf['totalNetAmount'] = totalNetAmount;
          });
        }
      }, (error) => {
        console.log('Error getting remittance summrary', error);
        this.toaster.error(`${error.error.message}`, 'Error');
      })
  }

  toTitleCase(name) {
    return name.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  onSendEmail() {
    this.showMessageModal = true;
  }

  closeMessageModal() {
    this.showMessageModal = false;
  }
}
