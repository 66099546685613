import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class LedgerService {
    constructor(
        private http: HttpClientService) {
    }

    getLedger() {
        return this.http.get(`${APICONSTANTS.EPISODE_OF_CARE}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

}
