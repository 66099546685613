<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group has-search input-group mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          [(ngModel)]="searchKeyword"
          class="form-control"
          name="searchKeyword"
          placeholder="Search"
          (ngModelChange)="searchByKeyword($event)" />
        <div class="input-group-append" *ngIf="searchKeyword">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-light z-depth-0 pl-3 pr-3"
          data-toggle="modal"
          data-target="#basicExample"
          mdbWavesEffect
          (click)="openAdvanceSearch()">
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50">
      <button
        *ngIf="user.roleCode != 'MP' && reportModulePermission"
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        [disabled]="!claimsList || claimsList.length === 0 || loading.claims"
        (click)="exportToCsv()"
        data-target="#basicExample"
        mdbWavesEffect>
        <span
          *ngIf="loading.exportingClaims"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"></span>
        <i class="fas fa-file-export" *ngIf="!loading.exportingClaims"></i>
        <span class="d-none d-sm-inline-block ml-1"> Export</span>
      </button>
    </div>
  </div>
  <div class="keywords position-sticky" *ngIf="filterList && filterList.length > 0">
    <div class="keywords d-flex mt-2 flex-wrap align-items-center">
      <small class="mb-2 mr-2">Filters: </small>
      <span class="d-block mr-1 mb-2" *ngFor="let filter of filterList">
        <small class="d-block text-muted ml-1"></small>
        <div class="chip blue lighten-5 mb-0">
          {{ filter.field }}:
          <span class="font-weight-500 text-dark">{{ filter.label | titlecase }}</span>
          <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
        </div>
      </span>
      <button
        type="button"
        class="btn btn-link waves-light m-0 p-0"
        mdbWavesEffect
        (click)="ResetFilter()">
        <span class="text-info">Reset Filter</span>
      </button>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table
          mdbTable
          hover="true"
          small="true"
          class="table-mobile-view v-top"
          stickyHeader="true">
          <thead id="scrollToTop">
            <tr>
              <th width="180" [class.active]="sortBy === 'claimId'" (click)="setOrder('claimId')">
                Claim ID
                <span *ngIf="sortBy === 'claimId'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="170"
                [class.active]="sortBy === 'patientName'"
                (click)="setOrder('patientName')">
                Patient Info
                <span *ngIf="sortBy === 'patientName'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="230"
                [class.active]="sortBy === 'episodeName'"
                (click)="setOrder('episodeName')">
                Episode Name
                <span *ngIf="sortBy === 'episodeName'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="400"
                [class.active]="sortBy === 'billingProviderName'"
                (click)="setOrder('billingProviderName')">
                Billing Info
                <span *ngIf="sortBy === 'billingProviderName'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <!-- <th [class.active]="sortBy === 'renderingProviderName'"
                                (click)="setOrder('renderingProviderName')">
                                Rendering Provider
                                <span *ngIf="sortBy === 'renderingProviderName'">
                                    <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
                                    <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
                                </span>
                            </th> -->
              <th
                class="text-right"
                width="80"
                [class.active]="sortBy === 'billingAmount'"
                (click)="setOrder('billingAmount')">
                Billed Amt
                <span *ngIf="sortBy === 'billingAmount'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <!-- <th width="110" class="text-center">
                                Status
                            </th> -->
              <th
                width="120"
                class="text-right"
                [class.active]="sortBy === 'allowedAmount'"
                (click)="setOrder('allowedAmount')">
                Allowed Amt
                <span *ngIf="sortBy === 'allowedAmount'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th width="230" class="pl-3">Status</th>
              <th width="150" [class.active]="sortBy === 'timeline'" (click)="setOrder('timeline')">
                Timeline
                <span *ngIf="sortBy === 'timeline'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th width="50"></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading.claims"
            [style.top]="claimsList && claimsList.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading.claims"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let claims of claimsList
                | paginate : {itemsPerPage: limit, currentPage: page, totalItems: totalCount};
              let i = index
            ">
            <tr
              [style.pointer-events]="
                i === processingClaimIndex && loading.claimsDetail ? 'none' : 'auto'
              "
              [style.opacity]="i === processingClaimIndex && loading.claimsDetail ? '0.4' : '1'">
              <td>
                {{ claims.patientAccountNumber }}<br />
                <span class="badge badge-sm bg-primary-dark text-white z-depth-0">
                  {{ claims.transaction.ediType }} </span
                ><br />
                <ul class="fa-ul ml-4 mb-0">
                  <li *ngIf="claims.statementDate && claims.statementDate !== ''">
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="calendar-alt fa-sm"></mdb-icon>
                    </span>
                    <small mdbTooltip="Statement Date" placement="right">{{
                      claims.statementDate
                    }}</small>
                  </li>
                  <li
                    *ngIf="
                      claims.serviceLines &&
                      claims.serviceLines.length &&
                      claims.serviceLines[0].serviceDate !== ''
                    ">
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="calendar-alt fa-sm"></mdb-icon>
                    </span>
                    <small mdbTooltip="DOS" placement="right">{{
                      claims.serviceLines[0].serviceDate
                    }}</small>
                  </li>
                </ul>
              </td>
              <td (click)="getClaimsDetail(claims.id)">
                <ul class="fa-ul ml-4 mb-0">
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="user fa-sm"></mdb-icon>
                    </span>
                    {{ claims.patient.displayName | titlecase }}
                  </li>
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="birthday-cake fa-sm"></mdb-icon>
                    </span>
                    {{
                      (claims.patient.patientDob
                        ? claims.patient.patientDob
                        : claims.patient.subscriberDob
                      ) | date : 'MM/dd/yyyy' : 'UTC'
                    }}
                  </li>
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="address-card fa-sm"></mdb-icon>
                    </span>
                    {{ claims.patient.subscriberIdentificationCode }}
                  </li>
                </ul>
              </td>
              <td (click)="getClaimsDetail(claims.id)">
                {{
                  claims.claimXRefHolista && claims.claimXRefHolista.episodeName
                    ? claims.claimXRefHolista.episodeName
                    : ''
                }}
              </td>
              <td (click)="getClaimsDetail(claims.id)">
                <span class="font-weight-500">{{
                  (claims.transaction.billingProvider.basicInfo &&
                  claims.transaction.billingProvider.basicInfo.displayName
                    ? claims.transaction.billingProvider.basicInfo.displayName
                    : ''
                  ) | uppercase
                }}</span>
                <ul class="fa-ul ml-4 mb-0">
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="address-card fa-sm"></mdb-icon>
                    </span>
                    <span mdbTooltip="NPI" placement="right">
                      {{
                        (claims.transaction.billingProvider.basicInfo &&
                        claims.transaction.billingProvider.basicInfo.identificationCode
                          ? claims.transaction.billingProvider.basicInfo.identificationCode
                          : ''
                        ) | titlecase
                      }}</span
                    >
                  </li>
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="address-card fa-sm"></mdb-icon>
                    </span>
                    <span mdbTooltip="TIN" placement="right"
                      >{{
                        (claims.transaction.billingProvider &&
                        claims.transaction.billingProvider.taxIdentificationNumber
                          ? claims.transaction.billingProvider.taxIdentificationNumber
                          : ''
                        ) | titlecase
                      }}
                    </span>
                  </li>
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="map-marker-alt fa-sm"></mdb-icon>
                    </span>
                    {{
                      (claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.addressLine1
                        ? claims.transaction.billingProvider.basicInfo.addressLine1
                        : ''
                      ) | titlecase
                    }}
                    {{
                      (claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.addressLine2
                        ? claims.transaction.billingProvider.basicInfo.addressLine2
                        : ''
                      ) | titlecase
                    }}
                    <br />
                    {{
                      (claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.city
                        ? claims.transaction.billingProvider.basicInfo.city
                        : ''
                      ) | titlecase
                    }}
                    {{
                      claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.state
                        ? claims.transaction.billingProvider.basicInfo.state
                        : ''
                    }}
                    {{
                      (claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.zipCode
                        ? claims.transaction.billingProvider.basicInfo.zipCode
                        : ''
                      ) | titlecase
                    }}
                  </li>
                </ul>
              </td>
              <!-- <td (click)="getClaimsDetail(claims.id)">
                                {{(claims.renderingProvider && claims.renderingProvider.displayName ?
                                claims.renderingProvider.displayName : '') | titlecase}}
                                <ul class="fa-ul ml-4 mb-0" *ngIf="claims.renderingProvider">
                                    <li>
                                        <span class="fa-li" *ngIf="claims.renderingProvider.identificationCode">
                                            <mdb-icon class='text-primary' fas icon="address-card fa-sm"></mdb-icon>
                                        </span>
                                        {{claims.renderingProvider ? claims.renderingProvider.identificationCode:""}}
                                    </li>
                                    <li>
                                        <span class="fa-li" *ngIf="claims.renderingProvider.addressLine1">
                                            <mdb-icon class='text-primary' fas icon="map-marker-alt fa-sm"></mdb-icon>
                                        </span>
                                        {{(claims.renderingProvider ? claims.renderingProvider.addressLine1:"") |
                                        titlecase}}
                                        <br>
                                        {{(claims.renderingProvider ? claims.renderingProvider.city:"") | titlecase}}
                                        {{claims.renderingProvider ? claims.renderingProvider.state:""}}
                                        {{claims.renderingProvider ? claims.renderingProvider.zipCode:""}}
                                    </li>
                                </ul>
                            </td> -->
              <td class="text-right" (click)="getClaimsDetail(claims.id)">
                {{ claims.totalClaimChargeAmount | currency }}
              </td>
              <td class="text-right">
                {{ claims.claimXRefHolista.allowedAmount | currency }}
                <ng-template #allowedAmountTemplate>
                  <table style="width: 300px" mdbTable small="true">
                    <tr>
                      <td colspan="2">
                        <p class="m-0"><b>Cash Paid</b></p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td><p class="m-0">Claim Amount</p></td>
                      <td class="px-0 text-right w-25">
                        <p class="m-0">
                          {{
                            claims.claimXRefHolista.netAmount !== null
                              ? (claims.claimXRefHolista.netAmount | currency)
                              : '-'
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td><p class="m-0">HRA/HSA</p></td>
                      <td class="px-0 text-right w-25">
                        <p class="m-0">
                          {{
                            claims.claimXRefHolista.hraHsaAmount !== null
                              ? (claims.claimXRefHolista.hraHsaAmount | currency)
                              : '-'
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td colspan="2">
                        <p class="m-0"><b>Payment Adjustment</b></p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td><p class="m-0">Patient Responsibility</p></td>
                      <td class="px-0 text-right w-25">
                        <p class="m-0">
                          {{
                            claims.claimXRefHolista.patientPayAmount !== null
                              ? (claims.claimXRefHolista.patientPayAmount | currency)
                              : '-'
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td class="pb-0">
                        <p class="m-0"><b>Total Allowed Amount</b></p>
                      </td>
                      <td class="px-0 pb-0 text-right">
                        <p class="m-0">
                          <b>{{ claims.claimXRefHolista.allowedAmount | currency }}</b>
                        </p>
                      </td>
                    </tr>
                  </table>
                </ng-template>
                <i
                  *ngIf="claims.claimXRefHolista.allowedAmount"
                  class="icofont-info-circle btn-link pointer-cursor ml-1"
                  [mdbPopover]="allowedAmountTemplate"
                  placement="bottom"
                  triggers="mouseenter:mouseleave"></i>
              </td>
              <td class="pl-3">
                <div
                  class="chip alert m-0 mb-2 w-auto rounded-sm {{ claims.claimStatusClass }}"
                  *ngIf="claims.claimXRefHolista && claims.claimXRefHolista.processingStatus">
                  <div
                    *ngIf="user.roleCode !== 'PR'"
                    (mouseover)="
                      showDropdown =
                        selectedClaim && selectedClaim.id === claims.id && loading.statusChanged
                          ? false
                          : true
                    "
                    class="custom-dropdown">
                    <div class="d-flex justify-content-between w-100 pl-2 pr-2">
                      {{ claims.claimXRefHolista.processingStatus | titlecase }}
                      <button
                        class="btn btn-link border-0 dropdown-toggle waves-light m-0 ml-2 p-0"
                        type="button"></button>
                    </div>
                    <div
                      class="custom-dropdown-content drop-satatus rounded-sm z-depth-2"
                      *ngIf="user.roleCode !== 'PR' && showDropdown">
                      <a
                        *ngFor="let status of claims.processingStatus"
                        class="cursor-pointer dropdown-item"
                        (click)="statusChanged(status, claims)">
                        {{ status.label }}</a
                      >
                    </div>
                  </div>
                  <div class="status-label" *ngIf="user.roleCode === 'PR'">
                    {{ claims.claimXRefHolista.processingStatus | titlecase }}
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <small
                    class="text-info"
                    *ngIf="selectedClaim && selectedClaim.id === claims.id && loading.statusChanged"
                    >Processing...</small
                  >
                  <div class="claim-status">
                    <small
                      [class]="
                        claims.claimXRefHolista.processingStatus.toLowerCase() === 'rejected'
                          ? 'is-rejected'
                          : claims.claimXRefHolista.processingStatus.toLowerCase() === 'denied'
                          ? 'is-denied'
                          : claims.claimXRefHolista.processingStatus.toLowerCase() === 'queue' ||
                            claims.claimXRefHolista.processingStatus.toLowerCase() === 'queue-hold'
                          ? 'is-queue'
                          : claims.claimXRefHolista.processingStatus.toLowerCase() === 'paid'
                          ? 'is-paid'
                          : claims.claimXRefHolista.processingStatus.toLowerCase() === 'in-review'
                          ? 'is-in-review'
                          : 'text-info'
                      "
                      *ngIf="
                        claims.claimXRefHolista.processingStatus.toLowerCase() !== 'rejected' ||
                        claims.claimXRefHolista.processingStatus.toLowerCase() !== 'denied' ||
                        claims.claimXRefHolista.processingStatus.toLowerCase() !== 'queue-hold' ||
                        claims.claimXRefHolista.processingStatus.toLowerCase() !== 'queue'
                      "
                      >{{ claims.claimXRefHolista.statusReason }}</small
                    >
                  </div>
                </div>
              </td>
              <td (click)="getClaimsDetail(claims.id)">
                <div class="d-flex align-items-center">
                  <div [style.width]="'65px'">Created</div>
                  {{ claims.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}
                </div>
                <div class="d-flex align-items-center">
                  <div [style.width]="'65px'">Paid</div>
                  <!-- {{
                    claims.claimXRefHolista.transactionDetail &&
                    claims.claimXRefHolista.transactionDetail.length > 0
                      ? (claims.claimXRefHolista.transactionDetail[0].transaction?.transactionDate
                        | date : 'MM/dd/yyyy' : 'UTC')
                      : claims.claimXRefHolista.processingStatus.toLowerCase() === 'paid'
                      ? (claims.claimXRefHolista.updatedAt | date : 'MM/dd/yyyy' : 'UTC')
                      : '-'
                  }} -->
                  {{
                    claims.claimXRefHolista.processingStatus.toUpperCase() ===
                    CLAIMS_PROCESSING_STATUS.PAID
                      ? claims.claimPayment
                        ? (claims.claimPayment.payment.paymentDate | date : 'MM/dd/yyyy')
                        : (claims.claimXRefHolista.updatedAt | date : 'MM/dd/yyyy')
                      : claims.claimXRefHolista.processingStatus.toUpperCase() ===
                          CLAIMS_PROCESSING_STATUS.DENIED && claims.isCreatedPriorToJuneFirst
                      ? (claims.updatedAt | date : 'MM/dd/yyyy')
                      : '-'
                  }}
                </div>
              </td>
              <td>
                <div
                  class="custom-dropdown"
                  *ngIf="
                    user.roleCode !== 'PR' &&
                    (claims.claimXRefHolista.processingStatus.toLowerCase() ===
                      'fund-request-in-process' ||
                      claims.claimXRefHolista.processingStatus.toLowerCase() === 'rejected' ||
                      claims.claimXRefHolista.processingStatus.toLowerCase() === 'denied' ||
                      claims.claimXRefHolista.processingStatus.toLowerCase() === 'queue' ||
                      claims.claimXRefHolista.processingStatus.toLowerCase() === 'queue-hold' ||
                      claims.claimXRefHolista.processingStatus.toLowerCase() === 'fund-requested' ||
                      claims.claimXRefHolista.processingStatus.toLowerCase() ===
                        'fund-requested-rejected' ||
                      claims.claimXRefHolista.processingStatus.toLowerCase() ===
                        'fund-request-cancelled')
                  ">
                  <div class="d-flex flex-column align-items-center">
                    <button
                      class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                      type="button"
                      mdbWavesEffect>
                      <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                    </button>
                    <span
                      *ngIf="
                        selectedClaimForAutomation &&
                        selectedClaimForAutomation['id'] === claims.id &&
                        loading.claimsDetail
                      "
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"></span>
                  </div>
                  <div class="custom-dropdown-content rounded-sm z-depth-2">
                    <a class="cursor-pointer dropdown-item" (click)="onSendEmail(claims)">
                      <i class="fa-fa fa-envelope fas"></i> Send Email
                    </a>
                    <a
                      (click)="view835Claim(claims)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="
                        claims.claimXRefHolista.processingStatus.toUpperCase() ===
                          CLAIMS_PROCESSING_STATUS.DENIED && claims.claimXRefHolista.ediData
                      ">
                      <i class="far fa-eye fa-fw mr-1"></i>835 EDI</a
                    >
                    <a
                      (click)="goToPayment(claims)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="
                        user.roleCode !== ROLE_CODES.PROVIDER_ADMINISTRATOR &&
                        claims.claimXRefHolista.processingStatus.toUpperCase() ===
                          CLAIMS_PROCESSING_STATUS.DENIED &&
                        !claims.claimXRefHolista.ediData
                      ">
                      <i class="fas fa-credit-card"></i> Add Payment</a
                    >
                    <a
                      (click)="goToEditPayment(claims)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="
                        user.roleCode !== ROLE_CODES.PROVIDER_ADMINISTRATOR &&
                        claims.claimXRefHolista.processingStatus.toUpperCase() ===
                          CLAIMS_PROCESSING_STATUS.PAYMENT_IN_PROCESS
                      ">
                      <i class="fas fa-credit-card"></i> Edit Payment</a
                    >
                  </div>
                </div>
                <div class="custom-dropdown" *ngIf="showClaimsEllipsis(claims.claimXRefHolista)">
                  <div class="d-flex flex-column align-items-center">
                    <button
                      class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                      type="button"
                      mdbWavesEffect>
                      <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                    </button>
                    <span
                      *ngIf="
                        selectedClaimForAutomation &&
                        selectedClaimForAutomation['id'] === claims.id &&
                        loading.claimsDetail
                      "
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"></span>
                  </div>
                  <div class="custom-dropdown-content rounded-sm z-depth-2">
                    <a
                      (click)="openAssociateModal(claims)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="showAssociateEpisode(claims.claimXRefHolista)">
                      <i class="fas fa-link"></i> Associate Episode</a
                    >
                    <a
                      (click)="runAutomation(claims, i)"
                      class="cursor-pointer dropdown-item"
                      [class.disabled]="loading.claimsDetail"
                      [style.opacity]="loading.claimsDetail ? '0.5' : '1'"
                      *ngIf="
                        user.roleCode !== 'PR' &&
                        claims.claimXRefHolista.processingStatus.toLowerCase() === 'in-review'
                      ">
                      <i class="fas fa-sync"></i> Run Automation
                    </a>
                    <a
                      class="cursor-pointer dropdown-item"
                      (click)="onSendEmail(claims)"
                      *ngIf="user.roleCode !== 'PR'">
                      <i class="fa-fa fa-envelope fas"></i> Send Email
                    </a>
                    <a
                      (click)="deleteClaim(claims)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="
                        user.roleCode !== 'PR' &&
                        (claims.claimXRefHolista.status.toLowerCase() === 'invalid' ||
                          !claims.claimXRefHolista.episodeId) &&
                        !(
                          claims.claimXRefHolista.processingStatus.toUpperCase() ===
                            CLAIMS_PROCESSING_STATUS.PAYMENT_IN_PROCESS ||
                          claims.claimXRefHolista.processingStatus.toUpperCase() ===
                            CLAIMS_PROCESSING_STATUS.PAID
                        )
                      ">
                      <i class="icofont-ui-delete"></i> Delete</a
                    >
                    <a
                      (click)="view835Claim(claims)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="
                        (claims.claimXRefHolista.processingStatus.toLowerCase() ===
                          'payment-in-process' &&
                          claims.claimXRefHolista.ediData !== null) ||
                        (claims.claimXRefHolista.processingStatus.toLowerCase() === 'paid' &&
                          claims.claimXRefHolista.ediData !== null)
                      ">
                      <i class="far fa-eye fa-fw mr-1"></i>835 EDI</a
                    >
                    <a
                      (click)="goToPayment(claims)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="
                        user.roleCode !== 'PR' &&
                        claims.claimXRefHolista.processingStatus.toLowerCase() === 'ready-to-pay'
                      ">
                      <i class="fas fa-credit-card"></i> Add Payment</a
                    >
                    <a
                      (click)="goToEditPayment(claims)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="
                        user.roleCode !== ROLE_CODES.PROVIDER_ADMINISTRATOR &&
                        claims.claimXRefHolista.processingStatus.toUpperCase() ===
                          CLAIMS_PROCESSING_STATUS.PAYMENT_IN_PROCESS
                      ">
                      <i class="fas fa-credit-card"></i> Edit Payment</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="claimsList && claimsList.length"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <div
    class="text-center p-4 border mt-3"
    *ngIf="!loading.claims && (!claimsList || claimsList.length === 0)">
    <i class="fas fa-3x fa-notes-medical text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>

<!-- Delete Modal -->
<app-delete-confirmation
  [_id]="!isAutomationModal && claimId"
  [message]="deleteConfitmationMessage"
  (confirmDelete)="deleteClaimConfirm($event)">
</app-delete-confirmation>

<!-- Filter Modal -->
<div
  mdbModal
  #advanceSearchModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter Claims</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeAdvanceSearchModal('close')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <form [formGroup]="filterForm">
            <div class="form-row">
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="submitter">Choose Submitter</label>
                  <input
                    type="text"
                    [ngModel]="submitterSearchText | async"
                    id="submitter"
                    (ngModelChange)="submitterSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto1"
                    placeholder="Type to select submitter" />
                  <mdb-auto-completer
                    #auto1="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displaySubmitter"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'submitterId')">
                    <mdb-option *ngFor="let option of submitterResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.displayName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="patient">Choose Patient</label>
                  <input
                    type="text"
                    [ngModel]="patientSearchText | async"
                    id="patient"
                    (ngModelChange)="patientSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto2"
                    placeholder="Type to select patient" />
                  <mdb-auto-completer
                    #auto2="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayPatient"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'patientId')">
                    <mdb-option *ngFor="let option of patientResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.displayName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="episode">Choose Episode</label>
                  <input
                    type="text"
                    [ngModel]="episodeSearchText | async"
                    id="episode"
                    (ngModelChange)="episodeSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto3"
                    placeholder="Type to select episode" />
                  <mdb-auto-completer
                    #auto3="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayEpisode"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'episodeName')">
                    <mdb-option *ngFor="let option of episodeResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.episodeName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="payor">Choose Payor</label>
                  <input
                    type="text"
                    [ngModel]="payerSearchText | async"
                    id="payor"
                    (ngModelChange)="payerSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto4"
                    placeholder="Type to select payor" />
                  <mdb-auto-completer
                    #auto4="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayPayer"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'payerCode')">
                    <mdb-option *ngFor="let option of payerResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.payerName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="client">Choose Client</label>
                  <input
                    type="text"
                    [ngModel]="clientSearchText | async"
                    id="client"
                    (ngModelChange)="clientSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto5"
                    placeholder="Type to select client" />
                  <mdb-auto-completer
                    #auto5="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayClient"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'clientCode')">
                    <mdb-option *ngFor="let option of clientResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.clientName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="purchaser">Choose Purchaser</label>
                  <input
                    type="text"
                    [ngModel]="purchaserSearchText | async"
                    id="purchaser"
                    (ngModelChange)="purchaserSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto6"
                    placeholder="Type to select purchaser" />
                  <mdb-auto-completer
                    #auto6="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayPurchaser"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'purchaserCode')">
                    <mdb-option *ngFor="let option of purchaserResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.purchaserName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="billingInfo">Choose Billing Provider</label>
                  <input
                    type="text"
                    [ngModel]="billingProviderSearchText | async"
                    id="billingInfo"
                    (ngModelChange)="billingProviderSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto7"
                    placeholder="Type to select billing provider" />
                  <mdb-auto-completer
                    #auto7="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayBillingInfo"
                    [optionHeight]="100"
                    (selected)="onSearchResultSelected($event, 'billingProviderId')">
                    <mdb-option
                      *ngFor="let option of billingProviderResults | async"
                      [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.displayName | titlecase }}</strong>
                        <span>{{ option.identificationCode }}</span>
                        <span>
                          located in {{ option.city | titlecase }}{{ option.state ? ',' : '' }}
                          {{ option.state }}</span
                        >
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="subscriberNumber">Subscriber Number</label>
                  <input
                    placeholder="Enter subscriber number"
                    type="text"
                    mdbInput
                    class="form-control"
                    formControlName="subscriberNumber" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <div class="form-group">
                  <label for="processingstatus">Choose Processing Status</label>
                  <mdb-select
                    [options]="processingStatus"
                    id="processingstatus"
                    placeholder="Select processing status"
                    [multiple]="true"
                    [enableSelectAll]="false"
                    formControlName="processingStatus">
                  </mdb-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="renderingProvider">Choose Rendering Provider</label>
                  <input
                    type="text"
                    [ngModel]="renderingProviderSearchText | async"
                    id="renderingProvider"
                    (ngModelChange)="renderingProviderSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto8"
                    placeholder="Type to select rendering provider" />
                  <mdb-auto-completer
                    #auto8="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayRenderingProvider"
                    [optionHeight]="50"
                    (selected)="onSearchResultSelected($event, 'renderingProviderId')">
                    <mdb-option
                      *ngFor="let option of renderingProviderResults | async"
                      [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.displayName | titlecase }}</strong>
                        <span>{{ option.identificationCode }}</span>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row">
              <!-- <div class="col-6">
                                <div class="form-group">
                                    <label for="status">Choose Status</label>
                                    <mdb-select [options]="status" id="status" placeholder="Select status"
                                        [multiple]='false' [enableSelectAll]="false" formControlName="status">
                                    </mdb-select>
                                </div>
                            </div> -->
              <div class="col-6">
                <div class="form-group">
                  <label for="ediType">Choose EDI Type</label>
                  <mdb-select
                    [options]="ediType"
                    id="ediType"
                    placeholder="Select EDI type"
                    [multiple]="false"
                    [enableSelectAll]="false"
                    formControlName="ediType">
                  </mdb-select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeAdvanceSearchModal('close')">
          Cancel
        </button>
        <button class="btn btn-secondary waves-light m-0" mdbWavesEffect (click)="filterClaims(1)">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>

<div
  mdbModal
  #associateEpisodeModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          Associate Episode ({{ claim ? claim.patientAccountNumber : '' }})
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeAssociateModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <form [formGroup]="associateForm">
            <div class="form-group position-relative" *ngIf="!POLICY_CODES.includes(policyCode)">
              <label for="patients"
                >Choose Patient *
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loading.patient"></span
              ></label>
              <input
                type="text"
                class="completer-input form-control mdb-autocomplete mb-0"
                [ngModel]="associatePatientSearchText | async"
                (ngModelChange)="associatePatientSearchText.next($event)"
                [ngModelOptions]="{standalone: true}"
                [mdbAutoCompleter]="autos"
                placeholder="Type to search patient"
                [readonly]="disableAssociateEpisodeFields(claim?.claimXRefHolista)" />
              <mdb-auto-completer
                #autos="mdbAutoCompleter"
                textNoResults="No result found."
                [disabled]="disableAssociateEpisodeFields(claim?.claimXRefHolista)"
                (selected)="onPatientSelect($event)"
                [displayValue]="onDisplayAssociatePatient">
                <mdb-option *ngFor="let option of associatePatientResults | async" [value]="option">
                  <div
                    class="d-flex flex-column"
                    mdbTooltip="{{ option.firstName }} {{ option.lastName }}">
                    <strong
                      >{{ option.firstName }} {{ option.lastName }}
                      <small *ngIf="option.dob"
                        >({{ option.dob | date : 'MM/dd/yyyy' }})</small
                      ></strong
                    >
                  </div>
                </mdb-option>
              </mdb-auto-completer>
            </div>
            <div class="form-group" *ngIf="!POLICY_CODES.includes(policyCode)">
              <label for="episodes"
                >Choose Episode *
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loading.episode"></span
              ></label>
              <mdb-select
                class="search-dropdown"
                [options]="episodeDropdownList | sort : 'label'"
                [disabled]="
                  !episodeDropdownList.length ||
                  disableAssociateEpisodeFields(claim?.claimXRefHolista)
                "
                id="episodes"
                placeholder="Select episode"
                [multiple]="false"
                [enableSelectAll]="false"
                [highlightFirst]="false"
                (selected)="episodeSelected($event)"
                formControlName="episodeId"
                [ngClass]="{'error-select': submitted && associateForm.get('episodeId').invalid}">
              </mdb-select>
            </div>
            <div class="form-group">
              <label for="bundles"
                >Choose Bundle *
                <span
                  class="spinner-grow spinner-grow-sm search-dropdown"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loading.bundle"></span>
              </label>
              <mdb-select
                class="search-dropdown"
                (selected)="onBundleSelected(claim, $event, true)"
                [options]="bundleList | sort : 'label'"
                [disabled]="
                  !bundleList.length || disableAssociateEpisodeFields(claim?.claimXRefHolista)
                "
                id="bundles"
                placeholder="Select bundle"
                [multiple]="false"
                [enableSelectAll]="false"
                formControlName="bundleUuid"
                [ngClass]="{
                  'error-select': submitted && associateForm.get('bundleUuid').invalid
                }">
              </mdb-select>
            </div>
            <div class="form-group">
              <label for="bundleComponent">
                {{
                  formControls?.bundleComponentUuid.isRequired
                    ? 'Choose Bundle Component *'
                    : 'Choose Bundle Component'
                }}
                <span
                  class="spinner-grow spinner-grow-sm search-dropdown"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loading.bundleComponent"></span>
              </label>
              <mdb-select
                class="search-dropdown"
                [options]="bundleComponentList | sort : 'label'"
                [disabled]="!bundleComponentList.length"
                id="bundleComponent"
                placeholder="Select bundle component"
                [multiple]="true"
                formControlName="bundleComponentUuid"
                (selected)="bundleComponentSelected($event, true)"
                (deselected)="bundleComponentSelected($event, false)"
                [ngClass]="{
                  'error-select':
                    showValidationError &&
                    submitted &&
                    associateForm.get('bundleComponentUuid').invalid
                }">
              </mdb-select>
            </div>
            <div class="form-group" *ngIf="POLICY_CODES.includes(policyCode)">
              <label for="episodes">
                {{ episodeDropdownList.length ? 'Choose Episode *' : 'Choose Episode' }}
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loading.episode"></span
              ></label>
              <mdb-select
                [options]="episodeDropdownList | sort : 'label'"
                id="episodes"
                placeholder="Select episode"
                [multiple]="false"
                [enableSelectAll]="false"
                [highlightFirst]="false"
                (selected)="episodeSelected($event, false)"
                formControlName="episodeId"
                [ngClass]="{
                  'error-select':
                    showValidationError && submitted && associateForm.get('episodeId').invalid
                }"
                [disabled]="
                  !episodeDropdownList.length ||
                  disableAssociateEpisodeFields(claim?.claimXRefHolista)
                ">
              </mdb-select>
            </div>
            <div
              *ngIf="
                episode &&
                episode.clientCode &&
                episode.clientCode === 'NOVANT' &&
                episode.name &&
                episode.name.includes('Maternity')
              ">
              <mdb-checkbox formControlName="isNewBorn" [class.disabled]="setNewbornChecked"
                >Newborn Claim</mdb-checkbox
              >
            </div>
            <div
              class="alert border border-danger grey lighten-5 text-center"
              role="alert"
              *ngIf="!hasSameFundingRequest">
              <mdb-icon fas icon="exclamation-triangle" class="text-danger"></mdb-icon>
              Please select the components with same funding request.
            </div>
            <div
              class="alert border border-warning grey lighten-5 text-center"
              role="alert"
              *ngIf="message">
              <mdb-icon fas icon="exclamation-triangle" class="text-warning"></mdb-icon>
              {{ message }}
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeAssociateModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="validateClaimAssociation(claim)"
          [disabled]="!hasSameFundingRequest || disableSave">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading.saving"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Reject/Deny Modal -->
<div
  mdbModal
  #reasonModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Reason</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeReasonModal('cancel')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <form #statusForm="ngForm">
            <div class="form-group">
              <label for="reason">Choose Reason *</label>
              <app-select
                [options]="exceptionCodes"
                [id]="'exceptionCodes'"
                [(selected)]="statusModel.value"
                (onSelected)="reasonSelected($event)"
                [placeholder]="'Select reason'"
                [isTemplateDriven]="true">
              </app-select>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeReasonModal('cancel')">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="onSave()"
          [disabled]="!statusModel.statusCode">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading.saving"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<!--In-review modal-->
<div
  mdbModal
  #confirmationModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <!--Body-->
      <div class="modal-body">
        <p class="mb-0 text-center" [innerHTML]="modalWarningMessage"></p>
        <div class="p-0 mt-4 text-center">
          <button
            class="btn btn-secondary waves-light"
            mdbWavesEffect
            (click)="isAutomationModal ? onProceedAutomation() : onSave()">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="loading.saving"></span>
            {{ isAutomationModal ? 'Proceed' : 'Save' }}
          </button>
          <button
            class="btn btn-light waves-light"
            mdbWavesEffect
            (click)="closeConfirmationModal()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [moduleOptions]="moduleOptions"
  [metaVariablesDetail]="metaVariablesDetail"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->
