import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CometChat } from '@cometchat-pro/chat';
import { ChatService, NotificationService, AuthService, UserService, EpisodeService, ClientService, IdleTimerService, HttpClientService } from '../../../services';
import * as moment from 'moment';
import * as Actions from '../../../action';
import { Subject } from 'rxjs';
import { Storage } from '../../../utils';
import { HttpHeaders } from "@angular/common/http";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { idleTimeCountdown, idleLogoutTimeCountdown, COMETCHAT_USER } from '../../../constants';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-layout-main',
  templateUrl: './layout-main.component.html',
  styleUrls: ['./layout-main.component.scss']
})
export class LayoutMainComponent implements OnInit, OnDestroy {
  messages: CometChat.TextMessage[] | null = null;
  activeUser: CometChat.UserObj;
  selectedUser: any;
  http: HttpClientService
  chatUsers = [];
  searchUsers: any = [];
  usersList: any = [];
  // episodeUsers: any;
  userDetails = [];
  userMessages: any = [];
  user: any;
  typingText: string;
  //for call
  incomingCall: any;
  outgoingCall: any;
  rejectedCall: any;
  loading = true;
  sidenavStatus;
  callSessionId: any;
  userCallStatus: any;
  hideCallScreen: boolean = true;
  listenerId: string;
  messageCount: any;
  callRingtone: any;
  searchKeyword = '';
  userRoles: any = [];
  searchKeywordChanged: Subject<string> = new Subject<string>();
  episodes = [];
  incomingRingtone: any;
  outgoingRingtone: any;
  minimizeScreen: boolean = false;
  theme: any;
  chatUsers_loading = false;
  showChatUsers = false;
  unreadUserMessages: any = [];
  isMobile: boolean = false;
  isUploadingFile: boolean = false;
  isMessageSent: boolean = false;
  idleLogoutTimer: any;

  @ViewChild('inactiveLogout', { static: true }) public inactiveLogout;
  // @ViewChild('frame', { static: true }) public frame;

  // draggable inbound
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  checkEdge(event) {
    this.edge = event;
  }

  constructor(
    private store: Store<{ sidenav_status: string; user: any; chatUsers: any; chatUserLoading: any, user_message: any }>,
    readonly chatService: ChatService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private userService: UserService,
    private episodeService: EpisodeService,
    private _storage: Storage,
    private deviceService: DeviceDetectorService,
    private clientService: ClientService,
    private _idleTimerService: IdleTimerService
  ) {
    store.select('sidenav_status').subscribe((res: any) => {
      this.sidenavStatus = res[0];
    });

    store.select('user_message').subscribe(res => {
      this.unreadUserMessages = res
    });

    store.select('chatUserLoading').subscribe(res => {
      if (res) this.chatUsers_loading = true;
    });

    store.select('chatUsers').subscribe(res => {
      this.chatUsers_loading = false;
      this.store.dispatch(new Actions.ChatUsersLoad(false));
      res.map(x => {
        x.conversationWith.role = x.conversationWith.role.toUpperCase();
        x.conversationWith.lastMessage = x.lastMessage;
        if ((x.conversationWith.role !== 'PR' && this.user.roleCode !== 'CLA') || this.user.roleCode === 'CLA') {
          const userFound = this.usersList.find(user => user.uid == x.conversationWith.uid);
          if (!userFound) this.usersList.push(x.conversationWith);
        }
      });
      this.usersList.map(x => {
        this.setUserRoleName(x)
        return x;
      });
    });

    store.select('user').subscribe(res => {
      if (res && res.uid) {
        CometChat.getUser(res.uid.toString()).then(
          (user: any) => {
            const status = user.status === 'online' ? true : false;
            this.updateChatUser(user.uid, status);
          },
          error => {
            console.log('User details fetching failed with error:', error);
          }
        );
        this.receiveMessageRead(res.uid);
      }
    });
    this.searchKeywordChanged
      .pipe(
        debounceTime(300), // wait 300ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe(model => {
        this.searchKeyword = model;
        this.onSearchChange(this.searchKeyword);
      });
  }

  startUserIdleTimer() {
    this._idleTimerService.startUserIdleTimer({
      timeout: idleTimeCountdown,
      onTimeout: () => {
        this.inactiveLogout.show();
        this._storage.set('local', 'isInactiveModalShown', true);
        this.idleLogoutTimer = setTimeout(() => {
          this.idleUserLogout();
        }, idleLogoutTimeCountdown)
      }
    });
  }

  async ngOnInit() {
    const otpToken = this._storage.get('session', 'otpToken');
    if (window.addEventListener) {
      window.addEventListener("storage", this.controlInactiveTabs, false);
    }
    this.theme = this._storage.get('local', 'UItheme');
    this.isMobile = this.deviceService.isMobile();
    !otpToken && this.startUserIdleTimer();
    this.getRoles();
    this.callRingtone = new Audio('../../../assets/messenger.mp3');
    const loggedInUser = this._storage.get('local', 'loggedInUser')
    this.user = loggedInUser && this._storage.get('local', 'loggedInUser', 'user');
    if (this.user && this.user.referenceType == 'CLIENT') {
      this.clientService.getPurchasersByClientCode(this.user.referenceCode, { defaultIncludes: false, limit: 0 }).subscribe(res => {
        this.user.purchaserCodes = res.rows.map(p => p.code)
      })
    }
    this.user && this.notificationService.emit('user_info', this.user);
    this.notificationService.listen('question_answer').subscribe(question_answer_notification => { });
    let status = this.user && await this.chatService.getStatus(this.user.id);

    const userMessages = this._storage.get('session', 'userMessages');
    if (this.user && userMessages) {
      this.showChatUsers = true
      setTimeout(() => {
        userMessages.map(x => {
          this.chatService.getPreviousMessages(x.uid).then((messages: any) => {
            x.messages = messages;
            x.typingText = '';
          });
          this.userMessages.push(x);
        });
      }, 100);
    }
    this.listenerId = this.user && `${this.user.id}${new Date().getTime()}`;
    // this.chatUsers = await this.chatService.fetchUsers()
    let prevConversation: any = this.user && await this.chatService.getPreviousConversation();
    prevConversation && prevConversation.map(x => {
      x.conversationWith.role = x.conversationWith.role.toUpperCase();
      x.conversationWith.lastMessage = x.lastMessage;
      if (x.conversationWith.lastMessage.category == 'call' && x.conversationWith.lastMessage.action == 'ended') {
        if (!x.conversationWith.lastMessage.readAt) {
          this.readMessage(x.conversationWith.lastMessage);
          x.conversationWith.lastMessage.readAt = Date.now();
        }
        if (!x.conversationWith.lastMessage.deliveredAt)
          x.conversationWith.lastMessage.deliveredAt = x.conversationWith.lastMessage.readAt ? x.conversationWith.lastMessage.readAt : Date.now();
      }
      if ((x.conversationWith.role !== 'PR' && this.user && (this.user.roleCode !== 'CLA') || this.user.roleCode === 'CLA')) this.chatUsers.push(x.conversationWith);
    });
    //for dev environment when trial ends, uncommment this and run the application changing appid and apikey of cometchat just in header of userCreate()
    // this.chatUsers.forEach(async user => {
    //   let body = { name: user.name, role: user.role, metadata: user.metadata, uid: user.uid }
    //   await this.chatService.userCreate(body)
    //     .subscribe(createduser => {
    //     });
    // });
    this.usersList = [...this.chatUsers];
    // for Message Count
    let userObj: any;
    this.messageCount = this.user && await this.chatService.getUnreadMessageCount();
    if (this.messageCount && this.messageCount.users && Object.keys(this.messageCount.users).length > 0) {
      this.userDetails = [];
      for (var key in this.messageCount.users) {
        userObj = await CometChat.getUser(key.toString());
        userObj.count = this.messageCount.users[key];
        this.userDetails.push(Object.assign({}, userObj));

        // userObj = await this.chatService.getUser(key);
        // userObj.data.count = this.messageCount.users[key];
        // this.userDetails.push(Object.assign({}, userObj.data));
      }
      this.userDetails.map(detail => {
        this.usersList.map(usrs => {
          if (detail.uid == usrs.uid) {
            if (usrs.lastMessage) {
              detail.lastMessage = usrs.lastMessage;
            }
          }
        });
        return detail;
      });
      // to get unread message notification
      this.store.dispatch(new Actions.GetUnreadMessageUserList(this.userDetails));
    } else {
      this.userMessages.map(x => {
        x.count = 0;
        x.unreadMessageCount = 0;
        return x;
      });
    }
    this.usersList = this.usersList.map(x => {
      this.setUserRoleName(x)
      this.userMessages.map(usr => {
        if (!usr.minimize) this.scrollChatToBottom(usr.uid);
        this.userLeftCall(usr);
        if (usr.uid == x.uid) usr.status = x.status;
        return usr;
      });
      return x;
    });
    this.loading = false;
    if (this.showChatUsers && this.usersList.length > 0) this.onUserSelected(this.usersList[0])
    // if (this.user.primaryRole && (this.user.primaryRole == 'MP' || this.user.primaryRole == 'CC' || this.user.primaryRole == 'ES')) {
    //   let body = { roleCode: this.user.primaryRole, id: this.user.id };
    //   this.episodeUsers = await this.episodeService.getEpisodeUsers(body).then((res: any) => {
    //     return res.data;
    //   });
    // }
    this.user && this.chatService.fetchUserMessagesWhenOffline(this.user.id.toString());
    this.user && this.chatService.listenForMessages(
      this.user.id,
      msg => {
        // this.showChatUsers = true
        this.appendUserMessages(msg);
      },
      status => {
        this.changeTypingStatus(status.sender.uid, true);
      },
      endStatus => {
        this.changeTypingStatus(endStatus.sender.uid, false);
      },
      mediaMsg => {
        // this.showChatUsers = true
        this.appendUserMessages(mediaMsg);
      },
      deliveredMessage => {
        this.userMessages.map(x => {
          x.messages.map(y => {
            if (y.id === deliveredMessage.messageId) {
              y.deliveredAt = deliveredMessage.deliveredAt;
            }
            return y;
          });
        });
      },
      readMessage => {
        this.userMessages.map(x => {
          x.messages.map(y => {
            if (y.id === readMessage.messageId) {
              y.readAt = readMessage.readAt;
              if (!y.deliveredAt) y.deliveredAt = readMessage.readAt;
            }
            return y;
          });
        });
        const userIndex = this.usersList.findIndex(x => x.uid == readMessage.sender.uid);
        if (userIndex > -1) {
          if (this.usersList[userIndex].lastMessage && this.usersList[userIndex].lastMessage.id == readMessage.messageId) {
            this.usersList[userIndex].lastMessage.readAt = readMessage.readAt;
            if (!this.usersList[userIndex].lastMessage.deliveredAt) this.usersList[userIndex].lastMessage.deliveredAt = readMessage.readAt;
          }
        }
      },
      deletedMessage => {
        // console.log('Message deleted', deletedMessage);
      }
    );
    // Call Listener
    this.user && this.chatService.listenForCall(
      this.listenerId,
      incomingCall => {
        if (this.user && this.user.id == incomingCall.receiverId) {
          if (this.callSessionId && this.callSessionId != incomingCall.sessionId) {
            CometChat.sendBusyResponse(incomingCall.sessionId);
          } else {
            this.showChatUsers = true
            this.minimizeScreen = false;
            const callscreen: any = document.getElementById('callscreen');
            callscreen.style.display = 'block';
            this.playRingTone('incoming');
            this.incomingCall = incomingCall;
            this.callSessionId = incomingCall.sessionId;
            const index = this.userMessages.findIndex(x => x.uid == incomingCall.receiverId);
            if (index == -1) {
              this.onUserSelected(incomingCall.sender);
            } else {
              this.userMessages[index].messages = [...this.userMessages[index].messages, incomingCall as any];
            }
            const userIndex = this.usersList.findIndex(x => x.uid == incomingCall.sender.uid);
            if (userIndex > -1) {
              incomingCall.readAt = Date.now();
            }
            this.setUserTop(userIndex, incomingCall, incomingCall.sender);
            this._storage.set('session', 'userMessages', this.userMessages);
          }
        }
      },
      cancelledCall => {
        this.mapUserMessageCallCancelOrReject(cancelledCall);
        if (cancelledCall.action == 'cancelled') {
          clearInterval(this.incomingRingtone);
          this.setCallStatus('session', 'callscreen');
          this.userCallStatus = 'Call Cancelled';
        } else if (cancelledCall.action == 'unanswered') {
          clearInterval(this.incomingRingtone);
          this.setCallStatus('session', 'callscreen');
          this.userCallStatus = 'No Answer';
        }
      },
      acceptCall => {
        this.hideCallScreen = false;
        this.setCallStatus();
        this.callStart(acceptCall);
      },
      rejectCall => {
        this.mapUserMessageCallCancelOrReject(rejectCall);
        if (rejectCall.action == 'busy') {
          clearInterval(this.outgoingRingtone);
          setTimeout(() => {
            this.setCallStatus('session', 'callscreen');
            this.userCallStatus = 'User busy';
          }, 500);
        } else if (rejectCall.action == 'rejected') {
          clearInterval(this.outgoingRingtone);
          clearInterval(this.incomingRingtone);
          this.setCallStatus('session', 'callscreen');
          this.userCallStatus = 'Call Rejected';
        } else if (rejectCall.action == 'unanswered') {
          clearInterval(this.outgoingRingtone);
          this.setCallStatus('session', 'callscreen');
          this.userCallStatus = 'No Answer';
        }
      }
    );

    // User Listener
    this.user && CometChat.addUserListener(
      this.listenerId,
      new CometChat.UserListener({
        onUserOnline: onlineUser => {
          this.updateChatUser(onlineUser.uid, true);
          // console.log(onlineUser, 'ONLINE')
        },
        onUserOffline: offlineUser => {
          this.updateChatUser(offlineUser.uid, false);
          // console.log(offlineUser, 'OFFLINE')
        }
      })
    );
    this.store.dispatch(new Actions.EnableChat());
  }

  /**
   * whether to logout or not in inactive tabs
   */
  private controlInactiveTabs = () => {
    const loggedInUser = this._storage.get('local', 'loggedInUser');
    const isInactiveModalShown = this._storage.get('local', 'isInactiveModalShown');
    !loggedInUser && this.idleUserLogout();
    if (!isInactiveModalShown) {
      this.inactiveLogout.hide();
      clearTimeout(this.idleLogoutTimer);
    } else {
      this.inactiveLogout.show();
      this.startUserIdleTimer();
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener("storage", this.controlInactiveTabs, false);
  }

  idleUserLogout() {
    this.authService.logout();
  }

  async readMessage(msg) {
    if (!msg.readAt) CometChat.markAsRead(msg.id, msg?.sender?.uid, msg.receiverType, msg?.sender?.uid);
  }

  updateChatUser(uid, action) {
    if (this.userMessages.length > 0) {
      this.userMessages.map(x => {
        if (x.uid == uid) {
          x.status = action ? 'online' : 'offline';
        }
        return x;
      });
    }
    if (this.usersList.length > 0) {
      this.usersList = JSON.parse(JSON.stringify(this.usersList));
      this.usersList.map(y => {
        if (y.uid == uid) {
          y.status = action ? 'online' : 'offline';
        }
        return y;
      });
    }
  }

  appendUserMessages(msg) {
    this.chatService.getUnreadMessageCount().then(async (users: any) => {
      let idFound = false;
      // for last msg notification
      const lastMessage = { lastMessage: msg, uid: msg.sender.uid, name: msg.sender.name, count: users.users[`${msg.sender.uid}`] };
      this.userMessages.map(x => {
        if (x.uid == msg.sender.uid) {
          idFound = true;
          let msgIndex = x.messages.findIndex(x => x.id == msg.id);
          if (msgIndex == -1) x.messages = [...x.messages, msg];
          x.typingText = '';
          let count = users.users[`${x.uid}`];
          x.count = count;
          // Object.assign(x, { unreadMessageCount: count });
          // msg.sender.count = count;
          if (!x.minimize && this.showChatUsers && this.user.id == msg.receiverId) {
            this.readMessage(msg);
            msg.readAt = Date.now();
            x.unreadMessageCount = 0;
            x.count = 0;
            this.scrollChatToBottom(msg.sender.uid);
            // this.store.dispatch(new Actions.MessageNotification(msg.sender));
          }
        } else {
          if (this.user && this.user.id.toString() == msg.receiverId) this.store.dispatch(new Actions.UpdateUserMessage(lastMessage));
        }
        return x;
      });
      // update lastmessage in searchUsers
      const searchUserIndex = this.searchUsers.findIndex(user => user.uid == msg.sender.uid)
      if (searchUserIndex > -1) {
        const modifiedUser = { ...this.searchUsers[searchUserIndex], lastMessage: msg };
        this.searchUsers[searchUserIndex] = modifiedUser;
      }
      this._storage.set('session', 'userMessages', this.userMessages);
      const userIndex = this.usersList.findIndex(x => x.uid == msg.sender.uid);
      this.setUserTop(userIndex, msg, msg.sender);
      if (!this.userMessages || this.userMessages.length == 0) {
        if (this.user && this.user.id.toString() == msg.receiverId) this.store.dispatch(new Actions.UpdateUserMessage(lastMessage));
      }
      // if (!idFound && this.user && this.user.id.toString() == msg.receiverId) {
      //   this.onUserSelected(msg.sender, msg);
      // }
    });
  }

  setUserTop(userIndex, message, sender?) {
    if (userIndex > -1) {
      this.usersList[userIndex] = JSON.parse(JSON.stringify(this.usersList[userIndex]));
      this.usersList[userIndex].lastMessage = message;
      if (userIndex != 0) {
        this.usersList.splice(0, 0, this.usersList[userIndex]);
        this.usersList.splice(userIndex + 1, 1);
      }
    } else if (userIndex == -1) {
      if (sender.uid !== this.user.id.toString()) {
        sender.minimize = false;
        this.setUserRoleName(sender)
        if (message) {
          // message.readAt = Date.now();
          sender.lastMessage = JSON.parse(JSON.stringify(message));
        }
        this.usersList = [sender, ...this.usersList];
      }
    }
  }

  setUserRoleName(user) {
    if (user.metadata && user.metadata.roles) {
      user.roleName = this.userRoles.filter(list => user.metadata.roles.find(role => role.roleCode == list.code)).map(x => x.name.trim())
    } else {
      this.userRoles.map(role => {
        if (role.code === user.role.toUpperCase()) {
          user.roleName = [role.name];
          const modifiedUser = { ...user, roleName: [role.name] };
          user = modifiedUser;
        }
      });
    }
  }

  onUserSelected(selected_user: CometChat.UserObj) {
    const index = this.usersList.findIndex(x => x.uid === selected_user.uid);
    if (index > -1) {
      if (this.usersList[index].lastMessage && this.usersList[index].lastMessage.receiverId == this.user.id && !this.usersList[index].lastMessage.readAt) {
        this.usersList[index] = JSON.parse(JSON.stringify(this.usersList[index]));
        this.usersList[index].lastMessage.readAt = Date.now();
      }
    }
    this.selectedUser = selected_user;
    let user = JSON.parse(JSON.stringify(selected_user));
    this.chatService.getPreviousMessages(user.uid).then((messages: any) => {
      // Mark message as read
      if (!user.minimize) {
        this.markAsRead(messages);
      }
      user.messages = messages.filter(msg => msg.type === 'text' || msg.type === 'file' || msg.type === 'audio' || msg.type === 'video');
      user.minimize = false;
      user.message = '';
      user.typingText = '';
      user.file = null;
      const index = this.userMessages.findIndex(x => x.uid == selected_user.uid);
      if (index == -1) {
        let usrIndex = this.usersList.findIndex(x => x.uid == selected_user.uid);
        if (usrIndex > -1) user.status = this.usersList[usrIndex].status;
        this.userMessages = []
        this.userMessages.push(user);
      } else {
        const msgIndex = this.userMessages[index];
        msgIndex.messages = messages.filter(msg => msg.type === 'text' || msg.type === 'file' || msg.type === 'audio' || msg.type === 'video');
        if (msgIndex.minimize) {
          this.markAsRead(messages);
          msgIndex.unreadMessageCount = 0;
          msgIndex.count = 0;
          msgIndex.minimize = false;
        }
      }
      if (this.userMessages && this.userMessages.length > 0) {
        this.userMessages.map(usr => {
          this.userLeftCall(usr);
        });
      }
      this.store.dispatch(new Actions.ReadMessage(selected_user));
      this._storage.set('session', 'userMessages', this.userMessages);
      setTimeout(() => {
        this.scrollChatToBottom(user.uid.toString());
      });
    });
  }

  userLeftCall(usr) {
    usr.messages.map(msg => {
      if (msg.category == 'call' && msg.action == 'ended') {
        if (!msg.readAt) {
          this.readMessage(msg);
          msg.readAt = Date.now();
        }
        if (!msg.deliveredAt) msg.deliveredAt = msg.readAt ? msg.readAt : Date.now();
      }
    });
  }

  markAsRead(messages) {
    messages.forEach(element => {
      if (!element.readAt && this.user.id == element.receiverId) {
        this.readMessage(element);
      }
    });
  }

  async sendMessage(uid, message, index) {
    this.isMessageSent = true;
    if (message && message.length > 1 && !message.name) {
      this.userMessages.map(x => {
        if (x.uid == uid) {
          x.message = '';
        }
        return x;
      });
      const sentMessage = await this.chatService.sendMessage(uid, message);
      if (sentMessage) {
        this.userMessages.map(x => {
          if (x.uid == uid) {
            x.messages = [...x.messages, sentMessage as any];
          }
          return x;
        });
        const userIndex = this.usersList.findIndex(user => user.uid == sentMessage.receiverId);
        this.setUserTop(userIndex, sentMessage, sentMessage.receiver);
      }
    } else if (message && message.name) {
      this.isUploadingFile = true;
      const sentFile = await this.chatService.sendFile(uid, message);
      if (sentFile) {
        this.userMessages.map(x => {
          if (x.uid == uid) {
            x.messages = [...x.messages, sentFile as any];
            this.removeAttachFile(x);
          }
          return x;
        });
        const userIndex = this.usersList.findIndex(user => user.uid == sentFile.receiverId);
        this.setUserTop(userIndex, sentFile, sentFile.receiver);
        let body = {
          documentPath: sentFile.data.attachments[0].url,
          displayName: sentFile.data.attachments[0].name,
          userIds: [sentFile.sender.uid, sentFile.receiver.uid]
        };
        this.userService.saveUserDocument(body).subscribe(
          () => { },
          err => {
            console.log('error while saving user document', err);
          }
        );
        this.isUploadingFile = false;
      }
    }
    this.scrollChatToBottom(uid);
    this.userMessages[index].message = '';
    this._storage.set('session', 'userMessages', this.userMessages);
  }

  /* For Call */
  initiateCall(userMessage, type) {
    let callUser = this.usersList.find(x => x.uid == userMessage.uid);
    if (!callUser) this.usersList = [userMessage, ...this.usersList]
    let receiver = this.usersList.find(x => x.uid == userMessage.uid);
    const callscreen: any = document.getElementById('callscreen');
    if (receiver && receiver.status == 'online') {
      this.chatService.initiateCall(userMessage.uid, type).then(
        (outgoingCall: any) => {
          this.playRingTone('outgoing');
          this.minimizeScreen = false;
          this.callSessionId = outgoingCall.sessionId;
          callscreen.style.display = 'block';
          this.outgoingCall = outgoingCall;
          this.mapUserMessages(outgoingCall);
          // perform action on success. Like show your calling screen.
        },
        error => {
          console.log('Call initialization failed with exception:', error);
          // in case of user busy, in another call, or not available
          this.userCallStatus = error.message;
          if (error.code === 'CALL_IN_PROGRESS') {
            // this.closeModal();
          } else if (error.code === 'ERR_CALL_TERMINATED') {
            // this.closeModal();
          }
        }
      );
    } else {
      this.userCallStatus = 'User not available';
      callscreen.style.display = 'block';
    }
  }

  playRingTone(action) {
    const ringtone = action + 'Ringtone';
    const audio: any = document.getElementById(ringtone);
    this[ringtone] = setInterval(function () {
      audio.autoplay = true;
      audio.play();
      audio.muted = false;
    }, 1000);
  }

  public callStart(call: any) {
    clearInterval(this.incomingRingtone);
    clearInterval(this.outgoingRingtone);
    this.hideCallScreen = false;
    setTimeout(() => {
      const sessionId = call.sessionId;
      const callType = call.type;
      const callSettings = new CometChat.CallSettingsBuilder()
        .setSessionID(sessionId)
        .enableDefaultLayout(true)
        .setIsAudioOnlyCall(callType == 'audio' ? true : false)
        .build();
      CometChat.startCall(
        callSettings,
        document.getElementById('callScreen'),
        new CometChat.OngoingCallListener({
          onUserJoined: user => {
            // Notification received here if another user joins the call.
            // this method can be use to display message or perform any actions if someone joining the call
          },
          onUserLeft: user => {
            // Notification received here if another user left the call. /
            CometChat.endCall(call.sessionId).then(
              call => {
                // console.log('call ended', call);
              },
              error => {
                console.log('error', error);
              }
            );
            this.setCallStatus();
            // this method can be use to display message or perform any actions if someone leaving the call /
          },
          onCallEnded: call => {
            // Notification received here if current ongoing call is ended. /
            this.userCallStatus = 'Call Ended';
            this.setCallStatus('session', 'callscreen');
            // this.closeModal();
            this.userMessages.map(x => {
              const msgIndex = x.messages.findIndex(a => a.id == call.id);
              if (x.uid == call.receiverId) {
                if (msgIndex == -1) {
                  x.messages = [...x.messages, call as any];
                }
                if (this.user.id == call.sender.uid) {
                  this.readMessage(call);
                  call.readAt = Date.now();
                  x.unreadMessageCount = 0;
                  x.count = 0;
                  this.store.dispatch(new Actions.ReadMessage(call.receiver));
                  this._storage.set('session', 'userMessages', this.userMessages);
                }
              } else if (x.uid == call.sender.uid) {
                if (msgIndex == -1) {
                  x.messages = [...x.messages, call as any];
                }
                if (this.user.id == call.receiverId) {
                  this.readMessage(call);
                  call.readAt = Date.now();
                  x.unreadMessageCount = 0;
                  x.count = 0;
                  this.store.dispatch(new Actions.ReadMessage(call.sender));
                  this._storage.set('session', 'userMessages', this.userMessages);
                  this.scrollChatToBottom(call.sender.uid);
                }
                // console.log('MSG INDEX', msgIndex);
              } else {
                // this.onUserSelected(call.sender);
              }
              return x;
            });
            let callReceiverId: any;
            if (this.user.id == call.receiver.uid) {
              callReceiverId = call.sender.uid;
            } else {
              callReceiverId = call.receiverId;
            }
            const userIndex = this.usersList.findIndex(user => user.uid == callReceiverId);
            this.setUserTop(userIndex, call, call.sender);
            // hiding/closing the call screen can be done here. /
          }
        })
      );
    }, 100);
  }

  setCallStatus(session?, callscreen?) {
    this.incomingCall = null;
    this.outgoingCall = null;
    if (session) {
      this.callSessionId = null;
    }
    if (callscreen) {
      this.hideCallScreen = true;
    }
  }

  public acceptCall(sessionId: string) {
    CometChat.acceptCall(sessionId).then(
      (call: any) => {
        this.setCallStatus();
        this.callStart(call);
        // start the call using the startCall() method
      },
      error => {
        console.log('Call acceptance failed with error', error);
        // handle exception
      }
    );
  }

  public rejectCall(sessionId: string) {
    var status = CometChat.CALL_STATUS.REJECTED;
    CometChat.rejectCall(sessionId, status).then(
      call => {
        clearInterval(this.incomingRingtone);
        this.setCallStatus('session');
        this.readMessage(call);
        if (!call.readAt) call.readAt = Date.now();
        this.mapUserMessages(call);
        const callscreen: any = document.getElementById('callscreen');
        callscreen.style.display = 'none';
      },
      error => {
        console.log('Call rejection failed with error:', error);
      }
    );
  }

  public endInitiatedCall(sessionId: string) {
    CometChat.cancelCall(this.callSessionId).then(
      call => {
        clearInterval(this.outgoingRingtone);
        this.setCallStatus('session');
        // this.userCallStatus = 'Call cancelled by the user.';
        // this.mapUserMessageCallCancelOrReject(call);
        if (!call.readAt) call.readAt = Date.now();
        this.mapUserMessages(call);
        const callscreen: any = document.getElementById('callscreen');
        callscreen.style.display = 'none';
      },
      error => {
        console.log('Call rejection failed with error:', error);
      }
    );
  }

  public cancelCall() {
    CometChat.cancelCall(this.callSessionId).then(
      call => {
        clearInterval(this.outgoingRingtone);
        this.outgoingCall = null;
        this.mapUserMessages(call);
        const callscreen: any = document.getElementById('callscreen');
        callscreen.style.display = 'none';
      },
      error => {
        console.log('Call rejection failed with error:', error);
      }
    );
  }
  /* For Call */

  handleInputChange(e, user) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
      this.userMessages.map(x => {
        if (x.uid == user.uid) x.file = file;
        let inputDiv = document.getElementById(`message${user.uid}`);
        if (inputDiv) inputDiv.focus();
      });
    }
  }

  getTime(timeStamp) {
    return moment.unix(timeStamp).format('hh:mm A');
  }

  closeChat(uid) {
    let index = this.userMessages.findIndex(x => x.uid == uid);
    if (index > -1) {
      this.userMessages.splice(index, 1);
      this._storage.set('session', 'userMessages', this.userMessages);
    }
  }

  onTyping(user) {
    this.isMessageSent = false;
    const metadata = {
      text: 'Typing'
    };
    const receiverType = CometChat.RECEIVER_TYPE.USER;
    const typingNotification = new CometChat.TypingIndicator(user.uid, receiverType, metadata);
    CometChat.startTyping(typingNotification);
  }
  scrollChatToBottom(uid) {
    var objDiv = document.getElementById(`chat${uid}`);
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  }

  changeTypingStatus(uid, state) {
    let index = this.userMessages.findIndex(x => x.uid == uid);
    if (index > -1) {
      this.userMessages[index].typingText = state ? 'Typing...' : '';
    }
  }

  closeModal() {
    const callscreen: any = document.getElementById('callscreen');
    callscreen.style.display = 'none';
    if (this.outgoingCall) {
      this.setCallStatus();
      this.cancelCall();
    } else if (this.incomingCall) {
      this.setCallStatus();
      this.rejectCall(this.callSessionId);
    }
  }

  minimizeChat(uid) {
    this.userMessages.map(x => {
      if (x.uid == uid) {
        x.minimize = !x.minimize;
        if (!x.minimize) {
          x.messages.map(y => {
            if (!y.readAt && this.user.id == y.receiverId) {
              this.readMessage(y);
              y.readAt = Date.now();
              x.unreadMessageCount = 0;
              x.count = 0;
              this.store.dispatch(new Actions.ReadMessage(x));
            }
            if (y.readAt && !y.deliveredAt) {
              y.deliveredAt = y.readAt;
            }
          });
          const userIndex = this.usersList.findIndex(x => x.uid == uid);
          if (userIndex > -1) {
            if (this.usersList[userIndex].lastMessage && this.usersList[userIndex].lastMessage.receiverId == this.user.id && !this.usersList[userIndex].lastMessage.readAt)
              this.usersList[userIndex].lastMessage.readAt = Date.now();
          }
        } else {
          if (!x.message) x.file = null;
        }
      }
      return x;
    });
    setTimeout(() => {
      this.scrollChatToBottom(uid);
    }, 100);
    this._storage.set('session', 'userMessages', this.userMessages);
  }

  mapUserMessages(call) {
    this.userMessages.map(x => {
      if (x.uid == call.receiverId) {
        let msgIndex = x.messages.findIndex(a => a.id == call.id);
        if (msgIndex == -1) {
          x.messages = [...x.messages, call as any];
          x.unreadMessageCount = 0;
          x.count = 0;
          this._storage.set('session', 'userMessages', this.userMessages);
        }
      }
      return x;
    });
    const userIndex = this.usersList.findIndex(user => user.uid == call.receiverId);
    this.setUserTop(userIndex, call, call.sender);
  }

  mapUserMessageCallCancelOrReject(call) {
    this.readMessage(call);
    if (!call.readAt) call.readAt = Date.now();
    this.userMessages.map(x => {
      const msgIndex = x.messages.findIndex(a => a.id == call.id);
      if (x.uid == call.sender.uid) {
        if (msgIndex == -1) {
          x.messages = [...x.messages, call as any];
          x.unreadMessageCount = 0;
          x.count = 0;
          this._storage.set('session', 'userMessages', this.userMessages);
        }
      } else if (x.uid == call.receiverId) {
        if (msgIndex == -1) {
          x.messages = [...x.messages, call as any];
          x.unreadMessageCount = 0;
          x.count = 0;
          this._storage.set('session', 'userMessages', this.userMessages);
        }
      }
      return x;
    });
    const userIndex = this.usersList.findIndex(user => user.uid == call.sender.uid || user.uid == call.receiverId);
    this.setUserTop(userIndex, call, call.sender);
  }

  receiveMessageRead(uid) {
    let idFound = false;
    this.showChatUsers = true
    this.userMessages.map(x => {
      if (x.uid == uid) {
        idFound = true;
        x.minimize = false;
        x.messages.forEach(y => {
          if (!y.readAt && this.user.id == y.receiverId) {
            this.readMessage(y);
            y.readAt = Date.now();
            x.count = 0;
            x.unreadMessageCount = 0;
            this.store.dispatch(new Actions.ReadMessage(x));
          }
        });
      }
      this._storage.set('session', 'userMessages', this.userMessages);
      return x;
    });
    if (!idFound) {
      let chatUserIndex = this.usersList.findIndex(x => x.uid === uid);
      if (chatUserIndex > -1) {
        this.onUserSelected(this.usersList[chatUserIndex]);
      } else {
        CometChat.getUser(uid.toString()).then(
          (user: any) => {
            this.setUserRoleName(user)
            // const roleName = user.data.role === 'cc' ? 'Care Cordinator' : user.data.role === 'es' ? 'Engagement Specialist' : user.data.role === 'ca' ? 'Client Administrator' : 'Super Administrator'
            // user.data.roleName = roleName
            this.onUserSelected(user);

          },
          error => {
            console.log('User details fetching failed with error:', error);
          }
        );
      }
    } else {
      setTimeout(() => {
        this.scrollChatToBottom(uid);
      });
    }
  }

  KeepMeLogged() {
    this.startUserIdleTimer();
    this.inactiveLogout.hide();
    this._storage.remove('local', 'isInactiveModalShown');
    clearTimeout(this.idleLogoutTimer);
  }

  change(text) {
    this.searchKeywordChanged.next(text);
  }
  async onSearchChange(searchKey: string) {
    if (searchKey.length > 0) {
      this.searchUsers = [];
      this.loading = true;
      await this.chatService.searchUsers(searchKey).then((result: any) => {
        this.searchUsers = [];
        this.loading = false;
        // if (this.user.roleCode !== 'CLA') result = result.filter(r => r.role.toUpperCase() !== 'PR');
        result.map(x => {
          this.usersList.map(y => {
            if (x.uid == y.uid) {
              x.lastMessage = y.lastMessage;
            }
          });
          x.role = x.role.toUpperCase();
          this.setUserRoleName(x)
          if ((this.user.referenceType === 'PLATFORM' && this.user.referenceCode === 'HOLISTA')) {
            if (this.user.roleCode == 'SV' || this.user.roleCode == 'CC' || this.user.roleCode == 'ES') {
              this.searchUsers = result.filter(x => x.role.toUpperCase() !== 'PR')
            } else this.searchUsers = [...this.searchUsers, x];
          } else if (this.user.referenceType === 'CLIENT') {
            this.searchUsers = result.filter(x => (x.metadata.referenceType === 'CLIENT' && x.metadata.referenceCode == this.user.referenceCode) || (x.role.toUpperCase() === 'PA' && x.metadata.referenceType === 'PLATFORM'))
            // this.searchUsers = result.filter(x => (x.metadata.referenceType === 'CLIENT' && x.metadata.referenceCode == this.user.referenceCode) || (x.metadata.referenceType === 'PURCHASER' && this.user.purchaserCodes.includes(x.metadata.referenceCode)) || (x.role.toUpperCase() === 'PA' && x.metadata.referenceType === 'PLATFORM'))
          } else if (this.user.referenceType === 'NETWORK') {
            this.searchUsers = result.filter(x => x.metadata.referenceType === 'PLATFORM' && (x.role.toUpperCase() === 'CLA' || x.role.toUpperCase() === 'PA'))
          } else if (this.user.roleCode === 'PUA') {
            this.searchUsers = result.filter(x => (x.role.toUpperCase() === 'PA' && x.metadata.referenceType === 'PLATFORM') || (x.role.toUpperCase() == 'PUA' && x.metadata.referenceCode == this.user.referenceCode))
          } else {
            this.searchUsers = result.filter(x => x.metadata.referenceType === this.user.referenceType);
            this.chatUsers.map(x => {
              result.map(y => {
                if (x.uid === y.uid && this.user.referenceType !== x.metadata.referenceType) {
                  this.searchUsers = [y, ...this.searchUsers];
                }
              });
            });
          }
          return x;
        });
      });
    } else {
      this.searchUsers = [];
    }
  }

  getRoles() {
    this.userService.getRoles({ limit: 0 }).then((res: any) => {
      this.userRoles = res.rows;
    });
  }

  removeAttachFile(userMessage) {
    let data: any = document.getElementById(`inputFile${userMessage.uid}`);
    data.value = '';
    userMessage.file = null;
  }

  minMaxCallScreen(action) {
    this.minimizeScreen = action
  }

  showChatList() {
    this.showChatUsers = !this.showChatUsers
    if (this.showChatUsers) {
      if (this.usersList.length > 0) this.onUserSelected(this.usersList[0])
    } else {
      this.searchKeyword = ''
      this._storage.remove('session', 'userMessages')
      this.userMessages = []
    }
  }

  showListForMobile() {
    this.selectedUser = null;
  }
}
