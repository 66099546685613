<div class="p-3">
  <div class="row">
    <div class="col-7 d-flex align-items-center">
      <h6 class="mb-0 font-weight-bold mr-2">Period Totals</h6>
      <span class="badge badge-sm bg-primary-dark text-white z-depth-0">
        Reporting Period
        <span> - {{ parsedTransactionReportFilterData.startDate }} - {{ parsedTransactionReportFilterData.endDate }} </span>
      </span>
    </div>
    <div class="col-5">
      <form [formGroup]="filterForm">
        <div class="row">
          <div class="col-5">
            <app-select
              [options]="dateTypeList"
              [id]="'ar-report-date-type'"
              [control]="filterForm.controls['dateType']"
              [placeholder]="'Select date type'"
              [filterEnabled]="false"
              [inputClass]="{
                'disabled-options': isLoading.report
              }"
              (onSelected)="getSummaryYTDData()"
              (onDeselected)="getSummaryYTDData()"></app-select>
          </div>
          <div class="col-5 pl-0">
            <app-select
              [options]="episodeTypeList"
              [multiple]="true"
              [id]="'ar-report-episode-type'"
              [control]="filterForm.controls['episodeType']"
              [placeholder]="'Select episode type'"
              [inputClass]="{
                'disabled-options': isLoading.report
              }"
              (onSelected)="getSummaryYTDData()"
              (onDeselected)="getSummaryYTDData()"></app-select>
          </div>
          <div class="col-2 pl-0">
            <app-select
              [options]="yearList"
              [id]="'summary-ytd-year'"
              [control]="filterForm.controls['year']"
              [placeholder]="'Select year'"
              [filterEnabled]="false"
              [inputClass]="{
                'disabled-options': isLoading.report
              }"
              (onSelected)="getSummaryYTDData()"
              (onDeselected)="getSummaryYTDData()"></app-select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-loader [loader]="isLoading.report"></app-loader>
  <div *ngIf="!isLoading.report">
    <div class="my-4">
      <app-table
        [report]="summaryYTDReportPeriodTotals"
        [module]="'report'"
        tableHeight="800px"
        [isCollapsed]="isCollapsed">
        <ng-template #total>
          <tr class="bg-grey-lighten-2 sticky-bottom">
            <td class="p-1 pl-2 d-flex align-items-center border-0" style="width: 115px">
              <mdb-icon
                *ngIf="isCollapsed"
                style="color: #5a9ec5"
                fas
                icon="chevron-down"
                (click)="onCollapseClicked()"></mdb-icon>
              <mdb-icon
                *ngIf="!isCollapsed"
                style="color: #5a9ec5"
                fas
                icon="chevron-up"
                (click)="onCollapseClicked()"></mdb-icon
              ><span class="ml-2">Period Total</span>
            </td>
            <td class="p-1 px-2">{{ data.summary.episodes }}</td>
            <td class="p-1 pr-2 text-right">{{ data.summary.fundsRequested | currency }}</td>
            <td class="p-1 pr-2 text-right">{{ data.summary.clientDistribution | currency }}</td>
            <td class="p-1 pr-2 text-right">{{ data.summary.patientResponsibility | currency }}</td>
            <td class="p-1 pr-2 text-right">{{ data.summary.fundsReceived | currency }}</td>
            <td class="p-1 pr-2 text-right">{{ data.summary.providerDistribution | currency }}</td>
            <td class="p-1 pr-2 text-right">{{ data.summary.holistaDistribution | currency }}</td>
            <td class="p-1 pr-2 text-right">{{ data.summary.totalNotConsumed | currency }}</td>
          </tr>
        </ng-template>
      </app-table>
    </div>
    <app-table [report]="summaryYTDReport" [module]="'report'" tableHeight="800px"> </app-table>
  </div>
</div>
