<div class="d-flex justify-content-start align-items-start">
  <div class="icon-block d-none d-sm-block">
    <i class="icofont-ui-text-chat icofont-2x skin-text-primary-dark"></i>
  </div>
  <div class="d-flex justify-content-between w-100 align-items-center">
    <span class="pl-2 pr-4">
      <p class="font-weight-400">
        {{message.title}}
      </p>
      <!-- <p>
        {{message.messages}}
      </p> -->
    </span>

    <div *ngIf="message.status && message.status==='DUE'" class="chip chip-sm mb-0 ml-1 alert alert-info"
      [ngClass]="message.status?(message.status.replace(' ','').toLowerCase()):''">
      <span class="text-sm status-label"> {{message.status?message.status?.toLowerCase():''}}
        {{message.escalationDays}}
      </span>
    </div>
  </div>
</div>