<div *ngIf="dependent_message" class="d-flex justify-content-start align-items-start">
    <div class="icon-block-sm d-none d-sm-block rounded-sm bg-secondary text-center mr-2">
        <i class="icofont-link text-white"></i>
    </div>
    <div class="icon-block d-none d-sm-block">
        <i class="icofont-ui-text-chat icofont-lg skin-text-primary-dark"></i>
    </div>
    <div class="d-flex justify-content-between w-100 align-items-center">
        <span class="d-flex pr-4 w-75">
            <p class="font-weight-400">
                {{dependent_message.title}}
            </p>
            <!-- <p>
          {{message.messages}}
        </p> -->
        </span>
    </div>