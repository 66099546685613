import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { AuthService, ToasterService } from '../../services';
import { RESEND_OTP_CODE_SUCCESS } from '../../constants';
import { finalize } from 'rxjs/operators';
import { LoginComponent } from 'src/app/components';

@Component({
  selector: 'app-multi-factor-authentication',
  templateUrl: './multi-factor-authentication.component.html',
  styleUrls: ['./multi-factor-authentication.component.scss']
})
export class MultiFactorAuthenticationComponent implements OnInit {
  mfaForm: FormGroup;

  object = Object;
  @Input() mfaDetail: any;
  @Output() handleMFAVerification: EventEmitter<any> = new EventEmitter();
  @ViewChild('mfaModal', { static: true }) mfaModal: ModalDirective;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput;

  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _toasterService: ToasterService,
    private _loginComponentRef: LoginComponent
  ) { }

  ngOnInit() {
    this.setMFAForm();
  }

  setMFAForm() {
    this.mfaForm = this._fb.group({
      otpCode: [null],
      rememberMfaCode: [null],
      token: [null]
    });
  }

  showModal() {
    this.mfaModal.show();
  }

  hideModal(event?) {
    this.mfaModal.hide();
    this.ngOtpInput.otpForm.reset();
    this.mfaForm.reset();
    if (event) {
      this._loginComponentRef.submit_enabled = false;
      this._loginComponentRef.isUserVerified = false;
    }
  }

  onOtpCodeChange(otpCode: string) {
    if (otpCode.length === this.mfaDetail.otpLength) {
      this.mfaForm.controls.otpCode.setValue(otpCode);
      this.handleMFAVerification.emit(this.mfaForm.value);
    }
  }

  resendMFACode() {
    this.ngOtpInput.otpForm.reset();
    const body = {
      token: this.mfaDetail.token
    }
    this._authService
      .resendMFACode(body)
      .pipe(finalize(() => { }))
      .subscribe((response: any) => {
        if (response) {
          this._toasterService.displaySuccess(RESEND_OTP_CODE_SUCCESS);
          this.mfaForm.controls.token.setValue(response.otpToken);
        }
      }, error => {
        console.log(error);
        this._toasterService.showError(error);
      });
  };
}
