import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsToTime'
})


export class SecondsToTimePipe implements PipeTransform {
    times = {
        year: 31557600,
        month: 2629746,
        day: 86400,
        h: 3600,
        m: 60,
        s: 1
    }
    transform(seconds:any): string {
        let time_string: string = '';
        for (var key in this.times) {
            if (Math.floor(seconds / this.times[key]) > 0) {
                time_string += Math.floor(seconds / this.times[key]).toString() + key.toString()+' ';
                seconds = seconds - this.times[key] * Math.floor(seconds / this.times[key]);
            }
        }
        return time_string;
    }
}