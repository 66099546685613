import { Component, OnInit, Output, Input, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { AccessType } from 'src/app/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-task-questionnaire',
  templateUrl: './task-questionnaire.component.html',
  styleUrls: ['./task-questionnaire.component.scss']
})
export class TaskQuestionnaireComponent implements OnInit {
  taskToBeDelete: any
  dependentTask: any
  qnrsId: any
  @Output() editTask = new EventEmitter<any>();
  @Output() addAction = new EventEmitter<any>();
  @Input() public optionId;
  @Input() public taskQuestionnaires;
  @Input() public taskDragged;
  @Output() public btns = new EventEmitter<any>();
  @Output() public deletedTask = new EventEmitter<any>();
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;


  @Input() set taskQnrsId(taskQnrsId) {
    if (taskQnrsId) {
      this.qnrsId = taskQnrsId
      this.getDependentTask(taskQnrsId);
    }
  }
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.qnrsId) this.getDependentTask(this.qnrsId)
    }
  }
  constructor(
    private utilityAccess: AccessType,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const buttons = this.buttons.toArray();
    setTimeout(() => {
      this.btns.emit(buttons);
    });
  }
  receiveBtns(buttons, field) {
    if (buttons) {
      this.btns.emit(buttons);
    }
  }

  editTaskHandler(questionnaire) {
    this.editTask.emit(questionnaire);
  }

  getDependentTask(id) {
    this.taskQuestionnaires = {};
    if (this.dependentTask && this.dependentTask.taskQnrs) {
      this.dependentTask.taskQnrs.filter(x => {
        if (x.id == id) {
          x.form = 'questionnaire';
          x.field = 'questionnaire';
          x.optionId = this.optionId;
          this.taskQuestionnaires = x
        }
      });
    }
  }
  addActionHandler(action?, uuid?) {
    if (action && action.optionId) {
      this.addAction.emit(action);
    } else {
      this.addAction.emit({ optionId: action ? action : this.optionId, isDependent: true, parentUuid: uuid })
    }
  }
  editTaskTodo(todo) {
    this.editTask.emit(todo);
  }

  editTaskMessage(message) {
    this.editTask.emit(message);
  }

  deleteTaskHandler(questionnaire) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      questionnaire['source'] = 'Pathway'
      questionnaire['type'] = 'Questionnaire'
      questionnaire['displayName'] = questionnaire.qnrs.name
      this.taskToBeDelete = questionnaire
    }
    else this.toastr.warning("Access Denied", "Warning")
  }
  cancelDelete(e) {
    this.taskToBeDelete = e
  }
  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }
}
