export const ROLECONSTANTS = [
  { role: "CC", roleName: "Care Coordinator" },
  { role: "CA", roleName: "Client Administrator" },
  { role: "ES", roleName: "Engagement Specialist" },
  // { role: "IA", roleName: "Insurer Administrator" },
  { role: "MP", roleName: "Member/patient" },
  { role: "PA", roleName: "Super Administrator" },
  { role: "SV", roleName: "Supervisor" },
  { role: "PR", roleName: "Provider Administrator" },
  { role: "CLA", roleName: "Claim Administrator" },
  { role: "PUA", roleName: "Purchaser Administrator" },
  { role: "RPT", roleName: "Reporting" },
  { role: "SCA", roleName: "Super Client Administrator" },
];

export const ROLE_CODES = {
  CARE_COORDINATOR: 'CC',
  CLIENT_ADMINISTRATOR: 'CA',
  ENGAGEMENT_SPECIALIST: 'ES',
  MEMBER_PATIENT: 'MP',
  SUPER_ADMINISTRATOR: 'PA',
  SUPERVISOR: 'SV',
  PROVIDER_ADMINISTRATOR: 'PR',
  CLAIM_ADMINSTRATOR: 'CLA',
  PURCHASER_ADMINISTRATOR: 'PUA',
  REPORTING: 'RPT',
  SUPER_CLIENT_ADMINISTRATOR: 'SCA',
}

export const CLIENT_ADMINS = [
  'SCA', 'CA'
];

