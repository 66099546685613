<div *ngIf="tasks && tasks.length > 0">
  <div
    class="d-flex justify-content-between h-auto align-items-center border-bottom pt-2 pb-2 mb-4">
    <h6 class="mb-0">Task List</h6>
    <div class="d-flex justify-content-end align-items-center">
      <!-- Material switch -->
      <div class="switch align-self-center">
        <label class="mb-0">
          Enable Ordering
          <input
            type="checkbox"
            [(ngModel)]="taskDragged"
            [disabled]="!utilityAccess.searchAccess('PM', 'isEditable', false)" />
          <span class="lever"></span>
        </label>
      </div>
      <button (click)="addTask()" type="button" class="btn btn-secondary btn-sm" mdbWavesEffect>
        Add Task
      </button>
    </div>
  </div>
  <div class="dndPlaceholder col-md-12" #placeholder></div>
  <div [dndList]="{disabled: !taskDragged}" [dndModel]="tasks" [dndPlaceholder]="placeholder">
    <div *ngFor="let task of tasks; let i = index">
      <div [dndType]="task.id" (dndMoved)="onDrop(task, tasks)" [dndDraggable] [dndObject]="task">
        <app-task-todo
          *ngIf="task.field == 'todo'"
          [taskTodo]="task"
          [dependent_task]="dependent_task"
          (editTask)="editTask($event, 'todo')"
          (btns)="receiveBtns($event, 'todo')"
          (deletedTask)="deleteTask($event)">
        </app-task-todo>
        <app-task-signature
          *ngIf="task.field == 'signature'"
          [taskSignature]="task"
          [dependent_task]="dependent_task"
          (editTask)="editTask($event, 'signature')"
          (btns)="receiveBtns($event, 'signature')"
          (deletedTask)="deleteTask($event)">
        </app-task-signature>
        <app-task-message
          *ngIf="task.field == 'message'"
          [message]="task"
          [dependent_task]="dependent_task"
          (editTask)="editTask($event, 'message')"
          (btns)="receiveBtns($event, 'message')"
          (deletedTask)="deleteTask($event)"></app-task-message>
        <app-task-question
          *ngIf="task.field == 'question'"
          [taskQuestion]="task"
          [dependent_task]="dependent_task"
          [taskDragged]="taskDragged"
          (editTask)="editTask($event, $event && $event.form ? $event.form : 'question')"
          (deletedTask)="deleteTask($event)"
          (addAction)="addAction($event)"
          (btns)="receiveBtns($event, 'question')">
        </app-task-question>
        <app-task-questionnaire
          *ngIf="task.field == 'questionnaire'"
          [taskQuestionnaires]="task"
          [taskDragged]="taskDragged"
          [dependent_task]="dependent_task"
          (btns)="receiveBtns($event, 'questionnaire')"
          (editTask)="editTask($event, $event && $event.form ? $event.form : 'questionnaire')"
          (addAction)="addAction($event)"
          (deletedTask)="deleteTask($event)">
        </app-task-questionnaire>
      </div>
    </div>
  </div>
</div>
<div class="text-center p-4" *ngIf="!tasks || tasks.length == 0">
  <h5>Please add task</h5>
  <button (click)="addTask()" class="btn btn-secondary btn-sm" type="button" mdbWavesEffect>
    Add Task
  </button>
</div>

<div
  mdbModal
  #taskModal="mdbModal"
  class="modal fade top"
  id="taskModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{
            (task_type == 'todo' && todoForm.value.id) ||
            (task_type == 'question' && questionForm.value.id) ||
            (task_type == 'message' && messageForm.value.id) ||
            (task_type == 'questionnaire' && questionnaireForm.value.id) ||
            (task_type == 'signature' && signatureForm.value.id)
              ? 'Update'
              : 'Add'
          }}
          Task
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <p>Select Task Type</p>
          <div class="radio-block d-flex justify-content-between mb-3">
            <div>
              <label class="form-check-label" for="todo">
                <input
                  type="radio"
                  id="todo"
                  name="materialExampleRadios"
                  [(ngModel)]="task_type"
                  [value]="'todo'"
                  (change)="changeTask()"
                  [disabled]="isEdit && task_type !== 'todo'" />
                <div
                  class="box px-2 m-0 d-flex justify-content-center align-items-center"
                  [style.opacity]="isEdit && task_type !== 'todo' ? 0.5 : 1">
                  <i class="icofont-tasks"></i> To-Do
                </div>
              </label>
            </div>
            <div>
              <label class="form-check-label" for="message">
                <input
                  type="radio"
                  id="message"
                  name="materialExampleRadios"
                  [(ngModel)]="task_type"
                  [value]="'message'"
                  (change)="changeTask()"
                  [disabled]="isEdit && task_type !== 'message'" />
                <div
                  class="box px-2 m-0 d-flex justify-content-center align-items-center"
                  [style.opacity]="isEdit && task_type !== 'message' ? 0.5 : 1">
                  <i class="icofont-ui-text-chat"></i> Message
                </div>
              </label>
            </div>
            <div>
              <label class="form-check-label" for="question">
                <input
                  type="radio"
                  id="question"
                  name="materialExampleRadios"
                  [(ngModel)]="task_type"
                  [value]="'question'"
                  (change)="changeTask()"
                  [disabled]="isEdit && task_type !== 'question'" />
                <div
                  class="box px-2 m-0 d-flex justify-content-center align-items-center"
                  [style.opacity]="isEdit && task_type !== 'question' ? 0.5 : 1">
                  <i class="icofont-question-circle"></i> Question
                </div>
              </label>
            </div>
            <div>
              <label class="form-check-label" for="questionnaire">
                <input
                  type="radio"
                  id="questionnaire"
                  name="materialExampleRadios"
                  [(ngModel)]="task_type"
                  [value]="'questionnaire'"
                  (change)="changeTask()"
                  [disabled]="isEdit && task_type !== 'questionnaire'" />
                <div
                  class="box px-2 m-0 d-flex justify-content-center align-items-center"
                  [style.opacity]="isEdit && task_type !== 'questionnaire' ? 0.5 : 1">
                  <i class="icofont-clip-board"></i> Questionnaire
                </div>
              </label>
            </div>
            <div>
              <label class="form-check-label" for="signature">
                <input
                  type="radio"
                  id="signature"
                  name="materialExampleRadios"
                  [(ngModel)]="task_type"
                  [value]="'signature'"
                  (change)="changeTask()"
                  [disabled]="isEdit && task_type !== 'signature'" />
                <div
                  class="box px-2 m-0 d-flex justify-content-center align-items-center"
                  [style.opacity]="isEdit && task_type !== 'signature' ? 0.5 : 1">
                  <i class="fas fa-file-signature"></i> Signature
                </div>
              </label>
            </div>
          </div>
        </form>
        <form *ngIf="task_type === 'todo' || task_type === null" [formGroup]="todoForm">
          <div class="form-group">
            <label for="patientTopic-name">Trigger for escalation</label>
            <div class="form-row">
              <div class="col">
                <div class="form-group m-0">
                  <input
                    type="number"
                    id="escalationDays"
                    class="form-control"
                    formControlName="escalationDays"
                    mdbInput />
                </div>
              </div>
              <div class="col">
                <mdb-select
                  [options]="triggerPeriodOptions"
                  formControlName="triggerPeriod"></mdb-select>
              </div>
            </div>
          </div>
          <div class="form-row justify-content-space-between mb-3">
            <div class="col">
              <label for="assignedBy">Assigned By *</label>
              <mdb-select
                (opened)="selectOpen('assignFromList', todoForm.controls.assignedTo.value)"
                (selected)="assignedSelected()"
                [ngClass]="{
                  'error-select': submitted && todoForm.get('assignedBy').invalid
                }"
                [options]="assignFromList"
                [filterEnabled]="true"
                id="assignedBy"
                placeholder="Select assignor"
                formControlName="assignedBy">
              </mdb-select>
            </div>
            <div class="col">
              <label for="assignedTo">Assigned To *</label>
              <mdb-select
                (opened)="selectOpen('assignToList', todoForm.controls.assignedTo.value)"
                [ngClass]="{
                  'error-select': submitted && todoForm.get('assignedTo').invalid
                }"
                [options]="assignToList"
                [filterEnabled]="true"
                (selected)="assignedSelected()"
                id="assignedTo"
                placeholder="Select assignee"
                formControlName="assignedTo">
              </mdb-select>
            </div>
            <div
              *ngIf="todoForm.value.assignedBy == 'MP' && todoForm.value.assignedTo == 'SV'"
              class="col">
              <label for="supervisorOf">Supervisor Of *</label>
              <mdb-select
                [ngClass]="{
                  'error-select': submitted && todoForm.get('supervisorOf').invalid
                }"
                [options]="supervisorOf"
                id="supervisorOf"
                placeholder="Select CC/ES"
                formControlName="supervisorOf">
              </mdb-select>
            </div>
          </div>
          <div class="form-group">
            <label for="TaskName">Task Name *</label>
            <input
              type="text"
              id="TaskName"
              class="form-control"
              mdbInput
              formControlName="name"
              [ngClass]="{
                'is-invalid': submitted && todoForm.get('name').invalid
              }" />
          </div>
          <div class="form-group">
            <label for="TaskInstruction">Instruction</label>
            <textarea
              type="text"
              id="TaskInstruction"
              class="md-textarea form-control"
              mdbInput
              formControlName="instruction"></textarea>
          </div>

          <div class="form-group">
            <h6 class="skin-link pointer-cursor" (click)="onAttach()">
              <i class="icofont-attachment"></i> Attach File
            </h6>
          </div>

          <div class="border grey lighten-5 p-3 rounded-sm mb-3" *ngIf="todoForm.value.isAttach">
            <div class="d-flex justify-content-between align-items-center">
              <h6>File upload options</h6>
              <button type="button" class="btn btn-link p-0 m-0" (click)="closeAttach()">
                <i class="icofont-close"></i> close
              </button>
            </div>
            <hr />
            <!-- Material inline 1 -->
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                (change)="attachChange(task_type)"
                id="fileurl"
                name="type"
                value="link"
                formControlName="type"
                mdbInput />
              <label class="form-check-label" for="fileurl">File/ Video Reference URL</label>
            </div>

            <!-- Material inline 2 -->
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                id="existing"
                (change)="attachChange(task_type)"
                name="type"
                value="holistalibrary"
                formControlName="type"
                mdbInput />
              <label class="form-check-label" for="existing">Choose from exisiting document</label>
            </div>

            <!-- Material inline 3 -->
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                id="upload"
                (change)="attachChange(task_type)"
                name="type"
                value="uploadFile"
                formControlName="type"
                mdbInput />
              <label class="form-check-label" for="upload">Upload file</label>
            </div>
            <div *ngIf="todoForm.value.type" class="form-group mt-3">
              <label for="filename">File Display Name *</label>
              <input
                type="text"
                id="filename"
                class="form-control"
                formControlName="documentDisplayName"
                [readonly]="todoForm.value.type == 'holistalibrary'"
                [ngClass]="{
                  'is-invalid': submitted && todoForm.get('documentDisplayName').invalid
                }" />
            </div>

            <div
              *ngIf="todoForm.value.type && todoForm.value.type == 'link'"
              class="form-group mb-0">
              <label for="TaskToDoLink">File/Video Reference URL *</label>
              <input
                type="text"
                id="TaskToDoLink"
                class="form-control"
                mdbInput
                formControlName="taskTodoLink"
                [readonly]="todoFile && todoFile.name"
                [ngClass]="{'is-invalid': submitted && todoForm.get('taskTodoLink').invalid}" />
            </div>

            <div
              *ngIf="todoForm.value.type && todoForm.value.type == 'holistalibrary'"
              class="form-group position-relative">
              <label>Choose Document *</label>
              <input
                type="text"
                [ngModel]="document_search_text | async"
                (ngModelChange)="document_search_text.next($event)"
                [ngModelOptions]="{standalone: true}"
                class="completer-input form-control mdb-autocomplete mb-0"
                id="autocompleteDocument"
                [mdbAutoCompleter]="auto"
                placeholder="Type to select document"
                [ngClass]="{'error-select': submitted && todoForm.get('taskTodoLink').invalid}" />
              <mdb-auto-completer
                #auto="mdbAutoCompleter"
                textNoResults="No document found"
                [displayValue]="onDocumentValue"
                (selected)="onDocumentSelect($event)">
                <mdb-option *ngFor="let option of document_name_results | async" [value]="option">
                  <div class="d-flex flex-column" mdbTooltip="{{ option.name }}" placement="right">
                    <strong>{{ option.name }} </strong>
                  </div>
                </mdb-option>
              </mdb-auto-completer>
            </div>

            <div
              *ngIf="todoForm.value.type && todoForm.value.type == 'uploadFile'"
              class="form-group mb-0">
              <mdb-file-upload
                (fileRemoved)="onFileRemove()"
                [disabled]="todoForm.get('taskTodoLink').value"
                (fileAdded)="onFileAdd($event)"
                [ngClass]="{
                  'is-invalid': submitted && todoForm.get('holistaMedia').invalid
                }"></mdb-file-upload>
            </div>
          </div>
          <div class="form-group">
            <mdb-checkbox formControlName="isAcknowledgedRequired"
              >Acknowledgement Required</mdb-checkbox
            >
            <mdb-checkbox formControlName="isHighPriority">High Priority</mdb-checkbox>
          </div>
        </form>
        <form *ngIf="task_type === 'signature'" [formGroup]="signatureForm">
          <div class="form-group">
            <label for="patientTopic-name">Trigger for escalation</label>
            <div class="form-row">
              <div class="col">
                <div class="form-group m-0">
                  <input
                    type="number"
                    id="escalationDays"
                    class="form-control"
                    formControlName="escalationDays"
                    mdbInput />
                </div>
              </div>
              <div class="col">
                <mdb-select
                  [options]="triggerPeriodOptions"
                  formControlName="triggerPeriod"></mdb-select>
              </div>
            </div>
          </div>
          <div class="form-row justify-content-space-between mb-3">
            <div class="col">
              <label for="assignedBy">Assigned By *</label>
              <mdb-select
                (opened)="selectOpen('assignFromList', signatureForm.controls.assignedTo.value)"
                (selected)="assignedSelected()"
                [ngClass]="{
                  'error-select': submitted && signatureForm.get('assignedBy').invalid
                }"
                [options]="assignFromList"
                id="assignedBy"
                placeholder="Select assignor"
                formControlName="assignedBy">
              </mdb-select>
            </div>
            <div class="col">
              <label for="assignedTo">Assigned To *</label>
              <mdb-select
                (opened)="selectOpen('assignToList', signatureForm.controls.assignedTo.value)"
                [ngClass]="{
                  'error-select': submitted && signatureForm.get('assignedTo').invalid
                }"
                [options]="assignToList"
                (selected)="assignedSelected()"
                id="assignedTo"
                placeholder="Select assignee"
                formControlName="assignedTo">
              </mdb-select>
            </div>
            <div
              *ngIf="
                signatureForm.value.assignedBy == 'MP' && signatureForm.value.assignedTo == 'SV'
              "
              class="col">
              <label for="supervisorOf">Supervisor Of *</label>
              <mdb-select
                [ngClass]="{
                  'error-select': submitted && signatureForm.get('supervisorOf').invalid
                }"
                [options]="supervisorOf"
                id="supervisorOf"
                placeholder="Select CC/ES"
                formControlName="supervisorOf">
              </mdb-select>
            </div>
          </div>
          <div class="form-group">
            <label for="TaskName">Task Name *</label>
            <input
              type="text"
              id="TaskName"
              class="form-control"
              mdbInput
              formControlName="name"
              [ngClass]="{
                'is-invalid': submitted && signatureForm.get('name').invalid
              }" />
          </div>
          <div class="form-group">
            <label for="TaskInstruction">Instruction</label>
            <textarea
              type="text"
              id="TaskInstruction"
              class="md-textarea form-control"
              mdbInput
              formControlName="instruction"></textarea>
          </div>
          <div class="form-group">
            <label for="agreementTemplate">Select Agreement Template *</label>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="isLoading.agreementTemplateList"></span>
            <mdb-select
              [ngClass]="{
                'error-select': submitted && signatureForm.get('docuSignTemplateId').invalid
              }"
              [options]="agreementTemplateList"
              [disabled]="!agreementTemplateList.length"
              id="agreementTemplate"
              placeholder="Select template"
              [formControl]="signatureForm.controls['docuSignTemplateId']">
            </mdb-select>
          </div>
          <div class="form-group">
            <mdb-checkbox formControlName="isNotifiableToAssignor"
              >Notifiable to Assignor</mdb-checkbox
            >
            <mdb-checkbox formControlName="isNotifiableToAssignee"
              >Notifiable to Assignee</mdb-checkbox
            >
            <mdb-checkbox formControlName="isHighPriority">High Priority</mdb-checkbox>
          </div>
        </form>

        <form [formGroup]="messageForm" *ngIf="task_type == 'message'">
          <div class="form-group">
            <label for="patientTopic-name">Trigger for escalation</label>
            <div class="form-row">
              <div class="col">
                <div class="form-group m-0">
                  <input
                    type="number"
                    id="escalationDays"
                    class="form-control"
                    formControlName="escalationDays"
                    mdbInput />
                </div>
              </div>
              <div class="col">
                <mdb-select
                  [options]="triggerPeriodOptions"
                  formControlName="triggerPeriod"></mdb-select>
              </div>
            </div>
          </div>
          <div class="form-row justify-content-space-between mb-3">
            <div class="col">
              <label for="messageFrom">From *</label>
              <mdb-select
                (opened)="selectOpen('messageFromList', messageForm.controls.messageTo.value)"
                (selected)="assignedSelected()"
                [ngClass]="{'error-select': submitted && messageForm.get('messageFrom').invalid}"
                [options]="messageFromList"
                id="messageFrom"
                placeholder="Select sender"
                formControlName="messageFrom">
              </mdb-select>
            </div>
            <div class="col">
              <label for="messageTo">To *</label>
              <mdb-select
                (opened)="selectOpen('messageToList', messageForm.controls.messageFrom.value)"
                (selected)="assignedSelected()"
                [ngClass]="{'error-select': submitted && messageForm.get('messageTo').invalid}"
                [options]="messageToList"
                id="messageTo"
                placeholder="Select recipient"
                formControlName="messageTo">
              </mdb-select>
            </div>
            <div
              *ngIf="messageForm.value.messageFrom == 'MP' && messageForm.value.messageTo == 'SV'"
              class="col">
              <label for="supervisorOf">Supervisor Of *</label>
              <mdb-select
                [ngClass]="{'error-select': submitted && messageForm.get('supervisorOf').invalid}"
                [options]="supervisorOf"
                id="supervisorOf"
                placeholder="Select CC/ES"
                formControlName="supervisorOf">
              </mdb-select>
            </div>
          </div>
          <div class="form-group">
            <label for="MessageTitle">Message Title *</label>
            <input
              type="text"
              id="MessageTitle"
              class="form-control"
              mdbInput
              formControlName="title"
              length="100"
              mdbCharCounter
              [ngClass]="{'is-invalid': submitted && messageForm.get('title').invalid}" />
          </div>
          <div class="form-group">
            <label for="Message">Message *</label>
            <textarea
              type="text"
              id="Message"
              class="md-textarea form-control"
              mdbInput
              formControlName="messages"
              [ngClass]="{
                'is-invalid': submitted && messageForm.get('messages').invalid
              }"></textarea>
          </div>
          <div class="form-group">
            <h6 class="skin-link pointer-cursor" (click)="onAttach()">
              <i class="icofont-attachment"></i> Attach File
            </h6>
          </div>
          <div *ngIf="messageForm.value.isAttach" class="border grey lighten-5 p-3 rounded-sm mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <h6>File upload options</h6>
              <button type="button" class="btn btn-link p-0 m-0" (click)="closeAttach()">
                <i class="icofont-close"></i> close
              </button>
            </div>
            <hr />
            <!-- Material inline 1 -->
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                id="fileurl"
                (change)="attachChange(task_type)"
                name="type"
                formControlName="type"
                value="link"
                mdbInput />
              <label class="form-check-label" for="fileurl">File/ Video Reference URL</label>
            </div>

            <!-- Material inline 2 -->
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                id="existing"
                (change)="attachChange(task_type)"
                name="type"
                formControlName="type"
                value="holistalibrary"
                mdbInput />
              <label class="form-check-label" for="existing">Choose from exisiting document</label>
            </div>

            <!-- Material inline 3 -->
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                id="upload"
                (change)="attachChange(task_type)"
                name="type"
                formControlName="type"
                value="uploadFile"
                mdbInput />
              <label class="form-check-label" for="upload">Upload file</label>
            </div>

            <div *ngIf="messageForm.value.type" class="form-group mt-3">
              <label for="filename">File Display Name *</label>
              <input
                type="text"
                id="filename"
                class="form-control"
                formControlName="documentDisplayName"
                [readonly]="messageForm.value.type == 'holistalibrary'"
                [ngClass]="{
                  'is-invalid': submitted && messageForm.get('documentDisplayName').invalid
                }" />
            </div>

            <div
              *ngIf="messageForm.value.type && messageForm.value.type == 'link'"
              class="form-group mt-4">
              <label for="DocumentPath">File/Video Reference URL *</label>
              <input
                type="text"
                id="DocumentPath"
                class="form-control"
                mdbInput
                formControlName="documentPath"
                [readonly]="messageFile && messageFile.name"
                [ngClass]="{'is-invalid': submitted && messageForm.get('documentPath').invalid}" />
            </div>

            <div
              *ngIf="messageForm.value.type && messageForm.value.type == 'holistalibrary'"
              class="form-group position-relative">
              <label>Choose Document *</label>
              <input
                type="text"
                [ngModel]="document_search_text | async"
                (ngModelChange)="document_search_text.next($event)"
                [ngModelOptions]="{standalone: true}"
                class="completer-input form-control mdb-autocomplete mb-0"
                id="autocompleteDocument"
                [mdbAutoCompleter]="auto"
                placeholder="Type to select document"
                [ngClass]="{
                  'error-select': submitted && messageForm.get('documentPath').invalid
                }" />
              <mdb-auto-completer
                #auto="mdbAutoCompleter"
                textNoResults="No document found"
                [displayValue]="onDocumentValue"
                (selected)="onDocumentSelect($event)">
                <mdb-option *ngFor="let option of document_name_results | async" [value]="option">
                  <div class="d-flex flex-column" mdbTooltip="{{ option.name }}" placement="right">
                    <strong>{{ option.name }} </strong>
                  </div>
                </mdb-option>
              </mdb-auto-completer>
            </div>

            <div
              *ngIf="messageForm.value.type && messageForm.value.type == 'uploadFile'"
              class="form-group">
              <mdb-file-upload
                (fileRemoved)="onFileRemove()"
                [disabled]="messageForm.get('documentPath').value"
                (fileAdded)="onFileAdd($event)"
                [ngClass]="{'is-invalid': submitted && messageForm.get('holistaMedia').invalid}">
              </mdb-file-upload>
            </div>
          </div>
          <div class="form-group">
            <mdb-checkbox formControlName="severity" [checked]="messageForm.get('severity').value">
              Is Notifiable ?
            </mdb-checkbox>
          </div>
        </form>
        <form [formGroup]="questionForm" *ngIf="task_type == 'question'">
          <div class="form-group">
            <label for="patientTopic-name">Trigger for escalation</label>
            <div class="form-row">
              <div class="col">
                <div class="form-group m-0">
                  <input
                    type="number"
                    id="escalationDays"
                    class="form-control"
                    formControlName="escalationDays"
                    mdbInput />
                </div>
              </div>
              <div class="col">
                <mdb-select
                  [options]="triggerPeriodOptions"
                  formControlName="triggerPeriod"></mdb-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label>Question Type *</label>
                <mdb-select
                  [options]="questionTypes"
                  id="questionTypes"
                  placeholder="Choose question type"
                  formControlName="questionTypeId"
                  (selected)="questionTypeChange()"
                  [ngClass]="{
                    'error-select': submitted && questionForm.get('questionTypeCode').invalid
                  }"></mdb-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="MilestoneName">Group</label>
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="isLoading.groups"></span>
                <app-select
                  [options]="groups"
                  [id]="'groupCode'"
                  [allowClear]="true"
                  [disabled]="isLoading.groups"
                  (onDeselected)="onGroupDeselected()"
                  [control]="questionForm.controls['groupCode']"></app-select>
              </div>
            </div>
            <!-- <div class="switch">
                  <label>
                    Required
                    <input type="checkbox" formControlName="isRequired" />
                    <span class="lever"></span>
                  </label>
                </div> -->
          </div>
          <div class="form-group">
            <label for="QuestionName">Question *</label>
            <input
              type="text"
              id="QuestionName"
              class="form-control"
              mdbInput
              formControlName="question"
              [ngClass]="{'is-invalid': submitted && questionForm.get('question').invalid}" />
          </div>

          <div class="form-group">
            <label for="QuestionHelp">Help Text</label>
            <textarea
              type="text"
              id="QuestionHelp"
              class="md-textarea form-control"
              mdbInput
              formControlName="questionHelp"
              [ngClass]="{'is-invalid': submitted && questionForm.get('questionHelp').invalid}">
            </textarea>
          </div>
          <div class="form-group">
            <mdb-checkbox formControlName="isHighPriority">High Priority</mdb-checkbox>
          </div>
          <div
            *ngIf="
              questionForm.get('questionTypeCode').value == 'RADIO' ||
              questionForm.get('questionTypeCode').value == 'CHECKBOX'
            ">
            <div class="row">
              <div class="col">
                <h6>Answer Options *</h6>
              </div>
              <!-- <div class='col'>
                <p class='text-center '>Is Notifiable ?</p>
              </div> -->
            </div>

            <div class="content-list mb-3">
              <div class="d-flex justify-content-start align-items-center">
                <div
                  [ngClass]="{
                    'radio-input': questionForm.get('questionTypeCode').value == 'RADIO',
                    'checkbox-input': questionForm.get('questionTypeCode').value == 'CHECKBOX'
                  }"
                  class="position-absolute"></div>

                <div class="form-row m-0 w-100 ml-4 align-items-center">
                  <div class="col md-form mt-0 mb-0">
                    <input
                      mdbInput
                      type="text"
                      id="form1"
                      class="form-control mb-0"
                      placeholder="Enter option"
                      [(ngModel)]="questionOption.optionValue"
                      [ngModelOptions]="{standalone: true}"
                      [ngClass]="{
                        'error-option':
                          (submitted &&
                            questionForm.value.questionTypeCode == 'CHECKBOX' &&
                            !(optionList.length > 0)) ||
                          (submitted &&
                            questionForm.value.questionTypeCode == 'RADIO' &&
                            !(optionList.length > 0))
                      }" />
                  </div>

                  <div class="col">
                    <div class="switch float-right mr-3">
                      <label>
                        Is Notifiable?
                        <input
                          type="checkbox"
                          [(ngModel)]="questionOption.severity"
                          id="isNotifiable"
                          [ngModelOptions]="{standalone: true}"
                          [disabled]="!questionOption.optionValue" />
                        <span class="lever"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-start align-items-center"
                *ngFor="let option of optionList; let i = index">
                <div
                  [ngClass]="{
                    'radio-input': questionForm.get('questionTypeCode').value == 'RADIO',
                    'checkbox-input': questionForm.get('questionTypeCode').value == 'CHECKBOX'
                  }"
                  class="position-absolute"></div>

                <div class="form-row m-0 w-100 ml-4 align-items-center">
                  <div class="col md-form mt-0 mb-0">
                    <input
                      mdbInput
                      type="text"
                      id="form1"
                      class="form-control mb-0"
                      placeholder="Enter option"
                      [(ngModel)]="option.optionValue"
                      [ngModelOptions]="{standalone: true}" />
                  </div>
                  <div class="col">
                    <div class="switch float-right mr-3">
                      <label>
                        Is Notifiable ?
                        <input
                          type="checkbox"
                          id="isNotifiable{{ i }}"
                          [(ngModel)]="option.severity"
                          [value]="true ? option.severity == 'YES' : false"
                          [ngModelOptions]="{standalone: true}" />
                        <span class="lever"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <button type="button" class="close" (click)="removeOption(option)">
                  <i class="icofont-close-line"></i>
                </button>
              </div>
            </div>
            <button
              type="submit"
              class="skin-link border-0 bg-transparent"
              (click)="addOptionValue()">
              <i class="icofont-plus"></i> Add to Options
            </button>
          </div>
        </form>

        <form [formGroup]="questionnaireForm" *ngIf="task_type == 'questionnaire'">
          <div class="form-group">
            <label for="patientTopic-name">Trigger for escalation</label>
            <div class="form-row">
              <div class="col">
                <div class="form-group m-0">
                  <input
                    type="number"
                    id="escalationDays"
                    class="form-control"
                    formControlName="escalationDays"
                    mdbInput />
                </div>
              </div>
              <div class="col">
                <mdb-select
                  [options]="triggerPeriodOptions"
                  formControlName="triggerPeriod"></mdb-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="questionnaireName">Questionnaire Name *</label>
            <input
              class="form-control mb-0"
              type="text"
              id="questionnaireName"
              formControlName="name"
              [ngClass]="{'is-invalid': submitted && questionnaireForm.get('name').invalid}" />
          </div>
          <div class="form-group">
            <mdb-checkbox formControlName="isHighPriority">High Priority</mdb-checkbox>
          </div>
          <!-- Nav tabs -->
          <mdb-tabset
            [buttonClass]="'classic-tabs'"
            [contentClass]="'card tab-clean d-block'"
            class="classic-tabs">
            <!--Panel 1-->
            <mdb-tab heading="Add to Questionnaire">
              <div class="form-group">
                <div class="form-group position-relative">
                  <label for="questionToQuestionnaire">Add Question to Questionnaire *</label>
                  <input
                    type="text"
                    [ngModel]="question_search_text | async"
                    (ngModelChange)="question_search_text.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto"
                    placeholder="Select question" />
                  <mdb-auto-completer
                    #auto="mdbAutoCompleter"
                    textNoResults="No question found"
                    [displayValue]="onDisplayValue"
                    (selected)="onQuestionSelect($event)">
                    <mdb-option *ngFor="let option of question_results | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.question }} </strong>
                        <!-- <small>{{ (option.company && option.company.name)? option.company.name:''}}</small> -->
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </mdb-tab>
            <!--Panel 2-->
            <mdb-tab heading="Create Question">
              <label>Question Type *</label>
              <div class="d-flex justify-content-between align-items-center border-top pt-3 pb-2">
                <mdb-select
                  class="w-50"
                  [options]="questionTypes"
                  id="questionTypes"
                  placeholder="Choose question type"
                  formControlName="questionTypeId"
                  (selected)="questionTypeChange()"
                  [ngClass]="{
                    'error-select':
                      create_submitted && questionnaireForm.get('questionTypeCode').invalid
                  }">
                </mdb-select>
                <div class="switch">
                  <label>
                    Required
                    <input type="checkbox" formControlName="isRequired" />
                    <span class="lever"></span>
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label for="QuestionName">Question *</label>
                <input
                  type="text"
                  id="QuestionName"
                  class="form-control"
                  mdbInput
                  formControlName="question"
                  [ngClass]="{
                    'is-invalid': create_submitted && questionnaireForm.get('question').invalid
                  }" />
              </div>

              <div class="form-group">
                <label for="QuestionHelp">Help Text</label>
                <textarea
                  type="text"
                  id="QuestionHelp"
                  class="md-textarea form-control"
                  mdbInput
                  formControlName="questionHelp"></textarea>
              </div>
              <div
                *ngIf="
                  questionnaireForm.get('questionTypeCode').value == 'CHECKBOX' ||
                  questionnaireForm.get('questionTypeCode').value == 'RADIO'
                ">
                <div class="row">
                  <div class="col">
                    <h6>Answer Options *</h6>
                  </div>
                </div>
                <div class="content-list mb-3">
                  <div class="d-flex justify-content-start align-items-center">
                    <div
                      [ngClass]="{
                        'radio-input': questionnaireForm.get('questionTypeCode').value == 'RADIO',
                        'checkbox-input':
                          questionnaireForm.get('questionTypeCode').value == 'CHECKBOX'
                      }"
                      class="position-absolute"></div>

                    <div class="form-row m-0 w-100 ml-4 align-items-center">
                      <div class="col md-form mt-0 mb-0">
                        <input
                          mdbInput
                          type="text"
                          id="form1"
                          class="form-control mb-0"
                          placeholder="Enter option"
                          [(ngModel)]="QnrsquestionOption.optionValue"
                          [ngModelOptions]="{standalone: true}"
                          [ngClass]="{
                            'error-option':
                              (create_submitted &&
                                questionnaireForm.get('questionTypeCode').value == 'CHECKBOX' &&
                                !(QnrsOptionList.length > 0)) ||
                              (create_submitted &&
                                questionnaireForm.get('questionTypeCode').value == 'RADIO' &&
                                !(QnrsOptionList.length > 0))
                          }" />
                      </div>
                      <div class="col">
                        <!-- Material checked -->
                        <div class="switch float-right mr-3">
                          <label>
                            Is Notifiable?
                            <input
                              type="checkbox"
                              [(ngModel)]="QnrsquestionOption.severity"
                              id="isNotifiable"
                              [ngModelOptions]="{standalone: true}"
                              [disabled]="!QnrsquestionOption.optionValue" />
                            <span class="lever"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-start align-items-center"
                    *ngFor="let option of QnrsOptionList; let i = index">
                    <div
                      [ngClass]="{
                        'radio-input': questionnaireForm.get('questionTypeCode').value == 'RADIO',
                        'checkbox-input':
                          questionnaireForm.get('questionTypeCode').value == 'CHECKBOX'
                      }"
                      class="position-absolute"></div>

                    <div class="form-row m-0 w-100 ml-4">
                      <div class="col md-form mt-0 mb-0">
                        <input
                          mdbInput
                          type="text"
                          id="form1"
                          class="form-control mb-0"
                          placeholder="Enter option"
                          [(ngModel)]="option.optionValue"
                          [ngModelOptions]="{standalone: true}" />
                      </div>
                      <div class="col">
                        <div class="switch float-right mr-3">
                          <label>
                            Is Notifiable?
                            <input
                              type="checkbox"
                              [(ngModel)]="option.severity"
                              id="isNotifiable{{ i }}"
                              [ngModelOptions]="{standalone: true}" />
                            <span class="lever"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="close" (click)="removeOption(option)">
                      <i class="icofont-close-line"></i>
                    </button>
                  </div>
                </div>

                <button
                  type="submit"
                  class="skin-link border-0 bg-transparent"
                  (click)="QnrsaddOptionValue()">
                  <i class="icofont-plus"></i> Add to Options
                </button>
              </div>
            </mdb-tab>
            <button
              class="ml-0 btn btn-secondary btn-outline btn-sm"
              type="button"
              mdbWavesEffect
              (click)="addQuestions()">
              Add Question
            </button>
          </mdb-tabset>
          <div class="form-group">
            <h5 *ngIf="questionnaireQuestions.length > 0">Questionnaire</h5>
            <p *ngIf="submitted && !(questionnaireQuestions.length > 0)" class="text-danger">
              Add question to questionnaire
            </p>
            <ul *ngFor="let questions of questionnaireQuestions" class="pl-0">
              <li class="d-flex justify-content-between border-bottom">
                <div class="d-flex justify-content-start align-items-start">
                  <div class="icon-block">
                    <i class="icofont-question-circle icofont-lg skin-text-primary-dark"></i>
                  </div>
                  <span class="pl-2 pr-4">
                    <p class="font-weight-400">{{ questions.question }}</p>
                  </span>
                </div>

                <button
                  type="button"
                  class="btn btn-link p-2 m-0"
                  (click)="removeQuestion(questions)">
                  <i class="icofont-close-line"></i>
                </button>
              </li>
            </ul>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submit()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{
            (task_type == 'todo' && todoForm.value.id) ||
            (task_type == 'question' && questionForm.value.id) ||
            (task_type == 'message' && messageForm.value.id) ||
            (task_type == 'questionnaire' && questionnaireForm.value.id) ||
            (task_type == 'signature' && signatureForm.value.id)
              ? 'Update'
              : 'Save'
          }}
        </button>
      </div>
    </div>
  </div>
</div>
