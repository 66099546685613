import { Role } from './model.role';
import { Milestone } from './model.milestones';
export class EpisodeResponse {
    ccId: number;
    assignedToCCName?: string;
    esId: number;
    cancelReason: string;
    createdDate: string;
    description: string;
    displayName?: string;
    endDate: string;
    episodeActionStatus?: [];
    episodeAssignedTo?: EpisodeAssigned[];
    id: number;
    individualProviderId?: string;
    isCancelled: boolean;
    milestones?: Milestone[];
    name: string;
    originalStatus?: string;
    pathwayId: number;
    patientName: string;
    procedureBundle: ProcedureBundle;
    procedureBundleId: number;
    procedureBundleName?: string;
    providerBundleId: number;
    providerLocation?: string;
    providerLocationId?: string;
    status: string;
    tag: string;
    userDetail: UserDetails;
    userId: number;
    ccName: string
    esName: string
}

export class ProcedureBundle {
    id: number;
    name: string;
    providerBundle: ProviderBundle[];
}

export class ProviderBundle {
    averageCost: number;
    cost: number;
    id: number;
    individualProviderId: string;
    locationProviderId: string;
    procedureBundleId: number;
    provider: ProviderResponse;
    providerLocation: ProviderResponse;
}

export class ProviderResponse {
    businessName: string;
    firstName: string;
    holistaProviderId: string;
    id: number;
    lastName: string;
    middleName: string;
    npi: string;
    type: string;
}

export class EpisodeAssigned {
    assignedToCCId: number;
    assignedToESId: number;
    createdDate: string;
    episodeId: number;
    episode_id: number;
    id: number;
}

export class CompanyResponse {
    id: number;
    name: string;
}

export class UserDetails {
    activeRole: string;
    company: CompanyResponse;
    companyId: number;
    company_id: number;
    createdBy: string;
    email: string;
    firstname: string;
    id: number;
    isActive: boolean;
    isMobile: boolean;
    isOnline: boolean;
    lastLoginDate: string;
    lastname: string;
    memberId: string;
    name: string;
    notes: string;
    phone: string;
    role: Role;
    roleCode: string;
    roleId: string;
    role_code: string;
    secondaryRole: string;
    socketId: string;
    status: string;
    triggerSms: boolean;
    updatedBy: string;
    updatedDate: string;
}