<section class="container-fluid">
    <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
        <div class="form-group has-search mt-2 mb-2 w-25">
            <span class="form-control-feedback">
                <i class="icofont-search-1"></i>
            </span>
            <input type="text" [(ngModel)]="searchKeyword" class="form-control " name="searchKeyword"
                placeholder="Search">
        </div>
        <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#basicExample" mdbWavesEffect
           ><i class="icofont-plus"></i>
            Create</button>
    </div>
    <div class="table-wrapper">
        <div class="container-fluid">
            <div class="row">
                <table mdbTable stickyHeader="true" small="true" hover="true">
                    <thead id="scrollToTop">
                        <tr>
                            <th width="20"></th>
                            <th scope="col">Name</th>
                            <th scope="col">Value Based Program</th>
                            <th scope="col">Pathway</th>
                            <th scope="col">Bundle</th>
                            <th scope="col">Created Date</th>
                            <th width="20"></th>
                        </tr>
                    </thead>
                    <div class="loader-wrapper" *ngIf="loading"
                        [style.top]="(ledgerList && ledgerList.length==0)?'auto':0">
                        <div class="loader">
                            <app-loader [loader]="loading"></app-loader>
                        </div>
                        <div class="backdrop"></div>
                    </div>
                    <tbody *ngFor="let ledger of ledgerList">
                        <tr>
                            <td (click)="ledger.collapsed = !ledger.collapsed">
                                <button type="button" class="btn btn-link p-0 m-0" mdbWavesEffect
                                    *ngIf="ledger.collapsed">
                                    <i class="icofont-minus-circle icofont-lg text-info"></i>
                                </button>
                                <button type="button" class="btn btn-link p-0 m-0" mdbWavesEffect
                                    *ngIf="!ledger.collapsed">
                                    <i class="icofont-plus-circle icofont-lg text-info"></i>
                                </button>
                            </td>
                            <td>{{ledger.name}}</td>
                            <td>{{ledger.valueBasedProgram.name}}</td>
                            <td>{{ledger.pathway.name}}</td>
                            <td>{{ledger.bundle.name}}</td>
                            <td>{{ledger.createdDate | date: 'MM/dd/yyyy'}}</td>
                            <td>
                                <div class="custom-dropdown">
                                    <button
                                        class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                                        type="button" mdbWavesEffect>
                                        <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                                    </button>
                                    <div class="custom-dropdown-content rounded-sm z-depth-2">
                                        <a class="dropdown-item" >
                                            <i class="fa-fw fas fa-pencil-alt"></i>
                                            Edit
                                        </a>
                                        <a class="dropdown-item">
                                            <i class="fa-fw far fa-trash-alt"></i>
                                            Delete
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="ledger.collapsed" class="grey lighten-5 border">
                            <td colspan="100%" class="p-3">
                                Test
                                <!-- <app-loader [loader]="!ledger.associatedPlans"></app-loader>
                                <div *ngIf="episode.associatedPlans && episode.associatedPlans.length > 0">
                                    <h6>Associated Plan Groups</h6>
                                    <div class="chip chip-lg blue lighten-5 rounded mb-2"
                                        *ngFor="let plan of episode.associatedPlans">
                                        <mdb-icon fas icon="check-circle" class="text-primary"></mdb-icon>
                                        {{plan.planGroup}} <span>-</span>
                                        {{plan.memberResponsibility | currency}}
                                    </div>
                                </div>
                                <div *ngIf="episode.associatedPlans && episode.associatedPlans.length == 0">
                                    <h6>No Associated Plan Groups</h6>
                                </div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- <div appScrollToTop class="fixed-action-btn donotprint" style="bottom: 145px; right: 10px;display:none;">
        <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
            <i class="fas fa-angle-up text-black-50"></i>
        </a>
    </div>
    <div class="text-center p-4 border mt-3" *ngIf="!loadingData.episode && episodeOfCareList.length==0">
        <i class="far fa-3x fa-clone text-black-50"></i>
        <p>
            Start adding Episode of care.
        </p>
    </div>
    <div class="text-center p-4 border mt-3"
        *ngIf="!loadingData.episode && episodeOfCareList.length>0 && (episodeOfCareList | filter: searchKeyword:['name']).length==0">
        <i class="far fa-3x fa-clone text-black-50"></i>
        <p>
            No results found.
        </p>
    </div> -->
</section>