import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { catchError, map } from "rxjs/operators";
import { of } from 'rxjs';
import { PayeeDetail } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ClaimsService {

    constructor(
        private http: HttpClientService
    ) {
    }

    getClaimsDashboard(body) {
        return this.http.post(`${APICONSTANTS.CLAIMS}/dashboard/claim-status`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getClaims(page, limit, type, keyword: string, patientAccountNumber?) {
        return this.http.get(`${APICONSTANTS.CLAIMS}?page=${page}&limit=${limit}&type=${type}&keyword=${keyword}&patientAccountNumber=${patientAccountNumber}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    // Claim Detail Section
    getClaimsDetail(id, basicInfo?: boolean) {
        const api = basicInfo ? `${APICONSTANTS.CLAIMS}/${id}?basicInfo=true` : `${APICONSTANTS.CLAIMS}/${id}`
        return this.http.get(api)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getClaimNotes(claimId) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/${claimId}/notes`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    createNotes(body) {
        return this.http.post(`${APICONSTANTS.CLAIMS}/notes`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    deleteNote(noteId) {
        return this.http.remove(`${APICONSTANTS.CLAIMS}/notes/${noteId}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    updateSubmitterReceiver(body, transactionId, claimId) {
        return this.http.put(`/claim-submitter-receiver/${body.id}/edi-transactions/${transactionId}?claimId=${claimId}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    updateProvider(body, transactionId, claimId) {
        return this.http.put(`/claim-providers/${body.id}?transactionId=${transactionId}&claimId=${claimId}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    updateSubscriberPatientInfo(body, claimId) {
        return this.http.put(`/claim-patients/${body.id}?claimId=${claimId}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    updateClaimsInfo(body) {
        return this.http.put(`${APICONSTANTS.CLAIMS}/${body.id}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    updateHealthCareDiagnosis(claimId, body, patientAccountNumber) {
        return this.http.put(`${APICONSTANTS.CLAIMS}/${claimId}/health-care-diagnosis/${body.id}?claimId=${patientAccountNumber}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    updateServiceLineInfo(claimId, body, patientAccountNumber) {
        return this.http.put(`${APICONSTANTS.CLAIMS}/${claimId}/service-lines/${body.id}?claimId=${patientAccountNumber}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }
    // Claim Detail Section

    searchClaims(body, type, isNewPayment?) {
        return this.http.post(`${APICONSTANTS.CLAIMS}/search?type=${type}&isFromPayment=${isNewPayment ? 1 : 0}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchPatient(name, claimType) {
        return this.http.get(`/claim-patients?keyword=${name}&claimType=${claimType}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchEpisode(name, claimType) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/episodes?keyword=${name}&claimType=${claimType}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchPayer(name, claimType) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/payers?keyword=${name}&claimType=${claimType}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchClient(name, claimType) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/clients?keyword=${name}&claimType=${claimType}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchPurchaser(name, claimType) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/purchasers?keyword=${name}&claimType=${claimType}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchSubmitter(name) {
        return this.http.get(`/claim-submitter-receiver/submitters?keyword=${name}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchBillingProvider(name) {
        return this.http.get(`/claim-providers?identifierCode=85&keyword=${name}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchRenderingProvider(name) {
        return this.http.get(`/claim-providers?identifierCode=82&keyword=${name}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    validateClaimAssociation(id, body) {
        return this.http.post(`${APICONSTANTS.CLAIMS}/${id}/validate-claim-association`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    associateEpisode(id, body) {
        return this.http.put(`${APICONSTANTS.CLAIMS}/${id}/associate-episode`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    reAssociateEpisode(id, body) {
        return this.http.put(`${APICONSTANTS.CLAIMS}/${id}/reassociate-claim`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    updateStatus(id, body) {
        return this.http.patch(`${APICONSTANTS.CLAIMS}/${id}/update-status`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getBillingProviders(searchText, isBatchPayment?) {
        return this.http.get(`/claim-providers/billing-providers?keyword=${searchText}&batchPayment=${isBatchPayment ? true : false}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getClaimAssociationsByClaimIdentifier(claimIdentifier, paymentType) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/claim-xref-episode-association/${claimIdentifier}/${paymentType}`).pipe(
            map((res: any) => {
                return res.data;
            }),
            catchError(err => {
                return of(err);
            })
        )

    }

    delete(id) {
        return this.http.remove(`${APICONSTANTS.CLAIMS}/${id}`).pipe(
            map((res: any) => {
                return res.data;
            })
        )
    }

    getClaimIdentifierForPayment(searchText, claimType) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/claim-xref-holista-for-payment/${searchText}/${claimType}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    // Outgoing-Claims

    getEdi(id, type) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/${id}/edi-files?type=${type}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getRemittanceSummary(claimId) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/${claimId}/remittance-summary`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getClaimInvoice(claimId) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/${claimId}/invoice`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }


    approveClaim(id, body, type) {
        let formData = new FormData();
        if (type === 'UB04' || type === 'INVOICE') {
            for (const key in body) {
                Array.isArray(body[key])
                    ? body[key].forEach(value => formData.append(key, value))
                    : formData.append(key, body[key]);
            }
        }
        const request = type === 'UB04' || type === 'INVOICE'
            ? this.http.put(`${APICONSTANTS.CLAIMS}/${id}/approve?type=${type}`, formData)
            : this.http.put(`${APICONSTANTS.CLAIMS}/${id}/approve?type=${type}`, body);
        return request.pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    updateOutGoingClaimStatus(id, body) {
        return this.http.patch(`${APICONSTANTS.CLAIMS}/${id}/update-outgoing-status`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getUrls(query) {
        return this.http.get(`/pwp-manuals`, query)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    searchBillingProviderList(keyword) {
        return this.http.get(`/claim-providers/billing-providers?keyword=${keyword}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    fetchExceptionCodes() {
        return this.http.get(`${APICONSTANTS.CLAIMS}/exception-codes`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    runAutomation(claimId: number) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/automation/run?claimId=${claimId}`)
            .pipe(map((res: any) => {
                return res.data
            }))
    }

    getUB04Details(claimId) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/${claimId}/ub04`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getBundlesByPolicyNumber(policyNumber: string) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/${policyNumber}/bundles`).pipe(
            map((res: any) => {
                return res.data;
            })
        )
    }

    getPaidAtZeroBundleCodes(code: string) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/paid-at-zero/bundle-codes?purchaserCode=${code}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getBillingProviderById(id: number) {
        return this.http.get(`/claim-providers/billing-providers?id=${id}`)
            .pipe(map((res: any) => {
                return res.data[0];
            }));
    }

    getPayeeDetail(keyword: string) {
        return this.http.get(`${APICONSTANTS.PAYEE_INFO}?limit=0&keyword=${keyword}`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    updatePayeeDetail(body: PayeeDetail) {
        return this.http.post(`${APICONSTANTS.CLAIMS_PAYEE_REFERENCE}`, body).pipe(map((response: any) => {
            return response.data;
        }))
    }

    getPayeeReferences(keyword: string) {
        return this.http.get(`${APICONSTANTS.CLAIMS_PAYEE_REFERENCE}?keyword=${keyword}`).pipe(map((response: any) => {
            return response.data;
        }))
    }

    getClaimStatus() {
        return this.http.get(`${APICONSTANTS.CLAIMS}/allowed-status`).pipe(map((response: any) => {
            return response.data;
        }))
    }
}