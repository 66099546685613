import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ACCOUNTS_PAYABLE_REPORT_DATA, ACCOUNTS_PAYABLE_DATE_TYPE_LIST } from '../../../../constants';
import { HolistaUtils } from '../../../../utils';
import { AccountsPayableData, TransactionReportFilterCriteria } from '../../../../models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClaimsService, ReportService } from '../../../../services';
import { debounceTime, finalize, map, startWith, switchMap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';

@Component({
  selector: 'app-accounts-payable',
  templateUrl: './accounts-payable.component.html',
  styleUrls: ['./accounts-payable.component.scss']
})
export class AccountsPayableComponent implements OnInit {

  accountsPayableReport = {};
  data = {} as AccountsPayableData;
  selectedIndividualData: any = {};
  parsedTransactionReportFilterData = {} as TransactionReportFilterCriteria;
  filterForm: FormGroup;
  isLoading = {
    report: false,
    providerList: false
  };
  providerList: any[] = [];
  dateTypeList = ACCOUNTS_PAYABLE_DATE_TYPE_LIST;
  backupSelectedIndividualData: any = {};
  billingProviderSearchText = new Subject();
  billingProviderResults: Observable<any>;

  @Input() set transactionReportFilterData(data) {
    if (data) {
      this.parsedTransactionReportFilterData = this._holistaUtils.deepClone(data);
      this.parsedTransactionReportFilterData.startDate && this.parsedTransactionReportFilterData.endDate && this.getAccountsPayableData();
    };
  };
  @Input() set downloadFile(downloadFile) {
    if (downloadFile.download) {
      this.filtersApplied.emit({ reportType: ACCOUNTS_PAYABLE_REPORT_DATA.EXCEL_NAME, filters: this._holistaUtils.getFormattedValues(this.filterForm.value) });
    };
  };
  @Input() episodeTypeList = [];
  @Output() reportExpansion = new EventEmitter<any>();
  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private _holistaUtils: HolistaUtils,
    private _formBuilder: FormBuilder,
    private _reportService: ReportService,
    private _claimsService: ClaimsService
  ) {
    this.setFilterForm();
  }

  ngOnInit(): void {
    this.billingProviderResults = this.billingProviderSearchText
      .pipe(
        startWith(this.billingProviderSearchText),
        switchMap((billingProviderSearchText: string) => this.searchBillingProvider(billingProviderSearchText))
      );
  }

  setFilterForm() {
    this.filterForm = this._formBuilder.group({
      providerPayee: [null],
      episodeType: [[]],
      dateType: [this.dateTypeList[0].value],
      transactionNo: [null]
    });
  }

  searchBillingProvider(searchText: string) {
    if (!searchText) {
      this.filterForm.controls['providerPayee'].setValue(null);
      this.getAccountsPayableData();
      return of([]);
    } else {
      if (searchText.length > 2) {
        return this._claimsService.searchBillingProvider(searchText)
          .pipe(
            debounceTime(250),
            map((items: any) => {
              return items
            })
          );
      } else {
        return of([]);
      };
    };
  }

  onDisplaySelectedProvider = (provider): string => {
    return this._holistaUtils.toTitleCase(provider.displayName);
  };

  onProviderSelected(event) {
    this.filterForm.controls['providerPayee'].setValue(event.text.identificationCode);
    this.getAccountsPayableData();
  }

  getAccountsPayableData() {
    this.isLoading.report = true;
    const { clientCode, startDate, endDate, networkCode } = this.parsedTransactionReportFilterData;
    const params = {
      startDate,
      endDate,
      networkCode,
      ...clientCode && { clientCode },
      ...this._holistaUtils.getFormattedValues(this.filterForm.value),
    };
    this._reportService.getAccountsPayableReport(params)
      .pipe(finalize(() => { this.isLoading.report = false; }))
      .subscribe(response => {
        if (response.success) {
          this.data = response.data;
          this.getAccountsPayableReport();
        };
      }, error => {
        console.log('error getting acoounts payable report', error);
      });
  }

  getAccountsPayableReport() {
    const { EXCEL_NAME, TABLE_HEADERS } = ACCOUNTS_PAYABLE_REPORT_DATA;
    let tableHeaders = [];
    if (this.filterForm.get('dateType').value === 'payment') {
      const headers = this._holistaUtils.deepClone(TABLE_HEADERS);
      tableHeaders = [...headers];
      tableHeaders.splice(0, 1, { label: 'Date', value: 'paymentDate' });
    } else tableHeaders = TABLE_HEADERS;
    this.accountsPayableReport = {
      data: this.data.accountPayables,
      tableHeaders,
      excelName: EXCEL_NAME
    };
  }

  onTransactionNumberChange(event) {
    if (event.type === 'keyup' || !event.target.value) {
      event.type === 'keyup'
        ? this.filterForm.controls['transactionNo'].setValue(event.target.value.toUpperCase())
        : this.filterForm.controls['transactionNo'].setValue(null);
      this.getAccountsPayableData();
    };
  };
}
