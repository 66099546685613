import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataLinkService {
  private dataSource = new BehaviorSubject<{ key: string; value: unknown }>({
    key: '',
    value: null,
  });
  constructor() { }

  get getDataSource() {
    return this.dataSource;
  }

  public setDataSource(value: { key: string; value: unknown }) {
    this.dataSource.next(value);
  }
}
