<div>
  <mdb-tabset
    #tabs
    [buttonClass]="'classic-tabs grey lighten-4 pt-4 pl-4'"
    [contentClass]="'tab-clean'"
    class="classic-tabs custom-tabs m-4">
    <!-- CURRENT SECTION STARTS -->
    <mdb-tab heading="Current" (select)="onTabSelected($event)">
      <section class="container">
        <div class="d-flex justify-content-between align-items-center">
          <div class="form-group has-search input-group my-2 w-25 w-xs-100">
            <span class="form-control-feedback left-0">
              <i class="icofont-search-1"></i>
            </span>
            <input
              type="text"
              [(ngModel)]="searchKeyword"
              class="form-control"
              id="search"
              placeholder="Search" />
            <div class="input-group-append" *ngIf="searchKeyword">
              <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
                <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
              </button>
            </div>
          </div>
        </div>

        <table mdbTable hover="true" small="true">
          <thead>
            <tr>
              <th>Role Code</th>
              <th>Role Name</th>
              <th colspan="2"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let role of roles | filter : searchKeyword : ['name', 'code']">
              <td>{{ role?.code }}</td>
              <td>{{ role?.name }}</td>
              <td></td>
              <td>
                <div class="d-flex justify-content-end align-items-center">
                  <button
                    class="mt-0 mb-0 ml-0 mr-3 btn btn-secondary btn-sm"
                    type="button"
                    (click)="openPermissionModal(role)"
                    mdbWavesEffect>
                    {{
                      utilityAccess.searchAccess('RM', 'isEditable', false)
                        ? 'Assign Permission'
                        : 'View
                    Permission'
                    }}
                  </button>
                  <div class="custom-dropdown">
                    <button
                      class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                      type="button"
                      mdbWavesEffect>
                      <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                    </button>
                    <div class="custom-dropdown-content rounded-sm z-depth-2">
                      <a class="dropdown-item" (click)="openRoleModal(role)"
                        ><i class="icofont-edit-alt"></i> Edit</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-center p-4 border mt-3" *ngIf="!roles || roles.length == 0">
          <i class="fas fa-3x fa-shield-alt text-black-50"></i>
          <p>Start adding Roles.</p>
        </div>

        <div
          class="text-center p-4 border mt-3"
          *ngIf="
            roles.length > 0 && (roles | filter : searchKeyword : ['name', 'code']).length == 0
          ">
          <i class="fas fa-3x fa-shield-alt text-black-50"></i>
          <p>No results found.</p>
        </div>

        <!-- UPDATE PERMISSION MODAL -->
        <div
          mdbModal
          #permissionModal="mdbModal"
          class="modal fade"
          id="permissionModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
          [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title w-100">{{ role.name }}</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="closePermissionModal()">
                  <i class="icofont-close-line"></i>
                </button>
              </div>
              <div class="modal-body">
                <app-loader [loader]="loading.permissions"></app-loader>
                <div
                  class="scrollbar scrollbar-primary thin overflow-auto"
                  style="max-height: 425px">
                  <table
                    mdbTable
                    hover="true"
                    small="true"
                    *ngIf="permissions"
                    class="position-relative">
                    <thead>
                      <tr class="position-sticky top-0" style="background: #ffffff; z-index: 99">
                        <th>Permissions</th>
                        <th>Read</th>
                        <th>Write</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let permission of permissions; let i = index">
                        <td>
                          <mdb-checkbox class="d-none">Material unchecked</mdb-checkbox>
                          <div class="form-check pl-0">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="materialChecked{{ i }}"
                              [checked]="permission.accessType !== null"
                              (click)="changeActiveStatus($event.target.checked, permission)"
                              [disabled]="
                                !utilityAccess.searchAccess('RM', 'isEditable', false) ||
                                role.code === 'PA'
                              " />
                            <label class="form-check-label" for="materialChecked{{ i }}">{{
                              permission.permission.name
                            }}</label>
                          </div>
                        </td>
                        <td>
                          <div class="form-check radio-green pl-0">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="defaultExampleRadios-{{ i }}"
                              id="defaultChecked1-{{ i }}"
                              [checked]="permission.accessType && permission.accessType === 'R'"
                              (change)="changeAccessType(permission, 'R')"
                              [disabled]="
                                !utilityAccess.searchAccess('RM', 'isEditable', false) ||
                                permission.accessType === null ||
                                role.code === 'PA'
                              " />
                            <label
                              class="custom-control-label"
                              for="defaultChecked1-{{ i }}"></label>
                          </div>
                        </td>
                        <td>
                          <div class="form-check radio-green pl-0">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="defaultExampleRadios-{{ i }}"
                              id="defaultChecked2-{{ i }}"
                              [checked]="permission.accessType && permission.accessType === 'W'"
                              (change)="changeAccessType(permission, 'W')"
                              [disabled]="
                                !utilityAccess.searchAccess('RM', 'isEditable', false) ||
                                permission.accessType === null ||
                                role.code === 'PA'
                              " />
                            <label
                              class="custom-control-label"
                              for="defaultChecked2-{{ i }}"></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                *ngIf="utilityAccess.searchAccess('RM', 'isEditable', false)"
                class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
                <button
                  class="waves-light m-0 btn-light btn"
                  mdbWavesEffect
                  (click)="closePermissionModal()">
                  Cancel
                </button>
                <button
                  class="btn btn-secondary waves-light m-0"
                  mdbWavesEffect
                  (click)="updatePermission()"
                  [disabled]="submit_enabled || role?.code == 'PA'">
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="submit_enabled"></span>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- CREATE ROLE MODAL -->
        <div
          mdbModal
          #createRoleModal="mdbModal"
          class="modal fade"
          id="modalLoginForm"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
          [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title w-100">{{ roleForm.value.id ? 'Edit' : 'Add' }} Role</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="closeRoleModal()">
                  <i class="icofont-close-line"></i>
                </button>
              </div>
              <div class="modal-body">
                <form [formGroup]="roleForm">
                  <div class="form-group">
                    <label for="roleName">Name *</label>
                    <input
                      type="text"
                      id="roleName"
                      class="form-control"
                      formControlName="name"
                      mdbInput
                      [ngClass]="{'is-invalid': submitted && roleForm.get('name').invalid}" />
                  </div>
                  <div class="form-group">
                    <label for="rolecode">Code*</label>
                    <input
                      type="text"
                      id="rolecode"
                      class="form-control"
                      formControlName="code"
                      mdbInput
                      [readonly]="roleForm.value.id"
                      [ngClass]="{'is-invalid': submitted && roleForm.get('code').invalid}" />
                  </div>
                </form>
              </div>
              <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2">
                <button
                  class="waves-light m-0 btn-light btn"
                  mdbWavesEffect
                  (click)="closeRoleModal()">
                  Cancel
                </button>
                <button
                  class="btn btn-secondary waves-light m-0"
                  mdbWavesEffect
                  (click)="submitRole()"
                  [disabled]="submit_enabled">
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="submit_enabled"></span>
                  {{ roleForm.value.id ? 'Update' : 'Save' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </mdb-tab>
    <!-- CURRENT SECTION ENDS -->

    <!-- UPDATED SECTION STARTS -->
    <mdb-tab heading="Updated" (select)="onTabSelected($event)">
      <section class="container">
        <div class="d-flex justify-content-between align-items-center">
          <div class="form-group has-search input-group my-2 w-25 w-xs-100">
            <span class="form-control-feedback left-0">
              <i class="icofont-search-1"></i>
            </span>
            <input
              type="text"
              [(ngModel)]="roleListSearchKeyword"
              class="form-control"
              id="search"
              placeholder="Search" />
            <div class="input-group-append" *ngIf="roleListSearchKeyword">
              <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
                <i class="icofont-close-line fa-2x" (click)="removeRoleListSearchKeyword()"></i>
              </button>
            </div>
          </div>
          <!-- <div>
            <button
              type="button"
              class="btn btn-secondary"
              data-toggle="modal"
              data-target="#basicExample"
              (click)="addEditRole()"
              mdbWavesEffect>
              <i class="icofont-plus"></i> Add Role
            </button>
          </div> -->
        </div>

        <div class="table-wrapper">
          <table mdbTable hover="true" small="true" class="table-mobile-view mb-5">
            <thead>
              <tr>
                <th class="w-25">Name</th>
                <th class="w-25"></th>
                <th>Modified By</th>
                <th>Last Modified</th>
                <!-- <th></th> -->
              </tr>
            </thead>

            <div class="loader-wrapper" *ngIf="loading.roles">
              <div class="loader">
                <app-loader [loader]="loading.roles"></app-loader>
              </div>
              <div class="backdrop"></div>
            </div>

            <tbody
              mdbTableCol
              *ngFor="let role of updatedRoles | filter : roleListSearchKeyword : ['name']">
              <tr [attr.data-test-id]="'role-row'">
                <td [attr.data-test-id]="'role-name'">
                  {{ role.name }}
                </td>
                <td [attr.data-test-id]="'role-permissions'">
                  <button
                    class="btn btn-outline-secondary text-capitalize border-1 m-0 py-2"
                    (click)="addEditRole(role)"
                    type="button">
                    Manage Permissions
                  </button>
                </td>
                <td [attr.data-test-id]="'role-modifiedBy'" *ngIf="role.user; else noUserName">
                  {{ role.user?.firstName }} {{ role.user?.middleName ?? '' }}
                  {{ role.user?.lastName }}
                </td>
                <ng-template #noUserName>
                  <td [attr.data-test-id]="'role-modifiedBy'"></td>
                </ng-template>
                <td [attr.data-test-id]="'role-modifiedDate'">
                  {{ role.updatedAt | date : 'MM/dd/yyyy' }}
                </td>
                <!-- <td width="32" class="d-none d-sm-block">
                  <div class="custom-dropdown" *ngIf="!role.queuedToDelete">
                    <button
                      [attr.data-test-id]="'role-ellipsis'"
                      class="btn-ellipsis border-0 dropdown-toggle m-0 btn-sm btn-floating"
                      type="button"
                      mdbWavesEffect>
                      <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                    </button>
                    <div
                      [attr.data-test-id]="'role-ellipsis-dropdown'"
                      class="custom-dropdown-content rounded-sm z-depth-2">
                      <a
                        [attr.data-test-id]="'role-delete'"
                        class="cursor-pointer dropdown-item"
                        (click)="deleteRole(role)">
                        <i class="icofont-ui-delete mr-1"></i> Delete Role</a
                      >
                    </div>
                  </div>
                  <span
                    *ngIf="role.queuedToDelete"
                    class="spinner-grow spinner-grow-sm" 
                    style="margin-left: 10px;"
                    role="status"
                    aria-hidden="true"
                    ></span>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </mdb-tab>
    <!-- UPDATED SECTION STARTS -->
  </mdb-tabset>
</div>

<app-view-permissions [id]="roleCode" (resetId)="resetRoleCode()"></app-view-permissions>

<app-delete-confirmation
  [_id]="deleteId"
  [message]="message"
  (confirmDelete)="confirmDelete($event)">
</app-delete-confirmation>
