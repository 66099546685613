<div class="m-0 p-0">
  <div class="d-flex justify-content-between align-items-center p-3 bg-blue-light">
    <div class="d-flex">
      <h5 class="font-weight-bold m-0">Value Management Dashboard</h5>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <form [formGroup]="reportFormConfig">
        <div class="d-flex justify-content-end align-items-center">
          <div class="row" style="width: 800px; max-width: 800px">
            <div class="col-md-4 p-0 pl-2">
              <div class="form-group bg-white m-0">
                <mdb-select
                  #select
                  [options]="clientLists | sort : 'label'"
                  placeholder="Select client"
                  id="date-type-select"
                  [visibleOptions]="3"
                  (selected)="onClientSelected($event)"
                  [disabled]="isClientAdmin"
                  formControlName="clientCode">
                </mdb-select>
              </div>
            </div>
            <div class="col-md-2 p-0 pl-2">
              <div class="form-group bg-white m-0">
                <mdb-select
                  [options]="reportDateList | sort : 'label'"
                  placeholder="Select date"
                  id="report-date-select"
                  [visibleOptions]="3"
                  formControlName="reportDate"
                  (selected)="selectedReportDate()">
                </mdb-select>
              </div>
            </div>
            <div class="col-md-6 p-0 pl-2">
              <div class="form-row my-1">
                <div class="col">
                  <div class="form-group bg-white m-0">
                    <mdb-date-picker
                      #toDatePicker
                      [inline]="true"
                      name="fromDate"
                      id="from-date-select"
                      [options]="startDateOptions"
                      placeholder="From"
                      formControlName="startDate"
                      [disabled]="isPreSelectedFilter || !reportFormConfig.value.reportDate"
                      (dateChanged)="onDateChange($event, 'startDate')">
                    </mdb-date-picker>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group bg-white m-0">
                    <mdb-date-picker
                      #toDatePicker
                      [inline]="true"
                      name="toDate"
                      id="to-date-select"
                      [options]="endDateOptions"
                      placeholder="To"
                      formControlName="endDate"
                      [disabled]="isPreSelectedFilter || !reportFormConfig.value.reportDate"
                      (dateChanged)="onDateChange($event, 'endDate')">
                    </mdb-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <button
        type="button"
        class="waves-light btn btn-secondary text-center text-white text-uppercase ml-4"
        data-toggle="modal"
        data-target="#downloadPDF"
        [disabled]="isDownloadPDF.download || reportExpanded || isDownloadDisabled"
        placement="bottom"
        mdbWavesEffect
        id="downloadAlert"
        [mdbPopover]="downloadAlert"
        #pop="bs-mdbPopover">
        <span
          *ngIf="isDownloadPDF.download"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"></span>
        <i class="icofont-download" *ngIf="!isDownloadPDF.download"></i>
        <span class="d-none d-sm-inline-block ml-1">Download</span>
      </button>
      <ng-template #downloadAlert>
        <div class="d-flex justify-content-between align-items-center">
          <p>Choose the file type to download.</p>
          <a class="ml-4 mb-3" aria-label="Close" (click)="pop.hide()">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="text-center">
          <p class="mb-0">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              [disabled]="false"
              (click)="download('excel'); pop.hide()">
              Excel
            </button>
            <button
              type="button"
              [disabled]="disableDownloadPDF"
              (click)="download('pdf'); pop.hide()"
              class="btn btn-secondary btn-sm">
              PDF
            </button>
          </p>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- Tabs Starts -->
  <div style="overflow-x: hidden">
    <!-- Nav tabs -->
    <mdb-tabset
      #tabs
      [buttonClass]="'classic-tabs bg-blue-light border-top rounded-0 '"
      [contentClass]="'card m-0 shadow-none p-0'"
      (getActiveTab)="onGetActiveTab($event)"
      class="classic-tabs bg-blue-light border-0 p-0">
      <div class="d-flex p-3 border-top bg-blue-light" *ngIf="loading.tabList">
        <app-loader [loaderSmall]="true" [loader]="loading.tabList"></app-loader>
      </div>
      <mdb-tab *ngFor="let tab of tabLists" heading="{{ tab.name }}">
        <app-overview
          *ngIf="tab.code === REPORT_TAB_CODE.OVERVIEW"
          (downloadedPDF)="isDownloaded($event)"
          (reportExpanded)="isReportExpanded($event)"
          (downloadButtonEnabled)="isDownloadButtonEnabled($event)"
          [downloadPDF]="isDownloadPDF"
          [valueManagementFilterData]="valueManagementFilterData"></app-overview>
        <app-patient-experience
          *ngIf="tab.code === REPORT_TAB_CODE.PATIENT_EXPERIENCE"
          (downloadedPDF)="isDownloaded($event)"
          (reportExpanded)="isReportExpanded($event)"
          (downloadButtonEnabled)="isDownloadButtonEnabled($event)"
          [downloadPDF]="isDownloadPDF"
          [valueManagementFilterData]="valueManagementFilterData"></app-patient-experience>
        <app-consented-patient-comments
          *ngIf="tab.code === REPORT_TAB_CODE.CONSENTED_PATIENTS_COMMENTS"
          (downloadedPDF)="isDownloaded($event)"
          (reportExpanded)="isReportExpanded($event)"
          (downloadButtonEnabled)="isDownloadButtonEnabled($event)"
          [downloadPDF]="isDownloadPDF"
          [valueManagementFilterData]="valueManagementFilterData"></app-consented-patient-comments>
      </mdb-tab>
    </mdb-tabset>
  </div>
  <!-- Tabs End -->

  <div
    class="d-flex justify-content-center align-items-center m-4 py-4 border no-conversation-container h-100"
    *ngIf="
      !loading.networkConfig &&
      !loading.clientList &&
      !loading.reportConfiguration &&
      !loading.tabList &&
      !tabLists.length
    ">
    <div class="text-center mt-3">
      <i class="icofont-file-document icofont-4x text-secondary"></i>
      <p class="mt-2">No report configuration.</p>
    </div>
  </div>
</div>
