<div
  class="d-flex justify-content-start align-items-start list-content p-2"
  *ngIf="taskSignature && taskSignature.id">
  <div class="icon-block">
    <i class="fas fa-file-signature skin-text-primary-dark ml-2" style="font-size: 21px"></i>
  </div>
  <div class="d-flex justify-content-between w-100 align-items-center">
    <span class="pl-2 pr-4">
      <p class="font-weight-400">
        {{ taskSignature.name }}
      </p>

      <a
        class="text-break"
        href="{{ taskSignature.taskTodoLink }}"
        target="_blank"
        *ngIf="taskSignature.taskTodoLink"
        >{{ taskSignature.documentDisplayName }}</a
      >
    </span>
    <span class="action-item position-relative d-none">
      <i
        class="icofont-ui-edit icofont-sm mr-2"
        #actionBtn
        (click)="editTaskHandler(taskSignature)"></i>
      <i
        class="icofont-ui-delete icofont-sm mr-2"
        #actionBtn
        (click)="deleteTaskHandler(taskSignature)"></i>
    </span>
  </div>
</div>

<app-milestone-topic-delete
  [deleteData]="taskToBeDelete"
  (deletedData)="deleteTask($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>
