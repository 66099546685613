import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'updateChatTime' })
export class ChatDate
    implements PipeTransform {
    transform(date: any, prevDate?: any) {
        if (!prevDate) {
            return moment(date * 1000).format('MMM Do, YYYY')
        } else {
            if (((moment(moment(date * 1000).format("l"))).diff((moment(moment(prevDate * 1000).format("l"))), 'days')) > 0)
                return moment(date * 1000).format('MMM Do, YYYY')
            else
                return ''
        }
    }
}