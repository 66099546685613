import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Injectable()

export class EmailUtility {
    /**
     * checks for validation on emails
     * @param control 
     * @returns 
     */
    validateEmail(control: AbstractControl) {
        const emailRegex = /^[\w+-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (control.value) {
            const emails = control.value.split(',').map(e => e.trim());
            const forbidden = emails.some((email: string) => email.match(emailRegex) ? false : true);
            return forbidden ? { 'to': { value: control.value } } : null;
        }
    }
}