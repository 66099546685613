import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ACCOUNTS_RECEIVABLE_REPORT_DATA, ACCOUNTS_RECEIVABLE_SUMMARY_DATE_TYPE_LIST } from '../../../../constants';
import { AccountsReceivableData, FormattedAccountReceivableSummaryData, TransactionReportFilterCriteria } from '../../../../models';
import { HolistaUtils } from '../../../../utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportService } from '../../../../services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-accounts-receivable',
  templateUrl: './accounts-receivable.component.html',
  styleUrls: ['./accounts-receivable.component.scss']
})
export class AccountsReceivableComponent implements OnInit {
  accountsReceivableReport: any = {};
  isClientSelected: boolean = false;
  data = {} as AccountsReceivableData;
  totalFundsReceived: number = 0;
  selectedIndividualData: any = {};
  parsedTransactionReportFilterData = {} as TransactionReportFilterCriteria;
  filterForm: FormGroup;
  isLoading = {
    report: true
  };
  summaryData = [];
  ACCOUNTS_RECEIVABLE_REPORT_DATA = ACCOUNTS_RECEIVABLE_REPORT_DATA;
  dateTypeList = ACCOUNTS_RECEIVABLE_SUMMARY_DATE_TYPE_LIST;
  backupSelectedIndividualData = {};
  backupSummaryData = {};

  @Input() set transactionReportFilterData(data) {
    if (data) {
      this.parsedTransactionReportFilterData = this._holistaUtils.deepClone(data);
      this.parsedTransactionReportFilterData.startDate && this.parsedTransactionReportFilterData.endDate && this.getAccountsReceivableData();
    };
  };

  @Input() set downloadFile(downloadFile) {
    if (downloadFile.download) {
      this.filtersApplied.emit({ reportType: ACCOUNTS_RECEIVABLE_REPORT_DATA.EXCEL_NAME, filters: this._holistaUtils.getFormattedValues(this.filterForm.value) });
    };
  };
  @Input() episodeTypeList = [];
  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private _holistaUtils: HolistaUtils,
    private _formBuilder: FormBuilder,
    private _reportService: ReportService
  ) {
    this.setFilterForm();
  }

  ngOnInit(): void {
  }

  setFilterForm() {
    this.filterForm = this._formBuilder.group({
      episodeType: [[]],
      dateType: [this.dateTypeList[0].value]
    });
  }

  getAccountsReceivableData() {
    this.isLoading.report = true;
    this.isClientSelected = false;
    const { clientCode, startDate, endDate, networkCode } = this.parsedTransactionReportFilterData;
    const params = {
      ...clientCode && { clientCode },
      startDate,
      endDate,
      networkCode,
      ...this.filterForm.value
    };
    this._reportService.getAccountsReceivableReport(params)
      .pipe(finalize(() => { this.isLoading.report = false; }))
      .subscribe(response => {
        if (response.success) {
          this.data = response.data;
          this.backupSummaryData = response.data.summary;
          this.getAccountsReceivableReport();
        };
      }, error => {
        console.log('error getting acoounts receivable report', error);
      });
  }

  getAccountsReceivableReport() {
    const { EXCEL_NAME, ROOT_DATA } = ACCOUNTS_RECEIVABLE_REPORT_DATA;
    const { TABLE_HEADERS } = ROOT_DATA;
    let tableHeaders = [];
    if (this.filterForm.get('dateType').value === 'invoiced') {
      const headers = this._holistaUtils.deepClone(TABLE_HEADERS);
      tableHeaders = [...headers];
      tableHeaders.splice(1, 1, { label: 'Date Invoiced', value: 'dateInvoiced' });
    } else tableHeaders = TABLE_HEADERS;
    this.accountsReceivableReport = {
      data: this.data.formattedAccountReceivable,
      tableHeaders,
      excelName: EXCEL_NAME,
    };
  }

  onClientSelected(event: any) {
    this.isClientSelected = true;
    this.selectedIndividualData = event.dateFundsReceived
      ? this.data.formattedAccountReceivable.find(({ dateFundsReceived }) => dateFundsReceived === event.dateFundsReceived)
      : this.data.formattedAccountReceivable.find(({ dateInvoiced }) => dateInvoiced === event.dateInvoiced);

    this.backupSelectedIndividualData = this._holistaUtils.deepClone(this.selectedIndividualData);
    const { TABLE_HEADERS } = ACCOUNTS_RECEIVABLE_REPORT_DATA.NESTED_DATA;
    this.accountsReceivableReport = {
      ...this.accountsReceivableReport,
      data: this.selectedIndividualData.patient,
      tableHeaders: TABLE_HEADERS,
      tableTitle: event.client
    };

    const { totalClientFee, totalFundRequested, total, totalHolistaFee } = this.selectedIndividualData;
    this.data.summary = {
      clientDistribution: totalClientFee,
      episodes: this.selectedIndividualData.patient.length,
      fundsReceived: total,
      fundsRequested: totalFundRequested,
      holistaDistribution: totalHolistaFee
    };
  }

  handleBackToClientView() {
    this.isClientSelected = false;
    this.getAccountsReceivableReport();
    this.data.summary = this.backupSummaryData as FormattedAccountReceivableSummaryData;
    this.filterForm.controls.episodeType.setValue([]);
  }

  onEpisodeTypeSelected() {
    if (this.filterForm.get('episodeType').value.length) {
      const newTotal = {
        total: 0,
        totalClientFee: 0,
        totalFundRequested: 0,
        totalHolistaFee: 0
      };
      this.accountsReceivableReport.data = this.selectedIndividualData.patient.filter(data => this.filterForm.get('episodeType').value.includes(data.episodeType));
      this.accountsReceivableReport.data.forEach(item => {
        newTotal.totalFundRequested += item.fundsRequested;
        newTotal.totalClientFee += item.clientFee;
        newTotal.total += item.fundReceived;
        newTotal.totalHolistaFee += item.holistaFee;
      });
      this.selectedIndividualData = { ...this.selectedIndividualData, ...newTotal };
      const { total, totalClientFee, totalFundRequested, totalHolistaFee } = newTotal;
      this.data.summary = {
        clientDistribution: totalClientFee,
        episodes: this.accountsReceivableReport.data.length,
        fundsReceived: total,
        fundsRequested: totalFundRequested,
        holistaDistribution: totalHolistaFee
      };
      return;
    };
    this.accountsReceivableReport.data = this.selectedIndividualData.patient;
    this.selectedIndividualData = this.backupSelectedIndividualData;

    const { totalClientFee, total, totalFundRequested, totalHolistaFee } = this.selectedIndividualData;
    this.data.summary = {
      clientDistribution: totalClientFee,
      episodes: this.selectedIndividualData.patient.length,
      fundsReceived: total,
      fundsRequested: totalFundRequested,
      holistaDistribution: totalHolistaFee
    };
  }
}
