import { Injectable } from "@angular/core";

@Injectable()

export class MetaVariablesBinding {
    getVariablePath(key: string, module: string) {
        switch (module) {
            case 'claim':
                return this.getClaimVariablePath(key);
            case 'episode':
                return this.getEpisodeVariablePath(key);
            case 'user':
                return this.getUserVariablePath(key);
        }
    }

    getClaimVariablePath(key: string) {
        switch (key) {
            case 'clientName':
                return 'claimXRefHolista?.clientName';
            case 'patientName':
                return 'patient?.displayName';
            case 'providerName':
                return 'transaction?.billingProvider?.basicInfo?.businessName';
            case 'patientDob':
                return 'patient?.subscriberDob';
            case 'subscriberId':
                return 'patient?.subscriberIdentificationCode';
            case 'episodeName':
                return 'claimXRefHolista?.episodeName';
            case 'procedureDate':
                return 'claimXRefHolista?.mainProcedureDate';
            case 'processingStatus':
                return 'claimXRefHolista?.processingStatus';
            case 'claimId':
                return 'patientAccountNumber'
            default:
                return;
        }
    }

    getEpisodeVariablePath(key: string) {
        switch (key) {
            case 'patientName':
                return 'patient?.fullName';
            case 'episodeName':
                return 'name';
            case 'clientPhoneNumber':
                return 'client?.phone'
            default:
                return;
        }
    }

    getUserVariablePath(key: string) {
        switch (key) {
            case 'userName':
                return 'fullName';
            case 'userContactInfo':
                return 'phone';
        }
    }
}