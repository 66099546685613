<div
  class="my-3 col-md-12"
  [attr.id]="expand.patientSatisfactionKeyQuestions ? 'patience-experience-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS) &&
    expand.patientSatisfactionKeyQuestions
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="patientSatisfactionRateForm" style="min-width: 200px">
            <div class="d-flex justify-content-between align-items-center">
              <span
                *ngIf="isIndividual && loading.subCategoryList"
                class="spinner-grow spinner-grow-sm mr-3"
                role="status"
                aria-hidden="true"></span>
              <mdb-select
                *ngIf="isIndividual"
                [options]="patientSatisfactionSubCategoryList | sort : 'label'"
                [disabled]="loading.subCategoryList"
                id="sub-category"
                placeholder="Select sub category"
                [visibleOptions]="4"
                formControlName="subCategoryName"
                (selected)="subCategorySelected($event, 'patient-satisfaction-key-question')">
              </mdb-select>
            </div>
          </form>
          <span class="link pointer-cursor" (click)="showfilterModal()">
            <i class="fas fa-cog ml-3"></i>
          </span>
          <span
            class="link pointer-cursor ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="min-height: 500px">
      <canvas
        baseChart
        *ngIf="
          !loading.patientSatisfactionKeyQuestions &&
          patientSatisfactionKeyQuestionsChart.labels.length
        "
        [datasets]="patientSatisfactionKeyQuestionsChart.dataset"
        [labels]="patientSatisfactionKeyQuestionsChart.labels"
        [options]="patientSatisfactionKeyQuestionsChart.options"
        [plugins]="patientSatisfactionKeyQuestionsChart.plugins"
        [chartType]="patientSatisfactionKeyQuestionsChart.chartType">
      </canvas>
      <p
        *ngIf="
          !loading.patientSatisfactionKeyQuestions &&
          !patientSatisfactionKeyQuestionsChart.labels.length
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.patientSatisfactionKeyQuestions">
        <app-loader [loader]="loading.patientSatisfactionKeyQuestions"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  class="my-3 col-md-12"
  [attr.id]="expand.surveyResponseRateYTD ? 'patience-experience-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD) &&
    expand.surveyResponseRateYTD
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="surveyResponseRateForm" style="min-width: 200px">
            <div class="d-flex justify-content-between align-items-center">
              <span
                *ngIf="isIndividual && loading.subCategoryList"
                class="spinner-grow spinner-grow-sm mr-3"
                role="status"
                aria-hidden="true"></span>
              <mdb-select
                *ngIf="isIndividual"
                [options]="surveyResponseRateSubCategoryList | sort : 'label'"
                [disabled]="loading.subCategoryList"
                id="survey-response-rate-sub-category"
                placeholder="Select sub category"
                [visibleOptions]="4"
                formControlName="subCategoryName"
                (selected)="subCategorySelected($event, 'survey-response-rate')">
              </mdb-select>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="min-height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.surveyResponseRateYTD && surveyResponseRateYTDChart.labels.length"
        [datasets]="surveyResponseRateYTDChart.dataset"
        [labels]="surveyResponseRateYTDChart.labels"
        [options]="surveyResponseRateYTDChart.options"
        [plugins]="surveyResponseRateYTDChart.plugins"
        [chartType]="surveyResponseRateYTDChart.chartType">
      </canvas>
      <p *ngIf="!loading.surveyResponseRateYTD && !surveyResponseRateYTDChart.labels.length">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.surveyResponseRateYTD">
        <app-loader [loader]="loading.surveyResponseRateYTD"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  class="my-3 col-md-12"
  [attr.id]="expand.summaryQuestionWithHighestVolumeOfNo ? 'patience-experience-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO) &&
    expand.summaryQuestionWithHighestVolumeOfNo
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="highestVolumeOfNoForm">
            <div class="d-flex justify-content-between align-items-center">
              <div class="w-100 d-flex justify-content-between align-items-center">
                <div style="display: inline-block; width: 150px">
                  <mdb-select
                    [options]="yearOptions.highestVolumeOfNo"
                    id="category"
                    placeholder="Year"
                    [multiple]="true"
                    [enableSelectAll]="true"
                    (selected)="highestVolumeOfNoYearSelected($event)"
                    (deselected)="highestVolumeOfNoYearDeselected($event)"
                    formControlName="selectedYear">
                  </mdb-select>
                </div>
              </div>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="
              onViewToggle(REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO, false)
            ">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="min-height: 500px">
      <canvas
        baseChart
        *ngIf="
          !loading.summaryQuestionWithHighestVolumeOfNo &&
          summaryQuestionWithHighestVolumeOfNoChart.labels.length
        "
        [datasets]="summaryQuestionWithHighestVolumeOfNoChart.dataset"
        [labels]="summaryQuestionWithHighestVolumeOfNoChart.labels"
        [options]="summaryQuestionWithHighestVolumeOfNoChart.options"
        [plugins]="summaryQuestionWithHighestVolumeOfNoChart.plugins"
        [chartType]="summaryQuestionWithHighestVolumeOfNoChart.chartType">
      </canvas>
      <p
        *ngIf="
          !loading.summaryQuestionWithHighestVolumeOfNo &&
          !summaryQuestionWithHighestVolumeOfNoChart.labels.length
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.summaryQuestionWithHighestVolumeOfNo">
        <app-loader [loader]="loading.summaryQuestionWithHighestVolumeOfNo"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>

<div
  [className]="
    !expand.patientSatisfactionKeyQuestions &&
    !expand.surveyResponseRateYTD &&
    !expand.summaryQuestionWithHighestVolumeOfNo
      ? ''
      : 'd-none'
  ">
  <div
    class="mt-3"
    *ngIf="
      parsedValueManagementFilterData?.isConfigLoading &&
      !parsedValueManagementFilterData?.toShowReport.length
    ">
    <app-loader [loader]="true"></app-loader>
  </div>

  <div
    id="patience-experience-overview"
    class="m-0 p-0"
    *ngIf="
      !parsedValueManagementFilterData?.isConfigLoading &&
      parsedValueManagementFilterData?.toShowReport &&
      parsedValueManagementFilterData?.toShowReport.length
    ">
    <div class="d-flex justify-content-between align-items-center p-3">
      <span class="d-flex">
        <h6 class="mb-0 font-weight-bold mr-2">
          {{
            patientExperienceFilterForm.get('categoryName').value === 'All'
              ? 'Patience Experience Overview'
              : patientExperienceFilterForm.get('categoryName').value
          }}
        </h6>
        <span
          class="badge badge-sm bg-primary-dark text-white z-depth-0"
          *ngIf="
            parsedValueManagementFilterData.startDate && parsedValueManagementFilterData.endDate
          ">
          Reporting Period
          <span>
            - {{ parsedValueManagementFilterData.startDate }} -
            {{ parsedValueManagementFilterData.endDate }}
          </span>
        </span>
      </span>

      <form [formGroup]="patientExperienceFilterForm" id="hide-while-downloading">
        <div class="row d-flex justify-content-end align-items-center" style="min-width: 400px">
          <div class="col-md-6 pr-2 text-right">
            <label>Show</label>
          </div>
          <div class="col-md-6 p-0 pl-2 pr-3">
            <div class="form-group m-0">
              <mdb-select
                [options]="categoryList | sort : 'label'"
                placeholder="Select category"
                id="report-date-select"
                [visibleOptions]="4"
                formControlName="categoryId"
                (selected)="categorySelected($event)">
              </mdb-select>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="px-3">
      <div
        class="row"
        *ngIf="
          toShowReport &&
          (toShowReport.includes(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS) ||
            toShowReport.includes(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD))
        ">
        <div
          class="mb-4 col-md-6"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="patientSatisfactionRateForm" style="min-width: 150px">
                    <div class="d-flex justify-content-between align-items-center">
                      <span
                        *ngIf="isIndividual && loading.subCategoryList"
                        class="spinner-grow spinner-grow-sm mr-3"
                        role="status"
                        aria-hidden="true"></span>
                      <mdb-select
                        *ngIf="isIndividual"
                        [options]="patientSatisfactionSubCategoryList | sort : 'label'"
                        [disabled]="loading.subCategoryList"
                        id="sub-category"
                        placeholder="Select sub category"
                        [visibleOptions]="4"
                        formControlName="subCategoryName"
                        (selected)="
                          subCategorySelected($event, 'patient-satisfaction-key-question')
                        ">
                      </mdb-select>
                    </div>
                  </form>
                  <span class="link pointer-cursor" (click)="showfilterModal()">
                    <i class="fas fa-cog ml-3"></i>
                  </span>
                  <span
                    class="link pointer-cursor donotprint ml-3"
                    (click)="
                      onViewToggle(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS, true)
                    ">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body [style]="'height: 320px'">
              <canvas
                baseChart
                *ngIf="
                  !loading.patientSatisfactionKeyQuestions &&
                  patientSatisfactionKeyQuestionsChart.labels.length
                "
                [datasets]="patientSatisfactionKeyQuestionsChart.dataset"
                [labels]="patientSatisfactionKeyQuestionsChart.labels"
                [options]="patientSatisfactionKeyQuestionsChart.options"
                [plugins]="patientSatisfactionKeyQuestionsChart.plugins"
                [chartType]="patientSatisfactionKeyQuestionsChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.patientSatisfactionKeyQuestions &&
                  !patientSatisfactionKeyQuestionsChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.patientSatisfactionKeyQuestions">
                <app-loader [loader]="loading.patientSatisfactionKeyQuestions"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>

        <div
          class="col-md-6 mb-4"
          *ngIf="toShowReport && toShowReport.includes(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD)">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="surveyResponseRateForm" style="min-width: 150px">
                    <div class="d-flex justify-content-between align-items-center">
                      <span
                        *ngIf="isIndividual && loading.subCategoryList"
                        class="spinner-grow spinner-grow-sm mr-3"
                        role="status"
                        aria-hidden="true"></span>
                      <mdb-select
                        *ngIf="isIndividual"
                        [options]="surveyResponseRateSubCategoryList | sort : 'label'"
                        [disabled]="loading.subCategoryList"
                        id="survey-response-rate-sub-category"
                        placeholder="Select sub category"
                        [visibleOptions]="4"
                        formControlName="subCategoryName"
                        (selected)="subCategorySelected($event, 'survey-response-rate')">
                      </mdb-select>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor ml-3"
                    (click)="onViewToggle(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD, true)">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="!loading.surveyResponseRateYTD && surveyResponseRateYTDChart.labels.length"
                [datasets]="surveyResponseRateYTDChart.dataset"
                [labels]="surveyResponseRateYTDChart.labels"
                [options]="surveyResponseRateYTDChart.options"
                [plugins]="surveyResponseRateYTDChart.plugins"
                [chartType]="surveyResponseRateYTDChart.chartType">
              </canvas>
              <p
                *ngIf="!loading.surveyResponseRateYTD && !surveyResponseRateYTDChart.labels.length">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.surveyResponseRateYTD">
                <app-loader [loader]="loading.surveyResponseRateYTD"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>

    <div class="px-3">
      <div
        class="row"
        *ngIf="
          toShowReport &&
          toShowReport.includes(REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO)
        ">
        <div
          class="mb-4 col-md-12"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="
                    getReport(
                      REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO
                    ) as report
                  ">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="highestVolumeOfNoForm">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="w-100 d-flex justify-content-between align-items-center">
                        <div style="display: inline-block; width: 150px">
                          <mdb-select
                            *ngIf="!loading.summaryQuestionWithHighestVolumeOfNo"
                            [options]="yearOptions.highestVolumeOfNo"
                            id="category"
                            placeholder="Year"
                            [multiple]="true"
                            [enableSelectAll]="true"
                            (selected)="highestVolumeOfNoYearSelected($event)"
                            (deselected)="highestVolumeOfNoYearDeselected($event)"
                            formControlName="selectedYear">
                          </mdb-select>
                        </div>
                      </div>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor donotprint ml-3"
                    (click)="
                      onViewToggle(
                        REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO,
                        true
                      )
                    ">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 400px">
              <canvas
                baseChart
                *ngIf="
                  !loading.summaryQuestionWithHighestVolumeOfNo &&
                  summaryQuestionWithHighestVolumeOfNoChart.labels.length
                "
                [datasets]="summaryQuestionWithHighestVolumeOfNoChart.dataset"
                [labels]="summaryQuestionWithHighestVolumeOfNoChart.labels"
                [options]="summaryQuestionWithHighestVolumeOfNoChart.options"
                [plugins]="summaryQuestionWithHighestVolumeOfNoChart.plugins"
                [chartType]="summaryQuestionWithHighestVolumeOfNoChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.summaryQuestionWithHighestVolumeOfNo &&
                  !summaryQuestionWithHighestVolumeOfNoChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.summaryQuestionWithHighestVolumeOfNo">
                <app-loader [loader]="loading.summaryQuestionWithHighestVolumeOfNo"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      !parsedValueManagementFilterData?.isConfigLoading &&
      parsedValueManagementFilterData?.toShowReport &&
      !parsedValueManagementFilterData?.toShowReport?.length
    "
    class="text-center no-conversation-container py-4 border mx-3 mt-4">
    <i class="icofont-file-spreadsheet icofont-3x text-secondary"></i>
    <p class="mt-2">Report Configuration Missing.</p>
  </div>
</div>

<!-- Additional Filter Modal Begins -->
<div
  mdbModal
  #filterModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ 'Set Key Question - All EOC' }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12 p-0">
          <div class="form-group has-search input-group mt-2 mb-2 w-100">
            <span class="form-control-feedback">
              <i class="icofont-search-1"></i>
            </span>
            <input
              type="text"
              class="form-control"
              id="search"
              placeholder="Search"
              autocomplete="off"
              [(ngModel)]="searchFilterKeyword"
              (keyup)="onFilterKeywordSearch()" />
            <div class="input-group-append" *ngIf="searchFilterKeyword">
              <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
                <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
              </button>
            </div>
          </div>
          <div>
            <div
              class="mt-4 px-2"
              *ngIf="!loading.getQuestionConfigurations && questionConfigurations.length">
              <div *ngFor="let questionConfiguration of questionConfigurations">
                <mdb-checkbox
                  [checked]="questionConfiguration.isActive"
                  (change)="onQuestionChecked($event, questionConfiguration)">
                  {{ questionConfiguration.questionName }}
                </mdb-checkbox>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <p
          class="m-0 mt-3"
          *ngIf="!loading.getQuestionConfigurations && !questionConfigurations.length">
          {{ searchFilterKeyword ? noDataMessage.value : 'Question Configurations Missing' }}
        </p>
        <div *ngIf="loading.getQuestionConfigurations">
          <app-loader [loader]="loading.getQuestionConfigurations"></app-loader>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="applyFilter()"
          [disabled]="
            loading.getQuestionConfigurations ||
            !questionConfigurations.length ||
            loading.saveQuestionConfiguration
          ">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading.saveQuestionConfiguration"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Additional Filter Modal Ends -->
