import { IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';

export const DatePickerOption: IMyOptions = {
    closeAfterSelect: true,
    showClearDateBtn: true,
    dateFormat: 'mm/dd/yyyy',
    closeBtnTxt: "Cancel",
    clearBtnTxt: "Clear",
    disableSince: { year: 0, month: 0, day: 0 },
    disableUntil: { year: 0, month: 0, day: 0 }
};