import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services'
import { finalize } from 'rxjs/operators';
import { Storage } from '../../utils';
@Component({
  selector: 'app-switch-role',
  templateUrl: './switch-role.component.html',
  styleUrls: ['./switch-role.component.scss']
})
export class SwitchRoleComponent implements OnInit {
  roleSwitch: any;
  submit_enabled: boolean = false;
  @Output() cancel_Switch = new EventEmitter<any>()
  @Input() set switchRole(data) {
    if (data) {
      this.roleSwitch = data
      this.switchModal.show()
    }
  }
  @ViewChild('switchModal', { static: true }) public switchModal;

  constructor(
    private authService: AuthService,
    private _storage: Storage
  ) { }

  ngOnInit() {
  }

  cancelSwitch(event) {
    this.cancel_Switch.emit(event)
    this.switchModal.hide()
  }

  roleSwitched(user) {
    this.submit_enabled = true;
    this.authService.switchRole().pipe(finalize(() => {
      this.submit_enabled = false;
      this.switchModal.hide()
    })).subscribe(res => {
      this._storage.set('local', 'loggedInUser', res.newToken)
      this._storage.clear('session')
      window.location.reload();
    })
  }
}
