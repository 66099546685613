import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PAYMENT_REPORT_DATA, PAYMENT_TYPE_LIST, PaymentMode, REPORT_DATES } from '../../../constants';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, finalize, map, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { ClaimsService, ClientService, EpisodeService, MemberService, PlatformService, ReportService } from '../../../services';
import { HolistaUtils } from '../../../utils';
import * as moment from 'moment';
import { HOLISTA_CONSTANT } from '../../../constants/holista.constant';

@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss']
})
export class PaymentReportComponent implements OnInit {

  paymentReportForm: FormGroup;
  paymentModeList = PaymentMode;
  payeeList: any[] = [];
  payorList: any[] = [];
  episodeList: any[] = [];
  paymentTypeList: any[] = PAYMENT_TYPE_LIST;
  reportDateTypeList: any[] = REPORT_DATES;
  isLoading = {
    paymentReport: false,
    episodeSearchList: false,
    patientSearchList: false,
    payeeSearchList: false
  };
  payeeSearchText = new Subject();
  patientSearchText = new Subject();
  payeesResult: Observable<any>;
  episodesResult: Observable<any>;
  patientsResult: [];
  showReport: boolean = false;
  report = {
    data: [],
    excelName: '',
    tableTitle: '',
    tableHeaders: [],
    columnsWidth: [],
    count: 0
  };
  isPreSelectedFilter: boolean = false;
  expandAdvanceFiltering: boolean = true;
  episodeTypeList: any[] = [];
  reportData = [];
  completeReport: any = {};
  defaultLimit: number = 100;

  constructor(
    private _formBuilder: FormBuilder,
    private _claimsService: ClaimsService,
    private _holistaUtils: HolistaUtils,
    private _clientService: ClientService,
    private _platformService: PlatformService,
    private _memberService: MemberService,
    private _reportService: ReportService,
    private _episodeService: EpisodeService
  ) {
    this.patientSearchText.pipe(debounceTime(500), distinctUntilChanged()).subscribe((keyword: any) => {
      this.onPatientSearch(keyword)
    });
    this.setPaymentReportForm();
  };

  ngOnInit(): void {
    this.getPayorList();
    this.getEpisodeTypes();
    this.payeesResult = this.payeeSearchText
      .pipe(
        startWith(this.payeeSearchText),
        switchMap((payeeSearchText: string) => this.onPayeeSearch(payeeSearchText))
      );
  };

  patientSearchChange($event) {
    this.patientSearchText.next($event)
  }

  setPaymentReportForm() {
    this.paymentReportForm = this._formBuilder.group({
      paymentMode: [null],
      payeeID: [null],
      payorID: [null],
      patientID: [null],
      episode: [null],
      paymentType: [null],
      claimID: [null],
      paymentModeTraceNumber: [null],
      reportDateType: [null, Validators.required],
      paymentDateFrom: [null],
      paymentDateTo: [null],
      subscriberNumber: [null],
      limit: [this.defaultLimit],
      offset: [HOLISTA_CONSTANT.OFFSET]
    });
  };

  getEpisodeTypes() {
    this._episodeService.getUniqueEpisodeTypes()
      .pipe()
      .subscribe((response: any) => {
        response = response.map((episode) => {
          const name = episode.name.trim();
          return {
            name,
            label: name,
            value: name.replaceAll(',', '')
          };
        });
        const episodeNames = response.map(episode => episode['name']);
        const filteredEpisodeTypeList = response.filter((episode, index: number) => !episodeNames.includes(episode['name'], index + 1))
          .map((episode: any) => ({ ...episode, name: episode.name.toLowerCase() }));
        this.episodeTypeList = this._holistaUtils.getUniqueList(filteredEpisodeTypeList, 'name');
      }, error => {
        console.log('error getting episode types', error);
      });
  };

  getPayorList() {
    return new Promise(resolve => {
      this._clientService.getClients({ limit: 0, fields: 'name,code,id' })
        .subscribe((response) => {
          if (response && response.count > 0) {
            const clientList = response.rows;
            clientList.map(client => {
              this.payorList = [...this.payorList, { label: client.name, value: client.id, typeCode: 'CLIENT' }];
            })
          };
        });
      this._platformService.getAll({ limit: 0, fields: 'name, code, id, addressLine1, addressLine2, city, state, zip' })
        .pipe(finalize(() => { }))
        .subscribe((response) => {
          if (response && response.count > 0) {
            const platformList = response.rows;
            platformList.map(platform => {
              this.payorList = [...this.payorList, { label: platform.name, value: platform.id, typeCode: 'PLATFORM' }];
            })
            resolve(this.payorList);
          };
        });
    });
  };

  onPayeeSearch(searchText: string) {
    this.paymentReportForm.controls.payeeID.setValue(null);
    if (searchText.length > 2) {
      this.isLoading.payeeSearchList = true;
      return this._claimsService.getPayeeReferences(searchText)
        .pipe(
          finalize(() => this.isLoading.payeeSearchList = false),
          debounceTime(250),
          map((items: any) => {
            return items;
          })
        );
    };
    return of([]);
  };

  onPatientSearch(searchText: string) {
    if (typeof (searchText) == 'string') { //On selecting patient this function should not be triggered. 
      this.paymentReportForm.controls.patientID.setValue(null);
      if (searchText.length > 2) {
        this.isLoading.patientSearchList = true;
        return this._memberService.searchMembers({ search: searchText, page: 0 }).subscribe((data) => {
          if (data?.rows && data?.rows.length) {
            this.patientsResult = data.rows
          }

          this.isLoading.patientSearchList = false;
        }, (error) => {
          this.isLoading.patientSearchList = false;
        })
      }
    }

  };

  onEpisodeSearchResultSelected(event) {
    this.paymentReportForm.controls.episodeID.setValue(event.text.episodeName);
  };

  onPatientSearchResultSelected(event) {
    this.paymentReportForm.controls.patientID.setValue(event.text.uuid);
  };

  displayPayee = (payee?): string | undefined => {
    return payee && this._holistaUtils.toTitleCase(payee.name);
  };

  displayEpisode = (episode?): string | undefined => {
    return episode && this._holistaUtils.toTitleCase(episode.episodeName);
  };

  displayPatient = (patient?): string | undefined => {
    const { firstName, middleName, lastName } = patient;
    return patient && `${firstName} ${middleName ? middleName + ' ' : ''}${lastName}`;
  };

  onPayeeSearchResultSelected(event) {
    this.paymentReportForm.controls.payeeID.setValue(event.text.id);
  };

  onResetFilters() {
    this.paymentReportForm.reset();
    this.payeeSearchText.next('');
    this.patientSearchText.next('');
    this.paymentReportForm.controls.paymentDateFrom.enable();
    this.paymentReportForm.controls.paymentDateTo.enable();
    this.showReport = false;
  };

  onGeneratePaymentReport() {
    this.paymentReportForm.controls.offset.setValue(0);
    this.reportData.length = 0;
    this.resetReport(false);
    this.getPaymentReport();
  };

  resetReport(event: boolean) {
    this.completeReport = {};
  };

  getPaymentReport(isDownload: boolean = false) {
    const filters = {
      ...this.paymentReportForm.value,
      paymentDateFrom: new Date(this.paymentReportForm.get('paymentDateFrom').value),
      paymentDateTo: new Date(this.paymentReportForm.get('paymentDateTo').value)
    };
    const params = this._holistaUtils.getFormattedValues(filters);
    !this.paymentReportForm.get('offset').value && this.paymentReportForm.get('limit').value === this.defaultLimit && (this.isLoading.paymentReport = true);
    this._reportService.getPaymentReport(params)
      .pipe(finalize(() => {
        this.isLoading.paymentReport = false;
        !this.paymentReportForm.get('limit').value && this.paymentReportForm.controls.limit.setValue(this.defaultLimit);
      }))
      .subscribe(response => {
        const { TABLE_HEADERS, EXCEL_NAME, TABLE_TITLE } = PAYMENT_REPORT_DATA;
        if (!this.paymentReportForm.get('limit').value) {
          this.completeReport = {
            data: response.data?.reports,
            tableHeaders: TABLE_HEADERS,
            excelName: EXCEL_NAME,
            tableTitle: TABLE_TITLE,
            columnsWidth: TABLE_HEADERS.map(header => header.columnWidth),
            isDownload
          };
          return;
        };
        response.data?.reports?.length && this.reportData.push(...response.data?.reports);
        this.showReport = true;

        if (!this.paymentReportForm.get('offset').value) {
          this.report = {
            data: response.data?.reports,
            tableHeaders: TABLE_HEADERS,
            excelName: EXCEL_NAME,
            tableTitle: TABLE_TITLE,
            columnsWidth: TABLE_HEADERS.map(header => header.columnWidth),
            count: response.data.count
          };
          return;
        };
        this.report.data = this.reportData;
      }, error => console.log('error getting payment report', error));
  };

  isGenerateReportDisabled(): boolean {
    return this.isLoading.paymentReport || this.paymentReportForm.invalid
  };

  displayReport(): boolean {
    return !this.isLoading.paymentReport && this.showReport;
  };

  onReportDateTypeSelected() {
    if (this.paymentReportForm.value.reportDateType) {
      const { paymentDateFrom, paymentDateTo } = this.paymentReportForm.controls;
      switch (this.paymentReportForm.value.reportDateType) {
        case "thisMonth":
          paymentDateFrom.setValue(new Date(moment().startOf('month').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          paymentDateTo.setValue(new Date(moment().endOf('month').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          this.isPreSelectedFilter = true;
          break;
        case "lastMonth":
          paymentDateFrom.setValue(new Date(moment().startOf('month').subtract(1, 'months').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          paymentDateTo.setValue(new Date(moment().startOf('month').subtract(1, 'months').endOf('month').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          this.isPreSelectedFilter = true;
          break;
        case "ytd":
          paymentDateFrom.setValue(new Date(moment().startOf('year').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          paymentDateTo.setValue(new Date(moment().format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          this.isPreSelectedFilter = true;
          break;
        case "lastYear":
          paymentDateFrom.setValue(new Date(moment().startOf('year').subtract(12, 'months').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          paymentDateTo.setValue(new Date(moment().endOf('year').subtract(12, 'months').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          this.isPreSelectedFilter = true;
          break;
        case "lastQuarter":
          let currentQuaterNumber = moment().quarter();
          if (currentQuaterNumber === 1) { //if 1st quater
            paymentDateFrom.setValue(new Date(moment().startOf('year').subtract(3, 'months').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
            paymentDateTo.setValue(new Date(moment().startOf('year').subtract(1, 'day').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          } else {
            paymentDateFrom.setValue(new Date(moment().subtract(1, 'quarter').startOf('quarter').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
            paymentDateTo.setValue(new Date(moment().subtract(1, 'quarter').endOf('quarter').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          }
          this.isPreSelectedFilter = true;
          break;
        case "thisQuarter":
          paymentDateFrom.setValue(new Date(moment().startOf('quarter').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          paymentDateTo.setValue(new Date(moment().endOf('quarter').format(HOLISTA_CONSTANT.DEFAULT_DATE_FORMAT)));
          this.isPreSelectedFilter = true;
          break;
        case "dateRange":
          paymentDateFrom.setValidators([Validators.required]);
          paymentDateFrom.updateValueAndValidity();
          paymentDateTo.setValidators([Validators.required]);
          paymentDateTo.updateValueAndValidity();
          if (this.isPreSelectedFilter) {
            try {
              paymentDateFrom.setValue(null);
              paymentDateTo.setValue(null);
              paymentDateFrom.enable();
              paymentDateTo.enable();
            } catch (error) {
              try {
                paymentDateFrom.enable();
                paymentDateTo.enable();
              } catch (error) {
                paymentDateTo.enable();
              }
            }
          }
          this.isPreSelectedFilter = false;
          break;
        default:
          break;
      }
    }
  };

  toggleCard() {
    this.expandAdvanceFiltering = !this.expandAdvanceFiltering;
  };

  onScrollToBottom(event) {
    if (event.scrolledToBottom && this.report.data.length < this.report.count) {
      this.paymentReportForm.controls.offset.setValue(this.paymentReportForm.get('offset').value + 1);
      this.getPaymentReport();
    };
  };

  getCompleteReport(event: boolean) {
    this.paymentReportForm.controls.limit.setValue(0);
    this.getPaymentReport(event);
  };
}
