import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { Storage } from '../utils'

const API_BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class MilestoneTaskService {

  constructor(
    private http: HttpClientService,
    // private httpClient: HttpClient,
    // private _storage: Storage
  ) { }

  // createHeader(formdata?) {
  //   let headers;
  //   const loggedInUser: any = this._storage.get('local', 'loggedInUser')
  //   if (loggedInUser) {
  //     const token = loggedInUser.token;
  //     headers = new HttpHeaders().set('Authorization', token)
  //   }
  //   return headers;
  // }

  getTasks(milestone_id) {
    return this.http.get(`${APICONSTANTS.MILESTONE}/${milestone_id}${APICONSTANTS.TASK}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  saveTask(task) {
    let formData = new FormData();
    if (task.holistaMedia && task.fileName) {
      formData.append('holistaMedia', task.holistaMedia, task.fileName);
      formData.append('fileName', task.fileName);
    }
    else {
      formData.append('taskTodoLink', task.taskTodoLink ? task.taskTodoLink : '');
    }
    task.parentUuid && formData.append('parentUuid', task.parentUuid);
    task.sequence && formData.append('sequence', task.sequence);
    task.type && formData.append('type', task.type);
    task.isNotifiableToAssignor && formData.append('isNotifiableToAssignor', task.isNotifiableToAssignor);
    task.isNotifiableToAssignee && formData.append('isNotifiableToAssignee', task.isNotifiableToAssignee);
    task.docuSignTemplateId && formData.append('docuSignTemplateId', task.docuSignTemplateId);
    task.documentDisplayName && formData.append('documentDisplayName', task.documentDisplayName);
    task.isAcknowledgedRequired && formData.append('isAcknowledgedRequired', task.isAcknowledgedRequired);
    task.escalationDays && formData.append('escalationDays', task.escalationDays);
    formData.append('assignedTo', task.assignedTo);
    formData.append('assignedBy', task.assignedBy);
    formData.append('taskId', task.taskId);
    formData.append('name', task.name);
    formData.append('isHighPriority', task.isHighPriority);
    formData.append('instruction', task.instruction ? task.instruction : '');
    formData.append('optionId', task.optionId ? task.optionId : 0);
    formData.append('isDependent', task.isDependent ? task.isDependent : false);
    formData.append('pathwayId', task.pathwayId);
    formData.append('milestoneId', task.milestoneId);
    if (task.supervisorOf) formData.append('supervisorOf', task.supervisorOf);
    return this.http.post(`${APICONSTANTS.TASK}/${task.taskId}${APICONSTANTS.TASKTODO}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateTask(task) {
    let formData = new FormData();
    if (task.holistaMedia && task.fileName) {
      formData.append('holistaMedia', task.holistaMedia, task.fileName);
      formData.append('fileName', task.fileName);
    }
    else {
      formData.append('taskTodoLink', task.taskTodoLink ? task.taskTodoLink : '');
    }
    formData.append('id', task.id);
    task.parentUuid && formData.append('parentUuid', task.parentUuid);
    task.sequence && formData.append('sequence', task.sequence);
    task.type && formData.append('type', task.type);
    task.isNotifiableToAssignor && formData.append('isNotifiableToAssignor', task.isNotifiableToAssignor);
    task.isNotifiableToAssignee && formData.append('isNotifiableToAssignee', task.isNotifiableToAssignee);
    task.docuSignTemplateId && formData.append('docuSignTemplateId', task.docuSignTemplateId);
    task.documentDisplayName && formData.append('documentDisplayName', task.documentDisplayName);
    task.isAcknowledgedRequired && formData.append('isAcknowledgedRequired', task.isAcknowledgedRequired);
    task.escalationDays && formData.append('escalationDays', task.escalationDays);
    formData.append('assignedTo', task.assignedTo);
    formData.append('assignedBy', task.assignedBy);
    formData.append('taskId', task.taskId);
    formData.append('name', task.name);
    formData.append('instruction', task.instruction ? task.instruction : '');
    formData.append('isHighPriority', task.isHighPriority);
    formData.append('optionId', task.optionId ? task.optionId : 0);
    formData.append('isDependent', task.isDependent ? task.isDependent : false);
    formData.append('pathwayId', task.pathwayId);
    formData.append('milestoneId', task.milestoneId);
    if (task.supervisorOf) formData.append('supervisorOf', task.supervisorOf);
    return this.http.put(`${APICONSTANTS.TASK}/${task.taskId}${APICONSTANTS.TASKTODO}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  saveQuestion(question) {
    return this.http.post(`${APICONSTANTS.TASK}/${question.taskId}/questions`, question)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateQuestion(question) {
    return this.http.post(`${APICONSTANTS.TASK}/${question.taskId}/questions`, question)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getQuestionTypes() {
    return this.http.get(APICONSTANTS.QUESTION_TYPE).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveMessage(message) {
    let formData = new FormData();
    if (message.holistaMedia && message.fileName) {
      formData.append('holistaMedia', message.holistaMedia, message.fileName);
      formData.append('fileName', message.fileName);
    }
    else
      formData.append('documentPath', message.documentPath ? message.documentPath : '');
    if (message.parentUuid)
      formData.append('parentUuid', message.parentUuid);
    if (message.sequence)
      formData.append('sequence', message.sequence);
    formData.append('title', message.title);
    formData.append('taskId', message.taskId);
    formData.append('messages', message.messages);
    formData.append('messageFrom', message.messageFrom);
    formData.append('messageTo', message.messageTo);
    formData.append('documentDisplayName', message.documentDisplayName);
    formData.append('severity', message.severity);
    formData.append('optionId', message.optionId ? message.optionId : 0);
    formData.append('isDependent', message.isDependent ? message.isDependent : false);
    formData.append('pathwayId', message.pathwayId);
    formData.append('milestoneId', message.milestoneId);
    formData.append('escalationDays', message.escalationDays);
    if (message.supervisorOf) formData.append('supervisorOf', message.supervisorOf);
    return this.http.post(`${APICONSTANTS.TASK}/${message.taskId}${APICONSTANTS.MESSAGE}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateMessage(message) {
    let formData = new FormData();
    if (message.holistaMedia && message.fileName) {
      formData.append('holistaMedia', message.holistaMedia, message.fileName);
      formData.append('fileName', message.fileName);
    }
    else
      formData.append('documentPath', message.documentPath ? message.documentPath : '');
    formData.append('id', message.id);
    formData.append('parentUuid', message.parentUuid);
    if (message.sequence)
      formData.append('sequence', message.sequence);
    formData.append('title', message.title);
    formData.append('taskId', message.taskId);
    formData.append('messages', message.messages);
    formData.append('messageFrom', message.messageFrom);
    formData.append('messageTo', message.messageTo);
    formData.append('severity', message.severity);
    formData.append('documentDisplayName', message.documentDisplayName);
    formData.append('optionId', message.optionId ? message.optionId : 0);
    formData.append('isDependent', message.isDependent ? message.isDependent : false);
    formData.append('pathwayId', message.pathwayId);
    formData.append('milestoneId', message.milestoneId);
    formData.append('escalationDays', message.escalationDays);
    if (message.supervisorOf) formData.append('supervisorOf', message.supervisorOf);
    return this.http.put(`${APICONSTANTS.TASK}/${message.taskId}${APICONSTANTS.MESSAGE}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  fetchDependentTask(task) {
    return this.http.post(`${APICONSTANTS.QUESTION}/option/${task.id}`, task)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  saveQuestionnaire(questionnaire) {
    return this.http.post(`${APICONSTANTS.TASK}/${questionnaire.taskId}/questionnaires`, questionnaire)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateQuestionnaire(questionnaire) {
    return this.http.post(`${APICONSTANTS.TASK}/${questionnaire.taskId}/questionnaires`, questionnaire)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  changeTaskSequence(tasks) {
    return this.http.post(`${APICONSTANTS.TASK}/order`, tasks)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
}
