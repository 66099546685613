import { ChartUtils } from '../utils/chart.utils';

const chartUtils = new ChartUtils();

export const CHART_CUSTOM_COLORS = [
  "#3c9584", "#67d590", "#67acd5",
  "#0088b2", "#9c93c7", "#9daf89",
  "#00e5ff", "#ff8936", "#00e5ce",
  "#888042", "#d5c867", "#d56767",
  "#301aa1", "#ffd356", "#696969",
];

export const LINE_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    display: true,
    labels: {
      fontColor: "black",
      padding: 25
    },
    position: "bottom"
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          display: true
        },
        scaleLabel: {
          display: true,
          labelString: "Average Days"
        }
      }
    ]
  },
};

export const HORIZONTAL_BAR_OPTION = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontStyle: "bold"
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {
          display: false
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          fontStyle: "bold",
          beginAtZero: true,
          suggestedMax: 50,
        },
        scaleLabel: {
          display: true,
          labelString: "Average Days",
        },
        gridLines: {
          display: true
        }
      }
    ]
  },
  plugins: {
    datalabels: {
      display: false,
    }
  },
  layout: {
    padding: {
      right: 50
    }
  }
};

export const LINE_CHART_DEFAULT_COLOR_CONFIG = {
  backgroundColor: CHART_CUSTOM_COLORS[2],
  borderColor: CHART_CUSTOM_COLORS[2],
  pointBackgroundColor: CHART_CUSTOM_COLORS[2],
  pointHoverBackgroundColor: CHART_CUSTOM_COLORS[2],
}

export const BAR_CHART_DEFAULT_COLOR_CONFIG = {
  backgroundColor: CHART_CUSTOM_COLORS,
  borderColor: CHART_CUSTOM_COLORS
}

export const LINE_CHART_DATA_CONFIG = {
  borderWidth: 2,
  fill: false,
  lineTension: 0,
  spanGaps: true,
  ...LINE_CHART_DEFAULT_COLOR_CONFIG,
  // pointRadius: 0,
};

export const HORIZONTAL_BAR_OPTION_WITH_PERCENTAGE_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback: chartUtils.lineBreakForChartTicks(25, ' '),
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {
          display: false
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          fontStyle: "bold",
          beginAtZero: true,
          suggestedMin: 100,
          callback: function (value) {
            if (Number.isInteger(value)) {
              if (value === 0) return value;
              return value + "%";
            }
            return "";
          }
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {
          display: true
        }
      }
    ]
  },
  plugins: {
    datalabels: {
      display: false,
    }
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const value =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return `${value}%`;
      }
    }
  },
  layout: {
    padding: {
      right: 50
    }
  }
};

export const STACKED_HORIZONTAL_BAR_GRAPH_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    labels: {
      boxWidth: 12,
    }
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          callback: chartUtils.lineBreakForChartTicks(40, ' '),
        }
      }
    ],
    xAxes: [
      {
        stacked: true,
        ticks: {
          callback: function (value) {
            if (Number.isInteger(value)) {
              if (value === 0) return value;
              return value;
            }
            return "";
          }
        }
      }
    ]
  },
  plugins: {
    datalabels: {
      display: false,
    }
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const label = data.datasets[tooltipItem.datasetIndex].label;
        const value =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return `${label}: ${value > 0 ? value : value * -1}`;
      }
    }
  },
  layout: {
    padding: {
      right: 50
    }
  }
};

export const VERTICAL_BAR_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    }
  },
  scales: {
    xAxes: [
      {
        barPercentage: 0.5,
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          max: 100,
          callback: (value: number) => value + '%'
        },
      }
    ]
  },
  plugins: {
    datalabels: {
      display: false, // Hide data labels
      anchor: "end",
      align: "top",
      formatter: (value: number) => value + '%'
    }
  },
  legend: {
    labels: {
      generateLabels: (chart) => {
        return chart.data.labels.map((label, index) => ({
          text: label,
          fillStyle: chart.data.datasets[0].backgroundColor[index],
          lineWidth: 0,
        }))
      },
      boxWidth: 12,
      padding: 25
    }
  }
}
