import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClientService) { }

  getClients(queryParam?) {
    return this.http.get(`${APICONSTANTS.CLIENT}`, queryParam)
      .pipe(map((res: any) => { return res.data }))
  }

  saveClient(client) {
    return this.http.post(`${APICONSTANTS.CLIENT}`, client)
      .pipe(map((res: any) => { return res.data }))
  }

  updateClient(client) {
    return this.http.put(`${APICONSTANTS.CLIENT}/${client.code}`, client)
      .pipe(map((res: any) => { return res.data }))
  }

  delete(clientId, queryParam) {
    return this.http.delete(`${APICONSTANTS.CLIENT}/${clientId}`, queryParam)
      .pipe(map((res: any) => { return res.data }))
  }

  getById(id) {
    return this.http.get(`${APICONSTANTS.CLIENT}/${id}`).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  getBanksByClientCode(clientCode) {
    return this.http.get(`${APICONSTANTS.ACCOUNT}`, { referenceCode: clientCode, limit: 0 }).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  saveBankInfo(account) {
    return this.http.post(`${APICONSTANTS.CLIENT}/${account.referenceCode}${APICONSTANTS.ACCOUNT}`, account).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  updateBankInfo(account) {
    return this.http.put(`${APICONSTANTS.CLIENT}/${account.referenceCode}${APICONSTANTS.ACCOUNT}/${account.uuid}`, account).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  deleteBank(accountUuid) {
    return this.http.delete(`${APICONSTANTS.ACCOUNT}/${accountUuid}`).pipe(
      map((response: any) => {
        return response.data
      })
    )
  }

  getPurchasersByClientCode(clientCode, queryParam?) {
    return this.http.get(`${APICONSTANTS.CLIENT}/${clientCode}/purchasers`, queryParam).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  getNetworksByClientCode(clientCode, queryParam?) {
    return this.http.get(`${APICONSTANTS.CLIENT}/${clientCode}/networks`, queryParam).pipe(
      map((response: any) => {
        return response.data
      })
    );
  }

  getFundingSourceByCode(payerCode: string) {
    return this.http.get(`/funding-sources?code=${payerCode}`).pipe(map((response: any) => {
      return response.data
    }))
  }

  getCategories(params, id?: number) {
    return this.http.get(id ? `/categories/${id}` : `/categories`, params).pipe(map((response: any) => {
      return response.data
    }))
  }

  getSubCategories(params) {
    return this.http.get(`/sub-categories`, params).pipe(map((response: any) => {
      return response.data
    }))
  }
}
