import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-services-detail',
  templateUrl: './services-detail.component.html',
  styleUrls: ['./services-detail.component.scss']
})
export class ServicesDetailComponent implements OnInit {
  @Input() remittancePdf: any;
  constructor() { }
  ngOnInit() {
  }
}