<div *ngIf="user.roleCode !== 'PR' && user.roleCode !== 'CLA'">
  <div class="p-3 grey lighten-4" *ngIf="selectedDashboard !== 'claim'">
    <div class="row">
      <div *ngIf="user.roleCode === 'MP'" class="col-sm-4">
        <h5 class="mt-2">
          Hi,
          {{
            selected_user
              ? selected_user?.firstName + ' ' + selected_user?.lastName
              : user?.firstName + ' ' + user?.lastName
          }}
        </h5>
      </div>
      <!-- new design search patient and filter user to see thier dashboard start here -->
      <div
        *ngIf="
          user.roleCode === 'PA' ||
          user.roleCode === 'CC' ||
          user.roleCode === 'ES' ||
          user.roleCode === 'CA' ||
          user.roleCode === 'SCA'||
          user.roleCode === 'SV' ||
          user.roleCode === 'IA' ||
          user.roleCode === 'PUA'
        "
        class="col-sm-4">
        <div class="d-flex justify-content-start align-items-center mt-3 mt-sm-0 position-relative">
          <div class="form-group input-group has-search mt-2 mb-2 w-xs-100">
            <input
              type="text"
              class="completer-input form-control bg-white mdb-autocomplete"
              [ngModel]="patient_search_text | async"
              (ngModelChange)="patient_search_text.next($event)"
              [mdbAutoCompleter]="auto"
              placeholder="Search patients" />
            <div class="input-group-append" *ngIf="patient_search_text | async">
              <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
                <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword('patient')"></i>
              </button>
            </div>
            <mdb-auto-completer
              #auto="mdbAutoCompleter"
              [textNoResults]="patientSearchKey ? 'Loading...' : 'No result found'"
              class="completer-dropdown-holder-dashboard"
              (selected)="onPatientSelect($event)"
              [displayValue]="onDisplayValue">
              <mdb-option *ngFor="let option of patient_results | async" [value]="option">
                <div class="d-flex flex-column">
                  <strong>
                    {{
                      !option.middleName && option.lastName
                        ? option.firstName + ' ' + option.lastName
                        : option.middleName && option.lastName
                        ? option.firstName + ' ' + option.middleName + ' ' + option.lastName
                        : option.firstName
                    }}
                  </strong>
                  <small>{{ option?.purchaserName }}</small>
                </div>
              </mdb-option>
            </mdb-auto-completer>
          </div>
        </div>
      </div>
      <div
        class="col-sm-4"
        *ngIf="user.roleCode === 'PA' || user.roleCode === 'IA' || user.roleCode === 'SV'">
        <div class="d-flex justify-content-start align-items-center mt-3 mt-sm-0 position-relative">
          <div class="form-group input-group has-search mt-2 mb-2 w-xs-100">
            <input
              type="text"
              class="completer-input form-control bg-white mdb-autocomplete"
              [ngModel]="user_search_text | async"
              (ngModelChange)="user_search_text.next($event)"
              [mdbAutoCompleter]="auto"
              placeholder="Search users"
              (change)="userChange()" />
            <div class="input-group-append" *ngIf="user_search_text | async">
              <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
                <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword('user')"></i>
              </button>
            </div>
            <mdb-auto-completer
              #auto="mdbAutoCompleter"
              [textNoResults]="userSearchKey ? 'Loading...' : 'No result found'"
              class="completer-dropdown-holder-dashboard"
              (selected)="selectedUserDashboard($event)"
              [displayValue]="onDisplayUser">
              <mdb-option *ngFor="let option of user_results | async" [value]="option">
                <div class="d-flex flex-column">
                  <strong> {{ option?.firstName }} {{ option?.lastName }}</strong>
                </div>
              </mdb-option>
            </mdb-auto-completer>
          </div>
        </div>
      </div>
      <div
        class="col-sm-4 d-flex justify-content-end align-items-center"
        *ngIf="user.roleCode === 'PA'">
        <p class="mb-0 mr-2">Show:</p>
        <mdb-select
          [options]="dashboardTypes"
          (selected)="showSelectedDashboard($event)"
          [highlightFirst]="false"
          [disabled]="!dashboardTypes || dashboardTypes.length === 0"
          [(ngModel)]="selectedDashboard">
        </mdb-select>
      </div>
    </div>
  </div>

  <div class="p-3" *ngIf="!loading && selectedDashboard !== 'claim'">
    <div class="row justify-content-between">
      <div
        class="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-9"
        *ngIf="showPatientDashboard && userEpisodeList.length > 0 && selectedEpisode">
        <div class="d-inline-flex align-items-center mb-3 mb-sm-0">
          <i class="icofont-nurse-alt icofont-2x mr-2 skin-text-primary-dark"></i>
          <div class="pr-5 d-flex align-items-center">
            <span>
              <small class="text-muted d-block">Care Coordinator</small>
              {{
                selectedEpisode?.data?.cc
                  ? selectedEpisode.data.cc.firstName + ' ' + selectedEpisode.data.cc.lastName
                  : ''
              }}
            </span>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="!enableChat"></span>
            <button
              *ngIf="enableChat"
              class="z-depth-0 mt-0 mb-0 btn btn-sm btn-outline-secondary"
              type="button"
              [ngClass]="{isDisabled: !enableChat}"
              (click)="openChat(selectedEpisode.data.ccId)"
              mdbWavesEffect>
              <i class="fas fa-comment-alt pointer-cursor"></i> Chat
            </button>
          </div>
        </div>
        <div class="d-inline-flex align-items-center mb-3 mb-sm-0">
          <i class="icofont-support icofont-2x mr-2 skin-text-primary-dark"></i>
          <div class="pr-5 d-flex align-items-center">
            <span>
              <small class="text-muted d-block">Engagement Specialist</small>
              {{
                selectedEpisode?.data?.es
                  ? selectedEpisode.data.es.firstName + ' ' + selectedEpisode.data.es.lastName
                  : ''
              }}
            </span>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="!enableChat"></span>
            <button
              *ngIf="enableChat"
              class="z-depth-0 mt-0 mb-0 btn btn-sm btn-outline-secondary"
              type="button"
              [ngClass]="{isDisabled: !enableChat}"
              (click)="openChat(selectedEpisode.data.esId)"
              mdbWavesEffect>
              <i class="fas fa-comment-alt pointer-cursor"></i> Chat
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-3"
        *ngIf="showPatientDashboard && userEpisodeList.length > 0">
        <!-- <div class="d-flex justify-content-start align-items-center mt-3 mt-sm-0"> -->
        <!-- <i class="icofont-settings icofont-lg mr-2 text-black-50 d-none d-sm-block"></i> -->
        <mdb-select
          [options]="userEpisodeList"
          [(ngModel)]="selectedEpisode.value"
          (selected)="optionSelectedEpisode($event)"></mdb-select>
        <!-- </div> -->
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="selectedDashboard === 'admin'">
    <app-loader [loader]="loading"></app-loader>
    <div *ngIf="!loading">
      <div class="row mb-3">
        <div [class]="isEocRequestPermitted ? 'col-sm-6 col-md-4' : 'col-sm-6'">
          <mdb-card class="mt-3 mt-sm-0 z-depth-0 bg-blue-light">
            <mdb-card-body class="p-3">
              <div class="d-flex justify-content-between align-items-center">
                <div class="media">
                  <div
                    class="icon-block icon-block-lg text-center bg-primary rounded-circle d-flex justify-content-center align-items-center">
                    <!-- <i class="icofont-user text-white"></i> -->
                    <i *ngIf="showPatientDashboard" class="icofont-tasks-alt text-white"></i>
                    <i *ngIf="showAdminDashboard" class="icofont-user text-white"></i>
                  </div>
                  <div class="content text-muted ml-2 media-body">
                    {{ showPatientDashboard ? 'Total' : 'Assigned' }}
                    <br />
                    {{ showPatientDashboard ? 'Milestones' : 'Patients' }}
                  </div>
                </div>
                <h3 class="mb-0">
                  {{
                    showPatientDashboard
                      ? dashboard.milestones.length
                      : assignedUsers && assignedUsers.length > 0
                      ? assignedUsers?.countVisibleUsers?.length
                      : 0
                  }}
                </h3>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div [class]="isEocRequestPermitted ? 'col-sm-6 col-md-4' : 'col-sm-6'">
          <mdb-card class="mt-3 mt-sm-0 z-depth-0 bg-blue-light">
            <mdb-card-body class="p-3">
              <div class="d-flex justify-content-between align-items-center">
                <div class="media">
                  <div
                    class="icon-block icon-block-lg text-center bg-primary rounded-circle d-flex justify-content-center align-items-center">
                    <i *ngIf="showPatientDashboard" class="icofont-tasks text-white"></i>
                    <i *ngIf="showAdminDashboard" class="fa fa-layer-group text-white"></i>
                  </div>
                  <div class="content text-muted ml-2 media-body">
                    Total <br />
                    {{ showPatientDashboard ? 'Tasks' : 'Episodes' }}
                  </div>
                </div>
                <h3 class="mb-0">
                  {{
                    showPatientDashboard
                      ? dashboard?.count?.task
                        ? dashboard.count.task
                        : 0
                      : adminEpisodeList && adminEpisodeList.length > 0
                      ? adminEpisodeList?.countVisibleEpisodes?.length
                      : 0
                  }}
                </h3>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div *ngIf="isEocRequestPermitted" class="col-sm-12 col-md-4 mt-sm-3 mt-md-0">
          <mdb-card class="mt-3 mt-sm-0 z-depth-0 bg-blue-light">
            <mdb-card-body class="p-3">
              <div class="d-flex justify-content-between align-items-center">
                <div class="media">
                  <div
                    class="icon-block icon-block-lg text-center bg-primary rounded-circle d-flex justify-content-center align-items-center">
                    <i class="fa-clone fas text-white"></i>
                  </div>
                  <div class="content text-muted ml-2 media-body">
                    Total
                    <br />
                    EOC Request
                  </div>
                </div>
                <h3 class="mb-0">
                  {{ totalEOCCount }}
                </h3>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4" *ngIf="showPatientDashboard">
          <h6 class="font-weight-500">Milestones</h6>
          <div
            class="sticky-content pr-1 scrollbar scrollbar-primary thin"
            style="height: calc(100vh - 380px)">
            <mdb-card
              class="mb-3 z-depth-0 pointer-cursor skin-light"
              *ngFor="let milestone of dashboard?.milestones"
              (click)="goToMilestone(milestone)">
              <mdb-card-header>
                <h6 class="mb-0">{{ milestone.name }}</h6>
                <small>
                  <mdb-icon far icon="calendar-alt"></mdb-icon> {{ milestone?.showDate }}
                </small>
              </mdb-card-header>
              <mdb-card-body>
                <mdb-card-text>
                  <p>
                    {{ milestone.description }}
                  </p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <div class="text-center p-3 grey lighten-4" *ngIf="dashboard.milestones.length === 0">
              <i class="icofont-tasks-alt icofont-3x text-black-50"></i>
              <p>No Milestones available</p>
            </div>
          </div>
        </div>
        <!-- My Task Status-->
        <div
          class="col-12 col-sm-6 mt-3 mt-sm-0"
          [class]="isEocRequestPermitted ? 'col-md-3' : 'col-md-4'"
          *ngIf="showAdminDashboard">
          <div class="d-flex flex-row">
            <h6 class="font-weight-500">My Task Status</h6>
            <span
              class="spinner-grow spinner-grow-sm ml-2"
              role="status"
              aria-hidden="true"
              *ngIf="adminTaskloading"></span>
          </div>
          <mdb-accordion
            class="accordion-block"
            [multiple]="false"
            *ngIf="!loading"
            [style.opacity]="adminTaskloading ? '0.4' : '1'"
            [class.disabled]="adminTaskloading ? true : false">
            <mdb-accordion-item id="high-priority">
              <mdb-accordion-item-head>
                <div class="d-flex justify-content-between align-items-center text-danger">
                  High Priority Tasks
                  <div class="chip chip-rounded bg-danger border-0 rounded-circle m-0 text-white">
                    {{
                      adminTasks?.highPriorityTasks && adminTasks.highPriorityTasks.length > 0
                        ? adminTasks.highPriorityTasks['totalCount']
                        : 0
                    }}
                  </div>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="adminTasks.highPriorityTasks.length === 0">
                  <h6 class="text-center mt-4">No Tasks Available</h6>
                </div>
                <div
                  class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin">
                  <div
                    class="d-flex justify-content-end"
                    *ngIf="adminTasks.highPriorityTasks.length">
                    <i
                      class="fas fa-lg fa-caret-up text-danger pointer-cursor m-1"
                      *ngIf="sortingType.highPriorityTasks === 'asc'"
                      (click)="sortTasks('desc', 'highPriorityTasks')"></i>
                    <i
                      class="fas fa-lg fa-caret-down text-danger pointer-cursor m-1"
                      *ngIf="sortingType.highPriorityTasks === 'desc'"
                      (click)="sortTasks('asc', 'highPriorityTasks')"></i>
                  </div>
                  <mdb-card class="white tasks" *ngFor="let user of adminTasks.highPriorityTasks">
                    <mdb-card-header>
                      <div class="d-flex justify-content-between align-items-center">
                        <h6>{{ user.assignedToName }}</h6>
                        <div
                          class="chip chip-rounded bg-white border-danger rounded-circle m-0 text-danger">
                          {{ user?.tasks?.length }}
                        </div>
                      </div>
                    </mdb-card-header>
                    <mdb-card-body class="pl-3 pr-3">
                      <mdb-card-text>
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item pointer-cursor pl-0 pr-0"
                            *ngFor="let todo of user.tasks"
                            (click)="directToTaskMilestone(todo)">
                            <span
                              class="list-item mb-2 d-flex justify-content-start align-items-start">
                              <i
                                *ngIf="todo.type === 'signature'; else todoIcon"
                                class="fas fa-file-signature skin-text-primary-dark ml-1 mr-2"
                                style="font-size: 18px"></i>
                              <ng-template #todoIcon>
                                <i class="icofont-tasks icofont-lg skin-text-primary-dark mr-2"></i>
                              </ng-template>
                              <p class="font-weight-500">{{ todo.name }}</p>
                            </span>
                            <span>
                              <p class="font-weight-400 ml-4">Patient - {{ todo.patientName }}</p>
                              <p class="font-weight-400 ml-4">Episode - {{ todo.episodeName }}</p>
                            </span>
                          </li>
                        </ul>
                      </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item id="medium-priority">
              <mdb-accordion-item-head>
                <div class="d-flex justify-content-between align-items-center text-warning">
                  Medium Priority Tasks
                  <div class="chip chip-rounded bg-warning border-0 rounded-circle m-0 text-white">
                    {{
                      adminTasks?.mediumPriorityTasks && adminTasks.mediumPriorityTasks.length > 0
                        ? adminTasks.mediumPriorityTasks['totalCount']
                        : 0
                    }}
                  </div>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="adminTasks.mediumPriorityTasks.length === 0">
                  <h6 class="text-center mt-4">No Tasks Available</h6>
                </div>
                <div
                  class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
                  <div
                    class="d-flex justify-content-end"
                    *ngIf="adminTasks.mediumPriorityTasks.length">
                    <i
                      class="fas fa-lg fa-caret-up text-warning pointer-cursor m-1"
                      *ngIf="sortingType.mediumPriorityTasks === 'asc'"
                      (click)="sortTasks('desc', 'mediumPriorityTasks')"></i>
                    <i
                      class="fas fa-lg fa-caret-down text-warning pointer-cursor m-1"
                      *ngIf="sortingType.mediumPriorityTasks === 'desc'"
                      (click)="sortTasks('asc', 'mediumPriorityTasks')"></i>
                  </div>
                  <mdb-card class="white tasks" *ngFor="let user of adminTasks.mediumPriorityTasks">
                    <mdb-card-header>
                      <div class="d-flex justify-content-between align-items-center">
                        <h6>{{ user.assignedToName }}</h6>
                        <div
                          class="chip chip-rounded bg-white border-warning rounded-circle m-0 text-warning">
                          {{ user?.tasks?.length }}
                        </div>
                      </div>
                    </mdb-card-header>
                    <mdb-card-body class="pl-3 pr-3">
                      <mdb-card-text>
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item pointer-cursor pl-0 pr-0"
                            *ngFor="let todo of user.tasks"
                            (click)="directToTaskMilestone(todo)">
                            <span
                              class="list-item mb-2 d-flex justify-content-start align-items-start">
                              <i
                                *ngIf="todo.type === 'signature'; else todoIcon"
                                class="fas fa-file-signature skin-text-primary-dark ml-1 mr-2"
                                style="font-size: 18px"></i>
                              <ng-template #todoIcon>
                                <i class="icofont-tasks icofont-lg skin-text-primary-dark mr-2"></i>
                              </ng-template>
                              <p class="font-weight-500">
                                {{ todo.name }}
                              </p>
                            </span>
                            <span>
                              <p class="font-weight-400 ml-4">Patient - {{ todo.patientName }}</p>
                              <p class="font-weight-400 ml-4">Episode - {{ todo.episodeName }}</p>
                            </span>
                          </li>
                        </ul>
                      </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item id="low-priority">
              <mdb-accordion-item-head>
                <div class="d-flex justify-content-between align-items-center text-success">
                  Completed Tasks
                  <div class="chip chip-rounded bg-success border-0 rounded-circle m-0 text-white">
                    {{
                      adminTasks?.completedTasks && adminTasks.completedTasks.length > 0
                        ? adminTasks.completedTasks['totalCount']
                        : 0
                    }}
                  </div>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="adminTasks.completedTasks.length === 0">
                  <h6 class="text-center mt-4">No Tasks Available</h6>
                </div>
                <div
                  class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
                  <div class="d-flex justify-content-end" *ngIf="adminTasks.completedTasks.length">
                    <i
                      class="fas fa-lg fa-caret-up text-success pointer-cursor m-1"
                      *ngIf="sortingType.completedTasks === 'asc'"
                      (click)="sortTasks('desc', 'completedTasks')"></i>
                    <i
                      class="fas fa-lg fa-caret-down text-success pointer-cursor m-1"
                      *ngIf="sortingType.completedTasks === 'desc'"
                      (click)="sortTasks('asc', 'completedTasks')"></i>
                  </div>
                  <mdb-card class="white tasks" *ngFor="let user of adminTasks.completedTasks">
                    <mdb-card-header>
                      <div class="d-flex justify-content-between align-items-center">
                        <h6>{{ user.assignedToName }}</h6>
                        <div
                          class="chip chip-rounded bg-white border-success rounded-circle m-0 text-success">
                          {{ user?.tasks?.length }}
                        </div>
                      </div>
                    </mdb-card-header>

                    <mdb-card-body class="pl-3 pr-3">
                      <mdb-card-text>
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item pointer-cursor pl-0 pr-0"
                            *ngFor="let todo of user.tasks"
                            (click)="directToTaskMilestone(todo)">
                            <span
                              class="list-item mb-2 d-flex justify-content-start align-items-start">
                              <i
                                *ngIf="todo.type === 'signature'; else todoIcon"
                                class="fas fa-file-signature skin-text-primary-dark ml-1 mr-2"
                                style="font-size: 18px"></i>
                              <ng-template #todoIcon>
                                <i class="icofont-tasks icofont-lg skin-text-primary-dark mr-2"></i>
                              </ng-template>
                              <p class="font-weight-500">
                                {{ todo.name }}
                              </p>
                            </span>
                            <span>
                              <p class="font-weight-400 ml-4">Patient - {{ todo.patientName }}</p>
                              <p class="font-weight-400 ml-4">Episode - {{ todo.episodeName }}</p>
                            </span>
                          </li>
                        </ul>
                      </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </mdb-accordion>
        </div>
        <!-- My Task Status-->

        <!--Tasks-->
        <div class="col-12 col-sm-6 col-md-4 mt-3 mt-sm-0" *ngIf="showPatientDashboard">
          <h6 class="font-weight-500">Tasks</h6>
          <div
            class="sticky-content pr-1 scrollbar scrollbar-primary thin"
            style="height: calc(100vh - 380px)">
            <div
              *ngFor="let topic of dashboard.tasks | orderBy : 'id'"
              (click)="directToTaskMilestone(topic)">
              <mdb-card
                class="z-depth-0 grey lighten-3 mb-1 pointer-cursor"
                *ngIf="
                  topic.taskTodos.length > 0 ||
                  topic.taskSignatures.length > 0 ||
                  topic.taskQuestions.length > 0 ||
                  topic.taskQnrs.length > 0
                ">
                <mdb-card-body class="p-3">
                  <mdb-card-title>
                    <h6 class="font-weight-500 mb-3">
                      {{
                        user.roleCode === 'MP' ||
                        (selected_user && selected_user.activeRole === 'MP')
                          ? topic.patientTopic
                          : topic.topic
                      }}
                    </h6>
                  </mdb-card-title>
                  <div
                    class="list-content-item p-2 d-flex justify-content-start align-items-start"
                    *ngFor="let todo of topic.taskTodos">
                    <!-- <mdb-checkbox [checked]="todo.status == 'COMPLETED'" [disabled]=true></mdb-checkbox> -->
                    <div class="icon-block">
                      <i class="icofont-tasks icofont-lg skin-text-primary-dark"></i>
                    </div>
                    <div class="d-flex justify-content-between w-100 align-items-center">
                      <span class="pl-2 pr-2">
                        <p class="font-weight-400">
                          {{ todo.name }}
                        </p>
                      </span>
                    </div>
                  </div>
                  <div
                    class="list-content-item p-2 d-flex justify-content-start align-items-start"
                    *ngFor="let signature of topic.taskSignatures">
                    <div class="icon-block">
                      <i
                        class="fas fa-file-signature skin-text-primary-dark ml-1"
                        style="font-size: 20px"></i>
                    </div>
                    <div class="d-flex justify-content-between w-100 align-items-center">
                      <span class="pl-2 pr-2">
                        <p class="font-weight-400">
                          {{ signature.name }}
                        </p>
                      </span>
                    </div>
                  </div>

                  <div
                    class="list-content-item p-2 d-flex justify-content-start align-items-start"
                    *ngFor="let question of topic.taskQuestions">
                    <!-- <mdb-checkbox [checked]="question.status == 'COMPLETED'" [disabled]=true></mdb-checkbox> -->
                    <div class="icon-block">
                      <i class="icofont-question-circle icofont-lg skin-text-primary-dark"></i>
                    </div>
                    <div class="d-flex justify-content-between w-100 align-items-center">
                      <span class="pl-2 pr-2">
                        <p class="font-weight-400">
                          {{ question?.questions?.ques }}
                        </p>
                      </span>
                    </div>
                  </div>
                  <div
                    class="list-content-item p-2 d-flex justify-content-start align-items-start"
                    *ngFor="let qnrs of topic.taskQnrs">
                    <!-- <mdb-checkbox [checked]="qnrs.status == 'COMPLETED'" [disabled]=true></mdb-checkbox> -->
                    <div class="icon-block">
                      <i class="icofont-clip-board icofont-lg skin-text-primary-dark"></i>
                    </div>
                    <div class="d-flex justify-content-between w-100 align-items-center">
                      <span class="pl-2 pr-2">
                        <p class="font-weight-400">
                          {{ qnrs?.qnrs?.name }}
                        </p>
                      </span>
                    </div>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
            <div class="text-center p-3 grey lighten-4" *ngIf="dashboard.tasks.length === 0">
              <i class="icofont-tasks icofont-3x text-black-50"></i>
              <p>No Tasks available</p>
            </div>
          </div>
        </div>
        <!--Tasks-->

        <!-- ADMIN_EPISODE -->
        <div
          class="col-12 col-sm-6 mt-3 mt-sm-0"
          [class]="isEocRequestPermitted ? 'col-md-3' : 'col-md-4'"
          *ngIf="showAdminDashboard">
          <app-admin-episode
            [episodes]="adminEpisodeList"
            [assignedUsers]="assignedUsers"></app-admin-episode>
        </div>

        <!-- Patient Task Status -->
        <div
          class="col-12 col-sm-6 col-md-4 mt-3 mt-sm-0"
          *ngIf="
            user.roleCode === 'CC' ||
            user.roleCode === 'ES' ||
            ((user.roleCode === 'PA' ||
              user.roleCode === 'CA' ||
              user.roleCode === 'SCA' ||
              user.roleCode === 'IA' ||
              user.roleCode === 'SV') &&
              (selected_user?.activeRole === 'CC' || selected_user?.activeRole === 'ES'))
          ">
          <div class="d-flex">
            <h6 class="font-weight-500">Patient Task Status</h6>
            <span
              class="spinner-grow spinner-grow-sm ml-2"
              role="status"
              aria-hidden="true"
              *ngIf="patientTaskLoading"></span>
          </div>
          <mdb-accordion
            class="accordion-block"
            [multiple]="false"
            *ngIf="!loading"
            [style.opacity]="patientTaskLoading ? '0.4' : '1'"
            [class.disabled]="patientTaskLoading ? true : false">
            <mdb-accordion-item id="criticalTasks">
              <mdb-accordion-item-head>
                <div class="d-flex justify-content-between align-items-center text-danger">
                  Critical Tasks
                  <div class="chip chip-rounded bg-danger border-0 rounded-circle m-0 text-white">
                    {{ criticalTasks?.count }}
                  </div>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="criticalTasks.count === 0">
                  <h6 class="text-center mt-4">No Tasks Available</h6>
                </div>
                <div
                  class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
                  <mdb-card
                    class="white tasks"
                    *ngFor="let episode of criticalTasks.groupByEpisode">
                    <mdb-card-header>
                      <div class="d-flex justify-content-between align-items-center">
                        <span>
                          <h6>{{ episode?.episode }}</h6>
                          <p>{{ episode?.patientName }}</p>
                        </span>
                        <div
                          class="chip chip-rounded bg-white border-danger rounded-circle m-0 text-danger">
                          {{ episode?.tasks.length }}
                        </div>
                      </div>
                    </mdb-card-header>
                    <mdb-card-body class="pl-3 pr-3">
                      <mdb-card-text>
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item pointer-cursor pl-0 pr-0"
                            *ngFor="let task of episode.tasks"
                            (click)="directToTaskMilestone(task)">
                            <span
                              class="list-item mb-2 d-flex justify-content-start align-items-start">
                              <span *ngIf="task.todo">
                                <i
                                  *ngIf="task.todo.type === 'signature'; else todoIcon"
                                  class="fas fa-file-signature skin-text-primary-dark ml-1 mr-2"
                                  style="font-size: 18px"></i>
                                <ng-template #todoIcon>
                                  <i
                                    class="icofont-tasks icofont-lg skin-text-primary-dark mr-2"></i>
                                </ng-template>
                              </span>

                              <i
                                class="icofont-question-circle icofont-lg skin-text-primary-dark mr-2"
                                *ngIf="task.tqn"></i>
                              <i
                                class="icofont-clip-board icofont-lg skin-text-primary-dark mr-2"
                                *ngIf="task.tqnrs"></i>
                              <p class="font-weight-500" *ngIf="task.todo">
                                {{ task.todo?.name }}
                              </p>
                              <p class="font-weight-500" *ngIf="task.tqn">
                                {{ task.tqn?.questions.question }}
                              </p>
                              <p class="font-weight-500" *ngIf="task.tqnrs">
                                {{ task.tqnrs?.qnrs.name }}
                              </p>
                            </span>
                          </li>
                        </ul>
                      </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <!-- hide for now non critical task  -->
            <!-- <mdb-accordion-item id="noncriticalTasks" >
              <mdb-accordion-item-head>
                <div class="d-flex justify-content-between align-items-center text-warning">
                  Non-Critical Tasks
                  <div class="chip chip-rounded bg-warning border-0 rounded-circle m-0 text-white">
                    {{nonCriticalTasks?.count}}
                  </div>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="nonCriticalTasks.count==0">
                  <h6 class="text-center mt-4"> No Tasks Available</h6>
                </div>
                <div class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
                  <mdb-card class="white tasks" *ngFor="let episode of nonCriticalTasks.groupByEpisode">
                    <mdb-card-header>
                      <div class="d-flex justify-content-between align-items-center">
                        <span>
                          <h6>{{episode?.episode}}</h6>
                          <p>{{episode?.patientName}}</p>
                        </span>
                        <div class="chip chip-rounded bg-white border-warning rounded-circle m-0 text-warning">
                          {{episode?.tasks.length}}
                        </div>
                      </div>
                    </mdb-card-header>
                    <mdb-card-body class="pl-3 pr-3">
                      <mdb-card-text>
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item pointer-cursor pl-0 pr-0" *ngFor="let task of episode.tasks"
                            (click)="directToTaskMilestone(task)">
                            <span class='list-item mb-2 d-flex justify-content-start align-items-start '>
                              <i class="icofont-tasks icofont-lg skin-text-primary-dark mr-2" *ngIf="task.todo"></i>
                              <i class="icofont-question-circle icofont-lg skin-text-primary-dark mr-2"
                                *ngIf="task.tqn"></i>
                              <i class="icofont-clip-board icofont-lg skin-text-primary-dark mr-2"
                                *ngIf="task.tqnrs"></i>
                              <p class="font-weight-500" *ngIf="task.todo">
                                {{task.todo?.name}}
                              </p>
                              <p class="font-weight-500" *ngIf="task.tqn">
                                {{task.tqn?.questions.question}}
                              </p>
                              <p class="font-weight-500" *ngIf="task.tqnrs">
                                {{task.tqnrs?.qnrs.name}}
                              </p>
                            </span>
                          </li>
                        </ul>
                      </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item> -->
          </mdb-accordion>
        </div>
        <!-- Patient Task Status -->

        <!-- My Team Task Status -->
        <div
          class="col-12 col-sm-6 mt-3 mt-sm-0"
          [class]="isEocRequestPermitted ? 'col-md-3' : 'col-md-4'"
          *ngIf="
            showAdminDashboard &&
            (selected_user?.roleCode === 'SV' ||
              (user.roleCode === 'SV' && !selected_user) ||
              (user.roleCode === 'PA' && !selected_user))
          ">
          <div class="d-flex flex-row">
            <h6 class="font-weight-500">
              {{ user.roleCode === 'PA' && !selected_user ? 'CC' : 'My Team' }} Task Status
            </h6>
            <span
              class="spinner-grow spinner-grow-sm ml-2"
              role="status"
              aria-hidden="true"
              *ngIf="adminTaskloading"></span>
          </div>
          <mdb-accordion
            class="accordion-block"
            [multiple]="false"
            *ngIf="!loading"
            [style.opacity]="adminTaskloading ? '0.4' : '1'"
            [class.disabled]="adminTaskloading ? true : false">
            <mdb-accordion-item id="high-priority">
              <mdb-accordion-item-head>
                <div class="d-flex justify-content-between align-items-center text-danger">
                  High Priority Tasks
                  <div class="chip chip-rounded bg-danger border-0 rounded-circle m-0 text-white">
                    {{
                      adminTasks?.highPriorityTasks2 && adminTasks.highPriorityTasks2.length > 0
                        ? adminTasks.highPriorityTasks2['totalCount']
                        : 0
                    }}
                  </div>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="adminTasks.highPriorityTasks2.length === 0">
                  <h6 class="text-center mt-4">No Tasks Available</h6>
                </div>
                <div
                  class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin">
                  <div
                    class="d-flex justify-content-end"
                    *ngIf="adminTasks.highPriorityTasks2.length">
                    <i
                      class="fas fa-lg fa-caret-up text-danger pointer-cursor m-1"
                      *ngIf="sortingType.highPriorityTasks2 === 'asc'"
                      (click)="sortTasks('desc', 'highPriorityTasks2')"></i>
                    <i
                      class="fas fa-lg fa-caret-down text-danger pointer-cursor m-1"
                      *ngIf="sortingType.highPriorityTasks2 === 'desc'"
                      (click)="sortTasks('asc', 'highPriorityTasks2')"></i>
                  </div>
                  <mdb-card class="white tasks" *ngFor="let user of adminTasks.highPriorityTasks2">
                    <mdb-card-header>
                      <div class="d-flex justify-content-between align-items-center">
                        <h6>{{ user.assignedToName }}</h6>
                        <div
                          class="chip chip-rounded bg-white border-danger rounded-circle m-0 text-danger">
                          {{ user?.tasks?.length }}
                        </div>
                      </div>
                    </mdb-card-header>
                    <mdb-card-body class="pl-3 pr-3">
                      <mdb-card-text>
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item pointer-cursor pl-0 pr-0"
                            *ngFor="let todo of user.tasks"
                            (click)="directToTaskMilestone(todo)">
                            <span
                              class="list-item mb-2 d-flex justify-content-start align-items-start">
                              <i class="icofont-tasks icofont-lg skin-text-primary-dark mr-2"></i>
                              <p class="font-weight-500">
                                {{ todo.name }}
                              </p>
                            </span>
                            <span>
                              <p class="font-weight-400 ml-4">Patient - {{ todo.patientName }}</p>
                              <p class="font-weight-400 ml-4">Episode - {{ todo.episodeName }}</p>
                            </span>
                          </li>
                        </ul>
                      </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item id="medium-priority">
              <mdb-accordion-item-head>
                <div class="d-flex justify-content-between align-items-center text-warning">
                  Medium Priority Tasks
                  <div class="chip chip-rounded bg-warning border-0 rounded-circle m-0 text-white">
                    {{
                      adminTasks?.mediumPriorityTasks2 && adminTasks.mediumPriorityTasks2.length > 0
                        ? adminTasks.mediumPriorityTasks2['totalCount']
                        : 0
                    }}
                  </div>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="adminTasks.mediumPriorityTasks2.length === 0">
                  <h6 class="text-center mt-4">No Tasks Available</h6>
                </div>
                <div
                  class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
                  <div
                    class="d-flex justify-content-end"
                    *ngIf="adminTasks.mediumPriorityTasks2.length">
                    <i
                      class="fas fa-lg fa-caret-up text-warning pointer-cursor m-1"
                      *ngIf="sortingType.mediumPriorityTasks2 === 'asc'"
                      (click)="sortTasks('desc', 'mediumPriorityTasks2')"></i>
                    <i
                      class="fas fa-lg fa-caret-down text-warning pointer-cursor m-1"
                      *ngIf="sortingType.mediumPriorityTasks2 === 'desc'"
                      (click)="sortTasks('asc', 'mediumPriorityTasks2')"></i>
                  </div>
                  <mdb-card
                    class="white tasks"
                    *ngFor="let user of adminTasks.mediumPriorityTasks2">
                    <mdb-card-header>
                      <div class="d-flex justify-content-between align-items-center">
                        <h6>{{ user.assignedToName }}</h6>
                        <div
                          class="chip chip-rounded bg-white border-warning rounded-circle m-0 text-warning">
                          {{ user?.tasks?.length }}
                        </div>
                      </div>
                    </mdb-card-header>
                    <mdb-card-body class="pl-3 pr-3">
                      <mdb-card-text>
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item pointer-cursor pl-0 pr-0"
                            *ngFor="let todo of user.tasks"
                            (click)="directToTaskMilestone(todo)">
                            <span
                              class="list-item mb-2 d-flex justify-content-start align-items-start">
                              <i class="icofont-tasks icofont-lg skin-text-primary-dark mr-2"></i>
                              <p class="font-weight-500">
                                {{ todo.name }}
                              </p>
                            </span>
                            <span>
                              <p class="font-weight-400 ml-4">Patient - {{ todo.patientName }}</p>
                              <p class="font-weight-400 ml-4">Episode - {{ todo.episodeName }}</p>
                            </span>
                          </li>
                        </ul>
                      </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item id="low-priority">
              <mdb-accordion-item-head>
                <div class="d-flex justify-content-between align-items-center text-success">
                  Completed Tasks
                  <div class="chip chip-rounded bg-success border-0 rounded-circle m-0 text-white">
                    {{
                      adminTasks?.completedTasks2 && adminTasks.completedTasks2.length > 0
                        ? adminTasks.completedTasks2['totalCount']
                        : 0
                    }}
                  </div>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div *ngIf="adminTasks.completedTasks2.length === 0">
                  <h6 class="text-center mt-4">No Tasks Available</h6>
                </div>
                <div
                  class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin mt-3">
                  <div class="d-flex justify-content-end" *ngIf="adminTasks.completedTasks2.length">
                    <i
                      class="fas fa-lg fa-caret-up text-success pointer-cursor m-1"
                      *ngIf="sortingType.completedTasks2 === 'asc'"
                      (click)="sortTasks('desc', 'completedTasks2')"></i>
                    <i
                      class="fas fa-lg fa-caret-down text-success pointer-cursor m-1"
                      *ngIf="sortingType.completedTasks2 === 'desc'"
                      (click)="sortTasks('asc', 'completedTasks2')"></i>
                  </div>
                  <mdb-card class="white tasks" *ngFor="let user of adminTasks.completedTasks2">
                    <mdb-card-header>
                      <div class="d-flex justify-content-between align-items-center">
                        <h6>{{ user.assignedToName }}</h6>
                        <div
                          class="chip chip-rounded bg-white border-success rounded-circle m-0 text-success">
                          {{ user?.tasks?.length }}
                        </div>
                      </div>
                    </mdb-card-header>

                    <mdb-card-body class="pl-3 pr-3">
                      <mdb-card-text>
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item pointer-cursor pl-0 pr-0"
                            *ngFor="let todo of user.tasks"
                            (click)="directToTaskMilestone(todo)">
                            <span
                              class="list-item mb-2 d-flex justify-content-start align-items-start">
                              <i class="icofont-tasks icofont-lg skin-text-primary-dark mr-2"></i>
                              <p class="font-weight-500">
                                {{ todo.name }}
                              </p>
                            </span>
                            <span>
                              <p class="font-weight-400 ml-4">Patient - {{ todo.patientName }}</p>
                              <p class="font-weight-400 ml-4">Episode - {{ todo.episodeName }}</p>
                            </span>
                          </li>
                        </ul>
                      </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </mdb-accordion>
        </div>
        <!-- My Team Task Status -->

        <div *ngIf="isEocRequestPermitted" class="col-12 col-sm-6 col-md-3 mt-3 mt-sm-0">
          <!-- new design for Episode list  start here-->
          <h6 class="font-weight-500">Episode of Care Request</h6>
          <div class="">
            <mdb-accordion class="accordion-block" [multiple]="false">
              <!-- Preliminary -->
              <mdb-accordion-item id="preliminary">
                <mdb-accordion-item-head>
                  <div class="d-flex justify-content-between align-items-center">
                    Pended
                    <div class="chip chip-rounded alert preliminary m-0">
                      {{ requestEocList.length }}
                    </div>
                  </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div *ngIf="requestEocList.length === 0">
                    <h6 class="text-center mt-4">No Pended Requests</h6>
                  </div>
                  <div
                    class="sticky-content sticky-content-50vh pr-1 scrollbar scrollbar-primary thin">
                    <div class="d-flex justify-content-end" *ngIf="requestEocList.length">
                      <i
                        class="fas fa-lg fa-caret-up is-in-review pointer-cursor m-1"
                        *ngIf="sortingType.eocList === 'asc'"
                        (click)="sortEoc('desc', 'eocList')"></i>
                      <i
                        class="fas fa-lg fa-caret-down is-in-review pointer-cursor m-1"
                        *ngIf="sortingType.eocList === 'desc'"
                        (click)="sortEoc('asc', 'eocList')"></i>
                    </div>
                    <mdb-card class="pointer-cursor white" *ngFor="let eachEoc of requestEocList">
                      <mdb-card-body (click)="onOneEocRequestClick()" class="p-3">
                        <mdb-card-title>
                          <h6>
                            {{ eachEoc?.eocMember[0]?.firstName }}
                            {{ eachEoc.eocMember[0]?.lastName }}
                          </h6>
                        </mdb-card-title>
                        <mdb-card-text>
                          <span>
                            <p class="font-weight-400">
                              <!-- Patient - {{ episode?.patient?.fullName || episode?.claimPatient?.fullName }} -->
                            </p>
                            <!-- <p class="font-weight-400">Care Coordinator - </p> -->
                            <p class="font-weight-400">
                              {{ eachEoc?.eocReference[0]?.bundleDisplayName }}
                            </p>
                          </span>
                        </mdb-card-text>
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                  <p class="text-right mt-3">
                    <a class="p-2 text-muted skin-link" (click)="onOneEocRequestClick()">
                      View All
                    </a>
                  </p>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </mdb-accordion>
            <!-- <div class="text-center p-3 grey lighten-4" *ngIf="episodes.length == 0">
    <i class="fa fa-3x fa-layer-group text-black-50"></i>
    <p>
      No Episodes available
    </p>
  </div> -->
          </div>
          <!-- new design for Episode list  end  here-->
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="user.roleCode === 'PR'">
  <app-claims-dashboard></app-claims-dashboard>
</div>
<div *ngIf="user.roleCode === 'CLA' || selectedDashboard === 'claim'">
  <app-new-claims-dashboard
    [selectedDashboard]="selectedDashboard"
    [dashboardTypes]="dashboardTypes"
    (emitSelectedDashboard)="receiveSelectedDashboard($event)"></app-new-claims-dashboard>
</div>
