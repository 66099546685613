<div class="grey lighten-5 mb-2 p-3">
  <mdb-card>
    <mdb-card-header class="pointer-cursor" (click)="toggleCard()">
      <div class="d-flex justify-content-between align-items-center">
        <p class="m-0 font-weight-bold">Report Filter Criteria</p>
        <span class="link pointer-cursor ml-3">
          <i [class]="expandAdvanceFiltering ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body class="p-3" *ngIf="expandAdvanceFiltering">
      <form [formGroup]="paymentReportForm">
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label for="payment-report-date">Report Date *</label>
              <mdb-select
                #select
                [options]="reportDateTypeList | sort : 'label'"
                placeholder="Select report date"
                data-cy="payment-report-date"
                [visibleOptions]="3"
                (selected)="onReportDateTypeSelected()"
                formControlName="reportDateType">
              </mdb-select>
            </div>
          </div>
          <div class="col-3 form-group">
            <div class="form-row">
              <div class="col">
                <label for="payment-report-payment-date-from">From</label>
                <mdb-date-picker
                  [inline]="true"
                  name="payment-report-payment-date-from"
                  data-cy="payment-report-payment-date-from"
                  placeholder="Select date"
                  formControlName="paymentDateFrom"
                  [disabled]="isPreSelectedFilter || !paymentReportForm.value.reportDateType">
                </mdb-date-picker>
              </div>
              <div class="col">
                <label for="payment-report-payment-date-to">To</label>
                <mdb-date-picker
                  [inline]="true"
                  name="payment-report-payment-date-to"
                  data-cy="payment-report-payment-date-to"
                  placeholder="Select date"
                  formControlName="paymentDateTo"
                  [disabled]="isPreSelectedFilter || !paymentReportForm.value.reportDateType">
                </mdb-date-picker>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group position-relative">
              <label for="payment-report-payee-select">Payee</label>
              <input
                type="text"
                [ngModel]="payeeSearchText | async"
                id="payment-report-payee-select"
                (ngModelChange)="payeeSearchText.next($event)"
                [ngModelOptions]="{standalone: true}"
                class="completer-input form-control mdb-autocomplete mb-0"
                autocomplete="off"
                [mdbAutoCompleter]="autoPayee"
                placeholder="Type to search payee" />
              <mdb-auto-completer
                #autoPayee="mdbAutoCompleter"
                [textNoResults]="isLoading.payeeSearchList ? 'Loading...' : 'No result found'"
                [displayValue]="displayPayee"
                [optionHeight]="80"
                (selected)="onPayeeSearchResultSelected($event)">
                <mdb-option *ngFor="let payee of payeesResult | async" [value]="payee">
                  <div class="d-flex flex-column">
                    <strong>{{ payee.name | titlecase }}</strong>
                    <span>
                      located in {{ payee.city }}{{ payee.state ? ',' : '' }}
                      {{ payee.state }}</span
                    >
                  </div>
                </mdb-option>
              </mdb-auto-completer>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label for="payment-report-payor-select">Payor</label>
              <mdb-select
                #select
                [options]="payorList | sort : 'label'"
                [allowClear]="true"
                placeholder="Select payor"
                data-cy="payment-report-payor-select"
                [filterEnabled]="true"
                [visibleOptions]="3"
                formControlName="payorID">
              </mdb-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label for="payment-report-payment-type-select">Payment Type</label>
              <mdb-select
                #select
                [options]="paymentTypeList | sort : 'label'"
                [allowClear]="true"
                placeholder="Select payment type"
                data-cy="payment-report-payment-type-select"
                [visibleOptions]="3"
                formControlName="paymentType">
              </mdb-select>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label for="payment-report-patient-account-number">Claim ID</label>
              <input
                class="form-control"
                type="text"
                placeholder="Enter claim ID"
                data-cy="payment-report-patient-account-number"
                formControlName="claimID" />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label for="payment-report-payment-mode-trace-number"
                >Payment Mode Trace Number</label
              >
              <input
                class="form-control"
                type="text"
                placeholder="Enter payment mode trace number"
                data-cy="payment-report-payment-mode-trace-number"
                formControlName="paymentModeTraceNumber" />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <div class="form-group position-relative">
                <label for="payment-report-patient-select">Patient</label>
                <input
                  type="text"
                  [ngModel]="patientSearchText | async"
                  id="payment-report-patient-select"
                  (ngModelChange)="patientSearchChange($event)"
                  [ngModelOptions]="{standalone: true}"
                  class="completer-input form-control mdb-autocomplete mb-0"
                  autocomplete="off"
                  [mdbAutoCompleter]="autoPatient"
                  placeholder="Type to search patient" />
                <mdb-auto-completer
                  #autoPatient="mdbAutoCompleter"
                  [textNoResults]="isLoading.patientSearchList ? 'Loading...' : 'No result found'"
                  [displayValue]="displayPatient"
                  [optionHeight]="80"
                  (selected)="onPatientSearchResultSelected($event)">
                  <mdb-option *ngFor="let patient of patientsResult" [value]="patient">
                    <div class="d-flex flex-column">
                      <strong
                        >{{ patient.firstName | titlecase }} {{ patient.middleName | titlecase }}
                        {{ patient.lastName | titlecase }}</strong
                      >
                    </div>
                  </mdb-option>
                </mdb-auto-completer>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label for="payment-report-payment-mode-select">Payment Mode</label>
              <mdb-select
                #select
                [options]="paymentModeList | sort : 'label'"
                [allowClear]="true"
                placeholder="Select payment mode"
                data-cy="payment-report-payment-mode-select"
                [visibleOptions]="3"
                formControlName="paymentMode">
              </mdb-select>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group position-relative">
              <label for="payment-report-episode-select">Episode</label>
              <app-select
                [options]="episodeTypeList"
                [allowClear]="true"
                id="payment-report-episode-select"
                [control]="paymentReportForm.controls.episode"
                [placeholder]="'Select episode type'"
                [multiple]="true"></app-select>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label for="payment-report-subscriber-number">Subscriber Number</label>
              <input
                class="form-control"
                type="text"
                placeholder="Enter subscriber number"
                data-cy="payment-report-subscriber-number"
                formControlName="subscriberNumber" />
            </div>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-end mt-4">
        <button
          class="btn btn-light bg-white border m-0"
          [disabled]="isLoading.paymentReport"
          mdbWavesEffect
          (click)="onResetFilters()">
          Clear
        </button>
        <button
          class="btn btn-secondary waves-light m-0 ml-2"
          mdbWavesEffect
          [disabled]="isGenerateReportDisabled()"
          (click)="onGeneratePaymentReport()">
          <span
            *ngIf="isLoading.paymentReport"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"></span>
          Generate Report
        </button>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>

<div class="container-fluid mb-5 pb-5">
  <app-loader [loader]="isLoading.paymentReport"></app-loader>
  <app-table
    *ngIf="displayReport()"
    [hasLazyLoadedData]="true"
    [report]="report"
    [completeReport]="completeReport"
    [exportTable]="true"
    [module]="'report'"
    (onScrollToBottom)="onScrollToBottom($event)"
    (emitReset)="resetReport($event)"
    (getCompleteReport)="getCompleteReport($event)"
    tableHeight="650px"></app-table>
</div>
