import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { PasswordValidation } from 'src/app/directives/directive.password-match';
import { Router } from '@angular/router';
import * as HeaderBreadCrumbActions from '../../../action'
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import { Storage } from '../../../utils';
import { ChangePassword } from '../../../models';
import { AuthService, ToasterService } from '../../../services';

@Component({
  selector: 'app-layout-changepassword',
  templateUrl: './layout-changepassword.component.html',
  styleUrls: ['./layout-changepassword.component.scss']
})
export class LayoutChangepasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  user;
  isSubmitEnabled: boolean = false
  submitted: boolean = false
  @ViewChild('changePasswordModal', { static: true }) public changePasswordModal
  constructor(
    private formBuilder: FormBuilder,
    private _toastr: ToasterService,
    private authService: AuthService,
    private router: Router,
    private store: Store<{ bread_crumbs: any }>,
    private _storage: Storage
  ) { }

  ngOnInit() {
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Change Password', path: '/change-password' }));
    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: ["", [Validators.required]],
        password: ["", [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$")]],
        confirmPassword: ["", Validators.required]
      },
      { validator: PasswordValidation.MatchPassword }
    );
  }
  changePassword() {
    this.submitted = true
    if (this.changePasswordForm.valid) {
      this.isSubmitEnabled = true;
      let model: ChangePassword = {
        oldPassword: this.changePasswordForm.value.oldPassword,
        email: this.user.email,
        newPassword: this.changePasswordForm.value.password,
        userId: this.user.id
      };
      this.authService.changePassword(model).pipe(finalize(() => {
        this.isSubmitEnabled = false;
      })).subscribe(
        response => {
          if (response.data.message) {
            this._toastr.displayError(response.data.message);
          } else {
            localStorage.clear();
            this.changePasswordModal.show();
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }
  changeSuccess() {
    this.router.navigate(["/logout"]);
  }
  closeModal() {
    this.changePasswordModal.hide();
    this.router.navigate(["/logout"]);
  }

}
