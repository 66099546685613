<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group has-search input-group mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          [(ngModel)]="searchKeyword"
          class="form-control"
          name="searchKeyword"
          placeholder="Search"
          (ngModelChange)="searchByKeyword($event)" />
        <div class="input-group-append" *ngIf="searchKeyword">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-light z-depth-0 pl-3 pr-3"
          data-toggle="modal"
          data-target="#basicExample"
          mdbWavesEffect
          (click)="openFilterModal()">
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
      </div>
    </div>
  </div>
  <div class="keywords position-sticky" *ngIf="filterList && filterList.length > 0">
    <div class="keywords d-flex mt-2 flex-wrap align-items-center">
      <small class="mb-2 mr-2">Filters: </small>
      <span class="d-block mr-1 mb-2" *ngFor="let filter of filterList">
        <small class="d-block text-muted ml-1"></small>
        <div class="chip blue lighten-5 mb-0">
          {{ filter.field }}:
          <span class="font-weight-500 text-dark">{{ filter.label | titlecase }}</span>
          <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
        </div>
      </span>
      <button
        type="button"
        class="btn btn-link waves-light m-0 p-0"
        mdbWavesEffect
        (click)="ResetFilter()">
        <span class="text-info">Reset Filter</span>
      </button>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table
          mdbTable
          hover="true"
          small="true"
          class="table-mobile-view v-top"
          stickyHeader="true">
          <thead id="scrollToTop">
            <tr>
              <th width="180" [class.active]="sortBy === 'claimId'" (click)="setOrder('claimId')">
                Claim ID
                <span *ngIf="sortBy === 'claimId'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="170"
                [class.active]="sortBy === 'patientName'"
                (click)="setOrder('patientName')">
                Patient Info
                <span *ngIf="sortBy === 'patientName'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="230"
                [class.active]="sortBy === 'episodeName'"
                (click)="setOrder('episodeName')">
                Episode Name
                <span *ngIf="sortBy === 'episodeName'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="400"
                [class.active]="sortBy === 'billingProviderName'"
                (click)="setOrder('billingProviderName')">
                Billing Info
                <span *ngIf="sortBy === 'billingProviderName'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="80"
                class="text-right"
                [class.active]="sortBy === 'billingAmount'"
                (click)="setOrder('billingAmount')">
                Billed Amt
                <span *ngIf="sortBy === 'billingAmount'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="120"
                class="text-right"
                [class.active]="sortBy === 'allowedAmount'"
                (click)="setOrder('allowedAmount')">
                Allowed Amt
                <span *ngIf="sortBy === 'allowedAmount'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th width="230" class="pl-3">Status</th>
              <th width="150" [class.active]="sortBy === 'timeline'" (click)="setOrder('timeline')">
                Timeline
                <span *ngIf="sortBy === 'timeline'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th width="50"></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading.claims"
            [style.top]="claimsList && claimsList.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading.claims"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let claims of claimsList
                | paginate : {itemsPerPage: limit, currentPage: page, totalItems: totalCount};
              let i = index
            ">
            <tr>
              <td (click)="getOutgoingClaimsDetail(claims.id)">
                {{ claims.patientAccountNumber }} <br />
                <span class="badge badge-sm bg-primary-dark text-white z-depth-0">
                  {{ claims.transaction.ediType }} </span
                ><br />
              </td>
              <td (click)="getOutgoingClaimsDetail(claims.id)">
                <ul class="fa-ul ml-4 mb-0">
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="user fa-sm"></mdb-icon>
                    </span>
                    {{ claims.patient.displayName | titlecase }}
                  </li>
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="birthday-cake fa-sm"></mdb-icon>
                    </span>
                    {{
                      (claims.patient.patientDob
                        ? claims.patient.patientDob
                        : claims.patient.subscriberDob
                      ) | date : 'MM/dd/yyyy' : 'UTC'
                    }}
                  </li>
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="address-card fa-sm"></mdb-icon>
                    </span>
                    {{ claims.patient.subscriberIdentificationCode }}
                  </li>
                </ul>
              </td>
              <td (click)="getOutgoingClaimsDetail(claims.id)">
                {{
                  claims.claimXRefHolista && claims.claimXRefHolista.episodeName
                    ? claims.claimXRefHolista.episodeName
                    : ''
                }}<br />
                <span class="badge badge-sm bg-primary-dark text-white z-depth-0">
                  {{ claims.claimXRefHolista.fundingReqName | titlecase }}
                </span>
              </td>
              <td (click)="getOutgoingClaimsDetail(claims.id)">
                <span class="font-weight-500">{{
                  (claims.transaction.billingProvider &&
                  claims.transaction.billingProvider.basicInfo &&
                  claims.transaction.billingProvider.basicInfo.displayName
                    ? claims.transaction.billingProvider.basicInfo.displayName
                    : ''
                  ) | uppercase
                }}</span>
                <ul class="fa-ul ml-4 mb-0">
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="address-card fa-sm"></mdb-icon>
                    </span>
                    <span mdbTooltip="NPI" placement="right">
                      {{
                        claims.transaction.billingProvider &&
                        claims.transaction.billingProvider.basicInfo &&
                        claims.transaction.billingProvider.basicInfo.identificationCode
                          ? claims.transaction.billingProvider.basicInfo.identificationCode
                          : ''
                      }}
                    </span>
                  </li>
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="address-card fa-sm"></mdb-icon>
                    </span>
                    <span mdbTooltip="TIN" placement="right"
                      >{{
                        (claims.transaction.billingProvider &&
                        claims.transaction.billingProvider.taxIdentificationNumber
                          ? claims.transaction.billingProvider.taxIdentificationNumber
                          : ''
                        ) | titlecase
                      }}
                    </span>
                  </li>
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="map-marker-alt fa-sm"></mdb-icon>
                    </span>
                    {{
                      (claims.transaction.billingProvider &&
                      claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.addressLine1
                        ? claims.transaction.billingProvider.basicInfo.addressLine1
                        : ''
                      ) | titlecase
                    }}
                    {{
                      (claims.transaction.billingProvider &&
                      claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.addressLine2
                        ? claims.transaction.billingProvider.basicInfo.addressLine2
                        : ''
                      ) | titlecase
                    }}
                    <br />
                    {{
                      (claims.transaction.billingProvider &&
                      claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.city
                        ? claims.transaction.billingProvider.basicInfo.city
                        : ''
                      ) | titlecase
                    }}
                    {{
                      claims.transaction.billingProvider &&
                      claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.state
                        ? claims.transaction.billingProvider.basicInfo.state
                        : ''
                    }}
                    {{
                      claims.transaction.billingProvider &&
                      claims.transaction.billingProvider.basicInfo &&
                      claims.transaction.billingProvider.basicInfo.zipCode
                        ? claims.transaction.billingProvider.basicInfo.zipCode
                        : ''
                    }}
                  </li>
                </ul>
              </td>
              <td (click)="getOutgoingClaimsDetail(claims.id)" class="text-right">
                {{ claims.totalClaimChargeAmount | currency }}
              </td>
              <td class="text-right" (click)="getOutgoingClaimsDetail(claims.id)">
                {{ claims.claimXRefHolista.allowedAmount | currency }}
                <ng-template #allowedAmountTemplate>
                  <table style="width: 300px" mdbTable small="true">
                    <tr>
                      <td colspan="2">
                        <p class="m-0"><b>Cash Received</b></p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td><p class="m-0">Claim Amount</p></td>
                      <td class="px-0 text-right w-25">
                        <p class="m-0">
                          {{
                            claims.claimXRefHolista.netAmount !== null
                              ? (claims.claimXRefHolista.netAmount | currency)
                              : '-'
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td><p class="m-0">HRA/HSA</p></td>
                      <td class="px-0 text-right w-25">
                        <p class="m-0">
                          {{
                            claims.claimXRefHolista.hraHsaAmount !== null
                              ? (claims.claimXRefHolista.hraHsaAmount | currency)
                              : '-'
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td colspan="2">
                        <p class="m-0"><b>Payment Adjustment</b></p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td><p class="m-0">Client Fee</p></td>
                      <td class="px-0 text-right w-25">
                        <p class="m-0">
                          {{
                            claims.claimXRefHolista.clientFeeAdjustment !== null
                              ? (claims.claimXRefHolista.clientFeeAdjustment | currency)
                              : '-'
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td><p class="m-0">Patient Responsibility</p></td>
                      <td class="px-0 text-right w-25">
                        <p class="m-0">
                          {{
                            claims.claimXRefHolista.patientPayAmount !== null
                              ? (claims.claimXRefHolista.patientPayAmount | currency)
                              : '-'
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr class="border-top">
                      <td class="pb-0">
                        <p class="m-0"><b>Total Allowed Amount</b></p>
                      </td>
                      <td class="px-0 pb-0 text-right">
                        <p class="m-0">
                          <b>{{ claims.claimXRefHolista.allowedAmount | currency }}</b>
                        </p>
                      </td>
                    </tr>
                  </table>
                </ng-template>
                <i
                  *ngIf="claims.claimXRefHolista.allowedAmount"
                  class="icofont-info-circle btn-link pointer-cursor ml-1"
                  [mdbPopover]="allowedAmountTemplate"
                  placement="bottom"
                  triggers="mouseenter:mouseleave"></i>
              </td>
              <td class="pl-3">
                <div
                  class="chip alert m-0 w-auto rounded-sm {{ claims.claimStatusClass }}"
                  *ngIf="
                    claims.claimXRefHolista &&
                    claims.claimXRefHolista.status.toLowerCase() !== 'invalid' &&
                    claims.claimXRefHolista.processingStatus
                  ">
                  <div
                    *ngIf="
                      allowedOutgoingClaimStatus[
                        claims?.claimXRefHolista?.processingStatus?.toLowerCase()
                      ].length
                    "
                    class="custom-dropdown">
                    <div class="d-flex justify-content-between w-100 pl-2 pr-2">
                      {{ claims.claimXRefHolista.processingStatus | titlecase }}
                      <button
                        class="btn btn-link border-0 dropdown-toggle waves-light m-0 ml-2 p-0"
                        type="button"></button>
                    </div>

                    <div class="custom-dropdown-content drop-satatus rounded-sm z-depth-2">
                      <a
                        *ngFor="let status of claims.processingStatus"
                        class="cursor-pointer dropdown-item"
                        (click)="statusChanged(status, claims)">
                        {{ status.label }}</a
                      >
                    </div>
                  </div>

                  <span
                    class="status-label"
                    placement="left"
                    *ngIf="
                      !allowedOutgoingClaimStatus[
                        claims?.claimXRefHolista?.processingStatus?.toLowerCase()
                      ].length
                    ">
                    {{ claims.claimXRefHolista.processingStatus | titlecase }}
                  </span>
                </div>
                <br />
                <div class="claim-status">
                  <small
                    *ngIf="
                      claims.claimXRefHolista.processingStatus.toLowerCase() !== 'rejected' ||
                      claims.claimXRefHolista.processingStatus.toLowerCase() !== 'cancelled'
                    "
                    [class]="
                      claims.claimXRefHolista.processingStatus.toLowerCase() === 'rejected'
                        ? 'is-rejected'
                        : 'is-cancelled'
                    ">
                    {{ claims.claimXRefHolista.statusReason }}
                  </small>
                </div>
              </td>

              <td (click)="getOutgoingClaimsDetail(claims.id)">
                <div class="d-flex align-items-center">
                  <div [style.width]="'65px'">Created</div>
                  {{ claims.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}
                </div>
                <div class="d-flex align-items-center">
                  <div [style.width]="'65px'">Received</div>
                  <!-- {{
                    claims.claimXRefHolista.transactionDetail &&
                    claims.claimXRefHolista.transactionDetail.length > 0
                      ? (claims.claimXRefHolista.transactionDetail[0].transaction?.transactionDate
                        | date : 'MM/dd/yyyy' : 'UTC')
                      : '-'
                  }} -->
                  {{
                    claims.claimPayment
                      ? (claims.claimPayment.payment.paymentDate | date : 'MM/dd/yyyy')
                      : '-'
                  }}
                </div>
              </td>
              <td>
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div class="custom-dropdown-content rounded-sm z-depth-2">
                    <a (click)="view(claims)" class="cursor-pointer dropdown-item">
                      <i class="far fa-eye fa-fw mr-1"></i> 837I</a
                    >
                    <a (click)="onSendEmail(claims)" class="cursor-pointer dropdown-item">
                      <i class="fas fa-envelope mr-1"></i> Send Email</a
                    >
                    <a
                      (click)="goToPayment(claims)"
                      class="cursor-pointer dropdown-item"
                      *ngIf="
                        claims.claimXRefHolista &&
                        claims.claimXRefHolista.status.toLowerCase() !== 'invalid' &&
                        claims.claimXRefHolista.processingStatus.toLowerCase() == 'approved'
                      ">
                      <i class="fas fa-credit-card"></i> Add Payment</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="claimsList && claimsList.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <div
    class="text-center p-4 border mt-3"
    *ngIf="!loading.claims && (!claimsList || claimsList.length == 0)">
    <i class="fas fa-3x fa-notes-medical text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>

<!-- Reject/Deny Modal -->
<div
  mdbModal
  #reasonModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Reason</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeReasonModal('cancel')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <form #statusForm="ngForm">
            <div class="form-group">
              <label for="reason">Choose Reason *</label>
              <mdb-select
                class="reason-dropdown"
                [options]="exceptionCodes | sort : 'label'"
                [filterEnabled]="true"
                [filterPlaceholder]="'Search..'"
                id="exceptionCodes"
                [(ngModel)]="statusModel.value"
                [ngModelOptions]="{standalone: true}"
                (selected)="reasonSelected($event)"
                placeholder="Select reason"
                [multiple]="false"
                [enableSelectAll]="false">
              </mdb-select>
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeReasonModal('cancel')">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitReason()"
          [disabled]="!statusModel.statusCode">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading.saving"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Filter Modal -->
<div
  mdbModal
  #outgoingClaimsFilterModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter Outgoing Claims</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFilterModal('close')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <form [formGroup]="outgoingClaimsfilterForm">
            <div class="form-row">
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="submitter">Choose Submitter</label>
                  <input
                    type="text"
                    [ngModel]="submitterSearchText | async"
                    id="submitter"
                    (ngModelChange)="submitterSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto1"
                    placeholder="Type to select submitter" />
                  <mdb-auto-completer
                    #auto1="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displaySubmitter"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'submitterId')">
                    <mdb-option *ngFor="let option of submitterResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.displayName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="patient">Choose Patient</label>
                  <input
                    type="text"
                    [ngModel]="patientSearchText | async"
                    id="patient"
                    (ngModelChange)="patientSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto2"
                    placeholder="Type to select patient" />
                  <mdb-auto-completer
                    #auto2="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayPatient"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'patientId')">
                    <mdb-option *ngFor="let option of patientResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.displayName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="billingInfo">Choose Billing Provider</label>
                  <input
                    type="text"
                    [ngModel]="billingProviderSearchText | async"
                    id="billingInfo"
                    (ngModelChange)="billingProviderSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto3"
                    placeholder="Type to select billing provider" />
                  <mdb-auto-completer
                    #auto3="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayBillingInfo"
                    [optionHeight]="100"
                    (selected)="onSearchResultSelected($event, 'billingProviderId')">
                    <mdb-option
                      *ngFor="let option of billingProviderResults | async"
                      [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.displayName | titlecase }}</strong>
                        <span>{{ option.identificationCode }}</span>
                        <span>
                          located in {{ option.city | titlecase }}{{ option.state ? ',' : '' }}
                          {{ option.state }}</span
                        >
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="renderingProvider">Subscriber Number</label>
                  <input
                    placeholder="Enter subscriber number"
                    type="text"
                    mdbInput
                    class="form-control"
                    formControlName="subscriberNumber" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="episode">Choose Episode</label>
                  <input
                    type="text"
                    [ngModel]="episodeSearchText | async"
                    id="episode"
                    (ngModelChange)="episodeSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto7"
                    placeholder="Type to select episode" />
                  <mdb-auto-completer
                    #auto7="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayEpisode"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'episodeName')">
                    <mdb-option *ngFor="let option of episodeResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.episodeName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="payor">Choose Payor</label>
                  <input
                    type="text"
                    [ngModel]="payerSearchText | async"
                    id="payor"
                    (ngModelChange)="payerSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto4"
                    placeholder="Type to select payor" />
                  <mdb-auto-completer
                    #auto4="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayPayer"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'payerCode')">
                    <mdb-option *ngFor="let option of payerResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.payerName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="client">Choose Client</label>
                  <input
                    type="text"
                    [ngModel]="clientSearchText | async"
                    id="client"
                    (ngModelChange)="clientSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto5"
                    placeholder="Type to select client" />
                  <mdb-auto-completer
                    #auto5="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayClient"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'clientCode')">
                    <mdb-option *ngFor="let option of clientResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.clientName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="purchaser">Choose Purchaser</label>
                  <input
                    type="text"
                    [ngModel]="purchaserSearchText | async"
                    id="purchaser"
                    (ngModelChange)="purchaserSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [mdbAutoCompleter]="auto6"
                    placeholder="Type to select purchaser" />
                  <mdb-auto-completer
                    #auto6="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayPurchaser"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'purchaserCode')">
                    <mdb-option *ngFor="let option of purchaserResults | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.purchaserName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <div class="form-group">
                  <label for="processingstatus">Choose Processing Status</label>
                  <mdb-select
                    [options]="processingStatus"
                    id="processingstatus"
                    placeholder="Select processing status"
                    [multiple]="true"
                    [enableSelectAll]="false"
                    formControlName="processingStatus">
                  </mdb-select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeFilterModal('close')">
          Cancel
        </button>
        <button class="btn btn-secondary waves-light m-0" mdbWavesEffect (click)="filterClaims(1)">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [moduleOptions]="moduleOptions"
  [metaVariablesDetail]="metaVariablesDetail"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->
