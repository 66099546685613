<!-- dependent task starts from here -->
<div *ngIf="dependentQuestion" class="mt-2 list-content w-100">
  <div class="d-flex">
    <div class="icon-block-sm d-none d-sm-block rounded-sm bg-secondary text-center mr-2">
      <i class="icofont-link text-white"></i>
    </div>
    <div class="w-100">
      <div class="d-flex">
        <div class="icon-block d-none d-sm-block">
          <i class="icofont-question-circle icofont-lg skin-text-primary-dark"></i>
        </div>
        <div>
          <p class="font-weight-400">
            {{ dependentQuestion.questions.question }}
          </p>
          <p *ngIf="dependentQuestion.questions.questionHelp">
            {{ dependentQuestion.questions.questionHelp }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="dependentQuestion.status === 'COMPLETED'" class="list-content-menu second-level">
    <div
      *ngFor="let answer of dependentQuestion.questions.ansOpt | orderBy : 'answerOptionId'"
      class="list-content-item"
      [ngClass]="{'d-none': !answer.answerValue}">
      <p *ngIf="answer.answerValue" class="text-primary">
        <span class="text-muted">Ans.</span>{{ answer.answerValue }}
      </p>
      <div *ngFor="let task of child_tasks">
        <app-episode-user-dependent-question
          *ngIf="task.field === 'question' && task.uuid === answer.taskQuesUuid"
          [dependent_question]="task"
          [dependentTopicTasks]="dependentTopicTasks">
        </app-episode-user-dependent-question>
        <app-episode-user-dependent-questionnaire
          *ngIf="task.field === 'questionnaire' && task.uuid === answer.taskQnrsUuid"
          [dependent_questionnaire]="task"
          [dependentTopicTasks]="dependentTopicTasks">
        </app-episode-user-dependent-questionnaire>
        <app-episode-user-dependent-message
          *ngIf="
            task.field === 'message' &&
            task.uuid === answer.taskMessageUuid &&
            task.messageToRole === 'MP'
          "
          [dependent_message]="task">
        </app-episode-user-dependent-message>
        <app-episode-user-dependent-todo
          *ngIf="task && task.uuid === answer.taskTodoUuid && task.assignedToRole === 'MP'"
          [dependent_todo]="task">
        </app-episode-user-dependent-todo>
        <app-episode-user-dependent-signature
          *ngIf="task && task.uuid === answer.taskSignatureUuid && task.assignedToRole === 'MP'"
          [dependentSignature]="task">
        </app-episode-user-dependent-signature>
      </div>
    </div>
  </div>
</div>
