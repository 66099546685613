import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Storage, FormatHtmlText } from '../../../../utils';
import { INBOX, OUTBOX, MESSAGE_STATUS } from '../../../../constants';
import { CommunicationService } from 'src/app/services';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @Input() messageList: any;
  @Input() type: string;
  @Input() sentMessageId: number;
  @Output() messageSelect: EventEmitter<any> = new EventEmitter();

  selectedInboxMessageId: number;
  selectedOutboxMessageId: number;
  readonly INBOX: string = INBOX;
  readonly BLOCKED = MESSAGE_STATUS.BLOCKED;
  readonly DEFERRED = MESSAGE_STATUS.DEFERRED;
  readonly BOUNCE = MESSAGE_STATUS.BOUNCE;
  readonly DROPPED = MESSAGE_STATUS.DROPPED;

  constructor(
    private _storage: Storage,
    private _communicationService: CommunicationService,
    private _formatHtmlText: FormatHtmlText
  ) { }

  ngOnInit() {
    const user = this._storage.get('session', 'user'); //non-platform user
    if (user) {
      this.selectedInboxMessageId = user.messageId;
      setTimeout(() => {
        this.type === INBOX && this.selectedInboxMessageId && this.getDefaultSelectedInboxMessage(this.selectedInboxMessageId)
      }, 1);
    } else {
      this.selectedInboxMessageId = this._storage.get('session', 'selectedInboxMessageId');
      const inboxMessage = { id: this.selectedInboxMessageId, isRead: true }
      this.type === INBOX && this.messageSelect.emit(inboxMessage);
    }
    this.selectedOutboxMessageId = this._storage.get('session', 'selectedOutboxMessageId');
    const outboxMessage = { id: this.selectedOutboxMessageId }
    this.type === OUTBOX && !this.sentMessageId && this.messageSelect.emit(outboxMessage);
    this.getFormattedText();
  }

  ngOnChanges() {
    this.sentMessageId && (this.sentMessageId !== this.selectedOutboxMessageId) && (
      this.selectedOutboxMessageId = this.sentMessageId,
      this.onMessageSelected(this.selectedOutboxMessageId)
    )
  }

  ngOnDestroy() {
    this.sentMessageId = null;
  }

  /**
   * selected message for non-platform user when verified by OTP
   * @param selectedInboxMessageId 
   */
  getDefaultSelectedInboxMessage(selectedInboxMessageId: number) {
    const selectedMessage = this.messageList.find(x => x.latestMessage.messageId === selectedInboxMessageId);
    if (selectedMessage) {
      const { messageId, _id } = selectedMessage.latestMessage;
      this.onMessageSelected(messageId, _id, selectedMessage.isRead);
    }
  }

  /**
   * returns thread related to the message selected
   * @param messageId 
   * @param id to set status of message as seen
   * @param isRead 
   */
  onMessageSelected(messageId: number, id?: number, isRead?: boolean) {
    if (this.type === INBOX) {
      this.selectedInboxMessageId = messageId;
      !isRead ? this._communicationService.updateReadStatus(messageId).subscribe(res => {
        if (res.isStatusChanged) {
          const index = this.messageList.findIndex(x => x.latestMessage.messageId === messageId);
          index >= 0 && (this.messageList[index].isRead = true);
        }
        this.messageSelect.emit({ id: messageId, isRead });
        this._storage.set('session', 'selectedInboxMessageId', this.selectedInboxMessageId);
      }) : (
        this.messageSelect.emit({ id: messageId, isRead }),
        this._storage.set('session', 'selectedInboxMessageId', this.selectedInboxMessageId)
      )
    } else {
      this.selectedOutboxMessageId = messageId;
      this.messageSelect.emit({ id: messageId, isRead });
      this._storage.set('session', 'selectedOutboxMessageId', this.selectedOutboxMessageId);
    }
  }

  /**
   * returns html to formatted text 
   */
  getFormattedText() {
    this.messageList = this.messageList.map(x => {
      const body = this._formatHtmlText.htmlToFormattedText(x.latestMessage.body);
      const latestMessage = { ...x.latestMessage, body };
      return { ...x, latestMessage };
    })
  }
}
