import { RolePermission } from "./model.permissions";

export class AuthUser {
  id: number;
  firstName: string;
  lastName: string;
  memberUuid: string;
  parentCompany: string;
  companyId: number;
  companyName: string;
  companyType: string;
  email: string;
  primaryRole: string;
  roleCode: string;
  rolePermissions: RolePermission[];
  secondaryRole: string;
  phone?: string;
  disclaimerAck: boolean = false;
  disclaimerAckDate: Date;
  roles: any = []
}

export class User {
  firstName: string;
  lastName: string;
  email: string;
  roleCode: string;
  phone?: string;
  referenceType: string;
  referenceCode: string;
  inviteType: string
  isMobile: boolean
  triggerSms: boolean
}

export class NonPlatformUser {
  email: string;
  messageId: string;
}