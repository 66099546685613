import { Routes } from "@angular/router";
import {
  LayoutLoginComponent,
  LoginComponent,
  PrivacyPolicyComponent,
  ResetPasswordComponent,
  TermsOfUseComponent,
  LogoutComponent,
  MobileActivationComponent,
  SSOLoginComponent,
  CommunicationsOtpComponent,
  OtpVerificationComponent,
  EpisodeSignatureCompletionComponent,
} from "../components";
import { loginGuard } from "../guards";
import { COMMUNICATIONS_OTP } from '../constants';

export const RoutePublic: Routes = [
  {
    path: "login",
    component: LayoutLoginComponent,
    children: [{ path: "", component: LoginComponent }], canActivate: [loginGuard]
  },
  { path: "mobile-activation", component: MobileActivationComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "reset-password", component: ResetPasswordComponent, canActivate: [loginGuard] },
  { path: "sso-login", component: SSOLoginComponent },
  { path: "set-password", component: ResetPasswordComponent, canActivate: [loginGuard] },
  { path: "terms-of-use", component: TermsOfUseComponent },
  { path: "sign-request", component: EpisodeSignatureCompletionComponent },
  { path: `${COMMUNICATIONS_OTP}`, component: CommunicationsOtpComponent },
  { path: `${COMMUNICATIONS_OTP}/otp-verification`, component: OtpVerificationComponent }
];
