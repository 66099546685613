import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() { }


  ngOnInit() {
    var id = 'termly-jssdk';
    var s = 'script';
    var js, tjs = document.getElementsByTagName('script')[0];
    if (document.getElementById(id)) return;
    js = document.createElement(s); js.id = id;
    js.src = "https://app.termly.io/embed-policy.min.js";
    tjs.parentNode.insertBefore(js, tjs);
  }

}
