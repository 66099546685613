import { Injectable } from "@angular/core";
import * as moment from 'moment';

@Injectable()

export class DateUtility {

  /**
   * counts days between start and end dates
   * @param startDate 
   * @param endDate 
   * @returns number of days
   */
  getNumberOfDays(startDate, endDate = moment(new Date()).utc()) {
    startDate = moment(startDate).utc();
    return moment(endDate).diff(moment(startDate), 'days') || 0;
  };

  /**
   * 
   * @param date 
   * @param format 
   * @returns formatted date
   */
  getFormattedDate(date: Date | string, format = 'MM/DD/YYYY') {
    return moment(date).format(format)
  }

  /**
   * 
   * @param date 
   * @returns year
   */
  getYearFromDate(date: Date | string) {
    return moment(date).format('YYYY')
  }

  /**
   * @param date
   * @returns date with time
   */
  getDateWithTime(date: Date | string, format = 'MM/DD/YYYY') {
    return moment(date).format(`${format} h:mm:ss A`);
  }
}