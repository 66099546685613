import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute
} from "@angular/router";
import { APICONSTANTS, ROLE_CODES } from "../constants";
import { AuthService } from '../services';
import { Storage } from "../utils";

@Injectable()
export class loginGuard implements CanActivate {
    constructor(
        private router: Router,
        private auth: AuthService,
        private route: ActivatedRoute,
        private _storage: Storage
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.auth.isAuthenticated()) {
            // let return_url = this.route.snapshot.queryParams["returnUrl"] || ["/dashboard"];
            const loggedInUser = this._storage.get('local', 'loggedInUser');
            loggedInUser.user.roleCode === ROLE_CODES.REPORTING ? this.router.navigate([APICONSTANTS.REPORT]) : this.router.navigate([APICONSTANTS.DASHBOARD]);
            return false;
        } else {
        }
        return true;
    }
}