import { Injectable } from "@angular/core";

@Injectable()

export class TitleCase {
    public toTitleCase(name: string) {
        return name.replace(
            /\w\S*/g,
            function (txt) {
                return typeof name === 'string' ? txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() : name;
            }
        );
    }
}