import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import { PasswordValidation } from '../../directives/directive.password-match';
import { ChangePassword } from '../../models';
import * as HeaderBreadCrumbActions from '../../action';
import { MaskConstant, MessageConstants, PhoneFormat, MFA_STATUS_UPDATED, RestrictSpace, US_STATES, ValidEmail, UPDATE_FAILED } from '../../constants';
import { AuthService, ClientService, MemberService, NetworkService, PlatformService, PurchaserService, RolePermissionsService, ToasterService, UserService } from '../../services';
import { FormatPhoneNumber, Storage } from '../../utils';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  loading = false;
  user: any;
  userProfile: any;
  changePasswordForm: FormGroup;
  platformInformationForm: FormGroup;
  submit_enabled: boolean = false;
  submitted: boolean = false;
  showModal = false;
  usStates = US_STATES;
  showIdSection = false;
  episodeDetail = [];
  triggerSms = false;
  isEdit = false;
  phone: any;
  isMobile = false;
  roleToSwitch: any;
  roleChange = '';
  phoneMask: any[] = MaskConstant.PHONE;
  episodes = [];
  errorMessage = '';
  providerLocationList = [];
  isNPIEdit = false;
  showNPI = false;
  npi: any;
  roleInfos = []
  platformInformation: any = {}
  backupPlatformInformation: any = {}
  clientInfo: any = {}
  purchaserInfo: any = {}
  networkInfo: any = {}
  editPlatform: boolean = false
  multiFactorForm: FormGroup;
  isSaving = {
    phone: false
  }

  @ViewChild('changePasswordModal', { static: true }) public changePasswordModal;

  constructor(
    private userService: UserService,
    private store: Store<{ bread_crumbs: any }>,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private authService: AuthService,
    private memberService: MemberService,
    private roleService: RolePermissionsService,
    private clientService: ClientService,
    private purchaserService: PurchaserService,
    private platformService: PlatformService,
    private networkService: NetworkService,
    private _storage: Storage,
    private _formatPhone: FormatPhoneNumber,
    private _messageConstants: MessageConstants
  ) {
    this.setPlatformInfoForm();
  }

  ngOnInit() {
    let user = this._storage.get('local', 'loggedInUser', 'user');
    this.user = user;
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'My Profile', path: '/my-profile' }));
    this.setChangePasswordForm();
    this.getMyProfile();
    if (this.user.roleCode == 'PR')
      this.showProvider();
  }

  setChangePasswordForm() {
    this.errorMessage = '';
    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required]],
        confirmPassword: ['', Validators.required],
        password: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/)]]
      },
      { validator: PasswordValidation.MatchPassword }
    );
  }

  setPlatformInfoForm() {
    this.platformInformationForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      identifier: ['', Validators.required],
      npi: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      tin: ['', [Validators.required, Validators.pattern(/^[0-9]{9}$/)]],
      email: ['', [Validators.required, Validators.pattern(ValidEmail)]],
      phone: ['', [Validators.required, Validators.pattern(PhoneFormat)]],
      addressLine1: [null, Validators.pattern(RestrictSpace)],
      addressLine2: [null],
      city: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      state: ['', [Validators.required]],
      zip: [null, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
      zip4: [null, [Validators.pattern(/^[0-9]{4}$/)]],
      contactName: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      contactEmail: ['', [Validators.required, Validators.pattern(ValidEmail)]],
      contactPhone: ['', [Validators.required, Validators.pattern(PhoneFormat)]],
    });
  }

  getMyProfile() {
    this.userService.getUserById(this.user.id)
      .then(res => {
        this.userProfile = res;
        this.setMultiFactorForm();
        this.userProfile.phone = this._formatPhone.phoneNumber(this.userProfile.phone);
        !res.isMobile && this.multiFactorForm.controls.enableMfaSms.disable();
        this.multiFactorForm.patchValue({
          enableMfaEmail: this.userProfile.userSetting.enableMfaEmail,
          enableMfaSms: this.userProfile.userSetting.enableMfaSms,
        })
        if (this.userProfile.roles.find(r => r.roleCode == 'PR'))
          this.showNPI = true;
        res.roles.forEach(async role => {
          await this.getRoleInfo(role)
        })
        if (res.referenceType.toLowerCase() == 'platform')
          this.getPlatformInformation();
        else if (res.referenceType.toLowerCase() == 'client')
          this.getClientInformation();
        else if (res.referenceType.toLowerCase() == 'purchaser')
          this.getPurchaserInformation();
        else
          this.getNetworkInformation();
        // if (this.userProfile.memberUuid) {
        //   this.memberService.getMemberById(this.userProfile.memberUuid)
        //     .subscribe(res => {
        //       if (res) {
        //         this.userProfile.planCode = res.memberPlan.clientProgramCode
        //         this.userProfile.dob = res.dob;
        //         this.userProfile.subscriberId = res.memberPlan.subscriberId;
        //         this.userProfile.memberName = (res.lastname && res.middlename) ? (res.firstname + ' ' + res.middlename + ' ' + res.lastname) : (res.lastname) ? (res.firstname + ' ' + res.lastname) : res.firstname;

        //       }
        //     })
        // }
      }, (error) => {
        this.toaster.showError(error);
      });
  }

  getRoleInfo(role) {
    this.roleService.getRoleByCode(role.roleCode)
      .subscribe((res) => {
        this.roleInfos.push(JSON.parse(JSON.stringify(res)))
      }, (error) => {
        console.log("Error getting platform information", error);
        this.toaster.showError(error);
      })
  }

  getPlatformInformation() {
    this.platformService.getById(this.user.referenceCode)
      .subscribe((res) => {
        this.platformInformation = JSON.parse(JSON.stringify(res))
        this.backupPlatformInformation = JSON.parse(JSON.stringify(res))
      }, (error) => {
        console.log("Error getting platform information", error);
        this.toaster.showError(error);
      })
  }

  getClientInformation() {
    this.clientService.getById(this.user.referenceCode)
      .subscribe((res) => {
        this.clientInfo = JSON.parse(JSON.stringify(res))
      }, (error) => {
        console.log("Error getting client information", error);
        this.toaster.showError(error);
      })
  }

  getPurchaserInformation() {
    this.purchaserService.getById(this.user.referenceCode)
      .subscribe((res) => {
        this.purchaserInfo = JSON.parse(JSON.stringify(res))
      }, (error) => {
        console.log("Error getting purchaser information", error);
        this.toaster.showError(error);
      })
  }

  getNetworkInformation() {
    this.networkService.getByCode(this.user.referenceCode)
      .subscribe((res) => {
        this.networkInfo = JSON.parse(JSON.stringify(res))
      }, (error) => {
        console.log("Error getting network information", error);
        this.toaster.showError(error);
      })
  }

  changePassword() {
    this.submitted = true;
    this.errorMessage = '';
    if (this.changePasswordForm.valid) {
      this.submit_enabled = true;
      let model: ChangePassword = {
        oldPassword: this.changePasswordForm.value.oldPassword,
        email: this.user.email,
        newPassword: this.changePasswordForm.value.password,
        userId: this.user.id
      };
      this.authService
        .changePassword(model)
        .pipe(finalize(() => { this.submit_enabled = false; }))
        .subscribe(_ => {
          this.toaster.displaySuccess("Password Changed Successfully. Please Login Again.")
          setTimeout(() => {
            this.authService.logout();
          }, 1000);
          this.authService.logout();
        }, error => {
          this.toaster.showError(error);
          this.errorMessage = error.error.message
          console.log(error);
        });
    }
  }

  showchangePassword() {
    this.submitted = false;
    this.showModal = !this.showModal;
    this.setChangePasswordForm();
  }
  public captureScreen() {
    window.print();
  }

  updateTriggerSMS(triggerSms) {
    this.userService
      .updateTriggerSms(this.user.id, triggerSms)
      .pipe(finalize(() => { }))
      .subscribe(res => {
        this.userProfile.triggerSMS = res.triggerSMS;
        this.toaster.displaySuccess(res.triggerSMS ? 'SMS alert turned ON' : 'SMS alert turned OFF');
      }, (error) => {
        this.toaster.showError(error)
      });
  }

  editPhone() {
    this.isEdit = true;
    this.phone = this.userProfile.phone;
    this.isMobile = this.userProfile.phone ? this.userProfile.isMobile : false;
  }

  savePhone() {
    this.isSaving.phone = true;
    if (!this.phone) {
      return true;
    }
    let model = {
      userId: this.userProfile.id,
      uuid: this.userProfile.memberUuid,
      phone: this.phone.replace(/\D/g, ''),
      isMobile: this.isMobile
    };
    this.userService
      .changePhone(model)
      .pipe(finalize(() => {
        this.isSaving.phone = false;
      }))
      .subscribe(
        res => {
          this.userProfile.phone = this.phone;
          this.userProfile.isMobile = this.isMobile;
          this.userProfile.userSetting.triggerSms = res.data.triggerSMS;
          this.triggerSms = res.data.triggerSMS;
          this.toaster.displaySuccess('Phone Updated Successfully.');
          if (this.isMobile) {
            this.multiFactorForm.controls.enableMfaSms.enable();
          } else {
            if (this.multiFactorForm.value.enableMfaSms) {
              this.multiFactorForm.controls.enableMfaSms.setValue(false);
              this.updateMFAPreference('triggerOtpViaSms', false);
            }
            this.multiFactorForm.controls.enableMfaSms.disable();
          }
          this.cancelPhone();

        }, (error) => {
          console.log(error);
          this.toaster.showError(error);
          this.cancelPhone();
        }
      );
  }

  cancelPhone() {
    this.phone = null;
    this.isMobile = false;
    this.isEdit = false;
  }

  checkPhoneValue(event) {
    if (!event) {
      this.isMobile = false;
    }
  }

  switchRole(user, e) {
    e.preventDefault();
    this.roleToSwitch = user;
  }

  cancelRoleSwitch() {
    this.roleToSwitch = null;
  }

  showProvider() {
    this.providerLocationList = [];
    this.userService
      .getProvidersByUserId(this.user.id)
      .pipe(finalize(() => { }))
      .subscribe(res => {
        if (res.length > 0) {
          this.providerLocationList = res;
        }
        else
          this.providerLocationList = [];
      }, (error) => {
        this.toaster.showError(error);
      });
  }

  editNPI() {
    this.isNPIEdit = true;
    this.npi = this.userProfile.npi;
  }

  cancelNPI() {
    this.npi = null;
    this.isNPIEdit = false;
  }

  updateNPI() {
    if (!this.npi) {
      return true;
    }
    this.submit_enabled = true;
    let model = {
      userId: this.userProfile.id,
      npi: this.npi
    };
    this.userService
      .updateNPI(model)
      .pipe(finalize(() => { this.submit_enabled = false; }))
      .subscribe(res => {
        this.userProfile.npi = this.npi;
        this.toaster.displaySuccess('NPI Updated Successfully. Please Login Again.');
        this.cancelNPI();
        setTimeout(() => {
          this.authService.logout();
        }, 1000);
      }, error => {
        console.log("Error updating NPI", error);
        this.toaster.showError(error);
      }
      );
  }

  editPlatformInfo() {
    this.platformInformationForm.patchValue(this.platformInformation)
  }

  updatePlatformInfo() {
    this.submitted = true
    if (this.platformInformationForm.valid) {
      let body = this.platformInformationForm.value;
      if (!body.zip4) body.zip4 = null
      this.submit_enabled = true
      this.platformService.update(this.user.referenceCode, body)
        .pipe(finalize(() => { this.submit_enabled = false }))
        .subscribe((res: any) => {
          if (res) {
            this.platformInformation = JSON.parse(JSON.stringify(res));
            this.backupPlatformInformation = JSON.parse(JSON.stringify(res));
            this.toaster.displaySuccess("Platform Information Updated Successfully.")
            this.editPlatform = false
            this.submitted = false
          }
        }, (error) => {
          console.log("Error updating platform information", error);
          this.toaster.showError(error);
        })
    }
  }

  denyUpdatePlatformInfo() {
    this.platformInformation = JSON.parse(JSON.stringify(this.backupPlatformInformation));
    this.submitted = false
  }

  setMultiFactorForm() {
    this.multiFactorForm = this.formBuilder.group({
      enableMfaEmail: [{ value: false, disabled: this.userProfile.isMfaEnabled ? true : false }],
      enableMfaSms: [{ value: false, disabled: false }],
    });
  }

  updateMFAPreference(type: string, isChecked: boolean) {
    const userSetting = this.multiFactorForm.getRawValue();
    const body = { userId: this.userProfile.id, userSetting };
    this.userService.updateMFAPreference(body).subscribe((response: any) => {
      if (response && response.success) {
        const otpType = type === 'triggerOtpViaSms' ? 'SMS' : 'Email';
        const otpState = isChecked ? 'on' : 'off';
        const otpStatusMessage = this._messageConstants.getMessage(MFA_STATUS_UPDATED, otpState, otpType);
        this.toaster.displaySuccess(otpStatusMessage.value);
      } else this.toaster.displayError(UPDATE_FAILED);
    }, (error) => {
      console.log('Error updating MFA', error);
      this.toaster.showError(error);
    });
  }
}