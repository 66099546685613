import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AccessType, Storage } from "../../utils";
import * as HeaderBreadCrumbActions from '../../action';
import { Network } from "../../models";
import { MarketService, NetworkService, ToasterService } from "../../services";
import { TrimFields, ADD_SUCCESS, UPDATE_SUCCESS, DELETE_SUCCESS, DELETE_WARNING, MessageConstants } from "../../constants";

@Component({
    selector: 'app-network',
    templateUrl: './network.component.html'
})

export class NetworkComponent implements OnInit {

    user: any;
    networks: Network[] = [];
    query = {
        keyword: '',
        page: 1,
        limit: 10,
        sortType: 'desc',
        sortBy: 'createdAt',
    }
    result = {
        networks: true,
        searchedNetwork: true
    }
    type: string = '';
    dataToDelete: any = {}
    totalCount: number = 0;
    loading: boolean = false;
    reverse: boolean = true;
    submitted: boolean = false;
    submit_enabled: boolean = false;
    message: string;
    networkForm: FormGroup
    marketForm: FormGroup
    filterForm: FormGroup
    searchModelChanged: Subject<string> = new Subject<string>();
    deletedMarket: any;
    @ViewChild('networkModal', { static: true }) public networkModal
    @ViewChild('marketModal', { static: true }) public marketModal

    constructor(private store: Store<{ bread_crumbs: any }>,
        private _storage: Storage,
        public utilityAccess: AccessType,
        private networkService: NetworkService,
        private marketService: MarketService,
        private formBuilder: FormBuilder,
        private toastr: ToasterService,
        private _messageConstants: MessageConstants) {
        this.setNetworkForm();
        this.setMarketForm();
        this.setFilterForm();
        this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
            this.query.keyword = keyword ? keyword.trim() : '';
            this.query.page = 1;
            this.getNetworks();
        });
    }

    ngOnInit() {
        this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Networks', path: '/network' }));
        this.user = this._storage.get('local', 'loggedInUser', 'user');
        this.getNetworks();
    }

    setNullForEmpty(formName) {
        Object.keys(formName.value).forEach(k => {
            formName.controls[k].setValue(formName.value[k] || typeof formName.value[k] == 'boolean' ? formName.value[k] : null)
        })
    }

    setNetworkForm() {
        this.networkForm = this.formBuilder.group({
            name: ['', Validators.required],
            code: ['', Validators.required],
            id: [''],
            timelyFiling: [null]
        })
    }

    setMarketForm() {
        this.marketForm = this.formBuilder.group({
            name: ['', Validators.required],
            code: ['', Validators.required],
            id: [''],
            networkCode: ['', Validators.required],
        })
    }

    setFilterForm() {
        this.filterForm = this.formBuilder.group({
        })
    }

    getNetworks() {
        this.loading = true;
        this.result.networks = true;
        this.result.searchedNetwork = true;
        this.networkService.getAll(this.query)
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: any) => {
                if (response && response.count > 0) {
                    this.totalCount = response.count;
                    this.networks = response.rows
                }
                else {
                    this.networks = [];
                    this.query.keyword.length > 0 ? (this.result.searchedNetwork = false) : (this.result.networks = false);
                }
            }, (error) => {
                console.log('Error fetching networks', error);
            })
    }

    searchByKeyword(event) {
        this.searchModelChanged.next(event);
    }

    pageChanged(event) {
        this.query.page = event;
        this.getNetworks();
    }

    setOrder(value: string) {
        if (this.query.sortBy === value) {
            this.reverse = !this.reverse;
            this.query.sortType = this.query.sortType === "desc" ? "asc" : "desc";
        }
        else {
            this.reverse = true;
            this.query.sortType = "desc";
        }
        this.query.sortBy = value;
        this.getNetworks();
    }

    getMarkets(network) {
        if (!network.markets) {
            this.marketService.getAll({ networkCode: network.code })
                .subscribe((res: any) => {
                    network.markets = res.rows
                }, (error) => {
                    console.log("Error getting market by network code", error);
                })
        }
    }

    openNetworkModal(network?) {
        if (this.utilityAccess.searchAccess('NTW', 'isEditable')) {
            if (network)
                this.networkForm.patchValue(network);
            this.networkModal.show();
        }
    }

    closeNetworkModal() {
        this.networkModal.hide();
        this.networkForm.reset();
        this.submitted = false;
    }

    submitNetwork() {
        this.submitted = true;
        if (this.networkForm.valid) {
            this.setNullForEmpty(this.networkForm)
            this.submit_enabled = true;
            if (this.networkForm.value.id) this.update();
            else this.save();
        }
    }

    save() {
        this.networkService.create(this.networkForm.getRawValue())
            .pipe(finalize(() => { this.submit_enabled = false; }))
            .subscribe(res => {
                if (res) {
                    this.query.page = 1;
                    this.getNetworks();
                    this.toastr.displaySuccess(ADD_SUCCESS);
                    this.closeNetworkModal();
                }
            }, (error) => {
                console.log("Error saving Network", error);
                this.toastr.showError(error);
            });
    }

    update() {
        this.networkService.update(this.networkForm.getRawValue())
            .pipe(finalize(() => { this.submit_enabled = false; }))
            .subscribe(res => {
                if (res) {
                    let index = this.networks.findIndex(x => x.code == res.code)
                    if (index > -1)
                        this.networks[index] = res
                    this.toastr.displaySuccess(UPDATE_SUCCESS);
                    this.closeNetworkModal();
                }
            }, (error) => {
                console.log("Error updating Network", error);
                this.toastr.showError(error);
            });
    }

    onDelete(data, type) {
        if (this.utilityAccess.searchAccess('NTW', 'isEditable')) {
            this.type = type
            this.dataToDelete = JSON.parse(JSON.stringify(data))
            const deleteWarningMessage = this._messageConstants.getMessage(DELETE_WARNING, data.name)
            this.message = deleteWarningMessage.value
            this.deletedMarket = type === 'market' ? data : null
        }
    }

    confirmDelete(code) {
        if (code) {
            if (this.type === 'network') {
                this.deleteNetwork(code)
            }
            if (this.type === 'market') {
                this.deleteMarket(code)
            }
        }
        else {
            this.dataToDelete.code = null;
            this.deletedMarket = null;
        }
    }

    deleteNetwork(code) {
        this.networkService.delete(code, 'code')
            .subscribe((response: any) => {
                if (response) {
                    const index = this.networks.findIndex(x => x.code === code);
                    if (index > -1) {
                        this.toastr.displaySuccess(DELETE_SUCCESS);
                        this.getNetworks()
                    }
                    this.dataToDelete = {};
                }
            }, (error) => {
                console.log("Error deleting network", error);
                this.toastr.showError(error);
                this.dataToDelete = {};
            })
    }

    openMarketModal(networkCode, market?) {
        if (this.utilityAccess.searchAccess('NTW', 'isEditable')) {
            if (market)
                this.marketForm.patchValue(market);
            else
                this.marketForm.controls.networkCode.setValue(networkCode)
            this.marketModal.show();
        }
    }

    closeMarketModal() {
        this.marketModal.hide();
        this.marketForm.reset();
        this.submitted = false;
    }

    submitMarket() {
        this.submitted = true;
        if (this.marketForm.valid) {
            TrimFields.Market.forEach(element => {
                this.marketForm.value[element] = this.marketForm.value[element] ? this.marketForm.value[element].trim() : this.marketForm.value[element];
            });
            this.setNullForEmpty(this.marketForm)
            this.submit_enabled = true;
            if (this.marketForm.value.id) this.updateMarket();
            else this.saveMarket();
        }
    }

    saveMarket() {
        this.marketService.create(this.marketForm.getRawValue())
            .pipe(finalize(() => { this.submit_enabled = false; }))
            .subscribe(res => {
                if (res) {
                    let index = this.networks.findIndex(x => x.code == res.networkCode)
                    if (index > -1) {
                        if (this.networks[index].collapsed)
                            this.networks[index].markets.unshift(res);
                        else {
                            this.networks[index].markets ? delete this.networks[index].markets : ''
                            this.networks[index].collapsed = true
                            this.getMarkets(this.networks[index])
                        }
                    }
                    this.toastr.displaySuccess(ADD_SUCCESS);
                    this.closeMarketModal();
                }
            }, (error) => {
                console.log("Error saving Market", error);
                this.toastr.showError(error);
            });
    }

    updateMarket() {
        this.marketService.update(this.marketForm.getRawValue(), 'id')
            .pipe(finalize(() => { this.submit_enabled = false; }))
            .subscribe(res => {
                if (res) {
                    let index = this.networks.findIndex(x => x.code == res.networkCode)
                    if (index > -1) {
                        if (this.networks[index].markets && this.networks[index].markets.length > 0) {
                            let index2 = this.networks[index].markets.findIndex(y => y.code == res.code)
                            if (index2 > -1)
                                this.networks[index].markets[index2] = res
                        }
                    }
                    this.toastr.displaySuccess(UPDATE_SUCCESS);
                    this.closeMarketModal();
                }
            }, (error) => {
                console.log("Error updating Market", error);
                this.toastr.showError(error);
            });
    }

    removeSearchKeyword() {
        this.query.keyword = ''
        this.searchByKeyword('')
    }

    deleteMarket(code) {
        this.marketService.delete(code, 'code')
            .subscribe(res => {
                if (res) {
                    const index = this.networks.findIndex(x => x.code == this.deletedMarket.networkCode)
                    if (index > -1) {
                        if (this.networks[index].markets && this.networks[index].markets.length > 0) {
                            const marketIndex = this.networks[index].markets.findIndex(y => y.code == code)
                            if (marketIndex > -1) {
                                this.networks[index].markets.splice(marketIndex, 1)
                            }
                        }
                    }
                    this.dataToDelete = {};
                    this.deletedMarket = null
                    this.toastr.displaySuccess(DELETE_SUCCESS);
                }
            }, (error) => {
                console.log("Error deleting Market", error);
                this.toastr.showError(error);
                this.dataToDelete = {};
                this.deletedMarket = null;
            });
    }
}