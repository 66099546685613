<div [ngClass]="{'second-level': optionId}" *ngIf="question && question.id">
  <div class="d-flex justify-content-start align-items-start">
    <div class="icon-block">
      <i class="icofont-question-circle icofont-lg skin-text-primary-dark"></i>
    </div>
    <div class="d-flex justify-content-between w-100 align-items-start">
      <span class="pl-2 pr-4 w-75">
        <p class="font-weight-400">
          {{ question.questions.question }}
        </p>
        <!-- <p *ngIf="question.questions.questionHelp">
          {{question.questions.questionHelp}}
        </p> -->
        <p
          *ngIf="
            question.status == 'COMPLETED' && question.questions.questionTypes.code == 'TEXTFIELD'
          ">
          <strong>Answer :</strong>
          <span
            [innerHtml]="
              question.answer && question.answer.length > 200 && question.showMore
                ? (question.answer | truncate : ['200', ' ...'])
                : question.answer
            "></span>
          &nbsp;&nbsp;<a
            (click)="question.showMore = !question.showMore"
            *ngIf="question.answer.length > 200">
            <span class="z-depth-0 badge mdb-color lighten-1 badge-pill">
              <i class="m-0 icofont-{{ question.showMore ? 'plus' : 'minus' }}"></i>
            </span>
          </a>
        </p>
      </span>
      <div class="action-item position-relative">
        <div
          *ngIf="question.status && question.isActivated"
          class="chip chip-sm mb-0 ml-1 alert alert-info"
          [ngClass]="question.status ? question.status.replace(' ', '').toLowerCase() : ''">
          <span class="text-sm status-label">
            {{ question.status ? question.status?.toLowerCase() : '' }}
            {{ question.status == 'DUE' ? question.escalationDays : '' }}</span
          >
        </div>
        <span
          *ngIf="
            question.status != 'COMPLETED' &&
            !(
              question.episodeStatus == 'CLOSED' ||
              question.episodeStatus == 'CANCELLED' ||
              question.episodeStatus == 'COMPLETED'
            ) &&
            question.milestoneStatus !== 'IN PROGRESS' &&
            !question.triggerDatePassed
          ">
          <i
            class="icofont-ui-edit icofont-sm mr-2"
            #actionBtn
            (click)="editTaskHandler(question)"></i>
          <i
            class="icofont-ui-delete icofont-sm mr-2"
            #actionBtn
            (click)="deleteTaskHandler(question)"></i>
        </span>
      </div>
    </div>
  </div>
  <div
    class="list-content-menu"
    [ngClass]="{'sub-level': optionId}"
    [hidden]="question && taskDragged">
    <div
      class="list-content-item"
      *ngFor="let option of question.questions.questionOptions | orderBy : 'id'">
      <div
        class="d-flex justify-content-between align-items-start list-content-item-option pt-0 pb-0"
        [ngClass]="{completed: question.status == 'COMPLETED' && option.status}">
        <p>
          <i
            class="far fa-square mr-2"
            *ngIf="
              question.questions.questionTypes.code == 'CHECKBOX' &&
              (question.status != 'COMPLETED' || !option.status)
            "></i>
          <i
            class="far fa-circle mr-2"
            *ngIf="
              question.questions.questionTypes.code == 'RADIO' &&
              (question.status != 'COMPLETED' || !option.status)
            "></i>
          <i
            class="far fa-check-square mr-2 green-text"
            *ngIf="
              question.questions.questionTypes.code == 'CHECKBOX' &&
              question.status == 'COMPLETED' &&
              option.status
            "></i>
          <i
            class="far fa-check-circle mr-2 green-text"
            *ngIf="
              question.questions.questionTypes.code == 'RADIO' &&
              question.status == 'COMPLETED' &&
              option.status
            "></i>
          {{ option.optionValue }}
        </p>
        <a
          class="add-action"
          #actionBtn
          (click)="addActionHandler(option.id, question.questions.uuid)"
          *ngIf="
            question.status != 'COMPLETED' &&
            !(
              question.episodeStatus == 'CLOSED' ||
              question.episodeStatus == 'CANCELLED' ||
              question.episodeStatus == 'COMPLETED'
            ) &&
            question.milestoneStatus !== 'IN PROGRESS' &&
            !question.triggerDatePassed
          "
          >Add action</a
        >
      </div>
      <div
        class="list-content p-2"
        *ngIf="
          option.taskMessageUuid ||
          option.taskQuesUuid ||
          option.taskTodoUuid ||
          option.taskSignatureUuid ||
          option.taskQnrsUuid
        ">
        <div class="p-2 flex-row justify-content-start align-items-start dependent-task">
          <app-episode-todo
            *ngIf="option.taskTodoUuid"
            [optionId]="option.id"
            [dependent_task]="dependentTask"
            [days_difference]="days_difference"
            [user]="user"
            (btns)="receiveBtns($event, 'todo')"
            [taskTodoUuid]="option.taskTodoUuid"
            (editTask)="editTaskTodo($event)"
            (deletedTask)="deleteTask($event)">
          </app-episode-todo>
          <app-episode-signature
            *ngIf="option.taskSignatureUuid"
            [optionId]="option.id"
            [dependent_task]="dependentTask"
            [days_difference]="days_difference"
            [user]="user"
            (btns)="receiveBtns($event, 'signature')"
            [taskSignatureUuid]="option.taskSignatureUuid"
            (editTask)="editTaskTodo($event)"
            (deletedTask)="deleteTask($event)">
          </app-episode-signature>
          <app-episode-message
            *ngIf="option.taskMessageUuid"
            [optionId]="option.id"
            [dependent_task]="dependentTask"
            [days_difference]="days_difference"
            (btns)="receiveBtns($event, 'message')"
            [taskMessageUuid]="option.taskMessageUuid"
            [user]="user"
            (editTask)="editTaskMessage($event)"
            (deletedTask)="deleteTask($event)"></app-episode-message>
          <app-episode-question
            *ngIf="option.taskQuesUuid"
            [optionId]="option.id"
            [dependent_task]="dependentTask"
            [days_difference]="days_difference"
            (btns)="receiveBtns($event, 'question')"
            [taskQuesUuid]="option.taskQuesUuid"
            [user]="user"
            (editTask)="editTaskHandler($event)"
            (addAction)="addActionHandler($event)"
            (deletedTask)="deleteTask($event)"></app-episode-question>
          <app-episode-questionnaire
            *ngIf="option.taskQnrsUuid"
            [optionId]="option.id"
            [user]="user"
            (btns)="receiveBtns($event, 'questionnaire')"
            [dependent_task]="dependentTask"
            [days_difference]="days_difference"
            [taskQnrsUuid]="option.taskQnrsUuid"
            (editTask)="editTaskHandler($event)"
            (addAction)="addActionHandler($event)"
            (deletedTask)="deleteTask($event)"></app-episode-questionnaire>
        </div>
      </div>
    </div>
  </div>
</div>

<app-milestone-topic-delete
  [deleteData]="taskToBeDelete"
  (deletedData)="deleteTask($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>
