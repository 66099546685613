import { Injectable } from "@angular/core";
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
import { APICONSTANTS } from "../constants";

@Injectable({
    providedIn: "root"
})

export class ClaimsDashboardService {
    constructor(private http: HttpClientService) { }

    getClaimsState(body) {
        return this.http.post(`${APICONSTANTS.CLAIMS_REPORT}/claim-states`, body).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getTotalClaims() {
        return this.http.get(`${APICONSTANTS.CLAIMS_REPORT}/claim-status-counts`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getClaimsPriority() {
        return this.http.get(`${APICONSTANTS.CLAIMS_REPORT}/claim-priorities`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getEpisodeClaimsNotReceived() {
        return this.http.get(`${APICONSTANTS.CLAIMS_REPORT}/episodes/priorities`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getEpisodeClaims(body) {
        return this.http.post(`${APICONSTANTS.CLAIMS_REPORT}/episodes/claim-not-received`, body).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    updateFollowUpStatus(claimXrefHolistaId, isFollowUp) {
        return this.http.patch(`${APICONSTANTS.CLAIMS}/${claimXrefHolistaId}/follow-up/${isFollowUp}`, {})
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getOutgoingClaimDetails(episodeId, fundingRequestUuid) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/${episodeId}/outgoing/${fundingRequestUuid}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getPayerDetails(identificationCode) {
        return this.http.get(`${APICONSTANTS.COMPANY}/payers/${identificationCode}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    fetchPayerByClaimId(claimId) {
        return this.http.get(`${APICONSTANTS.CLAIMS}/payers/${claimId}`)
            .pipe(map((res: any) => {
                return res.data;
            })
            );
    }
}