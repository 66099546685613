import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FUNDING_SUMMARY_HEADERS, FUNDING_REPORT_HEADERS, REPORT_LABELS } from '../../../constants';
import { ActivityLogService, ExcelService } from '../../../services';
import { CommunicationModuleOptions, ExportData } from '../../../models';
import { CurrencyFormat, TitleCase } from '../../../utils';

@Component({
  selector: 'app-funding-report',
  templateUrl: './funding-report.component.html',
  styleUrls: ['./funding-report.component.scss']
})
export class FundingReportComponent implements OnInit {
  @Input() public reportData: any;
  reportContainer: object;
  headers: any[] = [];
  summaryHeaders: any[] = [];
  data: any[] = [];
  summaryData: string[] = [];
  hasFundingRequest: boolean = false;
  showMessageModal: boolean = false;
  moduleOptions: CommunicationModuleOptions;

  constructor(
    private _excelService: ExcelService,
    private _currencyFormat: CurrencyFormat,
    private _titleCase: TitleCase,
    private _activityLogService: ActivityLogService
  ) { }

  ngOnInit() {
    this.getHeaders();
    this.getFundingData();
    this.getHeaders(true); //true for summaryHeaders
    this.getSummaryData();
  }

  /**
   * changes placement for an element of array
   * @param arr 
   * @param checkValue 
   * @param removeValue 
   * @returns 
   */
  replacementOfElementInArray(arr: Array<object>, checkValue: string, removeValue: string) {
    const element = arr.find(x => x['value'] === removeValue);
    const removeIndex = arr.findIndex(x => x['value'] === removeValue);
    arr.splice(removeIndex, 1);

    const insertIndex = arr.findIndex(x => x['value'] === checkValue) + 1;
    arr.splice(insertIndex, 0, element);
    return arr;
  }

  /**
   * provide headers depending upon table type
   * @param isSummaryHeaders 
   */
  getHeaders(isSummaryHeaders?: boolean) {
    const requiredHeaders = isSummaryHeaders ? FUNDING_SUMMARY_HEADERS : FUNDING_REPORT_HEADERS;
    let additionalHeaders = this.reportData.selectedOptions;
    const holistaFeeIndex = additionalHeaders.findIndex(({ value }) => value === 'holistaFee');
    const clientFeeIndex = additionalHeaders.findIndex(({ value }) => value === 'clientFee');
    const updatedHeaders = [];
    const updatedSummaryHeaders = [];

    additionalHeaders.forEach(header => {
      isSummaryHeaders ? (header.value !== 'fundingReqName' && updatedSummaryHeaders.push(header)) :
        (header.value !== 'holistaFee' && header.value !== 'clientFee') && updatedHeaders.push(header)
    })
    !isSummaryHeaders && (
      holistaFeeIndex > -1 && updatedHeaders.push({ ...additionalHeaders[holistaFeeIndex], label: 'Holista Fee' }),
      clientFeeIndex > -1 && updatedHeaders.push({ ...additionalHeaders[clientFeeIndex], label: 'Client Fee' }),
      additionalHeaders = [...updatedHeaders]
    );
    isSummaryHeaders ? this.summaryHeaders = [...requiredHeaders, ...updatedSummaryHeaders] : this.headers = [...requiredHeaders, ...additionalHeaders];
    this.hasFundingRequest = this.headers.find(({ value }) => value === 'fundingReqName') ? true : false;
    !isSummaryHeaders && this.hasFundingRequest && this.replacementOfElementInArray(this.headers, 'episodeType', 'fundingReqName');
  }

  /**
   * episodes summary data
   */
  getSummaryData() {
    this.summaryData = [];

    FUNDING_SUMMARY_HEADERS.forEach(({ value }) => {
      const data = value !== 'episodes' ? this._currencyFormat.format(this.reportData.reportContainer['summary'][value]) : this.reportData.reportContainer['summary'][value]
      this.summaryData.push(data)
    });
    this.reportData.selectedOptions.length !== 0 && this.reportData.selectedOptions.forEach(({ value }) => {
      value !== 'fundingReqName' && this.summaryData.push(this._currencyFormat.format(this.reportData.reportContainer['summary'][value]))
    })
  }

  /**
   * individual episode data
   */
  getFundingData() {
    this.data = [];
    let properties: string[] = [];
    let episodes: object[] = [];

    this.headers.forEach(({ value }) => properties.push(value));
    this.reportData.reportContainer['episodeFundings'] = this.reportData.reportContainer['episodeFundings'].map((x) => {
      x.fundRequested = this._currencyFormat.format(x.fundRequested);
      x.fundReceived = this._currencyFormat.format(x.fundReceived);
      x.holistaFee = this._currencyFormat.format(x.holistaFee);
      x.clientFee = this._currencyFormat.format(x.clientFee);
      x.paidAmount = this._currencyFormat.format(x.paidAmount);
      x.patientPayAmount = this._currencyFormat.format(x.patientPayAmount);
      x.hraHsaPaidAmount = x.hraHsaPaidAmount === null ? '-' : this._currencyFormat.format(x.hraHsaPaidAmount);
      x.hraHsaReceivedAmount = x.hraHsaReceivedAmount === null ? '-' : this._currencyFormat.format(x.hraHsaReceivedAmount);
      x.fundingRequestdDate = x.fundingRequestdDate ? moment(x.fundingRequestdDate).utc().format('MM/DD/YYYY') : '-';
      x.fundingReceivedDate = x.fundingReceivedDate ? moment(x.fundingReceivedDate).utc().format('MM/DD/YYYY') : '-';
      x.fundingReqName = x.fundingReqName ? this._titleCase.toTitleCase(x.fundingReqName) : '-';
      x.patientName = x.patientName ? this._titleCase.toTitleCase(x.patientName) : '-';
      return x;
    })
    this.reportData.reportContainer['episodeFundings'].forEach(episode => episodes.push(episode));
    episodes.forEach(episode => {
      let data = [];
      properties.forEach(property => data.push(episode[property]))
      this.data.push(data)
    })
  }

  /**
   * download excel file
   */
  async exportToCsv() {
    const exportData: ExportData[] = [{
      exportList: this.data,
      headers: this.headers.map(({ label }) => label),
      summaryData: {
        headers: this.summaryHeaders.map(({ label }) => label),
        data: this.summaryData,
        client: this.reportData.client ? this.reportData.client.label : '',
        date: this.reportData.date
      },
      excelName: 'Funding Report',
      options: { action: 'getMonthEndReport' }
    },
    ];
    this._excelService.exportExcel(exportData);
    this._activityLogService.logUserActivities('report', REPORT_LABELS.FUNDING_REPORT.toLowerCase(), 'downloaded').subscribe();
  }

  onSendEmail() {
    this.showMessageModal = true;
    this.moduleOptions = {
      module: 'report',
      subModule: REPORT_LABELS.FUNDING_REPORT.toLowerCase()
    };
  }

  closeMessageModal() {
    this.showMessageModal = false;
  }
}
