import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageConstants } from '../constants/constant.message';

@Injectable()
export class ToasterService {
  constructor(private toastrService: ToastrService,
    private _messageConstants: MessageConstants) { }

  message: any;
  defaultConfig: any = {
    timeOut: 2000
  };

  displayToaster(type: string, message: string, data?: string, title?: string, config?: any) {
    if (!config || (config.constructor === Object && Object.keys(config).length === 0))
      config = this.defaultConfig;
    this.message = this._messageConstants.getMessage(message, data, title);
    if (this.message.constructor === Object && Object.keys(this.message).length !== 0 && this.message.value) {
      if (type.toLowerCase() === 'success')
        this.toastrService.success(this.message.value, this.message.header, config);
      if (type.toLowerCase() === 'warning')
        this.toastrService.warning(this.message.value, this.message.header, config);
      if (type.toLowerCase() === 'error')
        this.toastrService.error(this.message.value, this.message.header, config);
      if (type.toLowerCase() === 'info')
        this.toastrService.info(this.message.value, this.message.header, config);
    } else {
      console.log("Toaster not working properly");
    }
  }

  displaySuccess(message: string, data?: string, title?: string, config?: any) {
    this.displayToaster('success', message, data, title, config)
  }

  displayWarning(message: string, data?: string, title?: string, config?: any) {
    this.displayToaster('warning', message, data, title, config)
  }

  displayError(message: string, data?: string, title?: string, config?: any) {
    this.displayToaster('error', message, data, title, config)
  }

  displayInfo(message: string, data?: string, title?: string, config?: any) {
    this.displayToaster('info', message, data, title, config)
  }

  showError(error) {
    if (error.data && error.data.message) this.toastrService.warning(error.data.message, 'Warning');
    if (error.error && error.error.name && error.error.name.toLowerCase() == 'client error')
      this.toastrService.warning(error.error.message, 'Warning');
    else
      this.toastrService.error(error.error.message || error.error, 'Error');
  }

}