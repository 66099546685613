<div class="d-flex justify-content-start align-items-start" *ngIf="taskTodo && taskTodo.id">
  <div class="icon-block">
    <i class="icofont-tasks icofont-2x skin-text-primary-dark"></i>
  </div>
  <mdb-checkbox
    [filledIn]="true"
    class="align-self-center"
    *ngIf="user && taskTodo.isActivated && taskTodo.assignedTo == user.id"
    [checked]="taskTodo.status && taskTodo.status !== 'DUE' ? true : false"
    (change)="taskTodo.status != 'COMPLETED' && updateTask($event, taskTodo)"
    [disabled]="
      !(days_difference >= 0) ||
      taskTodo.episodeStatus === 'CANCELLED' ||
      taskTodo.episodeStatus === 'CLOSED' ||
      taskTodo.episodeStatus === 'COMPLETED' ||
      taskTodo.status ||
      !utilityAccess.searchAccess('EM', 'isEditable', false)
    ">
  </mdb-checkbox>
  <div class="d-flex justify-content-between w-100 align-items-start">
    <span class="pr-4">
      <p class="font-weight-400">
        {{ taskTodo.name }}
      </p>
      <a class="text-break" target="_blank" href="{{ taskTodo.taskTodoLink }}">{{
        taskTodo.documentDisplayName
      }}</a>
    </span>
    <div class="d-flex align-items-center">
      <div
        *ngIf="
          taskTodo.status === 'COMPLETED' &&
          taskTodo.assignedTo == user.id &&
          !taskTodo.completionReason &&
          !showReasonOption &&
          (taskTodo.assignedToRole === 'ES' || taskTodo.assignedToRole === 'CC')
        ">
        <a
          [ngClass]="{
            isDisabled:
              taskTodo.episodeStatus == 'CANCELLED' ||
              taskTodo.episodeStatus == 'CLOSED' ||
              taskTodo.episodeStatus == 'COMPLETED'
          }"
          (click)="addReason()"
          ><i class="icofont-plus"></i> Add Reason</a
        >
      </div>
      <div
        style="width: 240px"
        *ngIf="taskTodo.completionReason || showReasonOption"
        [mdbTooltip]="
          taskTodo.completionReason &&
          (taskTodo.episodeStatus == 'CANCELLED' ||
            taskTodo.episodeStatus == 'CLOSED' ||
            taskTodo.episodeStatus == 'COMPLETED' ||
            taskTodo.assignedTo != user.id)
            ? taskTodo.completionReason
            : ''
        "
        placement="left">
        <mdb-select
          [options]="reasonList"
          placeholder="Select reason"
          [(ngModel)]="taskTodo.completionReason"
          [multiple]="false"
          [enableSelectAll]="false"
          [highlightFirst]="false"
          (selected)="selectReason($event, taskTodo)"
          [disabled]="
            taskTodo.assignedTo != user.id ||
            taskTodo.episodeStatus == 'CANCELLED' ||
            taskTodo.episodeStatus == 'CLOSED' ||
            taskTodo.episodeStatus == 'COMPLETED'
          ">
        </mdb-select>
      </div>
      <div
        *ngIf="taskTodo.status && taskTodo.isActivated"
        class="chip chip-sm mb-0 ml-3 alert alert-info"
        [ngClass]="taskTodo.status ? taskTodo.status.replace(' ', '').toLowerCase() : ''">
        <span class="text-sm status-label">
          {{ taskTodo.status ? taskTodo.status?.toLowerCase() : '' }}
          {{ taskTodo.status == 'DUE' ? taskTodo.escalationDays : '' }}</span
        >
      </div>

      <span
        class="action-item position-relative"
        *ngIf="
          taskTodo.status != 'COMPLETED' &&
          !(
            taskTodo.episodeStatus == 'CLOSED' ||
            taskTodo.episodeStatus == 'COMPLETED' ||
            taskTodo.episodeStatus == 'CANCELLED'
          ) &&
          taskTodo.milestoneStatus !== 'IN PROGRESS' &&
          !taskTodo.triggerDatePassed
        ">
        <i
          class="icofont-ui-edit icofont-sm mr-2"
          #actionBtn
          (click)="editTaskHandler(taskTodo)"></i>
        <i
          class="icofont-ui-delete icofont-sm mr-2"
          #actionBtn
          (click)="deleteTaskHandler(taskTodo)"></i>
      </span>
    </div>
  </div>

  <app-milestone-topic-delete
    [deleteData]="taskToBeDelete"
    (deletedData)="deleteTask($event)"
    (cancelDelete)="cancelDelete($event)">
  </app-milestone-topic-delete>
</div>
