export class Patient {
  nameSuffix: string
  firstname: string
  middlename: string
  lastname: string
  email: string
  phone: string
  isMobile: boolean
  companyCode: string
  gender: string
  dob: string
  socialSecurityNo: number
  addressline1: string
  addressline2: string
  city: string
  state: string
  zip: string
  notes: string
  addressCode: string
  subscriberId: string
  memberId: string
  clientProgramCode: string
  effectiveDate: string
  terminationDate: string
  clientEmployerGroupCode: string
  benefitStatusCode: string
  relationshipCode: string
  id: number
  isAdditional: boolean
}
