import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ACCOUNTS_RECEIVABLE_SUMMARY_DATE_TYPE_LIST, SUMMARY_YTD_REPORT_DATA } from '../../../../constants';
import { DateUtility, HolistaUtils } from '../../../../utils';
import { TransactionReportFilterCriteria, SummaryYTDData } from '../../../../models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportService } from '../../../../services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-summary-ytd',
  templateUrl: './summary-ytd.component.html',
  styleUrls: ['./summary-ytd.component.scss']
})
export class SummaryYtdComponent implements OnInit {
  summaryYTDReport: any = {};
  summaryYTDReportPeriodTotals: any = {};
  data = {} as SummaryYTDData;
  isCollapsed: boolean = true;
  temporaryDataStorage: any[] = [];
  parsedTransactionReportFilterData = {} as TransactionReportFilterCriteria;
  filterForm: FormGroup;
  isLoading = {
    report: false
  };
  yearList = [];
  dateTypeList = ACCOUNTS_RECEIVABLE_SUMMARY_DATE_TYPE_LIST;

  @Input() set transactionReportFilterData(data) {
    if (data) {
      this.parsedTransactionReportFilterData = this._holistaUtils.deepClone(data);
      if (this.parsedTransactionReportFilterData.startDate && this.parsedTransactionReportFilterData.endDate) {
        this.getYearList();
        this.getSummaryYTDData();
      };
    };
  };
  @Input() set downloadFile(downloadFile) {
    if (downloadFile.download) {
      this.filtersApplied.emit({ reportType: SUMMARY_YTD_REPORT_DATA.EXCEL_NAME, filters: this._holistaUtils.getFormattedValues(this.filterForm.value) });
    };
  };
  @Input() episodeTypeList = [];
  @Output() reportExpansion = new EventEmitter<any>();
  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private _holistaUtils: HolistaUtils,
    private _formBuilder: FormBuilder,
    private _reportService: ReportService,
    private _dateUtility: DateUtility
  ) {
    this.setFilterForm();
  }

  ngOnInit(): void {
  }

  setFilterForm() {
    this.filterForm = this._formBuilder.group({
      episodeType: [[]],
      year: [[]],
      dateType: [this.dateTypeList[0].value]
    });
  }

  getYearList() {
    const { startDate, endDate } = this.parsedTransactionReportFilterData;
    const reportYear = +this._dateUtility.getYearFromDate(endDate);
    const reportStartYear = +this._dateUtility.getYearFromDate(startDate);
    this.yearList = this._holistaUtils.getYearOptionList(reportStartYear, reportYear);
    this.filterForm.controls['year'].setValue(this.yearList[this.yearList.length - 1].value);
  }

  getSummaryYTDData() {
    this.isLoading.report = true;
    this.isCollapsed = true;
    this.temporaryDataStorage.length = 0;
    const { clientCode, networkCode } = this.parsedTransactionReportFilterData;
    const params = {
      networkCode,
      ...clientCode && { clientCode },
      ...this._holistaUtils.getFormattedValues(this.filterForm.value),
    };
    this._reportService.getSummaryYTDReport(params)
      .pipe(finalize(() => { this.isLoading.report = false; }))
      .subscribe(response => {
        if (response.success) {
          this.data = response.data;
          this.getSummaryYTDReport();
        };
      }, error => {
        console.log('error getting summary YTD report', error);
      })
  }

  getSummaryYTDReport() {
    const { TABLE_HEADERS: PERIOD_TOTALS_TABLE_HEADERS } = SUMMARY_YTD_REPORT_DATA.SUMMARY_DATA;
    this.summaryYTDReportPeriodTotals = {
      data: this.temporaryDataStorage,
      tableHeaders: PERIOD_TOTALS_TABLE_HEADERS,
    };
    const { TABLE_HEADERS } = SUMMARY_YTD_REPORT_DATA.REPORT_DATA;
    const { EXCEL_NAME } = SUMMARY_YTD_REPORT_DATA;
    this.summaryYTDReport = {
      data: this.data.accountSummaryDatas,
      tableHeaders: TABLE_HEADERS,
      excelName: EXCEL_NAME,
      columnsWidth: TABLE_HEADERS.map(header => header.columnWidth)
    };
  }

  onCollapseClicked() {
    this.temporaryDataStorage = this._holistaUtils.deepClone(this.data.monthwiseSummary);
    this.summaryYTDReportPeriodTotals.data = this.temporaryDataStorage;
    this.isCollapsed = !this.isCollapsed;
    this.isCollapsed && (this.summaryYTDReportPeriodTotals.data.length = 0);
  }
}
