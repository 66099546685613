import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../action';
import { RolePermissionsService, ToasterService } from '../../services';
import { finalize } from 'rxjs/operators';
import { Sorting, AccessType, Storage } from '../../utils';
import { RolePermission, Role } from '../../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ACCESS_DENIED, DELETE_SUCCESS, ERROR_CREATE_ROLE, ERROR_GET_ROLES, RestrictSpace, ROLE_CREATE_SUCCESS, TrimFields, UPDATE_FAILED, UPDATE_SUCCESS } from '../../constants';
import { Router } from '@angular/router';


@Component({
  selector: 'app-role-permissions',
  templateUrl: './role-permissions.component.html',
  styleUrls: ['./role-permissions.component.scss']
})
export class RolePermissionsComponent implements OnInit {
  searchKeyword: string = '';
  roleForm: FormGroup;
  submitted: boolean = false;
  submit_enabled: boolean = false;
  loading: any = {
    permissions: false,
    roles: true
  };
  role: Role = new Role()
  roles: Role[] = [];
  permissions: RolePermission[] = [];
  permissionsToUpdate: RolePermission[] = [];

  @ViewChild('permissionModal', { static: true }) public permissionModal;
  @ViewChild('createRoleModal', { static: true }) public createRoleModal;
  @ViewChild('tabs', { static: true }) public tabs;

  // For Updated Roles UI
  roleListSearchKeyword: string = ''
  roleCode: number
  deleteId: number
  message: string
  updatedRoles: Role[] = [];

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private router: Router,
    private rolepermissionService: RolePermissionsService,
    private _toastr: ToasterService,
    private utilitySorting: Sorting,
    private formBuilder: FormBuilder,
    public utilityAccess: AccessType,
    private _storage: Storage,
  ) {
    this.setRoleForm();
  }

  ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Roles & Permissions', path: 'rolepermissions' }));
  }

  ngAfterViewInit() {
    this.tabs && this.tabs.setActiveTab(2);
  }

  getRoles() {
    this.rolepermissionService.getRoles({ limit: 0 })
      .subscribe((res: any) => {
        if (res)
          this.roles = this.utilitySorting.sortBy(res.rows, 'name');
      }, (error) => {
        console.log("Error getting roles", error);
        this._toastr.displayError(ERROR_GET_ROLES);
      })
  }

  getUpdateRoles() {
    this.rolepermissionService.getUpdatedRoles({ limit: 0 })
      .pipe(finalize(() => { this.loading.roles = false; }))
      .subscribe((res: any) => {
        if (res)
          this.updatedRoles = this.utilitySorting.sortBy(res.rows, 'name');
      }, (error) => {
        console.log("Error getting roles", error);
      })
  }

  onTabClick(event: MouseEvent) {
    console.log('Mouse click detected on tab.', event);
  }

  onTabSelected(event?: any) {
    if (event?.heading.toLowerCase() === 'current') {
      this.getRoles();
      return;
    };
    this.getUpdateRoles();
  }

  setRoleForm() {
    this.roleForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      code: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      id: [null]
    })
  }

  openPermissionModal(role) {
    this.role = role
    this.permissionModal.show();
    this.loading.permissions = true
    this.getRolePermission(role.code)
    // this.rolepermissionService.getRolePermission(role.code)
    //   .then((res: any) => {
    //     this.loading.permissions = false
    //     if (res) {
    //       res.rows.map(x => {
    //         x.permissionName = x.permission.name
    //         return x
    //       })
    //       res.rows = this.utilitySorting.sortBy(res.rows, 'permissionName');
    //       this.permissions = JSON.parse(JSON.stringify(res.rows));
    //       //---filtered temporarily
    //       const filterOutPermissionCodes = ['CR', 'PR', 'FR', 'EFRR', 'CER', 'PER'];
    //       filterOutPermissionCodes.forEach(code => {
    //         this.permissions = this.permissions.filter(permission => permission.permissionCode !== code)
    //       })
    //       //---filtered temporarily
    //     }
    //   }, (error) => {
    //     console.log("Error getting permissions", error);
    //   })
  }

  closePermissionModal() {
    this.permissionModal.hide();
    this.role = new Role();
    this.permissions = [];
    this.permissionsToUpdate = [];
  }

  openRoleModal(role?) {
    if (this.utilityAccess.searchAccess('RM', 'isEditable')) {
      if (role)
        this.roleForm.patchValue(role);
      this.createRoleModal.show();
    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  closeRoleModal() {
    this.createRoleModal.hide();
    this.roleForm.reset();
    this.submitted = false;
  }

  submitRole() {
    this.submitted = true;
    if (this.roleForm.valid) {
      TrimFields.Roles.forEach(element => {
        this.roleForm.value[element] = this.roleForm.value[element] ? this.roleForm.value[element].trim() : this.roleForm.value[element];
      });
      this.submit_enabled = true;
      if (this.roleForm.value.id) this.updateRole();
      else this.saveRole();
    }
  }

  saveRole() {
    const role = Object.assign(new Role(), this.roleForm.value);
    this.rolepermissionService.saveRole(role)
      .pipe(finalize(() => { this.submit_enabled = false; }))
      .subscribe(res => {
        if (res) {
          this.updatedRoles.push(res);
          this.updatedRoles = this.utilitySorting.sortBy(this.updatedRoles, 'name');
          this._toastr.displaySuccess(ROLE_CREATE_SUCCESS);
          this.closeRoleModal();
        }
      }, (error) => {
        console.log("Error saving role", error);
        this._toastr.displayError(ERROR_CREATE_ROLE)
      });
  }

  updateRole() {
    const role = Object.assign(new Role(), this.roleForm.value);
    this.rolepermissionService.updateRole(role)
      .pipe(finalize(() => { this.submit_enabled = false; }))
      .subscribe(res => {
        if (res) {
          const index = this.updatedRoles.findIndex(x => x.code == res.code);
          if (index > -1) {
            this.updatedRoles[index] = res;
          };
          this.updatedRoles = this.utilitySorting.sortBy(this.updatedRoles, 'name');
          this._toastr.displaySuccess(UPDATE_SUCCESS);
          this.closeRoleModal();
        }
      }, (error) => {
        console.log("Error updating role", error);
        this._toastr.displayError(UPDATE_FAILED);
      });
  }

  updatePermission() {
    this.submit_enabled = true;
    this.rolepermissionService.updatePermission(this.role.code, this.permissionsToUpdate)
      .pipe(finalize(() => { this.submit_enabled = false; }))
      .subscribe(res => {
        if (res) {
          this.closePermissionModal()
          this._toastr.displaySuccess(UPDATE_SUCCESS);
        }
      }, (error) => {
        console.log("Error updating permission", error);
        this._toastr.displayError(UPDATE_FAILED)
      });
  }

  changeAccessType(permission, type) {
    permission.accessType = type
    this.getChangedPermissions(permission)
  }

  changeActiveStatus(event, permission) {
    permission.isActive = event
    permission.accessType = event ? "W" : null
    this.getChangedPermissions(permission)
  }

  getChangedPermissions(permission) {
    if (this.permissionsToUpdate.length > 0) {
      let index = this.permissionsToUpdate.findIndex(x => x.permissionCode == permission.permissionCode)
      if (index > -1)
        this.permissionsToUpdate[index] = permission
      else
        this.permissionsToUpdate.push(permission)
    }
    else
      this.permissionsToUpdate.push(permission)
  }

  removeSearchKeyword() {
    this.searchKeyword = ''
  }

  // For Updated Roles UI

  removeRoleListSearchKeyword() {
    this.roleListSearchKeyword = ''
  }


  getRolePermission(code: any) {
    this.rolepermissionService.getRolePermission(code)
      .then((res: any) => {
        this.loading.permissions = false
        if (res) {
          res.rows.map(x => {
            x.permissionName = x.permission.name
            return x
          })
          res.rows = this.utilitySorting.sortBy(res.rows, 'permissionName');
          this.permissions = JSON.parse(JSON.stringify(res.rows));
          //---filtered temporarily
          const filterOutPermissionCodes = ['CR', 'PR', 'FR', 'EFRR', 'CER', 'PER'];
          filterOutPermissionCodes.forEach(code => {
            this.permissions = this.permissions.filter(permission => permission.permissionCode !== code)
          })
          //---filtered temporarily
        }
      }, (error) => {
        console.log("Error getting permissions", error);
      })
  }

  viewPermissions(role: any) {
    this.roleCode = role.code;
  }

  addEditRole(role: any = null) {
    if (role) {
      this._storage.set('local', 'roleName', role.name);
      this.router.navigate(
        ['/rolepermission/add-edit'],
        { queryParams: { roleCode: role.code } }
      );
    } else {
      this.router.navigate([`/rolepermission/add-edit`]);
    }
  }

  resetRoleCode() {
    this.roleCode = null
  }

  deleteRole(role: any) {
    this.message = `Are you sure you want to delete this <strong>role</strong>? This is irreversible.
        If you're not sure, you can click <strong>Cancel</strong>.`
    this.deleteId = role.id

  }

  confirmDelete(event) {
    if (event) {
      const index = this.updatedRoles.findIndex(({ id }) => id === event);
      this.updatedRoles[index]['queuedToDelete'] = true;
      this.rolepermissionService.deleteRolePermission(event)
        .pipe(finalize(() => {
          this.role[index]?.queuedToDelete && delete this.role[index].queuedToDelete;
        }))
        .subscribe(response => {
          if (response) {
            this.updatedRoles = this.updatedRoles.filter(({ id }) => id !== response.id);
            this._toastr.displaySuccess(DELETE_SUCCESS);
          };
        },
          error => console.log('error deleting role permission', error)
        );
      return;
    };
    this.deleteId = null;
  }
}
