
  <div class="d-flex justify-content-start align-items-start list-content hover p-2" *ngIf="message && message.id">

    <div class="icon-block">
      <i class="icofont-ui-text-chat icofont-2x skin-text-primary-dark"></i>
    </div>
    <div class="d-flex justify-content-between w-100 align-items-center">

      <span class="pl-2 pr-4">
        <p class="font-weight-400">
          {{message.title}}
        </p>
        <p class="font-weight-300">
          {{message.messages}}
        </p>
      </span>
      <span class="action-item position-relative d-none">
        <i class="icofont-ui-edit icofont-sm mr-2" #actionBtn (click)="editTaskHandler(message)"></i>
        <i class="icofont-ui-delete icofont-sm mr-2" #actionBtn (click)="deleteTaskHandler(message)"></i>
      </span>
    </div>
  </div>

<app-milestone-topic-delete [deleteData]="taskToBeDelete" (deletedData)="deleteTask($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>