import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from "rxjs/operators";
@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    constructor(private http: HttpClientService) { }

    getAll(query?) {
        return this.http.get(`${APICONSTANTS.NETWORK}`, query)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getByCode(code) {
        return this.http.get(`${APICONSTANTS.NETWORK}/${code}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    create(network) {
        return this.http.post(`${APICONSTANTS.NETWORK}`, network)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    update(network) {
        return this.http.put(`${APICONSTANTS.NETWORK}/${network.code}`, network)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    delete(id, paramType) {
        return this.http.remove(`${APICONSTANTS.NETWORK}/${id}?paramType=${paramType}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }
}