import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HolistaUtils, AccessType, Storage, ScrollTo } from '../../../utils';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MilestoneService,
  EpisodeService,
  UserService,
  EpisodeTaskService,
  DocumentService,
  ClientService,
  CommunicationService,
  LookupService,
} from '../../../services';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../../action';
import { finalize, startWith, switchMap, debounceTime, map, takeWhile } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import {
  NOTETYPES,
  MILESTONECONSTANTS,
  TEXTEDITOR,
  DatePickerOption,
  PROVIDER_TYPE,
  NOTE_TYPE,
  PURCHASER_CODES,
  READ_ONLY_NOTE_TYPES,
  MODULES,
  ROLE_CODES,
  EPISODE_STATUSES,
} from '../../../constants';
import { EpisodeTopicComponent } from './episode-topic/episode-topic.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, Observable, of } from 'rxjs';
import { ResizeEvent } from 'angular-resizable-element';
import * as Actions from '../../../action';
import { CommunicationModuleOptions, EpisodeResponse } from '../../../models';
import { MDBDatePickerComponent } from 'ng-uikit-pro-standard';

const MILESTONE_STATUS = {
  COMPLETED: 'COMPLETED',
};

const FUNDING_TRIGGER_REFERENCE_TYPE = {
  MILESTONE: 'MILESTONE',
};
@Component({
  selector: 'app-episode-milestone',
  templateUrl: './episode-milestone.component.html',
  styleUrls: ['./episode-milestone.component.scss'],
})

export class EpisodeMilestoneComponent implements OnInit, OnDestroy {
  episode: any;
  selected_milestone: any;
  milestoneForm: FormGroup;
  noteForm: FormGroup;
  attachForm: FormGroup;
  triggerPeriodOptions = [
    { label: 'Day(s)', value: 'days' },
    { label: 'Week(s)', value: 'weeks' },
  ];
  triggerConditionOptions = [
    { label: 'Before', value: 'before' },
    { label: 'After', value: 'after' },
  ];
  triggerMilestoneOptions = [];
  milestoneList = [];
  searchKey = '';
  loading = false;
  notesloading = false;
  submit_enabled = false;
  assignedToList = [];
  assignedTo: any;
  user: any;
  submitted = false;
  dataToBeDelete: any;
  currentUrl: any;
  editMilestoneStatus = '';
  notes = [];
  noteTypes = NOTETYPES;
  patientDemographicId = null;
  document_search_text = new Subject();
  document_name_results: Observable<any>;
  documents = [];
  attachments = [];
  notesstatus = false; //notes expand status
  holistaMedia: Array<File> = [];
  uploadedFile: File;
  public style: object = {};
  usermode = false;
  episodeDescHeight = 65;
  userProfile: any;
  episodeDetail = [];
  cardLoading = false;
  assgnedCcEs: any;
  enableChat = false;
  displayMilestones: any;
  allMilestonesList = MILESTONECONSTANTS;
  userDetail: any;
  episodeEOB: any = {};
  editorConfig = TEXTEDITOR;
  myDatePickerOptions = { ...DatePickerOption, closeAfterSelect: false };
  alive: boolean = true;
  submit_reason = false;
  showReasonOption = false;
  reasonList: any = [];
  userLastLoginDate: string;
  patientIdCard: any;
  selectedMilestone: any;
  selectedDate: any;
  updateMilestoneDateLoading = false;
  selectedProvider: any;
  providerType = PROVIDER_TYPE;
  isDateWarningModalShown: boolean;
  datePickerComponents: any[];
  showMessageModal: boolean = false;
  // add reason popover
  addReasonform =
    '<div class="form-group mb-0"><label for="reasonCode">Add Reason Code </label><textarea type="text" id="reasonCode" class="md-textarea form-control "formControlName="reasonCode" mdbInput></textarea><button class="btn btn-secondary btn-sm waves-light ml-0" mdbWavesEffect>Save</button></div>';
  metaVariablesDetail: any;
  isLoading: boolean = false;
  messageThread: Array<any> = [];
  moduleOptions: CommunicationModuleOptions;
  noteType: string;
  readonly COMMUNICATIONS = NOTE_TYPE.COMMUNICATIONS;
  purchaserCodes = PURCHASER_CODES;
  readonly READ_ONLY_NOTE_TYPES = READ_ONLY_NOTE_TYPES;
  groups: any;
  loadingGroups: boolean = false;

  @ViewChild('milestoneModal', { static: true }) public milestoneModal;
  @ViewChild('noteModal', { static: true }) public noteModal;
  @ViewChild('episodeTopic', { static: false }) episodeTopic: EpisodeTopicComponent;
  @ViewChild('attachModal', { static: true }) public attachModal;
  @ViewChild('idCardModal', { static: true }) public idCardModal;
  @ViewChild('viewEOBModal', { static: true }) public viewEOBModal;
  @ViewChild('milestoneDateWarningModal', { static: true }) public milestoneDateWarningModal;
  @ViewChild('messageThreadModal', { static: true }) public messageThreadModal;
  @ViewChildren('datePicker') public datePicker: QueryList<MDBDatePickerComponent>;

  @Input() set selected_episode(selected_episode) {
    if (selected_episode.id) {
    }
  }
  @Input() public isMobile;

  constructor(
    private holistaUtils: HolistaUtils,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private milestoneService: MilestoneService,
    private episodeService: EpisodeService,
    private store: Store<{ bread_crumbs: any; chatUsers: any; enableChat: boolean }>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    public utilityAccess: AccessType,
    private _storage: Storage,
    private _scrollTo: ScrollTo,
    private episodeTaskService: EpisodeTaskService,
    private documentService: DocumentService,
    private clientService: ClientService,
    private _communicationService: CommunicationService,
    private _lookupService: LookupService
  ) {
    this.setMilestoneForm();
    this.setNoteForm();
    this.isMobile = this.deviceService.isMobile();
    this.setAttachForm();
    store.select('enableChat').subscribe(res => {
      this.enableChat = res;
    });
  }

  setMilestoneForm() {
    this.milestoneForm = this.formBuilder.group({
      id: [null],
      episodeId: [null, Validators.required],
      name: ['', Validators.required],
      description: [null],
      startDate: [],
      showDate: [null],
      endDate: [],
      triggerDays: [0],
      triggerPeriod: ['days'],
      triggerCondition: ['after'],
      triggerMilestoneId: [],
      createdBy: [],
      updatedBy: [],
      updatedDate: [null],
      sequence: [null],
      triggerMilestoneUuid: [''],
      isTriggerOnStart: [false],
      isKeyMilestone: [false],
      isMainProcedure: [false],
      groupCode: [null],
    });
  }

  setNoteForm() {
    this.noteForm = this.formBuilder.group({
      type: [''],
      note: [''],
      episodeId: [null],
    });
  }

  setAttachForm() {
    this.attachForm = this.formBuilder.group({
      type: ['link'],
      displayName: ['', Validators.required],
      documentPath: [''],
      holistalibraryPath: [''],
      uploadPath: [''],
    });
  }

  async ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(
      new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Episodes', path: '/episodes' })
    );
    this.user = this._storage.get('local', 'loggedInUser', 'user');
    this.getReasonList();
    if (this.user.roleCode === 'MP') this.usermode = true;
    this.document_name_results = this.document_search_text.pipe(
      startWith(this.document_search_text),
      switchMap((provider_search_text: string) => this.searchDocument(provider_search_text))
    );
    this.currentUrl = this.router.url.split('#')[0];
    let episodeId = this._activatedRoute.snapshot.params.episodeId;
    await this.getEpisode(episodeId);
    this.milestoneForm.get('triggerMilestoneUuid').valueChanges.subscribe(value => {
      if (value) {
        let index = this.triggerMilestoneOptions.findIndex(x => x.value == value);
        if (index > -1)
          this.milestoneForm.controls.triggerMilestoneId.setValue(
            this.triggerMilestoneOptions[index].id
          );
      }
    });
  }

  ngAfterViewInit() {
    this.datePicker.changes.subscribe(a => {
      this.datePickerComponents = a._results;
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  getEpisode(id) {
    this.loading = true;
    this.episodeService
      .getEpisode(parseInt(id))
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        async (res: EpisodeResponse) => {
          this.loading = false;
          if (res.milestones.length !== 0) {
            res.milestones.map((x: any) => {
              x.originalMilestoneStatus = x.status;
            });
            this.refreshEpisode(JSON.parse(JSON.stringify(res)));
          } else {
            this.refreshEpisode(JSON.parse(JSON.stringify(res)));
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  addMilestone() {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      this.milestoneModal.show();
      if (this.triggerMilestoneOptions.length > 0) {
        let latestMilestone =
          this.triggerMilestoneOptions[this.triggerMilestoneOptions.length - 1].value;
        this.milestoneForm.controls.triggerMilestoneUuid.setValue(latestMilestone);
      }
    } else this.toastr.warning('Access Denied', 'Warning');
  }

  getUserLastLoginDate(userId) {
    this.userService.getUserLastLoginDate(userId).subscribe(
      res => {
        if (res && res.lastLoginDate) this.userLastLoginDate = res.lastLoginDate;
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  /**
   * Check Episode's milestone and milestone's topic and set milestone and topic status
   * @param {any} episode selected episode
   */
  refreshEpisode(episode) {
    this.getUserLastLoginDate(episode.userId);
    if (episode.eobDate) {
      this.clientService
        .getById(episode.clientCode)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(
          client => {
            episode.client = client;
          },
          error => {
            console.log('Error', error);
          }
        );
    }
    const latestStatusDetail =
      episode.episodeStatus.length &&
      episode.episodeStatus.reduce((a, b) => (a.createdAt > b.createdAt ? a : b));
    //Final status set to default preliminary to support freshly created carrum claims i.e. episode status not yet changed
    const query = {
      initialStatus: latestStatusDetail.initialStatus,
      finalStatus: latestStatusDetail.finalStatus || 'PRELIMINARY',
    };
    this.episodeService.getEpisodeAllowedStatus(query).subscribe(res => {
      const isTaskDisabled = res.length ? res[0].disableTask : false;
      episode.milestones.map((x: any) => {
        x.episodeStatus = episode.status;
        x.assignedUserId = episode.patient && episode.patient.id;
        x.isTaskDisabled = isTaskDisabled;
      });
    });
    // episode.episodeOfCarePlans.map(x => {
    //   if (x.planGroup === episode.planGroup) {
    //     episode.memberResponsibility = x.memberResponsibility
    //   }
    //   return x
    // })
    episode.ccName = this.holistaUtils.getFullName(episode.cc);
    episode.esName = this.holistaUtils.getFullName(episode.es);
    episode.patientName = this.holistaUtils.getFullName(episode.patient || episode.claimPatient);
    this.episode = episode;
    this.episode.endDate = this.episode.endDate
      ? moment(this.episode.endDate).format('MM/DD/YYYY')
      : null;
    this.assignedTo = episode.assignedTo;
    if (this.episode.milestones.length > 0) {
      this.episode.milestones.map(x => {
        let data = { value: x.uuid, label: x.name, id: x.id };
        this.milestoneList = [...this.milestoneList, data];
        this.triggerMilestoneOptions = [...this.triggerMilestoneOptions, data];
      });
      this.episode.milestones = this.episode.milestones.map((x: any) => {
        x.showDate = null;
        let showDate = x.startDate ? x.startDate : x.relativeStartDate;
        x.showDate = showDate ? moment(showDate.split('T')[0]).format('MM/DD/YYYY') : null;
        const currentDate = moment(new Date().toISOString().split('T')[0]).format('MM/DD/YYYY');
        const daysDiff = moment(currentDate).diff(showDate, 'days');
        x.triggerDatePassed = daysDiff >= 0 ? true : false;
        return x;
      });
      this.episode.milestones = [...this.holistaUtils.sortBy(this.episode.milestones, 'sequence')];
      //Rearrange milestones regarding isTriggerOnStart milestones first, milestones which do not have showDate and isTriggerOnStart milestones second, and finally sort the milestones which have showDate and not isTriggerOnStart
      this.episode.milestones = [
        ...this.episode.milestones.filter(x => x.isTriggerOnStart),
        ...this.episode.milestones.filter(x => !x.showDate && !x.isTriggerOnStart),
        ...this.holistaUtils.sortByDate(
          this.episode.milestones.filter(x => !x.isTriggerOnStart),
          'showDate'
        ),
      ];
      //Set milestone/topic status and escalation days
      if (
        this.episode.status !== 'COMPLETED' &&
        (this.episode.status == 'CONFIRMED' || this.episode.status == 'CANCELLED')
      ) {
        this.episode.milestones.map(a => {
          if (a.topics && (!a.status || (a.status != 'COMPLETED' && a.startDate))) {
            a.topics.map(b => {
              if (b.escalationDays && (!b.status || (b.status && b.status != 'COMPLETED'))) {
                let daysDiff = moment(new Date()).diff(a.startDate, 'days');
                if (daysDiff > b.escalationDays) {
                  let dueDay = daysDiff - b.escalationDays;
                  b.escalatedDays = `${dueDay}${dueDay > 1 ? ' days' : ' day'} ago`;
                  b.status = 'DUE';
                  if (!a.escalatedDays) {
                    a.escalatedDays = b.escalatedDays;
                    a.status = 'DUE';
                  }
                }
              }
              return b;
            });
          }
          if (a.topics && (!a.status || (a.status != 'COMPLETED' && a.relativeStartDate))) {
            a.topics.map(b => {
              if (b.escalationDays && (!b.status || (b.status && b.status != 'COMPLETED'))) {
                let daysDiff = moment(new Date()).diff(a.relativeStartDate, 'days');
                if (daysDiff > b.escalationDays) {
                  let dueDay = daysDiff - b.escalationDays;
                  b.escalatedDays = `${dueDay}${dueDay > 1 ? ' days' : ' day'} ago`;
                  b.status = 'DUE';
                  if (!a.escalatedDays) {
                    a.escalatedDays = b.escalatedDays;
                    a.status = 'DUE';
                  }
                }
              }
              return b;
            });
          }
          return a;
        });
      }
      //If the current url consists of active field value then it will navigate to milestones's task page
      if (this._activatedRoute.snapshot.queryParams.active) {
        let index = this.episode.milestones.findIndex(
          x => x.id == this._activatedRoute.snapshot.queryParams.active
        );
        if (index > -1)
          this.goToMileStoneTopic(
            episode.milestones[index],
            this._activatedRoute.snapshot.queryParams.task
              ? this._activatedRoute.snapshot.queryParams.task
              : null
          );
      } else {
        if (!this.usermode || (this.usermode && !this.isMobile))
          this.goToMileStoneTopic(episode.milestones[0]);
      }
      this.displayMilestones = episode.milestones;
    }
    setTimeout(() => {
      const episodeDesc = document.getElementById('episodeDesc');
      if (episodeDesc) this.episodeDescHeight = episodeDesc.offsetHeight;
      this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Milestone', path: '' }));
    }, 0);
  }

  /**
   * Navigate to episode detail page
   * @param {any}milestone selected episode's milestone
   * @param {string}task optional param for active task id
   */
  goToMileStoneTopic(milestone, task?) {
    let url = this._activatedRoute.snapshot.url;
    this.router.navigate([url[0].path, url[1].path, url[2].path], {
      queryParams: { active: milestone.id, task: task },
    });
    let id = `milestone${milestone.uuid}`;
    setTimeout(() => {
      const documentId = document.getElementById(id);
      if (documentId) {
        documentId.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }, 100);
    milestone.ccId = this.episode.ccId;
    milestone.esId = this.episode.esId;
    this.selected_milestone = milestone;
  }

  getMilestoneGroups() {
    this.loadingGroups = true;
    this._lookupService
      .getLookups({ module: MODULES.MILESTONE })
      .pipe(
        finalize(() => {
          this.loadingGroups = false;
        })
      )
      .subscribe(
        (response: any) => {
          this.groups = response.rows.map(group => ({
            ...group,
            label: group.name,
            value: group.code,
          }));
        },
        error => {
          this.toastr.error(error);
          console.log('error getting groups', error);
        }
      );
  }

  editMilestone(milestone, index) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      milestone.sequence = index + 1;
      this.milestoneForm.patchValue(milestone);
      let list = this.triggerMilestoneOptions.filter(x => x.value != milestone.uuid);
      this.triggerMilestoneOptions = list;
      this.editMilestoneStatus = milestone.status;
      this.getMilestoneGroups();
      this.milestoneModal.show();
    } else this.toastr.warning('Access Denied', 'Warning');
  }

  deselectMilestone(event) {
    this.milestoneForm.controls.triggerMilestoneUuid.setValue('');
  }

  milestoneToDelete(milestone) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      let dependantMilestones = this.episode.milestones.filter(
        x => milestone.uuid == x.triggerMilestoneUuid && !x.startDate
      );
      const lastIndex = dependantMilestones.length - 1;
      // To check whether other milestones are dependent with selected milestone
      if (dependantMilestones && dependantMilestones.length) {
        let relativeMilestones = '';
        dependantMilestones.forEach(
          ({ name }, index: number) =>
          (relativeMilestones +=
            index !== 0 && index === lastIndex
              ? ` and ${name}`
              : index !== 0
                ? `, ${name}`
                : name)
        ),
          this.toastr.warning(
            `${relativeMilestones} ${dependantMilestones.length > 1 ? 'are' : 'is'} dependent on ${milestone.name
            }. Please update them to delete. `,
            null,
            { timeOut: 10000 }
          );
      } else {
        milestone['source'] = 'Episode';
        milestone['type'] = 'Milestone';
        milestone['displayName'] = milestone.name;
        this.dataToBeDelete = milestone;
      }
    } else this.toastr.warning('Access Denied', 'Warning');
  }

  closeModal() {
    this.milestoneForm.reset();
    this.setMilestoneForm();
    this.milestoneModal.hide();
    this.triggerMilestoneOptions = [...this.milestoneList];
    this.submit_enabled = false;
    this.submitted = false;
  }

  submitMilestone() {
    this.submitted = true;
    this.milestoneForm.controls.episodeId.setValue(
      this.milestoneForm.value.episodeId
        ? this.milestoneForm.value.episodeId
        : this.episode.id
          ? this.episode.id
          : null
    );
    if (this.milestoneForm.valid) {
      this.submit_enabled = true;
      if (this.milestoneForm.value.id) this.updateMilestone();
      else this.saveMilestone();
    }
  }

  saveMilestone() {
    let sequence = this.episode.milestones.length;
    this.milestoneForm.controls.sequence.setValue(sequence + 1);
    this.episodeService
      .saveMilestone(this.milestoneForm.value)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          if (res.message) {
            this.toastr.error(res.message, 'Error');
            this.closeModal();
          } else {
            let showDate = res.data.startDate ? res.data.startDate : res.data.relativeStartDate;
            res.data.showDate = showDate ? new Date(JSON.parse(JSON.stringify(showDate))) : null;
            this.refreshMilestones(res, 'saved');
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  updateMilestone() {
    this.episodeService
      .updateMilestone(this.milestoneForm.value)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this.displayMilestones = res.data;
          if (res.message) {
            this.toastr.error(res.message, 'Error');
            this.closeModal();
          } else {
            let updated_milestone = res.data.find(x => x.id == this.milestoneForm.value.id);
            this.refreshMilestones(res, 'updated', updated_milestone);
          }
        },
        error => {
          console.log(error);
        }
      );
  }
  /**
   * Check whether to add new milestone or update existing milestone
   * @param {any}response new/updated milestone
   * @param {string}action saved/updated text
   * @param {any}updated_milestone optional param for updated milestone
   */
  refreshMilestones(response, action, updated_milestone?) {
    let milestone = action == 'saved' ? response.data : updated_milestone;
    if (this.episode && this.episode.milestones && this.episode.milestones.length > 0) {
      let index = this.episode.milestones.findIndex(x => x.id === milestone.id);
      if (index > -1) {
        this.episode.milestones[index] = milestone;
      } else this.episode.milestones.push(milestone);
    } else {
      this.episode.milestones.push(milestone);
    }
    if (response.data && response.data.length > 0) {
      this.episode.milestones.map(x => {
        x.showDate = null;
        response.data.map(y => {
          if (y.uuid == x.uuid) {
            x.startDate = y.startDate;
            x.relativeStartDate = y.relativeStartDate;
            let showDate = x.startDate ? x.startDate : x.relativeStartDate;
            x.showDate = showDate ? moment(showDate.split('T')[0]).format('MM/DD/YYYY') : null;
          }
        });
        return x;
      });
    }
    if (this.selected_milestone && milestone.id == this.selected_milestone['id']) {
      this.selected_milestone = milestone;
    } else if (this.episode.milestones.length > 0) {
      this.goToMileStoneTopic(this.episode.milestones[0]);
    }
    this.milestoneList = [];
    this.triggerMilestoneOptions = [];
    this.episode.milestones.map(x => {
      let data = { value: x.uuid, label: x.name, id: x.id };
      this.milestoneList = [...this.milestoneList, data];
      this.triggerMilestoneOptions = [...this.triggerMilestoneOptions, data];
    });
    this.episode.milestones = [...this.holistaUtils.sortBy(this.episode.milestones, 'sequence')];
    //Rearrange milestones regarding isTriggerOnStart milestones first, milestones which do not have showDate and isTriggerOnStart milestones second, and finally sort the milestones which have showDate and not isTriggerOnStart
    this.displayMilestones = [
      ...this.episode.milestones.filter(x => x.isTriggerOnStart),
      ...this.episode.milestones.filter(x => !x.showDate && !x.isTriggerOnStart),
      ...this.holistaUtils.sortByDate(
        this.episode.milestones.filter(x => !x.isTriggerOnStart),
        'showDate'
      ),
    ];
    this.toastr.success(`Milestone ${action} successfully.`, 'Success');
    this.closeModal();
  }

  /**
   * Set milestone start date and order the milestones
   * @param {any}event start date for respponsible milestone
   * @param {milestone}milestone responsible milestone
   */
  setMilestoneDate(event, milestone) {
    const openedDatePicker = this.datePickerComponents.find(
      x => x._id === `milestone${milestone.id}`
    );
    // selected milestone date is in future and previous milestone date is either null or future then update the milestone date directly
    if (
      event.jsdate &&
      event.jsdate > new Date() &&
      (!milestone.showDate || new Date(milestone.showDate) > new Date())
    ) {
      this.updateMilestoneDate(event, milestone);
      openedDatePicker.closeBtnClicked();
    } else if (!event.jsdate) {
      this.updateMilestoneDateLoading = false;
      openedDatePicker.closeBtnClicked();
    } else {
      // if selected date is in past or selected milestone date was in past the show warning before update date
      this.selectedMilestone = milestone;
      this.selectedDate = event;
      this.selectedDate['currentDate'] = new Date();
      this.updateMilestoneDateLoading = false;
      this.isDateWarningModalShown = true;
      this.milestoneDateWarningModal.show();
    }
  }

  updateMilestoneDate(selectedDate, milestone) {
    if (selectedDate.jsdate) {
      this.updateMilestoneDateLoading = true;
      const body = {
        id: milestone.id,
        startDate: moment(selectedDate.jsdate).format('MM/DD/YYYY'),
        episodeId: milestone.episodeId,
      };
      this.episodeService.setMilestoneDate(body).subscribe(
        res => {
          if (res && res.length > 0)
            this.episode.milestones.map(x => {
              x.showDate = null;
              res.map(y => {
                if (y.uuid == x.uuid) {
                  x.startDate = y.startDate;
                  x.relativeStartDate = y.relativeStartDate;
                  let showDate = x.startDate ? x.startDate : x.relativeStartDate;
                  x.showDate = showDate
                    ? moment(showDate.split('T')[0]).format('MM/DD/YYYY')
                    : null;
                  const currentDate = moment(new Date().toISOString().split('T')[0]).format(
                    'MM/DD/YYYY'
                  );
                  const daysDiff = moment(currentDate).diff(showDate, 'days');
                  x.triggerDatePassed = daysDiff >= 0 ? true : false;
                }
              });
              return x;
            });
          this.episode.milestones = [
            ...this.holistaUtils.sortBy(this.episode.milestones, 'sequence'),
          ];
          //Rearrange milestones regarding isTriggerOnStart milestones first, milestones which do not have showDate and isTriggerOnStart milestones second, and finally sort the milestones which have showDate and not isTriggerOnStart
          this.episode.milestones = [
            ...this.episode.milestones.filter(x => x.isTriggerOnStart),
            ...this.episode.milestones.filter(x => !x.showDate && !x.isTriggerOnStart),
            ...this.holistaUtils.sortByDate(
              this.episode.milestones.filter(x => !x.isTriggerOnStart),
              'showDate'
            ),
          ];
          this.toastr.success(`Milestone date updated successfully.`, 'Success');
          this.updateMilestoneDateLoading = false;
          this.isDateWarningModalShown = false;
          if (selectedDate.jsdate) this.milestoneDateWarningModal.hide();
        },
        error => {
          this.updateMilestoneDateLoading = false;
          console.log(error);
        }
      );
    }
  }

  closeMilestoneDateWarningModal() {
    const index = this.episode.milestones.findIndex(m => m.id === this.selectedMilestone.id);
    if (index > -1) {
      this.episode.milestones[index].showDate = this.selectedDate.previousDateFormatted
        ? this.selectedDate.previousDateFormatted
        : null;
    }
    if (!this.episode.milestones[index].showDate) {
      // set input value of datepicker to null when user cancels confirmation after selecting past date
      const element = document.getElementById(`milestone${this.selectedMilestone.id}`);
      if (element && element.children.length > 0 && element.children[0].children.length > 0) {
        const inlineDatepicker: any = element.children[0].children[0];
        if (inlineDatepicker && inlineDatepicker['value']) {
          inlineDatepicker['value'] = null;
        }
      }
    }
    this.episode.milestones = [...this.episode.milestones];
    this.selectedMilestone = {};
    this.selectedDate = {};
    this.updateMilestoneDateLoading = false;
    this.isDateWarningModalShown = false;
    this.milestoneDateWarningModal.hide();
  }

  delete(data) {
    if (data.type == 'Milestone') this.deleteMilestone(data);
    if (data.noteType == 'Note') this.deleteNote(data);
  }
  /**
   * Delete selected milestone and milestone's topic
   * @param {any}milestone selected milestone
   */
  deleteMilestone(milestone) {
    let index = this.episode.milestones.findIndex(x => x.id == milestone.id);
    let triggerIndex = this.triggerMilestoneOptions.findIndex(x => x.id == milestone.id);
    if (triggerIndex > -1) {
      this.triggerMilestoneOptions = this.triggerMilestoneOptions.filter(
        x => x.id !== milestone.id
      );
      this.milestoneList = this.milestoneList.filter(x => x.id !== milestone.id);
    }
    if (index > -1) {
      if (index !== 0) {
        this.goToMileStoneTopic(this.episode.milestones[index - 1]);
      } else if (this.episode.milestones.length == 1) {
        this.selected_milestone = null;
      } else {
        this.goToMileStoneTopic(this.episode.milestones[index + 1]);
      }
      this.episode.milestones.splice(index, 1);
    }
    this.dataToBeDelete = null;
  }

  cancelDelete(e) {
    this.dataToBeDelete = e;
  }

  saveNote() {
    this.noteForm.controls.episodeId.setValue(this.episode.id);
    if (this.noteForm.valid) {
      this.submit_enabled = true;
      let data = Object.assign(this.noteForm.value);
      data.documents = JSON.stringify(this.documents);
      data.holistaMedia = this.holistaMedia;
      this.milestoneService
        .saveNote(data)
        .pipe(
          finalize(() => {
            this.submit_enabled = false;
          })
        )
        .subscribe(
          res => {
            this.noteTypes.map(y => {
              if (res.data.type == y.value) res.data.displayType = y.label;
              res.data.fullName = this.holistaUtils.getFullName(res.data.user);
            });
            this.notes.push(res.data);
            this.closeNoteModal('hide');
            this.ScrollTo(`${res.data.id}-newNote`, 'center', 'smooth');
            this.toastr.success(`Note saved successfully.`, 'Success');
          },
          error => {
            console.log(error);
          },
          () => this.closeNoteModal(null)
        );
    }
  }

  getNotes(episodeId) {
    this.notesloading = true;
    this.notes.length = 0;
    this.milestoneService.getNotes(episodeId).subscribe(
      res => {
        this.notesloading = false;
        this.notes = res.map(x => {
          x.fullName = x.user && this.holistaUtils.getFullName(x.user);
          this.noteTypes.map(y => {
            if (x.type == y.value) x.displayType = y.label;
            return y;
          });
          return x;
        });
        // this.ScrollTo(`${res.id}-newNote`, 'center', 'smooth')
      },
      error => {
        console.log(error);
      }
    );
  }

  noteToBeDelete(note) {
    note['noteType'] = 'Note';
    note['displayName'] = note.note;
    this.dataToBeDelete = note;
  }
  /**
   * Scroll to responsible document
   * @param {string}id id of html document
   * @param {string}placement alignment for document
   */
  ScrollTo(id, placement, transition) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      setTimeout(() => {
        this._scrollTo.ScrollTo(id, placement, transition);
      }, 200);
    }
  }

  OpenNotes() {
    this.getNotes(this.episode.id);
    this.noteModal.show();
  }

  deleteNote(note) {
    let index = this.notes.findIndex(x => x.id == note.id);
    if (index > -1) {
      let index = this.notes.findIndex(x => x.id == note.id);
      this.notes.splice(index, 1);
    }
    this.dataToBeDelete = null;
  }

  closeNoteModal(status) {
    if (status == 'close') this.noteModal.hide();
    this.noteForm.reset();
    this.setNoteForm();
    this.notesstatus = false;
    this.documents = [];
    this.holistaMedia = [];
  }
  /**
   * Navigate to episode page only for mobile view
   */
  goBack() {
    this.router.navigate(['/episodes']);
  }

  setMilestoneViewHandler(event) {
    if (event && event == 'setMilestoneView') {
      this.selected_milestone = null;
    }
  }

  ViewPatientDemographic(userId) {
    this.patientDemographicId = userId;
  }

  closePatientDemographic(e) {
    this.patientDemographicId = null;
  }

  onFileRemove() { }

  onFileAdd(file: File) {
    this.attachForm.controls.uploadPath.setValue(file);
    this.uploadedFile = file;
  }
  /**
   * Search document from existing documents
   * @param {string}searchText search keyword
   * @returns list of match documents
   */
  searchDocument(searchText: string) {
    if (searchText.length > 2) {
      return this.documentService.getDocument({ limit: 0, keyword: searchText }).pipe(
        debounceTime(250),
        map((items: any) => {
          return items.rows;
        })
      );
    } else {
      return of([]);
    }
  }
  /**
   * Display selected search document name
   * @param {any}procedure selected search document
   * @returns document full name
   */
  onDisplayValue = (procedure?): string | undefined => {
    return procedure ? procedure.name : undefined;
  };

  onDocumentSelect(e) {
    let data = e.text;
    data['displayName'] = data.name;
    data['type'] = this.attachForm.value.type;
    this.attachForm.controls.displayName.setValue(data.name);
    this.attachForm.controls.holistalibraryPath.setValue(data);
    this.attachments = [];
    this.attachments.push(data);
  }

  submitAttachFile() {
    this.submitted = true;
    if (this.attachForm.value.type == 'link') {
      this.attachForm.controls['documentPath'].setValidators([Validators.required]);
      this.attachForm.controls['documentPath'].updateValueAndValidity();
    }
    if (this.attachForm.value.type == 'holistalibrary') {
      this.attachForm.controls['holistalibraryPath'].setValidators([Validators.required]);
      this.attachForm.controls['holistalibraryPath'].updateValueAndValidity();
    }
    if (this.attachForm.value.type == 'uploadFile') {
      this.attachForm.controls['uploadPath'].setValidators([Validators.required]);
      this.attachForm.controls['uploadPath'].updateValueAndValidity();
    }

    if (this.attachForm.valid) {
      //If the selected file is choosed from existing documents
      if (this.attachForm.value.type == 'holistalibrary') {
        const is_found = this.documents.find(
          x => x.documentPath === this.attachments[0].documentPath
        );
        if (is_found) {
          this.toastr.warning('File already added', 'warning');
        } else {
          this.documents = [...this.documents, ...this.attachments];
        }
      }
      //If the selected file is link type
      if (this.attachForm.value.type == 'link') {
        const is_found = this.documents.find(
          x => x.documentPath === this.attachForm.value.documentPath
        );
        if (is_found) {
          this.toastr.warning('File already added', 'warning');
        } else {
          this.documents.push(this.attachForm.value);
        }
      }
      //If the selected file is user's uploaded file
      if (this.attachForm.value.type == 'uploadFile') {
        this.uploadedFile['displayName'] = this.attachForm.value.displayName;
        this.holistaMedia.push(this.uploadedFile);
      }
      this.closeAttachModal();
    }
  }

  closeAttachModal() {
    this.document_search_text.next('');
    this.attachments = [];
    this.attachForm.reset();
    this.setAttachForm();
    this.attachModal.hide();
    this.submitted = false;
    this.uploadedFile = null;
  }

  addAttachFile() {
    this.attachModal.show();
  }
  /**
   * open/close note's text editor
   */
  expandClass() {
    this.notesstatus = !this.notesstatus;
  }

  removeDocument(file, i) {
    if (file.type == 'holistalibrary' || file.type == 'link') {
      this.documents.splice(i, 1);
    }
    if (!(file.type == 'holistalibrary' || file.type == 'link')) {
      this.holistaMedia.splice(i, 1);
    }
  }

  changeAttachment() {
    this.attachForm.controls.displayName.setValue('');
    this.attachForm.controls.documentPath.setValue('');
    this.attachForm.controls['documentPath'].clearValidators();
    this.attachForm.controls['documentPath'].updateValueAndValidity();
    this.attachForm.controls['holistalibraryPath'].clearValidators();
    this.attachForm.controls['holistalibraryPath'].updateValueAndValidity();
    this.attachForm.controls['uploadPath'].clearValidators();
    this.attachForm.controls['uploadPath'].updateValueAndValidity();
  }

  addCompleteReason(index) {
    this.displayMilestones = this.displayMilestones.map((milestone, i) => {
      if (index == i) milestone.showReasonOption = true;
      else {
        milestone.showReasonOption = false;
        milestone.reasonCode = '';
      }
      return milestone;
    });
  }

  getReasonList() {
    this.reasonList = [];
    this.episodeTaskService.getTaskCompletionReasons().subscribe(
      (res: any) => {
        res.map(x => {
          let data = { label: x.reason, value: x.reason };
          this.reasonList = [...this.reasonList, data];
        });
      },
      error => {
        console.log('Error getting Task Completion Reason Lists', error);
      }
    );
  }

  saveUndoCompletionReason(milestone) {
    this.submit_reason = true;
    this.milestoneService
      .completeMilestoneReason(milestone.id, !milestone.completedByCcEs ? milestone.reasonCode : '')
      .pipe(
        finalize(() => {
          this.submit_reason = false;
        })
      )
      .subscribe(
        res => {
          this.toastr.success(this.getMilestoneCompletionToasterMessage(milestone, res.status));
          if (res.completedByCcEs) {
            res.showReasonOption = false;
            res.reasonCode = '';
          }
          const milestoneIndex = this.displayMilestones.findIndex(m => m.id == milestone.id);
          if (milestoneIndex > -1) {
            res.showDate = null;
            let showDate = res.startDate ? res.startDate : res.relativeStartDate;
            res.showDate = showDate ? moment(showDate.split('T')[0]).format('MM/DD/YYYY') : null;
            const currentDate = moment(new Date().toISOString().split('T')[0]).format('MM/DD/YYYY');
            const daysDiff = moment(currentDate).diff(showDate, 'days');
            res.triggerDatePassed = daysDiff >= 0 ? true : false;
            this.displayMilestones[milestoneIndex] = res;
          }
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  viewIdCard(episode) {
    this.patientIdCard = { memberUuid: episode.patient.memberUuid, episodeId: episode.id };
  }

  idCardClose(event) {
    this.patientIdCard = event;
  }
  /**
   * Open chat modal for assigned Care Coordinator/Engagement Specialist
   * @param {int}userId id of user
   */
  openChat(userId) {
    if (this.user.id !== userId) {
      let userChat = { uid: null };
      userChat.uid = userId;
      //Send signal to store so that it can be updated
      this.store.dispatch(new Actions.GetUser(userChat));
    }
  }
  /**
   * Show selected milestone's type list
   * @param {any}event selected mmilestone
   */
  filterMilestones(event: { label: string; value: string }) {
    if (event.value === 'ALL') return (this.displayMilestones = this.episode.milestones);
    if (event.value === 'PRIMARY MILESTONES')
      return (this.displayMilestones = this.episode.milestones.filter(
        x => x.isKeyMilestone === true
      ));
    this.displayMilestones = this.episode.milestones.filter(x => x.status === event.value);
  }

  viewEOB(episode) {
    this.episodeEOB = episode;
  }

  viewEOBClose(event) {
    this.episodeEOB = event;
  }

  validate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX: number = 400;
    if (event.rectangle.width && event.rectangle.width < MIN_DIMENSIONS_PX) {
      return false;
    }
    return true;
  }

  onResizeEnd(event: ResizeEvent): void {
    this.style = {
      width: `${event.rectangle.width}px`,
    };
  }

  openProviderDetail(providerId, type = PROVIDER_TYPE.provider) {
    this.selectedProvider = { id: providerId, type: type };
  }

  closeProviderDetail(event) {
    this.selectedProvider = event;
  }

  onSendEmail(id: number) {
    this.metaVariablesDetail = { detail: this.episode, module: 'episode' };
    this.moduleOptions = {
      module: 'episodes',
      moduleId: id.toString(),
      type: 'communications',
    };
    this.showMessageModal = true;
  }

  closeMessageModal() {
    this.showMessageModal = false;
    this.messageThreadModal.hide();
  }

  /**
   * opens modal that displays the related message thread of the note event selected
   * @param id
   */
  onNoteSelected(id: number) {
    this.isLoading = true;
    id
      ? this._communicationService
        .getMessageThread(id, { moduleId: this.episode.id })
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(res => {
          if (res.rows.length) {
            const messageDate = res.rows[0].createdAt.split('T')[0];
            const todaysDate = moment().format('YYYY-MM-DD');
            messageDate === todaysDate
              ? (this.messageThread = res.rows.map((x: object) => ({
                ...x,
                extendedDate: moment(x['createdAt']).calendar(),
              })))
              : (this.messageThread = res.rows.map((x: object) => ({
                ...x,
                extendedDate: moment(x['createdAt']).format('llll'),
              })));
          }
        })
      : ((this.messageThread.length = 0), (this.isLoading = false));
    !this.dataToBeDelete && this.messageThreadModal.show();
  }

  onGroupDeselected() {
    this.milestoneForm.controls.groupCode.setValue(null);
  }

  hasValidEpisodeStatusForActions(): boolean {
    return ![
      EPISODE_STATUSES.CLOSED,
      EPISODE_STATUSES.CANCELLED,
      EPISODE_STATUSES.COMPLETED,
    ].includes(this.episode.status);
  }

  isMilestoneValidForUserActions(milestone: any) {
    return !milestone.triggerDatePassed && !milestone.status;
  }

  hasValidRoleForUserActions(): boolean {
    return (
      this.user.roleCode === ROLE_CODES.SUPER_ADMINISTRATOR ||
      [this.episode.ccId, this.episode.esId].includes(this.user.id)
    );
  }

  isMilestoneValidForActions(milestone: any) {
    return (
      this.episode.status === EPISODE_STATUSES.CONFIRMED &&
      milestone.showDate
    );
  }

  isMilestoneCompletedByNonPatientUsers(milestone: any): boolean {
    if (!milestone.triggerDatePassed) {
      return false;
    }

    if (
      milestone.completedByCcEs &&
      milestone.status === MILESTONE_STATUS.COMPLETED &&
      !this.isTriggeringMilestone(milestone)
    ) {
      return true;
    }

    if (milestone.status !== MILESTONE_STATUS.COMPLETED) {
      return true;
    }

    return false;
  }

  hasMilestoneCompletedWithoutTriggerDatePassed(milestone) {
    return (
      !milestone.triggerDatePassed &&
      milestone.status === MILESTONE_STATUS.COMPLETED &&
      !this.isTriggeringMilestone(milestone)
    );
  }

  /**
   * provide additional functions for a selected milestone
   * @param milestone
   * @returns
   */
  showMilestoneActions(milestone: any): boolean {
    if (this.usermode) {
      return false;
    }

    if (!this.hasValidEpisodeStatusForActions()) {
      return false;
    }

    if (this.isMilestoneValidForUserActions(milestone)) {
      return true;
    }

    if (!this.hasValidRoleForUserActions()) {
      return false;
    }

    if (!this.isMilestoneValidForActions(milestone)) {
      return false;
    }

    if (this.isMilestoneCompletedByNonPatientUsers(milestone)) {
      return true;
    }

    if (this.hasMilestoneCompletedWithoutTriggerDatePassed(milestone)) {
      return true;
    }

    return false;
  }

  isUserEligibleToChangeMilestoneStatus(): boolean {
    if (
      ![
        ROLE_CODES.CARE_COORDINATOR,
        ROLE_CODES.ENGAGEMENT_SPECIALIST,
        ROLE_CODES.SUPER_ADMINISTRATOR,
      ].includes(this.user.roleCode)
    ) {
      return false;
    }

    if (this.user.roleCode === ROLE_CODES.SUPER_ADMINISTRATOR) {
      return true;
    }

    if ([this.episode.ccId, this.episode.esId].includes(this.user.id)) {
      return true;
    }

    return false;
  }

  isMilestoneValidForStatusChange(milestone: any): boolean {
    return milestone.showDate && this.episode.status === EPISODE_STATUSES.CONFIRMED;
  }

  isMilestoneDateValidForStatusChange(milestone: any): boolean {
    return (
      milestone.triggerDatePassed ||
      (!milestone.triggerDatePassed && milestone.status === EPISODE_STATUSES.COMPLETED)
    );
  }

  /**
   * check if the user have authority to complete a milestone or undo the process
   * @param milestone
   * @returns
   */
  canChangeMilestoneStatus(milestone: any): boolean {
    return (
      this.isUserEligibleToChangeMilestoneStatus() &&
      this.isMilestoneValidForStatusChange(milestone) &&
      this.isMilestoneDateValidForStatusChange(milestone)
    );
  }

  getMilestoneCompletedText(milestone: any) {
    const { completedReason, status } = milestone;
    if (status !== MILESTONE_STATUS.COMPLETED) {
      return;
    }

    if (!this.isTriggeringMilestone(milestone)) {
      return completedReason;
    }

    return `${completedReason} and generated outgoing claim`;
  }

  canUndoMilestoneComplete(milestone: any) {
    if (milestone.status !== MILESTONE_STATUS.COMPLETED || !milestone.completedByCcEs) {
      return false;
    }

    if (this.isTriggeringMilestone(milestone)) {
      return false;
    }

    return true;
  }

  private isTriggeringMilestone(milestone: any) {
    const { fundingTriggerMilestone } = this.episode;
    if (!fundingTriggerMilestone?.length) {
      return false;
    }

    const validTriggerMilestone = fundingTriggerMilestone.find(
      triggerMilestone =>
        triggerMilestone.referenceType === FUNDING_TRIGGER_REFERENCE_TYPE.MILESTONE &&
        triggerMilestone.referenceUuid === milestone.uuid
    );
    if (!validTriggerMilestone) {
      return false;
    }

    return true;
  }

  private getMilestoneCompletionToasterMessage(milestone: any, status) {
    if (status !== MILESTONE_STATUS.COMPLETED) {
      return `Milestone status undo successfully`;
    }

    if (!this.isTriggeringMilestone(milestone)) {
      return `Milestone completed successfully`;
    }

    return `Milestone completed and generated outgoing claim successfully`;
  }
}
