import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ValueBasedProgramService {

    constructor(
        private http: HttpClientService
    ) { }

    getAll(query?) {
        return this.http.get(`${APICONSTANTS.VALUE_BASED_PROGRAM}`, query)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getValueBasedProgramByClentCode(queryParam?) {
        return new Promise((resolve, reject) => {
            this.http.get(`${APICONSTANTS.VALUE_BASED_PROGRAM}`, queryParam).toPromise()
                .then((res: any) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    saveValueBasedProgram(valueBasedProgram) {
        return this.http.post(`${APICONSTANTS.VALUE_BASED_PROGRAM}`, valueBasedProgram).pipe(
            map((response: any) => {
                return response.data
            })
        );
    }

    updateValueBasedProgram(valueBasedProgram) {
        return this.http.put(`${APICONSTANTS.VALUE_BASED_PROGRAM}/${valueBasedProgram.code}`, valueBasedProgram).pipe(
            map((response: any) => {
                return response.data
            })
        );
    }

    deleteValueBasedProgram(vbpId, queryParam?) {
        return this.http.delete(`${APICONSTANTS.VALUE_BASED_PROGRAM}/${vbpId}`, queryParam).pipe(
            map((response: any) => {
                return response.data
            })
        );
    }
}