import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class RolePermissionsService {
  constructor(private http: HttpClientService) { }

  getRoles(query) {
    return this.http.get(`${APICONSTANTS.ROLE}`, query).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getRoleByCode(code) {
    return this.http.get(`${APICONSTANTS.ROLE}/${code}`,).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getRolePermissionV2(roleCode?: string) {
    return new Promise((resolve, reject) => {
      this.http.get(`/v2${APICONSTANTS.ROLE}/user-role/${roleCode}${APICONSTANTS.PERMISSION}`).toPromise()
        .then((res: any) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getRolePermission(roleCode?: string) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.ROLE}/${roleCode}${APICONSTANTS.PERMISSION}`).toPromise()
        .then((res: any) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  saveRole(role) {
    return this.http.post(`${APICONSTANTS.ROLE}`, role).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateRole(role) {
    return this.http.put(`${APICONSTANTS.ROLE}/${role.code}`, role).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updatePermission(roleCode, permission) {
    return this.http.put(`${APICONSTANTS.ROLE}/${roleCode}${APICONSTANTS.PERMISSION}`, permission).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getUpdatedRoles(query: any) {
    return this.http.get(`${APICONSTANTS.ROLE_V2}`, query).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getUpdatedRolePermissions(params: any) {
    const { userId, roleCode } = params;
    const endPoint = params.userId ? `${APICONSTANTS.ROLE_V2}/user/${userId}${APICONSTANTS.PERMISSION}` : `${APICONSTANTS.ROLE_V2}/${roleCode}${APICONSTANTS.PERMISSION}`;
    return this.http.get(endPoint).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateRolePermissions(params: any, body) {
    const { userId, roleCode } = params;
    const endPoint = params.userId ? `${APICONSTANTS.ROLE_V2}/user/${userId}${APICONSTANTS.PERMISSION}` : `${APICONSTANTS.ROLE_V2}/${roleCode}${APICONSTANTS.PERMISSION}`;
    return this.http.put(endPoint, body).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  addRolePermissions(body: any) {
    return this.http.post(`${APICONSTANTS.ROLE_V2}`, body).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  deleteRolePermission(id: number) {
    return this.http.delete(`${APICONSTANTS.ROLE_V2}/${id}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
}
