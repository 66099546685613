export const DefaultEndNotes = {
  note1: "If you are experiencing a life-threatening emergency, go directly to the ER or call 911.",
  note2: "Any questions for your physician, please call your provider's office directly.",
  note3: "For non-emergent questions regarding your episode of care, please contact your Care Coordinator: hours are Monday-Friday 8:00am - 5:00pm (CST)"
};

export const ThemeContent = {
  holista: {
    welcomeTitle: "Welcome to Holista's episodes of care benefit portal",
    welcomeDescription: "",
    copyrightText: "Holista Health",
    phoneText1: "To get started ",
    phoneText2: "call us at ",
    phone: "888-277-9611",
    favicon: "favicon.ico",
    tabTitle: "Holista Health",
    footerText: {
      note1: DefaultEndNotes.note1,
      note2: DefaultEndNotes.note2,
      note3: DefaultEndNotes.note3
    }
  },
  novant: {
    welcomeTitle: "Welcome to Novant Health's episodes of care benefit portal",
    welcomeDescription: "Novant Health team members and dependents covered by our medical plan are eligible to select an innovative  approach to care through our episodes of care benefit option! Register to learn how you can save money and  receive an even higher standard of care.",
    copyrightText: "Novant Health",
    phoneText1: "To get started ",
    phoneText2: "call us at ",
    phone: "888-408-0558",
    favicon: "favicon-novant.ico",
    tabTitle: "Novant Health",
    footerText: {
      note1: DefaultEndNotes.note1,
      note2: DefaultEndNotes.note2,
      note3: DefaultEndNotes.note3
    }
  },
  oakleaf: {
    welcomeTitle: "Welcome to OakLeaf's episodes of care benefit portal",
    welcomeDescription: "",
    copyrightText: "Holista Health",
    phoneText1: "To learn more about the program that offers out of pocket savings and high standards of care, please ",
    phoneText2: "call 888-503-0349",
    phone: "888-503-0349",
    favicon: "favicon-oakleaf.ico",
    tabTitle: "OakLeaf Medical Network",
    footerText: {
      note1: DefaultEndNotes.note1,
      note2: DefaultEndNotes.note2,
      note3: DefaultEndNotes.note3
    }
  },
  hps: {
    welcomeTitle: "Welcome to Holista's episodes of care benefit portal",
    welcomeDescription: "",
    copyrightText: "Holista Health",
    phoneText1: "To get started ",
    phoneText2: "call us at ",
    phone: "855-240-9430",
    favicon: "favicon-hps.ico",
    tabTitle: "Health Payment System",
    footerText: {
      note1: DefaultEndNotes.note1,
      note2: DefaultEndNotes.note2,
      note3: DefaultEndNotes.note3
    }
  },
  provider: {
    welcomeTitle: "Welcome to the Holista Provider Web Portal for Episodes of Care products",
    welcomeDescription: "The Holista Provider Web Portal offers a streamlined process to electronically access all of your important program documents. When you register for the provider portal you will be able to quickly:" + "<ul class='fa-ul mt-4 ml-4'><li class='mt-2 mb-2'><span class='fa-li'><i class='fas fa-check-circle'></i></span> Track Claims</li><li class='mt-2 mb-2'><span class='fa-li'><i class='fas fa-check-circle'></i></span> View Remittance</li><li class='mt-2 mb-2'><span class='fa-li'><i class='fas fa-check-circle'></i></span> Access the Provider Manual </li></ul>",
    copyrightText: "Holista Health",
    phoneText1: "Registration, Training or Billing Questions ",
    phoneText2: "call ",
    phone: "855-844-0619",
    favicon: "favicon.ico",
    tabTitle: "Holista Provider",
    footerText: {
      note1: DefaultEndNotes.note1,
      note2: DefaultEndNotes.note2,
      note3: DefaultEndNotes.note3
    }
  },
};