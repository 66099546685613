import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
    selector: 'app-view-permissions',
    templateUrl: './view-permissions.component.html',
    styleUrls: ['./view-permissions.component.scss']
})
export class ViewPermissionComponent implements OnInit {

    Id: any;

    @ViewChild('viewPermissonModal', { static: true }) public viewPermissonModal;

    @Input() set id(id) {
        if (id) {
            this.Id = id;
            this.viewPermissonModal.show();
        }
    }
    @Input() type: string = 'role'
    @Input() userName: string;
    @Output() resetId = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    hideModal() {
        this.viewPermissonModal.hide();
        this.resetId.emit()
    }


}

