import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService, ToasterService } from "../../services";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PasswordValidation } from "src/app/directives/directive.password-match";
import { finalize } from 'rxjs/operators';
import { Storage } from '../../utils'
import { ResetPassword, SetPassword } from '../../models';
import { PASSWORD_SET_SUCCESS } from "../../constants";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  isPageInvalid: boolean = false;
  isCodeInvalid: boolean = false;
  email: string = "";
  isSetPassword = false;
  isPasswordActive = false;
  resetPasswordForm: FormGroup;
  userId: number
  company_code: string = "";
  npi: string = "";
  submitted: boolean = false;
  submit_enabled = false
  errorMessage = ''
  isProvider = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private _toastr: ToasterService,
    private _storage: Storage
  ) { }
  setSetPasswordForm() {
    this.errorMessage = ''
    this.resetPasswordForm = this.fb.group(
      {
        email: ['', Validators.required],
        password: [
          "", [
            Validators.required,
            Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/)
          ]
        ],
        confirmPassword: ["", Validators.required],
        npi: ["", Validators.minLength(10)]
      },
      { validator: PasswordValidation.MatchPassword }
    );
  }
  ngOnInit() {
    this._storage.remove('local', 'loggedInUser')
    this._storage.remove('session', 'userMessages')
    let code = this.activatedRoute.snapshot.queryParamMap.get("code");
    if (this.activatedRoute.snapshot.url[0].path === "set-password") {
      this.isSetPassword = true;
      this.setSetPasswordForm()
    } else {
      this.setSetPasswordForm()
    }
    if (code) {
      this.authService.verifyCode({ code: code }).subscribe(response => {
        if (response.data.isVerified) {
          let user = response.data.user;
          this.email = user.email
          this.userId = user.userId
          this.company_code = user.company_code
          this.resetPasswordForm.controls.email.setValue(response.data.user.email)
          this.isProvider = user.company_code === 'PROVIDER' ? true : false;
        } else {
          this.isCodeInvalid = true;
        }
      },
        error => {
          console.log(error)
          this.isCodeInvalid = true;
        });
    } else {
      this.isPageInvalid = true;
    }
  }
  resetPassword() {
    this.activatedRoute.queryParams.subscribe(({ code }) => {
      this._storage.set('session', 'passwordResetCode', code);
    });
    this.errorMessage = ''
    this.submitted = true;
    if (this.resetPasswordForm.valid) {
      this.submit_enabled = true;
      let model: ResetPassword = {
        email: this.email,
        password: this.resetPasswordForm.value.password,
        id: this.userId
      };
      this.authService.resetPassword(model).pipe(finalize(() => {
        this.submit_enabled = false;
      })).subscribe(
        response => {
          if (response.data.message) {
            if (response.data.message !== 'Incorrect Old Password') this._toastr.showError(response);
            this.errorMessage = response.data.message
          } else {
            this._toastr.displaySuccess(PASSWORD_SET_SUCCESS);
            this._storage.remove('session', 'passwordResetCode');
            this.router.navigate(["/login"]);
          }
        },
        error => {
          if (error && error.message) {
            this._toastr.showError(error);
            this.errorMessage = error.error.message
          }
          console.log(error)
        }
      );
    }
  }

  setPassword() {
    this.errorMessage = ''
    this.submitted = true;
    if (this.resetPasswordForm.valid) {
      this.submit_enabled = true;
      let model: SetPassword = {
        email: this.email,
        password: this.resetPasswordForm.value.password,
        id: this.userId,
        npi: this.resetPasswordForm.value.npi
      };
      this.authService.setPassword(model).pipe(finalize(() => {
        this.submit_enabled = false;
      })).subscribe(
        response => {
          if (response.data.message) {
            if (response.data.message !== 'Incorrect Old Password' || response.data.message !== 'Password combination contains banned word.') this._toastr.showError(response);
            this.errorMessage = response.data.message
          } else {
            this._toastr.displaySuccess(PASSWORD_SET_SUCCESS);
            this.router.navigate(["/login"]);
          }
        },
        error => {
          this._toastr.showError(error)
          if (error && error.message) {
            this.errorMessage = error.error.message
          }
          console.log(error)
        }
      );
    }
  }

}
