import { Subject, Observable } from 'rxjs';
export class EpisodeCreate {
    selected_episodeCare: any
    facilityList = []
    providerList = []
    clinicList = []
    search_member: any
    networkCode: string
    episodeCareMarketCodes: any = []
    getInfo: boolean = false
    episodeCareList = []
    selectedFacilityMarketCode: any
    selected_CC: SelectedCcEs
    selected_ES: SelectedCcEs
    tagsList = []
    vbpCode: string
    bundleComponentProviders: any
    isGetProviders: boolean = false;
    triggerComponent?: any
}

export class CreateUserSearch {
    user_search_text = new Subject();
    user_results: Observable<any>;
}

class SelectedCcEs {
    label: string;
    value: number;
}