import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VERTICAL_BAR_OPTIONS, MessageConstants, NO_DATA_IN_REPORT, REPORT_NAME_CODES, BAR_CHART_DEFAULT_COLOR_CONFIG, REPORT_TAB_INDEX, UPDATE_FAILED, UPDATE_SUCCESS, ADD_SUCCESS, ADD_FAILED } from '../../../../constants';
import { DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility } from '../../../../utils';
import { finalize, map, startWith } from 'rxjs/operators';
import { MarketService, ReportService, ToasterService } from '../../../../services';
import { Observable, Subject, forkJoin } from 'rxjs';

@Component({
  selector: 'app-service-category-utilization',
  templateUrl: './service-category-utilization.component.html',
  styleUrls: ['./service-category-utilization.component.scss']
})
export class ServiceCategoryUtilizationComponent implements OnInit {

  serviceCategoryUtilizationForm: FormGroup;
  REPORT_NAME_CODES = REPORT_NAME_CODES;
  reportNameAndCodeList: any[] = [];
  parsedClientClaimsFilterData: any;
  toShowReport: any[] = [];
  baseConfigs = {
    options: {},
    plugins: [],
    labels: [],
    dataset: [],
    chartType: '',
    actualData: null,
  };
  serviceCategoryUtilizationChart = this._holistaUtils.deepClone(this.baseConfigs);
  loading = {
    serviceCategoryUtilizationChart: false,
    serviceCategories: false,
    saveServiceCategories: false,
    categories: false,
    marketList: false,
    categoryList: false,
  };
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT);
  marketList: any[] = [];
  searchFilterKeyword: string = '';
  serviceCategories: any[] = [];
  categorySearchText = new Subject();
  categoryResults: Observable<any>;
  episodeCategoryList: any[] = [];
  serviceCategoryList: any[] = [];
  episodeCategorySelected: string = '';

  @ViewChild('filterModal', { static: true }) public filterModal;

  @Input() set clientClaimsDashboardFilterData(data) {
    if (data && data.activeTabIndex === REPORT_TAB_INDEX.SERVICE_CATEGORY_UTILIZATION) {
      this.parsedClientClaimsFilterData = this._holistaUtils.deepClone(data);
      const { networkCode, endDate, toShowReport } = this.parsedClientClaimsFilterData;
      networkCode && !this.episodeCategoryList.length && this.getEpisodeCategoryList({ networkCode });
      this.toShowReport = toShowReport && toShowReport.map((x) => x.reportCode);
      this.reportNameAndCodeList = toShowReport && toShowReport.map((x) => ({
        reportName: x.customReportName,
        reportCode: x.reportCode
      }));
      this.getServiceCategories();
      const year = +this._dateUtility.getYearFromDate(endDate);
      const additionalConditons = toShowReport.length && networkCode && year && this.serviceCategoryUtilizationForm.get('episodeCategoryId').value;
      if (this.toShowReport.includes(REPORT_NAME_CODES.BUNDLE_COMPONENT_UTILIZATION) && additionalConditons) {
        this.getServiceCategoryUtilizationChart();
      };
      this.getMarketList();
    }
  }

  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === REPORT_TAB_INDEX.SERVICE_CATEGORY_UTILIZATION) {
      const { networkName, networkCode, startDate, endDate } = this.parsedClientClaimsFilterData;
      const tabsData = [];

      if (this.toShowReport.includes(REPORT_NAME_CODES.OVERALL_SERVICE_CATEGORY_CYCLE_TIME) && this.serviceCategoryUtilizationChart.labels.length) {
        const body = this._reportMappingUtils.percentageBarChartLabelAndDataMapper(this.serviceCategoryUtilizationChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: `${this.episodeCategorySelected} - ${this.serviceCategoryUtilizationForm.get('marketCode').value ? (this.serviceCategoryUtilizationForm.get('marketCode').value + ' - ') : ''}Service Category Utilization`,
          tabName: 'Service Category Utilization',
          headers: ['Categories', 'Percentage'],
          data: body,
          reportDate: `Period: ${startDate} - ${endDate}`,
        });
      };

      if (isDownloadPDF.type === 'pdf') {
        const downloadPDF = this._pdfUtility.convertToPDF(['service-category-utilization'], 'Client Claims Dashboard - Service Category Utilization', true, true);
        downloadPDF.finally(() => {
          this.downloadedPDF.emit(true);
        });
      };

      if (tabsData.length && isDownloadPDF.type === 'excel') {
        const params = {
          networkCode,
          episodeCategoryId: this.serviceCategoryUtilizationForm.get('episodeCategoryId').value,
          ...(this.serviceCategoryUtilizationForm.get('marketCode').value && { marketCode: this.serviceCategoryUtilizationForm.get('marketCode').value }),
          startDate,
          endDate
        };
        this._reportService.getBundleComponentUtilizationDetail(params)
          .pipe()
          .subscribe(response => {
            tabsData.push({
              clientName: networkName,
              reportName: `${this.episodeCategorySelected} - Service Category Utilization Detail`,
              tabName: 'Service Category Utilization Detail',
              headers: ['Network Code', 'Client Code', 'Market Code', 'Episode ID', 'Episode Name', 'Bundle Component Category', 'Claim Received'],
              data: response.map(detail => [detail.networkCode, detail.clientCode, detail.marketCode, detail.episodeId, detail.episodeName, detail.bundleComponentGroupName, detail.exist ? 'Yes' : 'No']),
              reportDate: `Period: ${startDate} - ${endDate}`
            });
            this._excelMultiTabUtility.exportToExcelMultiTab('Client Claims Dashboard - Service Category Utilization', tabsData).finally(() => {
              this.downloadedPDF.emit(true);
            })
          },
            error => console.log('error', console.log('error getting service category utilization detail', error))
          );
      };
    }
  }

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private _holistaUtils: HolistaUtils,
    private _messageConstants: MessageConstants,
    private _reportService: ReportService,
    private _toastr: ToasterService,
    private _dateUtility: DateUtility,
    private _reportMappingUtils: ReportMappingUtility,
    private _pdfUtility: PDFUtility,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
    private _marketService: MarketService,
  ) { }

  ngOnInit(): void {
    this.setServiceCategoryUtilizationForm();
    this.categoryResults = this.categorySearchText.pipe(
      startWith(''),
      map((value: string) => this.getCategorySearchResult(value))
    );
  }

  ngOnDestroy() {
    this.resetFlags();
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
  }

  resetFlags() {
    this.loading = {
      ...this.loading,
      serviceCategoryUtilizationChart: false
    };
  }

  resetAllReports() {
    this.serviceCategoryUtilizationChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  setServiceCategoryUtilizationForm() {
    this.serviceCategoryUtilizationForm = this._formBuilder.group({
      marketCode: [null],
      episodeCategoryId: [null],
    });
  }

  getCategorySearchResult(searchText: string): any[] {
    if (searchText) {
      return this.serviceCategoryList.filter(category => category.name.toLowerCase().includes(searchText.toLowerCase()));
    }
  }

  getServiceCategoryUtilizationChart() {
    this.loading.serviceCategoryUtilizationChart = true;
    const { networkCode, startDate, endDate } = this.parsedClientClaimsFilterData;
    const params = {
      networkCode,
      episodeCategoryId: this.serviceCategoryUtilizationForm.get('episodeCategoryId').value,
      ...this.serviceCategoryUtilizationForm.get('marketCode').value && { marketCode: this.serviceCategoryUtilizationForm.get('marketCode').value },
      startDate,
      endDate
    };
    this._reportService.getServiceCategoryUtilizationChart(params)
      .pipe(finalize(() => {
        this.loading.serviceCategoryUtilizationChart = false;
        this.updateDownloadEnabledState();
      }))
      .subscribe(response => {
        this.serviceCategoryUtilizationChart = this._holistaUtils.deepClone(this.baseConfigs);
        if (response.percentage?.length) {
          this.serviceCategoryUtilizationChart.dataset.push({
            "borderWidth": 2,
            "fill": false,
            "lineTension": 0,
            "barThickness": 70,
            "spanGaps": true,
            "backgroundColor": BAR_CHART_DEFAULT_COLOR_CONFIG.backgroundColor,
            "hoverBackgroundColor": BAR_CHART_DEFAULT_COLOR_CONFIG.backgroundColor,
            "borderColor": BAR_CHART_DEFAULT_COLOR_CONFIG.borderColor,
            "hoverBorderColor": BAR_CHART_DEFAULT_COLOR_CONFIG.borderColor,
            "data": response.percentage.map(percent => percent.toFixed(2))
          });
        };
        this.serviceCategoryUtilizationChart.options = VERTICAL_BAR_OPTIONS;
        this.serviceCategoryUtilizationChart.labels = response.labels;
        this.serviceCategoryUtilizationChart.actualData = response;
      },
        error => console.log('error getting service component utilization data', error)
      );
  }

  getReport(code: string) {
    return this.reportNameAndCodeList.find(report => report.reportCode === code) || null;
  }

  onMarketSelected(event) {
    this.serviceCategoryUtilizationForm.get('episodeCategoryId').value && this.getServiceCategoryUtilizationChart();
  }

  onEpisodeCategorySelected(event) {
    this.episodeCategorySelected = event.label;
    this.getServiceCategoryUtilizationChart();
  }

  showfilterModal() {
    this.filterModal.show();
  }

  closeModal() {
    this.searchFilterKeyword = null;
    this.filterModal.hide();
  }

  applyFilter() {
    this.closeModal();
    this.loading.serviceCategoryUtilizationChart = true;
    this._reportService.updateServiceCategoryGroupConfigurations(this.serviceCategories)
      .pipe(finalize(() => { }))
      .subscribe(response => {
        if (this.serviceCategoryUtilizationForm.get('episodeCategoryId').value) {
          response ? (this.getServiceCategoryUtilizationChart(), this._toastr.displaySuccess(UPDATE_SUCCESS)) : this._toastr.displayError(UPDATE_FAILED);
          return;
        };
        this._toastr.displaySuccess(UPDATE_SUCCESS);
      },
        error => console.log('error updating service category group configuration', error)
      );
  }

  onServiceCategoryChecked(event, category) {
    this.serviceCategories = this.serviceCategories.map(serviceCategory => {
      if (serviceCategory.code === category.code) {
        return {
          ...serviceCategory,
          isActive: event.checked
        };
      };
      return serviceCategory;
    });
  }

  getMarketList() {
    this.loading.marketList = true;
    this.serviceCategoryUtilizationChart = this._holistaUtils.deepClone(this.baseConfigs);
    const params = {
      networkCode: this.parsedClientClaimsFilterData.networkCode
    };
    this._marketService.getAll(params)
      .pipe(finalize(() => this.loading.marketList = false))
      .subscribe((response: any) => {
        this.marketList = response.rows.map(({ name, code }) => ({ label: `${name} - ${code}`, value: code }));
      }, error => console.log('error getting market list', error))
  }

  getEpisodeCategoryList(params?) {
    this.loading.categoryList = true;
    this._reportService.getCategoryList(params)
      .pipe(finalize(() => { this.loading.categoryList = false; }))
      .subscribe((response) => {
        if (response.length) {
          this.episodeCategoryList = response.map(({ id, name }) => ({ label: name, value: id.toString() }));
        };
      }, error => {
        console.log("Error getting category list", error);
      })
  }

  getServiceCategories() {
    this.loading.serviceCategories = true;
    forkJoin([
      this._reportService.getServiceCategoryGroupConfigurations({ networkCode: this.parsedClientClaimsFilterData.networkCode, limit: 0 }),
      this._reportService.getBundleComponentCategories({ networkCode: this.parsedClientClaimsFilterData.networkCode })
    ])
      .pipe(finalize(() => { this.loading.serviceCategories = false; }))
      .subscribe(([configuration, categories]) => {
        this.serviceCategories = configuration.rows;
        this.serviceCategories.sort((a, b) => a.isActive !== b.isActive ? a.isActive ? -1 : 1 : a.name.localeCompare(b.name));
        this.serviceCategoryList = categories.map((category: any) => ({ ...category, label: category.name, value: category.code }));
        this.serviceCategories.length && this.getCategorySearchOptions(categories);
      },
        error => console.log('error getting service categories', error)
      );
  }

  onCategorySelected(event) {
    this.loading.categories = true;
    const { name, code } = event;
    const params = {
      name,
      code,
      isActive: true,
      networkCode: this.parsedClientClaimsFilterData.networkCode
    };
    this._reportService.createServiceCategoryGroupConfigurations(params)
      .pipe(finalize(() => { this.loading.categories = false; }))
      .subscribe(response => {
        this.serviceCategories.push(response);
        this.getCategorySearchOptions(this.serviceCategoryList);
        this._toastr.displaySuccess(ADD_SUCCESS);
        this.categorySearchText.next('');
      },
        error => {
          this._toastr.displayError(ADD_FAILED)
          console.log('error adding category configuration', error);
        }
      );
  }

  /**
   * filter available search options based on listed categories
   * @param categories
   * @returns 
   */
  getCategorySearchOptions(categories) {
    this.serviceCategoryList = categories.filter(category => !this.serviceCategories.some(service => service.code === category.code));
  }

  onClear(type: string) {
    type === 'category' ? this.serviceCategoryUtilizationChart = this._holistaUtils.deepClone(this.baseConfigs) : this.getServiceCategoryUtilizationChart();
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = this.serviceCategoryUtilizationChart.labels.length;
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

  showUtilizationChart(): boolean {
    return !this.loading.serviceCategoryUtilizationChart && this.serviceCategoryUtilizationChart.dataset.length && true;
  }

  dataNotAvailable(): boolean {
    return this.serviceCategories.length && this.serviceCategoryUtilizationForm.get('episodeCategoryId').value && !this.loading.serviceCategoryUtilizationChart && !this.serviceCategoryUtilizationChart.dataset.length;
  }

  areServiceCategoriesAvailable(): boolean {
    return !this.loading.serviceCategories && this.serviceCategories.length && true;
  }

  serviceCategoriesNotFound(): boolean {
    return !this.loading.serviceCategories && !this.serviceCategories.length;
  }

  onCateogryDisplayValue(category?): string | undefined {
    return category.businessName;
  };

  isServiceCategoryNotSelected(): boolean {
    return !this.loading.serviceCategoryUtilizationChart && this.serviceCategoryUtilizationForm.get('episodeCategoryId').value && !this.serviceCategories.length;
  }
}
