<div id="by-volume" class="container-fluid" *ngIf="!data || data.length; else noData">
  <div class="grid-item w-100">
    <div class="mb-2" id="by-volume-chart" style="height: 350px">
      <canvas
        baseChart
        [datasets]="data"
        [labels]="labels"
        [options]="chartOptions"
        [chartType]="chartType"></canvas>
    </div>
  </div>
  <hr />
  <div class="mb2">
    <div class="grid-item w-100">
      <ng-container *ngIf="tableItems$ | async as tableItems">
        <app-table [report]="tableItems" [exportTable]="false" [module]="'report'"></app-table>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #noData>
  <div>
    <p>{{ noDataMessage.value }}</p>
  </div>
</ng-template>
