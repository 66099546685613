import { Injectable, Injector } from "@angular/core";
import { Storage } from '../utils/storageUtility';
import { ToasterService } from '../services/toasterService'

@Injectable()

export class AccessType {
    constructor(
        private injector: Injector,
        private toastr: ToasterService,
    ) { }
    public searchAccess(code: string, action: string, notify = true) {
        const _storage = this.injector.get(Storage);
        const loggedInUser = _storage.get('local', 'loggedInUser');
        if (loggedInUser && loggedInUser.user) {
            const loggedInUserPermissions = loggedInUser.user.rolePermissions
            const access = loggedInUserPermissions.find(x => x.permissionCode === code)
            if (action === 'isVisible') {
                if (access && access.accessType !== null) return true;
                return false;
            }
            if (action === 'isEditable') {
                if (access && access.accessType === "W") return true;
                return notify ? this.toastr.displayWarning('ACCESS_DENIED') : false;
            }
        }
        return false
    }
}