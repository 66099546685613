import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from 'src/app/utils';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {
  user: any
  @Input() claims;

  constructor(
    public router: Router,
    private _storage: Storage
  ) { }

  ngOnInit() {
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    this.claims.providers.map(y => {
      y.providerClaims = this.claims.valid.filter(x => x.ediTransaction.billingProvider.basicInfo.identificationCode == y.identificationCode)
      if (this.user.roleCode === "CLA") {
        const claims = [...this.claims.valid, ...this.claims.invalid]
        y.providerClaims = claims.filter(x => x.ediTransaction.billingProvider.basicInfo.identificationCode == y.identificationCode)
      }
      return y
    })
  }

  redirectToClaim(claim) {
    this.router.navigate(['/claims'])
    let claimIdentifier = claim.patientAccountNumber
    this._storage.set('session', 'searchClaim', claimIdentifier)
  }
}
