import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployerGroupService, EpisodeOfCareService, FundingSourceService, PurchaserService, ToasterService, ValueBasedProgramService, ClientService } from '../../services';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../action';
import { finalize, startWith, switchMap, debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { AccessType, FormatPhoneNumber, HolistaUtils, Storage } from '../../utils';
import { Observable, Subject, of } from "rxjs";
import { ACCESS_DENIED, ADD_SUCCESS, DELETE_SUCCESS, MaskConstant, PhoneFormat, RestrictSpace, UPDATE_SUCCESS, US_STATES, ValidEmail } from "../../constants";
import * as moment from "moment";

@Component({
    selector: 'app-purchaser',
    templateUrl: './purchaser.component.html'
})

export class PurchaserComponent implements OnInit {

    @ViewChild('purchaserModal', { static: true }) public purchaserModal;
    @ViewChild('valueBasedProgramModal', { static: true }) public valueBasedProgramModal;
    @ViewChild('fundingSourceModal', { static: true }) public fundingSourceModal;
    @ViewChild('employerGroupModal', { static: true }) public employerGroupModal;
    @ViewChild('associatedEOCModal', { static: true }) public associatedEOCModal;
    selectedValueBasedProgramEOCs: any = {};
    usStates = US_STATES;
    loadingData = {
        purchasers: false,
        clients: false,
        vbp: false,
    };
    query = {
        limit: 10,
        page: 1,
        keyword: '',
        sortType: 'desc',
        sortBy: 'createdAt',
        clientCode: '',
        searchFields: 'tin,name'
    }
    totalCount = 0;
    reverse: boolean = true;
    deleteId: any;
    message: any;
    deleteModelName: string = '';
    childObj: any = {};
    submitted: boolean = false;
    submit_enabled: boolean = false;
    result = { searchedPurchasers: true, purchasers: true };
    purchaserForm: FormGroup;
    valueBasedProgramForm: FormGroup;
    employerGroupForm: FormGroup;
    fundingSourceForm: FormGroup;
    searchModelChanged: Subject<string> = new Subject<string>();
    user: any;
    purchasers: any[] = [];
    vbpList: any[] = [];
    client_search_text = new Subject();
    client_results: Observable<any>;
    selectedVbp: any = {}
    dateMask: any[] = MaskConstant.DATE;
    phoneMask: any[] = MaskConstant.PHONE;
    selectedPurchaser: any = {};
    clientCode: string;
    clients: any[] = []

    constructor(private fb: FormBuilder,
        private store: Store<{ bread_crumbs: any }>,
        private toastr: ToasterService,
        public utilityAccess: AccessType,
        private purchaserService: PurchaserService,
        private clientService: ClientService,
        private valueBasedProgramService: ValueBasedProgramService,
        private fundingSourceService: FundingSourceService,
        private employerGroupService: EmployerGroupService,
        private episodeOfCareService: EpisodeOfCareService,
        // private _scrollTo: ScrollTo,
        private _storage: Storage,
        public holistaUtil: HolistaUtils,
        private _formatPhone: FormatPhoneNumber
    ) {
        this.setPurchaserForm();
        this.setValueBasedProgramForm();
        this.setEmployerGroupForm();
        this.setFundingSourceForm();
        this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
            this.query.keyword = keyword ? keyword.trim() : '';
            this.query.page = 1;
            this.getPurchasers();
        });
    }

    ngOnInit() {
        this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Purchasers', path: '/purchaser' }));
        this.user = this._storage.get('local', 'loggedInUser', 'user')
        this.getPurchasers();
        if (this.user.roleCode !== 'PUA')
            this.getClients();
        this.client_results = this.client_search_text
            .pipe(
                startWith(this.client_search_text),
                switchMap((client_search_text: string) => this.searchClient(client_search_text))
            );
    }

    searchKeywordChange(text) {
        this.searchModelChanged.next(text);
    }

    searchClient(value) {
        if (value.length > 2) {
            return this.clientService.getClients({ keyword: value, limit: 0, defaultIncludes: false })
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items.rows;
                    })
                );
        } else
            return of([]);
    }

    displayClient = (client?): string | undefined => {
        return client ? this.toTitleCase(client.name) : undefined;
    };

    toTitleCase(name) {
        return name.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    selectedClient: any = {}

    clientSelected(event) {
        this.valueBasedProgramForm.controls.clientCode.setValue((event.text && event.text.code) ? event.text.code : event.code);
        this.getValueBasedProgramsByClientCode(event.text && event.text.code ? event.text.code : event.code)
        this.selectedClient = event.text && event.text.code ? event.text : event;
    }

    getValueBasedProgramsByClientCode(code) {
        this.loadingData.vbp = true
        this.valueBasedProgramService.getAll({ clientCode: code, limit: 0 })
            .pipe(finalize(() => { this.loadingData.vbp = false }))
            .subscribe((res) => {
                if (res)
                    this.vbpList = res.rows.map(x => { x.label = x.name; x.value = x.code; return x })
            }, (error) => {
                console.log("Error getting value based program list", error);

            })
    }

    setPurchaserForm() {
        this.purchaserForm = this.fb.group({
            id: [null],
            code: [''],
            name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
            addressLine1: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
            addressLine2: ['', Validators.pattern(RestrictSpace)],
            city: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
            state: ['', Validators.required],
            zip: [null, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
            zip4: [null, Validators.pattern(/^[0-9]{4}$/)],
            tin: ['', [Validators.required, Validators.pattern(/^[0-9]{9}$/)]],
            number: ['', Validators.required],
            contactName: ['', [Validators.required]],
            contactEmail: ['', [Validators.required, Validators.pattern(ValidEmail)]],
            contactPhone: ['', [Validators.required, Validators.pattern(PhoneFormat)]],
            phone: ['', [Validators.required, Validators.pattern(PhoneFormat)]]
        })
    }

    setValueBasedProgramForm() {
        this.valueBasedProgramForm = this.fb.group({
            id: [''],
            purchaserCode: ['', Validators.required],
            clientCode: ['', Validators.required],
            vbpCode: ['', Validators.required],
            effectiveDate: [''],
            terminationDate: [''],
        })
    }

    setEmployerGroupForm() {
        this.employerGroupForm = this.fb.group({
            id: [null],
            name: ['', Validators.required],
            number: ['', Validators.required],
            code: ['', Validators.required],
            purchaserCode: [],
        })
    }

    setFundingSourceForm() {
        this.fundingSourceForm = this.fb.group({
            id: [],
            name: ['', Validators.required],
            purchaserCode: [],
            code: [],
            identifier: [null, Validators.required],
            tin: ['', [Validators.required, Validators.pattern(/^[0-9]{9}$/)]],
            npi: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
            addressLine1: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
            addressLine2: ['', Validators.pattern(RestrictSpace)],
            city: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
            state: ['', Validators.required],
            zip: [null, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
            zip4: [null, Validators.pattern(/^[0-9]{4}$/)],
            contactName: [''],
            contactEmail: ['', [Validators.pattern(ValidEmail)]],
            contactPhone: ['', [Validators.pattern(PhoneFormat)]],
            holistaBillingNpi: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]]
        })
    }

    getPurchasers() {
        this.loadingData.purchasers = true;
        this.result.purchasers = true;
        this.result.searchedPurchasers = true;
        this.purchaserService.getAll(this.query)
            .pipe(finalize(() => { this.loadingData.purchasers = false; }))
            .subscribe((response: any) => {
                if (response && response.count > 0) {
                    this.totalCount = response.count;
                    response.rows.map(x => {
                        x.clientNames = x.clients && x.clients.length > 0 ? x.clients.map(c => c.name) : []
                        x.contactPhone = this._formatPhone.phoneNumber(x.contactPhone);
                        x.phone = this._formatPhone.phoneNumber(x.phone);
                        return x
                    })
                    this.purchasers = response.rows;
                }
                else {
                    this.purchasers = [];
                    this.query.keyword.length > 0 ? (this.result.searchedPurchasers = false) : (this.result.purchasers = false);
                }
            }, (error) => {
                console.log('Error fetching purchasers', error);
                this.toastr.showError(error)
            })
    }

    getClients() {
        this.clientService.getClients({ limit: 0, fields: 'code,name' })
            .subscribe((res) => {
                res.rows.map(c => {
                    c.label = c.name;
                    c.value = c.code;
                    return c;
                })
                this.clients = res.rows
            }, (error) => {
                console.log("Error getting client list", error);
                this.toastr.showError(error)
            })
    }

    filterByClient(event, type?) {
        this.query.clientCode = (type && type == 'remove') ? '' : event.value;
        this.query.page = 1;
        if (!type)
            this.clients = this.clients.map(x => {
                if (x.value == event.value) x.disabled = true
                else x.disabled = false
                return x
            })
        else
            this.clients = this.clients.map(x => { x.disabled = false; return x })
        this.getPurchasers();
    }

    getDetails(purchaser) {
        if (purchaser) {
            if (!purchaser.vbpData)
                this.getValueBasedProgramsInfo(purchaser);
            if (!purchaser.employerGroupData)
                this.getEmployerGroups(purchaser);
        }
    }

    getValueBasedProgramsInfo(purchaser) {
        purchaser.vbpData = true
        this.purchaserService.getValueBasedProgramsByPurchaserCode(purchaser.code, { defaultIncludes: true, limit: 0 })
            .then((response: any) => {
                if (response) {
                    purchaser['valueBasedPrograms'] = []
                    if (response.count == 0) purchaser.vbpData = false
                    response.rows.map((vbp, i) => {
                        this.getEocByVbp(purchaser, vbp, response.count, i)
                    })
                }
            }, (error) => {
                console.log("Error getting value based programs", error);
                this.toastr.showError(error)
            })
    }

    getEocByVbp(purchaser, vbp, totalVbp?, vbpIndex?) {
        this.episodeOfCareService.getEocByVbpCode(vbp.vbpCode, { limit: 0, fields: 'name,uuid', defaultIncludes: false })
            .then((res: any) => {
                if (res) {
                    vbp.vbp['episodeOfCares'] = res.rows
                    purchaser.valueBasedPrograms.push(vbp)
                    if (totalVbp == vbpIndex + 1)
                        purchaser.vbpData = false
                }
            }, (error) => {
                console.log("Error getting EOC by vbp code", error);
                this.toastr.showError(error)
            })
    }

    getEmployerGroups(purchaser) {
        purchaser.employerGroupData = true
        this.employerGroupService.getAll({ purchaserCode: purchaser.code, limit: 0 })
            .subscribe((res: any) => {
                if (res) {
                    if (res.count == 0) purchaser.employerGroupData = false
                    purchaser['employerGroups'] = res.rows
                }
            }, (error) => {
                console.log("Error getting employer groups", error);
                this.toastr.showError(error)
            })
    }

    pageChanged(event) {
        this.query.page = event;
        this.getPurchasers();
    }

    setOrder(value: string) {
        if (this.query.sortBy === value) {
            this.reverse = !this.reverse;
            this.query.sortType = this.query.sortType === "desc" ? "asc" : "desc";
        }
        else {
            this.reverse = true;
            this.query.sortType = "desc";
        }
        this.query.sortBy = value;
        this.getPurchasers();
    }

    openPurchaserModal(purchaser?) {
        if (this.utilityAccess.searchAccess('PURM', 'isEditable')) {
            if (purchaser) {
                this.purchaserForm.patchValue(purchaser)
            }
            this.purchaserModal.show();
        }
        else {
            this.toastr.displayWarning(ACCESS_DENIED);
        }
    }

    closePurchaserModal() {
        this.purchaserModal.hide();
        this.purchaserForm.reset();
        this.submitted = false;
    }

    setNullForEmpty(formName) {
        Object.keys(formName.value).forEach(k => {
            formName.controls[k].setValue(formName.value[k] || typeof formName.value[k] == 'boolean' ? formName.value[k] : null)
        })
    }

    submitPurchaser() {
        this.submitted = true;
        if (this.purchaserForm.valid) {
            this.setNullForEmpty(this.purchaserForm)
            this.submit_enabled = true;
            if (this.purchaserForm.value.id) {
                let body = this.purchaserForm.getRawValue()
                body.phone = body.phone ? body.phone.replace(/\D/g, '') : ''
                this.purchaserService.update(body)
                    .pipe(finalize(() => { this.submit_enabled = false; }))
                    .subscribe(res => {
                        if (res) {
                            let index = this.purchasers.findIndex(x => x.id == res.id)
                            if (index > -1)
                                if (res.clients && res.clients.length > 0) res.clientNames = res.clients.map(c => c.name)
                                else res.clientNames = ''
                            res.contactPhone = this._formatPhone.phoneNumber(res.contactPhone);
                            res.phone = this._formatPhone.phoneNumber(res.phone);
                            this.purchasers[index] = res
                            this.toastr.displaySuccess(UPDATE_SUCCESS);
                            this.closePurchaserModal();
                        }
                    }, (error) => {
                        console.log("Error updating Purchaser", error);
                        this.toastr.showError(error)
                    });
            }
            else {
                let body = this.purchaserForm.value;
                body.code = body.name.replace(/[^\w]/g, "").toUpperCase()
                body.phone = body.phone ? body.phone.replace(/\D/g, '') : ''
                delete body.id
                this.purchaserService.create(body)
                    .pipe(finalize(() => { this.submit_enabled = false; }))
                    .subscribe(res => {
                        if (res) {
                            this.query.page = 1
                            this.getPurchasers()
                            this.toastr.displaySuccess(ADD_SUCCESS);
                            this.closePurchaserModal();
                        }
                    }, (error) => {
                        console.log("Error saving Purchaser", error);
                        this.toastr.showError(error);
                    });
            }
        }
    }

    delete(model, type, purchaser?) {
        if (this.utilityAccess.searchAccess('PURM', 'isEditable')) {
            this.childObj = model;
            this.deleteId = type === 'purchaser' ? model.code : model.id;
            this.deleteModelName = type;
            if (type == 'vbp' && purchaser) {
                this.selectedPurchaser = purchaser;
                this.clientCode = model.clientCode;
                this.deleteId = model.code;
            }
            this.message = `Are you sure you want to delete <strong>${model.name}</strong>? This is irreversible.
            If you're not sure, you can click <strong>Cancel</strong>.`
        }
        else
            this.toastr.displayWarning(ACCESS_DENIED);
    }

    deleteConfirm(id) {
        if (id) {
            switch (this.deleteModelName) {
                case 'purchaser':
                    this.deletePurchaser(id)
                    break;
                case 'vbp':
                    this.deleteValueBasedProgram(id)
                    break;
                // case 'funding-source':
                //     this.deleteFundingSource(id)
                //     break;
                case 'employer-group':
                    this.deleteEmployerGroup(id)
                    break;

                default:
                    break;
            }
        }
        else this.deleteId = null;
    }

    deletePurchaser(code) {
        this.purchaserService.delete(code, 'code')
            .subscribe((response: any) => {
                if (response) {
                    let index = this.purchasers.findIndex(x => x.code === code);
                    if (index > -1) {
                        this.toastr.displaySuccess(DELETE_SUCCESS);
                        this.getPurchasers()
                    }
                }
            }, (error) => {
                console.log("Error deleting Purchaser ", error);
                this.toastr.showError(error);
                this.deleteId = null;
            })
    }

    deleteValueBasedProgram(id) {
        this.purchaserService.deleteVbpForPurchaser(this.selectedPurchaser.code, this.clientCode, id)
            .subscribe((response: any) => {
                if (response) {
                    let index = this.purchasers.findIndex(x => x.id == this.selectedPurchaser.id);
                    if (index > -1) {
                        let index2 = this.purchasers[index].valueBasedPrograms.findIndex(y => y.vbpCode == id)
                        if (index2 > -1) {
                            this.purchasers[index].valueBasedPrograms.splice(index2, 1);
                            this.toastr.displaySuccess(DELETE_SUCCESS);
                            if (this.purchasers[index].valueBasedPrograms.length == 0) {
                                this.purchasers[index].clientNames = []
                                this.purchasers[index].clients = []
                            }
                            else {
                                this.purchasers[index].clients = this.purchasers[index].clients.filter(c => this.purchasers[index].valueBasedPrograms.findIndex(v => v.vbp.clientCode == c.code) > -1)
                                this.purchasers[index].clientNames = this.purchasers[index].clients.length > 0 ? this.purchasers[index].clients.map(c => c.name) : []
                            }
                        }
                    }
                }
            }, (error) => {
                console.log("Error deleting Purchaser ", error);
                this.toastr.showError(error);
                this.deleteId = null;
            })
    }

    // deleteFundingSource(id) {
    //     this.fundingSourceService.delete(id, 'id')
    //         .subscribe((response: any) => {
    //             if (response) {
    //                 let purchaserIndex = this.purchasers.findIndex(x => x.code == this.childObj.purchaserCode);
    //                 if (purchaserIndex > -1) {
    //                     this.purchasers[purchaserIndex].fundingSource = null
    //                     this.toastr.displaySuccess(`Funding Source Deleted Successfully.`);
    //                 }
    //             }
    //         }, (error) => {
    //             console.log("Error deleting funding source ", error);
    //             this.toastr.showError(error);
    //             this.deleteId = null;
    //         })
    // }

    deleteEmployerGroup(id) {
        this.employerGroupService.delete(id, 'id')
            .subscribe((response: any) => {
                if (response) {
                    let purchaserIndex = this.purchasers.findIndex(x => x.code == this.childObj.purchaserCode);
                    if (purchaserIndex > -1) {
                        let empGrpIndex = this.purchasers[purchaserIndex].employerGroups.findIndex(e => e.id == this.childObj.id)
                        if (empGrpIndex > -1) {
                            this.purchasers[purchaserIndex].employerGroups.splice(empGrpIndex, 1)
                            this.toastr.displaySuccess(DELETE_SUCCESS);
                        }
                    }
                }
            }, (error) => {
                console.log("Error deleting Employer group ", error);
                this.toastr.showError(error);
                this.deleteId = null;
            })
    }

    openValueBasedProgramModal(purchaser, vbp?) {
        if (this.utilityAccess.searchAccess('PURM', 'isEditable')) {
            if (purchaser) {
                this.selectedPurchaser = JSON.parse(JSON.stringify(purchaser))
                this.valueBasedProgramForm.controls.purchaserCode.setValue(purchaser.code)
                if (vbp) {
                    this.clientService.getById(vbp.vbp.clientCode)
                        .subscribe((res) => {
                            if (res) {
                                this.client_search_text.next(res.name)
                                this.clientSelected(res)
                            }
                        }, (error) => {
                            console.log("Error getting client by client code", error);

                        })
                    this.valueBasedProgramForm.controls.id.setValue(vbp.vbp.id);
                    this.valueBasedProgramForm.controls.purchaserCode.setValue(purchaser.code);
                    this.valueBasedProgramForm.controls.vbpCode.setValue(vbp.vbpCode);
                    this.valueBasedProgramForm.controls.effectiveDate.setValue(vbp.effectiveDate ? moment(vbp.effectiveDate).format('MM-DD-YYYY') : null);
                    this.valueBasedProgramForm.controls.terminationDate.setValue(vbp.terminationDate ? moment(vbp.terminationDate).format('MM-DD-YYYY') : null);
                    setTimeout(() => {
                        this.selectedVbp = this.vbpList.find(x => x.code == vbp.vbpCode)
                    }, (1500))
                }
                this.valueBasedProgramModal.show();
            }
        }
        else {
            this.toastr.displayWarning(ACCESS_DENIED);
        }
    }

    closeValueBasedProgramModal(action?) {
        this.valueBasedProgramForm.reset();
        this.client_search_text.next('');
        this.vbpList = [];
        this.submitted = false;
        this.selectedVbp = {}
        this.valueBasedProgramForm.controls.purchaserCode.setValue(this.selectedPurchaser.code)
        if (action == 'close' || !action)
            this.valueBasedProgramModal.hide();
    }

    vbpSelected(event) {
        this.selectedVbp = event
    }

    submitValueBasedProgram(action) {
        this.submitted = true;
        const effectiveDate = this.valueBasedProgramForm.value.effectiveDate ? moment(this.valueBasedProgramForm.value.effectiveDate) : '';
        if (effectiveDate && !effectiveDate.isValid())
            this.valueBasedProgramForm.controls['effectiveDate'].setErrors({ 'incorrect': true });
        const terminationDate = this.valueBasedProgramForm.value.terminationDate ? moment(this.valueBasedProgramForm.value.terminationDate) : '';
        if (terminationDate && !terminationDate.isValid())
            this.valueBasedProgramForm.controls['terminationDate'].setErrors({ 'incorrect': true });
        if (!effectiveDate && terminationDate)
            this.valueBasedProgramForm.controls['effectiveDate'].setErrors({ 'incorrect': true });
        if ((effectiveDate && effectiveDate.isValid()) && (terminationDate && terminationDate.isValid())) {
            const validDate = moment(this.valueBasedProgramForm.value.terminationDate).isAfter(this.valueBasedProgramForm.value.effectiveDate);
            if (!validDate) {
                this.valueBasedProgramForm.controls['terminationDate'].setErrors({ 'incorrect': true });
                this.valueBasedProgramForm.controls['effectiveDate'].setErrors({ 'incorrect': true });
            }
            else {
                this.valueBasedProgramForm.controls['terminationDate'].setErrors(null);
                this.valueBasedProgramForm.controls['effectiveDate'].setErrors(null);
            }
        }
        if (this.valueBasedProgramForm.valid) {
            this.setNullForEmpty(this.valueBasedProgramForm)
            if (this.valueBasedProgramForm.value.id) {
                const body = this.valueBasedProgramForm.getRawValue()
                this.purchaserService.updateVbpForPurchaser(body, body.purchaserCode)
                    .subscribe((res) => {
                        let index = this.purchasers.findIndex(x => x.id == this.selectedPurchaser.id);
                        if (index > -1) {
                            if (this.purchasers[index].valueBasedPrograms && this.purchasers[index].valueBasedPrograms.length > 0) {
                                let index2 = this.purchasers[index].valueBasedPrograms.findIndex(y => y.vbpCode == res.vbpCode)
                                if (index2 > -1) {
                                    this.purchasers[index].valueBasedPrograms[index2] = res;
                                    this.toastr.displaySuccess(UPDATE_SUCCESS);
                                }
                            }
                        }
                    }, (error) => {
                        console.log("Error updating vbp", error);
                        this.toastr.showError(error);
                    }, () => {
                        this.closeValueBasedProgramModal(action);
                    })
            }
            else {
                let body = this.valueBasedProgramForm.getRawValue()
                delete body.id
                this.purchaserService.saveVbpForPurchaser(body, body.purchaserCode)
                    .subscribe((res) => {
                        if (res) {
                            let index = this.purchasers.findIndex(p => p.code == this.selectedPurchaser.code)
                            if (index > -1) {
                                if (this.purchasers[index].valueBasedPrograms && this.purchasers[index].valueBasedPrograms.length > 0) {
                                    this.purchasers[index].valueBasedPrograms.unshift(res)
                                }
                                else {
                                    this.purchasers[index].valueBasedPrograms = []
                                    this.purchasers[index].valueBasedPrograms.push(res)
                                }
                                this.purchasers[index].clients.length > 0 ? (this.purchasers[index].clients.findIndex(c => c.code == this.selectedClient.code) > -1 ? '' : this.purchasers[index].clients.push({ code: this.selectedClient.code, name: this.selectedClient.name })) : this.purchasers[index].clients.push({ code: this.selectedClient.code, name: this.selectedClient.name })
                                this.purchasers[index].clientNames = this.purchasers[index].clients.length > 0 ? this.purchasers[index].clients.map(c => c.name) : []
                            }
                            this.toastr.displaySuccess(ADD_SUCCESS)
                        }
                    }, (error) => {
                        console.log("Error sasving vbp", error);
                        this.toastr.showError(error);
                    }, () => {
                        this.closeValueBasedProgramModal(action);
                    })
            }
        }
    }

    openFundingSourceModal(purchaser) {
        if (this.utilityAccess.searchAccess('PURM', 'isEditable')) {
            if (purchaser.fundingSource) this.fundingSourceForm.patchValue(purchaser.fundingSource)
            else this.fundingSourceForm.controls.purchaserCode.setValue(purchaser.code)
            this.fundingSourceModal.show();
        }
        else {
            this.toastr.displayWarning(ACCESS_DENIED);
        }
    }

    closeFundingSourceModal() {
        this.fundingSourceModal.hide();
        this.fundingSourceForm.reset();
        this.submitted = false;
    }

    submitFundingSource() {
        this.submitted = true;
        if (this.fundingSourceForm.valid) {
            this.setNullForEmpty(this.fundingSourceForm)
            this.submit_enabled = true;
            if (this.fundingSourceForm.value.id) {
                this.fundingSourceService.update(this.fundingSourceForm.value)
                    .pipe(finalize(() => { this.submit_enabled = false; }))
                    .subscribe(res => {
                        if (res) {
                            let purchaserIndex = this.purchasers.findIndex(x => x.code == res.purchaserCode)
                            if (purchaserIndex > -1) {
                                if (this.purchasers[purchaserIndex].fundingSource)
                                    this.purchasers[purchaserIndex].fundingSource = res
                            }
                            this.toastr.displaySuccess(UPDATE_SUCCESS);
                            this.closeFundingSourceModal();
                        }
                    }, (error) => {
                        console.log("Error updating Funding source", error);
                        this.toastr.showError(error);
                    });
            }
            else {
                let body = this.fundingSourceForm.value;
                delete body.id
                delete body.code
                this.fundingSourceService.create(body)
                    .pipe(finalize(() => { this.submit_enabled = false; }))
                    .subscribe(res => {
                        if (res) {
                            let purchaserIndex = this.purchasers.findIndex(x => x.code == res.purchaserCode)
                            if (purchaserIndex > -1) {
                                this.purchasers[purchaserIndex].fundingSource = res;
                            }
                            this.toastr.displaySuccess(ADD_SUCCESS);
                            this.closeFundingSourceModal();
                        }
                    }, (error) => {
                        console.log("Error saving Funding source", error);
                        this.toastr.showError(error);
                    });
            }
        }
    }

    openEmployerGroupModal(purchaser, employerGroup?) {
        if (this.utilityAccess.searchAccess('PURM', 'isEditable')) {
            if (employerGroup) this.employerGroupForm.patchValue(employerGroup)
            else this.employerGroupForm.controls.purchaserCode.setValue(purchaser.code)
            this.employerGroupModal.show();
        }
        else {
            this.toastr.displayWarning(ACCESS_DENIED);
        }
    }

    closeEmployerGroupModal() {
        this.employerGroupModal.hide();
        this.employerGroupForm.reset();
        this.submitted = false;
    }

    submitEmplpoyerGroup() {
        this.submitted = true;
        if (this.employerGroupForm.valid) {
            this.setNullForEmpty(this.employerGroupForm)
            this.submit_enabled = true;
            if (this.employerGroupForm.value.id) {
                this.employerGroupService.update(this.employerGroupForm.getRawValue())
                    .pipe(finalize(() => { this.submit_enabled = false; }))
                    .subscribe(res => {
                        if (res) {
                            let purchaserIndex = this.purchasers.findIndex(x => x.code == res.purchaserCode)
                            if (purchaserIndex > -1) {
                                if (this.purchasers[purchaserIndex].employerGroups && this.purchasers[purchaserIndex].employerGroups.length > 0) {
                                    let employerGrpIndex = this.purchasers[purchaserIndex].employerGroups.findIndex(f => f.code == res.code)
                                    if (employerGrpIndex > -1)
                                        employerGrpIndex = this.purchasers[purchaserIndex].employerGroups[employerGrpIndex] = res
                                }
                            }
                            this.toastr.displaySuccess(UPDATE_SUCCESS);
                            this.closeEmployerGroupModal();
                        }
                    }, (error) => {
                        console.log("Error updating Employer Group", error);
                        this.toastr.showError(error);
                    });
            }
            else {
                let body = this.employerGroupForm.getRawValue()
                delete body.id
                this.employerGroupService.create(body)
                    .pipe(finalize(() => { this.submit_enabled = false; }))
                    .subscribe(res => {
                        if (res) {
                            let purchaserIndex = this.purchasers.findIndex(x => x.code == res.purchaserCode)
                            if (purchaserIndex > -1) {
                                if (this.purchasers[purchaserIndex].employerGroups && this.purchasers[purchaserIndex].employerGroups.length > 0)
                                    this.purchasers[purchaserIndex].employerGroups.unshift(res)
                                else {
                                    this.purchasers[purchaserIndex].employerGroups = [];
                                    this.purchasers[purchaserIndex].employerGroups.push(res)
                                }
                            }
                            this.toastr.displaySuccess(ADD_SUCCESS);
                            this.closeEmployerGroupModal();
                        }
                    }, (error) => {
                        console.log("Error saving Employer Group", error);
                        this.toastr.showError(error);
                    });
            }
        }
    }

    showAssociatedEOC(vbp, eocs) {
        const data = { vbp, eocs }
        this.selectedValueBasedProgramEOCs = data
        this.associatedEOCModal.show()
    }

    closeAssociatedEOCModal() {
        this.associatedEOCModal.hide()
        this.selectedValueBasedProgramEOCs = {}
    }

    removeSearchKeyword() {
        this.query.keyword = ''
        this.searchKeywordChange('')
    }
}