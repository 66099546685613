<div class="d-flex justify-content-start align-items-start">

  <div class="icon-block d-none d-sm-block">
    <i class="icofont-tasks icofont-2x skin-text-primary-dark"></i>
  </div>
  <div class="d-flex justify-content-between w-100 align-items-center">
    <span class="pl-2 pr-4">
      <p class="font-weight-400">
        {{task.name}}
      </p>
      <!-- <a class="text-break" *ngIf="task.taskTodoLink">{{task.taskTodoLink}}</a> -->
    </span>
    <div *ngIf="task.status && task.status==='DUE'" class="chip chip-sm mb-0 ml-1 alert alert-info"
      [ngClass]="task.status?(task.status.replace(' ','').toLowerCase()):''">
      <span class="text-sm status-label"> {{task?.status?task.status.toLowerCase():''}}
        {{task.escalationDays}}
      </span>
    </div>
    
  </div>
</div>