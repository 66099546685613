import { Action } from '@ngrx/store';

export const READ_MESSAGE = '[MESSAGE] READ';
export const GET_MESSAGE = '[MESSAGE] GET';
export const UPDATE_USER_MESSAGE = '[MESSAGE] UPDATE';
export const GET_UNREAD_MESSAGE_USER_LIST = '[MESSAGE] UNREAD';
export const ADD_MESSAGE_NOTIFICATION = '[MESSAGE] NOTIFICATION';

export class ReadMessage implements Action {
  readonly type = READ_MESSAGE;
  constructor(public payload: {}) { }
}

export class GetMessage implements Action {
  readonly type = GET_MESSAGE;
  constructor(public payload: {}) { }
}

export class GetUnreadMessageUserList implements Action {
  readonly type = GET_UNREAD_MESSAGE_USER_LIST;
  constructor(public payload: {}) { }
}

export class UpdateUserMessage implements Action {
  readonly type = UPDATE_USER_MESSAGE;
  constructor(public payload: {}) { }
}

export class MessageNotification implements Action {
  readonly type = ADD_MESSAGE_NOTIFICATION;
  constructor(public payload: {}) { }
}

export type MessageActions = ReadMessage | GetMessage | GetUnreadMessageUserList | UpdateUserMessage | MessageNotification;
