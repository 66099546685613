<!-- Report Criteria form -->

<div *ngIf="!reportForm.value.reportType" class="px-3 my-4 d-flex align-items-center">
  <div class="col-12 p-0">
    <div class="d-flex justify-content-between align-items-end donotprint">
      <div class="form-group has-search input-group mt-2 mb-2 w-25 w-xs-100">
        <span class="form-control-feedback">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          class="form-control"
          id="search"
          placeholder="Search"
          autocomplete="off"
          [(ngModel)]="searchReportTypeKeyword"
          (keyup)="onReportKeywordSearch()" />
        <div class="input-group-append" *ngIf="searchReportTypeKeyword">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-light mb-0 p-0 rounded-circle"
          style="height: 40px; width: 40px"
          (click)="changeReportTypeView('list')"
          mdbTooltip="List"
          mdbWavesEffect>
          <mdb-icon fas icon="list-ul"></mdb-icon>
        </button>
        <button
          type="button"
          class="btn btn-light mb-0 p-0 rounded-circle"
          style="height: 40px; width: 40px"
          (click)="changeReportTypeView('grid')"
          mdbTooltip="Grid"
          mdbWavesEffect>
          <mdb-icon fas icon="th-large"></mdb-icon>
        </button>
      </div>
    </div>
    <!-- <div class="form-group mb-0 d-flex align-items-center">
      <label class="mr-2">Report Type *</label>
      <div [style.flex-grow]="1">
        <app-select
          [options]="reportTypeList | sort : 'label'"
          placeholder="Select report type"
          id="report-type-select"
          [visibleOptions]="3"
          [control]="reportForm.controls['reportType']"
          [disabled]="isLoading.reportType"
          (onSelected)="onReportTypeSelected($event)"
          required>
        </app-select>
      </div>
    </div> -->
  </div>
  <!-- <span
    *ngIf="isLoading.reportType"
    class="spinner-grow spinner-grow-sm"
    role="status"
    aria-hidden="true"></span> -->
</div>
<form [formGroup]="reportForm" *ngIf="!ownPageReportList.includes(reportForm.value.reportType)">
  <div class="grey lighten-5 mb-2 py-3 px-4" *ngIf="reportForm.value.reportType">
    <p class="mb-3"><b>Report Filter Criteria</b></p>
    <div class="row">
      <div class="col-4" *ngIf="additionalFilterFields.includes('dateType')">
        <div class="form-group">
          <label for="date-type-select">Date Type *</label>
          <mdb-select
            #select
            [disabled]="this.selectedReportType === REPORT_TYPE_CRS"
            [options]="dateTypes | sort : 'label'"
            placeholder="Select date type"
            id="date-type-select"
            [visibleOptions]="3"
            formControlName="dateType">
          </mdb-select>
        </div>
      </div>
      <div *ngIf="additionalFilterFields.includes('reportDate')" class="col-4">
        <div class="form-group">
          <label for="report-date-select">Report Date *</label>
          <mdb-select
            [options]="reportDateList | sort : 'label'"
            placeholder="Select report date"
            id="report-date-select"
            [visibleOptions]="3"
            formControlName="reportDate"
            (selected)="selectedReportDate()"
            required>
          </mdb-select>
        </div>
      </div>
      <div *ngIf="additionalFilterFields.includes('reportDate')" class="col-4">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="from-date-select">From</label>
              <mdb-date-picker
                [style.height]="'25px'"
                #toDatePicker
                [inline]="true"
                name="fromDate"
                id="from-date-select"
                [options]="startDateOptions"
                placeholder="Select date"
                formControlName="startDate"
                [disabled]="isPreSelectedFilter || !reportForm.value.reportDate"
                (dateChanged)="onDateChange($event, 'startDate')">
              </mdb-date-picker>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="to-date-select">To</label>
              <mdb-date-picker
                class="report-date-range"
                #toDatePicker
                [inline]="true"
                name="toDate"
                id="to-date-select"
                [options]="endDateOptions"
                placeholder="Select date"
                formControlName="endDate"
                [disabled]="isPreSelectedFilter || !reportForm.value.reportDate"
                (dateChanged)="onDateChange($event, 'endDate')">
              </mdb-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-4"
        *ngIf="
          additionalFilterFields.includes('network') &&
          user.roleCode !== roleCodes.PROVIDER_ADMINISTRATOR
        ">
        <div class="form-group">
          <label for="network">Network *</label>
          <span
            *ngIf="isLoading.networkType"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"></span>
          <app-select
            [options]="networks"
            [placeholder]="'Select network'"
            [id]="'network'"
            [visibleOptions]="3"
            [multiple]="true"
            [enableSelectAll]="true"
            [disabled]="isLoading.networkType"
            (onDeselected)="onNetworkDeslected()"
            [control]="reportForm.controls['network']">
          </app-select>
        </div>
      </div>
      <div class="col-4" *ngIf="additionalFilterFields.includes('speciality')">
        <div class="form-group position-relative">
          <label for="specialityName"
            >Speciality
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="isSearching.speciality"></span
          ></label>
          <input
            type="text"
            [ngModel]="specialitySearchText | async"
            (ngModelChange)="onSpecialitySearchTextChange($event)"
            required
            [ngModelOptions]="{standalone: true}"
            class="completer-input form-control mdb-autocomplete mb-0"
            [mdbAutoCompleter]="autoSpeciality"
            placeholder="Search by speciality"
            autocomplete="off"
            [ngClass]="{isDisabled: false}" />
          <mdb-auto-completer
            #autoSpeciality="mdbAutoCompleter"
            textNoResults="No speciality found"
            (selected)="specialitySelected($event)"
            [displayValue]="onDisplayValue">
            <mdb-option
              class="border-bottom"
              *ngFor="let option of specialityResult | async"
              [value]="option">
              <div class="d-flex flex-column">
                <strong>{{ option.displayName }}</strong>
                <p class="m-0">
                  <small>{{ option.vSpecialty }} ({{ option.code }})</small>
                </p>
              </div>
            </mdb-option>
          </mdb-auto-completer>
        </div>
      </div>
      <div
        class="col-4"
        *ngIf="
          additionalFilterFields.includes('provider') &&
          reportForm.value.reportType !== reportTypes.PROVIDER_EPISODE_REPORT &&
          ((reportForm.value.reportType !== reportTypes.PROVIDER_FACILITY_REPORT &&
            user.roleCode !== roleCodes.PROVIDER_ADMINISTRATOR) ||
            reportForm.value.reportType == reportTypes.PROVIDER_FACILITY_REPORT)
        ">
        <div class="form-group position-relative">
          <label for="providerName"
            >{{
              reportForm.value.reportType === reportTypes.PROVIDER_FACILITY_REPORT
                ? 'Facility'
                : 'Provider'
            }}
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="isSearching.providers || isSearching.facilities"></span
          ></label>
          <input
            #billingProviderInput
            type="text"
            [ngModel]="providerSearchText | async"
            (ngModelChange)="onProviderSearchTextChange($event)"
            [ngModelOptions]="{standalone: true}"
            class="completer-input form-control mdb-autocomplete mb-0"
            [mdbAutoCompleter]="autoProvider"
            [class.disabled]="
              reportForm.value.reportType === reportTypes.PROVIDER_FACILITY_REPORT &&
              (!reportForm.value.network || !reportForm.value.network.length)
            "
            [placeholder]="
              reportForm.value.reportType === reportTypes.PROVIDER_FACILITY_REPORT
                ? 'Search by facility name'
                : 'Search by provider name or NPI'
            "
            autocomplete="off"
            [ngClass]="{isDisabled: false}" />
          <mdb-auto-completer
            #autoProvider="mdbAutoCompleter"
            [textNoResults]="
              reportForm.value.reportType === reportTypes.CLAIMS_REPORT
                ? 'No Provider found'
                : 'No Facility found'
            "
            (selected)="providerSelected($event)"
            [displayValue]="onDisplayValue">
            <mdb-option
              class="border-bottom"
              *ngFor="let option of providersResult | async"
              [value]="option">
              <div class="d-flex flex-column">
                <strong>{{ option.displayName || option.businessName }}</strong>
                <span>
                  located in {{ option.city || option.address.city | titlecase
                  }}{{ option.state || option.address.state ? ',' : '' }}
                  {{ option.state || option.address.state }}</span
                >
                <small
                  >(N){{
                    option.identificationCode
                      ? option.identificationCode
                      : option.npi
                      ? option.npi
                      : 'N/A'
                  }}, (T){{ option.taxId ? option.taxId : 'N/A' }}</small
                >
              </div>
            </mdb-option>
          </mdb-auto-completer>
        </div>
      </div>
      <div
        class="col-4"
        *ngIf="
          additionalFilterFields.includes('provider') &&
          reportForm.value.reportType === reportTypes.PROVIDER_EPISODE_REPORT
        ">
        <label for="filterFacility"> Provider * </label>
        <span
          *ngIf="isLoading.facilityList"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"></span>
        <app-select
          [id]="'filterFacility'"
          [options]="facilityList"
          [placeholder]="'Select provider'"
          [disabled]="isLoading.facilityList || !facilityList.length"
          [visibleOptions]="3"
          [multiple]="true"
          [enableSelectAll]="true"
          (onSelected)="onClientOrFacilityChange($event.providerId)"
          (onDeselected)="onClientOrFacilityChange($event.providerId)"
          [control]="reportForm.controls.provider">
        </app-select>
      </div>
      <div
        class="col-4"
        *ngIf="
          user.roleCode !== roleCodes.CLIENT_ADMINISTRATOR &&
          user.roleCode !== roleCodes.REPORTING &&
          additionalFilterFields.includes('client')
        ">
        <div class="form-group">
          <label for="client-select">{{
            selectedReportType === reportTypes.CLIENT_EPISODE_REPORT ? 'Client *' : 'Client'
          }}</label>
          <app-select
            [options]="clientList | sort : 'label'"
            placeholder="Select client"
            id="client-select"
            [visibleOptions]="3"
            [multiple]="true"
            [enableSelectAll]="true"
            [allowClear]="
              selectedReportType === reportTypes.FUNDING_REPORT ||
              selectedReportType === reportTypes.EPISODE_FINANCIAL_RECONCILIATION_REPORT
            "
            (onSelected)="onClientOrFacilityChange($event.providerId)"
            (onDeselected)="onClientOrFacilityChange($event.providerId)"
            [control]="reportForm.controls.client">
          </app-select>
        </div>
      </div>
      <div class="col-4" *ngIf="additionalFilterFields.includes('status')">
        <div class="form-group">
          <label for="status">{{
            selectedReportType === reportTypes.CLAIMS_REPORT ? 'Processing Status' : 'Status'
          }}</label>
          <mdb-select
            [allowClear]="true"
            [options]="status"
            id="status"
            placeholder="{{
              selectedReportType === reportTypes.CLAIMS_REPORT
                ? 'Select processing status'
                : 'Select status'
            }}"
            [multiple]="true"
            [enableSelectAll]="false"
            formControlName="status">
          </mdb-select>
        </div>
      </div>
      <div class="col-4" *ngIf="additionalFilterFields.includes('procedureCode')">
        <div class="form-group">
          <label for="procedure-code-input">Procedure Code</label>
          <input
            class="form-control"
            type="text"
            placeholder="Enter procedure code"
            id="procedure-code-input"
            maxlength="5"
            [disabled]="user.roleCode === roleCodes.CLIENT_ADMINISTRATOR"
            formControlName="procedureCode" />
        </div>
      </div>
      <div class="col-4" *ngIf="additionalFilterFields.includes('episodeType')">
        <div class="form-group">
          <label for="episode-type-select">Episode Type</label>
          <span
            *ngIf="isLoading.episodeType"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"></span>
          <app-select
            [options]="uniqueEpisodeTypeList | sort : 'label'"
            placeholder="Select episode type"
            [allowClear]="
              reportForm.value.episodeType && reportForm.value.episodeType.length ? true : false
            "
            id="episode-type-select"
            [visibleOptions]="3"
            [multiple]="true"
            [enableSelectAll]="true"
            [disabled]="
              (selectedReportType === reportTypes.CLIENT_EPISODE_REPORT &&
                !reportForm.value.client) ||
              (selectedReportType === reportTypes.PROVIDER_EPISODE_REPORT &&
                !reportForm.value.provider) ||
              isLoading.episodeType ||
              !uniqueEpisodeTypeList.length
            "
            [control]="reportForm.controls.episodeType">
          </app-select>
        </div>
      </div>
      <div class="col-4" *ngIf="additionalFilterFields.includes('columnOptions')">
        <div class="form-group">
          <label for="column-options-select">Column Options</label>
          <mdb-select
            [options]="columnOptionList"
            placeholder="Select column options"
            [allowClear]="true"
            id="column-options-select"
            [visibleOptions]="3"
            [multiple]="true"
            [enableSelectAll]="true"
            formControlName="columnOptions">
          </mdb-select>
        </div>
      </div>
    </div>
  </div>
</form>
<div
  class="d-flex justify-content-end mt-3 mb-4 px-2"
  *ngIf="reportForm.value.reportType && !ownPageReportList.includes(reportForm.value.reportType)">
  <button class="btn btn-light bg-white border" mdbWavesEffect (click)="resetFormValue(true)">
    Clear
  </button>
  <button
    class="btn btn-secondary waves-light"
    mdbWavesEffect
    [disabled]="reportForm.invalid || isLoading.facilityList"
    (click)="onReportSearch()">
    <span
      *ngIf="isLoading.report"
      class="spinner-grow spinner-grow-sm"
      role="status"
      aria-hidden="true"></span>
    Generate Report
  </button>
</div>
<!--  Report Criteria form Ends-->
<div
  *ngIf="!reportForm.value.reportType && searchReportTypeKeyword && reportTypeList?.length === 0"
  class="d-flex justify-content-center align-items-center py-4 border no-conversation-container mx-3">
  <div class="text-center">
    <i class="icofont-ui-settings icofont-4x text-secondary"></i>
    <p class="mt-2">Reports not found</p>
  </div>
</div>
<div
  *ngIf="
    !reportForm.value.reportType && reportView === 'grid' && reportTypeList && reportTypeList.length
  ">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-4 mb-4" *ngFor="let report of reportTypeList">
        <div class="bg-grey-lighten-2 border rounded-lg p-4">
          <div class="row">
            <div class="d-flex justify-content-end align-items-center col-sm-2">
              <mdb-icon class="link fa-lg" fas [icon]="report.icon"></mdb-icon>
            </div>
            <div class="col-sm-10">
              <p class="m-0 p-0" style="font-weight: 500">{{ report.name }}</p>
              <p
                class="link m-0 p-0 pointer-cursor"
                style="font-size: 14px"
                (click)="onReportTypeSelected(report)">
                View Report
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="
    !reportForm.value.reportType && reportView === 'list' && reportTypeList && reportTypeList.length
  "
  class="mx-3">
  <table mdbTable>
    <thead>
      <tr>
        <th>Report Type</th>
      </tr>
    </thead>
    <tbody>
      <tr mdbTableCol *ngFor="let report of reportTypeList">
        <td class="pointer-cursor" (click)="onReportTypeSelected(report)">
          {{ report.name }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-loader [loader]="isLoading.reportType"></app-loader>
<div
  class="container-fluid mb-5 pb-5"
  *ngIf="reportForm.value.reportType && !ownPageReportList.includes(reportForm.value.reportType)">
  <app-loader [loader]="isLoading.report && !showClaimsReconciliationSummary"></app-loader>
  <app-funding-report
    *ngIf="showFundingReport"
    [reportData]="fundingReportData"></app-funding-report>
  <app-financial-reconciliation
    *ngIf="showFinancialReconciliation"
    [financialReconciliationData]="financialReconciliationData"></app-financial-reconciliation>

  <app-table
    *ngIf="showReport"
    [report]="report"
    [exportTable]="true"
    [module]="'report'"
    tableHeight="800px"></app-table>

  <app-claims-reconciliation-summary
    [showClaimsReconciliationSummary]="showClaimsReconciliationSummary"
    [queryParams]="params"
    (isFetching)="setFetching($event)"
    *ngIf="showClaimsReconciliationSummary"></app-claims-reconciliation-summary>
</div>

<div *ngIf="reportForm.value.reportType && ownPageReportList.includes(reportForm.value.reportType)">
  <app-value-management-dashboard
    [reportParentCode]="reportForm.get('reportCode').value"
    *ngIf="
      reportForm.value.reportType === reportTypes.VALUE_MANAGEMENT_DASHBOARD
    "></app-value-management-dashboard>
  <app-client-claims-dashboard
    [reportParentCode]="reportForm.get('reportCode').value"
    *ngIf="
      reportForm.value.reportType === reportTypes.CLIENTS_CLAIMS_DASHBOARD
    "></app-client-claims-dashboard>
  <app-question-review-dashboard
    [reportParentCode]="reportForm.get('reportCode').value"
    *ngIf="
      reportForm.value.reportType === reportTypes.QUESTION_REVIEW_DASHBOARD
    "></app-question-review-dashboard>
  <app-transaction-report
    [reportParentCode]="reportForm.get('reportCode').value"
    [report]="reportForm.value"
    *ngIf="reportForm.value.reportType === reportTypes.TRANSACTION_REPORT"></app-transaction-report>
  <app-projected-episode-profit
    *ngIf="
      reportForm.value.reportType === reportTypes.PROJECTED_EPISODE_PROFIT_REPORT
    "></app-projected-episode-profit>
  <app-payment-report
    *ngIf="reportForm.value.reportType === reportTypes.PAYMENT_REPORT"></app-payment-report>
  <app-ancillary-report
    *ngIf="reportForm.value.reportType === reportTypes.ANCILLARY_REPORT"></app-ancillary-report>
</div>
