import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'input[numbersCommaOnly]'
})
export class NumberCommaDirective {

    constructor(private _el: ElementRef) { }

    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = this._el.nativeElement.value;
        this._el.nativeElement.value = initalValue.replace(/[^\d\s+,-]+/g, '');
        if (initalValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

}