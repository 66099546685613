export const CODES_GUIDE = {
    cptCode: `
    <b>CPT Code Guide</b>
    <p>The value for CPT code should be as: Eg.</p>
    <p>45378 (Accepts the exact value)</p>
    <p>01402, 01214 (Accepts any one matched value)</p>
    <p>- (Accepts no value)</p>
    <p>-99282 (Accepts any value except provided)</p>
    `,
    diagnosisCode: `
    <b>Diagnosis Code Guide</b>
    <p>The value for diagnosis code should be as: Eg.</p>
    <p>Z12.11 (Accepts the exact value)</p>
    <p>R19.5, K62.1 (Accepts any one matched value)</p>
    <p>- (Accepts no value)</p>
    <p>-Z86.101 (Accepts any value except provided)</p>
    `,
    revenueCode: `
    <b>Revenue Code Guide</b>
    <p>The value for revenue code should be as: Eg.</p>
    <p>0360 (Accepts the exact value)</p>
    <p>0636, 0710 (Accepts any one matched value)</p>
    <p>- (Accepts no value)</p>
    <p>-0450 (Accepts any value except provided)</p>
    `,
    modifier: `
    <b>Modifier Guide</b>
    <p>The value for modifier should be as: Eg.</p>
    <p>33 (Accepts the exact value)</p>
    <p>22, AS (Accepts any one matched value)</p>
    <p>- (Accepts no value)</p>
    <p>-21 (Accepts any value except provided)</p>
    `,
    taxonomyCode: `
    <b>Taxanomy Code Guide</b>
    <p>The value for taxonomy code should be as: Eg.</p>
    <p>282N00000X (Accepts the exact value)</p>
    <p>261QA1903X, 282NC0060X (Accepts any one matched value)</p>
    <p>- (Accepts no value)</p>
    <p>-284300000X (Accepts any value except provided)</p>
    `,
    msdrg: `
    <b>MSDRG Guide</b>
    <p>The value for MSDRG should be as: Eg.</p>
    <p>469 (Accepts the exact value)</p>
    <p>470, 433 (Accepts any one matched value)</p>
    <p>- (Accepts no value)</p>
    <p>-795 (Accepts any value except provided)</p>
    `,
    rules: `
    <b>Rules Guide</b>
    <p>Select from custom rules list where || and && represents OR and AND condition respectively.</p>
    `
}

export const MEDICAL_CENTER = 'isMedicalCenter'