<div class="d-flex justify-content-between align-items-center mb-2">
  <h5>Funding Report</h5>
  <div>
    <button
      type="button"
      class="btn btn-sm btn-light border mr-0"
      (click)="onSendEmail()"
      [class.disabled]="!reportData?.reportContainer?.episodeFundings?.length">
      <i class="fa-fa fa-envelope fas"></i> Send Email
    </button>
    <button
      type="button"
      class="btn btn-sm btn-light border mr-0"
      (click)="exportToCsv()"
      [class.disabled]="!reportData?.reportContainer?.episodeFundings?.length">
      <i class="fas fa-download"></i> Download
    </button>
  </div>
</div>
<!--  Table Template 1-->
<table class="datatable mb-3" bordered="true" hover="true" mdbTable id="fundingReportPDF">
  <thead>
    <tr>
      <th [attr.colspan]="hasFundingRequest ? '5' : '4'">SUMMARY Funded Episodes</th>
      <th [attr.colspan]="summaryHeaders.length + 1">Period Totals</th>
    </tr>
    <tr>
      <th colspan="2" *ngIf="reportData.client; else emptyCell1">Client</th>
      <th [attr.colspan]="hasFundingRequest ? '3' : '2'" *ngIf="reportData.client; else emptyCell2">
        {{ reportData.client.label }}
      </th>
      <ng-template #emptyCell1>
        <th colspan="2"></th>
      </ng-template>
      <ng-template #emptyCell2>
        <th [attr.colspan]="hasFundingRequest ? '3' : '2'"></th>
      </ng-template>
      <th
        *ngFor="let header of summaryHeaders; let i = index"
        [attr.colspan]="header.value === 'episodes' ? '2' : ''"
        [class.text-right]="i > 0">
        {{ header.label }}
      </th>
    </tr>
    <tr>
      <th colspan="2">{{ reportData.date.startDate }} - {{ reportData.date.endDate }}</th>
      <th [attr.colspan]="hasFundingRequest ? '3' : '2'"></th>
      <th
        *ngFor="let datum of summaryData; let i = index"
        [attr.colspan]="datum === summaryData[0] ? '2' : ''"
        [class.text-right]="i > 0">
        {{ datum }}
      </th>
    </tr>
  </thead>

  <tbody>
    <tr>
      <td colspan="2"></td>
      <td [attr.colspan]="hasFundingRequest ? '3' : '2'"></td>
      <td
        *ngFor="let header of summaryHeaders"
        [attr.colspan]="header.value === 'episodes' ? '2' : ''"></td>
    </tr>
    <tr class="green lighten-5">
      <th *ngFor="let header of headers; let i = index">
        <div *ngIf="hasFundingRequest; else withoutFundingRequestHeader" [class.text-right]="i > 6">
          {{ header.label }}
        </div>
        <ng-template #withoutFundingRequestHeader>
          <div [class.text-right]="i > 5">
            {{ header.label }}
          </div>
        </ng-template>
      </th>
    </tr>
    <tr *ngIf="data.length === 0">
      <td [attr.colspan]="headers.length">There is no data available.</td>
    </tr>
    <tr *ngFor="let datumArray of data">
      <td *ngFor="let datum of datumArray; let i = index">
        <div *ngIf="hasFundingRequest; else withoutFundingRequestData" [class.text-right]="i > 6">
          {{ datum }}
        </div>
        <ng-template #withoutFundingRequestData>
          <div [class.text-right]="i > 5">
            {{ datum }}
          </div>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [idPDF]="['fundingReportPDF']"
  [moduleOptions]="moduleOptions"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->
