import { v4 as uuidv4 } from 'uuid';

export class RequestForFunding {
    id: number
    uuid: string
    bundleUuid: string
    cptCode: string
    revenueCode: string
    diagnosisCode: string
    msdrg: string
    modifier1: string
    modifier2: string
    modifier3: string
    modifier4: string
    name: string
    isActive: boolean
    sequence: number
    createdAt: Date
    updatedAt: Date
    fundReqId: string = uuidv4()
    validCptCode: boolean
    validRevenueCode: boolean
}