export class submitAnswer {
    type: string
    taskId: number
    taskName: string
    episodeId: number
    milestoneId: number
    topicId: number
    answers = []
}
// export class answers {
//     answer: string
//     answerOptionId: []
//     episodeId: number
//     milestoneId: number
//     questionId: number
//     questionName: string
//     questionType: string
//     taskId: number
//     isDependent: boolean
//     dependentOptionId: null
//     userId: number
// }