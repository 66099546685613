import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { APICONSTANTS, ENCRYPTION_KEY } from '../constants';
import { ChangePassword, ForgotPassword, ResetPassword, SetPassword, Login, AuthResponse } from '../models';
import { Storage } from '../utils';
import { ChatService } from './chat.service';
import { HttpClientService } from './http-client.service';
import * as CryptoJS from 'crypto-js';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: any;

  constructor(private http: HttpClientService, private router: Router, private chatService: ChatService, private _storage: Storage) { }

  public isAuthenticated(): boolean {
    this.user = this._storage.get('local', 'loggedInUser');
    if (this.user) {
      const isExpired = helper.isTokenExpired(this.user.token);
      if (isExpired) {
        this._storage.remove('local', 'loggedInUser');
        // this.router.navigate(['/login']);
        location.href = 'login';
        return false;
      } else {
        return true;
      }
    } else {
      // this.router.navigate(["/login"]);
      return false;
    }
  }

  decryptMessageToken(cipherText: string) {
    const bytes = CryptoJS.AES.decrypt(decodeURIComponent(cipherText), ENCRYPTION_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }

  isOtpAuthenticated(): boolean {
    const otpToken = this._storage.get('session', 'otpToken');
    return otpToken ? true : false;
  }

  verifyToken(): void {
    this.user = this._storage.get('local', 'loggedInUser');
    if (this.user && !this.user.parentRole) {
      this._storage.remove('local', 'loggedInUser');
      this.user = null;
    }
    if (this.user) {
      const isExpired = helper.isTokenExpired(this.user.token);
      if (isExpired) {
        this._storage.remove('local', 'loggedInUser');
      }
    }
  }

  login(credentials: Login) {
    return this.http.post(`/auth${APICONSTANTS.LOGIN}`, credentials).pipe(
      map((response: any) => {
        if (response.data && response.data.token && !response.data.isOtpLogin) {
          const decodedToken = helper.decodeToken(response.data.token);
          decodedToken.token = response.data.token;
          return response.data;
        } else {
          return response.data;
        }
      })
    );
  }

  ssoLogin(credential) {
    return this.http.post(`/auth/sso-login`, credential).pipe(
      map((response: any) => {
        if (response.data && response.data.token) {
          const decodedToken = helper.decodeToken(response.data.token);
          decodedToken.token = response.data.token;
          return response.data;
        } else {
          return response.data;
        }
      })
    );
  }

  forgotPassword(credentials: ForgotPassword) {
    return this.http
      .post(`/auth${APICONSTANTS.FORGOTPASSWORD}`, credentials)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  clearStorage() {
    this._storage.clear('session');
    // this._storage.clear('local');
    this._storage.remove('local', 'loggedInUser');
    this._storage.remove('local', 'chatLoggedInUser');
    this._storage.remove('local', 'lastActiveTime');
    this._storage.remove('local', 'tabsUsed');
    this._storage.remove('local', 'isInactiveModalShown');
    this.chatService.logout();
  }

  logout() {
    //regex to verify https in url
    const rgx =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const user = this._storage.get('local', 'loggedInUser');
    if (user && user.isSso && user.logoutUrl) {
      if (rgx.test(user.logoutUrl)) {
        location.href = user.logoutUrl
      } else {
        const url = 'http://' + user.logoutUrl;
        location.href = url
      }
    } else {
      location.href = 'login';
    }
    this.clearStorage();
  }

  verifyCode(model: { code: string, isSSO?: boolean }) {
    return this.http.post(`/auth${APICONSTANTS.VERIFYCODE}`, model).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  setPassword(credentials: SetPassword) {
    return this.http.post(`/auth${APICONSTANTS.SETPASSWORD}`, credentials).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  resetPassword(credentials: ResetPassword) {
    return this.http
      .post(`/auth${APICONSTANTS.RESETPASSWORD}`, credentials)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  changePassword(credentials: ChangePassword) {
    return this.http
      .put(`${APICONSTANTS.USER}${APICONSTANTS.CHANGEPASSWORD}`, credentials)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  switchRole() {
    return this.http.post(`/auth/switch-role`, null).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  getSidebarMenu() {
    return this.http.get(`${APICONSTANTS.MENU}?limit=0`).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  getSideBarMenu(userId: number) {
    return this.http.get(`${APICONSTANTS.AUTH}/init`, { id: userId }).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  verifyOtpLoginCode(body) {
    return this.http.post(`/auth${APICONSTANTS.VERIFY_OTP}`, body).pipe(
      map((response: AuthResponse) => {
        return response;
      })
    )
  }

  resendMFACode(body) {
    return this.http.post(`${APICONSTANTS.AUTH}${APICONSTANTS.RESEND_OTP}`, body).pipe(
      map((response: any) => {
        return response.data
      })
    );
  };
}
