<div id="total-claims" class="container-fluid">
  <div class="row" *ngIf="data[0].data.length; else noClaimsData">
    <div class="col-md-5 col-sm-12">
      <div class="grid-item w-100">
        <ng-container *ngIf="tableItems$ | async as tableItems">
          <app-table [report]="tableItems" [exportTable]="false" [module]="'report'"></app-table>
        </ng-container>
      </div>
    </div>
    <div class="col-md-7 col-sm-12">
      <div class="grid-item w-100">
        <div class="mb-2" id="total-claims-chart" style="height: 350px">
          <!-- *ngIf="!data || data.length; else noClaimsData" -->
          <canvas
            baseChart
            [datasets]="data"
            [labels]="labels"
            [options]="chartOptions"
            [chartType]="chartType"></canvas>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noClaimsData>
  <div>
    <p>{{ noDataMessage.value }}</p>
  </div>
</ng-template>
