<!-- patient ID Card -->
<div mdbModal #idCardModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true"
    [config]="{backdrop: true, ignoreBackdropClick: true, keyboard:false}">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ">
            <div class="modal-header donotprint">
                <h5 class="modal-title w-100">Member ID Card</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeIdModal()">
                    <i class="icofont-close-line"></i>
                </button>
            </div>
            <div class="modal-body">
                <app-card-template [memberId]="patientDetail">
                </app-card-template>
            </div>
        </div>
    </div>
</div>