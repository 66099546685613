import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss']
})
export class IncomingCallComponent implements OnInit {
  ringtone: any;
  @Input() call: any;
  @Output() acceptCall = new EventEmitter<any>();
  @Output() rejectCall = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  @Input() set CallRingtone(CallRingtone) {
    if (CallRingtone) {
      this.ringtone = CallRingtone;
      // setInterval(() => {
      //   this.callRingtone.play();
      // }, 2000);
    }
  }

  accept() {
    this.acceptCall.emit(this.call.sessionId);
  }
  reject() {
    this.rejectCall.emit(this.call.sessionId);
  }
}
