import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Store } from '@ngrx/store';
import { ClaimsService, EpisodeService, JournalService, PurchaserService, ToasterService, UserService } from '../../services';
import * as HeaderBreadCrumbActions from '../../action';
import { debounceTime, finalize, map, startWith, switchMap } from 'rxjs/operators';
import { ACCESS_DENIED, ADD_SUCCESS, DatePickerOption, INCOMPLETE_JOURNAL_ENTRY, JOURNAL_PER_PAGE_LIMIT, DEFAULT_JOURNAL_CURRENT_PAGE, JOURNAL_SCROLL_ABS, MaskConstant, NO_ASSOCIATE_DETAILS, NO_PATIENT_ASSOCIATED, DEFAULT_TOP_POSITION, UNMATCHED_DEBIT_CREDIT_AMOUNT, COUNT_LIMIT_BLOCK_API } from '../../constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccessType, HolistaUtils, ScrollTo } from '../../utils';
import * as moment from 'moment'
import { Observable, of, Subject } from 'rxjs';

@Component({
    selector: "app-journal",
    templateUrl: "./journal.component.html"
})

export class JournalComponent implements OnInit {
    @ViewChild('journalModal', { static: true }) public journalModal;
    @ViewChild('filterModal', { static: true }) public filterModal;
    @ViewChild('scrollableTable') scrollableTable: ElementRef;

    isLoading: boolean = false;
    searchKeyword = '';
    page = DEFAULT_JOURNAL_CURRENT_PAGE;
    dateMask: any[] = MaskConstant.DATE;
    patientList: any[] = [];
    limit = JOURNAL_PER_PAGE_LIMIT;
    isInfiniteScrolling = false;
    journal: any = {}
    totalJournalCount = 0;
    shouldCallApi = true;
    totalCreditAmount: any = 0
    totalDebitAmount: any = 0
    totalDebitEntryAmount: any = 0
    totalCreditEntryAmount: any = 0
    accountList: any = []
    accountForm: FormGroup
    journalForm: FormGroup
    filterForm: FormGroup
    journalEntry = []
    isSubmitEnabled = false
    isSubmitAccount = false
    totalJournalLength: any = 0
    submitted = false
    debitJournalEntry: any
    creditJournalEntry: any
    changeAmount: any
    valueAscOrder: any
    userSearchText = new Subject();
    userResults: Observable<any>;
    episodes: any = []
    associateEpisodes: any = []
    associateList: any = []
    isGetEpisode = false
    journals: any = []
    startDateOptions = DatePickerOption
    endDateOptions = DatePickerOption
    filterList = []
    tempPatientList = []
    episodeDropdownList = []
    associateDropdownList = []
    filteredData: any
    claimIdSearchText = new Subject();
    claimIdResults: Observable<any>;
    submitForAddNew: boolean = false;
    bundleComponents: any[] = [];
    fundingRequest: any = {};
    episodeDetail: any = {};
    insurer: any[] = [];
    backupJournal: any = []
    purchasers: any = []

    constructor(private store: Store<{ bread_crumbs: any }>,
        private journalService: JournalService,
        private _toastr: ToasterService,
        private fb: FormBuilder,
        private _scrollTo: ScrollTo,
        private userService: UserService,
        private claimsService: ClaimsService,
        private utilityAccess: AccessType,
        private episodeService: EpisodeService,
        private holistaUtils: HolistaUtils,
        private purchaserService: PurchaserService
    ) {
        this.setAccountForm()
        this.setjournalForm()
        this.sortObject()
        this.setFilterForm()
    }

    ngOnInit() {
        this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Journals', path: '/journal' }));
        this.userResults = this.userSearchText.pipe(
            startWith(this.userSearchText),
            debounceTime(300),
            switchMap((userSearchText: string) => this.searchUser(userSearchText))
        );
        this.claimIdResults = this.claimIdSearchText
            .pipe(
                startWith(this.claimIdSearchText),
                switchMap((claimIdSearchText: string) => this.searchClaims(claimIdSearchText))
            );

        this.getJournal();
        this.getAccounts()
        this.getPurchasers()
    }

    getPurchasers() {
        this.purchaserService.getAll({ limit: 0 }).subscribe(res => {
            if (res.count != 0) {
                res.rows.map(purchaser => {
                    purchaser.label = purchaser.name
                    purchaser.value = purchaser.code
                    return purchaser
                })
                this.purchasers = res.rows
            }
        },
            (error) => {
                console.log('Error', error)
            })
    }

    searchClaims(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.getClaims(1, 10, '', '', searchText)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items.rows
                    })
                );
        } else {
            return of([]);
        }
    }

    displayClaimId = (claim?): string | undefined => {
        return claim ? claim.patientAccountNumber + '(' + claim.type + ')' : undefined;
    };

    claim: any = {};
    async onClaimSelected(event) {
        this.claim = event.text;
        this.isSubmitAccount = false
        this.accountForm.reset()
        if (event.text.claimXRefHolista.patientId && event.text.claimXRefHolista.episodeId) {
            event.text.id = event.text.claimXRefHolista.patientId
            this.userSearchText.next(event.text.claimXRefHolista.patientName);
            this.journalForm.controls.claimId.setValue(event.text.id);
            this.journalForm.controls.claimType.setValue(event.text.type);
            this.journalForm.controls.claimIdentifier.setValue(event.text.patientAccountNumber);
            this.journalForm.controls.patientId.setValue(event.text.claimXRefHolista.patientId);
            this.journalForm.controls.episodeId.setValue(event.text.claimXRefHolista.episodeId);
            this.onPatientSelect(event);
            event.value = event.text.claimXRefHolista.episodeId;
            if (this.claim.type === 'INCOMING' && (this.claim.claimXRefHolista.processingStatus === 'QUEUE' || this.claim.claimXRefHolista.processingStatus === 'PAID'))
                this.getBundleComponents();
            else if (this.claim.type === 'OUTGOING' && (this.claim.claimXRefHolista.processingStatus === 'APPROVED' || this.claim.claimXRefHolista.processingStatus === 'RECEIVED')) {
                this.getFundingRequest();
                await this.getEpisodeDetail();
            }
            this.episodeSelect(event);
        } else {
            this._toastr.displayWarning(NO_PATIENT_ASSOCIATED);
        }
    }

    patientNameMapper(patient) {
        let firstName = ''
        let lastName = ''

        if (patient?.firstName) {
            firstName = patient.firstName;
        }

        if (patient?.lastName) {
            lastName = patient.lastName;
        }

        return `${firstName} ${lastName}`;
    }

    getEpisodesDropdown() {
        this.episodeService.getEpisodesDropdown()
            .then((res: any) => {
                this.episodeDropdownList = [];
                let journals = this.journals.map(j => {
                    return j?.episode?.id
                })
                let filteredEpisode = res.rows.filter(item => journals.includes(item.id));
                filteredEpisode.map(episode => {
                    const patient = episode.patient || episode.claimPatient
                    let data = { label: episode?.name + '-' + this.patientNameMapper(patient), value: episode.id };
                    this.episodeDropdownList = [...this.episodeDropdownList, data];
                })
            }, (error) => {
                console.log("Error", error);
            })
    }

    sortObject() {
        this.valueAscOrder = (a, b): any => {
            return b.toString().localeCompare(a - b);
        }
    }

    setjournalForm() {
        this.journalForm = this.fb.group({
            date: ['', Validators.required],
            patientId: [null, Validators.required],
            episodeId: [null, Validators.required],
            claimId: [null],
            narration: [''],
            claimIdentifier: [null],
            claimType: ''
        })
    }

    setFilterForm() {
        this.filterForm = this.fb.group({
            fromDate: [''],
            episodeId: [null],
            toDate: [null],
            claimIdentifier: [null],
            accountId: [''],
            filterEnabled: [false]
        })
    }

    setAccountForm() {
        this.accountForm = this.fb.group({
            account: ['', Validators.required],
            accountName: [''],
            debit: [null],
            credit: [null],
            associateId: ['', Validators.required],
            associateName: [''],
            amountChange: [false],
            referenceType: [''],
            isEdit: [false]
        })
    }

    groupByEntryNumber(array) {
        return array.reduce((r, a) => {
            if (r[a.entryNumber] = r[a.entryNumber] || [])
                r[a.entryNumber].push(a);
            return r
        }, Object.create(null));
    }

    onTableScroll(scrollEvent) {
        if (this.isInfiniteScrolling || !this.shouldCallApi) {
            window.removeEventListener('scroll', this.onTableScroll); // Remove the scroll event listener
            return
        }

        const tableViewHeight = scrollEvent.target.offsetHeight;
        const tableScrollHeight = scrollEvent.target.scrollHeight;
        const scrollLocation = scrollEvent.target.scrollTop;
        if (Math.abs(scrollLocation - (tableScrollHeight - tableViewHeight)) < JOURNAL_SCROLL_ABS) {
            this.page = this.page + 1;
            this.isInfiniteScrolling = true
            const query = { page: this.page, limit: this.limit }
            this.journalService.journalFilter(this.filterForm.value, query)
                .pipe(finalize(() => { this.isLoading = false; }))
                .subscribe(res => {
                    this.isInfiniteScrolling = false
                    this.totalCreditAmount = res.totalDebitCredit[0].totalCredit;
                    this.totalDebitAmount = res.totalDebitCredit[0].totalDebit;
                    this.totalJournalCount = res.count
                    const remainingJournalCount = this.totalJournalCount / (this.page * this.limit);
                    if (remainingJournalCount <= COUNT_LIMIT_BLOCK_API) {
                        this.shouldCallApi = false
                    }

                    this.journals = [...this.journals, ...res.rows];
                    this.journals.map(x => {
                        if (x?.episode) {
                            x.episode.userName = this.getUserName(x.episode);
                        }
                    })
                    this.journal = this.groupByEntryNumber(this.journals);
                    this.sortJournal('filter')
                })
        }
    }
    getJournal() {
        this.isLoading = true;
        const journalQuery = { maxRows: this.limit, currentPage: DEFAULT_JOURNAL_CURRENT_PAGE }
        this.journalService.getJournal(journalQuery)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe((response: any) => {
                this.journals = response.rows;
                this.totalJournalCount = response.count;
                const remainingJournalCount = this.totalJournalCount / (this.page * this.limit);
                if (remainingJournalCount <= COUNT_LIMIT_BLOCK_API) {
                    this.shouldCallApi = false
                }

                this.totalDebitAmount = response.totalDebitCredit[0].totalDebit;
                this.totalCreditAmount = response.totalDebitCredit[0].totalCredit;
                this.getEpisodesDropdown();
                if (response.rows.length !== 0) {
                    response.rows.map(x => {
                        this.isInfiniteScrolling = false
                        if (x.episode)
                            x.episode.userName = this.getUserName(x.episode);
                        return x
                    })
                    this.backupJournal = this.groupByEntryNumber(response.rows);
                    this.sortJournal()
                }
            }, (error) => {
                this.isLoading = false;
                this.isInfiniteScrolling = false
                console.log("Error getting Claims List", error);
            })
    }

    sortJournal(filter?) {
        const journal = filter ? this.journal : this.backupJournal
        Object.keys(journal).forEach(k => {
            journal[k].sort((a, b) => {
                if (a.debit === "" || a.debit === null) return 1;
                if (b.debit === "" || b.debit === null) return -1;
                if (a.debit === b.debit) return 0;
                return b.debit < b.debit ? -1 : 1;
            })
        })
        if (!filter) this.journal = JSON.parse(JSON.stringify(this.backupJournal))
    }


    createJournal() {
        if (this.utilityAccess.searchAccess('JM', 'isEditable')) {
            this.totalJournalLength = Object.keys(this.backupJournal).length
            const date = moment(new Date().toISOString().split('T')[0]).format('MM/DD/YYYY')
            this.journalForm.controls.date.setValue(date)
            this.journalModal.show();
        }
        else
            this._toastr.displayWarning(ACCESS_DENIED);
    }

    closeJournalModal(addNew?) {
        if (!addNew) {
            this.journalModal.hide();
        }
        this.accountForm.reset();
        this.journalForm.reset();
        const date = moment(new Date().toISOString().split('T')[0]).format('MM/DD/YYYY')
        this.journalForm.controls.date.setValue(date)
        this.journalEntry = []
        this.totalDebitEntryAmount = 0
        this.totalCreditEntryAmount = 0
        this.submitted = false
        this.isSubmitAccount = false
        this.userSearchText.next('');
        this.claimIdSearchText.next('');
        this.isGetEpisode = false
        this.episodes = []
        this.associateEpisodes = []
        this.bundleComponents = []
        this.fundingRequest = {}
        this.insurer = [];
        this.episodeDetail = {}
    }

    addRow(isEdit?) {
        const { account, associateId, debit, credit, referenceType } = this.accountForm.value
        this.isSubmitAccount = true
        if ((+debit < 0 && +credit < 0) || (debit === null && credit === null)) {
            this.accountForm.controls["debit"].setValidators([Validators.required])
            this.accountForm.controls["debit"].updateValueAndValidity();
            this.accountForm.controls["credit"].setValidators([Validators.required])
            this.accountForm.controls["credit"].updateValueAndValidity();
        }
        if (this.accountForm.valid) {
            this.accountForm.controls.isEdit.setValue(false)
            this.accountForm.controls.amountChange.setValue(false)
            this.isSubmitAccount = false
            let accountName: any
            let referenceName: any

            this.accountList.map(x => {
                if (x.value === account) {
                    accountName = x.label
                }
            })
            this.associateList.map(x => {
                if (x.value === associateId) {
                    referenceName = x.label
                }
            })
            const data = {
                account: accountName,
                accountNumber: account,
                debit: debit,
                credit: credit,
                referenceId: associateId,
                referenceName: referenceName,
                referenceType: referenceType
            }
            this.journalEntry.push(data);
            if (isEdit) {
                if (debit) {
                    this.totalDebitEntryAmount += +debit
                } else if (credit) {
                    this.totalCreditEntryAmount += +credit
                }
            }
            this.accountForm.reset()
        }
    }

    editEntry(i) {
        if (this.accountForm.value.debit) {
            this.totalDebitEntryAmount = +this.totalDebitEntryAmount
            this.totalDebitEntryAmount -= +this.accountForm.value.debit
        }
        else if (this.accountForm.value.credit) {
            this.totalCreditEntryAmount = +this.totalCreditEntryAmount
            this.totalCreditEntryAmount -= +this.accountForm.value.credit
        }
        const { accountNumber, account, debit, credit, referenceId, referenceName, referenceType } = this.journalEntry[i]
        if (this.accountForm.value.isEdit) {
            this.addRow('isEdit')
        }
        this.accountForm.reset();
        this.accountForm.controls.account.setValue(accountNumber);
        this.accountForm.controls.accountName.setValue(account);
        this.accountForm.controls.debit.setValue(debit);
        this.accountForm.controls.credit.setValue(credit);
        this.accountForm.controls.associateId.setValue(referenceId);
        this.accountForm.controls.associateName.setValue(referenceName);
        this.accountForm.controls.referenceType.setValue(referenceType);
        this.accountForm.controls.isEdit.setValue(true);
        if (this.claim) {
            const data = { label: referenceName, value: referenceId }
            this.associateList = [...this.associateList, data]
        }
        if (account === 'Cash')
            this.getCashAssociate(debit ? 'debit' : 'credit');
        else
            this.checkAssociateList();
        this.journalEntry.splice(i, 1);
    }

    removeEntry(i, form?) {
        if (form) {
            this.accountForm.controls.isEdit.setValue(false)
            if (this.accountForm.value.debit)
                this.totalDebitEntryAmount -= +this.accountForm.value.debit;
            else
                this.totalCreditEntryAmount -= +this.accountForm.value.credit;
            this.accountForm.reset()
        } else {
            if (this.journalEntry[i].debit)
                this.totalDebitEntryAmount -= +this.journalEntry[i].debit;
            else
                this.totalCreditEntryAmount -= +this.journalEntry[i].credit;
            this.journalEntry.splice(i, 1);
        }

    }

    getAccounts() {
        this.journalService.getAccounts().subscribe(response => {
            response.map(x => {
                const data = { label: x.name, value: x.number, associateType: x.associateType }
                this.accountList = [...this.accountList, data]
            })
        })
    }

    debitChange() {
        this.accountForm.controls['credit'].clearValidators();
        this.accountForm.controls['credit'].updateValueAndValidity();
    }

    creditChange() {
        this.accountForm.controls['debit'].clearValidators();
        this.accountForm.controls['debit'].updateValueAndValidity();
    }

    saveJournal(action) {
        const { patientId, episodeId, date, narration, claimId, claimIdentifier, claimType } = this.journalForm.value
        if (this.accountForm.valid) {
            this.addRow()
        }
        this.submitted = true
        const journalCredit = this.journalEntry.filter(credit => credit.credit || credit.credit == 0)
        const journalDebit = this.journalEntry.filter(debit => debit.debit || debit.debit == 0)
        if (journalCredit.length > 0 && journalDebit.length > 0) {
            if (this.journalForm.valid && this.journalEntry && this.journalEntry.length > 1 && (!this.accountForm.value.isEdit || (this.accountForm.value.isEdit && this.accountForm.valid))) {
                if (+this.totalDebitEntryAmount === +this.totalCreditEntryAmount) {
                    if (action == 'addNew') this.submitForAddNew = true;
                    else this.isSubmitEnabled = true
                    const data = {
                        entryNumber: this.totalJournalLength + 1,
                        date: date,
                        account: this.journalEntry,
                        patientId: patientId,
                        episodeId: episodeId,
                        narration: narration,
                        claimId: claimId,
                        claimIdentifier: claimIdentifier,
                        claimType: claimType,
                    }
                    this.journalService.saveJournal(data)
                        .pipe(finalize(() => {
                            this.isSubmitEnabled = false;
                            this.submitForAddNew = false;
                        }))
                        .subscribe(res => {
                            const sumsOfDebitCredit = res.reduce((firstValue, secondValue) => {
                                return { debit: Number(firstValue.debit) + Number(secondValue.debit), credit: Number(firstValue.credit) + Number(secondValue.credit) }
                            }, { debit: 0, credit: 0 })
                            this.totalCreditAmount = Number(this.totalCreditAmount) + sumsOfDebitCredit.credit
                            this.totalDebitAmount = Number(this.totalDebitAmount) + sumsOfDebitCredit.debit
                            res.map(x => {
                                this.journals.push(x)
                            });

                            this.journals.map(x => {
                                if (x.episode) {
                                    x.episode.userName = this.getUserName(x.episode);
                                }
                            })

                            this.getEpisodesDropdown()
                            this.backupJournal = this.groupByEntryNumber(this.journals);
                            this.sortJournal()
                            this._toastr.displaySuccess(ADD_SUCCESS);
                            if (action == 'close') {
                                this.closeJournalModal();
                            }
                            else {
                                this.closeJournalModal('addNew');
                                this.totalJournalLength = Object.keys(this.backupJournal).length
                            }
                        })
                } else {
                    this._toastr.displayWarning(UNMATCHED_DEBIT_CREDIT_AMOUNT);
                }
            }
        } else {
            this._toastr.displayWarning(INCOMPLETE_JOURNAL_ENTRY);
        }
    }

    amtChange(event, type) {
        if (this.journalEntry.length > 0) {
            if (type == 'debit')
                this.totalDebitEntryAmount = 0;
            else
                this.totalCreditEntryAmount = 0;
            this.journalEntry.forEach(element => {
                if (type == 'debit') {
                    this.totalDebitEntryAmount += +element.debit
                }
                else {
                    this.totalCreditEntryAmount += +element.credit
                }
            });
            if (type == 'debit')
                this.totalDebitEntryAmount += +event.target.value;
            else
                this.totalCreditEntryAmount += +event.target.value;
        }
        // adding journal row for the first time
        else {
            if (type == 'debit') {
                this.totalDebitEntryAmount = 0;
                this.totalDebitEntryAmount += +event.target.value;
            }
            else {
                this.totalCreditEntryAmount = 0;
                this.totalCreditEntryAmount += +event.target.value;
            }
        }
        if (this.accountForm.value.accountName === "Cash") {
            if (event.target.value) this.getCashAssociate(type)
            else this.associateList = []

        }
    }

    getCashAssociate(amountType) {
        if (this.accountForm.value.accountName === "Cash") {
            this.associateList = []
            const associate = amountType === 'debit' ? 'fundingReq' : 'bundleComponent'
            const label = 'name'
            const value = 'uuid'
            this.associateEpisodes[associate].map(y => {
                const data = { label: y[label], value: y[value] }
                this.associateList = [...this.associateList, data]
            })
        }
    }

    checkAssociateList() {
        this.associateList = []
        const { associateId, associateName } = this.accountForm.value
        let associateData
        if (this.claim && this.claim.id && associateId && associateName) {
            associateData = { label: associateName, value: associateId }
        }
        switch (this.accountForm.value.accountName.toLowerCase()) {
            case "professional claims payable":
                this.associateList = []
                if (associateData) this.associateList = [...this.associateList, associateData]
                // this.associateEpisodes.providers.map(y => {
                //     const data = { label: y.displayName, value: y.providerId }
                //     this.associateList = [...this.associateList, data]
                // })
                break;
            case "fees payable":
            case "accounts receivable":
                const insurer = { label: this.associateEpisodes.insurer.name, value: this.associateEpisodes.insurer.id }
                const owner = { label: this.associateEpisodes.owner.name, value: this.associateEpisodes.owner.id }
                if (this.accountForm.value.accountName.toLowerCase() === "fees payable") {
                    this.associateList = [...this.associateList, insurer, owner]
                } else {
                    this.associateList = [...this.associateList, insurer]
                }
                if (associateData) {
                    const associateDataFound = this.associateList.find(bundle => bundle.value == associateData.value)
                    if (!associateDataFound) this.associateList.push(JSON.parse(JSON.stringify(associateData)))
                } break;
            case "funded bundle":
                this.associateEpisodes.fundingReq.map(y => {
                    const data = { label: y.fundingRequestName, value: y.fundingRequestId }
                    this.associateList = [...this.associateList, data]
                })
                if (associateData) {
                    const associateDataFound = this.associateList.find(bundle => bundle.value == associateData.value)
                    if (!associateDataFound) this.associateList.push(JSON.parse(JSON.stringify(associateData)))
                }
                break;
            case "other revenue":
            case "adjustments":
            case "bonus payable":
                this.associateList = []
                break;
            case "professional":
            case "institutional":
            case "(holista) holista fees":
            case "partner fees":
                this.associateEpisodes.bundleComponent.map(component => {
                    const data = { label: component.name, value: component.uuid }
                    this.associateList = [...this.associateList, data]
                })
                if (associateData) {
                    const associateDataFound = this.associateList.find(bundle => bundle.value == associateData.value)
                    if (!associateDataFound) this.associateList.push(JSON.parse(JSON.stringify(associateData)))
                } break;
        }
        if (!this.associateList || this.associateList.length === 0) {
            this._toastr.displayWarning(NO_ASSOCIATE_DETAILS);
        }
    }

    scrollToTop() {
        this._scrollTo.ScrollTo('scrollToTop', 'center', 'smooth')
    }

    searchUser(searchText: string) {
        if (searchText && searchText.length > 2) {
            return this.userService.searchUsers({ keyword: searchText, limit: 0, ['roles-roleCode']: 'MP' }).pipe(
                debounceTime(250),
                map((items: any) => {
                    items = items.rows.map(user => {
                        const purchaser = this.purchasers.find(x => x.code == user.referenceCode)
                        user.purchaserName = purchaser ? purchaser.label : ''
                        return user
                    })
                    return items
                })
            );
        } else {
            return of([]);
        }
    }

    onDisplayValue = (user?): string | undefined => {
        return user ? (user.lastName ? user.firstName + ' ' + user.lastName : user.firstName) : undefined;
    };

    onPatientSelect(event, filter?) {
        this.isSubmitAccount = false
        this.accountForm.reset()
        this.isGetEpisode = true
        this.episodes = []
        this.associateEpisodes = []
        this.journalEntry = []
        this.associateList = []
        this.totalDebitEntryAmount = 0
        this.totalCreditEntryAmount = 0
        if (!this.journalForm.value.claimdId)
            this.journalForm.controls.patientId.setValue(event.text.id)
        this.episodeService.getEpisodeByUserId(event.text.id)
            .pipe(finalize(() => this.isGetEpisode = false))
            .subscribe(res => {
                res.map(x => {
                    const data = { label: x.name, value: x.id }
                    this.episodes = [...this.episodes, data]
                })
            },
                error => {
                    console.log("Error", error)
                    this.isGetEpisode = false
                })
    }

    getBundleComponents() {
        this.episodeService.getBundleComponent(this.claim.claimXRefHolista.episodeId)
            .subscribe((response: any) => {
                this.bundleComponents = response;
            }, (error) => {
                console.log("Error getting bundle component list", error);
            })
    }

    getFundingRequest() {
        this.episodeService.getFundingRequest(this.claim.claimXRefHolista.episodeId, this.claim.claimXRefHolista.fundingReqId)
            .subscribe((response: any) => {
                this.fundingRequest = response;
            }, (error) => {
                console.log("Error getting funding request list", error);
            })
    }

    async getEpisodeDetail() {
        await this.episodeService.getEpisodeDetail(this.claim.claimXRefHolista.episodeId)
            .then(async (response: any) => {
                if (response) {
                    this.episodeDetail = response;
                    await this.episodeService.getInsurer(response.companyId)
                        .then((res: any) => {
                            this.insurer = res;
                        }, (error) => {
                            console.log("Error getting insurer list", error);
                        })
                }
            }, (error) => {
                console.log("Error getting episode detail", error);
            })
    }

    episodeSelect(event) {
        this.isSubmitAccount = false
        this.accountForm.reset()
        this.associateList = []
        this.journalEntry = []
        this.associateEpisodes = []
        this.totalDebitEntryAmount = 0
        this.totalCreditEntryAmount = 0
        this.journalService.getAssociateEpisodeById(event.value).subscribe(res => {
            this.associateEpisodes = res;
            if (this.journalForm.value.claimId) {
                if (this.claim.type === 'INCOMING' && this.claim.claimXRefHolista.processingStatus === 'QUEUE') {
                    let filteredData = []
                    this.bundleComponents.map(x => {
                        this.claim.claimXRefHolista.claimComponents.map(y => {
                            if (y.bundleComponentUuid === x.serviceBundleComponentUuid) {
                                x.name = y.bundleComponentName
                                filteredData.push(x)
                            }
                        })
                    })
                    let accountTypeForDebit = this.accountList.find(x => x.label.toLowerCase() == 'professional');
                    if (accountTypeForDebit) {
                        filteredData.forEach(component => {
                            this.journalEntry.push({
                                account: accountTypeForDebit.label,
                                accountNumber: accountTypeForDebit.value,
                                debit: component.cost,
                                credit: null,
                                referenceId: component.serviceBundleComponentUuid,
                                referenceName: component.name,
                                referenceType: 'bundleComponent'
                            })
                            this.totalDebitEntryAmount += +component.cost;
                        });
                    }
                    let accountTypeForCredit = this.accountList.find(x => x.label.toLowerCase() == 'professional claims payable');
                    if (accountTypeForCredit) {
                        filteredData.forEach(component => {
                            this.journalEntry.push({
                                account: accountTypeForCredit.label,
                                accountNumber: accountTypeForCredit.value,
                                debit: null,
                                credit: component.cost,
                                referenceId: this.claim.transaction.billingProvider.id,
                                referenceName: this.claim.transaction.billingProvider.basicInfo.businessName,
                                referenceType: 'billingProvider'
                            })
                            this.totalCreditEntryAmount += +component.cost;
                        });
                    }
                    this.journalForm.controls.narration.setValue('Receive claim');
                }
                else if (this.claim.type === 'OUTGOING' && this.claim.claimXRefHolista.processingStatus === 'APPROVED') {
                    let accountTypeForCredit = this.accountList.find(x => x.label.toLowerCase() == 'funded bundle');
                    if (accountTypeForCredit) {
                        this.journalEntry.push({
                            account: accountTypeForCredit.label,
                            accountNumber: accountTypeForCredit.value,
                            debit: null,
                            credit: this.fundingRequest && this.fundingRequest.bundleCostFundingRequest.cost,
                            referenceId: this.fundingRequest && this.fundingRequest.fundingRequestId,
                            referenceName: this.fundingRequest && this.fundingRequest.fundingRequestName,
                            referenceType: accountTypeForCredit.associateType
                        })
                        this.totalCreditEntryAmount = this.fundingRequest.bundleCostFundingRequest.cost;
                    }
                    let accountTypeForDebit = this.accountList.find(x => x.label.toLowerCase() == 'accounts receivable');
                    if (accountTypeForDebit) {
                        this.journalEntry.push({
                            account: accountTypeForDebit.label,
                            accountNumber: accountTypeForDebit.value,
                            debit: this.fundingRequest && this.fundingRequest.bundleCostFundingRequest.cost,
                            credit: null,
                            referenceId: this.insurer.length && this.insurer[0].parentCompany.id,
                            referenceName: this.insurer.length && this.insurer[0].parentCompany.name,
                            referenceType: accountTypeForDebit.associateType
                        })
                        this.totalDebitEntryAmount = this.fundingRequest && this.fundingRequest.bundleCostFundingRequest.cost;
                    }
                    this.journalForm.controls.narration.setValue('Request funding');
                }
                else if (this.claim.type === 'OUTGOING' && this.claim.claimXRefHolista.processingStatus === 'RECEIVED') {
                    let accountTypeForCredit = this.accountList.find(x => x.label.toLowerCase() == 'accounts receivable');
                    if (accountTypeForCredit) {
                        this.journalEntry.push({
                            account: accountTypeForCredit.label,
                            accountNumber: accountTypeForCredit.value,
                            debit: null,
                            credit: this.fundingRequest && this.fundingRequest.bundleCostFundingRequest.cost,
                            referenceId: this.insurer.length && this.insurer[0].parentCompany.id,
                            referenceName: this.insurer.length && this.insurer[0].parentCompany.name,
                            referenceType: accountTypeForCredit.associateType
                        })
                        this.totalCreditEntryAmount = this.fundingRequest && this.fundingRequest.bundleCostFundingRequest.cost;
                    }
                    let accounTypeForDebit = this.accountList.find(x => x.label.toLowerCase() == 'cash');
                    if (accounTypeForDebit) {
                        this.journalEntry.push({
                            account: accounTypeForDebit.label,
                            accountNumber: accounTypeForDebit.value,
                            debit: this.fundingRequest && this.fundingRequest.bundleCostFundingRequest.cost,
                            credit: null,
                            referenceId: this.fundingRequest && this.fundingRequest.fundingRequestId,
                            referenceName: this.fundingRequest && this.fundingRequest.fundingRequestName,
                            referenceType: 'fundingReq'
                        })
                        this.totalDebitEntryAmount = this.fundingRequest && this.fundingRequest.bundleCostFundingRequest.cost;
                    }
                    this.journalForm.controls.narration.setValue('Fund received');
                }
                else if (this.claim.type === 'INCOMING' && this.claim.claimXRefHolista.processingStatus === 'PAID') {
                    let filteredData = []
                    this.bundleComponents.map(x => {
                        this.claim.claimXRefHolista.claimComponents.map(y => {
                            if (y.bundleComponentUuid === x.serviceBundleComponentUuid) {
                                x.name = y.bundleComponentName
                                filteredData.push(x)
                            }
                        })
                    })
                    let accountTypeForCredit = this.accountList.find(x => x.label.toLowerCase() == 'cash');
                    if (accountTypeForCredit) {
                        filteredData.forEach(component => {
                            this.journalEntry.push({
                                account: accountTypeForCredit.label,
                                accountNumber: accountTypeForCredit.value,
                                debit: null,
                                credit: component.cost,
                                referenceId: component.serviceBundleComponentUuid,
                                referenceName: component.name,
                                referenceType: 'bundleComponent'
                            })
                            this.totalCreditEntryAmount += +component.cost;
                        });
                    }
                    let accountTypeForDebit = this.accountList.find(x => x.label.toLowerCase() == 'professional claims payable');
                    if (accountTypeForDebit) {
                        filteredData.forEach(component => {
                            this.journalEntry.push({
                                account: accountTypeForDebit.label,
                                accountNumber: accountTypeForDebit.value,
                                debit: component.cost,
                                credit: null,
                                referenceId: this.claim.transaction.billingProvider.id,
                                referenceName: this.claim.transaction.billingProvider.basicInfo.businessName,
                                referenceType: 'billingProvider'
                            })
                            this.totalDebitEntryAmount += +component.cost;
                        });
                    }
                    this.journalForm.controls.narration.setValue('Pay claim');
                }
            }
        }, error => {
            console.log("Error", error)
        })
    }

    accountSelect(event) {
        this.accountForm.controls.associateId.setValue('')
        this.accountForm.controls.associateName.setValue('')
        this.accountForm.controls.accountName.setValue(event.label)
        this.accountForm.controls.referenceType.setValue(event.associateType)
        this.associateList = []
        if (event.label === 'Cash') {
            const amountType = this.accountForm.value.debit ? 'debit' : this.accountForm.value.credit ? 'credit' : null
            if (amountType) {
                this.getCashAssociate(amountType)
            }
        } else {
            this.checkAssociateList()
        }
    }

    closeFilterModal() {
        this.filterModal.hide()
        this.claimIdSearchText.next('')
        this.filterForm.reset()
    }

    openFilterModal() {
        this.filterModal.show()
    }

    tempClaimList: any = [];

    claimSelectedForFilter(event) {
        this.filterForm.controls.claimIdentifier.setValue(event.text && event.text.patientAccountNumber ? event.text.patientAccountNumber : null);
        this.tempClaimList.push({
            label: event.text && event.text.patientAccountNumber ? event.text.patientAccountNumber : '',
            value: event.text && event.text.patientAccountNumber ? event.text.patientAccountNumber : null
        });
    }

    submitFilter() {
        const fromDate = this.filterForm.value.fromDate ? moment(this.filterForm.value.fromDate) : '';
        this.filterForm.controls.filterEnabled.setValue(true)
        if (fromDate && !fromDate.isValid())
            this.filterForm.controls['fromDate'].setErrors({ 'incorrect': true });
        const toDate = this.filterForm.value.toDate ? moment(this.filterForm.value.toDate) : '';
        if (toDate && !toDate.isValid())
            this.filterForm.controls['toDate'].setErrors({ 'incorrect': true });
        if ((fromDate && fromDate.isValid()) && (toDate && toDate.isValid())) {
            const validDate = moment(this.filterForm.value.toDate).isAfter(this.filterForm.value.fromDate);
            if (!validDate)
                this.filterForm.controls['toDate'].setErrors({ 'incorrect': true });
        }
        if (this.filterForm.valid) {
            const filter_data = this.filterForm.value
            this.filteredData = filter_data
            this.filterList.length = 0;
            Object.keys(filter_data).forEach(key => {
                let value = filter_data[key]
                if (value && key == 'fromDate')
                    this.updateList('From Date', key, value);
                if (value && key == 'toDate')
                    this.updateList('To Date', key, value);
                if (value && key == 'accountId')
                    this.updateList('Account', key, value, this.accountList);
                if (value && key == 'episodeId')
                    this.updateList('Episode', key, value, this.episodeDropdownList);
                if (value && key == 'claimIdentifier')
                    this.updateList('Claim Identifier', key, value, this.tempClaimList);
            });
            this.isLoading = true;
            this.page = DEFAULT_JOURNAL_CURRENT_PAGE;
            this.limit = JOURNAL_PER_PAGE_LIMIT;
            const newScrollPosition = {
                top: DEFAULT_TOP_POSITION
            }
            this.scrollableTable.nativeElement.scrollTo(newScrollPosition)

            const query = { page: this.page, limit: this.limit }
            this.journalService.journalFilter(this.filterForm.value, query)
                .pipe(finalize(() => { this.isLoading = false; }))
                .subscribe(res => {
                    this.totalJournalCount = res.count;
                    const remainingJournalCount = this.totalJournalCount / (this.page * this.limit);
                    if (remainingJournalCount <= COUNT_LIMIT_BLOCK_API) {
                        this.shouldCallApi = false
                    }

                    this.totalCreditAmount = res.totalDebitCredit[0].totalCredit;
                    this.totalDebitAmount = res.totalDebitCredit[0].totalDebit;
                    this.journals = res.rows;

                    this.journals.map(x => {
                        if (x.episode) {
                            x.episode.userName = this.getUserName(x.episode);
                        }
                    })

                    this.journal = this.groupByEntryNumber(this.journals);
                    this.sortJournal('filter')
                })
            this.filterModal.hide();
        }
    }

    getUserName(episode) {
        return (episode.patient || episode.claimPatient) ? this.holistaUtils.getFullName(episode.patient || episode.claimPatient) :
            episode.subscriberPatient?.patientFirstName ? this.holistaUtils.getFullName(episode?.subscriberPatient, 'patient') :
                this.holistaUtils.getFullName(episode.subscriberPatient, 'subscriber');
    }

    updateList(field, formField, value, list?) {
        if ((formField == 'fromDate' || formField == 'toDate')) {
            let data = { field: field, formField: formField, value: value, label: value }
            this.filterList.push(data);
        }
        else if (formField == 'claimIdentifier') {
            let valueIndex = list.findIndex(x => x.value == value);
            if (valueIndex > -1) {
                let data = { field: field, formField: formField, value: value, label: list[valueIndex].label };
                this.filterList.push(data);
            }
        }
        else {
            Object.keys(value).forEach(y => {
                let values = value[y]
                let valueIndex = list.findIndex(x => x.value == values);
                if (valueIndex > -1) {
                    let index = this.filterList.findIndex(x => (x.field == field && x.value == values));
                    if (index == -1) {
                        let data = { field: field, formField: formField, value: values, label: list[valueIndex].label }
                        this.filterList.push(data);

                    }
                }
            })
        }
    }

    removeFilter(filter) {
        const { formField, value } = filter
        const filteredData = this.filterForm.get(formField).value;

        if (filteredData && filteredData.length && typeof filteredData === 'object') {
            const removeIndex = filteredData.findIndex(x => x === value);
            if (removeIndex > -1) {
                filteredData.splice(removeIndex, 1);
            }
        }
        if (formField === 'fromDate' || formField === 'toDate') {
            if (formField === 'fromDate') this.filterForm.controls.fromDate.setValue('');
            if (formField === 'toDate') this.filterForm.controls.toDate.setValue('');
        }
        if (formField === 'claimIdentifier') {
            this.claimIdSearchText.next('');
            this.filterForm.controls.claimIdentifier.setValue('');
        }
        this.filterForm.get(formField).reset();
        this.filterForm.controls[`${formField}`].setValue(filteredData && typeof filteredData === 'object' ? filteredData : '');
        this.submitFilter();
    }

    ResetFilter() {
        this.page = DEFAULT_JOURNAL_CURRENT_PAGE
        this.limit = JOURNAL_PER_PAGE_LIMIT;
        this.claimIdSearchText.next('')
        this.getJournal()
        this.filterList.length = 0;
        this.filterForm.reset();
        this.filteredData = null
        this.filterForm.controls.filterEnabled.setValue(false)
    }
}