import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { AccessType } from 'src/app/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-task-message',
  templateUrl: './task-message.component.html',
  styleUrls: ['./task-message.component.scss']
})
export class TaskMessageComponent implements OnInit {
  taskToBeDelete: any
  dependentTask: any
  messageId: any
  @Output() editTask = new EventEmitter<any>();
  @Input() public message;
  @Input() public optionId;
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;
  @Output() public btns = new EventEmitter<any>();
  @Output() public deletedTask = new EventEmitter<any>();


  @Input() set taskMessageId(taskMessageId) {
    if (taskMessageId) {
      this.messageId = taskMessageId
      this.getMessage(taskMessageId);
    }
  }
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.messageId) this.getMessage(this.messageId)
    }
  }
  constructor(
    private utilityAccess: AccessType,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const buttons = this.buttons.toArray();
    setTimeout(() => {
      this.btns.emit(buttons);
    });
  }

  editTaskHandler(message) {
    this.editTask.emit(message);
  }

  getMessage(id) {
    this.message = {};
    if (this.dependentTask && this.dependentTask.taskMessages) {
      this.dependentTask.taskMessages.filter(x => {
        if (x.id == id) {
          x.form = 'message';
          x.field = 'message';
          x.optionId = this.optionId;
          this.message = x;
        }
      });
    }
  }

  deleteTaskHandler(message) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      message['source'] = 'Pathway'
      message['type'] = 'Message'
      message['displayName'] = message.title
      this.taskToBeDelete = message
    }
    else this.toastr.warning("Access Denied", "Warning")
  }
  cancelDelete(e) {
    this.taskToBeDelete = e
  }
  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }

}
