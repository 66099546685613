import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import * as HeaderBreadCrumbActions from '../../action';
import {
  AdditionalPatientField,
  US_STATES,
  NameSuffixList,
  MaskConstant,
  RestrictSpace,
  TrimFields,
  Gender,
  PhoneFormat,
  ValidDate,
  ValidEmail,
  ACCESS_DENIED,
  RESTRICTED_PATIENT_EDIT,
  UPDATE_SUCCESS,
  ADD_SUCCESS,
  FIELD_UPDATE_SUCCESS,
  MEMBER_ALREADY_ACTIVATED,
  MEMBER_INVITED,
  SEND_RESET_PASSWORD_LINK_SUCCESS,
  SEND_INVITATION_SUCCESS,
  DELETE_SUCCESS,
  ROLE_CODES,
  CLIENT_ADMINS,
  NOT_AVAILABLE,
} from '../../constants';
import { CometChatUser, Member, Patient } from '../../models';
import {
  AuthService,
  ClientService,
  EpisodeOfCareService,
  MemberService,
  NetworkService,
  PurchaserService,
  ToasterService,
  UserService,
} from '../../services';
import {
  AccessType,
  FormatPhoneNumber,
  HolistaUtils,
  NameInitials,
  Sorting,
  Storage,
} from '../../utils';
import { PopoverDirective } from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
})
export class PatientComponent implements OnInit, OnDestroy {
  usStates = US_STATES;
  gender = Gender;
  showAdvanceSearch: boolean = true;
  benefitStatusList = [];
  clientList = [];
  backupClientList = [];
  networkList = [];
  purchaserCodeLoader = false;
  selectedNetwork = null;
  selectedClient = null;
  member: any;
  eligibleEocList: any;
  searchText: string = '';
  filteredEligibleEocList: any;
  nameSuffixList = NameSuffixList;
  members = [];
  loading = {
    clientList: false,
    networkList: false,
    searching: false,
    saving: false,
  };
  page = 1;
  limit = 10;
  searchKeyword: any;
  searchModelChanged: Subject<string> = new Subject<string>();
  totalCount: number = 0;
  patientForm: FormGroup;
  addEditNoteForm: FormGroup;
  advanceSearchForm: FormGroup;
  memberForm: FormGroup;
  phoneForm: FormGroup;
  emailForm: FormGroup;
  resendInvitationForm: FormGroup;
  resetPasswordForm: FormGroup;
  submit_enabled = false;
  submitted = false;
  memberInfo: any;
  phoneMask: any[] = MaskConstant.PHONE;
  dateMask: any[] = MaskConstant.DATE;
  purchaserList = [];
  backupPurchaserList = [];
  backupPurchaserListForSearch = [];
  clientCodeLoader = false;
  purchaserListForSearch: any[] = [];
  phone_Edit: boolean = false;
  email_Edit: boolean = false;
  selected_member: any;
  noteEditable = false;
  isSuperAdmin = false;
  isAdditionalField = false;
  relationShipList: any;
  cometChatUser = new CometChatUser();
  headElements = [
    'Name',
    'DOB',
    'Subscriber #',
    'Program ID',
    'Effective Period',
    'Contact',
    'Address',
    'Note',
    '',
    '',
  ];
  loggedUser: any;
  isChecked = false;
  validDob = false;
  validPatientDob = false;
  patientSearched: boolean = false;
  inviteBySMS = false;
  vbpList: any[] = [];
  clientCode: string = '';
  disablePhone: boolean = false;
  masterHeadElements = [
    'Member ID',
    'Subscriber ID',
    'Relationship',
    'Benefit Code',
    'Effective Date',
    'Termination Date',
  ];
  deleteId: any;
  message: any;
  isEditPhoneRadioSelected: boolean;
  selectedMember: any;

  @ViewChild('memberModal', { static: true }) public memberModal;
  @ViewChild('patientModal', { static: true }) public patientModal;
  @ViewChild('addPatientModal', { static: true }) public addPatientModal;
  @ViewChild('resendInvitaionModal', { static: true }) public resendInvitaionModal;
  @ViewChild('viewEocModal', { static: true }) public viewEocModal;
  @ViewChild('scrollWrapper', { static: false }) private scrollWrapper: ElementRef;
  @ViewChild('addEditNoteModal', { static: true }) public addEditNoteModal;

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private _toastr: ToasterService,
    private memberService: MemberService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private utilityAccess: AccessType,
    public router: Router,
    public holistaUtils: HolistaUtils,
    private userService: UserService,
    private purchaserService: PurchaserService,
    private utilitySorting: Sorting,
    private authService: AuthService,
    private _storage: Storage,
    private _nameInitials: NameInitials,
    private _episodeOfCareService: EpisodeOfCareService,
    private networkService: NetworkService,
    private _formatPhone: FormatPhoneNumber
  ) {
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
      this.searchKeyword = keyword;
      this.advanceSearchForm.controls.search.setValue(keyword);
      if (this.advanceSearchForm.controls.dob.invalid)
        this.advanceSearchForm.controls.dob.setValue('');
      this.searchMembers(1);
    });
    this.setMemberForm();
    this.setAdvanceSearchForm();
    this.setPhoneForm();
    this.setEmailForm();
    this.setResendInvitationForm();
    this.setResetPasswordForm();
    this.setPatientForm();
  }

  ngOnInit() {
    this.getBenefitStatusCode();
    this.loggedUser = this._storage.get('local', 'loggedInUser', 'user');
    CLIENT_ADMINS.includes(this.loggedUser.roleCode) ? this.getPurchaserByClientCode() : this.getPurchasers()
    this.isSuperAdmin = !CLIENT_ADMINS.includes(this.loggedUser.roleCode) ? true : false
    this.getRelationShips();
    if (!CLIENT_ADMINS.includes(this.loggedUser.roleCode)) {
      this.getClients('', false, true);
      this.getNetworks();
    }
    this.setAddEditNoteForm();
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(
      new HeaderBreadCrumbActions.AddBreadCrumb({
        name: 'Patients',
        path: '/patient',
      })
    );
    let searchedPatient = this._storage.get('session', 'searchPatient');
    if (searchedPatient) {
      this.searchKeyword = searchedPatient;
      this.change(searchedPatient);
    }
  }
  ngOnDestroy() {
    // sessionStorage.removeItem('searchPatient')
    this._storage.remove('session', 'searchPatient');
  }

  setPatientForm() {
    this.patientForm = this.formBuilder.group({
      nameSuffix: [null],
      firstName: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      middleName: ['', Validators.pattern(RestrictSpace)],
      lastName: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      email: ['', [Validators.required, Validators.pattern(ValidEmail)]],
      phone: ['', [Validators.required, Validators.pattern(PhoneFormat)]],
      planCode: [''],
      purchaserName: ['', Validators.required],
      purchaserCode: ['', Validators.required],
      purchaserNumber: [''],
      isMobile: false,
      gender: ['M', Validators.required],
      dob: ['', [Validators.required, Validators.pattern(ValidDate)]],
      ssn: ['', [Validators.pattern(/^(\d{4}|\d{9}?)?$/)]],
      residentialAddressLine1: [null, Validators.pattern(RestrictSpace)],
      residentialAddressLine2: [null, Validators.pattern(RestrictSpace)],
      residentialCity: [null, Validators.pattern(RestrictSpace)],
      residentialState: [null],
      residentialZip: [null, Validators.pattern(/^[0-9]{5}$/)],
      residentialZip4: [null, Validators.pattern(/^[0-9]{4}$/)],
      addressCode: [null],
      subscriberId: [null],
      memberId: [null],
      clientProgramCode: [null],
      eligibilitySource: [null],
      effectiveDate: [null],
      terminationDate: [null],
      employerGroupCode: [null],
      employerGroupNumber: [null],
      benefitStatusCode: [null],
      relationshipCode: [null],
      id: [null],
      uuid: [null],
      note: [''],
      isAdditional: [false],
    });
  }

  setAddEditNoteForm() {
    this.addEditNoteForm = this.formBuilder.group({
      note: [''],
    });
  }

  setAdvanceSearchForm() {
    this.advanceSearchForm = this.formBuilder.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      email: [''],
      dob: ['', Validators.pattern(ValidDate)],
      subscriberNumber: [''],
      purchaserCode: [''],
      search: [''],
      client: [''],
      network: [''],
      page: this.page,
      limit: this.limit,
    });
  }

  clearAdvanceSearch() {
    this.selectedNetwork = null;
    this.selectedClient = null;
    this.backupPurchaserList = [...this.purchaserList];
    this.backupPurchaserListForSearch = [...this.backupPurchaserListForSearch];
    this.searchKeyword = '';
    this.validPatientDob = false;
    this.clientList = [...this.backupClientList];
    Object.keys(this.advanceSearchForm.value).forEach(element => {
      // if (element !== 'search')
      this.advanceSearchForm.controls[element].setValue('');
    });
  }

  searchMembers(page?) {
    this.page = page ? page : this.page;
    if (this.advanceSearchForm.value.dob) {
      const currentDate = moment(new Date().toISOString().split('T')[0]).format('MM/DD/YYYY');
      if (new Date(this.advanceSearchForm.value.dob) <= new Date(currentDate))
        this.validPatientDob = false;
      else this.validPatientDob = true;
    } else this.validPatientDob = false;
    this.advanceSearchForm.controls.page.setValue(page ? page : this.page);
    this.advanceSearchForm.controls.limit.setValue(10);
    const props = ['search', 'firstName', 'middleName', 'lastName', 'email', 'subscriberNumber'];
    props.forEach(element => {
      this.advanceSearchForm.value[element] = this.advanceSearchForm.value[element].trim();
    });
    if (
      this.advanceSearchForm.valid &&
      (this.advanceSearchForm.value.search ||
        this.advanceSearchForm.value.firstName ||
        this.advanceSearchForm.value.middleName ||
        this.advanceSearchForm.value.lastName ||
        this.advanceSearchForm.value.subscriberNumber ||
        this.advanceSearchForm.value.email ||
        this.advanceSearchForm.value.dob ||
        this.advanceSearchForm.value.purchaserCode ||
        this.advanceSearchForm.value.client ||
        this.advanceSearchForm.value.network)
    ) {
      this.patientSearched = true;
      this.loading.searching = true;
      let encodedQuery = new URLSearchParams(this.advanceSearchForm.value).toString();
      this.memberService
        .searchMembers(encodedQuery)
        .pipe(
          finalize(() => {
            this.loading.searching = false;
          })
        )
        .subscribe(
          (res: any) => {
            this.phone_Edit = false;
            this.email_Edit = false;
            this.totalCount = res.count;
            if (res.rows.length != 0) {
              res.rows.map(x => {
                x['fullName'] = x.firstName + ' ' + x.lastName;
                if (x.phone) {
                  x.phone = this._formatPhone.phoneNumber(x.phone);
                }
                return x;
              });
              this.members = res.rows;
            } else {
              this.members = [];
            }
          },
          error => {
            console.log('Error', error);
            this._toastr.showError(error);
          }
        );
    } else {
      this.patientSearched = false;
      this.members = [];
    }
  }

  async removeSelection(name) {
    switch (name) {
      case 'purchaser':
        this.advanceSearchForm.controls.purchaserCode.setValue('');
        break;
      case 'network':
        this.advanceSearchForm.controls.network.setValue('');
        this.selectedNetwork = null;
        this.clientList = [...this.backupClientList];
        if (!this.selectedClient) {
          this.backupPurchaserList = [...this.purchaserList];
          this.backupPurchaserListForSearch = [...this.purchaserListForSearch];
        }
        break;
      case 'client':
        this.advanceSearchForm.controls.client.setValue('');
        this.selectedClient = null;
        if (this.selectedNetwork && this.advanceSearchForm.get('network').value !== '') {
          await this.getClients(this.selectedNetwork.value, true);
        } else {
          this.backupPurchaserList = [...this.purchaserList];
          this.backupPurchaserListForSearch = [...this.purchaserListForSearch];
        }
        break;
    }
  }

  async getMemberPlans(member) {
    if (!member.memberPlan) {
      await this.memberService.getMemberPlans({ memberUuid: member.uuid, limit: 0 }).then(
        (res: any) => {
          if (res) {
            res.rows.map(m => {
              if (m.benefitStatusCode) {
                const benefitStatus = this.benefitStatusList.find(
                  b => b.value == m.benefitStatusCode
                );
                if (benefitStatus) m.benefitStatusName = benefitStatus.label;
              }
            });
            member['memberPlan'] = res.rows;
          }
        },
        error => {
          console.log('Error getting member plans', error);
          this._toastr.showError(error);
        }
      );
    }
  }

  addPatient() {
    this.email_Edit = false;
    this.phone_Edit = false;
    this.selected_member = null;
    this.submitted = false;
    if (this.utilityAccess.searchAccess('MM', 'isEditable')) {
      this.patientForm.controls.isMobile.setValue(false);
      this.addPatientModal.show();
    } else this._toastr.displayWarning(ACCESS_DENIED);
  }

  purchaserSelected(event) {
    this.patientForm.controls.purchaserName.setValue(event.name);
    this.patientForm.controls.purchaserNumber.setValue(event.number);
    this.patientForm.controls.clientProgramCode.setValue('');
    this.getValueBasedPrograms(event);
  }

  getValueBasedPrograms(event, programId?) {
    this.purchaserService
      .getValueBasedProgramsByPurchaserCode(event.code, { defaultIncludes: true, limit: 0 })
      .then(
        (res: any) => {
          if (res && res.count > 0) {
            res.rows.map(v => {
              v.label = v.vbp.name;
              v.value = v.vbpCode;
              return v;
            });
            this.vbpList = res.rows;
            if (programId) {
              let index = this.vbpList.findIndex(v => v.vbpCode == programId);
              index > -1
                ? this.patientForm.controls.clientProgramCode.setValue(programId)
                : this.patientForm.controls.clientProgramCode.setValue('');
            }
          } else {
            this.patientForm.controls.clientProgramCode.setValue('');
            this.vbpList = [];
          }
        },
        error => {
          console.log('Error getting value based programs', error);
          this._toastr.showError(error);
        }
      );
  }

  vbpSelected(event) {
    if (this.isAdditionalField) {
      this.clientCode = event.vbp.clientCode;
    }
  }

  onAdditionalFieldClick(event: any) {
    if (event.checked) {
      this.submitted = false;
      this.validateAdditionalField();
      this.isAdditionalField = true;
    } else {
      this.resetAdditionalField();
      this.isAdditionalField = false;
    }
  }

  validateAdditionalField() {
    AdditionalPatientField.forEach(element => {
      if (
        element !== 'terminationDate' &&
        element !== 'planCode' &&
        element !== 'eligibilitySource' &&
        element !== 'employerGroupNumber'
      )
        this.patientForm.controls[element].setValidators(Validators.required);
      this.patientForm.controls[element].updateValueAndValidity();
    });
  }

  resetAdditionalField() {
    AdditionalPatientField.forEach(element => {
      this.patientForm.controls[element].clearValidators();
      this.patientForm.controls[element].updateValueAndValidity();
      this.patientForm.controls[element].setValue('');
    });
  }

  async editPatient(patient) {
    this.submitted = false;
    if (this.utilityAccess.searchAccess('MM', 'isEditable')) {
      if (patient.manuallyCreated) {
        if (!patient.memberPlan) {
          await this.getMemberPlans(patient);
        }
        patient.phone = this._formatPhone.phoneNumber(patient.phone);
        if (this.loggedUser.roleCode === 'PA' && patient.subscriberId) {
          this.isAdditionalField = true;
          this.isChecked = true;
          this.validateAdditionalField();
        }
        this.patientForm.patchValue(patient);
        if (
          (this.loggedUser.roleCode === 'CC' ||
            this.loggedUser.roleCode === 'ES' ||
            this.loggedUser.roleCode === 'PA') &&
          patient.memberPlan &&
          patient.memberPlan.length > 0
        ) {
          this.clientCode = patient.memberPlan[0].clientCode;
          this.setAdditionalFormValues(patient.memberPlan[0]);
          this.getValueBasedPrograms(
            { code: patient.purchaserCode },
            patient.memberPlan[0].programId
          );
          this.patientForm.controls.planCode.setValue(patient.memberPlan[0].benefitPlanCode);
        } else this.getValueBasedPrograms({ code: patient.purchaserCode });
        this.addPatientModal.show();
        this.formatDate(['dob']);
        this.formatDate(['effectiveDate']);
        this.formatDate(['terminationDate']);
        if (
          this.loggedUser.roleCode === 'CC' ||
          this.loggedUser.roleCode === 'ES' ||
          this.loggedUser.roleCode === 'PA'
        )
          this.formatDate(['effectiveDate', 'terminationDate']);
      } else this._toastr.displayWarning(RESTRICTED_PATIENT_EDIT);
    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  setAdditionalFormValues(additionalField) {
    AdditionalPatientField.forEach(element => {
      if (
        !(
          element === 'subscriberId' ||
          element === 'relationshipCode' ||
          element === 'memberId' ||
          element === 'employerGroupNumber'
        )
      )
        this.patientForm.controls[element].setValue(additionalField[element]);
    });
  }

  formatDate(dateObjects) {
    dateObjects.forEach(element => {
      let d = this.patientForm.value[element]
        ? moment(this.patientForm.value[element]).isValid()
        : null;
      d == null
        ? this.patientForm.controls[element].setValue(null)
        : this.patientForm.controls[element].setValue(
          moment(this.patientForm.value[element]).format('MM/DD/YYYY')
        );
    });
  }

  submitPatient() {
    const currentDate = moment(new Date().toISOString().split('T')[0]).format('MM/DD/YYYY');
    if (new Date(this.patientForm.value.dob) <= new Date(currentDate)) {
      this.validDob = true;
    } else {
      this.validDob = false;
    }
    const effectiveDate = this.patientForm.value.effectiveDate
      ? moment(this.patientForm.value.effectiveDate)
      : '';
    if (effectiveDate && !effectiveDate.isValid())
      this.patientForm.controls['effectiveDate'].setErrors({ incorrect: true });
    const terminationDate = this.patientForm.value.terminationDate
      ? moment(this.patientForm.value.terminationDate)
      : '';
    if (terminationDate && !terminationDate.isValid())
      this.patientForm.controls['terminationDate'].setErrors({ incorrect: true });
    if (effectiveDate && effectiveDate.isValid() && terminationDate && terminationDate.isValid()) {
      const validDate = moment(this.patientForm.value.terminationDate).isAfter(
        this.patientForm.value.effectiveDate
      );
      if (!validDate) {
        this.patientForm.controls['terminationDate'].setErrors({ incorrect: true });
        this.patientForm.controls['effectiveDate'].setErrors({ incorrect: true });
      } else {
        this.patientForm.controls['terminationDate'].setErrors(null);
        this.patientForm.controls['effectiveDate'].setErrors(null);
      }
    }
    if (!this.patientForm.value.zip && this.patientForm.value.zip4) {
      this.patientForm.controls.zip4.setValue('');
    }
    if (this.patientForm.valid && this.validDob) {
      this.loading.saving = true;
      TrimFields.Patient.forEach(element => {
        this.patientForm.value[element] = this.patientForm.value[element]
          ? this.patientForm.value[element].trim()
          : this.patientForm.value[element];
      });
      this.patientForm.value;
      if (this.patientForm.value.id) this.updatePatient();
      else this.savePatient();
    }
  }

  updatePatient() {
    let patient = Object.assign(new Patient(), this.patientForm.getRawValue());
    const validDobFormat = moment(this.patientForm.value.dob).format('YYYY-MM-DD');
    patient.phone = patient.phone.replace(/\D/g, '');
    patient.dob = validDobFormat;
    if (this.isAdditionalField)
      patient.memberPlan = [
        {
          programId: patient.clientProgramCode,
          clientCode: this.clientCode,
          benefitPlanCode: patient.planCode,
          eligibilitySource: patient.eligibilitySource,
          benefitStatusCode: patient.benefitStatusCode,
          effectiveDate: patient.effectiveDate,
          terminationDate: patient.terminationDate,
        },
      ];
    this.memberService
      .updatePatient(patient)
      .pipe(
        finalize(() => {
          this.loading.saving = false;
        })
      )
      .subscribe(
        res => {
          this.refreshPatient(res, 'updated');
        },
        error => {
          this._toastr.showError(error);
          console.log(error);
        }
      );
  }

  savePatient() {
    let patient = Object.assign(new Patient(), this.patientForm.getRawValue());
    const validDobFormat = moment(this.patientForm.value.dob).format('YYYY-MM-DD');
    patient.phone = patient.phone.replace(/\D/g, '');
    patient.dob = validDobFormat;
    delete patient.id;
    delete patient.uuid;
    if (this.isAdditionalField)
      patient.memberPlan = [
        {
          programId: patient.clientProgramCode,
          clientCode: this.clientCode,
          benefitPlanCode: patient.planCode,
          eligibilitySource: patient.eligibilitySource,
          benefitStatusCode: patient.benefitStatusCode,
          effectiveDate: patient.effectiveDate,
          terminationDate: patient.terminationDate,
        },
      ];
    this.memberService
      .savePatient(patient)
      .pipe(
        finalize(() => {
          this.loading.saving = false;
        })
      )
      .subscribe(
        res => {
          this.refreshPatient(res, 'saved');
        },
        error => {
          this._toastr.showError(error);
          console.log(error);
        }
      );
  }

  refreshPatient(response, status) {
    response['fullName'] = response.firstName + ' ' + response.lastName;
    if (response.phone) {
      response.phone = this._formatPhone.phoneNumber(response.phone);
    }
    if (response.memberPlan && response.memberPlan.length > 0) {
      response.memberPlan.map(m => {
        if (m.benefitStatusCode)
          m.benefitStatusName = this.benefitStatusList.find(
            b => b.value == m.benefitStatusCode
          ).label;
      });
    }
    if (this.members && this.members.length > 0) {
      if (status == 'saved' || status == 'updated') {
        const index = this.members.findIndex(member => member.id === response.id);
        if (index > -1) {
          this.members[index] = response;
        } else {
          this.members = [response, ...this.members];
          this.totalCount = this.members.length;
        }
      } else {
        const index = this.members.findIndex(
          x => x.uuid === (response.uuid ? response.uuid : response.memberUuid)
        );
        if (index > -1) {
          if (status == 'Phone') {
            response.phone = this._formatPhone.phoneNumber(response.phone);
            this.members[index].phone = response.phone;
            this.members[index].isMobile = response.isMobile;
          }
          if (status == 'Email') {
            this.members[index].email = response.email;
          }
          if (status == 'Note') {
            this.members[index].note = response.note;
            this.members[index]['noteEditable'] = false;
          }
        }
      }
    } else {
      if (status === 'saved' || status === 'updated') {
        this.members.push(response);
        this.totalCount = this.members.length;
      }
    }
    if (status === 'saved' || status === 'updated') {
      status === 'updated'
        ? this._toastr.displaySuccess(UPDATE_SUCCESS)
        : this._toastr.displaySuccess(ADD_SUCCESS);
      this.closeModal();
    } else {
      this._toastr.displaySuccess(UPDATE_SUCCESS);
      this.closeEdit();
    }
  }

  setMemberForm() {
    this.memberForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(ValidEmail)]],
      phone: ['', [Validators.required, Validators.pattern(PhoneFormat)]],
      isMobile: [false],
      inviteType: [true],
    });
  }

  setPhoneForm() {
    this.phoneForm = this.formBuilder.group({
      memberUuid: [null],
      phone: ['', [Validators.required, Validators.pattern(PhoneFormat)]],
      isMobile: [],
    });
  }

  setEmailForm() {
    this.emailForm = this.formBuilder.group({
      memberUuid: [null],
      email: ['', [Validators.required, Validators.pattern(ValidEmail)]],
    });
  }

  setResendInvitationForm() {
    this.resendInvitationForm = this.formBuilder.group({
      memberUuid: [null],
      inviteType: true,
    });
  }

  setResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      email: [null],
      phone: [null],
      inviteType: 'email',
      resetPassword: true,
    });
  }

  getPurchasers(code: any = '', key = '') {
    this.purchaserCodeLoader = true;
    this.backupPurchaserList = [];
    this.backupPurchaserListForSearch = [];
    let payload: any = { limit: 0, fields: 'code,name,id', defaultIncludes: false };
    if (code) payload = { ...payload, [key]: code };
    this.purchaserService.getAll(payload).subscribe(
      res => {
        this.purchaserCodeLoader = false;
        if (res && res.count > 0) {
          res.rows.map(p => {
            this.backupPurchaserList = [
              ...this.backupPurchaserList,
              { ...p, label: p.name, value: p.code },
            ];
            this.backupPurchaserListForSearch = [
              ...this.backupPurchaserListForSearch,
              { ...p, label: p.name, value: p.code },
            ];
            if (code == '') {
              this.purchaserList = [...this.purchaserList, { ...p, label: p.name, value: p.code }];
              this.purchaserListForSearch = [
                ...this.purchaserListForSearch,
                { label: p.name, value: p.code },
              ];
            }
          });
        }
      },
      e => {
        this.purchaserCodeLoader = false;
      }
    );
  }

  getClients(networkCode = '', callPurchasers = false, callOnce = false) {
    this.clientCodeLoader = true;
    let payload: any = { limit: 0, fields: 'code,name' };
    if (networkCode) payload = { ...payload, networkCode };
    this.loading.clientList = true;
    this.clientService
      .getClients(payload)
      .pipe(
        finalize(() => {
          this.loading.clientList = false;
        })
      )
      .subscribe(
        res => {
          this.clientCodeLoader = false;
          if (res.count !== 0) {
            res.rows.map(client => {
              client.label = client.name;
              client.value = client.code;
              return client;
            });
            if (callOnce) {
              this.backupClientList = this.holistaUtils.deepClone(res.rows);
            }
            this.clientList = this.holistaUtils.deepClone(res.rows);
          } else {
            this.clientList = [];
          }

          if (callPurchasers) {
            const allClientids = [...new Set(this.clientList.map((c: any) => c.value))];
            this.getPurchasers(allClientids, 'clientCode');
          }
        },
        error => {
          this.clientCodeLoader = false;
          console.log('Error getting Clients', error);
        }
      );
  }

  async onNetworkSelect(event) {
    this.selectedNetwork = event;
    this.selectedClient = null;
    this.advanceSearchForm.controls.purchaserCode.setValue('');
    if (!this.clientCode) this.purchaserCodeLoader = true;
    await this.getClients(event.value, true);

    // await this.getPurchasers(allClientids, 'cleintCode')
  }
  onClientSelect(event) {
    this.backupPurchaserList = [];
    this.advanceSearchForm.controls.purchaserCode.setValue('');
    this.backupPurchaserListForSearch = [];
    this.selectedClient = event;
    this.getPurchasers(event.value, 'clientCode');
  }

  getNetworks() {
    this.loading.networkList = true;
    this.networkService
      .getAll({ limit: 0, fields: 'code,name' })
      .pipe(
        finalize(() => {
          this.loading.networkList = false;
        })
      )
      .subscribe(
        res => {
          if (res.count !== 0) {
            res.rows.map(network => {
              network.label = network.name;
              network.value = network.code;
              return network;
            });
            this.networkList = JSON.parse(JSON.stringify(res.rows));
          } else {
            this.networkList = [];
          }
        },
        error => {
          console.log('Error getting network', error);
        }
      );
  }

  getPurchaserByClientCode() {
    this.clientService
      .getPurchasersByClientCode(this.loggedUser.referenceCode, { limit: 0 })
      .subscribe(res => {
        if (res && res.count > 0) {
          res.rows.map(p => {
            this.purchaserList = [...this.purchaserList, { ...p, label: p.name, value: p.code }];
            this.purchaserListForSearch = [
              ...this.purchaserListForSearch,
              { label: p.name, value: p.code },
            ];
          });
        }
      });
  }

  getRelationShips() {
    this.memberService.getRelationShips({ limit: 0 }).subscribe(res => {
      if (res.rows.length != 0) {
        res.rows.map(relation => {
          relation['label'] = relation.value;
        });
        this.relationShipList = this.utilitySorting.sortBy(res.rows, 'label');
      }
    });
  }

  getBenefitStatusCode() {
    this.memberService.getBenefitStatusCode({ limit: 0 }).subscribe(res => {
      if (res.rows.length != 0) {
        res.rows.map(x => {
          (x['label'] = x.value), (x['value'] = x.code);
        });
        this.benefitStatusList = this.utilitySorting.sortBy(res.rows, 'label');
      }
    });
  }

  inviteToPatient(member, sms?) {
    if (this.utilityAccess.searchAccess('MM', 'isEditable')) {
      if (member.isUser && member.isInvited) {
        this._toastr.displayWarning(MEMBER_ALREADY_ACTIVATED);
      } else {
        if (sms) {
          this.inviteBySMS = true;
          this.memberForm.controls['email'].clearValidators();
        } else {
          this.inviteBySMS = false;
          this.memberForm.controls['email'].setValidators([
            Validators.required,
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ]);
        }
        this.memberForm.controls['email'].updateValueAndValidity();

        if (member.phone) {
          this.memberForm.controls.phone.setValue(member.phone);
        }
        if (member.email) {
          this.memberForm.controls.email.setValue(member.email);
        }
        this.memberInfo = member;
        this.memberModal.show();
      }
    } else {
      this._toastr.displayWarning(ACCESS_DENIED);
    }
  }

  closeModal() {
    this.addPatientModal.hide();
    this.patientForm.reset();
    this.setPatientForm();
    this.memberForm.reset();
    this.setMemberForm();
    this.memberModal.hide();
    this.submit_enabled = false;
    this.submitted = false;
    this.isAdditionalField = false;
    this.isChecked = false;
    this.resetAdditionalField();
    this.vbpList = [];
  }

  submitMember() {
    if (this.memberForm.valid) {
      this.submit_enabled = true;
      this.saveMember();
    }
  }

  saveMember() {
    if (this.memberForm.valid) {
      let member = Object.assign(new Member(), this.memberForm.getRawValue());
      member.phone = member.phone.replace(/\D/g, '');
      member.firstName = this.memberInfo.firstName;
      member.lastName = this.memberInfo.lastName;
      member.memberUuid = this.memberInfo.uuid;
      // member.inviteType = this.inviteBySMS ? 'sms' : 'email'
      member.inviteType = this.inviteBySMS ? 'sms' : member.inviteType ? 'email' : 'phone';
      member.dob = this.memberInfo.dob;
      (member.subscriberNumber =
        this.memberInfo.subscriberId && this.memberInfo.memberId
          ? this.memberInfo.subscriberId + this.memberInfo.memberId
          : null),
        (member.referenceType = 'PURCHASER'),
        (member.referenceCode = this.memberInfo.purchaserCode);
      this.memberService
        .assignMemberToUser(member)
        .pipe(
          finalize(() => {
            this.submit_enabled = false;
          })
        )
        .subscribe(
          res => {
            this.refreshMember(res, 'invited', member.memberUuid);
          },
          error => {
            console.log(error);
            this._toastr.showError(error);
          }
        );
    }
  }
  refreshMember(response, action, memberUuid) {
    if (this.members && this.members.length > 0) {
      let index = this.members.findIndex(x => x.uuid === memberUuid);
      if (index > -1) {
        this.members[index].isUser = true;
        this.members[index].isInvited = true;
        this.members[index].email = response.email;
        this.members[index].phone = this._formatPhone.phoneNumber(response.phone);
        this.members[index].isRegistered = response.isRegistered;
        this.members[index].isMobile = response.isMobile;
      }
    }
    this._toastr.displaySuccess(MEMBER_INVITED, action);
    this.closeModal();
  }

  change(text) {
    this.searchModelChanged.next(text);
  }

  pageChanged(event) {
    this.page = event;
    this.advanceSearchForm.controls.page.setValue(this.page);
    this.searchMembers();
  }

  createPatientEpisode(patient) {
    this.router.navigate(['episodes']);
    this._storage.set('session', 'patientEpisode', patient);
    this._storage.remove('session', 'episodeFilterData');
  }

  closePatientModal() {
    this.phoneForm.reset();
    this.patientModal.hide();
    this.setPhoneForm();
    this.submitted = false;
  }

  submit(action, member?) {
    this.submitted = true;
    switch (action) {
      case 'Email':
        this.submitEmail(member);
        break;
      case 'Phone':
        this.submitPhone(member);
        break;
      case 'Note':
        this.submitNote(member);
        break;
      case 'Member':
        this.submitMember();
        break;
      case 'Patient':
        this.submitPatient();
        break;
    }
  }

  submitEmail(member) {
    if (this.emailForm.valid) {
      this.loading.saving = true;
      let email = Object.assign(this.emailForm.value);
      email.uuid = this.emailForm.value.uuid;
      if (!member?.isUser) {
        this.memberService.updatePatient(email).subscribe(
          res => {
            this.refreshPatient(this.mapMemberUpdateResponse(res), 'Email');
            this.loading.saving = false;
          },
          error => {
            this.loading.saving = false;
            this._toastr.showError(error);
          }
        );
      } else {
        this.userService.changeEmail(email).subscribe(
          res => {
            this.refreshPatient(res.data, 'Email');
            this.loading.saving = false;
          },
          error => {
            this.loading.saving = false;
            this._toastr.showError(error);
          }
        );
      }
    }
  }

  submitPhone(member?) {
    if (this.phoneForm.valid) {
      this.loading.saving = true;
      let phone = Object.assign(this.phoneForm.value);
      phone.phone = phone.phone.replace(/\D/g, '');
      phone.uuid = this.phoneForm.value.uuid;
      if (!member?.isUser) {
        this.memberService.updatePatient(phone).subscribe(
          res => {
            this.refreshPatient(this.mapMemberUpdateResponse(res), 'Phone');
            this.loading.saving = false;
          },
          error => {
            this.loading.saving = false;
            this._toastr.showError(error);
          }
        );
      } else {
        this.userService.changePhone(phone).subscribe(
          res => {
            this.refreshPatient(res.data, 'Phone');
            this.loading.saving = false;
          },
          error => {
            this.loading.saving = false;
            this._toastr.showError(error);
          }
        );
      }
    }
  }

  isResetPasswordAvailable(member) {
    if (member?.isSSO && member?.isSSOCreated) return true;
    if (member?.isUser && member?.isRegistered && !member?.isSSO) return true;

    return false;
  }

  mapMemberUpdateResponse(response) {
    return {
      memberUuid: response.uuid,
      id: response.id,
      email: response.email,
      phone: response.phone,
      isMobile: response.isMobile,
    };
  }

  submitNote(member) {
    let note = {
      uuid: member.uuid,
      note: member.note ? member.note.trim() : member.note,
    };
    this.submitted = true;
    this.userService.changeMemberNote(note).subscribe(
      res => {
        this.refreshPatient(res, 'Note');
      },
      error => {
        console.log(error);
        this._toastr.showError(error);
      }
    );
  }

  editEmail(member) {
    this.phone_Edit = false;
    this.email_Edit = true;
    this.selected_member = member.uuid;
    member.memberUuid = member.uuid;
    this.emailForm.patchValue(member);
    if (member) {
      let index = this.members.findIndex(x => x.uuid == member.uuid);
      if (index > -1) {
        this.members[index]['noteEditable'] = false;
      }
    }
  }

  editPhone(member) {
    this.email_Edit = false;
    this.phone_Edit = true;
    this.selected_member = member.uuid;
    member.isMobile = member.isMobile;
    member.phone = this._formatPhone.phoneNumber(member.phone);
    member.memberUuid = member.uuid;
    this.phoneForm.patchValue(member);
    if (member) {
      let index = this.members.findIndex(x => x.uuid == member.uuid);
      if (index > -1) {
        this.members[index]['noteEditable'] = false;
      }
    }
  }

  closeEdit(member?) {
    this.selected_member = null;
    this.emailForm.reset();
    this.setEmailForm();
    this.phoneForm.reset();
    this.setPhoneForm();
    this.patientForm.reset();
    this.setPatientForm();
    this.submitted = false;
    if (member) {
      let index = this.members.findIndex(x => x.uuid == member.uuid);
      if (index > -1) {
        this.members[index]['noteEditable'] = false;
      }
      member.note = member.initialNote;
    }
  }

  openResendInviation(member) {
    if (this.utilityAccess.searchAccess('MM', 'isEditable')) {
      if (member) {
        this.resendInvitationForm.controls.memberUuid.setValue(member.uuid);
        this.disablePhone = member.isMobile ? false : true;
        this.resendInvitaionModal.show();
      }
    } else this._toastr.displayWarning(ACCESS_DENIED);
  }

  openResetPassword(member) {
    if (this.utilityAccess.searchAccess('MM', 'isEditable')) {
      this.resetPasswordForm.controls.email.setValue(member.email);
      this.resetPasswordForm.controls.phone.setValue(member.phone.replace(/\D/g, ''));
      this.authService.forgotPassword(this.resetPasswordForm.value).subscribe(
        (res: any) => {
          this._toastr.displaySuccess(SEND_RESET_PASSWORD_LINK_SUCCESS);
        },
        error => {
          console.log('Error to send reset password link', error);
          this._toastr.showError(error);
        },
        () => {
          this.resetPasswordForm.reset();
          this.setResetPasswordForm();
        }
      );
    } else this._toastr.displayWarning(ACCESS_DENIED);
  }

  submitResend() {
    this.submit_enabled = true;
    let body = JSON.parse(JSON.stringify(this.resendInvitationForm.getRawValue()));
    body.inviteType = body.inviteType ? 'email' : 'phone';
    this.userService
      .reInvite(body)
      .pipe(
        finalize(() => {
          this.submit_enabled = false;
        })
      )
      .subscribe(
        res => {
          this._toastr.displaySuccess(SEND_INVITATION_SUCCESS);
        },
        error => {
          console.log('Error re-inviting', error);
          this._toastr.showError(error);
        },
        () => {
          this.closeResendModal();
        }
      );
  }

  closeResendModal() {
    this.resendInvitationForm.reset();
    this.setResendInvitationForm();
    this.resendInvitaionModal.hide();
  }

  editNote(member) {
    if (this.utilityAccess.searchAccess('MM', 'isEditable')) {
      if (member.isUser) {
        let index = this.members.findIndex(x => x.uuid == member.uuid);
        if (index > -1) {
          this.members[index]['noteEditable'] = true;
          this.members[index]['initialNote'] = member.note ? member.note.trim() : member.note;
        }
        this.phone_Edit = false;
        this.email_Edit = false;
      }
    } else this._toastr.displayWarning(ACCESS_DENIED);
  }

  handleChange(event, formName) {
    if (event.target.checked) {
      if (this[formName].value.inviteType == false)
        this[formName].controls.inviteType.setValue(true);
    }
  }

  onEditPhoneRadioSelected() {
    this.isEditPhoneRadioSelected = true;
  }

  onFocusIn() {
    this.isEditPhoneRadioSelected = false;
  }

  onFocusOut(type) {
    setTimeout(() => {
      if (type == 'phone') {
        !this.loading.saving && !this.isEditPhoneRadioSelected && (this.phone_Edit = false);
      } else this.email_Edit = false;
    }, 200);
  }

  removeSearchKeyword() {
    this.searchKeyword = '';
    this.change('');
  }

  deletePatient(member) {
    if (this.utilityAccess.searchAccess('MM', 'isEditable')) {
      this.deleteId = member.uuid;
      this.message = `Are you sure you want to delete <strong>${member.firstName} ${member.lastName}</strong>? This is irreversible.
      If you're not sure, you can click <strong>Cancel</strong>.`;
    } else this._toastr.displayWarning(ACCESS_DENIED);
  }

  confirmDelete(id) {
    if (id) {
      this.memberService.deleteMember(id, 'uuid').subscribe(
        (response: any) => {
          if (response.message) {
            this._toastr.displayWarning(response.message);
            this.deleteId = null;
          } else {
            this._toastr.displaySuccess(DELETE_SUCCESS);
            this.page = 1;
            this.searchMembers();
          }
        },
        error => {
          this.deleteId = null;
          console.log('Error deleting Patient ', error);
          this._toastr.showError(error);
        }
      );
    } else this.deleteId = null;
  }

  /**
   * searches for eligible eoc through keyword
   * @param data
   */
  searchByKeyword(data: string, member) {
    this.searchText = data;
    if (member?.memberPlan && member?.memberPlan?.length) {
      this.search();
    } else {
      this.filteredEligibleEocList = [];
    }
  }

  onViewEoc(member: any) {
    this.member = member;
    this.member.loading = true;
    this.member.nameInitials = this._nameInitials.getNameInitials(member.fullName);
    const data = {
      memberUuid: member.uuid,
      purchaserCode: member.purchaserCode,
      networkCode: this.loggedUser.referenceCode,
      referenceType: this.loggedUser.referenceType,
    };
    if (member?.memberPlan?.length) {
      this._episodeOfCareService.getEocOfMember(data).subscribe(
        (res: any) => {
          this.eligibleEocList = res;
          this.search();
          this.viewEocModal.show();
          this.member.loading = false;
        },
        error => {
          console.log('Error getting eoc list', error);
          this._toastr.displayError(error.error.message);
          this.member.loading = false;
        }
      );
    } else {
      this.member.loading = false;
      this.viewEocModal.show();
      this.filteredEligibleEocList = [];
    }
  }

  search() {
    this.filteredEligibleEocList =
      this.searchText === ''
        ? this.eligibleEocList
        : this.eligibleEocList.filter(element => {
          return (
            element.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
            element.cptCodes
              .map((code: string) => code.toLowerCase())
              .includes(this.searchText.toLowerCase())
          );
        });
  }

  closeEOCModal() {
    this.viewEocModal.hide();
    this.scrollWrapper.nativeElement.scrollTop = 0;
    this.searchText = '';
    this.filteredEligibleEocList = this.eligibleEocList;
  }

  closeNoteModal() {
    this.addEditNoteModal.hide();
    this.selectedMember = null;
  }

  addEditNote(member, isAdd) {
    this.selectedMember = member;
    isAdd
      ? this.addEditNoteForm.reset()
      : this.addEditNoteForm.controls['note'].setValue(member.note.trim());
    this.addEditNoteModal.show();
  }

  submitAddEditNote() {
    const uuid = this.selectedMember.uuid;
    const params = {
      note: this.addEditNoteForm.value.note.trim(),
      uuid,
    };
    this.submitted = true;
    this.userService
      .changeMemberNote(params)
      .pipe(
        finalize(() => {
          this.submitted = false;
          this.selectedMember = null;
        })
      )
      .subscribe(
        res => {
          this.addEditNoteModal.hide();
          this.selectedMember.note
            ? this._toastr.displaySuccess(UPDATE_SUCCESS)
            : this._toastr.displaySuccess(ADD_SUCCESS);
          this.members = this.members.map(member => {
            if (member.uuid === uuid) {
              return {
                ...member,
                note: res.note,
              };
            }
            return member;
          });
        },
        error => {
          console.log(error);
          this._toastr.showError(error);
        }
      );
  }

  getEmployerGroup(member: any) {
    if (member.manuallyCreated) {
      return member?.employerGroupNumber || NOT_AVAILABLE
    }

    return member?.employerGroupName || NOT_AVAILABLE
  }
}

export class MemberPlan {
  programId: string;
  benefitPlanCode: string;
  eligibilitySource: string;
  benefitStatusCode: string;
  effectiveDate: Date;
  terminationDate: Date;
}
