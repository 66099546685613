<section class="container-fluid">
  <div class="loader">
    <app-loader [loader]="loading.edi"></app-loader>
  </div>
  <div *ngIf="ediFile">
    <div class="row">
      <div class="col-10 mx-auto">
        <div class="mt-3 mb-3 d-flex justify-content-between align-items-end donotprint">
          <span>
            <label>Choose Option</label>
            <mdb-select
              [disabled]="saving"
              [(ngModel)]="defaultSelected"
              [options]="options"
              (ngModelChange)="valueChanged($event); pop.hide()"></mdb-select>
          </span>
          <span>
            <button
              *ngIf="defaultSelected !== 1"
              type="button"
              class="btn btn-light mb-0 p-0 rounded-circle"
              style="height: 40px; width: 40px"
              (click)="onSendEmail()"
              mdbTooltip="Send Email"
              mdbWavesEffect>
              <i class="fas fa-envelope"></i>
            </button>
            <button
              type="button"
              class="btn btn-light mb-0 p-0 rounded-circle"
              style="height: 40px; width: 40px"
              (click)="captureScreen()"
              mdbTooltip="Print"
              mdbWavesEffect>
              <i class="fas fa-print"></i>
            </button>
            <span placement="top" mdbTooltip="Download" style="display: inline-block">
              <button
                type="button"
                class="btn btn-light mb-0 p-0 rounded-circle"
                style="height: 40px; width: 40px"
                mdbWavesEffect
                id="downloadAlert"
                [mdbPopover]="downloadAlert"
                placement="bottom"
                #pop="bs-mdbPopover">
                <i class="fas fa-download" *ngIf="!loading.pdf"></i>
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loading.pdf"></span>
              </button>
            </span>
            <button
              type="button"
              class="btn btn-secondary"
              (click)="approve()"
              mdbWavesEffect
              *ngIf="
                isClaimApproved == 'false' &&
                fundingFormat &&
                ((defaultSelected === 1 && fundingFormat.toLowerCase().includes('837i')) ||
                  (defaultSelected === 2 && fundingFormat.toLowerCase().includes('invoice')) ||
                  (defaultSelected === 3 && fundingFormat.toLowerCase().includes('ub04')))
              ">
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="saving"></span>
              <i class="fas fa-check" *ngIf="!saving"></i> Submit
            </button>
            <ng-template #downloadAlert>
              <div class="d-flex justify-content-between align-items-center">
                <p>Choose the file type to download.</p>
                <a class="ml-4 mb-3" aria-label="Close" (click)="pop.hide()">
                  <i class="fas fa-times"></i>
                </a>
              </div>
              <div class="text-center">
                <p class="mb-0">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    [disabled]="defaultSelected == 2 || defaultSelected == 3"
                    (click)="download('text'); pop.hide()">
                    .txt
                  </button>
                  <button
                    type="button"
                    (click)="download('pdf'); pop.hide()"
                    class="btn btn-secondary btn-sm">
                    .pdf
                  </button>
                </p>
              </div>
            </ng-template>
          </span>
        </div>
        <div>
          <div
            *ngIf="defaultSelected == 1"
            class="border mx-auto h-auto p-4 grey lighten-5"
            [innerHTML]="ediFile"></div>
        </div>
        <app-loader [loader]="loading.invoice"></app-loader>
        <div
          id="invoice-wrapper"
          class="border mx-auto h-auto"
          *ngIf="defaultSelected == 2 && !loading.invoice">
          <div class="d-flex align-items-center bg-primary-dark p-4" style="height: 130px">
            <div class="media justify-content-between w-100">
              <img class="img-fluid logo" src="../../../assets/img/png/holista-logo-white.png" />
              <h3 class="text-light">Invoice</h3>
            </div>
          </div>
          <div class="p-4">
            <h6><strong>Services Detail</strong></h6>
            <div class="d-flex justify-content-between mt-3">
              <table class="table-xs w-50" mdbTable small="true" borderless="true">
                <tr>
                  <td>Claim ID #:</td>
                  <td>
                    <strong>{{ invoicePdf.claimId }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Patient Name:</td>
                  <td>
                    <strong>{{ invoicePdf.patientName | titlecase }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Subscriber/ Member:</td>
                  <td>
                    <strong>{{ invoicePdf.subscriberMember }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>DOB:</td>
                  <td>
                    <strong>{{
                      invoicePdf.dob ? (invoicePdf.dob | date : 'MM/dd/yyyy') : '-'
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Payor ID:</td>
                  <td>
                    <strong>{{ invoicePdf.payerId }}</strong>
                  </td>
                </tr>
              </table>
              <table class="table-xs w-50" mdbTable small="true" borderless="true">
                <tr>
                  <td>Facility:</td>
                  <td>
                    <strong>{{ invoicePdf.facilityName | titlecase }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Service Location:</td>
                  <td>
                    <strong>
                      {{
                        invoicePdf.facilityLocation.addressLine1
                          ? invoicePdf.facilityLocation.addressLine1 +
                            ' ' +
                            invoicePdf.facilityLocation.addressLine2
                          : invoicePdf.facilityLocation.addressLine2
                      }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Plan:</td>
                  <td>
                    <strong>{{ invoicePdf.plan }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Bundle Name:</td>
                  <td>
                    <strong>{{ invoicePdf.bundleName }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Tax ID:</td>
                  <td>
                    <strong>{{ invoicePdf.taxId }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>NPI:</td>
                  <td>
                    <strong>{{ invoicePdf.npi }}</strong>
                  </td>
                </tr>
              </table>
            </div>
            <table class="mt-3" mdbTable small="true">
              <tbody
                *ngIf="invoicePdf && invoicePdf.serviceLines.length > 0"
                style="background-color: white">
                <tr>
                  <th class="font-weight-bold">#</th>
                  <th class="font-weight-bold">DOS</th>
                  <th class="font-weight-bold">Rev <br />Code</th>
                  <!-- <th class="font-weight-bold">Procedure Date</th> -->
                  <th class="font-weight-bold" style="width: 250px">
                    Procedure/<br />
                    Mod
                  </th>
                  <th class="font-weight-bold">DRX</th>
                  <th class="font-weight-bold">Procedure <br />Description</th>
                  <th class="font-weight-bold">Billed <br />Qty</th>
                  <th class="text-right font-weight-bold">Allowed<br />Amt</th>
                </tr>
                <tr *ngFor="let data of invoicePdf.serviceLines; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ invoicePdf.dateOfService ? invoicePdf.dateOfService : '-' }}</td>
                  <td>{{ data.code ? data.code : '-' }}</td>
                  <td>
                    {{ generateProcedureCodeWithModifier(data) }}
                  </td>
                  <td>
                    {{ data.drx ? data.drx : '-' }}
                  </td>
                  <td>
                    {{ data.description ? data.description : '-' }}
                  </td>
                  <td>{{ data.billedQuantity }}</td>
                  <td class="text-right pr-0">{{ data.allowedAmount | currency }}</td>
                </tr>
                <tr class="grey lighten-2 w-100">
                  <td colspan="6"></td>
                  <td class="font-weight-bold">Total</td>
                  <td class="text-right font-weight-bold pr-0">
                    {{ totalAllowedAmount | currency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <footer class="d-flex justify-content-between p-3 align-items-end grey lighten-2">
            <div>
              <strong>Holista</strong>
              <ul class="fa-ul fa-inline mb-0 mt-2">
                <li class="text-muted">
                  <span class="fa-li"> <i class="fas fa-map-marker-alt"></i></span>
                  {{ mailingAddress }} <br />
                  {{ address }}
                </li>
                <li class="text-muted">
                  <span class="fa-li">
                    <mdb-icon fas icon="mobile-alt"></mdb-icon>
                  </span>
                  855-844-0619
                </li>
              </ul>
            </div>
            <p class="mb-0 text-muted">© {{ currentYear }} Holista. All rights reserved.</p>
          </footer>
        </div>
        <div *ngIf="defaultSelected == 3 && !loading.invoice">
          <app-ub04-form
            [claimId]="claimId"
            [approveUB04Flag]="approveUB04"
            [downloadUB04FormOrInvoicePDFFlag]="downloadUB04FormOrInvoicePDF"
            (sendApproveFlagEvent)="receiveApproveFlagResetEvent($event)"
            (sendDownloadPdfFlagEvent)="receiveEventOfDownloadedPDF($event)"
            (getInvoiceCoverSheet)="getInvoiceCoverSheet($event)"></app-ub04-form>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Confirmation Modal -->
<div
  mdbModal
  #approveConfirmationModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <!--Body-->
      <div class="modal-body">
        <p class="mb-0 text-center">
          {{ confirmationMessage }}
        </p>
        <div class="p-0 mt-4 text-center">
          <button
            class="btn btn-secondary waves-light"
            mdbWavesEffect
            (click)="triggerAction('yes', defaultSelected)">
            Yes
          </button>
          <button class="btn btn-light waves-light" mdbWavesEffect (click)="triggerAction('no')">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [idPDF]="
    this.defaultSelected === 1
      ? null
      : this.defaultSelected === 2
      ? ['invoice-wrapper' + '-id-' + patientAccountNumber]
      : showInvoiceCoverSheet
      ? [
          'invoice-cover-sheet' + '-id-' + patientAccountNumber,
          'ub04-wrapper' + '-id-' + patientAccountNumber
        ]
      : ['ub04-wrapper' + '-id-' + patientAccountNumber]
  "
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->
