import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Color, PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts';
import { ClaimsDashboardService, ToasterService, ClaimsService, EpisodeService, UserService, ClientService } from '../../../services';
import { finalize, startWith, switchMap, debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { Storage } from '../../../utils';
import { Router } from '@angular/router';
import { OutgoingClaimsConstant } from 'src/app/constants';

class ClaimPriority {
  highPriority: any = []
  mediumPriority: any = []
  lowPriority: any = []
}
class EpisodePriority {
  highPriority: any = []
  mediumPriority: any = []
}

class ClaimsStateCount {
  inReview: number
  triggerClaimNotReceived: number
  fundingClaimNotSent: number
  fundingNotReceived: number
  providerPaymentNotSent: number
}

class ClaimsPriorityCount {
  HIGH: number
  MEDIUM: number
  LOW: number
}

class EpisodePriorityCount {
  HIGH: number
  MEDIUM: number
}

@Component({
  selector: 'app-new-claims-dashboard',
  templateUrl: './new-claims-dashboard.component.html',
  styleUrls: ['./new-claims-dashboard.component.scss']
})

export class NewClaimsDashboardComponent implements OnInit {
  searchModelChanged: Subject<string> = new Subject<string>();
  @ViewChild('filterModal', { static: true }) public filterModal;
  @ViewChild('claimFilterModal', { static: true }) public claimFilterModal;
  @ViewChild('payerContactModal', { static: true }) public payerContactModal;
  @Input() public selectedDashboard;
  @Input() public dashboardTypes;
  @Output() emitSelectedDashboard = new EventEmitter<any>();
  user: any;
  loading = {
    totalClaimsChart: false,
    claimStatusChart: false,
    episodeClaimsChart: false,
    claims: false,
    episode: false,
    popup: false,
    patientSearch: false,
    clientSearch: false,
    billingProviderSearch: false,
    payerSearch: false,
    episodeSearch: false
  };
  totalClaimsChartList = [];
  claimsStatusChartList = [];
  episodeChartList = [];
  claimsStateCount = new ClaimsStateCount();
  claimsPriorityCount = new ClaimsPriorityCount();
  episodePriorityCount = new EpisodePriorityCount();
  claimsStateList = [];
  inReviewList = [];
  triggerClaimNotReceivedList = [];
  fundingClaimNotSentList = [];
  fundingNotReceivedList = [];
  providerPaymentNotSentList = [];
  inReviewState = new ClaimPriority();
  triggerClaimNotReceivedState = new ClaimPriority();
  fundingClaimNotSentState = new ClaimPriority();
  fundingNotReceivedState = new ClaimPriority();
  providerPaymentNotSentState = new ClaimPriority();
  episodeList = [];
  episodeTriggerClaimNotReceivedState = new EpisodePriority();
  milestoneTriggerClaimNotReceived: any = [];
  filterForm: FormGroup;
  claimFilterForm: FormGroup;
  payerContactForm: FormGroup;

  payer_search_text = new Subject();
  payer_results: Observable<any>;
  billing_provider_search_text = new Subject();
  billing_provider_results: Observable<any>;
  client_search_text = new Subject();
  client_results: Observable<any>;
  episode_search_text = new Subject();
  episode_results: Observable<any>;
  patient_search_text = new Subject();
  patient_results: Observable<any>;

  claimType = "INCOMING";

  tempPayer: any[] = [];
  tempBillingProvider: any[] = [];
  tempClient: any[] = [];
  tempEpisode: any[] = [];
  tempPatient: any[] = [];

  claimsFilterList = [];
  filterList = [];
  searchKeyword = '';

  hasPayerAddress = false;
  exceptionCodes: any = []
  tempExceptionCode: any = []
  fundRequestStatusTypes = OutgoingClaimsConstant.claimsDashboardFilterableStatus
  selectedFundRequestStatus = 'ALL'
  fundingClaimNotSentStateBackup = new ClaimPriority();

  // Doughnut
  // Common settings
  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { position: 'right' },
    cutoutPercentage: 70,
    plugins: {
      datalabels: {
        display: false,
        font: {
          size: 20,
        }
      }
    }
  };
  public doughnutChartType: ChartType = 'doughnut';

  // Total Claim Chart
  public claimLabels = [];
  public claimData: SingleDataSet = [];
  public claimLegends = false;
  public claimDataColors: Color[] = [
    {
      backgroundColor: [],
      borderWidth: 1,
    }
  ];
  public claimsPlugins: PluginServiceGlobalRegistrationAndOptions[] = []

  // Claim Status Chart
  public claimStatusLegends = false;
  public claimStatusLabels = [];
  public claimStatusData: SingleDataSet = [];
  public claimStatusColors: Color[] = [
    {
      backgroundColor: [],
      borderWidth: 1,
    }
  ];
  public claimStatusPlugins: PluginServiceGlobalRegistrationAndOptions[] = []

  // Episode Chart
  public episodeClaimLegends = false;
  public episodeClaimLabels = [];
  public episodeClaimData: SingleDataSet = [];
  public episodeClaimColors: Color[] = [
    {
      backgroundColor: [],
      borderWidth: 1,
    }
  ];
  public episodeClaimsPlugins: PluginServiceGlobalRegistrationAndOptions[] = []

  constructor(
    private formBuilder: FormBuilder,
    private _toaster: ToasterService,
    private claimsDashboardService: ClaimsDashboardService,
    private claimsService: ClaimsService,
    private episodeService: EpisodeService,
    private userService: UserService,
    private _storage: Storage,
    private router: Router,
    private renderer: Renderer2, private el: ElementRef,
    private clientService: ClientService
  ) {
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      this.searchKeyword = data;
      let filterData = this._storage.get('session', 'claimsDashboardFilterData');
      if (filterData) {
        filterData.searchKeyword = data;
        this._storage.set('session', 'claimsDashboardFilterData', filterData)
      }
      this.filterForm.controls.searchKeyword.setValue(this.searchKeyword.trim());
      this.getClaimDashboardData()
    });
    this.setFilterForm();
    this.setClaimFilterForm()
    this.setPayerContactForm();
  }

  addClass() {
    const elements = this.el.nativeElement.querySelectorAll('.sb-item-body');

    for (const e of elements) {
      this.renderer.removeClass(e, 'overflow-vis');
      setTimeout(() => {
        this.renderer.addClass(e, 'overflow-vis');
      });
    }
  }

  ngOnInit() {
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    this._storage.remove('session', 'claimsDashboardFilterData')
    this._storage.remove('session', 'claimsStateFilterData')
    this.getTotalClaims();
    this.getClaimsPriority();
    this.getEpisodeClaimsNotReceived();
    this.getClaimDashboardData()
    this.getExceptionCodes()

    this.payer_results = this.payer_search_text
      .pipe(
        startWith(this.payer_search_text),
        switchMap((payer_search_text: string) => this.searchPayer(payer_search_text))
      );
    this.billing_provider_results = this.billing_provider_search_text
      .pipe(
        startWith(this.billing_provider_search_text),
        switchMap((billing_provider_search_text: string) => this.searchBillingProvider(billing_provider_search_text))
      );
    this.client_results = this.client_search_text
      .pipe(
        startWith(this.client_search_text),
        switchMap((client_search_text: string) => this.searchClient(client_search_text))
      );
    this.episode_results = this.episode_search_text
      .pipe(
        startWith(this.episode_search_text),
        switchMap((episode_search_text: string) => this.searchEpisode(episode_search_text))
      );
    this.patient_results = this.patient_search_text
      .pipe(
        startWith(this.patient_search_text),
        switchMap((patient_search_text: string) => this.searchPatient(patient_search_text))
      );
  }

  getClaimDashboardData(filter = 'dashboard', data?) {
    let value = {}
    if (filter === 'dashboard') {
      const claimsStateFilterData = this._storage.get('session', 'claimsStateFilterData')
      value = data ? data : this.filterForm.value
      if (claimsStateFilterData) value = { ...value, ...claimsStateFilterData }
      this.getClaimsState(value);
      this.getEpisodeClaims(value);
      this.getEpisodeClaims({ ...value, milestoneTriggered: true }, true);
    }
    if (filter === 'claim') {
      const claimsDashboardFilterData = this._storage.get('session', 'claimsDashboardFilterData')
      value = data ? data : this.claimFilterForm.value
      if (claimsDashboardFilterData) value = { ...value, ...claimsDashboardFilterData }
      this.getClaimsState(value);
    }
  }

  setFilterForm() {
    this.filterForm = this.formBuilder.group({
      clientCode: [null],
      episodeName: [null],
      patientName: [null],
      searchKeyword: ''
    });
  }

  setClaimFilterForm() {
    this.claimFilterForm = this.formBuilder.group({
      payerCode: [null],
      billingProviderId: [null],
      patientAccountNumber: '',
      procedureCode: '',
      isFollowUp: '',
      exceptionCode: ['']
    });
  }

  setPayerContactForm() {
    this.payerContactForm = this.formBuilder.group({
      name: '',
      identifier: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      zip4: '',
      contactPhone: '',
      contactEmail: '',
      contactName: ''
    });
  }

  // return individual claim state data and individual priority of claim state data
  getClaimStateList(state) {
    let list = this.claimsStateList.filter(x => x.claimState === state)
    list.map(x => {
      x.days = this.calculateDateDiff(x.reviewDate)
      x.claimStatusClass = this.getClassName(x.outgoingClaimStatus?.toLowerCase())
    })
    list.sort((a, b) => b.days - a.days)
    let object = new ClaimPriority()
    object.highPriority = list.filter(x => x.priority === 'HIGH')
    object.mediumPriority = list.filter(x => x.priority === 'MEDIUM')
    object.lowPriority = list.filter(x => x.priority === 'LOW')
    return { list, object }
  }

  getClassName(status) {
    switch (status) {
      case 'in-review':
        return 'badge-info'
      case 'pended':
        return 'queue'
      case 'submitted':
      case 're-submitted':
        return 'requested'
      case 'cancelled':
        return 'closed'
      case 'rejected':
        return 'danger'
    }
  }

  fillClaimStates() {
    this.claimsPlugins = []
    this.claimStatusPlugins = []
    this.setClaimPlugin(this.claimsStateList.length)
    this.setClaimStatusPlugins(this.claimsStateList.length)

    const inReviewData = this.getClaimStateList('IN-REVIEW')
    this.inReviewList = inReviewData.list
    this.inReviewState = inReviewData.object
    this.claimsStateCount.inReview = this.inReviewList.length

    const triggerClaimNotReceivedData = this.getClaimStateList('TRIGGER-CLAIM-NOT-RECEIVED')
    this.triggerClaimNotReceivedList = triggerClaimNotReceivedData.list
    this.triggerClaimNotReceivedState = triggerClaimNotReceivedData.object
    this.claimsStateCount.triggerClaimNotReceived = this.triggerClaimNotReceivedList.length

    const fundingClaimNotSentData = this.getClaimStateList('FUNDING-CLAIM-NOT-SENT')
    this.fundingClaimNotSentList = fundingClaimNotSentData.list
    this.fundingClaimNotSentState = fundingClaimNotSentData.object
    this.fundingClaimNotSentStateBackup = JSON.parse(JSON.stringify(this.fundingClaimNotSentState))
    this.claimsStateCount.fundingClaimNotSent = this.fundingClaimNotSentList.length

    const fundingNotReceivedData = this.getClaimStateList('FUNDING-NOT-RECEIVED')
    this.fundingNotReceivedList = fundingNotReceivedData.list
    this.fundingNotReceivedState = fundingNotReceivedData.object
    this.claimsStateCount.fundingNotReceived = this.fundingNotReceivedList.length

    const providerPaymentNotSentData = this.getClaimStateList('PROVIDER-PAYMENT-NOT-SENT')
    this.providerPaymentNotSentList = providerPaymentNotSentData.list
    this.providerPaymentNotSentState = providerPaymentNotSentData.object
    this.claimsStateCount.providerPaymentNotSent = this.providerPaymentNotSentList.length

    this.claimsPriorityCount.HIGH = this.inReviewState.highPriority.length + this.triggerClaimNotReceivedState.highPriority.length + this.fundingClaimNotSentState.highPriority.length + this.fundingNotReceivedState.highPriority.length + this.providerPaymentNotSentState.highPriority.length
    this.claimsPriorityCount.MEDIUM = this.inReviewState.mediumPriority.length + this.triggerClaimNotReceivedState.mediumPriority.length + this.fundingClaimNotSentState.mediumPriority.length + this.fundingNotReceivedState.mediumPriority.length + this.providerPaymentNotSentState.mediumPriority.length
    this.claimsPriorityCount.LOW = this.inReviewState.lowPriority.length + this.triggerClaimNotReceivedState.lowPriority.length + this.fundingClaimNotSentState.lowPriority.length + this.fundingNotReceivedState.lowPriority.length + this.providerPaymentNotSentState.lowPriority.length
  }

  getExceptionCodes() {
    this.claimsService.fetchExceptionCodes().subscribe(res => {
      this.exceptionCodes = res.map(x => {
        return { label: x.description.trim(), value: x.code }
      })
    }, (error) => {
      console.log('Error', error)
    })
  }

  getClaimsState(body) {
    this.loading.claims = true;
    this.loading.totalClaimsChart = true;
    this.loading.claimStatusChart = true;
    this.claimsDashboardService.getClaimsState(body)
      .pipe(
        finalize(() => {
          this.loading.claims = false;
          this.loading.totalClaimsChart = false;
          this.loading.claimStatusChart = false;
        })
      )
      .subscribe(res => {
        if (res.data.results.length > 0) {
          this.claimsStateList = res.data.results;
          this.fillClaimStates()
        }
        else {
          this.resetData();
        }
      }), (error) => {
        this.loading.claims = false;
        this.loading.totalClaimsChart = false;
        this.loading.claimStatusChart = false;
        console.log("Error fetching Claims State List", error);
        this._toaster.displayError('Fetching Claims State List Failed');
      }
  };

  resetData() {
    this.claimsStateList = [];
    this.inReviewList = [];
    this.triggerClaimNotReceivedList = [];
    this.fundingClaimNotSentList = [];
    this.fundingNotReceivedList = [];
    this.providerPaymentNotSentList = [];
    this.inReviewState = new ClaimPriority();
    this.triggerClaimNotReceivedState = new ClaimPriority();
    this.fundingClaimNotSentState = new ClaimPriority();
    this.fundingNotReceivedState = new ClaimPriority();
    this.providerPaymentNotSentState = new ClaimPriority();
    this.loading.claims = false;
    this.loading.totalClaimsChart = false;
    this.loading.claimStatusChart = false;
    this.claimsStateCount.inReview = 0
    this.claimsStateCount.triggerClaimNotReceived = 0
    this.claimsStateCount.fundingClaimNotSent = 0
    this.claimsStateCount.fundingNotReceived = 0
    this.claimsStateCount.providerPaymentNotSent = 0
    this.claimsPriorityCount.HIGH = 0
    this.claimsPriorityCount.MEDIUM = 0
    this.claimsPriorityCount.LOW = 0
    this.claimsPlugins = []
    this.claimStatusPlugins = []
    this.setClaimPlugin(0)
    this.setClaimStatusPlugins(0)
  }

  // return count by comparing the value in the list
  compareAndGetCount(list, compareField, compareValue, valueField) {
    return list.filter(x => x[compareField] === compareValue).length > 0 ? list.filter(x => x[compareField] === compareValue)[0][valueField] : 0
  }

  getTotalClaims() {
    this.loading.totalClaimsChart = true;
    this.claimsDashboardService.getTotalClaims()
      .pipe(
        finalize(() => {
          this.loading.totalClaimsChart = false;
        })
      )
      .subscribe(res => {
        if (res.data.results.length > 0) {
          let labels = []
          let data = []
          let colors = []
          this.totalClaimsChartList = res.data.results;
          this.totalClaimsChartList.forEach(function (value) {
            if (value.claimState !== 'CLAIM-REJECTED') {
              labels.push(value.claimState.split('-').join(' ') === 'TRIGGER CLAIM NOT RECEIVED' ? 'IN QUEUE' : value.claimState.split('-').join(' '))
              data.push(value.claimStateCount)
              if (value.claimState === 'IN-REVIEW')
                colors.push('#8D67D5')
              else if (value.claimState === 'TRIGGER-CLAIM-NOT-RECEIVED')
                colors.push('#67D5C4')
              else if (value.claimState === 'FUNDING-CLAIM-NOT-SENT')
                colors.push('#A2D567')
              else if (value.claimState === 'FUNDING-NOT-RECEIVED')
                colors.push('#3C6F95')
              else if (value.claimState === 'PROVIDER-PAYMENT-NOT-SENT')
                colors.push('#D56785')
            }
          })
          this.claimLabels = labels
          this.claimData = data
          this.claimDataColors[0].backgroundColor = colors
          this.claimsStateCount.inReview = this.compareAndGetCount(this.totalClaimsChartList, 'claimState', 'IN-REVIEW', 'claimStateCount')
          this.claimsStateCount.triggerClaimNotReceived = this.compareAndGetCount(this.totalClaimsChartList, 'claimState', 'TRIGGER-CLAIM-NOT-RECEIVED', 'claimStateCount')
          this.claimsStateCount.fundingClaimNotSent = this.compareAndGetCount(this.totalClaimsChartList, 'claimState', 'FUNDING-CLAIM-NOT-SENT', 'claimStateCount')
          this.claimsStateCount.fundingNotReceived = this.compareAndGetCount(this.totalClaimsChartList, 'claimState', 'FUNDING-NOT-RECEIVED', 'claimStateCount')
          this.claimsStateCount.providerPaymentNotSent = this.compareAndGetCount(this.totalClaimsChartList, 'claimState', 'PROVIDER-PAYMENT-NOT-SENT', 'claimStateCount')
          this.setClaimPlugin(this.totalClaimsChartList.length > 0 ? this.totalClaimsChartList[0].totalClaimCount : 0);
        }
        else {
          this.totalClaimsChartList = [];
          this.loading.totalClaimsChart = false;
        }
      }), (error) => {
        this.loading.totalClaimsChart = false;
        console.log("Error fetching Total Claims Chart", error);
        this._toaster.displayError('Fetching Total Claims Chart Failed');
      }
  };

  getClaimsPriority() {
    this.loading.claimStatusChart = true;
    this.claimsDashboardService.getClaimsPriority()
      .pipe(
        finalize(() => {
          this.loading.claimStatusChart = false;
        })
      )
      .subscribe(res => {
        let labels = []
        let data = []
        let colors = []
        if (res.data.results.length > 0) {
          this.claimsStatusChartList = res.data.results;
          this.claimsStatusChartList.forEach(function (value) {
            labels.push(value.priority === 'LOW' ? 'NEW' : value.priority)
            data.push(value.claimPriorityCount)
            if (value.priority === 'HIGH')
              colors.push('#D56767')
            else if (value.priority === 'MEDIUM')
              colors.push('#D5C867')
            else
              colors.push('#67ACD5')
          })
          this.claimStatusLabels = labels
          this.claimStatusData = data
          this.claimStatusColors[0].backgroundColor = colors
          this.claimsPriorityCount.HIGH = this.compareAndGetCount(this.claimsStatusChartList, 'priority', 'HIGH', 'claimPriorityCount')
          this.claimsPriorityCount.MEDIUM = this.compareAndGetCount(this.claimsStatusChartList, 'priority', 'MEDIUM', 'claimPriorityCount')
          this.claimsPriorityCount.LOW = this.compareAndGetCount(this.claimsStatusChartList, 'priority', 'LOW', 'claimPriorityCount')
          this.setClaimStatusPlugins(this.claimsStatusChartList.length > 0 ? this.claimsStatusChartList[0].totalClaimCount : 0)
        }
        else {
          this.claimsStatusChartList = [];
          this.loading.claimStatusChart = false;
        }
      }), (error) => {
        this.loading.claimStatusChart = false;
        console.log("Error fetching Claims Priority Chart", error);
        this._toaster.displayError('Fetching Claims Priority Chart Failed');
      }
  };

  getEpisodeClaimsNotReceived() {
    this.loading.episodeClaimsChart = true;
    this.claimsDashboardService.getEpisodeClaimsNotReceived()
      .pipe(
        finalize(() => {
          this.loading.episodeClaimsChart = false;
        })
      )
      .subscribe(res => {
        let labels = []
        let data = []
        let colors = []

        if (res.data.results.length > 0) {
          this.episodeChartList = res.data.results;
          this.episodeChartList.forEach(function (value) {
            labels.push(value.priority)
            data.push(value.episodePriorityCount)
            if (value.priority === 'HIGH')
              colors.push('#D56767')
            else
              colors.push('#D5C867')
          })
          labels.push('Milestone Trigger')
          colors.push('#5a9ec5')
          this.episodeClaimLabels = labels
          this.episodeClaimData = data
          this.episodeClaimColors[0].backgroundColor = colors
          this.episodePriorityCount.HIGH = this.compareAndGetCount(this.episodeChartList, 'priority', 'HIGH', 'episodePriorityCount')
          this.episodePriorityCount.MEDIUM = this.compareAndGetCount(this.episodeChartList, 'priority', 'MEDIUM', 'episodePriorityCount')
          this.setEpisodeClaimsPlugins(this.episodeChartList.length > 0 ? this.episodeChartList[0].totalEpisodeCount : 0)
        }
        else {
          this.episodeChartList = [];
          this.loading.episodeClaimsChart = false;
        }
      }), (error) => {
        this.loading.episodeClaimsChart = false;
        console.log("Error fetching Episode Claims Not Received Chart", error);
        this._toaster.displayError('Fetching Episode Claims Not Received Chart Failed');
      }
  };

  searchPayer(searchText) {
    if (searchText.length > 2) {
      this.loading.payerSearch = true;
      return this.claimsService.searchPayer(searchText, this.claimType)
        .pipe(
          debounceTime(250),
          map((items: any) => {
            this.loading.payerSearch = false;
            return items
          })
        );
    } else {
      this.loading.payerSearch = false;
      return of([]);
    }
  }

  displayPayer = (payer?): string | undefined => {
    return payer ? this.toTitleCase(payer.payerName) : undefined;
  };

  searchBillingProvider(searchText) {
    if (searchText.length > 2) {
      this.loading.billingProviderSearch = true;
      return this.claimsService.searchBillingProvider(searchText)
        .pipe(
          debounceTime(250),
          map((items: any) => {
            this.loading.billingProviderSearch = false;
            return items
          })
        );
    } else {
      this.loading.billingProviderSearch = false;
      return of([]);
    }
  }

  displayBillingInfo = (billing?): string | undefined => {
    return billing ? this.toTitleCase(billing.displayName) : undefined;
  };

  searchClient(searchText) {
    if (searchText.length > 2) {
      this.loading.clientSearch = true;
      return this.clientService.getClients({ keyword: searchText, limit: 0 })
        .pipe(
          debounceTime(250),
          map((items: any) => {
            this.loading.clientSearch = false;
            return items.rows
          })
        );
    } else {
      this.loading.clientSearch = false;
      return of([]);
    }
  }

  displayClient = (client?): string | undefined => {
    return client ? this.toTitleCase(client.name) : undefined;
  };

  searchEpisode(searchText) {
    if (searchText.length > 2) {
      this.loading.episodeSearch = true;
      return this.episodeService.searchEpisodes({ all: true, searchKeyword: searchText })
        .pipe(
          debounceTime(250),
          map((items: any) => {
            const uniqueEpisodes = items.rows.filter((episode, i) => episode && (items.rows.findIndex(x => x.name === episode.name) === i));
            let item = []
            uniqueEpisodes.map(element => {
              let data = { episodeName: element.name }
              item = [...item, data]
            })
            this.loading.episodeSearch = false;
            return item
          })
        );
    } else {
      this.loading.episodeSearch = false;
      return of([]);
    }
  }

  displayEpisode = (episode?): string | undefined => {
    return episode ? this.toTitleCase(episode.episodeName) : undefined;
  };

  searchPatient(searchText) {
    if (searchText.length > 2) {
      this.loading.patientSearch = true;
      return this.userService.searchUsers({ keyword: searchText, limit: 0 })
        .pipe(
          debounceTime(250),
          map((items: any) => {
            let item = []
            items.rows.map(element => {
              let data = { displayName: element.firstName + ' ' + element.lastName }
              item = [...item, data]
            })
            this.loading.patientSearch = false;
            return item
          })
        );
    } else {
      this.loading.patientSearch = false;
      return of([]);
    }
  }

  displayPatient = (patient?): string | undefined => {
    return patient ? this.toTitleCase(patient.displayName) : undefined;
  };

  toTitleCase(name) {
    return name.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  onClaimSearchResultSelected(event, field) {
    if (field === 'billingProviderId')
      this.claimFilterForm.controls[`${field}`].setValue(event.text && event.text.id ? event.text.id : null);
    else if (field === 'payerCode')
      this.claimFilterForm.controls[`${field}`].setValue(event.text && event.text.payerCode ? event.text.payerCode : null);
    else {
      if (field !== 'exceptionCode') this.claimFilterForm.controls[`${field}`].setValue(event.text && event.text.id ? event.text.id : null);
    }
    switch (field) {
      case 'billingProviderId':
        this.tempBillingProvider = [];
        this.tempBillingProvider.push({
          label: event.text && event.text.id ? event.text.displayName : '',
          value: event.text && event.text.id ? event.text.id : null
        });
        break;
      case 'payerCode':
        this.tempPayer = [];
        this.tempPayer.push({
          label: event.text && event.text.payerCode ? event.text.payerName : '',
          value: event.text && event.text.payerCode ? event.text.payerCode : null
        });
        break;
      case 'exceptionCode':
        this.tempExceptionCode = [];
        this.tempExceptionCode.push(event);
        break;
    }
  }

  filterClaimStates() {
    ['patientAccountNumber', 'procedureCode'].forEach(element => {
      this.claimFilterForm.value[element] = this.claimFilterForm.value[element] ? this.claimFilterForm.value[element].trim() : this.claimFilterForm.value[element];
    });
    let filter_data = this.claimFilterForm.value;
    const sessionData = [{ label: 'claimsStateFilterData', value: filter_data }, { label: 'claimsFilteredBillingProvider', value: this.tempBillingProvider }, { label: 'claimsFilteredPayer', value: this.tempPayer }, { label: 'claimsFilteredExceptionCode', value: this.tempExceptionCode }]
    sessionData.forEach(x => {
      if (x.label === 'claimsStateFilterData' || (x.label !== 'claimsStateFilterData' && x.value.length !== 0)) this._storage.set('session', x.label, x.value);
    })

    Object.keys(filter_data).forEach(key => {
      let value = filter_data[key];
      if (value && key === 'billingProviderId') this.updateClaimFilterApplied('Billing Provider', key, value, this.tempBillingProvider);
      if (!value && key === 'billingProviderId') this.billing_provider_search_text.next('');
      if (value && key === 'payerCode') this.updateClaimFilterApplied('Payor', key, value, this.tempPayer);
      if (!value && key === 'payerCode') this.payer_search_text.next('');
      if (value && key === 'patientAccountNumber') this.updateClaimFilterApplied('Claim #', key, value, null);
      if (value && key === 'procedureCode') this.updateClaimFilterApplied('Procedure Code', key, value, null);
      if (value && key === 'isFollowUp') this.updateClaimFilterApplied('Follow Up', key, value, null);
      if (value && key === 'exceptionCode') this.updateClaimFilterApplied('Exception Code', key, value, this.tempExceptionCode);
    });
    this.getClaimDashboardData('claim', filter_data);
    this.closeClaimFilterModal(null);
  }

  updateClaimFilterApplied(field, formField, value, list) {
    if (this.claimsFilterList.length > 0) {
      let index = this.claimsFilterList.findIndex(x => x.field === field);
      if (index > -1)
        this.claimsFilterList.splice(index, 1);
    }
    if (list) {
      let valueIndex = list.findIndex(x => x.value === value);
      if (valueIndex > -1) {
        let data = { field: field, formField: formField, value: value, label: list[valueIndex].label };
        this.claimsFilterList.push(data);
      }
    }
    else {
      let data = { field: field, formField: formField, value: value, label: value };
      if (formField === 'isFollowUp') {
        data.label = value === 'true' ? 'Yes' : value === 'false' ? 'No' : 'All'
      }
      this.claimsFilterList.push(data);
    }
  }

  removeClaimsFilter(filter) {
    let filteredData = this._storage.get('session', 'claimsStateFilterData');
    this.claimFilterForm.patchValue(filteredData);
    switch (filter.formField) {
      case 'billingProviderId':
        this.billing_provider_search_text.next('');
        this.claimFilterForm.controls.billingProviderId.setValue('');
        break;
      case 'payerCode':
        this.payer_search_text.next('');
        this.claimFilterForm.controls.payerCode.setValue('');
        break;
      case 'patientAccountNumber':
        this.claimFilterForm.controls[filter.formField].setValue('');
        break;
      case 'procedureCode':
        this.claimFilterForm.controls[filter.formField].setValue('');
        break;
      case 'isFollowUp':
        this.claimFilterForm.controls[filter.formField].setValue('');
        break;
      case 'exceptionCode':
        this.tempExceptionCode = []
        this.claimFilterForm.controls.exceptionCode.setValue('');
        break;
    }
    let index = this.claimsFilterList.findIndex(x => x.value === filter.value)
    if (index > -1)
      this.claimsFilterList.splice(index, 1);
    this.getClaimDashboardData('claim', this.claimFilterForm.value);
    this._storage.set('session', 'claimsStateFilterData', this.claimFilterForm.value);
  }

  resetClaimsFilter() {
    this.billing_provider_search_text.next('');
    this.payer_search_text.next('');
    this.claimsFilterList = [];
    this.tempExceptionCode = [];
    this.claimFilterForm.reset();
    this.getClaimDashboardData('claim', this.claimFilterForm.value);
    this._storage.remove('session', 'claimsStateFilterData')
  }

  closeClaimFilterModal(action) {
    if (action === 'close') {
      this.billing_provider_search_text.next('');
      this.payer_search_text.next('');
      this.claimFilterForm.reset();
    }
    this.claimFilterModal.hide();
  }

  closeFilterModal(action) {
    if (action === 'close') {
      this.patient_search_text.next('');
      this.episode_search_text.next('');
      this.client_search_text.next('');
      this.filterForm.reset();
    }
    this.filterModal.hide();
  }

  searchByKeyword(event) {
    this.searchModelChanged.next(event);
  }

  setClaimPlugin(count) {
    // Total Data Value in center of doughnut Total Claims
    this.claimsPlugins.push({

      afterDraw(chart) {
        const ctx = chart.ctx;
        var txt1 = count;
        var txt2 = 'Total Claims';


        //Get options from the center object in options
        const sidePadding = 60;
        // const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

        //Get the width of the string and also the width of the element minus 10 to give it 5px side padding

        const stringWidth = ctx.measureText(txt1).width;
        // const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        // const widthRatio = elementWidth / stringWidth;
        // const newFontSize = Math.floor(30 * widthRatio);
        // const elementHeight = (chart.innerRadius * 2);

        // Pick a new font size so it will not be larger than the height of label.
        const fontSizeToUse = 24;
        ctx.font = fontSizeToUse + 'px Arial';
        ctx.fillStyle = 'black';

        // Draw text in center
        ctx.fillText(txt1, centerX, centerY - 10);
        var fontSizeToUse1 = 11;
        ctx.font = fontSizeToUse1 + 'px Arial';
        ctx.fillText(txt2, centerX, centerY + 10);
      }
    });
  }

  setClaimStatusPlugins(count) {
    this.claimStatusPlugins.push({

      afterDraw(chart) {
        const ctx = chart.ctx;
        var txt1 = count;
        var txt2 = 'Total Claims';


        //Get options from the center object in options
        const sidePadding = 60;
        // const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

        //Get the width of the string and also the width of the element minus 10 to give it 5px side padding

        const stringWidth = ctx.measureText(txt1).width;
        // const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        // const widthRatio = elementWidth / stringWidth;
        // const newFontSize = Math.floor(30 * widthRatio);
        // const elementHeight = (chart.innerRadius * 2);

        // Pick a new font size so it will not be larger than the height of label.
        const fontSizeToUse = 24;
        ctx.font = fontSizeToUse + 'px Arial';
        ctx.fillStyle = 'black';

        // Draw text in center
        ctx.fillText(txt1, centerX, centerY - 10);
        var fontSizeToUse1 = 11;
        ctx.font = fontSizeToUse1 + 'px Arial';
        ctx.fillText(txt2, centerX, centerY + 10);
      }
    });
  }

  setEpisodeClaimsPlugins(count) {
    this.episodeClaimsPlugins.push({

      afterDraw(chart) {
        const ctx = chart.ctx;
        var txt1 = count;
        var txt2 = 'Total Episodes';


        //Get options from the center object in options
        const sidePadding = 60;
        // const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

        //Get the width of the string and also the width of the element minus 10 to give it 5px side padding

        const stringWidth = ctx.measureText(txt1).width;
        // const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        // const widthRatio = elementWidth / stringWidth;
        // const newFontSize = Math.floor(30 * widthRatio);
        // const elementHeight = (chart.innerRadius * 2);

        // Pick a new font size so it will not be larger than the height of label.
        const fontSizeToUse = 24;
        ctx.font = fontSizeToUse + 'px Arial';
        ctx.fillStyle = 'black';

        // Draw text in center
        ctx.fillText(txt1, centerX, centerY - 10);
        var fontSizeToUse1 = 11;
        ctx.font = fontSizeToUse1 + 'px Arial';
        ctx.fillText(txt2, centerX, centerY + 10);
      }
    });
  }

  getEpisodeClaims(body, milestoneTriggered = false) {
    this.loading.episode = true;
    this.loading.episodeClaimsChart = true;
    this.claimsDashboardService.getEpisodeClaims(body)
      .pipe(
        finalize(() => {
          this.loading.episode = false;
          this.loading.episodeClaimsChart = false;
        })
      )
      .subscribe(res => {
        if (res.data.results.length > 0) {
          this.episodeList = res.data.results;
          this.episodeList.map(x => {
            x.days = this.calculateDateDiff(x.procedureDate)
          })
          this.episodeList.sort((a, b) => b.days - a.days)
          if (milestoneTriggered) {
            this.milestoneTriggerClaimNotReceived = this.episodeList
            this.episodeClaimData.push(this.milestoneTriggerClaimNotReceived.length)
          } else {
            this.episodeTriggerClaimNotReceivedState.highPriority = this.episodeList.filter(x => x.priority === 'HIGH')
            this.episodeTriggerClaimNotReceivedState.mediumPriority = this.episodeList.filter(x => x.priority === 'MEDIUM')
            this.episodePriorityCount.HIGH = this.episodeTriggerClaimNotReceivedState.highPriority.length
            this.episodePriorityCount.MEDIUM = this.episodeTriggerClaimNotReceivedState.mediumPriority.length
          }
          this.episodeClaimsPlugins = []
          this.setEpisodeClaimsPlugins(this.episodeTriggerClaimNotReceivedState.highPriority.length + this.episodeTriggerClaimNotReceivedState.mediumPriority.length + this.milestoneTriggerClaimNotReceived.length)
        }
        else {
          this.episodeList = this.episodeList ?? [];
          milestoneTriggered ? this.milestoneTriggerClaimNotReceived = [] : this.episodeTriggerClaimNotReceivedState = new EpisodePriority();
          this.loading.episode = false;
          this.loading.episodeClaimsChart = false;
          this.episodePriorityCount.HIGH = this.episodePriorityCount.HIGH ?? 0
          this.episodePriorityCount.MEDIUM = this.episodePriorityCount.MEDIUM ?? 0
          this.episodeClaimsPlugins = []
          this.setEpisodeClaimsPlugins(this.episodeList.length)
        }
      }), (error) => {
        this.loading.episode = false;
        this.loading.episodeClaimsChart = false;
        console.log("Error fetching Episode Claims", error);
        this._toaster.displayError('Fetching Episode Claims Failed');
      }
  };

  onSearchResultSelected(event, field) {
    if (field === 'episodeName') this.filterForm.controls[`${field}`].setValue(event.text && event.text.episodeName ? event.text.episodeName : null);
    else if (field === 'patientName') this.filterForm.controls[`${field}`].setValue(event.text && event.text.displayName ? event.text.displayName.toUpperCase() : null);
    else if (field === 'clientCode') this.filterForm.controls[`${field}`].setValue(event.text && event.text.code ? event.text.code : null);
    else
      this.filterForm.controls[`${field}`].setValue(event.text && event.text.id ? event.text.id : null);
    switch (field) {
      case 'episodeName':
        this.tempEpisode = [];
        this.tempEpisode.push({
          label: event.text && event.text.episodeName ? event.text.episodeName : '',
          value: event.text && event.text.episodeName ? event.text.episodeName : null
        });
        break;
      case 'patientName':
        this.tempPatient = [];
        this.tempPatient.push({
          label: event.text && event.text.displayName ? event.text.displayName.toUpperCase() : '',
          value: event.text && event.text.displayName ? event.text.displayName.toUpperCase() : null
        });
        break;
      case 'clientCode':
        this.tempClient = [];
        this.tempClient.push({
          label: event.text && event.text.name ? event.text.name : '',
          value: event.text && event.text.code ? event.text.code : null
        });
        break;
    }
  }

  filter() {
    let filter_data = this.filterForm.value;
    const sessionData = [{ label: 'claimsDashboardFilterData', value: filter_data }, { label: 'claimsFilteredClient', value: this.tempClient },
    { label: 'claimsFilteredPatient', value: this.tempPatient }, { label: 'claimsFilteredEpisode', value: this.tempEpisode }]
    sessionData.forEach(x => {
      if (x.label === 'claimsDashboardFilterData' || (x.label !== 'claimsDashboardFilterData' && x.value.length !== 0)) this._storage.set('session', x.label, x.value);
    })

    Object.keys(filter_data).forEach(key => {
      let value = filter_data[key];
      if (value && key === 'patientName') this.updateFilterApplied('Patient', key, value, this.tempPatient);
      if (!value && key === 'patientName') this.patient_search_text.next('');
      if (value && key === 'episodeName') this.updateFilterApplied('Episode', key, value, this.tempEpisode);
      if (!value && key === 'episodeName') this.episode_search_text.next('');
      if (value && key === 'clientCode') this.updateFilterApplied('Client', key, value, this.tempClient);
      if (!value && key === 'clientCode') this.client_search_text.next('');
    });
    this.getClaimDashboardData('dashboard', filter_data);
    this.closeFilterModal(null);
  }

  updateFilterApplied(field, formField, value, list) {
    if (this.filterList.length > 0) {
      let index = this.filterList.findIndex(x => x.field === field);
      if (index > -1)
        this.filterList.splice(index, 1);
    }
    if (list) {
      let valueIndex = list.findIndex(x => x.value === value);
      if (valueIndex > -1) {
        let data = { field: field, formField: formField, value: value, label: list[valueIndex].label };
        this.filterList.push(data);
      }
    }
    else {
      let data = { field: field, formField: formField, value: value, label: value };
      this.filterList.push(data);
    }
  }

  removeFilter(filter) {
    let filteredData = this._storage.get('session', 'claimsDashboardFilterData');
    this.filterForm.patchValue(filteredData);
    switch (filter.formField) {
      case 'patientName':
        this.patient_search_text.next('');
        this.filterForm.controls.patientName.setValue('');
        break;
      case 'episodeName':
        this.episode_search_text.next('');
        this.filterForm.controls.episodeName.setValue('');
        break;
      case 'clientCode':
        this.client_search_text.next('');
        this.filterForm.controls.clientCode.setValue('');
        break;
    }
    let index = this.filterList.findIndex(x => x.value === filter.value)
    if (index > -1) this.filterList.splice(index, 1);

    this.getClaimDashboardData('dashboard', this.filterForm.value);
    this._storage.set('session', 'claimsDashboardFilterData', this.filterForm.value);
  }

  resetFilter() {
    this.patient_search_text.next('');
    this.episode_search_text.next('');
    this.client_search_text.next('');
    this.filterList = [];
    this.filterForm.reset({
      searchKeyword: this.filterForm.get('searchKeyword').value
    });
    this.getClaimDashboardData('dashboard', this.filterForm.value);
    this._storage.remove('session', 'claimsDashboardFilterData')
  }

  updateFollowUp(claim) {
    this.claimsDashboardService.updateFollowUpStatus(claim.claimXrefHolistaId, true)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(res => {
        if (res && res.success) {
          this._toaster.displaySuccess('Successfully Follow Up');
          let index = this.claimsStateList.map(function (x) { return x.claimXrefHolistaId }).indexOf(claim.claimXrefHolistaId)
          claim.isFollowUp = true
          this.claimsStateList[index] = claim
          this.fillClaimStates()
        }
        else {
          this._toaster.displayWarning('Could not Follow Up');
        }
      }), (error) => {
        console.log("Error Updating Follow Up Status", error);
        this._toaster.displayError('Follow Up Failed');
      }
  }

  goToClaimsDetail(id) {
    window.open('claims/' + id, '_blank')
  }

  goToEpisodeDetails(id) {
    window.open('episodes/' + id + '/milestone', '_blank')
  }

  goToOutgoingClaimsDetail(claim) {
    window.open('outgoing-claims/' + claim.outgoingClaimId, '_blank')
  }

  goToContactDetails(claim) {
    window.open('provider?clientCode=' + claim.clientCode + '&npi=' + claim.providerCode, '_blank')
  }

  openContactPayerPopup(claim) {
    this.payerContactModal.show()
    this.loading.popup = true;
    this.claimsDashboardService.fetchPayerByClaimId(claim.claimId)
      .pipe(finalize(() => { this.loading.popup = false; }))
      .subscribe((response: any) => {
        if (response.data.payer) {
          if (response.data.payer.zip) {
            this.hasPayerAddress = (response.data.payer.addressLine1 || response.data.payer.addressLine2 || response.data.payer.city
              || response.data.payer.state || response.data.payer.zip) ? true : false
          }
          this.payerContactForm.patchValue(response.data.payer);
        } else {
          this.hasPayerAddress = false;
        }

      }, (error) => {
        console.log("Error getting payer Detail", error);
      })
  }

  closePayerContactModal() {
    this.payerContactModal.hide()
    this.payerContactForm.reset()
  }

  openFilterModal() {
    let filterData = this._storage.get('session', 'claimsDashboardFilterData');
    if (filterData) {
      this.getFilterList(filterData)
      this.filterForm.patchValue(filterData);
    }
    this.filterModal.show()
  }

  openClaimsFilterModal() {
    this.claimFilterForm.controls.isFollowUp.setValue("all");
    let filterData = this._storage.get('session', 'claimsStateFilterData');
    if (filterData) {
      this.getFilterList(filterData)
      this.claimFilterForm.patchValue(filterData);
      if (filterData.isFollowUp === '')
        this.claimFilterForm.controls.isFollowUp.setValue("all");
    }
    this.claimFilterModal.show()
  }

  changeFollowUp(e) {
    this.claimFilterForm.controls.isFollowUp.setValue(e.target.value);
  }

  getFilterList(filterData) {
    if (filterData.patientName) {
      this.tempPatient = this._storage.get('session', 'claimsFilteredPatient');
      let patientName = this.tempPatient.find(x => x.value === filterData.patientName).label;
      this.patient_search_text.next(this.toTitleCase(patientName));
    }
    if (filterData.billingProviderId) {
      this.tempBillingProvider = this._storage.get('session', 'claimsFilteredBillingProvider');
      let billingProviderName = this.tempBillingProvider.find(x => x.value === filterData.billingProviderId).label;
      this.billing_provider_search_text.next(this.toTitleCase(billingProviderName));
    }
    if (filterData.episodeName) {
      this.tempEpisode = this._storage.get('session', 'claimsFilteredEpisode');
      let episodeName = this.tempEpisode.find(x => x.value === filterData.episodeName).label;
      this.episode_search_text.next(this.toTitleCase(episodeName));
    }
    if (filterData.payerCode) {
      this.tempPayer = this._storage.get('session', 'claimsFilteredPayer');
      let payerName = this.tempPayer.find(x => x.value === filterData.payerCode).label;
      this.payer_search_text.next(this.toTitleCase(payerName));
    }
    if (filterData.clientCode) {
      this.tempClient = this._storage.get('session', 'claimsFilteredClient');
      let clientName = this.tempClient.find(x => x.value === filterData.clientCode).label;
      this.client_search_text.next(this.toTitleCase(clientName));
    }
  }

  // Returns date difference in days
  calculateDateDiff(dateSent) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //console.log(event, active);
  }

  removeSearchKeyword() {
    this.searchKeyword = '';
    this.searchByKeyword('');
  }

  showSelectedDashboard(event) {
    this.dashboardTypes = this.dashboardTypes.map((dashboard: any) => {
      if (dashboard.value === event.value) dashboard.disabled = true
      else dashboard.disabled = false
      return dashboard
    })
    this.emitSelectedDashboard.emit(this.selectedDashboard)
  }

  showSelectedFundRequestStatus(event) {
    const unapprovedFundingClaims = JSON.parse(JSON.stringify(this.fundingClaimNotSentStateBackup))
    if (event.value === 'ALL') {
      this.fundingClaimNotSentState = unapprovedFundingClaims
    } else {
      Object.keys(this.fundingClaimNotSentState).forEach(key => {
        this.fundingClaimNotSentState[key] = unapprovedFundingClaims[key].filter(x => x.outgoingClaimStatus == event.value)
      })
    }
  }


}

