import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  constructor(private http: HttpClientService) { }

  getMembers(page, limit) {
    return this.http.get(`${APICONSTANTS.MEMBER}/all?page=${page}&limit=${limit}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getMemberById(memberUuid) {
    return this.http.get(`${APICONSTANTS.MEMBER}/${memberUuid}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getMembersBySearchKeyword(keyword, page, limit) {
    return this.http.get(`${APICONSTANTS.MEMBER}/search/${keyword}?page=${page}&limit=${limit}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  checkMemberStatus(Id) {
    return this.http.post(`${APICONSTANTS.USER}/check-user`, { memberId: Id }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  assignMemberToUser(member) {
    return this.http.post(`${APICONSTANTS.USER}/invite`, member).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  searchPatientFromDashboard(keyword) {
    return this.http.get(`${APICONSTANTS.MEMBER}/search-member/${keyword}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  savePatient(patient) {
    return this.http.post(`${APICONSTANTS.MEMBER}`, patient).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updatePatient(patient) {
    return this.http.put(`${APICONSTANTS.MEMBER}/${patient.uuid || patient.memberUuid}`, patient).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getRelationShips(query?) {
    return this.http.get(`${APICONSTANTS.MEMBER_RELATIONSHIP}`, query).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getBenefitStatusCode(query?) {
    return this.http.get(`${APICONSTANTS.BENEFIT_STATUS_CODE}`, query).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  searchMembers(query) {
    let url = typeof query == 'string' ? `?${query}` : query.keyword ? '/search' : ''
    return this.http.get(`${APICONSTANTS.MEMBER}${url}`, typeof query == 'object' ? query : {}).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getMemberPlans(query?) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.MEMBER_PLAN}`, query).toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  deleteMember(id, paramType) {
    return this.http.delete(`${APICONSTANTS.MEMBER}/${id}?paramType=${paramType}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
}
