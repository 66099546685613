<div id="consented-patients-comments" class="m-0 p-0">
  <div class="d-flex justify-content-between align-items-center px-3 pt-3">
    <div class="d-flex">
      <span
        class="badge badge-sm bg-primary-dark text-white z-depth-0"
        *ngIf="reportingPeriod.startDate && reportingPeriod.endDate">
        Reporting Period
        <span>
          - {{ reportingPeriod.startDate }} -
          {{ reportingPeriod.endDate }}
        </span>
      </span>
    </div>

    <div class="row d-flex justify-content-end align-items-center">
      <div class="mr-2">
        <form [formGroup]="overviewFilterForm">
          <div class="row d-flex justify-content-end align-items-center" style="min-width: 400px">
            <div class="col-md-6 pr-2 text-right">
              <label id="hide-show-label">Show</label>
            </div>
            <div class="col-md-6 p-0 pl-2 pr-4">
              <div class="form-group m-0">
                <mdb-select
                  [options]="categoryList | sort : 'label'"
                  placeholder="Select category"
                  id="report-date-select"
                  [visibleOptions]="4"
                  formControlName="categoryId"
                  (selected)="categorySelected($event)">
                </mdb-select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div
    class="p-3"
    *ngIf="
      !loading.consentedPatientsComments &&
      (!consentedPatientsCommentsTableData || consentedPatientsCommentsTableData.data.length)
    ">
    <div style="margin-top: -24px">
      <app-table
        [report]="consentedPatientsCommentsTableData"
        [exportTable]="false"
        [module]="'report'"
        tableHeight="600px"></app-table>
    </div>
  </div>
  <div
    class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100 my-4 mx-3"
    *ngIf="
      !loading.consentedPatientsComments &&
      !consentedPatientsCommentsTableData.data.length &&
      !overviewFilterForm.get('categoryId').value
    ">
    <div class="text-center">
      <i class="icofont-file-spreadsheet icofont-4x text-secondary"></i>
      <p class="mt-2">Please select category</p>
    </div>
  </div>
  <div
    class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100 my-4 mx-3"
    *ngIf="
      !loading.consentedPatientsComments &&
      !consentedPatientsCommentsTableData.data.length &&
      overviewFilterForm.get('categoryId').value
    ">
    <div class="text-center">
      <i class="icofont-file-spreadsheet icofont-4x text-secondary"></i>
      <p class="mt-2">{{ noDataMessage.value }}</p>
    </div>
  </div>
  <div
    style="height: 300px !important"
    class="d-flex justify-content-center h-100 mt-4"
    *ngIf="loading.consentedPatientsComments">
    <app-loader [loader]="loading.consentedPatientsComments"></app-loader>
  </div>
</div>
