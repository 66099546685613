import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { ClientService, ExcelService, NetworkService, ProviderService, PurchaserService, ReportService, UserService } from '../../services';
import * as HeaderBreadCrumbActions from '../../action';
import { ReportUtility, ScrollTo, Storage } from '../../utils';
import { ActivatedRoute } from '@angular/router';
import { CLIENT_ADMINS, PROVIDERS_REPORT_DATA } from 'src/app/constants';
@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit {
  query = {
    keyword: '',
    page: 1,
    limit: 10,
    offset: 0,
    network: 'NH',
    entityCode: 1,
    tag: '',
    sortBy: 'npi',
    sortOrder: 'desc',
    category: 'medicalNetwork'
  }
  providerList: any = [];
  loading: boolean = false;
  reverse: boolean = true;
  user: any
  providerTypeList = [{ label: 'Physician', value: 1, disabled: false }, { label: 'Facility', value: 2, disabled: false }]
  networks: any = []
  totalProvider = 0
  userNetworkCode: string
  selectedEntityCode: any = 1
  showLimits = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 50, value: 50 }, { label: 100, value: 100 }]
  exportProviderList: any = []
  searchModelChanged: Subject<string> = new Subject<string>();
  backupProviderList: any = [];
  export_loading: boolean = false
  initial_loading: boolean = false
  providerIds: any = []

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private providerService: ProviderService,
    private _scrollTo: ScrollTo,
    private _storage: Storage,
    private route: ActivatedRoute,
    private userService: UserService,
    private ExcelService: ExcelService,
    private networkService: NetworkService,
    private clientService: ClientService,
    private purchaserService: PurchaserService,
    private _reportUtility: ReportUtility,
    private _reportService: ReportService
  ) {
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      this.query.keyword = data ? data.trim() : '';
      this.query.page = 1;
      this.query.offset = 0;
      if (this.user.roleCode == 'PR') this.getProvidersByUserId()
      else this.getProviders();
    });
  }

  async ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Providers', path: '/provider' }));
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    if (CLIENT_ADMINS.includes(this.user.roleCode)) this.getClientNetworks()
    else if (this.user.roleCode == 'PUA') this.getPurchaserNetworks()
    else {
      this.getNetworks().then((res) => {
        this.route.queryParams.subscribe(params => {
          if (params.clientCode && params.npi) {
            this.clientService.getNetworksByClientCode(params.clientCode, { limit: 0 })
              .subscribe((res) => {
                if (res.length > 0) {
                  let claimNetwork = this.networks.find(x => x.code == res[0].code)
                  this.query.network = claimNetwork.value
                }
              }, (error) => {
                console.log("Error getting networks by clientCode", error);
              })
            this.query.keyword = params.npi
            this.query.entityCode = 2
            this.getProviders();
          }
          else {
            if (this.user.roleCode == 'PR')
              this.getProvidersByUserId();
            else
              this.getNetWorkCode()
          }
        });
      })
    }
  }

  // Get list of providers Id to export providers
  async getProvidersId() {
    this.providerIds = []
    let exportQuery = JSON.parse(JSON.stringify(this.query));
    exportQuery.limit = this.totalProvider
    await this.providerService
      .getProviders(exportQuery)
      .then((response: any) => {
        let ids = []
        response.providerList.forEach(x => {
          ids.push(x.providerId)
        })
        this.providerIds = ids
      },
        error => {
          console.log('Error', error)
        });
  }

  async exportToCsv() {
    this.export_loading = true;
    let exportQuery = JSON.parse(JSON.stringify(this.query));
    if (this.query.keyword) {
      await this.getProvidersId()
      exportQuery.provider = this.providerIds.length ? String(this.providerIds) : null
    } else {
      delete exportQuery.provider
    }
    const { TABLE_TITLE, EXCEL_NAME } = PROVIDERS_REPORT_DATA;
    let tableHeaders = JSON.parse(JSON.stringify(PROVIDERS_REPORT_DATA.TABLE_HEADERS));
    exportQuery.entityCode === 1 ? tableHeaders.sort((a, b) => a.physicianOrder - b.physicianOrder) : tableHeaders.sort((a, b) => a.facilityOrder - b.facilityOrder);
    this._reportService.getProvidersReport(exportQuery, false).subscribe((res: any) => {
      this.exportProviderList = [];
      this.export_loading = false;
      res.map(x => {
        if (x.facilityPhone) {
          x.facilityPhone = this.formatPhoneNumber(x.facilityPhone);
        }
        return x
      });
      this.exportProviderList = this._reportUtility.mapProvidersExportData(res);
      const columnsWidth = tableHeaders.map(header => header.columnWidth);
      const report = {
        data: this.exportProviderList,
        tableHeaders: tableHeaders,
        excelName: EXCEL_NAME,
        tableTitle: TABLE_TITLE,
        columnsWidth
      };
      this._reportUtility.exportToCsv(report, true);
    }, error => {
      console.log('Error', error);
      this.export_loading = false;
    });
  }

  getClientNetworks() {
    this.loading = true;
    this.clientService.getNetworksByClientCode(this.user.referenceCode)
      .subscribe(res => {
        res.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.networks = JSON.parse(JSON.stringify(res))
        this.query.network = this.networks[0].code
        this.getProviders()
      }, (error) => {
        console.log('Error', error)
      })
  }

  getPurchaserNetworks() {
    this.purchaserService.getNetworks(this.user.referenceCode)
      .subscribe(res => {
        res.rows.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.networks = JSON.parse(JSON.stringify(res.rows))
        this.query.network = this.networks[0].code
        this.getProviders()
      },
        (error) => {
          console.log('Error', error)
        })
  }

  getNetworks() {
    this.loading = true;
    return new Promise(resolve => {
      this.networkService
        .getAll({ limit: 0, fields: 'code,name' })
        .pipe(
          finalize(() => { this.loading = false; })
        )
        .subscribe((res) => {
          if (res.rows.length != 0) {
            res.rows.map(network => {
              network.label = network.name
              network.value = network.code
              network.disabled = network.code == 'NH' ? true : false
              return network
            })
            this.networks = res.rows
            resolve(this.networks);
          }
        });
    })
  }

  async getNetWorkCode() {
    this.loading = true;
    this.initial_loading = true
    this.query.network = 'NH'
    this.query.entityCode = 1
    this.networks = this.networks.map(x => {
      x.disabled = x.value === 'NH' ? true : false;
      return x
    })
    this.providerTypeList = this.providerTypeList.map(x => {
      x.disabled = x.value === 1 ? true : false;
      return x
    })
    this.getProviders();
  }

  async getProviders() {
    this.loading = true;
    await this.providerService
      .getProviders(this.query)
      .then((response: any) => {
        this.providerList = []
        this.loading = false;
        this.totalProvider = response.totalProvider
        response.providerList.map(x => {
          if (x.phone) {
            x.phone = this.formatPhoneNumber(x.phone);
          }
          if (x.fax) {
            x.fax = this.formatPhoneNumber(x.fax);
          }
          return x
        })
        this.providerList = response.providerList
      },
        error => {
          this.loading = false;
          console.log('Error', error)
        });
  }

  formatPhoneNumber(phoneNumber) {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  setOrder(value: string) {
    if (this.query.sortBy === value) {
      this.reverse = !this.reverse;
      this.query.sortOrder = this.query.sortOrder === "desc" ? "asc" : "desc";
    }
    else {
      this.reverse = true;
      this.query.sortOrder = "desc";
    }
    this.query.sortBy = value;
    if (this.user.roleCode == 'PR') this.getProvidersByUserId()
    else this.getProviders();
  }

  scrollToTop() {
    this._scrollTo.ScrollTo('scrollToTop', 'center', 'smooth')
  }

  pageChanged(event) {
    this.query.page = event;
    this.query.offset = this.query.page * this.query.limit - this.query.limit
    if (this.user.roleCode == 'PR') this.getProvidersByUserId()
    else this.getProviders();
  }

  searchByProviderType(provider) {
    this.query.page = 1;
    this.query.offset = this.query.page * this.query.limit - this.query.limit;
    this.query.entityCode = provider.value
    this.providerTypeList = this.providerTypeList.map(x => {
      x.disabled = x.value == provider.value ? true : false;
      return x
    })
    this.getProviders()
  }

  async searchByNetwork(network) {
    this.query.page = 1;
    this.query.offset = this.query.page * this.query.limit - this.query.limit;
    this.query.network = network.value
    this.networks = this.networks.map(x => {
      x.disabled = x.value == network.value ? true : false;
      return x
    })
    this.providerList = []
    this.getProviders()
  }

  searchByLimit(limit) {
    this.query.page = 1;
    this.query.offset = this.query.page * this.query.limit - this.query.limit;
    this.query.limit = limit.value
    if (this.user.roleCode == 'PR') this.getProvidersByUserId()
    else this.getProviders()
  }

  getAssociatedDetails(provider, index) {
    if (provider.collapsed) {
      if (!this.providerList[index].associatedDetails) {
        if (this.query.entityCode == 1) {
          this.providerService.getAssociatedFacility(provider.providerId, { networkCode: this.query.network, physicianId: provider.providerId })
            .subscribe((res: any) => {
              this.providerList[index].associatedDetails = res;
            }), error => {
              console.log("Error getting facility", error)
            }
        } else {
          this.providerService.getAssociatedPhysician(provider.providerId, { locationId: provider.providerId, networkCode: this.query.network })
            .subscribe((res: any) => {
              this.providerList[index].associatedDetails = res;
            }), error => {
              console.log("Error getting physician", error)
            }
        }
        if (!this.providerList[index].episodePointOfContact) {
          this.providerService.getEpisodePointOfContact(provider.providerId, this.query.network)
            .subscribe(res => {
              this.providerList[index].episodePointOfContact = res !== undefined ? res : 'null'
            }, error => {
              console.log("Error", error)
            })
        }
      }
    }
  }

  searchProviderByKeyword(event) {
    this.searchModelChanged.next(event);
  }

  getProvidersByUserId() {
    this.loading = true;
    this.userService
      .getProvidersByUserId(this.user.id, this.query)
      .pipe(finalize(() => { }))
      .subscribe(res => {
        if (res.length > 0) {
          const pIdString = res.map(p => p.providerId).join();
          this.providerService
            .getProvidersByIds(pIdString)
            .pipe(finalize(() => { }))
            .subscribe(res => {
              this.loading = false;
              this.totalProvider = res.totalProvider
              res.providerList.map(x => {
                if (x.phone) {
                  x.phone = this.formatPhoneNumber(x.phone);
                }
                if (x.fax) {
                  x.fax = this.formatPhoneNumber(x.fax);
                }
                return x
              })
              this.providerList = res.providerList;
              this.backupProviderList = this.providerList.map(x => Object.assign({}, x));
            });
        }
        else {
          this.loading = false;
          this.totalProvider = 0
          this.providerList = []
        }
      });
  }

  removeSearchKeyword() {
    this.query.keyword = ''
    this.searchProviderByKeyword('')
  }
}
