<!-- Expanded Chart Begins -->
<div
  class="my-3 col-md-12"
  [attr.id]="expand.newEpisodeByMonth ? 'vmd-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH) &&
    expand.newEpisodeByMonth
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="newEpisodeByMonthForm">
            <div class="d-flex justify-content-between align-items-center">
              <label class="m-0">Period</label>
              <mdb-select
                style="width: 80px"
                class="ml-3"
                placeholder="Year"
                [options]="yearOptions | sort : 'label'"
                id="year-select"
                [visibleOptions]="3"
                formControlName="year"
                (selected)="yearSelected($event)">
              </mdb-select>
            </div>
          </form>
          <span
            class="link pointer-cursor donotprint ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="min-height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.newEpisodesByMonthReport && newEpisodeByMonthChart.labels.length"
        [datasets]="newEpisodeByMonthChart.dataset"
        [labels]="newEpisodeByMonthChart.labels"
        [options]="newEpisodeByMonthChart.options"
        [plugins]="newEpisodeByMonthChart.plugins"
        [chartType]="newEpisodeByMonthChart.chartType">
      </canvas>
      <p *ngIf="!loading.newEpisodesByMonthReport && !newEpisodeByMonthChart.labels.length">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.newEpisodesByMonthReport">
        <app-loader [loader]="loading.newEpisodesByMonthReport"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  class="my-3 col-md-12"
  [attr.id]="expand.episodeByCarePathway ? 'vmd-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY) &&
    expand.episodeByCarePathway
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY) as report">
          {{ report.reportName }}
        </p>
        <span
          class="link pointer-cursor donotprint"
          (click)="onViewToggle(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY, false)">
          <i class="fas fa-compress-alt"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <div class="row h-100">
        <div class="col-6">
          <canvas
            baseChart
            *ngIf="!loading.episodeByCarePathwayReport && episodeCarePathwayChart.labels.length"
            [datasets]="episodeCarePathwayChart.dataset"
            [labels]="episodeCarePathwayChart.labels"
            [options]="episodeCarePathwayChart.options"
            [plugins]="episodeCarePathwayChart.plugins"
            [chartType]="episodeCarePathwayChart.chartType">
          </canvas>
          <p *ngIf="!loading.episodeByCarePathwayReport && !episodeCarePathwayChart.labels.length">
            {{ noDataMessage.value }}
          </p>
          <div
            class="d-flex justify-content-center align-items-center h-75"
            *ngIf="loading.episodeByCarePathwayReport">
            <app-loader [loader]="loading.episodeByCarePathwayReport"></app-loader>
          </div>
        </div>
        <div class="col-6">
          <app-table
            *ngIf="!loading.episodeByCarePathwayReport && totalEpisodeByCareTableData.data.length"
            [report]="totalEpisodeByCareTableData"
            [exportTable]="false"
            [module]="'report'"
            tableHeight="auto">
            <ng-template #total>
              <tr class="bg-white sticky-bottom">
                <td class="p-1 pl-2" colspan="2">Total</td>
                <td class="p-1 pr-2">
                  {{ totalEpisodeCount }}
                </td>
              </tr>
            </ng-template>
          </app-table>
        </div>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  class="my-3 col-md-12"
  [attr.id]="expand.portalEngagement ? 'vmd-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED) &&
    expand.portalEngagement
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED) as report">
          {{ report.reportName }}
        </p>
        <span
          class="link pointer-cursor donotprint"
          (click)="onViewToggle(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED, false)">
          <i class="fas fa-compress-alt"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body style="min-height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.portalEngagementReport && portalEngagementChart.labels.length"
        [datasets]="portalEngagementChart.dataset"
        [labels]="portalEngagementChart.labels"
        [options]="portalEngagementChart.options"
        [plugins]="portalEngagementChart.plugins"
        [chartType]="portalEngagementChart.chartType">
      </canvas>
      <p *ngIf="!loading.portalEngagementReport && !portalEngagementChart.labels.length">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.portalEngagementReport">
        <app-loader [loader]="loading.portalEngagementReport"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  class="my-3 col-md-12"
  [attr.id]="expand.patientComplianceRequiredTaskYtd ? 'vmd-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD) &&
    expand.patientComplianceRequiredTaskYtd
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="patientComplianceRequiredTaskYtdForm" style="min-width: 300px">
            <div class="d-flex justify-content-between align-items-center">
              <div class="w-100 d-flex justify-content-between align-items-center">
                <div style="display: inline-block; width: 220px">
                  <mdb-select
                    *ngIf="!isIndividual"
                    [options]="patientComplianceCategoryList"
                    id="category"
                    placeholder="Select category"
                    [multiple]="true"
                    [enableSelectAll]="true"
                    (selected)="patientComplianceCategorySelected($event)"
                    (deselected)="patientComplianceCategoryDeselected($event)"
                    formControlName="categoryId">
                  </mdb-select>
                </div>
                <mdb-select
                  style="width: 80px"
                  class="ml-3"
                  placeholder="Year"
                  [options]="patientComplianceYtdYearOptions | sort : 'label'"
                  id="year-select"
                  [visibleOptions]="3"
                  formControlName="selectedYear"
                  (selected)="
                    reportYearSelected(
                      $event,
                      REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD
                    )
                  ">
                </mdb-select>
              </div>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="
              onViewToggle(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD, false)
            ">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="
          !loading.patientComplianceRequiredTaskYtd &&
          patientComplianceRequiredTaskChart.labels.length
        "
        [datasets]="patientComplianceRequiredTaskChart.dataset"
        [labels]="patientComplianceRequiredTaskChart.labels"
        [options]="patientComplianceRequiredTaskChart.options"
        [plugins]="patientComplianceRequiredTaskChart.plugins"
        [chartType]="patientComplianceRequiredTaskChart.chartType">
      </canvas>
      <div
        class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
        *ngIf="
          !loading.patientComplianceRequiredTaskYtd &&
          !patientComplianceRequiredTaskChart.labels.length &&
          !patientComplianceRequiredTaskYtdForm.get('categoryId').value
        ">
        <div class="text-center">
          <i class="icofont-layers icofont-4x text-secondary"></i>
          <p class="mt-2">Please select category</p>
        </div>
      </div>
      <p
        *ngIf="
          !loading.patientComplianceRequiredTaskYtd &&
          !patientComplianceRequiredTaskChart.labels.length &&
          patientComplianceRequiredTaskYtdForm.get('categoryId').value
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.patientComplianceRequiredTaskYtd">
        <app-loader [loader]="loading.patientComplianceRequiredTaskYtd"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  class="my-3 col-md-12"
  [attr.id]="expand.portalEngagementMilestoneDetail ? 'vmd-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL) &&
    expand.portalEngagementMilestoneDetail
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL) as report">
          {{ report.reportName }}
        </p>
        <span
          class="link pointer-cursor donotprint"
          (click)="onViewToggle(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL, false)">
          <i class="fas fa-compress-alt"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body style="min-height: 500px">
      <canvas
        baseChart
        *ngIf="
          !loading.portalEngagementMilestoneDetailReport &&
          portalEngagementMilestoneDetailChart.labels.length
        "
        [datasets]="portalEngagementMilestoneDetailChart.dataset"
        [labels]="portalEngagementMilestoneDetailChart.labels"
        [options]="portalEngagementMilestoneDetailChart.options"
        [plugins]="portalEngagementMilestoneDetailChart.plugins"
        [chartType]="portalEngagementMilestoneDetailChart.chartType">
      </canvas>
      <p
        *ngIf="
          !loading.portalEngagementMilestoneDetailReport &&
          !portalEngagementMilestoneDetailChart.labels.length
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.portalEngagementMilestoneDetailReport">
        <app-loader [loader]="loading.portalEngagementMilestoneDetailReport"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  [attr.id]="expand.patientAgreementSignedIn3Days ? 'vmd-overview' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS) &&
    expand.patientAgreementSignedIn3Days
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p class="m-0 font-weight-normal">
          Patient Agreement
          {{ isNovantAndMaternity ? '(Signed in < 3 days)' : 'Signed Prior to Surgery' }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="patientAgreementSignedIn3DaysForm">
            <div class="d-flex justify-content-between align-items-center">
              <label class="m-0">Period</label>
              <mdb-select
                style="width: 80px"
                class="ml-3"
                placeholder="Year"
                [options]="yearOptionReports.patientAgreementSignedIn3Days | sort : 'label'"
                id="year-select"
                [visibleOptions]="3"
                formControlName="selectedYear"
                (selected)="
                  reportYearSelected($event, REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS)
                ">
              </mdb-select>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="
          !loading.patientAgreementSignedIn3Days && patientAgreementSignedIn3DaysChart.labels.length
        "
        [datasets]="patientAgreementSignedIn3DaysChart.dataset"
        [labels]="patientAgreementSignedIn3DaysChart.labels"
        [options]="patientAgreementSignedIn3DaysChart.options"
        [plugins]="patientAgreementSignedIn3DaysChart.plugins"
        [chartType]="patientAgreementSignedIn3DaysChart.chartType">
      </canvas>
      <p
        *ngIf="
          !loading.patientAgreementSignedIn3Days &&
          !patientAgreementSignedIn3DaysChart.labels.length
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.patientAgreementSignedIn3Days">
        <app-loader [loader]="loading.patientAgreementSignedIn3Days"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  class="my-3 col-md-12"
  [attr.id]="expand.reasonForCancellationAccumulative ? 'vmd-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.REASON_FOR_CANCELLATION_ACCUMULATIVE) &&
    expand.reasonForCancellationAccumulative
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.REASON_FOR_CANCELLATION_ACCUMULATIVE) as report">
          {{ report.reportName }}
        </p>
        <span
          class="link pointer-cursor donotprint"
          (click)="onViewToggle(REPORT_NAME_CODES.REASON_FOR_CANCELLATION_ACCUMULATIVE, false)">
          <i class="fas fa-compress-alt"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body style="min-height: 500px">
      <canvas
        baseChart
        *ngIf="
          !loading.reasonForCancellationAccumulative &&
          reasonForCancellationAccumulativeChart.labels.length
        "
        [datasets]="reasonForCancellationAccumulativeChart.dataset"
        [labels]="reasonForCancellationAccumulativeChart.labels"
        [options]="reasonForCancellationAccumulativeChart.options"
        [plugins]="reasonForCancellationAccumulativeChart.plugins"
        [chartType]="reasonForCancellationAccumulativeChart.chartType">
      </canvas>
      <p
        *ngIf="
          !loading.reasonForCancellationAccumulative &&
          !reasonForCancellationAccumulativeChart.labels.length
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.reasonForCancellationAccumulative">
        <app-loader [loader]="loading.reasonForCancellationAccumulative"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  class="my-3 col-md-12"
  [attr.id]="expand.portalEngagementIndividual ? 'vmd-overview' : null"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL) &&
    expand.portalEngagementIndividual
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL) as report">
          {{ report.reportName }}
        </p>
        <span
          class="link pointer-cursor donotprint"
          (click)="onViewToggle(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL, false)">
          <i class="fas fa-compress-alt"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body style="min-height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.portalEngagementIndividual && portalEngagementIndividualChart.labels.length"
        [datasets]="portalEngagementIndividualChart.dataset"
        [labels]="portalEngagementIndividualChart.labels"
        [options]="portalEngagementIndividualChart.options"
        [plugins]="portalEngagementIndividualChart.plugins"
        [chartType]="portalEngagementIndividualChart.chartType">
      </canvas>
      <p
        *ngIf="
          !loading.portalEngagementIndividual && !portalEngagementIndividualChart.labels.length
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.portalEngagementIndividual">
        <app-loader [loader]="loading.portalEngagementIndividual"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<!-- Expanded Chart Ends -->

<div
  [className]="
    !expand.newEpisodeByMonth &&
    !expand.episodeByCarePathway &&
    !expand.activeEpisodeByMonth &&
    !expand.portalEngagement &&
    !expand.portalEngagementIndividual &&
    !expand.patientComplianceRequiredTaskYtd &&
    !expand.portalEngagementMilestoneDetail &&
    !expand.patientAgreementSignedIn3Days &&
    !expand.reasonForCancellationAccumulative
      ? ''
      : 'd-none'
  ">
  <div
    class="mt-3"
    *ngIf="
      parsedValueManagementFilterData?.isConfigLoading &&
      !parsedValueManagementFilterData?.toShowReport.length
    ">
    <app-loader [loader]="true"></app-loader>
  </div>
  <div
    id="vmd-overview"
    class="m-0 p-0"
    *ngIf="
      !parsedValueManagementFilterData?.isConfigLoading &&
      parsedValueManagementFilterData?.toShowReport &&
      parsedValueManagementFilterData?.toShowReport.length
    ">
    <div class="d-flex justify-content-between align-items-center p-3">
      <span class="d-flex">
        <h6 class="mb-0 font-weight-bold mr-2">
          {{
            overviewFilterForm.get('categoryName').value === 'All'
              ? 'Program Overview'
              : overviewFilterForm.get('categoryName').value
          }}
        </h6>
        <span
          class="badge badge-sm bg-primary-dark text-white z-depth-0"
          *ngIf="
            parsedValueManagementFilterData.startDate && parsedValueManagementFilterData.endDate
          ">
          Reporting Period
          <span>
            - {{ parsedValueManagementFilterData.startDate }} -
            {{ parsedValueManagementFilterData.endDate }}
          </span>
        </span>
      </span>

      <form [formGroup]="overviewFilterForm" id="hide-while-downloading">
        <div class="row d-flex justify-content-end align-items-center" style="min-width: 400px">
          <div class="col-md-6 pr-2 text-right">
            <label>Show</label>
          </div>
          <div class="col-md-6 p-0 pl-2 pr-3">
            <div class="form-group m-0">
              <mdb-select
                [options]="categoryList | sort : 'label'"
                placeholder="Select category"
                id="report-date-select"
                [visibleOptions]="4"
                formControlName="categoryId"
                (selected)="categorySelected($event)">
              </mdb-select>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="px-3">
      <!-- Active Episode By Month Table Starts -->
      <div
        class="col-m-12 p-0 mb-4"
        *ngIf="toShowReport && toShowReport.includes(REPORT_NAME_CODES.ACTIVE_EPISODE_BY_MONTH)">
        <mdb-card>
          <mdb-card-header *ngIf="getReport(REPORT_NAME_CODES.ACTIVE_EPISODE_BY_MONTH) as report">{{
            report.reportName
          }}</mdb-card-header>
          <mdb-card-body [style.padding]="'0 0 0 0'">
            <div>
              <table
                id="datatable"
                class="datatable position-relative m-0"
                bordered="true"
                hover="true"
                mdbTable>
                <thead class="position-sticky top-0" style="z-index: 1">
                  <tr class="position-sticky green lighten-5">
                    <th *ngFor="let header of MONTHS_NAME_FULL">{{ header }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of activeEpisodeByMonthList">
                    <td>{{ item.year }}</td>
                    <td>{{ item.january }}</td>
                    <td>{{ item.february }}</td>
                    <td>{{ item.march }}</td>
                    <td>{{ item.april }}</td>
                    <td>{{ item.may }}</td>
                    <td>{{ item.june }}</td>
                    <td>{{ item.july }}</td>
                    <td>{{ item.august }}</td>
                    <td>{{ item.september }}</td>
                    <td>{{ item.october }}</td>
                    <td>{{ item.november }}</td>
                    <td>{{ item.december }}</td>
                  </tr>
                  <tr
                    *ngIf="
                      !loading.activeEpisodeByMonthReport &&
                      (!activeEpisodeByMonthList || activeEpisodeByMonthList.length === 0)
                    ">
                    <td [attr.colspan]="MONTHS_NAME_FULL.length">
                      {{ noDataMessage.value }}
                    </td>
                  </tr>
                  <tr *ngIf="loading.activeEpisodeByMonthReport">
                    <td [attr.colspan]="MONTHS_NAME_FULL.length">
                      <div class="p-1">
                        <app-loader
                          [loader]="loading.activeEpisodeByMonthReport"
                          [loaderSmall]="true"></app-loader>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mdb-card-body>
        </mdb-card>
      </div>
      <!-- Active Episode By Month Table Ends -->

      <div
        class="row"
        *ngIf="
          toShowReport &&
          (toShowReport.includes(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH) ||
            toShowReport.includes(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY))
        ">
        <div
          class="mb-4"
          [class]="isIndividual ? 'col-md-6' : 'col-md-8'"
          *ngIf="toShowReport && toShowReport.includes(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH)">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="newEpisodeByMonthForm">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="m-0">Period</label>
                      <mdb-select
                        style="width: 80px"
                        class="ml-3"
                        placeholder="Year"
                        [options]="yearOptions | sort : 'label'"
                        id="year-select"
                        [visibleOptions]="3"
                        formControlName="year"
                        (selected)="yearSelected($event)">
                      </mdb-select>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor donotprint ml-3"
                    (click)="onViewToggle(REPORT_NAME_CODES.NEW_EPISODES_BY_MONTH, true)">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="!loading.newEpisodesByMonthReport && newEpisodeByMonthChart.labels.length"
                [datasets]="newEpisodeByMonthChart.dataset"
                [labels]="newEpisodeByMonthChart.labels"
                [options]="newEpisodeByMonthChart.options"
                [plugins]="newEpisodeByMonthChart.plugins"
                [chartType]="newEpisodeByMonthChart.chartType">
              </canvas>
              <p *ngIf="!loading.newEpisodesByMonthReport && !newEpisodeByMonthChart.labels.length">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.newEpisodesByMonthReport">
                <app-loader [loader]="loading.newEpisodesByMonthReport"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div
          class="col-md-4 mb-4"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY) &&
            !isIndividual
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY) as report">
                  {{ report.reportName }}
                </p>
                <span
                  class="link pointer-cursor donotprint"
                  (click)="onViewToggle(REPORT_NAME_CODES.EPISODES_BY_CARE_PATHWAY, true)">
                  <i class="fas fa-expand-alt"></i>
                </span>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 336px">
              <canvas
                baseChart
                *ngIf="!loading.episodeByCarePathwayReport && episodeCarePathwayChart.labels.length"
                [datasets]="episodeCarePathwayChart.dataset"
                [labels]="episodeCarePathwayChart.labels"
                [options]="episodeCarePathwayChart.options"
                [plugins]="episodeCarePathwayChart.plugins"
                [chartType]="episodeCarePathwayChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.episodeByCarePathwayReport && !episodeCarePathwayChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.episodeByCarePathwayReport">
                <app-loader [loader]="loading.episodeByCarePathwayReport"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div
          class="col-md-6 mb-4"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL) &&
            isIndividual
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="
                    getReport(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL) as report
                  ">
                  {{ report.reportName }}
                </p>
                <span
                  class="link pointer-cursor donotprint"
                  (click)="
                    onViewToggle(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL, true)
                  ">
                  <i class="fas fa-expand-alt"></i>
                </span>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 336px">
              <canvas
                baseChart
                *ngIf="
                  !loading.portalEngagementMilestoneDetailReport &&
                  portalEngagementMilestoneDetailChart.labels.length
                "
                [datasets]="portalEngagementMilestoneDetailChart.dataset"
                [labels]="portalEngagementMilestoneDetailChart.labels"
                [options]="portalEngagementMilestoneDetailChart.options"
                [plugins]="portalEngagementMilestoneDetailChart.plugins"
                [chartType]="portalEngagementMilestoneDetailChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.portalEngagementMilestoneDetailReport &&
                  !portalEngagementMilestoneDetailChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.portalEngagementMilestoneDetailReport">
                <app-loader [loader]="loading.portalEngagementMilestoneDetailReport"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          toShowReport &&
          (toShowReport.includes(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD) ||
            toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED) ||
            toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL))
        ">
        <div
          [class]="isIndividual ? 'col-md-6 mb-4' : 'col-md-8 mb-4'"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="
                    getReport(REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD) as report
                  ">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="patientComplianceRequiredTaskYtdForm" style="min-width: 300px">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="w-100 d-flex justify-content-between align-items-center">
                        <div style="display: inline-block; width: 220px">
                          <mdb-select
                            *ngIf="!isIndividual"
                            [options]="patientComplianceCategoryList"
                            id="category"
                            placeholder="Select category"
                            [multiple]="true"
                            [enableSelectAll]="true"
                            (selected)="patientComplianceCategorySelected($event)"
                            (deselected)="patientComplianceCategoryDeselected($event)"
                            formControlName="categoryId">
                          </mdb-select>
                        </div>
                        <mdb-select
                          style="width: 80px"
                          class="ml-3"
                          placeholder="Year"
                          [options]="patientComplianceYtdYearOptions | sort : 'label'"
                          id="year-select"
                          [visibleOptions]="3"
                          formControlName="selectedYear"
                          (selected)="
                            reportYearSelected(
                              $event,
                              REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD
                            )
                          ">
                        </mdb-select>
                      </div>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor ml-3"
                    (click)="
                      onViewToggle(
                        REPORT_NAME_CODES.PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD,
                        true
                      )
                    ">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="
                  !loading.patientComplianceRequiredTaskYtd &&
                  patientComplianceRequiredTaskChart.labels.length
                "
                [datasets]="patientComplianceRequiredTaskChart.dataset"
                [labels]="patientComplianceRequiredTaskChart.labels"
                [options]="patientComplianceRequiredTaskChart.options"
                [plugins]="patientComplianceRequiredTaskChart.plugins"
                [chartType]="patientComplianceRequiredTaskChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.patientComplianceRequiredTaskYtd &&
                  !patientComplianceRequiredTaskChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.patientComplianceRequiredTaskYtd">
                <app-loader [loader]="loading.patientComplianceRequiredTaskYtd"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div
          class="col-md-6 mb-4"
          *ngIf="
            isIndividual &&
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL) as report">
                  {{ report.reportName }}
                </p>
                <span
                  class="link pointer-cursor donotprint"
                  (click)="onViewToggle(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_DETAIL, true)">
                  <i class="fas fa-expand-alt"></i>
                </span>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 335px">
              <canvas
                baseChart
                *ngIf="
                  !loading.portalEngagementIndividual &&
                  portalEngagementIndividualChart.labels.length
                "
                [datasets]="portalEngagementIndividualChart.dataset"
                [labels]="portalEngagementIndividualChart.labels"
                [options]="portalEngagementIndividualChart.options"
                [plugins]="portalEngagementIndividualChart.plugins"
                [chartType]="portalEngagementIndividualChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.portalEngagementIndividual &&
                  !portalEngagementIndividualChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.portalEngagementIndividual">
                <app-loader [loader]="loading.portalEngagementIndividual"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div
          class="col-md-4 mb-4"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="
                    getReport(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED) as report
                  ">
                  {{ report.reportName }}
                </p>
                <span
                  class="link pointer-cursor donotprint"
                  (click)="
                    onViewToggle(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED, true)
                  ">
                  <i class="fas fa-expand-alt"></i>
                </span>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 335px">
              <canvas
                baseChart
                *ngIf="!loading.portalEngagementReport && portalEngagementChart.labels.length"
                [datasets]="portalEngagementChart.dataset"
                [labels]="portalEngagementChart.labels"
                [options]="portalEngagementChart.options"
                [plugins]="portalEngagementChart.plugins"
                [chartType]="portalEngagementChart.chartType">
              </canvas>
              <p *ngIf="!loading.portalEngagementReport && !portalEngagementChart.labels.length">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.portalEngagementReport">
                <app-loader [loader]="loading.portalEngagementReport"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          toShowReport &&
          (toShowReport.includes(REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS) ||
            toShowReport.includes(REPORT_NAME_CODES.REASON_FOR_CANCELLATION_ACCUMULATIVE))
        ">
        <div
          class="col-md-6 mb-4"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p class="m-0 font-weight-normal">
                  Patient Agreement
                  {{ isNovantAndMaternity ? '(Signed in < 3 days)' : 'Signed Prior to Surgery' }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="patientAgreementSignedIn3DaysForm">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="m-0">Period</label>
                      <mdb-select
                        style="width: 80px"
                        class="ml-3"
                        placeholder="Year"
                        [options]="yearOptionReports.patientAgreementSignedIn3Days | sort : 'label'"
                        id="year-select"
                        [visibleOptions]="3"
                        formControlName="selectedYear"
                        (selected)="
                          reportYearSelected(
                            $event,
                            REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS
                          )
                        ">
                      </mdb-select>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor ml-3"
                    (click)="
                      onViewToggle(REPORT_NAME_CODES.PATIENT_AGREEMENT_SIGNED_IN_3_DAYS, true)
                    ">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 320px">
              <canvas
                baseChart
                *ngIf="
                  !loading.patientAgreementSignedIn3Days &&
                  patientAgreementSignedIn3DaysChart.labels.length
                "
                [datasets]="patientAgreementSignedIn3DaysChart.dataset"
                [labels]="patientAgreementSignedIn3DaysChart.labels"
                [options]="patientAgreementSignedIn3DaysChart.options"
                [plugins]="patientAgreementSignedIn3DaysChart.plugins"
                [chartType]="patientAgreementSignedIn3DaysChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.patientAgreementSignedIn3Days &&
                  !patientAgreementSignedIn3DaysChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.patientAgreementSignedIn3Days">
                <app-loader [loader]="loading.patientAgreementSignedIn3Days"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div
          class="col-md-6 mb-4"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.REASON_FOR_CANCELLATION_ACCUMULATIVE)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="
                    getReport(REPORT_NAME_CODES.REASON_FOR_CANCELLATION_ACCUMULATIVE) as report
                  ">
                  {{ report.reportName }}
                </p>
                <span
                  class="link pointer-cursor donotprint"
                  (click)="
                    onViewToggle(REPORT_NAME_CODES.REASON_FOR_CANCELLATION_ACCUMULATIVE, true)
                  ">
                  <i class="fas fa-expand-alt"></i>
                </span>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 335px">
              <canvas
                baseChart
                *ngIf="
                  !loading.reasonForCancellationAccumulative &&
                  reasonForCancellationAccumulativeChart.labels.length
                "
                [datasets]="reasonForCancellationAccumulativeChart.dataset"
                [labels]="reasonForCancellationAccumulativeChart.labels"
                [options]="reasonForCancellationAccumulativeChart.options"
                [plugins]="reasonForCancellationAccumulativeChart.plugins"
                [chartType]="reasonForCancellationAccumulativeChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.reasonForCancellationAccumulative &&
                  !reasonForCancellationAccumulativeChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.reasonForCancellationAccumulative">
                <app-loader [loader]="loading.reasonForCancellationAccumulative"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      !parsedValueManagementFilterData?.isConfigLoading &&
      parsedValueManagementFilterData?.toShowReport &&
      !parsedValueManagementFilterData?.toShowReport?.length
    "
    class="text-center no-conversation-container py-4 border mx-3 mt-4">
    <i class="icofont-file-spreadsheet icofont-3x text-secondary"></i>
    <p class="mt-2">Report Configuration Missing.</p>
  </div>
</div>
