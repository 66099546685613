import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClientService, PlatformService } from '../../services';
import * as HeaderBreadCrumbActions from '../../action';
import { PaymentService, ClaimsService } from '../../services';
import { CLAIM_TABLE_HEADERS, MaskConstant, CLAIMS_TABLE_UNEDITABLE_FIELDS, NON_PRE_POPULATED_FIELDS, INVALID_STATUS_EXCEEDS_REASON, PAYMENT_STATUS, MISMATCHED_STATUS, PAYMENT_TYPE_LIST } from '../../constants';
import { finalize, startWith, switchMap, debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { PaymentMode } from '../../constants';
import { Observable, Subject, of } from 'rxjs';
import * as moment from 'moment';
import { HolistaUtils, Storage } from '../../utils';
import { Router } from '@angular/router';
import { TableComponent } from 'src/app/common';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  paymentList = [];
  payorList = [];
  payeeList = [];
  paymentForm: FormGroup;
  filterForm: FormGroup;
  dateMask: any[] = MaskConstant.DATE;
  searchKeyword: any;
  loading = {
    payment: false,
    episode: false,
    popup: false
  };
  page = 1;
  limit = 10;
  totalCount = 0;
  isEnabled = null;
  submitted = false;
  submit_enabled = false;
  paymentModeList = [];
  episodeDropdownList: any = [];
  bundleComponentList: any = [];
  filterList = [];
  tempPayor = [];
  tempPayee = [];
  allPayorList = [];
  allPayeeList = [];
  billingProviderList = [];
  paymentTypes: any[] = PAYMENT_TYPE_LIST;
  clientList: any[] = [];
  platformList: any[] = [];
  searchModelChanged: Subject<string> = new Subject<string>();
  payeeSearchText = new Subject();
  payee_results: Observable<any>;
  claimIdentifierSearchText = new Subject();
  claimIdentifier_results: Observable<any>;
  patientAccNumText = '';
  user: any;
  disableSave: boolean = false;
  result = { payments: true, searchedPayments: true };
  totalPayment: number = 0;
  isBatchPayment: boolean = false;
  claimList: any;
  invalidStatusReason = MISMATCHED_STATUS;
  claimsTableUneditableFields = CLAIMS_TABLE_UNEDITABLE_FIELDS;
  claimTableHeaders = CLAIM_TABLE_HEADERS;
  selectedClaims = [];
  nonPrePopulatedFields = NON_PRE_POPULATED_FIELDS;
  invalidStatusExceedsReason = INVALID_STATUS_EXCEEDS_REASON;
  PAYMENT_STATUS = PAYMENT_STATUS;
  providerSearchText = new Subject();
  providersFound: Observable<any>;
  PAYMENT_EXPAND_TABLE_HEADERS = ['Claim ID', 'Patient', 'Episode', 'Funding Request', 'Bundle', 'Bundle Component'];


  @ViewChild('paymentModal', { static: true }) public paymentModal;
  @ViewChild('filterModal', { static: true }) public filterModal;
  @ViewChild(TableComponent, { static: false }) public tableComponent;

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private clientService: ClientService,
    private platformService: PlatformService,
    private claimsService: ClaimsService,
    private route: ActivatedRoute,
    private _storage: Storage,
    private router: Router,
    private _holistaUtils: HolistaUtils,
  ) {
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      this.searchKeyword = data;
      this.search();
    });
    this.setFilterForm();
  }

  ngOnInit() {
    const filterData = this._storage.get('session', 'paymentFilterData');
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(
      new HeaderBreadCrumbActions.AddBreadCrumb({
        name: 'Payments',
        path: '/payment'
      })
    );
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    this.paymentModeList = PaymentMode;
    this.tempPayor = this._storage.get('session', 'tempPayor');
    this.tempPayee = this._storage.get('session', 'tempPayee');

    filterData ? (
      this.filterForm.patchValue(filterData),
      this.filterPayment(true),
      this.getAllPayor(),
      this.getAllPayee(),
      this.searchPayment(filterData)
    ) : this.getAllPayor().then((data: any) => {
      this.getAllPayee().then((data: any) => {
        this.searchPayment(this.filterForm.value);
      })
    });

    this.payee_results = this.payeeSearchText
      .pipe(
        startWith(this.payeeSearchText),
        switchMap((payeeSearchText: string) => this.searchPayee(payeeSearchText))
      );
  }

  setFilterForm() {
    this.filterForm = this.formBuilder.group({
      paymentType: [null],
      paymentMode: [null],
      payorId: [null],
      payeeId: [null],
      page: 1,
      limit: 10,
      keyword: null
    });
  }

  getPayor(type) {
    this.payorList = [];
    if (type == 'RECEIVED') {
      this.allPayorList.map(c => {
        if (c.typeCode == 'CLIENT') {
          this.payorList = [...this.payorList, { label: c.label, value: c.value, typeCode: c.typeCode }];
        }
      })
    } else {
      this.allPayorList.map(c => {
        if (c.typeCode == 'PLATFORM') {
          this.payorList = [...this.payorList, { label: c.label, value: c.value, typeCode: c.typeCode }];
        }
      })
    }
  }

  getPayee(type) {
    this.payeeList = [];
    if (type == 'RECEIVED') {
      this.allPayeeList.map(c => {
        if (c.typeCode == 'PLATFORM') {
          this.payeeList = [...this.payeeList, { label: c.label, value: c.value, typeCode: c.typeCode }];
        }
      })
    } else {
      this.allPayeeList.map(c => {
        if (c.typeCode != 'PLATFORM') {
          this.payeeList = [...this.payeeList, { label: c.label, value: c.value, typeCode: c.typeCode }];
        }
      })
    }
  }

  closeModal() {
    this.paymentForm.reset();
    this.totalPayment = 0;
    this.paymentModal.hide();
    this.isEnabled = null;
    this.submitted = false;
    this.submit_enabled = false;
    this.loading.popup = false;
    this.episodeDropdownList = [];
    this.bundleComponentList = [];
    this.claimIdentifierSearchText.next('');
    this.patientAccNumText = '';
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
    this.disableSave = false;
    this.isBatchPayment = false;
    this.selectedClaims.length = 0;
  }

  searchPaymentByKeyword(event) {
    this.searchModelChanged.next(event);
  }

  search() {
    let data = Object.assign({}, this.filterForm.value);
    data.page = 1;
    data.limit = this.limit;
    data.keyword = this.searchKeyword.trim();
    this.searchPayment(data);
  }

  searchPayee(searchText) {
    if (searchText.length > 2) {
      return this.claimsService.getPayeeReferences(searchText)
        .pipe(
          debounceTime(250),
          map((items: any) => {
            return items
          })
        );
    } else {
      return of([]);
    }
  }

  displayPayee = (payee?): string | undefined => {
    return payee && this.toTitleCase(payee.name);
  };

  openFilterModal() {
    const filterData = this._storage.get('session', 'paymentFilterData');
    this.tempPayee = this._storage.get('session', 'tempPayee');
    this.tempPayor = this._storage.get('session', 'tempPayor');
    if (filterData) {
      if (this.tempPayee && this.tempPayee.length) {
        const payeeName = this.tempPayee[0].label;
        this.payeeSearchText.next(this.toTitleCase(payeeName));
      }
      this.filterForm.reset();
      this.filterForm.patchValue(filterData);
    }
    this.filterModal.show();
  }

  closeFilterModal(action) {
    if (action === 'close') {
      !this.filterList.length && this.filterForm.reset();
      this.payeeSearchText.next('');
      this.tempPayee = [];
      this.tempPayor = [];
    }
    this.filterModal.hide();
  }

  onSearchResultSelected(event, field) {
    if (field == 'payeeId')
      this.filterForm.controls[`${field}`].setValue(event.text && event.text.id ? event.text.id : null);
    else
      this.filterForm.controls[`${field}`].setValue(event.value ? event.value : null);

    switch (field) {
      case 'payorId':
        this.tempPayor = [];
        this.tempPayor.push({
          label: event.label && event.value ? event.label : '',
          value: event.label && event.value ? event.value : null
        });
        break;
      case 'payeeId':
        this.tempPayee = [];
        this.tempPayee.push({
          label: event.text && event.text.id && event.text.name,
          value: event.text && event.text.id ? event.text.id : null
        });
        break;
    }
  }

  filterPayment(isInitiated?: boolean) {
    const filterData = this.filterForm.value;
    Object.keys(filterData).forEach(key => {
      const value = filterData[key];
      if (value && key == 'paymentType') this.updateFilterApplied('Payment Type', key, value, null);
      if (value && key == 'paymentMode') this.updateFilterApplied('Payment Mode', key, value, null);
      if (value && key == 'payorId') this.updateFilterApplied('Payor', key, value, this.tempPayor);
      if (value && key == 'payeeId') this.updateFilterApplied('Payee', key, value, this.tempPayee);
    });
    filterData.page = 1;
    filterData.limit = this.limit;
    this.searchPayment(filterData);
    this._storage.set('session', 'tempPayor', this.tempPayor);
    this._storage.set('session', 'tempPayee', this.tempPayee);
    !isInitiated && this.closeFilterModal(null);
    !isInitiated && this._storage.set('session', 'paymentFilterData', filterData);
  }

  updateFilterApplied(field, formField, value, list) {
    if (this.filterList.length > 0) {
      const index = this.filterList.findIndex(x => x.field === field);
      if (index > -1)
        this.filterList.splice(index, 1);
    }
    if (list) {
      const valueIndex = list.findIndex(x => x.value === value);
      if (valueIndex > -1) {
        const data = { field, formField, value, label: list[valueIndex].label };
        this.filterList.push(data);
      }
    }
    else {
      const data = { field, formField, value, label: value };
      this.filterList.push(data);
    }
  }

  removeFilter(filter) {
    switch (filter.formField) {
      case 'paymentType':
        this.filterForm.controls.paymentType.setValue(null);
        break;
      case 'paymentMode':
        this.filterForm.controls.paymentMode.setValue(null);
        break;
      case 'payorId':
        this.filterForm.controls.payorId.setValue(null);
        break;
      case 'payeeId':
        this.tempPayee = [];
        this.payeeSearchText.next('');
        this._storage.set('session', 'tempPayee', this.tempPayee);
        this.filterForm.controls.payeeId.setValue(null);
        break;
    }
    const index = this.filterList.findIndex(x => x.value == filter.value)
    index > -1 && (
      this.filterList.splice(index, 1),
      this._storage.set('session', 'paymentFilterData', this.filterForm.value)
    )
    this.page = 1;
    this.filterForm.controls.page.setValue(this.page);
    this.filterForm.controls.limit.setValue(this.limit);
    let data = Object.assign({}, this.filterForm.value);
    if (this.searchKeyword) {
      data.keyword = this.searchKeyword;
    }
    this.searchPayment(data);
  }

  resetFilter() {
    this.filterList = [];
    this.tempPayee = [];
    this.filterForm.reset();
    this.payeeSearchText.next('');
    this._storage.set('session', 'paymentFilterData', this.filterForm.value);
    this._storage.set('session', 'tempPayee', this.tempPayee);
    this.page = 1;
    let data = Object.assign({}, this.filterForm.value);
    if (this.searchKeyword) {
      data.page = this.page;
      data.limit = this.limit;
      data.keyword = this.searchKeyword;
      this.searchPayment(data);
    } else {
      this.setFilterForm();
      this.searchPayment(this.filterForm.value);
    }
  }

  searchPayment(body) {
    this.loading.payment = true;
    this.result.payments = true;
    this.result.searchedPayments = true;
    for (const key in body) {
      if (!body[key]) delete body[key]
    }
    // this.paymentService.searchPayment(body)
    this.paymentService.searchNewPayments(body)
      .pipe(
        finalize(() => {
          this.loading.payment = false;
        })
      )
      .subscribe(res => {
        if (res.count > 0) {
          this.totalCount = res.count;
          this.paymentList = res.rows.map((payment) => {
            let totalPatientResponsibility = 0;
            payment.status = this._holistaUtils.titleCasing(payment.status, '-'),
              payment.claimPayments = payment.claimPayments.map((claim) => {
                claim.bundleComponentName = claim.claimPaymentComponents.map((bundleComponent) => bundleComponent.bundleComponentName).join(', ');
                totalPatientResponsibility += (+claim.patientResponsibility || 0);
                return claim;
              });
            payment.totalAmount -= totalPatientResponsibility; //gets total net amount
            return payment;
          });
        }
        else {
          this.totalCount = 0;
          this.paymentList = [];
          this.result.searchedPayments = false;
        }
      }), (error) => {
        this.result.searchedPayments = false;
        console.log("Error while applying filter to Payment", error);
      }
  };

  checkFilterFormData() {
    let hasFormData = false;
    for (const key in this.filterForm.value) {
      if (this.filterForm.value[key])
        hasFormData = true;
    }
    return hasFormData;
  }

  pageChanged(event) {
    this.page = event;
    this.filterForm.controls.page.setValue(event);
    this.filterForm.controls.limit.setValue(this.limit);
    if (this.checkFilterFormData() || this.searchKeyword) {
      let data = Object.assign({}, this.filterForm.value);
      if (this.searchKeyword) {
        data.keyword = this.searchKeyword;
      }
      this.searchPayment(data);
    }
  }

  editPayment(payment) {
    this.router.navigate([`payment/edit/paid/${payment.uuid}`]);
  }

  viewPayment(payment) {
    const paymentType = payment.paymentType === "PAID" ? 'paid' : 'received';
    this.router.navigate([`/payment/view/${paymentType}/${payment.uuid}`]);
  }

  formatDate(dateObjects) {
    dateObjects.forEach(element => {
      let d = moment.utc(this.paymentForm.value[element], 'YYYY-MM-DD')
      d == null || !d.isValid() ? this.paymentForm.controls[element].setValue(null) : this.paymentForm.controls[element].setValue(moment.utc(this.paymentForm.value[element]).format('MM/DD/YYYY'));
    });
  }

  getAllPayor() {
    this.loading.payment = true;
    return new Promise(resolve => {
      this.clientService.getClients({ limit: 0, fields: 'name,code,id' })
        .subscribe((res) => {
          if (res && res.count > 0) {
            this.clientList = res.rows;
            this.clientList.map(c => {
              this.allPayorList = [...this.allPayorList, { label: c.name, value: c.id, typeCode: 'CLIENT' }];
            })
          }
        });
      this.platformService.getAll({ limit: 0, fields: 'name,code,id,addressLine1,addressLine2,city,state,zip' })
        .pipe(finalize(() => { this.loading.payment = false; }))
        .subscribe((res) => {
          if (res && res.count > 0) {
            this.platformList = res.rows;
            this.platformList.map(p => {
              this.allPayorList = [...this.allPayorList, { label: p.name, value: p.id, typeCode: 'PLATFORM' }];
            })
            resolve(this.allPayorList);
          }
        });
    })
  }

  getAllPayee() {
    this.loading.payment = true;
    return new Promise(resolve => {
      this.platformList.map(p => {
        this.allPayeeList = [...this.allPayeeList, { label: p.name, value: p.id, typeCode: 'PLATFORM' }];
      })
      this.claimsService.getBillingProviders('')
        .pipe(finalize(() => { this.loading.payment = false; }))
        .subscribe((res) => {
          this.billingProviderList = res;
          this.billingProviderList.forEach(x => {
            this.allPayeeList = [...this.allPayeeList, {
              label: x.basicInfo.businessName ? x.basicInfo.businessName : x.basicInfo.firstName + ' ' + x.basicInfo.lastName,
              value: x.id, typeCode: x.specialityInformationProviderCode
            }];
          })
          resolve(this.allPayeeList);
        });
    });
  }

  paymentCollapsed(payment) {
    if (payment.uuid) {
      payment.collapsed = !payment.collapsed;
    }
  }

  toTitleCase(name) {
    return name.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  view835Claim(payment) {
    this.router.navigate(['claims', payment.claimPayments[0].claimId, 'edi', payment.status.toUpperCase() !== "IN-PROGRESS", payment.uuid])
  }

  removeSearchKeyword() {
    this.searchKeyword = ''
    this.searchPaymentByKeyword('')
  }
}
