<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center mt-4 action position-sticky">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group has-search input-group mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          [(ngModel)]="query.keyword"
          (ngModelChange)="searchByKeyword($event)"
          class="form-control"
          id="search"
          placeholder="Search" />
        <div class="input-group-append" *ngIf="query.keyword">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-light z-depth-0 pl-3 pr-3"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="OpenFilterModal()"
          mdbWavesEffect>
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50 mt-2 mb-2">
      <div style="width: 40%; margin-top: 5px">
        <app-select
          [options]="networksForFilter"
          [id]="'filterClient'"
          [placeholder]="'Select network'"
          (onSelected)="searchByNetwork($event)"
          (onDeselected)="searchByNetwork($event, 'remove')"
          [allowClear]="true"></app-select>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="openBundleModal()"
          mdbWavesEffect>
          <i class="icofont-plus"></i> ADD
        </button>
      </div>
    </div>
  </div>

  <div class="keywords position-sticky" *ngIf="filterList && filterList.length > 0">
    <div class="keywords d-flex mt-2 flex-wrap align-items-center">
      <small class="mb-2">Filters: </small>
      <span class="d-block mr-1 mb-2" *ngFor="let filter of filterList">
        <small class="d-block text-muted ml-1"></small>
        <div class="chip blue lighten-5 mb-0">
          {{ filter.field }}:
          <span class="font-weight-500 text-dark">{{ filter.label }}</span>
          <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
        </div>
      </span>
      <button
        type="button"
        class="btn btn-link waves-light m-0 p-0"
        mdbWavesEffect
        (click)="ResetFilter()">
        <span class="text-info">Reset Filter</span>
      </button>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table
          mdbTable
          hover="true"
          small="true"
          class="table-mobile-view v-top"
          stickyHeader="true">
          <thead id="scrollToTop">
            <tr>
              <th width="20"></th>
              <th class="w-25" [class.active]="query.sortBy === 'name'" (click)="setOrder('name')">
                Name
                <span *ngIf="query.sortBy === 'name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th [class.active]="query.sortBy === 'description'" (click)="setOrder('description')">
                Description
                <span *ngIf="query.sortBy === 'description'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th [class.active]="query.sortBy === 'networkName'" (click)="setOrder('networkName')">
                Network
                <span *ngIf="query.sortBy === 'networkName'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="110"
                [class.active]="query.sortBy === 'createdAt'"
                (click)="setOrder('createdAt')">
                Created Date
                <span *ngIf="query.sortBy === 'createdAt'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading"
            [style.top]="bundleServiceList && bundleServiceList.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let bundleService of bundleServiceList
                | paginate
                  : {
                      itemsPerPage: query.limit,
                      currentPage: query.page,
                      totalItems: totalCount
                    };
              let i = index
            ">
            <tr [ngClass]="{'grey lighten-4 border': bundleService.collapsed}">
              <td (click)="bundleService.collapsed = !bundleService.collapsed">
                <button
                  type="button"
                  class="btn btn-link p-0 m-0"
                  mdbWavesEffect
                  *ngIf="bundleService.collapsed">
                  <i class="icofont-minus-circle icofont-lg text-info"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-link p-0 m-0"
                  mdbWavesEffect
                  *ngIf="!bundleService.collapsed">
                  <i class="icofont-plus-circle icofont-lg text-info"></i>
                </button>
              </td>
              <td (click)="openBundleModal(bundleService)">
                {{ bundleService?.name }}
              </td>
              <td (click)="openBundleModal(bundleService)">
                {{ bundleService?.description }}
              </td>
              <td (click)="openBundleModal(bundleService)">
                {{ bundleService?.network?.name }}
              </td>
              <td (click)="openBundleModal(bundleService)">
                {{ bundleService?.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}
              </td>
              <td width="32">
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div
                    class="custom-dropdown-content rounded-sm z-depth-2"
                    [style.top]="
                      (i === query.limit - 3 || i === query.limit - 2 || i === query.limit - 1) &&
                      '-255px'
                    ">
                    <a class="dropdown-item" (click)="openBundleSetting(bundleService)"
                      ><i class="fas fa-cog"></i> Manage Benefit Settings</a
                    >
                    <a class="dropdown-item" (click)="openBundleComponent(bundleService)"
                      ><i class="icofont-files-stack"></i> Add Bundle Component</a
                    >
                    <a class="dropdown-item" (click)="openRequestForFundingModal(bundleService)"
                      ><i class="fas fa-hand-holding-usd"></i> Add Request For Funding</a
                    >
                    <a class="dropdown-item" (click)="openAssociateModal(bundleService)"
                      ><i class="fas fa-link"></i> Associate Funding Request</a
                    >
                    <a class="dropdown-item" (click)="openBundleModal(bundleService)"
                      ><i class="icofont-edit-alt"></i> Edit</a
                    >
                    <a class="dropdown-item" (click)="delete(bundleService, 'bundle')"
                      ><i class="icofont-ui-delete"></i> Delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="bundleService.collapsed" class="grey lighten-5 border">
              <td colspan="100%" class="p-3">
                <mdb-tabset
                  [buttonClass]="'classic-tabs'"
                  [contentClass]="'card tab-clean d-block p-0'"
                  class="classic-tabs">
                  <!--Panel 1-->
                  <mdb-tab heading="Bundle Components">
                    <div class="row">
                      <div class="col-12">
                        <br />
                        <strong
                          *ngIf="bundleService.components && bundleService.components.length == 0"
                          >No Bundle Components.</strong
                        >
                        <table
                          mdbTable
                          small="true"
                          striped="true"
                          bordered="true"
                          *ngIf="bundleService.components && bundleService.components.length > 0">
                          <thead class="white black-text">
                            <tr>
                              <th>Name</th>
                              <th>CPT Code</th>
                              <th>Rev. Code</th>
                              <th>Diag. Code</th>
                              <th>Modifier</th>
                              <th width="190">Taxonomy Code</th>
                              <th>MSDRG</th>
                              <th>Req. For Funding</th>
                              <th>Rules</th>
                              <th>Description</th>
                              <th width="7%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let component of bundleService.components;
                                let componentIndex = index
                              ">
                              <td>
                                {{ component?.name }}
                                <div class="d-flex">
                                  <span
                                    *ngIf="component.isPrimary"
                                    class="bg-info rounded-sm flag mr-2"
                                    mdbTooltip="Primary">
                                    <i class="icofont icofont-flag-alt-1 text-white"></i>
                                  </span>
                                  <span
                                    *ngIf="component.isMedicalCenter"
                                    class="teal rounded-sm flag mr-2"
                                    mdbTooltip="Medical Center">
                                    <i class="fas fa-hospital text-white"></i>
                                  </span>
                                  <span
                                    *ngIf="component.isFundingTrigger"
                                    class="blue darken-3 rounded-sm flag mr-2"
                                    mdbTooltip="Trigger Request">
                                    <i class="icofont icofont-dollar text-white"></i>
                                  </span>
                                  <span
                                    *ngIf="component.showInEpisode"
                                    class=""
                                    mdbTooltip="Show In Episode">
                                    <i class="far fa-eye deep-blue"></i>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <span *ngFor="let cptCode of component.displayCptCodes">
                                  <span
                                    *ngIf="cptCode"
                                    [mdbTooltip]="
                                      displayCptDescription(
                                        'components',
                                        cptCode,
                                        i,
                                        componentIndex
                                      )
                                    "
                                    class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                    {{ cptCode }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span *ngFor="let revCode of component.displayRevenueCodes">
                                  <span
                                    *ngIf="revCode"
                                    class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                    {{ revCode }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span *ngFor="let diagCode of component.displayDiagnosisCodes">
                                  <span
                                    *ngIf="diagCode"
                                    [mdbTooltip]="
                                      displayDiagCodeDescription(
                                        'components',
                                        diagCode,
                                        i,
                                        componentIndex
                                      )
                                    "
                                    class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                    {{ diagCode }}
                                  </span>
                                </span>
                              </td>
                              <td>{{ component?.modifier }}</td>
                              <td>
                                <span
                                  class="pl-1"
                                  *ngFor="let taxonomyCode of component.displayTaxonomyCodes">
                                  <span
                                    *ngIf="taxonomyCode"
                                    class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                    {{ taxonomyCode }}
                                  </span>
                                </span>
                              </td>
                              <td>{{ component?.msdrg }}</td>
                              <td>{{ component?.fundingRequest?.name }}</td>
                              <td>{{ component?.ruleDescription }}</td>
                              <td>{{ component?.description }}</td>
                              <td>
                                <button
                                  mdbTooltip="Edit"
                                  placement="top"
                                  class="btn btn-link mt-0 mb-0 p-0 z-depth-0"
                                  (click)="openBundleComponent(bundleService, component)">
                                  <i class="icofont-edit-alt"></i>
                                </button>
                                <ng-template #deletealert>
                                  <div class="text-center">
                                    <p>
                                      Are you sure you want to delete ? This is irreversible. If
                                      you're not sure, you can click Cancel.
                                    </p>

                                    <p class="mb-0">
                                      <button
                                        type="button"
                                        class="btn btn-secondary btn-sm"
                                        (click)="deleteBundleComponent(component); pop.hide()">
                                        Delete
                                      </button>
                                      <button
                                        type="button"
                                        (click)="pop.hide()"
                                        class="btn btn-light btn-sm">
                                        Cancel
                                      </button>
                                    </p>
                                  </div>
                                </ng-template>
                                <button
                                  id="deletealert"
                                  [mdbPopover]="deletealert"
                                  #pop="bs-mdbPopover"
                                  placement="left"
                                  class="btn btn-link mt-0 mb-0 p-0 z-depth-0">
                                  <i class="icofont-ui-delete"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </mdb-tab>
                  <!--Panel 2-->
                  <mdb-tab heading="Request For Funding">
                    <div class="row">
                      <div class="col-12">
                        <br />
                        <div
                          class="form-row"
                          *ngIf="
                            bundleService.fundingRequests &&
                            bundleService.fundingRequests.length > 0
                          ">
                          <div
                            class="col-12 col-sm-6 col-md-3 col-xl-3"
                            *ngFor="
                              let fund of bundleService.fundingRequests;
                              let fundIndex = index
                            ">
                            <mdb-card class="z-depth-0 bg-blue-light border mb-2">
                              <mdb-card-body class="p-2">
                                <div class="d-flex justify-content-between align-items-center">
                                  <h6>{{ fund.name }}</h6>
                                  <div class="custom-dropdown">
                                    <button
                                      class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                                      type="button"
                                      mdbWavesEffect>
                                      <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                                    </button>
                                    <div class="custom-dropdown-content rounded-sm z-depth-2">
                                      <a
                                        class="dropdown-item"
                                        (click)="openRequestForFundingModal(bundleService, fund)"
                                        ><i class="icofont-edit-alt"></i> Edit</a
                                      >
                                      <a
                                        class="dropdown-item"
                                        [ngClass]="
                                          bundleService.fundingRequests.length == 1
                                            ? 'isDisabled'
                                            : ''
                                        "
                                        (click)="delete(fund, 'fund', bundleService)"
                                        ><i class="icofont-ui-delete"></i> Delete</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <table mdbTable class="table-xs">
                                  <tbody class="bg-transparent">
                                    <tr>
                                      <td>CPT Code</td>
                                      <td>
                                        <span *ngFor="let cpt of fund.displayCptCodes">
                                          <span
                                            *ngIf="cpt"
                                            [mdbTooltip]="
                                              displayCptDescription(
                                                'fundingRequests',
                                                cpt,
                                                i,
                                                fundIndex
                                              )
                                            "
                                            class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                            {{ cpt }}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Revenue Code</td>
                                      <td>
                                        <span *ngFor="let element of fund.displayRevenueCodes">
                                          <span
                                            class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                            {{ element }}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Diagnosis Code</td>
                                      <td>
                                        <span *ngFor="let diagCode of fund.displayDiagnosisCodes">
                                          <span
                                            [mdbTooltip]="
                                              displayDiagCodeDescription(
                                                'fundingRequests',
                                                diagCode,
                                                i,
                                                fundIndex
                                              )
                                            "
                                            class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                            {{ diagCode }}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>MSDRG</td>
                                      <td>
                                        <span *ngFor="let element of fund.displayMsdrg">
                                          <span
                                            class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                            {{ element }}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr *ngIf="fund.modifier1">
                                      <td>Modifier 1</td>
                                      <td>
                                        <span>
                                          <span
                                            class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                            {{ fund.modifier1 }}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr *ngIf="fund.modifier2">
                                      <td>Modifier 2</td>
                                      <td>
                                        <span>
                                          <span
                                            class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                            {{ fund.modifier2 }}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr *ngIf="fund.modifier3">
                                      <td>Modifier 3</td>
                                      <td>
                                        <span>
                                          <span
                                            class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                            {{ fund.modifier3 }}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr *ngIf="fund.modifier4">
                                      <td>Modifier 4</td>
                                      <td>
                                        <span>
                                          <span
                                            class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                            {{ fund.modifier4 }}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p>Associated bundle components</p>
                                <div
                                  *ngIf="
                                    bundleService &&
                                    bundleService.components &&
                                    bundleService.components.length > 0
                                  ">
                                  <span *ngFor="let comp of bundleService.components">
                                    <span
                                      *ngIf="comp.fundingRequestUuid == fund.uuid"
                                      class="bg-primary-dark mr-1 badge badge-sm mb-1 text-white rounded-sm z-depth-0">
                                      {{ comp.name }}
                                    </span>
                                  </span>
                                </div>
                              </mdb-card-body>
                            </mdb-card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mdb-tab>
                  <!-- Panel 3 -->
                  <mdb-tab heading="Benefit Settings">
                    <div class="row">
                      <div class="col-12">
                        <br />
                        <strong *ngIf="!bundleService.benefitSetting">No Benefit Setting.</strong>
                        <table
                          mdbTable
                          small="true"
                          bordered="true"
                          *ngIf="bundleService.benefitSetting">
                          <thead class="white black-text">
                            <tr>
                              <th>Start Type</th>
                              <th *ngIf="bundleService.benefitSetting.startType == 'RULE'">
                                Start Date <br />
                                Rule Code
                              </th>
                              <th *ngIf="bundleService.benefitSetting.startType == 'MILESTONE'">
                                Start Date <br />
                                Milestone Name
                              </th>
                              <th *ngIf="bundleService.benefitSetting.startType == 'MILESTONE'">
                                Start Date <br />
                                Timing
                              </th>
                              <th *ngIf="bundleService.benefitSetting.startType == 'MILESTONE'">
                                Start Date <br />
                                Days
                              </th>
                              <th>
                                Start Date <br />
                                Default Label
                              </th>
                              <th>End Type</th>
                              <th *ngIf="bundleService.benefitSetting.endType == 'RULE'">
                                End Date <br />
                                Rule Code
                              </th>
                              <th *ngIf="bundleService.benefitSetting.endType == 'MILESTONE'">
                                End Date <br />
                                Milestone Name
                              </th>
                              <th *ngIf="bundleService.benefitSetting.endType == 'MILESTONE'">
                                End Date <br />
                                Timing
                              </th>
                              <th *ngIf="bundleService.benefitSetting.endType == 'MILESTONE'">
                                End Date <br />
                                Days
                              </th>
                              <th>
                                End Date <br />
                                Default Label
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {{ bundleService.benefitSetting.startType }}
                              </td>
                              <td *ngIf="bundleService.benefitSetting.startType == 'RULE'">
                                {{ bundleService.benefitSetting.startDateRuleCode }}
                              </td>
                              <td *ngIf="bundleService.benefitSetting.startType == 'MILESTONE'">
                                {{ bundleService.benefitSetting.startDateMilestoneName }}
                              </td>
                              <td *ngIf="bundleService.benefitSetting.startType == 'MILESTONE'">
                                {{ bundleService.benefitSetting.startDateTiming }}
                              </td>
                              <td *ngIf="bundleService.benefitSetting.startType == 'MILESTONE'">
                                {{ bundleService.benefitSetting.startDateDays }}
                              </td>
                              <td>
                                {{ bundleService.benefitSetting.startDateDefaultLabel }}
                              </td>
                              <td>
                                {{ bundleService.benefitSetting.endType }}
                              </td>
                              <td *ngIf="bundleService.benefitSetting.endType == 'RULE'">
                                {{ bundleService.benefitSetting.endDateRuleCode }}
                              </td>
                              <td *ngIf="bundleService.benefitSetting.endType == 'MILESTONE'">
                                {{ bundleService.benefitSetting.endDateMilestoneName }}
                              </td>
                              <td *ngIf="bundleService.benefitSetting.endType == 'MILESTONE'">
                                {{ bundleService.benefitSetting.endDateTiming }}
                              </td>
                              <td *ngIf="bundleService.benefitSetting.endType == 'MILESTONE'">
                                {{ bundleService.benefitSetting.endDateDays }}
                              </td>
                              <td>
                                {{ bundleService.benefitSetting.endDateDefaultLabel }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </mdb-tab>
                </mdb-tabset>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls class="text-center" (pageChange)="pageChanged($event)" autoHide="true">
    </pagination-controls>
  </div>
  <div
    appScrollToTop
    class="fixed-action-btn donotprint"
    style="bottom: 145px; right: 10px; display: none">
    <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
      <i class="fas fa-angle-up text-black-50"></i>
    </a>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.bundles">
    <i class="fas fa-3x fa-cubes text-black-50"></i>
    <p>Start adding Service Bundle.</p>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.searchedBundles">
    <i class="fas fa-3x fa-cubes text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>

<!--SERVICE BUNDLE MODAL -->
<div
  mdbModal
  #bundleServiceModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ bundleServiceForm.value.id ? 'Edit' : 'Add' }} Bundle</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeBundleModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <form class="p-3" [formGroup]="bundleServiceForm">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="bundleName">Bundle Name *</label>
                <input
                  type="text"
                  id="bundleName"
                  class="form-control"
                  formControlName="name"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': submitted && bundleServiceForm.get('name').invalid
                  }" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="displayName">Display Name *</label>
                <input
                  type="text"
                  id="displayName"
                  class="form-control"
                  formControlName="displayName"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': submitted && bundleServiceForm.get('displayName').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="networkCode">Select Network *</label>
                <mdb-select
                  [options]="networks | sort : 'label'"
                  id="networkCode"
                  [class.disabled]="networks.length == 0"
                  placeholder="Select network"
                  formControlName="networkCode"
                  [ngClass]="{
                    'pointer-disabled': bundleServiceForm.value.id,
                    'error-select': submitted && bundleServiceForm.get('networkCode').invalid
                  }">
                </mdb-select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="EffectiveDate">Effective Date</label>
                <input
                  type="text"
                  id="EffectiveDate"
                  placeholder="MM/DD/YYYY"
                  [textMask]="{mask: dateMask}"
                  class="form-control"
                  formControlName="effectiveDate"
                  [ngClass]="{
                    'error-select': submitted && bundleServiceForm.get('effectiveDate').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="terminationDate">Termination Date</label>
                <input
                  type="text"
                  id="terminationDate"
                  placeholder="MM/DD/YYYY"
                  [textMask]="{mask: dateMask}"
                  class="form-control"
                  formControlName="terminationDate"
                  [ngClass]="{
                    'error-select': submitted && bundleServiceForm.get('terminationDate').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="bundleDescription">Description </label>
            <textarea
              type="text"
              id="bundleDescription"
              class="md-textarea form-control"
              formControlName="description"
              mdbInput
              [ngClass]="{
                'is-invalid': submitted && bundleServiceForm.get('description').invalid
              }">
            </textarea>
          </div>
          <div class="form-group">
            <mdb-checkbox
              formControlName="inOffice"
              [checked]="bundleServiceForm.get('inOffice').value">
              Bundle Only</mdb-checkbox
            >
          </div>
          <div *ngIf="!bundleServiceForm.value.id">
            <div class="d-flex justify-content-between align-items-center">
              <span>
                <strong>Request For Funding *</strong>
              </span>
              <span>
                <a (click)="addRequestForFunding()"
                  ><i class="icofont-plus"></i> Add Request For Funding</a
                >
              </span>
            </div>
            <div
              class="border grey lighten-5 p-2 rounded-sm mb-2"
              *ngFor="let request of requestForFunding; let i = index">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="fundingName">Name *</label>
                    <input
                      type="text"
                      name="fundingName"
                      id="fundingName{{ i }}"
                      class="form-control"
                      [(ngModel)]="request.name"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Enter name"
                      [ngClass]="{'is-invalid': submitted && !request.name}"
                      mdbInput />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="d-flex justify-content-between align-items-center">
                      <label for="Sequence">Sequence *</label>
                      <a (click)="removeRequestForFunding(request)"><i class="fas fa-times"></i></a>
                    </div>
                    <input
                      type="text"
                      name="Sequence"
                      id="Sequence"
                      class="form-control"
                      placeholder="Enter sequence"
                      numbersOnly
                      [(ngModel)]="request.sequence"
                      [ngModelOptions]="{standalone: true}"
                      [ngClass]="{
                        'is-invalid': submitted && (!request.sequence || request.sequence == 0)
                      }"
                      mdbInput />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <div class="form-group">
                    <label for="cptCode">CPT Code</label>
                    <input
                      type="text"
                      name="fundingCptCode"
                      id="fundingCptCode{{ i }}"
                      class="form-control"
                      [(ngModel)]="request.cptCode"
                      [ngModelOptions]="{standalone: true}"
                      (change)="checkCodeValidity(request, i, 'cptCode')"
                      placeholder="eg. 10021, 70010, 90281"
                      [ngClass]="{
                        'is-invalid': submitted && !request.validCptCode
                      }"
                      mdbInput />
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label for="cptCode">Diagnosis Code </label>
                    <input
                      type="text"
                      name="fundingDiagnosisCode"
                      id="fundingDiagnosisCode{{ i }}"
                      class="form-control"
                      [(ngModel)]="request.diagnosisCode"
                      [ngModelOptions]="{standalone: true}"
                      mdbInput />
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label for="cptCode">Revenue Code </label>
                    <input
                      type="text"
                      name="fundingRevenueCode"
                      id="fundingRevenueCode{{ i }}"
                      class="form-control"
                      [(ngModel)]="request.revenueCode"
                      numbersCommaOnly
                      [ngModelOptions]="{standalone: true}"
                      (change)="checkCodeValidity(request, i, 'revenueCode')"
                      [ngClass]="{
                        'is-invalid': submitted && !request.validRevenueCode
                      }"
                      mdbInput />
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label for="cptCode">MSDRG </label>
                    <input
                      type="text"
                      name="fundingMsdrg"
                      id="fundingMsdrg{{ i }}"
                      class="form-control"
                      [(ngModel)]="request.msdrg"
                      [ngModelOptions]="{standalone: true}"
                      mdbInput />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="modifier1">Modifier 1</label>
                    <input
                      type="text"
                      name="modifier1"
                      id="modifier1{{ i }}"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="request.modifier1"
                      [ngModelOptions]="{standalone: true}"
                      (focusout)="onFocusOutEvent(i, 'mod1')"
                      alphaNumericWithoutComma />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="modifier2">Modifier 2</label>
                    <input
                      type="text"
                      name="modifier2"
                      id="modifier2{{ i }}"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="request.modifier2"
                      [ngModelOptions]="{standalone: true}"
                      [readOnly]="!request.modifier1"
                      (focusout)="onFocusOutEvent(i, 'mod2')"
                      alphaNumericWithoutComma />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="modifier3">Modifier 3</label>
                    <input
                      type="text"
                      name="modifier3"
                      id="modifier3{{ i }}"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="request.modifier3"
                      [ngModelOptions]="{standalone: true}"
                      [readOnly]="!request.modifier2"
                      (focusout)="onFocusOutEvent(i, 'mod3')"
                      alphaNumericWithoutComma />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="modifier4">Modifier 4</label>
                    <input
                      type="text"
                      name="modifier4"
                      id="modifier4{{ i }}"
                      mdbInput
                      class="form-control"
                      [(ngModel)]="request.modifier4"
                      [ngModelOptions]="{standalone: true}"
                      [readOnly]="!request.modifier3"
                      alphaNumericWithoutComma />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeBundleModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitBundle()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ bundleServiceForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- BUNDLE COMPONENT MODAL -->
<div
  mdbModal
  #bundleComponentModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered bundle-modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ bundleComponentForm.value.id ? 'Edit' : 'Add' }} Bundle Component
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closebundleComponentModal('close')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <form [formGroup]="bundleComponentForm">
            <div class="form-row justify-content-around mb-4">
              <div>
                <mdb-checkbox
                  [filledIn]="true"
                  formControlName="isPrimary"
                  (change)="statusChange($event, 'isPrimary')">
                  <label>Is Primary</label>
                </mdb-checkbox>
              </div>
              <div>
                <mdb-checkbox
                  [filledIn]="true"
                  formControlName="isMedicalCenter"
                  (change)="statusChange($event, 'isMedicalCenter')"
                  ><label>Is Medical Center</label>
                </mdb-checkbox>
              </div>
              <div>
                <mdb-checkbox
                  [filledIn]="true"
                  formControlName="isFundingTrigger"
                  (change)="statusChange($event, 'isFundingTrigger')"
                  ><label>Trigger Request For Funding</label>
                </mdb-checkbox>
              </div>
              <div>
                <mdb-checkbox
                  [filledIn]="true"
                  [disabled]="disableShowInEpisode"
                  formControlName="showInEpisode"
                  (change)="statusChange($event, 'showInEpisode')"
                  ><label>Show In Episode</label>
                </mdb-checkbox>
              </div>
           
            </div>
            <div class="form-row">
              <div class="col">
                <label for="name">Bundle Component Name *</label>
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  formControlName="name"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': bundleComponentForm.get('name').invalid && submit_component
                  }" />
              </div>
              <div class="col">
                <label for="name">Category</label>
                <app-select
                  [options]="bundleComponentCategories"
                  [id]="'Category'"
                  [control]="bundleComponentForm.controls['bundleComponentGroup']"
                  (onDeselected)="onCategoryDeselected()"
                  [allowClear]="true"></app-select>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <label for="fundReq">Funding Request *</label>
                <mdb-select
                  [options]="fundingRequestList | sort : 'label'"
                  id="fundReq"
                  placeholder="Select funding"
                  formControlName="fundingRequestUuid"
                  [ngClass]="{
                    'error-select':
                      bundleComponentForm.get('fundingRequestUuid').invalid && submit_component
                  }">
                </mdb-select>
              </div>
              <div class="col">
                <label for="expenseAllocation">Expense Allocation *</label>
                <mdb-select
                  [options]="expenseAllocationList | sort : 'label'"
                  id="expenseAllocation"
                  formControlName="expenseAllocation"
                  placeholder="Select expense allocation"
                  (selected)="expenseSelected($event)"
                  [ngClass]="{
                    'error-select':
                      bundleComponentForm.get('expenseAllocation').invalid && submit_component
                  }">
                </mdb-select>
              </div>
            </div>
            
            <div class="form-row">
              <div class="col">
                <div class="form-group mb-0">
                  <ng-template #cptTemplate>
                    <div class="d-flex flex-column" [innerHTML]="codesGuide['cptCode']"></div>
                  </ng-template>
                  <label for="cptCode">CPT Code </label>
                  <i
                    class="icofont-info-circle btn-link pointer-cursor ml-1"
                    [mdbPopover]="cptTemplate"
                    placement="left"
                    triggers="mouseenter:mouseleave"></i>
                  <input
                    type="text"
                    id="cptCode"
                    class="form-control"
                    formControlName="cptCode"
                    (change)="checkCodeValidity(null, null, 'cptCode')"
                    placeholder="eg. 10021, 70010, 90281"
                    mdbInput
                    [ngClass]="{
                      'is-invalid': submit_component && bundleComponentForm.get('cptCode').invalid
                    }"
                    [class.disabled]="disableFields ? true : false" />
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-0">
                  <ng-template #diagnosisTemplate>
                    <div class="d-flex flex-column" [innerHTML]="codesGuide['diagnosisCode']"></div>
                  </ng-template>
                  <label for="DiagnosisCode">Diagnosis Code </label>
                  <i
                    class="icofont-info-circle btn-link pointer-cursor ml-1"
                    [mdbPopover]="diagnosisTemplate"
                    placement="left"
                    triggers="mouseenter:mouseleave"></i>
                  <input
                    type="text"
                    id="DiagnosisCode"
                    class="form-control"
                    formControlName="diagnosisCode"
                    (change)="checkCode('diagnosisCode')"
                    mdbInput
                    [ngClass]="{
                      'is-invalid':
                        submit_component && bundleComponentForm.get('diagnosisCode').invalid
                    }"
                    [class.disabled]="disableFields ? true : false" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group mb-0">
                  <ng-template #taxonomyTemplate>
                    <div class="d-flex flex-column" [innerHTML]="codesGuide['taxonomyCode']"></div>
                  </ng-template>
                  <label for="taxonomyCode">Taxonomy Code </label>
                  <i
                    class="icofont-info-circle btn-link pointer-cursor ml-1"
                    [mdbPopover]="taxonomyTemplate"
                    placement="left"
                    triggers="mouseenter:mouseleave"></i>
                  <input
                    type="text"
                    id="taxonomyCode"
                    class="form-control"
                    (change)="checkCode('taxonomyCode')"
                    placeholder="eg. 207XS0114X, -207XS0114X"
                    formControlName="taxonomyCode"
                    mdbInput
                    [ngClass]="{
                      'is-invalid':
                        submit_component && bundleComponentForm.get('taxonomyCode').invalid
                    }"
                    [class.disabled]="disableFields ? true : false" />
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-0">
                  <ng-template #revenueTemplate>
                    <div class="d-flex flex-column" [innerHTML]="codesGuide['revenueCode']"></div>
                  </ng-template>
                  <label for="revenueCode">Revenue Code </label>
                  <i
                    class="icofont-info-circle btn-link pointer-cursor ml-1"
                    [mdbPopover]="revenueTemplate"
                    placement="left"
                    triggers="mouseenter:mouseleave"></i>
                  <input
                    type="text"
                    id="revenueCode"
                    class="form-control"
                    (change)="checkCodeValidity(null, null, 'revenueCode')"
                    formControlName="revenueCode"
                    numbersCommaOnly
                    mdbInput
                    [ngClass]="{
                      'is-invalid':
                        submit_component && bundleComponentForm.get('revenueCode').invalid
                    }"
                    [class.disabled]="disableFields ? true : false" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group mb-0">
                  <ng-template #msdrgTemplate>
                    <div class="d-flex flex-column" [innerHTML]="codesGuide['msdrg']"></div>
                  </ng-template>
                  <label for="MSDRG">MSDRG </label>
                  <i
                    class="icofont-info-circle btn-link pointer-cursor ml-1"
                    [mdbPopover]="msdrgTemplate"
                    placement="left"
                    triggers="mouseenter:mouseleave"></i>
                  <input
                    type="text"
                    id="MSDRG"
                    class="form-control"
                    formControlName="msdrg"
                    (change)="checkCode('msdrg')"
                    mdbInput
                    [ngClass]="{
                      'is-invalid': submit_component && bundleComponentForm.get('msdrg').invalid
                    }"
                    [class.disabled]="disableFields ? true : false" />
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-0">
                  <ng-template #modifierTemplate
                    ><div class="d-flex flex-column" [innerHTML]="codesGuide['modifier']"></div>
                  </ng-template>
                  <label for="Modifier">Modifier </label>
                  <i
                    class="icofont-info-circle btn-link pointer-cursor ml-1"
                    [mdbPopover]="modifierTemplate"
                    placement="left"
                    triggers="mouseenter:mouseleave"></i>
                  <input
                    type="text"
                    id="Modifier"
                    class="form-control"
                    formControlName="modifier"
                    (change)="checkCode('modifier')"
                    mdbInput
                    [ngClass]="{
                      'is-invalid': submit_component && bundleComponentForm.get('modifier').invalid
                    }"
                    [class.disabled]="disableFields ? true : false" />
                </div>
              </div>
            </div>
            <div class="form-group mb-0">
              <ng-template #rulesTemplate>
                <div class="d-flex flex-column" [innerHTML]="codesGuide['rules']"></div>
              </ng-template>
              <label for="rules">Rules </label>
              <i
                class="icofont-info-circle btn-link pointer-cursor ml-1"
                [mdbPopover]="rulesTemplate"
                placement="left"
                triggers="mouseenter:mouseleave"></i>
              <mdb-select
                [options]="codeRulesList"
                [allowClear]="true"
                [class.disabled]="disableFields"
                id="rules"
                formControlName="ruleCode"
                placeholder="Select rules"
                [ngClass]="{
                  'error-select': bundleComponentForm.get('ruleCode').invalid && submit_component
                }">
              </mdb-select>
            </div>
            <div class="form-group mb-0">
              <label for="description">Description </label>
              <textarea
                type="text"
                id="description"
                class="md-textarea form-control"
                formControlName="description"
                mdbInput
                [ngClass]="{
                  'is-invalid': submit_component && bundleComponentForm.get('description').invalid
                }"></textarea>
            </div>
            <div
              class="alert border border-warning grey lighten-5 text-center mt-2"
              role="alert"
              *ngIf="message">
              <mdb-icon fas icon="exclamation-triangle" class="text-warning"></mdb-icon>
              {{ message }}
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closebundleComponentModal('close')">
          Cancel
        </button>
        <div>
          <button
            class="btn btn-secondary waves-light m-0 mr-2"
            mdbWavesEffect
            (click)="submitBundleComponent('addnew')"
            *ngIf="!bundleComponentForm.value.id"
            [disabled]="submit_enabled || submitForAddNew">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="submitForAddNew"></span>
            Save and New
          </button>
          <button
            class="btn btn-secondary waves-light m-0"
            mdbWavesEffect
            (click)="submitBundleComponent('close')"
            *ngIf="!message && !submitBtnName"
            [disabled]="submit_enabled || submitForAddNew">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="submit_enabled"></span>
            {{ bundleComponentForm.value.id ? 'Update' : 'Save and Close' }}
          </button>
          <button
            class="btn btn-secondary waves-light m-0"
            mdbWavesEffect
            (click)="updateBundleComponent('close')"
            [disabled]="submit_enabled"
            *ngIf="message && submitBtnName">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="submit_enabled"></span>
            {{ submitBtnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ASSOCIATE FUNDING REQUEST MODAL -->
<div
  mdbModal
  #associateModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Associate Funding Request</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeAssociateModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <h6
          *ngIf="
            associtateFundingReq &&
            associtateFundingReq.components &&
            associtateFundingReq.components.length == 0
          "
          style="text-align: center">
          No Bundle Components Available
        </h6>
        <form ngForm="associateFundingRequestForm">
          <div class="scrollbar scrollbar-primary thin overflow-auto" style="max-height: 400px">
            <table
              mdbTable
              small="true"
              stickyHeader="true"
              *ngIf="
                associtateFundingReq &&
                associtateFundingReq.components &&
                associtateFundingReq.components.length > 0
              ">
              <thead>
                <tr>
                  <th>Bundle Component</th>
                  <th>Funding Request</th>
                  <th>Trigger Request</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let component of associtateFundingReq.components; let i = index">
                  <td>{{ component.name }}</td>
                  <td>
                    <mdb-select
                      [options]="fundingRequestList | sort : 'label'"
                      id="funding{{ i }}"
                      [(ngModel)]="component.fundingRequestUuid"
                      name="fundingRequestId{{ i }}"
                      (selected)="fundingRequestChange(i)">
                    </mdb-select>
                  </td>
                  <td>
                    <div class="switch">
                      <label>
                        <input
                          type="checkbox"
                          [(ngModel)]="component.isFundingTrigger"
                          name="isFundingTrigger{{ i }}"
                          (ngModelChange)="fundingRequestTriggered($event, i)"
                          [disabled]="!component.fundingRequestUuid" />
                        <span class="lever"></span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="alert border border-warning grey lighten-5 text-center"
            role="alert"
            *ngIf="message">
            <mdb-icon fas icon="exclamation-triangle" class="text-warning"></mdb-icon>
            {{ message }}
          </div>
        </form>
      </div>

      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeAssociateModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitFundingRequest(associtateFundingReq)"
          *ngIf="!message && !submitBtnName"
          [disabled]="
            submit_enabled ||
            (associtateFundingReq &&
              associtateFundingReq.components &&
              associtateFundingReq.components.length == 0)
          ">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          Save
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="saveAssociateFundingRequest(associtateFundingReq, associtateFundReq)"
          *ngIf="message && submitBtnName"
          [disabled]="
            submit_enabled ||
            (associtateFundingReq &&
              associtateFundingReq.components &&
              associtateFundingReq.components.length == 0)
          ">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ submitBtnName }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- REQUEST FOR FUNDING MODAL -->
<div
  mdbModal
  #requestForFundingModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ requestForFundingForm.value.id ? 'Edit' : 'Add' }} Request For Funding
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeRequestForFundingModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="requestForFundingForm">
          <div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="fundingName">Name *</label>
                  <input
                    type="text"
                    name="fundingName"
                    id="fundingName"
                    class="form-control"
                    placeholder="Enter name"
                    [ngClass]="{
                      'is-invalid': submitted && requestForFundingForm.get('name').invalid
                    }"
                    mdbInput
                    formControlName="name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="fundingSequence">Sequence *</label>
                  <input
                    type="number"
                    name="fundingSequence"
                    id="fundingSequence"
                    class="form-control"
                    placeholder="Enter sequence"
                    numbersOnly
                    [ngClass]="{
                      'is-invalid': submitted && requestForFundingForm.get('sequence').invalid
                    }"
                    mdbInput
                    formControlName="sequence" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <div class="form-group">
                  <label for="cptCode">CPT Code</label>
                  <input
                    type="text"
                    name="fundingCptCode"
                    id="fundingCptCode"
                    class="form-control"
                    placeholder="eg. 10021, 70010, 90281"
                    mdbInput
                    formControlName="cptCode"
                    [ngClass]="{
                      'is-invalid': submitted && requestForFundingForm.get('cptCode').invalid
                    }" />
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label for="cptCode">Diagnosis Code</label>
                  <input
                    type="text"
                    name="fundingDiagnosisCode"
                    id="fundingDiagnosisCode"
                    class="form-control"
                    mdbInput
                    formControlName="diagnosisCode"
                    [ngClass]="{
                      'is-invalid': submitted && requestForFundingForm.get('diagnosisCode').invalid
                    }" />
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label for="cptCode">Revenue Code</label>
                  <input
                    type="text"
                    name="fundingRevenueCode"
                    id="fundingRevenueCode"
                    class="form-control"
                    mdbInput
                    formControlName="revenueCode"
                    [ngClass]="{
                      'is-invalid': submitted && requestForFundingForm.get('revenueCode').invalid
                    }" />
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label for="cptCode">MSDRG </label>
                  <input
                    type="text"
                    name="fundingMsdrg"
                    id="fundingMsdrg"
                    class="form-control"
                    mdbInput
                    formControlName="msdrg" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="modifier1">Modifier 1</label>
                  <input
                    type="text"
                    name="modifier1"
                    id="modifier1"
                    mdbInput
                    class="form-control"
                    formControlName="modifier1"
                    alphaNumericWithoutComma />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="modifier2">Modifier 2</label>
                  <input
                    type="text"
                    name="modifier2"
                    id="modifier2"
                    mdbInput
                    class="form-control"
                    formControlName="modifier2"
                    [readOnly]="requestForFundingForm.value.modifier1 ? false : true"
                    alphaNumericWithoutComma />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="modifier3">Modifier 3</label>
                  <input
                    type="text"
                    name="modifier3"
                    id="modifier3"
                    mdbInput
                    class="form-control"
                    formControlName="modifier3"
                    [readOnly]="requestForFundingForm.value.modifier2 ? false : true"
                    alphaNumericWithoutComma />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="modifier4">Modifier 4</label>
                  <input
                    type="text"
                    name="modifier4"
                    id="modifier4"
                    mdbInput
                    class="form-control"
                    formControlName="modifier4"
                    [readOnly]="requestForFundingForm.value.modifier3 ? false : true"
                    alphaNumericWithoutComma />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeRequestForFundingModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitRequestForFunding()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ requestForFundingForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<app-delete-confirmation
  [_id]="deleteId"
  [message]="message"
  (confirmDelete)="confirmDelete($event)">
</app-delete-confirmation>

<!-- BUNDLE SETTING MODAL -->
<div
  mdbModal
  #bundleSettingModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Manage Benefit Settings</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeBundleSettingModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="bundleSettingForm">
          <!-- Benefit Start Date Section -->
          <h6 class="font-weight-bold">Benefit Start Date</h6>
          <div class="row mb-2">
            <div class="col form-inline">
              <div class="form-group">
                <label class="mr-3">Set bundle settings by</label>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="startRules"
                    mdbInput
                    [checked]="bundleSettingForm.value.startType == 'RULE'"
                    (change)="changeType('start', 'RULE')" />
                  <label class="form-check-label" for="startRules">Rules</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="startMilestone"
                    mdbInput
                    [checked]="bundleSettingForm.value.startType == 'MILESTONE'"
                    (change)="changeType('start', 'MILESTONE')" />
                  <label class="form-check-label" for="startMilestone">Milestone</label>
                </div>
              </div>
            </div>
            <div class="col">
              <mdb-select
                [options]="rulesList | sort : 'label'"
                id="startDateRuleCode"
                formControlName="startDateRuleCode"
                placeholder="Select rule"
                (selected)="ruleSelected('start', $event)"
                [class.disabled]="bundleSettingForm.value.startType == 'MILESTONE' ? true : false"
                [ngClass]="{
                  'error-select': submitted && bundleSettingForm.get('startDateRuleCode').invalid,
                  'pointer-disabled': bundleSettingForm.value.startType == 'MILESTONE'
                }">
              </mdb-select>
            </div>
          </div>
          <label class="mr-3" *ngIf="startRuleDescription"
            >Rule description: <strong>{{ startRuleDescription }}</strong></label
          >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="benefitStartDate"
                  >Benefit start date determined by
                  <span *ngIf="bundleSettingForm.value.startType == 'MILESTONE'"> *</span></label
                >
                <input
                  type="text"
                  mdbInput
                  formControlName="startDateMilestoneName"
                  class="form-control"
                  id="benefitStartDate"
                  [readonly]="bundleSettingForm.value.startType == 'RULE' ? true : false"
                  [ngClass]="{
                    'error-select':
                      submitted && bundleSettingForm.get('startDateMilestoneName').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="startDateTiming"
                  >Timing
                  <span *ngIf="bundleSettingForm.value.startType == 'MILESTONE'"> *</span></label
                >
                <mdb-select
                  [options]="timing"
                  id="startDateTiming"
                  formControlName="startDateTiming"
                  placeholder="Select timing"
                  (selected)="timingSelected('start', $event)"
                  [class.disabled]="bundleSettingForm.value.startType == 'RULE' ? true : false"
                  [ngClass]="{
                    'error-select': submitted && bundleSettingForm.get('startDateTiming').invalid,
                    'pointer-disabled': bundleSettingForm.value.startType == 'RULE'
                  }">
                </mdb-select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="startDateDays"
                  >Days
                  <span *ngIf="bundleSettingForm.value.startType == 'MILESTONE'"> *</span></label
                >
                <input
                  type="number"
                  mdbInput
                  formControlName="startDateDays"
                  class="form-control"
                  id="startDateDays"
                  [readonly]="
                    bundleSettingForm.value.startType == 'RULE' ||
                    bundleSettingForm.value.startDateTiming == 'Day of'
                      ? true
                      : false
                  "
                  [ngClass]="{
                    'error-select': submitted && bundleSettingForm.get('startDateDays').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="startDatelabel">Default Label</label>
            <input
              type="text"
              mdbInput
              formControlName="startDateDefaultLabel"
              class="form-control"
              id="startDatelabel"
              [ngClass]="{
                'error-select': submitted && bundleSettingForm.get('startDateDefaultLabel').invalid
              }" />
          </div>
          <hr />
          <!-- Benefit End Date Section -->
          <h6 class="font-weight-bold">Benefit End Date</h6>
          <div class="row mb-2">
            <div class="col form-inline">
              <div class="form-group">
                <label class="mr-3">Set bundle settings by</label>

                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="endRule"
                    [value]="true"
                    mdbInput
                    [checked]="bundleSettingForm.value.endType == 'RULE'"
                    (change)="changeType('end', 'RULE')" />
                  <label class="form-check-label" for="endRule">Rules</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="endMilestone"
                    [value]="false"
                    mdbInput
                    [checked]="bundleSettingForm.value.endType == 'MILESTONE'"
                    (change)="changeType('end', 'MILESTONE')" />
                  <label class="form-check-label" for="endMilestone">Milestone</label>
                </div>
              </div>
            </div>
            <div class="col">
              <mdb-select
                [options]="rulesList | sort : 'label'"
                id="endDateRuleCode"
                formControlName="endDateRuleCode"
                placeholder="Select rule"
                (selected)="ruleSelected('end', $event)"
                [class.disabled]="bundleSettingForm.value.endType == 'MILESTONE' ? true : false"
                [ngClass]="{
                  'error-select': submitted && bundleSettingForm.get('endDateRuleCode').invalid,
                  'pointer-disabled': bundleSettingForm.value.endType == 'MILESTONE'
                }">
              </mdb-select>
            </div>
          </div>
          <label class="mr-3" *ngIf="endRuleDescription"
            >Rule description: <strong>{{ endRuleDescription }}</strong></label
          >
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="benefitendDate"
                  >Benefit end date determined by
                  <span *ngIf="bundleSettingForm.value.endType == 'MILESTONE'"> *</span></label
                >
                <input
                  type="text"
                  mdbInput
                  formControlName="endDateMilestoneName"
                  class="form-control"
                  id="benefitendDate"
                  [readonly]="bundleSettingForm.value.endType == 'RULE' ? true : false"
                  [ngClass]="{
                    'error-select':
                      submitted && bundleSettingForm.get('endDateMilestoneName').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="endDateTiming"
                  >Timing
                  <span *ngIf="bundleSettingForm.value.endType == 'MILESTONE'"> *</span></label
                >
                <mdb-select
                  [options]="timing"
                  id="endDateTiming"
                  formControlName="endDateTiming"
                  placeholder="Select timing"
                  (selected)="timingSelected('end', $event)"
                  [class.disabled]="bundleSettingForm.value.endType == 'RULE' ? true : false"
                  [ngClass]="{
                    'error-select': submitted && bundleSettingForm.get('endDateTiming').invalid,
                    'pointer-disabled': bundleSettingForm.value.endType == 'RULE'
                  }">
                </mdb-select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="endDateDays"
                  >Days
                  <span *ngIf="bundleSettingForm.value.endType == 'MILESTONE'"> *</span></label
                >
                <input
                  type="number"
                  mdbInput
                  formControlName="endDateDays"
                  class="form-control"
                  id="endDateDays"
                  [readonly]="
                    bundleSettingForm.value.endType == 'RULE' ||
                    bundleSettingForm.value.endDateTiming == 'Day of'
                      ? true
                      : false
                  "
                  [ngClass]="{
                    'error-select': submitted && bundleSettingForm.get('endDateDays').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="endDatelabel">Default Label</label>
            <input
              type="text"
              mdbInput
              formControlName="endDateDefaultLabel"
              class="form-control"
              id="endDatelabel"
              [ngClass]="{
                'error-select': submitted && bundleSettingForm.get('endDateDefaultLabel').invalid
              }" />
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeBundleSettingModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="saveBundleSettings()">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div
  mdbModal
  #filterModal="mdbModal"
  class="modal fade top"
  id="filterModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter Service Bundle</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFilterModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="filterForm">
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label for="filterCptCode">CPT Code</label>
                <input
                  type="text"
                  class="form-control"
                  id="filterCptCode"
                  formControlName="cptCode"
                  placeholder="Enter CPT code"
                  alphaNumericWithoutComma
                  minlength="5"
                  maxlength="5"
                  [ngClass]="{
                    'error-select': filter_submitted && filterForm.get('cptCode').invalid
                  }" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="filterRevenueCode">Revenue Code</label>
                <input
                  type="text"
                  class="form-control"
                  id="filterRevenueCode"
                  numbersOnly
                  formControlName="revenueCode"
                  placeholder="Enter revenue code"
                  maxlength="4"
                  [ngClass]="{
                    'error-select': filter_submitted && filterForm.get('revenueCode').invalid
                  }"
                  minlength="4" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeFilterModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitFilterBundle()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span
          >Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>
