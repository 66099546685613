export const EPISODE_STATUSES = {
  PRELIMINARY: 'PRELIMINARY',
  CONFIRMED: 'CONFIRMED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  CLOSED: 'CLOSED',
};

export const EPISODESTATUS = [
  { value: 'PRELIMINARY', label: 'Preliminary' },
  { value: 'CONFIRMED', label: 'Confirmed' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'CLOSED', label: 'Closed' },
];
