import { formatCurrency } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()

export class CurrencyFormat {
    public format(num): any {
        return ((num || num === 0) ? formatCurrency(Number(num), 'en', '$') : '-');
    }

    public parseCurrency(currencyString) {
        if (typeof currencyString !== 'string') {
            currencyString = currencyString.toString()
        }

        return Number(currencyString.replace(/[$,]/g, ''));
    }

}
