import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, CommunicationService, EpisodeOfCareService, HttpClientService, RolePermissionsService, ToasterService } from "../../services";
import { finalize } from "rxjs/operators";
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ForgotPasswordResponse, AuthResponse, LandingPage } from '../../models';
import { AccessType, HolistaUtils, HostTheme, Storage } from '../../utils';
import { ThemeContent, ValidEmail, ERROR_LOGIN, ERROR_REQUIRED_LOGIN, SUCCESS_EMAIL_SENT, ERROR_EMAIL_SENT, COMMUNICATIONS, ROLE_CODES, APICONSTANTS, OTP_VERIFIED_SUCCESSFULLY, RestrictSpace, PhoneFormat, MaskConstant, APP_OWNER } from '../../constants';
import { MultiFactorAuthenticationComponent } from '../../modals';
import { ClientJS } from 'clientjs';
import { ANNOUNCEMENT_APP } from "src/app/constants/holista-app.constant";

const appleQr = '../../../assets/img/jpg/ios-qr.jpeg'
const androidQr = '../../../assets/img/png/android-qr.png'
const googlePlayIcon = '../../../assets/img/png/google-play-badge.png'
const applePlayIcon = '../../../assets/img/png/apple-store-badge.png'
const mobileLoginImage = '../../../assets/img/png/mobile-login.png'
const mobileDashboardImage = '../../../assets/img/png/mobile-dashboard.png'
const mobileTaskImage = '../../../assets/img/png/mobile-task.png'

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  eocRequestForm: FormGroup;
  eocFormGroup: FormGroup;
  return_url: string;
  submitted: boolean = false;
  submit_enabled = false;
  submit_email = false;
  submit_email_enabled = false;
  hasAppAnnouncement = false;
  current_year: any;
  dateMask: any[] = MaskConstant.DATE;
  eocList: any[];
  eocListBackUp: any[];
  searchKey = ''
  contactMedium = ''
  appleQr = appleQr;
  androidQr = androidQr;
  googlePlayIcon = googlePlayIcon;
  applePlayIcon = applePlayIcon;
  mobileLoginImage = mobileLoginImage;
  mobileDashboardImage = mobileDashboardImage;
  mobileTaskImage = mobileTaskImage;
  eocLoader = false;
  hasContactVia = true;
  showSuccessPage = false;
  selectedEoc = null;
  showBasicInfoPage = false;
  hasEocViewPermission = false;
  showEocListPage = false;
  duplicateMessage = ''
  showClientName = true;
  eocSubmitButtonDisabler = false;
  pageNumber = 1;
  mask: any[] = MaskConstant.PHONE;
  googlePlayLink = ANNOUNCEMENT_APP.GOOGLE_STORE;
  appleStoreLink = ANNOUNCEMENT_APP.APPLE_STORE;
  landingPage = new LandingPage;
  theme: any;
  mfaDetail: any = {};
  isUserVerified: boolean = false;

  @ViewChild("forgotPasswordModal", { static: true }) public forgotPasswordModal;
  @ViewChild('mfaModal', { static: true }) mfaModal: MultiFactorAuthenticationComponent;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private toastr: ToasterService,
    private http: HttpClientService,
    public hostTheme: HostTheme,
    private episodeOfCareService: EpisodeOfCareService,
    private deviceService: DeviceDetectorService,
    private _storage: Storage,
    private rolePermissionService: RolePermissionsService,
    private holistaUtils: HolistaUtils,
    private _communicationService: CommunicationService,
    private _utilityAccess: AccessType
  ) {
    this.loginForm = this.formBuilder.group({
      userName: ["", Validators.required],
      password: ["", Validators.required],
      source: [""],
      browser: [""],
      browserId: [null],
      rememberMe: [false]
    });

    this.eocRequestForm = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.pattern(RestrictSpace)]],
      lastName: ["", [Validators.required, Validators.pattern(RestrictSpace)]],
      dob: ["", [Validators.required]],
      subscriberId: [""],
      email: ['', [Validators.required, Validators.pattern(ValidEmail)]],
      phone: ["", [Validators.required, Validators.pattern(PhoneFormat)]],
    });



    this.forgotPasswordForm = formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(ValidEmail)]]
    });
  }

  ngOnInit() {
    this.theme = this._storage.get('local', 'UItheme');
    this.showClientName = APP_OWNER.includes(this.theme);
    this.hasEocViewPermission = this.hostTheme.RequestEoc()
    this.hasAppAnnouncement = this.hostTheme.hasAppAnnouncement()
    this.landingPage = ThemeContent[this.theme]
    this.current_year = new Date().getFullYear();
    this.return_url = this.route.snapshot.queryParams["returnUrl"] || "";
    if (this.showEocListPage) {
      this.openEOCList()
    }
    this.showBasicInfoPage = this.route.snapshot.queryParams.redirected;
  }

  onsearchKeyChange(searchKey) {
    this.eocListBackUp = [...this.eocList]
    this.searchKey = searchKey.trim();
    this.eocList = this.eocList.map((x) => ({ ...x, checked: (this.selectedEoc && x.value.eocId == this.selectedEoc.eocId) ? true : false }))
    this.eocListBackUp = this.eocList.filter(item => {
      if (item.label) {
        return item.label.toLowerCase().includes(this.searchKey.toLowerCase());
      }
    });
  }
  removeSearchKeyword() {
    this.eocList = this.eocList.map((x) => ({ ...x, checked: (this.selectedEoc && x.value.eocId == this.selectedEoc.eocId) ? true : false }))
    this.eocListBackUp = [...this.eocList]
    this.searchKey = ''
  }
  login() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.loginForm.controls.browser.setValue(this.deviceService.browser);
      this.loginForm.controls.browserId.setValue(new ClientJS().getFingerprint());
      this.loginForm.controls.source.setValue('web');
      this.submit_enabled = true;
      this.isUserVerified = true;
      this.authService
        .login(this.loginForm.value)
        .pipe(finalize(() => { }))
        .subscribe((response: any) => {
          const { expiresIn, token, isOtpLogin, isMfaEnabled, rememberMfaFor } = response;
          this._storage.remove('local', 'isInactiveModalShown');
          if (isMfaEnabled && isOtpLogin) {
            this.mfaDetail = {
              expiresIn: `${expiresIn} minutes`,
              popupMessage: response.popupMessage,
              otpLength: 6,
              allowNumbersOnly: true,
              token,
              rememberMfaFor
            };
            this.mfaModal.showModal();
            this.submit_enabled = false;
          } else {
            this.handleAuthentication(response);
          }
        },
          (error: { error: any }) => {
            this.submit_enabled = false;
            this.isUserVerified = false;
            console.log('Error', error)
            if (error.error) {
              if (error.error.code) {
                this.router.navigate(['/reset-password'], { queryParams: { code: error.error.code } });
              } else this.toastr.displayError(ERROR_LOGIN, error.error.message, 'LOGIN', { timeOut: 4000 });
            }
          }
        );
    }
    if (this.loginForm.get("password").invalid && this.loginForm.get("userName").valid) {
      this.toastr.displayError(ERROR_REQUIRED_LOGIN, 'Password', 'LOGIN', { timeOut: 4000 });
    }
    if (this.loginForm.get("userName").invalid && this.loginForm.get("password").valid) {
      this.toastr.displayError(ERROR_REQUIRED_LOGIN, 'Username', 'LOGIN', { timeOut: 4000 });
    }
    if (this.loginForm.get("password").invalid && this.loginForm.get("userName").invalid) {
      this.toastr.displayError(ERROR_REQUIRED_LOGIN, 'Username & Password', 'LOGIN', { timeOut: 4000 });
    }
  }

  isAtBottom() {
    return window.innerHeight + window.scrollY >= document.body.offsetHeight;
  }

  showErrors() {
    Object.values(this.eocRequestForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  openEOCList() {
    if (!this.eocRequestForm.value?.contactVia) {
      this.hasContactVia = false
    }

    if (this.eocRequestForm.valid && this.contactMedium) {
      this.hasContactVia = true;
      this.showBasicInfoPage = false;
      this.showEocListPage = true;
    } else {
      this.showErrors()
    }
  }

  onEocChange(checked, eocDetail) {
    if (eocDetail) {
      // this.eocSubmitButtonDisabler = !checked;
      this.eocListBackUp = this.eocListBackUp.map((x) => {
        return {
          ...x,
          checked: x.value.eocId === eocDetail.value.eocId,
        }
      })
      this.selectedEoc = eocDetail.value;
    }
  }

  onContactViaClick(contact) {
    this.contactMedium = contact
    this.hasContactVia = true;
    // this.eocRequestForm.value.contactVia = contact;
  }

  eocApi() {
    this.eocLoader = true
    const eocQuery = {
      limit: 0,
      page: this.pageNumber,
      keyword: this.searchKey,
      sortType: 'desc',
      sortBy: 'id',
      networkCode: 'HPS',
      showOther: true
    };

    this.episodeOfCareService.getEpisodeOfCare(eocQuery).subscribe(eoc => {
      const allEoc = eoc.rows;
      allEoc.sort((a, b) => {
        if (a.name.toLowerCase() === 'others' && b.name.toLowerCase() !== 'others') {
          return -1; // 'other' comes first
        } else {
          return 1; // 'other' comes first
        }
      });
      this.eocLoader = false
      if (allEoc.length) {
        const eocLabelValue = []
        allEoc.map((eachEoc) => {
          const obj: any = {}
          obj.label = eachEoc.bundle?.displayName;
          obj.value = { eocName: eachEoc.name, bundleDisplayName: eachEoc?.bundle ? eachEoc?.bundle?.displayName : null, eocId: eachEoc.uuid }
          eocLabelValue.push(obj)
        })
        this.eocList = eocLabelValue.map((x) => ({ ...x, checked: false }))
        this.eocListBackUp = [...this.eocList]
      }
    }, (err) => {
      this.eocLoader = false
    });
  }

  eocRequestInitiate() {
    // this.submittedForm1 = true;
    this.showBasicInfoPage = true;
    this.eocApi()
  }

  finalEocSubmit() {
    this.eocSubmitButtonDisabler = true
    if (this.selectedEoc) {
      const finalBody = { ...this.eocRequestForm.value, contactVia: this.contactMedium, eocName: this.selectedEoc.eocName, bundleDisplayName: this.selectedEoc.bundleDisplayName, eocUuid: this.selectedEoc.eocId.toString() }
      this.http.post('/members-request-eoc', finalBody).subscribe((submittedValue: any) => {
        if (submittedValue?.data.type && submittedValue?.data?.type.toLowerCase() === 'exists') {
          this.eocSubmitButtonDisabler = false
          this.duplicateMessage = submittedValue.data.message;
        } else {
          this.eocSubmitButtonDisabler = false
          this.showSuccessPage = true;
        }
      }, (error) => {
        this.eocSubmitButtonDisabler = false
        if (error.error.message) {
          this.toastr.showError(error);
        }
      })
    }
  }

  handleAuthentication(data: AuthResponse) {
    this._storage.set('local', 'tabsUsed', '1');
    const encryptedMessageToken = this._storage.get('session', 'encryptedMessageToken');
    data.user = { ...data.user, ...this.holistaUtils.getUserRoleInfo(data.user.roles) }
    this._storage.set('local', 'loggedInUser', data);
    this._communicationService.getCommunicationToken({ email: data.user.email }).subscribe(async res => {
      data.communicationToken = res;
      this._storage.set('local', 'communicationToken', data.communicationToken);
      data.user.rolePermissions = await this.rolePermissionService.getRolePermissionV2(data.user.roleCode).then((res: any) => {
        return res
      });
      this.isUserVerified = false;
      this._storage.set('local', 'loggedInUser', data);
      encryptedMessageToken && this._utilityAccess.searchAccess('COMMS', 'isVisible', false) ? this.router.navigateByUrl(`/${COMMUNICATIONS}`)
        : data.user.roleCode === ROLE_CODES.REPORTING ? this.router.navigateByUrl(`${APICONSTANTS.REPORT}`)
          : this.router.navigateByUrl(this.return_url);
    }, error => {
      if (error.error.message) {
        this.toastr.showError(error);
      }
    });
  }

  handleMFAVerification(data) {
    const { otpCode, rememberMfaCode, token } = data;
    const body = {
      token: token || this.mfaDetail.token,
      otpCode,
      rememberMfaCode,
      userName: this.loginForm.value.userName
    };
    this.authService.verifyOtpLoginCode(body).pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response.message) {
        this.toastr.displayError(response.message);
      } else {
        this.toastr.displaySuccess(OTP_VERIFIED_SUCCESSFULLY);
        this.handleAuthentication(response.data);
        this.mfaModal.hideModal();
      }
    },
      error => {
        if (error.error.message) {
          this.toastr.showError(error);
        }
      }
    );
  }
  onEocListBackClick() {
    this.duplicateMessage = "";
    if (this.contactMedium) {
      this.hasContactVia = true
    }
    this.showEocListPage = false;
    this.showBasicInfoPage = true;
    this.showSuccessPage = false;
    this.searchKey = ''
  }
  onBackClick() {
    if (this.contactMedium) {
      this.hasContactVia = true
    }
    this.showBasicInfoPage = false;
    this.showEocListPage = false;
    this.showSuccessPage = false
  }

  forgotPassword() {
    this.submit_email = true;
    if (this.forgotPasswordForm.valid) {
      this.submit_email_enabled = true;
      this.authService
        .forgotPassword(this.forgotPasswordForm.value)
        .pipe(finalize(() => { this.submit_email_enabled = false; }))
        .subscribe((res: ForgotPasswordResponse) => {
          if (res.success) {
            this.toastr.displaySuccess(SUCCESS_EMAIL_SENT)
          }
          else {
            this.toastr.displayWarning(ERROR_EMAIL_SENT)
          }
          this.submit_email = false
          this.forgotPasswordForm.controls.email.setValue("");
          this.forgotPasswordModal.hide();
        },
          (error: { error: { message: any } }) => {
            console.log(error);
            if (error.error.message) {
              this.toastr.displayError(ERROR_EMAIL_SENT, error.error.message);
            }
          }
        );
    }
  }
  showLoginPage() {
    this.showSuccessPage = false;
    this.showEocListPage = false;
    this.showBasicInfoPage = false;
  }

  toTitleCase(name) {
    return name.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  closeModal() {
    this.forgotPasswordModal.hide();
    this.forgotPasswordForm.reset();
    this.submit_email = false;
  }
}
