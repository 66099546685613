import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs/operators';
import { HolistaUtils } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(
    private _http: HttpClientService,
    private _holistaUtility: HolistaUtils
  ) { }

  getLookups(params) {
    return this._http.get(`${APICONSTANTS.LOOKUP}`, { ...params, limit: 0 })
      .pipe(map((res: any) => {
        const data = { rows: this._holistaUtility.getUniqueList(res.data.rows, 'name') };
        return data;
      }))
  }
}
