import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class EpisodeOfCareService {

    constructor(
        private http: HttpClientService
    ) {

    }

    getEpisodeOfCare(queryParam?) {
        return this.http.get(`${APICONSTANTS.EPISODE_OF_CARE}`, queryParam)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getEpisodeOfCareById(id) {
        return this.http.get(`${APICONSTANTS.EPISODE_OF_CARE}/${id}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getAssociatedPlans(id) {
        return this.http.get(`${APICONSTANTS.EPISODE_OF_CARE}/${id}/associated-plans`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    savePlan(id, body) {
        return this.http.post(`${APICONSTANTS.EPISODE_OF_CARE}/${id}/associate-plan-groups`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getPlans(id) {
        return new Promise((resolve, reject) => {
            this.http.get(`${APICONSTANTS.EPISODE_OF_CARE}/${id}/associate-plan-groups`).toPromise()
                .then((res: any) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    getValueBasedProgramDropDown() {
        return this.http.get(`${APICONSTANTS.VALUE_BASED_PROGRAM}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    getBundleDropDown(bundleUuid, query?) {
        const param = bundleUuid ? `/${bundleUuid}` : ''
        return this.http.get(`${APICONSTANTS.SERVICE_BUNDLE}${param}`, query)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    createEpisodeOfCare(body) {
        return this.http.post(`${APICONSTANTS.EPISODE_OF_CARE}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    updateEpisodeOfCare(body) {
        return this.http.put(`${APICONSTANTS.EPISODE_OF_CARE}/${body.uuid}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    deleteEpisodeOfCare(id) {
        return this.http.remove(`${APICONSTANTS.EPISODE_OF_CARE}/${id}`)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    async getEocByVbpCode(vbpCode, query) {
        return new Promise((resolve, reject) => {
            this.http.get(`${APICONSTANTS.EPISODE_OF_CARE}/vbp/${vbpCode}`, query).toPromise()
                .then((res: any) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    getEocByNetworkCode(queryParam?) {
        return new Promise((resolve, reject) => {
            this.http.get(`${APICONSTANTS.EPISODE_OF_CARE}`, queryParam).toPromise()
                .then((res: any) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    getMemberPlanGroup(uuid, queryParam?) {
        return new Promise((resolve, reject) => {
            this.http.get(`${APICONSTANTS.EPISODE_OF_CARE}/${uuid}/associate-plan-groups`, queryParam).toPromise()
                .then((res: any) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    getMemberPlanCode(memberPlanCode, query?) {
        return new Promise((resolve, reject) => {
            this.http.get(`${APICONSTANTS.PLAN}/${memberPlanCode}`, query).toPromise()
                .then((res: any) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }

    getEocOfMember(data) {
        return this.http.get(`${APICONSTANTS.EOC}?memberUuid=${data.memberUuid}${data.networkCode ? `&networkCode=${data.networkCode}` : ''}&purchaserCode=${data.purchaserCode}`)
            .pipe(map((res: any) => {
                return res.data
            }))
    }
}
