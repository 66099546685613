import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AccessType, Sorting, ScrollTo, Storage } from '../../utils';
import { BundleService, ClientService, LookupService, NetworkService, PurchaserService, ToasterService } from '../../services';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { RequestForFunding, ServiceBundle } from '../../models';
import * as HeaderBreadCrumbActions from '../../action'
import { ACCESS_DENIED, ADD_SUCCESS, DELETE_SUCCESS, MaskConstant, UPDATE_SUCCESS, CODES_GUIDE, MODULES, MEDICAL_CENTER, CLIENT_ADMINS } from '../../constants';
import * as moment from 'moment';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-bundle',
  templateUrl: './bundle.component.html',
  styleUrls: ['./bundle.component.scss']
})
export class BundleComponent implements OnInit {
  bundleServiceList = [];
  fundingRequestList = [];
  fullFundRequestList = [];
  expenseAllocationList = [];
  validEffectiveDate = false;
  validTerminationDate = false;
  loadingData = {
    fundingRequest: false,
    categories: false
  }
  loading: boolean = false;
  disableShowInEpisode = false;
  bundleServiceForm: FormGroup;
  bundleComponentForm: FormGroup;
  bundleSettingForm: FormGroup;
  requestForFundingForm: FormGroup;
  associateFundingRequestForm: NgForm;
  submitted = false;
  submit_enabled = false;
  submit_component = false;
  reverse: boolean = true;
  dateMask: any[] = MaskConstant.DATE;
  associtateFundingReq: any = {};
  disableFields: boolean = false;
  fundingChanged = false;
  exisitingComponent: any = {};
  existingBundle: any = {};
  associtateFundReq = { components: [] };
  message: string = null;
  submitBtnName: string = null;
  submitForAddNew: boolean = false;
  fundingRequests: any = [];
  networks: any[] = []
  networksForFilter: any[] = []
  requestForFunding: RequestForFunding[] = [new RequestForFunding()];
  query = {
    keyword: '',
    page: 1,
    limit: 10,
    orderBy: 'desc',
    sortBy: 'createdAt',
    cptCode: '',
    revenueCode: '',
    networkCode: '',
  }
  result = {
    bundles: true,
    searchedBundles: true
  }
  totalCount: number = 0;
  insurerDropdown: any[] = [];
  insurerId = null;
  user: any;
  searchModelChanged: Subject<string> = new Subject<string>();
  rulesList: any[] = [];
  startRuleDescription = null;
  endRuleDescription = null;
  timing = [
    { label: 'Before', value: 'Before' },
    { label: 'After', value: 'After' },
    { label: 'Day of', value: 'Day of' },
    { label: 'Completion of', value: 'Completion of' },
  ]
  filterForm: FormGroup
  filterList: any = []
  filter_submitted = false
  deleteId: string = '';
  deleteModelName: string = '';
  codeRulesList: any[] = [];
  codesGuide: object = CODES_GUIDE;
  bundleComponentCategories = [];

  @ViewChild('bundleServiceModal', { static: true }) public bundleServiceModal;
  @ViewChild('bundleComponentModal', { static: true }) public bundleComponentModal;
  @ViewChild('associateModal', { static: true }) public associateModal;
  @ViewChild('bundleSettingModal', { static: true }) public bundleSettingModal
  @ViewChild('requestForFundingModal', { static: true }) public requestForFundingModal
  @ViewChild('filterModal', { static: true }) public filterModal;

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private bundleService: BundleService,
    private formBuilder: FormBuilder,
    private toastr: ToasterService,
    private utilityAccess: AccessType,
    private utilitySorting: Sorting,
    private networkService: NetworkService,
    private clientService: ClientService,
    private purchaserService: PurchaserService,
    private _scrollTo: ScrollTo,
    private _storage: Storage,
    private _lookupService: LookupService
  ) {
    this.setBundleServiceForm();
    this.setBundleComponentForm();
    this.setBundleSettingForm();
    this.setFundingRequestForm();
    this.setFilterForm();
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
      this.query.keyword = keyword ? keyword.trim() : '';
      this.query.page = 1;
      this.getBundles();
    });
    this.getCodeRules();
  }

  ngOnInit() {
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Bundles', path: '/bundle' }));
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    let filterData = this._storage.get('session', 'bundleFilterData');
    if (filterData) {
      this.filterForm.patchValue(filterData);
      this.submitFilterBundle()
    } else {
      this.getBundles();
    }
    this.getExpenseAllocation();
    this.getRules();
    // this.getNetworks();
    CLIENT_ADMINS.includes(this.user.roleCode) ? this.getClientNetworks() : this.user.roleCode == 'PUA' ? this.getPurchaserNetworks() : this.getNetworks()
  }

  setBundleServiceForm() {
    this.bundleServiceForm = this.formBuilder.group({
      id: [null],
      uuid: [null],
      name: ['', Validators.required],
      networkCode: ['', Validators.required],
      description: [null],
      effectiveDate: [null],
      terminationDate: [null],
      displayName: ['', Validators.required],
      inOffice: [false],
      fundingRequests: [],
      bundleCode: ['']
    })
  }

  setFilterForm() {
    this.filterForm = this.formBuilder.group({
      cptCode: [''],
      revenueCode: ['']
    })
  }

  setBundleComponentForm() {
    this.bundleComponentForm = this.formBuilder.group({
      isPrimary: [false],
      showInEpisode: [false],
      isMedicalCenter: [false],
      isFundingTrigger: [false],
      name: ['', Validators.required],
      cptCode: [''],
      revenueCode: [''],
      description: [''],
      ruleCode: [''],
      msdrg: [''],
      modifier: [''],
      id: [null],
      taxonomyCode: [''],
      diagnosisCode: [''],
      expenseAllocation: ['', Validators.required],
      editIndex: [null],
      uuid: [null],
      bundleUuid: [null, Validators.required],
      fundingRequestUuid: [null, Validators.required],
      displayRevenueCodes: [],
      displayDiagnosisCodes: [],
      displayCptCodes: [],
      displayTaxonomyCodes: [],
      bundleComponentGroup: [null],
      bundleCode: ['']
    })
  }

  setBundleSettingForm() {
    this.bundleSettingForm = this.formBuilder.group({
      id: [],
      uuid: [],
      bundleUuid: [],
      startType: ['RULE'],
      startDateRuleCode: [''],
      startDateDefaultLabel: [''],
      startDateMilestoneName: [''],
      startDateTiming: [''],
      startDateDays: [null],
      endType: ['RULE'],
      endDateRuleCode: [''],
      endDateDefaultLabel: [''],
      endDateMilestoneName: [''],
      endDateTiming: [''],
      endDateDays: [null]
    })
  }

  setFundingRequestForm() {
    this.requestForFundingForm = this.formBuilder.group({
      id: [''],
      uuid: [''],
      bundleUuid: ['', Validators.required],
      cptCode: [''],
      revenueCode: [''],
      diagnosisCode: [''],
      msdrg: [null],
      modifier1: [null],
      modifier2: [null],
      modifier3: [null],
      modifier4: [null],
      name: ['', Validators.required],
      sequence: ['', [Validators.required, Validators.min(1)]]
    })
  }

  getExpenseAllocation() {
    this.bundleService.getExpenseAllocation()
      .subscribe((res: any) => {
        this.expenseAllocationList = [];
        res.rows = this.utilitySorting.sortBy(res.rows, 'name');
        res.rows.map(x => {
          let data = { label: x.name, value: x.name };
          this.expenseAllocationList = [...this.expenseAllocationList, data];
        });
      }, (error) => {
        console.log("Error getting Expense Allocation List", error);
      })
  }

  getRules() {
    this.bundleService.getRules('BENEFIT_RUlE')
      .subscribe((res: any) => {
        this.rulesList = [];
        res.rows.map(x => {
          let data = { ...x, label: x.name, value: x.code };
          this.rulesList = [...this.rulesList, data];
        });
      }, (error) => {
        console.log("Error getting rule List", error);
      })
  }

  /**
   * Gets list of code rules fro API
   */
  getCodeRules() {
    this.bundleService.getRules('COMPONENT_RULE')
      .subscribe((res: any) => {
        this.codeRulesList = [];
        res.rows.map(x => {
          let data = { ...x, label: x.name, value: x.code };
          this.codeRulesList = [...this.codeRulesList, data];
        });
      }, (error) => {
        console.log("Error getting code rule List", error);
      })
  }

  getRuleDescription(ruleCode: string): string {
    const matchedRule = this.codeRulesList.find(({ code }) => code === ruleCode);
    return matchedRule ? matchedRule.label : null;
  }

  getBundles() {
    this.loading = true
    this.result.bundles = true;
    this.result.searchedBundles = true;
    let queryParams = JSON.parse(JSON.stringify(this.query))
    if (!queryParams.cptCode) delete queryParams.cptCode
    if (!queryParams.revenueCode) delete queryParams.revenueCode
    this.bundleService.getServiceBundle(queryParams)
      .pipe(finalize(() => { this.loading = false }))
      .subscribe(response => {
        this.totalCount = response.count
        if (response.count > 0) {
          response.rows.map(x => {
            x.components = this.utilitySorting.sortBy(x.components, "name")
            x.fundingRequests = this.utilitySorting.sortBy(x.fundingRequests, "sequence")
            x.components.map(y => {
              this.convertStringToArray(y);
              return y
            })
            x.fundingRequests.map(fund => {
              this.convertStringToArray(fund);
              return fund
            })
            return x
          })
          this.bundleServiceList = JSON.parse(JSON.stringify(response.rows)).map(x => {
            const components = x.components.map(y => {
              return { ...y, ruleDescription: this.getRuleDescription(y.ruleCode) }
            }
            )
            return { ...x, components };
          });
        }
        else {
          this.bundleServiceList = [];
          (this.query.keyword.length > 0 || this.query.networkCode || this.filterList.length > 0) ? (this.result.searchedBundles = false) : (this.result.bundles = false);
        }
      }, (error) => {
        console.log("Error getting bundles", error);
        this.toastr.showError(error);
      })
  }

  searchByNetwork(event, type?) {
    this.query.networkCode = (type && type == 'remove') ? '' : event.value;
    this.query.page = 1;
    if (!type)
      this.networksForFilter = this.networksForFilter.map(network => {
        if (network.value == event.value) network.disabled = true
        else network.disabled = false
        return network
      })
    else
      this.networksForFilter = this.networksForFilter.map(x => { x.disabled = false; return x })
    this.getBundles();
  }

  openBundleModal(bundle?) {
    if (this.utilityAccess.searchAccess('SBM', 'isEditable')) {
      if (bundle) {
        bundle.effectiveDate = bundle.effectiveDate ? moment(bundle.effectiveDate).format('MM-DD-YYYY') : null;
        bundle.terminationDate = bundle.terminationDate ? moment(bundle.terminationDate).format('MM-DD-YYYY') : null;
        this.bundleServiceForm.patchValue(bundle)
        this.existingBundle = bundle;
      }
      this.bundleServiceModal.show()
    }
    else this.toastr.displayWarning(ACCESS_DENIED);
  }

  closeBundleModal() {
    this.bundleServiceModal.hide();
    this.bundleServiceForm.reset();
    this.setBundleServiceForm();
    this.requestForFunding = [new RequestForFunding()];
    this.submit_enabled = false;
    this.submitted = false;
  }

  submitBundle() {
    let inValidFundingRequests = [];
    if (!this.bundleServiceForm.value.id) {
      this.requestForFunding = this.requestForFunding.map(x => {
        const reqValues = ['cptCode', 'diagnosisCode', 'revenueCode', 'msdrg', 'modifier1', 'modifier2', 'modifier3', 'modifier4'];
        Object.keys(x).forEach(key => {
          this.removeCommaAndSpace(reqValues, x)
        });
        delete x.fundReqId;
        return x;
      })
      this.bundleServiceForm.controls.fundingRequests.setValue(this.requestForFunding)
      this.requestForFunding.map((x, i) => {
        this.checkCodeValidity(x, i, 'cptCode')
        this.checkCodeValidity(x, i, 'revenueCode')

        if (!x.sequence || x.sequence == 0)
          this.bundleServiceForm.controls.fundingRequests.setErrors({ 'incorrect': true });
      })
      inValidFundingRequests = this.requestForFunding.filter(funding => !funding.validCptCode || !funding.validRevenueCode)
    }
    this.submitted = true;
    const effectiveDate = this.bundleServiceForm.value.effectiveDate ? moment(this.bundleServiceForm.value.effectiveDate) : '';
    if (effectiveDate && !effectiveDate.isValid())
      this.bundleServiceForm.controls['effectiveDate'].setErrors({ 'incorrect': true });
    const terminationDate = this.bundleServiceForm.value.terminationDate ? moment(this.bundleServiceForm.value.terminationDate) : '';
    if (terminationDate && !terminationDate.isValid())
      this.bundleServiceForm.controls['terminationDate'].setErrors({ 'incorrect': true });
    if (!effectiveDate && terminationDate)
      this.bundleServiceForm.controls['effectiveDate'].setErrors({ 'incorrect': true });
    if ((effectiveDate && effectiveDate.isValid()) && (terminationDate && terminationDate.isValid())) {
      const validDate = moment(this.bundleServiceForm.value.terminationDate).isAfter(this.bundleServiceForm.value.effectiveDate);
      if (!validDate) {
        this.bundleServiceForm.controls['terminationDate'].setErrors({ 'incorrect': true });
        this.bundleServiceForm.controls['effectiveDate'].setErrors({ 'incorrect': true });
      }
      else {
        this.bundleServiceForm.controls['terminationDate'].setErrors(null);
        this.bundleServiceForm.controls['effectiveDate'].setErrors(null);
      }
    }
    if (this.bundleServiceForm.valid && (!inValidFundingRequests || inValidFundingRequests.length == 0)) {
      this.setNullForEmpty(this.bundleServiceForm)
      if (this.bundleServiceForm.value.id)
        this.updateBundle();
      else this.saveBundle();
    }
  }

  updateBundle() {
    this.submit_enabled = true;
    let serviceBundle = Object.assign(new ServiceBundle(), this.bundleServiceForm.getRawValue());
    serviceBundle.id = this.bundleServiceForm.get('id').value;
    serviceBundle.uuid = this.bundleServiceForm.get('uuid').value;
    serviceBundle.inOffice = serviceBundle.inOffice ? true : false
    delete serviceBundle.components
    delete serviceBundle.fundingRequests
    delete serviceBundle.benefitSetting
    this.bundleService.updateServiceBundle(serviceBundle).pipe(finalize(() => {
      this.submit_enabled = false;
    })).subscribe(res => {
      this.refreshBundle(res, UPDATE_SUCCESS)
    }, error => {
      console.log('Error updating bundle', error);
      this.toastr.showError(error);
    }
    );
  }

  saveBundle() {
    this.submit_enabled = true;
    let serviceBundle = Object.assign(new ServiceBundle(), this.bundleServiceForm.value);
    delete serviceBundle.id
    delete serviceBundle.uuid
    this.bundleService.saveServiceBundle(serviceBundle).pipe(finalize(() => {
      this.submit_enabled = false;
    })).subscribe(res => {
      this.refreshBundle(res, ADD_SUCCESS);
    }, error => {
      console.log("Error saving Bundle", error);
      this.toastr.displayWarning(error.error.message);
    });
  }

  refreshBundle(response, action) {
    const serviceBundle = JSON.parse(JSON.stringify(response))
    if (this.bundleServiceList && this.bundleServiceList.length > 0) {
      let index = this.bundleServiceList.findIndex(x => x.uuid === serviceBundle.uuid);
      if (index > -1) {
        serviceBundle.components = this.bundleServiceList[index].components
        serviceBundle.fundingRequests = this.bundleServiceList[index].fundingRequests
        serviceBundle.benefitSetting = this.bundleServiceList[index].benefitSetting
        this.bundleServiceList[index] = serviceBundle;
      }
      else {
        this.query.page = 1
        this.getBundles()
      }
    } else
      this.getBundles()
    this.toastr.displaySuccess(action);
    this.closeBundleModal();
  }

  setOrder(value: string) {
    if (this.query.sortBy === value) {
      this.reverse = !this.reverse;
      this.query.orderBy = this.query.orderBy === "desc" ? "asc" : "desc";
    }
    else {
      this.reverse = true;
      this.query.orderBy = "desc";
    }
    this.query.sortBy = value;
    this.getBundles();
  }

  updateIcon(e, index, response) {
    if (response) {
      if (this.bundleServiceList[index].components && this.bundleServiceList[index].components.length > 0) {
        this.bundleServiceList[index].components.map(x => {
          x[response.isPrimary ? 'isPrimary' : 'isMedicalCenter'] = (response.id != x.id) ? false : e.checked
          return x
        })
      }
    }
  }

  openAssociateModal(service) {
    if (this.utilityAccess.searchAccess('SBM', 'isEditable')) {
      this.associateModal.show();
      this.associtateFundingReq = JSON.parse(JSON.stringify(service));
      this.fullFundRequestList = this.associtateFundingReq.fundingRequests;
      this.fundingRequestList = this.associtateFundingReq.fundingRequests.map(f => {
        f['label'] = f.name;
        f['value'] = f.uuid;
        return f
      });
    } else {
      this.toastr.displayWarning(ACCESS_DENIED);
    }
  }

  submitFundingRequest(data) {
    data.components.forEach(element => {
      let index = this.fullFundRequestList.findIndex(x => x.fundingRequestUuid == element.fundingRequestUuid);
      if (index >= 0)
        element.fundingRequestName = this.fullFundRequestList[index].name;
      this.associtateFundReq.components.push(element);
    });
    if (!this.fundingChanged)
      this.saveAssociateFundingRequest(data, this.associtateFundReq);
    else {
      this.message = `Change in funding request might affect the bundle cost validation. Are you
          sure you want to continue?`;
      this.submitBtnName = 'Save Anyway';
    }
  }

  saveAssociateFundingRequest(data, fundReqs) {
    this.submit_enabled = true;
    this.bundleService.saveAssociateFundingRequest(data.uuid, fundReqs)
      .pipe(finalize(() => this.submit_enabled = false))
      .subscribe((res: any) => {
        // if (res.insurer && res.insurer.name)
        //   res.requestForFunding = res.fundingRequests;
        res = this.utilitySorting.sortBy(res, "name")
        res.map(y => {
          this.convertStringToArray(y);
          // y.fundingRequests = res.fundingRequests;
          return y
        })
        const serviceIndex = this.bundleServiceList.findIndex(x => x.uuid === res[0].bundleUuid)
        if (serviceIndex > -1) {
          // if (res.isPrimary || res.isMedicalCenter) {
          //   this.updateIcon({
          //     checked: true
          //   }, serviceIndex, res)
          // }
          this.bundleServiceList[serviceIndex].components = res;
        }
        this.closeAssociateModal();
        this.toastr.displaySuccess(UPDATE_SUCCESS);
      }, (error) => {
        console.log("Error saving associate funding request", error);
      })
  }

  closeAssociateModal() {
    this.associateModal.hide();
    this.fundingRequestList = [];
    this.associtateFundingReq = {};
    this.associtateFundReq.components = [];
    this.fundingChanged = false;
    this.message = null;
    this.submitBtnName = null;
  }

  selectedBundle: any = {}

  openBundleComponent(service, component?) {
    if (this.utilityAccess.searchAccess('SBM', 'isEditable')) {
      this.selectedBundle = JSON.parse(JSON.stringify(service))
      this.bundleComponentForm.controls.bundleUuid.setValue(service.uuid)
      this.bundleComponentForm.controls.bundleCode.setValue(service.bundleCode)
      let bundle = JSON.parse(JSON.stringify(service))
      if (component) {
        // this.disableFields = (component.expenseAllocation.toLowerCase() == 'administrative fee' || component.expenseAllocation.toLowerCase() == 'client fee') ? true : false;
        this.bundleComponentForm.patchValue(component);
        this.expenseSelected({ value: component.expenseAllocation })
        this.exisitingComponent = component;
      }
      this.loadingData.categories = false;
      this._lookupService.getLookups({ module: MODULES.BUNDLE_COMPONENT })
        .pipe(finalize(() => { this.loadingData.categories = false }))
        .subscribe((response: any) => {
          if (response) {
            this.bundleComponentCategories = response.rows.map(category => ({ ...category, label: category.name, value: category.code }));
          }
        })
      this.bundleComponentModal.show();
      this.fundingRequestList = bundle.fundingRequests.map(f => {
        f['label'] = f.name;
        f['value'] = f.uuid;
        return f
      });
    } else {
      this.toastr.displayWarning(ACCESS_DENIED);
    }
  }

  closebundleComponentModal(action?) {
    this.disableShowInEpisode = false
    if (action && action == 'close') {
      this.bundleComponentModal.hide();
      this.bundleComponentForm.reset();
      this.setBundleComponentForm();
    }
    this.bundleComponentForm.reset();
    this.setBundleComponentForm();
    if (action == 'addnew') {
      this.bundleComponentForm.controls.bundleUuid.setValue(this.selectedBundle.uuid)
      this.bundleComponentForm.controls.bundleCode.setValue(this.selectedBundle.bundleCode)
    }
    this.submitted = false;
    this.disableFields = false;
    this.submit_enabled = false;
    this.submit_component = false;
    this.exisitingComponent = {};
    this.message = null;
    this.submitBtnName = null;
  }

  convertStringToArray(components?) {
    const value = components ? components : this.bundleComponentForm.value
    if (value.cptCode) {
      let cptCodes = new Array();
      cptCodes = value.cptCode.replace(/\s*,\s*/g, ',').split(",");
      if (components)
        components.displayCptCodes = cptCodes
      else
        this.bundleComponentForm.controls.displayCptCodes.setValue(cptCodes)
    }
    if (value.taxonomyCode) {
      let taxonomyCodes = new Array();
      taxonomyCodes = value.taxonomyCode.replace(/\s*,\s*/g, ',').split(",");
      if (components)
        components.displayTaxonomyCodes = taxonomyCodes
      else
        this.bundleComponentForm.controls.displayTaxonomyCodes.setValue(taxonomyCodes)
    }
    if (value.revenueCode) {
      let revenueCodes = new Array();
      revenueCodes = value.revenueCode.replace(/\s*,\s*/g, ',').split(",");
      if (components)
        components.displayRevenueCodes = revenueCodes
      else
        this.bundleComponentForm.controls.displayRevenueCodes.setValue(revenueCodes)
    }

    if (value.diagnosisCode) {
      let diagnosisCodes = new Array();
      diagnosisCodes = value.diagnosisCode.replace(/\s*,\s*/g, ',').split(",");
      if (components)
        components.displayDiagnosisCodes = diagnosisCodes
      else
        this.bundleComponentForm.controls.displayDiagnosisCodes.setValue(diagnosisCodes)
    }

    if (value.msdrg) {
      let msdrg = new Array();
      msdrg = value.msdrg.replace(/\s*,\s*/g, ',').split(",");
      if (components)
        components.displayMsdrg = msdrg
      else
        this.bundleComponentForm.controls.displayMsdrg.setValue(msdrg)
    }
  }

  setNullForEmpty(formName) {
    Object.keys(formName.value).forEach(k => {
      formName.controls[k].setValue(formName.value[k] || typeof formName.value[k] == 'boolean' ? formName.value[k] : null)
    })
  }

  submitBundleComponent(action) {
    this.submit_component = true;
    if (this.bundleComponentForm.valid) {
      const reqValues = ['cptCode', 'diagnosisCode', 'taxonomyCode', 'revenueCode', 'msdrg', 'modifier'];
      Object.keys(this.bundleComponentForm.value).forEach(key => {
        this.removeCommaAndSpace(reqValues, this.bundleComponentForm.value);
        this.bundleComponentForm.value.ruleCode === undefined && this.bundleComponentForm.controls['ruleCode'].setValue(null);
        this.bundleComponentForm.setValue(this.bundleComponentForm.value);
      });
      this.setNullForEmpty(this.bundleComponentForm);
      if (this.bundleComponentForm.value.id) {
        if (this.exisitingComponent.fundingRequestUuid != this.bundleComponentForm.value.fundingRequestUuid) {
          this.message = `Change in funding request might affect the bundle cost validation. Are you
          sure you want to continue?`;
          this.submitBtnName = 'Update Anyway';
        }
        else
          this.updateBundleComponent(action);
      }
      else
        this.saveBundleComponent(action);
    }
  }

  deleteBundleComponent(component) {
    if (this.utilityAccess.searchAccess('SBM', 'isEditable')) {
      if (component) {
        this.bundleService.deleteServiceBundleComponent(component.uuid)
          .subscribe((response: any) => {
            const serviceIndex = this.bundleServiceList.findIndex(x => x.uuid === component.bundleUuid)
            if (serviceIndex > -1) {
              const componentIndex = this.bundleServiceList[serviceIndex].components.findIndex(y => y.uuid === component.uuid)
              if (componentIndex > -1) {
                this.bundleServiceList[serviceIndex].components.splice(componentIndex, 1);
                this.toastr.displaySuccess(DELETE_SUCCESS);
              }
            }
          }, (error) => {
            this.toastr.showError(error);
            console.log('error deleting bundle component', error);
          })
      }
    }
    else this.toastr.displayWarning(ACCESS_DENIED)
  }

  saveBundleComponent(action) {
    if (action == 'addnew')
      this.submitForAddNew = true;
    else
      this.submit_enabled = true;
    this.bundleService.saveBundleComponent(this.bundleComponentForm.getRawValue()).pipe(finalize(() => {
      this.submit_enabled = false;
      this.submitForAddNew = false;
    })).subscribe(res => {
      this.refreshBundleComponent({ ...res, ruleDescription: this.getRuleDescription(res.ruleCode) }, ADD_SUCCESS, action)
    }, error => {
      console.log("Error saving bundle component", error);
      this.toastr.showError(error);
    })
  }

  updateBundleComponent(action) {
    this.submit_enabled = true;
    this.bundleService.updateBundleComponent(this.bundleComponentForm.getRawValue()).pipe(finalize(() => {
      this.submit_enabled = false;
    })).subscribe(res => {
      this.refreshBundleComponent({ ...res, ruleDescription: this.getRuleDescription(res.ruleCode) }, UPDATE_SUCCESS, action)
    }, error => {
      console.log("Error updating bundle component", error);
      this.toastr.showError(error);
    })
  }

  refreshBundleComponent(response, type, action?) {
    if (this.bundleServiceList && this.bundleServiceList.length > 0) {
      response.displayCptCodes = null
      response.displayDiagnosisCodes = null
      response.displayRevenueCodes = null
      response.displayTaxonomyCodes = null
      this.convertStringToArray(response)
      const serviceIndex = this.bundleServiceList.findIndex(x => x.uuid === response.bundleUuid)
      if (serviceIndex > -1) {
        if (type === UPDATE_SUCCESS) {
          const componentIndex = this.bundleServiceList[serviceIndex].components.findIndex(y => y.uuid === response.uuid)
          if (componentIndex > -1) {
            this.bundleServiceList[serviceIndex].components[componentIndex] = response
          }
        } else
          this.bundleServiceList[serviceIndex].components.push(response)
        if (response.isPrimary || response.isMedicalCenter) {
          this.updateIcon({
            checked: true
          }, serviceIndex, response)
        }
        if (response.isFundingTrigger) {
          this.bundleServiceList[serviceIndex].components.forEach(component => {
            if (component.fundingRequestUuid === response.fundingRequestUuid) {
              if (response.id !== component.id)
                component.isFundingTrigger = false;
              else
                component.isFundingTrigger = true;
            }
          });
        }
      }
      this.toastr.displaySuccess(type);
      this.closebundleComponentModal(action)
    }
  }

  scrollToTop() {
    this._scrollTo.ScrollTo('scrollToTop', 'center', 'smooth')
  }

  /**
   * Validates Taxonomy code, Diagnosis code, MSDRG & Modifier
   * @param type 
   */
  checkCode(type: string) {
    const code = this.bundleComponentForm.value[type].replace(/\s*,\s*/g, ',').split(",")
    let validCode = true;
    const taxonomyReg = /^-?[a-zA-Z0-9_]{10}$/;
    const diagnosisMsdrgReg = /^-?[a-zA-Z0-9_]+([\.\-]?[a-zA-Z0-9_]+)?$/;
    const reg = /^-?[a-zA-Z0-9_]*$/;
    code.forEach(x => {
      if (validCode && x) {
        if (!((x.length === 10 || x.length === 11) && type === 'taxonomyCode' && taxonomyReg.test(x)) &&
          !((type === 'diagnosisCode' || type === 'msdrg') && diagnosisMsdrgReg.test(x)) &&
          !((type !== 'taxonomyCode' && type !== 'diagnosisCode' && type !== 'msdrg') && reg.test(x)) &&
          !(x === '-')) {
          validCode = false;
          this.bundleComponentForm.controls[type].setErrors({ 'invalid': true });
        }
      }
    })
  }

  /**
   * Validates cpt code and revenue code
   * @param fundingRequest 
   * @param index 
   * @param code 
   */
  checkCodeValidity(fundingRequest, index, code) {
    let validCode = fundingRequest ? (fundingRequest[code] ? fundingRequest[code] : '') : this.bundleComponentForm.value[code]
    const validCodeName = 'valid' + code.charAt(0).toUpperCase() + code.substr(1)
    let validate = true
    if (validCode) {
      validCode = validCode.replace(/\s*,\s*/g, ',').split(",")
      validCode.map(x => {
        if (validate && x) {
          const cptCodeReg = /^-?[a-zA-Z0-9]{5}$/;
          const revenueCodeReg = /^-?[0-9]{4}$/;
          if (((x.length === 5 || x.length === 6) && code === 'cptCode') || ((x.length === 4 || x.length === 5) && code === 'revenueCode')) {
            validate = code === 'cptCode' ? cptCodeReg.test(x) : revenueCodeReg.test(x);
          } else if (x === '-') {
            validate = true;
          } else {
            validate = false
          }
          if (!validate) {
            if (!fundingRequest) this.bundleComponentForm.controls[code].setErrors({ 'invalid': true });
          }
        }
      })
    } else {
      if (this.requestForFunding[index]) this.requestForFunding[index][validCodeName] = true
    }
    if (fundingRequest && validCode) {
      if (validate) {
        this.requestForFunding[index][validCodeName] = true
      } else {
        this.requestForFunding[index][validCodeName] = false
      }
    }
  }

  statusChange(event, name) {
    if (event.checked) {
      if (name !== 'isFundingTrigger') {
        this.bundleComponentForm.controls.isPrimary.setValue(false);
        this.bundleComponentForm.controls.isMedicalCenter.setValue(false);
      }
      this.bundleComponentForm.controls[name].setValue(true);
    }
    const { isPrimary, isMedicalCenter } = this.bundleComponentForm.value
    if (isPrimary || isMedicalCenter) {
      this.bundleComponentForm.controls.showInEpisode.setValue(true);
      this.disableShowInEpisode = true
    } else {
      this.disableShowInEpisode = false
    }
  }

  fundingRequestChange(i) {
    this.associtateFundingReq.components[i].isFundingTrigger = false;
    this.fundingChanged = true;
  }

  fundingRequestTriggered(event, i) {
    if (event == true) {
      this.associtateFundingReq.components.forEach((component, index) => {
        if (component.fundingRequestUuid == this.associtateFundingReq.components[i].fundingRequestUuid) {
          if (index != i)
            component.isFundingTrigger = false;
          else
            component.isFundingTrigger = true;
        }
      });
    }
  }

  expenseSelected(event) {
    if (event) {
      if (event.value.toLowerCase() == 'administrative fee' || event.value.toLowerCase() == 'client fee') {
        let fieldsToDisable = ['cptCode', 'revenueCode', 'msdrg', 'modifier', 'taxonomyCode', 'diagnosisCode', 'displayCptCodes', 'displayTaxonomyCodes', 'displayDiagnosisCodes', 'displayRevenueCodes'];
        fieldsToDisable.forEach(element => {
          this.bundleComponentForm.controls[element].setValue(null)
        });
        this.disableFields = true;
        this.bundleComponentForm.get('ruleCode').value && this.bundleComponentForm.controls['ruleCode'].reset();
        this.bundleComponentForm.controls.ruleCode.setValue(null);
      }
      else this.disableFields = false;
    }
  }

  pageChanged(event) {
    this.query.page = event;
    this.getBundles();
  }

  searchByKeyword(text) {
    this.searchModelChanged.next(text);
  }

  openBundleSetting(bundleService) {
    if (this.utilityAccess.searchAccess('SBM', 'isEditable')) {
      this.bundleSettingModal.show()
      if (bundleService.benefitSetting) {
        this.bundleSettingForm.patchValue(bundleService.benefitSetting);
        this.bundleSettingForm.controls.startType.setValue(bundleService.benefitSetting.startType ? bundleService.benefitSetting.startType : 'RULE');
        this.bundleSettingForm.controls.endType.setValue(bundleService.benefitSetting.endType ? bundleService.benefitSetting.endType : 'RULE');
        if (bundleService.benefitSetting.startType == 'RULE') {
          this.startRuleDescription = bundleService.benefitSetting.startDateRuleCode ? this.rulesList.find(x => x.code == bundleService.benefitSetting.startDateRuleCode).description : null;
          this.bundleSettingForm.controls.startDateRuleCode.setValidators(Validators.required)
          this.bundleSettingForm.controls.startDateRuleCode.updateValueAndValidity()
        }
        if (bundleService.benefitSetting.endType == 'RULE') {
          this.endRuleDescription = bundleService.benefitSetting.endDateRuleCode ? this.rulesList.find(x => x.code == bundleService.benefitSetting.endDateRuleCode).description : null;
          this.bundleSettingForm.controls.endDateRuleCode.setValidators(Validators.required)
          this.bundleSettingForm.controls.endDateRuleCode.updateValueAndValidity()
        }
      }
      else {
        this.bundleSettingForm.controls.bundleUuid.setValue(bundleService.uuid)
      }
    }
    else this.toastr.displayWarning(ACCESS_DENIED)
  }

  closeBundleSettingModal() {
    this.bundleSettingModal.hide();
    this.bundleSettingForm.reset();
    this.setBundleSettingForm();
    this.submitted = false;
    this.startRuleDescription = null;
    this.endRuleDescription = null;
  }

  saveBundleSettings() {
    this.submitted = true;
    if (this.bundleSettingForm.valid) {
      this.submit_enabled = true;
      if (this.bundleSettingForm.value.id) {
        this.bundleService.updateSetting(this.bundleSettingForm.value.uuid, this.bundleSettingForm.getRawValue())
          .pipe(finalize(() => { this.submit_enabled = false; }))
          .subscribe((res: any) => {
            if (res) {
              let index = this.bundleServiceList.findIndex(x => x.uuid == res.bundleUuid)
              if (index > -1)
                this.bundleServiceList[index].benefitSetting = res;
              this.toastr.displaySuccess(UPDATE_SUCCESS);
            }
          }, (error) => {
            console.log('Error updating bundle setting', error);
            this.toastr.showError(error);
          }, () => { this.closeBundleSettingModal() })
      }
      else {
        let body = this.bundleSettingForm.getRawValue();
        delete body.uuid
        this.bundleService.createSetting(body)
          .pipe(finalize(() => { this.submit_enabled = false; }))
          .subscribe((res: any) => {
            if (res) {
              let index = this.bundleServiceList.findIndex(x => x.uuid == res.bundleUuid)
              if (index > -1)
                this.bundleServiceList[index].benefitSetting = res;
              this.toastr.displaySuccess(UPDATE_SUCCESS);
            }
          }, (error) => {
            console.log('Error saving bundle setting', error);
            this.toastr.showError(error);
          }, () => { this.closeBundleSettingModal() })
      }
    }
  }

  changeType(type, typeName) {
    if (type == 'start') {
      this.bundleSettingForm.controls.startType.setValue(typeName);
      this.startRuleDescription = null;
      if (typeName == 'RULE') {
        this.bundleSettingForm.controls.startDateRuleCode.setValidators([Validators.required]);
        this.bundleSettingForm.controls.startDateRuleCode.updateValueAndValidity();
        this.bundleSettingForm.controls.startDateMilestoneName.clearValidators();
        this.bundleSettingForm.controls.startDateMilestoneName.updateValueAndValidity();
        this.bundleSettingForm.controls.startDateMilestoneName.setValue('');
        this.bundleSettingForm.controls.startDateTiming.clearValidators();
        this.bundleSettingForm.controls.startDateTiming.updateValueAndValidity();
        this.bundleSettingForm.controls.startDateTiming.setValue('');
        this.bundleSettingForm.controls.startDateDays.clearValidators();
        this.bundleSettingForm.controls.startDateDays.updateValueAndValidity();
        this.bundleSettingForm.controls.startDateDays.setValue(null);
      }
      else {
        this.bundleSettingForm.controls.startDateMilestoneName.setValidators([Validators.required]);
        this.bundleSettingForm.controls.startDateMilestoneName.updateValueAndValidity();
        this.bundleSettingForm.controls.startDateTiming.setValidators([Validators.required]);
        this.bundleSettingForm.controls.startDateTiming.updateValueAndValidity();
        this.bundleSettingForm.controls.startDateDays.setValidators([Validators.required]);
        this.bundleSettingForm.controls.startDateDays.updateValueAndValidity();
        this.bundleSettingForm.controls.startDateRuleCode.clearValidators();
        this.bundleSettingForm.controls.startDateRuleCode.updateValueAndValidity();
        this.bundleSettingForm.controls.startDateRuleCode.setValue('');
      }
    }
    else {
      this.bundleSettingForm.controls.endType.setValue(typeName);
      this.endRuleDescription = null;
      if (typeName == 'RULE') {
        this.bundleSettingForm.controls.endDateRuleCode.setValidators([Validators.required]);
        this.bundleSettingForm.controls.endDateRuleCode.updateValueAndValidity();
        this.bundleSettingForm.controls.endDateMilestoneName.clearValidators();
        this.bundleSettingForm.controls.endDateMilestoneName.updateValueAndValidity();
        this.bundleSettingForm.controls.endDateMilestoneName.setValue('');
        this.bundleSettingForm.controls.endDateTiming.clearValidators();
        this.bundleSettingForm.controls.endDateTiming.updateValueAndValidity();
        this.bundleSettingForm.controls.endDateTiming.setValue('');
        this.bundleSettingForm.controls.endDateDays.clearValidators();
        this.bundleSettingForm.controls.endDateDays.updateValueAndValidity();
        this.bundleSettingForm.controls.endDateDays.setValue(null);
      }
      else {
        this.bundleSettingForm.controls.endDateMilestoneName.setValidators([Validators.required]);
        this.bundleSettingForm.controls.endDateMilestoneName.updateValueAndValidity();
        this.bundleSettingForm.controls.endDateTiming.setValidators([Validators.required]);
        this.bundleSettingForm.controls.endDateTiming.updateValueAndValidity();
        this.bundleSettingForm.controls.endDateDays.setValidators([Validators.required]);
        this.bundleSettingForm.controls.endDateDays.updateValueAndValidity();
        this.bundleSettingForm.controls.endDateRuleCode.clearValidators();
        this.bundleSettingForm.controls.endDateRuleCode.updateValueAndValidity();
        this.bundleSettingForm.controls.endDateRuleCode.setValue('');
      }
    }
    this.submitted = false;
  }

  ruleSelected(type, event) {
    if (type == 'start')
      this.startRuleDescription = event.description;
    else
      this.endRuleDescription = event.description;
  }

  timingSelected(type, event) {
    if (type == 'start') {
      if (event.value == 'Day of') {
        this.bundleSettingForm.controls.startDateDays.clearValidators();
        this.bundleSettingForm.controls.startDateDays.updateValueAndValidity();
        this.bundleSettingForm.controls.startDateDays.setValue(null);
      }
    }
    else {
      if (event.value == 'Day of') {
        this.bundleSettingForm.controls.endDateDays.clearValidators();
        this.bundleSettingForm.controls.endDateDays.updateValueAndValidity();
        this.bundleSettingForm.controls.endDateDays.setValue(null);
      }
    }
  }

  onFocusOutEvent(i, modifierName) {
    if (modifierName == 'mod1' && !this.requestForFunding[i].modifier1) {
      this.requestForFunding[i].modifier2 = null;
      this.requestForFunding[i].modifier3 = null;
      this.requestForFunding[i].modifier4 = null;
    }
    if (modifierName == 'mod2' && !this.requestForFunding[i].modifier2) {
      this.requestForFunding[i].modifier3 = null;
      this.requestForFunding[i].modifier4 = null;
    }
    if (modifierName == 'mod3' && !this.requestForFunding[i].modifier3) {
      this.requestForFunding[i].modifier4 = null;
    }
  }

  OpenFilterModal() {
    let filterData = this._storage.get('session', 'bundleFilterData');
    if (filterData) this.filterForm.patchValue(filterData);
    this.filterModal.show()
  }

  closeFilterModal() {
    this.filter_submitted = false
    if (!this.filterList || this.filterList.length == 0) this.filterForm.reset()
    this.filterModal.hide()
  }

  submitFilterBundle() {
    this.filter_submitted = true
    let filter_data = this.filterForm.value;
    if (this.filterForm.valid) {
      this.filterList = []
      this._storage.set('session', 'bundleFilterData', filter_data);
      this.query.page = 1
      this.query.revenueCode = this.filterForm.value.revenueCode
      this.query.cptCode = this.filterForm.value.cptCode
      this.getBundles()
      Object.keys(filter_data).forEach(key => {
        let value = filter_data[key];
        if (value && key == 'cptCode') this.updateFilterApplied('CPT Code', key, value);
        if (value && key == 'revenueCode') this.updateFilterApplied('Revenue Code', key, value);
      });
      this.filterModal.hide()
      this.filter_submitted = false
    }
  }

  updateFilterApplied(field, formField, value) {
    let fieldIndex = this.filterList.findIndex(field => field.formField == formField)
    if (fieldIndex > -1) {
      this.filterList.splice(fieldIndex, 1);
    }
    let valueIndex = this.filterList.findIndex(x => x.field == field && x.label == value);
    if (valueIndex == -1) {
      let data = { field: field, formField: formField, label: value };
      this.filterList.push(data);
    }
  }

  removeFilter(filter) {
    let filteredData = this.filterForm.get(filter.formField).value;
    this.filterForm.controls[`${filter.formField}`].setValue(filteredData && typeof filteredData == 'object' ? filteredData : '');
    this.submitFilterBundle()
  }

  ResetFilter() {
    this.query.revenueCode = ''
    this.query.cptCode = ''
    this.filterForm.reset();
    this.filterList.length = 0;
    this.getBundles()
    this._storage.remove('session', 'bundleFilterData');
  }

  // revenueInput(event) {
  //   if (event.target.value.length >= 3) this.filterForm.controls.revenueCode.setValue(event.target.value.replace(/\D/g, ''))
  // }

  displayCptDescription(name, cpt, bundleIndex, componentIndex) {
    if (this.bundleServiceList[bundleIndex][name][componentIndex].cptDescription) {
      let filteredCptDescription = this.bundleServiceList[bundleIndex][name][componentIndex].cptDescription.filter(x => x.cptCode == cpt);
      if (filteredCptDescription.length > 0)
        return filteredCptDescription[0].shortDescription;
      else return null;
    }
    else return null;
  }

  displayDiagCodeDescription(name, diagCode, bundleIndex, componentIndex) {
    if (this.bundleServiceList[bundleIndex][name][componentIndex].diagDescription) {
      let filteredDiagCodeDescription = this.bundleServiceList[bundleIndex][name][componentIndex].diagDescription.filter(x => x.icdCode == diagCode);
      if (filteredDiagCodeDescription.length > 0)
        return filteredDiagCodeDescription[0].shortDescription;
      else return null;
    }
    else return null;
  }

  getClientNetworks() {
    this.clientService.getNetworksByClientCode(this.user.referenceCode)
      .subscribe(res => {
        res.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.networksForFilter = JSON.parse(JSON.stringify(res))
        this.networks = JSON.parse(JSON.stringify(res))
      },
        (error) => {
          console.log('Error', error)
        })
  }

  getPurchaserNetworks() {
    this.purchaserService.getNetworks(this.user.referenceCode)
      .subscribe(res => {
        res.rows.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.networksForFilter = JSON.parse(JSON.stringify(res.rows))
        this.networks = JSON.parse(JSON.stringify(res.rows))
      },
        (error) => {
          console.log('Error', error)
        })
  }

  getNetworks() {
    this.networkService.getAll({ limit: 0, fields: 'code,name' })
      .subscribe((res: any) => {
        res.rows = this.utilitySorting.sortBy(res.rows, 'name');
        res.rows.map(x => {
          x.label = x.name,
            x.value = x.code
          return x;
        })
        this.networks = JSON.parse(JSON.stringify(res.rows))
        this.networksForFilter = JSON.parse(JSON.stringify(res.rows))
      }, (error) => {
        console.log("Error getting Networks", error);
      })
  }

  addRequestForFunding() {
    this.requestForFunding.push(new RequestForFunding())
  }

  removeRequestForFunding(req) {
    if (this.requestForFunding.length != 1) {
      let index = this.requestForFunding.findIndex(x => x.fundReqId == req.fundReqId)
      if (index > -1)
        this.requestForFunding.splice(index, 1)
    }
  }

  openRequestForFundingModal(bundle, fundingRequest?) {
    if (this.utilityAccess.searchAccess('SBM', 'isEditable')) {
      if (fundingRequest)
        this.requestForFundingForm.patchValue(fundingRequest);
      this.requestForFundingForm.controls.bundleUuid.setValue(bundle.uuid);
      this.requestForFundingModal.show();
    }
    else this.toastr.displayWarning(ACCESS_DENIED)
  }

  closeRequestForFundingModal() {
    this.requestForFundingModal.hide();
    this.requestForFundingForm.reset();
    this.setFundingRequestForm();
    this.submitted = false;
  }

  removeCommaAndSpace(reqValues, value) {
    reqValues.forEach(code => {
      if (value[code]) {
        value[code] = value[code].trim();
        const firstChar = value[code].charAt(0);
        const lastChar = value[code].slice(-1);
        firstChar === ',' && (value[code] = value[code].slice(1));
        lastChar === ',' && (value[code] = value[code].slice(0, -1));
      }
    })
  }

  submitRequestForFunding() {
    this.submitted = true;
    const reqValues = ['cptCode', 'diagnosisCode', 'revenueCode', 'msdrg', 'modifier1', 'modifier2', 'modifier3', 'modifier4'];
    Object.keys(this.requestForFundingForm.value).forEach(key => {
      this.removeCommaAndSpace(reqValues, this.requestForFundingForm.value)
      this.requestForFundingForm.setValue(this.requestForFundingForm.value)
    });
    this.checkValidity();
    if (this.requestForFundingForm.valid) {
      this.submit_enabled = true;
      this.setNullForEmpty(this.requestForFundingForm)
      this.requestForFundingForm.controls.sequence.setValue(+this.requestForFundingForm.value.sequence)
      if (this.requestForFundingForm.value.id) this.updateRequestForFunding();
      else this.saveRequestForFunding();
    }
  }

  checkValidity() {
    if (this.requestForFundingForm.value.cptCode) {
      const code = this.requestForFundingForm.value.cptCode.replace(/\s*,\s*/g, ',').split(",")
      for (const x of code) {
        if (x.length == 5) this.requestForFundingForm.controls.cptCode.setErrors(null);
        else { this.requestForFundingForm.controls.cptCode.setErrors({ 'invalid': true }); break }
      }
    }
    if (this.requestForFundingForm.value.revenueCode) {
      const code = this.requestForFundingForm.value.revenueCode.replace(/\s*,\s*/g, ',').split(",")
      for (const x of code) {
        if (x.length == 4) this.requestForFundingForm.controls.revenueCode.setErrors(null);
        else { this.requestForFundingForm.controls.revenueCode.setErrors({ 'invalid': true }); return }
      }
    }
  }

  saveRequestForFunding() {
    let body = this.requestForFundingForm.value
    delete body.uuid
    this.bundleService.createFundingRequest(this.requestForFundingForm.getRawValue())
      .pipe(finalize(() => { this.submit_enabled = false; }))
      .subscribe(res => {
        if (res) {
          let index = this.bundleServiceList.findIndex(x => x.uuid == res.bundleUuid)
          if (index > -1) {
            this.bundleServiceList[index].fundingRequests.push(res);
            this.bundleServiceList[index].fundingRequests = this.utilitySorting.sortBy(this.bundleServiceList[index].fundingRequests, "sequence");
          }
          this.convertStringToArray(res)
          this.toastr.displaySuccess(ADD_SUCCESS);
          this.closeRequestForFundingModal();
        }
      }, (error) => {
        console.log("Error saving funding request", error);
        this.toastr.showError(error);
      });
  }

  updateRequestForFunding() {
    this.bundleService.updateFundingRequest(this.requestForFundingForm.getRawValue())
      .pipe(finalize(() => { this.submit_enabled = false; }))
      .subscribe(res => {
        if (res) {
          let index = this.bundleServiceList.findIndex(x => x.uuid == res.bundleUuid)
          if (index > -1) {
            if (this.bundleServiceList[index].fundingRequests && this.bundleServiceList[index].fundingRequests.length > 0) {
              let index2 = this.bundleServiceList[index].fundingRequests.findIndex(y => y.uuid == res.uuid)
              if (index2 > -1) {
                this.bundleServiceList[index].fundingRequests[index2] = res
                this.bundleServiceList[index].fundingRequests = this.utilitySorting.sortBy(this.bundleServiceList[index].fundingRequests, "sequence");
              }
              if (this.bundleServiceList[index].components && this.bundleServiceList[index].components.length > 0) {
                this.bundleServiceList[index].components.map(c => {
                  if (c.fundingRequest.uuid == res.uuid)
                    c.fundingRequest = res
                })
              }
            }
          }
          this.convertStringToArray(res)
          this.toastr.displaySuccess(UPDATE_SUCCESS);
          this.closeRequestForFundingModal();
        }
      }, (error) => {
        console.log("Error updating Funding Request", error);
        this.toastr.showError(error);
      });
  }

  delete(model, type, bundle?) {
    if (this.utilityAccess.searchAccess('SBM', 'isEditable')) {
      this.deleteId = model.uuid;
      this.deleteModelName = type;
      if (type == 'fund')
        this.selectedBundle = bundle
      this.message = `Are you sure you want to delete <strong>${model.name}</strong>? This is irreversible.
      If you're not sure, you can click <strong>Cancel</strong>.`
    }
    else
      this.toastr.displayWarning(ACCESS_DENIED);
  }

  confirmDelete(id) {
    if (id) {
      switch (this.deleteModelName) {
        case 'bundle':
          this.deleteBundle(id)
          break;
        case 'fund':
          this.deleteRequestForFunding(id)
          break;
        default:
          break;
      }
    }
    else
      this.deleteId = null;
    this.message = ''
  }

  deleteBundle(id) {
    this.bundleService.deleteServiceBundle(id)
      .subscribe((res) => {
        this.toastr.displaySuccess(DELETE_SUCCESS);
        this.getBundles();
      }, (error) => {
        console.log("Error deleting Bundle", error);
        this.toastr.showError(error);
        this.deleteId = null;
      })
  }

  deleteRequestForFunding(id) {
    this.bundleService.deleteFundingRequest(id)
      .subscribe((res: any) => {
        if (res) {
          let index = this.bundleServiceList.findIndex(x => x.uuid == this.selectedBundle.uuid)
          if (index > -1) {
            if (this.bundleServiceList[index].fundingRequests && this.bundleServiceList[index].fundingRequests.length > 0) {
              let index2 = this.bundleServiceList[index].fundingRequests.findIndex(y => y.uuid == id)
              if (index2 > -1) {
                this.bundleServiceList[index].fundingRequests.splice(index2, 1)
                this.toastr.displaySuccess(DELETE_SUCCESS);
              }
            }
          }
        }
      }, (error) => {
        console.log("Error deleting Funding Request", error);
        this.toastr.showError(error);
        this.deleteId = null;
      })
  }

  removeSearchKeyword() {
    this.query.keyword = ''
    this.searchByKeyword('')
  }

  onCategoryDeselected() {
    this.bundleComponentForm.controls.bundleComponentGroup.setValue(null);
  }
}
