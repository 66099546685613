import { Component, OnInit, ViewChild } from "@angular/core";
import { Store } from '@ngrx/store';
import { ClaimsService, ToasterService } from '../../../services';
import * as HeaderBreadCrumbActions from '../../../action';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AccessType, PDFUtility, Storage } from '../../../utils';
import { saveAs as importedSaveAs } from 'file-saver';
import { ACCESS_DENIED, APPROVED, ADDRESS, MAILING_ADDRESS, CLAIMS_REEDITABLE_STATUSES, SUBMITTED } from "../../../constants";
@Component({
    selector: 'claims-edi',
    templateUrl: './claims-edi.component.html'
})

export class ClaimsEdiComponent implements OnInit {
    @ViewChild('approveConfirmationModal', { static: true }) public approveConfirmationModal;

    claimId = null;
    ediFile = null;
    backupEdi = null;
    loading = {
        edi: false,
        invoice: false,
        pdf: false
    };
    isClaimApproved: any;
    saving: boolean = false;
    type = '837I'
    defaultSelected = 1;
    currentYear = new Date().getFullYear();
    options = [
        { label: 'EDI', value: 1 },
        { label: 'Invoice Form', value: 2 },
        { label: 'UB04', value: 3 },
    ]
    invoicePdf: any;
    totalAllowedAmount: any;
    approveUB04: boolean = false;
    downloadUB04FormOrInvoicePDF: boolean = false;
    showMessageModal: boolean = false;
    showInvoiceCoverSheet: boolean = false;
    fundingFormat: string;
    address: string = ADDRESS;
    mailingAddress: string = MAILING_ADDRESS;
    patientAccountNumber: number;
    claimsReeditableStatuses = CLAIMS_REEDITABLE_STATUSES
    confirmationMessage = `{{additionalMsg}} This file will be sent to insurer. Are you sure you want to proceed ?`

    constructor(private store: Store<{ bread_crumbs: any }>,
        private claimsService: ClaimsService,
        private _toastr: ToasterService,
        private route: ActivatedRoute,
        private utilityAccess: AccessType,
        private location: Location,
        private _pdfUtility: PDFUtility,
        private _storage: Storage
    ) {
    }

    ngOnInit() {
        this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Outgoing Claims', path: '/outgoing-claims' }));
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: '837I', path: '' }));
        this.claimId = this.route.snapshot.paramMap.get("id");
        if (this.claimId)
            this.viewEdi(this.claimId);
        this.isClaimApproved = this.route.snapshot.paramMap.get("isApproved");
        const claimsEdiData = this._storage.get('session', 'claimsEdiData');
        this.fundingFormat = claimsEdiData.fundingFormat ? claimsEdiData.fundingFormat.toString() : '837i';
        this.confirmationMessage = CLAIMS_REEDITABLE_STATUSES.includes(claimsEdiData.processingStatus) && !claimsEdiData.originalReferenceNumber ? this.confirmationMessage.replace('{{additionalMsg}}', 'Original reference number is missing.') : this.confirmationMessage.replace('{{additionalMsg}}', '')
    }

    ngOnDestroy() {
        this._storage.remove('session', 'fundingFormat');
    }

    viewEdi(claimId) {
        this.loading.edi = true;
        this.claimsService.getEdi(claimId, this.type)
            .pipe(finalize(() => this.loading.edi = false))
            .subscribe((res: any) => {
                this.ediFile = `<pre>${res.data}</pre>`;
                this.backupEdi = res.data;
                this.patientAccountNumber = res.claimId
            }, (error) => {
                this._toastr.showError(error);
                console.log('Error getting claims edi', error);
            })
    }

    public captureScreen() {
        window.print();
    }

    download(type) {
        if (type == 'text') {
            const blob = new Blob([this.backupEdi], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            importedSaveAs(url, `837I-${this.patientAccountNumber}.txt`);
        }
        else {
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            const documentDefinition = { content: this.backupEdi };
            switch (this.defaultSelected) {
                case 1:
                    pdfMake.createPdf(documentDefinition).download('837I-' + this.patientAccountNumber);
                    break;
                case 2:
                    this._pdfUtility.convertToPDF(['invoice-wrapper'], 'Invoice-' + this.invoicePdf.claimId, true);
                    break;
                case 3:
                    this.downloadUB04FormOrInvoicePDF = true;
                    break;
                default:
                    break;
            }
        }
    }

    approve() {
        if (this.utilityAccess.searchAccess('OCLM', 'isEditable'))
            this.approveConfirmationModal.show();
        else
            this._toastr.displayWarning(ACCESS_DENIED);
    }

    async approveInvoiceForm() {
        const files = [];
        const pdfConvertedFile = await this._pdfUtility.convertToPDF(['invoice-wrapper'], this.patientAccountNumber.toString());
        files.push(pdfConvertedFile);
        this.claimsService.approveClaim(this.claimId, { files: files }, 'INVOICE')
            .pipe(finalize(() => this.saving = false))
            .subscribe((res) => {
                this.isClaimApproved = true
                if (res) {
                    this._toastr.displaySuccess(SUBMITTED);
                }
            }, (error) => {
                this._toastr.showError(error);
                console.log('Error approving.', error);
            })
    }

    triggerAction(action, type?) {
        if (action == 'yes') {
            if (type == 3) {
                this.saving = true;
                this.approveUB04 = true;
            }
            else if (type === 2) {
                this.saving = true;
                this.approveInvoiceForm();
            }
            else {
                this.saving = true;
                let body = {
                    isApproved: true
                }
                this.claimsService.approveClaim(this.claimId, body, '837I')
                    .pipe(finalize(() => this.saving = false))
                    .subscribe((res: any) => {
                        if (res) {
                            this.isClaimApproved = 'true';
                            this.location.replaceState(`/outgoing-claims/${this.claimId}/edi/true`);
                            this._toastr.displaySuccess(SUBMITTED);
                        }
                    }, (error) => {
                        console.log('Error approving', error);
                        this._toastr.showError(error);
                    })
            }
        }
        this.approveConfirmationModal.hide();
    }

    valueChanged(event) {
        this.defaultSelected = event;
        if (event == 2 && !this.invoicePdf)
            this.getClaimInvoice()
    }

    getClaimInvoice() {
        this.loading.invoice = true;
        this.claimsService.getClaimInvoice(this.claimId)
            .pipe(finalize(() => this.loading.invoice = false))
            .subscribe((res: any) => {
                if (res) {
                    this.invoicePdf = res;
                    this.totalAllowedAmount = parseFloat(this.invoicePdf.serviceLines[0].allowedAmount).toFixed(2);
                }
            }, (error) => {
                console.log('Error getting remittance summrary', error);
                this._toastr.showError(`${error.error.message}`);
            })
    }

    receiveApproveFlagResetEvent(event) {
        this.approveUB04 = event.approveUB04;
        this.saving = event.approveUB04;
        if (event.approved) {
            this.isClaimApproved = 'true';
            this.location.replaceState(`/outgoing-claims/${this.claimId}/edi/true`);
        }
    }

    /**
     * resets pdf download flag once the download is completed
     * @param event 
     */
    receiveEventOfDownloadedPDF(event) {
        if (event.isCompleted) {
            setTimeout(() => {
                this.downloadUB04FormOrInvoicePDF = event.downloadUB04FormOrInvoicePDF;
            }, 1);
        }
    }

    onSendEmail() {
        this.showMessageModal = true;
    }

    closeMessageModal() {
        this.showMessageModal = false;
    }

    getInvoiceCoverSheet(event) {
        this.showInvoiceCoverSheet = event;
    }

    generateProcedureCodeWithModifier(institutionalService) {
        return `${institutionalService?.procedureCode} ${institutionalService?.modifier1} ${institutionalService?.modifier2} ${institutionalService?.modifier3} ${institutionalService?.modifier4}`
    }
}
