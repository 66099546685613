import * as moment from "moment";

export const SUCCESS = 'success';
export const DANGER = 'danger';

export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 10;

export const DUPLICATE_STATUS = 409;

export const activeClass = (isActive) => {
    if (isActive) {
        return SUCCESS;
    }

    return DANGER;
};

export const MMDDYYYY = 'MM/DD/YYYY'

export const payeeDateFormat = (date, format) => {
    if (date) {
        return moment(date).format(format);
    }

    return null;
}

