import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class FundingSourceService {

    constructor(
        private http: HttpClientService
    ) { }

    getAll(query?) {
        return this.http.get(`${APICONSTANTS.FUNDING_SOURCE}`, query)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    create(fundingSrc) {
        return this.http.post(`${APICONSTANTS.FUNDING_SOURCE}`, fundingSrc)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    update(fundingSrc) {
        return this.http.put(`${APICONSTANTS.FUNDING_SOURCE}/${fundingSrc.code}`, fundingSrc)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    delete(id, paramType) {
        return this.http.remove(`${APICONSTANTS.FUNDING_SOURCE}/${id}?paramType=${paramType}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getFundingSourceByPurchaserCode(code, query?) {
        return this.http.get(`${APICONSTANTS.FUNDING_SOURCE}/${code}`, query)
            .pipe(map((res: any) => {
                return res
            }));
    }
}