<div mdbModal #providerDetailModal="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard:false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header z-depth-0 border-bottom">
        <h5 class="modal-title">{{type | titlecase}} Information</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeProviderDetail()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body information-modal">
        <app-loader [loader]="loading"></app-loader>
        <div *ngIf="!loading">
          <div class="field-group">
            <label class="p-0 font-weight-500" for="">Name </label>
            <div class="field-value">{{providerData?.displayName}}</div>
          </div>
          <div class="field-group">
            <label class="p-0 font-weight-500" for="">Email </label>
            <div class="field-value">{{providerData?.email ? providerData.email :'N/A'}}</div>
          </div>
          <div class="field-group">
            <label class="p-0 font-weight-500" for="">Phone </label>
            <div class="field-value">
              {{providerData?.phone|formatPhoneNumber}}{{!providerData?.phone?'N/A':''}}</div>
          </div>
          <div class="field-group">
            <label class="p-0 font-weight-500" for="">Address </label>
            <div class="field-value"> {{(!providerData?.practiceAddress?.addressline1 && !providerData?.practiceAddress?.addressline2 &&
              !providerData?.practiceAddress?.city && !providerData?.practiceAddress?.state && !providerData?.practiceAddress?.zip)?'N/A':''}}{{
                providerData?.practiceAddress?.addressline1 }} {{ providerData?.practiceAddress?.addressline2 }}<br>
              {{ (providerData && providerData.practiceAddress && providerData.practiceAddress.city)?providerData.practiceAddress.city:'' }} {{
                providerData?.practiceAddress?.state }} {{ providerData?.practiceAddress?.zip }}
            </div>
          </div>
        <div *ngIf="type.toUpperCase() === 'CLINIC'">
            <hr>
            <h6>Episode point of contact</h6>
            <app-loader [loader]="loadingEPOC"></app-loader>
            <div *ngIf="episodePOC.length > 0 && (episodePOC[0]?.primaryFirstName || episodePOC[0]?.schedulingFirstName)"
                class="form-row"
                style="max-height: 400px;">
                <div *ngIf="episodePOC[0]?.primaryFirstName"
                    class="col col-sm-6">
                    <div class="alert bg-blue-light rounded-sm d-flex align-items-center">
                        <span class="font-weight-500 text-dark">
                            <p class="mb-0 border-bottom">
                                {{episodePOC[0]?.primaryFirstName}}
                                {{episodePOC[0]?.primaryLastName}}
                            </p>
                            <small><i class="icofont icofont-email"></i> {{episodePOC[0]?.primaryEmail ? episodePOC[0]?.primaryEmail : 'N/A'}}</small><br>
                            <small><i class="icofont icofont-phone"></i> {{episodePOC[0]?.primaryPhone ? (episodePOC[0]?.primaryPhone|formatPhoneNumber) : 'N/A'}}</small>

                        </span>
                    </div>
                </div>
                <div *ngIf="episodePOC[0]?.schedulingFirstName"
                    class="col col-sm-6">
                    <div class="alert bg-blue-light rounded-sm d-flex align-items-center">
                        <span class="font-weight-500 text-dark">
                            <p class="mb-0 border-bottom">
                                {{episodePOC[0]?.schedulingFirstName}}
                                {{episodePOC[0]?.schedulingLastName}}
                            </p>
                            <small><i class="icofont icofont-email"></i> {{episodePOC[0]?.schedulingEmail ? episodePOC[0]?.schedulingEmail : 'N/A'}}</small><br>
                            <small><i class="icofont icofont-phone"></i> {{episodePOC[0]?.schedulingPhone ? (episodePOC[0]?.schedulingPhone|formatPhoneNumber) : 'N/A'}}</small>
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="!loadingEPOC && episodePOC.length == 0 || (episodePOC.length > 0 && !episodePOC[0]?.schedulingFirstName && !episodePOC[0]?.primaryFirstName)">
                <em> No episode point of contact person available.</em>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>