export const INBOX = 'inbox';
export const OUTBOX = 'outbox';
export const RE = 'Re: ';
export const OTP_LENGTH = 6;
export const COPYRIGHT_TEXT = 'Holista Health. All rights reserved.';
export const HOLISTA_COMMUNICATION_DESC = 'The information contained in this transmission is privileged and confidential and/or Protected Health Information (PHI) and may be subject to protection under the law, including the Health Insurance Portability and Accountability Act of 1996, as amended (HIPAA).'
export const OTP_VERIFICATION_DESC = 'A one-time-password (OTP) will be generated and sent to your associated email which will allow you to view the message.';
export const EXISTING_MEMBER_DESC = 'If you are already a Holista Health member, activate or login to your account to view your message and message history.';
export const EI_TO_CLIENT = 'EI_TO_CLIENT';
export const CLAIMS_INQUIRY_TO_PROVIDER = 'CLAIMS_INQUIRY_TO_PROVIDER';
export const NOTE_TYPE = {
    COMMUNICATIONS: 'communications'
};
export const USER_DATA = [{
    key: 'userName',
    title: 'User Name'
},
{
    key: 'userContactInfo',
    title: 'Contact Info'
}
];
export const MESSAGE_STATUS = {
    SENT: 'sent',
    PROCESSED: 'processed',
    DROPPED: 'dropped',
    DELIVERED: 'delivered',
    DEFERRED: 'deferred',
    BOUNCE: 'bounce',
    BLOCKED: 'blocked',
}