import { Component, OnInit, Output, Input, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ToasterService, UserService } from 'src/app/services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-patient-demographic',
  templateUrl: './patient-demographic.component.html',
  styleUrls: ['./patient-demographic.component.scss']
})

export class PatientDemographicComponent implements OnInit {

  patientDemographic: any;
  patientId: any;
  loading = false;
  @Output() hidePatientDemographic = new EventEmitter<any>();
  @Input() set userId(data) {
    if (data) {
      this.patientId = data;
      this.ViewPatientDemographic(this.patientId);
    }
  }
  @ViewChild('patientDemographicModal', { static: true }) public patientDemographics;

  constructor(private userService: UserService,
    private toastr: ToasterService
  ) { }

  ngOnInit() {
  }

  ViewPatientDemographic(userId) {
    this.loading = true;
    this.patientDemographics.show();
    this.userService.getPatientDemographics(userId)
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe(res => {
        this.patientDemographic = res.data;
      }, error => {
        this.toastr.showError(error);
        console.log("Error getting patient demographic info", error);
      })
  }

  closePatientDemographic() {
    this.patientDemographics.hide();
    this.hidePatientDemographic.emit(null)
    this.patientDemographic = {};
  }
}
