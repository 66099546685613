import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Store } from '@ngrx/store';
import { ClaimsService, EpisodeService, MemberService, ToasterService, UserService, PurchaserService, BundleService, BundleCostService, ReportService } from '../../services';
import * as HeaderBreadCrumbActions from '../../action';
import { finalize, startWith, switchMap, debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import * as Constants from '../../constants'
// import { ACCESS_DENIED, MessageConstants, ClaimsConstants, CLAIMS_ASSOCIATE_SUCCESS, DELETE_SUCCESS, ERROR_DELETE_CLAIMS, FR_REJECTED_CANCELED, NO_ELIGIBLE_EPISODE, NO_PATIENT_FOUND, UPDATE_SUCCESS, CHANGE_STATUS_WARNING, CLAIM_AUTOMATION_WARNING, EXPENSE_ALLOCATIONS, POLICY_CODES, ADMINISTRATIVE_FEE, CLIENT_FEE, FETCH_BUNDLE_COST_FAILED, NO_BUNDLES_FOUND, CLAIMS_REPORT_DATA, CLIENTS_WITH_OPTIONAL_BUNDLE_COMPONENT_SELECTION, PURCHASER_CODES, CLAIMS_PROCESSING_STATUS, ROLE_CODES, PAYMENT_STATUS, EDI_GENERATED_DENIED_CLAIMS_CREATED_PRIOR_TO_DATE, POSSIBLE_INCOMING_CLAIM_STATUSES, SHOW_ELLIPSIS_STATUSES, HIDE_ASSOCIATE_EPISODE_STATUSES } from '../../constants'
import { Storage, AccessType, TitleCase, ReportUtility, CurrencyFormat } from '../../utils';
import * as moment from 'moment';
import { ExportReportParams, CommunicationModuleOptions } from '../../models';
@Component({
    selector: "app-claims",
    templateUrl: "./claims.component.html",
    styleUrls: ['./claims.component.scss'],
})

export class ClaimsComponent implements OnInit, OnDestroy {
    searchModelChanged: Subject<string> = new Subject<string>();
    @ViewChild('advanceSearchModal', { static: true }) public advanceSearchModal;
    @ViewChild('associateEpisodeModal', { static: true }) public associateEpisodeModal;
    @ViewChild('reasonModal', { static: true }) public reasonModal;
    @ViewChild('confirmationModal', { static: true }) public confirmationModal;
    user: any;
    associateForm: FormGroup;
    filterForm: FormGroup;
    statusForm: NgForm;
    loading = {
        claims: false,
        claimsDetail: false,
        episode: false,
        saving: false,
        patient: false,
        statusChanged: false,
        bundle: false,
        bundleComponent: false,
        exportingClaims: false
    };
    submitted: boolean = false;
    searchKeyword = '';
    claimsList: any[] = [];
    backupClaimsList: any[] = [];
    filterList = [];
    page = 1;
    limit = 10;
    totalCount = 0;
    claimType = "INCOMING";
    sortBy: string;
    sortOrder: string = 'DESC';
    reverse: boolean = true;
    episodeDropdownList: any[] = [];
    bundleList: any[] = [];
    submitterSearchText = new Subject();
    submitterResults: Observable<any>;
    patientSearchText = new Subject();
    patientResults: Observable<any>;
    episodeSearchText = new Subject();
    episodeResults: Observable<any>;
    payerSearchText = new Subject();
    payerResults: Observable<any>;
    clientSearchText = new Subject();
    clientResults: Observable<any>;
    purchaserSearchText = new Subject();
    purchaserResults: Observable<any>;
    associatePatientSearchText = new Subject();
    associatePatientResults: Observable<any>;
    billingProviderSearchText = new Subject();
    billingProviderResults: Observable<any>;
    renderingProviderSearchText = new Subject();
    renderingProviderResults: Observable<any>;
    memberPatients: any = [];
    backupMemberPatients: any = [];
    bundleComponentList: any[] = [];
    patientId = null;
    selectedClaim: any = {};
    statusModel: statusModel = new statusModel();
    claim = null;
    patient: any = {};
    hasSameFundingRequest: boolean = true;
    message: string = null;
    processingStatus = Constants.ClaimsConstants.processingStatus;
    CLAIMS_PROCESSING_STATUS = Constants.CLAIMS_PROCESSING_STATUS;
    ROLE_CODES = Constants.ROLE_CODES;
    PAYMENT_STATUS = Constants.PAYMENT_STATUS;
    // status = ClaimsConstants.status;
    ediType = Constants.ClaimsConstants.ediType;
    disableSave: boolean = false;
    claimId: any;
    deleteConfitmationMessage: string;
    tempPatient: any[] = [];
    tempSubmiter: any[] = [];
    tempBillingProvider: any[] = [];
    tempRenderingProvider: any[] = [];
    tempClient: any[] = [];
    tempEpisode: any[] = [];
    tempPayer: any[] = [];
    tempPurchaser: any[] = [];
    exceptionCodes: any[] = [];
    modalWarningMessage: string;
    showDropdown: boolean = true;
    isAutomationModal: boolean = false;
    processingClaimIndex: number;
    selectedClaimForAutomation: object;
    claimIdForAutomation: number;
    episode: any = {};
    setNewbornChecked: boolean = false;
    showMessageModal: boolean = false;
    metaVariablesDetail: any;
    moduleOptions: CommunicationModuleOptions;
    POLICY_CODES: string[] = Constants.POLICY_CODES;
    selectedBundle: any = {};
    policyCode: string;
    showValidationError: boolean = false;
    reportModulePermission: string;
    formControls: any = {
        bundleComponentUuid: { isRequired: true }
    }

    constructor(private store: Store<{ bread_crumbs: any }>,
        private claimsService: ClaimsService,
        private router: Router,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private memberService: MemberService,
        private _episodeService: EpisodeService,
        private _storage: Storage,
        private utilityAccess: AccessType,
        private _messageConstants: Constants.MessageConstants,
        private _purchaserService: PurchaserService,
        private _toastr: ToasterService,
        private _bundleService: BundleService,
        private _bundleCostService: BundleCostService,
        private _tilteCase: TitleCase,
        private _reportUtility: ReportUtility,
        private _reportService: ReportService,
        private _currencyFormat: CurrencyFormat
    ) {
        this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
            this.searchKeyword = data;
            let filterData = this._storage.get('session', 'claimsFilterData');
            if (filterData) {
                filterData.keyword = data;
                this._storage.set('session', 'claimsFilterData', filterData)
            }
            this.filterForm.controls.keyword.setValue(data.trim());
            this.page = 1
            this.filterForm.controls.page.setValue(1);
            this.searchClaims(this.filterForm.value);
        });

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (!event.url.startsWith('/claims') && this._storage.get('session', 'claimsFilterData')) {
                    let filterData = this._storage.get('session', 'claimsFilterData');
                    filterData.keyword = '';
                    filterData.page = 1;
                    this._storage.set('session', 'claimsFilterData', filterData);
                }
            }
        });
        this.setFilterForm();
        this.setAssociateForm();
    }

    ngOnInit() {
        this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
        this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Claims', path: '/claims' }));
        this.user = this._storage.get('local', 'loggedInUser', 'user');
        this.reportModulePermission = this.user.rolePermissions.find(rolePermission => rolePermission.permissionCode === 'RPM')?.accessType;
        this.submitterResults = this.submitterSearchText
            .pipe(
                startWith(this.submitterSearchText),
                switchMap((submitterSearchText: string) => this.searchSubmitter(submitterSearchText))
            );
        this.patientResults = this.patientSearchText
            .pipe(
                startWith(this.patientSearchText),
                switchMap((patientSearchText: string) => this.searchPatient(patientSearchText))
            );
        this.episodeResults = this.episodeSearchText
            .pipe(
                startWith(this.episodeSearchText),
                switchMap((episodeSearchText: string) => this.searchEpisode(episodeSearchText))
            );
        this.payerResults = this.payerSearchText
            .pipe(
                startWith(this.payerSearchText),
                switchMap((payerSearchText: string) => this.searchPayer(payerSearchText))
            );
        this.clientResults = this.clientSearchText
            .pipe(
                startWith(this.clientSearchText),
                switchMap((clientSearchText: string) => this.searchClient(clientSearchText))
            );
        this.purchaserResults = this.purchaserSearchText
            .pipe(
                startWith(this.purchaserSearchText),
                switchMap((purchaserSearchText: string) => this.searchPurchaser(purchaserSearchText))
            );
        this.associatePatientResults = this.associatePatientSearchText
            .pipe(
                startWith(this.associatePatientSearchText),
                switchMap((associatePatientSearchText: string) => this.searchAssociatePatient(associatePatientSearchText))
            );
        this.billingProviderResults = this.billingProviderSearchText
            .pipe(
                startWith(this.billingProviderSearchText),
                switchMap((billingProviderSearchText: string) => this.searchBillingProvider(billingProviderSearchText))
            );
        this.renderingProviderResults = this.renderingProviderSearchText
            .pipe(
                startWith(this.renderingProviderSearchText),
                switchMap((renderingProviderSearchText: string) => this.searchRenderingProvider(renderingProviderSearchText))
            );
        const claimIdentifier = this._storage.get('session', 'searchClaim');
        if (claimIdentifier) {
            this.searchModelChanged.next(claimIdentifier);
        }
        this.getUsers();
        this.getExceptionCodes();
        let filterData = this._storage.get('session', 'claimsFilterData');
        if (filterData) {
            this.getFilterList(filterData)
            if (filterData.keyword && filterData.keyword.length > 0)
                this.searchKeyword = filterData.keyword;
            this.filterForm.patchValue(filterData);
            this.filterClaims();
        } else if (!claimIdentifier)
            this.searchClaims(this.filterForm.value);

    }

    ngOnDestroy() {
        this._storage.remove('session', 'searchClaim');
    }

    getFilterList(filterData) {
        if (filterData.patientId) {
            this.tempPatient = this._storage.get('session', 'claimsFilteredPatient');
            let patientName = this.tempPatient.find(x => x.value == filterData.patientId).label;
            this.patientSearchText.next(this.toTitleCase(patientName));
        }
        if (filterData.submitterId) {
            this.tempSubmiter = this._storage.get('session', 'claimsFilteredSubmitter');
            let submitterName = this.tempSubmiter.find(x => x.value == filterData.submitterId).label;
            this.submitterSearchText.next(this.toTitleCase(submitterName));
        }
        if (filterData.billingProviderId) {
            this.tempBillingProvider = this._storage.get('session', 'claimsFilteredBillingProvider');
            let billingProviderName = this.tempBillingProvider.find(x => x.value == filterData.billingProviderId).label;
            this.billingProviderSearchText.next(this.toTitleCase(billingProviderName));
        }
        if (filterData.renderingProviderId) {
            this.tempRenderingProvider = this._storage.get('session', 'claimsFilteredRenderingProvider');
            let renderingProviderName = this.tempRenderingProvider.find(x => x.value == filterData.renderingProviderId).label;
            this.renderingProviderSearchText.next(this.toTitleCase(renderingProviderName));
        }
        if (filterData.episodeName) {
            this.tempEpisode = this._storage.get('session', 'claimsFilteredEpisode');
            let episodeName = this.tempEpisode.find(x => x.value == filterData.episodeName).label;
            this.episodeSearchText.next(this.toTitleCase(episodeName));
        }
        if (filterData.payerCode) {
            this.tempPayer = this._storage.get('session', 'claimsFilteredPayer');
            let payerName = this.tempPayer.find(x => x.value == filterData.payerCode).label;
            this.payerSearchText.next(this.toTitleCase(payerName));
        }
        if (filterData.clientCode) {
            this.tempClient = this._storage.get('session', 'claimsFilteredClient');
            let clientName = this.tempClient.find(x => x.value == filterData.clientCode).label;
            this.clientSearchText.next(this.toTitleCase(clientName));
        }
        if (filterData.purchaserCode) {
            this.tempPurchaser = this._storage.get('session', 'claimsFilteredPurchaser');
            let purchaserName = this.tempPurchaser.find(x => x.value == filterData.purchaserCode).label;
            this.purchaserSearchText.next(this.toTitleCase(purchaserName));
        }
    }

    setOrder(value: string) {
        if (this.sortBy === value) {
            this.reverse = !this.reverse;
            this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
        }
        else {
            this.reverse = true;
            this.sortOrder = "DESC";
        }
        this.sortBy = value;
        let filter_data = this._storage.get('session', 'claimsFilterData');
        if (filter_data) {
            filter_data.sortOrder = this.sortOrder;
            filter_data.sortBy = this.sortBy;
            this._storage.set('session', 'claimsFilterData', filter_data)
        }
        this.filterForm.controls.sortOrder.setValue(this.sortOrder);
        this.filterForm.controls.sortBy.setValue(this.sortBy);
        this.searchClaims(this.filterForm.value);
    }

    filterStatus(claim: any) {
        let validStatus: any;
        const claimStatus = claim.claimXRefHolista.processingStatus.toUpperCase()
        switch (claimStatus) {
            case Constants.CLAIMS_PROCESSING_STATUS.IN_REVIEW:
                validStatus = Constants.POSSIBLE_INCOMING_CLAIM_STATUSES[claimStatus]
                if ((claim.claimXRefHolista.bundleUuid)) {
                    if (claim.claimXRefHolista.claimComponents.length) {
                        validStatus = this.filterProcessingStatus(validStatus.ASSOCIATED)
                    } else {
                        validStatus = this.filterProcessingStatus(validStatus.PARTIALLY_ASSOCIATED)
                    }
                }
                else {
                    validStatus = this.filterProcessingStatus(validStatus.UN_ASSOCIATED)
                }

                break;

            case Constants.CLAIMS_PROCESSING_STATUS.ADJUSTMENT:
                validStatus = this.filterProcessingStatus([claim.claimXRefHolista.previousProcessingStatus])
                break;

            default:
                validStatus = this.filterProcessingStatus(Constants.POSSIBLE_INCOMING_CLAIM_STATUSES[claimStatus])
                break;
        }

        return validStatus;
    }

    filterProcessingStatus(statusArray: Array<string>) {
        return this.processingStatus.filter(status => statusArray.includes(status.value))
    }

    setFilterForm() {
        this.filterForm = this.formBuilder.group({
            patientId: [null],
            episodeName: [null],
            payerCode: [null],
            clientCode: [null],
            purchaserCode: [null],
            submitterId: [null],
            billingProviderId: [null],
            renderingProviderId: [null],
            subscriberNumber: '',
            processingStatus: [null],
            status: [null],
            ediType: [null],
            page: 1,
            keyword: '',
            sortBy: '',
            sortOrder: ''
        });
    }

    setAssociateForm() {
        this.associateForm = this.formBuilder.group({
            ediTransactionId: [null],
            patientId: [null, Validators.required],
            patientName: [null],
            episodeId: [null, Validators.required],
            episodeName: [null],
            bundleUuid: [null, Validators.required],
            bundleName: [null],
            bundleDisplayName: [null],
            bundleComponentUuid: [null, Validators.required],
            clientCode: [null, Validators.required],
            clientName: [null],
            purchaserCode: [null, Validators.required],
            purchaserName: [null],
            payerCode: [null],
            payerName: [null],
            isNewBorn: [null]
        })
    }

    getClassName(status) {
        switch (status) {
            case 'in-review':
                return 'preliminary'
            case 'queue':
            case 'queue-hold':
            case 'adjustment':
                return 'queue'
            case 'fund-request-in-process':
            case 'fund-requested':
                return 'requested'
            case 'payment-in-process':
            case 'ready-to-pay':
                return 'confirmed'
            case 'paid':
                return 'completed'
            case 'denied':
                return 'closed'
            case 'rejected':
                return 'due'
            case 'fund-request-cancelled':
                return 'fundReqCancelled'
        }
    }

    statusChanged(event, claim) {
        this.isAutomationModal = false;
        this.showDropdown = false;
        if (this.utilityAccess.searchAccess('CLM', 'isEditable')) {
            this.statusModel = new statusModel();
            this.statusModel.status = event.value;
            this.selectedClaim = claim;
            if (event.value === 'REJECTED' || event.value === 'DENIED') {
                this.reasonModal.show();
            }
            else if (event.value === 'IN-REVIEW') {
                const message = this._messageConstants.getMessage(Constants.CHANGE_STATUS_WARNING);
                this.modalWarningMessage = message.value;
                this.confirmationModal.show();
            }
            else {
                this.loading.statusChanged = true;
                let status = { status: event.value };
                this.updateStatus(claim, status);
            }
        }
        else {
            this._toastr.displayWarning(Constants.ACCESS_DENIED);
        }
    }

    updateStatus(claim, statusBody, isSaved = false) {
        let stausForError = this.backupClaimsList.find(x => x.id === claim.id).claimXRefHolista.processingStatus;
        this.loading.saving = true;
        this.claimsService.updateStatus(claim.id, statusBody)
            .pipe(finalize(() => {
                this.loading.saving = false
            }))
            .subscribe((res: any) => {
                if (res) {
                    if (statusBody.status === 'QUEUE') {
                        if (claim.claimXRefHolista.processingStatus.toLowerCase() === 'fund-request-cancelled' && res.claimXRefHolista.processingStatus.toLowerCase() === 'fund-request-cancelled')
                            this._toastr.displayWarning(Constants.FR_REJECTED_CANCELED);
                        else
                            !isSaved && this._toastr.displaySuccess(Constants.UPDATE_SUCCESS);
                        this.searchClaims(this.filterForm.value);
                    }
                    else {
                        if (res.renderingProvider)
                            res.renderingProvider.name = res.renderingProvider.entityTypeQualifier === '1' ? res.renderingProvider.firstName + ' ' + res.renderingProvider.lastName : res.renderingProvider.businessName;
                        res['processingStatus'] = this.filterStatus(res);
                        res['claimStatusClass'] = this.getClassName(res.claimXRefHolista.processingStatus.toLowerCase());
                        let index = this.claimsList.findIndex(x => x.id === res.id)
                        if (index > -1)
                            this.claimsList[index] = JSON.parse(JSON.stringify(res));
                        index = this.backupClaimsList.findIndex(x => x.id === res.id)
                        if (index > -1)
                            this.backupClaimsList[index] = JSON.parse(JSON.stringify(res));
                        !isSaved && this._toastr.displaySuccess(Constants.UPDATE_SUCCESS);
                        this.loading.claims = false;
                    }
                }
                this.loading.statusChanged = false;
            }, (error) => {
                let index = this.claimsList.findIndex(x => x.id === claim.id);
                if (index > -1)
                    this.claimsList[index].claimXRefHolista.processingStatus = stausForError;
                console.log('Error changing status', error);
                !isSaved && this._toastr.displayError(`${error.error.message}`);
                this.loading.claims = false;
                this.loading.statusChanged = false;
            }, () => {
                (statusBody.status === 'REJECTED' || statusBody.status === 'DENIED' || statusBody.status === 'QUEUE' || statusBody.status === 'QUEUE-HOLD') ? this.closeReasonModal(null) : this.closeConfirmationModal();
            })
    }

    searchByKeyword(event) {
        this.searchModelChanged.next(event);
    }

    pageChanged(event) {
        this.page = event;
        this.filterForm.controls.page.setValue(event);
        this.searchClaims(this.filterForm.value);
    }

    getClaimsDetail(id) {
        this._storage.set('session', 'claimsFilterData', this.filterForm.value);
        this.router.navigate(['claims', id]);
    }

    openAdvanceSearch() {
        const filterData = this._storage.get('session', 'claimsFilterData');
        if (filterData) {
            this.getFilterList(filterData);
            this.filterForm.reset();
            this.filterForm.patchValue(filterData);
        }
        this.advanceSearchModal.show();
    }

    closeAdvanceSearchModal(action) {
        if (action == 'close') {
            this.patientSearchText.next('');
            this.submitterSearchText.next('');
            this.billingProviderSearchText.next('');
            this.renderingProviderSearchText.next('');
            this.episodeSearchText.next('');
            this.payerSearchText.next('');
            this.clientSearchText.next('');
            this.purchaserSearchText.next('');
            !this.filterList.length && this.filterForm.reset();
        }
        this.advanceSearchModal.hide();
    }

    //Advance Search
    searchSubmitter(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchSubmitter(searchText)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displaySubmitter = (submitter?): string | undefined => {
        return submitter ? this.toTitleCase(submitter.displayName) : undefined;
    };

    searchPatient(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchPatient(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayPatient = (patient?): string | undefined => {
        return patient ? this.toTitleCase(patient.displayName) : undefined;
    };

    searchEpisode(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchEpisode(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayEpisode = (episode?): string | undefined => {
        return episode ? this.toTitleCase(episode.episodeName) : undefined;
    };

    searchPayer(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchPayer(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayPayer = (payer?): string | undefined => {
        return payer ? this.toTitleCase(payer.payerName) : undefined;
    };

    searchClient(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchClient(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayClient = (client?): string | undefined => {
        return client ? this.toTitleCase(client.clientName) : undefined;
    };

    searchPurchaser(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchPurchaser(searchText, this.claimType)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayPurchaser = (purchaser?): string | undefined => {
        return purchaser ? this.toTitleCase(purchaser.purchaserName) : undefined;
    };

    searchBillingProvider(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchBillingProvider(searchText)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayBillingInfo = (billing?): string | undefined => {
        return billing ? this.toTitleCase(billing.displayName) : undefined;
    };

    searchRenderingProvider(searchText) {
        if (searchText.length > 2) {
            return this.claimsService.searchRenderingProvider(searchText)
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        return items
                    })
                );
        } else {
            return of([]);
        }
    }

    displayRenderingProvider = (rendering?): string | undefined => {
        return rendering ? this.toTitleCase(rendering.displayName) : undefined;
    };

    toTitleCase(name) {
        return name.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    onSearchResultSelected(event, field) {
        if (field == 'billingProviderId')
            this.filterForm.controls[`${field}`].setValue(event.text && event.text.billingProvider.id ? event.text.billingProvider.id : null);
        else if (field == 'patientId')
            this.filterForm.controls[`${field}`].setValue(event.text && event.text.displayName ? event.text.displayName : null);
        else if (field == 'episodeName')
            this.filterForm.controls[`${field}`].setValue(event.text && event.text.episodeName ? event.text.episodeName : null);
        else if (field == 'clientCode')
            this.filterForm.controls[`${field}`].setValue(event.text && event.text.clientCode ? event.text.clientCode : null);
        else if (field == 'payerCode')
            this.filterForm.controls[`${field}`].setValue(event.text && event.text.payerCode ? event.text.payerCode : null);
        else if (field == 'purchaserCode')
            this.filterForm.controls[`${field}`].setValue(event.text && event.text.purchaserCode ? event.text.purchaserCode : null);
        else
            this.filterForm.controls[`${field}`].setValue(event.text && event.text.id ? event.text.id : null);
        switch (field) {
            case 'patientId':
                this.tempPatient = [];
                this.tempPatient.push({
                    label: event.text && event.text.displayName ? event.text.displayName : '',
                    value: event.text && event.text.displayName ? event.text.displayName : null
                });
                this._storage.set('session', 'claimsFilteredPatient', this.tempPatient);
                break;
            case 'submitterId':
                this.tempSubmiter = [];
                this.tempSubmiter.push({
                    label: event.text && event.text.id ? event.text.displayName : '',
                    value: event.text && event.text.id ? event.text.id : null
                });
                this._storage.set('session', 'claimsFilteredSubmitter', this.tempSubmiter);
                break;
            case 'billingProviderId':
                this.tempBillingProvider = [];
                this.tempBillingProvider.push({
                    label: event.text && event.text.id ? event.text.displayName : '',
                    value: event.text && event.text.id ? event.text.billingProvider.id : null
                });
                this._storage.set('session', 'claimsFilteredBillingProvider', this.tempBillingProvider);
                break;
            case 'renderingProviderId':
                this.tempRenderingProvider = [];
                this.tempRenderingProvider.push({
                    label: event.text && event.text.id ? event.text.displayName : '',
                    value: event.text && event.text.id ? event.text.id : null
                });
                this._storage.set('session', 'claimsFilteredRenderingProvider', this.tempRenderingProvider);
                break;
            case 'episodeName':
                this.tempEpisode = [];
                this.tempEpisode.push({
                    label: event.text && event.text.episodeName ? event.text.episodeName : '',
                    value: event.text && event.text.episodeName ? event.text.episodeName : null
                });
                this._storage.set('session', 'claimsFilteredEpisode', this.tempEpisode);
                break;
            case 'payerCode':
                this.tempPayer = [];
                this.tempPayer.push({
                    label: event.text && event.text.payerCode ? event.text.payerName : '',
                    value: event.text && event.text.payerCode ? event.text.payerCode : null
                });
                this._storage.set('session', 'claimsFilteredPayer', this.tempPayer);
                break;
            case 'clientCode':
                this.tempClient = [];
                this.tempClient.push({
                    label: event.text && event.text.clientCode ? event.text.clientName : '',
                    value: event.text && event.text.clientCode ? event.text.clientCode : null
                });
                this._storage.set('session', 'claimsFilteredClient', this.tempClient);
                break;
            case 'purchaserCode':
                this.tempPurchaser = [];
                this.tempPurchaser.push({
                    label: event.text && event.text.purchaserCode ? event.text.purchaserName : '',
                    value: event.text && event.text.purchaserCode ? event.text.purchaserCode : null
                });
                this._storage.set('session', 'claimsFilteredPurchaser', this.tempPurchaser);
                break;
        }
    }

    filterClaims(page?) {
        this.filterList.length = 0;
        let filter_data = this.filterForm.value;
        filter_data.processingStatus = this.filterForm.value.processingStatus && this.filterForm.value.processingStatus.length == 0 ? null : this.filterForm.value.processingStatus;
        this.page = page ? 1 : this.filterForm.value.page;
        filter_data.page = this.page;
        this.filterForm.controls.page.setValue(this.page);
        this._storage.set('session', 'claimsFilterData', filter_data);
        //show applied tags for episode filter
        Object.keys(filter_data).forEach(key => {
            let value = filter_data[key];
            if (value && key == 'patientId') this.updateFilterApplied('Patient', key, value, this.tempPatient);
            if (!value && key == 'patientId') this.patientSearchText.next('');
            if (value && key == 'submitterId') this.updateFilterApplied('Submitter', key, value, this.tempSubmiter);
            if (!value && key == 'submitterId') this.submitterSearchText.next('');
            if (value && key == 'billingProviderId') this.updateFilterApplied('Billing Provider', key, value, this.tempBillingProvider);
            if (!value && key == 'billingProviderId') this.billingProviderSearchText.next('');
            if (value && key == 'renderingProviderId') this.updateFilterApplied('Rendering Provider', key, value, this.tempRenderingProvider);
            if (!value && key == 'renderingProviderId') this.renderingProviderSearchText.next('');
            if (value && key == 'episodeName') this.updateFilterApplied('Episode', key, value, this.tempEpisode);
            if (!value && key == 'episodeName') this.episodeSearchText.next('');
            if (value && key == 'payerCode') this.updateFilterApplied('Payor', key, value, this.tempPayer);
            if (!value && key == 'payerCode') this.payerSearchText.next('');
            if (value && key == 'clientCode') this.updateFilterApplied('Client', key, value, this.tempClient);
            if (!value && key == 'clientCode') this.clientSearchText.next('');
            if (value && key == 'purchaserCode') this.updateFilterApplied('Purchaser', key, value, this.tempPurchaser);
            if (!value && key == 'purchaserCode') this.purchaserSearchText.next('');
            if (value && key == 'subscriberNumber') this.updateFilterApplied('Subscriber Number', key, value, null);
            if (value && key == 'processingStatus') this.updateFilterApplied('Processing Status', key, value, this.processingStatus);
            // if (value && key == 'status') this.updateFilterApplied('Status', key, value, null);
            if (value && key == 'ediType') this.updateFilterApplied('EDI Type', key, value, null);
        });
        this.searchClaims(filter_data);
        this.closeAdvanceSearchModal(null);
    }

    updateFilterApplied(field, formField, value, list) {
        if (this.filterList.length > 0) {
            let index = this.filterList.findIndex(x => x.field == field);
            if (index > -1)
                this.filterList.splice(index, 1);
        }
        if (list) {
            if (formField == 'processingStatus') {
                Object.keys(value).forEach(y => {
                    let values = value[y];
                    let valueIndex = list.findIndex(x => x.value == values);
                    if (valueIndex > -1) {
                        let index = this.filterList.findIndex(x => x.field == field && x.value == values);
                        if (index == -1) {
                            let data = { field: field, formField: formField, value: values, label: list[valueIndex].label };
                            this.filterList.push(data);
                        }
                    }
                });
            }
            else {
                let valueIndex = list.findIndex(x => x.value == value);
                if (valueIndex > -1) {
                    let data = { field: field, formField: formField, value: value, label: this.toTitleCase(list[valueIndex].label) };
                    this.filterList.push(data);
                }
            }
        }
        else {
            let data = { field: field, formField: formField, value: value, label: value };
            this.filterList.push(data);
        }
    }

    searchClaims(body) {
        this.loading.claims = true;
        this.claimsService.searchClaims(body, this.claimType)
            .pipe(finalize(() => { this.loading.claims = false; }))
            .subscribe((response: any) => {
                if (response.count != 0) {
                    this.totalCount = response.count;
                    response.rows.map(x => {
                        x['processingStatus'] = this.filterStatus(x);
                        x['claimStatusClass'] = this.getClassName(x.claimXRefHolista.processingStatus.toLowerCase());
                        if (x.claimXRefHolista.processingStatus === Constants.CLAIMS_PROCESSING_STATUS.DENIED) {
                            x.isCreatedPriorToJuneFirst = (moment(x.createdAt).format('YYYYMMDD')) < moment(Constants.EDI_GENERATED_DENIED_CLAIMS_CREATED_PRIOR_TO_DATE).format('YYYYMMDD');
                        }
                        return x;
                    })
                    this.claimsList = JSON.parse(JSON.stringify(response.rows));
                    this.backupClaimsList = JSON.parse(JSON.stringify(response.rows));
                }
                else {
                    this.claimsList = [];
                    this.backupClaimsList = [];
                }
            }, (error) => {
                console.log("Error while advance search", error);
            })
    }

    removeFilter(filter) {
        const filteredData = this._storage.get('session', 'claimsFilterData');
        this.filterForm.reset();
        this.filterForm.patchValue(filteredData);
        switch (filter.formField) {
            case 'patientId':
                this.patientSearchText.next('');
                this.filterForm.controls.patientId.setValue('');
                break;
            case 'submitterId':
                this.submitterSearchText.next('');
                this.filterForm.controls.submitterId.setValue('');
                break;
            case 'billingProviderId':
                this.billingProviderSearchText.next('');
                this.filterForm.controls.billingProviderId.setValue('');
                break;
            case 'renderingProviderId':
                this.renderingProviderSearchText.next('');
                this.filterForm.controls.renderingProviderId.setValue('');
                break;
            case 'episodeName':
                this.episodeSearchText.next('');
                this.filterForm.controls.episodeName.setValue('');
                break;
            case 'payerCode':
                this.payerSearchText.next('');
                this.filterForm.controls.payerCode.setValue('');
                break;
            case 'clientCode':
                this.clientSearchText.next('');
                this.filterForm.controls.clientCode.setValue('');
                break;
            case 'purchaserCode':
                this.purchaserSearchText.next('');
                this.filterForm.controls.purchaserCode.setValue('');
                break;
            case 'processingStatus':
                let filteredProcessingStatus = this.filterForm.get(filter.formField).value;
                let removeIndex = filteredProcessingStatus.findIndex(x => x === filter.value);

                removeIndex > -1 && filteredProcessingStatus.splice(removeIndex, 1);
                this.filterForm.get('processingStatus').reset();
                this.filterForm.controls.processingStatus.setValue(filteredProcessingStatus.length ? filteredProcessingStatus : null);
                break;
            case 'status':
            case 'ediType':
            case 'subscriberNumber':
                this.filterForm.controls[filter.formField].setValue('');
                break;
        }
        let index = this.filterList.findIndex(x => x.value == filter.value)
        if (index > -1)
            this.filterList.splice(index, 1);
        this.page = 1;
        this.filterForm.controls.page.setValue(1);
        this.searchClaims(this.filterForm.value);
        this._storage.set('session', 'claimsFilterData', this.filterForm.value);
    }

    ResetFilter() {
        this.patientSearchText.next('');
        this.submitterSearchText.next('');
        this.billingProviderSearchText.next('');
        this.renderingProviderSearchText.next('');
        this.episodeSearchText.next('');
        this.payerSearchText.next('');
        this.clientSearchText.next('');
        this.purchaserSearchText.next('');
        this.filterList = [];
        this.filterForm.reset();
        this.page = 1;
        this.filterForm.controls.keyword.setValue(this.searchKeyword);
        this.filterForm.controls.sortOrder.setValue(this.sortOrder);
        this.filterForm.controls.sortBy.setValue(this.sortBy);
        this.filterForm.controls.page.setValue(1);
        this.filterForm.controls.processingStatus.setValue(null);
        this.searchClaims(this.filterForm.value);
        this._storage.remove('session', 'claimsFilterData');
    }

    // Associtate Episode
    openAssociateModal(claim) {
        this.policyCode = claim.patient.insuredPolicyNumber.split('-')[0];
        if (this.utilityAccess.searchAccess('CLM', 'isEditable')) {
            this.associateForm.controls.ediTransactionId.setValue(claim.patient.ediTransactionId);
            this.claim = claim;
            let memberInfo = {
                dob: claim.patient.patientDob ? moment(claim.patient.patientDob).utc().format('MM-DD-YYYY') : moment(claim.patient.subscriberDob).utc().format('MM-DD-YYYY'),
                firstName: claim.patient.patientFirstName ? claim.patient.patientFirstName : claim.patient.subscriberFirstName,
                gender: claim.patient.subscriberGender,
                subscriberNumber: claim.patient.subscriberIdentificationCode,
                lastName: claim.patient.patientLastName ? claim.patient.patientLastName : claim.patient.subscriberLastName,
                // middleName: claim.patient.patientMiddleName ? claim.patient.patientMiddleName : claim.patient.subscriberMiddleName,
                // isPatientInfo: claim.patient.patientFirstName ? true : false
            }
            this.associateEpisodeModal.show();

            //for claim without episode
            if (Constants.POLICY_CODES.includes(claim.patient.insuredPolicyNumber.split('-')[0])) {
                this.loading.bundle = true;
                this.bundleList = [];
                this.claimsService.getBundlesByPolicyNumber(claim.patient.insuredPolicyNumber).subscribe((res: any) => {
                    this.bundleList = res.map(x => ({ ...x, label: x.name, value: x.uuid }))
                    if (claim.claimXRefHolista.bundleUuid) {
                        let bundleComponents = [];
                        this.claim.claimXRefHolista.claimComponents.forEach(component => {
                            bundleComponents.push(component.bundleComponentUuid);
                        });
                        this.associateForm.controls.bundleUuid.setValue(claim.claimXRefHolista.bundleUuid);
                        this.associateForm.controls.bundleComponentUuid.setValue(bundleComponents);
                        this.associateForm.controls.episodeId.setValue(claim.claimXRefHolista.episodeId);
                        this._bundleService.getBundleById(claim.claimXRefHolista.bundleUuid).then((res: any) => {
                            this.onBundleSelected(claim, res);
                        });
                    }
                    this.loading.bundle = false;
                }, (error) => {
                    this._toastr.displayError(Constants.NO_BUNDLES_FOUND);
                    this.loading.bundle = false;
                });
            }

            // for claim already associated with episode
            else if (claim.claimXRefHolista.patientId) {
                this.claimsService.getClaimsDetail(claim.id, true).subscribe(res => {
                    this.associateForm.controls.isNewBorn.setValue(res.isNewBorn);
                })
                this.patientId = claim.claimXRefHolista.patientId;
                let patient = this.memberPatients.find(x => x.id == this.patientId);
                this.associatePatientSearchText.next(claim.claimXRefHolista.patientName);
                let patientName = claim.claimXRefHolista.patientName.split(' ');
                patient.text = {
                    firstName: patientName[0],
                    lastName: patientName[1],
                    id: claim.claimXRefHolista.patientId,
                    memberUuid: patient.memberUuid,
                    clientCode: claim.claimXRefHolista.clientCode,
                    clientName: claim.claimXRefHolista.clientName,
                    purchaserCode: claim.claimXRefHolista.purchaserCode,
                    purchaserName: claim.claimXRefHolista.purchaserName,
                    payerCode: claim.claimXRefHolista.payerCode,
                    payerName: claim.claimXRefHolista.payerName,
                    payerIdentifier: claim.claimXRefHolista.payerIdentifier,
                }
                this.patientSelected(patient);
            }

            // associating claim for the first time
            else {
                this.loading.patient = true;
                this.memberService.searchMembers(memberInfo)
                    .pipe(finalize(() => this.loading.patient = false))
                    .subscribe((res: any) => {
                        if (res.count > 0) {
                            let user = this.memberPatients.find(m => m.memberUuid == res.rows[0].uuid)
                            if (user) {
                                user.value = user.id;
                                user.label = user.firstName + ' ' + user.lastName;
                                this.patientId = user.id;
                                this.associatePatientSearchText.next(user.label);
                                user.text = {
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    id: user.id,
                                    memberUuid: user.memberUuid,
                                    purchaserCode: res.rows[0].purchaserCode,
                                    purchaserName: res.rows[0].purchaserName
                                }
                                this.patientSelected(user);
                            }
                            else this._toastr.displayWarning(Constants.NO_PATIENT_FOUND);
                        }
                        else {
                            this._toastr.displayWarning(Constants.NO_PATIENT_FOUND);
                        }
                    }, (error) => {
                        this._toastr.showError(error)
                    })
            }
        }
        else this._toastr.displayWarning(Constants.ACCESS_DENIED);
    }

    onPatientSelect(event) {
        event.text.purchaserCode = event.text.referenceCode;
        !event.text.purchaserName && this._purchaserService.getById(event.text.referenceCode).subscribe(res => {
            res && (
                event.text.purchaserName = res.name
            );
            this.patientSelected(event, true);
        });
    }

    closeAssociateModal() {
        this.setNewbornChecked = false;
        this.associatePatientSearchText.next('');
        this.associateEpisodeModal.hide();
        this.associateForm.reset();
        this.episodeDropdownList = [];
        this.bundleList = [];
        this.bundleComponentList = [];
        this.claim = null;
        this.submitted = false;
        this.patientId = null;
        this.patient = null;
        this.episode = null;
        this.hasSameFundingRequest = true;
        this.message = null;
        this.disableSave = false;
        this.associateForm.controls.clientCode.enable();
        this.associateForm.controls.purchaserCode.enable();
        this.associateForm.controls.patientId.enable();
        this.setConditionalRequiredField('bundleComponentUuid');
        this.policyCode = null;
    }

    validateClaimAssociation(claim) {
        this.submitted = true;
        this.showValidationError = true;
        if (Constants.POLICY_CODES.includes(claim.patient.insuredPolicyNumber.split('-')[0])) {
            this.associateForm.controls.patientId.disable();
        }
        if (this.associateForm.valid) {
            this.submitAssociate();
            // this.loading.saving = true;
            // const selectedBundleComponents = this.associateForm.get('episodeBundleComponentUuid').value;
            // let body = {
            //     memberUuid: '',
            //     episodeId: '',
            //     components: []
            // }
            // this.bundleComponentList.forEach((component, i) => {
            //     let index = selectedBundleComponents.findIndex(x => x == component.uuid)
            //     if (index > -1)
            //         body.components.push({ cptCodes: this.bundleComponentList[i].cptCode ? this.bundleComponentList[i].cptCode.split(',') : [], revenueCodes: this.bundleComponentList[i].revenueCode ? this.bundleComponentList[i].revenueCode.split(',') : [] })
            // });
            // body.memberUuid = this.patient.memberUuid;
            // body.episodeId = this.episode.id;
            // this.claimsService.validateClaimAssociation(this.claim.id, body)
            //     .subscribe((response: any) => {
            //         if (response) {
            //             if (response.subscriberNumber && response.patientDob && response.serviceDate && response.revenueCode && response.cptCode)
            //                 this.submitAssociate();
            //             else {
            //                 this.loading.saving = false;
            //                 this.disableSave = true;
            //                 this.message = `${!response.subscriberNumber ? 'Subscriber number, ' : ''} ${!response.patientDob ? 'Patient DOB, ' : ''}${!response.revenueCode ? 'Revenue Code, ' : ''}${!response.cptCode ? 'CPT Code, ' : ''}${!response.serviceDate ? 'Service Date ' : ''} did not match. You cannot continue anymore.`
            //             }
            //         }
            //     }, (error) => {
            //         console.log('Error validating', error);
            //         this._toastr.displayError(`${error.error.message}`);
            //     })
        }
    }

    submitAssociate() {
        this.loading.saving = true;
        const holistaFeeComponents = this.selectedBundle.components.filter(component => component.expenseAllocation.toLowerCase() === Constants.ADMINISTRATIVE_FEE);
        const clientFeeComponents = this.selectedBundle.components.filter(component => component.expenseAllocation.toLowerCase() === Constants.CLIENT_FEE);
        const selectedBundleComponentUuids = this.associateForm.get('bundleComponentUuid').value;
        const selectedBundleComponent = this.bundleComponentList.find(x => x.uuid === selectedBundleComponentUuids[0]);
        let clientFee = 0;
        if (selectedBundleComponent && clientFeeComponents.length) {
            clientFeeComponents.forEach(component => {
                component.fundingRequestUuid === selectedBundleComponent.fundingRequestUuid && (clientFee += +component.cost);
            });
        };
        let holistaFee = 0;
        if (selectedBundleComponent && holistaFeeComponents.length) {
            holistaFeeComponents.forEach(component => {
                component.fundingRequestUuid === selectedBundleComponent.fundingRequestUuid && (holistaFee += +component.cost);
            });
        };
        const patientName = this._tilteCase.toTitleCase(this.claim.patient.displayName);
        const payloadForNoEpisodeClaim = {
            policyCode: this.policyCode,
            subscriberIdentificationCode: this.claim.patient.subscriberIdentificationCode,
            serviceDate: this.claim.serviceLines.length && this.claim.serviceLines[0].serviceDate ? this.claim.serviceLines[0].serviceDate : this.claim.statementDate,
            marketCode: this.selectedBundle.bundleCost && this.selectedBundle.bundleCost[0].marketCode,
            networkCode: this.selectedBundle.networkCode,
            patient: this.claim.patient,
            patientName
        };
        let associateFormValue = Constants.POLICY_CODES.includes(this.policyCode) && !this.episode ?
            { ...this.associateForm.value, ...payloadForNoEpisodeClaim } :
            Constants.POLICY_CODES.includes(this.policyCode) && patientName ?
                { ...this.associateForm.value, holistaFee: holistaFee.toFixed(2), clientFee: clientFee.toFixed(2), patientName } :
                { ...this.associateForm.value, holistaFee: holistaFee.toFixed(2), clientFee: clientFee.toFixed(2) };
        associateFormValue['components'] = [];
        this.bundleComponentList.forEach((component) => {
            if (selectedBundleComponentUuids && selectedBundleComponentUuids.find(x => x == component.uuid))
                associateFormValue.components.push({
                    bundleComponentUuid: component.uuid,
                    bundleComponentName: component.name,
                    fundingReqUuid: component.fundingRequestUuid,
                    fundingReqName: component.fundingRequest.name,
                    cost: +component.cost,
                    cptCode: component.cptCode,
                    diagnosisCode: component.diagnosisCode,
                    modifier: component.modifier,
                    revenueCode: component.revenueCode,
                    ruleCode: component.ruleCode,
                    marketCode: this.episode && this.episode.marketCode ? this.episode.marketCode : this.selectedBundle.bundleCost[0].marketCode
                })
        });
        associateFormValue.episodeName = this.associateForm.get('episodeName').value;
        this.handleClaimAssociation(associateFormValue)
    }

    handleClaimAssociation(associateFormValue: any) {
        if (this.claim.claimXRefHolista.processingStatus !== Constants.CLAIMS_PROCESSING_STATUS.ADJUSTMENT) {
            this.associateClaim(associateFormValue)
        } else {
            this.reAssociateClaim(associateFormValue)
        }
    }


    /**
     * For both associating episode and reassociating claim -> when the claim is in adjustment status
     * @param response 
     */
    handleAssociateEpisodeResponse(response: any) {
        if (response.renderingProvider) {
            response.renderingProvider.name = response.renderingProvider.entityTypeQualifier === '1' ? response.renderingProvider.firstName + ' ' + response.renderingProvider.lastName : response.renderingProvider.businessName;
        }

        response['processingStatus'] = this.filterStatus(response);
        response['claimStatusClass'] = this.getClassName(response.claimXRefHolista.processingStatus.toLowerCase());
        let index = this.claimsList.findIndex(x => x.id == response.id)
        if (index > -1) {
            this.claimsList[index] = JSON.parse(JSON.stringify(response));
        }

        index = this.backupClaimsList.findIndex(x => x.id == response.id)
        if (index > -1) {
            this.backupClaimsList[index] = JSON.parse(JSON.stringify(response));
        }
    }

    associateClaim(associateFormValue: any) {
        this.claimsService.associateEpisode(this.claim.id, associateFormValue)
            .pipe(finalize(() => this.loading.saving = false))
            .subscribe((response: any) => {
                if (response) {
                    this.handleAssociateEpisodeResponse(response)
                    const status = this.associateForm.value.bundleComponentUuid ? Constants.CLAIMS_PROCESSING_STATUS.QUEUE : Constants.CLAIMS_PROCESSING_STATUS.QUEUE_HOLD;
                    this.loading.claims = true;
                    this.updateStatus(this.claim, { status }, true);
                    this._toastr.displaySuccess(Constants.CLAIMS_ASSOCIATE_SUCCESS);
                }

            }, (error) => {
                console.log('Error associating with episode', error);
                this._toastr.displayWarning(`${error.error.message}`);
            }, () => {
                this.closeAssociateModal();
            })
    }

    reAssociateClaim(associateFormValue: any) {
        this.claimsService.reAssociateEpisode(this.claim.id, associateFormValue)
            .pipe(finalize(() => this.loading.saving = false))
            .subscribe((response: any) => {
                if (response) {
                    this.handleAssociateEpisodeResponse(response)
                    this._toastr.displaySuccess(Constants.CLAIMS_ASSOCIATE_SUCCESS);
                }

            }, (error) => {
                console.log('Error associating with episode', error);
                this._toastr.displayWarning(`${error.error.message}`);
            }, () => {
                this.closeAssociateModal();
            })
    }

    patientSelected(event, isSelectedFromDropdown = false) {
        this.episode = null;
        this.associateForm.controls.isNewBorn.setValue(false);
        this.associateForm.controls.isNewBorn.enable();
        if (this.message)
            this.message = null;
        this.disableSave = false;
        this.episodeDropdownList = [];
        this.associateForm.controls.episodeId.setValue('');
        this.bundleList = [];
        this.associateForm.controls.bundleUuid.setValue('');
        this.bundleComponentList = [];
        this.associateForm.controls.bundleComponentUuid.setValue('');
        this.hasSameFundingRequest = true;
        this.patient = event.text;
        this.loading.episode = true;
        let id = event.text.id;

        this._episodeService.getEpisodeForClaims(id)
            .pipe(finalize(() => this.loading.episode = false))
            .subscribe((response: any) => {
                if (response.length > 0) {
                    response.map(x => {
                        x.label = x.name + (x.status.toLowerCase() == 'cancelled' && x.isCancelledPriorToProcedure == false ? ' (Cancelled)' : '') + (x.tag ? ' (' + x.tag + ')' : '') + ` (${x.benefitStartDate ? moment(x.benefitStartDate).format('MM/DD/YYYY') : 'N/A'} - ${x.benefitEndDate ? moment(x.benefitEndDate).format('MM/DD/YYYY') : 'N/A'})`;
                        x.value = x.id;
                        return x;
                    })
                    this.episodeDropdownList = response;
                    this.associateForm.controls.patientId.setValue(event.text.id);
                    this.associateForm.controls.patientName.setValue(event.text.firstName + ' ' + event.text.lastName);
                    this.associateForm.controls.clientName.setValue(event.text.clientName || null);
                    this.associateForm.controls.payerCode.setValue(event.text.payerCode || null);
                    this.associateForm.controls.payerName.setValue(event.text.payerName || null);
                    this.associateForm.controls.purchaserCode.setValue(event.text.purchaserCode || null);
                    this.associateForm.controls.purchaserName.setValue(event.text.purchaserName || null);
                    // for the claim already associated with episode
                    if (this.claim && this.claim.claimXRefHolista && this.claim.claimXRefHolista.episodeId && !isSelectedFromDropdown) {
                        this.associateForm.controls.episodeId.setValue(this.claim.claimXRefHolista.episodeId);
                        this.associateForm.controls.episodeName.setValue(this.claim.claimXRefHolista.episodeName);
                        let episode = this.episodeDropdownList.find(x => x.id == this.claim.claimXRefHolista.episodeId);
                        if (episode) {
                            this.episodeSelected(episode);
                        }
                    }
                }
                else
                    this._toastr.displayWarning(Constants.NO_ELIGIBLE_EPISODE);
            }, (error) => {
                console.log('Error getting Episode for claims by patient id', error);
            })
    }

    /**
     * get bundle components related to funding requests and adding funding request to them
     * @param response 
     * @param components 
     */
    getFundingRequests(response, components) {
        response.fundingRequests.forEach((x: any) => {
            components.forEach((y: any) => {
                if (y.fundingRequestUuid === x.uuid) {
                    y.fundingRequest = x;
                }
            });
        })
    }

    async episodeSelected(episode, isClaimWithPatient = true) {
        if (isClaimWithPatient) {
            this.loading.bundle = true;
            this.loading.bundleComponent = true;
            this.episode = episode;
            this.setConditionalRequiredField('bundleComponentUuid', episode.clientCode);
            this.bundleList = [];
            this.associateForm.controls.bundleUuid.setValue('');
            this.associateForm.controls.bundleComponentUuid.setValue('');
            this.hasSameFundingRequest = true;
            this._bundleService.getBundleById(episode.episodeOfCare.bundleUuid).then((res: any) => {
                this.bundleList = [...this.bundleList, { ...res, label: res.name, value: res.uuid }];
                this.episode = { ...this.episode, bundle: this.bundleList[0] };
                this.selectedBundle = this.episode.bundleComponent;
                let bundleComponents = res.components.map(x => ({ ...x, label: x.name, value: x.uuid }));
                this.getFundingRequests(res, bundleComponents);

                //get bundle cost for each bundle components
                const bundleCost = res.bundleCost.find(x => x.marketCode === episode.marketCode);
                this._bundleCostService.getBundleComponentsByBundleCostId(bundleCost.uuid).subscribe((response: any) => {
                    bundleComponents.forEach(bundleComponent => {
                        const component = response.rows.find(x => bundleComponent.uuid === x.bundleComponentUuid)
                        bundleComponent.cost = component.cost;
                    })
                    this.episode.bundle.components = [...bundleComponents];
                    this.selectedBundle = this.episode.bundle;
                    this.associateForm.controls.clientCode.setValue(episode.clientCode);
                    this.associateForm.controls.bundleUuid.setValue(res.uuid);
                    this.associateForm.controls.bundleName.setValue(res.name);
                    this.associateForm.controls.bundleDisplayName.setValue(res.displayName);
                    this.bundleComponentList = bundleComponents.filter(component => !Constants.EXPENSE_ALLOCATIONS.includes(component.expenseAllocation.toLowerCase()));
                    this.associateForm.controls.episodeName.setValue(episode.name);
                    // for the claim already associated with episode
                    if (this.claim.claimXRefHolista.episodeId && this.claim.claimXRefHolista.episodeId == episode.id) {
                        let bundleComponents = [];
                        this.claim.claimXRefHolista.claimComponents.forEach(component => {
                            bundleComponents.push(component.bundleComponentUuid);
                        });
                        this.associateForm.controls.bundleUuid.setValue(this.claim.claimXRefHolista.bundleUuid);
                        this.associateForm.controls.bundleComponentUuid.setValue(bundleComponents);
                        const hospitalNewborn = this.bundleComponentList.find(x => x.name.toLowerCase() === 'hospital (newborn)');
                        if (hospitalNewborn) bundleComponents.find(x => x === hospitalNewborn.uuid) && (this.setNewbornChecked = true);
                    }
                    this.loading.bundle = false;
                    this.loading.bundleComponent = false;
                }, (error) => {
                    this._toastr.showError(error);
                    this.loading.bundle = false;
                    this.loading.bundleComponent = false;
                });
            }, (error) => {
                this._toastr.showError(error);
                this.loading.bundle = false;
                this.loading.bundleComponent = false;
            });
        } else {
            this.episode = episode;
            this.associateForm.controls.episodeId.setValue(episode.id);
            this.associateForm.controls.episodeName.setValue(episode.name);
            this.associateForm.controls.purchaserCode.setValue(episode.purchaserCode);
            this.associateForm.controls.clientCode.setValue(episode.clientCode);
        }
    }

    bundleComponentSelected(event, isSelected: boolean) {
        const isHospitalNewborn = event && event.name && event.name.toLowerCase() === 'hospital (newborn)';
        isHospitalNewborn && (isSelected ? (
            this.associateForm.controls.isNewBorn.setValue(true),
            this.setNewbornChecked = true
        ) : (
            this.associateForm.controls.isNewBorn.setValue(false),
            this.setNewbornChecked = false
        ));
        let bundleComponentIds = this.associateForm.value.bundleComponentUuid;
        let bundleComponents = [];
        if (bundleComponentIds.length > 1) {
            bundleComponentIds.forEach(element => {
                let index = this.bundleComponentList.findIndex(x => x.uuid == element);
                if (index > -1) {
                    bundleComponents.push(this.bundleComponentList[index]);
                }
            });
            this.hasSameFundingRequest = bundleComponents.filter(x => x.fundingRequestUuid == bundleComponents[0].fundingRequestUuid).length == bundleComponents.length ? true : false;
        }
        else this.hasSameFundingRequest = true;
        if (this.message) {
            this.message = null;
            this.disableSave = false;
        }
    }

    // Dropdown List

    getUsers() {
        this.userService.getUsers({ limit: 0, fields: 'id,firstName,lastName,roles,memberUuid' })
            .then((res: any) => {
                if (res.count != 0) {
                    res.rows = res.rows.map(x => {
                        x.label = x.firstName + ' ' + x.lastName,
                            x.value = x.id;
                        if (x.roles) {
                            x.roles.map(r => {
                                if (r.isActiveRole) x.roleCode = r.roleCode
                            })
                        }
                        return x;
                    })
                    this.memberPatients = res.rows.filter(x => x.roleCode == "MP");
                    this.backupMemberPatients = JSON.parse(JSON.stringify(this.memberPatients));
                }
            }), (error) => {
                console.log("Error fetching Users List", error);
            };
    }

    searchAssociatePatient(value) {
        this.loading.patient = true;
        if (value.length > 2) {
            return this.userService.searchUsers({ keyword: value, limit: 0 })
                .pipe(
                    debounceTime(250),
                    map((items: any) => {
                        this.loading.patient = false;
                        return items.rows;
                    })
                );
        } else {
            this.loading.patient = false;
            return of([]);
        }
    }

    onDisplayAssociatePatient(patient): string | undefined {
        return patient ? (patient.lastName ? patient.firstName + ' ' + patient.lastName : patient.firstName) : undefined;
    }

    // Reject / Deny / Change status Rejected - In-Review
    onSave() {
        this.updateStatus(this.selectedClaim, this.statusModel);
    }

    closeReasonModal(action) {
        if (action === 'cancel') {
            let index = this.claimsList.findIndex(x => x.id === this.selectedClaim.id)
            if (index > -1) {
                let index2 = this.backupClaimsList.findIndex(x => x.id === this.selectedClaim.id)
                if (index2 > -1)
                    this.claimsList[index].claimXRefHolista.processingStatus = this.backupClaimsList[index2].claimXRefHolista.processingStatus;
            }
        }
        this.reasonModal.hide();
        this.selectedClaim = {};
    }

    closeConfirmationModal() {
        this.confirmationModal.hide();
    }

    view835Claim(claim) {
        this._storage.set('session', 'claimsFilterData', this.filterForm.value);
        const paymentUuid = (claim && claim.claimPayment) ? claim.claimPayment.payment.uuid : ''
        this.router.navigate(
            [
                'claims',
                claim.id,
                'edi',
                // claim.claimPayment.payment.status.toUpperCase() === PAYMENT_STATUS.COMPLETED && claim.claimXRefHolista.processingStatus.toUpperCase() === CLAIMS_PROCESSING_STATUS.PAID,
                paymentUuid ? claim.claimXRefHolista.processingStatus.toUpperCase() === Constants.CLAIMS_PROCESSING_STATUS.PAID : claim.isApproved,
                paymentUuid
            ]
        )
    }

    goToPayment(claim) {
        if (this.utilityAccess.searchAccess('PAY', 'isEditable'))
            this.router.navigate(['payment/create/paid'], { queryParams: { claimId: claim.id, claimIdentifier: claim.patientAccountNumber } });
        else
            this._toastr.displayWarning(Constants.ACCESS_DENIED);
    }

    goToEditPayment(claim) {
        this.router.navigate([`payment/edit/paid/${claim.claimPayment ? claim.claimPayment.payment.uuid : ''}`]);
    }

    deleteClaim(claim) {
        this.isAutomationModal = false;
        if (this.utilityAccess.searchAccess('CLM', 'isEditable')) {
            this.claimId = claim.id;
            this.deleteConfitmationMessage = `Are you sure you want to delete Claim <strong>${claim.patientAccountNumber}</strong>? This is irreversible.
        If you're not sure, you can click <strong>Cancel</strong>.`
        }
        else this._toastr.displayWarning(Constants.ACCESS_DENIED);
    }

    deleteClaimConfirm(id) {
        if (id) {
            this.claimsService.delete(id)
                .subscribe((response: any) => {
                    if (response && response.length > 0) {
                        let index = this.claimsList.findIndex(x => x.id == response[1][0].id);
                        if (index >= 0) {
                            this.claimsList.splice(index, 1);
                            this._toastr.displaySuccess(Constants.DELETE_SUCCESS);
                        }
                        index = this.backupClaimsList.findIndex(x => x.id == response[1][0].id);
                        if (index >= 0)
                            this.backupClaimsList.splice(index, 1);
                    }
                }, (error) => {
                    console.log("Error deleting Claims", error);
                    this._toastr.displayError(Constants.ERROR_DELETE_CLAIMS);
                    this.claimId = null;
                })
        }
        else this.claimId = null;
    }

    getExceptionCodes() {
        this.claimsService.fetchExceptionCodes()
            .subscribe((res: any) => {
                if (res) {
                    this.exceptionCodes = res.map(x => {
                        let data = { ...x, label: x.description, value: x.id }
                        return data
                    })
                }
            }, (error) => {
                console.log("Error getting exception codes", error);
            })
    }

    reasonSelected(event) {
        this.statusModel.reason = event.description;
        this.statusModel.statusCode = event.code;
    }

    removeSearchKeyword() {
        this.searchKeyword = ''
        this.searchByKeyword('')
    }

    runAutomation(claim, index: number) {
        const message = this._messageConstants.getMessage(Constants.CLAIM_AUTOMATION_WARNING);
        this.claimIdForAutomation = claim.id;
        this.isAutomationModal = true;
        this.modalWarningMessage = message.value;
        this.confirmationModal.show();
        this.processingClaimIndex = index;
        this.selectedClaimForAutomation = claim;
    }

    onProceedAutomation() {
        this.loading.claimsDetail = true;
        this.claimsService.runAutomation(this.claimIdForAutomation).subscribe((res: any) => {
            res === true && this.claimsService.getClaimsDetail(this.claimIdForAutomation).subscribe(res => {
                const claimIndex = this.claimsList.findIndex(x => x.id === res.id);
                res['processingStatus'] = this.filterStatus(res);
                res['claimStatusClass'] = this.getClassName(res.claimXRefHolista.processingStatus.toLowerCase());
                this.claimsList[claimIndex] = res;
                this.claimsList[claimIndex].claimXRefHolista = { ...res.claimXRefHolista };
                this.loading.claimsDetail = false;
                const isNewBorn = this.claimsList[claimIndex].claimXRefHolista.isNewBorn;
                const episodeId = this.claimsList.find(x => x.id === this.claimIdForAutomation).claimXRefHolista.episodeId;
                if (isNewBorn) {
                    const newbornClaim = this.claimsList.find(x => (x.claimXRefHolista.episodeId === episodeId) && x.claimXRefHolista.isNewBorn && (x.id !== this.claimIdForAutomation))
                    newbornClaim && this.searchClaims(this.filterForm.value);
                }
                this.claimIdForAutomation = null;
                this._toastr.displaySuccess(Constants.UPDATE_SUCCESS);
            })
            res === false && (this.loading.claimsDetail = false);
        }, (error) => {
            this._toastr.displayError(`${error.error.message}`);
            console.log('Error validating claim', error);
            this.loading.claimsDetail = false;
            this.claimIdForAutomation = null;
        });
        this.confirmationModal.hide();
    }

    onSendEmail(metaVariablesDetail) {
        this.metaVariablesDetail = { detail: metaVariablesDetail, module: 'claim' };
        this.moduleOptions = {
            module: "claims",
            moduleId: metaVariablesDetail.id.toString(),
            moduleIdentifier: metaVariablesDetail.patientAccountNumber,
            type: 'communications'
        }
        this.showMessageModal = true;
    }

    closeMessageModal() {
        this.showMessageModal = false;
    }

    /**
     * gets bundle components for claims without episode
     * @param claim 
     * @param bundle 
     * @param isSelectedFromDropdown 
     * @returns 
     */
    onBundleSelected(claim, bundle, isSelectedFromDropdown: boolean = false) {
        const policyCode = claim.patient.insuredPolicyNumber.split('-')[0];
        if (Constants.POLICY_CODES.includes(policyCode)) {
            this.episode = null;
            this.claimsService.getPaidAtZeroBundleCodes(Constants.PURCHASER_CODES.carrum)
                .pipe(finalize(() => { }))
                .subscribe((res: any) => {
                    res.includes(bundle.bundleCode)
                        ? this.setConditionalRequiredField('bundleComponentUuid', policyCode)
                        : this.setConditionalRequiredField('bundleComponentUuid');
                }, (error) => {
                    console.log(error);
                });
            if (isSelectedFromDropdown) {
                this.episodeDropdownList = [];
                this.associateForm.controls.episodeId.setValue('');
                this.bundleComponentList = [];
                this.associateForm.controls.bundleComponentUuid.setValue('');
                this.showValidationError = false;
            };
            this.loading.bundleComponent = true;
            this.loading.episode = true;
            bundle.components = bundle.components.map(x => ({ ...x, label: x.name, value: x.uuid }));
            const bundleCostUuid = bundle && bundle.bundleCost && bundle.bundleCost[0].uuid;
            if (!bundleCostUuid) {
                this._toastr.displayError(Constants.FETCH_BUNDLE_COST_FAILED);
                this.loading.bundleComponent = false;
                return;
            }
            this._bundleCostService.getBundleComponentsByBundleCostId(bundleCostUuid).subscribe((response: any) => {
                bundle.components.forEach(bundleComponent => {
                    const component = response.rows.find(x => bundleComponent.uuid === x.bundleComponentUuid);
                    bundleComponent.cost = component.cost;
                })
                this.bundleComponentList = bundle.components.filter(component => !Constants.EXPENSE_ALLOCATIONS.includes(component.expenseAllocation.toLowerCase()));
                this.getFundingRequests(bundle, bundle.components);
                this.selectedBundle = bundle;
                this.associateForm.controls.bundleName.setValue(bundle.name);
                this.associateForm.controls.bundleDisplayName.setValue(bundle.displayName);
                this.loading.bundleComponent = false;
            }, (error) => {
                this._toastr.showError(error);
                this.loading.bundleComponent = false;
            });
            this._episodeService.getEpisodeBySubscriberAndBundleId(claim.patient.subscriberIdentificationCode, bundle.uuid).subscribe((response: any) => {
                if (response.length) {
                    this.episodeDropdownList = response.map(x => ({ ...x, label: `${x.name} ${x.serviceEndDate ? `(${moment(x.serviceStartDate).format('MM/DD/YYYY')} - ${moment(x.serviceEndDate).format('MM/DD/YYYY')})` : ''}`, value: x.id }));
                    this.episode = response.length === 1 ? response[0] : response.find(x => x.id === this.claim.claimXRefHolista.episodeId);
                    if (this.episode) {
                        this.associateForm.controls.episodeId.setValue(this.episode.id);
                        this.associateForm.controls.episodeName.setValue(this.episode.name);
                        this.associateForm.controls.purchaserCode.setValue(this.episode.purchaserCode);
                        this.associateForm.controls.clientCode.setValue(this.episode.clientCode);
                    }
                    this.loading.episode = false;
                } else {
                    this.associateForm.controls.episodeId.disable();
                    this.associateForm.controls.episodeName.disable();
                    this.associateForm.controls.purchaserCode.disable();
                    this.associateForm.controls.clientCode.disable();
                }
                this.loading.episode = false;
            }, (error) => {
                this._toastr.showError(error);
                this.loading.episode = false;
            })
        }
    }

    async exportToCsv() {
        this.loading.exportingClaims = true;
        const { TABLE_HEADERS, TABLE_TITLE, EXCEL_NAME, COLUMNS_WIDTH } = Constants.CLAIMS_REPORT_DATA;
        this.filterForm.value.limit = 0;
        this.claimsService.searchClaims(this.filterForm.value, this.claimType).subscribe(res => {
            const filters = { claimIDs: res.rows.map(x => x.claimXRefHolista.claimId) }
            this._reportService.getClaimsReport(filters as ExportReportParams).subscribe(res => {
                const report = {
                    data: res,
                    tableHeaders: TABLE_HEADERS,
                    excelName: EXCEL_NAME,
                    tableTitle: TABLE_TITLE,
                    columnsWidth: COLUMNS_WIDTH
                }
                this._reportUtility.exportToCsv(report);
                this.loading.exportingClaims = false;
            })
        });
    }

    /**
     * sets if input field is required conditionally 
     * @param formControl 
     * @param data 
     */
    setConditionalRequiredField(formControl: string, code?: string) {
        if (code && Constants.CLIENTS_WITH_OPTIONAL_BUNDLE_COMPONENT_SELECTION.includes(code)) {
            this.associateForm.controls[formControl].clearValidators();
            this.associateForm.controls[formControl].updateValueAndValidity();
            this.formControls[formControl].isRequired = false;
        }
        else {
            this.associateForm.controls[formControl].setValidators(Validators.required);
            this.associateForm.controls[formControl].updateValueAndValidity();
            this.formControls[formControl].isRequired = true;
        }
    }

    showClaimsEllipsis(claimXrefHolista: any) {
        if (claimXrefHolista.status.toLowerCase() === Constants.CLAIMS_STATUS.INVALID) {
            return true
        }

        if (Constants.SHOW_ELLIPSIS_STATUSES.includes(claimXrefHolista.processingStatus)) {
            if (this.user.roleCode == Constants.ROLE_CODES.PROVIDER_ADMINISTRATOR) { // As edi data will not be available in in-review and readyy-to-pay status
                if (claimXrefHolista.processingStatus == Constants.CLAIMS_PROCESSING_STATUS.PAID) {
                    return true
                }

                return false
            }

            if ([Constants.CLAIMS_PROCESSING_STATUS.PAID, Constants.CLAIMS_PROCESSING_STATUS.PAYMENT_IN_PROCESS].includes(claimXrefHolista.processingStatus) && !claimXrefHolista.ediData) {
                return false
            }

            return true
        }

        return false
    }

    showAssociateEpisode(claimXrefHolista: any) {
        if (!Constants.HIDE_ASSOCIATE_EPISODE_STATUSES.includes(claimXrefHolista.processingStatus)) {
            if (claimXrefHolista.status.toLowerCase() !== Constants.CLAIMS_STATUS.INVALID) {
                return true
            }
        }

        return false
    }

    disableAssociateEpisodeFields(claimXrefHolista: any) {
        return claimXrefHolista?.processingStatus === Constants.CLAIMS_PROCESSING_STATUS.ADJUSTMENT
    }
}

export class statusModel {
    status: string;
    value: string;
    reason: string;
    statusCode: string;
}