<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
    <div class="form-group has-search input-group mt-2 mb-2 w-25 w-xs-100">
      <span class="form-control-feedback left-0">
        <i class="icofont-search-1"></i>
      </span>
      <input
        type="text"
        [(ngModel)]="query.searchKey"
        (ngModelChange)="change($event)"
        class="form-control"
        id="search"
        placeholder="Search" />
      <div class="input-group-append" *ngIf="query.searchKey">
        <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
          <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50 mt-2 mb-2">
      <div style="width: 40%; margin-top: 5px">
        <app-select
          [options]="networks"
          [placeholder]="'Select network'"
          (onSelected)="filterByNetwork($event)"
          (onDeselected)="filterByNetwork($event, 'remove')"
          [allowClear]="true">
        </app-select>
      </div>
      <div>
        <button
          *ngIf="user.roleCode === 'PA'"
          type="button"
          class="btn btn-secondary"
          data-toggle="modal"
          (click)="openPayeeModal()"
          mdbWavesEffect>
          <i class="icofont-plus"></i>
          <span class="d-none d-sm-inline-block ml-1"> Add Payee</span>
        </button>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table
          mdbTable
          hover="true"
          small="true"
          class="table-mobile-view v-top mb-5"
          stickyHeader="true">
          <thead>
            <tr>
              <th width="50"></th>
              <th>Payee ID</th>
              <th>TIN</th>
              <th class="w-25" [class.active]="query.sortBy === 'name'" (click)="setOrder('name')">
                Payee Name
                <span *ngIf="query.sortBy === 'name'" class="ml-2">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th>Address</th>
              <th>Status</th>
              <th [class.active]="query.sortBy === 'createdAt'" (click)="setOrder('createdAt')">
                Created Date
                <span *ngIf="query.sortBy === 'createdAt'" class="ml-2">
                  <span [hidden]="reverseCreatedDateSort"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverseCreatedDateSort"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <div
          class="loader-wrapper"
          *ngIf="loading"
          [style.top]="payeeList && payeeList.length == 0 ? 'auto' : 0">
          <div class="loader">
            <app-loader [loader]="loading"></app-loader>
          </div>
          <div class="backdrop"></div>
        </div>
          <tbody
            mdbTableCol
            *ngFor="
              let payee of payeeList
                | paginate : {itemsPerPage: limit, currentPage: query.page, totalItems: totalCount};
              let i = index
            ">
            <tr>
              <td (click)="payeeCollapsed(payee)">
                <button
                  [attr.data-test-id]="'payee-button-expand'"
                  type="button"
                  class="btn btn-link p-0 mt-0"
                  mdbWavesEffect
                  *ngIf="payee.collapsed">
                  <i class="icofont-minus-circle icofont-lg text-info"></i>
                </button>
                <button
                  [attr.data-test-id]="'payee-button-collapse'"
                  type="button"
                  class="btn btn-link p-0 mt-0"
                  mdbWavesEffect
                  *ngIf="!payee.collapsed">
                  <i class="icofont-plus-circle icofont-lg text-info"></i>
                </button>
              </td>
              <td>
                {{ payee.payeeId || 'N/A' }}
              </td>
              <td>
                {{ payee.tin || 'N/A' }} 
              </td>
              <td [attr.data-test-id]="'payee-name'" width="170">
                {{ payee.name }}
                <br />
                <span
                  [attr.data-test-id]="'payee-network-name'"
                  class="badge badge-sm bg-primary-dark text-white z-depth-0">
                  {{ payee.networkName }}
                </span>
                <br />
              </td>
              <td [attr.data-test-id]="'payee-address'">
                <ul class="fa-ul ml-4 mb-0">
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="map-marker-alt"></mdb-icon>
                    </span>
                    <ng-template
                      [ngIf]="payee.addressLine1 || payee.addressLine2 || payee.city || payee.state"
                      [ngIfElse]="noAddress">
                      {{ payee.addressLine1 ? payee.addressLine1 : '' }}
                      {{ payee.addressLine2 ? payee.addressLine2 : '' }}
                      <br *ngIf="payee.addressLine1 || payee.addressLine2" />
                      <span
                        >{{ payee.city ? payee.city : '' }}
                        {{ payee.state ? payee.state : '' }}
                        {{ payee.zip ? payee.zip : '' }}</span
                      >
                    </ng-template>
                    <ng-template #noAddress> N/A </ng-template>
                  </li>
                </ul>
              </td>
              <td>
                <span
                  [attr.data-test-id]="'payee-status'"
                  class="z-depth-0 badge badge-pill badge-{{ payee.statusClass }} mt-1">
                  {{ payee.isActive ? 'Active' : 'Inactive' }}
                </span>
              </td>
              <td [attr.data-test-id]="'payee-createdAt'">
                {{ payee.createdAt || 'N/A' }}
              </td>
              <td width="32" class="d-none d-sm-block">
                <div class="custom-dropdown">
                  <button
                    [attr.data-test-id]="'payee-ellipsis'"
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div
                    [attr.data-test-id]="'payee-ellipsis-dropdown'"
                    class="custom-dropdown-content rounded-sm z-depth-2">
                    <a
                      [attr.data-test-id]="'payee-edit'"
                      class="cursor-pointer dropdown-item"
                      (click)="openPayeeModal(payee)"
                      ><i class="icofont-edit-alt mr-1"></i> Edit</a
                    >
                    <a
                      *ngIf="payee.isActive"
                      [attr.data-test-id]="'payee-deactivate'"
                      class="cursor-pointer dropdown-item"
                      (click)="deactivatePayee(payee)"
                      ><i class="icofont-ui-block mr-1"></i> Deactivate</a
                    >
                    <a
                      *ngIf="!payee.isActive"
                      [attr.data-test-id]="'payee-activate'"
                      class="cursor-pointer dropdown-item"
                      (click)="activatePayee(payee)"
                      ><i class="icofont-ui-block mr-1"></i> Activate</a
                    >
                    <a
                      [attr.data-test-id]="'payee-delete'"
                      class="cursor-pointer dropdown-item"
                      (click)="deletePayee(payee)">
                      <i class="icofont-ui-delete mr-1"></i> Delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="payee.collapsed" class="grey lighten-5 border">
              <td colspan="100%" class="p-0 p-sm-2 pl-sm-0">
                <div class="d-flex flex-column ml-2">
                  <div class="d-flex">
                    <span class="font-weight-500 my-2 font-size-larger">Additional Information</span>
                  </div>
                  <div class="d-flex w-25">
                    <span class="font-weight-500 w-50">NPI</span>
                    <span class="text-muted">{{ payee.npi }}</span>
                  </div>
                  <div class="d-flex w-25">
                    <span class="font-weight-500 w-50">Effective Date</span>
                    <span class="text-muted">{{ payee.effectiveDate || 'N/A' }}</span>
                  </div>
                  <div class="d-flex w-25">
                    <span class="font-weight-500 w-50">Termination Date</span>
                    <span class="text-muted">{{ payee.terminationDate || 'N/A' }}</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading && !payeeList.length">
    <i class="fas fa-3x fa-user-tie text-black-50"></i>
    <p>No results found.</p>
  </div>
  <pagination-controls
    *ngIf="payeeList && payeeList.length > 0"
    class="text-center"
    (pageChange)="pageChanged($event)"
    autoHide="true">
  </pagination-controls>
</section>

<div
  mdbModal
  #addPayeeModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ isEditPayee ? 'Edit' : 'Add' }} Payee</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closePayeeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="payeeForm">
          <div class="form-group">
            <label for="network">Network *</label>
            <app-select
              [options]="networks"
              [attr.data-test-id]="'payee-network'"
              [id]="'networks'"
              [placeholder]="'Select Network'"
              [control]="payeeForm.controls['network']"
              mdbInput
              [inputClass]="{
                'error-select': submitted && payeeForm.get('network').invalid
              }">
            </app-select>
          </div>
          <div class="form-group">
            <label for="name">Payee Name *</label>
            <input
              type="text"
              id="name"
              placeholder="Eg. Novant Healthcare"
              class="form-control"
              [attr.data-test-id]="'payee-name'"
              formControlName="name"
              [ngClass]="{'is-invalid': submitted && payeeForm.get('name').invalid}"
              mdbInput />
          </div>
          <div class="form-group">
            <mdb-checkbox formControlName="isActive"> Payee Active</mdb-checkbox>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label for="tin">TIN *</label>
                <input
                  type="text"
                  id="tin"
                  class="form-control"
                  [attr.data-test-id]="'payee-tin'"
                  formControlName="tin"
                  placeholder="Eg. 123456789"
                  [ngClass]="{'is-invalid': submitted && payeeForm.get('tin').invalid}"
                  numbersOnly
                  maxlength="9"
                  minlength="9"
                  mdbInput />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="npi">NPI *</label>
                <input
                  type="text"
                  id="npi"
                  class="form-control"
                  [attr.data-test-id]="'payee-npi'"
                  formControlName="npi"
                  placeholder="Eg. 0123456789"
                  [ngClass]="{'is-invalid': submitted && payeeForm.get('npi').invalid}"
                  numbersOnly
                  maxlength="10"
                  minlength="10"
                  mdbInput />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="payeeEmail">Address</label>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="addressLine1"
                  [attr.data-test-id]="'payee-address1'"
                  class="form-control"
                  formControlName="addressLine1"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && payeeForm.get('addressLine1').invalid}"
                  placeholder="Address Line 1" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="addressLine2"
                  [attr.data-test-id]="'payee-address2'"
                  placeholder="Address Line 2"
                  formControlName="addressLine2"
                  class="form-control"
                  [ngClass]="{'is-invalid': submitted && payeeForm.get('addressLine2').invalid}"
                  mdbInput />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <input
                  type="text"
                  id="payeeCity"
                  [attr.data-test-id]="'payee-city'"
                  class="form-control"
                  formControlName="city"
                  mdbInput
                  placeholder="City" />
              </div>
            </div>
            <div class="col-2">
              <app-select
                [options]="usStates | sort : 'label'"
                [attr.data-test-id]="'payee-state'"
                [id]="'payeeState'"
                [placeholder]="'State'"
                [control]="payeeForm.controls['state']"
                mdbInput>
              </app-select>
            </div>
            <div class="col">
              <input
                type="text"
                id="payeeZip"
                [attr.data-test-id]="'payee-zip'"
                class="form-control"
                formControlName="zip"
                [ngClass]="{'is-invalid': submitted && payeeForm.get('zip').invalid}"
                placeholder="Zip"
                numbersOnly
                maxlength="5" />
            </div>
          </div>
          <div class="form-row">
            <div *ngIf="isEditPayee" class="col-6">
              <div class="form-group">
                <label for="payeeId">Payee ID *</label>
                <input
                  type="text"
                  id="payeeId"
                  [readOnly]="true"
                  class="form-control"
                  formControlName="payeeId"
                  [attr.data-test-id]="'payee-id'"
                  mdbInput />
              </div>
            </div>
            <div [ngClass]="isEditPayee ? 'col-6' : 'col-12'">
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="effectiveDate">Effective Date *</label>
                    <input
                      type="text"
                      id="payeeEffectiveDate"
                      [attr.data-test-id]="'payee-effectivedate'"
                      placeholder="MM/DD/YYYY"
                      [textMask]="{mask: dateMask}"
                      class="form-control"
                      [ngClass]="{'is-invalid': submitted && payeeForm.get('effectiveDate').invalid}"
                      formControlName="effectiveDate" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="terminationDate">Termination Date</label>
                    <input
                      type="text"
                      id="payeeTerminationDate"
                      [attr.data-test-id]="'payee-terminationdate'"
                      placeholder="MM/DD/YYYY"
                      [textMask]="{mask: dateMask}"
                      class="form-control"
                      [ngClass]="{'is-invalid': submitted && payeeForm.get('terminationDate').invalid}"
                      formControlName="terminationDate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closePayeeModal()">
          Cancel
        </button>
        <button class="btn btn-secondary waves-light m-0" [disabled]="buttonSpinner" mdbWavesEffect (click)="forceAddPayee()">
          <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
          *ngIf="buttonSpinner"></span>
          {{ isEditPayee ? 'Update' : 'Save'}}
        </button>
      </div>
    </div>
  </div>
</div>

<app-delete-confirmation
  [_id]="submitAnyway"
  [message]="addPayeeAnywayMessage"
  yes="Add Anyway"
  (confirmDelete)="addPayee($event)">
</app-delete-confirmation>

<app-delete-confirmation
  [_id]="updateAnyway"
  [message]="updatePayeeAnywayMessage"
  yes="Update Anyway"
  (confirmDelete)="forceUpdatePayee($event)">
</app-delete-confirmation>

<app-delete-confirmation
  [_id]="deleteId"
  [message]="message"
  (confirmDelete)="confirmDelete($event)">
</app-delete-confirmation>

<!-- <app-delete-confirmation
  [_id]="deactivateId"
  [message]="message"
  (confirmDelete)="confirmDeactivate($event)">
</app-delete-confirmation> -->
