export const CLAIMS_TABLE_UNEDITABLE_FIELDS = ['claimIdentifier', 'status', 'allowedAmount', 'totalPayment', 'claimsStatus'];
export const CLAIM_TABLE_HEADERS = [
    { label: '', value: 'isChecked', type: 'checkbox', hideHeader: false },
    { label: '', value: 'collapsible', type: 'collapsible', isCollapsed: true },
    { label: 'Identifier', value: 'claimIdentifier', hideHeader: false },
    { label: 'Status', value: 'claimsStatus', hideHeader: false },
    { label: 'Claim Amount', value: 'amount', hideHeader: false, type: 'amount' },
    { label: 'HRA/HSA', value: 'hraHsaAmount', hideHeader: false, type: 'amount' },
    { label: 'Client Fee', value: 'clientFeeAdjustment', hideHeader: false, type: 'amount' },
    { label: 'Patient Responsibility', value: 'patientPayAmount', hideHeader: false, type: 'amount' },
    { label: 'Amount', value: 'totalPayment', hideHeader: true, type: 'amount' },
    { label: 'Allowed Amount', value: 'allowedAmount', hideHeader: true, type: 'amount' },
    { label: '', value: 'status', hideHeader: false },
];

export const NON_PRE_POPULATED_FIELDS = ['amount', 'hraHsaAmount', 'patientPayAmount', 'totalPayment', 'allowedAmount', 'clientFeeAdjustment'];

export const INVALID_STATUS_EXCEEDS_REASON = 'Billed amount exceeds allowed amount.';
export const INVALID_STATUS_SUBCEEDS_REASON = 'Billed amount subceeds allowed amount.';
export const MISMATCHED_STATUS = 'Billed amount does not match allowed amount.';
export const NEGATIVE_CLAIM_AMOUNT = 'Negative value for claim amount is not acceptable.'
import { PURCHASER_CODES } from "./codes.constant";

export const NO_EPISODE_PURCHASER_CODES = [PURCHASER_CODES.carrum.toLowerCase()];

export const PAYMENT_STATUS = {
    COMPLETED: 'COMPLETED',
    IN_PROGRESS: 'IN-PROGRESS',
}

export const REFERENCE_TYPE = {
    CLIENT: 'CLIENT',
    PLATFORM: 'PLATFORM',
    PROVIDER: 'PROVIDER',
}

export const SUBMIT_DISABLED_REASONS = {
    PAYEE_MISSING: 'Payee is missing.',
}