export const LOGCONSTANTS = {
    module_list: [
        { label: 'Episodes', value: 'episode' },
        { label: 'Pathways', value: 'pathway' },
        // { label: 'Documents', value: 'document' },
        { label: 'Users', value: 'user' },
        // { label: 'Companies', value: 'company' },
        { label: 'Bundles', value: 'bundle' },
        // { label: 'Bundle Costs', value: 'bundlecost' },
        // { label: 'Episode Of Care', value: 'episode of care' },
        { label: 'Claims', value: 'claim' },
        { label: 'Journal', value: 'journal' },
        { label: 'Providers', value: 'provider' },
        // { label: 'Payment', value: 'payment' },
        { label: 'Roles & Permissions', value: 'permission' },
        { label: 'Communications', value: 'communication' },
        { label: 'Reports', value: 'report' }
    ],

    loginActions: [
        { label: 'Login', value: 'LOGIN' },
        { label: 'Logout', value: 'LOGOUT' }
    ],

    userActions: [
        { label: 'Assigned', value: 'assigned' },
        { label: 'Created', value: 'created' },
        { label: 'Updated', value: 'updated' },
        { label: 'Deleted', value: 'deleted' },
        { label: 'Generated', value: 'generated' },
        { label: 'Downloaded', value: 'downloaded' },
        { label: 'Email Sent', value: 'email sent' },
    ],

    episode_sub_module_list: [
        { label: 'Task', value: 'task' },
        { label: 'Topic', value: 'topic' },
        { label: 'Milestone', value: 'milestone' },
        { label: 'Note', value: 'note' },
        { label: 'Email', value: 'email' }
    ],

    pathway_sub_module_list: [
        { label: 'Task', value: 'task' },
        { label: 'Topic', value: 'topic' },
        { label: 'Milestone', value: 'milestone' },
        { label: 'Note', value: 'note' }
    ],

    bundle_sub_module_list: [
        { label: 'Bundle Component', value: 'component' }
    ],

    claim_sub_module_list: [
        { label: 'Subscriber', value: 'subscriber' },
        { label: 'Submitter ', value: 'submitter' },
        { label: 'Receiver', value: 'receiver' },
        { label: 'Pay To Provider', value: 'pay to provider' },
        { label: 'Billing Provider', value: 'billing provider' },
        { label: 'Payor', value: 'payer' },
        { label: 'Health Care Diagnosis', value: 'health care diagnosis' },
        { label: 'Service Line', value: 'service line' },
        { label: 'Incoming', value: 'incoming' },
        { label: 'Outgoing', value: 'outgoing' },
        { label: 'Email', value: 'email' },
    ],

    task_list: [
        { label: 'Todo', value: 'todo' },
        { label: 'Message', value: 'message' },
        { label: 'Question', value: 'question' },
        { label: 'Questionnaire', value: 'questionnaire' }
    ],

    eoc_status_options: [
        { label: 'Closed', value: 'CLOSED' },
        { label: 'Pended', value: 'PENDED' },
        { label: 'EOC Created', value: 'EOC-CREATED' }
    ],

    contact_via_options: [
        { label: 'Call', value: 'call' },
        { label: 'Email', value: 'email' }
    ],

    report_sub_module_list: [
        { label: 'Claims Report', value: 'claims report' },
        { label: 'Client Episode Report', value: 'client episode report' },
        { label: 'Episode Financial Reconciliation Report', value: 'episode financial reconciliation report' },
        { label: 'Funding Report', value: 'funding report' },
        { label: 'Provider Episode Report', value: 'provider episode report' },
        { label: 'Provider/Facility Report', value: 'provider/facility report' },
    ],

    MODULES_WITH_SUB_MODULES: ['episode', 'pathway', 'bundle', 'claim', 'report']
}