export const FUNDING_REPORT_HEADERS = [
    { label: 'Company', value: 'purchaserName' },
    { label: 'Patient Name', value: 'patientName' },
    { label: 'Subscriber ID', value: 'subscriberNumber' },
    { label: 'Episode Type', value: 'episodeType' },
    { label: 'Date Invoiced', value: 'fundingRequestdDate' },
    { label: 'Date Funds Received', value: 'fundingReceivedDate' },
    { label: 'Funds Requested', value: 'fundRequested' },
    { label: 'Funds Received', value: 'fundReceived' },
];

export const FUNDING_SUMMARY_HEADERS = [
    { label: 'Episodes', value: 'episodes' },
    { label: 'Funds Requested', value: 'fundRequested' },
    { label: 'Funds Received', value: 'fundReceived' },
];

export const FUNDING_ADDITIONAL_HEADERS = [
    { label: 'HRA/HSA Received', value: 'hraHsaReceivedAmount' },
    { label: 'Funding Request', value: 'fundingReqName' },
    { label: 'Patient Responsibility', value: 'patientPayAmount' },
    { label: 'Provider Distribution', value: 'paidAmount' },
    { label: 'HRA/HSA Distribution', value: 'hraHsaPaidAmount' },
    { label: 'Holista Distribution', value: 'holistaFee' },
    { label: 'Client Distribution', value: 'clientFee' },
];

export const REPORT_TYPES = {
    FUNDING_REPORT: 'FR',
    EPISODE_FINANCIAL_RECONCILIATION_REPORT: 'EFRR',
    CLAIMS_REPORT: 'CR',
    CLIENT_EPISODE_REPORT: 'CER',
    PROVIDER_EPISODE_REPORT: 'PER',
    PROVIDER_FACILITY_REPORT: 'PR',
    CLAIMS_RECONCILIATION_SUMMARY: 'CRS',
    CLIENTS_CLAIMS_DASHBOARD: 'CCD',
    VALUE_MANAGEMENT_DASHBOARD: 'VMD',
    QUESTION_REVIEW_DASHBOARD: 'QRD',
    TRANSACTION_REPORT: 'TR',
    PAYMENT_REPORT: 'PMR',
    PROJECTED_EPISODE_PROFIT_REPORT: 'PEPR',
    ANCILLARY_REPORT: 'AR'
};

export const REPORT_DATES = [
    { label: 'Date Range', value: 'dateRange' },
    { label: 'Last Month', value: 'lastMonth' },
    { label: 'This Month', value: 'thisMonth' },
    { label: 'Last Year', value: 'lastYear' },
    { label: 'Last Quarter', value: 'lastQuarter' },
    { label: 'This Quarter', value: 'thisQuarter' },
    { label: 'YTD', value: 'ytd' },
];

export const EPISODE_FINANCIAL_SUMMARY_HEADER = ['Subscriber ID', 'Patient Name', 'Episode Type', 'Funded Amount', 'HRA/HSA Received', 'Consumed Amount', 'HRA/HSA Amount', 'Balance'];

export const EPISODE_FINANCIAL_DETAIL_HEADER = ['Subscriber ID', 'First Name', 'Last Name', 'Episode', 'Market', 'Claim Category', 'Type', 'Category Amount', 'Consumed Amount', 'HRA/HSA Amount'];

export const REPORT_FILTERS = {
    CR: ['reportDate', 'dateType', 'provider', 'procedureCode', 'status'],
    FR: ['reportDate', 'client', 'episodeType', 'columnOptions'],
    EFRR: ['reportDate', 'client'],
    PR: ['speciality', 'network', 'provider'],
    CER: ['client', 'episodeType', 'status'],
    PER: ['provider', 'episodeType', 'status'],
    CRS: ['dateType', 'reportDate'],
};

export const DATE_TYPES = [
    { label: 'Received Date', value: 'receivedDate' },
    { label: 'Date of Service', value: 'dateOfService' }
];

export const CLAIMS_REPORT_DATA = {
    EXCEL_NAME: 'Claims_Report',
    TABLE_TITLE: 'Claims Report',
    TABLE_HEADERS: [
        { label: 'Claim ID', value: 'claimId' },
        { label: 'Date Received', value: 'dateReceived' },
        { label: 'Member', value: 'member' },
        { label: 'DOB', value: 'dob' },
        { label: 'Subscriber', value: 'subscriber' },
        { label: 'Billing Provider', value: 'billingProvider' },
        { label: 'Rendering Provider', value: 'renderingProvider' },
        { label: 'Provider NPI', value: 'providerNpi' },
        { label: 'Tax ID', value: 'taxId' },
        { label: 'DX Item Number', value: 'dxItemNumber' },
        { label: 'ABK Diagnosis Code', value: 'diagnosisCode' },
        { label: 'Diagnosis Description', value: 'diagnosisDescription' },
        { label: 'P Modifier', value: 'pModifier' },
        { label: 'Item Number', value: 'itemNumber' },
        { label: 'Code', value: 'code' },
        { label: 'Procedure Description', value: 'procedureDescription' },
        { label: 'Date of Service', value: 'dateOfService' },
        { label: 'Billed Amount', value: 'billedAmount', type: 'amount' },
        { label: 'Paid Amount', value: 'paidAmount', type: 'amount' },
        { label: 'HRA/HSA Amount', value: 'hraHsaAmount', type: 'amount' },
        { label: 'Paid Date', value: 'paidDate' },
        { label: 'Processing Status', value: 'status' },
        { label: 'Exception Reason', value: 'exceptionDescription' }
    ],
    COLUMNS_WIDTH: [20, 15, 25, 15, 15, 50, 25, 15, 15, 20, 20, 60, 15, 15, 15, 40, 22, 15, 15, 20, 15, 25, 70]
};

export const HIGH_OCCURRENCE_PROVIDER_HEADER = ['PROVIDER', 'TAX ID', 'DUPLICATE', 'MISDIRECT', 'TOTAL'];

export const HIGH_OCCURRENCE_PROVIDER_DATA = {
    EXCEL_NAME: 'Claims_Report',
    TABLE_TITLE: 'Claims Report',
    TABLE_HEADERS: [
        { label: 'PROVIDER', value: 'provider', mergeCellLength: 3 },
        { label: 'TAX ID', value: 'taxId', mergeCellLength: 1 },
        { label: 'DUPLICATE', value: 'duplicate', mergeCellLength: 1 },
        { label: 'MISDIRECT', value: 'misdirect', mergeCellLength: 1 },
        { label: 'TOTAL', value: 'total', mergeCellLength: 1 },
    ],
};

export const PROVIDERS_REPORT_DATA = {
    EXCEL_NAME: 'Provider_Facility_Report',
    TABLE_TITLE: 'Provider/Facility Report',
    TABLE_HEADERS: [
        { label: 'Network', value: 'network', physicanOrder: 1, facilityOrder: 1, columnWidth: 30 },
        { label: 'NPI', value: 'physicianNPI', physicanOrder: 2, facilityOrder: 14, columnWidth: 15 },
        { label: 'Physician Name', value: 'physicianName', physicanOrder: 3, facilityOrder: 15, columnWidth: 30 },
        { label: 'Speciality', value: 'speciality', physicanOrder: 4, facilityOrder: 16, columnWidth: 50 },
        { label: 'Taxonomy Code', value: 'physicianPrimaryTaxonomyCode', physicanOrder: 5, facilityOrder: 17, columnWidth: 20 },
        { label: 'Provider Effective Date', value: 'physicianEffectiveStartDate', physicanOrder: 6, facilityOrder: 18, columnWidth: 25 },
        { label: 'Provider Termination Date', value: 'physicianEffectiveEndDate', physicanOrder: 7, facilityOrder: 19, columnWidth: 25 },
        { label: 'Facility Name', value: 'facilityDisplayName', physicanOrder: 8, facilityOrder: 3, columnWidth: 50 },
        { label: 'Address Line 1', value: 'facilityAddressLine1', physicanOrder: 9, facilityOrder: 4, columnWidth: 30 },
        { label: 'Address Line 2', value: 'facilityAddressLine2', physicanOrder: 10, facilityOrder: 5, columnWidth: 30 },
        { label: 'City', value: 'facilityCity', physicanOrder: 11, facilityOrder: 6, columnWidth: 20 },
        { label: 'State', value: 'facilityState', physicanOrder: 12, facilityOrder: 7, columnWidth: 10 },
        { label: 'Zip', value: 'facilityZipCode', physicanOrder: 13, facilityOrder: 8, columnWidth: 15 },
        { label: 'Tax ID', value: 'facilityTaxId', physicanOrder: 14, facilityOrder: 9, columnWidth: 15 },
        { label: 'Location NPI', value: 'facilityNPI', physicanOrder: 15, facilityOrder: 2, columnWidth: 15 },
        { label: 'Location Effective Date', value: 'facilityEffectiveStartDate', physicanOrder: 16, facilityOrder: 10, columnWidth: 25 },
        { label: 'Location Termination Date', value: 'facilityEffectiveEndDate', physicanOrder: 17, facilityOrder: 11, columnWidth: 25 },
        { label: 'Email', value: 'facilityEmail', physicanOrder: 18, facilityOrder: 12, columnWidth: 30 },
        { label: 'Phone', value: 'facilityPhone', physicanOrder: 19, facilityOrder: 13, columnWidth: 20 }
    ]
};

export const EPISODE_REPORT_DATA = {
    EXCEL_NAME: 'Provider_Episode_Report',
    TABLE_TITLE: 'Provider Episode Report',
    TABLE_HEADERS: [
        { label: 'Episode ID', value: 'episodeId', columnWidth: 15 },
        { label: 'Company', value: 'company', columnWidth: 30 },
        { label: 'Employer Client', value: 'employerClient', columnWidth: 30 },
        { label: 'Status', value: 'status', columnWidth: 20 },
        { label: 'Member First Name', value: 'memberFirstName', columnWidth: 25 },
        { label: 'Member Last Name', value: 'memberLastName', columnWidth: 25 },
        { label: 'Member DOB', value: 'memberDob', columnWidth: 20 },
        { label: 'Subscriber ID', value: 'subscriberId', columnWidth: 20 },
        { label: 'Member Address', value: 'memberAddress', columnWidth: 45 },
        { label: 'Episode Type', value: 'episodeType', columnWidth: 55 },
        { label: 'Date Confirmed', value: 'dateConfirmed', columnWidth: 20 },
        { label: 'Date of Surgery', value: 'dateOfSurgery', columnWidth: 20 },
        { label: 'Expected Delivery Date', value: 'expectedDeliveryDate', columnWidth: 20 },
        { label: 'Actual Delivery Date', value: 'actualDeliveryDate', columnWidth: 20 },
        { label: 'Benefit Start Date', value: 'benefitStartDate', columnWidth: 20 },
        { label: 'Benefit End Date', value: 'benefitEndDate', columnWidth: 20 },
        { label: 'Facility Name', value: 'facilityName', columnWidth: 70 },
        { label: 'Clinic Name', value: 'clinicName', columnWidth: 50 },
        { label: 'Provider Name', value: 'providerName', columnWidth: 70 },
        { label: 'Facility NPI', value: 'facilityNpi', columnWidth: 20 },
        { label: 'Market', value: 'marketCode', columnWidth: 15 },
    ],
};

export const NON_NOVANT_ELIMINATED_TABLE_HEADERS = ['expectedDeliveryDate', 'actualDeliveryDate'];

export const CLIENT_EPISODE_REPORT_ELIMINATED_TABLE_HEADERS = ['company'];

export const PROVIDER_EPISODE_REPORT_ELIMIMATED_TABLE_HEADERS = ['employerClient', 'memberAddress', 'dateConfirmed'];

export const REPORT_LABELS = {
    FUNDING_REPORT: 'Funding Report',
    EPISODE_FINANCIAL_RECONCILIATION_REPORT: 'Episode Financial Reconciliation Report',
    CLAIMS_REPORT: 'Claims Report',
    CLIENT_EPISODE_REPORT: 'Client Episode Report',
    PROVIDER_EPISODE_REPORT: 'Provider Episode Report',
    PROVIDER_FACILITY_REPORT: 'Provider Facility Report',
    CLAIMS_RECONCILIATION_SUMMARY_REPORT: 'Claims Reconciliation Summary Report'
}

export const PATHWAY_REPORT = ['SN', 'Name', 'Pathway Description', 'Milestones', 'Milestone Description', 'Task Type', 'Tasks', 'Task Description', 'Media'];

export const CLAIMS_RECONCILIATION_TOTAL_CLAIM_DATA = {
    EXCEL_NAME: '',
    TABLE_TITLE: '',
    TABLE_HEADERS: [
        { label: '', value: 'name', mergeCellLength: 1 },
        { label: 'Volume', value: 'volume', mergeCellLength: 1 },
        { label: 'Measure', value: 'measure', mergeCellLength: 1 },
    ],
    COLUMNS_WIDTH: []
}

export const CLAIMS_RECONCILIATION_VOLUME_BY_EPISODE_TYPE_DATA = {
    EXCEL_NAME: '',
    TABLE_TITLE: '',
    TABLE_HEADERS: [
        { label: '', value: 'name', mergeCellLength: 2 },
        { label: 'Maternity', value: 'maternity', mergeCellLength: 2 },
        { label: 'Total Joint', value: 'totalJoint', mergeCellLength: 2 },
        { label: 'Total', value: 'total', mergeCellLength: 2 },
    ],
    COLUMNS_WIDTH: []
}

export const CLAIMS_RECONCILITATION_REPORT_BY_PROVIDER_AND_EPISODE_DATA = {
    EXCEL_NAME: 'CLAIMS_RECONCILITATION_REPORT_BY_PROVIDER_AND_EPISODE',
    TABLE_TITLE: 'Claims Reconciliation Report By Provider & Episode',
    TABLE_HEADERS: [
        { label: 'Billing Provider', value: 'billingProvider' },
        { label: 'Tax ID', value: 'taxId' },
        { label: 'NPI', value: 'npi' },
        { label: 'Episode', value: 'episode' },
        { label: 'Total #Claims Submitted by Billing Provider / Episode', value: 'totalClaimSubmittedByBillingProviderOrEpisode' },
        { label: 'Total Misdirected Claims', value: 'totalMisdirectedClaim' },
        { label: 'Total Duplicate Claims', value: 'totalDuplicateClaim' },
    ],
    COLUMNS_WIDTH: [],
};

export const CLAIMS_RECON_REPORT_RAW_DATA = {
    EXCEL_NAME: 'Claims_Reconcilation_Raw_Data',
    TABLE_TITLE: 'Claims Reconcilation Raw Data',
    TABLE_HEADERS: [
        { label: 'Client Claim ID', value: 'clientClaimId', columnWidth: 15 },
        { label: 'Date Received', value: 'dateReceived', columnWidth: 15 },
        { label: 'Member', value: 'patientName', columnWidth: 15 },
        { label: 'DOB', value: 'dob', columnWidth: 15 },
        { label: 'Subscriber', value: 'subscriber', columnWidth: 15 },
        { label: 'Prov Location', value: 'provLocation', columnWidth: 30 },
        { label: 'Provider', value: 'provider', columnWidth: 25 },
        { label: 'Provider NPI', value: 'providerNpi', columnWidth: 15 },
        { label: 'Tax ID', value: 'taxId', columnWidth: 15 },
        { label: 'PAR', value: 'par', columnWidth: 15 },
        { label: 'Bundle', value: 'bundle', columnWidth: 15 },
        { label: 'Diagnosis Code 1', value: 'diagnosisCode1', columnWidth: 15 },
        { label: 'Diagnosis Code 2', value: 'diagnosisCode2', columnWidth: 15 },
        { label: 'Diagnosis Code 3', value: 'diagnosisCode3', columnWidth: 15 },
        { label: 'Item Number', value: 'itemNumber', columnWidth: 15 },
        { label: 'Procedure Code', value: 'procedureCode', columnWidth: 15 },
        { label: 'P Modifier', value: 'pModifier', columnWidth: 15 },
        { label: 'F Modifier', value: 'fModifier', columnWidth: 15 },
        { label: 'Date of Service', value: 'dateOfService', columnWidth: 15 },
        { label: 'Paid Amt', value: 'paidAmount', columnWidth: 15 },
        { label: 'Billed Amt', value: 'billedAmount', columnWidth: 15 },
        { label: 'Co Pay Amt', value: 'copayAmount', columnWidth: 15 },
        { label: 'Cob Amt', value: 'cobAmount', columnWidth: 15 },
        { label: 'Current Status', value: 'currentStatus', columnWidth: 15 },
        { label: 'Previous Status', value: 'previousStatus', columnWidth: 15 },
    ],
    COLUMNS_WIDTH: [15, 15, 15, 15, 25, 30, 25, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,]
};

export const EPISODE_WITH_CLAIMS_TABLE_DATA = {
    EXCEL_NAME: 'Episode_With_Claims_Table',
    TABLE_TITLE: 'Episode With Claims_Table',
    TABLE_HEADERS: [
        { label: 'Category', value: 'category', hideHeader: true },
        { label: 'Sub Category', value: 'subCategory', hideHeader: true, },
        { label: 'Year', value: 'year', hideHeader: true, },
        { label: 'Active', value: 'janActive', },
        { label: 'Closed', value: 'janClosed', },
        { label: 'Active', value: 'febActive', },
        { label: 'Closed', value: 'febClosed', },
        { label: 'Active', value: 'marActive', },
        { label: 'Closed', value: 'marClosed', },
        { label: 'Active', value: 'aprActive', },
        { label: 'Closed', value: 'aprClosed', },
        { label: 'Active', value: 'mayActive', },
        { label: 'Closed', value: 'mayClosed', },
        { label: 'Active', value: 'junActive', },
        { label: 'Closed', value: 'junClosed', },
        { label: 'Active', value: 'julActive', },
        { label: 'Closed', value: 'julClosed', },
        { label: 'Active', value: 'augActive', },
        { label: 'Closed', value: 'augClosed', },
        { label: 'Active', value: 'sepActive', },
        { label: 'Closed', value: 'sepClosed', },
        { label: 'Active', value: 'octActive', },
        { label: 'Closed', value: 'octClosed', },
        { label: 'Active', value: 'novActive', },
        { label: 'Closed', value: 'novClosed', },
        { label: 'Active', value: 'decActive', },
        { label: 'Closed', value: 'decClosed', },
    ],
};

export const CLAIMS_RECONN_TABS = {
    SUMMARY: 'Summary',
    BY_PROVIDER_AND_EPISODE: 'Provider & Episode',
    RAW_DATA: 'Raw Data',
};

export const REPORT_TAB_NAME = {
    OVERVIEW: 'Overview',
    PATIENT_EXPERIENCE: 'Patient Experience',
    PATIENT_COMMENTS: 'Patient Comments',
    OVERALL_CLAIMS: 'Overall Claims',
    TRIGGER_CLAIM_CYCLE_TIME_DETAIL: 'Trigger Claim Cycle Time Detail',
    PAYOR_CYCLE_TIME_DETAIL: 'Payor Cycle Time Detail',
    NETWORK_CYCLE_TIME: 'Network Cycle Time',
    BUNDLE_COMPONENT_UTILIZATION: 'Bundle Component Utilization',
    HOLISTA_CYCLE_TIME: 'Holista Cycle Time',
    TOTAL_CYCLE_TIME: 'Total Cycle Time',
    CONSENTED_PATIENTS_COMMENTS: 'Consented Patients: Comments',
    PORTAL_QUESTION_DETAIL: 'Portal Question Detail',
    SERVICE_CATEGORY_UTILIZATION: 'Service Category Utilization'
};

export const REPORT_TAB_CODE = {
    OVERVIEW: "TOV",
    PATIENT_EXPERIENCE: "TPE",
    PATIENT_COMMENTS: "RCPC",
    OVERALL_CLAIMS: "TOC",
    TRIGGER_CLAIM_CYCLE_TIME_DETAIL: "TTCCTD",
    PAYOR_CYCLE_TIME_DETAIL: "TPCTD",
    NETWORK_CYCLE_TIME: "NCT",
    BUNDLE_COMPONENT_UTILIZATION: "BCU",
    HOLISTA_CYCLE_TIME: "THCT",
    TOTAL_CYCLE_TIME: "TTCT",
    CONSENTED_PATIENTS_COMMENTS: "TCPC",
    PORTAL_QUESTION_DETAIL: "TPQD",
    ACCOUNTS_RECEIVABLE: "TAR",
    ACCOUNTS_PAYABLE: "TAP",
    SUMMARY_YTD: "TSYTD"
};

export const REPORT_NAME_TYPES = {
    ACTIVE_EPISODE_BY_MONTH: "active episode by month",
    NEW_EPISODES_BY_MONTH: "new episodes by month",
    EPISODES_BY_CARE_PATHWAY: "episodes by care pathway",
    PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD: 'patient compliance with required tasks (ytd)',
    PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED: "portal engagement (ytd) % of content accessed",
    PORTAL_ENGAGEMENT_MILESTONES_DETAIL: "portal engagement - milestones detail",
    EPISODE_WITH_CLAIMS_TABLE: "episode (with claims) table",
    CYCLE_TIMES: "cycle times",
    DETAIL: "detail",
    TRIGGER_CLAIM_CYCLE_TIMES_COMPANY: "trigger claim cycle times - company",
    TRIGGER_CLAIM_CYCLE_TIMES_FACILITY: "trigger claim cycle times - facility",
    TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART: "trigger claim cycle times (facility chart)",
    PAYOR_CYCLE_TIMES_COMPANY: "payor cycle times - company",
    PAYOR_CYCLE_TIMES_PAYOR: "payor cycle times - payor",
    PAYOR_CYCLE_TIMES_PAYOR_CHART: "payor cycle times - (payor chart)",
    HOLISTA_PROCESSING_CYCLE_TIMES: "holista processing cycle times",
    HOLISTA_PAYMENT_CYCLE_TIMES: "holista payment cycle times",
    TOTAL_CYCLE_TIMES: "total cycle time",
    PATIENT_SATISFACTION_KEY_QUESTIONS: "patient satisfaction - key questions",
    SURVEY_RESPONSE_RATE_YTD: "survey response rate (ytd)",
    PATIENT_AGREEMENT_SIGNED_IN_3_DAYS: "patient aggrement (signed in < 3 days)",
    REASON_FOR_CANCELLATION_ACCUMULATIVE: "reason for cancellation (accumulative)",
    SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO: "survey questions with highest volume of `no`",
    PORTAL_ENGAGEMENT_DETAIL: "portal engagement detail",
    OVERALL_SERVICE_CATEGORY_CYCLE_TIME: "Overall Service Category Cycle Time",
    PROVIDER_CYCLE_TIME: "Provider Cycle Time",
    MARKET_CYCLE_TIME: "Market Cycle Time"
};

export const REPORT_NAME_CODES = {
    ACTIVE_EPISODE_BY_MONTH: "RAEM",
    NEW_EPISODES_BY_MONTH: "RNEM",
    EPISODES_BY_CARE_PATHWAY: "RECP",
    PATIENT_COMPLIANCE_WITH_REQUIRED_TASK_YTD: "RPCRT",
    PORTAL_ENGAGEMENT_OF_CONTENT_ACCESSED: "RPECA",
    PORTAL_ENGAGEMENT_MILESTONES_DETAIL: "RPEMD",
    EPISODE_WITH_CLAIMS_TABLE: "RECD",
    CYCLE_TIMES: "RCT",
    DETAIL: "RD",
    TRIGGER_CLAIM_CYCLE_TIMES_COMPANY: "RTCCTC",
    TRIGGER_CLAIM_CYCLE_TIMES_FACILITY: "RTCCTF",
    TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART: "RTCCTFC",
    PAYOR_CYCLE_TIMES_COMPANY: "RPCTC",
    PAYOR_CYCLE_TIMES_PAYOR: "RPCTP",
    PAYOR_CYCLE_TIMES_PAYOR_CHART: "RPCTPC",
    HOLISTA_PROCESSING_CYCLE_TIMES: "RHPRCT",
    HOLISTA_PAYMENT_CYCLE_TIMES: "RHPCT",
    TOTAL_CYCLE_TIMES: "TTCT",
    PATIENT_SATISFACTION_KEY_QUESTIONS: "RPSKQ",
    SURVEY_RESPONSE_RATE_YTD: "RSRR",
    PATIENT_AGREEMENT_SIGNED_IN_3_DAYS: "RPA",
    REASON_FOR_CANCELLATION_ACCUMULATIVE: "RRC",
    SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO: "RSQHVN",
    PORTAL_ENGAGEMENT_DETAIL: "RPED",
    NETWORK_CYCLE_TIME: "NCT",
    OVERALL_SERVICE_CATEGORY_CYCLE_TIME: "OSCCT",
    PROVIDER_CYCLE_TIME: "PCT",
    BUNDLE_COMPONENT_UTILIZATION: "BCUR",
    MARKET_CYCLE_TIME: "MCT",
};

export const KPI_VALUES = {
    TRIGGER_CLAIM_CYCLE_TIMES_COMPANY: 7,
    TRIGGER_CLAIM_CYCLE_TIMES_FACILITY: 7,
    PAYOR_CYCLE_TIMES_COMPANY: 21,
    PAYOR_CYCLE_TIMES_PAYOR: 21,
    HOLISTA_PROCESSING_CYCLE_TIMES: 3,
    HOLISTA_PAYMENT_CYCLE_TIMES: 4,
    TOTAL_CYCLE_TIMES: 45,
    OVERALL_SERVICE_CATEGORY_CYCLE_TIMES: 14,
    PROVIDER_CYCLE_TIMES: 14,
    MARKET_CYCLE_TIME: 14,
}

export const NAME_VARIABLES = ['memberFirstName', 'memberLastName', 'member', 'physicianName', 'patientName'];

export const CONSENTED_PATIENTS_COMMENTS_TABLE_DATA = {
    EXCEL_NAME: 'Consented_Patients_Comments_Table',
    TABLE_TITLE: 'Consented Patients Comments_Table',
    TABLE_HEADERS: [
        { label: 'Answer Date', value: 'answerDate', headerWidth: '8%', },
        { label: 'Patient Name', value: 'fullName', headerWidth: '8%', },
        { label: 'Questionnaire', value: 'questionnaireName', headerWidth: '20%' },
        { label: 'Question', value: 'question', headerWidth: '8%', },
        { label: 'Answer', value: 'answer', headerWidth: '50%', },
    ],
    COLUMNS_WIDTH: [10, 10, 30, 10, 50]
};

export const PORTAL_QUESTION_DETAIL_TABLE_DATA = {
    EXCEL_NAME: 'Portal_Question_Detail_Table',
    TABLE_TITLE: 'Portal Question Detail_Table',
    TABLE_HEADERS: [
        { label: 'Answer Date', value: 'answerDate', headerWidth: '8%' },
        { label: 'Patient Name', value: 'fullName', headerWidth: '8%' },
        { label: 'Milestone Start', value: 'milestoneStartDate', headerWidth: '8%' },
        { label: 'Questionnaire', value: 'questionnaireName', headerWidth: '10%' },
        { label: 'Question', value: 'question', headerWidth: '20%' },
        { label: 'Answer', value: 'answer', headerWidth: '30%' },
    ],
    COLUMNS_WIDTH: [10, 10, 30, 50, 50, 10]
};

export const YES_NO_ALL = [
    { id: 1, label: "Yes", value: "yes" },
    { id: 2, label: "No", value: "no" },
]

export const EPISODE_CARE_PATHWAY_DATA = {
    EXCEL_NAME: 'Episode_Care_Pathway_Table',
    TABLE_TITLE: 'Episode Care Pathway_Table',
    TABLE_HEADERS: [
        { label: 'Category', value: 'category', },
        { label: 'Sub Category', value: 'subCategory', },
        { label: 'Episode Count', value: 'episodeCount', },
    ],
};

export const PAGINATION_CONST = {
    OFFSET: 0,
    LIMIT: 10,
    MAX_LIMIT: 10000,
}

export const REPORT_FILTERING_TYPES = {
    INDIVIDUAL_MATERNITY_REPORT: 'individualMaternityReport',
    OVERALL_REPORT: 'overallReport',
    INDIVIDUAL_REPORT: 'individualReport',
}

export const REPORT_TAB_INDEX = {
    OVERALL_CLAIMS: 0,
    TRIGGER_CLAIM_CYCLE_TIME_DETAIL: 1,
    PAYOR_CYCLE_TIME_DETAIL: 2,
    NETWORK_CYCLE_TIMES: 3,
    SERVICE_CATEGORY_UTILIZATION: 4,
    HOLISTA_CYCLE_TIME: 5,
    TOTAL_CYCLE_TIME: 6
}

export const PAYMENT_REPORT_DATA = {
    EXCEL_NAME: 'Payment_Report',
    TABLE_TITLE: 'Payment Report',
    TABLE_HEADERS: [
        { label: 'Invoice ID', value: 'invoiceId', columnWidth: 15 },
        { label: 'Patient Name', value: 'patientName', columnWidth: 25 },
        { label: 'Subscriber Number', value: 'subscriberNumber', columnWidth: 25 },
        { label: 'Episode Name', value: 'episodeName', columnWidth: 25 },
        { label: 'Funding Request', value: 'fundingRequest', columnWidth: 20 },
        { label: 'Claim ID', value: 'patientAccNo', columnWidth: 25 },
        { label: 'Payment Type', value: 'paymentType', columnWidth: 18 },
        { label: 'Payment Mode', value: 'paymentMode', columnWidth: 18 },
        { label: 'Payment Mode Trace Number', value: 'paymentModeTraceNo', columnWidth: 30 },
        { label: 'Payor', value: 'payor', columnWidth: 25 },
        { label: 'Payee', value: 'payee', columnWidth: 25 },
        // { label: 'Bundle Component', value: 'bundleComponent', columnWidth: 25 },
        { label: 'Claim Amount', value: 'claimAmount', columnWidth: 18, type: 'amount' },
        { label: 'Patient Resposibility', value: 'patientResponsibility', columnWidth: 25, type: 'amount' },
        { label: 'Client Fee', value: 'clientFee', columnWidth: 15, type: 'amount' },
        { label: 'HRA/HSA', value: 'hraHsa', columnWidth: 15, type: 'amount' },
        { label: 'Allowed Amount', value: 'allowedAmount', columnWidth: 20, type: 'amount' },
        { label: 'Payment Date', value: 'paymentDate', columnWidth: 20, type: 'date' },
        { label: 'Status', value: 'status', columnWidth: 15 },
    ],
};

export const PROJECTED_EPISODE_PROFIT_REPORT_DATA = {
    EXCEL_NAME: 'Projected_Profit_Report',
    TABLE_TITLE: 'Projected Profit Report',
    TABLE_HEADERS: [
        { label: 'Client', value: 'client', columnWidth: 35 },
        { label: 'Episode', value: 'episode', columnWidth: 65 },
        { label: 'Provider', value: 'market', columnWidth: 15 },
        { label: 'Episode Count', value: 'episodeCount', columnWidth: 10 },
        { label: 'Funding Requested', value: 'fundingRequested', columnWidth: 25, type: 'amount' },
        { label: 'Funding Received', value: 'fundingReceived', columnWidth: 25, type: 'amount' },
        { label: 'Total External Costs', value: 'totalExternalCosts', columnWidth: 25, type: 'amount' },
        { label: 'Total Holista Costs', value: 'totalHolistaCosts', columnWidth: 25, type: 'amount' },
        { label: 'Margin', value: 'margin', columnWidth: 20, type: 'amount' },
        { label: '% to Quality', value: 'percentToQuality', columnWidth: 20, type: 'amount' },
        { label: '% to Comission', value: 'percentToComission', columnWidth: 20, type: 'amount' },
        { label: 'Net Profit', value: 'netProfit', columnWidth: 20, type: 'amount' },
        { label: '% Profit', value: 'percentProfit', columnWidth: 20, type: 'percentage' },
    ],
    SUMMARY_HEADERS: [
        { label: 'Funding Requested', value: 'episodes' },
        { label: 'Funding Received', value: 'fundsRequested', type: 'amount' },
        { label: 'Total External Costs', value: 'totalExternalCosts', type: 'amount' },
        { label: 'Total Holista Costs', value: 'totalHolistaCosts', type: 'amount' },
        { label: '% to Quality', value: 'percentToQuality', type: 'amount' },
        { label: '% to Comission', value: 'percentToComission', type: 'amount' },
        { label: 'Net Profit', value: 'netProfit', type: 'amount' },
    ],
};

export const ACCOUNTS_RECEIVABLE_REPORT_DATA = {
    EXCEL_NAME: 'Accounts_Receivable_Report',
    TABLE_TITLE: 'Accounts Receivable Report',
    EXCEL_HEADERS: [
        { label: 'Client', value: 'client', columnWidth: 35 },
        { label: 'Patient Name', value: 'patientName', columnWidth: 35 },
        { label: 'Subscriber ID', value: 'subscriberId', columnWidth: 35 },
        { label: 'Episode Type', value: 'episodeType', columnWidth: 55 },
        { label: 'Date Invoiced', value: 'dateInvoiced', type: 'date', columnWidth: 25 },
        { label: 'Date Funds Received', value: 'dateFundsReceived', type: 'date', columnWidth: 25 },
        { label: 'Funds Requested', value: 'fundsRequested', type: 'amount', columnWidth: 25 },
        { label: 'Client Fee', value: 'clientFee', type: 'amount', columnWidth: 25 },
        { label: 'Funds Received', value: 'fundReceived', type: 'amount', columnWidth: 25 },
        { label: 'Holista Fee', value: 'holistaFee', type: 'amount', columnWidth: 25 },
    ],
    SUMMARY_HEADERS: [
        { label: 'Episodes', value: 'episodes' },
        { label: 'Funds Requested', value: 'fundsRequested', type: 'amount' },
        { label: 'Client Distribution', value: 'clientDistribution', type: 'amount' },
        { label: 'Funds Received', value: 'fundsReceived', type: 'amount' },
        { label: 'Holista Distribution', value: 'holistaDistribution', type: 'amount' },
    ],
    ROOT_DATA: {
        TABLE_HEADERS: [
            { label: 'Client', value: 'client' },
            { label: 'Date Funds Received', value: 'dateFundsReceived', type: 'date' },
            { label: 'Total', value: 'total', type: 'amount' }
        ]
    },
    NESTED_DATA: {
        TABLE_HEADERS: [
            { label: 'Patient Name', value: 'patientName' },
            { label: 'Subscriber ID', value: 'subscriberId' },
            { label: 'Episode Type', value: 'episodeType' },
            { label: 'Date Invoiced', value: 'dateInvoiced', type: 'date' },
            { label: 'Date Funds Received', value: 'dateFundsReceived', type: 'date' },
            { label: 'Funds Requested', value: 'fundsRequested', type: 'amount' },
            { label: 'Client Fee', value: 'clientFee', type: 'amount' },
            { label: 'Funds Received', value: 'fundReceived', type: 'amount' },
            { label: 'Holista Fee', value: 'holistaFee', type: 'amount' }
        ]
    }
};

export const ACCOUNTS_PAYABLE_REPORT_DATA = {
    EXCEL_NAME: 'Accounts_Payable_Report',
    TABLE_TITLE: 'Accounts Payable Report',
    SUMMARY_HEADERS: [
        { label: 'Provider Distribution', value: 'providerDistribution', type: 'amount' },
    ],
    TABLE_HEADERS: [
        { label: 'Provider', value: 'provider', columnWidth: 55 },
        { label: 'Tax ID', value: 'taxId', columnWidth: 25 },
        { label: 'Patient Name', value: 'patientName', columnWidth: 35 },
        { label: 'Subscriber ID', value: 'subscriberId', columnWidth: 35 },
        { label: 'Episode Type', value: 'episodeType', columnWidth: 65 },
        { label: 'Date Funds Received', value: 'dateFundsReceived', columnWidth: 25, type: 'date' },
        { label: 'Payment Date', value: 'paymentDate', columnWidth: 20, type: 'date' },
        { label: 'Payment Mode Trace Number', value: 'transactionNo', columnWidth: 30 },
        { label: 'Provider Distribution', value: 'providerDistribution', columnWidth: 30, type: 'amount' },
    ]
};

export const ANCILLARY_REPORT_DATA = {
    EXCEL_NAME: 'Ancillary_Report',
    TABLE_TITLE: 'Ancillary Report',
    TABLE_HEADERS: [
        { label: 'Episode ID', value: 'episodeId', columnWidth: 15 },
        { label: 'Company', value: 'company', columnWidth: 35 },
        { label: 'Status', value: 'status', columnWidth: 15 },
        { label: 'Member First Name', value: 'memberFirstName', columnWidth: 25 },
        { label: 'Member Last Name', value: 'memberLastName', columnWidth: 25 },
        { label: 'Member DOB', value: 'memberDob', columnWidth: 20, type: 'date' },
        { label: 'Subscriber ID', value: 'subscriberId', columnWidth: 35 },
        { label: 'Episode Type', value: 'episodeType', columnWidth: 65 },
        { label: 'Procedure Date', value: 'dateOfSurgery', columnWidth: 65, type: 'date' },
        { label: 'Expected Delivery Date', value: 'expectedDeliveryDate', columnWidth: 20, type: 'date' },
        { label: 'Actual Delivery Date', value: 'actualDeliveryDate', columnWidth: 20, type: 'date' },
        { label: 'Benefit Start Date', value: 'benefitStartDate', columnWidth: 20, type: 'date' },
        { label: 'Benefit End Date', value: 'benefitEndDate', columnWidth: 20, type: 'date' },
        { label: 'Facility Name', value: 'facilityName', columnWidth: 70 },
        { label: 'Clinic Name', value: 'clinicName', columnWidth: 50 },
        { label: 'Provider Name', value: 'providerName', columnWidth: 70 },
        { label: 'Facility NPI', value: 'facilityNpi', columnWidth: 20 },
        { label: 'Market', value: 'marketCode', columnWidth: 15 },
    ]
};

export const SUMMARY_YTD_REPORT_DATA = {
    EXCEL_NAME: 'Summary_YTD_Report',
    TABLE_TITLE: 'Summary YTD Report',
    SUMMARY_DATA: {
        TABLE_HEADERS: [
            { label: '', value: 'monthName', },
            { label: 'Episodes', value: 'episodes' },
            { label: 'Funds Requested', value: 'fundsRequested', type: 'amount' },
            { label: 'Client Distribution', value: 'clientDistribution', type: 'amount' },
            { label: 'Patient Responsibility', value: 'patientResponsibility', type: 'amount' },
            { label: 'Funds Received', value: 'fundsReceived', type: 'amount' },
            { label: 'Provider Distribution', value: 'providerDistribution', type: 'amount' },
            { label: 'Holista Distribution', value: 'holistaDistribution', type: 'amount' },
            { label: 'Non Consumed', value: 'totalNotConsumed', type: 'amount' },
        ]
    },
    REPORT_DATA: {
        TABLE_HEADERS: [
            { label: 'Client', value: 'clientName', columnWidth: 35 },
            { label: 'Patient Name', value: 'patientName', columnWidth: 35 },
            { label: 'Episode Type', value: 'episodeType', columnWidth: 65 },
            { label: 'Date Invoiced', value: 'dateInvoiced', columnWidth: 25, type: 'date' },
            { label: 'Date Funds Requested', value: 'dateFundsReceived', columnWidth: 25, type: 'date' },
            // { label: 'Payment Date', value: 'paymentDate', type: 'date' },
            { label: 'Funds Requested', value: 'fundsRequested', columnWidth: 25, type: 'amount' },
            { label: 'Client Fee', value: 'clientFee', columnWidth: 25, type: 'amount' },
            { label: 'Patient Responsibility', value: 'patientResponsibility', columnWidth: 25, type: 'amount' },
            { label: 'Funds Received', value: 'fundReceived', columnWidth: 25, type: 'amount' },
            { label: 'Provider Distribution', value: 'providerDistribution', columnWidth: 30, type: 'amount' },
            { label: 'Holista Fee', value: 'holistaFee', columnWidth: 25, type: 'amount' },
            { label: 'Non Consumed', value: 'totalNotConsumed', columnWidth: 30, type: 'amount' },
            { label: 'Status', value: 'episodeStatus', columnWidth: 30 },
        ]
    }
};

export const TRANSACTION_REPORT_TAB_INDEXES = {
    ACCOUNTS_RECEIVABLE: 0,
    ACCOUNTS_PAYABLE: 1,
    SUMMARY_YTD: 2,
}

export const ACCOUNTS_RECEIVABLE_SUMMARY_DATE_TYPE_LIST = [
    { label: 'Date Funds Received', value: 'fundsReceived' },
    { label: 'Date Invoiced', value: 'invoiced' },
];

export const ACCOUNTS_PAYABLE_DATE_TYPE_LIST = [
    { label: 'Date Funds Received', value: 'fundsReceived' },
    { label: 'Payment Date', value: 'payment' },
];
