import { Component, OnInit, Output, EventEmitter, Input, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { MdbBtnDirective } from 'ng-uikit-pro-standard';
import { AccessType } from 'src/app/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-episode-questionnaire',
  templateUrl: './episode-questionnaire.component.html',
  styleUrls: ['./episode-questionnaire.component.scss']
})
export class EpisodeQuestionnaireComponent implements OnInit {
  taskToBeDelete: any
  dependentTask: any
  qnrsUuid: any
  @Output() editTask = new EventEmitter<any>();
  @Output() addAction = new EventEmitter<any>();
  @Input() public taskQnr;
  @Input() public optionId;
  @Input() public taskDragged;
  @Output() public btns = new EventEmitter<any>();
  @Output() public deletedTask = new EventEmitter<any>();
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;
  @Input() user: any;
  @Input() set taskQuestionnaires(taskQuestionnaires) {
    if (taskQuestionnaires) {
      taskQuestionnaires.qnrs.qnrsQues.map(x => {
        if (x.question.status == 'COMPLETED') {
          if (x.question.questionTypes.code == 'TEXTFIELD') {
            x.answer = x.question.ansOpt[0].answer;
            x.showMore = true;
          } else if (x.question.questionTypes.code == 'RADIO') {
            x.question.questionOptions.map(y => {
              y.status = (x.question.ansOpt[0].answerOptionId == y.id) ? true : false;
              return y;
            })
          } else if (x.question.questionTypes.code == 'CHECKBOX') {
            x.question.ansOpt.map(a => {
              x.question.questionOptions.map(y => {
                if (a.answerOptionId == y.id)
                  y.status = true;
                return y;
              })
              return a;
            })
          }
        }
        return x;
      })
      this.taskQnr = taskQuestionnaires;
    }
  }
  @Input() public days_difference;
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.qnrsUuid)
        this.getDependentTask(this.qnrsUuid)
    }
  }

  @Input() set taskQnrsUuid(taskQnrsUuid) {
    if (taskQnrsUuid) {
      this.qnrsUuid = taskQnrsUuid
      this.getDependentTask(taskQnrsUuid);
    }
  }
  constructor(
    private utilityAccess: AccessType,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const buttons = this.buttons.toArray();
    setTimeout(() => {
      this.btns.emit(buttons);
    });
  }
  receiveBtns(buttons, field) {
    if (buttons) {
      this.btns.emit(buttons);
    }
  }
  editTaskHandler(questionnaire) {
    this.editTask.emit(questionnaire);
  }

  getDependentTask(uuid) {
    this.taskQnr = {};
    if (this.dependentTask && this.dependentTask.taskQnrs) {
      this.dependentTask.taskQnrs.filter(x => {
        if (x.uuid == uuid) {
          x.form = 'questionnaire';
          x.field = 'questionnaire';
          x.optionId = this.optionId;
          x.qnrs.qnrsQues.map(q => {
            if (q.question.status == 'COMPLETED' && x.isActivated) {
              if (q.question.questionTypes.code == 'TEXTFIELD') {
                q.answer = q.question.ansOpt[0].answer;
                q.showMore = true;
              } else if (q.question.questionTypes.code == 'RADIO') {
                q.question.questionOptions.map(y => {
                  y.status = (q.question.ansOpt[0].answerOptionId == y.id) ? true : false;
                  return y;
                })
              } else if (q.question.questionTypes.code == 'CHECKBOX') {
                q.question.ansOpt.map(a => {
                  q.question.questionOptions.map(y => {
                    if (a.answerOptionId == y.id)
                      y.status = true;
                    return y;
                  })
                  return a;
                })
              }
            }
            return q;
          })
          this.taskQnr = x;
        }
      });
    }
  }

  addActionHandler(action?, uuid?) {
    if (action && action.optionId) {
      this.addAction.emit(action);
    } else {
      this.addAction.emit({ optionId: action ? action : this.optionId, isDependent: true, parentUuid: uuid })
    }
  }

  editTaskTodo(todo) {
    this.editTask.emit(todo);
  }

  editTaskMessage(message) {
    this.editTask.emit(message);
  }

  deleteTaskHandler(questionnaire) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      questionnaire['source'] = 'Episode'
      questionnaire['type'] = 'Questionnaire'
      questionnaire['displayName'] = questionnaire.qnrs.name
      this.taskToBeDelete = questionnaire
    }
    else this.toastr.warning("Access Denied", "Warning");
  }

  cancelDelete(e) {
    this.taskToBeDelete = e
  }

  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }
}
