import { Component, OnInit, Inject, HostListener } from "@angular/core";
import { Router, NavigationEnd, Event } from "@angular/router";
import { CommonService } from "./services";
import { environment } from "../environments/environment";
import { DOCUMENT } from "@angular/common";
import { Theme, ThemeContent, COMMUNICATIONS_OTP, APICONSTANTS } from './constants';
import { VersionCheckService } from './services/version-check.service';
import { HostTheme, Storage } from "./utils";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  hostTheme = Theme;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private versionCheckService: VersionCheckService,
    private _storage: Storage,
    private _hostTheme: HostTheme,
    // private swUpdate: SwUpdate,
    // private _authService: AuthService,
    @Inject(DOCUMENT) private _document: HTMLDocument) {
    const theme = this._hostTheme.Theme()
    this._storage.set('local', 'UItheme', theme)
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this._document.getElementById("appFavicon").setAttribute("href", ThemeContent[theme].favicon);
        this.commonService.setTitle(`${ThemeContent[theme].tabTitle} | Providing a platform to empower employees to be informed healthcare shoppers`);
      }
    });
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: Event) {
    const loggedInUser = this._storage.get('local', 'loggedInUser');
    if (loggedInUser && event['currentTarget'].location.pathname !== APICONSTANTS.LOGIN) {
      const tabsUsed = JSON.parse(this._storage.get('local', 'tabsUsed'));
      if (tabsUsed) {
        tabsUsed !== 1
          ? this._storage.set('local', 'tabsUsed', JSON.stringify(tabsUsed - 1))
          : (
            this._storage.remove('local', 'tabsUsed'),
            this._storage.remove('local', 'isInactiveModalShown')
          )
      }
    }
  }

  @HostListener('window:load', ['$event'])
  onLoad(event: Event) {
    const loggedInUser = this._storage.get('local', 'loggedInUser');
    if (loggedInUser) {
      const tabsUsed = JSON.parse(this._storage.get('local', 'tabsUsed'));
      this._storage.set('local', 'tabsUsed', JSON.stringify(tabsUsed + 1));
    }
  }

  ngOnInit(): void {
    let urlRoute: any
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) urlRoute = event.url
    });
    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage || event.storageArea === sessionStorage) {
        const token = localStorage.getItem('loggedInUser');
        const otpToken = sessionStorage.getItem('otpToken');
        if (token === undefined && !(urlRoute.includes('set-password') || urlRoute.includes('sso-login') || urlRoute.includes('privacy-policy') || urlRoute.includes('terms-of-use') || urlRoute.includes('mobile-activation') || urlRoute.includes(COMMUNICATIONS_OTP))) {
          !otpToken && this.router.navigate(['/login']);
        }
      }
    });
    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
  }
}

