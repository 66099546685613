<app-loader [loader]="loading"></app-loader>
<section
  class="grey lighten-4 donotprint"
  [ngClass]="{'pr-3': usermode}"
  *ngIf="episode && episode.id"
  id="episodeDesc">
  <div class="pl-3 py-3 pr-2 form-row m-0 justify-content-between">
    <div class="col col-sm-6">
      <h5 class="mb-0" [attr.data-test-id]="'episode-name'">
        {{ episode?.name }} <span *ngIf="episode.tag">{{ '- ' + episode.tag }}</span>
      </h5>
      <label
        class="z-depth-0"
        [attr.data-test-id]="'episode-bundle-name'"
        *ngIf="episode?.episodeOfCare?.bundle?.name">
        <mdb-icon fas icon="cubes"></mdb-icon> {{ episode?.episodeOfCare?.bundle?.name }}
      </label>
      <p class="mt-3 mb-0">{{ episode?.description }}</p>
    </div>
    <div
      class="col-auto mr-3 mb-3 mb-sm-0"
      [ngClass]="!usermode ? 'mr-5' : ''"
      *ngIf="episode?.episodeOfCare?.bundle?.name">
      <h6
        class="font-weight-500 mb-0 pointer-cursor"
        (click)="openProviderDetail(episode.facilityId, providerType.facility)">
        {{ episode?.facility?.name }}
      </h6>
      <div class="list-content border-0 donotprint">
        <div class="field-group pt-0 pb-0" *ngIf="episode?.provider?.name">
          <label class="text-nowrap" for="pname"
            ><i class="icofont-doctor text-muted"></i> <span>Provider Name</span></label
          >
          <div class="field-value" id="pname">
            <p
              class="mb-0 pointer-cursor lh-sm text-dark font-weight-500"
              (click)="openProviderDetail(episode.providerId, providerType.provider)">
              {{ episode?.provider?.name }}
            </p>
          </div>
        </div>
        <div class="field-group pt-0 pb-0" *ngIf="episode?.clinic?.name">
          <label class="text-nowrap" for="cname"
            ><i class="icofont-hospital text-muted"></i> <span>Clinic Name </span></label
          >
          <div class="field-value" id="cname">
            <p
              class="mb-0 pointer-cursor lh-sm font-weight-500 text-dark"
              (click)="openProviderDetail(episode.clinicId, providerType.clinic)">
              {{ episode?.clinic?.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="d-block d-sm-flex justify-content-between align-items-center border-top pl-3 pt-1 pb-1">
    <div class="d-sm-flex justify-content-start">
      <span class="d-flex justify-content-between align-items-center" *ngIf="episode?.ccName">
        <span class="mr-3">
          <small class="text-muted" [attr.data-test-id]="'care-cordinator'">Care Cordinator</small>
          <h6 class="font-weight-500 mb-0">{{ episode?.ccName }}</h6>
        </span>
        <span class="mr-3">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="!enableChat"></span>
          <button
            *ngIf="enableChat"
            class="z-depth-0 mt-0 mb-0 btn btn-sm btn-light border"
            type="button"
            [ngClass]="{isDisabled: !enableChat || user.id === episode.ccId}"
            (click)="openChat(episode.ccId)"
            mdbWavesEffect>
            <i class="fas fa-comment-alt pointer-cursor"></i> Chat
          </button>
        </span>
      </span>
      <hr class="d-block d-sm-none" />
      <span
        class="d-flex justify-content-between align-items-center"
        *ngIf="episode.esId !== episode.ccId && episode?.esName">
        <span class="mr-3">
          <small class="text-muted">Engagement Specialist</small>
          <h6 class="font-weight-500 mb-0">{{ episode?.esName }}</h6>
        </span>
        <span class="mr-3">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="!enableChat"></span>
          <button
            *ngIf="enableChat"
            class="z-depth-0 mt-0 mb-0 btn btn-sm btn-light border"
            type="button"
            [ngClass]="{isDisabled: !enableChat || user.id === episode.esId}"
            (click)="openChat(episode.esId)"
            mdbWavesEffect>
            <i class="fas fa-comment-alt pointer-cursor"></i> Chat
          </button>
        </span>
      </span>
      <hr class="d-block d-sm-none" *ngIf="episode.esId !== episode.ccId && episode?.esName" />
    </div>
    <div class="d-sm-flex justify-content-end">
      <div class="d-block d-sm-flex justify-content-start align-items-center">
        <!--Potential Member Responsibility -->
        <span class="mr-sm-3 d-flex align-items-center" *ngIf="usermode">
          <i class="icofont-dollar icofont-2x text-primary mr-2"></i>

          <span>
            <small class="text-muted">Potential Member Responsibility</small>
            <h6 class="font-weight-500 mb-0">
              {{
                episode?.patientResponsibility ? (episode.patientResponsibility | currency) : 'N/A'
              }}
            </h6>
          </span>
        </span>

        <div
          *ngIf="user.roleCode != 'MP'"
          class="d-flex justify-content-start align-items-center pointer-cursor"
          [class.mr-4]="episode?.purchaserCode === purchaserCodes.carrum">
          <span class="mr-4">
            <small class="text-muted" [attr.data-test-id]="'status'">Status</small>
            <h6 class="font-weight-500 mb-0">{{ episode?.status | titlecase }}</h6>
          </span>
          <i class="icofont-user icofont-2x text-primary mr-2"></i>
          <span>
            <small class="text-muted" [attr.data-test-id]="'patients-name'">Patient Name</small>
            <h6 (click)="ViewPatientDemographic(episode.userId)" class="font-weight-500 mb-0">
              {{ episode?.patientName }}
            </h6>
          </span>
          <span
            *ngIf="episode?.purchaserCode !== purchaserCodes.carrum"
            mdbTooltip="Last Login: {{
              userLastLoginDate ? (userLastLoginDate | date : 'medium') : 'N/A'
            }}"
            html="true"
            ><i class="icofont-history icofont-2x mr-3 ml-1 text-info"></i
          ></span>
        </div>
        <div class="d-sm-flex mt-3 mt-sm-0 mb-3 mb-sm-0">
          <!-- Patient Chat button -->
          <span *ngIf="user.roleCode != 'MP'">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="!enableChat"></span>
            <button
              *ngIf="enableChat && episode?.purchaserCode !== purchaserCodes.carrum"
              [ngClass]="{isDisabled: !enableChat}"
              (click)="openChat(episode?.patient?.id)"
              class="border z-depth-0 mt-0 mb-0 btn btn-sm btn-light"
              type="button"
              mdbWavesEffect>
              <i class="fas fa-comment-alt pointer-cursor"></i> Chat
            </button>
          </span>
          <!-- Patient Chat button -->
          <span *ngIf="user.roleCode != 'MP'">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="!enableChat"></span>
            <button
              (click)="onSendEmail(episode.id)"
              class="border z-depth-0 mt-0 mb-0 btn btn-sm btn-light"
              type="button"
              mdbWavesEffect>
              <i class="fa-fa fa-envelope fas" [attr.data-test-id]="'send-email'"></i> Send Email
            </button>
          </span>
          <!-- Patient Id Card -->
          <button
            *ngIf="
              episode?.purchaserCode !== purchaserCodes.carrum &&
              episode.status.toLowerCase() !== 'cancelled'
            "
            (click)="viewIdCard(episode)"
            class="border z-depth-0 mt-0 mb-0 btn btn-sm btn-light"
            type="button"
            mdbWavesEffect>
            <i class="icofont-id-card" [attr.data-test-id]="'view-id-card'"></i> View ID
          </button>

          <!--View EOB-->
          <button
            *ngIf="
              episode?.purchaserCode !== purchaserCodes.carrum &&
              episode?.eobDate &&
              episode?.client?.generateEob &&
              episode.status.toLowerCase() !== 'cancelled'
            "
            (click)="viewEOB(episode)"
            class="border z-depth-0 mt-0 mb-0 btn btn-sm btn-light"
            type="button"
            mdbWavesEffect>
            <i class="icofont-eye" [attr.data-test-id]="'view-eod'"></i> View EOB
          </button>
          <!-- Notes -->
          <button
            type="button"
            *ngIf="user && user.roleCode != 'MP'"
            class="btn btn-light border btn-sm waves-light mt-0 mb-0"
            data-toggle="modal"
            data-target="#basicExample"
            (click)="OpenNotes()"
            mdbWavesEffect>
            <i class="icofont-ui-note" [attr.data-test-id]="'notes'"></i> Notes
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  class="container-fluid h-md-auto donotprint"
  *ngIf="episode && episode.id"
  [ngStyle]="{height: 'calc(100vh - ' + '(' + episodeDescHeight + 'px + 98px)' + ')'}">
  <div class="row">
    <div
      class="col-sm-4 col-md-4 col-lg-3 pr-sm-0"
      [hidden]="usermode && isMobile && selected_milestone && selected_milestone['id']">
      <div
        class="sticky-content mt-2 p-0 pr-sm-2 scrollbar scrollbar-primary thin h-md-auto"
        [ngStyle]="{height: 'calc(100vh - ' + '(' + episodeDescHeight + 'px + 98px)' + ')'}">
        <div class="position-sticky header">
          <div class="d-flex justify-content-between h-auto pb-2">
            <h6 class="mb-0">Milestones</h6>
          </div>
          <div class="form-group has-search mt-2 mb-2 position-relative">
            <span class="form-control-feedback">
              <i class="icofont-search-1"></i>
            </span>
            <input
              type="text"
              [(ngModel)]="searchKey"
              class="form-control"
              id="search"
              [attr.data-test-id]="'milestone-search'"
              placeholder="Search" />
          </div>

          <div class="d-flex justify-content-between mt-3">
            <label class="mt-2">Show</label>
            <mdb-select
              [attr.data-test-id]="'episode-milestone-all-button'"
              class="w-50 bg-white"
              [visibleOptions]="allMilestonesList.length"
              placeholder="All"
              [options]="allMilestonesList"
              (selected)="filterMilestones($event)">
            </mdb-select>
          </div>
        </div>
        <div
          class="text-center p-4 border mt-3"
          *ngIf="
            !loading &&
            episode.milestones.length > 0 &&
            displayMilestones &&
            displayMilestones.length > 0 &&
            (displayMilestones | filter : searchKey : ['name', 'description']).length == 0
          ">
          <i class="icofont-tasks-alt icofont-3x text-black-50"></i>
          <p>No results found.</p>
        </div>
        <div
          class="text-center p-4 border mt-3"
          *ngIf="
            !loading &&
            episode.milestones.length > 0 &&
            displayMilestones &&
            displayMilestones.length === 0
          ">
          <i
            class="icofont-tasks-alt icofont-3x text-black-50"
            [attr.data-test-id]="'no-milestones-available'"></i>
          <p>No Milestones Available.</p>
        </div>
        <div *ngIf="episode && displayMilestones && displayMilestones.length > 0">
          <mdb-card
            [attr.data-test-id]="'episode-milestone-card'"
            class="mb-3 z-depth-0 pointer-cursor skin-light hoverable"
            *ngFor="
              let milestone of displayMilestones | filter : searchKey : ['name', 'description'];
              let i = index
            "
            [ngClass]="{
              'active skin-primary text-white border-0 ': selected_milestone
                ? selected_milestone['uuid'] === milestone['uuid']
                : ''
            }"
            (click)="!loading && goToMileStoneTopic(milestone)"
            id="milestone{{ milestone.uuid }}">
            <mdb-card-header
              [attr.data-test-id]="'episode-milestone-card-header'"
              class="pr-2 pl-2"
              [ngClass]="{'': !usermode}">
              <div class="mb-0 d-flex justify-content-between">
                <div class="d-flex">
                  <span *ngIf="milestone.isKeyMilestone" class="bg-info mr-1 rounded-sm flag">
                    <i class="icofont icofont-flag-alt-1 text-white"></i>
                  </span>
                  <h6 [attr.data-test-id]="'episode-milestone-name'">{{ milestone.name }}</h6>
                </div>
                <div
                  class="d-flex align-items-center justify-content-end"
                  [attr.data-test-id]="'epiosde-status'">
                  <div *ngIf="milestone.status">
                    <!-- [ngClass]="{'text-green':milestone.status=='COMPLETED'}" -->

                    <span class="font-italic float-right mt-0">
                      <button
                        [attr.data-test-id]="'episode-milestone-completed-button'"
                        *ngIf="milestone.status == 'COMPLETED'"
                        class="btn btn-link p-1 m-0 mr-1"
                        type="button"
                        mdbWavesEffect
                        mdbTooltip="Completed"
                        placement="top">
                        <i
                          class="fas a-check-circle fa-lg text-success"
                          [ngClass]="{'fa-check-circle': milestone.status == 'COMPLETED'}"></i>
                      </button>
                      <button
                        [attr.data-test-id]="'episode-milestone-in-progress-button'"
                        *ngIf="milestone.status == 'IN PROGRESS'"
                        class="btn btn-link p-1 m-0 mr-1"
                        type="button"
                        mdbWavesEffect
                        mdbTooltip="In Progress"
                        placement="top">
                        <i class="fas fa-clock fa-lg text-warning"></i>
                      </button>
                      <button
                        [attr.data-test-id]="'episode-milestone-due-button'"
                        *ngIf="milestone.status == 'DUE'"
                        class="btn btn-link p-1 m-0 mr-1"
                        type="button"
                        mdbWavesEffect
                        mdbTooltip="Due"
                        placement="top">
                        <i class="fas fa-clock fa-lg text-danger"> </i>
                      </button>
                      <button
                        [attr.data-test-id]="'episode-milestone-completed-reason-button'"
                        *ngIf="milestone.completedByCcEs"
                        class="btn btn-link p-1 m-0 mr-1"
                        type="button"
                        mdbWavesEffect
                        mdbTooltip="{{ getMilestoneCompletedText(milestone) }}"
                        placement="top">
                        <i class="fas fa-exclamation-triangle fa-lg text-info"></i>
                      </button>
                    </span>
                  </div>
                  <div *ngIf="showMilestoneActions(milestone)" class="custom-dropdown">
                    <button
                      class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                      type="button"
                      mdbWavesEffect>
                      <mdb-icon
                        class="skin-link"
                        [attr.data-test-id]="'milestone-ellipsis'"
                        fas
                        icon="ellipsis-v"></mdb-icon>
                    </button>
                    <div
                      [attr.data-test-id]="'episode-milestone-status-action-dropdown'"
                      class="custom-dropdown-content custom-dropdown-content-md rounded-sm z-depth-2">
                      <div *ngIf="canChangeMilestoneStatus(milestone)">
                        <a
                          *ngIf="!milestone.completedByCcEs && milestone.status !== 'COMPLETED'"
                          class="cursor-pointer dropdown-item"
                          (click)="addCompleteReason(i)"
                          ><i
                            class="fas fa-check-circle"
                            [attr.data-test-id]="'completed-milestone'"></i>
                          Complete Milestone</a
                        >

                        <a
                          *ngIf="canUndoMilestoneComplete(milestone)"
                          class="cursor-pointer dropdown-item"
                          (click)="saveUndoCompletionReason(milestone)"
                          ><i class="fas fa-check-circle"></i> Undo Complete Milestone
                        </a>

                        <div class="p-2" *ngIf="milestone.showReasonOption">
                          <mdb-select
                            [attr.data-test-id]="'episode-milestone-reason-code-select'"
                            [options]="reasonList"
                            placeholder="Select reason"
                            [(ngModel)]="milestone.reasonCode"
                            [multiple]="false"
                            [enableSelectAll]="false"
                            [highlightFirst]="false"
                            (selected)="saveUndoCompletionReason(milestone)">
                          </mdb-select>
                        </div>
                      </div>
                      <span
                        [attr.data-test-id]="'episode-milestone-action-dropdown'"
                        *ngIf="!milestone.status && !milestone.triggerDatePassed">
                        <a
                          [attr.data-test-id]="'episode-milestone-edit'"
                          (click)="editMilestone(milestone, i)"
                          class="cursor-pointer dropdown-item"
                          ><i class="icofont-edit-alt"></i> Edit</a
                        >
                        <a
                          [attr.data-test-id]="'episode-milestone-delete'"
                          (click)="milestoneToDelete(milestone)"
                          class="cursor-pointer dropdown-item">
                          <i class="icofont-ui-delete"></i> Delete</a
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body class="p-2">
              <mdb-card-text [attr.data-test-id]="'episode-milestone-description'">
                <p
                  [ngClass]="{
                    'text-white': selected_milestone
                      ? selected_milestone['id'] == milestone['id']
                      : ''
                  }">
                  {{ milestone?.description }}
                </p>
              </mdb-card-text>
            </mdb-card-body>

            <mdb-card-footer class="p-2">
              <!-- milestone date-picker if condition -->
              <mdb-date-picker
                [attr.data-test-id]="'episode-milestone-datepicker'"
                *ngIf="
                  !usermode &&
                  utilityAccess.searchAccess('EM', 'isEditable', false) &&
                  (user.roleCode === 'PA'
                    ? episode.status.toLowerCase() !== 'cancelled'
                    : !milestone.isTaskDisabled)
                "
                #datePicker
                [inline]="true"
                name="mydate"
                class="d-inline-block bg-transparent episode-milestone-picker"
                id="milestone{{ milestone.id }}"
                [attr.data-test-id]="'milestone-date'"
                [options]="myDatePickerOptions"
                (dateChanged)="setMilestoneDate($event, milestone)"
                [placeholder]="'Select milestone date'"
                [(ngModel)]="milestone.showDate"
                required></mdb-date-picker>
              <!-- milestone date-picker else condition -->
              <small
                class="mt-2 d-inline-block"
                *ngIf="
                  usermode ||
                  !utilityAccess.searchAccess('EM', 'isEditable', false) ||
                  (user.roleCode === 'PA'
                    ? episode.status.toLowerCase() == 'cancelled'
                    : milestone.isTaskDisabled)
                ">
                <mdb-icon far icon="calendar-alt"></mdb-icon> {{ milestone?.showDate }}
              </small>
            </mdb-card-footer>
          </mdb-card>
        </div>
        <div class="text-center p-4 border mt-3" *ngIf="episode && episode.milestones.length == 0">
          <i class="icofont-tasks-alt icofont-4x text-black-50"></i>
          <p>
            <!-- Add milestone to create tasks for that milestone. -->
            No Milestones Available.
          </p>
        </div>
      </div>
    </div>
    <div class="col pl-0">
      <app-episode-topic
        #episodeTopic
        *ngIf="selected_milestone && selected_milestone['id']"
        [usermode]="usermode"
        [isMobile]="isMobile"
        [milestones]="episode.milestones"
        [selected_milestone]="selected_milestone"
        [assignedBy]="assignedTo"
        [episodeDescHeight]="episodeDescHeight"
        [assignedTo]="episode.userId"
        (setMilestoneView)="setMilestoneViewHandler($event)"
        [userDetails]="episode.patient || episode.claimPatient">
      </app-episode-topic>
    </div>
  </div>
  <button
    [attr.data-test-id]="'episode-milestone-go-back-button'"
    class="btn btn-light btn-lg btn-block waves-light border position-sticky mt-3 mx-auto"
    mdbWavesEffect
    *ngIf="usermode && isMobile && !(selected_milestone && selected_milestone['id'])"
    (click)="goBack()">
    Back to Episode
  </button>
</section>

<div
  mdbModal
  #milestoneModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" [attr.data-test-id]="'add-topic-form'">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ milestoneForm.value.id ? 'Edit' : 'Add' }} Milestone</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="milestoneForm">
          <div class="form-row">
            <div class="col-7">
              <div class="form-group">
                <label for="MilestoneName">Milestone Name *</label>
                <input
                  type="text"
                  id="MilestoneName"
                  class="form-control"
                  mdbInput
                  formControlName="name"
                  [ngClass]="{'is-invalid': submitted && milestoneForm.get('name').invalid}" />
              </div>
            </div>
            <div class="col-5">
              <div class="form-group">
                <label for="MilestoneName">Group</label>
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="loadingGroups"></span>
                <app-select
                  [options]="groups"
                  [allowClear]="true"
                  [id]="'groupCode'"
                  (onDeselected)="onGroupDeselected()"
                  [disabled]="loadingGroups"
                  [control]="milestoneForm.controls['groupCode']"></app-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="milestone-date">Milestone Date</label>
            <div class="form-row">
              <div class="col">
                <input
                  type="text"
                  id="triggerDays"
                  class="form-control mt-2"
                  mdbInput
                  formControlName="triggerDays"
                  [readonly]="
                    milestoneForm.value.id &&
                    (milestoneForm.value.startDate || editMilestoneStatus == 'IN PROGRESS')
                  " />
              </div>
              <div class="col">
                <mdb-select
                  [options]="triggerPeriodOptions"
                  class="mt-2"
                  formControlName="triggerPeriod"
                  [disabled]="
                    milestoneForm.value.id &&
                    (milestoneForm.value.startDate || editMilestoneStatus == 'IN PROGRESS')
                  ">
                </mdb-select>
              </div>
              <div class="col">
                <mdb-select
                  [options]="triggerConditionOptions"
                  class="mt-2"
                  formControlName="triggerCondition"
                  [disabled]="
                    milestoneForm.value.id &&
                    (milestoneForm.value.startDate || editMilestoneStatus == 'IN PROGRESS')
                  ">
                </mdb-select>
              </div>
              <div class="col-5">
                <mdb-select
                  [options]="triggerMilestoneOptions"
                  class="mt-2"
                  formControlName="triggerMilestoneUuid"
                  [disabled]="
                    milestoneForm.value.id &&
                    (milestoneForm.value.startDate || editMilestoneStatus == 'IN PROGRESS')
                  "
                  (deselected)="deselectMilestone($event)"
                  [allowClear]="true">
                </mdb-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="MilestoneDesc">Description</label>
            <textarea
              type="text"
              id="MilestoneDesc"
              class="md-textarea form-control"
              mdbInput
              formControlName="description"></textarea>
          </div>
          <div class="switch mt-3">
            <label class="d-flex justify-content-between">
              <label>Trigger at the start of the episode</label>
              <input type="checkbox" formControlName="isTriggerOnStart" disabled />
              <span class="lever"></span>
            </label>
          </div>
          <div class="switch mt-3">
            <label class="d-flex justify-content-between">
              <label>Set as Primary Milestone</label>
              <input type="checkbox" formControlName="isKeyMilestone" disabled />
              <span class="lever"></span>
            </label>
          </div>
          <div class="switch mt-3">
            <label class="d-flex justify-content-between">
              <label>Set as Main Procedure</label>
              <input type="checkbox" formControlName="isMainProcedure" disabled />
              <span class="lever"></span>
            </label>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2">
        <button
          class="waves-light m-0 btn-light btn"
          [attr.data-test-id]="'episode-milestone-cancel-button'"
          mdbWavesEffect
          (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          [attr.data-test-id]="'episode-milestone-submit-button'"
          mdbWavesEffect
          (click)="submitMilestone()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ milestoneForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--begin notes section-->
<div
  mdbModal
  #noteModal="mdbModal"
  class="modal fade right"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}"
  (close)="closeNoteModal('hide')">
  <div
    mwlResizable
    class="modal-dialog modal-full-height fixed-height modal-right modal-notify mw-100"
    [validateResize]="validate"
    [ngStyle]="style"
    [enableGhostResize]="true"
    [resizeEdges]="{left: true}"
    [resizeSnapGrid]="{width: 400}"
    (resizeEnd)="onResizeEnd($event)"
    role="document">
    <div class="modal-content bg-light">
      <!--Header-->
      <div class="modal-header z-depth-1 position-sticky header">
        <p class="mb-0 lead">Notes</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeNoteModal('close')">
          <i class="icofont-close-line"></i>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body p-2 scrollbar scrollbar-primary thin">
        <app-loader [loader]="notesloading"></app-loader>
        <div id="notesSection" #notesSection>
          <ul class="list-unstyled" *ngIf="!notesloading && notes.length > 0">
            <li class="p-1" *ngFor="let note of notes | orderBy : 'createdDate'">
              <div
                (click)="
                  note.type &&
                    note.type.toLowerCase() === COMMUNICATIONS &&
                    onNoteSelected(note.referenceId)
                "
                id="{{ note.id }}-newNote"
                class="list-content step-content bg-white border border-light ml-xl-0 p-2 rounded hover">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="avatar-wrapper d-flex justify-content-start">
                    <div class="profile-photo user-online">
                      <i class="icofont-ui-user text-info"></i>
                    </div>
                    <div class="text-smaller ml-2 text-black-50">
                      <p class="font-weight-bold">
                        {{ note?.fullName }}
                      </p>
                      <p class="text-muted">
                        {{ note.createdDate | date : 'MM/dd/yyyy hh:mm a' }}
                      </p>
                      <span
                        class="z-depth-0 badge badge-info badge-pill"
                        *ngIf="note.displayType || note.type">
                        {{
                          note.displayType
                            ? (note.displayType | titlecase)
                            : (note.type | titlecase)
                        }}
                      </span>
                    </div>
                  </div>
                  <span class="action-item position-relative d-none">
                    <i
                      *ngIf="
                        !READ_ONLY_NOTE_TYPES.includes(note.type.toLowerCase()) &&
                        note.createdBy == user.id
                      "
                      class="icofont-ui-delete icofont-sm mr-2"
                      (click)="noteToBeDelete(note)"></i>
                  </span>
                </div>
                <hr class="mt-2 mb-2" />
                <div *ngIf="note.type?.toLowerCase() !== 'signature'" [innerHtml]="note.note"></div>
                <strong>
                  <p *ngIf="note.documents && note.documents.length > 0" class="pt-1">
                    <i class="icofont-link mr-2"></i>Attachments
                  </p>
                </strong>
                <ol>
                  <li class="font-weight-400" *ngFor="let file of note.documents">
                    <a
                      class="text-break"
                      href="{{ file.documentPath }}"
                      target="_blank"
                      (click)="$event.stopPropagation()"
                      >{{ file.name }}</a
                    >
                  </li>
                </ol>
              </div>
            </li>
          </ul>
          <div class="text-center p-2 border mt-1" *ngIf="!notesloading && notes.length == 0">
            <i class="icofont-ui-note icofont-3x text-black-50"></i>
            <p>Start adding notes to this epsiode.</p>
          </div>
        </div>
      </div>
      <div
        class="modal-footer position-sticky d-block z-depth-2"
        *ngIf="this.utilityAccess.searchAccess('EM', 'isEditable', false)">
        <button
          class="btn btn-secondary btn-block waves-light"
          type="button"
          (click)="expandClass()"
          mdbWavesEffect>
          {{ notesstatus ? 'Minimize Note' : 'Add Note'
          }}<i *ngIf="notesstatus" class="icofont-rounded-down"></i>
        </button>
        <div class="mt-3 ml-0" [ngClass]="notesstatus ? 'expand' : 'collapse'">
          <!--Add new notes-->
          <form [formGroup]="noteForm">
            <div id="newNote">
              <mdb-select
                [options]="noteTypes | sort : 'label'"
                class="border border-light mb-1"
                formControlName="type"
                placeholder="Select note type"></mdb-select>
              <angular-editor [config]="editorConfig" formControlName="note"></angular-editor>
              <div class="file-path-wrapper mt-3">
                <p>Attachments</p>
                <div class="list-attachments sticky-content scrollbar scrollbar-primary thin">
                  <ul class="list-group list-group-flush" *ngIf="documents && documents.length > 0">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center text-small pl-0 pr-0"
                      *ngFor="let file of documents; let i = index">
                      <div class="d-flex">
                        <i class="icofont-file-document mr-1 text-primary fa-lg fa-fw"></i>
                        <span class="text-break">{{ file.displayName }}</span>
                      </div>
                      <button
                        type="button"
                        class="btn btn-link p-0"
                        mdbWavesEffect
                        (click)="removeDocument(file, i)">
                        <i class="icofont-close-line icofont-lg"></i>
                      </button>
                    </li>
                  </ul>
                  <ul class="list-group" *ngIf="holistaMedia && holistaMedia.length > 0">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center text-small pl-0 pr-0"
                      *ngFor="let file of holistaMedia; let i = index">
                      <div class="d-flex">
                        <i class="icofont-file-document mr-1 text-primary fa-lg fa-fw"></i>
                        <span class="text-break">{{ file.displayName }} </span>
                      </div>
                      <button
                        type="button"
                        class="btn btn-link p-0"
                        mdbWavesEffect
                        (click)="removeDocument(file, i)">
                        <i class="icofont-close-line icofont-lg"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-2">
                <button
                  (click)="addAttachFile()"
                  class="btn btn-secondary btn-outline btn-sm m-0"
                  mdbWavesEffect>
                  <i class="icofont-attachment"></i> Attach File
                </button>
                <button
                  class="btn btn-secondary btn-sm m-0"
                  mdbWavesEffect
                  [disabled]="submit_enabled || !noteForm.value.note"
                  (click)="saveNote()">
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="submit_enabled"></span>
                  <mdb-icon far icon="paper-plane" *ngIf="!submit_enabled"></mdb-icon> Submit
                </button>
              </div>
            </div>
          </form>
          <!--Add new notes-->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Attach-File-modal-->
<div
  mdbModal
  #attachModal="mdbModal"
  class="modal fade top"
  id="topicModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100 mb-0">Attach File</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeAttachModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="attachForm">
          <div class="radio-block form-row mb-3">
            <div class="col">
              <label class="form-check-label" for="link">
                <input
                  type="radio"
                  class="form-check-input"
                  id="link"
                  name="type"
                  value="link"
                  formControlName="type"
                  (change)="changeAttachment()"
                  mdbInput />
                <div class="box m-0 d-flex justify-content-center align-items-center">
                  <i class="icofont-link-alt"></i> External Link
                </div>
              </label>
            </div>
            <div class="col">
              <label class="form-check-label" for="existing">
                <input
                  type="radio"
                  class="form-check-input"
                  id="existing"
                  name="type"
                  value="holistalibrary"
                  (change)="changeAttachment()"
                  formControlName="type"
                  mdbInput />
                <div class="box m-0 d-flex justify-content-center align-items-center">
                  <i class="icofont-files-stack"></i> Existing document
                </div>
              </label>
            </div>
            <div class="col">
              <label class="form-check-label" for="upload">
                <input
                  type="radio"
                  class="form-check-input"
                  id="upload"
                  name="type"
                  value="uploadFile"
                  formControlName="type"
                  (change)="changeAttachment()"
                  mdbInput />
                <div class="box m-0 d-flex justify-content-center align-items-center">
                  <i class="icofont-upload"></i> Upload File
                </div>
              </label>
            </div>
          </div>

          <div class="form-group mt-0">
            <label for="TopicName">File Display Name *</label>
            <input
              type="text"
              id="TopicName"
              class="form-control"
              mdbInput
              formControlName="displayName"
              [readonly]="attachForm.value.type == 'holistalibrary'"
              [ngClass]="{'is-invalid': submitted && attachForm.get('displayName').invalid}" />
          </div>

          <div *ngIf="attachForm.value.type == 'uploadFile'" class="form-group">
            <div class="form-group">
              <mdb-file-upload
                (fileRemoved)="onFileRemove()"
                (fileAdded)="onFileAdd($event)"
                [ngClass]="{
                  'is-invalid': submitted && attachForm.get('uploadPath').invalid
                }"></mdb-file-upload>
            </div>
          </div>

          <div *ngIf="attachForm.value.type == 'link'" class="form-group">
            <label for="fileUrl">File/Video Reference Url *</label>
            <input
              type="text"
              id="fileUrl"
              class="form-control"
              mdbInput
              formControlName="documentPath"
              [ngClass]="{'is-invalid': submitted && attachForm.get('documentPath').invalid}" />
          </div>

          <div
            *ngIf="attachForm.value.type == 'holistalibrary'"
            class="form-group position-relative">
            <label>Choose Document *</label>
            <input
              type="text"
              [ngModel]="document_search_text | async"
              (ngModelChange)="document_search_text.next($event)"
              [ngModelOptions]="{standalone: true}"
              class="completer-input form-control mdb-autocomplete mb-0"
              id="autocompleteDocument"
              [mdbAutoCompleter]="auto"
              placeholder="Type to select document"
              [ngClass]="{
                'is-invalid': submitted && attachForm.get('holistalibraryPath').invalid
              }" />
            <mdb-auto-completer
              #auto="mdbAutoCompleter"
              textNoResults="No document found"
              [displayValue]="onDisplayValue"
              (selected)="onDocumentSelect($event)">
              <mdb-option *ngFor="let option of document_name_results | async" [value]="option">
                <div class="d-flex flex-column" mdbTooltip="{{ option.name }}" placement="right">
                  <strong>{{ option.name }} </strong>
                </div>
              </mdb-option>
            </mdb-auto-completer>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2">
        <button
          class="waves-light m-0 btn-light btn"
          [attr.data-test-id]="'episode-milestone-cancel-button'"
          mdbWavesEffect
          (click)="closeAttachModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          [attr.data-test-id]="'episode-milestone-submit-button'"
          mdbWavesEffect
          (click)="submitAttachFile()">
          <i class="icofont-check-circled"></i> Done
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Attach-File-modal-->
<!--end notes section-->

<!-- EPISODE CANCEL MODAL -->
<app-milestone-topic-delete
  [deleteData]="dataToBeDelete"
  (deletedData)="delete($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>
<!-- EPISODE CANCEL MODAL -->

<!-- SHOW ID CARD MODAL-->
<app-id-card [patientIdCard]="patientIdCard" (idCardClose)="idCardClose($event)"> </app-id-card>
<!-- SHOW ID CARD MODAL-->

<!-- PATIENT DETAIL MODAL -->
<app-patient-demographic
  [userId]="patientDemographicId"
  (hidePatientDemographic)="closePatientDemographic($event)">
</app-patient-demographic>
<!-- PATIENT DETAIL MODAL -->

<!-- VIEW EOB MODAL-->
<app-view-eob [episodeForEOB]="episodeEOB" (viewEOBClose)="viewEOBClose($event)"> </app-view-eob>
<!-- VIEW EOB MODAL-->
<!-- Milestone date warning MODAL-->
<div
  mdbModal
  #milestoneDateWarningModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <iframe *ngIf="isDateWarningModalShown" class="main-frame"></iframe>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <iframe *ngIf="isDateWarningModalShown" class="modal-frame"></iframe>
      <div class="modal-header">
        <h5 class="modal-title w-100">Warning</h5>
        <button
          type="button"
          class="close modal-btn"
          [attr.data-test-id]="'close-icon'"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeMilestoneDateWarningModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <p
          *ngIf="
            selectedMilestone?.triggerDatePassed && selectedDate?.jsdate > selectedDate?.currentDate
          ">
          Changing the date on this milestone ({{ selectedMilestone?.name }}) will change the dates
          on all those milestones that are linked to this one. Are you sure you want to change the
          date to {{ selectedDate?.actualDateFormatted }}?
        </p>
        <p *ngIf="selectedDate?.jsdate < selectedDate?.currentDate">
          The selected date appears to be today's date or in the past. Are you sure you want to set
          the milestone date for "{{ selectedMilestone?.name }}" to
          {{ selectedDate?.actualDateFormatted }}?
        </p>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2">
        <button
          type="button"
          class="waves-light m-0 btn-light btn modal-btn"
          [attr.data-test-id]="'episode-milestone-cancel-button'"
          mdbWavesEffect
          (click)="closeMilestoneDateWarningModal()">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-secondary waves-light m-0 modal-btn"
          [attr.data-test-id]="'episode-milestone-submit-button'"
          mdbWavesEffect
          (click)="updateMilestoneDate(selectedDate, selectedMilestone)"
          [disabled]="updateMilestoneDateLoading">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="updateMilestoneDateLoading"></span>
          Update
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Milestone date warning MODAL-->

<!-- PROVIDER DETAIL MODAL -->
<app-provider-detail
  [provider]="selectedProvider"
  (hideProviderDetail)="closeProviderDetail($event)">
</app-provider-detail>
<!-- PROVIDER DETAIL MODAL -->

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [moduleOptions]="moduleOptions"
  [metaVariablesDetail]="metaVariablesDetail"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->

<!--- Email events popup -->
<div
  mdbModal
  #messageThreadModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Communications</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeMessageModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div
        class="modal-body sticky-content scrollbar scrollbar-primary thin pt-0"
        [ngStyle]="{
          height: 'calc(100vh - 220px)'
        }">
        <app-loader [loader]="isLoading"></app-loader>
        <app-message-view
          *ngIf="!isLoading && messageThread.length"
          [messageThread]="messageThread"></app-message-view>
      </div>
      <div class="modal-footer d-flex flex-end border-top-0 pt-0"></div>
    </div>
  </div>
</div>
<!--- Email events popup -->
