import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { isEditAnswerEnable } from 'src/app/utils';

@Component({
  selector: 'app-episode-user-question',
  templateUrl: './episode-user-question.component.html',
  styleUrls: ['./episode-user-question.component.scss']
})
export class EpisodeUserQuestionComponent implements OnInit {
  questions: any;
  dependent_tasks = [];
  isEditAnswerEnable = isEditAnswerEnable
  @Output() updateQuestionAnswer = new EventEmitter<any>();
  @Input() dependentTopicTasks;
  @Input() user;
  @Input() set question(question) {
    question.questions.ansOpt.map(y => {
      if (question.questions.questionTypes.code === 'RADIO' || question.questions.questionTypes.code === 'CHECKBOX') {
        const optionId = y.answerOptionId ? y.answerOptionId : y;
        question.questions.questionOptions.map(z => {
          if (optionId === z.id) {
            y.answerValue = z.optionValue;
            y.taskQuesUuid = z.taskQuesUuid;
            y.taskQnrsUuid = z.taskQnrsUuid;
            y.taskMessageUuid = z.taskMessageUuid;
            y.taskTodoUuid = z.taskTodoUuid;
            y.taskSignatureUuid = z.taskSignatureUuid;
            this.getChilds(z);
          }
          return z;
        });
      } else {
        y.answerValue = y.answer;
      }
      return y;
    });
    this.questions = question;
  }

  constructor() { }

  ngOnInit() { }
  editAnswer(editAnswer) {
    this.updateQuestionAnswer.emit(editAnswer);
  }

  getChilds(task) {
    setTimeout(() => {
      if (this.dependentTopicTasks && this.dependentTopicTasks.length > 0) {
        task.taskQuesUuid && this.addDepenedent(task, 'taskQuesUuid');
        task.taskQnrsUuid && this.addDepenedent(task, 'taskQnrsUuid');
        task.taskMessageUuid && this.addDepenedent(task, 'taskMessageUuid');
        task.taskTodoUuid && this.addDepenedent(task, 'taskTodoUuid');
        task.taskSignatureUuid && this.addDepenedent(task, 'taskSignatureUuid');
      }
    }, 500);
  }
  addDepenedent(task, taskType) {
    const dependentTask = this.dependentTopicTasks.find(y => y.uuid === task[taskType]);
    if (dependentTask) {
      const dependentExist = this.dependent_tasks.find(tsk => tsk.uuid === dependentTask.uuid);
      if (!dependentExist) this.dependent_tasks.push(dependentTask);
    }
  }

}
