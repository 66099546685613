import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CHART_CUSTOM_COLORS, KPI_VALUES, LINE_CHART_OPTIONS, MODULES, MONTHS_NAME_FULL, MessageConstants, NO_DATA_IN_REPORT, REPORT_NAME_CODES, REPORT_TAB_INDEX } from '../../../../constants';
import { ChartUtils, DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility } from '../../../../utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MarketService, ReportService } from '../../../../services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-network-cycle-time',
  templateUrl: './network-cycle-time.component.html',
  styleUrls: ['./network-cycle-time.component.scss']
})
export class NetworkCycleTimeComponent implements OnInit {

  reportNameAndCodeList: any[] = [];
  parsedClientClaimsFilterData: any;
  toShowReport: any[] = [];
  baseConfigs = {
    options: {},
    plugins: [],
    labels: [],
    dataset: [],
    chartType: '',
    actualData: null,
  };
  overallServiceCategoryCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  providerCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  marketCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  expand = {
    overallServiceCategoryCycleTime: false,
    providerCycleTime: false,
    marketCycleTime: false
  };
  loading = {
    overallServiceCategoryCycleTime: false,
    providerCycleTime: false,
    marketCycleTime: false,
    categories: false,
    providerList: false,
    marketList: false
  };
  REPORT_NAME_CODES = REPORT_NAME_CODES;
  categoryList: any[] = [];
  providerList: any[] = [];
  marketList: any[] = [];
  yearOptions: number[] = [];
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT);
  overallServiceCategoryCycleTimeForm: FormGroup;
  providerCycleTimeForm: FormGroup;
  marketCycleTimeForm: FormGroup;

  @Input() set clientClaimsDashboardFilterData(data) {
    if (data && data.activeTabIndex === REPORT_TAB_INDEX.NETWORK_CYCLE_TIMES) {
      this.parsedClientClaimsFilterData = this._holistaUtils.deepClone(data);
      const { toShowReport, endDate, networkCode } = this.parsedClientClaimsFilterData;
      this.toShowReport = toShowReport && toShowReport.map((x) => x.reportCode);
      this.reportNameAndCodeList = toShowReport && toShowReport.map((x) => ({
        reportName: x.customReportName,
        reportCode: x.reportCode
      }));
      const year = +this._dateUtility.getYearFromDate(endDate);
      const additionalConditons = toShowReport.length && networkCode && year;

      if (this.toShowReport.includes(REPORT_NAME_CODES.OVERALL_SERVICE_CATEGORY_CYCLE_TIME) && additionalConditons) {
        this.overallServiceCategoryCycleTimeForm.controls['selectedYear'].setValue(year);
        this.getOverallServiceCategoryCycleTime();
      };

      if (this.toShowReport.includes(REPORT_NAME_CODES.PROVIDER_CYCLE_TIME) && additionalConditons && this.providerCycleTimeForm.get('providerId').value.length) {
        this.providerCycleTimeForm.controls['selectedYear'].setValue(year);
        this.getProviderCycleTimeChart(true);
      };

      if (this.toShowReport.includes(REPORT_NAME_CODES.MARKET_CYCLE_TIME) && additionalConditons && this.marketCycleTimeForm.get('marketId').value.length) {
        this.marketCycleTimeForm.controls['selectedYear'].setValue(year);
        this.getMarketCycleTimeChart(true);
      }
      this.getCategoryList();
      this.getMarketList();
    }
  }

  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === REPORT_TAB_INDEX.NETWORK_CYCLE_TIMES) {
      const { networkName, networkCode, startDate, endDate } = this.parsedClientClaimsFilterData;
      const tabsData = [];

      if (this.toShowReport.includes(REPORT_NAME_CODES.OVERALL_SERVICE_CATEGORY_CYCLE_TIME) && this.overallServiceCategoryCycleTimeChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.overallServiceCategoryCycleTimeChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Overall Service Category Cycle Time',
          tabName: 'Overall Service Category Cycle Time',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.overallServiceCategoryCycleTimeForm.value.selectedYear}`,
        });
      };

      if (this.toShowReport.includes(REPORT_NAME_CODES.PROVIDER_CYCLE_TIME) && this.providerCycleTimeChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.providerCycleTimeChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Provider Cycle Times',
          tabName: 'Provider Cycle Times',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.providerCycleTimeForm.value.selectedYear}`,
        });
      };

      if (this.toShowReport.includes(REPORT_NAME_CODES.MARKET_CYCLE_TIME) && this.marketCycleTimeChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.marketCycleTimeChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Market Cycle Times',
          tabName: 'Market Cycle Times',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.marketCycleTimeForm.value.selectedYear}`,
        });
      };

      if (isDownloadPDF.type === 'pdf') {
        const downloadPDF = this._pdfUtility.convertToPDF(['network-cycle-time'], 'Client Claims Dashboard - Network Cycle Time', true, true);
        downloadPDF.finally(() => {
          this.downloadedPDF.emit(true);
        });
      }

      if (tabsData.length && isDownloadPDF.type === 'excel') {
        const params = {
          networkCode,
          year: +this._dateUtility.getYearFromDate(endDate)
        };
        this._reportService.getNetworkCycleTimeDetail(params)
          .pipe()
          .subscribe(response => {
            console.log('responseNT', response)
            tabsData.push({
              clientName: networkName,
              reportName: 'Network Cycle Time Detail',
              tabName: 'Network Cycle Time Detail',
              headers: ['Client Code', 'Market Code', 'Patient ID', 'Patient Name', 'Episode ID', 'Episode Name', 'Provider ID', 'Provider NPI', 'Provider Name', 'Bundle UUID', 'Bundle Name', 'Bundle Component Group', 'Claim ID', 'Service Date', 'Claim Created Date', 'Business Days (DOS-Claim Created Date)', 'Month', 'Year'],
              data: response.map(detail => [detail.clientCode, detail.marketCode, detail.patientId, detail.patientName, detail.episodeId, detail.episodeName, detail.providerId, detail.providerNpi, detail.providerName, detail.bundleUuid, detail.bundleName, detail.bundleComponentGroup, detail.claimId, detail.serviceDate, detail.claimCreatedDate, detail.days, detail.monthName, detail.year]),
              reportDate: `Period: ${startDate} - ${endDate}`
            });
            this._excelMultiTabUtility.exportToExcelMultiTab('Client Claims Dashboard - Network Cycle Time', tabsData).finally(() => {
              this.downloadedPDF.emit(true);
            })
          },
            error => console.log('error', console.log('error getting network cycle time detail', error))
          );
      };
    }
  }

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();

  constructor(
    private _holistaUtils: HolistaUtils,
    private _dateUtility: DateUtility,
    private _formBuilder: FormBuilder,
    private _messageConstants: MessageConstants,
    private _reportService: ReportService,
    private _marketService: MarketService,
    private _chartUtils: ChartUtils,
    private _reportMappingUtils: ReportMappingUtility,
    private _pdfUtility: PDFUtility,
    private _excelMultiTabUtility: ExcelMultiTabUtility
  ) { }

  ngOnInit(): void {
    this.setOverallServiceCategoryCycleTimeForm();
    this.setProviderCycleTimeForm();
    this.setMarketCycleTimeForm();
  }

  ngOnDestroy() {
    this.resetFlags();
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
  }

  resetFlags() {
    this.loading = {
      ...this.loading,
      overallServiceCategoryCycleTime: false,
      providerCycleTime: false,
      marketCycleTime: false,
    }
  }

  resetAllReports() {
    this.overallServiceCategoryCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.providerCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.marketCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  setOverallServiceCategoryCycleTimeForm() {
    this.overallServiceCategoryCycleTimeForm = this._formBuilder.group({
      selectedYear: [null],
      categoryId: [[]]
    });
  }

  setProviderCycleTimeForm() {
    this.providerCycleTimeForm = this._formBuilder.group({
      categoryId: [null],
      selectedYear: [null],
      providerId: [[]]
    });
  }

  setMarketCycleTimeForm() {
    this.marketCycleTimeForm = this._formBuilder.group({
      categoryId: [null],
      selectedYear: [null],
      marketId: [[]]
    });
  }

  getOverallServiceCategoryCycleTime(resetDate: boolean = true, isCategorySelected: boolean = true) {
    this.loading.overallServiceCategoryCycleTime = true;
    !isCategorySelected && (this.overallServiceCategoryCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs));
    const params = {
      networkCode: this.parsedClientClaimsFilterData.networkCode,
      bundleComponentCategoryIds: [this.overallServiceCategoryCycleTimeForm.get('categoryId').value],
      year: this.overallServiceCategoryCycleTimeForm.get('selectedYear').value
    };
    this._reportService.getOverallServiceCategoryCycleTimeChart(params)
      .pipe(finalize(() => {
        this.loading.overallServiceCategoryCycleTime = false;
        resetDate && this.getYearSelected();
        this.updateDownloadEnabledState();
      }))
      .subscribe((response: any) => {
        if (response) {
          this.setOverallServiceCategoryCycleTimeChart(response);
        }
      })
  }

  getChartConfigurations(index: number, label: string) {
    return {
      "borderWidth": 2,
      "fill": false,
      "lineTension": 0,
      "spanGaps": true,
      "backgroundColor": CHART_CUSTOM_COLORS[index],
      "borderColor": CHART_CUSTOM_COLORS[index],
      "pointBackgroundColor": CHART_CUSTOM_COLORS[index],
      "pointHoverBackgroundColor": CHART_CUSTOM_COLORS[index],
      "label": label
    }
  }

  setOverallServiceCategoryCycleTimeChart(params) {
    this.overallServiceCategoryCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);

    if (params.dataSets?.length) {
      params.dataSets.map((dataSet, index: number) => {
        this.overallServiceCategoryCycleTimeChart.dataset.push({
          ...this.getChartConfigurations(index, dataSet.label),
          data: dataSet.datas,
        });
      });
      this.overallServiceCategoryCycleTimeChart.options = LINE_CHART_OPTIONS;
      this.overallServiceCategoryCycleTimeChart.chartType = 'line';
      this.overallServiceCategoryCycleTimeChart.labels = params.labels;
      this.overallServiceCategoryCycleTimeChart.actualData = params;
      this.overallServiceCategoryCycleTimeChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.PROVIDER_CYCLE_TIMES);
    };
  }

  setProviderCycleTimeChart(params) {
    this.providerCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);

    if (params.dataSets?.length) {
      params.dataSets.map((dataSet, index: number) => {
        this.providerCycleTimeChart.dataset.push({
          ...this.getChartConfigurations(index, dataSet.label),
          data: dataSet.datas,
        });
      });
      this.providerCycleTimeChart.options = LINE_CHART_OPTIONS;
      this.providerCycleTimeChart.chartType = 'line';
      this.providerCycleTimeChart.labels = params.labels;
      this.providerCycleTimeChart.actualData = params;
      this.providerCycleTimeChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.PROVIDER_CYCLE_TIMES);
    };
  }

  setMarketCycleTimeChart(params) {
    this.marketCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);

    if (params.dataSets?.length) {
      params.dataSets.map((dataSet, index: number) => {
        this.marketCycleTimeChart.dataset.push({
          ...this.getChartConfigurations(index, dataSet.label),
          data: dataSet.datas,
        });
      });
      this.marketCycleTimeChart.options = LINE_CHART_OPTIONS;
      this.marketCycleTimeChart.chartType = 'line';
      this.marketCycleTimeChart.labels = params.labels;
      this.marketCycleTimeChart.actualData = params;
      this.marketCycleTimeChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.PROVIDER_CYCLE_TIMES);
    };
  }

  getYearSelected(isYearSelected = false) {
    const { startDate, endDate } = this.parsedClientClaimsFilterData;
    const startYear = +this._dateUtility.getYearFromDate(startDate);
    const endYear = +this._dateUtility.getYearFromDate(endDate);

    if (startYear && endYear) {
      this.yearOptions = this._holistaUtils.getYearOptionList(startYear, endYear);
    };
    this.overallServiceCategoryCycleTimeForm.controls['selectedYear'].setValue(endYear);
    this.providerCycleTimeForm.controls['selectedYear'].setValue(endYear);
    this.marketCycleTimeForm.controls['selectedYear'].setValue(endYear);
  }

  getReport(code: string) {
    return this.reportNameAndCodeList.find(report => report.reportCode === code) || null;
  }

  onViewToggle(reportCode: string, expand = false) {
    // this.reportExpanded.emit(expand); // Uncomment if download is not needed while expanding
    switch (reportCode) {
      case REPORT_NAME_CODES.OVERALL_SERVICE_CATEGORY_CYCLE_TIME:
        this.expand.overallServiceCategoryCycleTime = !this.expand.overallServiceCategoryCycleTime;
        break;
      case REPORT_NAME_CODES.PROVIDER_CYCLE_TIME:
        this.expand.providerCycleTime = !this.expand.providerCycleTime;
        break;
      case REPORT_NAME_CODES.MARKET_CYCLE_TIME:
        this.expand.marketCycleTime = !this.expand.marketCycleTime;
        break;
      default:
        break;
    }
  }

  onCategorySelected(reportType: string) {
    if (reportType === REPORT_NAME_CODES.MARKET_CYCLE_TIME && this.marketCycleTimeForm.get('marketId').value.length) {
      this.getMarketCycleTimeChart(true);
      return;
    };

    if (reportType === REPORT_NAME_CODES.PROVIDER_CYCLE_TIME) {
      this.providerCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
      this.providerCycleTimeForm.controls['providerId'].setValue([]);
      this.loading.providerList = true;
      const params = {
        networkCode: this.parsedClientClaimsFilterData.networkCode,
        bundleComponentGroup: this.providerCycleTimeForm.get('categoryId').value
      };
      this._reportService.getClaimProviders(params)
        .pipe(finalize(() => this.loading.providerList = false))
        .subscribe((response: any) => {
          this.providerList = response.map(({ providerName, providerId, providerNpi }) => ({ label: `${providerName} - (N)${providerNpi}`, value: providerId }));
        }, error => console.log('error getting provider list', error))
    };
  }

  getMarketList() {
    this.loading.marketList = true;
    this.marketCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
    const params = {
      networkCode: this.parsedClientClaimsFilterData.networkCode
    };
    this._marketService.getAll(params)
      .pipe(finalize(() => this.loading.marketList = false))
      .subscribe((response: any) => {
        this.marketList = response.rows.map(({ name, code }) => ({ label: `${name} - ${code}`, value: code }));
      }, error => console.log('error getting market list', error))
  }

  /**
   * get bundle component categories
   */
  getCategoryList() {
    this._reportService.getBundleComponentCategories({ networkCode: this.parsedClientClaimsFilterData.networkCode })
      .pipe(finalize(() => { this.loading.categories = false }))
      .subscribe((response: any) => {
        if (response) {
          this.categoryList = response.map(category => ({ label: category.name, value: category.code }));
        };
      })
  }

  getProviderCycleTimeChart(isProviderSelected: boolean = false, resetDate: boolean = true, isYearSelected: boolean = false) {
    if (this.providerCycleTimeForm.get('categoryId').value && ((isYearSelected && this.providerCycleTimeForm.get('providerId').value.length) || isProviderSelected)) {
      this.loading.providerCycleTime = true;
      !isProviderSelected && (this.providerCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs));
      const params = {
        networkCode: this.parsedClientClaimsFilterData.networkCode,
        bundleComponentCategoryId: this.providerCycleTimeForm.get('categoryId').value,
        providerIds: [this.providerCycleTimeForm.get('providerId').value],
        year: this.providerCycleTimeForm.get('selectedYear').value
      };
      this._reportService.getProviderCycleTimeChart(params)
        .pipe(finalize(() => {
          this.loading.providerCycleTime = false;
          resetDate && this.getYearSelected();
          this.updateDownloadEnabledState();
        }
        ))
        .subscribe((response: any) => {
          if (response) {
            this.setProviderCycleTimeChart(response);
          }
        })
    };
  }

  getMarketCycleTimeChart(isMarketSelected: boolean = false, resetDate: boolean = true, isYearSelected: boolean = false) {
    if (this.marketCycleTimeForm.get('categoryId').value && ((isYearSelected && this.marketCycleTimeForm.get('marketId').value.length) || isMarketSelected)) {
      this.loading.marketCycleTime = true;
      !isMarketSelected && (this.marketCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs));
      const params = {
        networkCode: this.parsedClientClaimsFilterData.networkCode,
        bundleComponentCategoryId: this.marketCycleTimeForm.get('categoryId').value,
        marketCodes: [this.marketCycleTimeForm.get('marketId').value],
        year: this.marketCycleTimeForm.get('selectedYear').value
      };
      this._reportService.getMarketCycleTimeChart(params)
        .pipe(finalize(() => {
          this.loading.marketCycleTime = false;
          resetDate && this.getYearSelected();
          this.updateDownloadEnabledState();
        }
        ))
        .subscribe((response: any) => {
          if (response) {
            this.setMarketCycleTimeChart(response);
          }
        })
    };
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = (
      this.overallServiceCategoryCycleTimeChart.labels.length ||
      this.providerCycleTimeChart.labels.length ||
      this.marketCycleTimeChart.labels.length
    );
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

  onCategoryDeselected(reportType: string) {
    if (reportType === REPORT_NAME_CODES.PROVIDER_CYCLE_TIME) {
      this.providerList.length = 0;
      this.providerCycleTimeForm.controls['categoryId'].setValue(null);
      this.providerCycleTimeForm.controls['providerId'].setValue([]);
      this.providerCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
      return;
    };
    this.marketCycleTimeForm.controls['categoryId'].setValue(null);
    this.marketCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  noProviderCycleTimeChartData(): boolean {
    return !this.loading.providerCycleTime && this.providerCycleTimeForm.get('providerId').value.length > 0 && !this.providerCycleTimeChart.dataset.length;
  }

  noOverallServiceCategoryCycleTimeChartData(): boolean {
    return !this.loading.overallServiceCategoryCycleTime && this.overallServiceCategoryCycleTimeForm.get('categoryId').value.length > 0 && !this.overallServiceCategoryCycleTimeChart.dataset.length;
  }

  noMarketCycleTimeChartData(): boolean {
    return !this.loading.marketCycleTime && this.marketCycleTimeForm.get('categoryId').value && this.marketCycleTimeForm.get('marketId').value.length > 0 && !this.marketCycleTimeChart.dataset.length;
  }

  showOverallServiceCategoryCycleTime(): boolean {
    return !this.expand.providerCycleTime && !this.expand.marketCycleTime;
  }

  showProviderCycleTime(): boolean {
    return !this.expand.overallServiceCategoryCycleTime && !this.expand.marketCycleTime;
  }

  showMarketCycleTime(): boolean {
    return !this.expand.overallServiceCategoryCycleTime && !this.expand.providerCycleTime;
  }

  showOverallServiceCategoryCycleTimeChart(): boolean {
    return !this.loading.overallServiceCategoryCycleTime && this.overallServiceCategoryCycleTimeChart.dataset.length && true;
  }

  showProviderCycleTimeChart(): boolean {
    return !this.loading.providerCycleTime && this.providerCycleTimeChart.dataset.length && true;
  }

  showMarketCycleTimeChart(): boolean {
    return !this.loading.marketCycleTime && this.marketCycleTimeChart.dataset.length && true;
  }

  marketCycleChartCriteriaNotFulfilled(): boolean {
    return !this.marketCycleTimeForm.get('categoryId').value || !this.marketCycleTimeForm.get('marketId').value.length;
  }

}
