import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ClaimsService, ToasterService } from '../../../../services';
import { APPROVED, ClaimsInfo, SUBMITTED } from '../../../../constants';
import { PDFUtility } from '../../../../utils';

@Component({
  selector: 'app-ub04-form',
  templateUrl: './ub04-form.component.html',
  styleUrls: ['./ub04-form.component.scss']
})
export class Ub04FormComponent implements OnInit {

  @Input() claimId: string;
  approveUB04: boolean = false;
  @Input() set approveUB04Flag(data) {
    this.approveUB04 = data;
    if (data) {
      this.generateUB04PDF();
    }
  }
  downloadUB04FormOrInvoicePDF: boolean = false;
  @Input() set downloadUB04FormOrInvoicePDFFlag(data) {
    this.downloadUB04FormOrInvoicePDF = data;
    if (data) {
      this.downloadInvoiceOrUB04PDF();
    }
  }
  ub04Detail: any = {};
  loading: boolean = false;
  @Output() sendApproveFlagEvent = new EventEmitter();
  @Output() sendDownloadPdfFlagEvent = new EventEmitter();
  @Output() getInvoiceCoverSheet = new EventEmitter();
  carrumHealth = ClaimsInfo.carrumHealth;
  showInvoiceCoverSheet: boolean = false;

  constructor(
    private claimsService: ClaimsService,
    private _toastr: ToasterService,
    private pdfUtility: PDFUtility,
  ) { }

  ngOnInit() {
    this.fetchUB04Detail();
  }

  fetchUB04Detail() {
    this.loading = true;
    this.claimsService.getUB04Details(this.claimId)
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe((res: any) => {
        this.ub04Detail = res;
        this.ub04Detail.patientDetail = {
          patientName: this.ub04Detail.subscriber.firstName
            ? `${this.ub04Detail.subscriber.lastName} ${this.ub04Detail.subscriber.middleName} ${this.ub04Detail.subscriber.firstName}`
            : `${this.ub04Detail.patient.lastName} ${this.ub04Detail.patient.middleName} ${this.ub04Detail.patient.firstName}`
        }
      }, (error) => {
        console.log('Error fetting UB04 Details.', error);
        this._toastr.showError(`${error.error.message}`);
      })
  }

  files: any[] = []

  async generateUB04PDF() {
    this.files = [];
    let pdfConvertedFile;
    if (this.showInvoiceCoverSheet) {
      pdfConvertedFile = await this.pdfUtility.convertToPDF(['invoice-cover-sheet', 'ub04-wrapper'], this.ub04Detail.claim.patientAccountNumber);
    } else pdfConvertedFile = await this.pdfUtility.convertToPDF(['ub04-wrapper'], this.ub04Detail.claim.patientAccountNumber);
    this.files.push(pdfConvertedFile);
    this.sendCoverSheetOrUB04Pdf();
  }

  downloadInvoiceOrUB04PDF() {
    if (this.showInvoiceCoverSheet) {
      this.pdfUtility.convertToPDF(['invoice-cover-sheet', 'ub04-wrapper'], 'UB04-' + this.ub04Detail.claim.patientAccountNumber, true);
    } else this.pdfUtility.convertToPDF(['ub04-wrapper'], 'UB04-' + this.ub04Detail.claim.patientAccountNumber, true);
    this.sendDownloadPdfFlagEvent.emit({ isCompleted: true, downloadUB04FormOrInvoicePDF: false });
  }

  sendCoverSheetOrUB04Pdf() {
    this.claimsService.approveClaim(this.claimId, { files: this.files }, 'UB04')
      .subscribe((res) => {
        if (res) {
          this._toastr.displaySuccess(SUBMITTED);
          this.approveUB04 = false;
          this.sendApproveFlagEvent.emit({ approveUB04: false, approved: true })
        }
      }, (error) => {
        this.approveUB04 = false;
        this.sendApproveFlagEvent.emit({ approveUB04: false, approved: false })
        this._toastr.showError(error);
        console.log('Error approving.', error);
      })
  }

  onInvoiceCoverSheetClicked(event) {
    if (event) {
      this.showInvoiceCoverSheet = event.checked;
      this.getInvoiceCoverSheet.next(event.checked);
    }
  }

  generateProcedureCodeWithModifier(institutionalService) {
    return `${institutionalService.procedureCode} ${institutionalService.modifier1} ${institutionalService.modifier2} ${institutionalService.modifier3} ${institutionalService.modifier4}`
  }

}
