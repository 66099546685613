import { Component, OnInit } from '@angular/core';
import { ClaimsService, ProviderService } from '../../../services';
import { MaskConstant } from '../../../constants';
import { debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { HolistaUtils, Storage } from '../../../utils'
import { Observable, of, Subject } from 'rxjs';
@Component({
  selector: 'app-claims-dashboard',
  templateUrl: './claims-dashboard.component.html',
  styleUrls: ['./claims-dashboard.component.scss']
})
export class ClaimsDashboardComponent implements OnInit {
  dashboardForm: NgForm;
  loading = {
    dashboard: false,
    provider: false,
    url: false
  };
  dateMask: any[] = MaskConstant.DATE;
  providerList = [];
  totalClaims: number = 0;
  user: any = {};
  claims: any = {
    valid: [],
    invalid: [],
    queue: [],
    inReview: [],
    fundRequested: [],
    fundRequestInProcess: [],
    paymentInReview: [],
    readyToPay: [],
    paymentInProcess: [],
    paid: [],
    rejected: [],
    denied: [],
    providers: []
  };
  urls: any = []
  searchModelChanged: Subject<string> = new Subject<string>();
  provider_search_text = new Subject();
  provider_results: Observable<any>;
  selectedProvider: any
  claimsDashboard = {
    fromDate: moment(new Date().setMonth(new Date().getMonth() - 3)).format('MM-DD-YYYY'),
    toDate: moment(new Date()).format('MM-DD-YYYY'),
    claimIdentifier: "",
    providerNpi: ""
  }
  isProviderSelected: boolean = false;

  constructor(
    private claimsService: ClaimsService,
    private _storage: Storage,
    private providerService: ProviderService,
    private _holistaUtility: HolistaUtils
  ) {
    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      this.claimsDashboard.claimIdentifier = data
      this.getClaimsDashboard(this.claimsDashboard);
    });
  }

  async ngOnInit() {
    this.user = this._storage.get('local', 'loggedInUser', 'user')
    this.getClaimsDashboard(this.claimsDashboard);
    await this.getUsers();
    this.geturls()
    this.provider_results = this.provider_search_text
      .pipe(
        startWith(this.provider_search_text),
        switchMap((provider_search_text: string) => this.searchProvider(provider_search_text))
      )
  }

  async getUsers() {
    await this.providerService.getProvidersByUserId(this.user.id)
      .then((response: any) => {
        if (response.length != 0) {
          this.providerList = response
        }
      })
      .catch((error) => {
        console.log("Error getting providers list by user id", error);
      })
  }

  getClaimsDashboard(body) {
    Object.keys(this.claims).forEach(key =>
      this.claims[key] = []
    )
    this.loading.dashboard = true;
    this.claimsService.getClaimsDashboard(body)
      .pipe(finalize(() => this.loading.dashboard = false))
      .subscribe((response: any) => {
        if (response) {
          this.totalClaims = response.data.claims.length;
          this.claims.providers = response.data.claims.filter((x, i) => x && (response.data.claims.findIndex(y => y.ediTransaction.billingProviderId == x.ediTransaction.billingProviderId) == i)).map(x => {
            let provider = {
              businessName: x.ediTransaction.billingProvider && x.ediTransaction.billingProvider.basicInfo.businessName ? x.ediTransaction.billingProvider.basicInfo.businessName : '',
              identificationCode: x.ediTransaction.billingProvider && x.ediTransaction.billingProvider.basicInfo.identificationCode ? x.ediTransaction.billingProvider.basicInfo.identificationCode : '',
              totalAmount: x.claimPayment ? x.claimPayment.payment.totalAmount : 0,
              paymentDate: x.claimPayment ? x.claimPayment.payment.paymentDate : ''
            }
            return provider;
          })
          if (this.selectedProvider) {
            const uniqueProvider = this.selectedProvider.basicInfo ? this.selectedProvider.basicInfo.businessName : this.selectedProvider.businessName
            this.claims.providers = this.claims.providers.filter(x => x.businessName.toLowerCase() == uniqueProvider.toLowerCase())
          }
          if (this.claims.providers && this.claims.providers.length > 0) {
            const providerBusinessNames = this.claims.providers.map(b => {
              return b.businessName
            })
            const providerIdentificationCodes = this.claims.providers.map(i => {
              return i.identificationCode
            })
            const uniqueClaims = response.data.claims.filter(item => providerBusinessNames.includes(item.ediTransaction.billingProvider.basicInfo.businessName) && providerIdentificationCodes.includes(item.ediTransaction.billingProvider.basicInfo.identificationCode));
            uniqueClaims.map(claim => {
              if (claim.status === 'VALID') {
                this.claims.valid.push(claim);
                switch (claim.processingStatus.toLowerCase()) {
                  case 'in-review':
                    this.claims.inReview.push(claim);
                    break;
                  case 'queue':
                    this.claims.queue.push(claim);
                    break;
                  case 'fund-request-in-process':
                    this.claims.fundRequestInProcess.push(claim);
                    break;
                  case 'fund-requested':
                    this.claims.fundRequested.push(claim);
                    break;
                  case 'ready-to-pay':
                    this.claims.readyToPay.push(claim); break;
                  case 'payment-in-process':
                    this.claims.paymentInProcess.push(claim); break;
                  case 'paid':
                    this.claims.paid.push(claim); break;
                  case 'denied':
                    this.claims.denied.push(claim); break;
                  case 'rejected':
                    this.claims.rejected.push(claim); break;
                }
              }
              else {
                this.claims.invalid.push(claim);
              }
            })
          }
        }
      }, (error) => {
        console.log("Error getting claims dashboard", error);
      })
  }

  setDate(action) {
    if (action == 'thisMonth') {
      this.claimsDashboard.fromDate = moment(new Date().setDate(1)).format('MM-DD-YYYY');
      this.claimsDashboard.toDate = moment(new Date()).format('MM-DD-YYYY');
    }
    else if (action == 'lastMonth') {
      this.claimsDashboard.fromDate = moment(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)).format('MM-DD-YYYY');
      this.claimsDashboard.toDate = moment(new Date().setMonth(new Date().getMonth(), 0)).format('MM-DD-YYYY');
    }
    else {
      this.claimsDashboard.fromDate = moment(new Date().setMonth(0, 1)).format('MM-DD-YYYY');
      this.claimsDashboard.toDate = moment(new Date()).format('MM-DD-YYYY');
    }
    this.getClaimsDashboard(this.claimsDashboard);
  }

  onProviderSelect(event) {
    this.isProviderSelected = true;
    this.selectedProvider = event.text
    this.claimsDashboard.providerNpi = event.text.npi ? event.text.npi : event.text.basicInfo.identificationCode
    this.getClaimsDashboard(this.claimsDashboard);
  }

  getDashboard() {
    this.getClaimsDashboard(this.claimsDashboard);
  }

  geturls() {
    this.loading.url = true
    this.claimsService.getUrls({ networkCode: this.user.referenceCode })
      .pipe(finalize(() => this.loading.url = false))
      .subscribe(res => {
        this.urls = res.rows
      },
        (error) => {
          console.log("Error", error);
        })
  }

  searchProvider(searchText: string) {
    if (searchText.length > 2) {
      return this.claimsService.searchBillingProviderList(searchText)
        .pipe(
          debounceTime(250),
          map((items: any) => {
            const providerNpiList = this.providerList.map(x => {
              return x.npi;
            })
            items = items.filter(x =>
              providerNpiList.includes(x.basicInfo.identificationCode)
            );
            const uniqueItems = this._holistaUtility.getUniqueList(items, 'basicInfo', ['identificationCode', 'city']);
            return uniqueItems;
          })
        );
    }
    else {
      return of([]);
    }
  }

  onDisplayValue = (data?): string | undefined => {
    return data.basicInfo.businessName;
  }

  toTitleCase(data) {
    return data ? (data.basicInfo ? data.basicInfo.businessName : data.businessName).replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    ) : undefined
  }

  providerChange(event) {
    if (!event.target.value) {
      this.claimsDashboard.providerNpi = ''
      this.selectedProvider = null
      this.getClaimsDashboard(this.claimsDashboard);
    }
    this.provider_search_text.subscribe((res: any) => {
      if (res.length < 3) {
        this.selectedProvider = null;
      }
    })
    if (event.target.value && this.selectedProvider) {
      this.provider_search_text.next(this.toTitleCase(this.selectedProvider))
    }
  }

  pasteSearchProvider() {
    this.selectedProvider = null
  }

  change(text) {
    this.searchModelChanged.next(text);
  }

  /**
   * clears the searched result
   * @param type 
   */
  removeSearchKeyword(type) {
    type === "claimID" ? (
      this.claimsDashboard.claimIdentifier = '',
      this.change('')
    ) : (
      this.claimsDashboard.providerNpi = '',
      this.provider_search_text.next(''),
      this.isProviderSelected && (
        this.getClaimsDashboard(this.claimsDashboard),
        this.isProviderSelected = false
      )
    )
  }
}
