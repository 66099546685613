import { Component, OnInit, Output, Input, ViewChild, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { EpisodeService, MilestoneService, ToasterService } from '../../services';
import { CANCEL_REASON, DELETE_SUCCESS } from '../../constants';
@Component({
  selector: 'app-milestone-topic-delete',
  templateUrl: './milestone-topic-delete.component.html',
  styleUrls: ['./milestone-topic-delete.component.scss']
})
export class MilestoneTopicDeleteComponent implements OnInit {
  dataToBeDelete: any
  submit_enabled = false
  episodeToBeCancel: any
  selected_Reason = ''
  cancelReasons = CANCEL_REASON
  user = null
  @Output() deletedData = new EventEmitter<any>();
  @Output() cancelDelete = new EventEmitter<any>();
  @Output() abortEpisodeCancel = new EventEmitter<any>();
  @Output() episodeCancelled = new EventEmitter<any>();
  @Output() sendDeleteConfirmation = new EventEmitter();
  @Input() set deleteData(data) {
    if (data) {
      this.dataToBeDelete = data
      this.deleteModal.show()
    }
  }
  @Input() set episodeCancel(data) {
    if (data) {
      this.episodeToBeCancel = data
      this.deleteModal.show()
    }
  }
  @Input() set userData(data) {
    if (data && data.id) {
      this.user = { ...data };
      this.deleteModal.show();
    }
  }
  @ViewChild('deleteModal', { static: true }) public deleteModal;

  constructor(private episodeService: EpisodeService,
    private _toastr: ToasterService,
    private milestoneService: MilestoneService
  ) { }

  ngOnInit() {
  }

  dataToDelete() {
    this.submit_enabled = true;
    let type = this.dataToBeDelete.type
    let noteType = (this.dataToBeDelete.noteType) ? this.dataToBeDelete.noteType : ''
    let data = {
      source: this.dataToBeDelete.source ? this.dataToBeDelete.source : '',
      episodeId: this.dataToBeDelete.episodeId ? this.dataToBeDelete.episodeId : null,
      pathwayId: this.dataToBeDelete.pathwayId ? this.dataToBeDelete.pathwayId : null,
      milestoneId: this.dataToBeDelete.milestoneId ? this.dataToBeDelete.milestoneId : null,
      id: this.dataToBeDelete.id,
      type: this.dataToBeDelete.type,
      topicId: this.dataToBeDelete.taskId
    }
    // To delete note
    if (noteType === 'Note') {
      this.milestoneService.deleteNote(this.dataToBeDelete).pipe(finalize(() => {
        this.submit_enabled = false;
        this.deleteModal.hide()
      })).subscribe(response => {
        this.dataDeleted()
        this._toastr.displaySuccess(DELETE_SUCCESS);
      })
    }
    // To delete milestone || topic || task
    else {
      if (data.source == 'Pathway') delete data.episodeId
      if (data.source == 'Episode') delete data.pathwayId
      this.episodeService.deleteMilestoneTopic(data).pipe(finalize(() => {
        this.submit_enabled = false;
        this.deleteModal.hide()
      })).subscribe(response => {
        this.dataDeleted()
        this._toastr.displaySuccess(DELETE_SUCCESS);
      },
        error => {
          console.log('error', error)
        })
    }
  }

  closeMilestoneModal() {
    if (this.dataToBeDelete) this.cancelDelete.emit(null)
    if (this.episodeToBeCancel) {
      this.abortEpisodeCancel.emit(this.episodeToBeCancel)
      this.selected_Reason = ''
    }
  }

  dataDeleted() {
    this.deletedData.emit(this.dataToBeDelete)
  }

  cancelledEpisode(response) {
    this.episodeCancelled.emit(response)
  }

  episodeToCancelled() {
    this.submit_enabled = true;
    let body = {
      episodeId: this.episodeToBeCancel.episode.id,
      episodeStatus: this.episodeToBeCancel.event.value,
      reason: this.selected_Reason,
      isCancelled: (this.episodeToBeCancel.event.value == 'CANCELLED') ? true : false
    }
    this.episodeService.updateEpisodeStatus(body).pipe(finalize(() => {
      this.submit_enabled = false;
      this.deleteModal.hide()
      this.selected_Reason = ''
    })).subscribe(response => {
      this.cancelledEpisode(response)
    })
  }

  selectedReason(reason) {
    this.selected_Reason = reason
  }

  closeModal(action) {
    if (action === 'Yes') {
      this.sendDeleteConfirmation.emit({ value: true, user: this.user });
    }
    else {
      this.sendDeleteConfirmation.emit({ value: false, user: this.user });
    }
    this.deleteModal.hide();
  }
}
