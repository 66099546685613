<div class="questionnaire" [ngClass]="{'second-level': optionId}" *ngIf="taskQnr && taskQnr.id">
  <div class="d-flex justify-content-start align-items-start">
    <div class="icon-block">
      <i class="icofont-clip-board icofont-lg skin-text-primary-dark"></i>
    </div>
    <div class="d-flex justify-content-between w-100 align-items-start">
      <span class="pl-2 pr-4 w-75">
        <p class="font-weight-400">
          {{ taskQnr?.qnrs?.name }}
        </p>
      </span>
      <div class="action-item position-relative episode-status">
        <div
          *ngIf="taskQnr.status && taskQnr.isActivated"
          class="chip chip-sm mb-0 ml-1 alert alert-info"
          [ngClass]="taskQnr.status ? taskQnr.status.replace(' ', '').toLowerCase() : ''">
          <span class="text-sm status-label">
            {{ taskQnr.status ? taskQnr.status?.toLowerCase() : '' }}
            {{ taskQnr.status == 'DUE' ? taskQnr.escalationDays : '' }}
          </span>
        </div>
        <span
          *ngIf="
            !(taskQnr.status == 'COMPLETED' || taskQnr.status == 'IN PROGRESS') &&
            !(
              taskQnr.episodeStatus == 'CLOSED' ||
              taskQnr.episodeStatus == 'CANCELLED' ||
              taskQnr.episodeStatus == 'COMPLETED'
            ) &&
            taskQnr.milestoneStatus !== 'IN PROGRESS' &&
            !taskQnr.triggerDatePassed
          ">
          <i
            class="icofont-ui-edit icofont-sm mr-2"
            #actionBtn
            (click)="editTaskHandler(taskQnr)"></i>
          <i
            class="icofont-ui-delete icofont-sm mr-2"
            #actionBtn
            (click)="deleteTaskHandler(taskQnr)"></i>
        </span>
      </div>
    </div>
  </div>
  <div class="list-content p-2" *ngIf="true" [hidden]="taskQnr && taskDragged">
    <div
      class="flex-row justify-content-start align-items-start"
      *ngIf="taskQnr?.qnrs?.qnrsQues.length > 0">
      <div
        class="list-content p-2"
        [ngClass]="{'second-level': true}"
        *ngFor="let qnrQ of taskQnr.qnrs.qnrsQues | orderBy : 'id'">
        <div class="d-flex justify-content-start align-items-start">
          <div class="icon-block">
            <i class="icofont-question-circle icofont-lg skin-text-primary-dark"></i>
          </div>
          <div class="d-flex justify-content-between w-100 align-items-center">
            <span class="pl-2 pr-4">
              <p class="font-weight-400">
                {{ qnrQ.question?.question }}
              </p>
              <p *ngIf="qnrQ.question.questionHelp">
                {{ qnrQ.question?.questionHelp }}
              </p>
              <p
                *ngIf="
                  qnrQ.question.status == 'COMPLETED' &&
                  qnrQ.question.questionTypes.code == 'TEXTFIELD'
                ">
                <strong>Answer :</strong>
                <span
                  [innerHtml]="
                    qnrQ.answer && qnrQ.answer.length > 200 && qnrQ.showMore
                      ? (qnrQ.answer | truncate : ['200', ' ...'])
                      : qnrQ.answer
                  "></span>
                &nbsp;&nbsp;<a
                  (click)="qnrQ.showMore = !qnrQ.showMore"
                  *ngIf="qnrQ.answer.length > 200">
                  <span class="z-depth-0 badge badge-primary badge-pill mdb-color lighten-1">
                    <i class="m-0 icofont-{{ qnrQ.showMore ? 'plus' : 'minus' }}"></i>
                  </span>
                </a>
              </p>
            </span>
            <!-- 
                        <span class="action-item position-relative d-none"><i class="icofont-ui-edit"
                                (click)="editTaskHandler(question)"></i></span> -->
          </div>
        </div>
        <div class="list-content-menu" [ngClass]="{'sub-level': optionId}">
          <div
            class="list-content-item"
            *ngFor="let option of qnrQ.question.questionOptions | orderBy : 'id'">
            <div
              class="d-flex justify-content-between align-items-start list-content-item-option pt-0 pb-0 mb-2"
              [ngClass]="{completed: qnrQ.question.status == 'COMPLETED' && option.status}">
              <p *ngIf="option.optionValue">
                <i
                  class="far fa-square mr-2"
                  *ngIf="
                    qnrQ.question.questionTypes.code == 'CHECKBOX' &&
                    (qnrQ.question.status != 'COMPLETED' || !option.status)
                  "></i>
                <i
                  class="far fa-circle mr-2"
                  *ngIf="
                    qnrQ.question.questionTypes.code == 'RADIO' &&
                    (qnrQ.question.status != 'COMPLETED' || !option.status)
                  "></i>
                <i
                  class="far fa-check-square mr-2 green-text"
                  *ngIf="
                    qnrQ.question.questionTypes.code == 'CHECKBOX' &&
                    qnrQ.question.status == 'COMPLETED' &&
                    option.status
                  "></i>
                <i
                  class="far fa-check-circle mr-2 green-text"
                  *ngIf="
                    qnrQ.question.questionTypes.code == 'RADIO' &&
                    qnrQ.question.status == 'COMPLETED' &&
                    option.status
                  "></i>
                {{ option.optionValue }}
              </p>
              <a
                class="add-action"
                #actionBtn
                (click)="addActionHandler(option.id, qnrQ.question.uuid)"
                *ngIf="
                  qnrQ.question.status != 'COMPLETED' &&
                  !(
                    taskQnr.episodeStatus == 'CLOSED' ||
                    taskQnr.episodeStatus == 'CANCELLED' ||
                    taskQnr.episodeStatus == 'COMPLETED'
                  ) &&
                  taskQnr.milestoneStatus !== 'IN PROGRESS' &&
                  !taskQnr.triggerDatePassed
                "
                >Add action</a
              >
            </div>
            <div
              class="list-content p-2"
              *ngIf="
                option.taskMessageUuid ||
                option.taskQuesUuid ||
                option.taskTodoUuid ||
                option.taskSignatureUuid ||
                option.taskQnrsUuid
              ">
              <div class="p-2 flex-row justify-content-start align-items-start dependent-task">
                <app-episode-todo
                  *ngIf="option.taskTodoUuid"
                  [optionId]="option.id"
                  [user]="user"
                  [dependent_task]="dependentTask"
                  [days_difference]="days_difference"
                  [taskTodoUuid]="option.taskTodoUuid"
                  (btns)="receiveBtns($event, 'todo')"
                  (editTask)="editTaskTodo($event)"
                  (deletedTask)="deleteTask($event)">
                </app-episode-todo>
                <app-episode-signature
                  *ngIf="option.taskSignatureUuid"
                  [optionId]="option.id"
                  [user]="user"
                  [dependent_task]="dependentTask"
                  [days_difference]="days_difference"
                  [taskSignatureUuid]="option.taskSignatureUuid"
                  (btns)="receiveBtns($event, 'signature')"
                  (editTask)="editTaskTodo($event)"
                  (deletedTask)="deleteTask($event)">
                </app-episode-signature>
                <app-episode-message
                  *ngIf="option.taskMessageUuid"
                  [optionId]="option.id"
                  [user]="user"
                  (btns)="receiveBtns($event, 'message')"
                  [dependent_task]="dependentTask"
                  [days_difference]="days_difference"
                  [taskMessageUuid]="option.taskMessageUuid"
                  (editTask)="editTaskMessage($event)"
                  (deletedTask)="deleteTask($event)">
                </app-episode-message>
                <app-episode-question
                  *ngIf="option.taskQuesUuid"
                  [optionId]="option.id"
                  [user]="user"
                  [dependent_task]="dependentTask"
                  [days_difference]="days_difference"
                  [taskQuesUuid]="option.taskQuesUuid"
                  (btns)="receiveBtns($event, 'question')"
                  (editTask)="editTaskHandler($event)"
                  (addAction)="addActionHandler($event)"
                  (deletedTask)="deleteTask($event)">
                </app-episode-question>
                <app-episode-questionnaire
                  *ngIf="option.taskQnrsUuid"
                  [optionId]="option.id"
                  [user]="user"
                  [dependent_task]="dependentTask"
                  [days_difference]="days_difference"
                  [taskQnrsUuid]="option.taskQnrsUuid"
                  (btns)="receiveBtns($event, 'questionnaire')"
                  (editTask)="editTaskHandler($event)"
                  (addAction)="addActionHandler($event)"
                  (deletedTask)="deleteTask($event)">
                </app-episode-questionnaire>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-milestone-topic-delete
  [deleteData]="taskToBeDelete"
  (deletedData)="deleteTask($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>
