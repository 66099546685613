import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import * as io from "socket.io-client";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Storage } from '../utils'

// const API_BASE_URL = "http://localhost:3004";
const API_BASE_URL = environment.notificationUrl;
// environment.notificationUrl
@Injectable({
  providedIn: "root"
})
export class NotificationService {
  socket: any;
  message$: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private http: HttpClient,
    private _storage: Storage
  ) {
    if (!this.socket) {
      this.socket = io(API_BASE_URL);
      this.socket.on("connect", function (socket) {
        // console.log('client connected')
      });
      this.socket.on("disconnect", function () {
      });
    }

    this.fetchNotification();
  }

  // createHeader() {
  //   let headers;
  //   const loggedInUser: any = this._storage.get('local', 'loggedInUser')
  //   if (loggedInUser) {
  //     const token = loggedInUser.token;
  //     headers = new HttpHeaders().set("Authorization", token);
  //   }
  //   return headers;
  // }

  getSocket() {
    return this.socket;
  }

  listen(event_name) {
    this.socket.on(event_name,
      data => {
        this.message$.next(data);
      });
    return this.message$.asObservable();
  }

  emit(event_name: string, data: any) {
    // console.log('user infor emit', event_name, data)
    this.socket.emit(event_name, data);
  }

  fetchNotification() {
    return new Observable(subscriber => {
      this.socket.on("notification", function (data) {
        // console.log('notification received in notification service', data)
        subscriber.next(data);
      });
    });
  }

  updateNotification(id) {
    return this.http
      .get(`${API_BASE_URL}/api/episodes/notification/seen/${id}`
        // , {
        //   headers: this.createHeader()
        // }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }


}
