import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { Router } from '@angular/router'
import { AdminDashboardEpisodes } from '../../../models'
import { RollingSevenDays, Sorting } from '../../../utils'
@Component({
  selector: 'app-admin-episode',
  templateUrl: './admin-episode.component.html',
  styleUrls: ['./admin-episode.component.scss']
})
export class AdminEpisodeComponent implements OnInit, OnChanges {
  @Input() episodes = []
  @Input() assignedUsers = []
  Episodes = new AdminDashboardEpisodes()
  sortingType = {
    preliminaryEpisodes: 'desc',
    confirmedEpisodes: 'desc',
    closedEpisodes: 'desc',
    cancelledEpisodes: 'desc',
    completedEpisodes: 'desc',
  }

  constructor(
    public router: Router,
    private rollingDaysUtility: RollingSevenDays,
    private utilitySorting: Sorting
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.filterEpisodes()
  }

  filterEpisodes() {
    this.Episodes = new AdminDashboardEpisodes()
    this.episodes.map(x => {
      if (x.status === 'PRELIMINARY') {
        this.Episodes.preliminaryEpisodes.push(x)
      }
      if (x.status === 'CONFIRMED') {
        this.Episodes.confirmedEpisodes.push(x)
      }
      if (x.status === 'CLOSED' && this.rollingDaysUtility.rollingDays(x.statusUpdatedDate)) {
        this.Episodes.closedEpisodes.push(x)
      }
      if (x.status === 'CANCELLED' && this.rollingDaysUtility.rollingDays(x.statusUpdatedDate)) {
        this.Episodes.cancelledEpisodes.push(x)
      }

      if (x.status === 'COMPLETED' && this.rollingDaysUtility.rollingDays(x.statusUpdatedDate)) {
        this.Episodes.completedEpisodes.push(x)
      }
      return x
    })
    // To initially sort in ascending order
    Object.keys(this.Episodes).forEach(x => {
      this.Episodes[x] = this.utilitySorting.sortByDate(this.Episodes[x], 'createdAt', 'desc')
    })
  }

  directToEpisodeDetail(episode) {
    this.router.navigate(['episodes', episode.id, 'milestone'])
  }

  /**
   * Sort episodes
   * @param type 
   * @param episodeType 
   */
  sortEpisodes(type: string, episodeType: string) {
    this.sortingType[episodeType] = type;
    this.utilitySorting.sortByDate(this.Episodes[episodeType], 'createdAt', type)
  }
}
