import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService, MemberService } from '../../../services';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HIDE_GROUP_NAME_LABEL, CLIENT_CODES } from 'src/app/constants';
@Component({
  selector: 'app-card-template',
  templateUrl: './card-template.component.html',
  styleUrls: ['./card-template.component.scss']
})
export class CardTemplateComponent implements OnInit {
  userProfile: any;
  idCards: any[] = [];
  loading = {
    idCard: false
  }
  printIdCard: any
  showText: boolean = false;
  hideGroupNameLabel = HIDE_GROUP_NAME_LABEL;
  clientCodes = CLIENT_CODES;

  @Input() set memberId(id) {
    if (id) this.getIdCardDetail(id)
  }

  @Input() set user(userData) {
    if (userData) {
      this.showText = true;
      this.getMyProfile(userData)
    }
  }

  @Output() myProfileDetail = new EventEmitter<any>()
  @Output() idCardDetail = new EventEmitter<any>()
  constructor(
    private userService: UserService,
    private memberService: MemberService,
    private toaster: ToastrService,
  ) { }

  ngOnInit() {
  }

  getIdCardDetail(data) {
    this.loading.idCard = true;
    const queryParam = data.episodeId ? { episodeId: data.episodeId } : ''
    this.userService.getIdCard(data.memberUuid ? data.memberUuid : data, queryParam)
      .pipe(finalize(() => { this.loading.idCard = false }))
      .subscribe((res: any) => {
        this.idCards = res.rows;
      }, (error) => {
        console.log('Error getting Id Card', error);
        this.showErrorToaster(error);
      })
  }

  showErrorToaster(error) {
    if (error.error.name.toLowerCase() == 'client error')
      this.toaster.warning(error.error.message, 'Warning');
    else
      this.toaster.error(error.error.message, 'Error');
  }

  getMyProfile(user) {
    this.userService.getUserById(user.id)
      .then(res => {
        this.userProfile = res;
        if (this.userProfile.memberUuid) {
          this.memberService.getMemberById(this.userProfile.memberUuid)
            .subscribe(res => {
              if (res) {
                this.userProfile.planCode = res.memberPlan.clientProgramCode
                // this.userProfile.companyCode = res.parentCompany.typeCode === 'PLATFORM_OWNER' ? res.companyCode : res.parentCompany.companyCode
                this.userProfile.dob = res.dob;
                this.userProfile.subscriberId = res.memberPlan.subscriberId;
                this.userProfile.memberName = (res.lastname && res.middlename) ? (res.firstname + ' ' + res.middlename + ' ' + res.lastname) : (res.lastname) ? (res.firstname + ' ' + res.lastname) : res.firstname;
              }
            })
        }
        this.myProfileDetail.emit(this.userProfile)
      });
  }

  public captureScreen(idCard) {
    this.printIdCard = idCard.id
    setTimeout(() => {
      window.print();
    });
  }
}
