import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private _toastr: ToastrService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        console.log('This is client side error');
                        errorMessage = `${error.error.message}`;
                    }
                    else {
                        console.log('This is server side error');
                        switch (error.status) {
                            case 400:
                                errorMessage = "Bad Request.";
                                break;
                            case 401:
                                errorMessage = "You need to log in to do this action.";
                                break;
                            case 403:
                                errorMessage = "You don't have permission to access the requested resource.";
                                break;
                            case 404:
                                errorMessage = "The requested resource does not exist.";
                                break;
                            case 412:
                                errorMessage = "Precondition Failed.";
                                break;
                            case 500:
                                errorMessage = "Internal Server Error.";
                                break;
                            case 503:
                                errorMessage = "The requested service is not available.";
                                break;
                            case 422:
                                errorMessage = "Validation Error!";
                                break;
                            default:
                                errorMessage = "Something went wrong!";
                        }
                    }
                    this._toastr.error(`${errorMessage}`);
                    return throwError(errorMessage);
                })
            )
    }
}