import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import * as fs from 'file-saver';
import html2canvas from 'html2canvas';
import * as moment from 'moment'
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { finalize, map, switchMap, tap, take } from 'rxjs/operators';
import { CommunicationModuleOptions } from 'src/app/models';
import { Workbook, Worksheet } from 'exceljs';
import { CLAIMS_RECONN_TABS, CLAIMS_RECONCILITATION_REPORT_BY_PROVIDER_AND_EPISODE_DATA, HIGH_OCCURRENCE_PROVIDER_DATA, CLAIMS_RECON_REPORT_RAW_DATA, REPORT_LABELS, CLAIMS_RECONCILIATION_TOTAL_CLAIM_DATA, CLAIMS_RECONCILIATION_VOLUME_BY_EPISODE_TYPE_DATA } from './../../../constants/report.constant';
import { ReportService } from './../../../services/report.service';
import { CurrencyFormat, HolistaUtils } from 'src/app/utils';
import { DataLinkService } from 'src/app/services';

@Component({
  selector: 'app-claims-reconciliation-summary',
  templateUrl: './claims-reconciliation-summary.component.html',
  styleUrls: ['./claims-reconciliation-summary.component.scss']
})
export class ClaimsReconciliationSummaryComponent implements OnInit {
  showMessageModal: boolean = false;
  queryParam = new BehaviorSubject<{
    startDate: string | null,
    endDate: string | null,
    dateType: string | null,
    [key: string]: any
  }>({ startDate: null, endDate: null, dateType: null });
  @Input() set queryParams(params) {
    this.queryParam.next(params)
  };
  public claimsReconTabs = CLAIMS_RECONN_TABS;
  loading: boolean = false;
  selectedTab: string;
  headers = ["Billing Provider", "Tax ID", "NPI", "Episode", "Total # Claims Submitted By<br> Billing/Provider / Episode", "Total Processed Correctly", "Total Misdirected Claims", "Total Duplicate Claims"];
  @Output() isFetching = new EventEmitter();
  hasSummaryData$ = new BehaviorSubject(false);
  moduleOptions: CommunicationModuleOptions;

  rawData = {
    data: [],
    excelName: '',
    tableTitle: '',
    tableHeaders: [],
    columnsWidth: [],
    dateType: '',
    date: {},
  };

  totalClaimsBase64: string;
  byEpisodeBase64: string;
  byFinancialTypeBase64: string;

  doesDataExist$ = new BehaviorSubject(false);

  summaryData$ = this.queryParam.pipe(
    switchMap((param) => this._reportService.getClaimsReconcilitationSummary(param).pipe(
      tap((value) => Object.assign(this.reportData, { summaryData: value })),
      finalize(() => {
        this.getChartImage();
        this.isFetching.emit(true);
      })
    )),
  );
  tableData$ = this.queryParam.pipe(
    switchMap((param) => this._reportService.getClaimsReconcilitationByProviderAndEpisode(param).pipe(
      map((data) => {
        data.referenceData.items = {
          data: data.referenceData.items,
          excelName: '',
          tableTitle: '',
          tableHeaders: CLAIMS_RECONCILITATION_REPORT_BY_PROVIDER_AND_EPISODE_DATA.TABLE_HEADERS,
          columnsWidth: [],
          dateType: '',
          date: {},
        }
        return data;
      }),
      tap((value) => {
        Object.assign(this.reportData, { tableData: value });
        this.doesDataExist$.next(value && value.referenceData && value.referenceData.items.data.length ? true : false)
      }),
      finalize(() => {
        this.isFetching.emit(true);
      })
    )),
  );
  rawData$ = this.queryParam.pipe(
    switchMap((param) => this._reportService.getClaimsReconRawData(param).pipe(
      map((data) => {
        return {
          data: data.map((x) => {
            return {
              ...x,
              paidAmount: `${this._currencyFormat.format(x.paidAmount ? x.paidAmount : 0)}`,
              billedAmount: `${this._currencyFormat.format(x.billedAmount ? x.billedAmount : 0)}`,
              copayAmount: `${this._currencyFormat.format(x.copayAmount ? x.copayAmount : 0)}`,
              cobAmount: `${this._currencyFormat.format(x.cobAmount ? x.cobAmount : 0)}`,
            }
          }),
          excelName: '',
          tableTitle: '',
          tableHeaders: CLAIMS_RECON_REPORT_RAW_DATA.TABLE_HEADERS,
          columnsWidth: [],
          dateType: '',
          date: {},
        };
      }),
      tap((value) => {
        this.doesDataExist$.next(value && value.data.length ? true : false)
        Object.assign(this.reportData, { rawData: value })
      }),
      finalize(() => {
        this.isFetching.emit(true);
      })
    )),
  );

  @Input() showClaimsReconciliationSummary: boolean;
  downloadReportLoading: boolean = false;

  reportData = {
    summaryData: null,
    tableData: null,
    rawData: null,
  }

  @ViewChild('tabs', { static: true }) public tabs;

  closeMessageModal() {
    this.showMessageModal = false;
  }

  constructor(
    private _reportService: ReportService,
    private _currencyFormat: CurrencyFormat,
    private _dataLinkService: DataLinkService,
    private _holistaUtils: HolistaUtils
  ) { }

  ngOnInit() {
  }

  async getChartImage() {
    setTimeout(async () => {
      try {
        [this.totalClaimsBase64, this.byEpisodeBase64, this.byFinancialTypeBase64] = await Promise.all(
          [
            this.getBase64('total-claims-chart'),
            this.getBase64('by-volume-chart'),
            this.getBase64('by-financial-chart')
          ]
        );
        this.hasSummaryData$.next(true);
        this.doesDataExist$.next(true);
      } catch (error) {
        this.totalClaimsBase64 = null;
        this.byEpisodeBase64 = null;
        this.byFinancialTypeBase64 = null;
        this.hasSummaryData$.next(true);
        this.doesDataExist$.next(false);
      }
    }, 700);
  }

  onTabSelected(event) {
    this.selectedTab = event.heading;
  }

  async getBase64(id: string) {
    const data = document.getElementById(id);
    const canvas = await html2canvas(data);
    return canvas.toDataURL('image/png');
  }

  /**
   * exports claims reconciliation summary report
   */
  async onSendEmailOrDownloadClicked(eventType?: string) {
    const isButtonEventDownload = eventType === 'download';
    isButtonEventDownload && (this.downloadReportLoading = true);
    // Const Styles
    const blackColor = '00000000';
    const defaultBorderStyle = {
      top: { style: 'medium', color: { argb: blackColor } },
      left: { style: 'medium', color: { argb: blackColor } },
      bottom: { style: 'medium', color: { argb: blackColor } },
      right: { style: 'medium', color: { argb: blackColor } }
    };
    const defaultFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' },
      bgColor: { argb: 'FF0000FF' }
    };
    const mainTitleFont = {
      bold: true,
      size: 16,
      // color: { argb: '6AAEFF' }
      color: { argb: blackColor }
    };
    const defaultTitleFont = {
      bold: true,
      size: 12,
      color: { argb: 'blackColor' }
    };
    const defaultCenterAlign = { horizontal: 'center', vertical: 'middle' };

    // Create a new Excel workbook and worksheet
    const workbook = new Workbook();
    const selectedDate = `${this.queryParam.value.startDate} - ${this.queryParam.value.endDate}`;
    const claimsSummaryWorksheet = workbook.addWorksheet("Summary");
    const byProviderAndEpisodeDataWorksheet = workbook.addWorksheet("Provider & Episode");
    const rawDataWorksheet = workbook.addWorksheet("Raw Data");

    claimsSummaryWorksheet.mergeCells('B3:Z3');
    const claimSummaryReportDate = claimsSummaryWorksheet.getCell('B3');
    claimSummaryReportDate.value = `Date Received: ${selectedDate}`;

    // Merge Row and Format
    claimsSummaryWorksheet.mergeCells('B1:Z2');
    const claimsSummaryCell = claimsSummaryWorksheet.getCell('B1');
    claimsSummaryCell.value = 'Claims Reconciliation Summary';

    claimsSummaryWorksheet.mergeCells('B10:G25');

    claimsSummaryWorksheet.mergeCells('B4:M5');
    const claimsSummaryTitleCell = claimsSummaryWorksheet.getCell('B4');
    claimsSummaryTitleCell.value = 'Total Claims';

    claimsSummaryWorksheet.mergeCells('H6:M25');
    const totalClaimsImageCell = claimsSummaryWorksheet.getCell('F6');

    claimsSummaryWorksheet.mergeCells('B30:M49');
    const byVolumeImageCell = claimsSummaryWorksheet.getCell('B30');

    claimsSummaryWorksheet.mergeCells('O30:Z49');
    const byFinancialImageCell = claimsSummaryWorksheet.getCell('O30');

    claimsSummaryWorksheet.mergeCells('B28:M29');
    const byVolumeTitleCell = claimsSummaryWorksheet.getCell('B28');
    byVolumeTitleCell.value = "Claims Discrepencies - volume by episode type";

    claimsSummaryWorksheet.mergeCells('O28:Z29');
    const byEpisodeTitleCell = claimsSummaryWorksheet.getCell('O28');
    byEpisodeTitleCell.value = "Claims Discrepencies - financial by episode type";

    claimsSummaryWorksheet.mergeCells('O4:Z5');
    const providerDataTitleCell = claimsSummaryWorksheet.getCell('O4');
    providerDataTitleCell.value = "High Occurrence Providers - Top 10";

    /**
     * Provider Data Sheet Begins
     */

    byProviderAndEpisodeDataWorksheet.mergeCells('A1:H2');
    byProviderAndEpisodeDataWorksheet.mergeCells('A3:H3');
    const providerAndEpisodeDataTitleCell = byProviderAndEpisodeDataWorksheet.getCell('A1');
    const providerAndEpisodeDataDateCell = byProviderAndEpisodeDataWorksheet.getCell('A3');
    providerAndEpisodeDataTitleCell.value = 'Claims Reconciliation by Provider & Episode';
    providerAndEpisodeDataDateCell.value = `Date Received: ${selectedDate}`

    // Set border properties
    Object.assign(claimsSummaryCell, { alignment: defaultCenterAlign, border: defaultBorderStyle, font: mainTitleFont });
    Object.assign(providerAndEpisodeDataTitleCell, { alignment: defaultCenterAlign, border: defaultBorderStyle, font: mainTitleFont });
    Object.assign(claimsSummaryTitleCell, { alignment: defaultCenterAlign, border: defaultBorderStyle, font: defaultTitleFont });
    Object.assign(providerDataTitleCell, { alignment: defaultCenterAlign, border: defaultBorderStyle, font: defaultTitleFont });
    Object.assign(byVolumeTitleCell, { alignment: defaultCenterAlign, border: defaultBorderStyle, font: defaultTitleFont });
    Object.assign(byEpisodeTitleCell, { alignment: defaultCenterAlign, border: defaultBorderStyle, font: defaultTitleFont });
    Object.assign(totalClaimsImageCell, { border: defaultBorderStyle });
    Object.assign(byVolumeImageCell, { alignment: defaultCenterAlign, border: defaultBorderStyle });
    Object.assign(byFinancialImageCell, { alignment: defaultCenterAlign, border: defaultBorderStyle });
    // Object.assign(providerDataImageCell, { alignment: defaultCenterAlign, border: defaultBorderStyle, font: defaultTitleFont });

    // Image for charts to add in excel
    if (this.totalClaimsBase64) {
      const totalClaimsImg = workbook.addImage({
        base64: this.totalClaimsBase64,
        extension: "png"
      });

      claimsSummaryWorksheet.addImage(totalClaimsImg, {
        tl: { col: 8, row: 5 },
        br: { col: 13, row: 25 }
      });
    }

    if (this.byEpisodeBase64) {
      const byEpisodeImg = workbook.addImage({
        base64: this.byEpisodeBase64,
        extension: "png"
      });

      claimsSummaryWorksheet.addImage(byEpisodeImg, {
        tl: { col: 1, row: 29 },
        br: { col: 13, row: 49 }
      });
    }

    if (this.byFinancialTypeBase64) {
      const byFinancialImg = workbook.addImage({
        base64: this.byFinancialTypeBase64,
        extension: "png"
      });

      claimsSummaryWorksheet.addImage(byFinancialImg, {
        tl: { col: 14, row: 29 },
        br: { col: 26, row: 49 }
      });
    }

    combineLatest([
      this.reportData.summaryData ? of(this.reportData.summaryData) : this.summaryData$,
      this.reportData.tableData ? of(this.reportData.tableData) : this.tableData$,
      this.reportData.rawData ? of(this.reportData.rawData) : this.rawData$,
    ])
      .pipe(
        take(1),
        finalize(() => (this.downloadReportLoading = false)),
      )
      .subscribe((
        [
          summaryData,
          tableData,
          rawData
        ]
      ) => {
        const highOccurrenceData: Array<any> = this._holistaUtils.deepClone(summaryData.highOccuranceProviders.referenceData) || [];
        const byProviderAndEpisodeData: Array<any> = this._holistaUtils.deepClone(tableData.referenceData.items.data) || [];
        const byProviderAndEpisodeDataTotal = this._holistaUtils.deepClone(tableData.referenceData.total)
        const rawDataList: Array<any> = this._holistaUtils.deepClone(rawData.data) || [];
        const totalClaimsData: Array<any> = this._holistaUtils.deepClone(summaryData.totalClaims.referenceData.items.concat(summaryData.totalClaims.referenceData.total)) || [];
        const claimDiscrepenciesByVolumeData: Array<any> = this._holistaUtils.deepClone(summaryData.discrepenciesVolumeByEpisodeType
          .referenceData.items.concat(summaryData.discrepenciesVolumeByEpisodeType.referenceData.total)) || [];
        const claimDiscrepenciesByFinancialData: Array<any> = this._holistaUtils.deepClone(summaryData.discrepenciesFinancialByEpisodeType
          .referenceData.items.concat(summaryData.discrepenciesFinancialByEpisodeType
            .referenceData.total)) || [];

        const tableHeaders = HIGH_OCCURRENCE_PROVIDER_DATA.TABLE_HEADERS;

        const providerArray = [
          tableHeaders
        ];
        for (const item of highOccurrenceData) {
          providerArray.push(
            [
              { label: item.provider, value: null, mergeCellLength: 3 },
              { label: item.taxId, value: null, mergeCellLength: 1 },
              { label: item.duplicate, value: null, mergeCellLength: 1 },
              { label: item.misdirect, value: null, mergeCellLength: 1 },
              { label: item.total, value: null, mergeCellLength: 1 },
            ]
          )
        }
        highOccurrenceData.length = 0; // Memory cleanup
        this.addHighOccurenceProviderToSheet({
          providerArray,
          worksheet: claimsSummaryWorksheet, defaultBorderStyle, defaultFill
        })
        const totalClaimArray = [
          CLAIMS_RECONCILIATION_TOTAL_CLAIM_DATA.TABLE_HEADERS
        ]
        for (const item of totalClaimsData) {
          totalClaimArray.push(
            [
              { label: item.name, value: null, mergeCellLength: 1 },
              { label: item.volume, value: null, mergeCellLength: 1 },
              { label: item.measure, value: null, mergeCellLength: 1 },
            ]
          )
        }
        this.addTotalClaimsTableToSheet({ defaultBorderStyle, defaultFill, totalClaimArray, worksheet: claimsSummaryWorksheet });
        const discrepenciesByFinancialArray = [
          CLAIMS_RECONCILIATION_VOLUME_BY_EPISODE_TYPE_DATA.TABLE_HEADERS
        ]
        for (const item of claimDiscrepenciesByFinancialData) {
          discrepenciesByFinancialArray.push(
            [
              { label: item.name, value: null, mergeCellLength: 2 },
              { label: item.maternity, value: null, mergeCellLength: 2 },
              { label: item.totalJoint, value: null, mergeCellLength: 2 },
              { label: item.total, value: null, mergeCellLength: 2 },
            ]
          )
        }
        this.addClaimDiscrepenciesByFinancialTableToSheet({ discrepenciesArray: discrepenciesByFinancialArray, worksheet: claimsSummaryWorksheet, defaultBorderStyle, defaultFill })
        const discrepenciesByVolumeArray = [
          CLAIMS_RECONCILIATION_VOLUME_BY_EPISODE_TYPE_DATA.TABLE_HEADERS
        ]

        for (const item of claimDiscrepenciesByVolumeData) {
          discrepenciesByVolumeArray.push(
            [
              { label: item.name, value: null, mergeCellLength: 2 },
              { label: item.maternity, value: null, mergeCellLength: 2 },
              { label: item.totalJoint, value: null, mergeCellLength: 2 },
              { label: item.total, value: null, mergeCellLength: 2 },
            ]
          )
        }
        this.addClaimDiscrepenciesByVolumeTableToSheet({ discrepenciesArray: discrepenciesByVolumeArray, worksheet: claimsSummaryWorksheet, defaultBorderStyle, defaultFill })
        providerArray.length = 0;
        totalClaimArray.length = 0;
        discrepenciesByVolumeArray.length = 0;
        discrepenciesByFinancialArray.length = 0;

        // By Provider and Episode Sheet
        const byProviderAndEpisodeTableHeader = CLAIMS_RECONCILITATION_REPORT_BY_PROVIDER_AND_EPISODE_DATA.TABLE_HEADERS;
        // Add total
        byProviderAndEpisodeDataWorksheet.addRow(byProviderAndEpisodeTableHeader.map(header => header.value === 'billingProvider' ? 'Total' : byProviderAndEpisodeDataTotal[header.value]))
        const headerRow = byProviderAndEpisodeDataWorksheet.addRow(byProviderAndEpisodeTableHeader.map(header => header.label));
        headerRow.font = { bold: true };
        Object.assign(headerRow, { fill: defaultFill });

        // Set the column widths
        const headerColumnWidths = [30, 15, 15, 15, 40, 25, 25, 25];
        for (const [index, width] of headerColumnWidths.entries()) {
          byProviderAndEpisodeDataWorksheet.getColumn(index + 1).width = width;
        }

        byProviderAndEpisodeData.forEach(rowData => {
          byProviderAndEpisodeDataWorksheet.addRow(byProviderAndEpisodeTableHeader.map(header => rowData[header.value]));
        });

        byProviderAndEpisodeData.length = 0 // Memory cleanup

        // Raw Data
        const rawDataHeader = CLAIMS_RECON_REPORT_RAW_DATA.TABLE_HEADERS;
        const rawDataHeaderRow = rawDataWorksheet.addRow(rawDataHeader.map(header => header.label));
        rawDataHeaderRow.font = { bold: true };
        Object.assign(rawDataHeaderRow, { fill: defaultFill });

        // Set the column widths
        const rawDataHeaderColumnWidths = CLAIMS_RECON_REPORT_RAW_DATA.COLUMNS_WIDTH;
        for (const [index, width] of rawDataHeaderColumnWidths.entries()) {
          rawDataWorksheet.getColumn(index + 1).width = width;
        }

        rawDataList.forEach(rowData => {
          rawDataWorksheet.addRow(rawDataHeader.map(header => rowData[header.value]));
        });

        rawDataList.length = 0; // Memory cleanup

        // Save the Excel workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
          const date = moment().format('L').replace(/\//g, "");
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          if (isButtonEventDownload) {
            fs.saveAs(blob, `Claims_Reconciliation_Report_${date}.xlsx`);
          } else {
            this.showMessageModal = true;
            this.moduleOptions = {
              module: 'report',
              subModule: REPORT_LABELS.CLAIMS_RECONCILIATION_SUMMARY_REPORT.toLowerCase()
            };
            this._dataLinkService.setDataSource({ key: 'dataSummaryBlob', value: blob });
          }
        });
      });
  }

  addHighOccurenceProviderToSheet({ providerArray, worksheet, defaultBorderStyle, defaultFill }) {
    let initialColumnValue = 79; // O in ASCII 
    let startingRowIndex = 6;
    let mergeCellSkips = 0;

    for (const [idx, highOccurrance] of providerArray.entries()) {
      for (const value of highOccurrance) {
        worksheet.mergeCells(`${String.fromCharCode(initialColumnValue + mergeCellSkips)}${startingRowIndex}:${String.fromCharCode(initialColumnValue + value.mergeCellLength + mergeCellSkips)}${startingRowIndex}`);
        const cellNumber = worksheet.getCell(`${String.fromCharCode(initialColumnValue + mergeCellSkips)}${startingRowIndex}`);
        cellNumber.value = value.label;
        Object.assign(cellNumber, { border: defaultBorderStyle });
        if (idx === 0) {
          cellNumber.font = { bold: true };
          Object.assign(cellNumber, { fill: defaultFill });
        }
        initialColumnValue++;
        mergeCellSkips += value.mergeCellLength;
      }

      initialColumnValue = 79; // O in ASCII 
      mergeCellSkips = 0;
      startingRowIndex++
    }
  }

  addTotalClaimsTableToSheet({ worksheet, defaultBorderStyle, defaultFill, totalClaimArray }) {
    let initialColumnValue = 66; // B in ASCII 
    let startingRowIndex = 6;
    let mergeCellSkips = 0;

    for (const [idx, totalClaim] of totalClaimArray.entries()) {
      for (const value of totalClaim) {
        worksheet.mergeCells(`${String.fromCharCode(initialColumnValue + mergeCellSkips)}${startingRowIndex}:${String.fromCharCode(initialColumnValue + value.mergeCellLength + mergeCellSkips)}${startingRowIndex}`);
        const cellNumber = worksheet.getCell(`${String.fromCharCode(initialColumnValue + mergeCellSkips)}${startingRowIndex}`);
        cellNumber.value = value.label;
        Object.assign(cellNumber, { border: defaultBorderStyle, alignment: { horizontal: 'left' } });
        if (idx === 0) {
          cellNumber.font = { bold: true };
          Object.assign(cellNumber, { fill: defaultFill });
        }
        initialColumnValue++;
        mergeCellSkips += value.mergeCellLength;
      }

      initialColumnValue = 66; // B in ASCII 
      mergeCellSkips = 0;
      startingRowIndex++
    }
  }

  addClaimDiscrepenciesByVolumeTableToSheet({ worksheet, defaultBorderStyle, defaultFill, discrepenciesArray }) {
    let initialColumnValue = 66; // O in ASCII 
    let startingRowIndex = 51;
    let mergeCellSkips = 0;

    for (const [idx, totalClaim] of discrepenciesArray.entries()) {
      for (const value of totalClaim) {
        worksheet.mergeCells(`${String.fromCharCode(initialColumnValue + mergeCellSkips)}${startingRowIndex}:${String.fromCharCode(initialColumnValue + value.mergeCellLength + mergeCellSkips)}${startingRowIndex}`);
        const cellNumber = worksheet.getCell(`${String.fromCharCode(initialColumnValue + mergeCellSkips)}${startingRowIndex}`);
        cellNumber.value = value.label;
        Object.assign(cellNumber, { border: defaultBorderStyle, alignment: { horizontal: 'left' } });
        if (idx === 0) {
          cellNumber.font = { bold: true };
          Object.assign(cellNumber, { fill: defaultFill });
        }
        initialColumnValue++;
        mergeCellSkips += value.mergeCellLength;
      }

      initialColumnValue = 66; // O in ASCII 
      mergeCellSkips = 0;
      startingRowIndex++
    }
  }

  addClaimDiscrepenciesByFinancialTableToSheet({ worksheet, defaultBorderStyle, defaultFill, discrepenciesArray }) {
    let initialColumnValue = 79; // O in ASCII 
    let startingRowIndex = 51;
    let mergeCellSkips = 0;

    for (const [idx, totalClaim] of discrepenciesArray.entries()) {
      for (const [index, value] of totalClaim.entries()) {
        worksheet.mergeCells(`${String.fromCharCode(initialColumnValue + mergeCellSkips)}${startingRowIndex}:${String.fromCharCode(initialColumnValue + value.mergeCellLength + mergeCellSkips)}${startingRowIndex}`);
        const cellNumber = worksheet.getCell(`${String.fromCharCode(initialColumnValue + mergeCellSkips)}${startingRowIndex}`);
        cellNumber.value = value.label;
        Object.assign(cellNumber, { border: defaultBorderStyle });
        if (index !== 0 && idx !== 0) Object.assign(cellNumber, { alignment: { horizontal: 'right' } });
        if (idx === 0) {
          cellNumber.font = { bold: true };
          Object.assign(cellNumber, { fill: defaultFill });
        }
        initialColumnValue++;
        mergeCellSkips += value.mergeCellLength;
      }

      initialColumnValue = 79; // O in ASCII 
      mergeCellSkips = 0;
      startingRowIndex++
    }
  }
}
