import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'messageDateTime' })

export class MessageDate
    implements PipeTransform {
    transform(date: any) {
        const currentDate = moment().format('MM/DD/YYYY');
        const messageDate = moment(date * 1000).format('MM/DD/YYYY');
        const day_diff = moment(currentDate).diff(messageDate, 'days')
        if (day_diff == 0) {
            return moment(date * 1000).format('hh:mm A');
        } else if (day_diff == 1) {
            return 'Yesterday'
        } else if (day_diff > 1 && (currentDate.slice(-4) == messageDate.slice(-4))) {
            return moment(date * 1000).format('MMM DD')
        } else {
            return moment(date * 1000).format('DD/MM/YYYY')
        }
    }
}