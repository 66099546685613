const Excel = require('exceljs');
import { Injectable } from "@angular/core";
import { ExcelService } from "../services";
import * as fs from 'file-saver';
import * as moment from 'moment'
import { REPORT_TAB_NAME } from "../constants";

@Injectable({
    providedIn: 'root'
})

export class ExcelMultiTabUtility {
    constructor(
        private _excelService: ExcelService,
    ) { }

    exportToExcelMultiTab = async (fileName, tabsData, options: any = {}) => {
        const thinBorder = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        const alignMiddleLeft = { vertical: 'middle', horizontal: 'left' };
        const alignMiddleRight = { vertical: 'middle', horizontal: 'right' };
        const alignMiddleCenter = { vertical: 'middle', horizontal: 'center' };

        if (!Array.isArray(tabsData) || tabsData.length === 0) {
            throw new Error('Invalid tabsData. It should be an array of tab objects.');
        }
        const workbook = new Excel.Workbook();
        const addRowData = async (worksheet, tab, name, font, alignment) => {
            const row = worksheet.addRow([name]);
            row.font = font;
            row.alignment = alignment;
            const endCell = worksheet.getColumn(tab.data[0].length).letter;
            const mergedCell = worksheet.getCell(`A${row.number}:${endCell}${row.number}`);
            mergedCell.border = thinBorder;
            worksheet.mergeCells(`A${row.number}:${endCell}${row.number}`);
        }

        for (const tab of tabsData) {
            if (!tab || !tab.data || !Array.isArray(tab.data)) {
                throw new Error('Invalid tab object. Each tab object should have a "data" property with an array of data.');
            }
            const worksheet = workbook.addWorksheet(tab.tabName);
            if (tab.clientName) { await addRowData(worksheet, tab, tab.clientName, { size: 20, bold: true }, alignMiddleCenter); }
            if (tab.reportName) { await addRowData(worksheet, tab, tab.reportName, { size: 16, bold: true }, alignMiddleCenter); }
            if (tab.reportDate) { await addRowData(worksheet, tab, `${tab.reportDate}`, { size: 11, bold: true }, alignMiddleRight); }

            if (tab.additionalHeaders && Array.isArray(tab.additionalHeaders)) {
                const customHeaderRow = 4;
                let currentColumn = 1;
                for (const item of tab.additionalHeaders) {
                    const fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'D3D3D3' },
                        bgColor: { argb: 'FF0000FF' },
                    };
                    const fontStyle = { size: 10, bold: true };
                    if (typeof item === 'string') {
                        const cell = worksheet.getCell(customHeaderRow, currentColumn);
                        cell.value = item;
                        cell.fill = fill;
                        cell.font = fontStyle;
                        cell.border = thinBorder;
                        currentColumn++;
                    } else if (typeof item === 'object' && item.name) {
                        const mergeEndColumn = currentColumn + item.colspan - 1;
                        worksheet.mergeCells(customHeaderRow, currentColumn, customHeaderRow, mergeEndColumn);
                        const mergedCell = worksheet.getCell(customHeaderRow, currentColumn);
                        mergedCell.value = item.name;
                        mergedCell.fill = fill;
                        mergedCell.font = fontStyle;
                        mergedCell.border = thinBorder;
                        currentColumn = mergeEndColumn + 1;
                    }
                }
            }

            // Add custom headers if specified
            if (tab.headers && Array.isArray(tab.headers)) {
                const headerRow = worksheet.addRow(tab.headers);
                headerRow.font = { size: 10, bold: true };
                headerRow.alignment = alignMiddleLeft;

                // Apply bold formatting and bgColor to header cells
                headerRow.eachCell((cell) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'D3D3D3' },
                        bgColor: { argb: 'FF0000FF' },
                    };
                    cell.border = thinBorder;
                });
            }

            // Add data to the worksheet
            for (const rowData of tab.data) {
                worksheet.addRow(rowData);
            }

            // Add a dynamic row for totals if specified
            if (tab.totalRow && Array.isArray(tab.totalRow.values) && tab.totalRow.values.length > 0) {
                await addRowData(worksheet, tab, '', { bold: true }, {});
                worksheet.getRow(worksheet.rowCount).values = tab.totalRow.values;
            }

            // Set column widths based on the data
            const columnWidths = [];
            for (const row of tab.data) {
                for (let columnIndex = 1; columnIndex <= row.length; columnIndex++) {
                    const cellValue = row[columnIndex - 1];
                    const columnWidth = cellValue ? cellValue.toString().length + 2 : 10; // Default width if cellValue is empty
                    if (!columnWidths[columnIndex] || columnWidth > columnWidths[columnIndex]) {
                        columnWidths[columnIndex] = columnWidth;
                    }
                }
            }

            worksheet.columns.forEach((column, index) => {
                column.width = columnWidths[index + 1] < 13 ? 13 : columnWidths[index + 1]; // Minimum width 13 to ensure readability
            });

            if (fileName.includes(REPORT_TAB_NAME.SERVICE_CATEGORY_UTILIZATION) && worksheet.name === REPORT_TAB_NAME.SERVICE_CATEGORY_UTILIZATION && tabsData[0].headers.includes('Percentage')) {
                worksheet.columns[1].width = 70;
            };
        }
        // Save the workbook to the specified output file
        const blob = await this._excelService.getExcelData(workbook);
        const date = moment().format('L').replace(/\//g, "");
        fs.saveAs(blob, fileName + `_${date}.xlsx`);
    };
}
