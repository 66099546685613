<div class="p-3 grey lighten-4 mb-4">
  <form>
    <div class="form-row">
      <div class="col">
        <div class="form-group input-group has-search w-xs-100 mb-0">
          <span class="form-control-feedback">
            <i class="icofont-search-1"></i>
          </span>
          <input
            type="text"
            [(ngModel)]="claimsDashboard.claimIdentifier"
            class="form-control bg-white"
            id="searchByClaim"
            name="searchByClaim"
            placeholder="Claim ID"
            (ngModelChange)="change($event)" />
          <div class="input-group-append" *ngIf="claimsDashboard.claimIdentifier">
            <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
              <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword('claimID')"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group input-group has-search">
          <input
            type="text"
            class="completer-input form-control mdb-autocomplete mb-0 bg-white"
            [ngModel]="provider_search_text | async"
            [ngModelOptions]="{standalone: true}"
            autocomplete="off"
            (change)="providerChange($event)"
            (paste)="pasteSearchProvider()"
            (ngModelChange)="provider_search_text.next($event)"
            [mdbAutoCompleter]="auto"
            placeholder="Search provider"
            id="autocompleteSearchProvider" />
          <div class="input-group-append" *ngIf="provider_search_text | async">
            <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
              <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword('patient')"></i>
            </button>
          </div>
          <mdb-auto-completer
            #auto="mdbAutoCompleter"
            textNoResults="No result found"
            class="completer-dropdown-holder-dashboard"
            (selected)="onProviderSelect($event)"
            [displayValue]="onDisplayValue">
            <mdb-option *ngFor="let option of provider_results | async" [value]="option">
              <div class="d-flex flex-column">
                <strong>
                  {{ option?.basicInfo?.businessName }}
                </strong>
                <div class="d-flex flex-column">
                  <small>
                    {{
                      option?.basicInfo?.identificationCode
                        ? option.basicInfo.identificationCode + ','
                        : ''
                    }}
                    {{ option?.basicInfo?.city ? option.basicInfo.city + ',' : '' }}
                    {{ option?.basicInfo?.state ? option.basicInfo.state : '' }}
                  </small>
                </div>
              </div>
            </mdb-option>
          </mdb-auto-completer>
        </div>
      </div>
      <div class="col">
        <input
          type="text"
          id="fromDate"
          placeholder="MM/DD/YYYY"
          [textMask]="{mask: dateMask}"
          class="form-control bg-white"
          [(ngModel)]="claimsDashboard.fromDate"
          name="fromDate"
          (focusout)="getDashboard()" />
      </div>
      <div class="col">
        <input
          type="text"
          id="toDate"
          placeholder="MM/DD/YYYY"
          [textMask]="{mask: dateMask}"
          class="form-control bg-white"
          [(ngModel)]="claimsDashboard.toDate"
          name="toDate"
          (focusout)="getDashboard()" />
      </div>
      <div class="col-4">
        <button
          class="btn btn-secondary waves-light mr-1 mt-0 mb-0 pl-3 pr-3"
          type="button"
          mdbWavesEffect
          (click)="setDate('thisMonth')">
          This Month
        </button>
        <button
          class="btn btn-secondary waves-light mr-1 mt-0 mb-0 pl-3 pr-3"
          type="button"
          mdbWavesEffect
          (click)="setDate('lastMonth')">
          Last Month
        </button>
        <button
          class="btn btn-secondary waves-light mt-0 mb-0 pl-3 pr-3"
          type="button"
          mdbWavesEffect
          (click)="setDate('year')">
          YTD
        </button>
      </div>
    </div>
  </form>
</div>

<div class="container-fluid">
  <app-loader [loader]="loading.dashboard"></app-loader>
  <div *ngIf="!loading.dashboard">
    <div class="row">
      <div class="col-8">
        <div class="row mb-3">
          <div class="col-sm-6 col-md-6">
            <mdb-card class="mt-3 mt-sm-0 z-depth-0 bg-blue-light">
              <mdb-card-body class="p-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="media">
                    <div
                      class="icon-block icon-block-lg text-center bg-primary rounded-circle d-flex justify-content-center align-items-center">
                      <i class="fa fa-layer-group text-white"></i>
                    </div>
                    <div class="content text-muted ml-2 media-body">
                      Total <br />
                      Paid Claims
                    </div>
                  </div>
                  <h3 class="mb-0">
                    {{ this.claims.paid.length }}
                  </h3>
                </div>
              </mdb-card-body>
            </mdb-card>
          </div>
          <div class="col-sm-6 col-md-6">
            <mdb-card class="mt-3 mt-sm-0 z-depth-0 bg-blue-light">
              <mdb-card-body class="p-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="media">
                    <div
                      class="icon-block icon-block-lg text-center bg-primary rounded-circle d-flex justify-content-center align-items-center">
                      <i class="icofont-doctor text-white"></i>
                    </div>
                    <div class="content text-muted ml-2 media-body">
                      Total <br />
                      Provider
                    </div>
                  </div>
                  <h3 class="mb-0">
                    {{ this.claims.providers.length }}
                  </h3>
                </div>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 mt-3 mt-sm-0">
            <app-claims-processing-status [claims]="claims"></app-claims-processing-status>
          </div>
          <div class="col-12 col-sm-6 mt-3 mt-sm-0">
            <app-providers [claims]="claims"></app-providers>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <h6 class="font-weight-bold">Useful Links and Program Information</h6>
        <app-loader [loader]="loading.url"></app-loader>
        <ul class="list-group list-group-flush mt-4">
          <li *ngFor="let url of urls" class="list-group-item pointer-cursor pl-0 pr-0">
            <a target="_blank" href="{{ url.value }}">{{ url.name }}</a>
            <p class="text-muted mb-0 font-small">{{ url.description }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
