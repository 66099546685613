<div class="p-4">
  <h6><strong>Services Detail</strong></h6>
  <div class="d-flex justify-content-between mt-3">
    <table class="table-xs" mdbTable small="true" borderless="true">
      <tr>
        <td><span>Claim ID #:</span></td>
        <td>
          <strong>{{ remittancePdf.patientControlNumber }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Patient Name:</span></td>
        <td>
          <strong>{{ remittancePdf.patientName | titlecase }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Subscriber/ Member: </span></td>
        <td>
          <strong>{{ remittancePdf.subscriberMember }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>DOB: </span></td>
        <td>
          <strong>{{ remittancePdf.dob ? (remittancePdf.dob | date : 'MM/dd/yyyy') : '' }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Patient Control No:</span></td>
        <td>
          <strong>{{ remittancePdf.patientControlNumber }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Medical Record: </span></td>
        <td>
          <strong>{{ remittancePdf.medicalRecord }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Statement Date: </span></td>
        <td>
          <strong>{{ remittancePdf.statementDate }}</strong>
        </td>
      </tr>
    </table>
    <table class="table-xs" mdbTable small="true" borderless="true">
      <tr>
        <td><span>Facility Name:</span></td>
        <td>
          <strong>{{ remittancePdf.facilityName | titlecase }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Plan:</span></td>
        <td>
          <strong>{{ remittancePdf.plan }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Bundle Name: </span></td>
        <td>
          <strong>{{ remittancePdf.bundleName }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Tax ID: </span></td>
        <td>
          <strong>{{ remittancePdf.taxId }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Transaction No: </span></td>
        <td>
          <strong>{{ remittancePdf.transactionNumber }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Type of Bill: </span></td>
        <td>
          <strong>{{ remittancePdf.typeOfBill }}</strong>
        </td>
      </tr>
      <tr>
        <td><span>Date of Service: </span></td>
        <td>
          <strong>{{
            remittancePdf.serviceLines[0].serviceStartDate.date
              ? remittancePdf.serviceLines[0].serviceStartDate.date
              : 'N/A'
          }}</strong>
          <strong *ngIf="remittancePdf.serviceLines[0].serviceEndDate?.date">
            -
            {{ remittancePdf.serviceLines[0].serviceEndDate?.date }}</strong
          >
        </td>
      </tr>
    </table>
  </div>
  <table class="mt-3" mdbTable small="true">
    <tbody
      *ngIf="remittancePdf && remittancePdf.serviceLines.length > 0"
      style="background-color: white">
      <tr>
        <th class="font-weight-bold">#</th>
        <th class="font-weight-bold">Rev <br />Code</th>
        <th class="font-weight-bold" style="width: 250px">
          Procedure/<br />
          Mod
        </th>
        <th class="font-weight-bold">Billed <br />Qty</th>
        <th class="text-right font-weight-bold">Billed<br />Amt</th>
        <th class="text-right font-weight-bold">Allowed<br />Qty</th>
        <th class="text-right font-weight-bold">Allowed<br />Amt</th>
        <th class="text-right font-weight-bold">Patient<br />Pay</th>
        <th class="text-right font-weight-bold">Net<br />Amt</th>
        <th class="text-center font-weight-bold">EXCPT<br />Code</th>
      </tr>
      <tr *ngFor="let data of remittancePdf.serviceLines; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ data.code ? data.code : '-' }}</td>
        <td>
          <span>{{ data.procedureCode ? data.procedureCode : '-' }}</span
          ><br />
          <em *ngIf="!data.showMore"
            ><small>{{ data.description ? (data.description | slice : 0 : 30) : '' }} </small
            ><a
              (click)="data.showMore = !data.showMore"
              *ngIf="data.description && data.description.length > 30"
              >...</a
            ></em
          >
          <em *ngIf="data.showMore"
            ><small>{{ data.description }}</small></em
          >
        </td>
        <td>{{ data.billedQuantity }}</td>
        <td class="text-right">{{ (data.billedAmount | currency) || '-' }}</td>
        <td class="text-right">{{ data.allowedQuantity }}</td>
        <td class="text-right">{{ data.allowedAmount | currency }}</td>
        <td class="text-right">{{ (data.patientPay | currency) || '-' }}</td>
        <td class="text-right">{{ data.netAmount | currency }}</td>
        <td class="text-center pr-0">{{ data.excptCode || '-' }}</td>
      </tr>
      <tr class="grey lighten-2 w-100">
        <td colspan="3"></td>
        <td class="font-weight-bold">Total</td>
        <td class="text-right font-weight-bold">
          {{ remittancePdf.serviceLinesTotal.totalServiceBilledAmount | currency }}
        </td>
        <td></td>
        <td class="text-right font-weight-bold">
          {{ remittancePdf.serviceLinesTotal.totalServiceAllowedAmount | currency }}
        </td>
        <td class="text-right font-weight-bold">
          {{ remittancePdf.serviceLinesTotal.totalServicePatientPay | currency }}
        </td>
        <td class="text-right font-weight-bold">
          {{ remittancePdf.serviceLinesTotal.totalServiceNetAmount | currency }}
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>
