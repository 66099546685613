import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../action'
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivityLogService, EpisodeService, UserService } from '../../services';
import * as moment from 'moment'
import { MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { activityLog, taskActivityList } from '../../models'
import { LOGCONSTANTS, DatePickerOption } from '../../constants'
import { Storage, HolistaUtils } from '../../utils';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  loading = false
  filterForm: FormGroup
  user_filter_text = new Subject()
  user_filter_results: Observable<any>
  activity_type = 'login'
  filter_activity_type = 'login'
  logConstants = LOGCONSTANTS
  startDate: any
  endDate: any
  allUsersList = []
  logList = new activityLog
  selectedLogList = []
  filteredLogList = []
  filterList = []
  loginActivityFiltered: any
  userActivityFiltered: any
  show_Sub_Module = false
  searchKey = ''
  page = 1;
  limit = 10;
  totalCount: 0;
  taskActivities = new taskActivityList
  topics = []
  user: any
  searchModelChanged: Subject<string> = new Subject<string>();
  startDateOptions = DatePickerOption
  endDateOptions = DatePickerOption
  display_sub_module_list = [];

  @ViewChild('filterModal', { static: true }) public filterModal;
  @ViewChild('fromDatePicker', { static: true }) fromDatePicker: MDBDatePickerComponent
  @ViewChild('toDatePicker', { static: true }) toDatePicker: MDBDatePickerComponent

  constructor(
    private store: Store<{ bread_crumbs: any }>,
    private formBuilder: FormBuilder,
    private activityService: ActivityLogService,
    private userService: UserService,
    private episodeService: EpisodeService,
    private utilitySorting: HolistaUtils,
    private _storage: Storage,
  ) {
    this.setFilterForm();
    this.searchModelChanged.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(model => {
        this.searchKey = model;
        this.search(1);
      });
  }

  async ngOnInit() {
    this.loading = true
    this.user = this._storage.get('local', 'loggedInUser', 'user');
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Activity Logs', path: '/activity-log' }));
    this.getAllUsers();
    const activityFiltered = this._storage.get('session', 'activityFiltered');
    activityFiltered ? (
      this[activityFiltered] = this._storage.get('session', activityFiltered),
      this.filter_activity_type = activityFiltered.replace('ActivityFiltered', ''),
      this.activity_type = activityFiltered.replace('ActivityFiltered', ''),
      this[activityFiltered] && (
        this.filterForm.patchValue(this[activityFiltered]),
        this[activityFiltered].module && this[activityFiltered].module.length && this.moduleSelect()
      )
    ) : this.getLoginActivityLog();
  }

  async getLoginActivityLog() {
    this.loading = true
    const filterData = this.filterForm.value
    const filteredData = JSON.parse(JSON.stringify(filterData))
    if (filteredData.fromDate) {
      filteredData.fromDate = moment(filteredData.fromDate).utc()
      filteredData.fromDate = filteredData.fromDate._d
    }
    if (filteredData.toDate) {
      filteredData.toDate = moment(filteredData.toDate).utc()
      filteredData.toDate = filteredData.toDate._d
    }
    try {
      await this.activityService.getLoginActivity(this.limit, this.page, filteredData).then((res: any) => {
        this.loading = false
        if (this.activity_type == 'login') {
          this.totalCount = res.log.count
          this.logList.loginActivityLog = res.log.data
          this.selectedLogList = [...this.logList.loginActivityLog]
          this.filteredLogList = [...this.selectedLogList]
        }
      })
        .catch((error) => {
          console.log('Error', error)
        });
    }
    catch {
      this.loading = false
    }
  }

  async getUserActivityLog() {
    this.loading = true
    const filterData = this.filterForm.value;
    const filteredData = JSON.parse(JSON.stringify(filterData))
    if (filteredData.fromDate) {
      filteredData.fromDate = moment(filteredData.fromDate).utc()
      filteredData.fromDate = filteredData.fromDate._d
    }
    if (filteredData.toDate) {
      filteredData.toDate = moment(filteredData.toDate).utc()
      filteredData.toDate = filteredData.toDate._d
    }
    await this.activityService.getUserActivity(this.limit, this.page, filteredData).then((res: any) => {
      this.loading = false
      if (this.activity_type == 'user') {
        this.totalCount = res.log.count
        this.logList.userActivityLog = res.log.data
      }
    })
      .catch((error) => {
        console.log('Error', error)
      });
  }


  async getTaskActivityLog() {
    this.loading = true
    const filterData = this.filterForm.value
    const filteredData = JSON.parse(JSON.stringify(filterData))
    if (filteredData.fromDate) {
      filteredData.fromDate = moment(filteredData.fromDate).utc()
      filteredData.fromDate = filteredData.fromDate._d
    }
    if (filteredData.toDate) {
      filteredData.toDate = moment(filteredData.toDate).utc()
      filteredData.toDate = filteredData.toDate._d
    }
    await this.activityService.getTaskActivity(this.limit, this.page, filteredData).then((res: any) => {
      this.loading = false
      if (this.activity_type == 'task') {
        this.totalCount = res.log.count
        this.logList.taskActivityLog = res.log.data
      }
    })
      .catch((error) => {
        console.log('Error', error)
      });
  }

  OpenFilterModal() {
    this.filterForm.reset();
    const filterActivityValue = this.filter_activity_type + 'ActivityFiltered'
    const filterData = this._storage.get('session', filterActivityValue);
    const { fromDate, toDate } = this.filterForm.value
    if (filterData) {
      this.filterForm.patchValue(filterData);
      this.display_sub_module_list = filterData.display_sub_module_list;
      if (fromDate || toDate) {
        this.onDateChange()
      }
    }
    const { module } = this.filterForm.value
    if (this.activity_type === 'user' && module && module.some(value => LOGCONSTANTS.MODULES_WITH_SUB_MODULES.includes(value))) {
      this.show_Sub_Module = true
    } else {
      this.show_Sub_Module = false
    }
    this.filterModal.show()
  }

  setFilterForm() {
    this.filterForm = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
      module: [''],
      loginAction: [''],
      userAction: [''],
      taskAction: [''],
      userId: [null],
      filterEnabled: [false],
      sub_module: [''],
      taskType: ['']
    })
  }

  closeFilterModal() {
    this.filterModal.hide();
    if (this.filterList.length == 0 || (this.filterList && !this.filterList.find(x => x.formField === 'fromDate'))) this.fromDatePicker.clearDate()
    if (this.filterList.length == 0 || (this.filterList && !this.filterList.find(x => x.formField === 'toDate'))) this.toDatePicker.clearDate()
    !this.filterList.length && this.filterForm.reset();
    this.filter_activity_type = this.activity_type;
    this.filterForm.reset();
    const filterData = this._storage.get('session', this.filter_activity_type + 'ActivityFiltered');
    filterData && this.filterForm.patchValue(filterData);
  }

  updateList(field, formField, value, list) {
    if ((formField == 'fromDate' || formField == 'toDate')) {
      let data = { field: field, formField: formField, value: value, label: value }
      this.filterList.push(data);
    }
    Object.keys(value).forEach(y => {
      let values = value[y]
      let valueIndex = list.findIndex(x => x.value == values);
      if (valueIndex > -1) {
        let index = this.filterList.findIndex(x => (x.field == field && x.value == values));
        if (index == -1) {
          let data = { field: field, formField: formField, value: values, label: list[valueIndex].label }
          this.filterList.push(data);
        }
      }
    });
  }

  removeFilter(filter) {
    const { sub_module } = this.filterForm.value;
    const { formField, value } = filter;
    const { controls } = this.filterForm;
    const filteredData = this.filterForm.get(formField).value;
    if (formField === 'fromDate' || formField === 'toDate') {
      if (formField === 'fromDate') this.fromDatePicker.clearDate();
      if (formField === 'toDate') this.toDatePicker.clearDate();
      this.onDateChange();
    }
    controls[`${formField}`].setValue((filteredData && typeof (filteredData) == 'object') ? filteredData : '');
    if (formField === 'module') {
      if (LOGCONSTANTS.MODULES_WITH_SUB_MODULES.includes(value)) {
        const subModules = this.display_sub_module_list.filter(({ module }) => module.includes(value));
        subModules.forEach(x => {
          const index = sub_module && sub_module.length && sub_module.findIndex((y: string) => y === x.value);
          const moduleIndex = x.module && x.module.length && x.module.findIndex((x: string) => x === value);
          x.module.splice(moduleIndex, 1);
          index > -1 && x.module && !x.module.length && sub_module && sub_module.splice(index, 1);
        })
      }
    }
    if (filteredData && filteredData.length && typeof filteredData === 'object') {
      const removeIndex = filteredData.findIndex(x => x === value);
      if (removeIndex > -1) {
        filteredData.splice(removeIndex, 1);
      }
    }
    this.filterForm.get(formField).reset();
    controls[`${formField}`].setValue(filteredData && typeof filteredData === 'object' ? filteredData : '');
    this.submitFilterActivity();
  }

  filterActivityLogs(filterData) {
    Object.keys(filterData).forEach(key => {
      let value = filterData[key]
      if (value && key == 'userId') {
        this.updateList('User', key, value, this.allUsersList);
      }
      if (value && key == 'fromDate')
        this.updateList('From Date', key, value, this.selectedLogList);
      if (value && key == 'toDate')
        this.updateList('To Date', key, value, this.selectedLogList);
      if (value && key == 'module')
        this.updateList('Module', key, value, this.logConstants.module_list);
      if (value && key == 'sub_module')
        this.updateList('Sub Module', key, value, this.display_sub_module_list);
      if (this.filter_activity_type == 'login' && value && key == 'loginAction')
        this.updateList('Authentication Action', key, value, this.logConstants.loginActions);
      if (value && key == 'userAction')
        this.updateList('User Action', key, value, this.logConstants.userActions);
      if (value && key == 'taskType')
        this.updateList('Task Type', key, value, this.logConstants.task_list);
    });
  }

  submitFilterActivity(filterValue?) {
    this.filterList = [];
    this.filterForm.controls.filterEnabled.setValue(true);
    const filterData = this.filterForm.value;
    this.filter_activity_type === 'user' && (filterData.display_sub_module_list = this.display_sub_module_list);
    const filterActivityValue = this.filter_activity_type + 'ActivityFiltered';
    this._storage.set('session', filterActivityValue, filterData);
    this._storage.set('session', 'activityFiltered', filterActivityValue);
    this.page = 1;
    if (!filterValue) {
      this[filterActivityValue] = filterData;
    }
    this.filterActivityLogs(filterData);
    this.filterActivityTypeSpecifier(this.filter_activity_type);
    this.filterModal.hide();
  }

  changeFilterActivityType() {
    const filterActivityValue = this.filter_activity_type + 'ActivityFiltered';
    this.filterForm.reset();
    const filterData = this._storage.get('session', filterActivityValue);
    this.setFilterForm();
    if (filterData) {
      this.filterForm.patchValue(filterData);
      this.show_Sub_Module = filterData.display_sub_module_list.length ? true : false;
    }
    const { fromDate, toDate } = this.filterForm.value;
    if (!fromDate) this.fromDatePicker.clearDate();
    if (!toDate) this.toDatePicker.clearDate();
    this.onDateChange();
  }

  getAllUsers() {
    this.userService.getAllUsers({ limit: 0, fields: 'id,firstName,lastName', defaultIncludes: false })
      .then((res: any) => {
        if (res.count != 0) {
          res.rows.map(x => {
            x['value'] = x.id
            x['label'] = x.firstName + ' ' + x.lastName
          })
          this.allUsersList = this.utilitySorting.sortBy(res.rows, "firstName")
          if (this.user.roleCode == 'CC' || this.user.roleCode == 'ES') {
            this.episodeService.getEpisodesAssignedUsers().subscribe(result => {
              this.allUsersList = this.allUsersList.filter(user => result.assignedUsers.indexOf(user.id) >= 0 || user.id == this.user.id)
            })
          } else {
            this.allUsersList.push({ value: 0, label: 'System' })
            this.allUsersList = this.utilitySorting.sortBy(this.allUsersList, "label")
          }
          const activityFiltered = this._storage.get('session', 'activityFiltered');
          activityFiltered && (
            this[activityFiltered] && this.filterActivityLogs(this[activityFiltered]),
            this.filterActivityTypeSpecifier(this.filter_activity_type))
        }
      })
  }

  async changeActivityType(type) {
    this.filterForm.reset();
    this.setFilterForm();
    this.filterList = [];
    this.activity_type = type;
    this.filter_activity_type = type;
    this.selectedLogList = [];
    this.filteredLogList = [];
    this.page = 1;
    this.totalCount = 0;
    const filterActivityValue = this.filter_activity_type + 'ActivityFiltered';
    const logType = type + 'ActivityLog';
    const filteredValue = type + 'ActivityFiltered';
    this[filteredValue] = this._storage.get('session', filterActivityValue);
    if (this[filteredValue]) {
      this.filterForm.patchValue(this[filteredValue]);
      this.submitFilterActivity('filterValue');
    }
    else {
      await this['get' + logType[0].toLocaleUpperCase() + logType.slice(1)]();
    }
    if (this.activity_type == type) {
      this.selectedLogList = [...this.logList[logType]];
      this.filteredLogList = [...this.selectedLogList];
    }
    const { fromDate, toDate } = this.filterForm.value;
    if (!fromDate) this.fromDatePicker.clearDate();
    if (!toDate) this.toDatePicker.clearDate();
    this.onDateChange();
    if (this.searchKey) {
      this.searchKeyLogList(logType);
    }

  }

  async filterActivityTypeSpecifier(type) {
    this.activity_type = type
    this.filter_activity_type = type
    this.selectedLogList = []
    this.filteredLogList = []
    const logType = type + 'ActivityLog'
    if (this.searchKey) {
      this.searchKeyLogList(logType)
    } else {
      await this['get' + logType[0].toLocaleUpperCase() + logType.slice(1)]()
      this.selectedLogList = [...this.logList[logType]]
      this.filteredLogList = [...this.selectedLogList]
    }
  }

  async ResetFilter() {
    this.user_filter_text.next('');
    this.filterForm.reset();
    this.filteredLogList = [...this.selectedLogList]
    this.fromDatePicker.clearDate()
    this.toDatePicker.clearDate()
    this.filterList.length = 0;
    const logType = this.activity_type + 'ActivityLog'
    const filteredValue = this.activity_type + 'ActivityFiltered'
    this[filteredValue] = null;
    this._storage.set('session', filteredValue, null);
    if (this.searchKey) {
      this.searchKeyLogList(logType)
    } else {
      await this['get' + logType[0].toLocaleUpperCase() + logType.slice(1)]()
      this.selectedLogList = [...this.logList[logType]]
      this.filteredLogList = [...this.selectedLogList]
    }
  }

  onDateChange(event?, type?) {
    const { fromDate, toDate } = this.filterForm.value
    let startDate
    let endDate
    if (!(event && type)) {
      if (fromDate) {
        startDate = { year: fromDate.substr(-4, 4), month: fromDate.substr(-10, 2), day: fromDate.substr(-7, 2) }
      }
      if (toDate) {
        endDate = { year: toDate.substr(-4, 4), month: toDate.substr(-10, 2), day: toDate.substr(-7, 2) }
      }
    }
    if (type === 'startDate' || !(event && type)) {
      this.endDateOptions.disableUntil.year = startDate ? startDate.year : event ? event.date.year : 0
      this.endDateOptions.disableUntil.month = startDate ? startDate.month : event ? event.date.month : 0
      this.endDateOptions.disableUntil.day = startDate ? startDate.day : event ? event.date.day : 0
    }

    if (type === 'endDate' || !(event && type)) {
      this.startDateOptions.disableSince.year = endDate ? endDate.year : event ? event.date.year : 0
      this.startDateOptions.disableSince.month = endDate ? endDate.month : event ? event.date.month : 0
      this.startDateOptions.disableSince.day = endDate ? endDate.day : event ? event.date.day : 0
    }
  }

  moduleSelect(event?) {
    const { module, sub_module } = this.filterForm.value;
    const { episode_sub_module_list, pathway_sub_module_list, bundle_sub_module_list, claim_sub_module_list, report_sub_module_list } = this.logConstants;
    let subModuleList;
    subModuleList = module.includes('episode') ? episode_sub_module_list.map(x => ({ ...x, module: ['episode'] })) : module.includes('pathway') ? pathway_sub_module_list.map(x => ({ ...x, module: ['pathway'] })) : null;
    const bundleSubModuleList = module.includes('bundle') && bundle_sub_module_list.map(x => ({ ...x, module: ['bundle'] }));
    const claimSubModuleList = module.includes('claim') && claim_sub_module_list.map(x => ({ ...x, module: ['claim'] }));
    const reportSubModuleList = module.includes('report') && report_sub_module_list.map(x => ({ ...x, module: ['report'] }));

    if (event) {
      const subModules = this.display_sub_module_list.filter(({ module }) => module.includes(event.value)).map(x => x.value);
      sub_module && sub_module.length && subModules.forEach(module => {
        const index = sub_module.findIndex(x => x === module);
        index >= 0 && sub_module.splice(index, 1);
      });
    }

    if (module.some(value => LOGCONSTANTS.MODULES_WITH_SUB_MODULES.includes(value))) {
      this.show_Sub_Module = true;
      this.display_sub_module_list = [];
      module.forEach((value: string) => {
        switch (value) {
          case 'episode':
          case 'pathway':
            this.display_sub_module_list = subModuleList;
            break;
          case 'bundle':
            this.display_sub_module_list = [...this.display_sub_module_list, ...bundleSubModuleList];
            break;
          case 'report':
            this.display_sub_module_list = [...this.display_sub_module_list, ...reportSubModuleList];
            break;
          case 'claim':
            this.display_sub_module_list = [...this.display_sub_module_list, ...claimSubModuleList];
            break;
        }
      })
    }
    else this.show_Sub_Module = false;
  }

  pageChanged(event) {
    this.page = event;
    this.search(this.page);
  }

  async search(page) {
    this.page = page;
    this.loading = true
    const logType = this.activity_type + 'ActivityLog'
    const activityLogType = 'get' + this.activity_type[0].toLocaleUpperCase() + this.activity_type.slice(1) + 'Activity'
    if (this.searchKey) {
      this.searchKeyLogList(logType)
    }
    else {
      await this[activityLogType + 'Log']()
      this.selectedLogList = [...this.logList[logType]]
      this.filteredLogList = [...this.selectedLogList]
    }
  }

  searchKeyLogList(logType) {
    const filterData = this.filterForm.value
    const activityLogType = 'get' + this.activity_type[0].toLocaleUpperCase() + this.activity_type.slice(1) + 'Activity'
    this.activityService[activityLogType](this.limit, this.page, filterData, this.searchKey.trim()).then((res: any) => {
      this.loading = false
      this.totalCount = res.log.count
      this.logList[logType] = res.log.data
      this.selectedLogList = [...this.logList[logType]]
      this.filteredLogList = [...this.selectedLogList]
    })
      .catch((error) => {
        console.log('Error', error)
      });
  }

  change(text) {
    this.searchModelChanged.next(text);
  }

  removeSearchKeyword() {
    this.searchKey = ''
    this.change('')
  }
}
