import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { LINE_CHART_OPTIONS, MessageConstants, NO_DATA_IN_REPORT, REPORT_NAME_TYPES, KPI_VALUES, LINE_CHART_DATA_CONFIG, CHART_CUSTOM_COLORS, MONTHS_NAME_FULL, REPORT_NAME_CODES, REPORT_TAB_INDEX } from '../../../../constants';
import { ReportService } from '../../../../services';
import { ChartUtils, DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility } from '../../../../utils';


@Component({
  selector: 'app-trigger-claim-cycle-time',
  templateUrl: './trigger-claim-cycle-time.component.html',
  styleUrls: ['./trigger-claim-cycle-time.component.scss']
})
export class TriggerClaimCycleTimeComponent implements OnInit {

  triggerClaimCycleTimeDetailSelectedYearForm: FormGroup;
  loading = {
    triggerClaimsTimesCompany: false,
    triggerClaimsTimesFacility: false,
    triggerClaimsTimesFacilityChart: false,
    providerReference: false,
  }
  expand = {
    triggerClaimsTimesCompany: false,
    triggerClaimsTimesFacility: false,
    triggerClaimsTimesFacilityChart: false,
  }
  baseConfigs = {
    options: {},
    plugins: [],
    labels: [],
    dataset: [],
    chartType: '',
    actualData: null,
  };
  triggerClaimsTimesByCompanyChart = this._holistaUtils.deepClone(this.baseConfigs);
  triggerClaimsTimesByPayerChart = this._holistaUtils.deepClone(this.baseConfigs);
  triggerClaimsTimesFacilityChart = this._holistaUtils.deepClone(this.baseConfigs);
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT)
  REPORT_NAME_TYPES = REPORT_NAME_TYPES;
  REPORT_NAME_CODES = REPORT_NAME_CODES;

  parsedClientClaimsFilterData: any;
  triggerClaimsCycleTimesByFacilityForm: FormGroup;
  toShowReport: any[] = [];
  reportNameAndCodeList: any[] = [];
  providerReferencesList: any[] = [];
  detailList: any[] = [];
  yearOptions = {
    triggerClaimsCycleTimesByCompany: [],
    triggerClaimsCycleTimesByFacility: [],
  };

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();

  @Input() set clientClaimsDashboardFilterData(data) {
    if (data && data.activeTabIndex === REPORT_TAB_INDEX.TRIGGER_CLAIM_CYCLE_TIME_DETAIL) {
      let facilityId = '';
      if (this.triggerClaimsCycleTimesByFacilityForm) {
        facilityId = this.triggerClaimsCycleTimesByFacilityForm.get('facilityId').value;
      } else {
        this.setTriggerClaimsCycleTimesByFacilityForm();
      }
      this.expand = {
        triggerClaimsTimesCompany: false,
        triggerClaimsTimesFacility: false,
        triggerClaimsTimesFacilityChart: false,
      };
      this.parsedClientClaimsFilterData = this._holistaUtils.deepClone(data);
      const { networkCode, startDate, endDate, toShowReport } = this.parsedClientClaimsFilterData;
      this.toShowReport = toShowReport && toShowReport.map((x) => x.reportCode);
      this.reportNameAndCodeList = toShowReport && toShowReport.map((x) => ({
        reportName: x.customReportName,
        reportCode: x.reportCode
      }));
      this.getProviderReferencesList(
        {
          networkCode,
          entityCode: 2
        });
      const params = {
        networkCode,
        startDate,
        endDate,
      };
      const additionalConditons = toShowReport.length && startDate && endDate;
      if (this.toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY) && additionalConditons) {
        const year = +this._dateUtility.getYearFromDate(endDate);
        this.getTriggerCycleTimesCompany({ networkCode, year });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART) && additionalConditons) {
        this.getTriggerCycleTimesFacilityChart(params);
      }
      if (facilityId && this.toShowReport.includes(REPORT_NAME_CODES.PORTAL_ENGAGEMENT_MILESTONES_DETAIL) && additionalConditons) {
        const year = +this._dateUtility.getYearFromDate(endDate);
        this.getTriggerCycleTimesFacility(
          {
            networkCode,
            year,
            facilityId,
          }
        );
      }
    }
  }
  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === REPORT_TAB_INDEX.TRIGGER_CLAIM_CYCLE_TIME_DETAIL) {
      const { networkName, startDate, endDate } = this.parsedClientClaimsFilterData;
      const reportDate = `${startDate} ${endDate ? `- ${endDate}` : ''}`;
      const tabsData = [];
      if (this.toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY) && this.triggerClaimsTimesByCompanyChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.triggerClaimsTimesByCompanyChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Trigger Claim Cycle Times - Company',
          tabName: 'Trigger Claim Cycle Times - Company',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.triggerClaimCycleTimeDetailSelectedYearForm.value.triggerClaimCycleTimesByCompanySelectedYear}`,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY) && this.triggerClaimsTimesByPayerChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.triggerClaimsTimesByPayerChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Trigger Claim Cycle Times - Facility',
          tabName: 'Trigger Claim Cycle Times - Facility',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.triggerClaimsCycleTimesByFacilityForm.value.triggerClaimCycleTimesByFacilitySelectedYear}`,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART) && this.triggerClaimsTimesFacilityChart.labels.length) {
        const body = this._reportMappingUtils.triggerClaimCycleTimesMapper(this.triggerClaimsTimesFacilityChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Trigger Claim Cycle Times (Facility Chart)',
          tabName: 'Trigger Claim Cycle Times (Facility Chart)',
          headers: ['Facility', 'Average Days', 'Goal'],
          data: body,
          reportDate,
        });
      }
      if (isDownloadPDF.type === 'pdf') {
        const downloadPDF = this._pdfUtility.convertToPDF(['ccd-trigger-claim-cycle-time'], 'Client Claims Dashboard - Trigger Claim Cycle Time Detail', true, true);
        downloadPDF.finally(() => {
          this.downloadedPDF.emit(true);
        });
      }
      if (tabsData.length && isDownloadPDF.type === 'excel') {
        this._excelMultiTabUtility.exportToExcelMultiTab('Client Claims Dashboard - Trigger Claim Cycle Time Detail', tabsData).finally(() => {
          this.downloadedPDF.emit(true);
        })
      }
    }
  }

  constructor(
    private _messageConstants: MessageConstants,
    private _pdfUtility: PDFUtility,
    private _dateUtility: DateUtility,
    private _holistaUtils: HolistaUtils,
    private _chartUtils: ChartUtils,
    private reportService: ReportService,
    private formBuilder: FormBuilder,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
    private _reportMappingUtils: ReportMappingUtility,
  ) { }

  ngOnInit(): void {
    this.setTriggerClaimCycleTimeDetailSelectedYearForm();
    this.setTriggerClaimsCycleTimesByFacilityForm();
  }

  ngOnDestroy() {
    this.resetFlags();
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
  }

  getReport(code: string) {
    return this.reportNameAndCodeList.find(report => report.reportCode === code) || null;
  }

  resetFlags() {
    this.loading = {
      triggerClaimsTimesCompany: false,
      triggerClaimsTimesFacility: false,
      triggerClaimsTimesFacilityChart: false,
      providerReference: false,
    }
  }

  resetAllReports() {
    this.triggerClaimsTimesByCompanyChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.triggerClaimsTimesByPayerChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.triggerClaimsTimesFacilityChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  setTriggerClaimsCycleTimesByFacilityForm() {
    this.triggerClaimsCycleTimesByFacilityForm = this.formBuilder.group({
      facilityId: [null],
      triggerClaimCycleTimesByFacilitySelectedYear: [null],
    });
  }

  setTriggerClaimCycleTimeDetailSelectedYearForm() {
    this.triggerClaimCycleTimeDetailSelectedYearForm = this.formBuilder.group({
      triggerClaimCycleTimesByCompanySelectedYear: [null],
    });
  }

  getTriggerCycleTimesCompany(params?, isYearSelected = false) {
    this.loading.triggerClaimsTimesCompany = true;
    this.reportService.getTriggerCycleTimesCompany(params)
      .pipe(finalize(() => {
        this.loading.triggerClaimsTimesCompany = false;
        const { startDate, endDate } = this.parsedClientClaimsFilterData;
        const startYear = +this._dateUtility.getYearFromDate(startDate);
        const endYear = +this._dateUtility.getYearFromDate(endDate);
        if (startYear && endYear) {
          this.yearOptions.triggerClaimsCycleTimesByCompany = this._holistaUtils.getYearOptionList(startYear, endYear);
        }
        this.triggerClaimCycleTimeDetailSelectedYearForm.controls['triggerClaimCycleTimesByCompanySelectedYear'].setValue(isYearSelected ? params.year : endYear);
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.triggerClaimsTimesByCompanyChart.actualData = res;
          this.yearOptions.triggerClaimsCycleTimesByCompany = [];
          res = {
            ...res,
            datasets: res.dataSets.map((dataset) => {
              return {
                ...LINE_CHART_DATA_CONFIG,
                label: dataset.label,
                data: dataset.datas,
              }
            })
          };
          this.triggerClaimsTimesByCompanyChart.chartType = 'line';
          this.triggerClaimsTimesByCompanyChart.dataset = res.datasets;
          this.triggerClaimsTimesByCompanyChart.labels = res.labels;
          this.triggerClaimsTimesByCompanyChart.options = LINE_CHART_OPTIONS;
          this.triggerClaimsTimesByCompanyChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY);
        } else {
          this.triggerClaimsTimesByCompanyChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Trigger Cycle Times Company", error);
      })
  }

  getTriggerCycleTimesFacility(params?, isYearSelected = false) {
    this.loading.triggerClaimsTimesFacility = true;
    this.reportService.getTriggerCycleTimesFacility(params)
      .pipe(finalize(() => {
        this.loading.triggerClaimsTimesFacility = false;
        const { startDate, endDate } = this.parsedClientClaimsFilterData;
        const startYear = +this._dateUtility.getYearFromDate(startDate);
        const endYear = +this._dateUtility.getYearFromDate(endDate);
        if (startYear && endYear) {
          this.yearOptions.triggerClaimsCycleTimesByFacility = this._holistaUtils.getYearOptionList(startYear, endYear);
        }
        this.triggerClaimsCycleTimesByFacilityForm.controls['triggerClaimCycleTimesByFacilitySelectedYear'].setValue(isYearSelected ? params.year : endYear);
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.yearOptions.triggerClaimsCycleTimesByFacility = [];
          this.triggerClaimsTimesByPayerChart.actualData = res;
          res = {
            ...res,
            datasets: res.dataSets.map((dataset) => {
              return {
                ...LINE_CHART_DATA_CONFIG,
                label: dataset.label,
                data: dataset.datas,
              }
            })
          };
          this.triggerClaimsTimesByPayerChart.chartType = 'line';
          this.triggerClaimsTimesByPayerChart.dataset = res.datasets;
          this.triggerClaimsTimesByPayerChart.labels = res.labels;
          this.triggerClaimsTimesByPayerChart.options = LINE_CHART_OPTIONS;
          this.triggerClaimsTimesByPayerChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY);
        } else {
          this.triggerClaimsTimesByPayerChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Trigger Cycle Times Facility", error);
      })
  }

  getTriggerCycleTimesFacilityChart(params?) {
    this.loading.triggerClaimsTimesFacilityChart = true;
    this.reportService.getTriggerCycleTimesFacilityChart(params)
      .pipe(finalize(() => { this.loading.triggerClaimsTimesFacilityChart = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.label && res.label.length) {
          this.triggerClaimsTimesFacilityChart.actualData = res;
          res = {
            ...res,
            datasets: [{
              data: res.average_days,
              maxBarThickness: 30,
              backgroundColor: CHART_CUSTOM_COLORS[1],
              borderColor: CHART_CUSTOM_COLORS[1],
              hoverBackgroundColor: CHART_CUSTOM_COLORS[1],
            }]
          };
          this.triggerClaimsTimesFacilityChart.chartType = 'horizontalBar';
          this.triggerClaimsTimesFacilityChart.dataset = res.datasets;
          this.triggerClaimsTimesFacilityChart.labels = res.label;
          this.triggerClaimsTimesFacilityChart.options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    fontStyle: "bold"
                  },
                  scaleLabel: {
                    display: false,
                  },
                  gridLines: {
                    display: false
                  }
                }
              ],
              xAxes: [
                {
                  ticks: {
                    fontStyle: "bold",
                    beginAtZero: true,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Average Days"
                  },
                  gridLines: {
                    display: true
                  }
                }
              ]
            },
            plugins: {
              datalabels: {
                display: false,
              }
            },
            layout: {
              padding: {
                right: 50
              }
            }
          };
          this.triggerClaimsTimesFacilityChart.plugins = [{
            afterDatasetsDraw: function (chart) {
              const { ctx, chartArea: { top, bottom }, scales } = chart;
              const xAxis = scales["x-axis-0"]; // Retrieve x-axis scale
              ctx.save();
              const goal = res.goal;
              ctx.beginPath();
              ctx.strokeStyle = 'gray';
              ctx.lineWidth = 1.5;
              const xPosition = xAxis.getPixelForValue(goal); // Use xAxis instead of x
              ctx.moveTo(xPosition, top);
              ctx.lineTo(xPosition, bottom);
              ctx.stroke();
              // Add text label
              ctx.fillStyle = 'black';
              ctx.font = '12px Arial';
              ctx.textAlign = 'left';
              ctx.textBaseline = 'middle';
              ctx.fillText('Goal: ' + goal, (xPosition + 5), (bottom - 10)); // Adjust the position as needed
              ctx.restore();
            }
          }]
        } else {
          this.triggerClaimsTimesFacilityChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Trigger Cycle Times Facility Chart", error);
      })
  }

  onViewToggle(reportName, expand = false) {
    // this.reportExpanded.emit(expand); // Uncomment if download is not needed while expanding
    switch (reportName) {
      case REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY:
        this.expand.triggerClaimsTimesCompany = expand;
        break;
      case REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY:
        this.expand.triggerClaimsTimesFacility = expand;
        break;
      case REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART:
        this.expand.triggerClaimsTimesFacilityChart = expand;
        break;
      default:
        break;
    }
  }

  getProviderReferencesList(params?) {
    this.loading.providerReference = true;
    this.reportService.getProviderReferences(params)
      .pipe(finalize(() => {
        this.loading.providerReference = false;
      }))
      .subscribe((res) => {
        this.providerReferencesList = res.map((x) => {
          return {
            ...x,
            label: x.name,
            value: x.providerId,
          }
        });
      }, (error) => {
        console.log("Error getting Provider Reference List", error)
      })
  }

  facilityReferenceSelected(event) {
    if (event) {
      const selectedYear = this.triggerClaimsCycleTimesByFacilityForm.get('triggerClaimCycleTimesByFacilitySelectedYear').value;
      const { networkCode, endDate } = this.parsedClientClaimsFilterData;
      const year = selectedYear ? selectedYear : +this._dateUtility.getYearFromDate(endDate);
      this.getTriggerCycleTimesFacility(
        {
          networkCode,
          year,
          facilityId: this.triggerClaimsCycleTimesByFacilityForm.get('facilityId').value
        }
      )
    }
  }

  yearSelected(event, reportCode) {
    const params = {
      year: event.value,
      networkCode: this.parsedClientClaimsFilterData.networkCode,
    };
    switch (reportCode) {
      case REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY:
        this.getTriggerCycleTimesCompany(params, true);
        break;
      case REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY:
        this.getTriggerCycleTimesFacility(
          {
            ...params,
            facilityId: this.triggerClaimsCycleTimesByFacilityForm.get('facilityId').value,
          }, true);
        break;
      default:
        break;
    }
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = (
      this.triggerClaimsTimesByCompanyChart.labels.length ||
      this.triggerClaimsTimesByPayerChart.labels.length ||
      this.triggerClaimsTimesFacilityChart.labels.length
    );
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

}
