import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PathwayService, NetworkService, ClientService, PurchaserService, ToasterService, ExcelService } from '../../services';
import { Store } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../../action';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { AccessType, Storage, HolistaUtils, ScrollTo, Sorting } from '../../utils';
import { ACCESS_DENIED, ADD_SUCCESS, DELETE_SUCCESS, DELETE_WARNING, DUPLICATE_TAG_NAME, EXPORT_PATHWAY_FAILED, IMPORT_PATHWAY_FAILED, IMPORT_PATHWAY_SUCCESS, INVALID_FILE_FORMAT, MessageConstants, PATHWAY_NOT_FOUND, PATHWAY_REPORT, RestrictSpace, TrimFields, UPDATE_SUCCESS } from '../../constants';
import { Subject } from 'rxjs';
import { ExportData, PathwayReportMedia, PathwayReportTask } from '../../models/model.exportData';
import { DependentTask } from '../../models';

@Component({
  selector: 'app-pathway',
  templateUrl: './pathway.component.html',
  styleUrls: ['./pathway.component.scss']
})
export class PathwayComponent implements OnInit {
  pathwayForm: FormGroup;
  searchKeywordChanged: Subject<string> = new Subject<string>();
  pathways = [];
  networks: any[] = []
  networksForFilter: any[] = []
  selected_pathway: any;
  loading = { pathways: false };
  submit_enabled = false;
  submitted = false;
  is_tag_exist = false;
  file: any;
  import_enabled = false;
  loggedUser: any;
  totalCount = 0;
  importOptions = { allowedContentTypes: ['application/json'] };
  result =
    {
      pathways: true,
      searchedPathways: true
    }
  query = {
    networkCode: '',
    searchKeyword: '',
    limit: 10,
    page: 1,
    sortBy: 'createdDate',
    orderBy: 'desc'
  }
  reverse: boolean = true;
  message: any
  deleteId: string
  exportingExcel: boolean = false;

  @ViewChild('pathwayModal', { static: true }) public pathwayModal;

  constructor(
    private pathwayService: PathwayService,
    private networkService: NetworkService,
    private clientService: ClientService,
    private purchaserService: PurchaserService,
    private formBuilder: FormBuilder,
    private toastr: ToasterService,
    private _messageConstants: MessageConstants,
    private store: Store<{ bread_crumbs: any }>,
    private router: Router,
    private holistaUtils: HolistaUtils,
    public utilityAccess: AccessType,
    private _storage: Storage,
    private _scrollTo: ScrollTo,
    private utilitySorting: Sorting,
    private excelService: ExcelService) {
    this.setPathwayForm();
    this.searchKeywordChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
      this.query.searchKeyword = keyword ? keyword.trim() : '';
      this.query.page = 1;
      this.getPathways();
    });
  }

  ngOnInit() {
    this.loggedUser = this._storage.get('local', 'loggedInUser', 'user');
    this.loggedUser.roleCode == 'CA' ? this.getClientNetworks() : this.loggedUser.roleCode == 'PUA' ? this.getPurchaserNetworks() : this.getNetworks()
    this.getPathways();
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb());
    this.store.dispatch(new HeaderBreadCrumbActions.AddBreadCrumb({ name: 'Pathways', path: 'pathway' }));
  }

  setPathwayForm() {
    this.pathwayForm = this.formBuilder.group({
      networkCode: [null, Validators.required],
      name: ['', [Validators.required, Validators.pattern(RestrictSpace)]],
      description: [null, Validators.pattern(RestrictSpace)],
      id: [null],
      uuid: [null],
      tags: [null],
      tagName: ['', Validators.pattern(RestrictSpace)]
    });
  }

  getClientNetworks() {
    this.clientService.getNetworksByClientCode(this.loggedUser.referenceCode)
      .subscribe(res => {
        res.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.networks = JSON.parse(JSON.stringify(res))
        this.networksForFilter = JSON.parse(JSON.stringify(res))
      },
        (error) => {
          console.log('Error', error)
        })
  }

  getPurchaserNetworks() {
    this.purchaserService.getNetworks(this.loggedUser.referenceCode)
      .subscribe(res => {
        res.rows.map(network => {
          network.label = network.name
          network.value = network.code
          return network
        })
        this.networks = JSON.parse(JSON.stringify(res.rows));
        this.networksForFilter = JSON.parse(JSON.stringify(res.rows));
      },
        (error) => {
          console.log('Error', error)
        })
  }

  getNetworks() {
    this.networkService.getAll({ limit: 0, fields: 'code,name' })
      .subscribe((res: any) => {
        res.rows = this.utilitySorting.sortBy(res.rows, 'name');
        res.rows.map(x => {
          x.label = x.name,
            x.value = x.code
          return x;
        })
        this.networks = JSON.parse(JSON.stringify(res.rows));
        this.networksForFilter = JSON.parse(JSON.stringify(res.rows));
      }, (error) => {
        console.log("Error getting Networks", error);
      })
  }

  getPathways() {
    this.loading.pathways = true;
    this.result.searchedPathways = true;
    this.result.pathways = true;
    this.pathwayService.getPathways(this.query)
      .pipe(finalize(() => { this.loading.pathways = false; }))
      .subscribe((response: any) => {
        if (response && response.count > 0) {
          this.totalCount = response.count;
          response.rows.map(x => {
            x.tags = x.tags ? (typeof x.tags === 'string' ? JSON.parse(x.tags) : x.tags) : null;
          })
          this.pathways = response.rows;
        }
        else {
          this.pathways = [];
          (this.query.searchKeyword.length > 0 || this.query.networkCode) ? (this.result.searchedPathways = false) : (this.result.pathways = false);
        }
      });
  }

  searchByNetwork(event, type?) {
    this.query.networkCode = (type && type == 'remove') ? '' : event.value;
    this.query.page = 1;
    if (!type)
      this.networksForFilter = this.networksForFilter.map(network => {
        if (network.value == event.value) network.disabled = true
        else network.disabled = false
        return network
      })
    else
      this.networksForFilter = this.networksForFilter.map(x => { x.disabled = false; return x })
    this.getPathways();
  }

  goToMileStone(pathway) {
    this.router.navigate(['pathway', pathway.id, 'milestone'], { state: { data: pathway } });
    this.selected_pathway = pathway;
  }

  openPathwayModal(pathway?) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      if (pathway && pathway.id) {
        let body = JSON.parse(JSON.stringify(pathway));
        this.pathwayForm.patchValue(body);
      }
      this.pathwayModal.show();
    } else {
      this.toastr.displayWarning(ACCESS_DENIED);
    }
  }

  closePathwayModal() {
    this.pathwayForm.reset();
    this.pathwayModal.hide();
    this.submit_enabled = false;
    this.submitted = false;
    this.is_tag_exist = false;
    this.result.searchedPathways = true;
    this.result.pathways = true;
  }

  setNullForEmpty(formName) {
    Object.keys(formName.value).forEach(k => {
      formName.controls[k].setValue(formName.value[k] || typeof formName.value[k] == 'boolean' ? formName.value[k] : null)
    })
  }

  submitPathway() {
    this.submitted = true;
    this.is_tag_exist = false;
    this.onEnterTag();
    if (this.pathwayForm.valid && !this.is_tag_exist) {
      this.submit_enabled = true;
      TrimFields.Pathway.forEach(element => {
        this.pathwayForm.value[element] = this.pathwayForm.value[element] ? this.pathwayForm.value[element].trim() : this.pathwayForm.value[element];
      });
      this.setNullForEmpty(this.pathwayForm)
      if (this.pathwayForm.value.id) this.updatePathway();
      else this.savePathway();
    }
  }

  savePathway() {
    this.pathwayService
      .savePathway(this.pathwayForm.value)
      .pipe(finalize(() => { this.submit_enabled = false; }))
      .subscribe((res: any) => {
        if (res.data) {
          this.refreshPathways(res, ADD_SUCCESS);
        } else if (!res.isCreated && res.message) {
          this.toastr.displayWarning(res.message);
        }
      }, (error) => {
        console.log("Error saving pathway", error);
      });
  }

  updatePathway() {
    this.pathwayService
      .updatePathway(this.pathwayForm.getRawValue())
      .pipe(finalize(() => { this.submit_enabled = false; }))
      .subscribe((res: any) => {
        if (res.data) {
          this.refreshPathways(res, UPDATE_SUCCESS);
        } else if (!res.isCreated && res.message) {
          this.toastr.displayWarning(res.message);
        }
      }, (error) => {
        console.log("Error upadting pathway", error);
        this.toastr.displayError(error.error.message);
      });
  }

  refreshPathways(response, action) {
    if (response.message) {
      this.toastr.displayError(response.message);
    } else {
      let pathway = action == ADD_SUCCESS || action == IMPORT_PATHWAY_SUCCESS ? response.data : response.data[0];
      pathway.tags = pathway.tags ? JSON.parse(pathway.tags) : null;
      if (this.pathways && this.pathways.length > 0) {
        const index = this.pathways.findIndex(x => x.id === pathway.id);
        if (index > -1) {
          pathway.milestones = this.pathways[index].milestones;
          this.pathways[index] = pathway;
        } else {
          pathway.milestones = action == IMPORT_PATHWAY_SUCCESS ? pathway.milestones : [];
          this.query.page = 1;
          this.getPathways();
        }
      } else {
        pathway.milestones = [];
        this.getPathways();
      }
      this.toastr.displaySuccess(action);
    }
    this.closePathwayModal();
  }

  onEnterTag() {
    if (this.pathwayForm.value.tagName && this.pathwayForm.get('tagName').valid) {
      let temp_tags = [];
      temp_tags = this.pathwayForm.value.tags ? this.pathwayForm.value.tags : [];
      let i = temp_tags.findIndex(tag => tag.toLowerCase() === this.pathwayForm.value.tagName.trim().toLowerCase());
      if (i <= -1) {
        temp_tags.push(this.holistaUtils.properCase(this.pathwayForm.value.tagName.trim()));
        this.pathwayForm.controls.tags.setValue(temp_tags);
      } else {
        this.is_tag_exist = true;
        this.toastr.displayWarning(DUPLICATE_TAG_NAME);
      }
      this.pathwayForm.controls.tagName.setValue('');
    }
    if (!(this.pathwayForm.value.tags && this.pathwayForm.value.tags.length > 0)) {
      this.pathwayForm.controls.tags.setValue(null);
    }
  }

  removetag(tag) {
    let temp_tags = [];
    temp_tags = this.pathwayForm.value.tags ? this.pathwayForm.value.tags : [];
    temp_tags.splice(temp_tags.indexOf(tag), 1);
    this.pathwayForm.controls.tags.setValue(temp_tags);
    if (!(this.pathwayForm.value.tags && this.pathwayForm.value.tags.length > 0)) {
      this.pathwayForm.controls.tags.setValue(null);
    }
  }

  exportPathway({ uuid, name }) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      this.pathwayService.exportPathway({ uuid })
        .subscribe(([res]) => {
          if (res) {
            let export_json = JSON.stringify(res);
            var file = new File([export_json], `${name.replace(/ /g, '_')}.json`, { type: 'application/json;charset=utf-8' });
            saveAs(file);
          } else {
            this.toastr.displayError(EXPORT_PATHWAY_FAILED);
          }
        });
    } else {
      this.toastr.displayWarning(ACCESS_DENIED);
    }
  }

  importPathway(data) {
    this.import_enabled = true;
    this.pathwayService.importPathway(data)
      .pipe(finalize(() => { this.file = null; this.import_enabled = false; }))
      .subscribe(res => {
        this.refreshPathways(res, IMPORT_PATHWAY_SUCCESS);
      }, error => {
        console.log("Error importing pathway", error);
        this.toastr.displayError(IMPORT_PATHWAY_FAILED);
      });
  }

  onUploadOutput(output): void {
    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'addedToQueue') {
      this.file = output.file; // add file when added
      var reader = new FileReader();
      reader.onload = () => {
        let loadedJson = reader.result.toString();
        if (loadedJson.includes(`{"pathway":`)) {
          let formattedJson = JSON.parse(loadedJson);
          if (formattedJson && formattedJson.pathway && formattedJson.pathway.id) {
            this.importPathway(formattedJson);
          } else {
            this.toastr.displayWarning(PATHWAY_NOT_FOUND);
          }
        } else {
          this.toastr.displayWarning(PATHWAY_NOT_FOUND);
        }
      };
      reader.readAsText(this.file.nativeFile);
    } else if (output.type === 'rejected') {
      this.file = null;
      this.toastr.displayWarning(INVALID_FILE_FORMAT);
    }
  }

  scrollToTop() {
    this._scrollTo.ScrollTo('scrollToTop', 'center', 'smooth')
  }

  pageChanged(event) {
    this.query.page = event;
    this.getPathways();
  }

  setOrder(value: string) {
    if (this.query.sortBy === value) {
      this.reverse = !this.reverse;
      this.query.orderBy = this.query.orderBy === "desc" ? "asc" : "desc";
    }
    else {
      this.reverse = true;
      this.query.orderBy = "desc";
    }
    this.query.sortBy = value;
    this.getPathways();
  }

  change(text) {
    this.searchKeywordChanged.next(text);
  }

  descriptionChange(event) {
    this.pathwayForm.controls.description.setValue(event.target.value.replace(/[^a-zA-Z0-9_ ]/g, ""))
  }

  deletePathway(pathway) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      this.deleteId = pathway.uuid;
      const deleteWarningMessage = this._messageConstants.getMessage(DELETE_WARNING, pathway.name);
      this.message = deleteWarningMessage.value;
    }
    else this.toastr.displayWarning(ACCESS_DENIED);
  }

  confirmDelete(uuid) {
    if (uuid) {
      this.pathwayService.deletePathway(uuid).subscribe(res => {
        if (res.message) {
          this.toastr.displayWarning(res.message);
          this.deleteId = null;
        }
        else {
          this.toastr.displaySuccess(DELETE_SUCCESS);
          this.getPathways()
        }
      }, (error) => {
        this.deleteId = null;
        console.log('Error', error)
        this.toastr.displayError(error.error.message);
      })
    } else this.deleteId = null;
  }

  removeSearchKeyword() {
    this.query.searchKeyword = '';
    this.change('');
  }

  exportPathwayAsExcel(): void {
    this.exportingExcel = true;
    this.pathwayService.exportPathway({ searchKeyword: this.query.searchKeyword, networkCode: this.query.networkCode })
      .pipe(finalize(() => this.exportingExcel = false))
      .subscribe(res => {
        const headers: string[] = PATHWAY_REPORT;
        const exportList = this.formatPathwayToExcelExportFormat(res);
        const exportData: ExportData[] = [
          { exportList, headers, excelName: 'Pathway List', tableTitle: 'Pathway Report', options: { action: 'getPathwayHeader', customFormatter: 'setPathwayHeaderAndData' } }
        ];
        this.excelService.exportExcel(exportData);
      })
  }

  formatPathwayToExcelExportFormat(pathways: any[]): [number, string, string | null, string, string | null, string, string | null, string | null, PathwayReportMedia | null][] {
    const formattedPathway = [];
    for (const [pathwayIndex, { milestones, pathway: { name: pathwayName, description: pathwayDescription }, milestone_tasks, taskTodos, taskSignatures, taskMessages, taskQuestions, taskQnrs }] of pathways.entries()) {
      for (const [milestoneIndex, { id: milestoneId, name: milestoneName, description: milestoneDescription }] of this.utilitySorting.sortBy(milestones, 'sequence').entries()) {
        const milestoneTopics = this.utilitySorting.sortBy(milestone_tasks.filter(mt => mt.milestoneId === milestoneId), 'sequence');
        if (milestoneTopics.length) {
          for (const [topicIndex, { taskId }] of this.utilitySorting.sortBy(milestoneTopics, 'sequence').entries()) {
            const tasks = this.getTasksById(taskId, { taskTodos, taskSignatures, taskMessages, taskQuestions, taskQnrs }).entries();
            for (const [taskIndex, { name: taskName, description: taskDescription, text, hyperlink, taskType }] of tasks) {
              const showPathway = milestoneIndex === 0 && topicIndex === 0 && taskIndex === 0;
              // To show pathway name, pathway description and milestone name and description only once
              formattedPathway.push([showPathway ? pathwayIndex + 1 : null, showPathway ? pathwayName : null, showPathway ? pathwayDescription : null, topicIndex === 0 && taskIndex === 0 ? milestoneName : null, topicIndex === 0 && taskIndex === 0 ? milestoneDescription : null, taskType, taskName, taskDescription, text && text ? { text, hyperlink, tooltip: text } : null]);
            }
          }
        } else {
          formattedPathway.push([milestoneIndex === 0 ? pathwayIndex + 1 : null, milestoneIndex === 0 ? pathwayName : null, milestoneIndex === 0 ? pathwayDescription : null, milestoneName, milestoneDescription]);
        }
      }
    }
    return formattedPathway;
  }

  getTasksById(taskId: number, taskItems): PathwayReportTask[] {
    const { taskTodos, taskSignatures, taskMessages, taskQuestions, taskQnrs } = taskItems;
    let formattedTasks = this.utilitySorting.sortBy(
      [
        ...this.getTaskByTaskId(taskTodos, taskId, false)
          .map(({ name, instruction: description, documentDisplayName: text, taskTodoLink: hyperlink, sequence }) => { return { name, description, text, hyperlink, taskType: 'Action Item', sequence } }),
        ...this.getTaskByTaskId(taskSignatures, taskId, false)
          .map(({ name, instruction: description, documentDisplayName: text, taskTodoLink: hyperlink, sequence }) => { return { name, description, text, hyperlink, taskType: 'Signature', sequence } }),
        ...this.getTaskByTaskId(taskMessages, taskId, false)
          .map(({ messages: description, title: name, documentDisplayName: text, documentPath: hyperlink, sequence }) => { return { name, description, text, hyperlink, taskType: 'Message', sequence } }),
        ...this.getTaskByTaskId(taskQuestions, taskId, false)
          .map(({ questions: { question: name, description, uuid }, sequence }) => { return { name, description, taskType: 'Question', sequence, uuid } }),
        ...this.getTaskByTaskId(taskQnrs, taskId, false)
          .map(({ sequence, qnrs: { name, description, id: questionnaireId } }) => { return { name, description, sequence, taskType: 'Questionnaire', questionnaireId } })
      ],
      'sequence'
    );
    const questionnaireQuestions = [];
    for (const { qnrs: { qnrsQues } } of this.getTaskByTaskId(taskQnrs, taskId, false)) {
      for (const { question: { question: name, description, sequence, uuid }, questionnaireId } of qnrsQues) {
        questionnaireQuestions.push({ questionnaireId, name, description, taskType: 'Question', sequence, uuid });
      }
    }
    formattedTasks = this.flattenQuestionnaireToArray(formattedTasks, questionnaireQuestions);
    let dependentTasks = [
      ...this.getTaskByTaskId(taskTodos, taskId, true)
        .map(({ name, instruction: description, documentDisplayName: text, taskTodoLink: hyperlink, sequence, parentUuid }) => { return { name, description, text, hyperlink, taskType: 'Action Item', sequence, parentUuid } }),
      ...this.getTaskByTaskId(taskSignatures, taskId, true)
        .map(({ name, instruction: description, documentDisplayName: text, taskTodoLink: hyperlink, sequence, parentUuid }) => { return { name, description, text, hyperlink, taskType: 'Signature', sequence, parentUuid } }),
      ...this.getTaskByTaskId(taskMessages, taskId, true)
        .map(({ messages: description, title: name, documentDisplayName: text, documentPath: hyperlink, sequence, parentUuid }) => { return { name, description, text, hyperlink, taskType: 'Message', sequence, parentUuid } }),
      ...this.getTaskByTaskId(taskQuestions, taskId, true)
        .map(({ questions: { question: name, description }, sequence, parentUuid }) => { return { name, description, taskType: 'Question', sequence, parentUuid } }),
      ...this.getTaskByTaskId(taskQnrs, taskId, true)
        .map(({ sequence, parentUuid, qnrs: { name, description, id: questionnaireId } }) => { return { name, description, sequence, taskType: 'Questionnaire', questionnaireId, parentUuid } })
    ];

    while (dependentTasks.length) {
      const beforelength = dependentTasks.length;
      for (const [formattedTaskIndex, formattedTask] of formattedTasks.entries()) {
        const tasks = this.utilitySorting.sortBy(dependentTasks.filter(task => task.parentUuid === formattedTask.uuid), 'sequence');
        dependentTasks = dependentTasks.filter(task => task.parentUuid !== formattedTask.uuid);
        if (!tasks.length) continue;
        formattedTasks.splice(formattedTaskIndex + 1, 0, ...tasks);
        const nestedQuestionnaireIds = tasks.filter(t => t.taskType === 'Questionnaire').map(q => q.questionnaireId);
        if (!nestedQuestionnaireIds.length) continue;
        const nestedQuestionnaireQuestions = []
        for (const { qnrs: { qnrsQues } } of this.getTaskByTaskId(taskQnrs, taskId, true)
          .filter(({ qnrs: { id: questionnaireId } }) => nestedQuestionnaireIds.includes(questionnaireId))) {
          for (const { question: { question: name, description, sequence }, questionnaireId, uuid } of qnrsQues) {
            nestedQuestionnaireQuestions.push({ questionnaireId, name, description, taskType: 'Question', sequence, uuid });
            formattedTasks = this.flattenQuestionnaireToArray(formattedTasks, nestedQuestionnaireQuestions);
          }
        }
      }
      if (beforelength === dependentTasks.length) break;
    }
    return formattedTasks;
  }

  flattenQuestionnaireToArray(tasks: any[], questionnaireQuestions: any[]) {
    while (questionnaireQuestions.length) {
      for (const [taskIndex, { taskType, questionnaireId }] of tasks.entries()) {
        if (taskType !== 'Questionnaire') continue;
        const specificQuestionnaireQuestions = this.utilitySorting.sortBy(questionnaireQuestions.filter(({ questionnaireId: qid }) => qid === questionnaireId), 'sequence');
        questionnaireQuestions = questionnaireQuestions.filter(({ questionnaireId: qid }) => qid !== questionnaireId);
        if (!specificQuestionnaireQuestions.length) continue;
        tasks.splice(taskIndex + 1, 0, ...specificQuestionnaireQuestions);
      }
    }
    return tasks;
  }

  getTaskByTaskId(task: any[], taskId: number, dependent: boolean) {
    return task.filter(({ isDependent, taskId: specificTaskId }) => taskId === specificTaskId && dependent === isDependent);
  }
}
