<app-loader [loader]="loading"></app-loader>
<section class="p-3 grey lighten-4" *ngIf="pathway && pathway.id" id="sectionDesc">
  <div class="row">
    <div class="col">
      <h3>{{ pathway.name }}</h3>
      <p class="mb-0">{{ pathway.description }}</p>
    </div>
  </div>
</section>

<section
  class="container-fluid"
  *ngIf="pathway && pathway.id"
  [ngStyle]="{height: 'calc(100vh - ' + '(' + sectionDescHeight + 'px + 98px)' + ')'}">
  <div class="row">
    <div class="col-sm-4 col-md-4 col-lg-3 col-xl-3">
      <div
        class="sticky-content mt-2 pr-3 scrollbar scrollbar-primary thin"
        [ngStyle]="{height: 'calc(100vh - ' + '(' + sectionDescHeight + 'px + 98px)' + ')'}">
        <div class="position-sticky header">
          <header class="d-flex justify-content-between h-auto pb-2">
            <h5 class="mb-0">Milestones</h5>
            <a (click)="addMilestone()" class="skin-link"
              ><i class="icofont-plus icofont-sm"></i> Add Milestone</a
            >
          </header>
          <div class="form-group has-search mt-2 mb-2 position-relative">
            <span class="form-control-feedback">
              <i class="icofont-search-1"></i>
            </span>
            <input
              type="text"
              [(ngModel)]="searchKey"
              class="form-control"
              id="search"
              placeholder="Search" />
          </div>
        </div>
        <div class="dndPlaceholder col-md-12" #placeholder></div>
        <div
          *ngIf="pathway && pathway.milestones && pathway.milestones.length > 0"
          [dndList]
          [dndModel]="pathway.milestones"
          [dndPlaceholder]="placeholder">
          <div
            *ngFor="
              let milestone of pathway.milestones
                | filter : searchKey : ['name', 'description']
                | orderBy : 'sequence';
              let i = index
            ">
            <mdb-card
              class="mb-3 z-depth-0 pointer-cursor skin-light"
              [ngClass]="{
                'skin-primary text-white border-0 ': selectedMilestone['id'] == milestone['id']
              }"
              (click)="goToMileStoneTopic(milestone)"
              [dndType]="milestone.id"
              (dndMoved)="onDrop(milestone, pathway.milestones)"
              [dndDraggable]
              [dndObject]="milestone">
              <mdb-card-header class="d-flex justify-content-between pr-5">
                <h6 class="mb-0">{{ milestone.name }}</h6>

                <span class="action-item">
                  <i class="icofont-ui-edit icofont-sm mr-2" (click)="editMilestone(milestone)"></i>
                  <i
                    class="icofont-ui-delete icofont-sm mr-2"
                    (click)="milestoneToDelete(milestone)"></i>
                </span>
              </mdb-card-header>
              <mdb-card-body>
                <mdb-card-text>
                  <p [ngClass]="{'text-white': selectedMilestone['id'] == milestone['id']}">
                    {{ milestone.description }}
                  </p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
        <div
          class="text-center p-4 border mt-3"
          *ngIf="
            !loading &&
            pathway.milestones.length > 0 &&
            (pathway.milestones | filter : searchKey : ['name', 'description']).length == 0
          ">
          <i class="icofont-tasks-alt icofont-3x text-black-50"></i>
          <p>No results found.</p>
        </div>
        <div class="text-center p-4 border mt-3" *ngIf="pathway && pathway.milestones.length == 0">
          <i class="icofont-tasks-alt icofont-4x text-black-50"></i>
          <p>Add milestone to create tasks for that milestone.</p>
        </div>
      </div>
    </div>
    <div class="col">
      <div
        class="mt-2 sticky-content scrollbar scrollbar-primary thin"
        [ngStyle]="{height: 'calc(100vh - ' + '(' + sectionDescHeight + 'px + 98px)' + ')'}">
        <app-milestone-topic
          *ngIf="selectedMilestone && selectedMilestone['id']"
          [milestones]="pathway.milestones"
          [selected_milestone]="selectedMilestone"></app-milestone-topic>
      </div>
    </div>
  </div>
</section>

<div
  mdbModal
  #milestoneModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ milestoneForm.value.id ? 'Edit' : 'Add' }} Milestone</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="milestoneForm">
          <div class="form-row">
            <div class="col-7">
              <div class="form-group">
                <label for="MilestoneName">Milestone Name *</label>
                <input
                  type="text"
                  id="MilestoneName"
                  class="form-control"
                  mdbInput
                  formControlName="name"
                  [ngClass]="{'is-invalid': submitted && milestoneForm.get('name').invalid}" />
              </div>
            </div>
            <div class="col-5">
              <div class="form-group">
                <label for="MilestoneName">Group</label>
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="isLoading.groups"></span>
                <app-select
                  [disabled]="isLoading.groups"
                  [options]="groups"
                  [allowClear]="true"
                  [id]="'groupCode'"
                  (onDeselected)="onGroupDeselected()"
                  [control]="milestoneForm.controls['groupCode']"></app-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="milestone-date">Milestone Date</label>
            <div class="form-row">
              <div class="col">
                <input
                  type="text"
                  id="triggerDays"
                  class="form-control mt-2"
                  mdbInput
                  formControlName="triggerDays"
                  [readOnly]="TriggerOnStart" />
              </div>
              <div class="col">
                <mdb-select
                  [options]="triggerPeriodOptions"
                  class="mt-2 search-dropdown"
                  formControlName="triggerPeriod"
                  [disableControl]="TriggerOnStart ? true : false"></mdb-select>
              </div>
              <div class="col">
                <mdb-select
                  [options]="triggerConditionOptions"
                  class="mt-2 search-dropdown"
                  formControlName="triggerCondition"
                  [disableControl]="TriggerOnStart ? true : false">
                </mdb-select>
              </div>
              <div class="col-5">
                <mdb-select
                  [options]="triggerMilestoneOptions"
                  class="mt-2 search-dropdown"
                  formControlName="triggerMilestoneId"
                  [disableControl]="TriggerOnStart ? true : false"
                  (deselected)="deselectMilestone($event)"
                  [allowClear]="true">
                </mdb-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="MilestoneDesc">Description</label>
            <textarea
              type="text"
              id="MilestoneDesc"
              class="md-textarea form-control"
              mdbInput
              formControlName="description"
              [ngClass]="{'is-invalid': submitted && milestoneForm.get('description').invalid}">
            </textarea>
          </div>
          <div class="switch mt-3">
            <label class="d-flex justify-content-between">
              <label>Trigger at the start of the episode</label>
              <input
                type="checkbox"
                formControlName="isTriggerOnStart"
                (click)="isTriggerOnStart($event, milestoneForm.value.id)"
                [(ngModel)]="TriggerOnStart" />
              <span class="lever"></span>
            </label>
          </div>
          <div class="switch mt-3">
            <label class="d-flex justify-content-between">
              <label>Set as Primary Milestone</label>
              <input
                type="checkbox"
                formControlName="isKeyMilestone"
                (click)="isPrimaryMilestone($event)" />
              <span class="lever"></span>
            </label>
          </div>
          <div class="switch mt-3">
            <label class="d-flex justify-content-between">
              <label>Set as Main Procedure</label>
              <input
                type="checkbox"
                formControlName="isMainProcedure"
                (click)="isMainProcedure($event)" />
              <span class="lever"></span>
            </label>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitMilestone()"
          [disabled]="isSubmitEnabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="isSubmitEnabled"></span>
          <!-- <i class="icofont-plus" *ngIf="!isSubmitEnabled"></i>  -->
          {{ milestoneForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-milestone-topic-delete
  [deleteData]="dataToBeDelete"
  (deletedData)="deleteMilestone($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>
