<div class="d-flex justify-content-between align-items-center mb-2" *ngIf="report?.tableTitle">
  <h5>{{ report?.tableTitle }}</h5>
  <div *ngIf="exportTable">
    <button
      type="button"
      class="btn btn-sm btn-light border mr-0"
      (click)="sendEmail()"
      [class.disabled]="!report?.data?.length">
      <i class="fa-fa fa-envelope fas"></i> Send Email
    </button>
    <button
      type="button"
      class="btn btn-sm btn-light border mr-0"
      (click)="download()"
      [class.disabled]="!report?.data?.length || isLoading.download">
      <span
        *ngIf="isLoading.download"
        class="spinner-grow spinner-grow-sm"
        role="status"
        aria-hidden="true"></span>
      <i class="fas fa-download" *ngIf="!isLoading.download"></i> Download
    </button>
  </div>
</div>

<!--  Table Template -->
<div id="claimsReportPDF">
  <div
    class="d-flex justify-content-between align-items-center mb-2"
    *ngIf="report?.date && (report.date.startDate || report.date.endDate)">
    <small
      >{{ report.date.startDate ? report.date.startDate + ' - ' : ''
      }}{{ report.date.endDate ? report.date.endDate : '' }}</small
    >
  </div>
  <div
    #uiElement
    class="scrollbar scrollbar-primary thin overflow-auto"
    (scroll)="onScroll($event)">
    <div [style.maxHeight]="tableHeight">
      <table
        id="datatable"
        class="datatable mb-0 position-relative"
        bordered="true"
        hover="true"
        mdbTable>
        <thead class="position-sticky top-0" style="z-index: 1">
          <ng-container *ngTemplateOutlet="additionalHeader"></ng-container>
          <tr [class]="!isCollapsed ? 'position-sticky green lighten-5' : 'position-sticky'">
            <th
              *ngFor="let header of report?.tableHeaders | orderBy : orderBy; let i = index"
              [class.text-right]="header.type && header.type === 'amount'"
              [style.width]="
                ((header.type === 'checkbox' || header.label === '') && '20px') ||
                header?.headerWidth
              "
              [style.text-align]="header.headerAlign">
              <ng-template [ngIf]="header.type === 'checkbox'" [ngIfElse]="displayHeader">
                <div class="table-checkbox">
                  <mdb-checkbox
                    [disabled]="isCheckboxDisabled"
                    [(ngModel)]="isMasterCheckboxSelected"
                    (change)="onAllChecked($event.checked)"></mdb-checkbox>
                </div>
              </ng-template>
              <ng-template #displayHeader>
                {{ header.hideHeader ? '' : header.label }}
              </ng-template>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of report?.data">
            <tr (click)="onRowDataClicked(item)">
              <td
                *ngFor="let header of report?.tableHeaders; let i = index"
                [style.background-color]="
                  item.isSelected ? (item.status ? '#f4fbf5' : '#fff1ee') : '#ffffff'
                "
                [style.border-bottom]="
                  (item.hasHraHsaError && item.isSelected && header.value === 'hraHsaAmount') ||
                  (item.hasPatientResponsibilityError &&
                    item.isSelected &&
                    header.value === 'patientPayAmount')
                    ? '1px solid #ff000066'
                    : null
                "
                [class.text-right]="
                  header.value !== 'status' &&
                  ((item[header.value] && item[header.value].toString().includes('$')) ||
                    !item[header.value] ||
                    item[header.value] === ' ')
                ">
                <ng-template
                  [ngIf]="header.type === 'checkbox' || header.type === 'collapsible'"
                  [ngIfElse]="displayLabel">
                  <div *ngIf="header.type === 'checkbox'" class="table-checkbox">
                    <mdb-checkbox
                      [disabled]="isCheckboxDisabled"
                      [(ngModel)]="item.isSelected"
                      (change)="onChecked(item, $event.checked)"></mdb-checkbox>
                  </div>
                  <div *ngIf="header.type === 'collapsible'">
                    <mdb-icon
                      style="color: #5a9ec5"
                      fas
                      icon="chevron-down"
                      *ngIf="!item.isCollapsed"
                      (click)="onCollapseClicked(item, true)"></mdb-icon>
                    <mdb-icon
                      style="color: #5a9ec5"
                      fas
                      icon="chevron-up"
                      *ngIf="item.isCollapsed"
                      (click)="onCollapseClicked(item, false)"></mdb-icon>
                  </div>
                </ng-template>
                <ng-template #displayLabel>
                  <ng-template [ngIf]="isEditable" [ngIfElse]="nonEditable">
                    <div
                      *ngIf="
                        !isEditData ||
                        selectedHeaderValue !== header.value ||
                        selectedRowId !== item.id
                      "
                      [style.width]="header.value === 'status' && '20px'"
                      (click)="item.isSelected && editData(item, header.value)">
                      <span
                        *ngIf="
                          header.value !== 'status' &&
                          (item.isSelected || !nonPrePopulatedFields.includes(header.value))
                        ">
                        {{
                          item[header.value] && item[header.value] !== ' '
                            ? item[header.value]
                            : isEditable
                            ? '$0.00'
                            : '-'
                        }}</span
                      >
                      <div *ngIf="header.value === 'status' && item.isSelected" class="text-center">
                        <i
                          *ngIf="item.status"
                          class="icofont-check-circled text-primary"
                          style="font-size: 20px"></i>
                        <i
                          [mdbTooltip]="invalidStatusReason"
                          *ngIf="!item.status"
                          class="icofont-warning text-danger"
                          style="font-size: 20px"></i>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        isEditData &&
                        selectedHeaderValue === header.value &&
                        selectedRowId === item.id
                      ">
                      <input
                        type="number"
                        style="width: 80px; height: 30px; text-align: right; display: inline"
                        id="editableInput"
                        class="form-control"
                        [(ngModel)]="dataInput"
                        [readonly]="isLoading.saving"
                        (blur)="updateValue(item, header.value); closeEdit()"
                        (keyup.enter)="$event.target.blur()"
                        mdbInput />
                      <!-- [ngClass]="{'is-invalid': submitted && phoneForm.get().invalid}" -->
                      <!-- (keyup.enter)="updateValue(item, header.value); closeEdit();" -->
                      <div class="d-flex align-items-center">
                        <span
                          *ngIf="isLoading.saving"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"></span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #nonEditable>
                    <div
                      [class.text-right]="header.type && header.type === 'amount'"
                      *ngIf="!nameVariables.includes(header.value); else personnel">
                      {{ formattedValue(item, header) }}
                    </div>
                    <ng-template #personnel>
                      {{ item[header.value] | titlecase }}
                    </ng-template>
                    <ng-template></ng-template>
                  </ng-template>
                </ng-template>
              </td>
            </tr>
            <ng-container
              *ngIf="item.isCollapsed"
              [ngTemplateOutlet]="collapsibleContainer"
              [ngTemplateOutletContext]="{data: item.collapsibleMenu}"></ng-container>
          </ng-container>
          <!-- Commented: May be used for the future... -->
          <!-- <tr *ngFor="let value of report.data">
            <td
              *ngFor="let header of report.tableHeaders; let i = index"
              [class.text-right]="
                (value[header.value] && value[header.value].toString().includes('$')) ||
                !value[header.value] ||
                value[header.value] === ' '
              ">
              {{ value[header.value] && value[header.value] !== ' ' ? value[header.value] : '-' }}
            </td>
          </tr> -->
          <tr *ngIf="showNoDataMessage()">
            <td [attr.colspan]="report.tableHeaders.length">{{ noDataMessage.value }}</td>
          </tr>
          <tr *ngIf="lazyLoading">
            <ng-container *ngTemplateOutlet="lazyLoadingLoader"></ng-container>
          </tr>
        </tbody>
        <tfoot>
          <ng-container *ngTemplateOutlet="total"></ng-container>
        </tfoot>
      </table>
    </div>
  </div>
</div>
<!--  Table Template -->

<!--SEND MESSAGE MODAL-->
<app-communication-message
  *ngIf="showMessageModal"
  [showModal]="showMessageModal"
  [idPDF]="['claimsReportPDF']"
  [moduleOptions]="moduleOptions"
  (closeModal)="closeMessageModal()"></app-communication-message>
<!--SEND MESSAGE MODAL-->
