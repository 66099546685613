import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccessType } from '../../../../../../utils';

@Component({
  selector: 'app-task-signature',
  templateUrl: './task-signature.component.html',
  styleUrls: ['./task-signature.component.scss']
})
export class TaskSignatureComponent implements OnInit {
  taskToBeDelete: any;
  dependentTask: any;
  todoId: any;
  @Input() taskSignature: any;
  @Input() optionId: number;
  @Output() editTask = new EventEmitter<any>();
  @Output() btns = new EventEmitter<any>();
  @Output() deletedTask = new EventEmitter<any>();
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;
  @Input() set taskSignatureId(taskSignatureId) {
    if (taskSignatureId) {
      this.todoId = taskSignatureId
      this.getTaskSignature(taskSignatureId);
    }
  }
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.todoId) this.getTaskSignature(this.todoId)
    }
  }

  constructor(
    private _utilityAccess: AccessType,
    private _toastrService: ToastrService,
  ) { }

  ngOnInit() { }

  getTaskSignature(id) {
    this.taskSignature = {};
    if (this.dependentTask && this.dependentTask.taskSignatures) {
      this.dependentTask.taskSignatures.filter(x => {
        if (x.id == id) {
          x.form = 'signature';
          x.field = 'signature'
          x.optionId = this.optionId;
          this.taskSignature = x
        }
      })
    }
  }

  editTaskHandler(signature) {
    this.editTask.emit(signature);
  }

  deleteTaskHandler(signature) {
    if (this._utilityAccess.searchAccess('PM', 'isEditable')) {
      signature['source'] = 'Pathway'
      signature['type'] = 'Signature'
      signature['displayName'] = signature.name
      this.taskToBeDelete = signature
    }
    else this._toastrService.warning("Access Denied", "Warning")
  }

  cancelDelete(e) {
    this.taskToBeDelete = e
  }

  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }
}
