<div class="pr-3">
  <div class="h-auto position-sticky header">
    <div class="d-flex justify-content-between h-auto">
      <div>
        <h3 class="mb-0">
          {{ milestone.name }}
          <div
            class="chip chip-sm mb-0 ml-1"
            *ngIf="
              milestone.triggerDays &&
              milestone.triggerPeriod &&
              milestone.triggerCondition &&
              milestone.triggerMilestoneName
            ">
            <mdb-icon far icon="calendar-check"></mdb-icon> {{ milestone.triggerDays }}
            {{ milestone.triggerPeriod }} {{ milestone.triggerCondition }}
            <span class="font-weight-bold">{{ milestone.triggerMilestoneName }}</span>
          </div>
        </h3>

        <p>{{ milestone.description }}</p>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <h6>Topic</h6>
      <a (click)="createTopic()" class="skin-link"><i class="icofont-plus"></i> Add Topic</a>
    </div>
  </div>

  <div class="p-4 text-center grey lighten-4 border-light" *ngIf="!loading && topics.length == 0">
    <i class="icofont-tasks icofont-4x text-black-50"></i>
    <p class="text-muted">Start adding topics to your milestone</p>
  </div>
  <app-loader [loader]="loading"></app-loader>
  <!--Accordion wrapper-->
  <mdb-accordion
    class="accordion-block"
    [multiple]="false"
    aria-multiselectable="true"
    *ngIf="!loading && topics.length > 0">
    <mdb-accordion-item
      *ngFor="let topic of topics | orderBy : 'sequence'; let i = index"
      [collapsed]="activeTopicId == topic.id ? false : true"
      (click)="passDataToTask(topic.id)">
      <span class="text-dark action-item">
        <i class="icofont-ui-edit icofont-sm mr-2" (click)="editTopic(topic)"></i>
        <i class="icofont-ui-delete icofont-sm mr-2" (click)="topicToDelete(topic)"></i>
      </span>
      <mdb-accordion-item-head>
        {{ topic.topic }}
      </mdb-accordion-item-head>

      <mdb-accordion-item-body>
        <app-topic-task
          *ngIf="activeTopicId == topic.id"
          [topic]="topic"
          [dependent_tasks]="dependentTasks"
          (updateTopic)="updateTopicHandler($event)"
          (deleteDependent)="deleteDependent($event)"
          (toBeDeleteTask)="deleteTask($event)"></app-topic-task>
      </mdb-accordion-item-body>
    </mdb-accordion-item>
  </mdb-accordion>
  <!--/.Accordion wrapper-->
</div>
<div
  mdbModal
  #topicModal="mdbModal"
  class="modal fade top"
  id="topicModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100 mb-0">{{ topicForm.value.id ? 'Edit' : 'Add' }} Topic</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal('topicModal', 'topicForm')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="topicForm">
          <div class="form-group mt-0">
            <label for="TopicName">Topic Name *</label>
            <input
              type="text"
              id="TopicName"
              class="form-control"
              mdbInput
              formControlName="topic"
              [ngClass]="{'is-invalid': submitted && topicForm.get('topic').invalid}" />
          </div>
          <div class="form-group">
            <label for="patientTopic-name">Patient facing Topic *</label>
            <input
              type="text"
              id="patientTopicName"
              class="form-control"
              mdbInput
              formControlName="patientTopic"
              [ngClass]="{'is-invalid': submitted && topicForm.get('patientTopic').invalid}" />
          </div>
          <!-- <label for="patientTopic-name">Trigger for escalation</label>
          <div class="form-row">
            <div class="col">
              <div class="form-group m-0">
                <input type="text" id="escalationDays" class="form-control" mdbInput formControlName="escalationDays">
              </div>
            </div>
            <div class="col">
              <mdb-select [options]="triggerPeriodOptions" formControlName="triggerPeriod"></mdb-select>
            </div>
          </div> -->
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeModal('topicModal', 'topicForm')">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitTopic()"
          [disabled]="isSubmitEnabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="isSubmitEnabled"></span>
          <!-- <i class="icofont-plus" *ngIf="!isSubmitEnabled"></i> -->
          {{ topicForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-milestone-topic-delete
  [deleteData]="dataToBeDelete"
  (deletedData)="deleteTopic($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>
