import { environment } from "src/environments/environment";

export const APICONSTANTS = {
  LOGIN: '/login',
  PATHWAY: '/pathway',
  CLAIMS: '/claims',
  MILESTONE: '/milestones',
  TASK: '/tasks',
  TASKTODO: '/tasktodos',
  USER: '/users',
  PLATFORM_OWNERS: '/platform-owners',
  COMPANY: '/company',
  ROLE: '/roles',
  ROLE_V2: '/v2/roles',
  PURCHASER: '/purchasers',
  FUNDING_SOURCE: '/funding-sources',
  EMPLOYER_GROUP: '/employer-groups',
  VALUE_BASED_PROGRAM: '/value-based-programs',
  VERIFYCODE: '/verify-code',
  SETPASSWORD: '/set-password',
  QUESTION_TYPE: '/question-types',
  MESSAGE: '/messages',
  PERMISSION: '/permissions',
  MEMBER: '/members',
  REQUEST_EOC: '/members-request-eoc',
  MEMBER_PLAN: '/member-plans',
  EPISODE: '/episodes',
  EPISODE_OF_CARE: '/episode-of-cares',
  FORGOTPASSWORD: '/forgot-password',
  RESETPASSWORD: '/reset-password',
  CHANGEPASSWORD: '/change-password',
  QUESTION: '/question',
  DOCUMENT: '/documents',
  DASHBOARD: '/dashboard',
  ASSIGN_DOCUMENT: '/assign-document',
  NOTES: '/notes',
  SEND_INVITATION: '/re-invite',
  PROVIDER: '/providers',
  BUNDLECOST: '/bundle-costs',
  PROVIDERS_BY_BUNDLE_COMPONENT: '/facility-providers-by-bundle-component',
  SERVICE_BUNDLE: '/bundles',
  COMPONENT: '/components',
  COMPONENT_COST_PROVIDER: '/component-cost-providers',
  LOOKUP: '/lookups',
  BENEFIT_STATUS_CODE: '/benefit-status-codes',
  MEMBER_RELATIONSHIP: '/member-relationships',
  EXPENSE_ALLOCATION: '/expense-allocations',
  FUNDING_REQUEST: '/funding-requests',
  BENEFIT_SETTING: '/benefit-settings',
  PATIENTS: '/patients',
  DASHBOARD_EPISODES: '/dashboard-episodes',
  ACTIVITY_LOG: '/log-user-activities',
  FUNDING_REQUREST: '/funding-request',
  PAYMENT: '/payments',
  PAYEE: '/payee',
  CHECK_AND_CREATE_PAYEE: '/check-existing',
  JOURNAL: '/v1/journals',
  JOURNAL_V2: '/v2/journals',
  MENU: '/menus',
  NETWORK: '/networks',
  MARKET: '/markets',
  CLIENT: '/clients',
  BANK: '/banks',
  ACCOUNT: '/accounts',
  CLAIMS_REPORT: '/claims/reports',
  PLAN: '/plans',
  REPORT: '/reports',
  REPORT_V2: '/reports/V2',
  REPORT_CONFIGURATION: '/report-configurations',
  MESSAGES: '/messages',
  MESSAGE_TEMPLATES: '/message-templates',
  COMMUNICATION_SECURITY: '/security',
  COMMUNICATION_LOOKUPS: '/communication-lookups',
  COMETCHAT: `https:${environment.cometChat_appId}.api-${environment.cometChat_region}.cometchat.io`,
  BUNDLE_COST_SERVICE: '/bundle-costs',
  EOC: '/episode-of-care',
  REPORT_TYPES: '/report-types',
  FACILITIES: '/facilities',
  VERIFY_OTP: '/verify-otp',
  MFA_PREFERENCE: '/mfa-preference',
  AUTH: '/auth',
  RESEND_OTP: '/resend-otp',
  CLAIM_RECON_SUMMARY: '/reports/claim-reconciliation-summary',
  CLAIM_RECON_BY_PROVIDER_AND_EPISODE: '/reports/claim-reconciliation-by-provider-episode',
  CLAIM_RAW_DATA: '/reports/claim-reconciliation-raw',
  CATEGORIES: '/categories',
  SUB_CATEGORIES: '/sub-categories',
  PROVIDER_REFERENCE: '/provider-references',
  QUESTION_CCONFIGURATION: '/question-configurations',
  PAYEE_INFO: '/payee-info',
  CLAIMS_PAYEE_REFERENCE: '/claims-payee-reference',
  QUESTIONS: '/questions',
  QUESTIONNAIRES: '/questionnaires',
  EPISODE_MILESTONES: '/episode-milestones',
  REPORT_FILTER: '/report-filter',
  SERVICE_CATEGORY_GROUP_CONFIGURATIONS: '/bundle-component-group-configurations',
}

export const COMETCHAT_USER = `${APICONSTANTS.COMETCHAT}/v3.0/users`
