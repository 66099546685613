<section class="container-fluid">
  <div class="row">
    <div class="col-11 mx-auto">
      <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
        <button
          type="button"
          class="btn btn-light z-depth-0 pl-3 pr-3"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="openFilterModal()"
          mdbWavesEffect>
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-toggle="modal"
          (click)="createJournal()"
          data-target="#basicExample"
          mdbWavesEffect>
          <i class="icofont-plus"></i> Add Journal
        </button>
      </div>

      <div class="keywords position-sticky" *ngIf="filterList && filterList.length > 0">
        <div class="keywords d-flex mt-2 flex-wrap align-items-center">
          <small class="mb-2 mr-2">Filters: </small>
          <span class="d-block mb-2" *ngFor="let filter of filterList">
            <small class="d-block text-muted ml-1"></small>
            <div class="chip blue lighten-5 mb-0">
              {{ filter.field }}: <span class="font-weight-500 text-dark">{{ filter.label }}</span>
              <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
            </div>
          </span>
          <button
            type="button"
            class="btn btn-link waves-light m-0 p-0"
            mdbWavesEffect
            (click)="ResetFilter()">
            <span class="text-info">Reset Filter</span>
          </button>
        </div>
      </div>

      <div class="table-wrapper">
        <div class="container-fluid">
          <div class="row overflow-auto scrollbar scrollbar-primary thin"
          [style.height]="journals && journals?.length ? '750px' : ''"
          (scroll)="onTableScroll($event)"
          #scrollableTable
          >
            <table
              mdbTable
              stickyHeader="true"
              small="true"
              hover="true"
              bordered="true"
              class="table-journal">
              <thead id="scrollToTop">
                <tr>
                  <th style="top: 0" scope="col" width="80">Entry Number</th>
                  <th style="top: 0" scope="col">Date</th>
                  <th style="top: 0" scope="col">Account</th>
                  <th style="top: 0" width="200" scope="col" class="text-right">Debit</th>
                  <th style="top: 0" width="200" scope="col" class="text-right">Credit</th>
                </tr>
              </thead>
              <div
                class="loader-wrapper"
                *ngIf="isLoading"
                [style.top]="(journal | json) === '{}' ? 'auto' : 0">
                <div class="loader">
                  <app-loader [loader]="isLoading"></app-loader>
                </div>
                <div class="backdrop"></div>
              </div>
              <tbody *ngFor="let journal of journal | keyvalue : valueAscOrder">
                <tr *ngFor="let entry of journal.value; let i = index">
                  <td>{{ i === 0 ? entry.entryNumber : '' }}</td>
                  <td>{{ i === 0 ? (entry.date | date : 'MM/dd/yyyy') : '' }}</td>
                  <td>
                    {{ entry.account.name }}
                    {{
                      entry?.journalXref?.referenceName
                        ? ' - ' + (entry.journalXref.referenceName | titlecase)
                        : ''
                    }}
                    - {{ entry.episode?.userName | titlecase }}
                  </td>
                  <td class="text-right">
                    {{ (entry.debit && entry.debit === '0.00' ? '0' : entry.debit) | currency }}
                  </td>
                  <td class="text-right">
                    {{ (entry.credit && entry.credit === '0.00' ? '0' : entry.credit) | currency }}
                  </td>
                </tr>
                <tr *ngIf="journal.value[0]?.narration || journal.value[0]?.episode">
                  <td></td>
                  <td></td>
                  <td>
                    <!-- {{ journal.value[0].episode.name | titlecase }}
                    {{ ' - ' + journal.value[0].episode?.userName | titlecase }} -->
                    <em>{{
                      journal.value[0]?.narration ? '(' + journal.value[0].narration + ')' : ''
                    }}</em>
                  </td>
                  <td class="text-right"></td>
                  <td class="text-right"></td>
                </tr>
              </tbody>
            </table>
            <div class="text-center w-100">
              <app-loader [loader]="isInfiniteScrolling"></app-loader>
            </div>
          </div>
          <div>
      </div>
        </div>
        <div style="background-color: #d3d3d3; text-align: right; width: 100%;"
            *ngIf="(journal | json) != '{}'">
            <table
              small="true"
              hover="true"
              bordered="true"
              class="table"
            >
              <thead >
                  <tr>
                    <th class="text-center" colspan="3">Total</th>
                    <th width="200" scope="col" class="text-center">{{ totalDebitAmount | currency }}</th>
                    <th width="200" scope="col" class="text-right">{{ totalCreditAmount | currency }}</th>
                  </tr>
                </thead>
            </table>
        </div>
      </div>

      <div class="text-center p-4 border mt-3" *ngIf="!isLoading && (journal | json) === '{}'">
        <i class="icofont-book-alt icofont-4x text-black-50"></i>
        <p *ngIf="!filterForm.value.filterEnabled">Start adding Journals.</p>
        <p *ngIf="filterForm.value.filterEnabled">No results found.</p>
      </div>
      <div
        appScrollToTop
        class="fixed-action-btn donotprint"
        style="bottom: 145px; right: 10px; display: none">
        <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
          <i class="fas fa-angle-up text-black-50"></i>
        </a>
      </div>
    </div>
  </div>
</section>

<!-- Add/Edit Journal Modal  -->
<div
  mdbModal
  #journalModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content" style="width: 1000px">
      <div class="modal-header">
        <h5 class="modal-title w-100">Add Journal - {{ totalJournalLength + 1 }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeJournalModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="journalForm">
          <div class="form-row">
            <div class="col-2">
              <div class="form-group">
                <label for="date">Date *</label>
                <input
                  type="text"
                  id="date"
                  placeholder="MM/DD/YYYY"
                  [ngClass]="{'error-select': submitted && journalForm.get('date').invalid}"
                  name="date"
                  formControlName="date"
                  class="form-control"
                  [textMask]="{mask: dateMask}" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="claimId">Claim ID</label>
                <input
                  type="text"
                  [ngModel]="claimIdSearchText | async"
                  id="claimId"
                  (ngModelChange)="claimIdSearchText.next($event)"
                  [ngModelOptions]="{standalone: true}"
                  class="completer-input form-control mdb-autocomplete mb-0"
                  autocomplete="off"
                  [mdbAutoCompleter]="auto1"
                  placeholder="Type to select claim ID" />
                <mdb-auto-completer
                  #auto1="mdbAutoCompleter"
                  textNoResults="No result found"
                  [displayValue]="displayClaimId"
                  [optionHeight]="60"
                  (selected)="onClaimSelected($event)">
                  <mdb-option *ngFor="let option of claimIdResults | async" [value]="option">
                    <div class="d-flex flex-column">
                      <strong>{{ option.patientAccountNumber }}</strong>
                      <small>( {{ option.type }} )</small>
                    </div>
                  </mdb-option>
                </mdb-auto-completer>
              </div>
            </div>
            <div class="col">
              <div class="form-group position-relative">
                <label>Choose Patient *</label>
                <input
                  type="text"
                  [ngModel]="userSearchText | async"
                  (ngModelChange)="userSearchText.next($event)"
                  [ngModelOptions]="{standalone: true}"
                  class="completer-input form-control mdb-autocomplete mb-0"
                  id="autocompleteJournalUser"
                  [mdbAutoCompleter]="auto"
                  autocomplete="off"
                  placeholder="Type to select patient"
                  [ngClass]="{'is-invalid': submitted && journalForm.get('patientId').invalid}" />
                <mdb-auto-completer
                  #auto="mdbAutoCompleter"
                  textNoResults="No patient found"
                  [displayValue]="onDisplayValue"
                  (selected)="onPatientSelect($event)">
                  <mdb-option *ngFor="let option of userResults | async" [value]="option">
                    <div class="d-flex flex-column">
                      <strong>{{ option.firstName }} {{ option.lastName }}</strong>
                      <small>{{ option.purchaserName ? option.purchaserName : '' }}</small>
                    </div>
                  </mdb-option>
                </mdb-auto-completer>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="episode"
                  >Choose Episode *
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="isGetEpisode"></span
                ></label>
                <mdb-select
                  [options]="episodes | sort : 'label'"
                  id="episodeId"
                  name="episodeId"
                  (selected)="episodeSelect($event)"
                  formControlName="episodeId"
                  [disabled]="!episodes || episodes.length === 0"
                  [ngClass]="{'error-select': submitted && journalForm.get('episodeId').invalid}">
                </mdb-select>
              </div>
            </div>
          </div>
          <div class="table-wrapper">
            <table mdbTable bordered="true">
              <thead>
                <tr>
                  <th>Account</th>
                  <th width="15%">Debit</th>
                  <th width="15%">Credit</th>
                  <th>Associate</th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let entry of journalEntry; let i = index">
                  <td>{{ entry.account }}</td>
                  <td class="text-right">{{ entry.debit | currency }}</td>
                  <td class="text-right">{{ entry.credit | currency }}</td>
                  <td>{{ entry.referenceName | titlecase }}</td>
                  <td>
                    <button class="btn btn-link mt-0 mb-0 p-0 z-depth-0" (click)="editEntry(i)">
                      <i class="icofont-edit-alt"></i>
                    </button>
                    <button class="btn btn-link mt-0 mb-0 p-0 z-depth-0" (click)="removeEntry(i)">
                      <i class="icofont-ui-delete"></i>
                    </button>
                  </td>
                </tr>
                <tr [formGroup]="accountForm">
                  <td>
                    <div class="form-group">
                      <mdb-select
                        [options]="accountList | sort : 'label'"
                        name="account"
                        [ngClass]="{
                          'error-select': isSubmitAccount && accountForm.get('account').invalid
                        }"
                        (selected)="accountSelect($event)"
                        id="account"
                        [disabled]="
                          !episodes ||
                          episodes.length === 0 ||
                          !associateEpisodes ||
                          associateEpisodes.length === 0
                        "
                        formControlName="account">
                      </mdb-select>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      mdbInput
                      id="debit"
                      name="debit"
                      [readonly]="accountForm.value.credit"
                      class="form-control"
                      [ngClass]="{
                        'error-select': isSubmitAccount && accountForm.get('debit').invalid
                      }"
                      (input)="debitChange()"
                      (change)="amtChange($event, 'debit')"
                      formControlName="debit" />
                  </td>

                  <td>
                    <input
                      type="number"
                      mdbInput
                      id="credit"
                      name="credit"
                      [readonly]="accountForm.value.debit"
                      class="form-control"
                      [ngClass]="{
                        'error-select': isSubmitAccount && accountForm.get('credit').invalid
                      }"
                      (input)="creditChange()"
                      (change)="amtChange($event, 'credit')"
                      formControlName="credit" />
                  </td>
                  <td>
                    <mdb-select
                      [options]="associateList | sort : 'label'"
                      formControlName="associateId"
                      [disabled]="
                        !this.accountForm.value.accountName ||
                        !associateList ||
                        associateList.length === 0
                      "
                      name="associateId"
                      [ngClass]="{
                        'error-select':
                          (isSubmitAccount || (submitted && this.accountForm.value.isEdit)) &&
                          accountForm.get('associateId').invalid
                      }">
                    </mdb-select>
                  </td>
                  <td>
                    <button
                      class="btn btn-link mt-2 mb-0 p-0 z-depth-0"
                      (click)="removeEntry(null, 'form')">
                      <i class="icofont-ui-delete"></i>
                    </button>
                  </td>
                  <!-- </form> -->
                </tr>
              </tbody>
              <tfoot style="background-color: #d3d3d3; text-align: right">
                <tr>
                  <td><strong>Total</strong></td>
                  <td class="text-right">
                    <strong>{{ totalDebitEntryAmount | currency }}</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{ totalCreditEntryAmount | currency }}</strong>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="form-group">
            <a (click)="addRow()"><i class="icofont-plus"></i> Add Row</a>
          </div>
          <div class="form-group">
            <label for="Narration">Narration</label>
            <textarea
              type="text"
              id="narration"
              name="narration"
              formControlName="narration"
              class="md-textarea form-control"
              mdbInput></textarea>
          </div>
          <div
            *ngIf="(!journalEntry || journalEntry.length === 0) && submitted"
            class="text-danger">
            *Add journal entry
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeJournalModal()">
          Cancel
        </button>
        <div>
          <button
            class="btn btn-secondary waves-light m-0"
            (click)="saveJournal('close')"
            [disabled]="isSubmitEnabled || submitForAddNew"
            mdbWavesEffect>
            <span
              *ngIf="isSubmitEnabled"
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"></span>
            Save and Close
          </button>
          <button
            class="btn btn-secondary waves-light ml-2"
            (click)="saveJournal('addNew')"
            [disabled]="isSubmitEnabled || submitForAddNew"
            mdbWavesEffect>
            <span
              *ngIf="submitForAddNew"
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"></span>
            Save and New
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  mdbModal
  #filterModal="mdbModal"
  class="modal fade top"
  id="filterModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter Journal</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFilterModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="filterForm">
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label>Choose Episode </label>
                <mdb-select
                  [options]="episodeDropdownList | sort : 'label'"
                  placeholder="Select episode "
                  [multiple]="true"
                  formControlName="episodeId"
                  [enableSelectAll]="false"
                  [highlightFirst]="false">
                </mdb-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="action">Choose account</label>
                <mdb-select
                  [options]="accountList | sort : 'label'"
                  id="action"
                  placeholder="Select account"
                  [enableSelectAll]="false"
                  formControlName="accountId"
                  [multiple]="true"
                  [highlightFirst]="false">
                </mdb-select>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <div class="form-group position-relative">
                <label for="filterClaimId">Claim ID</label>
                <input
                  type="text"
                  [ngModel]="claimIdSearchText | async"
                  id="filterClaimId"
                  (ngModelChange)="claimIdSearchText.next($event)"
                  [ngModelOptions]="{standalone: true}"
                  class="completer-input form-control mdb-autocomplete mb-0"
                  autocomplete="off"
                  [mdbAutoCompleter]="auto2"
                  placeholder="Type to select claim ID" />
                <mdb-auto-completer
                  #auto2="mdbAutoCompleter"
                  textNoResults="No result found"
                  [displayValue]="displayClaimId"
                  [optionHeight]="60"
                  (selected)="claimSelectedForFilter($event)">
                  <mdb-option *ngFor="let option of claimIdResults | async" [value]="option">
                    <div class="d-flex flex-column">
                      <strong>{{ option.patientAccountNumber }}</strong>
                      <small>( {{ option.type }} )</small>
                    </div>
                  </mdb-option>
                </mdb-auto-completer>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="fromDate">From Date</label>
                <input
                  type="text"
                  id="fromDate"
                  placeholder="MM/DD/YYYY"
                  [textMask]="{mask: dateMask}"
                  class="form-control"
                  formControlName="fromDate"
                  [ngClass]="{
                    'error-select': filterForm.value.fromDate && filterForm.get('fromDate').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="toDate">To Date</label>
                <input
                  type="text"
                  id="toDate"
                  placeholder="MM/DD/YYYY"
                  [textMask]="{mask: dateMask}"
                  class="form-control"
                  formControlName="toDate"
                  [ngClass]="{
                    'error-select': filterForm.value.toDate && filterForm.get('toDate').invalid
                  }" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeFilterModal()">
          Cancel
        </button>
        <button class="btn btn-secondary waves-light m-0" mdbWavesEffect (click)="submitFilter()">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>
