import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private http: HttpClientService) { }

  getProviders(query) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.PROVIDER}`, query)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getBundleProviders(id) {
    return this.http.get(`${APICONSTANTS.PROVIDER}/id`).pipe(
      map((res: any) => {
        return res.data
      })
    )
  }

  saveProvider(provider) {
    return this.http.post(`${APICONSTANTS.PROVIDER}`, provider).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  updateProvider(provider) {
    return this.http.put(`${APICONSTANTS.PROVIDER}`, provider).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  searchProviderLocation(search_text) {
    return this.http.get(`${APICONSTANTS.PROVIDER}/location/search/${search_text}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  searchProviderName(search_text) {
    return this.http.get(`${APICONSTANTS.PROVIDER}/individual/search/${search_text}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  SearchAllProvider(search_text) {
    return this.http.get(`${APICONSTANTS.PROVIDER}/search/${search_text}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  searchVitafyProvider(queryParam?) {
    return this.http.get(`${APICONSTANTS.PROVIDER}`, queryParam).pipe(
      map((res: any) => {
        return res.data;
      })
    );

  }

  getAssociatedFacility(providerId, body) {
    return this.http.post(`${APICONSTANTS.PROVIDER}/${providerId}/facilities`, body).pipe(
      map((res: any) => {
        return res.data;
      })
    );

  }

  getAssociatedPhysician(providerId, body) {
    return this.http.post(`${APICONSTANTS.PROVIDER}/${providerId}/physicians`, body).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getProvidersByIds(ids) {
    return this.http.get(`${APICONSTANTS.PROVIDER}/${ids}`).pipe(
      map((res: any) => {
        return res.data
      })
    )
  }

  getEpisodePointOfContact(providerId, networkCode) {
    return this.http.get(`${APICONSTANTS.PROVIDER}/${providerId}/details?network=${networkCode}`).pipe(
      map((res: any) => {
        return res.data
      })
    )
  }

  searchSpecialityByName(searchText) {
    return this.http.get(`${APICONSTANTS.PROVIDER}/taxonomycodes/${searchText}`).pipe(
      map((res: any) => {
        return res.data
      })
    )
  }

  getProvidersByUserId(userId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.USER}/${userId}/providers`)
        .toPromise()
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getAllNetworks() {
    return this.http.get(`/network`).pipe(
      map((res: any) => {
        return res.data
      })
    )
  }
}
