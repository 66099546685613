<!-- SideNav slide-out button -->
<!-- <a (click)="sidenav.toggle()" mdbBtn color="primary" class="p-3 button-collapse">
  <mdb-icon fas icon="bars"></mdb-icon>
</a> -->
<!--/. SideNav slide-out button -->

<!-- Sidebar navigation -->
<mdb-side-nav #sidenav class="sn-bg-1 fixed" [fixed]="true">

  <mdb-navbar-brand>
    <!-- Logo -->
    <li>
      <div class="logo-wrapper waves-light">
        <a [routerLink]="['/dashboard']"><img src="https://mdbootstrap.com/img/logo/mdb-transparent.png"
            class="img-fluid flex-center"></a>
      </div>
    </li>
    <!--/. Logo -->
  </mdb-navbar-brand>

  <links>

    <!-- Side navigation links -->
    <li>
      <ul class="collapsible collapsible-accordion">

        <mdb-accordion [multiple]="true" aria-multiselectable="false">
          <mdb-accordion-item class="no-collase">
            <mdb-accordion-item-head mdbWavesEffect>
              <a [routerLink]="['/companies']">
                <mdb-icon far icon="hand-pointer"></mdb-icon> COMPANIES
              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item class="no-collase">
            <mdb-accordion-item-head mdbWavesEffect>
              <a [routerLink]="['/users']">
                <mdb-icon far icon="hand-pointer"></mdb-icon> USERS
              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item class="no-collase">
            <mdb-accordion-item-head mdbWavesEffect>
              <a [routerLink]="['/pathway']">
                <mdb-icon far icon="hand-pointer"></mdb-icon> PATHWAY
              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item class="no-collase">
            <mdb-accordion-item-head mdbWavesEffect>
              <a [routerLink]="['/episodes']">
                <mdb-icon far icon="hand-pointer"></mdb-icon> EPISODES

              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
          <!-- Simple link -->
          <mdb-accordion-item class="no-collase">
            <mdb-accordion-item-head mdbWavesEffect>
              <a [routerLink]="['/documents']">
                <mdb-icon far icon="gem"></mdb-icon> DOCUMENTS
              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
        </mdb-accordion>
      </ul>
    </li>
    <!--/. Side navigation links -->
    <div>Current Count: {{ count$ | async }}</div>
  </links>
  <div class="sidenav-bg mask-strong"></div>
</mdb-side-nav>
<!--/. Sidebar navigation -->