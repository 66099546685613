<!-- Expandable Chart Begins -->
<div
  [attr.id]="expand.triggerClaimsTimesCompany ? 'ccd-trigger-claim-cycle-time' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY) &&
    expand.triggerClaimsTimesCompany
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="triggerClaimCycleTimeDetailSelectedYearForm">
            <div class="d-flex justify-content-between align-items-center">
              <label class="m-0">Period</label>
              <mdb-select
                class="ml-3"
                placeholder="Year"
                [options]="yearOptions.triggerClaimsCycleTimesByCompany | sort : 'label'"
                id="year-select"
                [visibleOptions]="3"
                formControlName="triggerClaimCycleTimesByCompanySelectedYear"
                (selected)="
                  yearSelected($event, REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY)
                ">
              </mdb-select>
            </div>
          </form>
          <span
            class="link pointer-cursor ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.triggerClaimsTimesCompany && triggerClaimsTimesByCompanyChart.labels.length"
        [datasets]="triggerClaimsTimesByCompanyChart.dataset"
        [labels]="triggerClaimsTimesByCompanyChart.labels"
        [options]="triggerClaimsTimesByCompanyChart.options"
        [plugins]="triggerClaimsTimesByCompanyChart.plugins"
        [chartType]="triggerClaimsTimesByCompanyChart.chartType">
      </canvas>
      <p
        *ngIf="
          !loading.triggerClaimsTimesCompany && !triggerClaimsTimesByCompanyChart.labels.length
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.triggerClaimsTimesCompany">
        <app-loader [loader]="loading.triggerClaimsTimesCompany"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  [attr.id]="expand.triggerClaimsTimesFacility ? 'ccd-trigger-claim-cycle-time' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY) &&
    expand.triggerClaimsTimesFacility
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY) as report">
          {{ report.reportName }}
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <form [formGroup]="triggerClaimsCycleTimesByFacilityForm">
            <div class="d-flex justify-content-between align-items-center">
              <div class="w-100">
                <div style="width: 450px; display: inline-block">
                  <app-select
                    [options]="providerReferencesList"
                    [id]="'facility'"
                    [placeholder]="'Select facility'"
                    [filterEnabled]="false"
                    mdbInput
                    [control]="triggerClaimsCycleTimesByFacilityForm.controls['facilityId']"
                    (onSelected)="facilityReferenceSelected($event)">
                  </app-select>
                </div>
                <mdb-select
                  style="width: 80px"
                  class="ml-3"
                  placeholder="Year"
                  [options]="yearOptions.triggerClaimsCycleTimesByFacility | sort : 'label'"
                  id="year-select"
                  [visibleOptions]="3"
                  formControlName="triggerClaimCycleTimesByFacilitySelectedYear"
                  (selected)="
                    yearSelected($event, REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY)
                  ">
                </mdb-select>
              </div>
            </div>
          </form>
          <span
            class="link pointer-cursor donotprint ml-3"
            (click)="onViewToggle(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY, false)">
            <i class="fas fa-compress-alt"></i>
          </span>
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="!loading.triggerClaimsTimesFacility && triggerClaimsTimesByPayerChart.labels.length"
        [datasets]="triggerClaimsTimesByPayerChart.dataset"
        [labels]="triggerClaimsTimesByPayerChart.labels"
        [options]="triggerClaimsTimesByPayerChart.options"
        [plugins]="triggerClaimsTimesByPayerChart.plugins"
        [chartType]="triggerClaimsTimesByPayerChart.chartType">
      </canvas>
      <div
        class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
        *ngIf="
          !loading.triggerClaimsTimesFacility &&
          !triggerClaimsTimesByPayerChart.labels.length &&
          !triggerClaimsCycleTimesByFacilityForm.get('facilityId').value
        ">
        <div class="text-center">
          <i class="icofont-building icofont-4x text-secondary"></i>
          <p class="mt-2">Please select facility</p>
        </div>
      </div>
      <p
        *ngIf="
          !loading.triggerClaimsTimesFacility &&
          !triggerClaimsTimesByPayerChart.labels.length &&
          triggerClaimsCycleTimesByFacilityForm.get('facilityId').value
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.triggerClaimsTimesFacility">
        <app-loader [loader]="loading.triggerClaimsTimesFacility"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<div
  [attr.id]="expand.triggerClaimsTimesFacilityChart ? 'ccd-trigger-claim-cycle-time' : null"
  class="my-3 col-md-12"
  *ngIf="
    toShowReport &&
    toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART) &&
    expand.triggerClaimsTimesFacilityChart
  ">
  <mdb-card>
    <mdb-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="m-0 font-weight-normal"
          *ngIf="getReport(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART) as report">
          {{ report.reportName }}
        </p>
        <span
          class="link pointer-cursor donotprint"
          (click)="onViewToggle(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART, false)">
          <i class="fas fa-compress-alt"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body style="height: 500px">
      <canvas
        baseChart
        *ngIf="
          !loading.triggerClaimsTimesFacilityChart && triggerClaimsTimesFacilityChart.labels.length
        "
        [datasets]="triggerClaimsTimesFacilityChart.dataset"
        [labels]="triggerClaimsTimesFacilityChart.labels"
        [options]="triggerClaimsTimesFacilityChart.options"
        [plugins]="triggerClaimsTimesFacilityChart.plugins"
        [chartType]="triggerClaimsTimesFacilityChart.chartType">
      </canvas>
      <p
        *ngIf="
          !loading.triggerClaimsTimesFacilityChart && !triggerClaimsTimesFacilityChart.labels.length
        ">
        {{ noDataMessage.value }}
      </p>
      <div
        class="d-flex justify-content-center align-items-center h-75"
        *ngIf="loading.triggerClaimsTimesFacilityChart">
        <app-loader [loader]="loading.triggerClaimsTimesFacilityChart"></app-loader>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>
<!-- Expandable Chart Ends -->
<div
  [className]="
    !expand.triggerClaimsTimesCompany &&
    !expand.triggerClaimsTimesFacility &&
    !expand.triggerClaimsTimesFacilityChart
      ? 'mt-3'
      : 'd-none'
  ">
  <div
    class="mt-3"
    *ngIf="
      parsedClientClaimsFilterData?.isConfigLoading &&
      !parsedClientClaimsFilterData?.toShowReport.length
    ">
    <app-loader [loader]="true"></app-loader>
  </div>
  <div
    id="ccd-trigger-claim-cycle-time"
    class="m-0 p-0"
    *ngIf="
      !parsedClientClaimsFilterData?.isConfigLoading &&
      parsedClientClaimsFilterData?.toShowReport &&
      parsedClientClaimsFilterData?.toShowReport.length
    ">
    <div class="px-3">
      <!-- Trigger Claim Cycle Time - Company and Facility Begins -->
      <div
        class="row"
        *ngIf="
          toShowReport &&
          (toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY) ||
            toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY))
        ">
        <div
          class="col-md-6 mb-4"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form [formGroup]="triggerClaimCycleTimeDetailSelectedYearForm">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="m-0">Period</label>
                      <mdb-select
                        style="width: 80px"
                        class="ml-3"
                        placeholder="Year"
                        [options]="yearOptions.triggerClaimsCycleTimesByCompany | sort : 'label'"
                        id="year-select"
                        [visibleOptions]="3"
                        formControlName="triggerClaimCycleTimesByCompanySelectedYear"
                        (selected)="
                          yearSelected($event, REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY)
                        ">
                      </mdb-select>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor ml-3"
                    (click)="
                      onViewToggle(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_COMPANY, true)
                    ">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 335px">
              <canvas
                baseChart
                *ngIf="
                  !loading.triggerClaimsTimesCompany &&
                  triggerClaimsTimesByCompanyChart.labels.length
                "
                [datasets]="triggerClaimsTimesByCompanyChart.dataset"
                [labels]="triggerClaimsTimesByCompanyChart.labels"
                [options]="triggerClaimsTimesByCompanyChart.options"
                [plugins]="triggerClaimsTimesByCompanyChart.plugins"
                [chartType]="triggerClaimsTimesByCompanyChart.chartType">
              </canvas>
              <p
                *ngIf="
                  !loading.triggerClaimsTimesCompany &&
                  !triggerClaimsTimesByCompanyChart.labels.length
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.triggerClaimsTimesCompany">
                <app-loader [loader]="loading.triggerClaimsTimesCompany"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div
          class="col-md-6 mb-4"
          *ngIf="
            toShowReport &&
            toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY)
          ">
          <mdb-card>
            <mdb-card-header>
              <div class="d-flex justify-content-between align-items-center">
                <p
                  class="m-0 font-weight-normal"
                  *ngIf="getReport(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY) as report">
                  {{ report.reportName }}
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <form
                    [formGroup]="triggerClaimsCycleTimesByFacilityForm"
                    style="min-width: 300px">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="w-100 d-flex justify-content-between align-items-center">
                        <div style="display: inline-block; width: 300px">
                          <app-select
                            [options]="providerReferencesList"
                            [id]="'facility'"
                            [placeholder]="'Select facility'"
                            [filterEnabled]="false"
                            mdbInput
                            [control]="triggerClaimsCycleTimesByFacilityForm.controls['facilityId']"
                            (onSelected)="facilityReferenceSelected($event)">
                          </app-select>
                        </div>
                        <mdb-select
                          style="width: 80px"
                          class="ml-3"
                          placeholder="Year"
                          [options]="yearOptions.triggerClaimsCycleTimesByFacility | sort : 'label'"
                          id="year-select"
                          [visibleOptions]="3"
                          formControlName="triggerClaimCycleTimesByFacilitySelectedYear"
                          (selected)="
                            yearSelected(
                              $event,
                              REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY
                            )
                          ">
                        </mdb-select>
                      </div>
                    </div>
                  </form>
                  <span
                    class="link pointer-cursor donotprint ml-3"
                    (click)="
                      onViewToggle(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY, true)
                    ">
                    <i class="fas fa-expand-alt"></i>
                  </span>
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body style="height: 335px">
              <canvas
                baseChart
                *ngIf="
                  !loading.triggerClaimsTimesFacility &&
                  triggerClaimsTimesByPayerChart.labels.length
                "
                [datasets]="triggerClaimsTimesByPayerChart.dataset"
                [labels]="triggerClaimsTimesByPayerChart.labels"
                [options]="triggerClaimsTimesByPayerChart.options"
                [plugins]="triggerClaimsTimesByPayerChart.plugins"
                [chartType]="triggerClaimsTimesByPayerChart.chartType">
              </canvas>
              <div
                class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
                *ngIf="
                  !loading.triggerClaimsTimesFacility &&
                  !triggerClaimsTimesByPayerChart.labels.length &&
                  !triggerClaimsCycleTimesByFacilityForm.get('facilityId').value
                ">
                <div class="text-center">
                  <i class="icofont-building icofont-4x text-secondary"></i>
                  <p class="mt-2">Please select facility</p>
                </div>
              </div>
              <p
                *ngIf="
                  !loading.triggerClaimsTimesFacility &&
                  !triggerClaimsTimesByPayerChart.labels.length &&
                  triggerClaimsCycleTimesByFacilityForm.get('facilityId').value
                ">
                {{ noDataMessage.value }}
              </p>
              <div
                class="d-flex justify-content-center align-items-center h-75"
                *ngIf="loading.triggerClaimsTimesFacility">
                <app-loader [loader]="loading.triggerClaimsTimesFacility"></app-loader>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
      <!--  Trigger Claim Cycle Time - Company and Facility Ends -->

      <!-- Trigger Claim Cycle Times (Facility Chart) Begins -->
      <div
        class="mb-4"
        *ngIf="
          toShowReport &&
          toShowReport.includes(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART)
        ">
        <mdb-card>
          <mdb-card-header>
            <div class="d-flex justify-content-between align-items-center">
              <p
                class="m-0 font-weight-normal"
                *ngIf="
                  getReport(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART) as report
                ">
                {{ report.reportName }}
              </p>
              <span
                class="link pointer-cursor donotprint"
                (click)="
                  onViewToggle(REPORT_NAME_CODES.TRIGGER_CLAIM_CYCLE_TIMES_FACILITY_CHART, true)
                ">
                <i class="fas fa-expand-alt"></i>
              </span>
            </div>
          </mdb-card-header>
          <mdb-card-body style="height: 320px">
            <canvas
              baseChart
              *ngIf="
                !loading.triggerClaimsTimesFacilityChart &&
                triggerClaimsTimesFacilityChart.labels.length
              "
              [datasets]="triggerClaimsTimesFacilityChart.dataset"
              [labels]="triggerClaimsTimesFacilityChart.labels"
              [options]="triggerClaimsTimesFacilityChart.options"
              [plugins]="triggerClaimsTimesFacilityChart.plugins"
              [chartType]="triggerClaimsTimesFacilityChart.chartType">
            </canvas>
            <p
              *ngIf="
                !loading.triggerClaimsTimesFacilityChart &&
                !triggerClaimsTimesFacilityChart.labels.length
              ">
              {{ noDataMessage.value }}
            </p>
            <div
              class="d-flex justify-content-center align-items-center h-75"
              *ngIf="loading.triggerClaimsTimesFacilityChart">
              <app-loader [loader]="loading.triggerClaimsTimesFacilityChart"></app-loader>
            </div>
          </mdb-card-body>
        </mdb-card>
      </div>
      <!-- Trigger Claim Cycle Times (Facility Chart) Ends -->
    </div>
  </div>
</div>
