<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
    <div class="form-group has-search input-group mt-2 mb-2 w-25 w-xs-100">
      <span class="form-control-feedback left-0">
        <i class="icofont-search-1"></i>
      </span>
      <input
        type="text"
        [(ngModel)]="query.keyword"
        (ngModelChange)="change($event)"
        class="form-control"
        id="search"
        placeholder="Search" />
      <div class="input-group-append" *ngIf="query.keyword">
        <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
          <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50 mt-2 mb-2">
      <div style="width: 40%; margin-top: 5px" *ngIf="!CLIENT_ADMINS.includes(user.roleCode)">
        <app-select
          [options]="networks"
          [placeholder]="'Select network'"
          (onSelected)="filterByNetwork($event)"
          (onDeselected)="filterByNetwork($event, 'remove')"
          [allowClear]="true">
        </app-select>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="openClientModal()"
          mdbWavesEffect>
          <i class="icofont-plus"></i>
          <span class="d-none d-sm-inline-block ml-1"> ADD</span>
        </button>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table
          mdbTable
          hover="true"
          small="true"
          class="table-mobile-view v-top"
          stickyHeader="true">
          <thead id="scrollToTop">
            <tr>
              <th width="20"></th>
              <th [class.active]="query.sortBy === 'tin'" (click)="setOrder('tin')">
                TIN
                <span *ngIf="query.sortBy === 'tin'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th [class.active]="query.sortBy === 'name'" (click)="setOrder('name')">
                Name
                <span *ngIf="query.sortBy === 'name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th
                width="110"
                [class.active]="query.sortBy === 'createdAt'"
                (click)="setOrder('createdAt')">
                Created Date
                <span *ngIf="query.sortBy === 'createdAt'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading"
            [style.top]="clients && clients.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let client of clients
                | paginate
                  : {itemsPerPage: query.limit, currentPage: query.page, totalItems: totalCount};
              let i = index
            ">
            <tr [ngClass]="{'grey lighten-4 border': client.collapsed}">
              <td (click)="client.collapsed = !client.collapsed; showClientDetails(client, i)">
                <button
                  type="button"
                  class="btn btn-link p-0 m-0"
                  mdbWavesEffect
                  *ngIf="client.collapsed">
                  <i class="icofont-minus-circle icofont-lg text-info"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-link p-0 m-0"
                  mdbWavesEffect
                  *ngIf="!client.collapsed">
                  <i class="icofont-plus-circle icofont-lg text-info"></i>
                </button>
              </td>
              <td (click)="editClient(client)">{{ client?.tin }}</td>
              <td (click)="editClient(client)">
                {{ client?.name }}
                <br />
                <span *ngFor="let name of client?.networkName">
                  <span class="badge badge-sm bg-primary-dark text-white z-depth-0">
                    <i class="fas fa-sitemap fa-sm mr-1"></i>
                    {{ name | titlecase }} </span
                  >&nbsp;
                </span>
              </td>
              <td>{{ client?.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}</td>
              <td width="32">
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div
                    class="custom-dropdown-content rounded-sm z-depth-2"
                    [style.top]="(i === query.limit - 2 || i === query.limit - 1) && '-175px'">
                    <a class="dropdown-item" (click)="createValueBasedProgram(client)"
                      ><i class="fas fa-cog"></i> Add Value Based Program</a
                    >
                    <a class="dropdown-item" (click)="addBankInfo(client)"
                      ><i class="icofont-files-stack"></i> Add Bank Information</a
                    >
                    <a class="dropdown-item" (click)="editClient(client)"
                      ><i class="icofont-edit-alt"></i> Edit</a
                    >
                    <a class="dropdown-item" (click)="delete(client, 'client')"
                      ><i class="icofont-ui-delete"></i> Delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="client.collapsed" class="grey lighten-5 border">
              <td colspan="100%" class="p-3">
                <div class="row">
                  <div class="col-8">
                    <div class="d-flex align-items-center">
                      <h6 class="font-weight-500 mr-4">Addtional Information</h6>
                      <mdb-badge
                        *ngIf="client.generateEob"
                        pill="true"
                        class="z-depth-0 bg-primary-dark"
                        ><i class="fas fa-check-circle"></i> Generate EOB</mdb-badge
                      >
                    </div>
                    <div class="d-flex mb-3">
                      <div class="list-content">
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0">
                            <span class="d-none d-sm-inline-block">Address:</span></label
                          >
                          <div class="field-value">
                            {{ client?.addressLine1 }}
                            {{ client?.addressLine2 ? client?.addressLine2 : '' }}
                            <br />
                            {{ client?.city }} {{ client?.state }}
                            {{ client?.zip4 ? client?.zip + '-' + client?.zip4 : client?.zip }}
                          </div>
                        </div>
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0 m-0">
                            <span class="d-none d-sm-inline-block"
                              >Client Customer Support Number:</span
                            ></label
                          >
                          <div class="field-value">
                            {{ client?.phone ? (client.phone | formatPhoneNumber) : 'N/A' }}
                          </div>
                        </div>
                      </div>
                      <div class="ml-5 list-content border-top-0">
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0"
                            ><span class="d-none d-sm-inline-block">Contact Name:</span></label
                          >
                          <div class="field-value">
                            {{ client.contactName }}
                          </div>
                        </div>
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0"
                            ><span class="d-none d-sm-inline-block">Contact Email:</span></label
                          >
                          <div class="field-value">
                            {{ client.contactEmail }}
                          </div>
                        </div>
                        <div class="field-group pt-0 pb-0">
                          <label class="pl-0"
                            ><span class="d-none d-sm-inline-block">Contact Phone:</span></label
                          >
                          <div class="field-value">
                            {{
                              client.contactPhone
                                ? (client.contactPhone | formatPhoneNumber)
                                : 'N/A'
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col"></div>
                </div>
                <mdb-tabset
                  #clientExpandTabs
                  [buttonClass]="'classic-tabs border-bottom'"
                  [contentClass]="'card tab-clean d-block p-0'"
                  class="classic-tabs">
                  <!--Panel 1-->
                  <mdb-tab heading="Value Based Program">
                    <app-loader
                      [loader]="
                        (!client?.valueBasedPrograms || client?.valueBasedPrograms.length == 0) &&
                        value_based_loading &&
                        !client.isVbpLoad
                      ">
                    </app-loader>
                    <div class="form-row mt-3">
                      <div
                        *ngFor="let vbp of client?.valueBasedPrograms"
                        class="col-4 col-sm-4 col-md-4 col-xl-3 mb-2">
                        <mdb-card class="z-depth-0 bg-blue-light h-100">
                          <mdb-card-header
                            class="d-flex justify-content-between align-items-center p-2">
                            <div>
                              <h6 class="font-weight-500">{{ vbp?.name }}</h6>
                              <mdb-badge class="z-depth-0 bg-primary-dark"
                                ><i class="icofont-building"></i> {{ vbp?.code }}</mdb-badge
                              >
                            </div>
                            <div class="custom-dropdown">
                              <button
                                class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                                type="button"
                                mdbWavesEffect>
                                <mdb-icon class="skin-link" fas icon="ellipsis-v"> </mdb-icon>
                              </button>
                              <div class="custom-dropdown-content rounded-sm z-depth-2">
                                <a
                                  class="dropdown-item"
                                  (click)="editValueBasedProgram(vbp, client)">
                                  <i class="fa-fw fas fa-pencil-alt"></i>
                                  Edit
                                </a>
                                <a class="dropdown-item" (click)="delete(vbp, 'valueBasedProgram')"
                                  ><i class="icofont-ui-delete"></i> Delete</a
                                >
                              </div>
                            </div>
                          </mdb-card-header>
                          <mdb-card-body class="p-2">
                            <p>{{ vbp?.description }}</p>

                            <p class="font-weight-500 mt-3">Associated Episode Of Care</p>
                            <ul
                              class="pl-0 mb-0 scrollbar scrollbar-primary thin overflow-auto"
                              style="max-width: 250px">
                              <li *ngFor="let eoc of vbp?.episodeOfCares | slice : 0 : 3">
                                <span class="badge badge-info z-depth-0 mr-2 mb-2 p-1">
                                  {{ eoc?.name }}
                                </span>
                              </li>
                            </ul>
                            <div
                              *ngIf="vbp?.episodeOfCares && vbp?.episodeOfCares?.length > 3"
                              class="action-item position-absolute"
                              (click)="showAssociatedEOC(vbp, vbp.episodeOfCares)"
                              style="bottom: 10px; right: 10px">
                              <span class="chip chip-rounded alert preliminary m-0">
                                {{ vbp?.episodeOfCares?.length - 3 }}+</span
                              >
                              View more
                            </div>
                          </mdb-card-body>
                        </mdb-card>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        (!client?.valueBasedPrograms || client?.valueBasedPrograms.length == 0) &&
                        !value_based_loading
                      ">
                      <strong>No Value Based Program Available</strong>
                    </div>
                  </mdb-tab>
                  <!--Panel 2-->
                  <mdb-tab heading="Purchaser">
                    <app-loader
                      [loader]="
                        (!client?.purchasers || client?.purchasers.length == 0) &&
                        purchaser_loading &&
                        !client.isPurchaserLoad
                      ">
                    </app-loader>
                    <div class="form-row mt-3">
                      <div
                        *ngFor="let purchaser of client?.purchasers"
                        class="col-4 col-sm-4 col-md-3 col-xl-3 mb-3">
                        <mdb-card class="z-depth-0 border bg-blue-light">
                          <mdb-card-body class="p-3">
                            <div class="media">
                              <div class="avatar d-flex border mr-3 bg-white">
                                <img
                                  class="img-fluid"
                                  src="{{
                                    purchaser.logo
                                      ? purchaser.logo
                                      : '../../../assets/img/png/building_icon.png'
                                  }}"
                                  alt="Provider" />
                              </div>

                              <div class="media-body">
                                <h6 class="mt-0 font-weight-bold mb-0">
                                  {{ purchaser.name }}
                                </h6>
                                <p class="mb-2 text-muted">
                                  <span class="badge badge-info z-depth-0">
                                    {{ purchaser.code }}
                                  </span>
                                </p>
                              </div>
                              <div class="custom-dropdown">
                                <button
                                  class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                                  type="button"
                                  mdbWavesEffect>
                                  <mdb-icon class="skin-link" fas icon="ellipsis-v"> </mdb-icon>
                                </button>
                                <div class="custom-dropdown-content rounded-sm z-depth-2">
                                  <a
                                    class="dropdown-item"
                                    (click)="viewClientPurchaser(purchaser, client)"
                                    ><i class="fas fa-eye"></i> View Detail</a
                                  >
                                </div>
                              </div>
                            </div>
                          </mdb-card-body>
                        </mdb-card>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        (!client?.purchasers || client?.purchasers.length == 0) &&
                        !purchaser_loading
                      ">
                      <strong>No Purchasers Available</strong>
                    </div>
                  </mdb-tab>

                  <!-- Panel 3 -->
                  <mdb-tab heading="Bank Information">
                    <app-loader
                      [loader]="
                        (!client?.banks || client?.banks.length == 0) &&
                        bank_loading &&
                        !client.isBankLoad
                      ">
                    </app-loader>
                    <div class="form-row mt-3">
                      <div
                        *ngFor="let bank of client?.banks; let bankIndex = index"
                        class="col-4 col-sm-4 col-md-4 col-xl-3 mb-2">
                        <mdb-card class="z-depth-0 bg-blue-light h-100">
                          <mdb-card-header>
                            <div class="d-flex justify-content-between align-items-center">
                              <h6 class="mb-0">{{ bank?.bank?.name }}</h6>
                              <div class="custom-dropdown">
                                <button
                                  class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                                  type="button"
                                  mdbWavesEffect>
                                  <mdb-icon class="skin-link" fas icon="ellipsis-v"> </mdb-icon>
                                </button>
                                <div class="custom-dropdown-content rounded-sm z-depth-2">
                                  <a class="dropdown-item" (click)="editBank(bank)">
                                    <i class="fa-fw fas fa-pencil-alt"></i>
                                    Edit
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    (click)="delete(bank, 'bankInformation')">
                                    <i class="fa-fw far fa-trash-alt"></i>
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <small class="text-muted">Bank Account</small>

                                <p class="text-dark font-weight-bold mb-0">
                                  {{
                                    bank.isAccountHide ? bank.hideAccountNumber : bank.accountNumber
                                  }}
                                  <a (click)="hideBankDetail(i, bankIndex, 'account')" class="ml-3">
                                    <mdb-icon
                                      *ngIf="bank?.isAccountHide"
                                      fas
                                      icon="eye-slash"></mdb-icon>
                                    <mdb-icon
                                      *ngIf="!bank?.isAccountHide"
                                      fas
                                      icon="eye"></mdb-icon>
                                  </a>
                                </p>
                              </div>
                              <div class="col-6">
                                <small class="text-muted">Routing Number</small>

                                <p class="text-dark font-weight-bold mb-0">
                                  {{
                                    bank.isRoutingHide ? bank.hideRoutingNumber : bank.routingNumber
                                  }}
                                  <a (click)="hideBankDetail(i, bankIndex, 'routing')" class="ml-3">
                                    <mdb-icon *ngIf="bank?.isRoutingHide" fas icon="eye-slash">
                                    </mdb-icon>
                                    <mdb-icon *ngIf="!bank?.isRoutingHide" fas icon="eye">
                                    </mdb-icon>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </mdb-card-header>

                          <mdb-card-body class="p-3">
                            <div class="ml-2 list-content border-top-0">
                              <div class="field-group pt-0 pb-0">
                                <label class="pl-0">
                                  <span class="d-none d-sm-inline-block">Address</span></label
                                >
                                <div class="field-value">
                                  {{
                                    bank?.bank?.addressLine1 ||
                                    bank?.bank?.addressLine2 ||
                                    bank?.bank?.city ||
                                    bank?.bank?.state ||
                                    bank?.bank?.zip ||
                                    bank?.bank?.zip4
                                      ? ''
                                      : 'N/A'
                                  }}
                                  {{ bank?.bank?.addressLine1 ? bank.bank.addressLine1 : '' }}
                                  {{ bank?.bank?.addressLine2 ? bank.bank.addressLine2 : '' }}
                                  <br
                                    *ngIf="bank?.bank?.addressLine1 || bank?.bank?.addressLine2" />
                                  {{ bank?.bank?.city ? bank.bank.city : '' }}
                                  {{ bank?.bank?.zip ? bank.bank.state : bank.bank.state }}
                                  {{
                                    bank?.bank?.zip4
                                      ? bank?.bank?.zip + '-' + bank.bank.zip4
                                      : bank?.bank?.zip
                                  }}
                                </div>
                              </div>
                              <div class="field-group pt-0 pb-0">
                                <label class="pl-0"
                                  ><span class="d-none d-sm-inline-block">Contact Name</span></label
                                >
                                <div class="field-value">
                                  {{ bank?.contact?.name ? bank.contact.name : 'N/A' }}
                                </div>
                              </div>
                              <div class="field-group pt-0 pb-0">
                                <label class="pl-0"
                                  ><span class="d-none d-sm-inline-block"
                                    >Contact Email</span
                                  ></label
                                >
                                <div class="field-value">
                                  {{ bank?.contact?.email ? bank.contact.email : 'N/A' }}
                                </div>
                              </div>
                              <div class="field-group pt-0 pb-0">
                                <label class="pl-0"
                                  ><span class="d-none d-sm-inline-block"
                                    >Contact Phone</span
                                  ></label
                                >
                                <div class="field-value">
                                  {{
                                    bank?.contact?.phone
                                      ? (bank.contact.phone | formatPhoneNumber)
                                      : 'N/A'
                                  }}
                                </div>
                              </div>
                            </div>
                          </mdb-card-body>
                        </mdb-card>
                      </div>
                    </div>
                    <div *ngIf="(!client?.banks || client?.banks.length == 0) && !bank_loading">
                      <strong>No Banks Available</strong>
                    </div>
                  </mdb-tab>
                </mdb-tabset>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="clients && clients.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.clients">
    <i class="fas fa-3x fa-building text-black-50"></i>
    <p>Start adding Client.</p>
  </div>

  <div class="text-center p-4 border mt-3" *ngIf="!loading && !result.searchedClients">
    <i class="fas fa-3x fa-building text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>

<!--CLIENT MODAL -->
<div
  mdbModal
  #clientModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ clientForm.value.id ? 'Edit' : 'Add' }} Client</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeClientModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <form class="p-3" [formGroup]="clientForm">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="clentName">Name *</label>
                <input
                  type="text"
                  id="clentName"
                  class="form-control"
                  formControlName="name"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': submitted && clientForm.get('name').invalid
                  }" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="tin">TIN *</label>
                <input
                  type="text"
                  id="tin"
                  class="form-control"
                  formControlName="tin"
                  mdbInput
                  numbersOnly
                  maxlength="9"
                  [ngClass]="{
                    'is-invalid': submitted && clientForm.get('tin').invalid
                  }" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="networks">Select Network *</label>
                <mdb-select
                  [options]="networks | sort : 'label'"
                  id="networks"
                  placeholder="Select network"
                  [multiple]="true"
                  [enableSelectAll]="false"
                  formControlName="networks"
                  [ngClass]="{
                    'error-select': submitted && clientForm.get('networks').invalid
                  }">
                </mdb-select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="phone">Client Customer Support Number *</label>
                <input
                  type="text"
                  id="phone"
                  class="form-control"
                  formControlName="phone"
                  [textMask]="{mask: mask}"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && clientForm.get('phone').invalid}" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="cAddress">Address *</label>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="clientAddressLine1"
                  class="form-control"
                  formControlName="addressLine1"
                  mdbInput
                  placeholder="Address line 1"
                  [ngClass]="{
                    'is-invalid': submitted && clientForm.get('addressLine1').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="clientAddress2"
                  class="form-control"
                  formControlName="addressLine2"
                  mdbInput
                  placeholder="Address line 2" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="clientCity"
                  class="form-control"
                  formControlName="city"
                  mdbInput
                  placeholder="City"
                  [ngClass]="{'is-invalid': submitted && clientForm.get('city').invalid}" />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <mdb-select
                  [options]="usStates | sort : 'label'"
                  id="state"
                  formControlName="state"
                  placeholder="State"
                  [ngClass]="{
                    'error-select': submitted && clientForm.get('state').invalid
                  }">
                </mdb-select>
              </div>
            </div>
            <div class="col-4">
              <div class="form-row">
                <div class="col">
                  <input
                    type="text"
                    id="clientZip"
                    class="form-control"
                    formControlName="zip"
                    mdbInput
                    numbersOnly
                    maxlength="5"
                    placeholder="Zip"
                    [ngClass]="{'is-invalid': submitted && clientForm.get('zip').invalid}" />
                </div>
                <div>
                  <span class="zip-dash">-</span>
                </div>
                <div class="col">
                  <input
                    type="text"
                    id="clientZip2"
                    class="form-control"
                    formControlName="zip4"
                    placeholder="Zip4"
                    numbersOnly
                    maxlength="4"
                    mdbInput
                    [readonly]="!clientForm.value.zip"
                    [ngClass]="{'is-invalid': submitted && clientForm.get('zip4').invalid}" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="clientContactName">Contact Name *</label>
                <input
                  type="text"
                  id="clientContactName"
                  class="form-control"
                  formControlName="contactName"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && clientForm.get('contactName').invalid}" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="clientContactEmail">Contact Email *</label>
                <input
                  type="text"
                  id="clientContactEmail"
                  class="form-control"
                  formControlName="contactEmail"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && clientForm.get('contactEmail').invalid}" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="clientContactPhone">Contact Phone *</label>
                <input
                  type="text"
                  id="clientContactPhone"
                  class="form-control"
                  formControlName="contactPhone"
                  [textMask]="{mask: mask}"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && clientForm.get('contactPhone').invalid}" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <mdb-checkbox formControlName="generateEob" [checked]="clientForm.value.generateEob">
              Generate EOB</mdb-checkbox
            >
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeClientModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submit('client')"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ clientForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- ASSOCIATED EOC LIST MODAL -->
<div
  mdbModal
  #associatedEOCModal="mdbModal"
  class="modal fade top"
  id="associatedEOC"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ selectedValueBasedProgramEOCs?.vbp?.name }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeAssociatedEOCModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div
        class="modal-body scrollbar scrollbar-primary thin overflow-auto p-2"
        style="max-height: 400px">
        <div *ngIf="selectedValueBasedProgramEOCs?.vbp?.description">
          <p class="font-weight-500 pl-2">Description</p>
          <p class="pl-2">{{ selectedValueBasedProgramEOCs?.vbp?.description }}</p>
        </div>
        <p class="font-weight-500 pl-2">Associated Episode Of Care</p>
        <ul class="pl-2 mb-0">
          <li *ngFor="let associatedEoc of selectedValueBasedProgramEOCs?.eocs">
            <span class="badge badge-info z-depth-0 mr-2 mb-2 p-1">
              {{ associatedEoc?.name }}
            </span>
          </li>
        </ul>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-end p-2 border-top-0">
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="closeAssociatedEOCModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!-- VALUE BASED PROGRAM MODAL -->
<div
  mdbModal
  #valueBaseProgramModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ valueBasedProgramForm.value.id ? 'Edit' : 'Add' }} Value Based Program
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeValueBasedModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="valueBasedProgramForm">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="vbpCode">VBP Code *</label>
                <input
                  type="text"
                  class="form-control"
                  id="vbpCode"
                  formControlName="code"
                  [readonly]="valueBasedProgramForm.value.id"
                  mdbInput
                  [ngClass]="{
                    'error-select': submitted && valueBasedProgramForm.get('code').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="vbpName">VBP Name *</label>
                <input
                  type="text"
                  class="form-control"
                  id="vbpName"
                  formControlName="name"
                  mdbInput
                  [ngClass]="{
                    'error-select': submitted && valueBasedProgramForm.get('name').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="valueBasedNetworkCode">Select Network *</label>
                <mdb-select
                  [options]="vbpClientNetworks | sort : 'label'"
                  id="valueBasedNetworkCode"
                  [disableControl]="
                    valueBasedProgramForm.value.id || vbpClientNetworks.length == 0 ? true : false
                  "
                  placeholder="Select network"
                  formControlName="networkCode"
                  (selected)="selectClientNetwork($event)"
                  [ngClass]="{
                    'pointer-disabled': valueBasedProgramForm.value.id,
                    'error-select': submitted && valueBasedProgramForm.get('networkCode').invalid
                  }">
                </mdb-select>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="valueBasedDescription">Description </label>
            <textarea
              type="text"
              id="valueBasedDescription"
              class="md-textarea form-control"
              formControlName="description"
              mdbInput
              [ngClass]="{
                'is-invalid': submitted && valueBasedProgramForm.get('description').invalid
              }">
            </textarea>
          </div>
          <p>Associate EOC (Episodes of Care)</p>

          <div
            *ngIf="selectedClientNetwork || valueBasedProgramForm.value.id"
            class="row m-0 border">
            <div class="col-6 col-sm-6 col-md-6 col-xl-6">
              <h6 class="mt-3 font-weight-bold">Available EOC</h6>
              <div class="form-group has-search position-relative mt-2">
                <span class="form-control-feedback">
                  <i class="icofont-search-1"></i>
                </span>
                <input
                  type="text"
                  class="completer-input form-control mdb-autocomplete"
                  [ngModel]="episode_of_care_search_text | async"
                  [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="episode_of_care_search_text.next($event)"
                  [mdbAutoCompleter]="auto"
                  placeholder="Search episode of care" />
                <mdb-auto-completer
                  #auto="mdbAutoCompleter"
                  textNoResults="No result found"
                  (selected)="selectedEoc($event)"
                  class="completer-dropdown-holder-dashboard"
                  [displayValue]="onDisplayValue">
                  <mdb-option
                    *ngFor="let option of episode_of_care_results | async"
                    [value]="option">
                    <div class="d-flex flex-column">
                      <strong> {{ option.name }} </strong>
                    </div>
                  </mdb-option>
                </mdb-auto-completer>
              </div>
              <div
                *ngIf="associateEpisodeOfCares && associateEpisodeOfCares.length != 0"
                class="scrollbar scrollbar-primary thin overflow-auto p-2"
                style="max-height: 300px">
                <ul class="pl-0 mb-1">
                  <li>
                    <mdb-checkbox
                      (change)="selectAllEOC($event)"
                      [checked]="
                        associateEpisodeOfCares &&
                        associateEpisodeOfCares[0] &&
                        associateEpisodeOfCares[0].selectAll
                      "
                      [disabled]="!associateEpisodeOfCares || associateEpisodeOfCares.length === 0">
                      <strong>Select All</strong>
                    </mdb-checkbox>
                  </li>
                  <li *ngFor="let eoc of associateEpisodeOfCares">
                    <mdb-checkbox
                      [checked]="eoc.selected"
                      id="eoc{{ eoc.uuid }}"
                      (change)="selectClientEoc($event, eoc)"
                      >{{ eoc.name }}
                    </mdb-checkbox>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-xl-6 border-left">
              <h6 class="mt-3 font-weight-bold">Selected EOC</h6>
              <div class="scrollbar scrollbar-primary thin overflow-auto" style="max-height: 300px">
                <ul class="pl-0 mb-1">
                  <li class="mb-1" *ngFor="let eoc of selectedEpisodeOfCares">
                    <span class="badge badge-info z-depth-0 mr-2">
                      <a (click)="selectClientEoc({checked: false}, eoc)" class="text-white">
                        <mdb-icon fas icon="times"></mdb-icon>
                      </a>
                      {{ eoc?.name }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeValueBasedModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submit('valueBasedProgram')"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span
          >{{ valueBasedProgramForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- BANK INFORMATION MODAL -->
<div
  mdbModal
  #bankInfoModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ bankInfoForm.value.id ? 'Edit' : 'Add' }} Client Bank Information
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeBankInfoModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="bankInfoForm">
          <div class="form-group">
            <label for="name">Bank Name *</label>
            <input
              type="text"
              class="form-control"
              id="name"
              formControlName="name"
              mdbInput
              [ngClass]="{'error-select': submitted && bankInfoForm.get('name').invalid}" />
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="accountNumber">Bank Account *</label>
                <input
                  type="text"
                  class="form-control"
                  id="accountNumber"
                  formControlName="accountNumber"
                  numbersOnly
                  mdbInput
                  [ngClass]="{
                    'error-select': submitted && bankInfoForm.get('accountNumber').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="routingNumber">Routing No. *</label>
                <input
                  type="text"
                  class="form-control"
                  id="routingNumber"
                  formControlName="routingNumber"
                  numbersOnly
                  mdbInput
                  [ngClass]="{
                    'error-select': submitted && bankInfoForm.get('routingNumber').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="bankContactName">Contact Name</label>
            <input
              type="text"
              class="form-control"
              id="bankContactName"
              formControlName="contactName"
              mdbInput
              [ngClass]="{'error-select': submitted && bankInfoForm.get('contactName').invalid}" />
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="bankContactEmail">Contact Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="bankContactEmail"
                  formControlName="contactEmail"
                  mdbInput
                  [ngClass]="{
                    'error-select': submitted && bankInfoForm.get('contactEmail').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="bankContactPhone">Contact Phone</label>
                <input
                  type="text"
                  class="form-control"
                  id="bankContactPhone"
                  formControlName="contactPhone"
                  mdbInput
                  [textMask]="{mask: mask}"
                  [ngClass]="{
                    'error-select': submitted && bankInfoForm.get('contactPhone').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="pAddress">Address</label>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="bankAddressLine1"
                  class="form-control"
                  formControlName="addressLine1"
                  mdbInput
                  placeholder="Address line 1"
                  [ngClass]="{
                    'is-invalid': submitted && bankInfoForm.get('addressLine1').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="addressLine2"
                  class="form-control"
                  formControlName="addressLine2"
                  mdbInput
                  placeholder="Address line 2" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="bankCity"
                  class="form-control"
                  formControlName="city"
                  mdbInput
                  placeholder="City"
                  [ngClass]="{'is-invalid': submitted && bankInfoForm.get('city').invalid}" />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <mdb-select
                  [options]="usStates | sort : 'label'"
                  id="state"
                  formControlName="state"
                  placeholder="State"
                  [ngClass]="{
                    'error-select': submitted && bankInfoForm.get('state').invalid
                  }">
                </mdb-select>
              </div>
            </div>
            <div class="col-4">
              <div class="form-row">
                <div class="col">
                  <input
                    type="text"
                    id="bankZip"
                    class="form-control"
                    formControlName="zip"
                    mdbInput
                    numbersOnly
                    maxlength="5"
                    placeholder="Zip"
                    [ngClass]="{'is-invalid': submitted && bankInfoForm.get('zip').invalid}" />
                </div>
                <div>
                  <span class="zip-dash">-</span>
                </div>
                <div class="col">
                  <input
                    type="text"
                    id="bankZip2"
                    class="form-control"
                    formControlName="zip4"
                    placeholder="Zip4"
                    numbersOnly
                    maxlength="4"
                    mdbInput
                    [readonly]="!bankInfoForm.value.zip"
                    [ngClass]="{'is-invalid': submitted && bankInfoForm.get('zip4').invalid}" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeBankInfoModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submit('bankInfo')"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span
          >{{ bankInfoForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- CLIENT PURCHASER MODAL -->
<div
  mdbModal
  #purchaserModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header grey lighten-4 border-0">
        <h5 class="modal-title w-100">{{ purchaserDetail?.purchaser?.name }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closePurchaserModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <mdb-tabset
          #viewPurchaserDetailTabs
          [buttonClass]="'classic-tabs grey lighten-4'"
          [contentClass]="'card tab-clean d-block '"
          class="classic-tabs">
          <!--Panel 1-->
          <mdb-tab heading="General Information">
            <div *ngIf="purchaserDetail?.purchaser">
              <table mdbTable small="true" class="v-top mb-0">
                <tbody class="bg-white">
                  <tr>
                    <td class="font-weight-500">Name</td>
                    <td>
                      {{
                        purchaserDetail?.purchaser?.name ? purchaserDetail.purchaser.name : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Number</td>
                    <td>
                      {{
                        purchaserDetail?.purchaser?.number
                          ? purchaserDetail.purchaser.number
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">TIN</td>
                    <td>
                      {{ purchaserDetail?.purchaser?.tin ? purchaserDetail.purchaser.tin : 'N/A' }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Address</td>
                    <td>
                      {{ purchaserDetail?.purchaser?.addressLine1 }}
                      {{
                        purchaserDetail?.purchaser?.addressLine2
                          ? purchaserDetail.purchaser.addressLine2
                          : ''
                      }}
                      <br />
                      {{ purchaserDetail?.purchaser?.city }} {{ purchaserDetail?.purchaser?.state }}
                      {{
                        purchaserDetail?.purchaser?.zip4
                          ? purchaserDetail?.purchaser?.zip + '-' + purchaserDetail?.purchaser?.zip4
                          : purchaserDetail?.purchaser?.zip
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Contact Name</td>
                    <td>
                      {{
                        purchaserDetail?.purchaser?.contactName
                          ? purchaserDetail.purchaser.contactName
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Contact Email</td>
                    <td>
                      {{
                        purchaserDetail?.purchaser?.contactEmail
                          ? purchaserDetail.purchaser.contactEmail
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Contact Phone</td>
                    <td>
                      {{
                        purchaserDetail?.purchaser?.contactPhone
                          ? (purchaserDetail.purchaser.contactPhone | formatPhoneNumber)
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="!purchaserDetail?.purchaser" class="text-center p-4 border mt-3">
              <p>No Information Available.</p>
            </div>
          </mdb-tab>
          <!--Panel 2-->
          <mdb-tab heading="Value Based Program">
            <app-loader
              [loader]="
                (!purchaserDetail?.purchaser?.valueBasedPrograms ||
                  purchaserDetail?.purchaser?.valueBasedPrograms.length == 0) &&
                purchaser_vbp_loading
              ">
            </app-loader>
            <mdb-accordion
              *ngIf="purchaserDetail?.purchaser?.valueBasedPrograms?.length > 0"
              [multiple]="false"
              class="accordion-block">
              <mdb-accordion-item
                *ngFor="
                  let valueBased of purchaserDetail?.purchaser?.valueBasedPrograms;
                  let i = index
                "
                [collapsed]="i == 0 ? false : true">
                <mdb-accordion-item-head>{{ valueBased?.vbp?.name }}</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <p class="font-weight-500">Description</p>
                  <p class="mb-3">{{ valueBased?.vbp?.description }}</p>
                  <p class="font-weight-500 mt-3">Associated Episode of Care</p>
                  <div
                    class="scrollbar scrollbar-primary thin overflow-auto"
                    style="max-height: 234px">
                    <span
                      *ngFor="let eoc of valueBased?.episodeOfCares"
                      class="z-depth-0 badge badge-info mr-2 mb-2">
                      {{ eoc?.name }}
                    </span>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </mdb-accordion>

            <div
              *ngIf="
                (!purchaserDetail?.purchaser?.valueBasedPrograms ||
                  purchaserDetail?.purchaser?.valueBasedPrograms?.length == 0) &&
                !purchaser_vbp_loading
              "
              class="text-center p-4 border mt-3">
              <p>No Value BasedProgram Available.</p>
            </div>
          </mdb-tab>

          <!-- Panel 3 -->
          <mdb-tab heading="Funding Source">
            <app-loader
              [loader]="
                !purchaserDetail?.purchaser?.fundingSource?.data && purchaser_funding_source_loading
              ">
            </app-loader>
            <div *ngIf="purchaserDetail?.purchaser?.fundingSource?.data">
              <table mdbTable small="true" class="v-top mb-0">
                <tbody class="bg-white">
                  <tr>
                    <td class="font-weight-500">Name</td>
                    <td>{{ purchaserDetail?.purchaser?.fundingSource?.data?.name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Payor ID</td>
                    <td>
                      {{
                        purchaserDetail?.purchaser?.fundingSource?.data?.identifier
                          ? purchaserDetail.purchaser.fundingSource.data.identifier
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">NPI</td>
                    <td>{{ purchaserDetail?.purchaser?.fundingSource?.data?.npi }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">TIN</td>
                    <td>{{ purchaserDetail?.purchaser?.fundingSource?.data?.tin }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Address</td>
                    <td>
                      {{ purchaserDetail?.purchaser?.fundingSource?.data?.addressLine1 }}
                      {{
                        purchaserDetail?.purchaser?.fundingSource?.data?.addressLine2
                          ? purchaserDetail.purchaser.fundingSource.data.addressLine2
                          : ''
                      }}
                      <br />
                      {{ purchaserDetail?.purchaser?.fundingSource?.data?.city }}
                      {{ purchaserDetail?.purchaser?.fundingSource?.data?.state }}
                      {{
                        purchaserDetail?.purchaser?.fundingSource?.data?.zip4
                          ? purchaserDetail?.purchaser?.fundingSource?.data?.zip +
                            '-' +
                            purchaserDetail?.purchaser?.fundingSource?.data?.zip4
                          : purchaserDetail?.purchaser?.fundingSource?.data?.zip
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Contact Name</td>
                    <td>
                      {{
                        purchaserDetail?.purchaser?.fundingSource?.data?.contactName
                          ? purchaserDetail.purchaser.fundingSource.data.contactName
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Contact Email</td>
                    <td>
                      {{
                        purchaserDetail?.purchaser?.fundingSource?.data?.contactEmail
                          ? purchaserDetail.purchaser.fundingSource.data.contactEmail
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-500">Contact Phone</td>
                    <td>
                      {{
                        purchaserDetail?.purchaser?.fundingSource?.data?.contactPhone
                          ? (purchaserDetail.purchaser.fundingSource.data.contactPhone
                            | formatPhoneNumber)
                          : 'N/A'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              *ngIf="
                !purchaserDetail?.purchaser?.fundingSource?.data &&
                !purchaser_funding_source_loading
              "
              class="text-center p-4 border mt-3">
              <p>No Funding Source Available.</p>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-end p-2 border-top-0">
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="closePurchaserModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<app-delete-confirmation
  [_id]="deleteId"
  [message]="message"
  (confirmDelete)="deleteConfirm($event)">
</app-delete-confirmation>
