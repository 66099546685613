import { QuestionOption } from './model.questionOption'

export class Questions {
    questionTypeCode: string
    questionTypeId: number
    question: string
    questionHelp: string
    description: string
    questionOptions: QuestionOption[] = [
    ]
}
