import { NgControl } from '@angular/forms';
import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective implements OnChanges {
  @Input('disableControl') disableControl: boolean;
  constructor(private ngControl: NgControl) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disableControl']) {
      const action = this.disableControl ? 'disable' : 'enable';

      this.ngControl.control[action]();
    }
  }
}