<app-table
  *ngIf="!providerData || providerData.data.length; else noProviderData"
  [report]="providerData"
  [exportTable]="false"
  [module]="'report'"
  tableHeight="350px"></app-table>

<ng-template #noProviderData>
  <div>
    <p>{{ noDataMessage.value }}</p>
  </div>
</ng-template>
