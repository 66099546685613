<div *ngIf="dependent_todo" class="d-flex justify-content-start align-items-start">
  <div class="icon-block-sm d-none d-sm-block rounded-sm bg-secondary text-center mr-2">
    <i class="icofont-link text-white"></i>
  </div>
  <div class="icon-block d-none d-sm-block">
    <i class="icofont-tasks icofont-lg skin-text-primary-dark"></i>
  </div>
  <div class="d-flex justify-content-between w-100 align-items-center">
    <span class="pl-2 pr-4">
      <p class="font-weight-400">
        {{ dependent_todo.name }}
      </p>
      <!-- <a class="text-break" *ngIf="task.taskTodoLink">{{task.taskTodoLink}}</a> -->
    </span>
  </div>
</div>
