import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
import { APICONSTANTS, INBOX } from "../constants";

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private _http: HttpClientService) { }

  getCommunicationToken(body) {
    return this._http.post(`${APICONSTANTS.COMMUNICATION_SECURITY}/access-token`, body).pipe(map((res: any) => {
      return res.data
    }))
  }

  getMessageList(route: string, queryParams?) {
    return this._http.get(`${APICONSTANTS.MESSAGES}/${route}`, queryParams)
      .pipe(map((res: any) => {
        return res.data;
      }));
  };

  getMessageThread(id, queryParams?) {
    return this._http.get(`${APICONSTANTS.MESSAGES}/${id}/threads`, { limit: 9999, ...queryParams }).pipe(map((res: any) => {
      return res.data;
    }));
  };

  getMessageTemplates() {
    return this._http.get(`${APICONSTANTS.MESSAGE_TEMPLATES}`).pipe(map((res: any) => {
      return res.data;
    }));
  };

  sendMessage(message) {
    const formData = new FormData();
    message.body && formData.append('body', message.body);
    message.receivers && formData.append('receivers', JSON.stringify(message.receivers));
    message.sender && formData.append('sender', JSON.stringify(message.sender));
    message.subject && formData.append('subject', message.subject);
    message.moduleOption && formData.append('moduleOption', JSON.stringify(message.moduleOption));
    message.messageId && formData.append('messageId', message.messageId);
    if (message.attachments && message.attachments.length) {
      for (let i = 0; i < message.attachments.length; i++) {
        formData.append('attachments', message.attachments[i]);
      }
    }
    return this._http.post(`${APICONSTANTS.MESSAGES}`, formData)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  generateOtp(body) {
    return this._http.post(`${APICONSTANTS.COMMUNICATION_SECURITY}/generate-otp`, body).pipe(map((res: any) => {
      return res.data;
    }))
  }

  verifyOtp(body) {
    return this._http.post(`${APICONSTANTS.COMMUNICATION_SECURITY}/verify-otp`, body).pipe(map((res: any) => {
      return res.data;
    }))
  }

  getTemplateData(lookupCode: string) {
    return this._http.get(`${APICONSTANTS.COMMUNICATION_LOOKUPS}/${lookupCode}?paramType=code`).pipe(map((res: any) => {
      return res.data;
    }))
  }

  getInboxUnreadCount() {
    return this._http.get(`${APICONSTANTS.MESSAGES}/${INBOX}/unread-count`).pipe(map((res: any) => {
      return res.data;
    }))
  }

  updateReadStatus(messageId: number) {
    const body = {
      isRead: true
    }
    return this._http.patch(`${APICONSTANTS.MESSAGES}/read-status?messageId=${messageId}`, body).pipe(map((res: any) => {
      return res.data;
    }))
  }
}