import { HighOccuranceProvidersItem } from './../../../../models/model.reports';
import { HIGH_OCCURRENCE_PROVIDER_DATA } from './../../../../constants/report.constant';
import { Component, OnInit, Input } from '@angular/core';
import { MessageConstants, NO_DATA_IN_REPORT } from 'src/app/constants';

@Component({
  selector: 'app-high-occurrence-providers',
  templateUrl: './high-occurrence-providers.component.html',
  styleUrls: ['./high-occurrence-providers.component.scss']
})
export class HighOccurrenceProvidersComponent implements OnInit {
  providers: HighOccuranceProvidersItem[];
  providerData = {
    data: [],
    excelName: '',
    tableTitle: '',
    tableHeaders: [],
    columnsWidth: [],
    dateType: '',
    date: {},
  };
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT)

  @Input() set highOccuranceProviders(highOccuranceProviders) {
    if (highOccuranceProviders !== null) {
      this.providers = highOccuranceProviders.referenceData;
      const { TABLE_HEADERS } = HIGH_OCCURRENCE_PROVIDER_DATA;
      this.providerData.tableHeaders = TABLE_HEADERS;
      this.providerData.data = this.providers;
    } else {
      this.providerData.data = [];
    }
  }
  constructor(
    private _messageConstants: MessageConstants
  ) { }

  ngOnInit() { }
}
