import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CommunicationService } from '../../services';
import { Storage } from '../../utils';
import { COMMUNICATIONS_OTP, COPYRIGHT_TEXT, EXISTING_MEMBER_DESC, HOLISTA_COMMUNICATION_DESC, OTP_VERIFICATION_DESC } from '../../constants';

@Component({
  selector: 'app-communications-otp',
  templateUrl: './communications-otp.component.html',
  styleUrls: ['./communications-otp.component.scss']
})
export class CommunicationsOtpComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  user: any;
  copyrightText: string = COPYRIGHT_TEXT;
  holistaCommunicationDesc: string = HOLISTA_COMMUNICATION_DESC;
  otpVerificationDesc: string = OTP_VERIFICATION_DESC;
  existingMemberDesc: string = EXISTING_MEMBER_DESC;

  constructor(
    private _router: Router,
    private _communicationService: CommunicationService,
    private _storage: Storage,
    private _auth: AuthService
  ) { }

  ngOnInit() {
    const encryptedMessageToken = this._storage.get('session', 'encryptedMessageToken');
    const decryptedData = this._auth.decryptMessageToken(encryptedMessageToken);
    this.user = JSON.parse(decryptedData);
  }

  onOtpVerification() {
    const body = { email: this.user.email }
    this._storage.set('session', 'user', this.user);
    this._communicationService.generateOtp(body).subscribe(res => {
      this._storage.set('session', 'generateOtpToken', res);
    });
    this._router.navigate([`/${COMMUNICATIONS_OTP}/otp-verification`]);
  }

  onExisitingMember() {
    this._router.navigate(['/login']);
  }
}
