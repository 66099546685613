import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { finalize } from "rxjs/operators";
import { AuthService, RolePermissionsService, ToasterService } from '../../services';
import { Storage, HolistaUtils } from '../../utils';


@Component({
  selector: 'sso-login',
  template: `
  <app-loader [loader]="loading"></app-loader>
  <div *ngIf="!isVerified" class="m-4">
      <div class="note note-info p-3 d-flex align-items-center mb-0 mt-3 w-100" role="alert">
          <i class="fas fa-exclamation-triangle fa-lg mr-2 text-info"></i>
          <span>
              <h3 class="mb-0">Something went wrong. Please try again.</h3>
          </span>
      </div>
  </div>
  `
})

export class SSOLoginComponent implements OnInit {
  return_url: string;
  loading: boolean = false;
  isVerified: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private _storage: Storage,
    private deviceService: DeviceDetectorService,
    private _toastr: ToasterService,
    private _holistaUtils: HolistaUtils,
    private rolePermissionService: RolePermissionsService,
  ) {
    this.return_url =
      this.activatedRoute.snapshot.queryParams['returnUrl'] || '';
  }

  ngOnInit() {
    let code = this.activatedRoute.snapshot.queryParamMap.get('code');
    if (code) {
      this.loading = true;
      this.authService.verifyCode({ code: code, isSSO: true }).subscribe(
        (response) => {
          const {
            isVerified,
            user: { logoutUrl },
          } = response.data;
          if (isVerified) {
            let user = {
              userId: response.data.user.userId,
              rememberMe: false,
              source: this.deviceService.isMobile() ? 'mobile' : 'web',
              browser: this.deviceService.browser,
              ssoFromLink: true
            };
            this.authService
              .ssoLogin(user)
              .pipe(
                finalize(() => {
                  this.loading = false;
                })
              )
              .subscribe(
                async (res: any) => {
                  if (res.data) {
                    res.data.user = { ...res.data.user, ...this._holistaUtils.getUserRoleInfo(res.data.user.roles) }
                    res.data.user.rolePermissions = await this.rolePermissionService.getRolePermission(res.data.user.roleCode).then((res: any) => {
                      return res.rows
                    })
                    this._storage.set('local', 'loggedInUser', {
                      ...res.data,
                      isSso: true,
                      logoutUrl: logoutUrl ? logoutUrl : '',
                    });
                    location.href = 'login';
                  }
                },
                (error) => {
                  this.loading = false;
                  this.isVerified = false;
                  this._toastr.showError(error);
                }
              );
          }
        },
        (error) => {
          this.loading = false;
          this.isVerified = false;
          this._toastr.showError(error);
        }
      );
    } else this.router.navigate(['/login']);
  }
}
