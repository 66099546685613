import { Injectable } from "@angular/core";
import { CometChat } from "@cometchat-pro/chat";
// import { CometChatCalls } from "@cometchat-pro/web-calls"
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Storage } from '../utils'
import { TruncatePipe } from "../pipes";
import { APICONSTANTS, COMETCHAT_USER } from '../constants';
@Injectable({
  providedIn: "root"
})
export class ChatService {
  user: any;
  chatInitialized = false;
  contacts: CometChat.UserObj[];
  constructor(private http: HttpClient,
    private _storage: Storage
  ) { }

  // // async init(appId: string = environment.cometChat_appId) {
  // //   // console.log('inside comet chat init', appId);
  // //   return await CometChat.init(appId).then(
  // //     msg => {
  // //       console.log('CometChat initialized succesfully.');
  // //       return true;
  // //     },
  // //     err => {
  // //       console.log('err', err);
  // //       return false;
  // //     }
  // //   );
  // // }

  // init(appId: string = environment.cometChat_appId) {
  //   return new Promise((resolve, reject) => {
  //     // console.log('inside comet chat init', appId);
  //     CometChat.init(appId).then(
  //       msg => {
  //         console.log('CometChat initialized succesfully.');
  //         resolve(true);
  //       },
  //       err => {
  //         console.log('err', err);
  //         reject(false);
  //       }
  //     );
  //   })
  // cometChatv3Setup = () => {
  //   const callAppSetting = new CometChatCalls.CallAppSettingsBuilder()
  //     .setAppId(environment.cometChat_appId)
  //     .setRegion(environment.cometChat_region)
  //     .build();

  //   CometChatCalls.init(callAppSetting).then(
  //     () => {
  //       console.log('CometChatCalls initialization completed successfully');
  //     },
  //     error => {
  //       console.log('CometChatCalls initialization failed with error:', error);
  //     },
  //   );
  // }
  async init(appId: string = environment.cometChat_appId) {
    return new Promise((resolve, reject) => {
      let cometChatSettings = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion('us').build();
      CometChat.init(appId, cometChatSettings).then(
        msg => {
          // console.log('CometChat initialized succesfully.');
          // return true;
          resolve(true);

        },
        err => {
          console.log('err', err);
          return false;
        }
      );
    })
  }

  getStatus(id) {
    const chatloggedInUser = this._storage.get('local', 'chatLoggedInUser')
    return new Promise((resolve, reject) => {
      if (this.user && chatloggedInUser) {
        // console.log('get status if inside user', this.user);
        resolve('success');
      }
      // else if (chatloggedInUser) {
      //   this.init()
      //     .then(() => {
      //       CometChat.login(chatloggedInUser)
      //         .then(() => {
      //           resolve('success');
      //         },
      //           error => (console.log(error)));
      //     })
      //     .catch((err) => {
      //       reject('fail');
      //     });
      // }

      else {
        // this.cometChatv3Setup();
        // console.log('get status else inside user', this.user);
        this.init()
          .then(() => {
            this.login(id)
              .then(() => {
                resolve('success');
              });
          })
          .catch((err) => {
            reject('fail');
          });
      }
    });
  }

  login(userId: string) {
    // console.log('user comet login',userId);
    // console.log('comet chat login userId', this.chatInitialized, this.user);
    return new Promise((resolve, reject) => {
      if (!this.chatInitialized && !this.user) {
        // console.log('inside if of login', environment.cometChat_apiKey);
        let apiKey = environment.cometChat_apiKey;
        CometChat.login(userId, apiKey)
          .then(usr => {
            this.chatInitialized = true;
            // console.log('Chat Login success', usr);
            this.user = usr;
            this._storage.set('local', 'chatLoggedInUser', usr.authToken);
            (resolve(usr));
            // console.log(usr, 'cometchat')
          }, err => {
            console.log('Chat Login failed', err);
            reject(err);
          })
      } else {
        // console.log('inside else of login');
        (resolve(this.user));
      }
    });
  }


  fetchUsers() {
    return new Promise(async (resolve, reject) => {
      new CometChat.UsersRequestBuilder()
        .setLimit(1000)
        .build()
        .fetchNext()
        .then((users: any) => {
          this.contacts = users as CometChat.UserObj[];
          resolve(users);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  searchUsers(searchkey: string) {
    return new Promise(async (resolve, reject) => {
      new CometChat.UsersRequestBuilder()
        .setLimit(30)
        .setSearchKeyword(searchkey)
        .build()
        .fetchNext()
        .then((users: any) => {
          resolve(users);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  sendMessage(receiverId: string, text: string) {
    const message = new CometChat.TextMessage(
      receiverId,
      text,
      CometChat.RECEIVER_TYPE.USER
    );
    return CometChat.sendMessage(message);
  }

  sendFile(receiverId: string, file: File) {
    const messageIle = new CometChat.MediaMessage(
      receiverId,
      file,
      CometChat.MESSAGE_TYPE.FILE,
      CometChat.RECEIVER_TYPE.USER
    );
    return CometChat.sendMediaMessage(messageIle);
  }

  deleteMessage(messageId) {
    CometChat.deleteMessage(messageId).then(
      message => {
        // console.log('Message deleted', message);
      },
      error => {
        console.log('Message delete failed with error:', error);
      }
    );
  }

  listenForMessages(
    listenerId: string,
    onMessageReceived: (msg: any) => void,
    OnTypingStatus: (type: any) => void,
    onTypingEndStatus: (type: any) => void,
    onMediaMessage: (file: any) => void,
    onDeliveredMessage: (msg: any) => void,
    onReadMessage: (msg: any) => void,
    onDeleteMessage: (msg: any) => void,
  ) {
    CometChat.addMessageListener(
      listenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: onMessageReceived,
        onMediaMessageReceived: onMediaMessage,
        onTypingStarted: OnTypingStatus,
        onTypingEnded: onTypingEndStatus,
        onMessagesDelivered: onDeliveredMessage,
        onMessagesRead: onReadMessage,
        onMessageDeleted: onDeleteMessage
      })
    );
  }

  trackOnlineStatus(listenerId: string) {
    return CometChat.addUserListener(listenerId, {
      onUserOffline: usr => this.setUserStatus(usr, "offline"),
      onUserOnline: usr => this.setUserStatus(usr, "online")
    });
  }

  setUserStatus(usr: CometChat.UserObj, status: string) {
    if (!this.contacts) {
      return;
    }
    const userToUpdate = this.contacts.find(c => c.uid === usr.uid);
    if (userToUpdate) {
      userToUpdate.status = status;
    }
  }

  fetchUserMessagesWhenOffline(uid) {
    var messagesRequest = new CometChat.MessagesRequestBuilder()
      .setLimit(100)
      .setUID(uid)
      .setUnread(false)
      .setMessageId(867)
      .build();
    messagesRequest.fetchNext().then(
      messages => {
      },
      error => {
        console.log("Message fetching failed with error:", error);
      }
    );
  }

  getUnreadMessageCount() {
    return new Promise(async (resolve, reject) => {
      const unreadMessages = CometChat.getUnreadMessageCount()
        .then(array => {
          resolve(array);
        }, error => {
          reject(error);
          console.log('Error in getting message count', error);
        });
      return unreadMessages;
    });
  }

  initiateCall(receiverId, type) {
    var receiverID = receiverId;
    var callType =
      type == "VIDEO" ? CometChat.CALL_TYPE.VIDEO : CometChat.CALL_TYPE.AUDIO;
    var receiverType = CometChat.RECEIVER_TYPE.USER;

    var call = new CometChat.Call(receiverID, callType, receiverType);

    return CometChat.initiateCall(call);
  }

  acceptCall(sessionId) {
    CometChat.acceptCall(sessionId).then(
      call => {
        // console.log("Call accepted successfully:", call);
        // start the call using the startCall() method
      },
      error => {
        console.log("Call acceptance failed with error", error);
        // handle exception
      }
    );
  }

  startCall(sessionId) {
    CometChat.startCall(
      sessionId,
      document.getElementById("callScreen"),
      new CometChat.OngoingCallListener({
        onUserJoined: user => {
          /* Notification received here if another user joins the call. */
          // console.log("User joined call:", user);
          /* this method can be use to display message or perform any actions if someone joining the call */
        },
        onUserLeft: user => {
          /* Notification received here if another user left the call. */
          // console.log("User left call:", user);
          /* this method can be use to display message or perform any actions if someone leaving the call */
        },
        onCallEnded: call => {
          /* Notification received here if current ongoing call is ended. */
          // console.log("Call ended:", call);
          /* hiding/closing the call screen can be done here. */
        }
      })
    );
  }

  listenForCall(
    listnerId: string,
    incomingCall: (call: any) => void,
    cancelledCall: (call: any) => void,
    acceptedCall: (accept: any) => void,
    rejectedCall: (reject: any) => void
  ) {
    CometChat.addCallListener(
      listnerId,
      new CometChat.CallListener({
        onIncomingCallReceived: incomingCall,
        onOutgoingCallAccepted: acceptedCall,
        onOutgoingCallRejected: rejectedCall,
        onIncomingCallCancelled: cancelledCall
      })
    );
  }

  updateUser(body) {
    let header = this.createHeader();
    return this.http.put(
      `${COMETCHAT_USER}/${body.uid}`,
      body,
      {
        headers: header
      }
    );
  }

  removeListener(listenerId: string) {
    CometChat.removeMessageListener(listenerId);
  }

  conversationsRequest = new CometChat.ConversationsRequestBuilder()
    .setLimit(20)
    .withUserAndGroupTags(true)
    .build()

  getPreviousConversation() {
    return new Promise(async (resolve, reject) => {
      this.conversationsRequest.fetchNext().then(
        (conversationList: any) => {
          resolve(conversationList);
        })
        .catch(error => {
          reject(error);
        });
      return this.conversationsRequest;
    });
  }

  getPreviousMessages(uid: string) {
    return new Promise(async (resolve, reject) => {
      const messageRequest = new CometChat.MessagesRequestBuilder()
        .setLimit(100)
        .setUID(uid)
        .build()
        .fetchPrevious()
        .then(messages => {
          resolve(messages);
        })
        .catch(error => {
          reject(error);
        });
      return messageRequest;
    });
  }

  userCreate(body) {
    let header = this.createHeader();
    return this.http.post(`${COMETCHAT_USER}`, body, {
      headers: header
    });
  }
  getUser(uid) {
    let header = this.createHeader();
    return this.http.get(`${COMETCHAT_USER}/${uid}`, { headers: header }).toPromise();
  }

  createHeader() {
    let headers;
    headers = new HttpHeaders()
      .set("accept", "application/json")
      .set("content-type", "application/json")
      .set("apikey", environment.cometChat_apiKey)
    return headers;
  }

  logout() {
    if (this.user)
      CometChat.logout(this.user.authToken)
        .then(() => {
          this.chatInitialized = false;
          this.user = null;
          // console.log('Logged out successfully');
        }, error => {
          console.log('Logout failed with exception:', { error });
        });
    else this.chatInitialized = false
  }
}
