import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { KPI_VALUES, LINE_CHART_DATA_CONFIG, LINE_CHART_OPTIONS, MONTHS_NAME_FULL, MessageConstants, NO_DATA_IN_REPORT, REPORT_NAME_CODES, REPORT_NAME_TYPES, REPORT_TAB_INDEX } from '../../../../constants';
import { ReportService } from '../../../../services';
import { ChartUtils, DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility } from '../../../../utils';
@Component({
  selector: 'app-total-cycle-time',
  templateUrl: './total-cycle-time.component.html',
  styleUrls: ['./total-cycle-time.component.scss']
})
export class TotalCycleTimeComponent implements OnInit {

  totalCycleTimeForm: FormGroup;
  loading = {
    totalCycleTime: false,
  }
  expand = {
    totalCycleTime: false,
  }
  baseConfigs = {
    options: {},
    plugins: [],
    labels: [],
    dataset: [],
    chartType: '',
    actualData: null,
  };
  totalCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT);
  REPORT_NAME_TYPES = REPORT_NAME_TYPES;
  REPORT_NAME_CODES = REPORT_NAME_CODES;

  parsedClientClaimsFilterData: any;
  toShowReport: any[] = [];
  reportNameAndCodeList: any[] = [];
  yearOptions = {
    totalCycleTime: [],
  };

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();

  @Input() set clientClaimsDashboardFilterData(data) {
    if (data && data.activeTabIndex === REPORT_TAB_INDEX.TOTAL_CYCLE_TIME) {
      this.expand = {
        totalCycleTime: false,
      };
      this.parsedClientClaimsFilterData = this._holistaUtils.deepClone(data);
      const { networkCode, endDate, toShowReport } = this.parsedClientClaimsFilterData;
      this.toShowReport = toShowReport && toShowReport.map((x) => x.reportCode);
      this.reportNameAndCodeList = toShowReport && toShowReport.map((x) => ({
        reportName: x.customReportName,
        reportCode: x.reportCode
      }));
      const year = +this._dateUtility.getYearFromDate(endDate);
      const params = {
        networkCode,
        year
      };
      const additionalConditons = toShowReport.length && networkCode && year;
      if (this.toShowReport.includes(REPORT_NAME_CODES.TOTAL_CYCLE_TIMES) && additionalConditons) {
        this.getTotalCycleTime(params);
      }
    }
  }
  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === REPORT_TAB_INDEX.TOTAL_CYCLE_TIME) {
      const { networkName } = this.parsedClientClaimsFilterData;
      const tabsData = [];
      const downloadPDF = this._pdfUtility.convertToPDF(['total-cycle-time'], 'Client Claims Dashboard - Total Cycle Time', true, true);
      if (this.toShowReport.includes(REPORT_NAME_CODES.TOTAL_CYCLE_TIMES) && this.totalCycleTimeChart.labels.length) {
        const body = this._reportMappingUtils.lineChartLabelAndDataMapper(this.totalCycleTimeChart.actualData);
        tabsData.push({
          clientName: networkName,
          reportName: 'Total Cycle Time',
          tabName: 'Total Cycle Time',
          headers: ['Year', ...MONTHS_NAME_FULL],
          data: body,
          reportDate: `Period: ${this.totalCycleTimeForm.value.selectedYear}`,
        });
      }
      if (isDownloadPDF.type === 'pdf') {
        downloadPDF.finally(() => {
          this.downloadedPDF.emit(true);
        });
      }
      if (tabsData.length && isDownloadPDF.type === 'excel') {
        this._excelMultiTabUtility.exportToExcelMultiTab('Client Claims Dashboard - Total Cycle Time', tabsData).finally(() => {
          this.downloadedPDF.emit(true);
        })
      }
    }
  }

  constructor(
    private _messageConstants: MessageConstants,
    private _pdfUtility: PDFUtility,
    private _dateUtility: DateUtility,
    private _holistaUtils: HolistaUtils,
    private _chartUtils: ChartUtils,
    private reportService: ReportService,
    private formBuilder: FormBuilder,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
    private _reportMappingUtils: ReportMappingUtility,
  ) { }

  ngOnInit(): void {
    this.setPayorCycleTimesCompanyForm();
  }

  ngOnDestroy() {
    this.resetFlags();
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
  }

  resetFlags() {
    this.loading = {
      totalCycleTime: false,
    }
  }

  resetAllReports() {
    this.totalCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  getReport(code: string) {
    return this.reportNameAndCodeList.find(report => report.reportCode === code) || null;
  }

  setPayorCycleTimesCompanyForm() {
    this.totalCycleTimeForm = this.formBuilder.group({
      selectedYear: [null],
    });
  }

  getTotalCycleTime(params?, isYearSelected = false) {
    this.loading.totalCycleTime = true;
    this.reportService.getTotalCycleTimes(params)
      .pipe(finalize(() => {
        this.loading.totalCycleTime = false;
        const { startDate, endDate } = this.parsedClientClaimsFilterData;
        const startYear = +this._dateUtility.getYearFromDate(startDate);
        const endYear = +this._dateUtility.getYearFromDate(endDate);
        if (startYear && endYear) {
          this.yearOptions.totalCycleTime = this._holistaUtils.getYearOptionList(startYear, endYear);
        }
        this.totalCycleTimeForm.controls['selectedYear'].setValue(isYearSelected ? params.year : endYear);
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.totalCycleTimeChart.actualData = res;
          this.yearOptions.totalCycleTime = [];
          res = {
            ...res,
            datasets: res.dataSets.map((dataset) => {
              return {
                ...LINE_CHART_DATA_CONFIG,
                label: dataset.label,
                data: dataset.datas,
              }
            })
          };
          this.totalCycleTimeChart.chartType = 'line';
          this.totalCycleTimeChart.dataset = res.datasets;
          this.totalCycleTimeChart.labels = res.labels;
          this.totalCycleTimeChart.options = LINE_CHART_OPTIONS;
          this.totalCycleTimeChart.plugins = this._chartUtils.displayHorizontalLine("KPI:", KPI_VALUES.TOTAL_CYCLE_TIMES);
        } else {
          this.totalCycleTimeChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Total Cycle Times", error);
      })
  }

  onViewToggle(reportCode, expand = false) {
    // this.reportExpanded.emit(expand); // Uncomment if download is not needed while expanding
    switch (reportCode) {
      case REPORT_NAME_CODES.TOTAL_CYCLE_TIMES:
        this.expand.totalCycleTime = expand;
        break;
      default:
        break;
    }
  }

  yearSelected(event, reportCode) {
    const params = {
      year: event.value,
      networkCode: this.parsedClientClaimsFilterData.networkCode,
    };
    switch (reportCode) {
      case REPORT_NAME_CODES.TOTAL_CYCLE_TIMES:
        this.getTotalCycleTime(params, true);
        break;
      default:
        break;
    }
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = (
      this.totalCycleTimeChart.labels.length
    );
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

}
