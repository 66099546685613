import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestEocService } from 'src/app/services/request-eoc.service';
import * as HeaderBreadCrumbActions from '../../action'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ADD_SUCCESS, LOGCONSTANTS, UPDATE_SUCCESS } from 'src/app/constants';
import { DateUtility } from 'src/app/utils';
import { ToasterService } from 'src/app/services';

@Component({
  selector: 'app-request-eoc',
  templateUrl: './request-eoc.component.html',
  styleUrls: ['./request-eoc.component.scss']
})
export class RequestEocComponent implements OnInit {
  requestEoc: any = [];
  private searchTerms = new Subject<string>();
  @ViewChild('advanceSearchModal', { static: true }) public advanceSearchModal;
  @ViewChild('noteModal', { static: true }) public noteModal;

  page = 1;
  allEoc = [];
  filterList = [];
  appliedFilter = [];
  userName = '';
  backupEocList: [];
  filterContact: any = [];
  filterEoc = '';
  totalCount = 0;
  lastName = '';
  selectedEoc = { note: '' };
  displayName = '';
  note = '';
  filterStatus = [];
  filterForm: FormGroup;
  backUpFilterStatus = [];
  searchUsers = [];
  searchEoc: any = [];
  searchTerm = '';
  searchKeyword = '';
  limit = 10;
  showSpinner = false;
  allStatus = [...LOGCONSTANTS.eoc_status_options]
  contactViaList = [...LOGCONSTANTS.contact_via_options]

  constructor(private requestEocService: RequestEocService, private formBuilder: FormBuilder, public dateUtility: DateUtility, private toastr: ToasterService,
    private store: Store<{
      bread_crumbs: any
      chatUsers: any
      enableChat: boolean
    }>,) {
    this.filterForm = this.formBuilder.group({
      filteredStatus: [[]],
      filteredContact: [[]]
    });

    this.searchTerms
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        switchMap((term) => this.requestEocService.getEocRequestMembers({
          limit: this.limit,
          page: this.page,
          searchTerm: this.searchTerm.trim(),
          status: this.filterStatus,
          eocName: this.filterEoc,
          contactVia: this.filterContact,
          firstName: this.userName,
          lastName: this.lastName,
          sortBy: { createdAt: 'DESC' },
        }))
      )
      .subscribe((results) => {
        this.showSpinner = false;
        results.rows.forEach(eachEoc => {
          if (eachEoc.status) {
            const d = this.allStatus.filter(d => {
              return d.value.toLowerCase() !== eachEoc?.status.toLowerCase()
            })
            eachEoc.statusList = d;
          } else {
            eachEoc.statusList = []
          }
        })
        this.requestEoc = results.rows;
        this.totalCount = results.count;
        this.showSpinner = false
        this.requestEoc.forEach((eachEoc: any) => {
          eachEoc?.eocMember[0].dob;
          eachEoc.eocMember[0].dob = this.dateUtility.getFormattedDate(eachEoc?.eocMember[0].dob)
        })

      }, (error) => {
        this.showSpinner = false;
        console.log("Error getting Networks", error);
      })
  }

  ngOnInit(): void {
    this.showSpinner = true;
    this.store.dispatch(new HeaderBreadCrumbActions.ResetBreadCrumb())
    this.store.dispatch(
      new HeaderBreadCrumbActions.AddBreadCrumb({
        name: 'Request EOC',
        path: '/request-eoc'
      })
    )
    this.getAllRequestEoc()
    this.getRequestEoc()
  }

  hideStatus(status) {
    const newStatus: any = this.allStatus.find((d: any) => {
      return d.label !== status.label
    })
    return newStatus.isHidden = true;
  }

  toTitleCase(name) {
    if (name.toUpperCase() === 'EOC-CREATED') {
      let string = name.split('-')
      string[1] = string[1].charAt(0).toUpperCase() + string[1].substr(1).toLowerCase();
      return string.join(' ')
    }
    return name.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  searchUserNameByPartialName(searchStr, field) {
    if (typeof searchStr == 'string') {
      const searchLowerCase = searchStr.toLowerCase();
      const listOfUsername = this.allEoc.filter((obj) => {
        const fullName = (obj?.eocMember[0].firstName + ' ' + obj.eocMember[0].lastName).toLowerCase();
        const reversedFullName = (obj.eocMember[0].lastName + ' ' + obj.eocMember[0].firstName).toLowerCase();
        return fullName.includes(searchLowerCase) || reversedFullName.includes(searchLowerCase);
      });
      const uniqueEocUsername = [];
      for (const member of listOfUsername) {
        for (const eachMember of member.eocMember) {
          if (!uniqueEocUsername.some(uniqueEoc => uniqueEoc.id === eachMember.id)) {
            uniqueEocUsername.push(eachMember);
          }
        }
      }
      return uniqueEocUsername
    }
  }

  searchObjectsByPartialName(searchStr, field) {
    if (typeof searchStr == 'string') {
      const searchLowerCase = searchStr.toLowerCase();
      const listOfEocName = this.allEoc.filter((obj) => {
        const searchText = obj.eocReference[0]?.bundleDisplayName ? obj.eocReference[0]?.bundleDisplayName?.toLowerCase() : '';
        return searchText.includes(searchLowerCase);
      });

      const uniqueEocReferences = [];
      for (const member of listOfEocName) {
        for (const eocRef of member.eocReference) {
          if (!uniqueEocReferences.some(uniqueEoc => uniqueEoc.id === eocRef.id)) {
            uniqueEocReferences.push(eocRef);
          }
        }
      }
      return uniqueEocReferences
    }
  }

  userSearch(key) {
    this.searchUsers = this.searchUserNameByPartialName(key, 'firstName');
  }

  eocSearch(key) {
    this.searchEoc = this.searchObjectsByPartialName(key, 'eocName');
  }

  onResetFilter() {
    this.filterList = []
    this.filterContact = [];
    this.filterStatus = [];
    this.appliedFilter = [];
    this.userName = '';
    this.lastName = '';
    this.displayName = '';
    this.filterEoc = '';
    this.showSpinner = true
    this.getRequestEoc()
  }

  displayUserValue(user: any): string {
    this.userName = user.firstName
    return `${user?.firstName} ${user?.lastName}`;
  }
  displayEocValue(eoc) {
    this.filterEoc = eoc.bundleDisplayName
    return eoc.bundleDisplayName
  }

  onSearchResultSelected(value, field) {
    if (field == 'userName') {
      this.displayName = value?.text?.firstName + ' ' + value.text?.lastName;
      this.userName = value?.text?.firstName;
      this.lastName = value?.text?.lastName;
      const contactViaIndex = this.filterList.findIndex(item => item.label === 'name');
      if (contactViaIndex !== -1) {
        this.filterList[contactViaIndex].value = value?.text?.firstName + ' ' + this.lastName;
      } else {
        this.filterList.push({ label: 'name', value: value?.text?.firstName + ' ' + this.lastName });
      }
    }
    else if (field == 'eocName') {
      this.filterEoc = value.text?.bundleDisplayName
      const contactViaIndex = this.filterList.findIndex(item => item.label === 'eocName');
      if (contactViaIndex !== -1) {
        this.filterList[contactViaIndex].value = this.filterEoc
      } else {
        this.filterList.push({ label: 'eocName', value: this.filterEoc });
      }
    }
  }

  removeFilter(filter) {
    switch (filter.label) {
      case 'status':
        const filterStatusClone = [...this.filterForm.value.filteredStatus];
        const indexOfStatus = filterStatusClone.findIndex(d => d === filter.value);
        if (indexOfStatus !== -1) {
          filterStatusClone.splice(indexOfStatus, 1);
        }
        this.filterStatus = filterStatusClone
        this.filterForm.value.filteredStatus = this.filterStatus
        break;
      case 'contactVia':
        const filterContactClone = [...this.filterForm.value.filteredContact];
        const indexOfContact = filterContactClone.findIndex(d => d === filter.value);
        if (indexOfContact !== -1) {
          filterContactClone.splice(indexOfContact, 1);
        }
        this.filterContact = filterContactClone;
        this.filterForm.value.filteredContact = this.filterContact
        break;
      case 'name':
        this.displayName = '';
        this.userName = '';
        this.lastName = '';
        break;
      case 'eocName':
        this.filterEoc = '';
        break
    }
    this.showSpinner = true;
    this.filterList = this.filterList.filter(d => d.value !== filter.value)
    this.appliedFilter = [...this.filterList]
    this.getRequestEoc()
  }

  openAdvanceSearch() {
    this.filterForm.reset();
    this.filterForm.patchValue({ filteredStatus: this.filterStatus })
    this.filterForm.patchValue({ filteredContact: this.filterContact })

    this.advanceSearchModal.show()
  }
  closeFilterModal(val) {
    this.filterForm.value.filteredStatus = this.filterStatus
    this.filterForm.value.filteredContact = this.filterContact
    this.advanceSearchModal.hide();
  }
  clearSearch() {
    this.searchTerm = '';
    this.searchKeyword = '';
    this.page = 1;
    this.getRequestEoc()
  }
  onApplyFilter() {
    const { filteredStatus, filteredContact } = this.filterForm.value;
    this.filterStatus = filteredStatus;
    this.filterContact = filteredContact
    this.filterForm.controls.filteredStatus.setValue(filteredStatus);
    this.filterForm.controls.filteredContact.setValue(filteredContact);

    let updatedFilterList = [...this.filterList]
    updatedFilterList = updatedFilterList.filter(d => d?.label !== 'status' && d?.label !== 'contactVia');

    if (filteredStatus.length) {
      filteredStatus.forEach(value => {
        updatedFilterList.push({ label: 'status', value });
      });
    }
    if (filteredContact.length) {
      filteredContact.forEach(value => {
        updatedFilterList.push({ label: 'contactVia', value });
      }
      );
    }

    this.filterList = updatedFilterList;
    this.advanceSearchModal.hide();
    this.page = 1;
    this.appliedFilter = [...this.filterList];
    this.filterForm.value.filteredStatus = this.filterStatus;
    this.filterForm.value.filteredContact = this.filterContact;
    this.getRequestEoc();
  }

  searchRequestEoc(searchKey) {
    this.showSpinner = true
    this.searchTerm = searchKey
    this.page = 1;
    this.searchTerms.next(searchKey);
  }

  statusChanged(status, eoc) {
    const payload = { status: status.value }
    this.requestEocService.updateStatus(payload, eoc.id).subscribe(d => {
      this.toastr.displaySuccess(UPDATE_SUCCESS)
      const index = this.requestEoc.findIndex((eachEoc: any) => eachEoc.id == eoc.id);
      this.requestEoc[index].date =
        this.requestEoc[index].statusList = this.allStatus.filter(d => d.value !== status.value)
      this.requestEoc[index] = { ...this.requestEoc[index], ...d };
    }, (err) => {
    })
  }


  pageChanged(event) {
    this.page = event;
    this.getRequestEoc()
  }

  getAllRequestEoc() {
    const body = {
      limit: 0,
      searchTerm: this.searchTerm,
      status: this.filterStatus,
      eocName: this.filterEoc,
      contactVia: this.filterContact,
      firstName: this.userName,
      lastName: this.lastName,
      sortBy: { createdAt: 'DESC' }
    }
    this.requestEocService.getEocRequestMembers(body)
      .subscribe((res: any) => {
        this.allEoc = res.rows;
        this.allEoc.forEach((eachEoc: any) => eachEoc.eocMember[0].dob = this.dateUtility.getFormattedDate(eachEoc.eocMember[0].dob))
      }, (error) => {
        console.log("Error getting Networks", error);
      })
  }

  onAddNote(note?) {
    const payload = { note: this.note.trim() }
    this.requestEocService.updateStatus(payload, this.noteModal.eocData.id).subscribe(res => {
      this.toastr.displaySuccess(note ? UPDATE_SUCCESS : ADD_SUCCESS)
      const index = this.requestEoc.findIndex((eachEoc: any) => eachEoc.id == this.noteModal.eocData.id);
      this.requestEoc[index] = { ...this.requestEoc[index], ...res };
    }, (err) => {
    })
    this.noteModal.hide()
  }
  changeNote(event) {
    this.note = event;
  }
  openNoteAdd(eachEoc) {
    this.note = eachEoc.note;
    this.selectedEoc = eachEoc
    this.noteModal.eocData = eachEoc
    this.noteModal.show()
  }

  closeNoteModal(val) {
    this.noteModal.hide();
    this.noteModal.eocData = null
  }
  getRequestEoc() {
    this.showSpinner = true
    const body = {
      limit: this.limit,
      page: this.page,
      searchTerm: this.searchTerm,
      status: this.filterStatus,
      eocName: this.filterEoc,
      contactVia: this.filterContact,
      firstName: this.userName,
      lastName: this.lastName,
      sortBy: { createdAt: 'DESC' }
    }
    this.requestEocService.getEocRequestMembers(body)
      .subscribe((res: any) => {
        res.rows.forEach(eachEoc => {
          if (eachEoc.status) {
            const d = this.allStatus.filter(d => {
              return d.value.toLowerCase() !== eachEoc?.status.toLowerCase()
            })
            eachEoc.statusList = d;
          } else {
            eachEoc.statusList = []
          }
        })
        this.requestEoc = res.rows
        this.totalCount = res.count
        this.showSpinner = false
        this.backupEocList = this.requestEoc;
      }, (error) => {
        this.showSpinner = false;
        console.log("Error getting Networks", error);
      })
  }
}
