<section class="container">
  <div class="mt-4">
    <form [formGroup]="paymentForm">
      <div
        *ngIf="!isViewPayment && !isEditPayment"
        class="row"
        style="display: flex; align-items: center; justify-content: space-between">
        <div class="col form-group" style="width: 100%">
          <label for="claimIdentifier">Claim Identifier</label>
          <input
            type="text"
            [ngModel]="claimIdentifierSearchText | async"
            id="claimIdentifier"
            (ngModelChange)="claimIdentifierSearchText.next($event)"
            [ngModelOptions]="{standalone: true}"
            (change)="searchClaimIdentifierChange()"
            class="completer-input form-control mdb-autocomplete mb-0"
            autocomplete="off"
            [mdbAutoCompleter]="auto3"
            placeholder="Type to select claim ID"
            [ngClass]="{
              'error-select':
                submitted &&
                paymentForm.controls?.claimPayments['controls'][0]?.get('patientAccountNumber')
                  .invalid
            }"
            [attr.disabled]="isViewPayment || isEditPayment || isAddPayment ? '' : null" />
          <mdb-auto-completer
            #auto3="mdbAutoCompleter"
            textNoResults="No result found"
            [displayValue]="displayClaimIdentifier"
            [optionHeight]="40"
            class="completer-dropdown-holder-payment-new"
            (selected)="onClaimIdentifierSelected($event)">
            <mdb-option *ngFor="let option of claimIdentifier_results | async" [value]="option">
              <div class="d-flex flex-column">
                <strong
                  >{{ option.patientAccountNumber }} -
                  {{ option.totalClaimChargeAmount | currency }}</strong
                >
              </div>
            </mdb-option>
          </mdb-auto-completer>
        </div>
        <label class="mt-4">OR</label>
        <div class="col form-group" style="width: 100%">
          <label for="providerName">Search Payee</label>
          <input
            type="text"
            [ngModel]="payeesSearchText | async"
            (ngModelChange)="payeesSearchText.next($event)"
            required
            [ngModelOptions]="{standalone: true}"
            class="completer-input form-control mdb-autocomplete mb-0"
            [mdbAutoCompleter]="autoProvider"
            placeholder="Search by payee name or tax ID"
            autocomplete="off"
            [attr.disabled]="isViewPayment || isEditPayment || isAddPayment ? '' : null" />
          <!-- [ngClass]="{
                    'error-select': submitted && paymentForm.get('payeeId').invalid
                  }" -->
          <mdb-auto-completer
            #autoProvider="mdbAutoCompleter"
            textNoResults="No Payee found"
            class="completer-dropdown-holder-payment-new"
            (selected)="onPayeeSelected($event)"
            [displayValue]="onPayeeDisplayValue">
            <mdb-option
              class="border-bottom"
              *ngFor="let payee of payeesFound$ | async"
              [value]="payee">
                <div class="d-flex flex-column" style="margin-right: 50px;">
                  <strong>{{ payee.name }}</strong>
                  <span>
                    located in {{ payee.city }}{{ payee.state ? ',' : '' }} {{ payee.state }}</span
                  >
                  <small> 
                    <span class="mr-2">(T){{ payee.tin }}</span>
                    <span class="z-depth-0 badge badge-{{payee.isActive ? 'success' : 'danger'}}">
                      {{ payee.isActive ? 'Active' : 'Inactive' }}
                    </span>
                  </small> 
                
                </div>
            </mdb-option>
          </mdb-auto-completer>
        </div>
      </div>
      <hr *ngIf="!isViewPayment && !isEditPayment" />
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="paymentMode">Payment Mode *</label>
            <mdb-select
              [options]="paymentModeList | sort : 'label'"
              id="paymentMode"
              formControlName="paymentMode"
              placeholder="Select payment mode"
              [ngClass]="{
                'error-select': submitted && paymentForm.get('paymentMode').invalid,
                'pointer-disabled': paymentForm.get('paymentMode').value
              }"
              [disabled]="isViewPayment || isEditPayment">
            </mdb-select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="paymentModeTraceNumber">Payment Mode Trace Number</label>
            <input
              type="text"
              id="paymentModeTraceNumber"
              class="form-control"
              formControlName="paymentModeTraceNumber"
              mdbInput
              [ngClass]="{
                'is-invalid': submitted && paymentForm.get('paymentModeTraceNumber').invalid
              }"
              [readonly]="
                isViewPayment ||
                loading.saving ||
                (selectedClaims.length && totalAllowedAmount === 0) ||
                (isEditPayment && totalAllowedAmount === 0)
              " />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="paymentDate">Payment Date *</label>
            <input
              type="text"
              id="paymentDate"
              class="form-control"
              formControlName="paymentDate"
              placeholder="MM/DD/YYYY"
              [textMask]="{mask: dateMask}"
              [ngClass]="{
                'is-invalid': submitted && paymentForm.get('paymentDate').invalid
              }"
              [readonly]="isViewPayment || isEditPayment" />
          </div>
        </div>
      </div>
      <p class="my-3"><b>Payment Information</b></p>
      <div class="row">
        <div class="col-6">
          <table>
            <tbody>
              <tr>
                <td><strong>Payor</strong></td>
              </tr>
              <tr>
                <td>
                  <p class="m-0">
                    <label>{{ paymentForm.value.payorName }}</label>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <table class="w-100">
            <tbody>
              <tr>
                <td>
                  <strong>Payee</strong>
                  <span
                    class="spinner-grow spinner-grow-sm search-dropdown"
                    role="status"
                    aria-hidden="true"
                    *ngIf="loading.payeeDetail"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <ng-template #noPayee>
                    <p class="mb-0">
                      <label>No Payee found</label>
                      <!-- <a (click)="handleEditPayeeInfo()"
                        ><i class="icofont-edit-alt ml-2"></i> Add</a
                      > -->
                    </p>
                  </ng-template>
                  <div>
                    <p class="mb-0">
                      <label>{{ payeeForm.value.name }}</label>
                      <!-- <a
                        *ngIf="!isViewPayment && !loading.saving && claimList?.data.length"
                        (click)="handleEditPayeeInfo()"
                        ><i class="icofont-edit-alt ml-2"></i> Edit</a
                      > -->
                    </p>
                    <div
                      *ngIf="
                        payeeForm.value.addressLine1 ||
                        payeeForm.value.city ||
                        payeeForm.value.state ||
                        payeeForm.value.taxId
                      "
                      class="d-flex flex-column pb-3">
                      <small *ngIf="payeeForm.value.addressLine1">
                        located in {{ payeeForm.value.addressLine1 }}
                        {{ payeeForm.value.addressLine2 }}
                      </small>
                      <small>
                        {{ payeeForm.value.city }} {{ payeeForm.value.state }}
                        {{ payeeForm.value.zip }}</small
                      >
                      <small>{{
                        payeeForm.value.taxId ? '(T) ' + payeeForm.value.taxId : ''
                      }}</small>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <app-loader [loader]="loading.claims || loading.detail"></app-loader>
      <table
        *ngIf="claimList?.length"
        id="datatable"
        class="datatable mb-3 position-relative"
        bordered="true"
        hover="true"
        mdbTable>
        <thead class="bg-light">
          <tr>
            <th width="20"></th>
            <th class="fs-6">Facility</th>
            <th class="fs-6" width="20">Total</th>
          </tr>
        </thead>
        <tbody *ngFor="let claims of claimList" class="bg-white">
          <tr>
            <td (click)="facilityPaymentCollapsed(claims)">
              <button
                [attr.data-test-id]="'facility-button-expand'"
                type="button"
                class="btn btn-link p-0 mt-0"
                mdbWavesEffect
                *ngIf="!claims.collapsed">
                <i class="icofont-minus-circle icofont-lg text-info"></i>
              </button>
              <button
                [attr.data-test-id]="'facility-button-collapse'"
                type="button"
                class="btn btn-link p-0 mt-0"
                mdbWavesEffect
                *ngIf="claims.collapsed">
                <i class="icofont-plus-circle icofont-lg text-info"></i>
              </button>
            </td>
            <td>
              <p class="my-1">
                {{ claims?.data[0]?.collapsibleMenu?.facility?.name }}
              </p>
            </td>
            <td>
              <span class="text-dark font-weight-bold">{{ claims?.totalPayment | currency }}</span>
            </td>
          </tr>
          <tr *ngIf="!claims.collapsed">
            <td colspan="3">
              <p class="text-dark font-weight-bold my-2">Payment Details</p>
              <ng-container>
                <app-table
                  [report]="claims"
                  [module]="'payment'"
                  tableHeight="800px"
                  [isCheckboxDisabled]="isSearchedByClaimIdentifier || isTableFormDisabled"
                  [showCheckbox]="true"
                  [isEditable]="true"
                  [uneditableFields]="claimsTableUneditableFields"
                  [nonPrePopulatedFields]="nonPrePopulatedFields"
                  [invalidStatusReason]="invalidStatusReason"
                  (onCheckboxChecked)="onClaimSelected($event)"
                  (onCollapse)="onCollapse($event)"
                  (onCheckboxUnchecked)="onClaimDeselected($event)"
                  (onUpdateCellData)="verifyPaymentValidity($event)">
                  <ng-template #additionalHeader>
                    <tr class="grey lighten-4">
                      <td class="p-0"></td>
                      <td class="p-1 pl-2" [attr.colspan]="isSearchedByClaimIdentifier ? 2 : 0">
                        {{ isSearchedByClaimIdentifier ? 'Claim' : '' }}
                      </td>
                      <td
                        class="p-1 pl-2"
                        *ngIf="!isSearchedByClaimIdentifier"
                        [attr.colspan]="isSearchedByClaimIdentifier ? 0 : 2">
                        Claim
                      </td>
                      <td class="p-1 pl-2 text-right" colspan="2">Cash Paid</td>
                      <td class="p-1 pl-2 text-right">Payment Adjustment</td>
                      <td class="p-1 pl-2 text-right">Amount</td>
                      <td class="p-1 pl-2 text-right">Allowed Amt.</td>
                      <td class="p-0"></td>
                    </tr>
                  </ng-template>
                  <ng-template #total>
                    <tr class="table-borderless bg-white sticky-bottom">
                      <td [attr.colspan]="isSearchedByClaimIdentifier ? 5 : 6" class="p-0"></td>
                      <td class="text-right p-1 font-weight-bold">Total</td>
                      <td class="text-right p-1 pr-2 font-weight-bold">
                        {{ claims?.totalPayment | currency }}
                      </td>
                      <td class="text-right p-1 pr-2 font-weight-bold">
                        {{ claims?.totalAllowedAmount | currency }}
                      </td>
                      <td class="p-0"></td>
                    </tr>
                  </ng-template>
                  <ng-template #collapsibleContainer let-data="data">
                    <tr>
                      <td colspan="100%" class="p-3">
                        <h6 class="font-weight-bold">Service Information</h6>
                        <div class="row">
                          <div class="col">
                            <div class="d-flex">
                              <div style="font-size: 14px">
                                <div class="pt-0 pb-0">
                                  <p class="mb-0">Patient</p>
                                  <p class="text-muted">
                                    <strong>
                                      {{ data.patientName | titlecase }}
                                    </strong>
                                  </p>
                                </div>
                                <div class="pt-0 pb-0">
                                  <p class="mb-0">Facility</p>
                                  <p class="text-muted mb-0">
                                    <strong> {{ data.facility.name | titlecase }} </strong><br />
                                  </p>
                                  <p
                                    class="mb-0"
                                    *ngIf="
                                      data.facility.addressLine1 || data.facility.addressLine2
                                    ">
                                    <small
                                      >{{ data.facility.addressLine1 }}
                                      {{ data.facility.addressLine2 }}
                                    </small>
                                  </p>
                                  <p class="mb-0" *ngIf="data.facility.city || data.facility.state">
                                    <small
                                      >{{ data.facility.city | titlecase }}
                                      {{ data.facility.state }}
                                      {{ data.facility.zipCode }}</small
                                    >
                                  </p>
                                  <p class="mb-0">
                                    <small
                                      >(N){{ data.facility.npi || 'N/A' }}, (T){{
                                        data.facility.taxId || 'N/A'
                                      }}</small
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="d-flex">
                              <div class="ml-5" style="font-size: 14px">
                                <div class="t-0 pb-0">
                                  <p class="mb-0">Funding Request</p>
                                  <p class="text-muted">
                                    <strong>
                                      {{ data.fundingRequestName | titlecase }}
                                    </strong>
                                  </p>
                                </div>
                                <div class="pt-0 pb-0">
                                  <p class="mb-0">Episode</p>
                                  <p class="text-muted">
                                    <strong>
                                      {{ data.episode | titlecase }}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="d-flex">
                              <div class="ml-5 border-top-0" style="font-size: 14px">
                                <div class="pt-0 pb-0">
                                  <p class="mb-0">Bundle Component</p>
                                  <p class="text-muted">
                                    <strong>
                                      {{ data.bundleComponent | titlecase }}
                                    </strong>
                                  </p>
                                </div>
                                <div class="pt-0 pb-0">
                                  <p class="mb-0">Bundle</p>
                                  <p class="text-muted">
                                    <strong>
                                      {{ data.bundleName | titlecase }}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="d-flex">
                              <div class="ml-5 border-top-0" style="font-size: 14px">
                                <div class="pt-0 pb-0">
                                  <p class="mb-0">Allowed Amount</p>
                                  <p class="text-muted">
                                    <strong>
                                      {{ data.allowedAmount }}
                                    </strong>
                                  </p>
                                </div>
                                <div class="pt-0 pb-0">
                                  <p class="mb-0">Service Date</p>
                                  <p class="text-muted">
                                    <strong>
                                      {{ data.serviceDate }}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </app-table>
              </ng-container>
            </td>
          </tr>
        </tbody>
        <tfoot class="bg-light position-sticky top-0" style="z-index: 1">
          <tr class="table-borderless bg-white sticky-bottom bg-light">
            <th colspan="2" class="text-right p-1 font-weight-bold">Total Payment</th>
            <th class="text-right p-1 pr-2 font-weight-bold">
              {{ totalPayment | currency }}
            </th>
          </tr>
        </tfoot>
      </table>
    </form>
    <div class="text-center mt-4" *ngIf="!loading.claims && !claimList?.length && !isViewPayment">
      <h5>Search Claims</h5>
      <p>
        {{
          !isSearched
            ? CLAIMS_PAYMENT_DESCRIPTIONS.PAID_PAYMENT
            : CLAIMS_PAYMENT_DESCRIPTIONS.NO_CLAIMS
        }}
      </p>
    </div>
    <div class="d-flex justify-content-between mt-4">
      <a [routerLink]="['/payment']" class="waves-light m-0 btn-light btn" mdbWavesEffect>
        Go Back
      </a>
      <div
        class="d-inline-block"
        [mdbTooltip]="
          isSearchedByClaimIdentifier &&
          !payeeForm.value.id &&
          SUBMIT_DISABLED_REASONS.PAYEE_MISSING
        ">
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitPayment()"
          *ngIf="!isViewPayment"
          [disabled]="
            (!submit_enabled && !isEditPayment) ||
            (!submit_enabled && isAddPayment) ||
            (paymentForm.invalid && !isEditPayment) ||
            loading.saving
          ">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading.saving"></span>
          {{ isEditPayment ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</section>
