<div *ngIf="questions" class="d-flex">
  <div class="w-100">
    <div class="d-block d-sm-flex justify-content-between">
      <div class="d-block d-sm-flex pr-4 w-xs-100 w-75">
        <div class="icon-block d-none d-sm-block">
          <i class="icofont-question-circle icofont-lg skin-text-primary-dark"></i>
        </div>
        <div class="w-100">
          <p class="font-weight-400">
            {{ questions.questions.question }}
          </p>
          <p *ngIf="questions.questions.questionHelp">
            {{ questions.questions.questionHelp }}
          </p>
          <div *ngIf="questions.status === 'COMPLETED'">
            <div *ngFor="let answer of questions.questions.ansOpt | orderBy : 'answerOptionId'">
              <p *ngIf="answer.answerValue" class="text-primary">
                <span class="text-muted">Ans.</span>{{ answer.answerValue }}
              </p>
              <div *ngFor="let task of dependent_tasks">
                <app-episode-user-dependent-todo
                  *ngIf="task && task.uuid === answer.taskTodoUuid && task.assignedToRole === 'MP'"
                  [dependent_todo]="task">
                </app-episode-user-dependent-todo>
                <app-episode-user-dependent-signature
                  *ngIf="
                    task && task.uuid === answer.taskSignatureUuid && task.assignedToRole === 'MP'
                  "
                  [dependentSignature]="task">
                </app-episode-user-dependent-signature>
                <app-episode-user-dependent-message
                  *ngIf="
                    task.field === 'message' &&
                    task.uuid === answer.taskMessageUuid &&
                    task.messageToRole === 'MP'
                  "
                  [dependent_message]="task">
                </app-episode-user-dependent-message>
                <app-episode-user-dependent-question
                  *ngIf="task.field === 'question' && task.uuid === answer.taskQuesUuid"
                  [dependent_question]="task"
                  [dependentTopicTasks]="dependentTopicTasks">
                </app-episode-user-dependent-question>
                <app-episode-user-dependent-questionnaire
                  *ngIf="task.field === 'questionnaire' && task.uuid === answer.taskQnrsUuid"
                  [dependent_questionnaire]="task"
                  [dependentTopicTasks]="dependentTopicTasks">
                </app-episode-user-dependent-questionnaire>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        *ngIf="isEditAnswerEnable(questions)"
        (click)="$event.stopPropagation(); editAnswer(questions)">
        <a class="skin-link"><i class="icofont-edit-alt"></i> Edit answer</a></span
      >
      <span
        *ngIf="questions.status && questions.status === 'DUE'"
        class="chip chip-sm mb-0 ml-1 mt-3 mt-sm-0 alert alert-info"
        [ngClass]="questions.status ? questions.status.replace(' ', '').toLowerCase() : ''">
        <span class="text-sm status-label">
          {{ questions.status ? questions.status?.toLowerCase() : '' }}
          {{ questions.status === 'DUE' ? questions.escalationDays : '' }}
        </span>
      </span>
    </div>
  </div>
</div>
