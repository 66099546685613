import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-outgoing-call',
  templateUrl: './outgoing-call.component.html',
  styleUrls: ['./outgoing-call.component.scss']
})
export class OutgoingCallComponent implements OnInit {
  ringtone: any;
  @Input() set CallRingtone(CallRingtone) {
    if (CallRingtone) {
      this.ringtone = CallRingtone;
      // setInterval(() => {
      //   this.callRingtone.play();
      // }, 2000);
    }
  }
  @Input() call: any;
  @Output() endCall = new EventEmitter<any>();
  @Output() cancelCall = new EventEmitter<any>();
  constructor() { }

  ngOnInit() { }

  

  endCurrentCall() {
    this.endCall.emit(this.call.sessionId);
  }

  cancelCurrentCall() {
    this.cancelCall.emit(this.call.sessionId);
  }
}
