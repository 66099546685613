import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-episode-user-dependent-todo',
  templateUrl: './episode-user-dependent-todo.component.html',
  styleUrls: ['./episode-user-dependent-todo.component.scss']
})
export class EpisodeUserDependentTodoComponent implements OnInit {
  @Input() dependent_todo

  constructor() { }

  ngOnInit() {
  }

}
