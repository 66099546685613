import { Injectable } from "@angular/core";
import * as moment from 'moment'

@Injectable()

export class RollingSevenDays {

    public rollingDays(episodeUpdatedDate): any {
        let currentDate = moment().format('MM/DD/YYYY');
        let time7daysAgo = moment().subtract(7, 'days').calendar();
        return moment(episodeUpdatedDate).isBetween(time7daysAgo, currentDate, 'days', '[]')
    }
}