export class Company {
  id?: number;
  parentId: number;
  type?: string;
  name: string;
  email: string;
  phone: string;
  addressline1: string;
  addressline2: string;
  city: string;
  state: string;
  zip: number;
  isActive: boolean;
  companyCode: string;
  typeCode?: string;
  parentCompany: ParentCompany;
}

export class ParentCompany {
  id: number;
  name: string;
}