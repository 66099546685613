import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation.component.html'
})

export class DeleteComfirmationComponent implements OnInit {
    Id: any;
    @ViewChild('deleteModal', { static: true }) public deleteModal;

    @Input() set _id(id) {
        if (id) {
            this.Id = id;
            this.deleteModal.show();
        }
    }
    @Input() message: string = `Are you sure you want to delete ? This is irreversible.
    If you're not sure, you can click Cancel.`
    @Input() yes: string = 'DELETE';
    @Input() no: string = 'CANCEL';
    @Output() confirmDelete = new EventEmitter();
    constructor() {

    }

    ngOnInit() {

    }

    triggerAction(action) {
        this.confirmDelete.emit(action == 'yes' ? this.Id : null);
        this.deleteModal.hide();
    }
}