import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EPISODE_FINANCIAL_DETAIL_HEADER, EPISODE_FINANCIAL_SUMMARY_HEADER, REPORT_LABELS } from '../../../constants';
import { CommunicationModuleOptions, ExportData, SummaryData } from '../../../models';
import { ActivityLogService, ExcelService } from '../../../services';

@Component({
  selector: 'app-financial-reconciliation',
  templateUrl: './financial-reconciliation.component.html',
  styleUrls: ['./financial-reconciliation.component.scss']
})
export class FinancialReconciliationComponent implements OnInit {
  showMessageModal: boolean = false;
  selectedTab: string;
  displayNone: boolean = false;
  hideDetail: boolean = true;
  moduleOptions: CommunicationModuleOptions;

  @Input() public financialReconciliationData: any;
  @ViewChild('tabs', { static: true }) public tabs;

  constructor(
    private _excelService: ExcelService,
    private _activityLogService: ActivityLogService
  ) { }

  ngOnInit() { }

  /**
   * exports Financial Reconciliation Report
   */
  exportFinancialReconciliation() {
    const episodeFinancialDetail = this.financialReconciliationData.episodeFinancialDetail.episodeFinancials.map(x => {
      return [x.subscriberNumber, x.firstName, x.lastName, x.episodeType, x.marketCode, x.componentName, x.type, x.componentCost, x.consumedAmount, x.hraHsaAmount];
    })
    let episodeFinancialDetailSummaryData = { data: {} } as SummaryData;
    episodeFinancialDetailSummaryData.client = this.financialReconciliationData.client ? this.financialReconciliationData.client.label : '';
    episodeFinancialDetailSummaryData.date = this.financialReconciliationData.date;
    Object.assign(episodeFinancialDetailSummaryData.data, { totalConsumed: this.financialReconciliationData.episodeFinancialDetail.totalConsumed });
    const episodeFinancialSummaryDetail = this.financialReconciliationData.episodeFinancialSummaryDetail.episodeFinancialSummary.map(x => {
      return [x.subscriberNumber, `${x.firstName} ${x.lastName}`, x.episodeType, x.fundReceived, x.hraHsaReceivedAmount, x.consumedAmount, x.hraHsaPaidAmount, x.balance];
    })
    let episodeFinancialSummaryData = { data: {} } as SummaryData;
    episodeFinancialSummaryData.client = this.financialReconciliationData.client ? this.financialReconciliationData.client.label : '';
    episodeFinancialSummaryData.date = this.financialReconciliationData.date;
    Object.assign(episodeFinancialSummaryData.data, { currentPeriodSummary: this.financialReconciliationData.episodeFinancialSummaryDetail.currentPeriodSummary });
    Object.assign(episodeFinancialSummaryData.data, { yearToDateSummary: this.financialReconciliationData.yearToDateSummary });
    let exportData: ExportData[] = [
      { exportList: episodeFinancialSummaryDetail, headers: EPISODE_FINANCIAL_SUMMARY_HEADER, excelName: 'Summary', options: { action: 'setFinancialReconciliationHeader' }, summaryData: episodeFinancialSummaryData }
      ,
      { exportList: episodeFinancialDetail, headers: EPISODE_FINANCIAL_DETAIL_HEADER, excelName: 'Detail', options: { action: 'setCurrentPeriodBalanceHeader' }, summaryData: episodeFinancialDetailSummaryData }
    ]
    this._excelService.exportExcel(exportData);
    this._activityLogService.logUserActivities('report', REPORT_LABELS.EPISODE_FINANCIAL_RECONCILIATION_REPORT.toLowerCase(), 'downloaded').subscribe();
  }

  onSendEmail() {
    this.selectedTab.toLowerCase() === 'detail' && this.tabs.setActiveTab(1)
    this.showMessageModal = true;
    this.moduleOptions = {
      module: 'report',
      subModule: REPORT_LABELS.EPISODE_FINANCIAL_RECONCILIATION_REPORT.toLowerCase()
    };
  }

  closeMessageModal() {
    this.showMessageModal = false;
  }

  onTabSelected(event) {
    this.selectedTab = event.heading;
    this.selectedTab.toLowerCase() === 'detail' ? (this.hideDetail = false) : (this.hideDetail = true);
  }
}