export const TrimFields = {
  Patient: [
    'firstname', 'middlename', 'lastname', 'ssNumber', 'addressline1', 'addressline2', 'city', 'notes', 'subscriberId', 'memberId', 'clientProgramCode', 'otherInfo1', 'clientEmployerGroupCode'
  ],
  Company: ['name', 'addressline1', 'addressline2', 'city', 'zip'],
  Pathway: ['name', 'description', 'tagName'],
  PathwayMilestone: ['name', 'description'],
  User: ['firstname', 'lastname', 'notes'],
  Roles: ['name', 'code'],
  Network: ['name', 'code'],
  Market: ['name', 'code'],
  PathwayMilestoneTopic: ['topic', 'patientTopic'],
  TaskMessage: ['title', 'messages'],
  ToDo: ['name', 'instruction'],
  Question: ['question', 'questionHelp']
};
