import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize } from 'rxjs/operators';
import { TRANSACTION_REPORT_TAB_INDEXES, DatePickerOption, REPORT_DATES, REPORT_TAB_CODE, ACCOUNTS_RECEIVABLE_REPORT_DATA, ACCOUNTS_PAYABLE_REPORT_DATA, SUMMARY_YTD_REPORT_DATA, ACCOUNTS_RECEIVABLE_SUMMARY_DATE_TYPE_LIST } from '../../../constants';
import { ClientService, EpisodeService, ExcelService, NetworkService, ReportService } from '../../../services';
import { DateUtility, HolistaUtils, ReportUtility } from '../../../utils';
import * as moment from 'moment';
import { Observable, forkJoin, of } from 'rxjs';
import { ExportData } from '../../../models';

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss']
})
export class TransactionReportComponent implements OnInit {

  @Input() reportParentCode: string;
  @Input() report;

  tabList: any[] = [];
  reportFormConfig: FormGroup;
  isLoading = {
    clientList: false,
    tabList: false,
    reportConfiguration: false
  };
  networkList: any[] = [];
  clientList: any[] = [];
  reportDateTypeList: any[] = REPORT_DATES;
  REPORT_TAB_CODE = REPORT_TAB_CODE;
  isReportExpanded: boolean = false;
  downloadFile = { download: false, tabIndex: 0, type: null };
  arAfFilterData: any;
  startDateOptions = this._holistaUtils.deepClone(DatePickerOption);
  endDateOptions = this._holistaUtils.deepClone(DatePickerOption);
  isPreSelectedFilter: boolean = false;
  transactionReportFilterData: any;
  episodeTypeList: any[] = [];
  TRANSACTION_REPORT_TAB_INDEXES = TRANSACTION_REPORT_TAB_INDEXES;
  accountsReceivableReport: any = {};
  accountsPayableReport: any = {};
  summaryYTDReport: any = {};

  @ViewChild('tabs', { static: true }) public tabs;

  constructor(
    private _formBuilder: FormBuilder,
    private _clientService: ClientService,
    private _reportService: ReportService,
    private _holistaUtils: HolistaUtils,
    private _episodeService: EpisodeService,
    private _networkService: NetworkService,
    private _excelService: ExcelService,
    private _reportUtility: ReportUtility,
    private _dateUtility: DateUtility
  ) {
    this.setReportFormConfig()
  }

  ngOnInit(): void {
    this.getTabsList();
    this.getEpisodeTypes();
    this.getNetworks();
  }

  setReportFormConfig() {
    this.reportFormConfig = this._formBuilder.group({
      activeTabIndex: [0],
      clientCode: [null],
      reportDateType: ['ytd'],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      networkCode: [null]
    });
  }

  getNetworks() {
    this._networkService.getAll({ limit: 0, fields: 'code,name' })
      .subscribe(response => {
        if (response.count) {
          this.networkList = response.rows.map(({ code, name }) => ({ label: name, value: code }));
          const defaultNetwork = this.networkList.find((network) => network.value.toUpperCase() === 'NH');
          this.reportFormConfig.controls.networkCode.setValue(defaultNetwork?.value ?? '');
          this.getClientList(defaultNetwork?.value);
        }
      }, error => console.log('error getting network list', error));
  }

  getTabsList() {
    this.isLoading.tabList = true;
    this._reportService.getReportsList({ parentCode: this.reportParentCode })
      .pipe(finalize(() => { this.isLoading.tabList = false; }))
      .subscribe(response => {
        this.tabList = response.sort((x, y) => x.order - y.order);
        setTimeout(() => {
          this.tabs.setActiveTab(1);
        }, 1);
      }, error => console.log('error getting report list', error))
  }

  onNetworkSelected({ value: code }) {
    this.reportFormConfig.controls.clientCode.setValue(null);
    this.getClientList(code);
  }

  getClientList(networkCode: string) {
    this.isLoading.clientList = true;
    this._clientService.getClients({ limit: 0, fields: 'code,name', networkCode })
      .pipe(finalize(() => { this.isLoading.clientList = false; }))
      .subscribe((response: any) => {
        this.clientList = response.rows.map(({ name, code }) => ({ label: name, value: code }));
        this.onReportDateTypeSelected();
      }, error => console.log('error getting clients', error));
  }

  onGetActiveTab(event) {
    if (event) {
      this.reportFormConfig.controls['activeTabIndex'].setValue(event.activeTabIndex);

      if (event.activeTabIndex === TRANSACTION_REPORT_TAB_INDEXES.SUMMARY_YTD) {
        this.reportFormConfig.controls['reportDateType'].setValue('ytd');
        this.onReportDateTypeSelected();
      };
    };
  }

  /**
   * Changes report dates From and To based on type selected
   */
  onReportDateTypeSelected() {
    if (this.reportFormConfig.value.reportDateType) {
      switch (this.reportFormConfig.value.reportDateType) {
        case "thisMonth":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('month').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().endOf('month').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastMonth":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('month').subtract(1, 'months').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().startOf('month').subtract(1, 'months').endOf('month').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "ytd":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('year').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastYear":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('year').subtract(12, 'months').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().endOf('year').subtract(12, 'months').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "lastQuarter":
          let currentQuaterNumber = moment().quarter();

          if (currentQuaterNumber === 1) { //if 1st quater
            this.reportFormConfig.controls['startDate'].setValue(moment().startOf('year').subtract(3, 'months').format('MM/DD/YYYY'));
            this.reportFormConfig.controls['endDate'].setValue(moment().startOf('year').subtract(1, 'day').format('MM/DD/YYYY'));
          } else {
            this.reportFormConfig.controls['startDate'].setValue(moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YYYY'));
            this.reportFormConfig.controls['endDate'].setValue(moment().subtract(1, 'quarter').endOf('quarter').format('MM/DD/YYYY'));
          }
          this.isPreSelectedFilter = true;
          break;
        case "thisQuarter":
          this.reportFormConfig.controls['startDate'].setValue(moment().startOf('quarter').format('MM/DD/YYYY'));
          this.reportFormConfig.controls['endDate'].setValue(moment().endOf('quarter').format('MM/DD/YYYY'));
          this.isPreSelectedFilter = true;
          break;
        case "dateRange":
          this.reportFormConfig.controls.startDate.setValidators([Validators.required]);
          this.reportFormConfig.controls.startDate.updateValueAndValidity();
          this.reportFormConfig.controls.endDate.setValidators([Validators.required]);
          this.reportFormConfig.controls.endDate.updateValueAndValidity();

          if (this.isPreSelectedFilter) {
            try {
              this.reportFormConfig.controls['startDate'].setValue(null);
              this.reportFormConfig.controls['endDate'].setValue(null);
              this.reportFormConfig.controls['startDate'].enable();
              this.reportFormConfig.controls['endDate'].enable();
            } catch (error) {
              try {
                this.reportFormConfig.controls['startDate'].enable();
                this.reportFormConfig.controls['endDate'].enable();
              } catch (error) {
                this.reportFormConfig.controls['endDate'].enable();
              }
            }
          }
          this.isPreSelectedFilter = false;
          break;
        default:
          break;
      }
    }
  }

  getEpisodeTypes() {
    this._episodeService.getUniqueEpisodeTypes()
      .pipe()
      .subscribe((response: any) => {
        response = response.map((episode) => {
          const name = episode.name.trim();
          return {
            name,
            label: name,
            value: name.replaceAll(',', '')
          };
        });
        const episodeNames = response.map(episode => episode['name']);
        const filteredEpisodeTypeList = response.filter((episode, index: number) => !episodeNames.includes(episode['name'], index + 1))
          .map((episode: any) => ({ ...episode, name: episode.name.toLowerCase() }));
        this.episodeTypeList = this._holistaUtils.getUniqueList(filteredEpisodeTypeList, 'name');
      }, error => {
        console.log('error getting episode types', error);
      })
  }

  onDateChange(event, type: string) {
    this.reportFormConfig.controls[type].setValue(event.actualDateFormatted);
  }

  getTransactionReports(event) {
    const params = { ...this._holistaUtils.getFormattedValues(this.reportFormConfig.value), dateType: ACCOUNTS_RECEIVABLE_SUMMARY_DATE_TYPE_LIST[0].value };
    delete params['activeTabIndex'];
    delete params['reportDateType'];

    //applied filters for individual report
    const accountsReceivableParams = event.reportType === ACCOUNTS_RECEIVABLE_REPORT_DATA.EXCEL_NAME ? { ...params, ...event.filters } : params;
    const accountsPayableParams = event.reportType === ACCOUNTS_PAYABLE_REPORT_DATA.EXCEL_NAME ? { ...params, ...event.filters } : params;
    const summaryYTDParams = event.reportType === SUMMARY_YTD_REPORT_DATA.EXCEL_NAME ? { ...params, ...event.filters } : { ...params, year: +this._dateUtility.getYearFromDate(this.reportFormConfig.get('endDate').value) }; //Summary report is returned based on year rather than date range

    const observables: Observable<any>[] = [
      this._reportService.getAccountsReceivableReport(accountsReceivableParams).pipe(
        catchError(error => {
          console.error('error getting accounts receivable report:', error);
          return of(null); // Emit null and continue with other observables
        })
      ),
      this._reportService.getAccountsPayableReport(accountsPayableParams).pipe(
        catchError(error => {
          console.error('error getting accounts payable report:', error);
          return of(null);
        })
      ),
      this._reportService.getSummaryYTDReport(summaryYTDParams).pipe(
        catchError(error => {
          console.error('error getting summary YTD report:', error);
          return of(null);
        })
      )
    ];
    forkJoin(observables)
      .pipe(finalize(() => { this.downloadFile.download = false }))
      .subscribe({
        next: ([
          accountsReceivableReportData,
          accountsPayableReportData,
          summaryYTDReportData
        ]: any) => {
          this.accountsReceivableReport = accountsReceivableReportData.data;
          this.accountsPayableReport = accountsPayableReportData.data;
          this.summaryYTDReport = summaryYTDReportData.data;
          this.exportToCsv();
        },
        error: (error) => console.log('error getting transaction reports', error)
      })
  }

  download(type) {
    this.downloadFile = { download: true, tabIndex: this.reportFormConfig.get('activeTabIndex').value, type };
  }

  applyFilters(event) {
    this.getTransactionReports(event);
  }

  mapReport(reportType) {
    const { clientCode, startDate, endDate } = this.reportFormConfig.value;
    const { EXCEL_NAME, SUMMARY_HEADERS, TABLE_TITLE, EXCEL_HEADERS, TABLE_HEADERS, REPORT_DATA, SUMMARY_DATA } = reportType;
    const excelHeaders = EXCEL_HEADERS ?? TABLE_HEADERS ?? REPORT_DATA.TABLE_HEADERS;
    const summaryHeaders = SUMMARY_HEADERS ?? SUMMARY_DATA.TABLE_HEADERS.slice(1);
    let reportData;
    const excelData = [];

    if (EXCEL_NAME === ACCOUNTS_RECEIVABLE_REPORT_DATA.EXCEL_NAME) {
      reportData = this.accountsReceivableReport;
      this.accountsReceivableReport.formattedAccountReceivable.map(datum => {
        datum.patient.map(detail => {
          const requiredData = [];
          excelHeaders.forEach(header => { requiredData.push(this._reportUtility.getFormattedValue(header.type, detail[header.value])) });
          excelData.push(requiredData);
        });
      });
    };

    if (EXCEL_NAME === ACCOUNTS_PAYABLE_REPORT_DATA.EXCEL_NAME || EXCEL_NAME === SUMMARY_YTD_REPORT_DATA.EXCEL_NAME) {
      let report;

      if (EXCEL_NAME === ACCOUNTS_PAYABLE_REPORT_DATA.EXCEL_NAME) {
        reportData = this.accountsPayableReport;
        report = this.accountsPayableReport.accountPayables;
      } else {
        reportData = this.summaryYTDReport;
        report = this.summaryYTDReport.accountSummaryDatas;
      };
      report.forEach(datum => {
        const data = [];
        excelHeaders.forEach(header => { data.push(this._reportUtility.getFormattedValue(header.type, datum[header.value])) })
        excelData.push(data);
      });
    };

    const exportData = {
      exportList: excelData,
      headers: excelHeaders.map((item: any) => item.label),
      summaryData: {
        headers: summaryHeaders.map(header => header.label),
        data: summaryHeaders.map(header => this._reportUtility.getFormattedValue(header.type, reportData.summary[header.value])),
        client: clientCode,
        date: { startDate, endDate }
      },
      columnsWidth: excelHeaders.map(header => header.columnWidth),
      options: { action: 'includedSummary' },
      excelName: EXCEL_NAME,
      tableTitle: TABLE_TITLE
    };
    return exportData;
  }

  async exportToCsv() {
    const exportData: ExportData[] = [];
    const reports = [ACCOUNTS_RECEIVABLE_REPORT_DATA, ACCOUNTS_PAYABLE_REPORT_DATA, SUMMARY_YTD_REPORT_DATA];
    reports.forEach(report => exportData.push(this.mapReport(report)));
    exportData[0].fileName = 'Transaction_Report';
    this._excelService.exportExcel(exportData);
  }
}
