<ng-template #showLoader>
  <div class="mt-3">
    <app-loader [loader]="true"></app-loader>
  </div>
</ng-template>
<div id="network-cycle-time" *ngIf="toShowReport.length; else showLoader">
  <!-- Overall Service Category Cycle Time -->
  <div
    *ngIf="showOverallServiceCategoryCycleTime()"
    [attr.id]="expand.overallServiceCategoryCycleTime ? 'network-cycle-time' : null"
    class="my-3 col-md-12">
    <mdb-card>
      <mdb-card-header>
        <div class="d-flex justify-content-between align-items-center">
          <p
            class="m-0 font-weight-normal"
            *ngIf="getReport(REPORT_NAME_CODES.OVERALL_SERVICE_CATEGORY_CYCLE_TIME) as report">
            {{ report.reportName }}
          </p>
          <div class="d-flex align-items-center">
            <form [formGroup]="overallServiceCategoryCycleTimeForm">
              <app-select
                [options]="categoryList"
                [id]="'oscct-category'"
                [style]="'width: 300px'"
                [placeholder]="'Select category'"
                [multiple]="true"
                [allowClear]="
                  overallServiceCategoryCycleTimeForm.get('categoryId').value.length && true
                "
                [inputClass]="{
                  'disabled-options': loading.overallServiceCategoryCycleTime
                }"
                (onSelected)="getOverallServiceCategoryCycleTime(false, true)"
                (onDeselected)="getOverallServiceCategoryCycleTime(false)"
                [control]="overallServiceCategoryCycleTimeForm.controls['categoryId']"></app-select>
              <app-select
                [options]="yearOptions | sort : 'label'"
                [id]="'year-select'"
                [style]="'width: 80px'"
                [class]="'ml-3'"
                [filterEnabled]="false"
                [control]="overallServiceCategoryCycleTimeForm.controls['selectedYear']"
                (onSelected)="getOverallServiceCategoryCycleTime(false, false)"
                [placeholder]="'Year'"></app-select>
            </form>
            <span
              class="link pointer-cursor ml-3"
              (click)="onViewToggle(REPORT_NAME_CODES.OVERALL_SERVICE_CATEGORY_CYCLE_TIME, false)">
              <i class="fas fa-expand-alt"></i>
            </span>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body style="height: 500px" *ngIf="expand.overallServiceCategoryCycleTime">
        <canvas
          *ngIf="showOverallServiceCategoryCycleTimeChart()"
          baseChart
          [datasets]="overallServiceCategoryCycleTimeChart.dataset"
          [options]="overallServiceCategoryCycleTimeChart.options"
          [labels]="overallServiceCategoryCycleTimeChart.labels"
          [plugins]="overallServiceCategoryCycleTimeChart.plugins"
          [chartType]="overallServiceCategoryCycleTimeChart.chartType">
        </canvas>
        <p *ngIf="noOverallServiceCategoryCycleTimeChartData()">
          {{ noDataMessage.value }}
        </p>
        <div
          class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
          *ngIf="!overallServiceCategoryCycleTimeForm.get('categoryId').value.length">
          <div class="text-center">
            <i class="icofont-file-spreadsheet icofont-4x text-secondary"></i>
            <p class="mt-2">Please select Category</p>
          </div>
        </div>
        <div
          class="d-flex justify-content-center align-items-center h-75"
          *ngIf="loading.overallServiceCategoryCycleTime">
          <app-loader [loader]="loading.overallServiceCategoryCycleTime"></app-loader>
        </div>
      </mdb-card-body>
      <mdb-card-body style="height: 320px" *ngIf="!expand.overallServiceCategoryCycleTime">
        <canvas
          *ngIf="showOverallServiceCategoryCycleTimeChart()"
          baseChart
          [datasets]="overallServiceCategoryCycleTimeChart.dataset"
          [options]="overallServiceCategoryCycleTimeChart.options"
          [labels]="overallServiceCategoryCycleTimeChart.labels"
          [plugins]="overallServiceCategoryCycleTimeChart.plugins"
          [chartType]="overallServiceCategoryCycleTimeChart.chartType">
        </canvas>
        <p *ngIf="noOverallServiceCategoryCycleTimeChartData()">
          {{ noDataMessage.value }}
        </p>
        <div
          class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
          *ngIf="!overallServiceCategoryCycleTimeForm.get('categoryId').value.length">
          <div class="text-center">
            <i class="icofont-file-spreadsheet icofont-4x text-secondary"></i>
            <p class="mt-2">Please select Category</p>
          </div>
        </div>
        <div
          class="d-flex justify-content-center align-items-center h-75"
          *ngIf="loading.overallServiceCategoryCycleTime">
          <app-loader [loader]="loading.overallServiceCategoryCycleTime"></app-loader>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
  <!-- Overall Service Category Cycle Time -->

  <!-- Provider Cycle Time -->
  <div
    *ngIf="showProviderCycleTime()"
    [attr.id]="expand.providerCycleTime ? 'provider-cycle-time' : null"
    class="my-3 col-md-12">
    <mdb-card>
      <mdb-card-header>
        <div class="d-flex justify-content-between align-items-center">
          <p
            class="m-0 font-weight-normal"
            *ngIf="getReport(REPORT_NAME_CODES.PROVIDER_CYCLE_TIME) as report">
            {{ report.reportName }}
          </p>
          <div class="d-flex align-items-center">
            <form [formGroup]="providerCycleTimeForm">
              <app-select
                [options]="categoryList"
                [id]="'pct-category'"
                [style]="'width: 300px'"
                [placeholder]="'Select category'"
                [allowClear]="true"
                [control]="providerCycleTimeForm.controls['categoryId']"
                (onSelected)="onCategorySelected(REPORT_NAME_CODES.PROVIDER_CYCLE_TIME)"
                (onDeselected)="
                  onCategoryDeselected(REPORT_NAME_CODES.PROVIDER_CYCLE_TIME)
                "></app-select>
              <div class="ml-3" style="display: inline-block">
                <app-select
                  [options]="providerList"
                  [id]="'pct-provider'"
                  [style]="'width: 500px'"
                  [placeholder]="'Select provider'"
                  [multiple]="true"
                  [inputClass]="{
                    'disabled-options': loading.providerCycleTime
                  }"
                  [allowClear]="providerCycleTimeForm.get('providerId').value.length && true"
                  [disabled]="loading.providerList || !providerList.length"
                  [control]="providerCycleTimeForm.controls['providerId']"
                  (onSelected)="getProviderCycleTimeChart(true, false)"
                  (onDeselected)="getProviderCycleTimeChart(true, false)"></app-select>
              </div>
              <app-select
                [options]="yearOptions | sort : 'label'"
                [id]="'year-select'"
                [style]="'width: 80px'"
                [class]="'ml-3'"
                [filterEnabled]="false"
                [control]="providerCycleTimeForm.controls['selectedYear']"
                (onSelected)="getProviderCycleTimeChart(false, false, true)"
                [placeholder]="'Year'"></app-select>
            </form>
            <span
              class="link pointer-cursor ml-3"
              (click)="onViewToggle(REPORT_NAME_CODES.PROVIDER_CYCLE_TIME, false)">
              <i class="fas fa-expand-alt"></i>
            </span>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body style="height: 500px" *ngIf="expand.providerCycleTime">
        <canvas
          *ngIf="showProviderCycleTimeChart()"
          baseChart
          [datasets]="providerCycleTimeChart.dataset"
          [options]="providerCycleTimeChart.options"
          [labels]="providerCycleTimeChart.labels"
          [plugins]="providerCycleTimeChart.plugins"
          [chartType]="providerCycleTimeChart.chartType">
        </canvas>
        <p *ngIf="noProviderCycleTimeChartData()">
          {{ noDataMessage.value }}
        </p>
        <div
          class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
          *ngIf="!providerCycleTimeForm.get('providerId').value.length">
          <div class="text-center">
            <i class="icofont-building icofont-4x text-secondary"></i>
            <p class="mt-2">Please select Category and Provider</p>
          </div>
        </div>
        <div
          class="d-flex justify-content-center align-items-center h-75"
          *ngIf="loading.providerCycleTime">
          <app-loader [loader]="loading.providerCycleTime"></app-loader>
        </div>
      </mdb-card-body>
      <mdb-card-body style="height: 320px" *ngIf="!expand.providerCycleTime">
        <canvas
          *ngIf="showProviderCycleTimeChart()"
          baseChart
          [datasets]="providerCycleTimeChart.dataset"
          [options]="providerCycleTimeChart.options"
          [labels]="providerCycleTimeChart.labels"
          [plugins]="providerCycleTimeChart.plugins"
          [chartType]="providerCycleTimeChart.chartType">
        </canvas>
        <p *ngIf="noProviderCycleTimeChartData()">
          {{ noDataMessage.value }}
        </p>
        <div
          class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
          *ngIf="!providerCycleTimeForm.get('providerId').value.length">
          <div class="text-center">
            <i class="icofont-building icofont-4x text-secondary"></i>
            <p class="mt-2">Please select Category and Provider</p>
          </div>
        </div>
        <div
          class="d-flex justify-content-center align-items-center h-75"
          *ngIf="loading.providerCycleTime">
          <app-loader [loader]="loading.providerCycleTime"></app-loader>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
  <!-- Provider Cycle Time -->

  <!-- Market Cycle Time -->
  <div
    *ngIf="showMarketCycleTime()"
    [attr.id]="expand.marketCycleTime ? 'market-cycle-time' : null"
    class="my-3 col-md-12">
    <mdb-card>
      <mdb-card-header>
        <div class="d-flex justify-content-between align-items-center">
          <p
            class="m-0 font-weight-normal"
            *ngIf="getReport(REPORT_NAME_CODES.MARKET_CYCLE_TIME) as report">
            {{ report.reportName }}
          </p>
          <div class="d-flex align-items-center">
            <form [formGroup]="marketCycleTimeForm">
              <app-select
                [options]="categoryList"
                [id]="'mct-category'"
                [style]="'width: 300px'"
                [placeholder]="'Select category'"
                [allowClear]="true"
                (onSelected)="onCategorySelected(REPORT_NAME_CODES.MARKET_CYCLE_TIME)"
                (onDeselected)="onCategoryDeselected(REPORT_NAME_CODES.MARKET_CYCLE_TIME)"
                [control]="marketCycleTimeForm.controls['categoryId']"></app-select>
              <div class="ml-3" style="display: inline-block">
                <app-select
                  [options]="marketList"
                  [id]="'mct-market'"
                  [style]="'width: 300px'"
                  [placeholder]="'Select market'"
                  [multiple]="true"
                  [inputClass]="{
                    'disabled-options': loading.marketCycleTime
                  }"
                  [allowClear]="marketCycleTimeForm.get('marketId').value.length && true"
                  [disabled]="loading.marketList || !marketList.length"
                  [control]="marketCycleTimeForm.controls['marketId']"
                  (onSelected)="getMarketCycleTimeChart(true, false)"
                  (onDeselected)="getMarketCycleTimeChart(true, false)"></app-select>
              </div>
              <app-select
                [options]="yearOptions | sort : 'label'"
                [id]="'year-select'"
                [style]="'width: 80px'"
                [class]="'ml-3'"
                [filterEnabled]="false"
                [control]="marketCycleTimeForm.controls['selectedYear']"
                (onSelected)="getMarketCycleTimeChart(false, false, true)"
                [placeholder]="'Year'"></app-select>
            </form>
            <span
              class="link pointer-cursor ml-3"
              (click)="onViewToggle(REPORT_NAME_CODES.MARKET_CYCLE_TIME, false)">
              <i class="fas fa-expand-alt"></i>
            </span>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body style="height: 500px" *ngIf="expand.marketCycleTime">
        <canvas
          *ngIf="showMarketCycleTimeChart()"
          baseChart
          [datasets]="marketCycleTimeChart.dataset"
          [options]="marketCycleTimeChart.options"
          [labels]="marketCycleTimeChart.labels"
          [plugins]="marketCycleTimeChart.plugins"
          [chartType]="marketCycleTimeChart.chartType">
        </canvas>
        <p *ngIf="noMarketCycleTimeChartData()">
          {{ noDataMessage.value }}
        </p>
        <div
          class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
          *ngIf="marketCycleChartCriteriaNotFulfilled()">
          <div class="text-center">
            <i class="icofont-building icofont-4x text-secondary"></i>
            <p class="mt-2">Please select Category and Market</p>
          </div>
        </div>
        <div
          class="d-flex justify-content-center align-items-center h-75"
          *ngIf="loading.marketCycleTime">
          <app-loader [loader]="loading.marketCycleTime"></app-loader>
        </div>
      </mdb-card-body>
      <mdb-card-body style="height: 320px" *ngIf="!expand.marketCycleTime">
        <canvas
          *ngIf="showMarketCycleTimeChart()"
          baseChart
          [datasets]="marketCycleTimeChart.dataset"
          [options]="marketCycleTimeChart.options"
          [labels]="marketCycleTimeChart.labels"
          [plugins]="marketCycleTimeChart.plugins"
          [chartType]="marketCycleTimeChart.chartType">
        </canvas>
        <p *ngIf="noMarketCycleTimeChartData()">
          {{ noDataMessage.value }}
        </p>
        <div
          class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
          *ngIf="marketCycleChartCriteriaNotFulfilled()">
          <div class="text-center">
            <i class="icofont-building icofont-4x text-secondary"></i>
            <p class="mt-2">Please select Category and Market</p>
          </div>
        </div>
        <div
          class="d-flex justify-content-center align-items-center h-75"
          *ngIf="loading.marketCycleTime">
          <app-loader [loader]="loading.marketCycleTime"></app-loader>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
  <!-- Market Cycle Time -->
</div>
