<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center action position-sticky mt-4">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group input-group has-search mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          [ngModel]="searchKeyword"
          class="form-control"
          id="searchpatient"
          [attr.data-test-id]="'search'"
          placeholder="Search"
          (ngModelChange)="change($event)" />
        <div class="input-group-append" *ngIf="searchKeyword">
          <button
            class="btn btn-sm btn-link text-decoration-none p-2 m-0"
            [attr.data-test-id]="'close-icon'">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
    </div>
    <button
      [attr.data-test-id]="'patient-add-button'"
      *ngIf="loggedUser.roleCode !== 'PR'"
      type="button"
      class="btn btn-secondary"
      data-toggle="modal"
      data-target="#basicExample"
      [disabled]="
        loggedUser.roleCode === 'CA' ||
        loggedUser.roleCode === 'SCA' ||
        loggedUser.roleCode === 'IA'
      "
      (click)="addPatient()"
      mdbWavesEffect>
      <i class="icofont-plus"></i> Add Patient
    </button>
  </div>

  <!-- Advance search form -->
  <div *ngIf="showAdvanceSearch && loggedUser.roleCode !== 'PR'">
    <div style="padding: 10px 25px" class="grey lighten-5 mt-2 mb-2">
      <div class="d-flex justify-content-between align-items-center">
        <h5>Search Patient Eligibility Records</h5>
        <!-- <button type="button" class="btn" (click)="showAdvanceSearch = !showAdvanceSearch"><i
                        class="fas fa-times"></i>
                </button> -->
      </div>
      <form [formGroup]="advanceSearchForm">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="fname">First Name</label>
              <input
                type="text"
                id="fname"
                [attr.data-test-id]="'search-member-fname'"
                class="md-textarea form-control"
                mdbInput
                formControlName="firstName"
                (keyup.enter)="searchMembers(1)" />
            </div>
          </div>
          <div [ngClass]="isSuperAdmin ? 'col-2' : 'col-4'">
            <div class="form-group">
              <label for="mname">Middle Name</label>
              <input
                type="text"
                id="mname"
                [attr.data-test-id]="'search-member-mname'"
                class="md-textarea form-control"
                mdbInput
                formControlName="middleName"
                (keyup.enter)="searchMembers(1)" />
            </div>
          </div>
          <div [ngClass]="isSuperAdmin ? 'col-2' : 'col-4'">
            <div class="form-group">
              <label for="lname">Last Name</label>
              <input
                type="text"
                id="lname"
                [attr.data-test-id]="'search-member-lname'"
                class="md-textarea form-control"
                mdbInput
                formControlName="lastName"
                (keyup.enter)="searchMembers(1)" />
            </div>
          </div>

          <div class="col-2">
            <div class="form-group">
              <label for="dob">Date of Birth</label>
              <input
                type="text"
                id="dob"
                [attr.data-test-id]="'search-member-dob'"
                placeholder="MM/DD/YYYY"
                class="form-control"
                [textMask]="{mask: dateMask}"
                formControlName="dob"
                [ngClass]="{
                  'is-invalid': advanceSearchForm.get('dob').invalid || validPatientDob
                }"
                (keyup.enter)="searchMembers(1)" />
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label for="SubscriberNumber">Subscriber Number</label>
              <input
                type="text"
                id="SubscriberNumber"
                [attr.data-test-id]="'search-member-subscriber-number'"
                class="md-textarea form-control"
                mdbInput
                formControlName="subscriberNumber"
                (keyup.enter)="searchMembers(1)" />
            </div>
          </div>
          <!-- </div> -->
          <!-- </div> -->
          <div class="col-4">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="text"
                id="email"
                [attr.data-test-id]="'search-member-email'"
                class="md-textarea form-control"
                mdbInput
                formControlName="email"
                (keyup.enter)="searchMembers(1)" />
            </div>
          </div>
          <div *ngIf="isSuperAdmin" class="col-4">
            <div class="form-group">
              <label for="network">Network</label>
              <mdb-select
                [options]="networkList | sort : 'label'"
                id="network"
                [attr.data-test-id]="'search-member-network'"
                [allowClear]="true"
                [filterEnabled]="true"
                (selected)="onNetworkSelect($event)"
                [visibleOptions]="2"
                (deselected)="removeSelection('network')"
                formControlName="network">
              </mdb-select>
            </div>
          </div>
          <div *ngIf="isSuperAdmin" class="col-2">
            <div class="form-group">
              <label for="client">Client</label>
              <span
                *ngIf="clientCodeLoader"
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"></span>
              <mdb-select
                [options]="clientList | sort : 'label'"
                id="client"
                [attr.data-test-id]="'search-member-client'"
                [allowClear]="true"
                [filterEnabled]="true"
                (selected)="onClientSelect($event)"
                [disabled]="clientCodeLoader"
                [visibleOptions]="2"
                (deselected)="removeSelection('client')"
                formControlName="client">
              </mdb-select>
            </div>
          </div>
          <div [ngClass]="isSuperAdmin ? 'col-2' : 'col-4'">
            <div class="form-group">
              <label for="purchaser">Purchaser</label>
              <span
                *ngIf="purchaserCodeLoader && isSuperAdmin"
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"></span>
              <mdb-select
                [options]="
                  isSuperAdmin
                    ? backupPurchaserListForSearch
                    : (purchaserListForSearch | sort : 'label')
                "
                [allowClear]="true"
                [filterEnabled]="true"
                [disabled]="purchaserCodeLoader"
                id="purchaser"
                [attr.data-test-id]="'search-member-purchaser'"
                [visibleOptions]="2"
                formControlName="purchaserCode"
                (deselected)="removeSelection('purchaser')">
              </mdb-select>
            </div>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-end mt-3">
        <button
          class="btn"
          [attr.data-test-id]="'clear-button'"
          style="background-color: white"
          mdbWavesEffect
          (click)="clearAdvanceSearch()">
          Clear
        </button>
        <button
          class="btn btn-secondary waves-light"
          [attr.data-test-id]="'search-button'"
          mdbWavesEffect
          (click)="searchMembers(1)">
          Search
        </button>
      </div>
    </div>
  </div>
  <!-- Advance search form -->

  <app-patient-search
    *ngIf="loggedUser.roleCode === 'PR'"
    [advanceSearchForm]="advanceSearchForm"
    [dateMask]="dateMask"
    [validPatientDob]="validPatientDob"
    (clearAdvanceSearch)="clearAdvanceSearch()"
    (searchMembers)="searchMembers($event)"></app-patient-search>

  <div *ngIf="loggedUser.roleCode !== 'PR'" class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table mdbTable stickyHeader="true" hover="true" small="true" class="v-top">
          <thead>
            <tr>
              <th *ngFor="let head of headElements" scope="col">
                {{ head }}
              </th>
            </tr>
            <tr></tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading.searching"
            [style.top]="members && members.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading.searching"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            mdbTableCol
            *ngFor="
              let member of members
                | paginate : {itemsPerPage: limit, currentPage: page, totalItems: totalCount};
              let i = index
            ">
            <tr [ngClass]="{'': member.isUser}">
              <td
                [attr.data-test-id]="'member-name-column'"
                scope="row"
                (click)="member.collapsed = !member.collapsed; getMemberPlans(member)">
                <span [attr.data-test-id]="'member-name'">
                  {{ member?.firstName }} {{ member?.middleName }}
                  {{ member?.lastName }}
                </span>
                <br />
                <!-- <span class="z-depth-0 bg-primary-dark badge text-white">
                  {{ member?.purchaserName }} </span
                ><br /> -->
                <span class="z-depth-0 bg-primary-dark badge text-white mr-2" *ngIf="member?.isSSO">
                  SSO
                </span>
                <span
                  *ngIf="!member?.manuallyCreated"
                  class="alert-info badge text-info z-depth-0 mr-2"
                  mdbTooltip="Eligibility File"
                  placement="top">
                  <i class="fas fa-file"></i>
                </span>
                <span
                  [attr.data-test-id]="'manual-patient-logo'"
                  *ngIf="member?.manuallyCreated"
                  class="alert-danger badge text-danger z-depth-0 mr-2"
                  mdbTooltip="Manual Entry"
                  placement="top">
                  <i class="fas fa-user-check"></i>
                </span>
              </td>
              <td [attr.data-test-id]="'member-dob-column'">
                {{ member?.dob | date : 'MM/dd/yyyy' }}
              </td>
              <td class="no-arrow-popover" [attr.data-test-id]="'member-subscriber-id-column'">
                <ng-template #popTemplate>
                  <div class="row">
                    <div class="col col-md-6">
                      <div class="list-content">
                        <div class="field-group field-group-small pt-0 pb-0">
                          <label class="pl-0">
                            <span class="d-none d-sm-inline-block">Member ID:</span>
                          </label>
                          <p>
                            {{ member?.memberId ? member.memberId : 'N/A' }}
                          </p>
                        </div>
                        <div class="field-group field-group-small pt-0 pb-0">
                          <label class="pl-0">
                            <span class="d-none d-sm-inline-block">Subscriber ID:</span>
                          </label>
                          <p>
                            {{ member?.subscriberId ? member.subscriberId : 'N/A' }}
                          </p>
                        </div>
                        <div class="field-group field-group-small pt-0 pb-0">
                          <label class="pl-0">
                            <span class="d-none d-sm-inline-block">Subscriber Number:</span>
                          </label>
                          <p>
                            {{ member?.subscriberNumber ? member.subscriberNumber : 'N/A' }}
                          </p>
                        </div>
                        <div class="field-group field-group-small pt-0 pb-0">
                          <label class="pl-0">
                            <span class="d-none d-sm-inline-block">Relationship:</span>
                          </label>
                          <p>
                            {{ member?.relationshipCode ? member.relationshipCode : 'N/A' }}
                          </p>
                        </div>
                        <div class="field-group field-group-small pt-0 pb-0">
                          <label class="pl-0">
                            <span class="d-none d-sm-inline-block">Employer Group:</span>
                          </label>
                          <p>
                            {{ getEmployerGroup(member) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <p
                  *ngIf="member.subscriberId"
                  class="link"
                  id="popTemplate"
                  mdbPopoverHeader="Subscription Information"
                  triggers="hover"
                  [mdbPopover]="popTemplate"
                  mdbWavesEffect>
                  {{ member?.subscriberId ? member.subscriberId : 'N/A' }}
                </p>
                <span *ngIf="!member.subscriberId"> N/A </span>
              </td>
              <td class="no-arrow-popover" [attr.data-test-id]="'member-program-id-column'">
                <span *ngIf="!member?.memberPlan?.length || !member?.memberPlan"> N/A </span>

                <div *ngIf="member?.memberPlan?.length">
                  <ng-template #popOverProgramID>
                    <div class="row g-2">
                      <div *ngFor="let memberPlan of member?.memberPlan">
                        <h6
                          style="
                            border-bottom: 1px solid #e7e7e7;
                            margin: 0 15px 0;
                            padding-bottom: 10px;
                          ">
                          {{ memberPlan?.clientName }}
                        </h6>
                        <div class="list-content col-6 mb-3" style="border: none">
                          <div class="field-group field-group-small pt-0 pb-0">
                            <label class="pl-0">
                              <span class="d-none d-sm-inline-block">Program ID:</span>
                            </label>
                            <p>
                              {{
                                memberPlan && memberPlan?.programId ? memberPlan.programId : 'N/A'
                              }}
                            </p>
                          </div>
                          <div class="field-group field-group-small pt-0 pb-0">
                            <label class="pl-0">
                              <span class="d-none d-sm-inline-block">Benefit Plan Code:</span>
                            </label>
                            <p>
                              {{ memberPlan?.benefitPlanCode ? memberPlan.benefitPlanCode : 'N/A' }}
                            </p>
                          </div>
                          <div class="field-group field-group-small pt-0 pb-0">
                            <label class="pl-0">
                              <span class="d-none d-sm-inline-block">Eligibility Source:</span>
                            </label>
                            <p>
                              {{
                                memberPlan?.eligibilitySource ? memberPlan.eligibilitySource : 'N/A'
                              }}
                            </p>
                          </div>
                          <div class="field-group field-group-small pt-0 pb-0">
                            <label class="pl-0">
                              <span class="d-none d-sm-inline-block">Benefit Status:</span>
                            </label>
                            <p class="text-primary">
                              {{
                                memberPlan?.benefitStatusCode ? memberPlan.benefitStatusCode : 'N/A'
                              }}
                            </p>
                          </div>
                          <div class="field-group field-group-small pt-0 pb-0">
                            <label class="pl-0">
                              <span class="d-none d-sm-inline-block">Effective Date:</span>
                            </label>
                            <p>
                              {{
                                memberPlan?.effectiveDate
                                  ? (memberPlan?.effectiveDate | date : 'MM/dd/yyyy')
                                  : 'N/A'
                              }}
                            </p>
                          </div>
                          <div class="field-group field-group-small pt-0 pb-0">
                            <label class="pl-0">
                              <span class="d-none d-sm-inline-block">Termination Date:</span>
                            </label>
                            <p>
                              {{
                                memberPlan?.terminationDate
                                  ? memberPlan.terminationDate == '9999-12-31'
                                    ? 'N/A'
                                    : (memberPlan.terminationDate | date : 'MM/dd/yyyy')
                                  : 'N/A'
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="member?.memberPlan && member.memberPlan.length == 0">
                        <span class="ml-3">
                          <strong>No Member Plans Available</strong>
                        </span>
                      </div>
                    </div>
                  </ng-template>
                  <div
                    #pop="bs-mdbPopover"
                    class="link"
                    triggers="hover"
                    [mdbPopover]="popOverProgramID"
                    mdbWavesEffect>
                    <span *ngFor="let item of member?.memberPlan" class="link m-0">
                      {{ item?.programId ? item.programId : 'N/A' }}
                      <br />
                    </span>
                  </div>
                </div>
              </td>
              <td data-test-id="member-effective-period-column">
                <div *ngIf="member?.memberPlan">
                  <span *ngFor="let item of member?.memberPlan" class="m-0">
                    {{ item?.effectiveDate ? (item.effectiveDate | date : 'MM/dd/yyyy') : 'N/A' }} -
                    {{
                      item?.terminationDate && item?.terminationDate !== '9999-12-31'
                        ? (item.terminationDate | date : 'MM/dd/yyyy')
                        : 'N/A'
                    }}
                    <br />
                  </span>
                </div>
                <span *ngIf="!member?.memberPlan?.length || !member?.memberPlan"> N/A </span>
              </td>

              <td [attr.data-test-id]="'member-contact-column'">
                <div class="inline-phone" [attr.data-test-id]="'member-phone-number-column'">
                  <div
                    class="pointer-cursor"
                    (click)="editPhone(member)"
                    *ngIf="
                      selected_member != member.uuid ||
                      (!phone_Edit && selected_member == member.uuid)
                    ">
                    <span *ngIf="!member?.phone" class="link">
                      <!-- <i class="icofont-plus-circle text-info"> </i> -->
                      Add Phone
                    </span>
                    {{ member?.phone }}
                  </div>
                  <form
                    [formGroup]="phoneForm"
                    class="align-items-start"
                    *ngIf="phone_Edit && selected_member == member.uuid">
                    <div class="d-flex mb-0">
                      <div class="form-group m-0">
                        <input
                          style="width: 150px"
                          type="text"
                          id="patientphone"
                          class="form-control patient-input"
                          formControlName="phone"
                          [textMask]="{mask: phoneMask}"
                          (focus)="onFocusIn()"
                          (focusout)="onFocusOut('phone')"
                          [readonly]="loading.saving"
                          mdbInput
                          [ngClass]="{'is-invalid': submitted && phoneForm.get('phone').invalid}" />
                      </div>
                      <button
                        *ngIf="!loading.saving"
                        class="btn btn-flat waves-effect m-0 pl-2 pr-2 skin-link"
                        mdbWavesEffect
                        type="submit"
                        (click)="submit('Phone', member)">
                        <mdb-icon fas icon="save"></mdb-icon>
                      </button>
                      <button
                        *ngIf="!loading.saving"
                        class="btn btn-flat waves-effect m-0 pl-2 pr-2"
                        (click)="closeEdit()"
                        mdbWavesEffect
                        type="submit">
                        <mdb-icon fas icon="times"></mdb-icon>
                      </button>
                      <div class="d-flex align-items-center">
                        <span
                          *ngIf="loading.saving"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"></span>
                      </div>
                    </div>
                    <p class="mb-0">Is this a mobile phone? *</p>
                    <div class="d-flex">
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="Yes{{ i }}"
                          name="isMobile"
                          [value]="true"
                          (click)="onEditPhoneRadioSelected()"
                          formControlName="isMobile"
                          mdbInput />
                        <label class="form-check-label" for="Yes{{ i }}">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="No{{ i }}"
                          name="isMobile"
                          [value]="false"
                          (click)="onEditPhoneRadioSelected()"
                          formControlName="isMobile"
                          mdbInput />
                        <label class="form-check-label" for="No{{ i }}">No</label>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="inline-email" [attr.data-test-id]="'member-email-column'">
                  <div
                    class="pointer-cursor"
                    (click)="editEmail(member)"
                    *ngIf="
                      selected_member != member.uuid ||
                      (!email_Edit && selected_member == member.uuid)
                    ">
                    <span *ngIf="!member?.email" class="link">
                      <!-- <i class="icofont-plus-circle text-info"> </i> -->
                      Add Email
                    </span>
                    {{ member?.email }}
                  </div>
                  <form
                    [formGroup]="emailForm"
                    class="form-inline align-items-start"
                    *ngIf="selected_member == member.uuid && email_Edit">
                    <div class="d-flex mb-2">
                      <div class="form-group" class="form-inline align-items-start">
                        <input
                          type="text"
                          id="patientEmail"
                          class="form-control patient-input"
                          formControlName="email"
                          mdbInput
                          [ngClass]="{'is-invalid': submitted && emailForm.get('email').invalid}" />
                      </div>
                      <button
                        *ngIf="!loading.saving"
                        class="btn btn-flat waves-effect m-0 pl-2 pr-2 skin-link"
                        mdbWavesEffect
                        type="submit"
                        (click)="submit('Email', member)">
                        <mdb-icon fas icon="save"></mdb-icon>
                      </button>
                      <button
                        *ngIf="!loading.saving"
                        class="btn btn-flat waves-effect m-0 pl-2 pr-2"
                        (click)="closeEdit()"
                        mdbWavesEffect
                        type="submit">
                        <mdb-icon fas icon="times"></mdb-icon>
                      </button>
                      <div class="d-flex align-items-center">
                        <span
                          *ngIf="loading.saving"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"></span>
                      </div>
                    </div>
                  </form>
                </div>
              </td>
              <td
                [attr.data-test-id]="'member-address-column'"
                *ngIf="
                  member?.residentialAddressLine1 ||
                  member?.residentialAddressLine2 ||
                  member?.residentialCity ||
                  member?.residentialState ||
                  member?.zip
                "
                width="222"
                (click)="member.collapsed = !member.collapsed">
                {{ member?.residentialAddressLine1 ? member.residentialAddressLine1 : '' }}
                {{ member.residentialAddressLine2 ? member.residentialAddressLine2 : '' }}
                <br *ngIf="member?.residentialAddressLine1 || member?.residentialAddressLine2" />
                {{ member?.residentialCity ? member.residentialCity : '' }}
                {{ member?.residentialState ? member.residentialState : '' }}
                {{
                  member?.residentialZip
                    ? member.residentialZip4
                      ? member.residentialZip + '-' + member.residentialZip4
                      : member.residentialZip
                    : ''
                }}
              </td>
              <td
                [attr.data-test-id]="'member-note-column'"
                *ngIf="
                  !(
                    member?.residentialAddressLine1 ||
                    member?.residentialAddressLine2 ||
                    member?.residentialCity ||
                    member?.residentialState ||
                    member?.residentialZip
                  )
                "
                width="222">
                N/A
              </td>
              <td class="text-center align-middle" width="20">
                <i
                  *ngIf="member.note"
                  class="icofont-notepad btn-link pointer-cursor ml-1"
                  [mdbPopover]="notePopOver"
                  placement="top"
                  triggers="hover"></i>
                <ng-template #notePopOver>
                  <div class="m-1" style="max-width: 350px">
                    {{ member?.note | slice : 0 : 105 }}
                    <span *ngIf="member?.note?.length > 105">...</span>
                  </div>
                </ng-template>
              </td>
              <td
                class="text-center align-middle"
                [attr.data-test-id]="'member-doc-column'"
                width="190">
                <button
                  class="z-depth-0 m-0 btn btn-sm btn-view-eoc"
                  [attr.data-test-id]="'member-doc-button'"
                  type="button"
                  (click)="onViewEoc(member)"
                  mdbWavesEffect>
                  <div class="d-flex align-items-center">
                    <i *ngIf="!member.loading" class="far fa-eye fa-fw"></i>
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="member.loading"></span>
                    <span class="pl-1">View EOC</span>
                  </div>
                </button>
              </td>
              <td [attr.data-test-id]="'member-ellipsis-column'">
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    [attr.data-test-id]="'custom-dropdown'"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div class="custom-dropdown-content rounded-sm z-depth-2">
                    <a
                      *ngIf="
                        member.manuallyCreated &&
                        (loggedUser.roleCode === 'PA' ||
                          loggedUser.roleCode === 'CC' ||
                          loggedUser.roleCode === 'ES')
                      "
                      class="dropdown-item"
                      (click)="editPatient(member)">
                      <mdb-icon fas icon="edit fa-fw" class="mr-1"></mdb-icon>
                      Edit
                    </a>
                    <a
                      *ngIf="!member?.isInvited"
                      class="dropdown-item"
                      (click)="inviteToPatient(member)">
                      <mdb-icon fas icon="paper-plane fa-fw" class="mr-1"></mdb-icon> Invite Patient
                    </a>
                    <a
                      *ngIf="!member.isInvited && member?.purchaserCode === 'MEDICAID'"
                      class="dropdown-item"
                      (click)="inviteToPatient(member, 'sms')">
                      <mdb-icon fas icon="paper-plane fa-fw" class="mr-1"></mdb-icon> Invite Patient
                      By SMS
                    </a>
                    <a
                      *ngIf="member.isUser"
                      class="dropdown-item"
                      (click)="addEditNote(member, !member.note)">
                      <mdb-icon fas icon="file-alt fa-fw" class="mr-1"></mdb-icon>
                      {{ member?.note ? 'Edit' : 'Add' }} Note
                    </a>
                    <a class="dropdown-item" (click)="createPatientEpisode(member)">
                      <mdb-icon fas icon="layer-group fa-fw" class="mr-1"></mdb-icon> Create Episode
                    </a>
                    <a
                      *ngIf="member?.isInvited && !member?.isRegistered && !member?.isSSO"
                      class="dropdown-item"
                      (click)="openResendInviation(member)">
                      <mdb-icon fas icon="envelope-open-text fa-fw" class="mr-1"></mdb-icon>
                      Resend Invitation
                    </a>
                    <a
                      *ngIf="isResetPasswordAvailable(member)"
                      class="dropdown-item"
                      (click)="openResetPassword(member)">
                      <mdb-icon fas icon="redo fa-fw" class="mr-1"></mdb-icon> Reset Password
                    </a>
                    <a
                      *ngIf="
                        member?.manuallyCreated &&
                        !member?.isUser &&
                        !member?.isRegistered &&
                        !member?.isSSO
                      "
                      class="dropdown-item"
                      (click)="deletePatient(member)">
                      <mdb-icon fas icon="trash-alt fa-fw" class="mr-1"></mdb-icon> Delete
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <!-- <tr *ngIf="member.collapsed" class="grey lighten-4">
              <td colspan="100%" class="p-3">
                <div class="row">
                  <div class="col-7">
                    <h6>Subscription Information</h6>
                    <div class="row">
                      <div class="col col-md-6">
                        <div class="list-content">
                          <div class="field-group pt-0 pb-0">
                            <label
                              ><i class="icofont-id"></i>
                              <span class="d-none d-sm-inline-block">Member ID:</span></label
                            >
                            <div class="field-value">
                              {{ member?.memberId ? member.memberId : 'N/A' }}
                            </div>
                          </div>
                          <div class="field-group pt-0 pb-0">
                            <label
                              ><i class="icofont-id"></i>
                              <span class="d-none d-sm-inline-block">Subscriber ID:</span></label
                            >
                            <div class="field-value">
                              {{ member?.subscriberId ? member.subscriberId : 'N/A' }}
                            </div>
                          </div>
                          <div class="field-group pt-0 pb-0">
                            <label
                              ><i class="icofont-id"></i>
                              <span class="d-none d-sm-inline-block"
                                >Subscriber Number:</span
                              ></label
                            >
                            <div class="field-value">
                              {{ member?.subscriberNumber ? member.subscriberNumber : 'N/A' }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col col-md-6">
                        <div class="list-content border-top-0">
                          <div class="field-group pt-0 pb-0">
                            <label
                              ><i class="icofont-users"></i>
                              <span class="d-none d-sm-inline-block">Relationship:</span></label
                            >
                            <div class="field-value">
                              {{ member?.relationshipCode ? member.relationshipCode : 'N/A' }}
                            </div>
                          </div>
                          <div class="field-group pt-0 pb-0">
                            <label
                              ><i class="icofont-workers-group"></i>
                              <span class="d-none d-sm-inline-block">Employer Group:</span></label
                            >
                            <div class="field-value">
                              {{ member?.employerGroupNumber ? member.employerGroupNumber : 'N/A' }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="member.isUser" class="col">
                    <mdb-card class="z-depth-0">
                      <mdb-card-body class="pb-0">
                        <mdb-card-title>
                          <span class="d-flex justify-content-between">
                            <h6><i class="icofont-notepad"></i> Notes</h6>
                            <button
                              (click)="editNote(member)"
                              *ngIf="!member?.noteEditable"
                              class="btn btn-flat waves-effect m-0 pt-0 pl-2 pr-2 skin-link"
                              mdbWavesEffect
                              type="submit">
                              <mdb-icon fas icon="pencil-alt"></mdb-icon> Edit
                            </button>
                          </span>
                        </mdb-card-title>
                        <mdb-card-text>
                          <span
                            *ngIf="!member?.noteEditable"
                            class="content-editable textarea mw-100 overflow-auto scrollbar scrollbar-primary thin mb-3">
                            {{ member?.note }}
                          </span>
                          <textarea
                            class="form-control"
                            *ngIf="member?.noteEditable"
                            [(ngModel)]="member.note">
                          </textarea>
                        </mdb-card-text>
                        <span *ngIf="member?.noteEditable">
                          <button
                            (click)="submit('Note', member)"
                            class="btn btn-flat waves-effect m-0 pl-2 pr-2 skin-link"
                            mdbWavesEffect
                            type="submit">
                            <mdb-icon fas icon="save"></mdb-icon> Save
                          </button>
                          <button
                            class="btn btn-flat waves-effect m-0 pl-2 pr-2"
                            (click)="closeEdit(member)"
                            mdbWavesEffect
                            type="submit">
                            <mdb-icon fas icon="times"></mdb-icon> Cancel
                          </button>
                        </span>
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                </div>
                <app-loader [loader]="!member?.memberPlan"></app-loader>
                <div *ngIf="member?.memberPlan && member.memberPlan.length > 0">
                  <p class="font-weight-bold mt-3">Member Plan</p>
                  <div class="row">
                    <div class="col-4" *ngFor="let memberPlan of member?.memberPlan">
                      <mdb-card class="z-depth-0 bg-blue-light border mb-2">
                        <mdb-card-header class="p-2">
                          <h6 class="mb-0">
                            {{ memberPlan?.clientName ? memberPlan.clientName : 'N/A' }}
                          </h6>
                        </mdb-card-header>
                        <mdb-card-body class="pt-2 pr-2 pb-0 pl-0">
                          <table mdbTable class="table-xs mb-0">
                            <tbody class="bg-transparent">
                              <tr>
                                <td>Program ID</td>
                                <td>
                                  {{ memberPlan?.programId ? memberPlan.programId : 'N/A' }}
                                </td>
                              </tr>
                              <tr>
                                <td>Benefit Plan Code</td>
                                <td>
                                  <span
                                    class="bg-secondary mr-1 badge mb-1 text-white rounded-sm z-depth-0">
                                    {{
                                      memberPlan?.benefitPlanCode
                                        ? memberPlan.benefitPlanCode
                                        : 'N/A'
                                    }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>Eligibility Source</td>
                                <td>
                                  {{
                                    memberPlan?.eligibilitySource
                                      ? memberPlan.eligibilitySource
                                      : 'N/A'
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Benefit Status</td>
                                <td>
                                  <mdb-badge pill="true" success="true" class="mb-1 z-depth-0">
                                    {{
                                      memberPlan?.benefitStatusName
                                        ? memberPlan.benefitStatusName
                                        : 'N/A'
                                    }}
                                  </mdb-badge>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </mdb-card-body>
                        <mdb-card-footer class="d-flex justify-content-between p-2">
                          <span>
                            <label for="">Effective Date</label>
                            <p class="font-weight-bold mb-0">
                              {{
                                memberPlan?.effectiveDate
                                  ? (memberPlan?.effectiveDate | date : 'MM/dd/yyyy')
                                  : 'N/A'
                              }}
                            </p>
                          </span>
                          <span>
                            <label for="">Termination Date</label>
                            <p class="font-weight-bold mb-0">
                              {{
                                memberPlan?.terminationDate
                                  ? memberPlan.terminationDate == '9999-12-31'
                                    ? 'N/A'
                                    : (memberPlan.terminationDate | date : 'MM/dd/yyyy')
                                  : 'N/A'
                              }}
                            </p>
                          </span>
                        </mdb-card-footer>
                      </mdb-card>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="member?.memberPlan && member.memberPlan.length == 0">
                  <span class="ml-3">
                    <strong>No Member Plans Available</strong>
                  </span>
                </div>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      *ngIf="members && members.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>

  <app-patient-list
    *ngIf="loggedUser.roleCode === 'PR'"
    [members]="members"
    [limit]="limit"
    [totalCount]="totalCount"
    [page]="page"
    [loading]="loading"
    (pageChanged)="pageChanged($event)"></app-patient-list>

  <div
    class="text-center mt-4"
    *ngIf="!loading.searching && !patientSearched && members && members.length == 0">
    <h5>Search Patient Eligibility Records</h5>
    <p>Use search or advance search to search patient.</p>
  </div>
  <div class="text-center mt-4" *ngIf="!loading.searching && patientSearched && totalCount == 0">
    <h5>We couldn't find what you're looking for.</h5>
    <p>
      Refine your search with advance search option or use add button to add patient to directory
      list.
    </p>
  </div>
</section>

<!-- Invite Patient Modal -->
<div
  mdbModal
  #memberModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content add-user-modal">
      <div class="modal-header flex-column">
        <h5 class="modal-title w-100">Invite Patient</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
        <div
          class="alert alert-info p-3 d-flex align-items-center mb-0 mt-3 w-100"
          role="alert"
          *ngIf="memberInfo?.isSSO">
          <i class="fas fa-exclamation-triangle fa-lg mr-3 text-info"></i>
          <span>
            <h6 class="mb-0">SSO Patient</h6>
          </span>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="memberForm">
          <div *ngIf="!inviteBySMS" class="">
            <div class="row mb-3">
              <div class="col-md-1 col-1">
                <i class="icofont-email animated rotateIn icofont-2x text-success-dark"></i>
              </div>
              <div class="col-md-11 col-11">
                <p class="mb-0">Send Activation Link *</p>
                <small>Enter email address to send activation link to the member</small>
                <div class="form-group d-flex justify-content-start">
                  <input
                    type="text"
                    id="email"
                    class="form-control"
                    formControlName="email"
                    mdbInput
                    [ngClass]="{
                      'is-invalid': submitted && memberForm.get('email').invalid
                    }" />
                </div>
              </div>
            </div>
          </div>
          <hr *ngIf="!inviteBySMS" />
          <div class="">
            <div class="row mb-3">
              <div class="col-md-1 col-1">
                <i class="icofont-phone animated rotateIn icofont-2x text-success-dark"></i>
              </div>
              <div class="col-md-11 col-11">
                <p class="mb-0">Send SMS *</p>
                <small>Enter the primary phone contact for the patient.</small>
                <div class="form-group d-flex justify-content-start">
                  <input
                    type="text"
                    [textMask]="{mask: phoneMask}"
                    id="phone"
                    class="form-control"
                    formControlName="phone"
                    mdbInput
                    [ngClass]="{
                      'is-invalid': submitted && memberForm.get('phone').invalid
                    }" />
                </div>
                <div class="form-check form-check-inline mt-2">
                  <small>Is this a mobile phone? *</small>
                  <input
                    type="radio"
                    class="form-check-input"
                    id="inviteYes"
                    name="isMobile"
                    [value]="true"
                    formControlName="isMobile"
                    mdbInput />
                  <label class="form-check-label" for="inviteYes">Yes</label>
                </div>
                <div class="form-check form-check-inline mt-2">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="inviteNo"
                    name="isMobile"
                    [value]="false"
                    formControlName="isMobile"
                    (change)="handleChange($event, 'memberForm')" />
                  <label class="form-check-label" for="inviteNo">No</label>
                </div>
                <br />
                <div class="form-check form-check-inline mt-2">
                  <small>Invite by</small>
                  <input
                    type="radio"
                    class="form-check-input"
                    id="inviteByEmail"
                    name="inviteType"
                    [value]="true"
                    formControlName="inviteType"
                    mdbInput />
                  <label class="form-check-label" for="inviteByEmail">Email</label>
                </div>
                <!-- <div
                  class="form-check form-check-inline mt-2"
                  [ngClass]="{
                    'element-disabled':
                      !memberForm.value.isMobile || (memberForm.value.isMobile && memberInfo?.isSSO)
                  }">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="inviteByPhone"
                    name="inviteType"
                    [value]="false"
                    formControlName="inviteType"
                    [disableControl]="
                      !memberForm.value.isMobile || (memberForm.value.isMobile && memberInfo?.isSSO)
                    " />
                  <label class="form-check-label" for="inviteByPhone">Phone</label>
                </div> -->
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submit('Member')"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          Invite
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Patient Modal -->
<div
  mdbModal
  #patientModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content add-user-modal">
      <div class="modal-header">
        <h5 class="modal-title w-100">Edit Note</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closePatientModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="phoneForm">
          <div class="form-group">
            <label for="userNotes">Notes</label>
            <textarea
              type="text"
              id="userNotes"
              class="md-textarea form-control"
              mdbInput
              formControlName="phone"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closePatientModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitPhone()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add/Edit Patient Modal -->
<div
  mdbModal
  #addPatientModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ patientForm.value.id ? 'Edit' : 'Add' }} Patient</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="patientForm">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="patientFirstname">First Name *</label>
                <input
                  type="text"
                  id="patientFirstname"
                  [attr.data-test-id]="'patient-firstname'"
                  class="form-control"
                  formControlName="firstName"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && patientForm.get('firstName').invalid}" />
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="patientMiddleName">Middle Name</label>
                <input
                  type="text"
                  id="patientMiddlename"
                  [attr.data-test-id]="'patient-middlename'"
                  class="form-control"
                  formControlName="middleName"
                  mdbInput />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="patientLastname">Last Name *</label>
                <input
                  type="text"
                  id="patientLastname"
                  [attr.data-test-id]="'patient-lastname'"
                  class="form-control"
                  formControlName="lastName"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && patientForm.get('lastName').invalid}" />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label for="nameSuffix">Name Suffix</label>
                <mdb-select
                  [options]="nameSuffixList"
                  id="nameSuffix"
                  [attr.data-test-id]="'patient-namesuffix'"
                  formControlName="nameSuffix"
                  placeholder="Select">
                </mdb-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="purchasers">Purchaser *</label>
                  <mdb-select
                    [options]="purchaserList | sort : 'label'"
                    id=" purchasers"
                    [attr.data-test-id]="'patient-purchasers-dropdown'"
                    placeholder="Select purchaser"
                    (selected)="purchaserSelected($event)"
                    formControlName="purchaserCode"
                    [ngClass]="{
                      'error-select': submitted && patientForm.get('purchaserCode').invalid
                    }">
                  </mdb-select>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="patientGender">Gender * </label>
                  <mdb-select
                    [options]="gender"
                    id="patientGender"
                    [attr.data-test-id]="'patient-gender'"
                    formControlName="gender"
                    placeholder="Gender"
                    [ngClass]="{
                      'error-select': submitted && patientForm.get('gender').invalid
                    }">
                  </mdb-select>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="patientDob">Date of Birth * </label>
                  <input
                    type="text"
                    id="patientDob"
                    [attr.data-test-id]="'patient-dob'"
                    placeholder="MM/DD/YYYY"
                    class="form-control"
                    [textMask]="{mask: dateMask}"
                    formControlName="dob"
                    [ngClass]="{
                      'is-invalid': submitted && (patientForm.get('dob').invalid || !validDob)
                    }" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="patientSsNumber">Social Security No.</label>
                  <input
                    type="text"
                    id="patientSsNumber"
                    [attr.data-test-id]="'patient-security-number'"
                    class="form-control"
                    formControlName="ssn"
                    numbersOnly
                    maxlength="9"
                    mdbInput
                    [ngClass]="{'is-invalid': submitted && patientForm.get('ssn').invalid}" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="patientPhone">Phone *</label>
                <input
                  type="text"
                  id="patientPhone"
                  [attr.data-test-id]="'patient-phone'"
                  class="form-control"
                  formControlName="phone"
                  [textMask]="{mask: phoneMask}"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && patientForm.get('phone').invalid}" />
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label for="patientEmail">Email *</label>
                <input
                  type="text"
                  id="patientEmail"
                  [attr.data-test-id]="'patient-email'"
                  class="form-control"
                  formControlName="email"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && patientForm.get('email').invalid}" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label for="isMobile">Is this a mobile phone? </label>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="Yes"
                    [attr.data-test-id]="'patient-ismobile-yes'"
                    name="isMobile"
                    [value]="true"
                    formControlName="isMobile"
                    mdbInput />
                  <label class="form-check-label" [attr.data-test-id]="'patient-checkbox'" for="Yes"
                    >Yes</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="No"
                    [attr.data-test-id]="'patient-ismobile-no'"
                    name="isMobile"
                    [value]="false"
                    formControlName="isMobile"
                    mdbInput />
                  <label class="form-check-label" for="No">No</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="patientEmail">Address</label>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="patientAddress1"
                  [attr.data-test-id]="'patient-address1'"
                  class="form-control"
                  formControlName="residentialAddressLine1"
                  mdbInput
                  placeholder="Address line 1"
                  [ngClass]="{
                    'is-invalid': submitted && patientForm.get('residentialAddressLine1').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  id="patientCity"
                  [attr.data-test-id]="'patient-city'"
                  placeholder="City"
                  class="form-control"
                  formControlName="residentialCity"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': submitted && patientForm.get('residentialCity').invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <input
                  type="text"
                  id="patientAddress2"
                  [attr.data-test-id]="'patient-address2'"
                  class="form-control"
                  formControlName="residentialAddressLine2"
                  mdbInput
                  placeholder="Address line 2"
                  [ngClass]="{
                    'is-invalid': submitted && patientForm.get('residentialAddressLine2').invalid
                  }" />
              </div>
            </div>
            <div class="col-2">
              <mdb-select
                [options]="usStates | sort : 'label'"
                id="patientState"
                [attr.data-test-id]="'patient-state'"
                formControlName="residentialState"
                placeholder="State"
                [ngClass]="{
                  'error-select': submitted && patientForm.get('residentialState').invalid
                }">
              </mdb-select>
            </div>
            <div class="col">
              <input
                type="text"
                id="patientZip"
                [attr.data-test-id]="'patient-zip'"
                class="form-control"
                formControlName="residentialZip"
                placeholder="Zip"
                numbersOnly
                maxlength="5"
                mdbInput
                [ngClass]="{
                  'is-invalid': submitted && patientForm.get('residentialZip').invalid
                }" />
            </div>
            <div>
              <span class="zip-dash">-</span>
            </div>
            <div class="col">
              <input
                type="text"
                id="patientZip4"
                [attr.data-test-id]="'patient-zip4'"
                class="form-control"
                formControlName="residentialZip4"
                placeholder="Zip4"
                numbersOnly
                maxlength="4"
                mdbInput
                [readonly]="!patientForm.value.residentialZip"
                [ngClass]="{
                  'is-invalid': submitted && patientForm.get('residentialZip4').invalid
                }" />
            </div>
          </div>
          <div class="form-group">
            <label for="patientNotes">Notes</label>
            <textarea
              type="text"
              id="patientNotes"
              [attr.data-test-id]="'patient-note'"
              class="md-textarea form-control"
              mdbInput
              [readonly]="patientForm.value.id"
              formControlName="note"></textarea>
          </div>
          <div
            class="form-group"
            [attr.data-test-id]="'checked-box'"
            *ngIf="loggedUser.roleCode === 'PA'">
            <mdb-checkbox
              formControlName="isAdditional"
              [disableControl]="isChecked ? true : false"
              (change)="onAdditionalFieldClick($event)"
              [(ngModel)]="isAdditionalField">
              Show Subscription Information
            </mdb-checkbox>
          </div>
          <div class="form-row" *ngIf="isAdditionalField">
            <div class="col">
              <div class="form-group">
                <label for="patientSubscriberId">Subscriber ID *</label>
                <input
                  type="text"
                  id="patientSubscriberId"
                  [attr.data-test-id]="'patient-subscriberid'"
                  class="form-control"
                  formControlName="subscriberId"
                  mdbInput
                  [ngClass]="{
                    'is-invalid': submitted && patientForm.get('subscriberId').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="patientMemberId">Member ID *</label>
                <input
                  type="text"
                  id="patientMemberId"
                  [attr.data-test-id]="'patient-memberid'"
                  class="form-control"
                  numbersOnly
                  formControlName="memberId"
                  maxlength="10"
                  mdbInput
                  [ngClass]="{'is-invalid': submitted && patientForm.get('memberId').invalid}" />
              </div>
            </div>
          </div>
          <div class="form-row" *ngIf="isAdditionalField">
            <div class="col">
              <div class="form-group">
                <label for="patientRelation">Relation *</label>
                <mdb-select
                  [options]="relationShipList"
                  id="patientRelation"
                  [attr.data-test-id]="'patient-relation'"
                  formControlName="relationshipCode"
                  placeholder="Select relation"
                  [ngClass]="{
                    'error-select': submitted && patientForm.get('relationshipCode').invalid
                  }">
                </mdb-select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="clientProgramCode">Program ID *</label>
                <!-- <input type="text" id="clientProgramCode" class="form-control"
                                    formControlName="clientProgramCode" mdbInput /> -->
                <mdb-select
                  [options]="vbpList | sort : 'label'"
                  id=" vbps"
                  [attr.data-test-id]="'patient-id'"
                  formControlName="clientProgramCode"
                  (selected)="vbpSelected($event)"
                  [ngClass]="{
                    'error-select': submitted && patientForm.get('clientProgramCode').invalid
                  }">
                </mdb-select>
              </div>
            </div>
          </div>
          <div class="form-row" *ngIf="isAdditionalField">
            <div class="col-6">
              <div class="form-group">
                <label for="employerGroupNumber">Employer Group Number</label>
                <input
                  type="number"
                  numbersOnly
                  id="employerGroupNumber"
                  [attr.data-test-id]="'patient-employergroupnumber'"
                  class="form-control"
                  formControlName="employerGroupNumber"
                  mdbInput />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="patientBenefitStatusCode">Benefit Status *</label>
                <mdb-select
                  [options]="benefitStatusList"
                  id="patientBenefitStatusCode"
                  [attr.data-test-id]="'patient-benefitstatuslist'"
                  formControlName="benefitStatusCode"
                  placeholder="Select benefit status"
                  [ngClass]="{
                    'error-select': submitted && patientForm.get('benefitStatusCode').invalid
                  }">
                </mdb-select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="plancode">Benefit Plan Code</label>
                <input
                  type="text"
                  id="plancode"
                  [attr.data-test-id]="'patient-plancode'"
                  class="form-control"
                  formControlName="planCode" />
              </div>
            </div>
          </div>
          <div class="form-row" *ngIf="isAdditionalField">
            <div class="col-6">
              <div class="form-group">
                <label for="planSponser">Eligibility Source</label>
                <input
                  type="text"
                  id="planSponser"
                  [attr.data-test-id]="'patient-plansponser'"
                  class="form-control"
                  formControlName="eligibilitySource" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="patientEffectiveDate">Effective Date * </label>
                <input
                  type="text"
                  id="patientEffectiveDate"
                  [attr.data-test-id]="'patient-effectivedate'"
                  placeholder="MM/DD/YYYY"
                  [textMask]="{mask: dateMask}"
                  class="form-control"
                  formControlName="effectiveDate"
                  [ngClass]="{
                    'is-invalid': submitted && patientForm.get('effectiveDate').invalid
                  }" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="patientTerminationDate">Termination Date </label>
                <input
                  type="text"
                  id="patientTerminationDate"
                  [attr.data-test-id]="'patient-terminationdate'"
                  placeholder="MM/DD/YYYY"
                  [textMask]="{mask: dateMask}"
                  class="form-control"
                  formControlName="terminationDate"
                  [ngClass]="{
                    'is-invalid': submitted && patientForm.get('terminationDate').invalid
                  }" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          [attr.data-test-id]="'patient-cancel-button'"
          (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          [attr.data-test-id]="'patient-submit-button'"
          mdbWavesEffect
          (click)="submit('Patient')"
          [disabled]="loading.saving">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading.saving"></span>
          {{ patientForm.value.id ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Resend Invitation -->
<div
  mdbModal
  #resendInvitaionModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content add-user-modal">
      <div class="modal-header">
        <h5 class="modal-title w-100">Resend Invitation</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeResendModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="resendInvitationForm">
          <div class="form-check form-check-inline mt-2">
            <small>Invite by</small>
            <input
              type="radio"
              class="form-check-input"
              id="rinviteByEmail"
              name="inviteType"
              [value]="true"
              formControlName="inviteType"
              mdbInput />
            <label class="form-check-label" for="rinviteByEmail">Email</label>
          </div>
          <!-- <div
            class="form-check form-check-inline mt-2"
            [ngClass]="{'element-disabled': disablePhone}">
            <input
              type="radio"
              class="form-check-input"
              id="rinviteByPhone"
              name="inviteType"
              [value]="false"
              formControlName="inviteType"
              [disableControl]="disablePhone" />
            <label class="form-check-label" for="rinviteByPhone">Phone</label>
          </div> -->
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeResendModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitResend()"
          [disabled]="submit_enabled">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          Send
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<app-delete-confirmation
  [_id]="deleteId"
  [message]="message"
  (confirmDelete)="confirmDelete($event)">
</app-delete-confirmation>

<!-- View EOC Modal -->
<div
  mdbModal
  #viewEocModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Eligible EOC</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeEOCModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center">
          <div class="avatar text-center user-initials mr-2">
            <b>{{ member?.nameInitials }}</b>
          </div>
          <div>
            <p class="mb-0"><small>Patient</small></p>
            <p class="mb-0">
              {{ member?.firstName }} {{ member?.middleName }} {{ member?.lastName }}
            </p>
          </div>
        </div>
        <div class="form-group input-group has-search mt-4 mb-3">
          <span class="form-control-feedback left-0">
            <i class="icofont-search-1"></i>
          </span>
          <input
            type="text"
            [ngModel]="searchText"
            (ngModelChange)="searchByKeyword($event, member)"
            class="form-control"
            id="search"
            placeholder="Search CPT, episode of care" />
        </div>
        <div
          #scrollWrapper
          class="scrollbar scrollbar-primary thin overflow-auto"
          style="max-height: 200px">
          <ul
            *ngIf="filteredEligibleEocList && filteredEligibleEocList.length"
            class="list-group list-group-flush">
            <li
              *ngFor="let element of filteredEligibleEocList"
              class="list-group-item pointer-cursor px-1 py-2">
              <small class="mb-0">{{ element.name }} ({{ element.vbpCode }})</small>
            </li>
          </ul>
          <p *ngIf="filteredEligibleEocList && !filteredEligibleEocList.length" class="mb-0">
            <small>No episode of care found.</small>
          </p>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="btn waves-light m-0 btn-light" mdbWavesEffect (click)="closeEOCModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div
  mdbModal
  #addEditNoteModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ selectedMember?.note ? 'Edit' : 'Add' }} Note</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeNoteModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <form [formGroup]="addEditNoteForm">
            <div class="form-row">
              <div class="col-6">
                <div class="form-group">
                  <textarea
                    style="width: 568px; height: 100px"
                    class="md-textarea form-control"
                    type="text"
                    mdbInput
                    formControlName="note">
                  </textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeNoteModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          [disabled]="submitted"
          (click)="submitAddEditNote()">
          <span
            *ngIf="submitted"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"></span>
          &nbsp;
          {{ selectedMember?.note ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>
