<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center mt-4 action position-sticky">
    <div class="form-group has-search input-group mt-2 mb-2 w-25 w-xs-100">
      <span class="form-control-feedback">
        <i class="icofont-search-1"></i>
      </span>
      <input
        type="text"
        [(ngModel)]="searchKeyword"
        class="form-control"
        id="search"
        placeholder="Search"
        autocomplete="off" />
      <div class="input-group-append" *ngIf="searchKeyword">
        <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
          <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end w-50 mt-2 mb-2">
      <div style="width: 40%; margin-top: 5px">
        <app-select
          [options]="networks"
          [placeholder]="'Select network'"
          (onSelected)="filterBundleCosts($event)"
          (onDeselected)="filterBundleCosts($event, 'remove')"
          [allowClear]="true">
        </app-select>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="createBundleCost()"
          mdbWavesEffect>
          <i class="icofont-plus"></i> ADD
        </button>
      </div>
    </div>
  </div>
  <table mdbTable hover="true" small="true" stickyHeader="true">
    <thead id="scrollToTop">
      <tr>
        <th width="20"></th>
        <!-- <th [class.active]="order === 'displayBundle'" (click)="setOrder('displayBundle')">Bundle Name
                    <span *ngIf="order === 'displayBundle'">
                        <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
                        <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
                    </span>
                </th>
                <th [class.active]="order === 'displayMarket'" (click)="setOrder('displayMarket')">
                    Market
                    <span *ngIf="order === 'displayMarket'">
                        <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
                        <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
                    </span>
                </th>
                <th width="200" [class.active]="order === 'createdDate'" (click)="setOrder('createdDate')">Created Date
                    <span *ngIf="order === 'createdDate'">
                        <span [hidden]="reverse"><i class="fas fa-sort-down "></i></span>
                        <span [hidden]="!reverse"><i class="fas fa-sort-up "></i></span>
                    </span>
                </th> -->
        <th>Bundle Name</th>
        <th>Market</th>
        <th>Created Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngFor="let bundleCostGroup of bundleCostGroups | keyvalue : valueAscOrder">
      <ng-container
        *ngFor="
          let bundleCost of bundleCostGroup.value
            | filter : searchKeyword : ['displayBundle', 'displayProvider', 'totalCost']
            | orderBy : order : reverse : true;
          let i = index
        ">
        <tr *ngIf="i === 0" [ngClass]="{'grey lighten-4 border': bundleCost.collapsed}">
          <td (click)="showBundleCostDetails(bundleCostGroup.value[0])">
            <button
              type="button"
              class="btn btn-link p-0 m-0"
              mdbWavesEffect
              *ngIf="bundleCostGroup.value[0].collapsed">
              <i class="icofont-minus-circle icofont-lg text-info"></i>
            </button>
            <button
              type="button"
              class="btn btn-link p-0 m-0"
              mdbWavesEffect
              *ngIf="!bundleCostGroup.value[0].collapsed">
              <i class="icofont-plus-circle icofont-lg text-info"></i>
            </button>
          </td>
          <td>{{ bundleCost?.displayBundle }}</td>
          <td>{{ bundleCost?.displayMarket }}</td>
          <td>{{ bundleCost?.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}</td>
          <td width="32">
            <div class="custom-dropdown">
              <button
                class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                type="button"
                mdbWavesEffect>
                <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
              </button>
              <div class="custom-dropdown-content rounded-sm z-depth-2">
                <a class="dropdown-item" (click)="createBundleCost(bundleCost)"
                  ><i class="icofont-plus"></i> Add</a
                >
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="bundleCostGroup.value[0].collapsed && i == 0" class="grey lighten-5 border">
          <td colspan="100%" class="p-3">
            <mdb-tabset
              #staticTabs
              [buttonClass]="'classic-tabs'"
              [contentClass]="'card bg-white p-3 m-0 z-depth-0 d-block border'"
              class="classic-tabs">
              <mdb-tab
                *ngFor="let bundleCost of bundleCostGroup.value; let index = index"
                heading="{{ bundleCost.marketCode }}"
                (select)="onTabSelect(bundleCost)">
                <div class="pr-5">
                  <div class="d-flex justify-content-between mb-3">
                    <h6>Service Bundle Components</h6>
                    <span>
                      <a class="mr-3" (click)="editBundleCost(bundleCost)"
                        ><i class="icofont-edit-alt"></i> Edit</a
                      >
                      <a (click)="delete(bundleCost)"><i class="icofont-ui-delete"></i>Delete</a>
                    </span>
                  </div>
                  <div
                    class="text-center p-4 border mt-3"
                    *ngIf="
                      !bundleCostGroup.value[0].costComponents ||
                      bundleCostGroup.value[0].costComponents.length == 0
                    ">
                    <h6>No Bundle Cost Components Available</h6>
                  </div>
                  <div
                    *ngIf="
                      bundleCostGroup.value[0].costComponents &&
                      bundleCostGroup.value[0].costComponents.length > 0
                    ">
                    <table mdbTable small="true" hover="true" bordered="true" striped="true">
                      <thead>
                        <tr>
                          <th>Components</th>
                          <th>Provider</th>
                          <th width="200" scope="col" class="text-right">Cost</th>
                        </tr>
                      </thead>
                      <tbody *ngFor="let bundleCost of bundleCostGroup.value">
                        <tr *ngFor="let costComponent of bundleCost.costComponents">
                          <td
                            *ngIf="
                              bundleCost.activeCode &&
                              bundleCost.activeCode === bundleCost.marketCode
                            ">
                            <div class="d-flex justify-content-start">
                              {{ costComponent.component.name }}
                              <div class="d-flex justify-content-between ml-2">
                                <span
                                  *ngIf="
                                    costComponent &&
                                    costComponent.component &&
                                    costComponent.component.isPrimary
                                  "
                                  class="bg-info ml-1 rounded flag"
                                  mdbTooltip="Primary">
                                  <i class="icofont icofont-flag-alt-1 text-white"></i>
                                </span>
                                <span
                                  *ngIf="
                                    costComponent &&
                                    costComponent.component &&
                                    costComponent.component.isMedicalCenter
                                  "
                                  class="teal ml-1 rounded flag"
                                  mdbTooltip="Medical Center">
                                  <i class="fas fa-hospital text-white"></i>
                                </span>
                                <span
                                  *ngIf="
                                    costComponent &&
                                    costComponent.component &&
                                    costComponent.component.isFundingTrigger
                                  "
                                  class="blue darken-3 ml-1 rounded flag"
                                  mdbTooltip="Trigger Request">
                                  <i class="icofont icofont-dollar text-white"></i>
                                </span>
                                <span
                                *ngIf="
                                  costComponent &&
                                  costComponent.component &&
                                  costComponent.component.showInEpisode
                                "
                                class="ml-1"
                                mdbTooltip="Show In Episode">
                                <i class="far fa-eye deep-blue"></i>
                              </span>
                              </div>
                            </div>
                          </td>
                          <td
                            *ngIf="
                              bundleCost.activeCode &&
                              bundleCost.activeCode === bundleCost.marketCode
                            ">
                            <a
                              *ngIf="
                                costComponent.component.expenseAllocation != 'Administrative Fee' &&
                                costComponent.component.expenseAllocation != 'Client Fee'
                              "
                              (click)="openProviderModal(costComponent)"
                              >Associate Providers</a
                            >
                          </td>
                          <td
                            *ngIf="
                              bundleCost.activeCode &&
                              bundleCost.activeCode === bundleCost.marketCode
                            "
                            class="text-right">
                            {{ costComponent.cost | currency }}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot *ngFor="let cost of bundleCostGroup.value" class="border-top">
                        <tr>
                          <td
                            *ngIf="cost.activeCode === cost.marketCode"
                            colspan="5"
                            class="text-right">
                            <h6>
                              <span class="font-weight-bold mr-2">Total Cost </span>
                              <span class="font-weight-bold">{{ cost.totalCost | currency }}</span>
                            </h6>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </mdb-tab>
            </mdb-tabset>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div
    appScrollToTop
    class="fixed-action-btn donotprint"
    style="bottom: 145px; right: 10px; display: none">
    <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
      <i class="fas fa-angle-up text-black-50"></i>
    </a>
  </div>
  <div
    class="text-center p-4 border mt-3"
    *ngIf="!loading && (!bundleCostList || bundleCostList.length == 0)">
    <i class="fas fa-3x fa-dollar-sign text-black-50"></i>
    <p>Start adding Bundle Cost.</p>
  </div>

  <div
    class="text-center p-4 border mt-3"
    *ngIf="
      !loading &&
      bundleCostList.length > 0 &&
      (bundleCostList | filter : searchKeyword : ['displayBundle', 'displayProvider', 'totalCost'])
        .length == 0
    ">
    <i class="fas fa-3x fa-dollar-sign text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>
<app-loader [loader]="loading"></app-loader>

<!--BUNDLE COST MODAL -->
<div
  mdbModal
  #bundleCostModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">{{ bundlecost.uuid ? 'Edit' : 'Add' }} Bundle Cost</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="false" [formGroup]="bundleCostForm">
          <div class="form-row">
            <div class="col-6">
              <div class="form-group position-relative">
                <label for="bundleLocation">Bundle Name *</label>
                <input
                  type="text"
                  [ngModel]="bundle_search_text | async"
                  (ngModelChange)="bundle_search_text.next($event)"
                  required
                  [ngModelOptions]="{standalone: true}"
                  class="completer-input form-control mdb-autocomplete mb-0"
                  [mdbAutoCompleter]="auto1"
                  placeholder="Search bundle"
                  [readonly]="bundlecost.uuid || addedBundleCost"
                  [ngClass]="{isDisabled: bundlecost.uuid}"
                  (change)="bundleSearchChange()" />
                <mdb-auto-completer
                  #auto1="mdbAutoCompleter"
                  textNoResults="No bundle found"
                  [disabled]="bundlecost.uuid || addedBundleCost"
                  [displayValue]="onBundleDisplayValue">
                  <mdb-option *ngFor="let option of bundle_results | async" [value]="option">
                    <div class="d-flex flex-column">
                      <strong>{{ option.name }}</strong>
                    </div>
                  </mdb-option>
                </mdb-auto-completer>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group position-relative mb-0">
                <label for="bundleLocation">Choose Market *</label>
                <mdb-select
                  [options]="displayMarketList | sort : 'label'"
                  id="market"
                  placeholder="Select market"
                  (selected)="marketSelect($event)"
                  formControlName="marketCode"
                  [highlightFirst]="false">
                </mdb-select>
              </div>
            </div>
          </div>

          <table mdbTable small="true" *ngIf="bundlecost && bundlecost.bundleUuid">
            <thead>
              <tr>
                <th>Funding Request</th>
                <th width="100" class="text-center">Cost</th>
              </tr>
            </thead>
            <tbody
              *ngIf="
                bundlecost && bundlecost.fundingRequests && bundlecost.fundingRequests.length > 0
              ">
              <tr *ngFor="let fund of bundlecost.fundingRequests; let i = index">
                <td>
                  <span>{{ fund.fundingRequestName }}</span>
                </td>
                <td>
                  <div class="form-group mb-0">
                    <input
                      type="number"
                      [(ngModel)]="fund.cost"
                      [ngModelOptions]="{standalone: true}"
                      id="fundCost{{ i }}"
                      class="form-control text-right form-control-sm"
                      mdbInput
                      (ngModelChange)="calculateFundingCost()" />
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot class="border-top">
              <tr>
                <td colspan="5" class="text-right">
                  <h6>
                    <span>Total Funding Cost </span>
                    <span class="font-weight-bold">{{
                      bundlecost?.totalFundingRequestCost | currency
                    }}</span>
                  </h6>
                </td>
              </tr>
            </tfoot>
          </table>
          <div
            *ngIf="bundlecost && bundlecost.bundleUuid"
            class="scrollbar scrollbar-primary thin overflow-auto"
            style="max-height: 400px">
            <div
              class="text-center"
              *ngIf="
                bundlecost && bundlecost.costComponents && bundlecost.costComponents.length == 0
              ">
              No components available
            </div>
            <table
              *ngIf="
                bundlecost && bundlecost.costComponents && bundlecost.costComponents.length > 0
              "
              stickyHeader="true"
              mdbTable
              small="true"
              class="v-top">
              <thead>
                <tr>
                  <th>Bundle Component</th>
                  <th>Funding Request</th>
                  <th width="100" class="text-center">Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let component of bundlecost.costComponents; let i = index">
                  <td>
                    <div class="d-flex align-items-center justify-content-start">
                      <span>{{ component.name }}</span>
                      <span
                        *ngIf="component.isPrimary"
                        class="bg-info ml-1 rounded-sm flag"
                        mdbTooltip="Primary">
                        <i class="icofont icofont-flag-alt-1 text-white"></i>
                      </span>
                      <span
                        *ngIf="component.isMedicalCenter"
                        class="teal ml-1 rounded-sm flag"
                        mdbTooltip="Medical Center">
                        <i class="fas fa-hospital text-white"></i>
                      </span>
                      <span
                        *ngIf="component?.component?.isFundingTrigger"
                        class="blue darken-3 ml-1 rounded-sm flag"
                        mdbTooltip="Trigger Request">
                        <i class="icofont icofont-dollar text-white"></i>
                      </span>
                    </div>
                  </td>
                  <td>
                    <span>{{ component.fundingRequestName }}</span>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <input
                        type="number"
                        id="cost{{ i }}"
                        class="form-control text-right form-control-sm"
                        [(ngModel)]="component.cost"
                        [ngModelOptions]="{standalone: true}"
                        mdbInput
                        (ngModelChange)="calculateCost()" />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot class="border-top">
                <tr>
                  <td colspan="5" class="text-right">
                    <h6>
                      <span>Total Bundle Cost </span>
                      <span class="font-weight-bold">{{ bundlecost?.totalCost | currency }}</span>
                    </h6>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div
            class="alert border border-warning grey lighten-5 text-center"
            role="alert"
            *ngIf="message">
            <mdb-icon fas icon="exclamation-triangle" class="text-warning"></mdb-icon>
            {{ message }}
          </div>
        </form>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn-light btn" mdbWavesEffect (click)="closeModal()">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="submitBundleCost()"
          [disabled]="submit_enabled || !(bundlecost.bundleUuid && bundlecost.marketCode)"
          *ngIf="!message && !submitBtnName">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ bundlecost.uuid ? 'Update' : 'Save' }}
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="save()"
          [disabled]="
            submit_enabled || !(bundlecost.bundleUuid && bundlecost.marketCode) || disableSave
          "
          *ngIf="message && submitBtnName">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="submit_enabled"></span>
          {{ submitBtnName }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- PROVIDER MODAL -->
<div
  mdbModal
  #providerModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">
          {{ providers.selectedBundleCostComponent?.name }} Associate Providers
        </h5>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="closeProviderModal()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group position-relative">
          <label for="providerName"
            >Provider *
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="searching"></span
          ></label>
          <input
            type="text"
            [ngModel]="provider_search_text | async"
            (ngModelChange)="provider_search_text.next($event)"
            required
            [ngModelOptions]="{standalone: true}"
            class="completer-input form-control mdb-autocomplete mb-0"
            [mdbAutoCompleter]="autoProvider"
            placeholder="Search by provider name or  NPI"
            autocomplete="off"
            [ngClass]="{isDisabled: false}" />
          <mdb-auto-completer
            #autoProvider="mdbAutoCompleter"
            textNoResults="No Provider found"
            (selected)="providerSelected($event)"
            [displayValue]="onProviderDisplayValue">
            <mdb-option
              class="border-bottom"
              *ngFor="let option of provider_results | async"
              [value]="option">
              <div class="d-flex flex-column">
                <strong>{{ option.displayName || option.businessName }}</strong>
                <span>
                  located in {{ option.practiceAddress.city
                  }}{{ option.practiceAddress.state ? ',' : '' }}
                  {{ option.practiceAddress.state }}</span
                >
                <small
                  >(N){{ option.npi ? option.npi : 'N/A' }}, (T){{
                    option.taxId ? option.taxId : 'N/A'
                  }}</small
                >
              </div>
            </mdb-option>
          </mdb-auto-completer>
        </div>
        <app-loader
          [loader]="
            providers.provider_loading &&
            (!providers.providerList || providers.providerList.length == 0)
          ">
        </app-loader>
        <div class="scrollbar scrollbar-primary thin overflow-auto" style="max-height: 300px">
          <div
            class="text-center p-4 mt-3"
            *ngIf="
              (!providers.providerList || providers.providerList.length == 0) &&
              !providers.provider_loading
            ">
            <i class="fas fa-3x fa-hospital text-black-50 mb-3"></i>
            <p>No Associated Provider Available</p>
          </div>
          <table
            class="v-top"
            mdbTable
            small="true"
            stickyHeader="true"
            *ngIf="providers.providerList && providers.providerList.length > 0">
            <thead>
              <tr>
                <th width="250">Provider Name/NPI/Address</th>
                <th width="150">Provider Type</th>
                <th width="150">Market Code</th>
                <th width="250">Taxonomy Code/Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <div class="loader">
              <app-loader [loader]="loading"></app-loader>
            </div>
            <tbody>
              <tr
                *ngFor="
                  let provider of providers.providerList | orderBy : 'displayName';
                  let i = index
                ">
                <td>
                  <h6>{{ provider.displayName || provider.businessName }}</h6>
                  <ul class="fa-ul ml-4 mb-0">
                    <li *ngIf="provider?.npi || provider?.taxId">
                      <div *ngIf="provider?.npi">
                        <span class="fa-li">
                          <mdb-icon class="text-primary" far icon="address-card"></mdb-icon>
                        </span>
                        <span mdbTooltip="NPI" placement="right">{{ provider?.npi }}</span>
                      </div>
                      <div *ngIf="provider?.taxId">
                        <span class="fa-li">
                          <mdb-icon class="text-primary" far icon="address-card"></mdb-icon>
                        </span>
                        <span mdbTooltip="TIN" placement="right">{{ provider?.taxId }}</span>
                      </div>
                    </li>
                    <li>
                      <span class="fa-li">
                        <mdb-icon class="text-primary" fas icon="map-marker-alt"></mdb-icon>
                      </span>
                      {{
                        provider.practiceAddress?.addressline1
                          ? provider.practiceAddress.addressline1
                          : ''
                      }}
                      {{
                        provider.practiceAddress.addressline2
                          ? provider.practiceAddress.addressline2
                          : ''
                      }}
                      <br
                        *ngIf="
                          provider.practiceAddress.addressline1 ||
                          provider.practiceAddress.addressline2
                        " />
                      <span
                        >{{ provider.practiceAddress?.city ? provider.practiceAddress.city : '' }}
                        {{ provider.practiceAddress?.state ? provider.practiceAddress.state : '' }}
                        {{
                          provider.practiceAddress?.zip ? provider.practiceAddress.zip : ''
                        }}</span
                      >
                    </li>
                  </ul>
                </td>
                <td>
                  {{ provider.providerType ? provider.providerType : 'N/A' }}
                </td>
                <td>
                  {{ provider?.marketCode }}
                </td>
                <td>
                  <span
                    *ngIf="provider.primaryTaxonomyCode"
                    class="bg-primary-dark mr-1 badge badge-sm text-white rounded-sm">
                    {{ provider.primaryTaxonomyCode }}
                  </span>
                  <br />
                  {{ provider.primaryTaxonomyDescription }}
                </td>
                <td>
                  <ng-template #deletealert>
                    <div class="text-center">
                      <p>
                        Are you sure you want to delete ? This is irreversible. If you're not sure,
                        you can click Cancel.
                      </p>

                      <p class="mb-0">
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          (click)="deleteViewProvider(provider); pop.hide()">
                          Delete
                        </button>
                        <button type="button" (click)="pop.hide()" class="btn btn-light btn-sm">
                          Cancel
                        </button>
                      </p>
                    </div>
                  </ng-template>
                  <button
                    id="deletealert"
                    [mdbPopover]="deletealert"
                    #pop="bs-mdbPopover"
                    placement="left"
                    class="btn btn-link mt-0 mb-0 p-0 z-depth-0">
                    <i class="icofont-ui-delete"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="waves-light m-0 btn btn-light" mdbWavesEffect (click)="closeProviderModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<app-delete-confirmation
  [_id]="deleteId"
  [message]="message"
  (confirmDelete)="confirmDelete($event)">
</app-delete-confirmation>
