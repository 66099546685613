import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() isTemplateDriven: boolean = false;
  @Input() control: FormControl = new FormControl();
  @Input() options: Array<object>;
  @Input() placeholder: string;
  @Input() id: string;
  @Input() multiple: boolean = false;
  @Input() filterEnabled: boolean = true;
  @Input() appendToBody: boolean = false;
  @Input() class: string = 'search-dropdown';
  @Input() enableSelectAll: boolean = false;
  @Input() allowClear: boolean = false;
  @Input() selected: string;
  @Input() visibleOptions: number = 3;
  @Input() disabled: boolean = false;
  @Input() inputClass: object;
  @Input() style: string;
  @Output('onSelected') onSelected: EventEmitter<any> = new EventEmitter();
  @Output('onDeselected') onDeselected: EventEmitter<any> = new EventEmitter();
  @Output('selectedChange') selectedChange: EventEmitter<any> = new EventEmitter<any>();
  changeDetector: any;

  constructor() { }

  ngOnInit() {
  }
}