import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { AccessType } from 'src/app/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-task-todo',
  templateUrl: './task-todo.component.html',
  styleUrls: ['./task-todo.component.scss']
})
export class TaskTodoComponent implements OnInit, AfterViewInit {
  taskToBeDelete: any
  dependentTask: any
  todoId: any
  @Output() editTask = new EventEmitter<any>();
  @Input() public taskTodo;
  @Input() public optionId;
  @Output() public btns = new EventEmitter<any>();
  @Output() public deletedTask = new EventEmitter<any>();
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;
  @Input() set taskTodoId(taskTodoId) {
    if (taskTodoId) {
      this.todoId = taskTodoId
      this.getTaskToDo(taskTodoId);
    }
  }
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.todoId) this.getTaskToDo(this.todoId)
    }
  }
  constructor(private utilityAccess: AccessType,
    private toastr: ToastrService,
  ) { }

  ngOnInit() { }
  ngAfterViewInit() {
    const buttons = this.buttons.toArray();
    setTimeout(() => {
      this.btns.emit(buttons);
    });
  }
  getTaskToDo(id) {
    this.taskTodo = {};
    if (this.dependentTask && this.dependentTask.taskTodos) {
      this.dependentTask.taskTodos.filter(x => {
        if (x.id == id) {
          x.form = 'todo';
          x.field = 'todo'
          x.optionId = this.optionId;
          this.taskTodo = x
        }
      })
    }
  }
  editTaskHandler(todo) {
    this.editTask.emit(todo);
  }

  deleteTaskHandler(todo) {
    if (this.utilityAccess.searchAccess('PM', 'isEditable')) {
      todo['source'] = 'Pathway'
      todo['type'] = 'Todo'
      todo['displayName'] = todo.name
      this.taskToBeDelete = todo
    }
    else this.toastr.warning("Access Denied", "Warning")
  }
  cancelDelete(e) {
    this.taskToBeDelete = e
  }
  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }

}
