import { Injectable } from "@angular/core";
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";
import { APICONSTANTS } from "../constants";

@Injectable({
  providedIn: "root"
})

export class ReportConfigurationService {
  constructor(
    private _http: HttpClientService,
  ) { }

  /**
   * @params params
   * @returns data for report configurations
   */
  getAll(params?: any) {
    return this._http.get(`${APICONSTANTS.REPORT_CONFIGURATION}`, params)
      .pipe(map((res: any) => {
        return res.data.rows;
      }))
  }
}
