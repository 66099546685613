<div mdbModal #patientDemographicModal="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard:false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header z-depth-0 border-bottom">
        <p class="modal-title">Patient Demographic</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePatientDemographic()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body information-modal">
        <app-loader [loader]="loading"></app-loader>
        <div *ngIf="!loading">
          <div class="field-group">
            <label for=""><i class="icofont icofont-user"></i> Name </label>
            <div class="field-value">{{patientDemographic?.firstName}}
              {{patientDemographic?.lastName}}</div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-email"></i> Email </label>
            <div class="field-value">{{patientDemographic?.email ? patientDemographic.email :'N/A'}}</div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-phone"></i> Phone </label>
            <div class="field-value">
              {{patientDemographic?.phone|formatPhoneNumber}}{{!patientDemographic?.phone?'N/A':''}}</div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-birthday-cake"></i> DOB </label>
            <div class="field-value">{{patientDemographic?.dob| date:
              'MM/dd/yyyy'}}{{!patientDemographic?.dob?'N/A':''}}</div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-users-alt-4"></i> Gender </label>
            <div class="field-value">{{patientDemographic?.gender}}{{!patientDemographic?.gender?'N/A':''}}</div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-location-pin"></i> Address </label>
            <div class="field-value"> {{(!patientDemographic?.addressLine1 && !patientDemographic?.addressLine2 &&
              !patientDemographic?.city && !patientDemographic?.state && !patientDemographic?.zip)?'N/A':''}}{{
              patientDemographic?.addressLine1 }} {{ patientDemographic?.addressLine2 }}<br>
              {{ (patientDemographic && patientDemographic.city)?patientDemographic.city:'' }} {{
              patientDemographic?.state }} {{ patientDemographic?.zip }}
            </div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-notepad"></i> Notes </label>
            <div class="field-value">{{patientDemographic?.note}}{{!patientDemographic?.note?'N/A':''}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>