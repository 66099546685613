export const AdditionalPatientField = [
  'subscriberId',
  'memberId',
  'clientProgramCode',
  'planCode',
  'effectiveDate',
  'terminationDate',
  'eligibilitySource',
  'employerGroupNumber',
  'benefitStatusCode',
  'relationshipCode'
];
