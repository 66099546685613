import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { EpisodeService, PlatformService, ToasterService } from 'src/app/services';
import { ToastrService } from 'ngx-toastr';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { EOB_CONSTANTS } from '../../constants';
import { HolistaUtils } from '../../utils';
@Component({
    selector: 'app-view-eob',
    templateUrl: './view-eob.component.html'
    // ,
    // styles: [`
    // @media print {
    //     #view-eob {
    //         transform: scale(2); 
    //         margin-top: 60%;
    //     }
    //   }
    // `]
})

export class ViewEOBComponent implements OnInit {
    episode: any
    loading = false;
    eobDetail: any;
    EOB_CONSTANTS = EOB_CONSTANTS;

    @Input() set episodeForEOB(data) {
        if (data && data.id) {
            this.episode = data
            this.viewEOBByEpisodeId(data.id)
        }
    }
    @Output() viewEOBClose = new EventEmitter<any>();
    @ViewChild('viewEOBModal', { static: true }) public viewEOBModal

    constructor(
        private episodeService: EpisodeService,
        private toastr: ToastrService,
        private platformService: PlatformService,
        private _toastr: ToasterService,
        private _holistaUtils: HolistaUtils
    ) {

    }

    ngOnInit() { }

    getPlatformInformation() {
        this.platformService.getById(EOB_CONSTANTS.PLATFORM_INFO_REFERENCE_CODE)
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response) => {
                const { code, addressLine1, addressLine2, city, state, zip } = response;
                this.eobDetail.client.name = this._holistaUtils.toTitleCase(code);
                this.eobDetail.client.addressLine1 = addressLine1;
                this.eobDetail.client.addressLine2 = addressLine2;
                this.eobDetail.client.city = city;
                this.eobDetail.client.state = state;
                this.eobDetail.client.zip = zip;
            }, (error) => {
                console.log("Error getting platform information", error);
                this._toastr.showError(error);
            })
    }

    viewEOBByEpisodeId(episodeId) {
        this.loading = true;
        this.viewEOBModal.show();
        this.episodeService.getEOBByEpisode(episodeId)
            .subscribe(res => {
                res.totalPaidAmount = res.totalFundAmount - res.patientResponsibilityAmount;
                res.client = this.episode.client;
                this.eobDetail = res;
                res.client.logo ? this.loading = false : this.getPlatformInformation();
            },
                error => {
                    this.toastr.error(error.message, "Error");
                })
    }

    closeEOBModal() {
        this.viewEOBModal.hide()
        this.eobDetail = null
        this.viewEOBClose.emit(null)
    }

    public captureScreen() {
        window.print();
    }

    download() {
        var data = document.getElementById('view-eob');
        html2canvas(data, { proxy: this.eobDetail.client.logo }).then(canvas => {
            var imgWidth = 208;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            const contentDataURL = canvas.toDataURL('image/png')
            let pdf = new jsPDF('p', 'mm', 'a4');
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
            pdf.save(`${this.eobDetail.episode.name}-EOB.pdf`);
        });
    }
}