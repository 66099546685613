import { createReducer, on } from '@ngrx/store';
import * as HeaderBreadCrumbActions from '../action';
 
export const initialState_breadCrumb = [];
 
// const _headerBreadcrumbsReducer = createReducer(initialState_breadCrumb,
//   on(addHeaderBreadcrumbs, state =>[]),
//   on(removeHeaderBreadcrumbs, state => ['abc']),
//   on(resetHeaderBreadcrumbs, state => []),
// );
 
export function headerBreadcrumbsReducer(state, action:HeaderBreadCrumbActions.Actions) {
  // return _headerBreadcrumbsReducer(state, action);
  switch(action.type){
    case HeaderBreadCrumbActions.ADD_HEADER_BREADCRUMB:
      return [...state, action.payload];
    case HeaderBreadCrumbActions.RESET_HEADER_BREADCRUMB:
      return [];
    default:
      return state;
  }

}