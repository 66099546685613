<div class="d-flex justify-content-between align-center position-sticky header pr-3">
  <div class="d-flex align-items-center">
    <small
      >{{ queryParams.offset + 1 }}-{{
        totalCountMessages > queryParams.offset + 10 ? queryParams.offset + 10 : totalCountMessages
      }}
      of {{ totalCountMessages }}
    </small>
    <mdb-icon
      class="ml-1 pointer-cursor"
      fas
      icon="redo"
      [ngStyle]="{fontSize: '0.9rem', color: 'grey'}"
      (click)="getMessageList(true)"></mdb-icon>
  </div>
  <div>
    <mdb-icon
      fas
      icon="angle-left"
      class="mr-2 pointer-cursor"
      [class.nav-disabled]="queryParams.offset === 0"
      (click)="getMessageList(false, 'prev')"></mdb-icon>
    <mdb-icon
      fas
      icon="angle-right nav-enabled pointer-cursor"
      [class.nav-disabled]="
        !totalCountMessages ||
        (queryParams.offset < totalCountMessages && totalCountMessages < queryParams.offset + 10)
      "
      (click)="getMessageList(false, 'next')"></mdb-icon>
  </div>
</div>
<app-loader [loader]="isLoading"></app-loader>
<app-message
  *ngIf="!isLoading"
  [type]="type"
  [messageList]="type === INBOX ? inboxMessageList : outboxMessageList"
  [sentMessageId]="sentMessageId"
  (messageSelect)="messageSelect.emit($event)"></app-message>

<div
  class="text-center p-4 border"
  *ngIf="!isLoading && (type === INBOX ? !inboxMessageList.length : !outboxMessageList.length)">
  <i class="fa-fa fa-envelope fas fa-3x text-black-50"></i>
  <p>Your {{ type === INBOX ? 'inbox' : 'sent' }} tab is empty.</p>
</div>
