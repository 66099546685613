import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export class PurchaserService {
    constructor(private http: HttpClientService) { }

    getAll(query?) {
        return this.http.get(`${APICONSTANTS.PURCHASER}`, query).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    create(purchaser) {
        return this.http.post(`${APICONSTANTS.PURCHASER}`, purchaser)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    update(purchaser) {
        return this.http.put(`${APICONSTANTS.PURCHASER}/${purchaser.code}`, purchaser)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    delete(id, paramType) {
        return this.http.remove(`${APICONSTANTS.PURCHASER}/${id}?paramType=${paramType}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getById(id) {
        return this.http.get(`${APICONSTANTS.PURCHASER}/${id}`).pipe(
            map((response: any) => {
                return response.data
            })
        );
    }

    getValueBasedProgramsByPurchaserCode(code, query) {
        return new Promise((resolve, reject) => {
            this.http.get(`${APICONSTANTS.PURCHASER}/${code}/vbps`, query).toPromise()
                .then((res: any) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    updateVbpForPurchaser(body, code) {
        delete body.purchaserCode
        return this.http.put(`${APICONSTANTS.PURCHASER}/${code}/clients/${body.clientCode}/vbps/${body.vbpCode}`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    saveVbpForPurchaser(body, code) {
        delete body.purchaserCode
        return this.http.post(`${APICONSTANTS.PURCHASER}/${code}/clients/${body.clientCode}/vbps`, body)
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    deleteVbpForPurchaser(purchaserCode, clientCode, id) {
        return this.http.remove(`${APICONSTANTS.PURCHASER}/${purchaserCode}/clients/${clientCode}/vbps/${id}`).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }

    getNetworks(code, query?) {
        return this.http.get(`${APICONSTANTS.PURCHASER}/${code}/networks`, query).pipe(
            map((res: any) => {
                return res.data;
            })
        );
    }
}