import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ANCILLARY_REPORT_DATA, EPISODESTATUS, REPORT_DATES, ROLE_CODES } from '../../../constants';
import * as moment from 'moment';
import { BundleCostService, EpisodeService, MarketService, ReportService, UserService } from '../../../services';
import { finalize } from 'rxjs/operators';
import { HolistaUtils, Storage } from '../../../utils';

@Component({
  selector: 'app-ancillary-report',
  templateUrl: './ancillary-report.component.html',
  styleUrls: ['./ancillary-report.component.scss']
})
export class AncillaryReportComponent implements OnInit {
  expandAdvanceFiltering: boolean = true;
  ancillaryReportForm: FormGroup;
  providerList: any[] = [];
  episodeTypeList: any[] = [];
  marketList: any[] = [];
  statusList: any[] = EPISODESTATUS;
  reportDateTypeList: any[] = REPORT_DATES;
  isLoading = {
    ancillaryReport: false,
    episodeTypeList: false,
    marketList: false,
    providerList: false
  };
  isPreSelectedFilter: boolean = false;
  showReport: boolean = false;
  report: any = {};
  user: any = {};
  roleCodes = ROLE_CODES;

  constructor(
    private _formBuilder: FormBuilder,
    private _episodeService: EpisodeService,
    private _holistaUtils: HolistaUtils,
    private _marketService: MarketService,
    private _reportService: ReportService,
    private _userService: UserService,
    private _bundleCostService: BundleCostService,
    private _storage: Storage
  ) {
    this.setAncillaryReportForm();
  }

  ngOnInit(): void {
    this.user = this._storage.get('local', 'loggedInUser', 'user');
    this.getEpisodes();
    this.getMarkets();
    this.getProviders();
  }

  setAncillaryReportForm() {
    this.ancillaryReportForm = this._formBuilder.group({
      provider: [null],
      episodeType: [null],
      marketCode: [null],
      status: [null],
      startDate: [null],
      endDate: [null],
      dateType: [null, Validators.required]
    });
  }

  getEpisodes() {
    this.isLoading.episodeTypeList = true;
    this._episodeService.getUniqueEpisodeTypes()
      .pipe(finalize(() => { this.isLoading.episodeTypeList = false; }))
      .subscribe((response: any) => {
        response = response.map((episode) => {
          const name = episode.name.trim();
          return {
            name,
            label: name,
            value: name.replaceAll(',', '')
          };
        });
        const episodeNames = response.map(episode => episode['name']);
        const filteredEpisodeTypeList = response.filter((episode, index: number) => !episodeNames.includes(episode['name'], index + 1))
          .map((episode: any) => ({ ...episode, name: episode.name.toLowerCase() }));
        this.episodeTypeList = this._holistaUtils.getUniqueList(filteredEpisodeTypeList, 'name');
      }, error => console.log('error getting episode types', error));
  }

  getMarkets(params?: any) {
    this.isLoading.marketList = true;
    this._marketService.getAll({ ...params, limit: 0 })
      .pipe(finalize(() => { this.isLoading.marketList = false; }))
      .subscribe((response: any) => {
        this.marketList = response.rows.map(({ name, code }) => ({ label: `${name} - ${code}`, value: code }));
      },
        error => console.log('error getting market list', error));
  }

  toggleCard() {
    this.expandAdvanceFiltering = !this.expandAdvanceFiltering;
  }

  onResetFilters() {
    this.ancillaryReportForm.reset();
    this.ancillaryReportForm.controls['startDate'].enable();
    this.ancillaryReportForm.controls['endDate'].enable();
    this.showReport = false;
  }

  onGenerateAncillaryReport() {
    this.isLoading.ancillaryReport = true;
    const params = this._holistaUtils.getFormattedValues(this.ancillaryReportForm.value);
    this._reportService.getAncillaryReport(params)
      .pipe(finalize(() => { this.isLoading.ancillaryReport = false; }))
      .subscribe((response: any) => {
        if (response.success) {
          const { TABLE_HEADERS, EXCEL_NAME, TABLE_TITLE } = ANCILLARY_REPORT_DATA
          this.report = {
            data: response.data,
            tableHeaders: TABLE_HEADERS,
            excelName: EXCEL_NAME,
            tableTitle: TABLE_TITLE,
            columnsWidth: TABLE_HEADERS.map(header => header.columnWidth)
          };
          this.showReport = true;
        };
      }, error => console.log('error getting ancillary report', error));
  }

  onReportDateTypeSelected(event: any) {
    switch (event.value) {
      case "thisMonth":
        this.ancillaryReportForm.controls['startDate'].setValue(new Date(moment().startOf('month').format()));
        this.ancillaryReportForm.controls['endDate'].setValue(new Date(moment().endOf('month').format()));
        this.isPreSelectedFilter = true;
        break;
      case "lastMonth":
        this.ancillaryReportForm.controls['startDate'].setValue(new Date(moment().startOf('month').subtract(1, 'months').format()));
        this.ancillaryReportForm.controls['endDate'].setValue(new Date(moment().startOf('month').subtract(1, 'months').endOf('month').format()));
        this.isPreSelectedFilter = true;
        break;
      case "ytd":
        this.ancillaryReportForm.controls['startDate'].setValue(new Date(moment().startOf('year').format()));
        this.ancillaryReportForm.controls['endDate'].setValue(new Date(moment().format()));
        this.isPreSelectedFilter = true;
        break;
      case "lastYear":
        this.ancillaryReportForm.controls['startDate'].setValue(new Date(moment().startOf('year').subtract(12, 'months').format()));
        this.ancillaryReportForm.controls['endDate'].setValue(new Date(moment().endOf('year').subtract(12, 'months').format()));
        this.isPreSelectedFilter = true;
        break;
      case "lastQuarter":
        let currentQuaterNumber = moment().quarter();
        if (currentQuaterNumber === 1) { //if 1st quater
          this.ancillaryReportForm.controls['startDate'].setValue(new Date(moment().startOf('year').subtract(3, 'months').format()));
          this.ancillaryReportForm.controls['endDate'].setValue(new Date(moment().startOf('year').subtract(1, 'day').format()));
        } else {
          this.ancillaryReportForm.controls['startDate'].setValue(new Date(moment().subtract(1, 'quarter').startOf('quarter').format()));
          this.ancillaryReportForm.controls['endDate'].setValue(new Date(moment().subtract(1, 'quarter').endOf('quarter').format()));
        }
        this.isPreSelectedFilter = true;
        break;
      case "thisQuarter":
        this.ancillaryReportForm.controls['startDate'].setValue(new Date(moment().startOf('quarter').format()));
        this.ancillaryReportForm.controls['endDate'].setValue(new Date(moment().endOf('quarter').format()));
        this.isPreSelectedFilter = true;
        break;
      case "dateRange":
        this.ancillaryReportForm.controls.startDate.setValidators([Validators.required]);
        this.ancillaryReportForm.controls.startDate.updateValueAndValidity();
        this.ancillaryReportForm.controls.endDate.setValidators([Validators.required]);
        this.ancillaryReportForm.controls.endDate.updateValueAndValidity();
        if (this.isPreSelectedFilter) {
          try {
            this.ancillaryReportForm.controls['startDate'].setValue(null);
            this.ancillaryReportForm.controls['endDate'].setValue(null);
            this.ancillaryReportForm.controls['startDate'].enable();
            this.ancillaryReportForm.controls['endDate'].enable();
          } catch (error) {
            try {
              this.ancillaryReportForm.controls['startDate'].enable();
              this.ancillaryReportForm.controls['endDate'].enable();
            } catch (error) {
              this.ancillaryReportForm.controls['endDate'].enable();
            }
          }
        }
        this.isPreSelectedFilter = false;
        break;
      default:
        break;
    }
  }

  getProviders() {
    this.isLoading.providerList = true;
    this.ancillaryReportForm.controls.provider.setValue(null);

    if (this.user.roleCode === this.roleCodes.PROVIDER_ADMINISTRATOR) {
      this._userService.getProvidersByUserId(this.user.id)
        .pipe(finalize(() => { this.isLoading.providerList = false; }))
        .subscribe(response => {
          if (response.length) {
            this.providerList = response.map(res => ({ ...res, label: (res.displayName || res.businessName), value: res.providerId }));
            const providerIds = response.map(provider => provider.providerId);
            this.ancillaryReportForm.controls.provider.setValue(providerIds);
          }
        });
      return;
    };

    this._bundleCostService.getComponentCostProviders(this.ancillaryReportForm.get('marketCode').value?.join(',') ?? null)
      .pipe(finalize(() => { this.isLoading.providerList = false; }))
      .subscribe(
        response => {
          this.providerList = response.rows.map(({ name, providerId }) => ({ label: name, value: providerId }));
        },
        error => console.log('error getting component cost providers', error)
      );
  }

  isEpisodeSelectDisabled(): boolean {
    return this.isLoading.episodeTypeList || !this.episodeTypeList.length;
  }

  isMarketSelectDisabled(): boolean {
    return this.isLoading.marketList || !this.marketList.length;
  }

  isProviderSelectDisabled(): boolean {
    return this.isLoading.providerList || !this.providerList.length;
  }

  displayReport(): boolean {
    return !this.isLoading.ancillaryReport && this.showReport;
  };

  enableReportGenerate(): boolean {
    return this.isLoading.ancillaryReport || !this.ancillaryReportForm.valid;
  }
}
