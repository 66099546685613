<div class="grey lighten-5 mb-2 p-3">
  <mdb-card>
    <mdb-card-header class="pointer-cursor" (click)="toggleCard()">
      <div class="d-flex justify-content-between align-items-center">
        <p class="m-0 font-weight-bold">Report Filter Criteria</p>
        <span class="link pointer-cursor ml-3">
          <i [class]="expandAdvanceFiltering ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </span>
      </div>
    </mdb-card-header>
    <mdb-card-body class="p-3" *ngIf="expandAdvanceFiltering">
      <form
        [formGroup]="projectedEpisodeProfitForm"
        (ngSubmit)="onGenerateProjectedEpisodeProfitReport()">
        <div class="row">
          <div class="col-4 form-group">
            <label for="pep-report-date-type-select">Report Date *</label>
            <app-select
              [options]="reportDateTypeList"
              [filterEnabled]="false"
              [id]="'pep-report-date-type-select'"
              [control]="projectedEpisodeProfitForm.controls.reportDateType"
              (onSelected)="onReportDateTypeSelected()"
              [placeholder]="'Select report date'"></app-select>
          </div>
          <div class="col-4 form-group">
            <div class="form-row">
              <div class="col">
                <label for="pep-date-from">From</label>
                <mdb-date-picker
                  [inline]="true"
                  name="pep-date-from"
                  data-cy="pep-date-from"
                  placeholder="Select date"
                  formControlName="startDate"
                  (dateChanged)="onDateChange($event, 'startDate')"
                  [disabled]="
                    isPreSelectedFilter || !projectedEpisodeProfitForm.value.reportDateType
                  ">
                </mdb-date-picker>
              </div>
              <div class="col">
                <label for="pep-start-date">To</label>
                <mdb-date-picker
                  [inline]="true"
                  name="pep-start-date"
                  data-cy="pep-start-date"
                  placeholder="Select date"
                  formControlName="endDate"
                  (dateChanged)="onDateChange($event, 'endDate')"
                  [disabled]="
                    isPreSelectedFilter || !projectedEpisodeProfitForm.value.reportDateType
                  ">
                </mdb-date-picker>
              </div>
            </div>
          </div>
          <div class="col-4 form-group">
            <label for="pep-network-code-select">
              Network
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="isLoading.networkList"></span>
            </label>
            <app-select
              [allowClear]="true"
              [options]="networkList"
              [multiple]="true"
              [disabled]="isLoading.networkList || !networkList.length"
              [id]="'pep-network-code-select'"
              (onSelected)="onNetworkChange()"
              (onDeselected)="onNetworkChange()"
              [control]="projectedEpisodeProfitForm.controls.networkCode"
              [placeholder]="'Select network'"></app-select>
          </div>
        </div>
        <div class="row">
          <div class="col-4 form-group">
            <label for="pep-market-code-select">
              Provider
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="isLoading.marketList"></span>
            </label>
            <app-select
              [options]="marketList"
              [allowClear]="true"
              [disabled]="isLoading.marketList || !marketList.length"
              [multiple]="true"
              [id]="'pep-market-code-select'"
              (onSelected)="onMarketChange()"
              (onDeselected)="onMarketChange()"
              [control]="projectedEpisodeProfitForm.controls.marketCode"
              [placeholder]="'Select provider'"></app-select>
          </div>
          <div class="col-4 form-group">
            <label for="pep-client-code-select">
              Client
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="isLoading.clientList"></span>
            </label>
            <app-select
              [allowClear]="true"
              [disabled]="isLoading.clientList || !clientList.length"
              [options]="clientList"
              [multiple]="true"
              [id]="'pep-client-code-select'"
              (onSelected)="onClientChange()"
              (onDeselected)="onClientChange()"
              [control]="projectedEpisodeProfitForm.controls.clientCode"
              [placeholder]="'Select client'"></app-select>
          </div>
          <div class="col-4 form-group">
            <label for="pep-episode-select">
              Episode
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="isLoading.episodeList"></span>
            </label>
            <app-select
              [allowClear]="true"
              [options]="episodeList"
              [disabled]="isLoading.episodeList || !episodeList.length"
              [multiple]="true"
              [id]="'pep-episode-select'"
              [control]="projectedEpisodeProfitForm.controls.episodeName"
              [placeholder]="'Select episode'"></app-select>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
          <button
            class="btn btn-light bg-white border m-0"
            [disabled]="isLoading.projectedEpisodeProfitReport"
            mdbWavesEffect
            type="button"
            (click)="onResetFilters()">
            Clear
          </button>
          <button
            class="btn btn-secondary waves-light m-0 ml-2"
            mdbWavesEffect
            [disabled]="enableReportGenerate()"
            type="submit">
            <span
              *ngIf="isLoading.projectedEpisodeProfitReport"
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"></span>
            Generate Report
          </button>
        </div>
      </form>
    </mdb-card-body>
  </mdb-card>
</div>

<div class="container-fluid mb-5 pb-5">
  <app-loader [loader]="isLoading.projectedEpisodeProfitReport"></app-loader>
  <app-table
    *ngIf="showReport && !isLoading.projectedEpisodeProfitReport"
    [report]="report"
    [exportTable]="true"
    [module]="'report'"
    tableHeight="800px">
    <ng-template #total>
      <tr class="bg-grey-lighten-2 sticky-bottom">
        <td class="p-1 pl-2 text-right" colspan="4">Total</td>
        <td class="p-1 pr-2 text-right">{{ summary?.fundingRequested | currency }}</td>
        <td class="p-1 pr-2 text-right">{{ summary?.fundingReceived | currency }}</td>
        <td class="p-1 pr-2 text-right">{{ summary?.totalExternalCosts | currency }}</td>
        <td class="p-1 pr-2 text-right">{{ summary?.totalHolistaCosts | currency }}</td>
        <td class="p-1 pr-2 text-right">{{ summary?.margin | currency }}</td>
        <td class="p-1 pr-2 text-right">{{ summary?.percentToQuality | currency }}</td>
        <td class="p-1 pr-2 text-right">{{ summary?.percentToComission | currency }}</td>
        <td class="p-1 pr-2 text-right">{{ summary?.netProfit | currency }}</td>
      </tr>
    </ng-template>
  </app-table>
</div>
