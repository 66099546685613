<div class="text-center">
    <div class="avatar avatar-lg mx-auto">
        <i class="icofont-ui-user"></i>
    </div>
    <h5>Calling {{call.data.entities.for.entity.name}}</h5>
    <p>Ringing ...</p>
    <div class="d-flex justify-content-center">
        <a href="javascript:void(0)" class="mx-3" (click)="endCurrentCall()">
            <div class="icon-block icon-block-lg text-center bg-danger rounded-circle">
                <i class="icofont-phone icofont-rotate-90 text-white"></i>
            </div>
            <small>End Call</small>
        </a>
        <a href="javascript:void(0)" class="mx-3" (click)="cancelCurrentCall()">
            <div class="icon-block icon-block-lg text-center bg-danger rounded-circle">
                <i class="icofont-close text-white"></i>
            </div>
            <small>Close</small>
        </a>
    </div>
    <!-- <div><img [src]="call.data.entities.for.entity.avatar" height="150" width="150" /></div> -->
</div>