import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { MessageConstants, NO_DATA_IN_REPORT, PAGINATION_CONST, PORTAL_QUESTION_DETAIL_TABLE_DATA, YES_NO_ALL } from '../../../../constants';
import { ReportService } from '../../../../services';
import { DateUtility, ExcelMultiTabUtility, HolistaUtils, PDFUtility, ReportMappingUtility } from '../../../../utils';

@Component({
  selector: 'app-portal-question-detail',
  templateUrl: './portal-question-detail.component.html',
  styleUrls: ['./portal-question-detail.component.scss']
})
export class PortalQuestionDetailComponent implements OnInit {

  portalQuestionAdvanceFilteringForm: FormGroup;
  loading = {
    categoryList: false,
    subCategoryList: false,
    portalQuestionDetail: false,
    lazyLoadingPortalQuestionDetail: false,
    milestones: false,
    questions: false,
    questionnaires: false,
  }
  offset: number = 0;
  list = {
    milestones: [],
    questions: [],
    consentedComments: this._holistaUtils.deepClone(YES_NO_ALL),
    questionnaires: [],
    categoryList: [],
    subCategoryList: [],
  }
  reportingPeriod = {
    startDate: null,
    endDate: null,
  }
  expandAdvanceFiltering: boolean = true;
  filterSubmitted: boolean = false;
  isfilterValidlySubmitted: boolean = false;
  parsedQuestionReviewFilterData: any;
  portalQuestionDetailTableData = {
    data: [],
    excelName: '',
    tableTitle: '',
    tableHeaders: [],
    columnsWidth: [],
    dateType: '',
    date: {},
  };
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT);
  @Input() set questionReviewFilterData(data) {
    this.parsedQuestionReviewFilterData = this._holistaUtils.deepClone(data);
    const { startDate, endDate } = this.parsedQuestionReviewFilterData;
    if (startDate && endDate) {
      this.reportingPeriod = {
        startDate,
        endDate,
      }
    }
  }
  @Input() set downloadPDF(isDownload) {
    if (isDownload && isDownload.download && isDownload.tabIndex === 0) {
      const { clientName, startDate, endDate } = this.parsedQuestionReviewFilterData;
      const reportDate = `${startDate} ${endDate ? `- ${endDate}` : ''}`;
      const tabsData = [];
      const { TABLE_HEADERS } = PORTAL_QUESTION_DETAIL_TABLE_DATA;
      if (this.portalQuestionDetailTableData.data.length && isDownload.type === 'excel') {
        const portalQuestionDetailSheetData = this._reportMappingUtils.portalQuestionDetailMapper(this.portalQuestionDetailTableData.data);
        tabsData.push({
          clientName,
          reportName: 'Portal Question Detail',
          tabName: 'Portal Question Detail',
          headers: TABLE_HEADERS.map((x) => x.label),
          data: portalQuestionDetailSheetData,
          reportDate,
        });
        if (tabsData.length) {
          this._excelMultiTabUtility.exportToExcelMultiTab('Question Review Dashboard - Portal Question Detail', tabsData).finally(() => {
            this.downloadedPDF.emit(true);
          })
        }
      }
      if (isDownload.type === 'exportAll') {
        const { clientCode, startDate, endDate } = this.parsedQuestionReviewFilterData;
        const params = {
          ...this.portalQuestionAdvanceFilteringForm.value,
          clientCode,
          startDate,
          endDate,
          offset: PAGINATION_CONST.OFFSET,
          limit: PAGINATION_CONST.MAX_LIMIT,
        }
        this.getPortalQuestionDetail(params, true);
      }
    }
  }
  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private _messageConstants: MessageConstants,
    private reportService: ReportService,
    private _dateUtility: DateUtility,
    private _pdfUtility: PDFUtility,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
    private _holistaUtils: HolistaUtils,
    private _reportMappingUtils: ReportMappingUtility,
  ) {
    this.setPortalQuestionAdvanceFilteringForm();
  }

  ngOnInit(): void {
    if (this.parsedQuestionReviewFilterData && this.parsedQuestionReviewFilterData.networkCode) {
      const { networkCode } = this.parsedQuestionReviewFilterData;
      !this.list.milestones.length && this.getDistinctMilestones(networkCode);
      !this.list.questions.length && this.getDistinctQuestions({ networkCode });
      !this.list.questionnaires.length && this.getDistinctQuestionnaires({ networkCode });
      !this.list.categoryList.length && this.getCategoryList({ networkCode })
    }
  }

  ngOnDestroy() {
    this.loading = {
      categoryList: false,
      subCategoryList: false,
      portalQuestionDetail: false,
      lazyLoadingPortalQuestionDetail: false,
      milestones: false,
      questions: false,
      questionnaires: false,
    }
    this.filterSubmitted = false;
    this.downloadButtonEnabled.emit(false);
    this.portalQuestionDetailTableData.data = [];
  }

  toggleAdvanceFiltering(event) {
    this.expandAdvanceFiltering = event;
  }

  setPortalQuestionAdvanceFilteringForm() {
    this.portalQuestionAdvanceFilteringForm = this.formBuilder.group({
      categoryId: [null, [Validators.required]],
      subCategoryId: [null],
      milestoneName: [null],
      questionnaireName: [null],
      isConsented: [null],
      questionName: [null],
    });
  }

  resetFilter(event?) {
    if (event !== 'clear') {
      this.expandAdvanceFiltering = false;
    }
    this.offset = 0;
    this.filterSubmitted = false;
    this.portalQuestionAdvanceFilteringForm.reset();
    this.portalQuestionDetailTableData.data = [];
    this.downloadButtonEnabled.emit(false);
  }

  getCategoryList(params?) {
    this.loading.categoryList = true;
    this.reportService.getCategoryList(params)
      .pipe(finalize(() => {
        this.loading.categoryList = false;
      }))
      .subscribe((res) => {
        this.list.categoryList = res.map((x) => {
          return {
            ...x,
            label: x.name,
            value: x.id,
          }
        });
      }, error => {
        console.log("Error getting Category List", error);
      })
  }

  getSubCategoryList(params?) {
    this.loading.subCategoryList = true;
    this.reportService.getSubCategoryList(params)
      .pipe(finalize(() => {
        this.loading.subCategoryList = false;
      }))
      .subscribe((res) => {
        this.list.subCategoryList = res.map((x) => {
          return {
            ...x,
            label: x.name,
            value: x.id,
          }
        });
      }, error => {
        console.log("Error getting Sub Category List", error);
      })
  }

  getDistinctQuestions(params) {
    this.loading.questions = true;
    this.reportService.getDistinctQuestions(params)
      .pipe(finalize(() => { this.loading.questions = false; }))
      .subscribe((res) => {
        if (res.length) {
          this.list.questions = res.map((x) => ({
            label: x,
            value: x,
          }))
        } else {
          this.list.questions = [];
        }
      })
  }

  getDistinctQuestionnaires(params) {
    this.loading.questionnaires = true;
    this.reportService.getDistinctQuestionnaires(params)
      .pipe(finalize(() => { this.loading.questionnaires = false; }))
      .subscribe((res) => {
        if (res.length) {
          this.list.questionnaires = res.map((x) => ({
            label: x,
            value: x,
          }))
        } else {
          this.list.questionnaires = [];
        }
      })
  }

  getDistinctMilestones(networkCode, params?) {
    this.loading.milestones = true;
    this.reportService.getDistinctMilestones(networkCode, params)
      .pipe(finalize(() => { this.loading.milestones = false; }))
      .subscribe((res) => {
        if (res.length) {
          this.list.milestones = res.map((x) => ({
            ...x,
            label: x.name,
            value: x.name,
          }))
        } else {
          this.list.milestones = [];
        }
      })
  }

  onCategorySelected(event) {
    if (event.id) {
      this.list.subCategoryList = [];
      this.portalQuestionAdvanceFilteringForm.controls['subCategoryId'].setValue(null);
      this.getSubCategoryList(
        {
          categoryId: event.id,
          networkCode: this.parsedQuestionReviewFilterData.networkCode,
        }
      );
    }
  }

  submitFilter() {
    this.filterSubmitted = true;
    this.isfilterValidlySubmitted = false;
    if (this.portalQuestionAdvanceFilteringForm.invalid) { return };
    this.isfilterValidlySubmitted = true;
    this.loading.portalQuestionDetail = true;
    this.offset = 0;
    const { clientCode, startDate, endDate } = this.parsedQuestionReviewFilterData;
    let params = this.portalQuestionAdvanceFilteringForm.value;
    params = {
      ...params,
      clientCode,
      startDate,
      endDate
    };
    this.reportService.getPortalQuestionDetail(this._holistaUtils.removeNullOrUndefinedKeys(params))
      .pipe(finalize(() => {
        this.loading.portalQuestionDetail = false;
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res && res.length) {
          const { TABLE_HEADERS, COLUMNS_WIDTH } = PORTAL_QUESTION_DETAIL_TABLE_DATA;
          this.portalQuestionDetailTableData.tableHeaders = TABLE_HEADERS;
          this.portalQuestionDetailTableData.columnsWidth = COLUMNS_WIDTH;
          this.portalQuestionDetailTableData.data = res.map((x) => ({
            ...x,
            fullName: `${x.lastName}, ${x.firstName}`,
            answerDate: this._dateUtility.getFormattedDate(x.answerDate),
            milestoneStartDate: x.milestoneStartDate ? this._dateUtility.getFormattedDate(x.milestoneStartDate) : '-',
          }))
          this.isfilterValidlySubmitted = false;
        } else {
          this.portalQuestionDetailTableData.data = [];
          this.isfilterValidlySubmitted = true;
        }
      }, (error) => {
        console.log("Error getting Portal Question Detail", error);
        this.portalQuestionDetailTableData.data = [];
        this.isfilterValidlySubmitted = false;
      })
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = this.portalQuestionDetailTableData.data.length;
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

  toggleCard() {
    this.expandAdvanceFiltering = !this.expandAdvanceFiltering;
  }

  getPortalQuestionDetail(params, exportAll = false) {
    this.reportService.getPortalQuestionDetail(this._holistaUtils.removeNullOrUndefinedKeys(params))
      .pipe(finalize(() => {
        if (!exportAll) { this.loading.lazyLoadingPortalQuestionDetail = false; }
      }))
      .subscribe((res) => {
        if (res && res.length) {
          const mappedData = res.map((x) => ({
            ...x,
            fullName: `${x.lastName}, ${x.firstName}`,
            answerDate: this._dateUtility.getFormattedDate(x.answerDate),
            milestoneStartDate: x.milestoneStartDate ? this._dateUtility.getFormattedDate(x.milestoneStartDate) : '-',
          }));
          if (exportAll) {
            const { clientName, startDate, endDate } = this.parsedQuestionReviewFilterData;
            const reportDate = `${startDate} ${endDate ? `- ${endDate}` : ''}`;
            const tabsData = [];
            const { TABLE_HEADERS } = PORTAL_QUESTION_DETAIL_TABLE_DATA;
            const portalQuestionDetailSheetData = this._reportMappingUtils.portalQuestionDetailMapper(mappedData);
            tabsData.push({
              reportName: 'Portal Question Detail',
              clientName,
              tabName: 'Portal Question Detail',
              headers: TABLE_HEADERS.map((x) => x.label),
              data: portalQuestionDetailSheetData,
              reportDate,
            });
            this._excelMultiTabUtility.exportToExcelMultiTab('Question Review Dashboard - Portal Question Detail', tabsData).finally(() => {
              this.downloadedPDF.emit(true);
            })
          } else {
            this.portalQuestionDetailTableData.data = [...this.portalQuestionDetailTableData.data, ...mappedData];
          }
        } else {
          if (exportAll) this.downloadedPDF.emit(true);
        }
      }, (error) => {
        console.log("Error getting Portal Question Detail", error);
        this.downloadedPDF.emit(true);
      })
  }

  scrollToBottom(event) {
    if (event.scrolledToBottom && !this.loading.lazyLoadingPortalQuestionDetail) {
      this.loading.lazyLoadingPortalQuestionDetail = true;
      const { clientCode, startDate, endDate } = this.parsedQuestionReviewFilterData;
      this.offset += 1;
      const params = {
        ...this.portalQuestionAdvanceFilteringForm.value,
        clientCode,
        startDate,
        endDate,
        offset: this.offset,
      }
      this.getPortalQuestionDetail(params);
    }
  }

}
