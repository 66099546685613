import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EpisodeTaskService } from '../../../../../../services';
import { AccessType } from '../../../../../../utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-episode-signature',
  templateUrl: './episode-signature.component.html',
  styleUrls: ['./episode-signature.component.scss']
})
export class EpisodeSignatureComponent implements OnInit {
  taskToBeDelete: any
  dependentTask: any
  signatureUuid: any;
  showReasonOption: boolean = false;
  reasonList = [];
  episodeId: number;
  isLoading = {
    docuSign: false
  }
  @Input() taskSignature: any;
  @Input() optionId: any;
  @Input() user: any;
  @Input() set dependent_task(dependent_task) {
    if (dependent_task) {
      this.dependentTask = dependent_task
      if (this.signatureUuid) this.getTaskSignature(this.signatureUuid)
    }
  }
  @Input() days_difference: any;
  @Output() editTask = new EventEmitter<any>();
  @Output() btns = new EventEmitter<any>();
  @Output() deletedTask = new EventEmitter<any>();
  @ViewChildren('actionBtn', { read: ElementRef }) buttons: QueryList<ElementRef>;

  @Input() set taskSignatureUuid(taskSignatureUuid) {
    if (taskSignatureUuid) {
      this.signatureUuid = taskSignatureUuid
      this.getTaskSignature(taskSignatureUuid);
    }
  }

  constructor(
    public utilityAccess: AccessType,
    private _toastrService: ToastrService,
    private _episodeTaskService: EpisodeTaskService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const buttons = this.buttons.toArray();
    setTimeout(() => {
      this.btns.emit(buttons);
    });
  }

  getTaskSignature(uuid) {
    this.taskSignature = {};
    if (this.dependentTask && this.dependentTask.taskSignatures) {
      this.dependentTask.taskSignatures.filter(x => {
        if (x.uuid == uuid) {
          x.form = 'signature';
          x.field = 'signature'
          x.optionId = this.optionId;
          this.taskSignature = x;
        }
      })
    }
  }

  editTaskHandler(signature) {
    this.editTask.emit(signature);
  }

  deleteTaskHandler(signature) {
    if (this.utilityAccess.searchAccess('EM', 'isEditable')) {
      signature['source'] = 'Episode';
      signature['type'] = 'Signature';
      signature['displayName'] = signature.name;
      this.taskToBeDelete = signature;
    }
    else this._toastrService.warning("Access Denied", "Warning");
  }

  cancelDelete(e) {
    this.taskToBeDelete = e
  }

  deleteTask(e) {
    this.deletedTask.emit(e)
    this.taskToBeDelete = null
  }

  redirectToDocuSignTemplate(selectedTask) {
    this.isLoading.docuSign = true;
    const { id, taskId } = selectedTask;
    const params = {
      taskTodoId: id
    }
    this._episodeTaskService.getLinkToDocuSign(this.episodeId, taskId, params)
      .pipe(
        finalize(() => { this.isLoading.docuSign = false }))
      .subscribe((response: any) => {
        this.handleRedirect(response.data.envelopeUrl);
      }, error => {
        console.log('Error getting link to docuSign');
        this._toastrService.error(error)
      }
      )
  }

  handleRedirect(url: string) {
    window.open(url, '_blank');
  }
}
