// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    env: 'dev',
    apiUrl: 'https://api.devholista.noveltytechnology.com/api',
    cometChat_region: 'US',
    cometChat_appId: '2476245d2e9a528e',
    cometChat_apiKey: 'fecfedef7c8561e05e9b1ba5f632083758e94ede',
    cometChat_authKey: 'ce082410d9b628565eabe54df5202a20684f0018',
    notificationUrl: "https://dev-api-holista-episode.noveltytechnology.com",
    versionCheckURL: "https://holista-versioning.s3-us-west-2.amazonaws.com/dev/version.json",
    appStoreLink: "https://apps.apple.com/us/app/myswaddle/id1585637439",
    playStoreLink: "https://play.google.com/store/apps/details?id=com.novelty.medicaid",
    communicationsAccessKey: 'U2FsdGVkX1+p360lyBjHHsfMR4GqbHJJUcITIGj2Oeo=',
    ownerId: '3!@/fX7',
    providerDirectoryUrl: "https://predemo.holista.app.noveltytechnology.com",
};
/*
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.