<div class="table-wrapper">
  <div class="container-fluid">
    <div class="row">
      <table mdbTable stickyHeader="true" hover="true" small="true" class="v-top">
        <thead>
          <tr>
            <th *ngFor="let head of headElements" scope="col">
              {{ head }}
            </th>
            <th width="20"></th>
          </tr>
          <tr></tr>
        </thead>
        <div
          class="loader-wrapper"
          *ngIf="loading?.searching"
          [style.top]="members && members.length === 0 ? 'auto' : 0">
          <div class="loader">
            <app-loader [loader]="loading?.searching"></app-loader>
          </div>
          <div class="backdrop"></div>
        </div>
        <tbody
          mdbTableCol
          *ngFor="
            let member of members
              | paginate: {itemsPerPage: limit, currentPage: page, totalItems: totalCount};
            let i = index
          ">
          <tr [ngClass]="{'': member.isUser}">
            <td scope="row">
              <span>
                {{ member?.firstName }} {{ member?.middleName }}
                {{ member?.lastName }}
              </span>
              <br />
              <span class="z-depth-0 bg-primary-dark badge text-white">
                {{ member?.purchaserName }} </span
              ><br />
            </td>
            <td>
              {{ member?.dob | date: 'MM/dd/yyyy' }}
            </td>
            <td>
              {{ member?.gender }}
            </td>
            <td>
              <div *ngFor="let plan of member?.memberPlan">{{ plan.clientName }}</div>
              <div *ngIf="!member?.memberPlan || member?.memberPlan?.length == 0">
                N/A
              </div>
            </td>
            <td>{{ member?.subscriberId ? member?.subscriberId : 'N/A'  }}</td>
            <td>
              <div *ngFor="let plan of member?.memberPlan">
                {{ plan.effectiveDate ? (plan.effectiveDate | date: 'MM/dd/yyyy') : 'N/A' }}
              </div>
              <div *ngIf="!member?.memberPlan || member?.memberPlan?.length == 0">
                N/A
              </div>
              
            </td>
            <td>
              <div *ngFor="let plan of member?.memberPlan">
                {{ plan.terminationDate && plan?.terminationDate !== '9999-12-31' ? (plan.terminationDate | date: 'MM/dd/yyyy') : 'N/A' }}
              </div>
              <div *ngIf="!member?.memberPlan || member?.memberPlan?.length == 0">
                  N/A
              </div>
            </td>
            <td
              *ngIf="
                member?.residentialAddressLine1 ||
                member?.residentialAddressLine2 ||
                member?.residentialCity ||
                member?.residentialState ||
                member?.zip
              "
              width="222"
              (click)="member.collapsed = !member.collapsed">
              {{ member?.residentialAddressLine1 ? member.residentialAddressLine1 : '' }}
              {{ member.residentialAddressLine2 ? member.residentialAddressLine2 : '' }}
              <br *ngIf="member?.residentialAddressLine1 || member?.residentialAddressLine2" />
              {{ member?.residentialCity ? member.residentialCity : '' }}
              {{ member?.residentialState ? member.residentialState : '' }}
              {{
                member?.residentialZip
                  ? member.residentialZip4
                    ? member.residentialZip + '-' + member.residentialZip4
                    : member.residentialZip
                  : ''
              }}
            </td>
            <td
              *ngIf="
                !(
                  member?.residentialAddressLine1 ||
                  member?.residentialAddressLine2 ||
                  member?.residentialCity ||
                  member?.residentialState ||
                  member?.residentialZip
                )
              "
              width="222">
              N/A
            </td>
            <td width="190">
              <button
                class="z-depth-0 m-0 btn btn-sm btn-view-eoc"
                type="button"
                (click)="onViewEoc(member)"
                mdbWavesEffect>
                <div class="d-flex align-items-center">
                  <i *ngIf="!member.loading" class="far fa-eye fa-fw"></i>
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="member.loading"></span>
                  <span class="pl-1">View EOC</span>
                </div>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <pagination-controls
    *ngIf="members && members.length > 0"
    class="text-center"
    (pageChange)="pageChanged.emit($event)"
    autoHide="true">
  </pagination-controls>
</div>

<div
  mdbModal
  #viewEocModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100">Eligible EOC</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center">
          <div class="avatar text-center user-initials mr-2">
            <b>{{ member?.nameInitials }}</b>
          </div>
          <div>
            <p class="mb-0"><small>Patient</small></p>
            <p class="mb-0">
              {{ member?.firstName }} {{ member?.middleName }} {{ member?.lastName }}
            </p>
          </div>
        </div>
        <div class="form-group input-group has-search mt-4 mb-3">
          <span class="form-control-feedback left-0">
            <i class="icofont-search-1"></i>
          </span>
          <input
            type="text"
            [ngModel]="searchText"
            (ngModelChange)="searchByKeyword($event)"
            class="form-control"
            id="search"
            placeholder="Search CPT, episode of care" />
        </div>
        <div
          #scrollWrapper
          class="scrollbar scrollbar-primary thin overflow-auto"
          style="max-height: 200px">
          <ul
            *ngIf="filteredEligibleEocList && filteredEligibleEocList.length"
            class="list-group list-group-flush">
            <li
              *ngFor="let element of filteredEligibleEocList"
              class="list-group-item pointer-cursor px-1 py-2">
              <small class="mb-0">{{ element.name }} ({{ element.vbpCode }})</small>
            </li>
          </ul>
          <p *ngIf="filteredEligibleEocList && !filteredEligibleEocList.length" class="mb-0">
            <small>No episode of care found.</small>
          </p>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button class="btn waves-light m-0 btn-light" mdbWavesEffect (click)="closeModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
