import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class JournalService {

    constructor(
        private http: HttpClientService) {
    }

    getJournal(query) {
        return this.http.get(`${APICONSTANTS.JOURNAL_V2}`, query)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getAccounts() {
        return this.http.get(`${APICONSTANTS.JOURNAL_V2}/accounts`)
            .pipe(map((res: any) => {
                return res.data;
            }))
    }

    saveJournal(data) {
        return this.http.post(`${APICONSTANTS.JOURNAL_V2}`, data)
            .pipe(map((res: any) => {
                return res;
            }))
    }

    getAssociateEpisodeById(episodeId) {
        return this.http.get(`${APICONSTANTS.EPISODE}/associate-details/${episodeId}`)
            .pipe(map((res: any) => {
                return res.data;
            }))
    }

    journalFilter(filterData, query) {
        const episodeId = filterData.episodeId && filterData.episodeId.length > 0 ? `episodeId=${filterData.episodeId}` : ''
        const associateId = filterData.associateId && filterData.associateId.length > 0 ? `associateId=${filterData.associateId}` : ''
        const fromDate = filterData.fromDate ? `&fromDate=${filterData.fromDate}` : ''
        const toDate = filterData.toDate ? `&toDate=${filterData.toDate}` : ''
        const accountNumber = filterData.accountId && filterData.accountId.length > 0 ? `&accountNumber=${filterData.accountId}` : ''
        const claimIdentifier = filterData.claimIdentifier && filterData.claimIdentifier.length > 0 ? `&claimIdentifier=${filterData.claimIdentifier}` : ''
        // const patient = filterData.patientId ? `&patientId=${filterData.patientId}` : ''
        return this.http.get(`${APICONSTANTS.JOURNAL_V2}?${episodeId}${associateId}${fromDate}${toDate}${accountNumber}${claimIdentifier}&currentPage=${query.page}&maxRows=${query.limit}`)
            .pipe(map((res: any) => {
                return res;
            }))
    }
}
