<section class="container-fluid">
  <div class="row grey lighten-4 donotprint">
    <div class="media p-4">
      <!--Avatar-->
      <div class="avatar avatar-lg mr-3 white d-none d-sm-inline-block">
        <i class="icofont-user"></i>
      </div>
      <div class="media-body align-self-center flex-row">
        <h5 class="m-0">{{ userProfile?.firstName }} {{ userProfile?.lastName }}</h5>
        <a class="pointer-text" href="javascript:void(0)">{{
          userProfile?.email ? userProfile?.email : 'N/A'
        }}</a>
        <br />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <!-- Nav tabs -->
      <mdb-tabset
        [buttonClass]="'classic-tabs grey lighten-4 border-0'"
        [contentClass]="'tab-clean'"
        class="classic-tabs custom-tabs">
        <!--Panel 1-->
        <mdb-tab heading="Personal Information">
          <div class="row m-0 no-gutters">
            <div class="col-12">
              <div class="list-content border-0 donotprint">
                <div class="field-group pt-0 pb-0">
                  <label for="mID"
                    ><i class="icofont-id"></i>
                    <span class="d-none d-sm-inline-block">Subscriber Number:</span></label
                  >
                  <div class="field-value" id="mID">
                    {{
                      userProfile?.subscriberNumber
                        ? userProfile.subscriberNumber.replace('-', '').toUpperCase()
                        : 'N/A'
                    }}
                  </div>
                </div>
                <div class="field-group pt-0 pb-0">
                  <label for="company"
                    ><i class="icofont-building-alt"></i>
                    <span class="d-none d-sm-inline-block"
                      >{{ userProfile?.referenceType | titlecase }}:</span
                    ></label
                  >
                  <div class="field-value" id="company">
                    {{ platformInformation?.name }}{{ clientInfo?.name }}{{ purchaserInfo?.name
                    }}{{ networkInfo?.name }}
                  </div>
                </div>
                <div class="field-group pt-0 pb-0">
                  <label for="email"
                    ><i class="icofont-email"></i>
                    <span class="d-none d-sm-inline-block">Email:</span></label
                  >
                  <div class="field-value" id="email">
                    {{ userProfile?.email ? userProfile?.email : 'N/A' }}
                  </div>
                </div>
                <div class="field-group pt-0 pb-0" [ngClass]="{'mb-2': isEdit}">
                  <label for="phone"
                    ><i class="icofont-smart-phone"></i>
                    <span class="d-none d-sm-inline-block">Phone:</span></label
                  >
                  <div class="field-value" id="phone">
                    {{ userProfile?.phone ? userProfile?.phone : isEdit ? '' : 'N/A' }}
                    <button
                      class="btn btn-flat waves-effect m-0 pl-2 pr-2 skin-link p-0"
                      mdbWavesEffect
                      type="submit"
                      *ngIf="!isEdit"
                      (click)="editPhone()">
                      <i class="icofont-ui-edit"></i> edit
                    </button>
                    <!-- edit view starts from here  -->
                    <form
                      name="form"
                      (ngSubmit)="f.form.valid && savePhone()"
                      #f="ngForm"
                      novalidate
                      *ngIf="isEdit">
                      <div class="form-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          name="phone"
                          [(ngModel)]="phone"
                          #phonenumber="ngModel"
                          (ngModelChange)="checkPhoneValue($event)"
                          [textMask]="{mask: phoneMask}"
                          pattern="^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$"
                          required
                          [ngClass]="{
                            'is-invalid': f.submitted && phonenumber.invalid
                          }"
                          mdbInput />
                      </div>
                      <div class="form-check form-check-inline">
                        <p class="mb-0 mt-2">
                          Is this a mobile phone? *
                          <input
                            type="radio"
                            class="form-check-input"
                            id="Yes"
                            name="isMobile"
                            [value]="true"
                            [(ngModel)]="isMobile"
                            [disabled]="!phone"
                            mdbInput />
                          <label class="form-check-label" for="Yes">Yes</label>
                        </p>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="No"
                          name="isMobile"
                          [value]="false"
                          [(ngModel)]="isMobile"
                          [disabled]="!phone"
                          mdbInput />
                        <label class="form-check-label" for="No">No</label>
                      </div>
                      <br />
                      <button
                        class="btn btn-secondary btn-sm waves-effect m-0"
                        mdbWavesEffect
                        [disabled]="isSaving.phone"
                        type="submit">
                        <div class="d-flex align-items-center">
                          <mdb-icon fas icon="save" *ngIf="!isSaving.phone"></mdb-icon>
                          <span
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                            *ngIf="isSaving.phone"></span>
                          &nbsp;save
                        </div>
                      </button>
                      <button
                        class="btn btn-light btn-sm waves-effect"
                        mdbWavesEffect
                        type="submit"
                        (click)="cancelPhone()">
                        <div class="d-flex align-items-center">
                          <mdb-icon fas icon="times"></mdb-icon>
                          &nbsp;close
                        </div>
                      </button>
                    </form>
                    <!-- edit view ends here -->
                  </div>
                </div>

                <div class="field-group pt-0 pb-0" [ngClass]="{'mb-2': isNPIEdit}" *ngIf="showNPI">
                  <label for="npi"
                    ><i class="fa-address-card text-primary fas"></i>
                    <span class="d-none d-sm-inline-block">NPI:</span></label
                  >
                  <div class="field-value" id="npi">
                    {{ userProfile?.npi ? userProfile?.npi : isNPIEdit ? '' : 'N/A' }}
                    <button
                      class="btn btn-flat waves-effect m-0 pl-2 pr-2 skin-link p-0"
                      mdbWavesEffect
                      type="submit"
                      *ngIf="!isEdit"
                      (click)="editNPI()">
                      <i class="icofont-ui-edit"></i> edit
                    </button>
                    <form
                      name="form"
                      (ngSubmit)="f.form.valid && updateNPI()"
                      #f="ngForm"
                      novalidate
                      *ngIf="isNPIEdit">
                      <div class="form-group mb-0">
                        <input
                          type="text"
                          class="form-control"
                          name="npi"
                          [(ngModel)]="npi"
                          minlength="10"
                          numbersOnly
                          maxlength="10"
                          [ngClass]="{'is-invalid': f.submitted && !f.form.valid}"
                          mdbInput />
                      </div>
                      <button
                        class="btn btn-secondary btn-sm waves-effect m-0"
                        mdbWavesEffect
                        type="submit"
                        [disabled]="submit_enabled">
                        <mdb-icon fas icon="save"></mdb-icon> save
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                          *ngIf="submit_enabled"></span>
                      </button>
                      <button
                        class="btn btn-light btn-sm waves-effect"
                        mdbWavesEffect
                        type="submit"
                        (click)="cancelNPI()">
                        <mdb-icon fas icon="times"></mdb-icon> close
                      </button>
                    </form>
                  </div>
                </div>

                <div class="field-group pt-0 pb-0 mb-3">
                  <label for="role"
                    ><i class="icofont-user-suited"></i>
                    <span class="d-none d-sm-inline-block">Role:</span>
                  </label>
                  <div class="field-value" id="role">
                    <span *ngFor="let role of roleInfos">{{ role?.name }} <br /></span>
                  </div>
                  <!-- <div *ngIf="userProfile?.roleCode=='MP' ||!userProfile?.secondaryRole" class="field-value" id="role">
                    {{userProfile?.role?.name}}</div>
                  <div *ngIf="userProfile?.roleCode!='MP' && userProfile?.secondaryRole" class="field-value w-100">
                    <div class="radio-block form-row">
                      <div class="col">
                        <label class="form-check-label" for="link">
                          <input type="radio" class="form-check-input" id="link" name="type"
                            [checked]="userProfile.secondaryRole && userProfile.activeRole!=='MP'"
                            [disabled]="userProfile.activeRole!=='MP'" (click)='switchRole(userProfile,$event)'
                            mdbInput>
                          <div class="box m-0 d-flex justify-content-center align-items-center">
                            {{userProfile?.role?.name}}
                          </div>

                        </label>
                      </div>
                      <div class="col">
                        <label class="form-check-label" for="existing">
                          <input type="radio" class="form-check-input" id="existing" name="type"
                            [checked]="userProfile.secondaryRole && userProfile.activeRole=='MP'"
                            [disabled]="userProfile.activeRole=='MP'" (click)='switchRole(userProfile,$event)' mdbInput>
                          <div class="box m-0 d-flex justify-content-center align-items-center">
                            Patient
                          </div>
                        </label>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="field-group pt-0 pb-0">
                  <label for="role"
                    ><i class="icofont-notification"></i>
                    <span class="d-none d-sm-inline-block">SMS Alerts:</span>
                  </label>
                  <div class="field-value">
                    <div class="switch">
                      <label>
                        <input
                          type="checkbox"
                          [(ngModel)]="triggerSms"
                          (change)="updateTriggerSMS(triggerSms)"
                          [disabled]="!(userProfile?.phone && userProfile?.isMobile)"
                          [checked]="userProfile?.userSetting.triggerSMS" />
                        <span class="lever m-0"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div *ngIf="userProfile && !userProfile.isSSO && userProfile.isMfaEnabled">
                  <p class="mt-4"><b>Multi-Factor Authentication</b></p>
                  <form [formGroup]="multiFactorForm">
                    <div class="field-group pt-0 pb-0">
                      <label for="triggerOtpViaEmail"
                        ><i class="icofont-notification"></i>
                        <span class="d-none d-sm-inline-block">Receive OTP via Email:</span>
                      </label>
                      <div class="field-value">
                        <div class="switch">
                          <label>
                            <input
                              type="checkbox"
                              formControlName="enableMfaEmail"
                              (change)="
                                updateMFAPreference('triggerOtpViaEmail', $event.target.checked)
                              " />
                            <span class="lever m-0"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="field-group pt-0 pb-0">
                      <label for="triggerOtpViaSms"
                        ><i class="icofont-notification"></i>
                        <span class="d-none d-sm-inline-block">Receive OTP via SMS:</span>
                      </label>
                      <div class="field-value">
                        <div class="switch">
                          <label>
                            <input
                              type="checkbox"
                              formControlName="enableMfaSms"
                              (change)="
                                updateMFAPreference('triggerOtpViaSms', $event.target.checked)
                              " />
                            <span class="lever m-0"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 2-->
        <mdb-tab heading="Password & Security" *ngIf="!userProfile?.isSSO">
          <div class="row mb-4 pb-4">
            <div class="col-sm-6 col-xs-12">
              <div class="list-content border-0 donotprint">
                <form [formGroup]="changePasswordForm">
                  <div class="form-group">
                    <label for="opwd">Old Password</label>
                    <input
                      type="password"
                      id="opwd"
                      class="form-control"
                      formControlName="oldPassword"
                      data-error=" "
                      data-success=" "
                      mdbInput
                      [ngClass]="{
                        'is-invalid': submitted && changePasswordForm.get('oldPassword').invalid
                      }" />
                  </div>
                  <div class="form-group">
                    <label for="npwd">New Password</label>
                    <input
                      type="password"
                      id="npwd"
                      class="form-control"
                      formControlName="password"
                      data-error=" "
                      data-success=" "
                      mdbInput
                      [ngClass]="{
                        'is-invalid': submitted && changePasswordForm.get('password').invalid
                      }" />
                  </div>
                  <div class="form-group">
                    <label for="cpwd">Confirm Password</label>
                    <input
                      type="password"
                      id="cpwd"
                      class="form-control"
                      formControlName="confirmPassword"
                      data-error=" "
                      data-success=" "
                      mdbInput
                      [ngClass]="{
                        'is-invalid': submitted && changePasswordForm.get('confirmPassword').invalid
                      }" />
                  </div>
                </form>
              </div>
            </div>
            <div class="col-12 mb-4 pb-4">
              <h6 class="font-weight-500 mt-3">Rules to follow while changing passwords</h6>
              <ul class="fa-ul">
                <li>
                  <span class="fa-li"
                    ><i
                      [ngClass]="
                        errorMessage.toLowerCase() === 'password does not match pattern.' ||
                        changePasswordForm.controls.password.errors?.pattern
                          ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                          : 'fa fa-info-circle fw-2 text-info mr-2'
                      "></i
                  ></span>
                  <small
                    [ngClass]="
                      errorMessage.toLowerCase() === 'password does not match pattern.' ||
                      changePasswordForm.controls.password.errors?.pattern
                        ? 'text-danger'
                        : ''
                    "
                    >Password must be at least 8 characters including at least one Uppercase,
                    Lowercase, Special character i.e #@! and a number. Spaces are not allowed in
                    Password.</small
                  >
                </li>
                <li>
                  <span class="fa-li"
                    ><i
                      [ngClass]="
                        errorMessage.toLowerCase() === 'password should not contain personal info.'
                          ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                          : 'fa fa-info-circle fw-2 text-info mr-2'
                      "></i
                  ></span>
                  <small
                    [ngClass]="
                      errorMessage.toLowerCase() === 'password should not contain personal info.'
                        ? 'text-danger'
                        : ''
                    "
                    >Password should not include personal information, such as name or birth
                    date.</small
                  >
                </li>
                <li>
                  <span class="fa-li"
                    ><i
                      [ngClass]="
                        errorMessage.toLowerCase() === 'password combination contains banned word.'
                          ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                          : 'fa fa-info-circle fw-2 text-info mr-2'
                      "></i
                  ></span>
                  <small
                    [ngClass]="
                      errorMessage.toLowerCase() === 'password combination contains banned word.'
                        ? 'text-danger'
                        : ''
                    "
                    >Password should not include global banned password.</small
                  >
                </li>
                <li>
                  <span class="fa-li"
                    ><i
                      [ngClass]="
                        errorMessage.toLowerCase() === 'password has already been used previously.'
                          ? 'fas fa-exclamation-triangle fw-2 text-danger mr-2'
                          : 'fa fa-info-circle fw-2 text-info mr-2'
                      "></i
                  ></span>
                  <small
                    [ngClass]="
                      errorMessage.toLowerCase() === 'password has already been used previously.'
                        ? 'text-danger'
                        : ''
                    "
                    >New password cannot be the same as any of the previous 6 passwords.</small
                  >
                </li>
                <li *ngIf="errorMessage.toLowerCase() === 'incorrect old password'">
                  <span class="fa-li"
                    ><i class="fas fa-exclamation-triangle fw-2 text-danger mr-2"></i
                  ></span>
                  <small class="text-danger">Incorrect Old Password.</small>
                </li>
                <li *ngIf="changePasswordForm.controls.confirmPassword.errors?.MatchPassword">
                  <span class="fa-li"
                    ><i class="fas fa-exclamation-triangle fw-2 text-danger mr-2"></i
                  ></span>
                  <small class="text-danger"
                    >New Password and Confirm Password does not match.</small
                  >
                </li>
              </ul>
              <div class="lighten-4 d-flex justify-content-start p-0 border-top-0">
                <button
                  class="btn btn-secondary waves-light ml-0"
                  type="button"
                  (click)="changePassword()"
                  [disabled]="submit_enabled || changePasswordForm.invalid">
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="submit_enabled"></span>
                  <i class="icofont-lock" *ngIf="!submit_enabled"></i> Change Password
                </button>
                <button
                  class="waves-light btn-light btn"
                  mdbWavesEffect
                  (click)="showchangePassword()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 3-->
        <mdb-tab heading="ID Card" *ngIf="user.roleCode == 'MP'">
          <!-- SHOW ID CARD -->
          <app-card-template [memberId]="user.memberUuid"> </app-card-template>

          <!-- SHOW ID CARD  END-->
        </mdb-tab>
        <mdb-tab heading="Platform Information" *ngIf="user.roleCode == 'PA'">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="platformInformationForm">
                <div class="list-content border-0 donotprint">
                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="icofont-building-alt"></i>
                      <span class="d-none d-sm-inline-block">Name:</span></label
                    >
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.name }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <input
                        type="text"
                        mdbInput
                        class="form-control"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': submitted && platformInformationForm.get('name').invalid
                        }"
                        name="name" />
                    </div>
                  </div>
                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="icofont-id"></i>
                      <span class="d-none d-sm-inline-block">Identifier:</span></label
                    >
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.identifier }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <input
                        type="text"
                        mdbInput
                        class="form-control"
                        formControlName="identifier"
                        maxLength="15"
                        numbersOnly
                        [ngClass]="{
                          'is-invalid':
                            submitted && platformInformationForm.get('identifier').invalid
                        }"
                        name="identifier" />
                    </div>
                  </div>
                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="icofont-id"></i>
                      <span class="d-none d-sm-inline-block">NPI:</span></label
                    >
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.npi }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <input
                        type="text"
                        mdbInput
                        class="form-control"
                        formControlName="npi"
                        numbersOnly
                        [ngClass]="{
                          'is-invalid': submitted && platformInformationForm.get('npi').invalid
                        }"
                        name="npi"
                        maxlength="10" />
                    </div>
                  </div>

                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="icofont-id"></i>
                      <span class="d-none d-sm-inline-block">TIN:</span></label
                    >
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.tin }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <input
                        type="text"
                        mdbInput
                        class="form-control"
                        formControlName="tin"
                        numbersOnly
                        [ngClass]="{
                          'is-invalid': submitted && platformInformationForm.get('tin').invalid
                        }"
                        name="tin"
                        maxlength="9" />
                    </div>
                  </div>
                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="icofont-email"></i>
                      <span class="d-none d-sm-inline-block">Email:</span>
                    </label>
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.email }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <input
                        type="text"
                        mdbInput
                        class="form-control"
                        formControlName="email"
                        [ngClass]="{
                          'is-invalid': submitted && platformInformationForm.get('email').invalid
                        }"
                        name="email" />
                    </div>
                  </div>
                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="icofont-phone"></i>
                      <span class="d-none d-sm-inline-block">Phone:</span>
                    </label>
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.phone }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <input
                        type="text"
                        mdbInput
                        class="form-control"
                        formControlName="phone"
                        [textMask]="{mask: phoneMask}"
                        [ngClass]="{
                          'is-invalid': submitted && platformInformationForm.get('phone').invalid
                        }"
                        name="phone" />
                    </div>
                  </div>
                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="fa-map-marker-alt text-primary fas"></i>
                      <span class="d-none d-sm-inline-block">Address:</span>
                    </label>
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.addressLine1 }}<br />
                      {{
                        platformInformation?.addressLine2 ? platformInformation.addressLine2 : ''
                      }}
                      {{ platformInformation?.city }}
                      {{ platformInformation?.state }} {{ platformInformation?.zip
                      }}{{ platformInformation?.zip4 ? '-' + platformInformation.zip4 : '' }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <div class="form-row mb-2">
                        <div class="col-6">
                          <input
                            type="text"
                            mdbInput
                            class="form-control mb-2"
                            formControlName="addressLine1"
                            [ngClass]="{
                              'is-invalid':
                                submitted && platformInformationForm.get('addressLine1').invalid
                            }"
                            name="addressLine1"
                            placeholder="Address line 1" />
                        </div>
                        <div class="col-6">
                          <input
                            type="text"
                            mdbInput
                            class="form-control mb-2"
                            formControlName="addressLine2"
                            name="addressLine2"
                            placeholder="Address line 2" />
                        </div>
                      </div>
                      <div class="form-row mb-2">
                        <div class="col-6">
                          <input
                            type="text"
                            mdbInput
                            class="form-control"
                            formControlName="city"
                            [ngClass]="{
                              'is-invalid': submitted && platformInformationForm.get('city').invalid
                            }"
                            name="city"
                            placeholder="City" />
                        </div>
                        <div class="col-2">
                          <mdb-select
                            [options]="usStates | sort : 'label'"
                            id="state"
                            formControlName="state"
                            placeholder="State"
                            [ngClass]="{
                              'error-select':
                                submitted && platformInformationForm.get('state').invalid
                            }">
                          </mdb-select>
                        </div>
                        <div class="col-2">
                          <input
                            type="text"
                            numbersOnly
                            mdbInput
                            class="form-control"
                            formControlName="zip"
                            maxlength="5"
                            [ngClass]="{
                              'is-invalid': submitted && platformInformationForm.get('zip').invalid
                            }"
                            name="zip" />
                        </div>
                        <div class="col-2">
                          <input
                            type="text"
                            numbersOnly
                            mdbInput
                            class="form-control"
                            formControlName="zip4"
                            maxlength="4"
                            [ngClass]="{
                              'is-invalid': submitted && platformInformationForm.get('zip4').invalid
                            }"
                            name="zip4"
                            [readonly]="!platformInformationForm.value.zip" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="icofont-user"></i>
                      <span class="d-none d-sm-inline-block">Contact Name:</span>
                    </label>
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.contactName }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <input
                        type="text"
                        mdbInput
                        class="form-control"
                        formControlName="contactName"
                        [ngClass]="{
                          'is-invalid':
                            submitted && platformInformationForm.get('contactName').invalid
                        }"
                        name="contactName" />
                    </div>
                  </div>
                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="icofont-email"></i>
                      <span class="d-none d-sm-inline-block">Contact Email:</span>
                    </label>
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.contactEmail }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <input
                        type="text"
                        mdbInput
                        class="form-control"
                        formControlName="contactEmail"
                        [ngClass]="{
                          'is-invalid':
                            submitted && platformInformationForm.get('contactEmail').invalid
                        }"
                        name="contactEmail" />
                    </div>
                  </div>
                  <div class="field-group pt-0 pb-0 d-flex mb-2">
                    <label
                      ><i class="icofont-phone"></i>
                      <span class="d-none d-sm-inline-block">Contact Phone:</span>
                    </label>
                    <div class="field-value" *ngIf="!editPlatform">
                      {{ platformInformation?.contactPhone }}
                    </div>
                    <div class="w-50" *ngIf="editPlatform">
                      <input
                        type="text"
                        mdbInput
                        class="form-control"
                        formControlName="contactPhone"
                        [textMask]="{mask: phoneMask}"
                        [ngClass]="{
                          'is-invalid':
                            submitted && platformInformationForm.get('contactPhone').invalid
                        }"
                        name="contactPhone" />
                    </div>
                  </div>
                </div>
                <div class="field-group pt-0 pb-0 d-flex mb-2">
                  <div class="field-value">
                    <button
                      type="button"
                      class="btn btn-secondary ml-0"
                      mdbWavesEffect
                      (click)="editPlatform = !editPlatform; editPlatformInfo()"
                      *ngIf="!editPlatform">
                      <i class="icofont-ui-edit"></i> Edit
                    </button>

                    <button
                      type="button"
                      class="btn btn-secondary ml-2"
                      mdbWavesEffect
                      (click)="updatePlatformInfo()"
                      *ngIf="editPlatform">
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                        *ngIf="submit_enabled"></span>
                      <i class="icofont-save"></i> Update
                    </button>
                    <button
                      type="button"
                      class="waves-light m-0 btn-light btn"
                      mdbWavesEffect
                      (click)="editPlatform = !editPlatform; denyUpdatePlatformInfo()"
                      *ngIf="editPlatform">
                      <i class="icofont-close"></i>
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </mdb-tab>
        <mdb-tab heading="Provider" *ngIf="user.roleCode == 'PR'">
          <div class="row">
            <div class="col-12">
              <section class="container-fluid grey lighten-5 p-4" *ngIf="user.roleCode == 'PR'">
                <h6 class="font-weight-500" *ngIf="providerLocationList.length > 0">
                  Assigned Provider
                </h6>

                <div class="form-row" *ngIf="providerLocationList.length > 0">
                  <div
                    class="col-6 col-sm-4 col-md-3 col-xl-3"
                    *ngFor="let component of providerLocationList">
                    <mdb-card class="z-depth-0 border mb-3">
                      <mdb-card-body class="p-3">
                        <div class="media">
                          <div class="avatar d-flex border mr-3">
                            <img
                              class="img-fluid"
                              src="{{
                                component.imageUrl
                                  ? component.imageUrl
                                  : '../../../assets/img/png/building_icon.png'
                              }}" />
                          </div>

                          <div class="media-body">
                            <h6 class="mt-0 font-weight-bold mb-0">{{ component.displayName }}</h6>
                            <p class="mb-2 text-muted" *ngIf="component.npi || component.taxId">
                              <small>
                                {{ component.npi ? 'NPI - ' : '' }}{{ component.npi }}
                                <br />
                                {{ component.taxId ? 'TIN - ' : '' }}{{ component.taxId }}
                              </small>
                            </p>
                            <p>
                              <small>
                                {{ component.addressline1 }} <br />
                                {{ component.city }} {{ component.state }} {{ component.zip }}
                              </small>
                            </p>
                          </div>
                        </div>
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                </div>
                <h6 *ngIf="providerLocationList.length == 0">No Assigned Providers</h6>
              </section>
            </div>
          </div>
        </mdb-tab>
      </mdb-tabset>
    </div>
  </div>
</section>

<!-- <app-switch-role [switchRole]="roleToSwitch" (cancel_Switch)="cancelRoleSwitch()">
</app-switch-role> -->
