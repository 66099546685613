<div class="donotprint">
  <!-- Sidebar navigation -->
  <mdb-side-nav
    #sidenav
    class="wide fixed side-nav-light z-depth-0"
    [ngClass]="{slim: sidenavStatus === 'layoutCollapse'}"
    [fixed]="true"
    [sidenavBreakpoint]="1200">
    <mdb-navbar-brand class="shadow-sm">
      <!-- Logo -->
      <div
        class="logo-wrapper waves-light border-0 shadow-sm position-sticky"
        [class.disabled]="otpToken || user.roleCode === roleCodes.REPORTING">
        <a
          class="d-flex align-items-center justify-content-between logo"
          [routerLink]="['/dashboard']"
          mdbWavesEffect>
          <i class="icofont-home icofont-lg text-primary"></i>
        </a>
      </div>
      <!--/. Logo -->
    </mdb-navbar-brand>

    <!-- This section is for nested modules and subModules -->
    <links>
      <li>
        <ul class="collapsible collapsible-accordion">
          <mdb-accordion [multiple]="true" aria-multiselectable="false">
            <div *ngIf="!otpToken">
              <mdb-accordion-item *ngFor="let menu of menus; let i = index">
                <mdb-accordion-item-head class="main-menu" mdbWavesEffect>
                  <a
                    class="position-relative"
                    [routerLink]="!menu.isExpanded ? [menu.redirectPath] : []"
                    (click)="toggleCollapsible(menu)"
                    [ngClass]="{
                      'overflow-hidden': sidenavStatus === 'layoutCollapse'
                    }">
                    <i class="{{ menu.iconName }}"></i>
                    {{ menu.name }}
                  </a>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body style="margin-left: 1.7rem">
                  <mdb-accordion [multiple]="true" aria-multiselectable="false">
                    <mdb-accordion-item
                      *ngFor="let module of menu.modules"
                      [ngClass]="{
                        'no-collase':
                          excludedSubModules.includes(module.name) || !module.subModules.length
                      }">
                      <mdb-accordion-item-head mdbWavesEffect>
                        <a
                          [routerLink]="!module.isExpanded ? [module.redirectPath] : []"
                          (click)="toggleCollapsible(module)"
                          class="position-relative font-weight-normal"
                          style="border-right: none"
                          [ngClass]="{
                            'active-menu': header$ && header$[0]['name'] === module.name,
                            'overflow-hidden': sidenavStatus === 'layoutCollapse'
                          }">
                          {{ module.name }}
                          <span
                            *ngIf="showMessageCount(module.code)"
                            class="position-absolute end-0 mr-2">
                            <small class="bg-primary-dark text-white px-2 py-1 rounded-circle">
                              {{ unreadInboxCountService.count }}
                            </small>
                          </span>
                        </a>
                      </mdb-accordion-item-head>
                      <mdb-accordion-item-body *ngIf="!excludedSubModules.includes(module.name)">
                        <a
                          *ngFor="let subModule of module.subModules"
                          [routerLink]="!subModule.isExpanded ? [subModule.redirectPath] : []"
                          class="position-relative ml-2"
                          style="border-right: none"
                          [ngClass]="{
                            'active-menu': header$ && header$[0]['name'] === subModule.name,
                            'overflow-hidden': sidenavStatus === 'layoutCollapse'
                          }">
                          {{ subModule.name }}
                        </a>
                      </mdb-accordion-item-body>
                    </mdb-accordion-item>
                  </mdb-accordion>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </div>
            <div *ngIf="otpToken">
              <mdb-accordion-item class="no-collase">
                <a
                  (click)="hideSideNav()"
                  mdbWavesEffect
                  [routerLink]="'/communications'"
                  class="position-relative"
                  class="active-menu"
                  [class.overflow-hidden]="sidenavStatus === 'layoutCollapse'">
                  <i class="fa-fa fa-envelope fas"></i>
                  Communications
                  <span
                    class="position-absolute end-0 mr-2"
                    *ngIf="unreadInboxCountService.count > 0">
                    <small class="bg-primary-dark text-white px-2 py-1 rounded-circle">
                      {{ unreadInboxCountService.count }}
                    </small>
                  </span>
                </a>
              </mdb-accordion-item>
            </div>
          </mdb-accordion>
        </ul>
      </li>
    </links>

    <!-- <div class="sidenav-bg mask-light"></div> -->
  </mdb-side-nav>
  <button
    type="button"
    (click)="toggleSideNav()"
    class="border-0 menu-toggle m-0 btn-primary btn-sm btn-floating"
    mdbWavesEffect>
    <mdb-icon fas icon="bars" [attr.data-test-id]="'nav-bar'"></mdb-icon>
  </button>
  <!--/. Sidebar navigation -->
  <div *ngIf="otpToken">
    <mdb-navbar
      SideClass="white navbar fixed-top navbar-toggleable-md navbar-expand-md scrolling-navbar double-nav pt-0 pb-0 pr-0 shadow-sm"
      [containerInside]="false"
      [ngStyle]="{height: '74px'}">
      <navlinks class="d-flex align-items-center" [ngStyle]="{height: '74px'}">
        <!-- Breadcrumb-->
        <div class="breadcrumbs breadcrumb-dn mr-auto ml-sm-5 ml-lg-1 d-none d-sm-flex w-auto">
          <ol class="breadcrumb">
            <li class="breadcrumb-item text-dark">
              <span>Communications</span>
            </li>
          </ol>
        </div>
      </navlinks>
    </mdb-navbar>
  </div>
  <div *ngIf="!otpToken">
    <mdb-navbar
      SideClass="white navbar fixed-top navbar-toggleable-md navbar-expand-md scrolling-navbar double-nav pt-0 pb-0 pr-0 shadow-sm"
      [containerInside]="false">
      <navlinks class="d-flex justify-content-between align-items-center w-100">
        <!-- Breadcrumb-->
        <div class="breadcrumbs mr-auto ml-5 ml-lg-1 w-auto">
          <ol class="breadcrumb">
            <li class="breadcrumb-item text-dark" *ngFor="let path of header$; let i = index">
              <a
                class="skin-link"
                [routerLink]="[path.path]"
                [queryParams]="{clicked: true}"
                *ngIf="i !== header$.length - 1"
                >{{ path.name }}
              </a>
              <span [attr.data-test-id]="'module-name'" *ngIf="i === header$.length - 1">{{
                path.name
              }}</span>
            </li>
          </ol>
        </div>
        <!--/. Breadcrumb-->
        <div>
          <!-- <a *ngIf="imageDetails && imageDetails.source == 'web' && (user?.companyCode?.toLowerCase() == 'novant' || (user?.parentCompany && user?.parentCompany?.companyCode?.toLowerCase() == 'novant'))"
          href="{{ imageDetails.link }}" target="_blank"><img class="d-none d-md-block" src="{{ imageDetails.imgpath }}"
            alt="" /></a> -->

          <div
            class="alert bg-secondary text-white p-2 mb-0 d-flex align-items-center disclaimer w-sm-100"
            role="alert"
            *ngIf="user?.roleCode === 'MP'">
            <i class="fas fa-exclamation-triangle fa-lg mr-2"></i>
            <p class="font-small mb-0 text-white">
              DISCLAIMER: This portal does not provide medical advice
            </p>
            <button
              type="button"
              class="btn btn-sm btn-light mt-0 mb-0"
              (click)="showDisclaimerModal()">
              Learn more
            </button>
          </div>
        </div>
        <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav">
          <li
            *ngIf="
              user?.roleCode === roleCodes.CARE_COORDINATOR ||
              user?.roleCode === roleCodes.SUPERVISOR ||
              user?.roleCode === roleCodes.SUPER_ADMINISTRATOR
            "
            class="nav-item"
            [attr.data-test-id]="'nav-item'">
            <div class="btn-group">
              <a
                mdbDropdownToggle
                mdbWavesEffect
                type="button"
                (click)="navigateToProviderDirectory()"
                class="nav-link waves-light position-relative"
                mdbWavesEffect>
                <i class="mr-1" style="font-style: normal">Provider Directory</i>
                <i class="icofont-external-link icofont-lg"></i>
              </a>
            </div>
          </li>
          <li *ngIf="user?.roleCode !== 'PR'" class="nav-item">
            <div class="btn-group dropdown" mdbDropdown>
              <a
                mdbDropdownToggle
                mdbWavesEffect
                type="button"
                class="nav-link waves-light position-relative"
                mdbWavesEffect>
                <i class="icofont-notification icofont-lg"></i>
                <span
                  class="badge badge-danger rounded-circle notification ml-2 p-0"
                  *ngIf="notifications.length > 0"
                  >{{ notifications.length }}</span
                >
              </a>

              <div
                role="menu"
                class="dropdown-menu dropdown-menu-lg dropdown-menu-right rounded-0 border-0 scrollbar scrollbar-primary thin"
                style="margin-right: -65px">
                <h6 class="m-2 text-muted">Notifications</h6>
                <div class="divider dropdown-divider mb-0"></div>
                <a
                  class="dropdown-item d-flex justify-content-start align-items-center border-bottom border-light"
                  *ngFor="let notify of notifications | orderBy : 'id' : true"
                  (click)="markNotification(notify)">
                  <div class="profile-photo">
                    <i class="icofont-ui-user"></i>
                  </div>
                  <div class="text-smaller ml-2">
                    <span class="font-weight-500">{{ notify.memberName }}</span>

                    <p class="mb-0">
                      <i
                        [className]="
                          notify.type === 'question'
                            ? 'icofont-question-circle'
                            : notify.type === 'signature'
                            ? 'fas fa-file-signature'
                            : 'icofont-ui-text-chat'
                        "></i>
                      {{
                        notify.questionName
                          ? notify.questionName
                          : notify.type === 'signature'
                          ? notify.name
                          : notify.message
                      }}
                    </p>
                  </div>
                </a>
                <p class="ml-2 mt-3" *ngIf="!notifications || notifications.length === 0">
                  Notification has been acknowledged.
                </p>
              </div>
            </div>
          </li>
          <li *ngIf="user?.roleCode !== 'PR'" class="nav-item">
            <div class="btn-group dropdown" mdbDropdown>
              <a
                mdbDropdownToggle
                mdbWavesEffect
                type="button"
                class="nav-link waves-light position-relative"
                mdbWavesEffect>
                <i class="icofont-ui-text-chat icofont-lg"></i>
                <span
                  class="badge badge-danger rounded-circle notification p-0"
                  *ngIf="userMessages && userMessages.length > 0"
                  >{{ userMessages.length }}</span
                >
              </a>
              <div
                role="menu"
                class="dropdown-menu dropdown-menu-lg dropdown-menu-right rounded-0 border-0 scrollbar scrollbar-primary thin">
                <h6 class="m-2 text-muted">Messages</h6>
                <div class="divider dropdown-divider mb-0"></div>
                <a
                  class="dropdown-item d-flex justify-content-start align-items-center border-bottom border-light"
                  href="javascript:void(0)"
                  (click)="markMessageAsRead(chatUser)"
                  *ngFor="let chatUser of userMessages">
                  <div class="profile-photo user-offline">
                    <i class="icofont-ui-user"></i
                    ><span
                      class="state"
                      [ngClass]="{
                        'state-online': chatUser.status === 'available',
                        'state-offline': chatUser.status !== 'available'
                      }"></span>
                  </div>
                  <div class="text-smaller ml-2">
                    <span class="font-weight-500">{{ chatUser.name }} ({{ chatUser.count }}) </span>
                    <p
                      class="mb-0"
                      [ngStyle]="{
                        color:
                          chatUser.lastMessage &&
                          user?.id === chatUser.lastMessage.receiver.uid &&
                          !chatUser.lastMessage.readAt
                            ? '#67ACD5'
                            : ''
                      }">
                      {{
                        chatUser.lastMessage &&
                        chatUser.lastMessage.category === 'message' &&
                        !chatUser.lastMessage.data.attachments
                          ? chatUser.lastMessage.sender.uid === user?.id
                            ? 'You' +
                              ':' +
                              ' ' +
                              (chatUser.lastMessage.data.text | truncate : ['30', '...'])
                            : (chatUser.lastMessage.data.text | truncate : ['30', '...'])
                          : ''
                      }}
                      {{
                        chatUser.lastMessage &&
                        chatUser.lastMessage.category === 'message' &&
                        chatUser.lastMessage.data.attachments
                          ? (chatUser.lastMessage.sender.uid === user?.id
                              ? 'You'
                              : chatUser.lastMessage.sender.name) +
                            '
                    ' +
                            'sent an attachment.'
                          : ''
                      }}
                    </p>
                  </div>
                </a>
                <p class="ml-2 mt-3" *ngIf="!userMessages || userMessages.length === 0">
                  Message has been acknowledged.
                </p>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div class="btn-group dropdown" mdbDropdown>
              <a
                mdbDropdownToggle
                mdbWavesEffect
                type="button"
                class="d-flex align-items-center nav-link dropdown-toggle waves-light"
                mdbWavesEffect>
                <span class="avatar d-inline-block grey lighten-3 text-center mr-0 mr-sm-2">
                  <i class="icofont-user"></i>
                </span>
                <span class="mr-3 d-none d-sm-block" *ngIf="user">
                  <h6 class="mb-0">{{ user?.firstName + ' ' + user?.lastName }}</h6>
                  <span class="badge badge-sm bg-primary-dark text-white z-depth-0">{{
                    user?.displayRole
                  }}</span>
                </span>
              </a>
              <div
                role="menu"
                class="dropdown-menu dropdown-menu-lg dropdown-menu-right rounded-0 border-0 scrollbar scrollbar-primary thin">
                <!-- <span class="mr-3 d-block d-sm-none dropdown-item" *ngIf="user">
                <h6 class="mb-0">{{ user?.firstName + ' ' + user?.lastName }}</h6>
                <span class="badge badge-sm bg-primary-dark  text-white z-depth-0">{{ user?.displayRole }}</span>
              </span> -->
                <!-- switch account design start here -->
                <h6 *ngIf="user?.roles.length > 1" class="dropdown-header pl-3">Switch Account</h6>
                <div *ngFor="let role of user?.roles">
                  <a
                    class="d-flex align-items-center dropdown-item"
                    (click)="switchRole(role)"
                    mdbWavesEffect>
                    <span class="avatar d-inline-block grey lighten-3 text-center mr-2">
                      <i class="icofont-user"></i>
                    </span>
                    <span class="mr-3" *ngIf="user">
                      <h6 class="mb-0">{{ user?.firstName + ' ' + user?.lastName }}</h6>
                      <span class="badge badge-sm bg-primary-dark text-white z-depth-0">{{
                        role?.name
                      }}</span>
                    </span>
                    <mdb-icon
                      *ngIf="role.isActiveRole"
                      class="text-success ml-auto"
                      fas
                      icon="check"></mdb-icon>
                  </a>
                </div>
                <div class="divider dropdown-divider"></div>
                <!-- switch account design end here -->
                <a class="dropdown-item" [routerLink]="['/my-profile']">My Profile</a>
                <div class="divider dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()">Logout</a>
              </div>
            </div>
          </li>
        </ul>
      </navlinks>
    </mdb-navbar>
  </div>

  <!-- <a *ngIf="imageDetails && imageDetails.source == 'mobile' && (user.companyCode.toLowerCase() == 'novant' || (user.parentCompany && user.parentCompany.companyCode.toLowerCase() == 'novant'))"
    href="{{ imageDetails.link }}" target="_blank"><img class="d-block d-md-none img-fluid"
      src="{{ imageDetails.imgpath }}" alt="" style="margin-top: 74px" /></a> -->
</div>

<!-- Disclaimer Modal -->

<div
  mdbModal
  #disclaimerModal="mdbModal"
  class="modal fade top"
  id="disclaimerModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header z-depth-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="hideDisclaimerModal()">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center mb-5">
          <i class="fas fa-exclamation-triangle fa-3x text-secondary mr-2"></i>
          <p class="mb-0 font-weight-500">
            DISCLAIMER: <br />
            THIS PORTAL DOES NOT PROVIDE MEDICAL ADVICE
          </p>
        </div>
        <p>
          The information, including but not limited to, text, graphics, images and other material
          contained on this website are for informational purposes only. No material on this site is
          intended to be a substitute for professional medical advice, diagnosis or treatment.
          Always seek the advice of your physician or other qualified health care provider with any
          questions you may have regarding a medical condition or treatment and before undertaking a
          new health care regimen, and never disregard professional medical advice or delay in
          seeking it because of something you have read on this website.
        </p>
        <p>
          A quote of benefits and/or authorization does not guarantee payment or verify eligibility.
          Payment of benefits are subject to all terms, conditions, limitations, and exclusions of
          the member’s contract at the time of service.
        </p>
        <p>
          I understand that my health insurance company may deny or limit payment for the services
          identified, for any reason (stated or not). If my health insurance company denies payment,
          I agree to be personally and fully responsible for payment. I also understand that if my
          health insurance company does make payment for services, I will be responsible for any
          co-payment, deductible, or coinsurance that applies.
        </p>
      </div>
      <div class="modal-footer justify-content-between grey lighten-3 border-0">
        <mdb-checkbox [(ngModel)]="dontShowAgain" [disabled]="user?.userSetting.disclaimerAck"
          >I understand, don't show again</mdb-checkbox
        >
        <button type="button" class="btn btn-light mt-0 mb-0" (click)="hideDisclaimerModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
