import { createReducer, on } from '@ngrx/store';
import * as SideNavActions from '../action';

export const initialStatus = 'layoutExpand';

// const _headerBreadcrumbsReducer = createReducer(initialState_breadCrumb,
//   on(addHeaderBreadcrumbs, state =>[]),
//   on(removeHeaderBreadcrumbs, state => ['abc']),
//   on(resetHeaderBreadcrumbs, state => []),
// );

export function sideNavReducer(state = [initialStatus], action: SideNavActions.SideNavActions) {
  // return _headerBreadcrumbsReducer(state, action);
  switch (action.type) {
    case SideNavActions.SET_SIDENAV_STATUS:
      return [action.payload];
    default:
      return state;
  }

}