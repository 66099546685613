<div class="container">
    <img class="img-fluid logo" src="../../../assets/img/svg/Logo.svg" />
    <img class="img-fluid hero-img" src="../../../assets/img/svg/illustration.svg" />
    <h3>Download App</h3>
    <p class="typography roboto-normal-concord-14px">
        A mobile device-based program to guide and monitor expectant mothers to reduce complications during
        pregnancy and after delivery.
    </p>
    <div class="call-to-action">
        <a href="{{ appStoreLink }}" class="btn-cta btn-appstore">
            <img class="img-fluid" src="../../../assets/img/svg/appstorebadge.svg" alt="">
        </a>
        <a href="{{ playStoreLink }}" class="btn-cta btn-appstore">
            <img class="img-fluid" src="../../../assets/img/svg/playstorebadge.svg" alt="">
        </a>
    </div>
</div>