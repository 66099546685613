import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { CHART_CUSTOM_COLORS, HORIZONTAL_BAR_OPTION_WITH_PERCENTAGE_OPTIONS, MessageConstants, NO_DATA_IN_REPORT, REPORT_FILTERING_TYPES, REPORT_NAME_CODES, REPORT_NAME_TYPES, SAVE_SUCCESS, STACKED_HORIZONTAL_BAR_GRAPH_OPTIONS } from '../../../../constants';
import { ReportService, ToasterService } from '../../../../services';
import { DateUtility, HolistaUtils, PDFUtility, ChartUtils, ReportMappingUtility, ExcelMultiTabUtility } from '../../../../utils';

@Component({
  selector: 'app-patient-experience',
  templateUrl: './patient-experience.component.html',
  styleUrls: ['./patient-experience.component.scss']
})
export class PatientExperienceComponent implements OnInit {
  patientExperienceFilterForm: FormGroup;
  surveyResponseRateForm: FormGroup;
  patientSatisfactionRateForm: FormGroup;
  highestVolumeOfNoForm: FormGroup;
  categoryList: any[] = [];
  loading = {
    categoryList: false,
    subCategoryList: false,
    patientSatisfactionKeyQuestions: false,
    getQuestionConfigurations: false,
    saveQuestionConfiguration: false,
    surveyResponseRateYTD: false,
    summaryQuestionWithHighestVolumeOfNo: false,
  }
  expand = {
    patientSatisfactionKeyQuestions: false,
    surveyResponseRateYTD: false,
    summaryQuestionWithHighestVolumeOfNo: false,
  }
  baseConfigs = {
    options: {},
    plugins: [],
    labels: [],
    dataset: [],
    chartType: '',
    actualData: null,
  };
  patientSatisfactionKeyQuestionsChart = this._holistaUtils.deepClone(this.baseConfigs);
  surveyResponseRateYTDChart = this._holistaUtils.deepClone(this.baseConfigs);
  summaryQuestionWithHighestVolumeOfNoChart = this._holistaUtils.deepClone(this.baseConfigs);
  REPORT_NAME_TYPES = REPORT_NAME_TYPES;
  REPORT_NAME_CODES = REPORT_NAME_CODES;
  parsedValueManagementFilterData: any;
  toShowReport: any[] = [];
  reportNameAndCodeList: any[] = [];
  questionConfigurations: any[] = [];
  surveyResponseRateSubCategoryList: any[] = [];
  patientSatisfactionSubCategoryList: any[] = [];
  backupQuestionConfigurations: any[] = [];
  noDataMessage = this._messageConstants.getMessage(NO_DATA_IN_REPORT);
  isIndividual: boolean = false;
  searchFilterKeyword: string = '';
  highestVolumeOfNoData = {
    list: [],
    incrementCount: 0,
  };
  yearOptions = {
    highestVolumeOfNo: [],
  };

  @Output() downloadedPDF = new EventEmitter<any>();
  @Output() reportExpanded = new EventEmitter<any>();
  @Output() downloadButtonEnabled = new EventEmitter<any>();

  @Input() set valueManagementFilterData(data) {
    if (data && data.activeTabIndex === 1) {
      this.expand = {
        patientSatisfactionKeyQuestions: false,
        surveyResponseRateYTD: false,
        summaryQuestionWithHighestVolumeOfNo: false,
      };
      this.parsedValueManagementFilterData = this._holistaUtils.deepClone(data);
      const { networkCode, clientCode, startDate, endDate, toShowReport } = this.parsedValueManagementFilterData;
      if (networkCode && !this.categoryList.length) {
        this.getCategoryList({ networkCode });
      }
      let isIndividual = false;
      let categoryName = '';
      if (this.patientExperienceFilterForm) {
        categoryName = this.patientExperienceFilterForm.get('categoryName').value;
        isIndividual = categoryName !== 'All';
        this.isIndividual = isIndividual;
      } else {
        this.setPatientExperienceFilterForm();
      }
      if (!this.highestVolumeOfNoForm) {
        this.setHighestVolumeOfNoForm();
      }
      if (!this.surveyResponseRateForm) {
        this.setSurveyResponseRateFormForm();
      } else {
        const { subCategoryId, subCategoryName } = this.surveyResponseRateForm.value;
        this.surveyResponseRateForm.controls['subCategoryId'].setValue(subCategoryId);
        this.surveyResponseRateForm.controls['subCategoryName'].setValue(subCategoryName);
      }
      if (!this.patientSatisfactionRateForm) {
        this.setPatientSatisfactionFilterForm();
      } else {
        const { subCategoryId, subCategoryName } = this.patientSatisfactionRateForm.value;
        this.patientSatisfactionRateForm.controls['subCategoryId'].setValue(subCategoryId);
        this.patientSatisfactionRateForm.controls['subCategoryName'].setValue(subCategoryName);
      }
      if (categoryName.toLowerCase() === 'maternity') {
        this.getReportCodes(REPORT_FILTERING_TYPES.INDIVIDUAL_MATERNITY_REPORT);
        this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.INDIVIDUAL_MATERNITY_REPORT);
      } else if (categoryName.toLowerCase() === 'all') {
        this.getReportCodes(REPORT_FILTERING_TYPES.OVERALL_REPORT);
        this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.OVERALL_REPORT);
      } else {
        this.getReportCodes(REPORT_FILTERING_TYPES.INDIVIDUAL_REPORT);
        this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.INDIVIDUAL_REPORT);
      }
      const params = {
        clientCode,
        networkCode,
        startDate,
        endDate,
        ...(isIndividual && { categoryId: this.patientExperienceFilterForm.get('categoryId').value })
      };
      const additionalConditons = toShowReport.length && startDate && endDate;
      if (this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS) && additionalConditons) {
        const subCaregoryId = this.patientSatisfactionRateForm.get('subCategoryId').value;
        this.getPatientSatisfactionKeyQuestions(
          {
            ...params,
            ...(subCaregoryId && { subCaregoryId })
          }, this.isIndividual);
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD) && additionalConditons) {
        const subCaregoryId = this.surveyResponseRateForm.get('subCategoryId').value;
        this.getSurveyResponseRate(
          {
            ...params,
            ...(subCaregoryId && { subCaregoryId })
          }
        );
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO) && additionalConditons) {
        this.getSurveyQuestionWithHighestNo(params);
      }
    }
  };
  @Input() set downloadPDF(isDownloadPDF) {
    if (isDownloadPDF && isDownloadPDF.download && isDownloadPDF.tabIndex === 1) {
      var elementToHide = document.getElementById('hide-while-downloading');
      elementToHide.style.display = 'none';
      const { clientName, startDate, endDate } = this.parsedValueManagementFilterData;
      const reportDate = `${startDate} ${endDate ? `- ${endDate}` : ''}`;
      const tabsData = [];
      const resetDownload = () => {
        this.downloadedPDF.emit(true);
        elementToHide.style.display = 'block';
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS) && this.surveyResponseRateYTDChart.labels.length) {
        const body = this._reportMappingUtils.patientSatisfactionKeyQuestionMapper(this.patientSatisfactionKeyQuestionsChart.actualData);
        tabsData.push({
          clientName,
          reportName: 'Patient Satisfaction - Key Questions',
          tabName: 'Patient Satisfaction - Key Questions',
          headers: ['Questions', 'Yes', 'No',],
          data: body,
          reportDate,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD) && this.surveyResponseRateYTDChart.labels.length) {
        const body = this._reportMappingUtils.surveyResponseRateYTDMapper(this.surveyResponseRateYTDChart.actualData);
        tabsData.push({
          clientName,
          reportName: 'Survey Response Rate (YTD)',
          tabName: 'Survey Response Rate (YTD)',
          headers: ['Category', 'Completed Count', 'Not Completed Count', 'Total Count', 'Engaged Percent', 'Not Engaged Percent',],
          data: body,
          reportDate,
        });
      }
      if (this.toShowReport.includes(REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO) && this.summaryQuestionWithHighestVolumeOfNoChart.labels.length) {
        const { actualData, dataset } = this.summaryQuestionWithHighestVolumeOfNoChart;
        const data = this._reportMappingUtils.surveyQuestionWithHightstVolumeOfNoMapper(actualData, dataset);
        tabsData.push({
          clientName,
          reportName: `Survey Questions With Highest Volume Of 'No'`,
          tabName: `Survey Questions With Highest Volume Of 'No'`,
          headers: ['Questions', ...data.header],
          data: data.body,
          reportDate,
        });
      }
      if (isDownloadPDF.type === 'excel') {
        this._excelMultiTabUtility.exportToExcelMultiTab('Value Management Dashboard - Patient Experience', tabsData).finally(() => {
          resetDownload();
        })
      }
      if (isDownloadPDF.type === 'pdf') {
        const downloadPDF = this._pdfUtility.convertToPDF(['patience-experience-overview'], 'Value Management Dashboard - Patient Experience', true, true);
        downloadPDF.finally(() => {
          resetDownload();
        });
      }
    }
  }
  @ViewChild('filterModal', { static: true }) public filterModal;

  constructor(
    private _messageConstants: MessageConstants,
    private _pdfUtility: PDFUtility,
    private _chartUtils: ChartUtils,
    private _dateUtility: DateUtility,
    private _holistaUtils: HolistaUtils,
    private _toastr: ToasterService,
    private reportService: ReportService,
    private formBuilder: FormBuilder,
    private _reportMappingUtils: ReportMappingUtility,
    private _excelMultiTabUtility: ExcelMultiTabUtility,
  ) { }

  ngOnInit(): void {
    this.setHighestVolumeOfNoForm();
  }

  ngOnDestroy() {
    this.resetFlags();
    this.resetAllReports();
    this.downloadButtonEnabled.emit(false);
  }

  resetAllReports() {
    this.patientSatisfactionKeyQuestionsChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.surveyResponseRateYTDChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.summaryQuestionWithHighestVolumeOfNoChart = this._holistaUtils.deepClone(this.baseConfigs);
  }

  resetFlags() {
    this.loading = {
      categoryList: false,
      subCategoryList: false,
      patientSatisfactionKeyQuestions: false,
      getQuestionConfigurations: false,
      saveQuestionConfiguration: false,
      surveyResponseRateYTD: false,
      summaryQuestionWithHighestVolumeOfNo: false,
    }
  }

  setPatientExperienceFilterForm() {
    this.patientExperienceFilterForm = this.formBuilder.group({
      categoryId: ['all'],
      categoryName: ['All'],
    });
  }

  setPatientSatisfactionFilterForm() {
    this.patientSatisfactionRateForm = this.formBuilder.group({
      subCategoryId: ['all'],
      subCategoryName: ['All'],
    });
  }

  setSurveyResponseRateFormForm() {
    this.surveyResponseRateForm = this.formBuilder.group({
      subCategoryId: ['all'],
      subCategoryName: ['All'],
    });
  }

  setHighestVolumeOfNoForm() {
    this.highestVolumeOfNoForm = this.formBuilder.group({
      selectedYear: [null],
    });
  }

  getCategoryList(params?) {
    this.loading.categoryList = true;
    this.reportService.getCategoryList(params)
      .pipe(finalize(() => {
        this.loading.categoryList = false;
        this.categoryList.push({ id: 'all', label: "All", value: "all" });
      }))
      .subscribe((res) => {
        this.categoryList = res.map((x) => {
          return {
            ...x,
            label: x.name,
            value: x.name,
          }
        });
      }, error => {
        console.log("Error getting Category List", error);
      })
  }

  getPatientSatisfactionKeyQuestions(params?, isIndividual = false) {
    this.loading.patientSatisfactionKeyQuestions = true;
    this.reportService.getPatientSatisfactionKeyQuestionReport(params)
      .pipe(finalize(() => { this.loading.patientSatisfactionKeyQuestions = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          const backgroundColor = ['#67d590', '#d56767'];
          this.patientSatisfactionKeyQuestionsChart.actualData = res;
          res = {
            ...res,
            datasets: res.dataSets.map((dataset, index) => {
              return {
                label: dataset.label,
                data: dataset.datas,
                maxBarThickness: 30,
                backgroundColor: backgroundColor[index],
                hoverBackgroundColor: backgroundColor[index],
                borderColor: backgroundColor[index],
                hoverBorderColor: backgroundColor[index],
              }
            })
          };
          this.patientSatisfactionKeyQuestionsChart.chartType = 'horizontalBar';
          this.patientSatisfactionKeyQuestionsChart.dataset = res.datasets;
          this.patientSatisfactionKeyQuestionsChart.labels = res.labels;
          this.patientSatisfactionKeyQuestionsChart.options = STACKED_HORIZONTAL_BAR_GRAPH_OPTIONS;
        } else {
          this.patientSatisfactionKeyQuestionsChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        this.patientSatisfactionKeyQuestionsChart = this._holistaUtils.deepClone(this.baseConfigs);
        console.log("Error getting Patient Satisfaction - Key Questions", error);
      })
  }

  getSurveyResponseRate(params?) {
    this.loading.surveyResponseRateYTD = true;
    const categoryId = this.patientExperienceFilterForm.get('categoryId').value;
    const commonParams = {
      ...params,
      categoryId: categoryId === 'all' ? 0 : categoryId,
    }
    if (categoryId === 'all') {
      delete commonParams.networkCode;
    }
    this.reportService.getSurveyResponseRate(commonParams)
      .pipe(finalize(() => { this.loading.surveyResponseRateYTD = false; this.updateDownloadEnabledState(); }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          this.surveyResponseRateYTDChart.actualData = res;
          res = {
            ...res,
            datasets: [{
              data: res.dataSets.find((dataset) => dataset.label === 'engaged_percent').datas || [],
              maxBarThickness: 30,
              backgroundColor: CHART_CUSTOM_COLORS,
              borderColor: CHART_CUSTOM_COLORS,
              hoverBackgroundColor: CHART_CUSTOM_COLORS,
              hoverBorderColor: CHART_CUSTOM_COLORS,
            }]
          };
          this.surveyResponseRateYTDChart.chartType = 'horizontalBar';
          this.surveyResponseRateYTDChart.dataset = res.datasets;
          this.surveyResponseRateYTDChart.labels = res.labels;
          this.surveyResponseRateYTDChart.options = HORIZONTAL_BAR_OPTION_WITH_PERCENTAGE_OPTIONS;
        } else {
          this.surveyResponseRateYTDChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        this.surveyResponseRateYTDChart = this._holistaUtils.deepClone(this.baseConfigs);
        console.log("Error getting Portal Engagement Report", error);
      })
  }

  getSurveyQuestionWithHighestNo(params?) {
    this.loading.summaryQuestionWithHighestVolumeOfNo = true;
    this.reportService.getSurveyQuestionWithHighestNo(params)
      .pipe(finalize(() => {
        this.loading.summaryQuestionWithHighestVolumeOfNo = false;
        const { startDate, endDate } = this.parsedValueManagementFilterData;
        const reportYear = +this._dateUtility.getYearFromDate(endDate);
        const reportStartYear = +this._dateUtility.getYearFromDate(startDate);
        if (reportYear && endDate) {
          this.yearOptions.highestVolumeOfNo = this._holistaUtils.getYearOptionList(reportStartYear, reportYear);
          this.highestVolumeOfNoForm.controls['selectedYear'].setValue(reportYear);
        }
        this.updateDownloadEnabledState();
      }))
      .subscribe((res) => {
        if (res.labels && res.labels.length) {
          const backgroundColor = this._holistaUtils.deepClone(CHART_CUSTOM_COLORS);
          this.highestVolumeOfNoData.list = res.dataSets;
          this.summaryQuestionWithHighestVolumeOfNoChart.actualData = res;
          const latestDataIndex = res.dataSets.length - 1;
          this.highestVolumeOfNoForm.controls['selectedYear'].setValue(res.dataSets[latestDataIndex].label);
          res = {
            ...res,
            datasets: [
              {
                label: res.dataSets[latestDataIndex].label,
                data: res.dataSets[latestDataIndex].datas,
                maxBarThickness: 30,
                backgroundColor: backgroundColor[0],
                hoverBackgroundColor: backgroundColor[0],
                borderColor: backgroundColor[0],
                hoverBorderColor: backgroundColor[0],
              }
            ]
          };
          this.summaryQuestionWithHighestVolumeOfNoChart.chartType = 'horizontalBar';
          this.summaryQuestionWithHighestVolumeOfNoChart.dataset = res.datasets;
          this.summaryQuestionWithHighestVolumeOfNoChart.labels = res.labels;
          this.summaryQuestionWithHighestVolumeOfNoChart.options = {
            ...STACKED_HORIZONTAL_BAR_GRAPH_OPTIONS,
            legend: {
              ...STACKED_HORIZONTAL_BAR_GRAPH_OPTIONS.legend,
              display: false,
            }
          };
        } else {
          this.summaryQuestionWithHighestVolumeOfNoChart = this._holistaUtils.deepClone(this.baseConfigs);
        }
      }, (error) => {
        console.log("Error getting Survey Questions with hignest volume of no.", error);
        this.summaryQuestionWithHighestVolumeOfNoChart = this._holistaUtils.deepClone(this.baseConfigs);
      })
  }

  onViewToggle(reportCode, expand = false) {
    // this.reportExpanded.emit(expand); // Uncomment if download is not needed while expanding
    switch (reportCode) {
      case REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS:
        this.expand.patientSatisfactionKeyQuestions = expand;
        break;
      case REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD:
        this.expand.surveyResponseRateYTD = expand;
        break;
      case REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO:
        this.expand.summaryQuestionWithHighestVolumeOfNo = expand;
        break;
      default:
        break;
    }
  }

  getReport(code: string) {
    return this.reportNameAndCodeList.find(report => report.reportCode === code) || null;
  }

  getReportCodes(filterKey) {
    const { toShowReport } = this.parsedValueManagementFilterData;
    this.toShowReport = toShowReport.filter((x) => x.report[filterKey]).map((x) => x.reportCode);
  }

  getReportNameAndCodeList(filterKey) {
    const { toShowReport } = this.parsedValueManagementFilterData;
    this.reportNameAndCodeList = toShowReport.filter((x) => x.report[filterKey]).map((x) => ({
      reportName: x.customReportName,
      reportCode: x.reportCode
    }));
  }

  categorySelected(category) {
    this.surveyResponseRateSubCategoryList = [];
    this.patientSatisfactionSubCategoryList = [];
    const isCategoryIndividual = category.id !== 'all';
    this.isIndividual = isCategoryIndividual;
    this.patientExperienceFilterForm.controls['categoryId'].setValue(category.id);
    this.patientExperienceFilterForm.controls['categoryName'].setValue(category.label);
    this.surveyResponseRateYTDChart = this._holistaUtils.deepClone(this.baseConfigs);
    this.patientSatisfactionKeyQuestionsChart = this._holistaUtils.deepClone(this.baseConfigs);
    const { clientCode, networkCode, startDate, endDate, toShowReport } = this.parsedValueManagementFilterData;
    const categoryName = category.value.toLowerCase();
    if (categoryName === 'maternity') {
      this.getReportCodes(REPORT_FILTERING_TYPES.INDIVIDUAL_MATERNITY_REPORT);
      this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.INDIVIDUAL_MATERNITY_REPORT);
    } else if (categoryName === 'all') {
      this.getReportCodes(REPORT_FILTERING_TYPES.OVERALL_REPORT);
      this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.OVERALL_REPORT);
    } else {
      this.getReportCodes(REPORT_FILTERING_TYPES.INDIVIDUAL_REPORT);
      this.getReportNameAndCodeList(REPORT_FILTERING_TYPES.INDIVIDUAL_REPORT);
    }
    const params = { clientCode, networkCode, startDate, endDate, categoryId: category.id };
    const additionalConditons = this.toShowReport.length && startDate && endDate;
    if (this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS) && additionalConditons && !this.isIndividual) {
      const { categoryId, ...rest } = params;
      this.getPatientSatisfactionKeyQuestions(rest, isCategoryIndividual);
    }
    const commonParams = {
      ...params,
      categoryId: params.categoryId === 'all' ? 0 : params.categoryId,
    }
    if (params.categoryId === 'all') { delete commonParams.networkCode; }
    if (this.toShowReport.includes(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD) && additionalConditons && !this.isIndividual) { this.getSurveyResponseRate(commonParams); }
    if (this.toShowReport.includes(REPORT_NAME_CODES.SUMMARY_QUESTION_WITH_HIGHEST_VOLUME_OF_NO) && additionalConditons) { this.getSurveyQuestionWithHighestNo(commonParams); }
    if (this.isIndividual) { this.getSubCategory({ categoryId: category.id, networkCode }); }
  }

  subCategorySelected(event, type?) {
    if (event.id) {
      const { clientCode, networkCode, startDate, endDate } = this.parsedValueManagementFilterData;
      const params = {
        clientCode,
        networkCode,
        startDate,
        endDate,
        categoryId: this.patientExperienceFilterForm.get('categoryId').value,
        subCategoryId: event.id === 'all' ? null : event.id
      };
      if (type === 'survey-response-rate') {
        this.surveyResponseRateForm.controls['subCategoryId'].setValue(event.id);
        this.surveyResponseRateForm.controls['subCategoryName'].setValue(event.label);
        if (this.toShowReport.includes(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD) && event.id) { this.getSurveyResponseRate(this._holistaUtils.removeNullOrUndefinedKeys(params)); }
      }
      if (type === 'patient-satisfaction-key-question') {
        this.patientSatisfactionRateForm.controls['subCategoryId'].setValue(event.id);
        this.patientSatisfactionRateForm.controls['subCategoryName'].setValue(event.label);
        if (this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS) && event.id) { this.getPatientSatisfactionKeyQuestions(this._holistaUtils.removeNullOrUndefinedKeys(params), event.id !== 'all'); }
      }
    }
  }

  onFilterKeywordSearch() {
    this.questionConfigurations = this.backupQuestionConfigurations.filter((question) => question.questionName.toLowerCase().includes(this.searchFilterKeyword.toLowerCase()));
    if (!this.searchFilterKeyword) {
      this.questionConfigurations = this.backupQuestionConfigurations;
    }
  }

  getSubCategory(params?) {
    this.loading.subCategoryList = true;
    this.reportService.getSubCategoryList(params)
      .pipe(finalize(() => {
        this.loading.subCategoryList = false;
        const commonSubCategory = { id: 'all', label: "All", value: "All" };
        this.surveyResponseRateSubCategoryList.push(commonSubCategory);
        this.patientSatisfactionSubCategoryList.push(commonSubCategory);
        const { clientCode, networkCode, startDate, endDate } = this.parsedValueManagementFilterData;
        const categoryId = this.patientExperienceFilterForm.get('categoryId').value;
        const params = {
          clientCode,
          networkCode,
          startDate,
          endDate,
          categoryId,
          subCategoryId: null,
        };
        if (this.toShowReport.includes(REPORT_NAME_CODES.SURVEY_RESPONSE_RATE_YTD)) {
          this.getSurveyResponseRate(this._holistaUtils.removeNullOrUndefinedKeys(params));
        }
        if (this.toShowReport.includes(REPORT_NAME_CODES.PATIENT_SATISFACTION_KEY_QUESTIONS)) {
          this.getPatientSatisfactionKeyQuestions(this._holistaUtils.removeNullOrUndefinedKeys(params));
        }
      }))
      .subscribe((res) => {
        if (res && res.length) {
          const subCategoryList = res.map((x) => {
            return {
              ...x,
              label: x.name,
              value: x.name,
            }
          });
          this.surveyResponseRateSubCategoryList = this._holistaUtils.deepClone(subCategoryList);
          this.patientSatisfactionSubCategoryList = this._holistaUtils.deepClone(subCategoryList);
        }
        else {
          this.surveyResponseRateSubCategoryList = [];
          this.patientSatisfactionSubCategoryList = [];
        }
      }, error => {
        console.log("Error getting Sub Category List", error);
      })
  }

  removeSearchKeyword() {
    this.searchFilterKeyword = '';
    this.questionConfigurations = this.backupQuestionConfigurations;
  }

  showfilterModal() {
    this.filterModal.show();
    const { networkCode } = this.parsedValueManagementFilterData;
    this.getQuestionConfigurations({ networkCode });
  }

  applyFilter() {
    this.loading.saveQuestionConfiguration = true;
    const changedQuestionConfig = this.questionConfigurations.filter((questionConfig) => {
      const question = this.backupQuestionConfigurations.find((obj) => obj.id === questionConfig.id);
      return question && question.isActive !== questionConfig.isActive;
    });
    const { networkCode } = this.parsedValueManagementFilterData;
    const uniqueQuestionConfig = changedQuestionConfig.map((x) => {
      return {
        questionCode: x.questionCode,
        isActive: x.isActive,
      }
    });
    this.reportService.updateQuestionConfigurationStatus({ networkCode, questions: uniqueQuestionConfig })
      .pipe(finalize(() => { this.loading.saveQuestionConfiguration = false; }))
      .subscribe((res) => {
        if (res) {
          this.closeModal();
          this._toastr.displaySuccess(SAVE_SUCCESS);
          const { clientCode, networkCode, startDate, endDate } = this.parsedValueManagementFilterData;
          const params = {
            clientCode,
            networkCode,
            startDate,
            endDate,
            ...(this.isIndividual && { categoryId: this.patientExperienceFilterForm.get('categoryId').value })
          };
          this.getPatientSatisfactionKeyQuestions(params, this.isIndividual);
        }
      }, (error) => {
        console.log("Error Saving Question Configurations.", error);
        this._toastr.displayError(error);
      })
  }

  closeModal() {
    this.searchFilterKeyword = null;
    this.filterModal.hide();
  }

  getQuestionConfigurations(params?) {
    this.loading.getQuestionConfigurations = true;
    this.reportService.getQuestionConfigurations(params)
      .pipe(finalize(() => { this.loading.getQuestionConfigurations = false; }))
      .subscribe((res) => {
        if (res.rows.length) {
          this.questionConfigurations = res.rows;
          this.backupQuestionConfigurations = res.rows;
        } else {
          this.questionConfigurations = [];
          this.backupQuestionConfigurations = [];
        }
      }, (error) => {
        console.log("Error getting Question Configurations.", error);
      })
  }

  onQuestionChecked(event, questionConfiguration) {
    this.questionConfigurations = this.questionConfigurations.map((x) => {
      if (x.questionCode === questionConfiguration.questionCode) {
        return {
          ...x,
          isActive: event.checked
        }
      }
      return x;
    })
  }

  updateDownloadEnabledState() {
    const isDownloadEnable = (
      this.patientSatisfactionKeyQuestionsChart.labels.length ||
      this.surveyResponseRateYTDChart.labels.length ||
      this.summaryQuestionWithHighestVolumeOfNoChart.labels.length
    );
    this.downloadButtonEnabled.emit(isDownloadEnable);
  }

  highestVolumeOfNoYearSelected(event) {
    if (event.label) {
      let highestVolumeOfNoData = null;
      const dataExistInChart = this.summaryQuestionWithHighestVolumeOfNoChart.dataset.find((data) => data.label === event.label);
      if (dataExistInChart) return;
      if (this.highestVolumeOfNoData.list.length) {
        highestVolumeOfNoData = this.highestVolumeOfNoData.list.find((x) => x.label === event.label);
      }
      this.highestVolumeOfNoData.incrementCount = this.highestVolumeOfNoData.incrementCount + 1;
      const { incrementCount } = this.highestVolumeOfNoData;
      const indexCount = incrementCount >= 15 ? 0 : incrementCount;
      if (incrementCount >= 15) { this.highestVolumeOfNoData.incrementCount = 0 }
      if (highestVolumeOfNoData) {
        highestVolumeOfNoData = {
          label: highestVolumeOfNoData.label,
          data: highestVolumeOfNoData.datas,
          maxBarThickness: 30,
          backgroundColor: CHART_CUSTOM_COLORS[indexCount],
          hoverBackgroundColor: CHART_CUSTOM_COLORS[indexCount],
          borderColor: CHART_CUSTOM_COLORS[indexCount],
          hoverBorderColor: CHART_CUSTOM_COLORS[indexCount],
        }
        this.summaryQuestionWithHighestVolumeOfNoChart.dataset.push(highestVolumeOfNoData);
        this.summaryQuestionWithHighestVolumeOfNoChart.dataset = this.summaryQuestionWithHighestVolumeOfNoChart.dataset.filter((data) => data.label);
      }
    }
  }

  highestVolumeOfNoYearDeselected(event) {
    if (event.label) {
      const existingData = this.summaryQuestionWithHighestVolumeOfNoChart.dataset.find((data) => data.label === event.label);
      if (existingData && existingData.label) {
        this.summaryQuestionWithHighestVolumeOfNoChart.dataset = this.summaryQuestionWithHighestVolumeOfNoChart.dataset.filter((data) => data.label !== event.label && data.label);
      }
      if (!this.summaryQuestionWithHighestVolumeOfNoChart.dataset.length) {
        this.highestVolumeOfNoData.incrementCount = 0;
      }
    }
  }

}
