<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center mt-4 action position-sticky">
    <div class="d-flex align-items-center w-50 w-xs-100">
      <div class="form-group has-search input-group mt-2 mb-2 w-50 w-xs-100">
        <span class="form-control-feedback left-0">
          <i class="icofont-search-1"></i>
        </span>
        <input
          type="text"
          [(ngModel)]="searchKeyword"
          (ngModelChange)="searchPaymentByKeyword($event)"
          class="form-control"
          id="search"
          placeholder="Search" />
        <div class="input-group-append" *ngIf="searchKeyword">
          <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
            <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
          </button>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-light z-depth-0 pl-3 pr-3"
          data-toggle="modal"
          data-target="#basicExample"
          (click)="openFilterModal()"
          mdbWavesEffect>
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <a
        [routerLink]="['/payment/create/received']"
        class="waves-light btn btn-secondary text-center text-white text-uppercase"
        mdbWavesEffect>
        <i class="icofont-plus"></i> Receive Payment
      </a>
      <a
        [routerLink]="['/payment/create/paid']"
        class="waves-light btn btn-secondary text-center text-white text-uppercase"
        mdbWavesEffect>
        <i class="icofont-plus"></i> Issue Payment
      </a>
    </div>
  </div>
  <div class="keywords position-sticky" *ngIf="filterList && filterList.length > 0">
    <div class="keywords d-flex mt-2 flex-wrap align-items-center">
      <small class="mb-2 mr-2">Filters: </small>
      <span class="d-block mr-1 mb-2" *ngFor="let filter of filterList">
        <small class="d-block text-muted ml-1"></small>
        <div class="chip blue lighten-5 mb-0">
          {{ filter.field }}:
          <span class="font-weight-500 text-dark">{{ filter.label | titlecase }}</span>
          <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
        </div>
      </span>
      <button
        type="button"
        class="btn btn-link waves-light m-0 p-0"
        mdbWavesEffect
        (click)="resetFilter()">
        <span class="text-info">Reset Filter</span>
      </button>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table mdbTable hover="true" small="true" stickyHeader="true">
          <thead id="scrollToTop">
            <tr>
              <th width="20"></th>
              <th>Inv. No.</th>
              <th>Payor</th>
              <th>Payee</th>
              <th width="100">Status</th>
              <th width="120" class="text-right">Amount</th>
              <th width="240" class="text-center">Payment Date</th>
              <th></th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading.payment"
            [style.top]="paymentList && paymentList.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading.payment"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            mdbTableCol
            *ngFor="
              let payment of paymentList
                | orderBy : 'createdAt' : true
                | paginate : {itemsPerPage: limit, currentPage: page, totalItems: totalCount};
              let i = index
            ">
            <tr [ngClass]="{'grey lighten-4 border': payment.collapsed}">
              <td (click)="paymentCollapsed(payment)">
                <button
                  type="button"
                  class="p-0 mt-0 btn-link btn"
                  mdbWavesEffect
                  *ngIf="payment.collapsed">
                  <i class="icofont-minus-circle icofont-lg text-info"></i>
                </button>
                <button
                  type="button"
                  class="p-0 mt-0 btn-link btn"
                  mdbWavesEffect
                  *ngIf="!payment.collapsed">
                  <i class="icofont-plus-circle icofont-lg text-info"></i>
                </button>
              </td>
              <td>
                {{ payment.invoiceId }} <br />
                <span class="badge badge-sm bg-primary-dark text-white z-depth-0">
                  {{ payment.paymentType }}
                </span>
              </td>
              <td>{{ (payment.payorName ? payment.payorName : '-') | titlecase }}</td>
              <td>
                {{ (payment.payeeName ? payment.payeeName : '-') | titlecase }}
              </td>
              <td>
                <div
                  class="chip alert m-0 mb-2 w-auto rounded-sm"
                  [ngClass]="
                    payment.status.toUpperCase() === PAYMENT_STATUS.COMPLETED
                      ? 'completed'
                      : 'inprogress'
                  ">
                  <span class="text-sm status-label">
                    {{ payment.status ? payment.status : '-' }}
                  </span>
                </div>
              </td>
              <td class="text-right">
                {{ +payment.totalAmount | currency }} <br />
                {{ payment.paymentMode }}
              </td>
              <td class="text-center">
                {{ payment.paymentDate | date : 'MM/dd/yyyy' }}
              </td>
              <td width="32">
                <div class="custom-dropdown">
                  <button
                    class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                    type="button"
                    mdbWavesEffect>
                    <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                  </button>
                  <div class="custom-dropdown-content rounded-sm z-depth-2">
                    <a
                      *ngIf="
                        payment?.status?.toUpperCase() === 'IN-PROGRESS' &&
                        payment?.paymentType?.toUpperCase() === 'PAID'
                      "
                      class="dropdown-item"
                      (click)="editPayment(payment)"
                      ><i class="icofont-edit-alt"></i> Edit</a
                    >
                    <a class="dropdown-item" (click)="viewPayment(payment)"
                      ><i class="icofont-eye-alt"></i> Detail</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="view835Claim(payment)"
                      *ngIf="payment.paymentType == 'PAID'"
                      ><i class="icofont-eye-alt"></i> 835 EDI</a
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="payment.collapsed" class="grey lighten-4 border">
              <td colspan="100%" class="p-3">
                <table mdbTable small="true" hover="true" bordered="true" striped="true">
                  <thead>
                    <tr>
                      <th *ngFor="let header of PAYMENT_EXPAND_TABLE_HEADERS">{{ header }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let claim of payment.claimPayments">
                      <td>{{ claim.patientAccountNumber ? claim.patientAccountNumber : '-' }}</td>
                      <td>
                        {{
                          (claim.episodeUserName | titlecase) ||
                            (claim.claimXrefHolista.claim.patient.displayName | titlecase) ||
                            '-'
                        }}
                      </td>
                      <td>{{ claim.episodeName ? claim.episodeName : '-' }}</td>
                      <td>{{ claim.fundingRequestName ? claim.fundingRequestName : '-' }}</td>
                      <td>{{ claim.bundleName ? claim.bundleName : '-' }}</td>
                      <td>
                        {{ claim.bundleComponentName ? claim.bundleComponentName : '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <pagination-controls
      *ngIf="paymentList && paymentList.length > 0"
      class="text-center"
      (pageChange)="pageChanged($event)"
      autoHide="true">
    </pagination-controls>
  </div>
  <div
    class="text-center p-4 border mt-3"
    *ngIf="!loading.payment && (!paymentList || paymentList.length == 0)">
    <i class="fas fa-3x fa-credit-card text-black-50"></i>
    <p *ngIf="!result.payments">Start adding Payments.</p>
    <p *ngIf="!result.searchedPayments">No results found.</p>
  </div>
</section>

<!-- FILTER MODAL -->
<div
  mdbModal
  #filterModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter Payment</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFilterModal('close')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="bg-light p-3">
          <form [formGroup]="filterForm">
            <div class="form-row">
              <div class="col-6">
                <div class="form-group">
                  <label for="paymentType">Choose Payment Type</label>
                  <mdb-select
                    [options]="paymentTypes"
                    id="paymentType"
                    placeholder="Select payment type"
                    [multiple]="false"
                    [enableSelectAll]="false"
                    formControlName="paymentType">
                  </mdb-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="paymentMode">Choose Payment Mode</label>
                  <mdb-select
                    [options]="paymentModeList | sort : 'label'"
                    id="paymentMode"
                    formControlName="paymentMode"
                    placeholder="Select payment mode">
                  </mdb-select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <div class="form-group">
                  <label for="payor">Choose Payor</label>
                  <app-select
                    [options]="allPayorList"
                    [id]="'payor'"
                    [control]="filterForm.controls['payorId']"
                    [placeholder]="'Select payor'"
                    (onSelected)="onSearchResultSelected($event, 'payorId')">
                  </app-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <label for="payee">Choose Payee</label>
                  <input
                    type="text"
                    [ngModel]="payeeSearchText | async"
                    id="payee"
                    (ngModelChange)="payeeSearchText.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    autocomplete="off"
                    [mdbAutoCompleter]="auto3"
                    placeholder="Type to select payee" />
                  <mdb-auto-completer
                    #auto3="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayPayee"
                    [optionHeight]="80"
                    (selected)="onSearchResultSelected($event, 'payeeId')">
                    <mdb-option *ngFor="let option of payee_results | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.name | titlecase }}</strong>
                        <span>
                          located in {{ option.city }}{{ option.state ? ',' : '' }}
                          {{ option.state }}</span
                        >
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeFilterModal('close')">
          Cancel
        </button>
        <button class="btn btn-secondary waves-light m-0" mdbWavesEffect (click)="filterPayment()">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>
