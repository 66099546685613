<div
  class="p-2"
  [ngClass]="{'second-level': optionId}"
  *ngIf="taskQuestion && taskQuestion.questions">
  <div class="d-flex justify-content-start align-items-start">
    <div class="icon-block">
      <i class="icofont-question-circle icofont-2x skin-text-primary-dark"></i>
    </div>
    <div class="d-flex justify-content-between w-100 align-items-center">
      <span class="pl-2 pr-4">
        <p class="font-weight-400">
          {{ taskQuestion?.questions?.question }}
        </p>
        <p *ngIf="taskQuestion.questions && taskQuestion.questions.questionHelp">
          {{ taskQuestion?.questions?.questionHelp }}
        </p>
      </span>
      <span class="action-item position-relative">
        <i
          class="icofont-ui-edit icofont-sm mr-2"
          #actionBtn
          (click)="editTaskHandler(taskQuestion)"></i>
        <i
          class="icofont-ui-delete icofont-sm mr-2"
          #actionBtn
          (click)="deleteTaskHandler(taskQuestion)"></i
      ></span>
    </div>
  </div>
  <div
    class="list-content-menu"
    [ngClass]="{'sub-level': optionId}"
    [hidden]="taskQuestion && taskDragged">
    <div
      class="list-content-item"
      *ngFor="let option of taskQuestion.questions.questionOptions | orderBy : 'id'">
      <div
        class="d-flex justify-content-between align-items-start list-content-item-option pt-0 pb-0 mb-2">
        <p>
          <i
            class="far fa-square mr-2"
            *ngIf="taskQuestion.questions.questionTypes.code == 'CHECKBOX'"></i>
          <i
            class="far fa-circle mr-2"
            *ngIf="taskQuestion.questions.questionTypes.code == 'RADIO'"></i>
          {{ option.optionValue }}
        </p>
        <a
          class="add-action"
          #actionBtn
          (click)="addActionHandler(option.id, taskQuestion.questions.uuid)"
          >Add action</a
        >
      </div>
      <div
        class="list-content p-2"
        *ngIf="
          option.taskMessageId ||
          option.taskQuestionId ||
          option.taskTodoId ||
          option.taskQnrsId ||
          option.taskSignatureId
        ">
        <div class="p-2 flex-row justify-content-start align-items-start dependent-task">
          <app-task-todo
            *ngIf="option.taskTodoId"
            [optionId]="option.id"
            [dependent_task]="dependentTask"
            (btns)="receiveBtns($event, 'todo')"
            [taskTodoId]="option.taskTodoId"
            (editTask)="editTaskTodo($event)"
            (deletedTask)="deleteTask($event)">
          </app-task-todo>
          <app-task-signature
            *ngIf="option.taskSignatureId"
            [optionId]="option.id"
            [dependent_task]="dependentTask"
            (btns)="receiveBtns($event, 'signature')"
            [taskSignatureId]="option.taskSignatureId"
            (editTask)="editTaskTodo($event)"
            (deletedTask)="deleteTask($event)">
          </app-task-signature>
          <app-task-message
            *ngIf="option.taskMessageId"
            [optionId]="option.id"
            [dependent_task]="dependentTask"
            (btns)="receiveBtns($event, 'message')"
            [taskMessageId]="option.taskMessageId"
            (editTask)="editTaskMessage($event)"
            (deletedTask)="deleteTask($event)"></app-task-message>
          <app-task-question
            *ngIf="option.taskQuestionId"
            [optionId]="option.id"
            [dependent_task]="dependentTask"
            [taskQuestionId]="option.taskQuestionId"
            (editTask)="editTaskHandler($event)"
            (btns)="receiveBtns($event, 'question')"
            (addAction)="addActionHandler($event)"
            (deletedTask)="deleteTask($event)"></app-task-question>
          <app-task-questionnaire
            *ngIf="option.taskQnrsId"
            [optionId]="option.id"
            [dependent_task]="dependentTask"
            [taskQnrsId]="option.taskQnrsId"
            (editTask)="editTaskHandler($event)"
            (btns)="receiveBtns($event, 'questionnaire')"
            (addAction)="addActionHandler($event)"
            (deletedTask)="deleteTask($event)">
          </app-task-questionnaire>
        </div>
      </div>
    </div>
  </div>
</div>

<app-milestone-topic-delete
  [deleteData]="taskToBeDelete"
  (deletedData)="deleteTask($event)"
  (cancelDelete)="cancelDelete($event)">
</app-milestone-topic-delete>
