import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { counterReducer, headerBreadcrumbsReducer, sideNavReducer, tasksMessageReducer, tasksTodoReducer, tasksQuestionReducer, userMessageReducer, userReducer, chatUsersReducer, enableChatReducer, chatUserLoadReducer } from './reducer';

@NgModule({
  imports: [StoreModule.forRoot({
    count: counterReducer,
    bread_crumbs: headerBreadcrumbsReducer,
    sidenav_status: sideNavReducer,
    task_todo: tasksTodoReducer,
    task_message: tasksMessageReducer,
    task_question: tasksQuestionReducer,
    user_message: userMessageReducer,
    user: userReducer,
    chatUsers: chatUsersReducer,
    enableChat: enableChatReducer,
    // chatOpen: chatOpenReducer,
    chatUserLoading: chatUserLoadReducer
  })],
  exports: [StoreModule]
})
export class AppStoreModule { }