import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-id-card',
  templateUrl: './id-card.component.html',
  styleUrls: ['./id-card.component.scss']
})
export class IdCardComponent implements OnInit {
  user: any;
  patientDetail: string;

  @Input() set patientIdCard(data) {
    if (data) {
      this.patientDetail = data;
      this.idCardModal.show();
    }
  }

  @Output() idCardClose = new EventEmitter<any>();
  @ViewChild('idCardModal', { static: true }) public idCardModal
  constructor() { }

  ngOnInit() {
  }

  closeIdModal() {
    this.idCardModal.hide();
    this.idCardClose.emit(null);
  }
}
