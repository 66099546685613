<div class="p-3 grey lighten-4">
  <div class="row">
    <div *ngIf="user.roleCode === 'PA' || user.roleCode === 'CLA'" class="col-sm-8">
      <div class="d-flex align-items-center w-50 w-xs-100">
        <div class="form-group input-group has-search mt-2 mb-2 w-100 w-xs-100">
          <span class="form-control-feedback left-0">
            <i class="icofont-search-1"></i>
          </span>
          <input
            type="text"
            class="form-control bg-white"
            id="search"
            placeholder="Search"
            [(ngModel)]="searchKeyword"
            (ngModelChange)="searchByKeyword($event)" />
          <div class="input-group-append" *ngIf="searchKeyword">
            <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
              <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
            </button>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-light z-depth-0 pl-3 pr-3 bg-white"
          data-toggle="modal"
          data-target="#filterModal"
          (click)="openFilterModal()">
          <i class="icofont-settings"></i>
          <span class="d-none d-sm-inline-block ml-1">Filter</span>
        </button>
      </div>
      <div
        class="keywords position-sticky grey lighten-4"
        *ngIf="filterList && filterList.length > 0">
        <div class="keywords d-flex mt-2 flex-wrap align-items-center">
          <small class="mb-2 mr-2">Filters: </small>
          <span class="d-block mr-1 mb-2" *ngFor="let filter of filterList">
            <small class="d-block text-muted ml-1"></small>
            <div class="chip blue lighten-5 mb-0">
              {{ filter.field }}:
              <span class="font-weight-500 text-dark">{{ filter.label | titlecase }}</span>
              <mdb-icon fas icon="times" class="close" (click)="removeFilter(filter)"></mdb-icon>
            </div>
          </span>
          <button
            type="button"
            class="btn btn-link waves-light m-0 p-0"
            mdbWavesEffect
            (click)="resetFilter()">
            <span class="text-info">Reset Filter</span>
          </button>
        </div>
      </div>
    </div>

    <div
      class="col-sm-4 d-flex justify-content-end align-items-center"
      *ngIf="user.roleCode === 'PA'">
      <p class="mb-0 mr-2">Show:</p>
      <mdb-select
        [options]="dashboardTypes"
        (selected)="showSelectedDashboard($event)"
        [highlightFirst]="false"
        [disabled]="!dashboardTypes || dashboardTypes.length === 0"
        [(ngModel)]="selectedDashboard">
      </mdb-select>
    </div>
  </div>
</div>

<div class="row m-0 mt-3">
  <div class="col-sm-4">
    <mdb-card>
      <mdb-card-header>Total Claims</mdb-card-header>
      <mdb-card-body>
        <div class="loader-wrapper" *ngIf="loading.totalClaimsChart" style="margin-left: -1.25rem">
          <div class="loader">
            <app-loader [loader]="loading.totalClaimsChart"></app-loader>
          </div>
          <div class="backdrop"></div>
        </div>
        <div class="row no-gutters">
          <div class="col-6">
            <div style="height: 150px">
              <canvas
                baseChart
                [data]="claimData"
                [labels]="claimLabels"
                [legend]="claimLegends"
                [options]="doughnutChartOptions"
                [colors]="claimDataColors"
                [chartType]="doughnutChartType"
                [plugins]="claimsPlugins"></canvas>
            </div>
          </div>
          <div class="col-6 align-self-center">
            <div class="d-flex align-items-center mb-2">
              <span
                class="alert border-0 alert-danger rounded-0 flag mr-2 p-0 mb-0"
                style="background-color: #8d67d5; width: 15px; height: 15px">
              </span>
              <small
                ><span class="font-weight-bold">{{ claimsStateCount.inReview }}</span>
                In-Review</small
              >
            </div>
            <div class="d-flex align-items-center mb-2">
              <span
                class="alert border-0 alert-warning rounded-0 flag mr-2 p-0 mb-0"
                style="background-color: #67d5c4; width: 15px; height: 15px">
              </span>
              <small
                ><span class="font-weight-bold">{{
                  claimsStateCount.triggerClaimNotReceived
                }}</span>
                In-Queue</small
              >
            </div>
            <div class="d-flex align-items-center mb-2">
              <span
                class="alert border-0 alert-info rounded-0 flag mr-2 p-0 mb-0"
                style="background-color: #a2d567; width: 15px; height: 15px">
              </span>
              <small
                ><span class="font-weight-bold">{{ claimsStateCount.fundingClaimNotSent }}</span>
                Funding Claim Not Sent</small
              >
            </div>
            <div class="d-flex align-items-center mb-2">
              <span
                class="alert border-0 alert-warning rounded-0 flag mr-2 p-0 mb-0"
                style="background-color: #3c6f95; width: 15px; height: 15px">
              </span>
              <small
                ><span class="font-weight-bold">{{ claimsStateCount.fundingNotReceived }}</span>
                Funding Not Received</small
              >
            </div>
            <div class="d-flex align-items-center mb-2">
              <span
                class="alert border-0 alert-info rounded-0 flag mr-2 p-0 mb-0"
                style="background-color: #d56785; width: 15px; height: 15px">
              </span>
              <small
                ><span class="font-weight-bold">{{ claimsStateCount.providerPaymentNotSent }}</span>
                Provider Payment Not Sent</small
              >
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
  <div class="col-sm-4">
    <mdb-card>
      <mdb-card-header>Claims Status</mdb-card-header>
      <mdb-card-body>
        <div class="row no-gutters">
          <div
            class="loader-wrapper"
            *ngIf="loading.claimStatusChart"
            style="margin-left: -1.25rem">
            <div class="loader">
              <app-loader [loader]="loading.claimStatusChart"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <div class="col-6">
            <div style="height: 150px">
              <canvas
                baseChart
                [data]="claimStatusData"
                [labels]="claimStatusLabels"
                [legend]="claimStatusLegends"
                [options]="doughnutChartOptions"
                [colors]="claimStatusColors"
                [chartType]="doughnutChartType"
                [plugins]="claimStatusPlugins"></canvas>
            </div>
          </div>
          <div class="col-6 align-self-center">
            <div class="d-flex">
              <span class="alert border-0 alert-danger rounded flag mr-2 p-0">
                <i class="fas fa-angle-double-up text-danger fa-xs"></i>
              </span>
              <small
                ><span class="font-weight-bold">{{ claimsPriorityCount.HIGH }}</span> High Priority
                Claims</small
              >
            </div>
            <div class="d-flex">
              <span class="alert border-0 alert-warning rounded flag mr-2 p-0">
                <i class="fas fa-chevron-up text-warning fa-xs"></i>
              </span>
              <small
                ><span class="font-weight-bold">{{ claimsPriorityCount.MEDIUM }}</span> Medium
                Priority Claims</small
              >
            </div>
            <div class="d-flex">
              <span class="alert border-0 alert-info rounded flag mr-2 p-0">
                <i class="icofont-flag-alt-1 text-info fa-xs"></i>
              </span>
              <small
                ><span class="font-weight-bold">{{ claimsPriorityCount.LOW }}</span> New
                Claims</small
              >
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
  <div class="col-sm-4">
    <mdb-card>
      <mdb-card-header>Episode</mdb-card-header>
      <mdb-card-body>
        <!-- [style.top]="(episodeClaimsNotReceivedList && episodeClaimsNotReceivedList.length==0)?'auto':0" -->
        <div
          class="loader-wrapper"
          *ngIf="loading.episodeClaimsChart"
          style="margin-left: -1.25rem">
          <div class="loader">
            <app-loader [loader]="loading.episodeClaimsChart"></app-loader>
          </div>
          <div class="backdrop"></div>
        </div>
        <div class="row no-gutters">
          <div class="col-6">
            <div style="height: 150px">
              <canvas
                baseChart
                [data]="episodeClaimData"
                [labels]="episodeClaimLabels"
                [legend]="episodeClaimLegends"
                [options]="doughnutChartOptions"
                [colors]="episodeClaimColors"
                [chartType]="doughnutChartType"
                [plugins]="episodeClaimsPlugins"></canvas>
            </div>
          </div>
          <div class="col-6 align-self-center">
            <div class="d-flex">
              <span class="alert border-0 alert-danger rounded flag mr-2 p-0">
                <i class="fas fa-angle-double-up text-danger fa-xs"></i>
              </span>
              <small
                ><span class="font-weight-bold">{{ episodePriorityCount.HIGH }}</span> High Priority
                Episode</small
              >
            </div>
            <div class="d-flex">
              <span class="alert border-0 alert-warning rounded flag mr-2 p-0">
                <i class="fas fa-chevron-up text-warning fa-xs"></i>
              </span>
              <small
                ><span class="font-weight-bold">{{ episodePriorityCount.MEDIUM }}</span> Medium
                Priority Episode</small
              >
            </div>
            <div class="d-flex">
              <span class="alert border-0 alert-info rounded flag mr-2 p-0">
                <i class="fas fa-layer-group fas text-info fa-xs"></i>
              </span>
              <small
                ><span class="font-weight-bold">{{ milestoneTriggerClaimNotReceived.length }}</span>
                Milestone Trigger Episode</small
              >
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
<div class="row m-0 mt-4">
  <div class="col">
    <!-- Nav tabs -->
    <mdb-tabset
      [buttonClass]="'classic-tabs'"
      class="classic-tabs"
      [contentClass]="'card tab-clean d-block p-0'">
      <!--Panel 1-->
      <mdb-tab heading="Claims">
        <div class="row mt-3">
          <div class="col-12">
            <div class="d-flex align-items-center w-50 w-xs-100">
              <button
                type="button"
                class="btn btn-light z-depth-0 pl-3 pr-3"
                data-toggle="modal"
                data-target="#claimFilterModal"
                (click)="openClaimsFilterModal()"
                mdbWavesEffect>
                <i class="icofont-settings"></i>
                <span class="d-none d-sm-inline-block ml-1">Filter</span>
              </button>
            </div>

            <div class="keywords" *ngIf="claimsFilterList && claimsFilterList.length > 0">
              <div class="keywords d-flex mt-2 flex-wrap align-items-center">
                <small class="mb-2 mr-2">Filters: </small>
                <span class="d-block mr-1 mb-2" *ngFor="let filter of claimsFilterList">
                  <small class="d-block text-muted ml-1"></small>
                  <div class="chip blue lighten-5 mb-0">
                    {{ filter.field }}:
                    <span class="font-weight-500 text-dark">{{ filter.label | titlecase }}</span>
                    <mdb-icon
                      fas
                      icon="times"
                      class="close"
                      (click)="removeClaimsFilter(filter)"></mdb-icon>
                  </div>
                </span>
                <button
                  type="button"
                  class="btn btn-link waves-light m-0 p-0"
                  mdbWavesEffect
                  (click)="resetClaimsFilter()">
                  <span class="text-info">Reset Filter</span>
                </button>
              </div>
            </div>

            <div class="col-12" *ngIf="loading.claims">
              <div class="loader-wrapper" style="margin-left: -1.25rem; margin-top: 1.25rem">
                <div class="loader">
                  <app-loader [loader]="loading.claims"></app-loader>
                </div>
                <div class="backdrop"></div>
              </div>
            </div>
            <mdb-accordion class="accordion-block" [multiple]="false" *ngIf="!loading.claims">
              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="addClass()">
                  <h6>In-Review</h6>
                  <span class="action-item d-flex">
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-danger rounded flag mr-1 p-0">
                        <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        inReviewState.highPriority.length > 0
                          ? inReviewState.highPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-warning rounded flag mr-1 p-0">
                        <i class="fas fa-chevron-up text-warning fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        inReviewState.mediumPriority.length > 0
                          ? inReviewState.mediumPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-info rounded flag mr-1 p-0">
                        <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        inReviewState.lowPriority.length > 0
                          ? inReviewState.lowPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                  </span>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body
                  class="new-claims-custom-scrollbar new-claims-custom-scrollbar-primary">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-danger rounded flag mr-2 p-0">
                          <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">High Priority</h6>
                      </div>
                      <div
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="inReviewState.highPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let highPriorityClaim of inReviewState.highPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ highPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{ calculateDateDiff(highPriorityClaim.reviewDate) }} Days</small
                            >
                            <span *ngIf="highPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>

                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            highPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ highPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown dropup">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(highPriorityClaim.claimId)">
                              <i class="icofont-search-document"></i> View Incoming Claim
                            </a>
                            <a
                              class="dropdown-item"
                              *ngIf="!highPriorityClaim.isFollowUp"
                              (click)="updateFollowUp(highPriorityClaim)">
                              <i class="fas fa-user-check"></i> Set Follow Up
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-warning rounded flag mr-2 p-0">
                          <i class="fas fa-chevron-up text-warning fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">Medium Priority</h6>
                      </div>
                      <div
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="inReviewState.mediumPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let mediumPriorityClaim of inReviewState.mediumPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ mediumPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{ calculateDateDiff(mediumPriorityClaim.reviewDate) }} Days</small
                            >
                            <span *ngIf="mediumPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            mediumPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ mediumPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown dropup">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(mediumPriorityClaim.claimId)">
                              <i class="icofont-search-document"></i> View Incoming Claim
                            </a>
                            <a
                              class="dropdown-item"
                              *ngIf="!mediumPriorityClaim.isFollowUp"
                              (click)="updateFollowUp(mediumPriorityClaim)">
                              <i class="fas fa-user-check"></i> Set Follow Up
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-info rounded flag mr-2 p-0">
                          <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">New</h6>
                      </div>
                      <div
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="inReviewState.lowPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let lowPriorityClaim of inReviewState.lowPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ lowPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{ calculateDateDiff(lowPriorityClaim.reviewDate) }} Days</small
                            >
                            <span *ngIf="lowPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            lowPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ lowPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown dropup">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(lowPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="addClass()">
                  <h6>In-Queue</h6>
                  <span class="action-item d-flex">
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-danger rounded flag mr-1 p-0">
                        <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        triggerClaimNotReceivedState.highPriority.length > 0
                          ? triggerClaimNotReceivedState.highPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-warning rounded flag mr-1 p-0">
                        <i class="fas fa-chevron-up text-warning fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        triggerClaimNotReceivedState.mediumPriority.length > 0
                          ? triggerClaimNotReceivedState.mediumPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-info rounded flag mr-1 p-0">
                        <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        triggerClaimNotReceivedState.lowPriority.length > 0
                          ? triggerClaimNotReceivedState.lowPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                  </span>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body
                  class="new-claims-custom-scrollbar new-claims-custom-scrollbar-primary">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-danger rounded flag mr-2 p-0">
                          <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">High Priority</h6>
                      </div>
                      <div
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="triggerClaimNotReceivedState.highPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let highPriorityClaim of triggerClaimNotReceivedState.highPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ highPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{ calculateDateDiff(highPriorityClaim.procedureDate) }} Days</small
                            >
                            <span *ngIf="highPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>

                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            highPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ highPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown dropup">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToContactDetails(highPriorityClaim)">
                              <i class="icofont-ui-contact-list"></i> Contact Facility
                            </a>
                            <a
                              class="dropdown-item"
                              *ngIf="!highPriorityClaim.isFollowUp"
                              (click)="updateFollowUp(highPriorityClaim)">
                              <i class="fas fa-user-check"></i> Set Follow Up
                            </a>
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(highPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-warning rounded flag mr-2 p-0">
                          <i class="fas fa-chevron-up text-warning fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">Medium Priority</h6>
                      </div>
                      <div
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="triggerClaimNotReceivedState.mediumPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="
                          let mediumPriorityClaim of triggerClaimNotReceivedState.mediumPriority
                        ">
                        <div>
                          <p class="font-weight-bold">
                            {{ mediumPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(mediumPriorityClaim.procedureDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="mediumPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            mediumPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ mediumPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown dropup">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToEpisodeDetails(mediumPriorityClaim.episodeId)">
                              <i class="icofont-search-folder"></i> Research Episode
                            </a>
                            <a
                              class="dropdown-item"
                              *ngIf="!mediumPriorityClaim.isFollowUp"
                              (click)="updateFollowUp(mediumPriorityClaim)">
                              <i class="fas fa-user-check"></i> Set Follow Up
                            </a>
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(mediumPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-info rounded flag mr-2 p-0">
                          <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">New</h6>
                      </div>
                      <div
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="triggerClaimNotReceivedState.lowPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let lowPriorityClaim of triggerClaimNotReceivedState.lowPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ lowPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{ calculateDateDiff(lowPriorityClaim.procedureDate) }} Days</small
                            >
                            <span *ngIf="lowPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            lowPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ lowPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown dropup">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(lowPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="addClass()">
                  <h6>Funding Claim Not Sent</h6>
                  <span class="action-item d-flex">
                    <div class="d-flex mr-4 w-100">
                      <p class="mb-0 mt-2 mr-2 w-100">Fund Request Status</p>
                      <mdb-select
                        style="min-width: 10rem !important"
                        [options]="fundRequestStatusTypes"
                        (selected)="showSelectedFundRequestStatus($event)"
                        [highlightFirst]="false"
                        [(ngModel)]="selectedFundRequestStatus"
                        (click)="$event.stopPropagation()">
                      </mdb-select>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-danger rounded flag mr-1 p-0">
                        <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        fundingClaimNotSentState.highPriority.length
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-warning rounded flag mr-1 p-0">
                        <i class="fas fa-chevron-up text-warning fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        fundingClaimNotSentState.mediumPriority.length
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-info rounded flag mr-1 p-0">
                        <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        fundingClaimNotSentState.lowPriority.length
                      }}</span>
                    </div>
                  </span>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body
                  class="new-claims-custom-scrollbar new-claims-custom-scrollbar-primary">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-danger rounded flag mr-2 p-0">
                          <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">High Priority</h6>
                      </div>
                      <div
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="fundingClaimNotSentState.highPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let highPriorityClaim of fundingClaimNotSentState.highPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ highPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(highPriorityClaim.triggerClaimReceivedDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="highPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>

                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            highPriorityClaim.patientName
                          }}</span>
                          <!-- <p class="text-info">{{ highPriorityClaim.statusReason }}</p> -->
                        </div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <span
                              class="z-depth-0 badge badge-pill {{
                                highPriorityClaim.claimStatusClass
                              }} mt-1">
                              {{ highPriorityClaim.outgoingClaimStatus | titlecase }}
                            </span>
                          </div>
                          <div class="custom-dropdown">
                            <button
                              class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                              type="button"
                              mdbWavesEffect>
                              <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                            </button>
                            <div class="custom-dropdown-content rounded-sm z-depth-2">
                              <a
                                class="dropdown-item"
                                (click)="goToOutgoingClaimsDetail(highPriorityClaim)">
                                <i class="icofont-search-folder"></i> View Outgoing Claim
                              </a>
                              <a
                                class="dropdown-item"
                                *ngIf="!highPriorityClaim.isFollowUp"
                                (click)="updateFollowUp(highPriorityClaim)">
                                <i class="fas fa-user-check"></i> Set Follow Up
                              </a>
                              <a
                                class="dropdown-item"
                                (click)="goToClaimsDetail(highPriorityClaim.claimId)">
                                <i class="icofont-eye-alt"></i> View Incoming Claim
                              </a>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-warning rounded flag mr-2 p-0">
                          <i class="fas fa-chevron-up text-warning fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">Medium Priority</h6>
                      </div>
                      <div
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="fundingClaimNotSentState.mediumPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let mediumPriorityClaim of fundingClaimNotSentState.mediumPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ mediumPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(mediumPriorityClaim.triggerClaimReceivedDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="mediumPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            mediumPriorityClaim.patientName
                          }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <span
                              class="z-depth-0 badge badge-pill {{
                                mediumPriorityClaim.claimStatusClass
                              }} mt-1">
                              {{ mediumPriorityClaim.outgoingClaimStatus | titlecase }}
                            </span>
                          </div>
                          <div class="custom-dropdown">
                            <button
                              class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                              type="button"
                              mdbWavesEffect>
                              <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                            </button>
                            <div class="custom-dropdown-content rounded-sm z-depth-2">
                              <a
                                class="dropdown-item"
                                (click)="goToOutgoingClaimsDetail(mediumPriorityClaim)">
                                <i class="icofont-search-folder"></i> View Outgoing Claim
                              </a>
                              <a
                                class="dropdown-item"
                                *ngIf="!mediumPriorityClaim.isFollowUp"
                                (click)="updateFollowUp(mediumPriorityClaim)">
                                <i class="fas fa-user-check"></i> Set Follow Up
                              </a>
                              <a
                                class="dropdown-item"
                                (click)="goToClaimsDetail(mediumPriorityClaim.claimId)">
                                <i class="icofont-eye-alt"></i> View Incoming Claim
                              </a>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-info rounded flag mr-2 p-0">
                          <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">New</h6>
                      </div>
                      <div
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="fundingClaimNotSentState.lowPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let lowPriorityClaim of fundingClaimNotSentState.lowPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ lowPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(lowPriorityClaim.triggerClaimReceivedDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="lowPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            lowPriorityClaim.patientName
                          }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <span
                              class="z-depth-0 badge badge-pill {{
                                lowPriorityClaim.claimStatusClass
                              }} mt-1">
                              {{ lowPriorityClaim.outgoingClaimStatus | titlecase }}
                            </span>
                          </div>
                          <div class="custom-dropdown">
                            <button
                              class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                              type="button"
                              mdbWavesEffect>
                              <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                            </button>
                            <div class="custom-dropdown-content rounded-sm z-depth-2">
                              <a
                                class="dropdown-item"
                                (click)="goToClaimsDetail(lowPriorityClaim.claimId)">
                                <i class="icofont-eye-alt"></i> View Incoming Claim
                              </a>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="addClass()">
                  <h6>Funding Not Received</h6>
                  <span class="action-item d-flex">
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-danger rounded flag mr-1 p-0">
                        <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        fundingNotReceivedState.highPriority.length > 0
                          ? fundingNotReceivedState.highPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-warning rounded flag mr-1 p-0">
                        <i class="fas fa-chevron-up text-warning fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        fundingNotReceivedState.mediumPriority.length > 0
                          ? fundingNotReceivedState.mediumPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-info rounded flag mr-1 p-0">
                        <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        fundingNotReceivedState.lowPriority.length > 0
                          ? fundingNotReceivedState.lowPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                  </span>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body
                  class="new-claims-custom-scrollbar new-claims-custom-scrollbar-primary">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-danger rounded flag mr-2 p-0">
                          <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">High Priority</h6>
                      </div>
                      <div
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="fundingNotReceivedState.highPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let highPriorityClaim of fundingNotReceivedState.highPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ highPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(highPriorityClaim.fundingRequestedDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="highPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>

                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            highPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ highPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="openContactPayerPopup(highPriorityClaim)">
                              <i class="icofont-ui-contact-list"></i> Contact Payor
                            </a>
                            <a
                              class="dropdown-item"
                              *ngIf="!highPriorityClaim.isFollowUp"
                              (click)="updateFollowUp(highPriorityClaim)">
                              <i class="fas fa-user-check"></i> Set Follow Up
                            </a>
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(highPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                            <a
                              class="dropdown-item"
                              (click)="goToOutgoingClaimsDetail(highPriorityClaim)">
                              <i class="icofont-search-folder"></i> View Outgoing Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-warning rounded flag mr-2 p-0">
                          <i class="fas fa-chevron-up text-warning fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">Medium Priority</h6>
                      </div>
                      <div
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="fundingNotReceivedState.mediumPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let mediumPriorityClaim of fundingNotReceivedState.mediumPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ mediumPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(mediumPriorityClaim.fundingRequestedDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="mediumPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            mediumPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ mediumPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToOutgoingClaimsDetail(mediumPriorityClaim)">
                              <i class="icofont-search-folder"></i> View Outgoing Claim
                            </a>
                            <a
                              class="dropdown-item"
                              *ngIf="!mediumPriorityClaim.isFollowUp"
                              (click)="updateFollowUp(mediumPriorityClaim)">
                              <i class="fas fa-user-check"></i> Set Follow Up
                            </a>
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(mediumPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-info rounded flag mr-2 p-0">
                          <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">New</h6>
                      </div>
                      <div
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="fundingNotReceivedState.lowPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let lowPriorityClaim of fundingNotReceivedState.lowPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ lowPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(lowPriorityClaim.fundingRequestedDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="lowPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            lowPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ lowPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(lowPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="addClass()">
                  <h6>Provider Payment Not Sent</h6>
                  <span class="action-item d-flex">
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-danger rounded flag mr-1 p-0">
                        <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        providerPaymentNotSentState.highPriority.length > 0
                          ? providerPaymentNotSentState.highPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-warning rounded flag mr-1 p-0">
                        <i class="fas fa-chevron-up text-warning fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        providerPaymentNotSentState.mediumPriority.length > 0
                          ? providerPaymentNotSentState.mediumPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-info rounded flag mr-1 p-0">
                        <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        providerPaymentNotSentState.lowPriority.length > 0
                          ? providerPaymentNotSentState.lowPriority[0].priorityCount
                          : 0
                      }}</span>
                    </div>
                  </span>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body
                  class="new-claims-custom-scrollbar new-claims-custom-scrollbar-primary">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-danger rounded flag mr-2 p-0">
                          <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">High Priority</h6>
                      </div>
                      <div
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="providerPaymentNotSentState.highPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let highPriorityClaim of providerPaymentNotSentState.highPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ highPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(highPriorityClaim.fundingReceivedDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="highPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>

                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            highPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ highPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToOutgoingClaimsDetail(highPriorityClaim)">
                              <i class="icofont-search-folder"></i> View Outgoing Claim
                            </a>
                            <a
                              class="dropdown-item"
                              *ngIf="!highPriorityClaim.isFollowUp"
                              (click)="updateFollowUp(highPriorityClaim)">
                              <i class="fas fa-user-check"></i> Set Follow Up
                            </a>
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(highPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-warning rounded flag mr-2 p-0">
                          <i class="fas fa-chevron-up text-warning fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">Medium Priority</h6>
                      </div>
                      <div
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="providerPaymentNotSentState.mediumPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="
                          let mediumPriorityClaim of providerPaymentNotSentState.mediumPriority
                        ">
                        <div>
                          <p class="font-weight-bold">
                            {{ mediumPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(mediumPriorityClaim.fundingReceivedDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="mediumPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            mediumPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ mediumPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToOutgoingClaimsDetail(mediumPriorityClaim)">
                              <i class="icofont-search-folder"></i> View Outgoing Claim
                            </a>
                            <a
                              class="dropdown-item"
                              *ngIf="!mediumPriorityClaim.isFollowUp"
                              (click)="updateFollowUp(mediumPriorityClaim)">
                              <i class="fas fa-user-check"></i> Set Follow Up
                            </a>
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(mediumPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-info rounded flag mr-2 p-0">
                          <i class="icofont-flag-alt-1 text-info fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">New</h6>
                      </div>
                      <div
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="providerPaymentNotSentState.lowPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-info d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="let lowPriorityClaim of providerPaymentNotSentState.lowPriority">
                        <div>
                          <p class="font-weight-bold">
                            {{ lowPriorityClaim.patientAccountNumber }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(lowPriorityClaim.fundingReceivedDate)
                              }}
                              Days</small
                            >
                            <span *ngIf="lowPriorityClaim.isFollowUp">
                              - <i class="icofont-ui-clip-board text-primary pointer-cursor"></i
                            ></span>
                          </p>
                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            lowPriorityClaim.patientName
                          }}</span>
                          <p class="text-info">{{ lowPriorityClaim.statusReason }}</p>
                        </div>

                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToClaimsDetail(lowPriorityClaim.claimId)">
                              <i class="icofont-eye-alt"></i> View Incoming Claim
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </mdb-accordion>
          </div>
        </div>
      </mdb-tab>
      <!--Panel 2-->
      <mdb-tab heading="Episode ">
        <div class="row mt-3">
          <div class="col-12">
            <div class="col-12" *ngIf="loading.episode">
              <div class="loader-wrapper" style="margin-left: -1.25rem; margin-top: 1.25rem">
                <div class="loader">
                  <app-loader [loader]="loading.episode"></app-loader>
                </div>
                <div class="backdrop"></div>
              </div>
            </div>
            <mdb-accordion class="accordion-block" *ngIf="!loading.episode">
              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="addClass()">
                  <h6>Trigger Claim Not Received</h6>
                  <span class="action-item d-flex">
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-danger rounded flag mr-1 p-0">
                        <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        episodeTriggerClaimNotReceivedState.highPriority.length > 0
                          ? episodeTriggerClaimNotReceivedState.highPriority.length
                          : 0
                      }}</span>
                    </div>
                    <div class="d-flex mr-4">
                      <span class="alert border-0 alert-warning rounded flag mr-1 p-0">
                        <i class="fas fa-chevron-up text-warning fa-xs"></i>
                      </span>
                      <span class="font-weight-bold">{{
                        episodeTriggerClaimNotReceivedState.mediumPriority.length > 0
                          ? episodeTriggerClaimNotReceivedState.mediumPriority.length
                          : 0
                      }}</span>
                    </div>
                  </span>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body
                  class="new-claims-custom-scrollbar new-claims-custom-scrollbar-primary">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-danger rounded flag mr-2 p-0">
                          <i class="fas fa-angle-double-up text-danger fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">High Priority</h6>
                      </div>
                      <div
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="episodeTriggerClaimNotReceivedState.highPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="
                          let highPriorityEpisodeClaim of episodeTriggerClaimNotReceivedState.highPriority
                        ">
                        <div>
                          <p class="font-weight-bold">
                            {{ highPriorityEpisodeClaim.episodeName }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(highPriorityEpisodeClaim.procedureDate)
                              }}
                              Days</small
                            >
                          </p>

                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            highPriorityEpisodeClaim.patientName
                          }}</span>
                        </div>

                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToEpisodeDetails(highPriorityEpisodeClaim.episodeId)">
                              <i class="icofont-search-folder"></i> Research Episode
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="col-sm-4">
                      <div class="d-flex mt-2">
                        <span class="alert border-0 alert-warning rounded flag mr-2 p-0">
                          <i class="fas fa-chevron-up text-warning fa-xs"></i>
                        </span>
                        <h6 class="font-weight-bold">Medium Priority</h6>
                      </div>
                      <div
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngIf="episodeTriggerClaimNotReceivedState.mediumPriority.length === 0">
                        <h6>No Claims Available</h6>
                      </div>
                      <span
                        class="note note-warning d-flex justify-content-between mb-2 bg-white shadow-sm"
                        *ngFor="
                          let mediumPriorityEpisodeClaim of episodeTriggerClaimNotReceivedState.mediumPriority
                        ">
                        <div>
                          <p class="font-weight-bold">
                            {{ mediumPriorityEpisodeClaim.episodeName }} -
                            <small class="text-muted"
                              >{{
                                calculateDateDiff(mediumPriorityEpisodeClaim.procedureDate)
                              }}
                              Days</small
                            >
                          </p>

                          <span class="badge badge-sm bg-primary-dark text-white rounded-sm">{{
                            mediumPriorityEpisodeClaim.patientName
                          }}</span>
                        </div>

                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToEpisodeDetails(mediumPriorityEpisodeClaim.episodeId)">
                              <i class="icofont-search-folder"></i> Research Episode
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
              <mdb-accordion-item>
                <mdb-accordion-item-head (click)="addClass()">
                  <h6>Milestone Trigger Awaiting</h6>
                  <span class="action-item d-flex">
                    <div class="d-flex mr-4">
                      <small class="border rounded-circle alert-info p-2">{{
                        milestoneTriggerClaimNotReceived.length
                      }}</small>
                    </div>
                  </span>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body
                  class="new-claims-custom-scrollbar new-claims-custom-scrollbar-primary">
                  <div class="row">
                    <div
                      class="col-sm-4 mt-3"
                      *ngFor="let episode of milestoneTriggerClaimNotReceived">
                      <span
                        class="note note-danger d-flex justify-content-between mb-2 bg-white shadow-sm border-0">
                        <div>
                          <p class="font-weight-bold">{{ episode.episodeName }}</p>
                          <span class="d-block">{{ episode.patientName }}</span>
                          <span class="d-block"
                            >Care Coordinator - {{ episode.careCoordinatorName }}</span
                          >
                        </div>
                        <div class="custom-dropdown">
                          <button
                            class="btn-ellipsis border-0 dropdown-toggle waves-light m-0 btn-link btn-sm btn-floating"
                            type="button"
                            mdbWavesEffect>
                            <mdb-icon class="skin-link" fas icon="ellipsis-v"></mdb-icon>
                          </button>
                          <div class="custom-dropdown-content rounded-sm z-depth-2">
                            <a
                              class="dropdown-item"
                              (click)="goToEpisodeDetails(episode.episodeId)">
                              <i class="icofont-eye"></i> View Episode Detail
                            </a>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </mdb-accordion>
          </div>
        </div>
      </mdb-tab>
    </mdb-tabset>
  </div>
</div>

<!-- Claim Filter Modal -->
<div
  mdbModal
  #claimFilterModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter Claims</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeClaimFilterModal('close')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="p-3">
          <form [formGroup]="claimFilterForm">
            <div class="form-row">
              <div class="col-4">
                <label for="claimNumber">Claim #</label>
                <input
                  placeholder=""
                  type="text"
                  mdbInput
                  class="form-control"
                  formControlName="patientAccountNumber" />
              </div>
              <div class="col-8">
                <div class="position-relative">
                  <label for="billingProvider"
                    >Billing Provider<span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="loading.billingProviderSearch"></span
                  ></label>
                  <input
                    type="text"
                    [ngModel]="billing_provider_search_text | async"
                    id="billingInfo"
                    (ngModelChange)="billing_provider_search_text.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    autocomplete="off"
                    [mdbAutoCompleter]="auto7"
                    placeholder="Type to select billing provider" />
                  <mdb-auto-completer
                    #auto7="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayBillingInfo"
                    [optionHeight]="100"
                    (selected)="onClaimSearchResultSelected($event, 'billingProviderId')">
                    <mdb-option
                      *ngFor="let option of billing_provider_results | async"
                      [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.displayName | titlecase }}</strong>
                        <span>{{ option.identificationCode }}</span>
                        <span>
                          located in {{ option.city | titlecase }}{{ option.state ? ',' : '' }}
                          {{ option.state }}</span
                        >
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col">
                <div class="position-relative">
                  <label for="payor"
                    >Payor<span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="loading.payerSearch"></span
                  ></label>
                  <input
                    type="text"
                    [ngModel]="payer_search_text | async"
                    id="payor"
                    (ngModelChange)="payer_search_text.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    autocomplete="off"
                    [mdbAutoCompleter]="auto4"
                    placeholder="Type to select payor" />
                  <mdb-auto-completer
                    #auto4="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayPayer"
                    [optionHeight]="40"
                    (selected)="onClaimSearchResultSelected($event, 'payerCode')">
                    <mdb-option *ngFor="let option of payer_results | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.payerName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-4">
                <label for="procedureCode">Procedure Code</label>
                <input
                  placeholder=""
                  type="text"
                  mdbInput
                  class="form-control"
                  formControlName="procedureCode"
                  alphaNumericWithoutComma
                  maxlength="5" />
              </div>
              <div class="col-8">
                <div class="position-relative">
                  <label for="exceptionCode">Choose Exception Code</label>
                  <app-select
                    [options]="exceptionCodes | sort : 'label'"
                    [id]="'exceptionCode'"
                    [placeholder]="'Select exception code'"
                    (onSelected)="onClaimSearchResultSelected($event, 'exceptionCode')"
                    [control]="claimFilterForm.controls['exceptionCode']">
                  </app-select>
                </div>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col">
                <div class="form-check form-check-inline">Choose follow up</div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="followupAll"
                    name="isFollowUp"
                    value="all"
                    mdbInput
                    formControlName="isFollowUp"
                    (change)="changeFollowUp($event)" />
                  <label class="form-check-label" for="followupAll">All</label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="followupYes"
                    name="isFollowUp"
                    value="true"
                    mdbInput
                    formControlName="isFollowUp"
                    (change)="changeFollowUp($event)" />
                  <label class="form-check-label" for="followupYes">Yes</label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="followupNo"
                    name="isFollowUp"
                    value="false"
                    mdbInput
                    formControlName="isFollowUp"
                    (change)="changeFollowUp($event)" />
                  <label class="form-check-label" for="followupNo">No</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeClaimFilterModal('close')">
          Cancel
        </button>
        <button
          class="btn btn-secondary waves-light m-0"
          mdbWavesEffect
          (click)="filterClaimStates()">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Filter Modal -->
<div
  mdbModal
  #filterModal="mdbModal"
  class="modal fade top"
  id="frameModalTop1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{ignoreBackdropClick: true, backdrop: true, keyboard: false}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content rounded-0">
      <div class="modal-header">
        <h5 class="modal-title w-100">Filter</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeFilterModal('close')">
          <i class="icofont-close-line"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="p-3">
          <form [formGroup]="filterForm">
            <div class="form-row">
              <div class="col">
                <div class="position-relative">
                  <label for="episodeType"
                    >Episode<span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="loading.episodeSearch"></span
                  ></label>
                  <input
                    type="text"
                    [ngModel]="episode_search_text | async"
                    id="episode10"
                    (ngModelChange)="episode_search_text.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    autocomplete="off"
                    [mdbAutoCompleter]="auto10"
                    placeholder="Type to select episode" />
                  <mdb-auto-completer
                    #auto10="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayEpisode"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'episodeName')">
                    <mdb-option *ngFor="let option of episode_results | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.episodeName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col">
                <div class="position-relative">
                  <label for="patient"
                    >Patient<span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="loading.patientSearch"></span
                  ></label>
                  <input
                    type="text"
                    [ngModel]="patient_search_text | async"
                    id="patient11"
                    (ngModelChange)="patient_search_text.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    autocomplete="off"
                    [mdbAutoCompleter]="auto11"
                    placeholder="Type to select patient" />
                  <mdb-auto-completer
                    #auto11="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayPatient"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'patientName')">
                    <mdb-option *ngFor="let option of patient_results | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.displayName | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>

            <div class="form-row mt-3">
              <div class="col">
                <div class="position-relative">
                  <label for="client"
                    >Client<span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="loading.clientSearch"></span
                  ></label>
                  <input
                    type="text"
                    [ngModel]="client_search_text | async"
                    id="client"
                    (ngModelChange)="client_search_text.next($event)"
                    [ngModelOptions]="{standalone: true}"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    autocomplete="off"
                    [mdbAutoCompleter]="auto12"
                    placeholder="Type to select client" />
                  <mdb-auto-completer
                    #auto12="mdbAutoCompleter"
                    textNoResults="No result found"
                    [displayValue]="displayClient"
                    [optionHeight]="40"
                    (selected)="onSearchResultSelected($event, 'clientCode')">
                    <mdb-option *ngFor="let option of client_results | async" [value]="option">
                      <div class="d-flex flex-column">
                        <strong>{{ option.name | titlecase }}</strong>
                      </div>
                    </mdb-option>
                  </mdb-auto-completer>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer grey lighten-3 d-flex justify-content-between p-2 border-top-0">
        <button
          class="waves-light m-0 btn-light btn"
          mdbWavesEffect
          (click)="closeFilterModal('close')">
          Cancel
        </button>
        <button class="btn btn-secondary waves-light m-0" mdbWavesEffect (click)="filter()">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>

<!--Payor Contact Modal-->

<div
  mdbModal
  #payerContactModal="mdbModal"
  class="modal fade"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-notify modal-dialog-centered" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header z-depth-0 border-bottom">
        <p class="modal-title">Payor Contact</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closePayerContactModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <app-loader [loader]="loading.popup"></app-loader>
        <div *ngIf="!loading.popup">
          <div class="field-group">
            <label for=""><i class="icofont icofont-user"></i> Name </label>
            <div class="field-value">
              {{ payerContactForm.value.name ? payerContactForm.value.name : 'N/A' }}
            </div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-calendar"></i> Payor ID </label>
            <div class="field-value">
              {{ payerContactForm.value.identifier ? payerContactForm.value.identifier : 'N/A' }}
            </div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-user"></i> Contact Name </label>
            <div class="field-value">
              {{ payerContactForm.value.contactName ? payerContactForm.value.contactName : 'N/A' }}
            </div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-phone"></i> Contact Phone </label>
            <div class="field-value">
              {{
                payerContactForm.value.contactPhone
                  ? (payerContactForm.value.contactPhone | formatPhoneNumber)
                  : 'N/A'
              }}
            </div>
          </div>
          <div class="field-group">
            <label for=""><i class="icofont icofont-email"></i> Contact Email </label>
            <div class="field-value">
              {{
                payerContactForm.value.contactEmail ? payerContactForm.value.contactEmail : 'N/A'
              }}
            </div>
          </div>
          <div class="field-group" *ngIf="hasPayerAddress">
            <label for=""><i class="icofont icofont-location-pin"></i> Address </label>
            <div class="field-value">
              {{ payerContactForm.value.addressLine1 ? payerContactForm.value.addressLine1 : '' }}
              {{ payerContactForm.value.addressLine2 }}<br />
              {{ payerContactForm.value.city ? payerContactForm.value.city : '' }}
              {{ payerContactForm.value.state }}
              {{
                payerContactForm.value?.zip4
                  ? payerContactForm.value?.zip + '-' + payerContactForm.value?.zip4
                  : payerContactForm.value?.zip
              }}
            </div>
          </div>
          <div class="field-group" *ngIf="!hasPayerAddress">
            <label for=""><i class="icofont icofont-location-pin"></i> Address </label>
            <div class="field-value">N/A</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
