import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-episode-user-dependent-message',
  templateUrl: './episode-user-dependent-message.component.html',
  styleUrls: ['./episode-user-dependent-message.component.scss']
})
export class EpisodeUserDependentMessageComponent implements OnInit {
  @Input() dependent_message
  constructor() { }

  ngOnInit() {
  }

}
