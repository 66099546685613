import { Action } from '@ngrx/store';

export const SET_SIDENAV_STATUS = '[SIDENAVSTATUS] Set'


export class SetSidenavStatus implements Action {
    readonly type = SET_SIDENAV_STATUS
    constructor(public payload: {}) { }
}

export type SideNavActions = SetSidenavStatus
