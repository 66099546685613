import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PathwayService {

  constructor(
    private http: HttpClientService
  ) { }

  // getPathways(page, limit, keyword, id) {
  //   const insurerId = id ? `&insurerId=${id}` : ''
  //   const searchKeyWord = keyword ? `&searchKeyword=${keyword}` : ''
  //   const pages = page ? `page=${page}` : ''
  //   const limits = limit ? `&limit=${limit}` : ''
  //   return this.http.get(`${APICONSTANTS.PATHWAY}?${pages}${limits}${insurerId}${searchKeyWord}`)
  //     .pipe(map((res: any) => {
  //       return res.data;
  //     }));
  // }

  getPathways(query?) {
    return this.http.get(`${APICONSTANTS.PATHWAY}`, query)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  savePathway(pathway) {
    return this.http.post(`${APICONSTANTS.PATHWAY}`, pathway)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  updatePathway(pathway) {
    return this.http.put(`${APICONSTANTS.PATHWAY}`, pathway)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  exportPathway(params) {
    return this.http.get(`${APICONSTANTS.PATHWAY}/export`, params)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  importPathway(pathway) {
    return this.http.post(`${APICONSTANTS.PATHWAY}/import`, pathway)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getPathwayById(id, queryParam?) {
    return new Promise((resolve, reject) => {
      this.http.get(`${APICONSTANTS.PATHWAY}/detail/${id}`, queryParam).toPromise()
        .then((res: any) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  deletePathway(uuid) {
    return this.http.delete(`${APICONSTANTS.PATHWAY}/${uuid}`)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }

  getDocuSignAgreementTemplates(params) {
    return this.http.get(`${APICONSTANTS.LOOKUP}`, params)
      .pipe(map((res: any) => {
        return res.data;
      }))
  }
}
