<section class="container-fluid">
  <div class="d-flex justify-content-between align-items-center mt-4 action position-sticky">
    <div class="form-group has-search input-group mt-2 mb-2 w-25 w-xs-100">
      <span class="form-control-feedback left-0">
        <i class="icofont-search-1"></i>
      </span>
      <input
        type="text"
        [(ngModel)]="query.keyword"
        class="form-control"
        id="search"
        placeholder="Search"
        (ngModelChange)="searchProviderByKeyword($event)" />
      <div class="input-group-append" *ngIf="query.keyword">
        <button class="btn btn-sm btn-link text-decoration-none p-2 m-0">
          <i class="icofont-close-line fa-2x" (click)="removeSearchKeyword()"></i>
        </button>
      </div>
    </div>
    <div class="w-50" [ngClass]="user.roleCode == 'PR' ? 'd-flex justify-content-end' : 'form-row'">
      <div class="col-2">
        <mdb-select
          placeholder="Show 10"
          [options]="showLimits"
          (selected)="searchByLimit($event)"
          [highlightFirst]="false">
        </mdb-select>
      </div>
      <div class="col-3" *ngIf="user.roleCode !== 'PR'">
        <mdb-select
          placeholder="Physician"
          [options]="providerTypeList"
          [highlightFirst]="false"
          [(ngModel)]="query.entityCode"
          (selected)="searchByProviderType($event)">
        </mdb-select>
      </div>
      <div class="col-4" *ngIf="user.roleCode !== 'PR'">
        <app-select
          [options]="networks"
          (onSelected)="searchByNetwork($event)"
          [disabled]="!networks || networks.length == 0"
          [(selected)]="query.network"
          [isTemplateDriven]="true">
        </app-select>
      </div>
      <div class="col-3" *ngIf="user.roleCode !== 'PR'">
        <button
          type="button"
          class="btn btn-secondary mt-0 btn-block"
          data-toggle="modal"
          [disabled]="!providerList || providerList.length === 0"
          (click)="exportToCsv()"
          data-target="#basicExample"
          mdbWavesEffect>
          <span
            *ngIf="export_loading"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"></span>
          <i *ngIf="!export_loading" class="fas fa-file-export"></i>
          <span class="d-none d-sm-inline-block ml-1"> Export</span>
        </button>
      </div>
    </div>
  </div>

  <div class="table-wrapper">
    <div class="container-fluid">
      <div class="row">
        <table mdbTable hover="true" small="true" stickyHeader="true">
          <thead id="scrollToTop">
            <tr>
              <th width="20"></th>
              <th [class.active]="query.sortBy === 'name'" (click)="setOrder('name')">
                Name
                <span *ngIf="query.sortBy === 'name'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th [class.active]="query.sortBy === 'specialty'" (click)="setOrder('specialty')">
                Speciality
                <span *ngIf="query.sortBy === 'specialty'">
                  <span [hidden]="reverse"><i class="fas fa-sort-down"></i></span>
                  <span [hidden]="!reverse"><i class="fas fa-sort-up"></i></span>
                </span>
              </th>
              <th>Address</th>
              <th>Contact</th>
            </tr>
          </thead>
          <div
            class="loader-wrapper"
            *ngIf="loading"
            [style.top]="providerList && providerList.length == 0 ? 'auto' : 0">
            <div class="loader">
              <app-loader [loader]="loading"></app-loader>
            </div>
            <div class="backdrop"></div>
          </div>
          <tbody
            *ngFor="
              let provider of providerList
                | paginate
                  : {itemsPerPage: query.limit, currentPage: query.page, totalItems: totalProvider};
              let i = index
            ">
            <tr [ngClass]="{'grey lighten-3 border': provider.collapsed}">
              <td
                (click)="
                  provider.collapsed = !provider.collapsed; getAssociatedDetails(provider, i)
                ">
                <button
                  type="button"
                  class="btn btn-link p-0 mt-0"
                  mdbWavesEffect
                  *ngIf="provider.collapsed">
                  <i class="icofont-minus-circle icofont-lg text-info"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-link p-0 mt-0"
                  mdbWavesEffect
                  *ngIf="!provider.collapsed">
                  <i class="icofont-plus-circle icofont-lg text-info"></i>
                </button>
              </td>
              <td>
                {{
                  provider?.displayName ||
                    provider?.businessName ||
                    provider?.firstName + ' ' + provider?.middleName + ' ' + provider?.lastName
                }}
                <br />
                {{ provider.npi ? '(N)' + provider.npi : ''
                }}{{ provider.taxId ? ', (T)' + provider.taxId : '' }}
              </td>
              <td>
                {{ provider?.primaryTaxonomyCode }}<br />
                {{ provider?.taxonomyDescription }}
              </td>
              <td>
                <ul class="fa-ul ml-4 mb-0">
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="map-marker-alt"></mdb-icon>
                    </span>
                    <ng-template
                      [ngIf]="
                        provider.practiceAddress.addressline1 ||
                        provider.practiceAddress.addressline2 ||
                        provider.practiceAddress.city ||
                        provider.practiceAddress.state
                      "
                      [ngIfElse]="noAddress">
                      {{
                        provider.practiceAddress?.addressline1
                          ? provider.practiceAddress.addressline1
                          : ''
                      }}
                      {{
                        provider.practiceAddress.addressline2
                          ? provider.practiceAddress.addressline2
                          : ''
                      }}
                      <br
                        *ngIf="
                          provider.practiceAddress.addressline1 ||
                          provider.practiceAddress.addressline2
                        " />
                      <span
                        >{{ provider.practiceAddress?.city ? provider.practiceAddress.city : '' }}
                        {{ provider.practiceAddress?.state ? provider.practiceAddress.state : '' }}
                        {{
                          provider.practiceAddress?.zip ? provider.practiceAddress.zip : ''
                        }}</span
                      >
                    </ng-template>
                    <ng-template #noAddress> N/A </ng-template>
                  </li>
                </ul>
              </td>
              <td>
                <ul class="fa-ul ml-4 mb-0">
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="envelope"></mdb-icon>
                    </span>
                    {{ provider?.email ? provider.email : 'N/A' }}
                  </li>
                  <li>
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="phone-alt"></mdb-icon>
                    </span>
                    {{ provider?.phone ? provider.phone : 'N/A' }}
                  </li>
                  <li *ngIf="provider.fax">
                    <span class="fa-li">
                      <mdb-icon class="text-primary" fas icon="fax"></mdb-icon>
                    </span>
                    {{ provider.fax }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr *ngIf="provider.collapsed" class="grey lighten-5 border">
              <td colspan="100%" class="p-3">
                {{ query.entityCode == 2 ? 'Physicians' : 'Facilities' }}
                <app-loader [loader]="!provider.associatedDetails"> </app-loader>
                <div
                  *ngIf="
                    provider?.associatedDetails?.providerList &&
                    provider?.associatedDetails?.providerList.length == 0
                  ">
                  <i> No {{ query.entityCode == 2 ? 'physicians' : 'facilities' }} associated</i>
                </div>
                <div
                  *ngIf="
                    provider?.associatedDetails?.providerList &&
                    provider?.associatedDetails?.providerList.length > 0
                  ">
                  <div
                    class="mt-3 form-row scrollbar scrollbar-primary thin overflow-auto"
                    style="max-height: 400px">
                    <div
                      class="col-6 col-sm-4 col-md-3 col-xl-3"
                      *ngFor="let associated of provider?.associatedDetails?.providerList">
                      <div class="alert bg-blue-light rounded-sm d-flex align-items-center p-2">
                        <div class="avatar avatar-md mr-3">
                          <img
                            *ngIf="associated.imageUrl"
                            class="img-fluid"
                            src="{{ associated.imageUrl }}" />
                          <img
                            *ngIf="!associated.imageUrl"
                            class="img-fluid"
                            src="{{
                              query.entityCode == 1
                                ? '../../../assets/img/png/building_icon.png'
                                : '../../../assets/img/png/doctor_icon.png'
                            }}" />
                        </div>
                        <span class="font-weight-500 text-dark">
                          <p class="mb-0">
                            {{
                              associated.displayName ||
                                associated.businessName ||
                                associated.firstName +
                                  ' ' +
                                  associated.middleName +
                                  ' ' +
                                  associated.lastName
                            }}
                          </p>
                          <small>NPI - {{ associated.npi ? associated.npi : 'N/A' }}</small>
                          <br />
                          <small>TIN - {{ associated.taxId ? associated.taxId : 'N/A' }}</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr />
                  Episode Point of Contact Person(s)
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="!provider.episodePointOfContact"></span>
                  <div
                    *ngIf="
                      provider.episodePointOfContact &&
                      provider.episodePointOfContact != 'null' &&
                      provider.episodePointOfContact?.providerContact &&
                      provider.episodePointOfContact?.providerContact.length > 0
                    "
                    class="form-row scrollbar scrollbar-primary thin overflow-auto"
                    style="max-height: 400px">
                    <div
                      *ngIf="provider.episodePointOfContact.providerContact[0]?.primaryFirstName"
                      class="col-6 col-sm-4 col-md-3 col-xl-3 mt-3">
                      <div class="alert bg-blue-light rounded-sm d-flex align-items-center">
                        <span class="font-weight-500 text-dark">
                          <p class="mb-0">
                            {{
                              provider.episodePointOfContact.providerContact[0]?.primaryFirstName
                            }}
                            {{ provider.episodePointOfContact.providerContact[0]?.primaryLastName }}
                          </p>
                          <small>{{
                            provider.episodePointOfContact.providerContact[0]?.primaryEmail
                          }}</small
                          ><br />
                          <small>{{
                            provider.episodePointOfContact.providerContact[0]?.primaryPhone
                          }}</small>
                        </span>
                      </div>
                    </div>
                    <div
                      *ngIf="provider.episodePointOfContact.providerContact[0]?.schedulingFirstName"
                      class="col-6 col-sm-4 col-md-3 col-xl-3 mt-3">
                      <div class="alert bg-blue-light rounded-sm d-flex align-items-center">
                        <span class="font-weight-500 text-dark">
                          <p class="mb-0">
                            {{
                              provider.episodePointOfContact.providerContact[0]?.schedulingFirstName
                            }}
                            {{
                              provider.episodePointOfContact.providerContact[0]?.schedulingLastName
                            }}
                          </p>
                          <small>{{
                            provider.episodePointOfContact.providerContact[0]?.schedulingEmail
                          }}</small
                          ><br />
                          <small>{{
                            provider.episodePointOfContact.providerContact[0]?.schedulingPhone
                          }}</small>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      provider.episodePointOfContact &&
                      (provider.episodePointOfContact == 'null' ||
                        !provider.episodePointOfContact.providerContact ||
                        provider.episodePointOfContact.providerContact.length == 0 ||
                        (provider.episodePointOfContact.providerContact &&
                          provider.episodePointOfContact.providerContact.length > 0 &&
                          !provider.episodePointOfContact.providerContact[0].primaryFirstName &&
                          !provider.episodePointOfContact.providerContact[0].schedulingFirstName))
                    ">
                    <i> No episode point of contact person available.</i>
                  </div>
                </div>
                <div>
                  <hr />
                  <div class="d-flex justify-content-between w-50">
                    <div class="d-flex flex-column">
                      <div>
                        Effective Date
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                          *ngIf="!provider.episodePointOfContact"></span>
                      </div>
                      <i
                        *ngIf="
                          provider &&
                            provider.episodePointOfContact &&
                            provider.episodePointOfContact.effectiveStartDate;
                          else provider.episodePointOfContact && notAvailable
                        "
                        >{{
                          provider.episodePointOfContact.effectiveStartDate
                            | date : 'yyyy-MM-dd' : 'UTC'
                        }}</i
                      >
                    </div>
                    <div class="d-flex flex-column">
                      <div>
                        Termination Date
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                          *ngIf="!provider.episodePointOfContact"></span>
                      </div>
                      <i
                        *ngIf="
                          provider &&
                            provider.episodePointOfContact &&
                            provider.episodePointOfContact.effectiveEndDate;
                          else provider.episodePointOfContact && notAvailable
                        "
                        >{{
                          provider.episodePointOfContact.effectiveEndDate
                            | date : 'yyyy-MM-dd' : 'UTC'
                        }}</i
                      >
                    </div>
                    <ng-template #notAvailable><i>N/A</i></ng-template>
                  </div>
                </div>
                <div
                  *ngIf="user.roleCode == 'PA' || user.roleCode == 'PR' || user.roleCode == 'CLA'">
                  <hr />
                  Financial
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="!provider.episodePointOfContact"></span>
                  <div
                    *ngIf="
                      provider.episodePointOfContact && provider.episodePointOfContact != 'null'
                    "
                    class="form-row scrollbar scrollbar-primary thin overflow-auto"
                    style="max-height: 400px">
                    <div
                      class="col-6 col-sm-4 col-md-3 col-xl-3 mt-3"
                      *ngIf="
                        provider.episodePointOfContact?.providerFinancial &&
                        provider.episodePointOfContact?.providerFinancial.length > 0 &&
                        provider.episodePointOfContact.providerFinancial[0]?.bankDetails
                          ?.displayAccountNumber
                      ">
                      <div class="alert bg-blue-light rounded-sm d-flex align-items-center p-2">
                        <span class="font-weight-500 text-dark">
                          <table mdbTable small="true" borderless="true">
                            <tr>
                              <td><span>Account Number -</span></td>
                              <td>
                                <span *ngIf="!provider.hideAccNumber">
                                  {{
                                    provider.episodePointOfContact?.providerFinancial[0]
                                      ?.bankDetails?.displayAccountNumber
                                  }}</span
                                >
                                <span *ngIf="provider.hideAccNumber">
                                  {{
                                    provider.episodePointOfContact?.providerFinancial[0]
                                      ?.bankDetails?.accountNumber
                                  }}</span
                                >
                              </td>
                              <td>
                                <a (click)="provider.hideAccNumber = !provider.hideAccNumber">
                                  <i class="fas fa-eye" *ngIf="!provider.hideAccNumber"></i>
                                  <i class="fas fa-eye-slash" *ngIf="provider.hideAccNumber"></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td><span>Routing Number -</span></td>
                              <td>
                                <span *ngIf="!provider.hideRoutingNumber">
                                  {{
                                    provider.episodePointOfContact?.providerFinancial[0]
                                      ?.bankDetails?.displayRoutingNumber
                                  }}</span
                                >
                                <span *ngIf="provider.hideRoutingNumber">
                                  {{
                                    provider.episodePointOfContact?.providerFinancial[0]
                                      ?.bankDetails?.routingNumber
                                  }}</span
                                >
                              </td>
                              <td>
                                <a
                                  (click)="
                                    provider.hideRoutingNumber = !provider.hideRoutingNumber
                                  ">
                                  <i class="fas fa-eye" *ngIf="!provider.hideRoutingNumber"></i>
                                  <i
                                    class="fas fa-eye-slash"
                                    *ngIf="provider.hideRoutingNumber"></i>
                                </a>
                              </td>
                            </tr>
                          </table>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="
                      provider.episodePointOfContact &&
                      (provider.episodePointOfContact == 'null' ||
                        !provider.episodePointOfContact.providerFinancial ||
                        provider.episodePointOfContact.providerFinancial.length == 0 ||
                        (provider.episodePointOfContact.providerFinancial &&
                          provider.episodePointOfContact.providerFinancial.length > 0 &&
                          !provider.episodePointOfContact.providerFinancial[0].bankDetails
                            .accountNumber))
                    ">
                    <i>No financial information available.</i>
                  </div>
                </div>
                <hr />
                <div class="keywords">
                  Profile Tags
                  <div
                    *ngIf="provider.tag && provider.tag.length > 0"
                    class="keywords d-flex mt-2 flex-wrap align-items-center">
                    <span class="d-block mb-2" *ngFor="let tag of provider.tag">
                      <div
                        class="bg-primary-dark mr-1 badge p-2 mb-1 text-white rounded-sm z-depth-0 mb-0">
                        <span class="font-weight-500">{{ tag }}</span>
                      </div>
                    </span>
                  </div>
                  <div *ngIf="!provider.tag || provider.tag.length === 0">
                    <i>No profile tags available.</i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <pagination-controls
    *ngIf="providerList && providerList.length > 0"
    class="text-center"
    (pageChange)="pageChanged($event)"
    autoHide="true">
  </pagination-controls>

  <div
    appScrollToTop
    class="fixed-action-btn donotprint"
    style="bottom: 145px; right: 10px; display: none">
    <a mdbPageScroll (click)="scrollToTop()" class="waves-light btn-light btn-floating">
      <i class="fas fa-angle-up text-black-50"></i>
    </a>
  </div>
  <div
    class="text-center p-4 border mt-3"
    *ngIf="!loading && (!providerList || providerList.length == 0)">
    <i class="fas fa-3x fa-hospital text-black-50"></i>
    <p>No results found.</p>
  </div>
</section>
