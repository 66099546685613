export class Milestone {
    completionDate?: string;
    createdBy: string;
    createdDate: string;
    description: string;
    endDate: string;
    episodeId?: number;
    id: number;
    isActive: boolean;
    isKeyMilestone?: boolean;
    isTriggerOnStart: boolean;
    name: string;
    originalMilestoneStatus?: string;
    relativeStartDate?: string;
    pathwayId?: number;
    sequence: number;
    startDate: string;
    status?: string;
    topics?: Topic[];
    triggerCondition: string;
    triggerDays: number;
    triggerMilestoneId: number;
    triggerMilestoneName?: string;
    triggerMilestoneUuid: string;
    triggerPeriod: string;
    updatedBy: string;
    updatedDate: string;
    uuid: string;
}

export class Topic {
    createdBy: string;
    createdDate: string;
    description: string;
    escalationDays: string;
    id: number;
    isActive: boolean;
    patientTopic: string;
    sequence: number;
    status: string;
    topic: string;
    type: string;
    updatedBy: string;
    updatedDate: string;
    uuid: string;
}