import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MilestoneTopicService {

  constructor(
    private http: HttpClientService
  ) { }

  getTopics(milestone_id) {
    return this.http.get(`${APICONSTANTS.TASK}/${milestone_id}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  saveTopic(topic) {
    return this.http.post(`${APICONSTANTS.MILESTONE}/${topic.milestoneId}${APICONSTANTS.TASK}`, topic)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
  updateTopic(topic) {
    return this.http.put(`${APICONSTANTS.MILESTONE}/${topic.milestoneId}${APICONSTANTS.TASK}`, topic)
      .pipe(map((res: any) => {
        return res.data;
      }));
  }
}
