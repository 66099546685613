<div class="holista-skin" [class]="theme" [ngClass]="sidenavStatus">
  <app-header (emitMessageRead)="receiveMessageRead($event)"></app-header>
  <main [ngClass]="user?.roleCode == 'MP' ? 'layoutPatient' : ' '">
    <router-outlet></router-outlet>
  </main>
  <!-- <div id="ringtone"></div> -->
  <audio id="incomingRingtone" muted>
    <source src="../../../assets/messenger.mp3" type="audio/mpeg" />
  </audio>
  <audio id="outgoingRingtone" muted>
    <source src="../../../assets/outgoing_ringtone.mp3" type="audio/mpeg" />
  </audio>
  <!-- Main layout -->
  <main class="mt-n5">
    <mdb-card class="chat-room-wrapper donotprint">
      <mdb-card-body class="p-0">
        <!-- Grid row -->
        <div *ngIf="showChatUsers" class="row no-gutters">
          <!-- Grid column -->
          <div class="col-12 col-md-6 col-lg-5 col-xl-4 border-right">
            <div class="align-self-stretch">
              <!-- <mdb-card class="chat-room small-chat wide donotprint m-0 h-100"> -->
              <div
                class="d-flex justify-content-between align-items-center p-3 border-bottom"
                id="toggle"
                style="cursor: pointer"
                (click)="showChatList()">
                <div class="heading d-flex justify-content-start">
                  <i class="icofont-ui-message icofont-lg text-primary mr-2"></i>
                  <h6 class="mb-0">Messages</h6>
                </div>
                <div class="alert bg-danger border-0 rounded-circle m-0 text-white">
                  {{ unreadUserMessages.length }}
                </div>
              </div>
              <div
                [hidden]="!showChatUsers"
                appPreviousChatUsers
                class="user-panel scrollbar scrollbar-primary thin">
                <div
                  class="form-group has-search m-2 position-relative"
                  *ngIf="user.primaryRole !== 'MP'">
                  <span class="form-control-feedback search-icon">
                    <i class="icofont-search-1"></i>
                  </span>
                  <input
                    type="text"
                    [(ngModel)]="searchKeyword"
                    (ngModelChange)="onSearchChange($event)"
                    class="form-control"
                    id="searchUser"
                    placeholder="Search user" />
                </div>
                <app-loader [loader]="loading"></app-loader>
                <div
                  class="text-center p-4 border mt-3"
                  *ngIf="
                    !loading &&
                    (((!usersList || usersList.length === 0) && searchKeyword.length === 0) ||
                      (searchKeyword.length > 0 && searchUsers.length === 0))
                  ">
                  <i class="fas fa-3x fa-user-tie text-black-50"></i>
                  <p>
                    {{
                      searchKeyword.length > 0
                        ? 'No User found'
                        : user.primaryRole !== 'MP'
                        ? 'Search User to start
                    conversation'
                        : 'No Conversation'
                    }}
                  </p>
                </div>
                <ul class="list-unstyled users-list">
                  <!--  chat mobile view on click add slideIn class-->
                  <li
                    class="p-2"
                    *ngFor="
                      let chatUser of searchKeyword.length > 0
                        ? searchUsers
                        : (usersList | filter : searchKeyword : ['name'])
                    "
                    (click)="onUserSelected(chatUser)">
                    <a
                      href="javascript:void(0)"
                      class="d-flex justify-content-start align-items-start">
                      <div class="profile-photo  user-{{ chatUser.status }}">
                        <i class="icofont-ui-user"></i>
                        <span class="state state-{{ chatUser.status }}"></span>
                      </div>
                      <div class="font-small ml-2 text-black-50">
                        <div class="d-flex justify-content-between align-items-start">
                          <div class="mb-0">
                            <span class="d-block">
                              {{ chatUser.name }}
                            </span>
                            <span
                              *ngFor="let role of chatUser.roleName"
                              class="badge badge-info badge-pill z-depth-0 mt-1">
                              {{ role }}
                            </span>
                          </div>
                          <small
                            [ngStyle]="{
                              color:
                                chatUser.lastMessage &&
                                user.id == chatUser.lastMessage.receiver.uid &&
                                !chatUser.lastMessage.readAt
                                  ? '#67ACD5'
                                  : ''
                            }">
                            {{
                              chatUser.lastMessage
                                ? (chatUser.lastMessage.sentAt | messageDateTime)
                                : ''
                            }}
                          </small>
                        </div>
                        <span class="d-flex justify-content-between">
                          <b
                            [ngStyle]="{
                              color:
                                chatUser.lastMessage &&
                                user.id == chatUser.lastMessage.receiver.uid &&
                                !chatUser.lastMessage.readAt
                                  ? '#67ACD5'
                                  : ''
                            }"
                            class="mr-2"
                            >{{
                              chatUser.lastMessage &&
                              chatUser.lastMessage.category == 'message' &&
                              !chatUser.lastMessage.data.attachments
                                ? chatUser.lastMessage.sender.uid == user.id
                                  ? 'You' +
                                    ':' +
                                    ' ' +
                                    (chatUser.lastMessage.data.text | truncate : ['30', '...'])
                                  : (chatUser.lastMessage.data.text | truncate : ['30', '...'])
                                : ''
                            }}
                            {{
                              chatUser.lastMessage && chatUser.lastMessage.category == 'call'
                                ? (chatUser.lastMessage.type | titlecase) +
                                  ' ' +
                                  chatUser.lastMessage.category +
                                  '
                            ' +
                                  (chatUser.lastMessage.action == 'initiated' ||
                                  chatUser.lastMessage.action == 'ongoing'
                                    ? 'started'
                                    : chatUser.lastMessage.action)
                                : ''
                            }}
                            {{
                              chatUser.lastMessage &&
                              chatUser.lastMessage.category == 'message' &&
                              chatUser.lastMessage.data.attachments
                                ? (chatUser.lastMessage.sender.uid == user.id
                                    ? 'You'
                                    : chatUser.lastMessage.sender.name) +
                                  '
                            ' +
                                  'sent an attachment.'
                                : ''
                            }}
                          </b>
                          <i
                            *ngIf="
                              chatUser.lastMessage && user.id == chatUser.lastMessage.sender.uid
                            "
                            class="pl-1 fa-check-circle mr-2"
                            [ngClass]="{
                              fas: chatUser.lastMessage.deliveredAt,
                              far: !chatUser.lastMessage.deliveredAt,
                              messageRead: chatUser.lastMessage.readAt
                            }"></i>
                        </span>
                      </div>
                    </a>
                  </li>
                  <!--  chat mobile view on click slideIn class end-->
                  <app-loader [loader]="chatUsers_loading"></app-loader>
                </ul>
              </div>
            </div>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div
            class="col-12 col-md-6 col-lg-7 col-xl-8 bg-white"
            [ngClass]="selectedUser ? 'slideIn' : 'slideOut'">
            <div
              class="h-100"
              *ngFor="let userMessage of userMessages; let i = index"
              [ngClass]="{'mr-2': userMessage.minimize}">
              <form
                class="h-100"
                (keyup.enter)="
                  userMessage.message.length &&
                    sendMessage(userMessage.uid, userMessage.message, i);
                  !isUploadingFile &&
                    userMessage.file &&
                    userMessage.file.name &&
                    sendMessage(userMessage.uid, userMessage.file, i)
                "
                autocomplete="off">
                <div
                  class="d-flex justify-content-between align-items-center p-2 border-bottom"
                  id="toggle"
                  style="cursor: pointer"
                  (click)="isMobile ? '' : showChatList()">
                  <div class="heading d-flex justify-content-start align-items-center">
                    <button
                      class="btn btn-link p-0 m-0 d-block d-sm-none"
                      (click)="showListForMobile()">
                      <i class="icofont-simple-left icofont-2x"></i>
                    </button>

                    <div class="profile-photo mr-2 profile-photo-sm">
                      <i class="icofont-ui-user"></i>
                      <span class="state state-{{ userMessage.status }}"></span>
                    </div>
                    <div class="data">
                      <p class="mb-0">{{ userMessage.name }}</p>
                      <small class="text-muted">{{
                        userMessage.status == 'online'
                          ? 'Active Now'
                          : !userMessage.metadata.isRegistered ||
                            (userMessage.metadata.status &&
                              userMessage.metadata.status === 'inactive')
                          ? 'Deactivated'
                          : 'Offline'
                      }}</small>
                    </div>
                    <span
                      *ngIf="userMessage.minimize && userMessage.unreadMessageCount > 0"
                      class="badge badge-danger rounded-lg notification ml-2"
                      >{{ userMessage.count }}</span
                    >
                  </div>
                  <div class="icons grey-text d-flex justify-content-end">
                    <!-- <a
                      [ngClass]="{
                        isDisabled:
                          !userMessage.metadata.isRegistered ||
                          (userMessage.metadata.status &&
                            userMessage.metadata.status === 'inactive')
                      }"
                      class="feature"
                      *ngIf="!userMessage.minimize">
                      <button
                        type="button"
                        class="btn btn-link p-0 btn"
                        (click)="!callSessionId && initiateCall(userMessage, 'VIDEO')"
                        mdbWavesEffect>
                        <mdb-icon fas icon="video" class="text-info mr-1"></mdb-icon>
                      </button>
                    </a>
                    <a
                      [ngClass]="{
                        isDisabled:
                          !userMessage.metadata.isRegistered ||
                          (userMessage.metadata.status &&
                            userMessage.metadata.status === 'inactive')
                      }"
                      class="feature"
                      *ngIf="!userMessage.minimize">
                      <button
                        type="button"
                        class="btn btn-link p-0 btn mr-2"
                        (click)="!callSessionId && initiateCall(userMessage, 'AUDIO')"
                        mdbWavesEffect>
                        <mdb-icon fas icon="phone" class="text-info mr-1"></mdb-icon>
                      </button>
                    </a> -->
                    <button
                      type="button"
                      class="btn btn-link p-0 btn mr-2"
                      (click)="showChatList()">
                      <mdb-icon fas icon="times"></mdb-icon>
                    </button>
                    <!-- <a type="button" id="closeButton" (click)="closeChat(userMessage.uid)">
                        <mdb-icon fas icon="times" class="mr-1"></mdb-icon>
                      </a> -->
                  </div>
                </div>
                <div
                  class="message-container d-flex flex-column donotprint"
                  [ngClass]="{'chat-collapsed mr-4': userMessage.minimize}"
                  id="myForm{{ i }}">
                  <div [hidden]="userMessage.minimize" class="message-panel">
                    <mdb-card-body class="p-0 d-block">
                      <div
                        class="chat-message p-3 scrollbar scrollbar-primary thin"
                        id="chat{{ userMessage.uid }}">
                        <div *ngIf="userMessage.messages.length == 0" class="text-center mt-5">
                          <i class="icofont-envelope-open icofont-4x grey-text"></i>
                          <p>Send message to start conversation</p>
                        </div>
                        <div *ngFor="let message of userMessage.messages; let i = index">
                          <div
                            class="text-center m-0"
                            *ngIf="
                              message.sentAt
                                | updateChatTime
                                  : (i == 0 ? null : userMessage.messages[i - 1].sentAt)
                            ">
                            <small>{{
                              message.sentAt
                                | updateChatTime
                                  : (i == 0 ? null : userMessage.messages[i - 1].sentAt)
                            }}</small>
                            <hr class="m-0" style="margin-bottom: 8px !important" />
                          </div>
                          <div
                            class="d-flex flex-column"
                            [ngClass]="{
                              'justify-content-end align-items-end': user.id == message.sender.uid,
                              'justify-content-start align-items-start':
                                user.id != message.sender.uid
                            }">
                            <!-- <small class="font-italic mb-1">Typing...</small> -->
                            <mdb-card
                              class="chat-bubble w-75 z-depth-0"
                              *ngIf="
                                message.text ||
                                (message.data.attachments && message.data.attachments.length > 0) ||
                                ((message.type == 'audio' || message.type == 'video') &&
                                  message.action != 'ongoing')
                              "
                              [ngClass]="{
                                'grey lighten-3 chat-bubble-user float-right z-depth-0 mb-2':
                                  user.id == message.sender.uid,
                                'bg-info float-left z-depth-0 mb-1 chat-bubble-sender':
                                  user.id != message.sender.uid
                              }">
                              <!-- <mdb-card-body class="p-2" mdbTooltip="{{message.sentAt?((message.sentAt*1000)| date:'h:mm aaa, MMM dd yyyy'):''}}" placement="top" > -->
                              <mdb-card-body class="p-2">
                                <p
                                  [ngClass]="{
                                    'text-dark': user.id == message.sender.uid,
                                    'text-white': user.id != message.sender.uid
                                  }">
                                  {{ message?.text }}
                                  {{
                                    message.type == 'audio' || message.type == 'video'
                                      ? (message.type | titlecase) +
                                        ' ' +
                                        message.category +
                                        '
                                  ' +
                                        (message.data.action == 'initiated'
                                          ? 'started'
                                          : message.data.action)
                                      : ''
                                  }}
                                  <strong>
                                    {{
                                      message.data.action == 'ended'
                                        ? (message?.data?.entities?.on?.entity?.duration
                                          | secondsToTime)
                                        : ''
                                    }}
                                  </strong>
                                  <a
                                    *ngIf="message.type == 'file' && message.data.url"
                                    href="{{ message.data.url }}"
                                    target="_blank">
                                    <i class="icofont-attachment"></i
                                    >{{ message.data.attachments[0].name }}
                                  </a>
                                  <a
                                    *ngIf="
                                      message.type == 'text' &&
                                      message.data.attachments &&
                                      message.data.attachments.length > 0
                                    "
                                    href="{{ message.data.attachments[0].url }}"
                                    target="_blank"
                                    ><br />
                                    <i class="icofont-attachment"></i>
                                    {{ message.data.attachments[0].name }}
                                  </a>
                                </p>
                                <span
                                  class="font-italic float-right mt-0"
                                  [ngClass]="{
                                    'text-black-50': user.id == message.sender.uid,
                                    'text-white': user.id != message.sender.uid,
                                    'float-none text-center text-black-20 d-block':
                                      message.type == 'audio' || message.type == 'video'
                                  }"
                                  >{{
                                    message.sentAt
                                      ? (message.sentAt * 1000 | date : 'shortTime')
                                      : ''
                                  }}
                                  <i
                                    *ngIf="
                                      user.id == message.sender.uid &&
                                      (message.type != 'audio' || message.type != 'video')
                                    "
                                    class="pl-1 fas"
                                    [ngClass]="{
                                      'fa-check-double': message.deliveredAt,
                                      'fa-check': !message.deliveredAt,
                                      messageRead: message.readAt
                                    }"></i>
                                </span>
                              </mdb-card-body>
                            </mdb-card>
                          </div>
                        </div>
                        <!--  -->
                        <span *ngIf="userMessage.typingText" class="typing-alert ml-3">{{
                          userMessage.typingText
                        }}</span>
                      </div>
                    </mdb-card-body>
                  </div>
                  <footer
                    *ngIf="!userMessage.minimize"
                    class="text-muted white pt-1 pb-0 px-3 border-top text-editor">
                    <textarea
                      type="text"
                      name="message"
                      rows="2"
                      [placeholder]="isUploadingFile ? 'Sending...' : 'Type a message...'"
                      [style.height]="isMessageSent && '60px'"
                      class="md-textarea md-textarea-auto form-control bg-white border-0 pl-2 pr-2"
                      [(ngModel)]="userMessage.message"
                      id="message{{ userMessage.uid }}"
                      [disabled]="
                        isUploadingFile ||
                        !userMessage.metadata.isRegistered ||
                        (userMessage.metadata.status && userMessage.metadata.status === 'inactive')
                      "
                      (input)="onTyping(userMessage)"
                      mdbInput>
                    </textarea>
                    <mdb-card
                      *ngIf="userMessage.file && userMessage.file.name"
                      class="z-depth-0 border border-light p-2 mb-2 bg-bluegrey">
                      <mdb-card-body class="p-0">
                        <div class="media align-items-center">
                          <i class="icofont-attachment icofont-lg d-flex mr-2 text-info"></i>
                          <div class="media-body">
                            {{ userMessage.file?.name }}
                            <a
                              *ngIf="!isUploadingFile"
                              class="float-right text-danger"
                              (click)="removeAttachFile(userMessage)">
                              <i class="icofont-close-line-circled"></i
                            ></a>
                          </div>
                        </div>
                      </mdb-card-body>
                    </mdb-card>
                    <div class="p-2 d-flex justify-content-between chat-footer-btns">
                      <a
                        [ngClass]="{
                          isDisabled:
                            !userMessage.metadata.isRegistered ||
                            (userMessage.metadata.status &&
                              userMessage.metadata.status === 'inactive')
                        }"
                        class="file-upload"
                        mdbTooltip="Add File"
                        placement="top">
                        <mdb-icon
                          fas
                          icon="paperclip"
                          (click)="inputFile.click()"
                          class="mr-2"></mdb-icon>
                        <input
                          type="file"
                          name="inputFile"
                          id="inputFile{{ userMessage.uid }}"
                          #inputFile
                          hidden
                          (change)="handleInputChange($event, userMessage)" />
                      </a>
                      <a mdbTooltip="Send" placement="top">
                        <mdb-icon
                          fas
                          icon="paper-plane"
                          (click)="
                            userMessage.message.length &&
                              sendMessage(userMessage.uid, userMessage.message, i);
                            !isUploadingFile &&
                              userMessage.file &&
                              userMessage.file.name &&
                              sendMessage(userMessage.uid, userMessage.file, i)
                          "
                          class="ml-2"></mdb-icon>
                      </a>
                    </div>
                  </footer>
                </div>
              </form>
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </mdb-card-body>
    </mdb-card>
    <div class="w-auto pl-4 chat-room-wrapper chat-room scrollbar scrollbar-primary thin">
      <div class="d-flex align-items-start clearfix h-100"></div>
    </div>
  </main>

  <!-- Chat icon -->
  <div *ngIf="user" class="fixed-action-btn donotprint" style="bottom: 20px; right: 10px">
    <a
      class="waves-light btn-info btn-floating"
      data-toggle="modal"
      data-target="#basicExample"
      (click)="showChatList()">
      <i class="far fa-comments">
        <span class="badge badge-danger rounded-circle notification p-0">{{
          unreadUserMessages.length > 0 ? unreadUserMessages.length : ''
        }}</span>
      </i>
    </a>
  </div>

  <!-- <div mdbModal #frame="mdbModal" class="modal fade right chat-room  scrollbar scrollbar-primary thin"
    appPreviousChatUsers id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" [config]="{ backdrop: false, ignoreBackdropClick: false, keyboard:false }"
    (close)="searchKeyword=''">
    <div class="modal-dialog modal-full-height modal-right modal-notify modal-sm" role="document">
      <div class="modal-content grey lighten-5">
        <div class="modal-header z-depth-0 position-sticky header">
          <p class="mb-0 lead">Users</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="frame.hide();searchKeyword=''">
            <i class="icofont-close-line"></i>
          </button>
        </div>
        <div class="modal-body p-2">
          <div class="form-group has-search position-relative mt-2 mb-2" *ngIf="user.primaryRole !=='MP'">
            <span class="form-control-feedback">
              <i class="icofont-search-1"></i>
            </span>
            <input type="text" [(ngModel)]="searchKeyword" (ngModelChange)="onSearchChange($event)" class="form-control"
              id="searchUser" placeholder="Search user">
          </div>

          <app-loader [loader]="loading"></app-loader>

          <div class="text-center p-4 border mt-3"
            *ngIf="!loading && ((!usersList ||usersList.length===0) && searchKeyword.length===0 ||( searchKeyword.length>0 &&  searchUsers.length===0))">
            <i class="fas fa-3x fa-user-tie text-black-50"></i>
            <p>
              {{(searchKeyword.length>0)? 'No User found' : (user.primaryRole !=='MP')? 'Search User to start
              conversation':'No Conversation'}}
            </p>
          </div>
          <ul class="list-unstyled users-list">
            <li class="p-2"
              *ngFor="let chatUser of searchKeyword.length>0?searchUsers:usersList | filter: searchKeyword:['name']"
              (click)="onUserSelected(chatUser)">
              <a href="javascript:void(0)" class="d-flex justify-content-start align-items-center">
                <div class="profile-photo  user-{{ chatUser.status }}">
                  <i class="icofont-ui-user"></i>
                  <span class="state state-{{ chatUser.status }}"></span>
                </div>
                <div class="text-smaller ml-2 text-black-50">
                  <div class="d-flex justify-content-between align-items-start">

                    <div class="mb-0">
                      <span class="d-block">
                        {{ chatUser.name }}
                      </span>

                      <span class="badge badge-info badge-pill z-depth-0">{{
                        chatUser.roleName
                        }}
                      </span>
                    </div>
                    <small
                      [ngStyle]="{'color':chatUser.lastMessage && user.id == chatUser.lastMessage.receiver.uid && !chatUser.lastMessage.readAt?'#67ACD5':''}">
                      {{chatUser.lastMessage?(chatUser.lastMessage.sentAt|messageDateTime):''}}
                    </small>
                  </div>
                  <span class="d-flex justify-content-between">
                    <b [ngStyle]="{'color':chatUser.lastMessage && user.id == chatUser.lastMessage.receiver.uid && !chatUser.lastMessage.readAt?'#67ACD5':''}"
                      class="mr-2">{{chatUser.lastMessage && chatUser.lastMessage.category=="message" &&
                      !chatUser.lastMessage.data.attachments?(chatUser.lastMessage.sender.uid==user.id?'You'+ ':' + ' '
                      +
                      (chatUser.lastMessage.data.text|truncate:['30','...']):(chatUser.lastMessage.data.text|truncate:['30','...'])):''}}
                      {{chatUser.lastMessage &&
                      chatUser.lastMessage.category=="call"?((chatUser.lastMessage.type|titlecase)+ ' ' +
                      chatUser.lastMessage.category+ '
                      '+((chatUser.lastMessage.action=='initiated'||chatUser.lastMessage.action=='ongoing')?'started':chatUser.lastMessage.action)):''}}
                      {{chatUser.lastMessage && chatUser.lastMessage.category=="message" &&
                      chatUser.lastMessage.data.attachments?(chatUser.lastMessage.sender.uid==user.id?'You':chatUser.lastMessage.sender.name)+'
                      '+'sent an attachment.':''}}
                    </b>
                    <i *ngIf="chatUser.lastMessage && user.id == chatUser.lastMessage.sender.uid"
                      class='pl-1 fa-check-circle mr-2' [ngClass]="{
                    'fas':chatUser.lastMessage.deliveredAt,
                    'far':!chatUser.lastMessage.deliveredAt,
                    'messageRead':chatUser.lastMessage.readAt}"></i>
                  </span>
                </div>
              </a>
            </li>
            <app-loader [loader]="chatUsers_loading"></app-loader>
          </ul>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Audio/Video Call Screen -->
  <div
    class="drag-block"
    [ngClass]="minimizeScreen ? 'modalcollapse' : 'modalexpand'"
    style="display: none"
    ngDraggable
    (edge)="checkEdge($event)"
    id="callscreen"
    [handle]="dragHandle"
    [bounds]="callscreen"
    [inBounds]="inBounds"
    [zIndex]="1080">
    <div class="modal-content call-container">
      <div #dragHandle class="modal-header border-0 p-0 justify-content-end">
        <button
          class="btn btn-link btn-sm m-0 p-2"
          type="button"
          mdbWavesEffect
          (click)="minMaxCallScreen(true)">
          <mdb-icon fas icon="window-minimize" class="text-white"></mdb-icon>
        </button>
        <button
          class="btn btn-link btn-sm m-0 p-2"
          type="button"
          mdbWavesEffect
          (click)="minMaxCallScreen(false)">
          <mdb-icon far icon="window-maximize" class="text-white"></mdb-icon>
        </button>
      </div>
      <div
        class="modal-body p-0 d-flex align-items-center justify-content-center flex-column"
        *ngIf="callSessionId">
        <app-outgoing-call
          class="d-block w-100"
          *ngIf="outgoingCall"
          [call]="outgoingCall"
          (endCall)="endInitiatedCall($event)"
          (cancelCall)="endInitiatedCall($event)"
          [CallRingtone]="callRingtone">
        </app-outgoing-call>
        <app-incoming-call
          class="d-block w-100"
          *ngIf="incomingCall"
          (acceptCall)="acceptCall($event)"
          (rejectCall)="rejectCall($event)"
          [call]="incomingCall"
          [CallRingtone]="callRingtone">
        </app-incoming-call>
        <div id="callScreen" [ngClass]="{'call-screen': hideCallScreen}"></div>
      </div>
      <div
        class="modal-body p-0 d-flex align-items-center justify-content-center flex-column"
        *ngIf="!callSessionId">
        <div class="text-center">
          <div>
            <div class="icon-block icon-block-lg text-center bg-danger rounded-circle mx-auto">
              <i class="icofont-phone icofont-rotate-90 text-white"></i>
            </div>
            <small>{{ userCallStatus }}</small>
          </div>
          <button
            class="mt-4 btn btn-info btn-rounded btn-outline btn-sm"
            type="button"
            aria-label="Close"
            (click)="closeModal()"
            mdbWavesEffect>
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    mdbModal
    #inactiveLogout="mdbModal"
    class="modal fade"
    id="frameModalTop"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    [config]="{backdrop: true, ignoreBackdropClick: true, keyboard: false}">
    <div class="modal-dialog modal-info modal-dialog-centered" role="document">
      <!--Content-->
      <div class="modal-content text-center">
        <!--Header-->
        <div class="modal-header d-flex justify-content-center">
          <h6 class="modal-title text-white">Inactive Logout</h6>
        </div>
        <!--Body-->
        <div class="modal-body">
          <mdb-icon
            fas
            icon="bell"
            size="4x"
            class="animated rotateIn text-primary mb-4"></mdb-icon>
          <p>
            You have been idle for about 15 minutes. If you wish to stay online please click "Keep
            me Logged In".
          </p>
        </div>
        <!--Footer-->
        <div class="modal-footer justify-content-center">
          <a
            href="javascript:void(0)"
            (click)="KeepMeLogged()"
            class="btn btn-secondary text-white waves-light"
            mdbWavesEffect
            >Keep me Logged in</a
          >
        </div>
      </div>
      <!--/.Content-->
    </div>
  </div>
</div>
<div class="drag-boundary" #callscreen></div>
