<div *ngIf="tasks && tasks.length > 0">
  <div class="scrollbar scrollbar-primary thin overflow-auto" style="max-height: 500px">
    <div
      class="list-content-item hover"
      *ngFor="let task of tasks"
      [ngClass]="{dependent: task.isDependent}">
      <div class="" (click)="selectTask($event, task)">
        <span class="text-info d-block" *ngIf="task.isDependent">
          <!-- <i class="icofont-link"></i> Dependent Task -->
        </span>
        <span class="d-flex justify-content-start align-items-start">
          <mdb-checkbox
            *ngIf="
              !(isTaskDisabled || task.field === 'message') &&
              (task.field === 'question' ||
                task.field === 'questionnaire' ||
                task.field === 'todo' ||
                task.field === 'signature') &&
              (!task.status || task.status == 'DUE' || task.status === 'DECLINED')
            "
            [filledIn]="true"
            [disabled]="
              (!task.status || task.status !== 'COMPLETED' || task.status !== 'DUE') &&
              !milestoneCompletedByCCES
                ? false
                : true
            "
            [checked]="task.selected"
            (change)="selectTask($event, task)"></mdb-checkbox>
          <mdb-checkbox
            *ngIf="
              isTaskDisabled ||
              task.field == 'message' ||
              (task.status && task.status !== 'DUE' && task.status !== 'DECLINED')
            "
            [checked]="task.status && !milestoneCompletedByCCES ? true : false"
            [disabled]="true">
          </mdb-checkbox>
          <app-episode-user-todo
            (click)="isTaskDisabled && $event.stopPropagation()"
            *ngIf="task.field == 'todo'"
            [task]="task"
            [user]="user"
            class="d-block w-100">
          </app-episode-user-todo>
          <app-episode-user-signature
            (click)="isTaskDisabled && $event.stopPropagation()"
            *ngIf="task.field == 'signature'"
            [task]="task"
            [user]="user"
            class="d-block w-100">
          </app-episode-user-signature>
          <app-episode-user-message
            (click)="isTaskDisabled && $event.stopPropagation()"
            *ngIf="task.field == 'message'"
            [message]="task"
            [user]="user"
            class="d-block w-100">
          </app-episode-user-message>
          <app-episode-user-question
            (click)="isTaskDisabled && $event.stopPropagation()"
            *ngIf="task.field == 'question'"
            id="task{{ task.uuid }}"
            [question]="task"
            [user]="user"
            [dependentTopicTasks]="dependentTopicTasks"
            class="d-block w-100"
            (updateQuestionAnswer)="updateQuestionsAnswer($event)">
          </app-episode-user-question>
          <app-episode-user-questionnaire
            (click)="isTaskDisabled && $event.stopPropagation()"
            *ngIf="task.field == 'questionnaire'"
            id="task{{ task.uuid }}"
            [questionnaire]="task"
            [user]="user"
            [dependentTopicTasks]="dependentTopicTasks"
            (updateQuestionnairesAnswer)="updateQuestionnairesAnswer($event)"
            class="d-block w-100"
            id="task">
          </app-episode-user-questionnaire>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="text-center p-4" *ngIf="!tasks || tasks.length == 0">
  <h5>No tasks available</h5>
</div>
