export const ClaimsConstants = {
    processingStatus: [
        { label: 'In-Review', value: 'IN-REVIEW' },
        { label: 'Rejected', value: 'REJECTED' },
        { label: 'Denied', value: 'DENIED' },
        { label: 'Queue', value: 'QUEUE' },
        { label: 'Queue-Hold', value: 'QUEUE-HOLD' },
        { label: 'Fund Request In-Process', value: 'FUND-REQUEST-IN-PROCESS' },
        { label: 'Fund Requested', value: 'FUND-REQUESTED' },
        { label: 'Ready To Pay', value: 'READY-TO-PAY' },
        { label: 'Payment In-Process', value: 'PAYMENT-IN-PROCESS' },
        { label: 'Paid', value: 'PAID' },
        { label: 'Fund Request Cancelled', value: 'FUND-REQUEST-CANCELLED' },
        { label: 'Adjustment', value: 'ADJUSTMENT' },
    ],

    status: [
        { label: 'Valid', value: 'VALID' },
        { label: 'Invalid', value: 'INVALID' },
    ],

    ediType: [
        { label: '837I', value: '837I' },
        { label: '837P', value: '837P' },
    ]
}

export const OutgoingClaimsConstant = {
    processingStatus: [
        { label: 'In-Review', value: 'IN-REVIEW' },
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Rejected', value: 'REJECTED' },
        { label: 'Cancelled', value: 'CANCELLED' },
        { label: 'Received', value: 'RECEIVED' },
        { label: 'Submitted', value: 'SUBMITTED' },
        { label: 'Re-Submitted', value: 'RE-SUBMITTED' },
        { label: 'Pended', value: 'PENDED' },
    ],
    claimsDashboardFilterableStatus: [
        { label: 'All', value: 'ALL' },
        { label: 'In-Review', value: 'IN-REVIEW' },
        { label: 'Rejected', value: 'REJECTED' },
        { label: 'Cancelled', value: 'CANCELLED' },
        { label: 'Submitted', value: 'SUBMITTED' },
        { label: 'Re-Submitted', value: 'RE-SUBMITTED' },
        { label: 'Pended', value: 'PENDED' }
    ]
}

export const SERVICE_LINE_VALIDATION_CONSTANTS = {
    PROCEDURE_CODE_LENGTH: 5,
    REVENUE_CODE_LENGTH: 4,
    NON_EDI_DATE_LENGTH: 8,
    RD8_FORMAT_DATE_LENGTH: 17,
    RD8_DATE_FORMAT: 'RD8'
}

export const POLICY_CODES = ['CAR'];
export const EXPENSE_ALLOCATIONS = ['administrative fee', 'client fee'];
export const ADMINISTRATIVE_FEE = 'administrative fee';
export const CLIENT_FEE = 'client fee';
export const CLIENTS_WITH_OPTIONAL_BUNDLE_COMPONENT_SELECTION = ['NOVANT', 'CAR'];

export const EXCEPTION_CODES = {
    SERVICES_INCL: { code: '2023', description: 'Services included in bundled payment. No additional payment allowed.' },
    HRA_HSA: { code: '187', description: 'Consumer Spending Account payments (includes but is not limited to Flexible Spending Account, Health Savings Account, Health Reimbursement Account, etc).' }
};

export const DISABLE_APPROVE_EDI_DESCRIPTION = 'Payment Mode Trace Number is missing.';

export const CLAIMS_PROCESSING_STATUS = {
    IN_REVIEW: 'IN-REVIEW',
    REJECTED: 'REJECTED',
    DENIED: 'DENIED',
    QUEUE: 'QUEUE',
    QUEUE_HOLD: 'QUEUE-HOLD',
    FUND_REQUEST_IN_PROCESS: 'FUND-REQUEST-IN-PROCESS',
    FUND_REQUESTED: 'FUND-REQUESTED',
    READY_TO_PAY: 'READY-TO-PAY',
    PAYMENT_IN_PROCESS: 'PAYMENT-IN-PROCESS',
    PAID: 'PAID',
    FUND_REQUEST_CANCELLED: 'FUND-REQUEST-CANCELLED',
    ADJUSTMENT: 'ADJUSTMENT',
}


export const POSSIBLE_INCOMING_CLAIM_STATUSES = {
    'IN-REVIEW': {
        ASSOCIATED: [CLAIMS_PROCESSING_STATUS.QUEUE, CLAIMS_PROCESSING_STATUS.REJECTED, CLAIMS_PROCESSING_STATUS.DENIED],
        UN_ASSOCIATED: [CLAIMS_PROCESSING_STATUS.REJECTED, CLAIMS_PROCESSING_STATUS.DENIED],
        PARTIALLY_ASSOCIATED: [CLAIMS_PROCESSING_STATUS.QUEUE_HOLD, CLAIMS_PROCESSING_STATUS.REJECTED, CLAIMS_PROCESSING_STATUS.DENIED]
    },
    'QUEUE': [CLAIMS_PROCESSING_STATUS.IN_REVIEW, CLAIMS_PROCESSING_STATUS.ADJUSTMENT],
    'FUND-REQUEST-IN-PROCESS': [CLAIMS_PROCESSING_STATUS.IN_REVIEW, CLAIMS_PROCESSING_STATUS.ADJUSTMENT],
    'FUND-REQUESTED': [CLAIMS_PROCESSING_STATUS.IN_REVIEW, CLAIMS_PROCESSING_STATUS.ADJUSTMENT],
    'READY-TO-PAY': [CLAIMS_PROCESSING_STATUS.IN_REVIEW, CLAIMS_PROCESSING_STATUS.ADJUSTMENT],
    'FUND-REQUEST-CANCELLED': [CLAIMS_PROCESSING_STATUS.IN_REVIEW, CLAIMS_PROCESSING_STATUS.QUEUE, CLAIMS_PROCESSING_STATUS.DENIED],
    'REJECTED': [CLAIMS_PROCESSING_STATUS.IN_REVIEW],
    'DENIED': [CLAIMS_PROCESSING_STATUS.IN_REVIEW],
    'QUEUE-HOLD': [CLAIMS_PROCESSING_STATUS.IN_REVIEW],
    'PAYMENT-IN-PROCESS': [CLAIMS_PROCESSING_STATUS.IN_REVIEW],
    'PAID': [CLAIMS_PROCESSING_STATUS.IN_REVIEW]
}

export const CLAIMS_PAYMENT_DESCRIPTIONS = {
    NO_CLAIMS: "No claims found.",
    RECEIVED_PAYMENT: "Use claim Identifier to search claims.",
    PAID_PAYMENT: "Use claim Identifier or search provider to search claims."
}

export const EDI_GENERATED_DENIED_CLAIMS_CREATED_PRIOR_TO_DATE = '20230601';
export const PRINCIPLE_DIAGNOSIS_QUALIFIER = 'ABK';
export const PRINCIPLE_DIAGNOSIS = 'principalDiagnosis';
export const OUTGOING = 'outgoing';
export const OUTGOING_CLAIM_MANAGEMENT_PERMISSION_CODE = 'OCLM'
export const IS_EDITABLE = 'isEditable'


export const CLAIMS_EDITABLE_STATUSES = { outgoing: ['IN-REVIEW', 'REJECTED', 'SUBMITTED', 'RE-SUBMITTED', 'PENDED'], incoming: ['IN-REVIEW'] }
export const CLAIMS_REEDITABLE_STATUSES = ['REJECTED', 'SUBMITTED', 'RE-SUBMITTED', 'PENDED'];

export const CLAIM_TYPES = {
    INCOMING: 'INCOMING',
    OUTGOING: 'OUTGOING'
}

export const SHOW_ELLIPSIS_STATUSES = ['IN-REVIEW', 'READY-TO-PAY', 'PAID', 'PAYMENT-IN-PROCESS', 'ADJUSTMENT']
export const HIDE_ASSOCIATE_EPISODE_STATUSES = ['READY-TO-PAY', 'PAID', 'PAYMENT-IN-PROCESS']
export const CLAIMS_STATUS = {
    VALID: 'valid',
    INVALID: 'invalid'
}
