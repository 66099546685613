import { Injectable } from "@angular/core";
import { Theme, RequestEocDomainList, appAnnouncementExcludedClient } from "../constants";

@Injectable()

export class HostTheme {
    hostTheme = Theme;
    requestEocDomainList = RequestEocDomainList;
    public Theme(): any {
        const urlHost = window.location.hostname;
        const theme = this.hostTheme.find(theme => urlHost.includes(theme)) || 'holista';
        return theme;
    }

    public RequestEoc(): any {
        const urlHost = window.location.hostname;
        const requestEocDomain = this.requestEocDomainList.find(permittedDomain => urlHost.includes(permittedDomain));
        if (requestEocDomain) return true
        return false;
    }

    public hasAppAnnouncement() {
        const url = window.location.hostname;
        const client = appAnnouncementExcludedClient.find(eachClient => url.includes(eachClient));
        if (client) {
            return false;
        }

        return true;
    }
}