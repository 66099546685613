export const MODULES = {
    QUESTION: 'question_group',
    MILESTONE: 'milestone_group',
    BUNDLE_COMPONENT: 'bundle_component_group',
    PAYMENT: 'payment'
}

export const MODULE_CODES = {
    REPORTING: 'RPM',
    PATHWAY: 'PM'
}

export const REPORTING_CUSTOM_REDIRECT_PATH = {
    DASHBOARD: 'reports'
}
